
export const mySingleton = (function () {
  var instance;
  function init() {
    var accessPermission = [];
    var selectedClient = "";
    var pageSpeedSelectedIndex = 0;
    var selectedModuleId = "";
    var orgId = "";

    var addData = true;
    var selectedObj = {};
    var selectedArray = [];
    var startDate = "";
    var endDate = "";
    var urlList = [];
    var scoauditdata = [];

    var seoObj = {};

    var isLoading = false;
    var changeData = "";

    var timer="00:00:00"

    return {
      setTimer: function (data) {
        timer = data;
      },
      getTimer: function () {
        return timer;
      },

      setCchangeData: function (data) {
        changeData = data;
      },
      getCchangeData: function () {
        return changeData;
      },

      setIsLoading: function (data) {
        isLoading = data;
      },
      getIsLoading: function () {
        return isLoading;
      },
      setSEOobj: function (data) {
        seoObj = data;
      },
      getSEOobj: function () {
        return seoObj;
      },
      setSCOAuditData: function (data) {
        scoauditdata = data;
      },
      getSCOAuditData: function () {
        return scoauditdata;
      },
      setUrls: function (data) {
        urlList = data;
      },
      getUrls: function () {
        return urlList;
      },
      setStartDate: function (data) {
        startDate = data;
      },
      getStartDate: function () {
        return startDate;
      },
      setEndDate: function (data) {
        endDate = data;
      },
      getEndDate: function () {
        return endDate;
      },

      setSelectedObj: function (data) {
        selectedObj = data;
      },
      getSelectedObj: function () {
        return selectedObj;
      },
      setSelectedArray: function (data) {
        selectedArray = data;
      },
      getSelectedArray: function () {
        return selectedArray;
      },
      setIsAdd: function (data) {
        addData = data;
      },
      getIsAdd: function () {
        return addData;
      },
      setOrgId: function (data) {
        orgId = data;
      },
      getOrgId: function () {
        return orgId;
      },
      setModuleId: function (data) {
        selectedModuleId = data;
      },
      getModuleId: function () {
        return selectedModuleId;
      },
      setPageIndex: function (data) {
        pageSpeedSelectedIndex = data;
      },
      getPageIndex: function () {
        return pageSpeedSelectedIndex;
      },
      setAccessPermission: function (data) {
        accessPermission = data;
      },
      getAccessPermission: function () {
        return accessPermission;
      },
      setSelectedClient: function (data) {
        selectedClient = data;
      },
      getSelectedClient: function () {
        return selectedClient;
      },
    };
  }
  return {
    // Get the Singleton instance if one exists
    // or create one if it doesn't
    getInstance: function () {
      if (!instance) {
        instance = init();
      }
      return instance;
    },
  };
})();
