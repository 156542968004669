import React, { useState, Component } from "react";
import { useEffect } from "react";
import { Accordion } from "react-bootstrap";

  function AppGoogleSheetDemo() {

  const [fullname, setfullname] = useState("");
  const [expandAll, setexpandAll] = useState(false);
  const [mainList, setmainList] = useState([
    {
      title: "Main 1",
      subList: [
        {
          subTitle: 'Sub 11',
          isActive: false
        }, {
          subTitle: 'Sub 12',
          isActive: false
        }
      ]
    }, {
      title: "Main 2",
      subList: [
        {
          subTitle: 'Sub 21',
          isActive: false
        }, {
          subTitle: 'Sub 22',
          isActive: false
        }
      ]
    }
  ]);

  function forceUpdateHandler(){
    let tempArr =  mainList
    tempArr.map((i) => {
      i.subList.map((ii) => {
        ii.isActive =  !expandAll
      })
    })
    setmainList(tempArr)
    setexpandAll(!expandAll)
    setfullname("value")
  };

  function stateChange(f){
    const { name, value } = f.target;
    setfullname(value)
  }

    return (
      <div>
        <input hidden type="text" name="fullname" value={fullname} onChange={stateChange} />
        { mainList.map((i, index) => {
          return (
            <Accordion className="mt-2">
              <Accordion.Item
                eventKey={index}
              >
                <Accordion.Header>
                  <div className="w-100 d-flex justify-content-between align-items-center">
                    <div class="acco-title">
                      {i.title}
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body className="accordian-content">
                  <div className="col-sm-12 pe-0">
                    <div className="d-flex justify-content-end align-items-center accordian-content pb-3 pe-0">
                      {! expandAll ? (<div
                        className="btn btn-primary"
                        onClick={()=>forceUpdateHandler()}
                      >
                        Expand All
                      </div>) : (<div
                        className="btn btn-primary"
                        onClick={()=>forceUpdateHandler()}
                      >
                        Collapse All
                      </div>)}
                    </div>
                  </div>

                  { mainList[index].subList.map((ii, indexx) => {
                    return (
                      <div key={indexx}>
                        <div>
                          <div>
                            <div class={ii.isActive ? "accordian-active" : "accordian-dactive"} onClick={() => {
                               mainList[index].subList[indexx].isActive = !ii.isActive
                              setfullname("value"+!ii.isActive)
                              setexpandAll(false)
                            }}> view </div>
                            {ii.isActive ? <div>{ii.subTitle}</div> : <></>}
                          </div>
                        </div>

                      </div>
                    )
                  })}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )
        })}

      </div>
    )
}

export default AppGoogleSheetDemo;
// export default React.memo(AppGoogleSheetDemo);

