import React, { useState, useEffect, Fragment, useRef } from "react";
import {
  Card,
  Table,
  Input,
  Button,
  Modal,
  Tag,
  Drawer,
  Pagination,
  Space,
  Tooltip,
  message,Spin
} from "antd";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import excelfile from "../assets/audit/SERPTracker - Import Template.xlsx";
import {
  getSerplist,
  serpTrackerSelector,
  setStartRank,
  uploadKeyWord,
  downloadFile,
} from "../../store/reducer/serptracker";
import {
  DownloadOutlined,
  PlusOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";
import {
  ErrorOutline,
  ErrorOutlineOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import FeatherIcon from "feather-icons-react";


const SERPTracker = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const inputFileRef = useRef(null);
  const [scriptData, SetScriptData] = useState([]);
  console.log(scriptData, "scriptDatafinal");
  const [chk_count, setChkcount] = useState(null);
  console.log(chk_count, "chk_count");
  const [scriptData1, SetScriptData1] = useState([]);
  console.log(scriptData1, "scriptDat1a");
  const [buttonVisible, SetButtonVisible] = useState(false);
  const [uploadModal, setUploadModal] = useState(false);
  const [maxfileLimit, setMaxFileError] = useState(false);
  const [checkboxArray, setCheckboxArray] = useState([]);
  console.log(checkboxArray, "checkboxArrayscriptDat1a");
  const { serpListData, count } = useSelector(serpTrackerSelector);
  console.log(serpListData, "serpListData");
  const [fileSetelctedMsg, setFileSetelctedMsg] = useState("No file chosen");
  const [uploadFileName, setUploadFileName] = useState([]);
  const [errorFilesData, setErrorFileData] = useState([]);
  console.log(errorFilesData, "errorFilesData");
  const [errorFilesDownload, setErrorFileDownload] = useState([]);
  console.log(errorFilesDownload, "errorFilesDownload");
  const [issuccessFile, setIssuccessFile] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [initialloader,Setinitialloader] = useState('none');
  console.log(currentPage, "currentPagecurrentPage");
  const [dataTable, setDataTable] = useState({
    page: 1,
    // pagesize: 10,
    // sortby: '',
    // orderby: ''
  });
  console.log(count, "count");

  useEffect(() => {
    if (maxfileLimit) {
      setTimeout(() => {
        setMaxFileError(false);
      }, 4000);
    }
  });
  const [arr, setArr] = useState([
    {
      key: 1,
      ProjectName: "Meesho",
      DomainName: "www.meesho.com",
      Projectdate: "12/2/2019",
      Location: "India",
      deviceType: "mobile",
      KeywordCount: 230,
      CompetitorCount: 5,
      status: 1,
    },
    {
      key: 2,
      ProjectName: "test",
      DomainName: "www.test.com",
      Projectdate: "12/2/2019",
      Location: "India",
      deviceType: "mobile",
      KeywordCount: 230,
      CompetitorCount: 5,
      status: 2,
    },
    {
      key: 3,
      ProjectName: "Sample",
      DomainName: "www.sample.com",
      Projectdate: "12/2/2019",
      Location: "India",
      deviceType: "mobile",
      KeywordCount: 230,
      CompetitorCount: 5,
      status: 1,
    },
  ]);
  const [isSearched, setIsSearched] = useState(false);
  const [serpData, setSerpData] = useState([]);
  const [serpFullData, setSerpFullData] = useState([]);
  console.log("serpDatafiba", serpFullData);
  useEffect(() => {
    SetButtonVisible(scriptData.length > 0);
  }, [scriptData]);

  useEffect(() => {
    SetScriptData({ id: scriptData1 });
  }, [scriptData1]);

  useEffect(() => {
    if (serpListData && serpListData.length > 0) {
      let temp = [];
      let statusText = "";
      for (let i in serpListData) {
        if (serpListData[i].status == 0) {
          statusText = "Configured";
        } else if (serpListData[i].status == 1) {
          statusText = "In Progress";
        } else if (serpListData[i].status == 2) {
          statusText = "Completed";
        }
        temp.push({
          Domain: serpListData[i].Domain,
          competitors_count: serpListData[i].competitors_count,
          // "created_at": scriptData[i].created_at,
          device_type: serpListData[i].device_type,
          download_status: serpListData[i].download_status,

          excelfile_path: serpListData[i].excelfile_path,

          file_path: serpListData[i].file_path,

          id: serpListData[i].id,

          keyword_count: serpListData[i].keyword_count,

          location: serpListData[i].location,

          project_name: serpListData[i].project_name,

          status: serpListData[i].status,
          updated_at: serpListData[i].updated_at,

          upload_on: serpListData[i].upload_on,

          username: serpListData[i].username,
          statusText: statusText,
          key: serpListData[i].id,
          searchDate: moment(serpListData[i].upload_on).format("DD MMM YYYY"),
        });
      }
      setSerpData(temp);
      setSerpFullData(temp);
    }
  }, [serpListData]);
  const column = [
    {
      title: "Project",
      width: "300px",
      key: "project_name",
      dataIndex: "project_name",
      // sorter: true
      render: (text, record, index) => {
        let splitdata = record.project_name.split("-");
        console.log(splitdata, "splitdata");
        return <span>{splitdata && splitdata.length > 0 && splitdata[0]}</span>;
      },
    },
    {
      title: "Domain",
      width: "500px",
      key: "Domain",
      dataIndex: "Domain",
      // sorter: true
      render: (text, record, index) => {
        return (
          <a target="_blank" href={record.Domain}>
            {record.Domain}
          </a>
        );
      },
    },
    {
      title: "Uploaded",
      width: "300px",
      key: "upload_on",
      dataIndex: "upload_on",
      // sorter: true,
      render: (text, record, index) => {
        return <span>{moment(record.upload_on).format("DD MMM YYYY")}</span>;
      },
    },
    {
      title: "Location",
      width: "80px",
      key: "location",
      dataIndex: "location",
      // sorter: true
      // render: (text, record, index) => {
      //     return (
      //         <span>{record.Location}</span>
      //     )
      // },
    },
    {
      title: "Device Type",
      width: "80px",
      key: "device_type",
      dataIndex: "device_type",
      // sorter: true
      // render: (text, record, index) => {
      //     return (
      //         <span>{record.deviceType}</span>
      //     )
      // },
    },
    {
      title: "Keyword Count",
      width: "60px",
      key: "keyword_count",
      dataIndex: "keyword_count",
      render: (text, record, index) => {
        return <span>{text != 0 ? text : `-`}</span>;
      },
    },
    {
      title: "Competitors Count",
      width: "30px",
      key: "competitors_count",
      dataIndex: "competitors_count",
      // sorter: true
      // render: (text, record, index) => {
      //     return (
      //         <span>{record.CompetitorCount}</span>
      //     )
      // },
    },
    {
      title: "Status",
      width: "60px",
      render: (text, record, index) => {
        return (
          <Tag
            color={
              record.status == 1
                ? "blue"
                : record.status == 0
                ? "orange"
                : "green"
            }
            style={{ borderRadius: "4px" }}
          >
            {record.status == 1
              ? "In Progress"
              : record.status == 0
              ? "Configured"
              : "Completed"}
          </Tag>
        );
      },
    },
    {
      title: "Actions",
      width: "50px",
      render: (text, record, index) => {
        return (
          <button
            disabled={record.status == 2 ? false : true}
            style={{ backgroundColor: record.status !== 2 ? "#f0f0f0" : "" }}
            class={
              record.status == 2
                ? "outline-btn btn btn-primary"
                : "outline-btn btn btn"
            }
            type="button"
            onClick={() => {
              dispatch(
                downloadFile({
                  postData: record.excelfile_path,
                })
              );
            }}
          >
            <FeatherIcon icon={"download"} />
          </button>
        );
      },
    },
  ];
  const PAGE_SIZE = 10;
  console.log(serpData, "serpData");
  const [paginatedDataSource, setpaginatedData] = useState([]);
  console.log(
    serpData.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE),
    "checkedd"
  );
  useEffect(() => {
    if (isSearched) {
      setpaginatedData(serpData);
    } else {
      setpaginatedData(
        serpData.slice((currentPage - 1) * PAGE_SIZE, currentPage * PAGE_SIZE)
      );
    }
  }, [serpData]);
  const paginationConfig = {
    pageSize: PAGE_SIZE,
    current: currentPage,
    total: serpData.length,
    showSizeChanger: false,
    // You can customize other pagination options here if needed
    // For example:
    // showQuickJumper: true, // to enable quick jump to page
    // showTotal: (total) => `Total ${total} items`, // to display total items count
    // ... and more
  };
  useEffect(() => {
    if (paginatedDataSource && paginatedDataSource.length > 0) {
      let id = _.filter(paginatedDataSource, { status: 0 });
      console.log(id, "id5255");
      setCheckboxArray(id);
    }
  }, [paginatedDataSource]);
  const rowSelection = {
    renderCell: (checked, record, index, originNode) => {
      return (
        <Fragment>
          <input
            type="checkbox"
            checked={
              scriptData1 &&
              scriptData1.length > 0 &&
              scriptData1.includes(record.id)
            }
            disabled={record.status == 2 || record.status == 1}
            style={{
              cursor:
                record.status == 2 || record.status == 1
                  ? "not-allowed"
                  : "default",
            }}
            name="checked"
            // hidden={record.is_locked === false ? false : true}

            // onClick={(e) => {

            //     let tempId = []

            //     let checked = e.target.checked;

            //     if (checked) {

            //         // let tempId = scriptData

            //         let data = []

            //         tempId.push(record)

            //         data.push(record.id)

            //         // SetScriptData1([...scriptData1, ...data])

            //     }

            //     else {

            //         for (let i in scriptData) {

            //             if (scriptData[i] == record.id) {

            //                 scriptData.splice(i, 1)

            //             }

            //         }

            //         // SetScriptData1([scriptData1])

            //     }

            //     SetScriptData({ "id": tempId })

            //     //   addSelectedDataValues(

            //     //     checked,

            //     //     record.u_id,

            //     //     // record.childId

            //     //   );

            // }}

            onClick={(e) => {
              let temp = scriptData1;
              let tempIds = [];
              if (e.target.checked == true) {
                if (temp.length < 200) {
                  temp.push(record.id);
                } else {
                  message.error(
                    "Reached the maximum number of projects can be selected"
                  );
                }
              } else {
                if (temp.includes(record.id) == true) {
                  for (let i in temp) {
                    if (temp[i] == record.id) {
                      temp.splice(i, 1);
                    }
                  }
                }
              }
              SetScriptData({ id: temp });
              SetScriptData1(temp);
            }}
          />
        </Fragment>
      );
    },
  };

  useEffect(() => {
    dispatch(
      getSerplist({
        postData: dataTable,
      })
    );
  }, [dataTable]);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const searchRecords = (e) => {
    const excludedKeys = [
      "excelfile_path",
      "file_path",
      "updated_at",
      "upload_on",
      "created_at",
      "id",
    ];
    let searchValue;
    if(e){
      searchValue = e.target.value.toLowerCase();
    }
    
 
    const statusMapping = {
      completed: 2,
      inprogress: 1,
      configured: 0,
      "in progress": 1,
    };
    console.log(serpListData, "serpListDataserpListData");
    const filterTable = serpFullData.filter((o) => {
      if (
        o.hasOwnProperty("statusText") &&
        o.statusText.toLowerCase().includes(searchValue)
      ) {
        return true;
      }

      /* if (o.hasOwnProperty("upload_on")) {
        const uploadedOnDate = new Date(o.upload_on);
        const formattedDate = `${uploadedOnDate.getDate()} ${uploadedOnDate.toLocaleString(
          "default",
          { month: "short" }
        )} ${uploadedOnDate.getFullYear()}`;
        if (formattedDate.toLowerCase().includes(searchValue)) {
          return true;
        }
      }*/

      if (
        o.hasOwnProperty("upload_on") &&
        o.searchDate.toLowerCase().includes(searchValue)
      ) {
        return true;
      }

      const excludedKeys = [
        "excelfile_path",
        "file_path",
        "status",
        "created_at",
        "updated_at",
        "upload_on",
        "id",
        "key",
      ];
      return Object.keys(o).some(
        (k) =>
          !excludedKeys.includes(k) &&
          String(o[k]).toLowerCase().includes(searchValue)
      );
    });
    let isComplete = "complete";
    if (e && e.target.value != "") {
      setIsSearched(true);
      console.log(isComplete.match(e.target.value.toLowerCase()), "c.splei");
    } else {
      setIsSearched(false);
    }
    setSerpData(filterTable);
  };
  const handleDownload = (file) => {
    const link = document.createElement("a");
    link.href = file.dataUrl;
    link.download = file.name;
    link.click();
  };

  const handleCancel = () => {
    Setinitialloader('none');
    setErrorFileDownload([]);
    setUploadModal(false);
    searchRecords("");
    dispatch(
      getSerplist({
        postData: dataTable,
      })
    );
  };

  const HandleStartRank = () => {
    dispatch(
      setStartRank({
        postData: scriptData,
      })
    );
  };
  const uploadOnchange = () => {
    setUploadModal(true);
    setUploadFileName([]);
    setErrorFileData([]);
    setFileSetelctedMsg("No file chosen");
  };
  // const fileuploadOnchange = (e) => {
  //     const files = e.target.files;
  //     let errorFiles = [];
  //     for (let i = 0; i < files.length; i++) {

  //         let hasError = false;
  //         const file = files[i];
  //         const reader = new FileReader();
  //         reader.onload = (e) => {
  //             const data = new Uint8Array(e.target.result);
  //             const workbook = XLSX.read(data, { type: 'array' });
  //             const sheetName = workbook.SheetNames[0];
  //             const worksheet = workbook.Sheets[sheetName];
  //             const range = worksheet['ref'];
  //             const rangeCells = XLSX.utils.sheet_to_json(worksheet, { header: 1, range, raw: false });
  //             if (rangeCells && rangeCells[0][1] == undefined) {
  //                 const cellAddress = 'E1';
  //                 const newValue = 'Please Enter Project Name';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             else if (rangeCells && rangeCells[0][1].length > 50) {
  //                 const cellAddress = 'E1';
  //                 const newValue = 'Allow only 50 Characters';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             if (rangeCells && rangeCells[1][1] == undefined) {
  //                 const cellAddress = 'E2';
  //                 const newValue = 'Please Enter Project Name';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             else if (rangeCells && rangeCells[1][1].length > 100) {
  //                 const cellAddress = 'E2';
  //                 const newValue = 'Allow only 100 Characters';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             if (rangeCells && rangeCells[2][1] == undefined) {
  //                 const cellAddress = 'E3';
  //                 const newValue = 'Please Enter Location';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             else if (rangeCells && rangeCells[2][1].length > 20) {
  //                 const cellAddress = 'E3';
  //                 const newValue = 'Allow only 20 Characters';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             if (rangeCells && rangeCells[3][1] == undefined) {
  //                 const cellAddress = 'E4';
  //                 const newValue = 'Please Enter Device Type';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             else if (rangeCells && rangeCells[3][1].length > 10) {
  //                 const cellAddress = 'E4';
  //                 const newValue = 'Allow only 10 Characters';
  //                 worksheet[cellAddress] = { t: 's', v: newValue };
  //                 hasError = true;
  //             }
  //             const sheetName1 = workbook.SheetNames[1];
  //             const worksheet1 = workbook.Sheets[sheetName1];
  //             const rangeCells1 = XLSX.utils.sheet_to_json(worksheet1, { header: 1, range, raw: false });
  //             rangeCells1.splice(0, 1)
  //             const flattenedArray = rangeCells1.flat();
  //             const uniqueSet = new Set(flattenedArray);
  //             rangeCells1.forEach((row, rowIndex) => {
  //                 if (row.length > 0) {
  //                     console.log(row, 'row1234');
  //                     if (rowIndex !== 0 && rangeCells1[rowIndex - 1] !== undefined) {
  //                         if (rangeCells1[rowIndex - 1].length == 0) {
  //                             const cellAddress = 'B1';
  //                             const newValue = 'Please Avoid Blank Entries';
  //                             worksheet1[cellAddress] = { t: 's', v: newValue };
  //                             hasError = true;
  //                         }
  //                     }
  //                 }
  //                 if (rangeCells1.every(item => item.length == 0)) {
  //                     const cellAddress = 'B1';
  //                     const newValue = 'Please Enter Atleast one Keyword';
  //                     worksheet1[cellAddress] = { t: 's', v: newValue };
  //                     hasError = true;
  //                 }
  //                 else if (flattenedArray.length !== uniqueSet.size) {
  //                     const cellAddress = 'B1';
  //                     const newValue = 'Please Remove Duplicate Keyword';
  //                     worksheet1[cellAddress] = { t: 's', v: newValue };
  //                     hasError = true;
  //                 }
  //             });
  //             const sheetName2 = workbook.SheetNames[2];
  //             const worksheet2 = workbook.Sheets[sheetName2];
  //             const rangeCells2 = XLSX.utils.sheet_to_json(worksheet2, { header: 1, range, raw: false });
  //             rangeCells2.splice(0, 1)
  //             rangeCells2.forEach((row, rowIndex) => {
  //                 if (row.length > 0) {
  //                     let urlRegex = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)*$/i;
  //                     const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\?[^\s]*$/i;
  //                     if (rowIndex !== 0 && rangeCells2[rowIndex - 1] !== undefined) {
  //                         if (rangeCells2[rowIndex - 1].length == 0) {
  //                             const cellAddress = 'B1';
  //                             const newValue = 'Please Avoid Blank Entries';
  //                             worksheet2[cellAddress] = { t: 's', v: newValue };
  //                             hasError = true;
  //                         }
  //                     }
  //                     if (urlRegex.test(row[0]) == false || urlPattern.test(row[0] == false)) {
  //                         const cellAddress = 'B1';
  //                         const newValue = 'Please Enter Correct url';
  //                         worksheet2[cellAddress] = { t: 's', v: newValue };
  //                         hasError = true;
  //                     }
  //                 }
  //             });

  //             if (hasError) {
  //                 console.log(files[i], 'files[i]');
  //                 errorFiles.push(file.name)
  //                 setErrorFileData(errorFiles)
  //                 const errorWorkbook = XLSX.utils.book_new();
  //                 XLSX.utils.book_append_sheet(errorWorkbook, worksheet, sheetName);
  //                 XLSX.utils.book_append_sheet(errorWorkbook, worksheet1, sheetName1);
  //                 XLSX.utils.book_append_sheet(errorWorkbook, worksheet2, sheetName2);
  //                 const errorFile = XLSX.write(errorWorkbook, { bookType: 'xlsx', type: 'array' });
  //                 const fileDataUrl = URL.createObjectURL(new Blob([errorFile], { type: 'application/octet-stream' }));
  //                 setErrorFileDownload(prevErrorFiles => [...prevErrorFiles, { name: `${file.name}_error.xlsx`, dataUrl: fileDataUrl }]);

  //             }
  //             else {
  //                 console.log(files[i], 'files[i]12344');
  //                 let fileArray = []
  //                 let FILE_TYPE = [
  //                     "xlsx",

  //                 ];
  //                 let max_file_size = 52428800
  //                 let extension = [];
  //                 for (let j = 0; j < files.length; j++) {
  //                     let abcde = files[j].name.split('.')
  //                     extension.push(abcde[1]);
  //                 }
  //                 const multipleExist = extension.every(value => {
  //                     return FILE_TYPE.includes(value);
  //                 });

  //                 if (multipleExist == false) {
  //                     return
  //                 }
  //                 else {
  //                     var file_name = file.name;
  //                     if (file.size <= max_file_size) {
  //                         var formdata = new FormData();
  //                         formdata.append('file', file);
  //                         dispatch(
  //                             uploadKeyWord({
  //                                 postData: formdata
  //                             }))

  //                         fileArray.push(file_name)
  //                     }
  //                     else {
  //                         toast.error('Maximum filesize limit exceed')
  //                     }

  //                     setUploadFileName(fileArray)
  //                     setFileSetelctedMsg(`${fileArray.length} Selected`)
  //                 }
  //             }
  //         };

  //         reader.readAsArrayBuffer(file);
  //     }
  // }

  const tolower = (input) => {
    let result = "";

    for (let i = 0; i < input.length; i++) {
      const char = input[i];

      // Check if the character is a letter
      if (/[a-zA-Z]/.test(char)) {
        result += char.toLowerCase();
      } else {
        result += char;
      }
    }

    return result;
  };

  const fileuploadOnchange = (e, type) => {
    // setErrorFileDownload([])
    let files;
    if (type === "select") {
      files = e.target.files;
    } else {
      files = e.dataTransfer.files[0];
    }
    let errorFiles = [];
    let successfulFile = issuccessFile;
    let fileArray = [];
    let runner = type === "select" ? files.length : files;
    if (runner <= 200) {
      Setinitialloader('block');
      for (let i = 0; i < runner; i++) {
        let hasError = false;
        const file = files[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const arrayBuffer = e.target.result;
          const workbook = XLSX.readFile(arrayBuffer, { type: "array" });
          const worksheet = workbook.Sheets[workbook.SheetNames[0]];
          const worksheet1 = workbook.Sheets[workbook.SheetNames[1]];
          const worksheet2 = workbook.Sheets[workbook.SheetNames[2]];
          const sheetName = workbook.SheetNames[0];
          const sheetName1 = workbook.SheetNames[1];
          const sheetName2 = workbook.SheetNames[2];
          const rangeCells = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          const rangeCells1 = XLSX.utils.sheet_to_json(worksheet1, {
            header: 1,
          });
          const rangeCells2 = XLSX.utils.sheet_to_json(worksheet2, {
            header: 1,
          });
          let urlvalidation =
            /^(?:https?|ftp):\/\/www\.[a-zA-Z0-9-]+\.[a-zA-Z]{2,3}$/i;
          let comp_url = [];
          const fileData = [];
          let index_comp = [];
          let comp_err = false;

          const headers = rangeCells2[0];

          for (let i = 1; i < rangeCells2.length; i++) {
            const row = rangeCells2[i];
            const rowData = {};
            for (let j = 0; j < headers.length; j++) {
              rowData[headers[j]] = row[j];
            }
            fileData.push(rowData);
          }

          for (let index = 0; index < fileData.length; index++) {
            const element = fileData[index];
            if (element["Competitors"]) {
              console.log(element, "elemelememk");
              if (
                tolower(element["Competitors"]) == tolower(rangeCells[1][1])
              ) {
                index_comp.push(index);
                comp_err = true;
              }
            }
          }
          let url_err = false;
          const datafile = [];

          const comp_headers = rangeCells2[0];

          for (let i = 1; i < rangeCells2.length; i++) {
            const row = rangeCells2[i];
            const rowData = {};
            for (let j = 0; j < comp_headers.length; j++) {
              rowData[comp_headers[j]] = row[j];
            }
            datafile.push(rowData);
          }

          for (let index = 0; index < datafile.length; index++) {
            const element = datafile[index];
            if (element["Competitors"]) {
              if (urlvalidation.test(element["Competitors"]) == false) {
                comp_url.push(index);
                url_err = true;
              }
            }
          }
          const splitdata =
            rangeCells &&
            rangeCells[0] &&
            rangeCells[0][1] &&
            rangeCells[0][1].length > 0 &&
            rangeCells[0][1].split("-");

          const projectName =
            serpFullData &&
            serpFullData.length > 0 &&
            serpFullData.map((item) => {
              let name = item.project_name.split("-");
              return name[0];
            });

          console.log(projectName, "projectName");
          // console.log(splitdata[0], 'splitdata');
          console.log(successfulFile, "successfulFile");
          // console.log(successfulFile.includes(splitdata[0]), '(successfulFile.includes(splitdata[0])');
          var letters = /^[A-Za-z]+$/;
          // if (rangeCells && rangeCells[0][0] !== 'Project Name') {
          //     const cellAddress = 'E1';
          //     const newValue = 'Wrong File Upload';
          //     worksheet[cellAddress] = { t: 's', v: newValue };
          //     hasError = true;
          // }
          if(rangeCells1[0][0] !='Keywords'){
            message.error('Header Mismatch');
            hasError = true;
          }
          if(rangeCells2[0][0] != 'Competitors'){
            message.error('Header Mismatch');
            hasError = true;
          }
          if (
            rangeCells &&
            rangeCells[0] !== undefined &&
            rangeCells[0][1] == undefined
          ) {
            const cellAddress = "E1";
            const newValue = "Please Enter Project Name";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (rangeCells && rangeCells[0] === undefined) {
            message.error(`Empty file cannot be uploaded : ${file.name}`);
          } else if (
            rangeCells &&
            rangeCells[0] &&
            rangeCells[0][1].length > 50
          ) {
            const cellAddress = "E1";
            const newValue = "Allow only 50 Characters";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            (successfulFile.length > 0 &&
              successfulFile.includes(splitdata[0])) ||
            (projectName.length > 0 && projectName.includes(splitdata[0]))
          ) {
            console.log("test12345");
            const cellAddress = "E1";
            const newValue = "Please Avoid Duplicate Project Name";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          }
          console.log(successfulFile, "successfulFile");
          // if (rangeCells && rangeCells[1][0] !== 'Domain') {
          //     const cellAddress = 'E1';
          //     const newValue = 'Wrong File Upload';
          //     worksheet[cellAddress] = { t: 's', v: newValue };
          //     hasError = true;
          // }
          // else
          let urlRegex = /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)*$/i;
          const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\?[^\s]*$/i;
          console.log(urlvalidation.test(rangeCells[1][1]), "checking");
          if (
            rangeCells &&
            rangeCells[1] !== undefined &&
            rangeCells[1][1] == undefined
          ) {
            const cellAddress = "E2";
            const newValue = "Please Enter Domain";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            urlvalidation.test(rangeCells[1][1]) == false 
          ) {
            const cellAddress = "E2";
            const newValue = "Please Enter Correct Domain Url";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            rangeCells &&
            rangeCells[1] !== undefined &&
            rangeCells[1][1].length > 100
          ) {
            const cellAddress = "E2";
            const newValue = "Allow only 100 Characters";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          }

          // else if (
          //   rangeCells &&
          //   rangeCells[1] !== undefined &&
          //   urlvalidation.test(rangeCells[1][1]) == false
          // ) {

          //   const cellAddress = "E2";
          //   const newValue = "Please Enter";
          //   worksheet[cellAddress] = { t: "s", v: newValue };
          //   hasError = true;
          // }
          // if (rangeCells && rangeCells[2][0] !== 'Location') {
          //     const cellAddress = 'E1';
          //     const newValue = 'Wrong File Upload';
          //     worksheet[cellAddress] = { t: 's', v: newValue };
          //     hasError = true;
          // }
          if (
            rangeCells &&
            rangeCells[2] !== undefined &&
            rangeCells[2][1] == undefined
          ) {
            const cellAddress = "E3";
            const newValue = "Please Enter Location";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            letters.test(
              rangeCells && rangeCells[2] !== undefined && rangeCells[2][1]
            ) == false
          ) {
            const cellAddress = "E3";
            const newValue = "Allow only Characters";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            rangeCells &&
            rangeCells[2] !== undefined &&
            rangeCells[2][1].length > 20
          ) {
            const cellAddress = "E3";
            const newValue = "Allow only 20 Characters";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          }
          console.log(rangeCells[3], "rangeCells && rangeCells[3][0] ");
          // if (rangeCells && rangeCells[3] !== undefined && rangeCells[3][0] !== 'Device Type') {
          //     const cellAddress = 'E1';
          //     const newValue = 'Wrong File Upload';
          //     worksheet[cellAddress] = { t: 's', v: newValue };
          //     hasError = true;
          // }
          if (
            rangeCells &&
            rangeCells[3] !== undefined &&
            rangeCells[3][1] == undefined
          ) {
            const cellAddress = "E4";
            const newValue = "Please Enter Device Type";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            letters.test(
              rangeCells && rangeCells[3] !== undefined && rangeCells[3][1]
            ) == false
          ) {
            const cellAddress = "E4";
            const newValue = "Allow only Characters";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            rangeCells &&
            rangeCells[3] !== undefined &&
            rangeCells[3][1].length > 10
          ) {
            const cellAddress = "E4";
            const newValue = "Allow only 10 Characters";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          } else if (
            !rangeCells[3][1].toLowerCase().includes("desktop") &&
            !rangeCells[3][1].toLowerCase().includes("mobile")
          ) {
            const cellAddress = "E4";
            const newValue = "Device Type should be Mobile/Desktop";
            worksheet[cellAddress] = { t: "s", v: newValue };
            hasError = true;
          }
          // if (worksheet1 == 'keywords') {
          rangeCells1.splice(0, 1);
          const flattenedArray = rangeCells1.flat();
          const uniqueSet = new Set(flattenedArray);
          rangeCells1.forEach((row, rowIndex) => {
            if (row.length > 0) {
              console.log(row, "row1234");
              if (rowIndex !== 0 && rangeCells1[rowIndex - 1] !== undefined) {
                if (rangeCells1[rowIndex - 1].length == 0) {
                  const cellAddress = "B1";
                  const newValue = "Please Avoid Blank Entries";
                  worksheet1[cellAddress] = { t: "s", v: newValue };
                  hasError = true;
                }
              }
            }
            if (rangeCells1.every((item) => item.length == 0)) {
              const cellAddress = "B1";
              const newValue = "Please Enter Atleast one Keyword";
              worksheet1[cellAddress] = { t: "s", v: newValue };
              hasError = true;
            } else if (flattenedArray.length !== uniqueSet.size) {
              const cellAddress = "B1";
              const newValue = "Please Remove Duplicate Keyword";
              worksheet1[cellAddress] = { t: "s", v: newValue };
              hasError = true;
            }
          });
          // }
          // else {
          //     const cellAddress = 'E1';
          //     const newValue = 'Wrong File Upload';
          //     worksheet[cellAddress] = { t: 's', v: newValue };
          //     hasError = true;
          // }
          // if (worksheet1 == 'keywords') {
          rangeCells2.splice(0, 1);
          rangeCells2.forEach((row, rowIndex) => {
            if (row.length > 0) {
              let urlRegex =
                /^(https?:\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/.*)*$/i;
              const urlPattern =
                /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*\?[^\s]*$/i;

              if (rowIndex !== 0 && rangeCells2[rowIndex - 1] !== undefined) {
                if (rangeCells2[rowIndex - 1].length == 0) {
                  const cellAddress = "B1";
                  const newValue = "Please Avoid Blank Entries";
                  worksheet2[cellAddress] = { t: "s", v: newValue };
                  hasError = true;
                }
              }
              if (url_err) {
                for (let index = 0; index < comp_url.length; index++) {
                  const element = comp_url[index];
                  const cellAddress = `E${element + 2}`;
                  const newValue = "Please Enter Correct url";
                  worksheet2[cellAddress] = { t: "s", v: newValue };
                }
                hasError = true;
              } else if (comp_err) {
                for (let index = 0; index < index_comp.length; index++) {
                  const element = index_comp[index];
                  const cellAddress = `E${element + 2}`;
                  const newValue = "Competitor doesn't accept domain url";
                  worksheet2[cellAddress] = { t: "s", v: newValue };
                }

                hasError = true;
              }
            }
          });
          // }
          // else {
          //     const cellAddress = 'E1';
          //     const newValue = 'Wrong File Upload';
          //     worksheet[cellAddress] = { t: 's', v: newValue };
          //     hasError = true;
          // }
          if (hasError) {
            console.log(
              rangeCells && rangeCells[0][1],
              "rangeCells && rangeCells[0][1]"
            );
            errorFiles.push(file.name);
            setErrorFileData(errorFiles);
            Setinitialloader('none');
            const errorWorkbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(errorWorkbook, worksheet, sheetName);
            XLSX.utils.book_append_sheet(errorWorkbook, worksheet1, sheetName1);
            XLSX.utils.book_append_sheet(errorWorkbook, worksheet2, sheetName2);
            const errorFile = XLSX.write(errorWorkbook, {
              bookType: "xlsx",
              type: "array",
            });
            const fileDataUrl = URL.createObjectURL(
              new Blob([errorFile], { type: "application/octet-stream" })
            );
            let extns = file.name.split(".");
            setErrorFileDownload((prevErrorFiles) => [
              ...prevErrorFiles,
              {
                name: `Error_${file.name}`,
                dataUrl: fileDataUrl,
                is_download: extns[extns.length - 1] !== "xlsx" ? false : true,
              },
            ]);
          } else {
            console.log(runner, "files[i]12344");

            let FILE_TYPE = ["xlsx"];
            let max_file_size = 52428800;
            let extension = [];
            if (type === "select") {
              for (let j = 0; j < files.length; j++) {
                let abcde = files[j].name.split(".");
                extension.push(abcde[1]);
              }
            } else {
              let abcde = files.name.split(".");
              extension.push(abcde[1]);
            }
            const multipleExist = extension.every((value) => {
              return FILE_TYPE.includes(value);
            });

            if (multipleExist == false) {
              return;
            } else {
              var file_name = file.name;
              if (file.size <= max_file_size) {
                Setinitialloader('none');
                var formdata = new FormData();
                formdata.append("file", file);
                dispatch(
                  uploadKeyWord({
                    postData: formdata,
                  })
                );

                fileArray.push(file_name);
                let data = rangeCells && rangeCells[0][1].split("-");
                successfulFile.push(data[0]);
              } else {
                Setinitialloader('none');
                toast.error("Maximum filesize limit exceed");
              }
              setIssuccessFile([...issuccessFile, ...successfulFile]);
              setUploadFileName([...uploadFileName, ...fileArray]);
              setFileSetelctedMsg(
                `${[...uploadFileName, ...fileArray].length} Selected`
              );
            }
          }
        };

        reader.readAsArrayBuffer(file);
      }
    } else {
      if (type != "drop") {
        setMaxFileError(true);
      }
    }
    e.target.value = null;
  };
  return (
    <Fragment>
      <Card className="rounded-3">
        <h5>
          <b>SERPTracker - Google SERP Ranking System</b>
        </h5>
        <h6>
          A Powerful Module For Retrieving{" "}
          <a style={{ color: "#0791EF", cursor: "text" }}>
            Google Search Engine Result Page (SERP)
          </a>{" "}
          Rankings For Targeted Keywords And Domains.
        </h6>
        {/* <div className="row mt-3">
          <div class="col-xl-6 col-lg-7 col-md-12 col-sm-12 ">
            <div class="input-group-icon input-group-lg widget15-compact w-100">
              <div class="input-group-prepend">
                <i class="fa fa-search " style={{ color: "black" }}></i>
              </div>
              <span className="w-100">
                <Input
                  allowClear
                  autoComplete="off"
                  style={{ padding: 7 + "px", zIndex: 2, width: "100%" }}
                  type="text"
                  class="form-control"
                  placeholder="Search"
                  onChange={(e) => {
                    searchRecords(e);
                  }}
                  id="input-s"
                />
              </span>
            </div>
          </div>
          <div className="col-xl-6 col-lg-5 col-md-12 col-sm-12">
            <div className="serp-btn-grp d-flex justify-content-end flex-wrap">
              <Button
                type="primary"
                ghost
                disabled={scriptData1?.length > 0}
                onClick={() => {
                  const downloadLink = document.createElement("a");
                  downloadLink.href = excelfile;
                  downloadLink.download = "SERPTracker-Import Template.xlsx";
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }}
                className="serp_button"
              >
                Download Template
              </Button>

              <Button
                className="serp_button"
                id="uploadButton"
                disabled={scriptData1.length > 0}
                style={{
                  borderRadius: "6px",
                  backgroundColor: scriptData1.length > 0 ? "#A9A9A9" : "",
                  color: scriptData1.length > 0 ? "white" : "",
                }}
                type="primary"
                // icon={<PlusOutlined />}
                onClick={uploadOnchange}
              >
                <FeatherIcon
                  icon="plus"
                  height="18"
                  style={{ paddingBottom: "3px" }}
                />
                Upload
              </Button>

              <Button
                id="uploadButton"
                onClick={HandleStartRank}
                disabled={scriptData1.length == 0}
                style={{
                  borderRadius: "5px",
                  backgroundColor: scriptData1.length == 0 ? "#A9A9A9" : "",
                  color: scriptData1.length == 0 ? "white" : "",
                }}
                className="serp_button"
                type="primary"
                // icon={<PlayCircleOutlined></PlayCircleOutlined>}
              >
                <FeatherIcon
                  icon="play-circle"
                  height="18"
                  style={{ paddingBottom: "3px" }}
                />
                Start Ranking Script
              </Button>
            </div>
          </div>
        </div> */}



        <div className="d-flex">
        <div class="input-group-icon input-group-lg widget15-compact w-100">
              <div class="input-group-prepend">
                <i class="fa fa-search " style={{ color: "black" }}></i>
              </div>
              <span className="w-100">
                <Input
                  allowClear
                  autoComplete="off"
                  style={{ padding: 7 + "px", zIndex: 2, width: "100%" }}
                  type="text"
                  class="form-control w-100"
                  placeholder="Search"
                  onChange={(e) => {
                    searchRecords(e);
                  }}
                  id="input-s"
                />
              </span>
            </div>
            
              <Button
                type="primary"
                ghost
                disabled={scriptData1?.length > 0}
                onClick={() => {
                  const downloadLink = document.createElement("a");
                  downloadLink.href = excelfile;
                  downloadLink.download = "SERPTracker-Import Template.xlsx";
                  document.body.appendChild(downloadLink);
                  downloadLink.click();
                  document.body.removeChild(downloadLink);
                }}
                className="serp_button"
              >
                Download Template
              </Button>

              <Button
                className="serp_button"
                id="uploadButton"
                disabled={scriptData1.length > 0}
                style={{
                  borderRadius: "6px",
                  backgroundColor: scriptData1.length > 0 ? "#A9A9A9" : "",
                  color: scriptData1.length > 0 ? "white" : "",
                }}
                type="primary"
                // icon={<PlusOutlined />}
                onClick={uploadOnchange}
              >
                <FeatherIcon
                  icon="plus"
                  height="18"
                  style={{ paddingBottom: "3px" }}
                />
                Upload
              </Button>

              <Button
                id="uploadButton"
                onClick={HandleStartRank}
                disabled={scriptData1.length == 0}
                style={{
                  borderRadius: "5px",
                  backgroundColor: scriptData1.length == 0 ? "#A9A9A9" : "",
                  color: scriptData1.length == 0 ? "white" : "",
                }}
                className="serp_button"
                type="primary"
                // icon={<PlayCircleOutlined></PlayCircleOutlined>}
              >
                <FeatherIcon
                  icon="play-circle"
                  height="18"
                  style={{ paddingBottom: "3px" }}
                />
                Start Ranking Script
              </Button>
           
        </div>
        {/* <div className="col-md-12"> 
                <div className="row">
                    <div className="col-md-4 mt-2">
                        <div class="sow-search-box d-flex  w-100 me-3 position-relative">
                            <i class="fa fa-search"></i>
                            <input
                                type="text"
                                class="scope-input w-100"
                                placeholder="Search"
                                name="search"
                                onChange={(e) => {
                                    searchRecords(e)
                                }}
                            />
                        </div>
                    </div>
                    <div className="col-md-3 mt-2">
                        <a
                            target="_blank"
                        //  href={GET_Server_URL + "audit/Competitors.xlsx"}
                        >
                            <Button className="ms-5" id={buttonVisible ? '' : "uploadButton"} disabled={buttonVisible} style={{ borderRadius: '5px' }} type="primary"
                                icon={<DownloadOutlined />}
                            >
                                Download Template
                            </Button></a>
                    </div>
                    <div className="col-md-2 mt-2" >
                        <Button className="ms-5" id={buttonVisible ? '' : "uploadButton"} disabled={buttonVisible} style={{ borderRadius: '5px' }} type="primary"
                            icon={<PlusCircleOutlined />}
                            onClick={
                                uploadOnchange
                            }  >
                            Upload
                        </Button>
                    </div>
                    <div className="col-md-3 mt-2 text-end" >
                        <Button  onClick={HandleStartRank} id={buttonVisible ? 'uploadButton' : ""} disabled={!buttonVisible} style={{ borderRadius: '5px' }}  type="primary" icon={<PlayCircleOutlined></PlayCircleOutlined>} >
                            Start Ranking Script
                        </Button>
                    </div>
                </div>
            </div> */}
        {/* <Table

                showSorterTooltip={false}
                onChange={(val, filter, sorter, extra) => {
                    setDataTable({
                        ...dataTable,
                        // sortby: sorter.columnKey,
                        // orderby: sorter.order !== undefined ? sorter.order : 'ascend'

                    })
                }}
                className="table table-bordered  table-hover mt-3 serpTable "
                // key={serpData}
                // rowSelection={{
                //     ...rowSelection
                // }}
                rowSelection={{
                    columnWidth: '30px',
                    columnTitle: <input type="checkbox" disabled={serpData.every(obj => obj.status == 1 || obj.status == 2)}

                        checked={checkboxArray && checkboxArray.length > 0 && checkboxArray.length == scriptData1.length}
                        onClick={(e) => {
                            let checked = e.target.checked;
                            if (checked) {
                                if (serpData.length > 0) {
                                    let StatusData = _.filter(serpData, { status: 0 })
                                    if (StatusData && StatusData.length > 0) {
                                        let id = StatusData.map((item) => {
                                            return item.id
                                        })
                                        console.log(id, 'id');
                                        SetScriptData1(id)
                                        SetScriptData({ "id": id })
                                    }
                                }
                            }
                            else {
                                SetScriptData1([])
                                SetScriptData([])
                            }
                        }}
                    />,
                    ...rowSelection
                }}
                dataSource={serpData}


                id="datatable-1"
                pagination={false}
                columns={column} /> */}

        <Table
          dataSource={paginatedDataSource}
          columns={column}
          onChange={(val, filter, sorter, extra) => {
            setDataTable({
              ...dataTable,
              // sortby: sorter.columnKey,
              // orderby: sorter.order !== undefined ? sorter.order : 'ascend'
            });
          }}
          className="table table-bordered p-0 table-hover mt-3 serpTable border-0"
          rowSelection={{
            columnWidth: "30px",
            columnTitle: (
              <input
                type="checkbox"
                disabled={paginatedDataSource.every(
                  (obj) => obj.status == 1 || obj.status == 2
                )}
                checked={
                  checkboxArray.length > 0
                    ? checkboxArray
                        .map((item) => item.id)
                        .every((value) => scriptData1.includes(value))
                    : false
                }
                onClick={(e) => {
                  let MaxReached = false;
                  let checked = e.target.checked;
                  let temp = scriptData1;
                  if (checked) {
                    if (paginatedDataSource.length > 0) {
                      let StatusData = _.filter(paginatedDataSource, {
                        status: 0,
                      });
                      if (StatusData && StatusData.length > 0) {
                        let id = StatusData.map((item) => {
                          return item.id;
                        });
                        for (let i = 0; i <= id.length; i++) {
                          if (temp?.length < 200) {
                            if (id[i]) {
                              temp.push(id[i]);
                              MaxReached = false;
                            }
                          } else {
                            MaxReached = true;
                          }
                        }
                        MaxReached &&
                          message.error(
                            "Reached the maximum number of projects can be selected"
                          );
                        SetScriptData1([...temp]);
                      }
                    }
                  } else {
                    let data = checkboxArray.map((item) => item.id);
                    SetScriptData1(
                      scriptData1.filter((item) => {
                        return !data.includes(item);
                      })
                    );
                  }
                }}
              />
            ),
            ...rowSelection,
          }}
          onRow={(record, index) => ({
            className: scriptData1.includes(record.id) ? "selected-row" : "",
          })}
          id="datatable-1"
          pagination={false}
        />

        {isSearched ? (
          ""
        ) : (
          <Pagination
            hideOnSinglePage={true}
            pageSize={10}
            current={currentPage}
            showSizeChanger={false}
            className="text-end"
            onChange={(pageNo, pageSize) => {
              setCurrentPage(pageNo);
              setDataTable({
                // ...dataTable,
                page: pageNo,
                pagesize: pageSize,
              });
            }}
            total={count}
          />
        )}
      </Card>
      <Drawer
        className="create-subticket industries-modal upload-scope-modal"
        placement="right"
        onClose={handleCancel}
        open={uploadModal}
        maskClosable={false}
      >
        <div class="modal-dialog scope-modal" role="document">
          <div class="modal-content">
            <div class="modal-body p-4">
              <div className="portlet common-form-fields border-0">
                <div className="">
                  <div>
                    <div className="radio">
                      <div className="ms-3">
                        <label className="bulk-upload  main-title">
                          Upload Projects
                        </label>
                      </div>
                      <span className="ms-5 csv-file">
                        Add attachment (Only xlsx file)
                      </span>
                      <span
                        className={`csv-file error-message ${
                          maxfileLimit ? `show` : ""
                        }`}
                      >
                        Upload failed. Maximum files limit should be 200{" "}
                      </span>
                      <div
                        style={{
                          position: "relative",
                          background: "lightblue",
                          borderRadius: "4px",
                        }}
                        className="scope-choose-file d-flex flex-row align-items-center p-2 mt-3 ms-5 me-3"
                      >
                        <input
                          className="w-50 p-2 mb-3"
                          style={{
                            position: "absolute",
                            opacity: 0,
                            cursor: "pointer",
                          }}
                          title=""
                          type="file"
                          id="sowFile"
                          onDrop={(e) => {
                            fileuploadOnchange(e, "drop");
                          }}
                          accept=".xlsx"
                          name="sowFile"
                          multiple={true}
                          ref={inputFileRef}
                          onChange={(e) => {
                            fileuploadOnchange(e, "select");
                          }}
                        />
                        <div className="d-flex align-items-center">
                          {/* <button
                            onClick={onBtnClick}
                            id="btnChooseFile"
                            className="btn btn-primary me-3"
                          >
                            Choose File
                          </button> */}
                          <div className="d-flex btn-drag-drop align-items-center">
                            <UploadFileOutlined />
                            <p style={{ margin: 0 }}>
                              Choose a file or drag it here
                            </p>
                          </div>
                          <span className="file-not-select">
                            {fileSetelctedMsg}
                          </span>
                        </div>
                      </div>
                    </div>
                    {uploadFileName && uploadFileName.length > 0 ? (
                      <div className="radio mt-3">
                        <div className="ms-3">
                          <label className="bulk-upload  main-title">
                            Uploaded Document
                          </label>
                        </div>
                        {uploadFileName?.map((item) => {
                          return (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-11">
                                  <span
                                    style={{
                                      color: "green",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {item}
                                  </span>
                                </div>
                                {/* <div className="col-md-1">
                                                            <span key={uploadFileName} onClick={() => {
                                                                removeFileName(item)
                                                            }}><CloseOutlined /></span>
                                                        </div> */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      
                      <div className="mt-5 text-center" style={{display:initialloader}}>
                      {" "}
                      <Spin
                        tip="Please Wait, File Validation Is In-Progress..."
                        size="Large"
                      ></Spin>{" "}
                    </div>
                    )}
                    {errorFilesData && errorFilesData.length > 0 ? (
                      <div className="radio mt-3">
                        <div className="ms-3">
                          <label className="bulk-upload  main-title">
                            Failed Document
                          </label>
                        </div>
                        {errorFilesDownload?.map((item, i) => {
                          return (
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-11">
                                  <span
                                    style={{
                                      color: "red",
                                      wordWrap: "break-word",
                                    }}
                                  >
                                    {item.name}
                                  </span>
                                </div>
                                <div
                                  className="col-md-1 download-btn"
                                  style={{
                                    paddingLeft: item.is_download ? "" : "6px",
                                  }}
                                >
                                  <span
                                    key={errorFilesDownload}
                                    onClick={() => {
                                      item.is_download && handleDownload(item);
                                    }}
                                  >
                                    {item.is_download ? (
                                      // <DownloadOutlined />
                                      <FeatherIcon icon={"download"} />
                                    ) : (
                                      <Tooltip
                                        placement="left"
                                        title="Invalid File Format"
                                      >
                                        {" "}
                                        <ErrorOutline
                                          style={{ color: "#0000009e" }}
                                        />
                                      </Tooltip>
                                    )}
                                  </span>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              {/* <div className="btn-box-bottom">

                            <div className="col-md-12 d-flex justify-content-end">
                                <div class="portlet-footer portlet-footer-bordered mt-3 text-end scopebtn">
                                    <button
                                        className="btn btn-outline-secondary me-3"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </button>
                                  
                                </div>
                            </div>

                        </div> */}
            </div>
          </div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default SERPTracker;

//By Aishwarya reference for file download

// if (hasError) {
//     const errorWorkbook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(errorWorkbook, worksheet, sheetName);
//     const errorFile = XLSX.write(errorWorkbook, { bookType: 'xlsx', type: 'array' });
//     const errorBlob = new Blob([errorFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//     saveAs(errorBlob, `${file.name}_error.xlsx`);
// }
