import { Table } from 'antd';
import React from 'react'
import { useState, useEffect } from 'react';
import { Sorter } from '../../common/Sorter';
import { exportInToCSV, exportToEXCEL, findUnique, mmDDyyyyFormateDateSet } from '../../utility/Globals';
import { Pagination } from 'antd';
import APIManager from '../../services/APIManager';
import { bandwidthsplit } from '../../utility/Constant';
import FeatherIcon from 'feather-icons-react'

function TeamMember(props) {

    const [teammemberExportList, setteammemberExportList] = useState([]);
    const [teammemberexport, setteammemberExport] = useState([]);
    const [teammemberTabcol, setteammemberTabcol] = useState([]);
    const [tabteammemberlist, settabteammemberList] = useState([]);

    const [values, setValues] = useState([
        new Date(Date.now()).toLocaleDateString(),
        new Date(Date.now()).toLocaleDateString(),
    ]);

    useEffect(() => {
        let dateObjArr = [
            new Date(props?.dataParentToChild[0]),
            new Date(props?.dataParentToChild[1]),
        ];
        setValues(dateObjArr)
        let rowdata = {
            StartDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[0]),
            EndDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[1]),
            OrganizationId : localStorage.getItem("organizationId")
        };
        getTableSplit(rowdata);
    }, [props?.dataParentToChild]);

    function getTableSplit(rowdata) {
        const filtername = [];
        const filterdesignation = [];
        const filterreportingmanager = [];
        const filterproject = [];
        const filterproject1 = [];
        let filterreportingmanager1 = []
        APIManager.postAPI(bandwidthsplit, rowdata, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                    let listData = [];
                    let exportList = [];
                    response?.data.map((item, index) => {
                        listData.push({
                            name: item.name,
                            designation: item.designation,
                            reportingManager: item.reportingManager ? item.reportingManager : "",
                            project: item.project ? item.project : "",
                            allocated: item.allocated,
                            available: item.available
                        })
                        listData.sort( (a,b) => a.allocated - b.allocated );

                        exportList.push({
                            Name: item.name,
                            Role: item.designation,
                            ReportingManager: item.reportingManager,
                            Project: item.project,
                            Allocated: item.allocated,
                            Available: item.available
                        })
                        exportList.sort( (a,b) => a.Allocated - b.Allocated );

                        let filternameobj = {
                            text: item.name,
                            value: item.name
                        }
                        filtername.push(filternameobj);
                        filtername.sort((a, b) => a.text.localeCompare(b.text))

                        let filterdesignationobj = {
                            text: item.designation,
                            value: item.designation
                        }
                        filterdesignation.push(filterdesignationobj);
                        // filterdesignation.sort((a, b) => a.text.localeCompare(b.text))
                        filterdesignation.sort( (a,b) => a.text - b.text );

                        let filterreportingmanagerobj = {
                            text: item.reportingManager ? item.reportingManager : "",
                            value: item.reportingManager? item.reportingManager : ""
                        }
                        filterreportingmanager.push(filterreportingmanagerobj)
                        // filterreportingmanager.sort((a, b) => a.text.localeCompare(b.text))
                        filterreportingmanager.sort( (a,b) => a.text - b.text );
                        filterreportingmanager.map((item)=>{
                            if(item.text != ""){
                                filterreportingmanager1.push(item)
                            }
                        })
                        let filterprojectobj = {
                            text: item.project ? item.project : "",
                            value: item.project? item.project : ""
                        }
                        filterproject.push(filterprojectobj);
                        // filterproject.sort((a, b) => a.text.localeCompare(b.text))
                        filterproject.sort( (a,b) => a.text - b.text );
                        filterproject.map((item)=>{
                            if(item.text != ""){
                                filterproject1.push(item)
                            }
                        })
                    })

                    let columns = [
                        {
                            title: "Name",
                            dataIndex: "name",
                            key: "name",
                            filters: findUnique(filtername, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.name == value;
                            },
                            width: '23%',
                        },
                        {
                            title: "Designation",
                            dataIndex: "designation",
                            key: "designation",
                            filters: findUnique(filterdesignation, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.designation == value;
                            },
                            width: '15%',
                        },
                        {
                            title: "Reporting Manager",
                            dataIndex: "reportingManager",
                            key: "reportingManager",
                            filters: findUnique(filterreportingmanager1, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.reportingManager == value;
                            },
                            width: '20%',
                        },
                        {
                            title: "Project",
                            dataIndex: "project",
                            key: "project",
                            filters: findUnique(filterproject1, (d) => d.value),
                            filterSearch: true,
                            onFilter: (value, record) => {
                                return record.project == value;
                            },
                            width: '20%',
                        },
                        {
                            title: "Allocate",
                            dataIndex: "allocated",
                            key: "allocated",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.allocated, b.allocated),
                                multiple: 3,
                            },
                            width: '10%',
                        },
                        {
                            title: "Available",
                            dataIndex: "available",
                            key: "available",
                            sorter: {
                                compare: (a, b) => Sorter.DEFAULT(a.available, b.available),
                                multiple: 3,
                            },
                            width: '10%',
                        },
                    ];
                    setteammemberTabcol(columns);
                    settabteammemberList(listData);
                    setteammemberExportList(exportList)
                };
            }
        );
    };

    return (
        <>
            <div className="portlet team-bandwidth mt-4">
                <div className="portlet-header portlet-header-bordered px-3">
                    <h3 className="portlet-title ps-0">Team Members Bandwidth Split </h3>
                    <div className="d-flex justify-content-between align-items-center">
                        <button
                            className="btn-outline-export d-flex align-items-center"
                            type="button"
                            onClick={() => {
                                exportToEXCEL(
                                    teammemberExportList,
                                    "TeamMember"
                                );
                            }}
                        >
                           <FeatherIcon icon="download" />
                           <span className='ms-2'>Export</span>
                        </button>
                        {/* <div
                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                            aria-labelledby="dropdownMenuButton"
                        >
                            <div
                                onClick={() => {
                                    exportToEXCEL(
                                        teammemberExportList,
                                        "TeamMember"
                                    );
                                }}
                            >
                                <a class="dropdown-item">Excel</a>
                            </div>
                            <div
                                onClick={() => {
                                    exportInToCSV(
                                        teammemberExportList,
                                        "TeamMember"
                                    );
                                }}
                            >
                                <a class="dropdown-item">CSV</a>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-md-12 mt-4 px-1">
                    <Table
                        className="table table-bordered Bandwidth-Split table-hover table-responsive border-0"
                        id="sample"
                        columns={teammemberTabcol}
                        dataSource={tabteammemberlist}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            defaultPageSize:5,
                            pageSizeOptions: [5,10,20,50,100],
                            showTotal: (total, range) =>
                            `Showing ${range[0]} to ${range[1]} of ${total} Team Members`,
                          }}
                    />
                    {/* <div className="col-md-12 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-start content-page">Showing 1 to 5 of 25 Team Members</div>
                        <div className="d-flex align-items-end"> <Pagination defaultCurrent={1} total={20} /></div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default TeamMember