import React, { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import { getAssignedProjects } from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { Progress } from "antd";

function PocOverview(props) {
    const [options, setObject] = useState({});
    const [series, setSeries] = useState([]);
    const [pocId, setPocId] = useState();
    const [projectList, setProjectList] = useState([]);
    const [projectSelected, setProjectSelected] = useState("");
    const [projectCompleted, setProjectCompleted] = useState(35)
    const [turnaroundTime, setTurnaroundTime] = useState(30)

    useEffect(() => {
        getAssignedProjectsFun()
    }, [props])

    useEffect(() => {
        let list = [500, 320, 200, 150, 300, 100, 300, 200, 120];

        let maxChartData = Math.max(...list) * 0.3 + Math.max(...list);
        setSeries([
            {
                name: "",
                data: list,
                color: "#42a6f5",
            },
        ]);

        let object = {
            title: {
                text: "",
                align: "left",
            },

            chart: {
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        zoom: false,
                        zoomin: true,
                        zoomout: true,
                        customIcons: [],
                    },
                },
                type: "line",
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return val;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                tickPlacement: "on",
                categories: [
                    "Requirement Raised",
                    "Writer Assigned",
                    "Writing in Progress",
                    "Awaiting Content QC",
                    "Editor Assigned",
                    "Editing in Progress",
                    "Awaiting Content QC",
                    "Sent for Approval",
                    "Articles Completed"
                ],
            },
            yaxis: {
                max: maxChartData,
                logBase: 20,
                tickAmount: 8,
                min: 0,
                show: false,
            },
            grid: {
                show: false,
            },
        }
        setObject(object)
    }, [])

    function getAssignedProjectsFun() {
        APIManager.postAPI(getAssignedProjects +
            "pocId=" + pocId
            , {}, null, true).then(
                (response) => {
                    if (response && response.status == 200) {
                        let obj = [];
                        response.data.map((item) => {
                            obj.push({
                                id: item.id,
                                projectId: item.projectId,
                                pocId: item.pocId,
                                createdAt: item.createdAt
                            })
                        });
                        setProjectList(obj);
                    }
                }
            );
    }

    return (
        <>
            <div className="title mt-3">
                <div className="d-flex justify-content-between align-items-center poc-overview">
                    <div className="d-flex justify-content-between align-items-center">
                        <h4 className="me-3">POC Overview</h4>
                    </div>
                    <div className="d-flex align-items-center poc-select">
                        <select
                            className="form-select session-select ">
                            <option>Rupesh Bhanje</option>
                            <option>Aaraf Sheikh</option>
                            <option>Doyal Mathai</option>
                        </select>
                    </div>
                </div>

                <div className="content-assign-project mb-3">
                    <div className="assign-project">
                        <h4>Assigned Projects</h4>
                    </div>
                    <select className="w-100 form-select "
                        onChange={(e) => {
                            setProjectSelected(e.target.value)
                        }}>
                        {projectList.map((item) => {
                            return <option value={item.value}>{item.label}</option>
                        })}
                    </select>

                    <div className="content-status-time">

                        <div className="row">
                            <div className="col-md-12 d-flex align-items-center pt-0">
                                <div className="col-md-3 me-3">
                                    <div className="completion-status p-4 d-flex">
                                        <div className="cms-dashboard-progress me-3">
                                            <Progress
                                                type="circle"
                                                percent={projectCompleted}
                                                // size={50} 
                                                strokeWidth={13}
                                                showInfo={false} style={{ height: "50px" }} />
                                        </div>
                                        <div>
                                            <span>Project Completed</span>
                                            <p>{projectCompleted}%</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 ms-4">
                                    <div className="turnaround-time p-4 d-flex">
                                        <div className="cms-dashboard-progress me-3">
                                            <Progress type="circle"
                                                percent={turnaroundTime}
                                                // size={2}
                                                showInfo={false}
                                                strokeWidth={13}
                                                style={{ height: "50px" }} />
                                        </div>
                                        <div>
                                        </div>
                                        <div>
                                            <span>Turnaround Time</span>
                                            <p>24 Jan 2022</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="portlet-body position-relative">
                        <Chart
                            options={options}
                            series={series}
                            type="bar"
                            height="380px"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}
export default PocOverview;