import React, { useEffect, useState } from "react";
import { Table } from "antd";
import APIManager from "../../services/APIManager";
import Chart from "react-apexcharts";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  exportToEXCEL,
  findUnique,
  formateDateIntoDatemonth,
  getDateRangeOfWeek,
  mmDDyyyyFormateDateSet,
  returnMagnitude,
  urlDomainName,
} from "../../utility/Globals";
import {
  categoryWiseTable,
  categoryWiseTableExport,
  getRankingCategoryWise,
  getRankingCategoryWiseExport,
  getRankingKeywordWise,
  getRankingKeywordWiseExport,
  keywordWiseTable,
  keywordWiseTableExport,
  getcompetitorsByRankingcampaignConfigId
} from "../../utility/Constant";
import { Sorter } from "../../common/Sorter";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { DownOutlined, UpOutlined } from "@ant-design/icons";

function RankingTrends(props) {
  const [labelCategory, setLabelCategory] = useState('');
  const [labelKeyword, setLabelKeyword] = useState('');
  const [CategorywiseTab, setCategorywiseTab] = useState(true);
  const [KeywordwiseTab, setKeywordwiseTab] = useState(false);
  const [isChart, setIsChart] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [compareStartDate, setCompareStartDate] = useState("");
  const [compareEndDate, setCompareEndDate] = useState("");
  const [mainDomainName, setMainDomainName] = useState("")
  const [seriesKeyword, setSeriesKeyword] = useState([]);
  const [optionsKeyword, setObjectKeyword] = useState({
    legend: {
      show: true,
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      stroke: {
        curve: "smooth",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [

      ],
      title: {
        text: "",
        style: {
          color: "#000",
        },
      },
      labels: {
      },
    },
    yaxis: {
      tickAmount: 13,
      min: 300,
      max: 1600,
      labels: {
      },
    },
    tooltip: {
      y: {
      },
    },
  });
  const [campaignId, setCampaignId] = useState("")
  const [viewId, setViewId] = useState("")
  const [deviceType, setDeviceType] = useState(0)
  const [rankingType, setRankingType] = useState(0)
  const [categoryColumn, setCategoryColumn] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [keywordColumn, setKeywordColumn] = useState([])
  const [keywordData, setKeywordData] = useState([])
  const [categoryColumnTabular, setCategoryColumnTabular] = useState([])
  const [categoryDataTabular, setCategoryDataTabular] = useState([])
  const [keywordColumnTabular, setKeywordColumnTabular] = useState([])
  const [keywordDataTabular, setKeywordDataTabular] = useState([])
  const [seriesCategory, setSeriesCategory] = useState([]);
  const [optionsCategory, setObjectCategory] = useState({
  });
  const [exportListCategory, setExportListCategory] = useState([]);
  const [exportListKeyword, setExportListKeyword] = useState([]);
  const [exportListCategoryTabular, setExportListCategoryTabular] = useState([]);
  const [exportListKeywordTabular, setExportListKeywordTabular] = useState([]);
  const [pageNumber1, setPageNumber1] = useState(1);
  const [tablePageSize1, setTablePageSize1] = useState(10);
  const [tableParams1, setTableParams1] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      total: 10,
      showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} Categories`
    },
  });
  const [pageNumber2, setPageNumber2] = useState(1);
  const [tablePageSize2, setTablePageSize2] = useState(10);
  const [tableParams2, setTableParams2] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      current: 1,
      pageSize: 10,
      total: 10,
      showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} keywords`
    },
  });
  const [pageNumber3, setPageNumber3] = useState(1);
  const [tablePageSize3, setTablePageSize3] = useState(10);
  const [pageNumber4, setPageNumber4] = useState(1);
  const [tablePageSize4, setTablePageSize4] = useState(10);
  const [competitorsList, setCompetitorsList] = useState([])
  const [competitorsSelected, setCompetitorsSelected] = useState([])
  const [categoryId, setCategoryId] = useState("")
  const [keywordId, setKeywordId] = useState("")
  const [sortListCategory, setSortListCategory] = useState([
    {
      columnName: "category",
      order: undefined
    }
  ])
  const [sortListKeyword, setSortListKeyword] = useState([
    {
      columnName: "keyword",
      order: undefined
    }
  ])
  const [categoryListFilter, setCategoryTypeFilter] = useState([]);
  const [filterList, setFilterList] = useState([])
  useEffect(() => {
    getRankTrendingData();
  }, [props?.dataParentToChild]);

  useEffect(() => {
    getRankTrendingData();
  }, [props?.rankingType]);

  useEffect(() => {
    getRankTrendingData();
  }, [props?.deviceType]);

  useEffect(() => {
    getRankTrendingData();
  }, [props?.selectedCampaign]);

  useEffect(() => {
    getRankTrendingData();
  }, [props?.selectedCampaigView]);

  function setAddCompetitorsSelectDropdown(selectedCampaign) {
    APIManager.getAPI(getcompetitorsByRankingcampaignConfigId + selectedCampaign).then(
      (response) => {
        if (response && response.status == 200) {
          if (response?.data) {
            let competitorList = [];
            let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

            response?.data.map((item) => {
              let domainName = urlDomainName(item.competitor); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
              if (mainDomainNameNew.toLocaleLowerCase().trim() != domainName.toLocaleLowerCase().trim()) {
                let competitorObj = {
                  label: domainName,
                  value: item.competitor,
                }
                competitorList.push(competitorObj);
              }
            });

            setCompetitorsList(competitorList);

            setTimeout(() => {
              setKeywordTableColumn();
              setCategoryTableColumn();
            }, 5000);
          }
        }
      }
    );
  }

  function setCategoryTableColumn() {
    let competitorsSelectedList = [];
    if (competitorsSelected.length > 0) {
      competitorsSelected.map((competitor) => {
        competitorsSelectedList.push(competitor.value);
      });
    }

    let rowData = {
      CompetitorIds: competitorsSelectedList,
      RankingCampaignConfigurationId: props?.selectedCampaign,
      RankingCampaignViewConfigurationId: props?.selectedCampaigView,
      DateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
      ProjectId: localStorage.getItem("projectGId"),
      Device: props?.deviceType,
      Type: props?.rankingType,
      CurrentPage: pageNumber1,
      TablePageSize: tablePageSize1,
      sorter: sortListCategory,
      isSorter: false,
    }
    getDataCategoryWise(rowData);

    let rowDataCT;
    rowDataCT = {
      CompetitorIds: [],
      RankingCampaignConfigurationId: props?.selectedCampaign,
      RankingCampaignViewConfigurationId: props?.selectedCampaigView,
      ProjectId: localStorage.getItem("projectGId"),
      Device: props?.deviceType,
      Type: props?.rankingType,
      DateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
      CurrentPage: pageNumber3,
      TablePageSize: tablePageSize3
    }
    getCategoryWiseDataForTabular(rowDataCT)
  }

  function setKeywordTableColumn() {
    let competitorsSelectedList = [];
    if (competitorsSelected.length > 0) {
      competitorsSelected.map((competitor) => {
        competitorsSelectedList.push(competitor.value);
      });
    }

    let rowData = {
      CompetitorIds: competitorsSelectedList,
      RankingCampaignConfigurationId: props?.selectedCampaign,
      RankingCampaignViewConfigurationId: props?.selectedCampaigView,
      DateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
      ProjectId: localStorage.getItem("projectGId"),
      Device: props?.deviceType,
      Type: props?.rankingType,
      CurrentPage: pageNumber2,
      TablePageSize: tablePageSize2,
      sorter: sortListKeyword,
      isSorter: false,
      filter: filterList,
    }
    getDataKeywordWise(rowData);

    let rowDataKT;
    rowDataKT = {
      CompetitorIds: [],
      RankingCampaignConfigurationId: props?.selectedCampaign,
      RankingCampaignViewConfigurationId: props?.selectedCampaigView,
      ProjectId: localStorage.getItem("projectGId"),
      Device: props?.deviceType,
      Type: props?.rankingType,
      DateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
      CurrentPage: pageNumber4,
      TablePageSize: tablePageSize4
    }
    getKeywordWiseDataForTabular(rowDataKT)
  }

  function getRankTrendingData() {
    setCategoryTypeFilter(props?.categoryFilterList)
    setStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setEndDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setCompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    setCompareEndDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    );
    setRankingType(props?.rankingType);
    setDeviceType(props?.deviceType);
    setCampaignId(props?.selectedCampaign);
    setViewId(props?.selectedCampaigView)
    setMainDomainName(props?.domainName);

    if (props?.selectedCampaign != '' && props?.selectedCampaigView != '' && props?.domainName != "") {
      localStorage.setItem("CategorywiseTab", true);
      localStorage.setItem("KeywordwiseTab", false);
      setAddCompetitorsSelectDropdown(props?.selectedCampaign);
    }
  }

  function getDataCategoryWise(rowData) {
    setCategoryData([]);

    APIManager.postAPI(
      getRankingCategoryWise,
      rowData,
      null,
      true
    ).then((response) => {
      let i = 0;
      let list = [];
      let filtercCategoryList = [];
      let categoryIndex = 0;

      if (response && response?.status == 200) {
        response.data.trends.table1.map((item) => {
          setTableParams1({
            pagination: {
              current: rowData.CurrentPage,
              pageSize: rowData.TablePageSize,
              position: ["bottomRight"],
              showSizeChanger: true,
              total: item.totalCount,
              showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${item.totalCount} Categories`
            },
          });
        })

        response.data.trends.table.map((item, index) => {
          if (categoryId == item.categoryId) {
            categoryIndex = index;
          }

          let object = {
            key: i++,
            categoryId: item.categoryId,
            categories: item.category,
            search_volume: item.searchVolume,
            avg_position: item.avgPosition,
            no_of_keywords: item.keywordCounts
          }
          list.push(object);

          let filterCategoryListObj = {
            text: item.category,
            value: item.category,
          };
          filtercCategoryList.push(filterCategoryListObj);
        })

        setCategoryData(list);

        let column = [
          {
            title: "Categories",
            dataIndex: "categories",
            key: "categories",
          },
          {
            title: "Search Volume",
            dataIndex: "search_volume",
            key: "search_volume",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.search_volume, b.search_volume),
              multiple: 3,
            },
          },
          {
            title: "Avg. Position",
            dataIndex: "avg_position",
            key: "avg_position",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.avg_position, b.avg_position),
              multiple: 3,
            },
          },
          {
            title: "No. Of Keywords",
            dataIndex: "no_of_keywords",
            key: "no_of_keywords",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.no_of_keywords, b.no_of_keywords),
              multiple: 3,
            },
          },
        ]
        setCategoryColumn(column);

        let rowDataNew = {
          RankingCampaignConfigurationId: props?.selectedCampaign,
          RankingCampaignViewConfigurationId: props?.selectedCampaigView,
          DateRange: {
            FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
            TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
          },
          ProjectId: localStorage.getItem("projectGId"),
          Device: props?.deviceType,
          Type: props?.rankingType,
          CurrentPage: pageNumber1,
          TablePageSize: tablePageSize1,
          categoryId: categoryId != "" ? categoryId : list[0].categoryId,
        }
        getDataCategoryWiseClick(rowDataNew);

        if (localStorage.getItem("CategorywiseTab") == "true") {
          let trElement = document.querySelectorAll(".ant-table-row");
          for (let j = 0; j < trElement.length; j++) {
            if (trElement[j].classList.contains('active'))
              trElement[j].classList.remove("active");
          }

          let element;
          if (document.getElementsByClassName("categorywise-table")[0].getElementsByClassName("ant-table-row")[categoryIndex] != undefined) {
            element = document.getElementsByClassName("categorywise-table")[0].getElementsByClassName("ant-table-row")[categoryIndex];
            element.classList.add("active");
          }
        }
      }
    });
  }

  function setCategoryChart(values) {
    let object;
    let list = [];
    let keys = [];
    let data = [];
    let maxArray = [];
    let chartLegends = [];
    let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

    values.map((item) => {
      keys = Object.keys(item);

      if (competitorsSelected.length > 0) {
        competitorsSelected.map((competitor) => {
          let selectCompetitors = urlDomainName(competitor.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
          if (selectCompetitors.toLocaleLowerCase().trim() == item.url.toLocaleLowerCase().trim()) {
            keys = Object.keys(item);
            data = Object.values(item);
            let urlName;
            let chartData = [];

            chartLegends.push(item.url);

            keys.map((i) => {
              if (i == "url") {
                urlName = item[i];
              }
              else {
                chartData.push(item[i] != null ? item[i] : 0)
              }
            })
            maxArray.push(Math.max(...chartData));
            object = {
              name: urlName,
              data: chartData
            }
            list.push(object)
          }
        });
      }

      if (mainDomainNameNew.toLocaleLowerCase().trim() == item.url.toLocaleLowerCase().trim()) {
        keys = Object.keys(item);
        data = Object.values(item);
        let urlName;
        let chartData = [];

        chartLegends.push(item.url);

        keys.map((i) => {
          if (i == "url") {
            urlName = item[i];
          }
          else {
            chartData.push(item[i] != null ? item[i] : 0)
          }
        })
        maxArray.push(Math.max(...chartData));

        object = {
          name: urlName,
          data: chartData
        }
        list.push(object)
      }
    })

    let dateList = []
    keys.map((date) => {
      if (date != "url") {
        dateList.push(date)
      }
    })
    setSeriesCategory(list);

    let maxChartData = Math.max(...maxArray) * 0.3 + Math.max(...maxArray);

    let chartObject = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
      },
      legend: {
        show: true,
      },
      colors: [
        "#e6194B",
        "#f58231",
        "#ffe119",
        "#bfef45",
        "#3cb44b",
        "#42d4f4",
        "#4363d8",
        "#911eb4",
        "#f032e6",
        "#a9a9a9",
        "#9A6324",
        "#800000",
        "#469990",
        "#000075",
        "#fabed4",
      ],
      xaxis: {
        tickPlacement: "on",
        categories: dateList,
        title: {
          style: {
            color: "#000",
          },
        },
        labels: {
          formatter: function (val) {
            return formateDateIntoDatemonth(getDateRangeOfWeek(val))
          },
        },
      },
      yaxis: {
        min: 0,
        max: maxChartData,
        labels: {
          formatter: function (val) {
            return returnMagnitude(val);
          },
        },
        title: {
          text: 'Number of Keywords',
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: 'smooth',
        width: 1,
        lineCap: 'round',
      }
    }

    setObjectCategory(chartObject)
  }

  function getDataKeywordWise(rowData) {
    setKeywordData([]);

    APIManager.postAPI(
      getRankingKeywordWise,
      rowData,
      null,
      true
    ).then((response) => {
      let list = [];
      let filterKeywordList = [];
      let filterCategoryList = []
      let i = 0;
      let keywordIndex = 0;

      if (response && response?.status == 200) {
        response.data.trends.table1.map((item) => {
          setTableParams2({
            pagination: {
              current: rowData.CurrentPage,
              pageSize: rowData.TablePageSize,
              position: ["bottomRight"],
              showSizeChanger: true,
              total: item.totalCount,
              showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${item.totalCount} keywords`

            },
          });
        })

        response.data.trends.table.map((item, index) => {
          if (keywordId == item.keywordId) {
            keywordIndex = index;
          }
          let tooltipText = (item.keyword).charAt(0).toUpperCase() + (item.keyword).slice(1);

          let object = {
            key: i++,
            keywords: item.keyword,
            keywordId: item.keywordId,
            keywords: (<> <div class="keyword-data d-flex align-tems-center">
              <span className="keyword-distribution-data">
                <a
                  href="#"
                  class="text-dark"
                  data-toggle="tooltip"
                  data-html="true"
                  title={tooltipText}
                >
                  {item.keyword}
                </a>
              </span>
              {item.keywordType ? <a
                href="#"
                class="text-dark"
                data-toggle="tooltip"
                data-html="true"
                title={item.keywordType}
              > <div class="scope-H d-flex align-tems-center ms-2 my-2"><span>{item.keywordType.charAt(0).toUpperCase()}</span></div> </a> : ""}
              {item.priority == 1 ?
                <a
                  href="#"
                  class="text-dark"
                  data-toggle="tooltip"
                  data-html="true"
                  title="Priority"
                >  <div class=" d-flex align-tems-center my-2 ms-2"> <i class="fa-solid fa-star" ></i></div></a> : <></>}</div></>),

            search_volume: item.searchVolume,
            avg_position: item.avgPosition,
            category: item.category
          }
          list.push(object);

          let filterKeywordListObj = {
            text: item.keyword,
            value: item.keyword,
          };
          filterKeywordList.push(filterKeywordListObj);

          let filterCategoryListObj = {
            text: item.category,
            value: item.category,
          }
          filterCategoryList.push(filterCategoryListObj);
        });

        setKeywordData(list);

        let column = [
          {
            title: "Keywords",
            dataIndex: "keywords",
            key: "keywords",
            width: "38%",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.keywords, b.keywords),
              multiple: 3,
            },
          },
          {
            title: "Search Volume",
            dataIndex: "search_volume",
            key: "search_volume",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.search_volume, b.search_volume),
              multiple: 3,
            },
          },
          {
            title: "Avg. Position",
            dataIndex: "avg_position",
            key: "avg_position",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.avg_position, b.avg_position),
              multiple: 3,
            },
          },
          {
            title: "Category",
            dataIndex: "category",
            key: "category",
            filters: findUnique(categoryListFilter, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.category.indexOf(value) === 0,
          },
        ]
        setKeywordColumn(column);

        let rowDataNew = {
          RankingCampaignConfigurationId: props?.selectedCampaign,
          RankingCampaignViewConfigurationId: props?.selectedCampaigView,
          DateRange: {
            FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
            TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
          },
          ProjectId: localStorage.getItem("projectGId"),
          Device: props?.deviceType,
          Type: props?.rankingType,
          CurrentPage: pageNumber1,
          TablePageSize: tablePageSize1,
          keywordId: keywordId != "" ? keywordId : list[0].keywordId,
        }
        // if (!labelKeyword)
        getDataKeywordWiseClick(rowDataNew, keywordIndex);

        if (localStorage.getItem("KeywordwiseTab") == "true") {
          let trElement = document.querySelectorAll(".ant-table-row");
          for (let j = 0; j < trElement.length; j++) {
            if (trElement[j].classList.contains('active'))
              trElement[j].classList.remove("active");
          }

          let element;
          if (document.getElementsByClassName("keywordwise-table")[0].getElementsByClassName("ant-table-row")[keywordIndex] != undefined) {
            element = document.getElementsByClassName("keywordwise-table")[0].getElementsByClassName("ant-table-row")[keywordIndex];
            element.classList.add("active");
          }
        }
      }
    });
  }

  function setKeywordChart(values) {
    let object;
    let list = [];
    let keys = [];
    let data = [];
    let maxArray = [];
    let chartLegends = [];
    let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

    values.map((item) => {
      keys = Object.keys(item);

      if (competitorsSelected.length > 0) {
        competitorsSelected.map((competitor) => {
          let selectCompetitors = urlDomainName(competitor.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
          if (selectCompetitors.toLocaleLowerCase().trim() == item.url.toLocaleLowerCase().trim()) {
            keys = Object.keys(item);
            data = Object.values(item);
            let urlName;
            let chartData = [];

            chartLegends.push(item.url);

            keys.map((i) => {
              if (i == "url") {
                urlName = item[i];
              }
              else {
                chartData.push(item[i] != null ? item[i] : 0)
              }
            })
            maxArray.push(Math.max(...chartData));
            object = {
              name: urlName,
              data: chartData
            }
            list.push(object)
          }
        });
      }

      if (mainDomainNameNew.toLocaleLowerCase().trim() == item.url) {
        keys = Object.keys(item);
        data = Object.values(item);
        let urlName;
        let chartData = [];

        chartLegends.push(item.url);

        keys.map((i) => {
          if (i == "url") {
            urlName = item[i];
          }
          else {
            chartData.push(item[i] != null ? item[i] : 0)
          }
        })
        maxArray.push(Math.max(...chartData));
        object = {
          name: urlName,
          data: chartData
        }
        list.push(object)
      }
    })

    let dateList = []
    keys.map((date) => {
      if (date != "url") {
        dateList.push(date)
      }
    })
    setSeriesKeyword(list)

    let maxChartData = Math.max(...maxArray) * 0.3 + Math.max(...maxArray);

    let chartObject = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
      },
      legend: {
        show: true,
      },
      colors: [
        "#e6194B",
        "#f58231",
        "#ffe119",
        "#bfef45",
        "#3cb44b",
        "#42d4f4",
        "#4363d8",
        "#911eb4",
        "#f032e6",
        "#a9a9a9",
        "#9A6324",
        "#800000",
        "#469990",
        "#000075",
        "#fabed4",
      ],
      xaxis: {
        tickPlacement: "on",
        categories: dateList,
        title: {
          style: {
            color: "#000",
          },
        },
        labels: {
          formatter: function (val) {
            return formateDateIntoDatemonth(getDateRangeOfWeek(val))
          },
        },
      },
      yaxis: {
        min: 0,
        max: maxChartData,
        labels: {
          formatter: function (val) {
            return returnMagnitude(val);

          },
        },
        title: {
          text: 'Rank',
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return value;
          },
        },
      },
      markers: {
        size: 5,
      },
      stroke: {
        curve: 'smooth',
        width: 1,
        lineCap: 'round',
      }
    }

    setObjectKeyword(chartObject);
  }

  function GetcolNameKT(colName) {
    if (
      colName == "keyword" ||
      colName == "searchVolume" ||
      colName == "websites" ||
      colName == "avgPosition"
    ) {
      switch (colName) {
        case "keyword":
          return "Keywords";
        case "searchVolume":
          return "Search Volume";
        case "websites":
          return "Websites";
        case "avgPosition":
          return "Avg. Position";
      }
    } else {
      return (<div>
        <div>Rank</div>
        <div>{formateDateIntoDatemonth(getDateRangeOfWeek(colName))}</div>
      </div>)
    }
  }

  function GetcolNameCT(colName) {
    if (
      colName == "category" ||
      colName == "searchVolume" ||
      colName == "websites" ||
      colName == "avgPosition"
    ) {
      switch (colName) {
        case "category":
          return "Categories";
        case "searchVolume":
          return "Search Volume";
        case "websites":
          return "Websites";
        case "avgPosition":
          return "Avg. Position";
      }
    } else {
      return (<div>
        <div>No. Of Keywords</div>
        <div>{formateDateIntoDatemonth(getDateRangeOfWeek(colName))}</div>
      </div>)
    }
  }

  function GetcolNameCTExport(colName) {
    if (
      colName == "category" ||
      colName == "searchVolume" ||
      colName == "websites" ||
      colName == "avgPosition"
    ) {
      switch (colName) {
        case "category":
          return "Categories";
        case "searchVolume":
          return "Search Volume";
        case "websites":
          return "Websites";
        case "avgPosition":
          return "Avg. Position";
      }
    } else {
      return "No. Of Keywords " + formateDateIntoDatemonth(getDateRangeOfWeek(colName));
    }
  }

  function GetcolNameKTExport(colName) {
    if (
      colName == "keyword" ||
      colName == "searchVolume" ||
      colName == "websites" ||
      colName == "avgPosition"
    ) {
      switch (colName) {
        case "keyword":
          return "Keywords";
        case "searchVolume":
          return "Search Volume";
        case "websites":
          return "Websites";
        case "avgPosition":
          return "Avg. Position";
      }
    } else {
      return "Rank " + formateDateIntoDatemonth(getDateRangeOfWeek(colName));
    }
  }

  let keywordNames = [];
  function getKeywordWiseDataForTabular(rowData) {
    setKeywordDataTabular([]);
    APIManager.postAPI(
      keywordWiseTable,
      rowData,
      null,
      true
    ).then((response) => {
      if (response.data.table.length > 0) {
        let list = [];
        let keywordList = [];
        let childDataArray = [];
        let filterKeywordList = [];
        let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

        response.data.table.map((keywordItem) => {
          keywordList.push(keywordItem.keyword);
        });
        keywordList = keywordList.filter(function (item, i, ar) { return ar.indexOf(item) === i; });

        keywordList.map((keywordItem) => {
          let filterKeywordListObj = {
            text: keywordItem,
            value: keywordItem,
          };
          filterKeywordList.push(filterKeywordListObj);
        });

        let keys = [];
        const columns = [];
        keys = Object.keys(response?.data.table[0]);

        let keys1 = [];
        keys.map((key) => {
          if (key == "keyword") {
            keys1[0] = "keyword";
          }
          else if (key == "searchVolume") {
            keys1[1] = "searchVolume";
          }
          else if (key == "websites") {
            keys1[2] = "websites";
          } else if (key == "avgPosition") {
            keys1[3] = "avgPosition";
          }
        });

        keys.map((key) => {
          if (key != "keyword" && key != "searchVolume" && key != "websites" && key != "avgPosition") {
            keys1.push(key);
          }
        });

        let filterListData = [];
        let i = 0;
        let j = 0;

        keywordList.map((keywordItem) => {
          let obj = {};
          let childObj = {};
          j = 0;

          filterListData = response.data.table.filter(val => val.keyword.includes(keywordItem));
          // filterListData.sort((a, b) =>
          //   (a.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) >
          //     b.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()))
          //     && (a.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()).toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim()
          //       || b.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()).toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim())
          //     ? 1
          //     : -1
          // );

          filterListData.sort((a, b) =>
            (a.websites.toLowerCase() > b.websites.toLowerCase())
              && (a.websites.toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim()
                || b.websites.toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim())
              ? 1
              : -1
          );

          let exists = filterListData.some(el => el.websites === mainDomainNameNew.toLocaleLowerCase().trim());

          filterListData.map((item, index) => {
            let domain = item.websites; //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
            obj = {};
            childObj = {};
            obj["key"] = i + 1;

            keys.map((key) => {
              if (mainDomainNameNew.toLocaleLowerCase().trim() != domain.toLocaleLowerCase().trim()) {
                if (competitorsSelected.length > 0) {
                  competitorsSelected.map((competitor) => {
                    let selectCompetitors = urlDomainName(competitor.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                    if (selectCompetitors.toLocaleLowerCase().trim() == domain.toLocaleLowerCase().trim()) {
                      childObj["key"] = (i + 1) + "." + (j + 1);
                      childObj[key] = key == "websites" ? item[key] : key == "keyword" ? "" : (item[key] ? item[key] : "-");
                      j++;
                    }
                  });
                }
              } else {
                obj[key] = key == "websites" ? item[key] : (item[key] ? item[key] : "-");
              }
            });

            if (childDataArray.length > 0)
              obj["children"] = [];

            if (mainDomainNameNew.toLocaleLowerCase().trim() != domain.toLocaleLowerCase().trim()) {
              if (competitorsSelected.length > 0) {
                competitorsSelected.map((competitor) => {
                  let selectCompetitors = urlDomainName(competitor.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                  if (selectCompetitors.toLocaleLowerCase().trim() == domain.toLocaleLowerCase().trim()) {
                    childDataArray.push(childObj);
                  }
                });
              }
            } else {
              list.push(obj);
              if (childDataArray.length > 0)
                list[i].children = childDataArray;
              childDataArray = [];
              childObj = {};
              i++;
            }

            if (!exists) {
              if (index == filterListData.length - 1) {
                keys.map((key) => {
                  obj[key] = key == "websites" ? mainDomainNameNew.toLocaleLowerCase().trim() : key == "keyword" ? item.keyword : "-";
                });

                list.push(obj);
                if (childDataArray.length > 0)
                  list[i].children = childDataArray;
                childDataArray = [];
                childObj = {};
                i++;
              }
            }
          })
        });

        setKeywordDataTabular(list)

        keys1.map((key) => {
          let colName;
          colName = key;
          let col

          if (key == "keyword") {
            col = {
              title: GetcolNameKT(colName),
              dataIndex: colName,
              key: colName,
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
                multiple: 3,
              },
              onCell: (_, index) => {
                if (_.keyword == "") {
                  let indexNew = _.key.split('.');
                  let newIndex = parseInt(indexNew[0]) - 1;
                  keywordNames.push(_.keyword);
                  let occurCount = list[newIndex].children.filter(data => data.keyword === _.keyword).length;
                  if (keywordNames.length == 1) {
                    return { rowSpan: occurCount };
                  }
                  else {
                    return { rowSpan: 0 };
                  }
                } else {
                  keywordNames = [];
                  return {};
                }
              },
            };
          }
          else {
            col = {
              title: GetcolNameKT(colName),
              dataIndex: colName,
              key: colName,
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a[colName], b[colName]),
                multiple: 3,
              },
            };
          }

          columns.push(col);
        });

        setKeywordColumnTabular(columns);
      }
    });
  }

  let categoryNames = [];
  function getCategoryWiseDataForTabular(rowData) {
    setCategoryDataTabular([]);
    APIManager.postAPI(
      categoryWiseTable,
      rowData,
      null,
      true
    ).then((response) => {

      if (response.data && response.data.table.length) {
        let list = [];
        let categoryList = [];
        let childDataArray = [];
        let filterCategoryList = [];
        let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

        response.data.table.map((categoryItem) => {
          categoryList.push(categoryItem.category);
        });
        categoryList = categoryList.filter(function (item, i, ar) { return ar.indexOf(item) === i; });

        categoryList.map((categoryItem) => {
          let filterCategoryListObj = {
            text: categoryItem,
            value: categoryItem,
          };
          filterCategoryList.push(filterCategoryListObj);
        });

        let keys = [];
        const columns = [];
        keys = Object.keys(response?.data.table[0]);

        let keys1 = [];
        keys.map((key) => {
          if (key == "category") {
            keys1[0] = "category";
          }
          else if (key == "searchVolume") {
            keys1[1] = "searchVolume";
          }
          else if (key == "websites") {
            keys1[2] = "websites";
          } else if (key == "avgPosition") {
            keys1[3] = "avgPosition";
          }
        });

        keys.map((key) => {
          if (key != "category" && key != "searchVolume" && key != "websites" && key != "avgPosition") {
            keys1.push(key);
          }
        });

        let filterListData = [];
        let i = 0;
        let j = 0;

        categoryList.map((categoryItem) => {
          let obj = {};
          let childObj = {};
          j = 0;

          filterListData = response.data.table.filter(val => val.category.includes(categoryItem));
          // filterListData.sort((a, b) =>
          //   (a.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()) >
          //     b.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()))
          //     && (a.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()).toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim()
          //       || b.websites.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase()).toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim())
          //     ? 1
          //     : -1
          // );

          filterListData.sort((a, b) =>
            (a.websites.toLowerCase() > b.websites.toLowerCase())
              && (a.websites.toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim()
                || b.websites.toLocaleLowerCase().trim() == mainDomainNameNew.toLocaleLowerCase().trim())
              ? 1
              : -1
          );

          let exists = filterListData.some(el => el.websites === mainDomainNameNew.toLocaleLowerCase().trim());

          filterListData.map((item, index) => {
            let domain = item.websites; //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
            obj = {};
            childObj = {};
            obj["key"] = i + 1;

            keys.map((key) => {
              if (mainDomainNameNew.toLocaleLowerCase().trim() != domain.toLocaleLowerCase().trim()) {
                if (competitorsSelected.length > 0) {
                  competitorsSelected.map((competitor) => {
                    let selectCompetitors = urlDomainName(competitor.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                    if (selectCompetitors.toLocaleLowerCase().trim() == domain.toLocaleLowerCase().trim()) {
                      childObj["key"] = (i + 1) + "." + (j + 1);
                      childObj[key] = key == "websites" ? item[key] : key == "category" ? "" : (item[key] ? item[key] : "-");
                      j++;
                    }
                  });
                }
              } else {
                obj[key] = key == "websites" ? item[key] : (item[key] ? item[key] : "-");
              }
            });

            if (childDataArray.length > 0)
              obj["children"] = [];

            if (mainDomainNameNew.toLocaleLowerCase().trim() != domain.toLocaleLowerCase().trim()) {
              if (competitorsSelected.length > 0) {
                competitorsSelected.map((competitor) => {
                  let selectCompetitors = urlDomainName(competitor.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                  if (selectCompetitors.toLocaleLowerCase().trim() == domain.toLocaleLowerCase().trim()) {
                    childDataArray.push(childObj);
                  }
                });
              }
            } else {
              list.push(obj);
              if (childDataArray.length > 0)
                list[i].children = childDataArray;
              childDataArray = [];
              childObj = {};
              i++;
            }

            if (!exists) {
              if (index == filterListData.length - 1) {
                keys.map((key) => {
                  obj[key] = key == "websites" ? mainDomainNameNew.toLocaleLowerCase().trim() : key == "category" ? item.category : "-";
                });

                list.push(obj);
                if (childDataArray.length > 0)
                  list[i].children = childDataArray;
                childDataArray = [];
                childObj = {};
                i++;
              }
            }
          });
        });

        setCategoryDataTabular(list);

        keys1.map((key) => {
          let colName;
          colName = key;
          let col;
          if (key == "category") {
            col = {
              title: GetcolNameCT(colName),
              dataIndex: colName,
              key: colName,
              onCell: (_, index) => {
                if (_.category == "") {
                  let indexNew = _.key.split('.');
                  let newIndex = parseInt(indexNew[0]) - 1;
                  categoryNames.push(_.category);
                  let occurCount = list[newIndex].children.filter(data => data.category === _.category).length;
                  if (categoryNames.length == 1) {
                    return { rowSpan: occurCount };
                  }
                  else {
                    return { rowSpan: 0 };
                  }
                } else {
                  categoryNames = [];
                  return {};
                }
              },
            };
          }
          else {
            col = {
              title: GetcolNameCT(colName),
              dataIndex: colName,
              key: colName,
              sorter: {
                compare: (a, b) => Sorter.DEFAULT(a[colName], b[colName]),
                multiple: 3,
              },
            };
          }

          columns.push(col);
        });

        setCategoryColumnTabular(columns);
      }
    });
  }

  function getColName(value) {
    if (value == "search_volume") {
      return "searchVolume"
    }
    else if (value == "avg_position") {
      return "avgPosition"
    }
    else if (value == "no_of_keywords") {
      return "keywordCounts"
    }
    else if (value == "keywords") {
      return "keyword"
    }
    else {
      return value
    }
  }

  const handleTableChange1 = (pagination, filter, sorter) => {
    setSortListCategory(sortListCategory);
    let sortListVar = [];
    if (sorter.length > 0) {
      sorter.map((item) => {
        sortListVar.push({
          columnName: getColName(item.columnKey),
          order: item.order
        })
      })
    }
    else {
      if (sorter.columnKey != undefined) {
        sortListVar.push({
          columnName: getColName(sorter.columnKey),
          order: sorter.order
        })
      }
      if (sortListVar.length > 0) {
        setSortListCategory(sortListVar)
      }
    }
    let isSorterVar = false;

    let competitorsSelectedList = [];
    if (competitorsSelected.length > 0) {
      competitorsSelected.map((competitor) => {
        competitorsSelectedList.push(competitor.value);
      });
    }

    let rowData = {
      CompetitorIds: competitorsSelectedList,
      RankingCampaignConfigurationId: props?.selectedCampaign,
      RankingCampaignViewConfigurationId: props?.selectedCampaigView,
      DateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
      ProjectId: localStorage.getItem("projectGId"),
      Device: props?.deviceType,
      Type: props?.rankingType,
      CurrentPage: pagination.current,
      TablePageSize: pagination.pageSize,
      sorter: sortListVar.length > 0 ? sortListVar : sortListCategory,
      isSorter: isSorterVar,
    }
    getDataCategoryWise(rowData);
  }

  const handleTableChange2 = (pagination, filter, sorter) => {
    setSortListKeyword(sortListKeyword);
    let sortListVar = [];
    if (sorter.length > 0) {
      sorter.map((item) => {
        sortListVar.push({
          columnName: getColName(item.columnKey),
          order: item.order
        })
      })
    }
    else {
      if (sorter.columnKey != undefined) {
        sortListVar.push({
          columnName: getColName(sorter.columnKey),
          order: sorter.order
        })
      }
      if (sortListVar.length > 0) {
        setSortListKeyword(sortListVar)
      }
    }

    if (sortListVar.length > 0) {
      setSortListKeyword(sortListVar)
    }

    let isSorterVar = false;
    let competitorsSelectedList = [];
    if (competitorsSelected.length > 0) {
      competitorsSelected.map((competitor) => {
        competitorsSelectedList.push(competitor.value);
      });
    }

    let filterList = [];
    if (filter.category != null) {
      if (filter.category.length > 0) {
        filter.category.map((item) => {
          filterList.push(item)
        })
        setFilterList(filterList)
      }
    }

    let rowData = {
      CompetitorIds: competitorsSelectedList,
      RankingCampaignConfigurationId: props?.selectedCampaign,
      RankingCampaignViewConfigurationId: props?.selectedCampaigView,
      DateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
      ProjectId: localStorage.getItem("projectGId"),
      Device: props?.deviceType,
      Type: props?.rankingType,
      CurrentPage: pagination.current,
      TablePageSize: pagination.pageSize,
      sorter: sortListVar.length > 0 ? sortListVar : sortListKeyword,
      isSorter: isSorterVar,
      filter: filterList,
    }
    getDataKeywordWise(rowData);
  }

  function getDataCategoryWiseExport(rowData) {
    APIManager.postAPI(
      getRankingCategoryWiseExport,
      rowData,
      null,
      true
    ).then((response) => {
      let exportCategoryList = [];
      if (response && response?.status == 200) {
        response.data.map((item) => {
          let expObj = {
            Categories: item.category,
            SearchVolume: item.searchVolume,
            AveragePosition: item.avgPosition,
            NoOfKeywords: item.keywordCounts
          }

          exportCategoryList.push(expObj)
        })
        setExportListCategory(exportCategoryList);
        exportToEXCEL(exportCategoryList, "Ranking Trends Category");
      }
    });
  }

  function getDataKeywordWiseExport(rowData) {
    APIManager.postAPI(
      getRankingKeywordWiseExport,
      rowData,
      null,
      true
    ).then((response) => {
      let exportKeywordList = [];
      if (response && response?.status == 200) {
        response.data.map((item) => {
          let expObj = {
            Keywords: item.keyword,
            SearchVolume: item.searchVolume,
            AveragePosition: item.avgPosition,
            Category: item.category
          }
          exportKeywordList.push(expObj)
        })
        setExportListKeyword(exportKeywordList)
        exportToEXCEL(exportKeywordList, "Ranking Trends Keyword");
      }
    });
  }

  function getCategoryWiseDataForTabularExport(rowData) {
    APIManager.postAPI(
      categoryWiseTableExport,
      rowData,
      null,
      true
    ).then((response) => {
      if (response.data.length > 0) {
        let list = [];
        let keys = [];
        let keys1 = [];
        keys = Object.keys(response?.data[0]);

        keys.map((key) => {
          if (key == "category") {
            keys1[0] = "category";
          }
          else if (key == "searchVolume") {
            keys1[1] = "searchVolume";
          }
          else if (key == "websites") {
            keys1[2] = "websites";
          } else if (key == "avgPosition") {
            keys1[3] = "avgPosition";
          }
        });

        keys.map((key) => {
          if (key != "category" && key != "searchVolume" && key != "websites" && key != "avgPosition") {
            keys1.push(key);
          }
        });

        response.data.map((item) => {
          let i = 0;
          let obj = {};
          obj["key"] = i += 1;

          keys1.map((key) => {
            obj[GetcolNameCTExport(key)] = key == "websites" ? item[key] : (item[key] ? item[key] : "-");
          });
          list.push(obj);
        })
        setExportListCategoryTabular(list)
        exportToEXCEL(list, "Ranking Trends Category");
      }
    });
  }

  function getKeywordWiseDataForTabularExport(rowData) {
    APIManager.postAPI(
      keywordWiseTableExport,
      rowData,
      null,
      true
    ).then((response) => {
      if (response.data.length > 0) {
        let list = [];
        let keys = [];
        let keys1 = [];
        keys = Object.keys(response?.data[0]);

        keys.map((key) => {
          if (key == "keyword") {
            keys1[0] = "keyword";
          }
          else if (key == "searchVolume") {
            keys1[1] = "searchVolume";
          }
          else if (key == "websites") {
            keys1[2] = "websites";
          } else if (key == "avgPosition") {
            keys1[3] = "avgPosition";
          }
        });

        keys.map((key) => {
          if (key != "keyword" && key != "searchVolume" && key != "websites" && key != "avgPosition") {
            keys1.push(key);
          }
        });

        response.data.map((item) => {
          let i = 0;
          let obj = {};
          obj["key"] = i += 1;

          keys1.map((key) => {
            obj[GetcolNameKTExport(key)] = key == "websites" ? item[key] : (item[key] ? item[key] : "-");
          });
          list.push(obj);
        })
        setExportListKeywordTabular(list);
        exportToEXCEL(list, "Ranking Trends Keyword");
      }
    });
  }

  function getDataCategoryWiseClick(rowData) {
    APIManager.postAPI(
      getRankingCategoryWise,
      rowData,
      null,
      true
    ).then((response) => {
      setSeriesCategory([]);
      setObjectCategory([]);
      setCategoryChart(response.data.trendsChart.table);
      if (!labelCategory && response.data && response.data.trends.table.length && response.data.trends.table[0].category)
        setLabelCategory(response.data.trends.table[0].category);
    })
  }

  function getDataKeywordWiseClick(rowData, index) {
    APIManager.postAPI(
      getRankingKeywordWise,
      rowData,
      null,
      true
    ).then((response) => {
      setSeriesKeyword([]);
      setObjectKeyword([]);

      setKeywordChart(response.data.trendsChart.table);
      if (keywordData.length && keywordData[index].keywords.props.children.length && keywordData[0].keywords.props.children[1].props.children[0].props.children)
        setLabelKeyword(keywordData[index].keywords.props.children[1].props.children[0].props.children);

    })
  }

  return (
    <>
      <div>
        <div className="overall-rank">
          <div className="overall-title">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="me-3 ranking-name my-1">Ranking Trends</h4>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div className="btn-box px-2 py-2">
                  <div className="d-flex align-items-center">
                    <button
                      className={`${isChart == 0 ? "active" : ""}`}
                      onClick={() => {
                        let type = 0;
                        setIsChart(type);
                        setKeywordTableColumn();
                        setCategoryTableColumn();
                      }}
                    >
                      <img
                        src={`${isChart == 0
                          ? "/images/graph-icon-white.svg"
                          : "/images/graph-icon-black.svg"
                          }`}
                        alt=""
                      />
                    </button>
                    <button
                      className={`${isChart == 1 ? "active" : ""}`}
                      onClick={() => {
                        let type = 1;
                        setIsChart(type);
                        setKeywordTableColumn();
                        setCategoryTableColumn();
                      }}
                    >
                      <img
                        src={`${isChart == 1
                          ? "/images/table-icon-white.svg"
                          : "/images/table-icon-black.svg"
                          }`}
                        alt=""
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isChart == 0 ? (
          <>
            <Tabs>
              <div className="portlet-header portlet-header-bordered p-0">
                <TabList className="nav nav-lines portlet-nav">
                  <Tab
                    className={
                      CategorywiseTab
                        ? "nav-item nav-link  active "
                        : "nav-item nav-link "
                    }
                    onClick={() => {
                      setFilterList([]);
                      setKeywordwiseTab(false);
                      setCategorywiseTab(true);
                      localStorage.setItem("CategorywiseTab", true);
                      localStorage.setItem("KeywordwiseTab", false);
                      setKeywordTableColumn();
                      setCategoryTableColumn();
                    }}
                  >Category wise
                  </Tab>
                  <Tab
                    className={
                      KeywordwiseTab
                        ? "nav-item nav-link active"
                        : "nav-item nav-link  "
                    }
                    onClick={() => {
                      setFilterList([]);
                      setCategorywiseTab(false);
                      setKeywordwiseTab(true);
                      localStorage.setItem("CategorywiseTab", false);
                      localStorage.setItem("KeywordwiseTab", true);
                      setKeywordTableColumn();
                      setCategoryTableColumn();
                    }}
                  >
                    Keyword wise
                  </Tab>

                </TabList>
              </div>
              <TabPanel>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <Table
                      className="categorywise-table ranking-table-design"
                      columns={categoryColumn}
                      dataSource={categoryData}
                      scroll={{ y: 200 }}
                      pagination={tableParams1.pagination}
                      onChange={handleTableChange1}
                      onRow={(record) => {
                        return {
                          onClick: event => {
                            setLabelCategory(record.categories);
                            setCategoryId(record.categoryId);

                            let trElement = document.querySelectorAll(".ant-table-row");
                            for (let j = 0; j < trElement.length; j++) {
                              if (trElement[j].classList.contains('active'))
                                trElement[j].classList.remove("active");
                            }

                            let element = event.target.parentNode;
                            element.classList.add("active");

                            let rowData = {
                              RankingCampaignConfigurationId: props?.selectedCampaign,
                              RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                              DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                              },
                              ProjectId: localStorage.getItem("projectGId"),
                              Device: props?.deviceType,
                              Type: props?.rankingType,
                              CurrentPage: pageNumber1,
                              TablePageSize: tablePageSize1,
                              categoryId: record.categoryId,
                            }
                            getDataCategoryWiseClick(rowData);
                          }
                        }
                      }}
                    ></Table>
                  </div>
                  <div className="col-md-6 category-chart mt-3">
                    <div className="d-flex align-items-center">
                      <div className="rankingtrends-column-select w-100">
                        <ReactMultiSelectCheckboxes className="d-flex align-items-center w-100"
                          options={competitorsList}
                          onChange={(selected) => {
                            setCompetitorsSelected(selected);
                          }}
                          value={competitorsSelected}
                          hideSearch />
                      </div>
                      <button
                        className="btn btn-primary add-competitor me-2"
                        onClick={() => {
                          let competitorsSelectedList = [];
                          if (competitorsSelected.length > 0) {
                            competitorsSelected.map((competitor) => {
                              competitorsSelectedList.push(competitor.value);
                            });
                          }

                          let rowData = {
                            CompetitorIds: competitorsSelectedList,
                            RankingCampaignConfigurationId: props?.selectedCampaign,
                            RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                            DateRange: {
                              FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                              TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                            },
                            ProjectId: localStorage.getItem("projectGId"),
                            Device: props?.deviceType,
                            Type: props?.rankingType,
                            CurrentPage: pageNumber1,
                            TablePageSize: tablePageSize1,
                            sorter: sortListCategory,
                            isSorter: false,
                          }
                          getDataCategoryWise(rowData);
                        }}
                      >
                        Add Competitor
                      </button>
                      <div className="d-flex justify-content-between align-items-center">
                        <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <button className="rank-export"
                          onClick={() => {
                            let rowData = {
                              RankingCampaignConfigurationId: props?.selectedCampaign,
                              RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                              DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                              },
                              ProjectId: localStorage.getItem("projectGId"),
                              Device: props?.deviceType,
                              Type: props?.rankingType,
                            }
                            getDataCategoryWiseExport(rowData);
                          }}>Export</button>
                      </div>

                    </div>

                    <div className="without-category-chart mt-2 mb-3">
                      {labelCategory ? <div className="chart-upper-text d-flex align-items-center ms-4 ps-2">
                        <span>Category :</span>
                        <span className="fw-bold ms-2">{labelCategory}</span>
                      </div> : <></>}
                      <Chart
                        options={optionsCategory}
                        series={seriesCategory}
                        type="line"
                        height="300"
                        id="chartId"
                      />
                    </div>
                  </div>
                </div>

              </TabPanel>

              <TabPanel>
                <div className="row">
                  <div className="col-md-6 mt-3">
                    <Table
                      className="keywordwise-table ranking-table-design"
                      columns={keywordColumn}
                      dataSource={keywordData}
                      scroll={{ y: 200 }}
                      pagination={tableParams2.pagination}
                      onChange={handleTableChange2}
                      onRow={(record) => {
                        return {
                          onClick: event => {
                            setKeywordId(record.keywordId);

                            let trElement = document.querySelectorAll(".ant-table-row");
                            for (let j = 0; j < trElement.length; j++) {
                              if (trElement[j].classList.contains('active'))
                                trElement[j].classList.remove("active");
                            }

                            let element = event.target.parentNode;
                            if (element.classList.contains('ant-table-row')) {
                              element.classList.add("active");
                            } else {
                              element = event.target.parentNode.parentNode.parentNode;
                              element.classList.add("active");
                            }

                            let rowData = {
                              RankingCampaignConfigurationId: props?.selectedCampaign,
                              RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                              DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                              },
                              ProjectId: localStorage.getItem("projectGId"),
                              Device: props?.deviceType,
                              Type: props?.rankingType,
                              CurrentPage: pageNumber1,
                              TablePageSize: tablePageSize1,
                              keywordId: record.keywordId,
                            }
                            getDataKeywordWiseClick(rowData, record.key);
                          }
                        }
                      }}
                    ></Table>
                  </div>
                  <div className="col-md-6 keyword-chart mt-3">
                    <div className="d-flex align-items-center">
                      <div className="rankingtrends-column-select w-100">
                        <ReactMultiSelectCheckboxes className="d-flex align-items-center w-100"
                          options={competitorsList}
                          onChange={(selected) => {
                            setCompetitorsSelected(selected);
                          }}
                          value={competitorsSelected}
                          hideSearch />
                      </div>
                      <button
                        className="btn btn-primary add-competitor me-2"
                        onClick={() => {
                          let competitorsSelectedList = [];
                          if (competitorsSelected.length > 0) {
                            competitorsSelected.map((competitor) => {
                              competitorsSelectedList.push(competitor.value);
                            });
                          }

                          let rowData = {
                            CompetitorIds: competitorsSelectedList,
                            RankingCampaignConfigurationId: props?.selectedCampaign,
                            RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                            DateRange: {
                              FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                              TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                            },
                            ProjectId: localStorage.getItem("projectGId"),
                            Device: props?.deviceType,
                            Type: props?.rankingType,
                            CurrentPage: pageNumber2,
                            TablePageSize: tablePageSize2,
                            sorter: sortListKeyword,
                            isSorter: false,
                            filter: filterList,
                          }
                          getDataKeywordWise(rowData);
                        }}
                      >
                        Add Competitor
                      </button>
                      <div className="d-flex justify-content-between align-items-center">
                        <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <button className="rank-export"
                          onClick={() => {
                            let rowData = {
                              RankingCampaignConfigurationId: props?.selectedCampaign,
                              RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                              DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                              },
                              ProjectId: localStorage.getItem("projectGId"),
                              Device: props?.deviceType,
                              Type: props?.rankingType,
                            }
                            getDataKeywordWiseExport(rowData)
                          }}>Export</button>
                      </div>
                    </div>
                    <div className="without-keyword-chart mt-2 mb-3">
                      {labelKeyword && <div className="chart-upper-text d-flex align-items-center ms-4 ps-2">
                        <span>Keyword :</span>
                        <span className="fw-bold ms-2">{labelKeyword}</span>
                      </div>}
                      <Chart
                        options={optionsKeyword}
                        series={seriesKeyword}
                        type="line"
                        height="300"
                        id="chartId"
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </>
        )
          :
          (
            <>
              <Tabs>
                <div className="portlet-header portlet-header-bordered">
                  <TabList className="nav nav-lines portlet-nav">
                    <Tab
                      className={
                        CategorywiseTab
                          ? "nav-item nav-link  active "
                          : "nav-item nav-link "
                      }
                      onClick={() => {
                        setKeywordwiseTab(false);
                        setCategorywiseTab(true);
                        localStorage.setItem("CategorywiseTab", true);
                        localStorage.setItem("KeywordwiseTab", false);
                        setKeywordTableColumn();
                        setCategoryTableColumn();
                      }}
                    >Category wise
                    </Tab>
                    <Tab
                      className={
                        KeywordwiseTab
                          ? "nav-item nav-link active"
                          : "nav-item nav-link  "
                      }
                      onClick={() => {
                        setCategorywiseTab(false);
                        setKeywordwiseTab(true);
                        localStorage.setItem("CategorywiseTab", false);
                        localStorage.setItem("KeywordwiseTab", true);
                        setKeywordTableColumn();
                        setCategoryTableColumn();
                      }}
                    >
                      Keyword wise
                    </Tab>

                  </TabList>
                </div>
                <TabPanel>
                  <div className="row full-category-table">
                    <div className="col-md-12 category-chart mt-3">
                      <div className="d-flex align-items-center px-3">
                        <div className="rankingtrends-column-select w-100">
                          <ReactMultiSelectCheckboxes className="d-flex align-items-center"
                            options={competitorsList}
                            onChange={(selected) => {
                              setCompetitorsSelected(selected);
                            }}
                            value={competitorsSelected}
                            hideSearch />
                        </div>
                        <button
                          className="btn btn-primary add-competitor me-2"
                          onClick={() => {
                            let rowDataCT = {
                              CompetitorIds: [],
                              RankingCampaignConfigurationId: props?.selectedCampaign,
                              RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                              ProjectId: localStorage.getItem("projectGId"),
                              Device: props?.deviceType,
                              Type: props?.rankingType,
                              DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                              },
                              CurrentPage: pageNumber3,
                              TablePageSize: tablePageSize3
                            }
                            getCategoryWiseDataForTabular(rowDataCT);
                          }}
                        >
                          Add Competitor
                        </button>
                        <div className="d-flex justify-content-between align-items-center">
                          <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <button className="rank-export"
                            onClick={() => {
                              let rowDataCT = {
                                CompetitorIds: [],
                                RankingCampaignConfigurationId: props?.selectedCampaign,
                                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                                ProjectId: localStorage.getItem("projectGId"),
                                Device: props?.deviceType,
                                Type: props?.rankingType,
                                DateRange: {
                                  FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                  TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                                },
                              }
                              getCategoryWiseDataForTabularExport(rowDataCT);
                            }}>Export</button>
                        </div>
                      </div>

                      <div className="col-md-12 mt-3">
                        <Table
                          className="table-bordered border-0 rt-category-table ranking-table-design"
                          columns={categoryColumnTabular}
                          dataSource={categoryDataTabular}
                          pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} Categories`
                          }}
                          expandIconColumnIndex={1}
                          expandable={{
                            expandIcon: ({ expanded, onExpand, record }) => {
                              if (record.children == undefined) {
                                return <span></span>;
                              } else {
                                return expanded ? (
                                  <UpOutlined
                                    onClick={(e) => {
                                      let element = e.target.parentNode.parentNode.parentNode;
                                      element.classList.remove("active");

                                      onExpand(record, e);
                                    }}
                                  />
                                ) : (
                                  <DownOutlined
                                    onClick={(e) => {
                                      let element = e.target.parentNode.parentNode.parentNode;
                                      element.classList.add("active");

                                      onExpand(record, e);
                                    }}
                                  />
                                );
                              }
                            }
                          }}
                        ></Table>
                      </div>
                    </div>
                  </div>

                </TabPanel>

                <TabPanel>
                  <div className="row full-keyword-table">
                    <div className="col-md-12 keyword-chart mt-3">
                      <div className="d-flex align-items-center px-3">
                        <div className="rankingtrends-column-select w-100">
                          <ReactMultiSelectCheckboxes className="d-flex align-items-center"
                            options={competitorsList}
                            onChange={(selected) => {
                              setCompetitorsSelected(selected);
                            }}
                            value={competitorsSelected}
                            hideSearch />
                        </div>
                        <button
                          className="btn btn-primary add-competitor me-2"
                          onClick={() => {
                            let rowDataKT = {
                              CompetitorIds: [],
                              RankingCampaignConfigurationId: props?.selectedCampaign,
                              RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                              ProjectId: localStorage.getItem("projectGId"),
                              Device: props?.deviceType,
                              Type: props?.rankingType,
                              DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                              },
                              CurrentPage: pageNumber4,
                              TablePageSize: tablePageSize4
                            }
                            getKeywordWiseDataForTabular(rowDataKT);
                          }}
                        >
                          Add Competitor
                        </button>
                        <div className="d-flex justify-content-between align-items-center">
                          <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                          </svg>
                          <button className="rank-export" onClick={() => {
                            let rowDataKT = {
                              CompetitorIds: [],
                              RankingCampaignConfigurationId: props?.selectedCampaign,
                              RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                              ProjectId: localStorage.getItem("projectGId"),
                              Device: props?.deviceType,
                              Type: props?.rankingType,
                              DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                              },
                            }
                            getKeywordWiseDataForTabularExport(rowDataKT);
                          }}>Export</button>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <Table
                          className="table-bordered rt-keyword-table ranking-table-design"
                          columns={keywordColumnTabular}
                          dataSource={keywordDataTabular}
                          pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} keywords`
                          }}
                          expandIconColumnIndex={1}
                          expandable={{
                            expandIcon: ({ expanded, onExpand, record }) => {
                              if (record.children == undefined) {
                                return <span></span>;
                              } else {
                                return expanded ? (
                                  <UpOutlined
                                    onClick={(e) => {
                                      let element = e.target.parentNode.parentNode.parentNode;
                                      element.classList.remove("active");

                                      onExpand(record, e);
                                    }}
                                  />
                                ) : (
                                  <DownOutlined
                                    onClick={(e) => {
                                      let element = e.target.parentNode.parentNode.parentNode;
                                      element.classList.add("active");

                                      onExpand(record, e);
                                    }}
                                  />
                                );
                              }
                            }
                          }}
                        ></Table>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              </Tabs>
            </>
          )
        }
      </div>
    </>
  );
}

export default RankingTrends;