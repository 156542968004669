import React, { useRef, useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';

const DoughnutChart3 = (props) => {
    const [series, setSeries] = useState(props.data);

    // useEffect(() => {
    //     const filteredSeries = props.data.filter((value) => value !== 0);
    //     setSeries(filteredSeries);
    // }, [props.data]);

    console.log(series, 'series');
    const totalValue = series.reduce((total, value) => total + value, 0); // Calculate the total value


    const [options, setOptions] = useState({
        chart: {
            type: 'donut',
            padding: '10px'
        },
        stroke: {
            width: 0,
        },
        labels: props.labels,
        tooltip: {
            style: {
                fontSize: '15px',
                fontWeight: 'bold',
                color: '#ffffff',
            },
        },
        title: {
            text: props.title,
            align: 'center',
            margin: 0,
            offsetX: 0,
            offsetY: 100,
            floating: true,
            style: {
                fontSize: '15px',
                fontFamily:'Montserrat , sans-serif',
                fontWeight: '500',
                color: '#757575',
                margin: '40px'
            },
        },
        responsive: [
            {
                // breakpoint: 200,
                options: {
                    dataLabels: {
                        enabled: false,
                    },
                    legend: {
                        show: false,
                        showForSingleSeries: false
                    }

                },
            },
        ],
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
                console.log(opts.w.config.series[opts.seriesIndex], 'actualValue')
                return val === 0 ? '' : opts.w.config.series[opts.seriesIndex]
            },
            style: {
                fontSize: '15px',
            },
            dropShadow: {
                enabled: false,
            },

            percentage: false
        },
        plotOptions: {
            stroke: {
                show: false,
                color: 'transparent',
            },
            pie: {
                donut: {
                    size: '40%',
                    labels: {
                        show: true,
                        name: {
                            show: false
                        },
                        total: {
                            show: true,
                            total: totalValue,
                            label: '',


                        },
                        value: {
                            offsetY: 2,
                            fontSize: '15px',
                            fontWeight: 'bold',
                        }
                    }
                },
            },
        },
        colors: ['#91e395', '#ffaa46'],


    });
    const tooltipRef = useRef(null);

    /* To Remove the Tooltip while exporting the pdf*/
    const handleMouseOver = () => {
        if (tooltipRef.current) {
            const tooltipElement = tooltipRef.current.querySelector('.apexcharts-tooltip-series-group');
            const toolTipStyleRemove = tooltipRef.current.querySelector('.apexcharts-tooltip');
            if (tooltipElement) {
                tooltipElement.classList.remove('apexcharts-active');
                toolTipStyleRemove.removeAttribute('style');
                let currentStyle = tooltipElement.getAttribute('style');
                const stylesToRemove = ['display: flex;', 'background-color: rgb(145, 227, 149);'];
                stylesToRemove.forEach(style => {
                    currentStyle = currentStyle.replace(style, '');
                });

                tooltipElement.setAttribute('style', currentStyle);

                const yLabelElement = tooltipRef.current.querySelectorAll('.apexcharts-tooltip-text-y-label');
                const yValueElement = tooltipRef.current.querySelectorAll('.apexcharts-tooltip-text-y-value')
                yLabelElement.forEach(element => {
                    element.innerHTML = ''; // Remove the text content
                });

                yValueElement.forEach(element => {
                    element.innerHTML = ''; // Remove the text content
                });
            }
        }
    };

    const handleMouseScroll = () => {
        if (tooltipRef.current) {
          const tooltipElement = tooltipRef.current.querySelector('.apexcharts-tooltip-series-group');
          const toolTipStyleRemove = tooltipRef.current.querySelector('.apexcharts-tooltip');
          if (tooltipElement) {
            tooltipElement.classList.remove('apexcharts-active');
            toolTipStyleRemove.removeAttribute('style');
            let currentStyle = tooltipElement.getAttribute('style');
            const stylesToRemove = ['display: flex;', 'background-color: rgb(145, 227, 149);'];
            stylesToRemove.forEach((style) => {
              currentStyle = currentStyle.replace(style, '');
            });
      
            tooltipElement.setAttribute('style', currentStyle);
      
            const yLabelElement = tooltipRef.current.querySelectorAll('.apexcharts-tooltip-text-y-label');
            const yValueElement = tooltipRef.current.querySelectorAll('.apexcharts-tooltip-text-y-value');
      
            yLabelElement.forEach((element) => {
              element.innerHTML = ''; // Remove the text content
            });
      
            yValueElement.forEach((element) => {
              element.innerHTML = ''; // Remove the text content
            });
          }
        }
      };
      

    useEffect(() => {
        if (tooltipRef.current) {
            tooltipRef.current.addEventListener('mouseover', handleMouseOver);
            tooltipRef.current.addEventListener('wheel', handleMouseScroll);

        }

        return () => {
            if (tooltipRef.current) {
                tooltipRef.current.addEventListener('mouseover', handleMouseOver);
                tooltipRef.current.removeEventListener('wheel', handleMouseScroll);
            }
        };
    }, []);

    return (
        <div ref={tooltipRef} id={props.id}>
            <ReactApexChart options={options} series={series} type="donut" width="100%" height="80%" />
            {/* <div className="chart-title">
                <div className="text-center">
                    <div className="d-flexx">
                        {props.title}
                    </div>
                </div>
            </div> */}
        </div>
    );
};

export default DoughnutChart3;
