import React, { useEffect, useState } from "react";
import { createOrganizations, getOrganizations } from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

const AddOrganization = () => {
  const [OrganizationsName, setOrganizationsName] = useState("");
  const [isAccActive, setIsActiveAcc] = useState(false);
  const [message, setMessage] = useState("");
  const [userOrg, setuserOrg] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");

  const history = useHistory();

  function createOrganization() {
    APIManager.postAPI(
      createOrganizations,
      {
        OrganizationName: OrganizationsName.trim(),
        status: isAccActive,
      },
      null,
      true
    ).then((response) => {
      if (response && response?.status == 200) {
        setOrganizationsName("");
        history.goBack();
        notifyAdd();
      }
    });
  }

  useEffect(() => {
    getOrganizationsList();
  }, []);

  function getOrganizationsList() {
    APIManager.getAPI(getOrganizations).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item, index) => {
          let obj = {
            value: item.organizationName,
            organizationname: item.organizationName,
            id: item.id,
            status: item.status,
          };
          list.push(obj);
        });
        setuserOrg(list);
      }
    });
  }

  function isValidOrganization() {
    if (OrganizationsName.trim() === "") {
      setMessage("Organization name is required!");
    } else if (OrganizationsName.trim() != "") {
      let isEqualItem = false;
      userOrg.map((i) => {
        if (i.organizationname == OrganizationsName.trim()) {
          isEqualItem = true;
        }
      });
      if (isEqualItem) {
        setMessage("Organization already exists!");
        return;
      } else {
        setMessage("");
        return true;
      }
    }
  }
  return (
    <>
      <div class="common-wrapper">
        <div class="common-wcard" style={{ background: "transparent" }}>
          <div class="common-form-fields align-items-center p-0">
            <div className="col-md-12 d-flex mb-3 p-0">
              <i
                class="fa fa-angle-left  back-arrow"
                onClick={() => {
                  history.goBack();
                }}
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div class="add-user portlet common-form-fields">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title"> Add Organization</h3>
        </div>
        <div className="portlet-body">
          <div class="col-md-12 col-sm-12">
            <label className="form-label">Organization Name</label>
            <label class="field-required"> * </label>

            <input
              className="form-control"
              type="text"
              style={{ borderRadius: 0 }}
              placeholder="Enter Organization Name"
              value={OrganizationsName}
              onChange={(text) => {
                setOrganizationsName(text.target.value);
              }}
            />
            <div className="field-required">
              <span>{message}</span>
            </div>
          </div>

          <div class="col-md-12 col-sm-12 mt-3">
            <label className="form-label">Status</label>
            <div class="col-sm-7">
              <label class="switch">
                <input
                  type="checkbox"
                  checked={isAccActive}
                  onClick={() => {
                    setIsActiveAcc(!isAccActive);
                  }}
                />
                <span class="slider round"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered">
          <div class="col-md-12 col-sm-12">
            <button
              className="btn btn-primary me-3"
              onClick={() => {
                if (isValidOrganization()) {
                  createOrganization();
                }
              }}
            >
              Save
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                history.goBack();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddOrganization;
