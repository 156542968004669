import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  avgOfArrayItems,
  fixTwoPlaceDecimal,
  formateDateIntoDatemonth,
  mmDDyyyyFormateDateSet,
  mmDDyyyyFormateForForm22,
  returnMagnitude,
  sumOfArrayItems,
  toMonthName,
  getClassName,
  compareValues,
  formatRankDate,
} from "../../utility/Globals";
import APIManager from "../../services/APIManager";
import {
  googleAnalyticsChart,
  googleAnalyticsSearch,
  googleAnalyticsSearchOS,
} from "../../utility/Constant";
import Chart from "react-apexcharts";
import { addDays } from "date-fns";

function Gachart(props) {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [compareendDate, setcompareendDate] = useState("");

  useEffect(() => {
    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    setcompareendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    );

    let chartData = {
      ComapareTo: 0,
      DATERANGE: 0,
      VIEW: 0,
      FILTER: ["Session"],
      DEVICETYPE: selectedDeviceType,
      PROJECTID: localStorage.getItem("projectGId"),
      GOOGLEANALYTICSDATERANGE: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.endDate
        ),
      },
      GoogleAnalyticsCompareToRange: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.endDate
        ),
      },
    };
    getChartDataCompare(chartData, 0);

    let getdata = {
      DATERANGE: 0,
      COMAPARETO: 0,
      DEVICETYPE: selectedDeviceType,
      PROJECTID: localStorage.getItem("projectGId"),
      GOOGLEANALYTICSDATERANGE: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.endDate
        ),
      },
      GOOGLEANALYTICSCOMPARETORANGE: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.endDate
        ),
      },
    };
    creategoogleAnalyticsSearchCompare(getdata);
    let getdata1 = {
      DATERANGE: 0,
      COMAPARETO: 0,
      DEVICETYPE: selectedDeviceType,
      PROJECTID: localStorage.getItem("projectGId"),
      GOOGLEANALYTICSDATERANGE: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.endDate
        ),
      },
      GOOGLEANALYTICSCOMPARETORANGE: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.endDate
        ),
      },
    };
    createorganicResearchSearchCompare(getdata1);

  }, [props?.dataParentToChild]);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  const [isChart, setIsChart] = useState(0);

  useEffect(() => {
    setIsChart(props?.IsSeoChart);
  }, [props?.IsSeoChart]);

  function handleClickChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }
  const [series, setSeries] = useState([]);
  const [selectedDeviceType, setDeviceType] = useState("0");
  const [selectedGaType, setGaType] = useState("0");
  const [GATable, setGATable] = useState([]);
  const [performanceMetricsCardList1, setCardListData] = useState([]);
  const [GACol, setGACol] = useState([]);
  const [GATableCompare, setGATableCompare] = useState([]);
  const [isDateRage, setIsDateRage] = useState(false);
  const [orperformanceMetricsCardList1, setORCardListData] = useState([]);
  const [ORCol, setORCol] = useState([]); //Organic search
  const [ORTableCompare, setORTableCompare] = useState([]); //Organic search
  const [ORTable, setORTable] = useState([]); //Organic search

  let titleText = "Days";
  const [options, setObject] = useState({
    legend: {
      show: true,
    },
    chart: {
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      stroke: {
        curve: "smooth",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: titleText,
        style: {
          color: "#000",
        },
      },
      labels: {
        formatter: function (val) {
          return toMonthName(val, titleText);
        },
      },
    },
    yaxis: {
      logBase: 5,
      tickAmount: 5,
      min: 0,
      labels: {
        formatter: function (val) {
          return returnMagnitude(val);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });
  function tableColGACompare() {
    var columns1 = [
      {
        title: "Default Channel",
        dataIndex: "defaultchannel",
        key: "defaultchannel",
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
      },
      {
        title: "New Users",
        dataIndex: "newusers",
        key: "newusers",
      },
      {
        title: "Bounce Rate",
        dataIndex: "bouncerate",
        key: "bouncerate",
      },
      {
        title: "Ecommerce Conversion Rate",
        dataIndex: "ecommerecerate",
        key: "ecommerecerate",
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
      },
    ];
    return columns1;
  }

  function getChartDataCompare(rowChartData, ChartView) {
    let titleText1 = "";
    if (ChartView == 0) {
      titleText1 = "Days";
    } else if (ChartView == 1) {
      titleText1 = "Weeks";
    } else if (ChartView == 2) {
      titleText1 = "Months";
    }
    let viewValue = ChartView;
    let listArrIds1 = [];
    let listArrIdsNum1 = [];
    let listArrIds2 = [];
    let listArrIdsNum2 = [];
    let keys1 = [];
    let keys2 = [];
    let values1 = [];
    let values2 = [];
    let fields1 = [];
    let fields2 = [];
    let graphData1 = [];
    let graphData2 = [];
    let seriesData1 = [];
    let seriesData2 = [];
    let seriesData = [];
    let dataForGraph = [];
    let option;
    APIManager.postAPI(googleAnalyticsChart, rowChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          response.data.tableContent.map((item) => {
            fields1.push({
              label: item.fields,
              value: item.fields,
            });
            keys1 = Object.keys(item);
            values1 = Object.values(item);
          });
          let i = 1;
          keys1.map((item, index) => {
            if (item != "fields") {
              listArrIds1.push(item);
              listArrIdsNum1.push(i);
              i++;
            }
          });
          response.data.tableContent.map((item) => {
            const startDate = rowChartData.GOOGLEANALYTICSDATERANGE.FROM;
            const endDate = rowChartData.GOOGLEANALYTICSDATERANGE.TO;

            let sdate = new Date(state.selection1.startDate);
            let eDate = new Date(state.selection1.endDate);

            if (viewValue == 0) {
              if (startDate == endDate) {
                listArrIds1.map((item1) => {
                  graphData1.push(item[item1] ? item[item1] : 0);
                  seriesData1.push(item1);
                });
              } else {
                const diffTime = Math.abs(
                  new Date(endDate) - new Date(startDate)
                );
                const diffDays = 0 | (diffTime / 864e5);
                if (diffDays) {
                  for (let i = 0; i <= diffDays; i++) {
                    const datedata = mmDDyyyyFormateDateSet(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    );
                    graphData1.push(item[datedata] ? item[datedata] : 0);
                    seriesData1.push(
                      formateDateIntoDatemonth(
                        new Date(new Date(startDate).getTime() + i * 864e5)
                      )
                    );
                  }
                }
              }
            } else if (viewValue == 1) {
              listArrIds1.map((item1) => {
                graphData1.push(item[item1] ? item[item1] : 0);
                seriesData1.push(item1);
              });
            } else if (viewValue == 2) {
              let sMonth = sdate.getMonth() + 1;
              let eMonth = eDate.getMonth() + 1;
              for (let month = sMonth; month <= eMonth; month++) {
                graphData1.push(item[month] ? item[month] : 0);
                seriesData1.push(month);
              }
            }
            dataForGraph.push({
              name: item.fields,
              data: graphData1,
            });
          });

          // Compare Table Content

          response.data.compareTableContent.map((item) => {
            fields2.push({
              label: item.fields,
              value: item.fields,
            });
            keys2 = Object.keys(item);
            values2 = Object.values(item);
          });
          let j = 1;
          keys2.map((item) => {
            if (item != "fields") {
              listArrIds2.push(item);
              listArrIdsNum2.push(j);
              j++;
            }
          });
          response.data.compareTableContent.map((item) => {
            const startDate = rowChartData.GoogleAnalyticsCompareToRange.FROM;
            const endDate = rowChartData.GoogleAnalyticsCompareToRange.TO;

            let sdate = new Date(state.selection2.startDate);
            let eDate = new Date(state.selection2.endDate);

            if (viewValue == 0) {
              if (startDate == endDate) {
                listArrIds2.map((item1) => {
                  graphData2.push(item[item1] ? item[item1] : 0);
                  seriesData2.push(item1);
                });
              } else {
                const diffTime = Math.abs(
                  new Date(endDate) - new Date(startDate)
                );
                const diffDays = 0 | (diffTime / 864e5);
                if (diffDays) {
                  for (let i = 0; i <= diffDays; i++) {
                    const datedata = mmDDyyyyFormateDateSet(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    );
                    graphData2.push(item[datedata] ? item[datedata] : 0);
                    seriesData2.push(
                      formateDateIntoDatemonth(
                        new Date(new Date(startDate).getTime() + i * 864e5)
                      )
                    );
                  }
                }
              }
            } else if (viewValue == 1) {
              listArrIds2.map((item1) => {
                graphData2.push(item[item1] ? item[item1] : 0);
                seriesData2.push(item1);
              });
            } else if (viewValue == 2) {
              let sMonth = sdate.getMonth() + 1;
              let eMonth = eDate.getMonth() + 1;
              for (let month = sMonth; month <= eMonth; month++) {
                graphData2.push(item[month] ? item[month] : 0);
                seriesData2.push(month);
              }
            }
            dataForGraph.push({
              name: item.fields,
              data: graphData2,
            });
          });
          let maxChartData;
          if (listArrIds1.length > listArrIds2.length) {
            if (ChartView == 0) {
              seriesData = listArrIdsNum1;
            } else {
              seriesData = seriesData1;
            }
          } else {
            if (ChartView == 0) {
              seriesData = listArrIdsNum2;
            } else {
              seriesData = seriesData2;
            }
          }
          if (sumOfArrayItems(graphData1) > sumOfArrayItems(graphData2)) {
            maxChartData =
              Math.max(...graphData1) * 0.3 + Math.max(...graphData1);
          } else {
            maxChartData =
              Math.max(...graphData2) * 0.3 + Math.max(...graphData2);
          }

          option = {
            chart: {
              id: "basic-bar",
            },
            xaxis: {
              tickPlacement: "on",
              categories: seriesData,
              title: {
                text: titleText1,
                style: {
                  color: "#000",
                },
              },
              labels: {
                formatter: function (val) {
                  if (ChartView == 0) {
                    return val;
                  } else {
                    return val;
                  }
                },
              },
            },
            yaxis: {
              logBase: 5,
              tickAmount: 5,
              min: 0,
              max: maxChartData,
              labels: {
                formatter: function (val) {
                  return returnMagnitude(val);
                },
              },
            },
            tooltip: {
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                let date = "-";
                const hoverXaxis =
                  w.globals.seriesX[seriesIndex][dataPointIndex];
                const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                  return seriesX.findIndex((xData) => xData === hoverXaxis);
                });

                let hoverList = "";
                hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                  if (seriesEachIndex == 0) {
                    if (viewValue == 0) {
                      seriesData1.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = item;
                        }
                      });
                    } else {
                      listArrIds1.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = toMonthName(item, titleText1);
                        }
                      });
                    }

                    if (
                      hoverIndex >= 0 &&
                      series[seriesEachIndex][hoverIndex] != undefined
                    ) {
                      hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  } else {
                    if (viewValue == 0) {
                      seriesData2.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = item;
                        }
                      });
                    } else {
                      listArrIds2.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = toMonthName(item, titleText1);
                        }
                      });
                    }

                    if (
                      hoverIndex >= 0 &&
                      series[seriesEachIndex][hoverIndex] != undefined
                    ) {
                      hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  }
                });
                const formatHoverX = hoverXaxis;

                return `<div class="card">
                      
                      <div class="card-body p-10">
                        ${hoverList}
                      </div>
                    </div>`;
              },
            },
            markers: {
              size: 5,
              colorField: ["#ba4636"],
            },
            dataLabels: { enabled: false },
            legend: { show: false },
          };
          setSeries("");
          setTimeout(() => {
            setSeries(dataForGraph);
          }, 100);
          setObject(option);
        }
      }
    );
  }
  function tableColGAOSCompare() {
    var columns1 = [
      {
        title: "Landing Pages",
        dataIndex: "landingpage",
        key: "landingpage",
        sorter: (a, b) => a.landingpage.localeCompare(b.landingpage),
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
      },
      {
        title: "New Users",
        dataIndex: "newusers",
        key: "newusers",
      },
      {
        title: "Bounce Rate",
        dataIndex: "bouncerate",
        key: "bouncerate",
      },

      {
        title: "Ecommerce Conversion Rate",
        dataIndex: "ecommerecerate",
        key: "ecommerecerate",
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
      },
    ];
    return columns1;
  }

  function creategoogleAnalyticsSearchCompare(rowdata) {
    APIManager.postAPI(googleAnalyticsSearch, rowdata, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          const list = [];
          const listCompareTableContent = [];
          const exportList = [];
          const compareexportList = [];
          let i = 0;

          let sessionMainList = [];
          let usersMainList = [];
          let newUsersMainList = [];
          let revenueMainList = [];
          let bounceRateMainList = [];
          let ecommerceConversionRateMainList = [];

          let sessionCompareLst = [];
          let usersCompareLst = [];
          let newUsersCompareLst = [];
          let revenueCompareLst = [];
          let bounceRateCompareLst = [];
          let ecommerceConversionRateCompareLst = [];

          response.data.tableContent.sort((a, b) =>
            a.channel.toLowerCase() > b.channel.toLowerCase() ? 1 : -1
          );
          response.data.tableContent.map((item) => {
            sessionMainList.push(item.session);
            usersMainList.push(item.users);
            newUsersMainList.push(item.newUsers);
            revenueMainList.push(item.revenue);
            bounceRateMainList.push(item.bounceRate);
            ecommerceConversionRateMainList.push(item.ecommerceConversionRate);

            let obj = {
              key: (i += 1),
              defaultchannel: item?.channel,
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            list.push(obj);
          });

          let list1 = [];
          let list2 = [];
          list.map((item1) => {
            if (item1.defaultchannel == "Organic Search") {
              list1.push(item1);
            } else {
              list2.push(item1);
            }
          });
          let list3 = list1.concat(list2);
          setGATable(list3);

          let objData = [];
          response.data.compareTableContent.sort((a, b) =>
            a.channel.toLowerCase() > b.channel.toLowerCase() ? 1 : -1
          );
          response.data.compareTableContent.map((item, index) => {
            sessionCompareLst.push(item.session);
            usersCompareLst.push(item.users);
            newUsersCompareLst.push(item.newUsers);
            revenueCompareLst.push(item.revenue);
            bounceRateCompareLst.push(item.bounceRate);
            ecommerceConversionRateCompareLst.push(
              item.ecommerceConversionRate
            );

            const filteredTableContent = Object.values(
              response?.data?.tableContent
            ).filter(
              (e) => e.channel.toLowerCase() == item.channel.toLowerCase()
            );

            let obj = {
              key: (i += 1),
              defaultchannel: item.channel,
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            listCompareTableContent.push(obj);

            let expObj = {
              key: (i += 1),
              defaultchannel: item.channel,
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            compareexportList.push(expObj);

            let obj1 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
              revenue: filteredTableContent[0]?.revenue,
            };
            listCompareTableContent.push(obj1);

            let expObj1 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
              revenue: filteredTableContent[0]?.revenue,
            };
            compareexportList.push(expObj1);

            let obj2 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            listCompareTableContent.push(obj2);
            objData[index] = [obj, obj1, obj2];

            let expObj2 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            compareexportList.push(expObj2);
          });

          let sessionAvg = sumOfArrayItems(sessionMainList);
          let sessionCompareAvg = sumOfArrayItems(sessionCompareLst);
          let usersAvg = sumOfArrayItems(usersMainList);
          let usersCompareAvg = sumOfArrayItems(usersCompareLst);
          let newUsersAvg = sumOfArrayItems(newUsersMainList);
          let newUsersCompareAvg = sumOfArrayItems(newUsersCompareLst);
          let revenueAvg = sumOfArrayItems(revenueMainList);
          let revenueCompareAvg = sumOfArrayItems(revenueCompareLst);
          let bounceRateAvg = avgOfArrayItems(bounceRateMainList);
          let bounceRateCompareAvg = avgOfArrayItems(bounceRateCompareLst);
          let ecommerceConversionRateAvg = sumOfArrayItems(
            ecommerceConversionRateMainList
          );
          let ecommerceConversionRateCompareAvg = sumOfArrayItems(
            ecommerceConversionRateCompareLst
          );

          let pSessionAvg =
            sessionAvg == 0 && sessionCompareAvg == 0
              ? 0
              : sessionAvg < sessionCompareAvg
                ? (100 * (sessionCompareAvg - sessionAvg)) / sessionCompareAvg
                : (100 * (sessionAvg - sessionCompareAvg)) / sessionAvg;

          let pUsersAvg =
            usersAvg == 0 && usersCompareAvg == 0
              ? 0
              : usersAvg < usersCompareAvg
                ? (100 * (usersCompareAvg - usersAvg)) / usersCompareAvg
                : (100 * (usersAvg - usersCompareAvg)) / usersAvg;

          let pNewUsersAvg =
            newUsersAvg == 0 && newUsersCompareAvg == 0
              ? 0
              : newUsersAvg < newUsersCompareAvg
                ? (100 * (newUsersCompareAvg - newUsersAvg)) / newUsersCompareAvg
                : (100 * (newUsersAvg - newUsersCompareAvg)) / newUsersAvg;

          let pRevenueAvg =
            revenueAvg == 0 && revenueCompareAvg == 0
              ? 0
              : revenueAvg < revenueCompareAvg
                ? (100 * (revenueCompareAvg - revenueAvg)) / revenueCompareAvg
                : (100 * (revenueAvg - revenueCompareAvg)) / revenueAvg;

          let pBounceRateAvg =
            bounceRateAvg == 0 && bounceRateCompareAvg == 0
              ? 0
              : bounceRateAvg < bounceRateCompareAvg
                ? (100 * (bounceRateCompareAvg - bounceRateAvg)) /
                bounceRateCompareAvg
                : (100 * (bounceRateAvg - bounceRateCompareAvg)) / bounceRateAvg;

          let pEcommerceConversionRateAvg =
            ecommerceConversionRateAvg == 0 &&
              ecommerceConversionRateCompareAvg == 0
              ? 0
              : ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg
                ? (100 *
                  (ecommerceConversionRateCompareAvg -
                    ecommerceConversionRateAvg)) /
                ecommerceConversionRateCompareAvg
                : (100 *
                  (ecommerceConversionRateAvg -
                    ecommerceConversionRateCompareAvg)) /
                ecommerceConversionRateAvg;

          let listData = [
            {
              avgData: pUsersAvg.toFixed(2),
              user: "Users",
              data: fixTwoPlaceDecimal(usersAvg),
              compareData: fixTwoPlaceDecimal(usersCompareAvg),
              isHighData: compareValues(usersAvg, usersCompareAvg),
            },
            {
              avgData: pNewUsersAvg.toFixed(2),
              user: "New Users",
              data: fixTwoPlaceDecimal(newUsersAvg),
              compareData: fixTwoPlaceDecimal(newUsersCompareAvg),
              isHighData: compareValues(newUsersAvg, newUsersCompareAvg),
            },
            {
              avgData: pSessionAvg.toFixed(2),
              user: "Sessions",
              data: fixTwoPlaceDecimal(sessionAvg),
              compareData: fixTwoPlaceDecimal(sessionCompareAvg),
              isHighData: compareValues(sessionAvg, sessionCompareAvg),
            },
            {
              avgData: pBounceRateAvg.toFixed(2),
              user: "Bounce Rate",
              data: fixTwoPlaceDecimal(bounceRateAvg) + "",
              compareData: fixTwoPlaceDecimal(bounceRateCompareAvg) + "",
              isHighData: compareValues(bounceRateAvg, bounceRateCompareAvg),
            },
          ];
          var columns1 = tableColGACompare();
          let newList = [];
          let index11, index22;
          columns1.map((item) => {
            newList.push(item);
          });

          if (
            ecommerceConversionRateAvg != 0 ||
            ecommerceConversionRateCompareAvg != 0
          ) {
            var obj = {
              avgData: pEcommerceConversionRateAvg.toFixed(2),
              user: "Ecommerce Conversion Rate",
              data: fixTwoPlaceDecimal(ecommerceConversionRateAvg),
              compareData: fixTwoPlaceDecimal(
                ecommerceConversionRateCompareAvg
              ),
              isHighData:
                ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg,
            };
            listData.push(obj);
          } else {
            columns1.map((item, index) => {
              if (item.dataIndex == "ecommerecerate") {
                index11 = index;
              }
            });
            delete newList[index11];

            compareexportList.forEach((object) => {
              delete object["ecommerecerate"];
            });
          }
          setCardListData(listData);
          setGACol(newList);
          let index1;
          let orlist = [];
          let orlist1 = [];

          objData.map((item, index) => {
            if (item[0].defaultchannel == "Organic Search") {
              index1 = index;
            }

            if (index == index1) {
              orlist.push(item);
            } else {
              orlist1.push(item);
            }
          });

          let mainList = orlist.concat(orlist1);

          let newObjData = [];
          let objListData = [];
          let childrenData = [];
          for (let i = 0; i < mainList.length; i++) {
            let newListData = mainList[i];
            let listKeys = Object.keys(newListData);
            let count = 0;
            childrenData = [];
            for (let j = 0; j < listKeys.length; j++) {
              if (parseInt(listKeys[j]) != 0) {
                childrenData[count] = {
                  key: newListData[listKeys[j]]["key"],
                  defaultchannel: newListData[listKeys[j]]["defaultchannel"],
                  sessions: newListData[listKeys[j]]["sessions"],
                  users: newListData[listKeys[j]]["users"],
                  newusers: newListData[listKeys[j]]["newusers"],
                  bouncerate: newListData[listKeys[j]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[j]]["ecommerecerate"],
                  revenue: newListData[listKeys[j]]["revenue"],
                };

                objListData = {
                  key: newListData[listKeys[0]]["key"],
                  defaultchannel: newListData[listKeys[0]]["defaultchannel"],
                  sessions: newListData[listKeys[0]]["sessions"],
                  users: newListData[listKeys[0]]["users"],
                  newusers: newListData[listKeys[0]]["newusers"],
                  bouncerate: newListData[listKeys[0]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[0]]["ecommerecerate"],
                  revenue: newListData[listKeys[0]]["revenue"],
                  children: childrenData,
                };

                count++;
              }
            }
            newObjData[i] = objListData;
          }
          setGATableCompare([]);
          setGATableCompare(newObjData);
        } else {
          setGATable([]);
          setGATableCompare([]);
        }
      }
    );
  }
  function tableColGA() {
    var columns1 = [
      {
        title: "Default Channel",
        dataIndex: "defaultchannel",
        key: "defaultchannel",
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
      },
      {
        title: "New Users",
        dataIndex: "newusers",
        key: "newusers",
      },
      {
        title: "Bounce Rate",
        dataIndex: "bouncerate",
        key: "bouncerate",
      },
      {
        title: "Ecommerce Conversion Rate",
        dataIndex: "ecommerecerate",
        key: "ecommerecerate",
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
      },
    ];
    return columns1;
  }

  function createorganicResearchSearchCompare(rowdata) {
    APIManager.postAPI(googleAnalyticsSearchOS, rowdata, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          const list = [];
          const listCompareTableContent = [];
          const compareorexportList = [];
          let j = 0;

          let sessionMainList = [];
          let usersMainList = [];
          let newUsersMainList = [];
          let revenueMainList = [];
          let bounceRateMainList = [];
          let ecommerceConversionRateMainList = [];

          let sessionCompareLst = [];
          let usersCompareLst = [];
          let newUsersCompareLst = [];
          let revenueCompareLst = [];
          let bounceRateCompareLst = [];
          let ecommerceConversionRateCompareLst = [];

          response.data.tableContent.sort((a, b) =>
            a.path.toLowerCase() > b.path.toLowerCase() ? 1 : -1
          );
          response.data.tableContent.map((item) => {
            sessionMainList.push(item.session);
            usersMainList.push(item.users);
            newUsersMainList.push(item.newUsers);
            revenueMainList.push(item.revenue);
            bounceRateMainList.push(item.bounceRate);
            ecommerceConversionRateMainList.push(item.ecommerceConversionRate);

            let obj = {
              key: (j += 1),
              landingpage: item?.path,
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            list.push(obj);
          });

          let objData = [];
          response.data.compareTableContent.sort((a, b) =>
            a.path.toLowerCase() > b.path.toLowerCase() ? 1 : -1
          );
          response.data.compareTableContent.map((item, index) => {
            sessionCompareLst.push(item.session);
            usersCompareLst.push(item.users);
            newUsersCompareLst.push(item.newUsers);
            revenueCompareLst.push(item.revenue);
            bounceRateCompareLst.push(item.bounceRate);
            ecommerceConversionRateCompareLst.push(
              item.ecommerceConversionRate
            );

            const filteredTableContent = Object.values(
              response?.data?.tableContent
            ).filter((e) => e.path.toLowerCase() == item.path.toLowerCase());

            let obj = {
              key: (j += 1),
              landingpage: item.path,
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            listCompareTableContent.push(obj);

            let expObj = {
              key: (j += 1),
              landingpage: item.path.toString().replace(",", " "),
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            compareorexportList.push(expObj);

            let obj1 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              revenue: filteredTableContent[0]?.revenue,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
            };
            listCompareTableContent.push(obj1);

            let expObj1 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              revenue: filteredTableContent[0]?.revenue,
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
            };
            compareorexportList.push(expObj1);

            let obj2 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              revenue: item?.revenue,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
            };
            listCompareTableContent.push(obj2);
            objData[index] = [obj, obj1, obj2];

            let expObj2 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              revenue: item?.revenue,
              ecommerecerate: item?.ecommerceConversionRate,
            };
            compareorexportList.push(expObj2);
          });

          let sessionAvg = sumOfArrayItems(sessionMainList);
          let sessionCompareAvg = sumOfArrayItems(sessionCompareLst);
          let usersAvg = sumOfArrayItems(usersMainList);
          let usersCompareAvg = sumOfArrayItems(usersCompareLst);
          let newUsersAvg = sumOfArrayItems(newUsersMainList);
          let newUsersCompareAvg = sumOfArrayItems(newUsersCompareLst);
          let revenueAvg = sumOfArrayItems(revenueMainList);
          let revenueCompareAvg = sumOfArrayItems(revenueCompareLst);
          let bounceRateAvg = avgOfArrayItems(bounceRateMainList);
          let bounceRateCompareAvg = avgOfArrayItems(bounceRateCompareLst);

          let ecommerceConversionRateAvg = sumOfArrayItems(
            ecommerceConversionRateMainList
          );
          let ecommerceConversionRateCompareAvg = sumOfArrayItems(
            ecommerceConversionRateCompareLst
          );

          let pSessionAvg =
            sessionAvg == 0 && sessionCompareAvg == 0
              ? 0
              : sessionAvg < sessionCompareAvg
                ? (100 * (sessionCompareAvg - sessionAvg)) / sessionCompareAvg
                : (100 * (sessionAvg - sessionCompareAvg)) / sessionAvg;

          let pUsersAvg =
            usersAvg == 0 && usersCompareAvg == 0
              ? 0
              : usersAvg < usersCompareAvg
                ? (100 * (usersCompareAvg - usersAvg)) / usersCompareAvg
                : (100 * (usersAvg - usersCompareAvg)) / usersAvg;

          let pNewUsersAvg =
            newUsersAvg == 0 && newUsersCompareAvg == 0
              ? 0
              : newUsersAvg < newUsersCompareAvg
                ? (100 * (newUsersCompareAvg - newUsersAvg)) / newUsersCompareAvg
                : (100 * (newUsersAvg - newUsersCompareAvg)) / newUsersAvg;

          let pRevenueAvg =
            revenueAvg == 0 && revenueCompareAvg == 0
              ? 0
              : revenueAvg < revenueCompareAvg
                ? (100 * (revenueCompareAvg - revenueAvg)) / revenueCompareAvg
                : (100 * (revenueAvg - revenueCompareAvg)) / revenueAvg;

          let pBounceRateAvg =
            bounceRateAvg == 0 && bounceRateCompareAvg == 0
              ? 0
              : bounceRateAvg < bounceRateCompareAvg
                ? (100 * (bounceRateCompareAvg - bounceRateAvg)) /
                bounceRateCompareAvg
                : (100 * (bounceRateAvg - bounceRateCompareAvg)) / bounceRateAvg;

          let pEcommerceConversionRateAvg =
            ecommerceConversionRateAvg == 0 &&
              ecommerceConversionRateCompareAvg == 0
              ? 0
              : ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg
                ? (100 *
                  (ecommerceConversionRateCompareAvg -
                    ecommerceConversionRateAvg)) /
                ecommerceConversionRateCompareAvg
                : (100 *
                  (ecommerceConversionRateAvg -
                    ecommerceConversionRateCompareAvg)) /
                ecommerceConversionRateAvg;

          let listData = [
            {
              avgData: pUsersAvg.toFixed(2),
              user: "Users",
              data: fixTwoPlaceDecimal(usersAvg),
              compareData: fixTwoPlaceDecimal(usersCompareAvg),
              isHighData: compareValues(usersAvg, usersCompareAvg),
            },
            {
              avgData: pNewUsersAvg.toFixed(2),
              user: "New Users",
              data: fixTwoPlaceDecimal(newUsersAvg),
              compareData: fixTwoPlaceDecimal(newUsersCompareAvg),
              isHighData: compareValues(newUsersAvg, newUsersCompareAvg),
            },
            {
              avgData: pSessionAvg.toFixed(2),
              user: "Sessions",
              data: fixTwoPlaceDecimal(sessionAvg),
              compareData: fixTwoPlaceDecimal(sessionCompareAvg),
              isHighData: compareValues(sessionAvg, sessionCompareAvg),
            },
            {
              avgData: pBounceRateAvg.toFixed(2),
              user: "Bounce Rate",
              data: bounceRateAvg.toFixed(2) + "",
              compareData: fixTwoPlaceDecimal(bounceRateCompareAvg) + "",
              isHighData: compareValues(bounceRateAvg, bounceRateCompareAvg),
            },
          ];
          var columns1 = tableColGAOSCompare();
          let newList = [];
          let index1, index2;
          columns1.map((item) => {
            newList.push(item);
          });

          if (
            ecommerceConversionRateAvg != 0 ||
            ecommerceConversionRateCompareAvg != 0
          ) {
            var obj = {
              avgData: pEcommerceConversionRateAvg.toFixed(2),
              user: "Ecommerce Conversion Rate",
              data: fixTwoPlaceDecimal(ecommerceConversionRateAvg),
              compareData: fixTwoPlaceDecimal(
                ecommerceConversionRateCompareAvg
              ),
              isHighData:
                ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg,
            };
            listData.push(obj);
          } else {
            columns1.map((item, index) => {
              if (item.dataIndex == "ecommerecerate") {
                index1 = index;
              }
            });
            delete newList[index1];
            compareorexportList.forEach((object) => {
              delete object["ecommerecerate"];
            });
          }
          if (revenueAvg != 0 || revenueCompareAvg != 0) {
            var obj = {
              avgData: pRevenueAvg.toFixed(2),
              user: "Revenue",
              data: fixTwoPlaceDecimal(revenueAvg),
              compareData: fixTwoPlaceDecimal(revenueCompareAvg),
              isHighData: revenueAvg < revenueCompareAvg,
            };
            listData.push(obj);
          } else {
            newList.map((item, index) => {
              if (item.dataIndex == "revenue") {
                index2 = index;
              }
            });
            delete newList[index2];

            compareorexportList.forEach((object) => {
              delete object["revenue"];
            });
          }
          setORCardListData(listData);
          setORCol(newList);

          let newORlistCompareTableContent = [];
          let indexForORCompareTable;
          objData.map((item, index) => {
            indexForORCompareTable = index;
            if (index == indexForORCompareTable) {
              newORlistCompareTableContent.push(item);
            } else if (index == indexForORCompareTable + 1) {
              newORlistCompareTableContent.push(item);
            } else if (index == indexForORCompareTable + 2) {
              newORlistCompareTableContent.push(item);
            }
          });

          let newORObjData = [];
          let objORListData = [];
          let ORChildrenData = [];
          for (let i = 0; i < newORlistCompareTableContent.length; i++) {
            let newListData = newORlistCompareTableContent[i];
            let listKeys = Object.keys(newListData);
            let count = 0;
            ORChildrenData = [];
            for (let j = 0; j < listKeys.length; j++) {
              if (parseInt(listKeys[j]) != 0) {
                ORChildrenData[count] = {
                  key: newListData[listKeys[j]]["key"],
                  landingpage: newListData[listKeys[j]]["landingpage"],
                  sessions: newListData[listKeys[j]]["sessions"],
                  users: newListData[listKeys[j]]["users"],
                  newusers: newListData[listKeys[j]]["newusers"],
                  bouncerate: newListData[listKeys[j]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[j]]["ecommerecerate"],
                  revenue: newListData[listKeys[j]]["revenue"],
                };

                objORListData = {
                  key: newListData[listKeys[0]]["key"],
                  landingpage: newListData[listKeys[0]]["landingpage"],
                  sessions: newListData[listKeys[0]]["sessions"],
                  users: newListData[listKeys[0]]["users"],
                  newusers: newListData[listKeys[0]]["newusers"],
                  bouncerate: newListData[listKeys[0]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[0]]["ecommerecerate"],
                  revenue: newListData[listKeys[0]]["revenue"],
                  children: ORChildrenData,
                };

                count++;
              }
            }
            newORObjData[i] = objORListData;
          }

          setORTableCompare(newORObjData);
        } else {
          setORTable([]);
          setORTableCompare([]);
        }
      }
    );
  }

  return (
    <>
      <div className="title pb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3">Traffic</h4>
          </div>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="">
              {isChart == 0 ? (
                <select
                  style={{ padding: 8 + "px" }}
                  className="form-select session-select w-100"
                  value={selectedGaType}
                  onChange={(e) => {
                    setGaType(e.target.value);

                    let chartData = {
                      ComapareTo: 0,
                      DATERANGE: 0,
                      VIEW: 0,
                      FILTER: [e.target.value],
                      DEVICETYPE: selectedDeviceType,
                      PROJECTID: localStorage.getItem("projectGId"),
                      GOOGLEANALYTICSDATERANGE: {
                        FROM: startDate,
                        TO: endDate,
                      },
                      GoogleAnalyticsCompareToRange: {
                        FROM: compareStartDate,
                        TO: compareendDate,
                      },
                    };

                    getChartDataCompare(chartData, 0);
                  }}
                >
                  <option value="Session">Sessions</option>
                  <option value="Users">Users</option>
                  <option value="NewUsers">New Users</option>
                  <option value="BounceRate">Bounce Rate</option>
                  <option value="Revenue">Revenue</option>
                  <option value="EcommerceConversionRate">
                    Ecommerce Conversation Rate
                  </option>
                </select>
              ) : (
                <></>
              )}
            </div>
            <div className="mx-2">
              <select
                style={{ padding: 8 + "px" }}
                className="form-select w-100"
                value={selectedDeviceType}
                onChange={(e) => {
                  setDeviceType(e.target.value);

                  let chartData = {
                    ComapareTo: 0,
                    DATERANGE: 0,
                    VIEW: 0,
                    FILTER: [selectedGaType],
                    DEVICETYPE: e.target.value,
                    PROJECTID: localStorage.getItem("projectGId"),
                    GOOGLEANALYTICSDATERANGE: {
                      FROM: startDate,
                      TO: endDate,
                    },
                    GoogleAnalyticsCompareToRange: {
                      FROM: compareStartDate,
                      TO: compareendDate,
                    },
                  };

                  getChartDataCompare(chartData, 0);

                  let getdata = {
                    DATERANGE: 0,
                    COMAPARETO: 0,
                    DEVICETYPE: e.target.value,
                    PROJECTID: localStorage.getItem("projectGId"),
                    GOOGLEANALYTICSDATERANGE: {
                      FROM: startDate,
                      TO: endDate,
                    },
                    GOOGLEANALYTICSCOMPARETORANGE: {
                      FROM: compareStartDate,
                      TO: compareendDate,
                    },
                  };
                  creategoogleAnalyticsSearchCompare(getdata);

                  let getdata1 = {
                    DATERANGE: 0,
                    COMAPARETO: 0,
                    DEVICETYPE: e.target.value,
                    PROJECTID: localStorage.getItem("projectGId"),
                    GOOGLEANALYTICSDATERANGE: {
                      FROM: startDate,
                      TO: endDate,
                    },
                    GOOGLEANALYTICSCOMPARETORANGE: {
                      FROM: compareStartDate,
                      TO: compareendDate,
                    },
                  };

                  createorganicResearchSearchCompare(getdata1);
                }}
              >
                <option value="0">Mobile</option>
                <option value="1">Desktop</option>
                <option value="2">All Devices</option>
              </select>
            </div>
            <div className="btn-box">
              <div className="d-flex align-items-center">
                <button
                  className={`${isChart == 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(0);
                  }}
                >
                  <img
                    src={`${isChart == 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isChart == 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(1);
                  }}
                >
                  <img
                    src={`${isChart == 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        {isChart == 0 ? (
          <>
            <Chart options={options} series={series} height="300" id="chartId" />
            <div className="legend-box d-flex align-items-center justify-content-center w-100 mt-4">
              <div className="d-flex align-items-center">
                <div className="legend-dot blue"></div>
                <div className="ml-2">{formatRankDate(startDate)}</div>
              </div>
              <div className="d-flex align-items-center ms-5">
                <div className="legend-dot"></div>
                <div className="ml-2">{formatRankDate(compareStartDate)}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-12 text-right backlink-daterange">
              Date Range : {formatRankDate(startDate)} -{" "}
              {formatRankDate(compareStartDate)}
            </div>
            <div class="container align-items-center">
              <div className="row html2pdf__page-break all-channel-table">
                <div className="d-flex  p-0 ga-allchannel">
                  <h4 className="ga-name ps-0 mt-3">All Channel</h4>
                  <div className="row allchannel-table">
                    {performanceMetricsCardList1.map((i) => {
                      return (
                        <div className="col-md-3 col-sm-6 m-0 p-0">
                          <div className="ga mt-3 me-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4>{i.user}</h4>
                              <div className="grren-domain text-right">
                                {i.isHighData != 1 ? (
                                  <>
                                    <svg
                                      width="35"
                                      height="34"
                                      viewBox="0 0 35 34"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="17.5"
                                        cy="17"
                                        r="17"
                                        fill="#D9FFDB"
                                      />
                                      <g clip-path="url(#clip0_4_4546)">
                                        <path
                                          d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                          stroke="#59D866"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M21.667 12H26.667V17"
                                          stroke="#59D866"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_4_4546">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                            transform="translate(7.5 7)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      width="34"
                                      height="34"
                                      viewBox="0 0 34 34"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        r="17"
                                        transform="matrix(1 0 0 -1 17 17)"
                                        fill="#FFC0C2"
                                      />
                                      <g clip-path="url(#clip0_4_4443)">
                                        <path
                                          d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                          stroke="#F64F53"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M21.167 22H26.167V17"
                                          stroke="#F64F53"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_4_4443">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                            transform="matrix(1 0 0 -1 7 27)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                )}
                              </div>
                            </div>

                            <h3 className={getClassName(i)}>
                              {i.avgData}
                              {isDateRage ? "" : "%"}
                            </h3>
                            <div className="row">
                              <div className="d-flex align-items-center justify-content-between p-0">
                                <div className="col-md-6 p-0">
                                  <p>{i.data}</p>
                                  <span>{formatRankDate(startDate)}</span>
                                </div>
                                <div className="col-md-6 roi-total-compare">
                                  <p>{i.compareData}</p>
                                  <span>
                                    {formatRankDate(compareStartDate)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>

              <div className="row  organic-table">
                <div className="d-flex  p-0 ga-organic">
                  <h4 className="ga-name ps-0 mt-3 ">Organic</h4>
                  <div className="row ga-organic-table">
                    {orperformanceMetricsCardList1.map((i) => {
                      return (
                        <div className="col-md-3 col-sm-6 m-0 p-0">
                          <div className=" ga mt-3 me-2">
                            <div className="d-flex align-items-center justify-content-between">
                              <h4>{i.user}</h4>
                              <div className="grren-domain text-right">
                                {i.isHighData != 1 ? (
                                  <>
                                    <svg
                                      width="35"
                                      height="34"
                                      viewBox="0 0 35 34"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        cx="17.5"
                                        cy="17"
                                        r="17"
                                        fill="#D9FFDB"
                                      />
                                      <g clip-path="url(#clip0_4_4546)">
                                        <path
                                          d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                          stroke="#59D866"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M21.667 12H26.667V17"
                                          stroke="#59D866"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_4_4546">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                            transform="translate(7.5 7)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                ) : (
                                  <>
                                    <svg
                                      width="34"
                                      height="34"
                                      viewBox="0 0 34 34"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <circle
                                        r="17"
                                        transform="matrix(1 0 0 -1 17 17)"
                                        fill="#FFC0C2"
                                      />
                                      <g clip-path="url(#clip0_4_4443)">
                                        <path
                                          d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                          stroke="#F64F53"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M21.167 22H26.167V17"
                                          stroke="#F64F53"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_4_4443">
                                          <rect
                                            width="20"
                                            height="20"
                                            fill="white"
                                            transform="matrix(1 0 0 -1 7 27)"
                                          />
                                        </clipPath>
                                      </defs>
                                    </svg>
                                  </>
                                )}
                              </div>
                            </div>

                            <h3 className={getClassName(i)}>
                              {i.avgData}
                              {isDateRage ? "" : "%"}
                            </h3>
                            <div className="row">
                              <div className="d-flex align-items-center justify-content-between p-0">
                                <div className="col-md-6 p-0">
                                  <p>{i.data}</p>
                                  <span>{formatRankDate(startDate)}</span>
                                </div>
                                <div className="col-md-6 roi-total-compare">
                                  <p>{i.compareData}</p>
                                  <span>
                                    {formatRankDate(compareStartDate)}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Gachart;
