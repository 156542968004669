import React from "react";
import { Table } from "antd";
import { useState } from "react";
import { useEffect } from "react";
import { Calendar } from "react-multi-date-picker";
import { Card } from "react-bootstrap";


function FreelancersRequirementDetails() {
    const [projectCols, setProjectCol] = useState([]);
    const [projectWorkList, setProjectWorkList] = useState([]);
    const [projectseleted, setprojectseleted] = useState({
        value: "",
        label: "",
    });
    const [projectList, setprojectList] = useState([]);
    const [open, setOpen] = useState(false);

    const [values, setValues] = useState([
        new Date(Date.now()).toLocaleDateString(),
        new Date(Date.now()).toLocaleDateString(),
    ]);

    useEffect(() => {
        let projectlist = [
            {
                id: 1,
                name: 'Myntra'
            },
            {
                id: 2,
                name: 'SEO'
            }
        ];
        setprojectList(projectlist);

        const columns = [
            {
                title: "ID",
                dataIndex: "id",
                key: "id",
                // width: "20%",
            },
            {
                title: "PROJECT",
                dataIndex: "project",
                key: "project",
                // width: "10%",
            },

            {
                title: "KEYWORD",
                dataIndex: "keyword",
                key: "keyword",
                // width: "10%",
            },
            {
                title: "COST",
                dataIndex: "cost",
                key: "cost",
                // width: "10%",
            },
            {
                title: "WORD COUNT",
                dataIndex: "word_count",
                key: "word_count",
                // width: "10%",
            },
            {
                title: "ETA",
                dataIndex: "eta",
                key: "eta",
                // width: "10%",
            },
            {
                title: "STATUS",
                dataIndex: "status",
                key: "status",
                // width: "10%",
            }

        ];
        setProjectCol(columns);
        const workList = [
            {
                id: '#0001',
                project: 'Myntra',
                keyword: 'shoes',
                cost: '15 / Word',
                word_count: '800 Words',
                eta: 'In 10 days',
                status: 'InProgress'
            },
            {
                id: '#0002',
                project: 'Myntra',
                keyword: 'women saree',
                cost: '10 / Word',
                word_count: '500 Words',
                eta: 'In 7 days',
                status: 'InProgress'
            }
        ];
        setProjectWorkList(workList);
    }, [])
    return (
        <>
            <div className="portlet work-history-cal ">
                <div className="work-hisory-select d-flex align-items-center justify-content-between">
                    <select
                        class=" form-select"
                        aria-label="Default select"
                        value={projectseleted}
                        onChange={(item) => {
                            if (item.target.value == "Select") {
                                setprojectseleted("");
                            } else {
                                setprojectseleted(item.target.value);
                            }
                        }}
                    >

                        {projectList?.map((item) => {

                            return <option value={item.id}>{item.name}</option>;
                        })}
                    </select>



                    <div className="d-flex align-items-center  justify-content-end require-cal">

                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-calendar"
                        >
                            <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                            <line x1="16" y1="2" x2="16" y2="6"></line>
                            <line x1="8" y1="2" x2="8" y2="6"></line>
                            <line x1="3" y1="10" x2="21" y2="10"></line>
                        </svg>
                        <div
                            className=""
                            onClick={() => setOpen(!open)}
                        >
                            January 2023
                        </div>
                        {open && (
                            <div className="scope-calendar">
                                <div id="example-collapse-text-calendar" style={{ right: '0' }}>
                                    <Card body className="daterange-picker-card  mt-2">
                                        <div className="d-flex justify-content-between">
                                            <div className="calendar-col">
                                                <Calendar
                                                    maxDate={new Date()}
                                                    onChange={(e) => {

                                                    }}
                                                    value={values}
                                                    onlyMonthPicker
                                                    range
                                                />
                                            </div>
                                            <div className="ms-3">
                                                <button
                                                    className="btn-outline btn btn-primary"
                                                    onClick={() => {
                                                        setOpen(false)
                                                    }}
                                                >
                                                    Apply
                                                </button>
                                            </div>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        )}
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                            <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                    </div>
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between p-0">
             
            <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-target"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="6"></circle>
                <circle cx="12" cy="12" r="2"></circle>
              </svg>
            </div>
            <div>
              <label className="form-label">Assigned</label>
              <h3>
               06
              </h3>
            </div>
          </div>

                <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3 ms-3">
                    <div className="raise-svg me-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#0691EF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-file-text"
                        >
                            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                            <polyline points="14 2 14 8 20 8"></polyline>
                            <line x1="16" y1="13" x2="8" y2="13"></line>
                            <line x1="16" y1="17" x2="8" y2="17"></line>
                            <polyline points="10 9 9 9 8 9"></polyline>
                        </svg>
                    </div>
                    <div>
                        <label className="form-label">Completed </label>
                        <h3>
                            06
                        </h3>
                    </div>
                </div>

                <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3 ms-3">
                    <div className="raise-svg me-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock">
                        <circle cx="12" cy="12" r="10"></circle>
                    <polyline points="12 6 12 12 16 14"></polyline>
                    </svg>
                    </div>
                    <div>
                        <label class="form-label">Awaiting Approval</label>
                        <h3>06</h3>
                    </div>
                </div>

              

                <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3 ms-3">
                    <div className="raise-svg me-4">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#0691EF"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-repeat"
                        >
                            <polyline points="17 1 21 5 17 9"></polyline>
                            <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                            <polyline points="7 23 3 19 7 15"></polyline>
                            <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                        </svg>
                    </div>
                    <div>
                        <label class="form-label">Rework</label>
                        <h3>06</h3>
                    </div>
                </div>
            </div>

            <div class="work-history-box px-0 my-4">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="px-0 work-history-search">
                        <form class="search-box w-100 d-flex">
                            <button type="button">
                                <i class="fa fa-search"></i>
                            </button>
                            <input
                                value=""
                                type="text"
                                class="w-100"
                                placeholder="Search..."
                                name="search"
                            />
                        </form>
                    </div>

                </div>
            </div>

            <div className="col-md-12 px-0">
                <Table
                    className="portlet table table-hover ticket-table view-all-tickets px-0   "
                    columns={projectCols}
                    dataSource={projectWorkList}
                />
            </div>

        </>

    )
}
export default FreelancersRequirementDetails;