import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import {
  createKeywords,
  createranktracking,
  createUrl,
  getAllCountry,
  getCompetitorsByProjectId,
  getKeyWordByProjectId,
  getRankTracking,
  getCreditRankingsData,
} from "../../utility/Constant";
import {
  deviceType,
  frequencyListKeyValue,
  frequencyListt,
  selectAllList,
  themeStyle,
} from "../../utility/Globals";
import toast from "react-hot-toast";

const RankTracking = ({ update }) => {

  const [keywordSelected, setkeywordSelected] = useState([]);
  const [competitorSelected, setcompetitorSelected] = useState([]);
  const [City, setCity] = useState("");
  const [urlByProject, seturlByProject] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [keywordLIST, setKEYWORDList] = useState([]);

  const [competitorsList, setCompetitorsList] = useState([]);
  const [frequncy, setfrequncy] = useState(frequencyListt[0].value);
  const [creditForRankings, setCreditForRankings] = useState(0);

  const [creditUsed, setCreditUsed] = useState(0);

  const [devicetype, setdevicetype] = useState(deviceType[0].value);
  const [isCityTrack, setIsCityTrack] = useState(false);
  const notifyUpdate = () => toast.success("Successfully Updated!");
  const [multicountryselectedList, setmulticountryselectedList] = useState([
    { Country: "", City: [] },
  ]);
  const [countryCityArray, setcountryCityArray] = useState([
    { Country: "", City: [] },
  ]);
  const [countryCount, setCountryCount] = useState(1);
  useEffect(() => {
    getKeywordlist();
    getCompetitorsList();
    getCountryList();
  }, []);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  function handleMultiCountrySelecctedAdd() {
    let list = [...multicountryselectedList];

    setmulticountryselectedList([
      ...multicountryselectedList,
      {
        Country: list[list?.length - 1]?.Country,
        City: countryCityArray[0]?.City[0]?.cityId,
      },
    ]);
    setcountryCityArray([
      ...countryCityArray,
      { Country: "", City: countryCityArray[0]?.City },
    ]);
    let value = countryCount + 1;
    setCountryCount(value);
    countCredit(keywordSelected, frequncy, devicetype, value);
  }

  function handleMultiCountrySelecctedRemove(index) {
    const list = [...multicountryselectedList];
    list.splice(index, 1);
    setmulticountryselectedList(list);
    let value = countryCount - 1;

    setCountryCount(value);
    countCredit(keywordSelected, frequncy, devicetype, value);
  }

  const handleCountryChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...multicountryselectedList];
    const optList = [...countryCityArray];
    list[index][name] = value;
    countryList.map((c) => {
      if (c.countryId == value) {
        optList[index]["City"] = c.cities;
      }
    });
    setcountryCityArray(optList);

    setmulticountryselectedList(list);
    setCountryCount(list.length);
  };
  const handleCityChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...multicountryselectedList];
    list[index][name] = value;
    setmulticountryselectedList(list);
  };

  const [isAddKeyword, setKeywordAdd] = useState(false);
  const [isAddCompetitors, setCompetitors] = useState(false);

  function getKeywordlist() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];

        list.push({
          count: 0,
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          keyword: "Select All",
        });
        let c = 1;
        const exportList = [];
        response.data.map((item) => {
          list.push({
            count: c,
            key: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            id: item.id,
            keyword: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => { }}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          });
          exportList.push({
            key: item.id,
            id: item.id,
            keyword: item.fieldValue,
          });
          c++;
        });
        setKEYWORDList(list);
      }
    });
  }

  function getCompetitorsList() {
    APIManager.getAPI(
      getCompetitorsByProjectId + localStorage.getItem("projectGId")
    ).then(
      (response) => {
        if (response && response.status == 200) {
          const exportList = [];
          let c = 1;
          const list = [];
          list.push({
            count: 0,
            key: "key",
            value: "Select All",
            label: "Select All",
            id: 0,
            keyword: "Select All",
          });
          response.data.map((item) => {
            let obj = {
              count: c,
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              comp: item.fieldValue,
              delete: (
                <div style={{ marginLeft: 24 + "px" }} onClick={() => { }}>
                  <i class="fa fa-trash"></i>
                </div>
              ),
            };
            c++;
            list.push(obj);
            let exportObj = {
              key: item.id,
              id: item.id,
              comp: item.fieldValue,
              delete: (
                <div style={{ marginLeft: 24 + "px" }} onClick={() => { }}>
                  <i class="fa fa-trash"></i>
                </div>
              ),
            };
            exportList.push(exportObj);
          });
          setCompetitorsList(list);
        }
      },
      (error) => { }
    );
  }

  function getCountryList() {
    APIManager.getAPI(
      getAllCountry + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        setCountryList(response.data);
        setCity(response.data[0]?.cities);
        const list = [...multicountryselectedList];
        list[0]["Country"] = response.data[0]?.countryId;
        list[0]["City"] =
          response.data[0]?.cities[0]?.cityId;
        setmulticountryselectedList(list);
        const optList = [...countryCityArray];
        optList[0]["Country"] = response.data[0]?.countryId;
        optList[0]["City"] = response.data[0]?.cities;
        setcountryCityArray(optList);
        setTimeout(() => {
          getCreditsDetails();
          getRanktrackData(response.data);
        }, 2000);
      }
    });
  }

  function getRanktrackData(countries) {
    APIManager.getAPI(
      getRankTracking + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        frequencyListKeyValue.map((item) => {
          if (item.value == response.data.rankTrackingConfiguration.frequency) {
            setfrequncy(item.label);
          }
        });

        setdevicetype(response.data.rankTrackingConfiguration.deviceType);
        setIsCityTrack(response.data?.rankTrackingConfiguration.isCityTrack);
        setCreditUsed(response.data?.creditUsed[0].creditUsed);

        let urlList = [];
        urlList.push({
          key: response.data.rankTrackingConfiguration.urlId,
          id: response.data.rankTrackingConfiguration.urlId,
          value: response.data.rankTrackingConfiguration.urls,
          label: response.data.rankTrackingConfiguration.urls,
        });
        seturlByProject(urlList);
        const complist = [];
        const keywordslist = [];
        response.data.rankTrackingConfiguration.rankTrackingCompetitorConfigurations.map(
          (item) => {
            complist.push({
              key: item.competitorId,
              id: item.competitorId,
              value: item.competitor,
              label: item.competitor,
            });
          }
        );
        setcompetitorSelected(complist);

        response.data.rankTrackingConfiguration.rankTrackingKeywordConfigurations.map(
          (item) => {
            keywordslist.push({
              key: item.keywordId,
              id: item.keywordId,
              value: item.keyword,
              label: item.keyword,
            });
          }
        );
        setkeywordSelected(keywordslist);

        let array = [];
        let optArray = [];
        if (
          response.data.rankTrackingConfiguration
            .rankTrackingLocationConfigurations?.length
        ) {
          setmulticountryselectedList([]);
          setcountryCityArray([]);
          response.data.rankTrackingConfiguration.rankTrackingLocationConfigurations?.map(
            (country, i) => {
              array.push({
                Country: country?.countryId,
                City: country?.rankTrackingCityConfigurations[0]?.cityId,
              });

              countries.map((c) => {
                if (country?.countryId == c.countryId) {
                  optArray.push({
                    Country: country?.countryId,
                    City: c?.cities,
                  });
                }
              });
            }
          );
          setcountryCityArray(optArray);
          setmulticountryselectedList(array);
        }
      }
    });
  }

  function addUpdateRankTrackingdata() {
    let keyword = [];
    let competitor = [];
    let countryConfig = [];

    if (isCityTrack) {
      multicountryselectedList?.map((data) => {
        countryConfig.push({
          CountryId: data.Country,
          rankTrackingCityConfigurations: [
            {
              CityId: data?.City,
            },
          ],
        });
      });
    } else {
      multicountryselectedList?.map((data) => {
        countryConfig.push({
          CountryId: data.Country,
        });
      });
    }
    keywordSelected.map((item) => {
      if (item.id != "0") {
        keyword.push({ keywordId: item.id });
      }
    });

    competitorSelected.map((item) => {
      if (item.id != "0") {
        competitor.push({ CompetitorId: item.id });
      }
    });
    APIManager.postAPI(
      createranktracking,
      {
        frequency: frequncy,
        deviceType: devicetype,
        isCityTrack: isCityTrack,
        rankTrackingLocationConfigurations: countryConfig,
        projectId: localStorage.getItem("projectGId"),
        RankTrackingKeywordConfigurations: keyword,
        RankTrackingCompetitorConfigurations: competitor,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        notifyUpdate();
        update("");
      }
    });
  }

  function countCredit(keyword, frequency, device, country) {
    let key1, key2;
    let length1 = keyword?.length;
    let length2 = keywordSelected?.length;
    keyword.map((item) => {
      if (item.value == "Select All") {
        key1 = length1 - 1;
      } else {
        key1 = length1;
      }
    });
    keywordSelected.map((item) => {
      if (item.value == "Select All") {
        key2 = length2 - 1;
      } else {
        key2 = length2;
      }
    });

    let frequncyCount;
    let frequncyCount1;
    let frequncyCount2;

    let keywordCount = keyword ? key1 : key2;
    let frequencySelection = frequency ? frequency : frequncy;
    let deviceSelection = device ? device : devicetype;
    let countrySelection1 = country ? country : countryCount;
    if (frequencySelection == "Daily") {
      frequncyCount = keywordCount * 31;
    } else if (frequencySelection == "Weekly") {
      frequncyCount = keywordCount * 4;
    } else if (frequencySelection == "Fornightly") {
      frequncyCount = keywordCount * 2;
    } else if (frequencySelection == "Monthly") {
      frequncyCount = keywordCount * 1;
    }

    if (deviceSelection == 0) {
      frequncyCount1 = frequncyCount * 1;
    } else if (deviceSelection == 1) {
      frequncyCount1 = frequncyCount * 1;
    } else if (deviceSelection == 2) {
      frequncyCount1 = frequncyCount * 2;
    }
    frequncyCount2 = frequncyCount1 * countrySelection1;

    setCreditUsed(frequncyCount2);
  }
  function getCreditsDetails() {
    APIManager.getAPI(
      getCreditRankingsData + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        setCreditForRankings(response.data?.value?.creditForRankings);
      }
    });
  }

  const [urlName, setURLName] = useState("");
  function addUrl() {
    APIManager.postAPI(
      createKeywords,
      {
        uckType: 2,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setURLName("");
        setKeywordAdd(false);
        getKeywordlist();
      }
    });
  }

  const [competitors, setCompetitorsData] = useState("");
  function addCompetitors() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 1,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: competitors,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setCompetitorsData("");
        getCompetitorsList();
        setCompetitors(false);
      }
    });
  }
  const history = useHistory();

  const [selectedAll2, setSelectedAll2] = useState(true);

  const [oldList, setOldList] = useState([]);

  return (
    <div className="custom-column-70 ">
      <div class="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
        <svg  onClick={() => update("")}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - Rankings
          </h3>
        </div>
        <div className="portlet-body">
          <div class="col-md-12 credit-remaining">
            <label className="form-label">Credits Remaining</label>
            <div class="progress">
              <div
                class="progress-bar progress-bar-primary"
                role="progressbar"
                aria-valuenow={(100 * creditUsed) / creditForRankings}
                aria-valuemin="0"
                aria-valuemax="100"
                id="file"
                value={(100 * creditUsed) / creditForRankings}
                max="100"
                style={{ width: (100 * creditUsed) / creditForRankings + "%" }}
              >
                {" "}
                {((100 * creditUsed) / creditForRankings).toFixed(2)} %
              </div>
            </div>
            <span>
              {creditUsed} of {creditForRankings}
            </span>
          </div>

          <div class="col-md-12 mt-3">
            <label className="form-label">Keywords</label>
            <div className="d-flex justify-content-between">
              <ReactSelect
                styles={themeStyle()}
                className="w-200 "
                options={keywordLIST}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={(selectedkeyword) => {
                  setkeywordSelected(
                    selectAllList(selectedkeyword, oldList, keywordLIST)
                  );
                  setOldList(selectedkeyword);
                  countCredit(
                    selectedkeyword,
                    frequncy,
                    devicetype,
                    countryCount
                  );
                }}
                allowSelectAll={true}
                value={keywordSelected}
              />
              <button
                className="border plus ms-3"
                style={{ background: "none" }}
                onClick={() => {
                  setKeywordAdd(true);
                }}
              >
                {" "}
                <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
              </button>
            </div>

            <div>
              {isAddKeyword ? (
                <div className="form-wrappers">
                  <label className="form-label"></label>
                  <div className="timecheck-icon d-flex">
                    <input
                      className="w-100"
                      type="text"
                      value={urlName}
                      placeholder="Value"
                      onChange={(text) => {
                        setURLName(text.target.value);
                      }}
                    />
                    <button
                      className="ms-3"
                      style={{ border: "none", background: "none" }}
                      onClick={(e) => {
                        setKeywordAdd(false);
                        setURLName("");
                      }}
                    >
                      {" "}
                      <i class="fa fa-times d-flex" aria-hidden="true"></i>{" "}
                    </button>

                    <button
                      style={{ border: "none", background: "none" }}
                      onClick={(e) => {
                        if (urlName) {
                          addUrl();
                        }
                      }}
                    >
                      {" "}
                      <i class="fa fa-check" aria-hidden="true"></i>{" "}
                    </button>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <label className="form-label">Competitors URLs</label>
            <div style={{ display: "flex" }}>
              <ReactSelect
                styles={themeStyle()}
                className="w-200"
                options={competitorsList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={(selectedCompetitor) => {
                  if (selectedCompetitor.length > 0) {
                    selectedCompetitor.map((i) => {
                      if (i.value == "Select All") {
                        if (selectedAll2) {
                          setcompetitorSelected(competitorsList);
                          setSelectedAll2(false);
                        } else {
                          setcompetitorSelected([]);
                          setSelectedAll2(true);
                        }
                      } else {
                        if (selectedAll2) {
                          setcompetitorSelected(selectedCompetitor);
                        } else {
                          setcompetitorSelected([]);
                          setSelectedAll2(true);
                        }
                      }
                    });
                  }
                }}
                allowSelectAll={true}
                value={competitorSelected}
              />
              <button
                className="border plus ms-3"
                style={{ background: "none" }}
                onClick={(e) => {
                  setCompetitors(true);
                }}
              >
                {" "}
                <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
              </button>
            </div>
            {isAddCompetitors ? (
              <div>
                <div class="form-wrappers">
                  <label className="form-label"></label>
                  <div className="timecheck-icon d-flex">
                    <input
                      className="w-100"
                      type="text"
                      value={competitors}
                      onChange={(text) => {
                        setCompetitorsData(text.target.value);
                      }}
                      placeholder="Value"
                    />
                    <button
                      style={{ border: "none", background: "none" }}
                      onClick={(e) => {
                        setCompetitorsData("");
                        setCompetitors(false);
                      }}
                    >
                      {" "}
                      <i
                        class="fa fa-times d-flex ms-3"
                        aria-hidden="true"
                      ></i>{" "}
                    </button>

                    <button
                      style={{ border: "none", background: "none" }}
                      onClick={(e) => {
                        if (competitorsList) {
                          addCompetitors();
                        }
                      }}
                    >
                      {" "}
                      <i class="fa fa-check" aria-hidden="true"></i>{" "}
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div class="col-md-12 mt-3">
            <label
              className="form-label"
              htmlFor=""
              style={{ marginRight: 0 + "px" }}
            >
              Set Frequency
            </label>
            <select
              style={{ padding: 8 + "px" }}
              value={frequncy}
              onChange={(e) => {
                var c = e.target.value;
                setfrequncy(c);
                countCredit(
                  keywordSelected,
                  c,
                  devicetype,
                  multicountryselectedList.length
                );
              }}
              class="w-100"
            >
              {frequencyListt.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>

          <div class="col-md-12 mt-3">
            <label
              className="form-label"
              htmlFor=""
              style={{ marginRight: 0 + "px" }}
            >
              Device Type
            </label>
            <br />
            <select
              style={{ padding: 8 + "px" }}
              onChange={(e) => {
                var c = e.target.value;
                setdevicetype(c);
                countCredit(
                  keywordSelected,
                  frequncy,
                  c,
                  multicountryselectedList.length
                );
              }}
              value={devicetype}
              class="w-100"
            >
              {deviceType.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>

          <div class="col-md-12 mt-3">
            <label
              className="form-label"
              htmlFor=""
              style={{ fontWeight: "bold", fontSize: "16px" }}
            >
              Location
            </label>
          </div>

          {countryCityArray?.length ? (
            multicountryselectedList.map((singleCountry, index) => (
              <div key={index}>
                <div class="row">
                  <div class="col-md-4">
                    <label
                      className="form-label"
                      htmlFor=""
                      style={{ marginRight: 0 + "px" }}
                    >
                      Country
                    </label>
                    <br />
                    <select
                      class="w-100 mb-3"
                      style={{ padding: 8 + "px" }}
                      name="Country"
                      value={singleCountry.Country}
                      onChange={(e) => {
                        handleCountryChange(e, index);
                      }}
                    >
                      {countryList?.map((item) => {
                        return (
                          <option value={item.countryId}>
                            {item.countryName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {isCityTrack ? (
                    <div class="col-md-4">
                      <label class="form-label w-100  text-start">City</label>
                      <select
                        class="w-100 mb-3"
                        style={{ padding: 8 + "px" }}
                        name="City"
                        value={singleCountry.City}
                        onChange={(e) => handleCityChange(e, index)}
                      >
                        {countryCityArray[index]?.City?.map((item) => {
                          return (
                            <option value={item.cityId}>{item.cityName}</option>
                          );
                        })}
                      </select>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div class="col-md-1 d-flex align-items-center mt-3">
                    {multicountryselectedList.length - 1 === index && (
                      <button
                        style={{ border: "none", background: "none" }}
                        onClick={handleMultiCountrySelecctedAdd}
                      >
                        {" "}
                        <i
                          class="fa fa-plus-circle"
                          aria-hidden="true"
                        ></i>{" "}
                      </button>
                    )}
                    {multicountryselectedList.length > 1 && (
                      <button
                        style={{ border: "none", background: "none" }}
                        onClick={() => handleMultiCountrySelecctedRemove(index)}
                      >
                        {" "}
                        <i
                          class="fa fa-minus-square"
                          aria-hidden="true"
                        ></i>{" "}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )}

          <div class="col-md-12 mt-3">
            <label className="form-label">Track City-Wise Ranking</label>
            <br></br>
            <input
              type="checkbox"
              checked={isCityTrack}
              onChange={(e) => {
                var chk = e.target.checked;
                setIsCityTrack(chk);
              }}
            />
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              class=" btn btn-primary"
              onClick={() => {
                addUpdateRankTrackingdata();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RankTracking;
