import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import APIManager from "../../services/APIManager";
import {
  updateRoleAccess,
  getRoleByDept,
  getRoleAccess,
  getDepartmentById,
  getMemberByRoleId,
  getUserAccess,
  useraccesslist,
  userpermission,
} from "../../utility/Constant";

const AccessPermissions = ({ update }) => {
  const notifyUpdate = () => toast.success("Successfully Updated!");

  const [selectedRole, setSelectedRole] = useState("");
  const [rolList, setRolList] = useState([]);
  const [organisationlist, setorganisationlist] = useState([]);
  const [projectsetuplist, setprojectsetuplist] = useState([]);
  const [clientsetuplist, setclientsetuplist] = useState([]);
  const [teamMemberList, setTeamMemberList] = useState([]);
  const [modulesList, setModulesList] = useState([]);
  const [researchList, setresearchList] = useState([]);
  const [projectsList, setProjectsList] = useState([]);
  const [projectConfigurationsList, setProjectConfigurationsList] = useState(
    []
  );
  const [projectId, setProjectId] = useState("");
  const [roleAccessList, setRoleAccessList] = useState(true);
  const history = useHistory();
  const [isAdvancedControlOpen, setIsAdvancedControlOpen] = useState(true);
  const [departmentList, setDepartmentList] = useState([]);
  const [departmentSelected, setDepartmentSelected] = useState("");
  const [memberlist, setmemberlist] = useState([]);
  const [memberlistSelected, setmemberlistSelected] = useState("");
  const [firstcolumnchecked, setfirstcolumnchecked] = useState(false);
  const [secondcolumnchecked, setsecondcolumnchecked] = useState(false);
  const [thirdcolumnchecked, setthirdcolumnchecked] = useState(false);
  const [forthcolumnchecked, setforthcolumnchecked] = useState(false);
  const [firstorganizationchecked, setfirstorganizationchecked] =
    useState(false);
  const [firstclientchecked, setfirstclientchecked] = useState(false);
  const [firstData, setFirstData] = useState([]);
  const [firstorganazationdata, setfirstorganazationdata] = useState([]);
  const [firstclientdata, setfirstclientdata] = useState([]);
  const [secondorganazationdata, setsecondorganazationdata] = useState([]);
  const [secondData, setSecondData] = useState([]);
  const [thirdData, setThirdData] = useState([]);
  const [forthData, setForthData] = useState([]);
  const [useraccess, setuseraccess] = useState([]);

  useEffect(() => {
    getDepartment();
  }, []);
  function changeRole(e) {
    let value = e.target.value;
    setSelectedRole(value);
    getRoleAccessData(value);
    setProjectId(value);
    getmembersData(value);
    setRoleAccessList([]);
  }

  function updateAccessPermissions() {
    const margeList = [
      ...organisationlist,
      ...firstclientdata,
      ...firstData,
      ...secondData,
      ...thirdData,
      ...forthData,
    ];
    APIManager.postAPI(
      updateRoleAccess,
      {
        roleId: projectId,
        RoleAccesses: margeList,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        notifyUpdate();

        update("");
      }
    });
  }

  function updateuserAccessPermissions() {
    const margeList =
      [...organisationlist,
      ...clientsetuplist,
      ...firstData,
      ...secondData,
      ...thirdData,
      ...forthData,]
    APIManager.postAPI(
      useraccesslist,
      {
        UserId: memberlistSelected,
        UserAccesses: margeList,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        notifyUpdate();
        update("");
      }
    });
  }

  function getRoleAccessData(id) {
    if (id != "select") {
      setorganisationlist([]);
      setfirstclientdata([]);
      setFirstData([]);
      setSecondData([]);
      setThirdData([]);
      setForthData([]);
      APIManager.getAPI(getRoleAccess + id).then((response) => {
        if (response && response.status == 200) {
          response.data.sort((a, b) => +a.srNo - +b.srNo);
          setRoleAccessList(response.data);
          if (response?.data && response?.data?.length != 0) {
            setRoleAccessList(true);
          } else {
            setRoleAccessList(false);
          }
          let list = response.data;
          let team_member = [];
          let modules = [];
          let projects = [];
          let project_configurations = [];
          let research = [];
          let organizationclient = [];
          let projectsetup = [];
          let clientsetup = [];

          let firstColumn = [];
          let firstorganazationColumn = [];
          let secondorganazationColumn = [];
          let secondColumn = [];
          let thirdColumn = [];
          let forthColumn = [];
          for (let i = 0; i < list.length; i++) {

            if (list[i].module == "organization_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo == 1 || list[i].srNo <= 6) {
                firstorganazationColumn.push(list[i]);
              }
              if (list[i].srNo >= 7 && list[i].srNo <= 11) {
                list[i]["isChecked"] = false;
                secondorganazationColumn.push(list[i]);
              }
              organizationclient.push(list[i]);
            } else if (list[i].module == "projects_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo == 1 || list[i].srNo == 2) {
                firstColumn.push(list[i]);
              }
              if (list[i].srNo > 2 && list[i].srNo < 10) {
                secondColumn.push(list[i]);
              }
              if (list[i].srNo >= 10 && list[i].srNo <= 17) {
                thirdColumn.push(list[i]);
              }
              if (list[i].srNo >= 18 && list[i].srNo <= 26) {
                forthColumn.push(list[i]);
              }
              firstColumn.sort((a, b) => +a.srNo - +b.srNo);
              secondColumn.sort((a, b) => +a.srNo - +b.srNo);
              thirdColumn.sort((a, b) => +a.srNo - +b.srNo);
              forthColumn.sort((a, b) => +a.srNo - +b.srNo);
            } else if (list[i].module == "clients_setup") {
              clientsetup.push(list[i]);
            }
          }
          setFirstData(firstColumn);
          setfirstcolumnchecked(firstColumn.every((x) => x.permission));
          setSecondData(secondColumn);
          setsecondcolumnchecked(secondColumn.every((x) => x.permission));
          setThirdData(thirdColumn);
          setthirdcolumnchecked(thirdColumn.every((x) => x.permission));
          setForthData(forthColumn);
          setfirstorganazationdata(firstorganazationColumn);
          setfirstclientdata(clientsetup);
          setfirstclientchecked(clientsetup.every((x) => x.permission));
          setsecondorganazationdata(secondorganazationColumn);
          setorganisationlist(organizationclient);
          setfirstorganizationchecked(
            organizationclient.every((x) => x.permission)
          );
          setprojectsetuplist(projectsetup);
          setclientsetuplist(clientsetup);
          setTeamMemberList(team_member);
          setModulesList(modules);
          setProjectsList(projects);
          setProjectConfigurationsList(project_configurations);
          setresearchList(research);
        }
      });
    }
  }

  function getRolesData(departmentId) {
    APIManager.getAPI(getRoleByDept + departmentId).then((response) => {
      if (response && response.status == 200) {
        if (response?.data.length > 0) {
          setRolList(response?.data);
          setSelectedRole(response?.data[0].id);
          getRoleAccessData(response?.data[0].id);
          setProjectId(response?.data[0].id);
          getmembersData(response?.data[0].id);
        } else {
          setRolList([]);
          setSelectedRole("");
          setmemberlist([]);
          setmemberlistSelected("");
        }
      }
    });
  }

  function getDepartment() {
    APIManager.getAPI(
      getDepartmentById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response.data.length > 0) {
          if (response?.data) {
            setDepartmentList(response.data);
          }
          setDepartmentSelected(response.data[0].id);
          getRolesData(response.data[0].id);
        } else {
          setDepartmentList([]);
          setDepartmentSelected("");
          setmemberlist([]);
          setmemberlistSelected("");
        }
      }
    });
  }

  function getmembersData(roleId) {
    APIManager.getAPI(getMemberByRoleId + roleId).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "select",
          name: "Select",
        });
        if (response.data.length > 0) {
          response.data.map((item) => {
            let obj = {
              id: item.id,
              name: item.name,
            };
            list.push(obj);
          });
          setmemberlist(list);
          setmemberlistSelected(list[0].id);
        } else {
          setmemberlist([]);
          setmemberlistSelected("");
        }
      }
    });
  }

  function getmemberuseraccess(userid) {
    if (userid != "select") {
      setorganisationlist([]);
      setfirstclientdata([]);
      setFirstData([]);
      setSecondData([]);
      setThirdData([]);
      setForthData([]);

      APIManager.getAPI(getUserAccess + userid).then((response) => {
        if (response && response.status == 200) {
          const userlist = [];
          response.data.map((item) => {
            let obj = {
              userId: item.userId,
              permission: item.permission,
              accessId: item.accessId,
            };
            userlist.push(obj);
          });
          setuseraccess(userlist);
        }

        if (response && response.status == 200) {
          response.data.sort((a, b) => +a.srNo - +b.srNo);
          setRoleAccessList(response.data);
          if (response?.data && response?.data?.length != 0) {
            setRoleAccessList(true);
          } else {
            setRoleAccessList(false);
          }
          let list = response.data;
          let team_member = [];
          let modules = [];
          let projects = [];
          let project_configurations = [];
          let research = [];
          let organizationclient = [];
          let projectsetup = [];
          let clientsetup = [];

          let firstColumn = [];
          let firstorganazationColumn = [];
          let secondorganazationColumn = [];
          let secondColumn = [];
          let thirdColumn = [];
          let forthColumn = [];
          for (let i = 0; i < list.length; i++) {

            if (list[i].module == "organization_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo == 1 || list[i].srNo <= 6) {
                firstorganazationColumn.push(list[i]);
              }
              if (list[i].srNo >= 7 && list[i].srNo <= 10) {
                list[i]["isChecked"] = false;
                secondorganazationColumn.push(list[i]);
              }
              organizationclient.push(list[i]);
            } else if (list[i].module == "projects_setup") {
              list[i].srNo = +list[i].srNo;
              if (list[i].srNo == 1 || list[i].srNo == 2) {
                firstColumn.push(list[i]);
              }
              if (list[i].srNo > 2 && list[i].srNo < 10) {
                secondColumn.push(list[i]);
              }
              if (list[i].srNo >= 10 && list[i].srNo <= 16) {
                thirdColumn.push(list[i]);
              }
              if (list[i].srNo >= 17 && list[i].srNo <= 24) {
                forthColumn.push(list[i]);
              }
              firstColumn.sort((a, b) => +a.srNo - +b.srNo);
              secondColumn.sort((a, b) => +a.srNo - +b.srNo);
              thirdColumn.sort((a, b) => +a.srNo - +b.srNo);
              forthColumn.sort((a, b) => +a.srNo - +b.srNo);
            } else if (list[i].module == "clients_setup") {
              clientsetup.push(list[i]);
            }
          }
          setFirstData(firstColumn);
          setfirstcolumnchecked(firstColumn.every((x) => x.permission));
          setSecondData(secondColumn);
          setsecondcolumnchecked(secondColumn.every((x) => x.permission));
          setThirdData(thirdColumn);
          setthirdcolumnchecked(thirdColumn.every((x) => x.permission));
          setForthData(forthColumn);
          setfirstorganazationdata(firstorganazationColumn);
          setfirstclientdata(clientsetup);
          setfirstclientchecked(clientsetup.every((x) => x.permission));
          setsecondorganazationdata(secondorganazationColumn);
          setorganisationlist(organizationclient);
          setfirstorganizationchecked(
            organizationclient.every((x) => x.permission)
          );
          setprojectsetuplist(projectsetup);
          setclientsetuplist(clientsetup);
          setTeamMemberList(team_member);
          setModulesList(modules);
          setProjectsList(projects);
          setProjectConfigurationsList(project_configurations);
          setresearchList(research);
        }
      });
    }
  }

  async function userselected(userid) {
    try {
      const response = await APIManager.deleteAPI(userpermission + userid);
      if (response == 200) {
        setmemberlistSelected("select");
        setIsAdvancedControlOpen(true);
        getDepartment();
      }
    } catch (e) { }
  }

  const handleOnChange = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action == "allSecondColSelected") {
      list.map((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      setsecondcolumnchecked(isChecked);
    } else {
      list.map((p, n) => {
        if (n == i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setsecondcolumnchecked(updatedList.every((x) => x.permission));
    setSecondData(updatedList);
    setfirstcolumnchecked(
      firstData.every((x) => x.permission) &&
      updatedList.every((x) => x.permission) &&
      thirdData.every((x) => x.permission) &&
      forthData.every((x) => x.permission)
    );
  };

  const handleOnChangeALLclients = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action == "allColSelecteddd") {
      let forthList = [];
      list.map((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      setfirstclientchecked(isChecked);
    } else {
      list.map((p, n) => {
        if (n == i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setfirstclientchecked(updatedList.every((x) => x.permission));
    setfirstorganazationdata(updatedList);
  };
  const handleOnChangeALLorganazation = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action == "allColSelectedd") {
      list.map((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      setfirstorganizationchecked(isChecked);
    } else {
      list.map((p, n) => {
        if (n == i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setfirstorganizationchecked(updatedList.every((x) => x.permission));
    setorganisationlist(updatedList);
  };
  const handleOnChangeALL = (list, name, i, isChecked) => {
    let updatedList = [];
    if (name.action == "allColSelected") {
      let forthList = [];
      list.map((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      forthData?.map((p) => {
        p["permission"] = isChecked;
        forthList.push(p);
      });
      setForthData(forthList);
      setfirstcolumnchecked(isChecked);
      handleOnChange(
        secondData,
        {
          action: "allSecondColSelected",
          displayName: "All Modules",
        },
        0,
        isChecked
      );
      handleOnChangeThird(
        thirdData,
        {
          action: "allThirdColSelected",
          displayName: "All Modules",
        },
        0,
        isChecked
      );
    } else {
      list.map((p, n) => {
        if (n == i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });
    }
    setfirstcolumnchecked(updatedList.every((x) => x.permission));
    setFirstData(updatedList);
  };
  const handleOnChangeThird = (list, name, i, isChecked) => {
    let updatedList = [];
    let fourthLsit = forthData;
    if (name.action == "allThirdColSelected") {
      fourthLsit = [];
      list.map((p) => {
        p["permission"] = isChecked;
        updatedList.push(p);
      });
      setthirdcolumnchecked(isChecked);
      forthData.map((p) => {
        p["permission"] = isChecked;
        fourthLsit.push(p);
        // return p;
      });
      // fourthLsit
    } else {
      list.map((p, n) => {
        if (n == i) {
          p["permission"] = !p["permission"];
        }
        updatedList.push(p);
      });

    }
    setthirdcolumnchecked(updatedList.every((x) => x.permission) && fourthLsit.every((x) => x.permission));
    setThirdData(updatedList);
    // setForthData()
    setfirstcolumnchecked(
      firstData.every((x) => x.permission) &&
      secondData.every((x) => x.permission) &&
      updatedList.every((x) => x.permission) &&
      forthData.every((x) => x.permission)
    );
  };

  return (
    <div className="custom-column-70 ModuleExpandDesignation">
      {roleAccessList ? (
        <div className="portlet mt-3 slideInUp setting-top">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("organizationName")} - Access Permission
            </h3>
          </div>
          <div className="portlet-body">
            <div className="common-form-fields select-role mt-2 ">
              <div className="row border-bottom">
                <div className="col-md-6 p-0">
                  <label className="selectrole">Select Role</label>
                </div>

                {departmentSelected == "Select Department" ? (
                  <></>
                ) : (
                  <div
                    className="col-md-6 text-end"
                    onClick={() => {
                      setIsAdvancedControlOpen(!isAdvancedControlOpen);
                    }}
                  >
                    <a className="advance-closeopen">
                      {isAdvancedControlOpen
                        ? "Advanced Control"
                        : "Close Advanced Control"}
                    </a>
                  </div>
                )}
              </div>

              <div className="row role-depart-designation mb-4 ">
                <div className="col-md-6 ps-0">
                  <div className="row align-items-center">
                    <div className="col-md-4">
                      <label className="depart mb-0">Department</label>
                    </div>
                    <div className="col-md-8 ps-0">
                      <select className="form-select"
                        name="depart"
                        value={departmentSelected}
                        onChange={(e) => {
                          setDepartmentSelected(e.target.value);
                          getRolesData(e.target.value);
                        }}
                      >
                        {departmentList.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="row align-items-center ">
                    <div className="col-md-4">
                      <label className="design mb-0">Designation</label>
                    </div>
                    <div className="col-md-8 pe-0">
                      <select
                        className="form-select accesspermission"
                        value={selectedRole}
                        onChange={changeRole}
                      >
                        {rolList.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {isAdvancedControlOpen ? (
                <></>
              ) : (
                <div className="row role-depart-designation mt-4 mb-4">
                  <div className="col-md-6 ps-0 ms-0">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <label className="access-team-member mb-0">
                          Team Member
                        </label>
                      </div>
                      <div className="col-md-8 ps-0">
                        <select
                          className="form-select "
                          name="depart"
                          value={memberlistSelected}
                          onChange={(e) => {
                            setmemberlistSelected(e.target.value);
                            getmemberuseraccess(e.target.value);
                          }}
                        >
                          {memberlist.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6  my-2 ps-3">
                    <a className="revoke-user-permission ms-2" style={memberlistSelected == "" || memberlistSelected == "select" ? { display: "none" } : { display: "block" }}
                      onClick={() => {
                        userselected(memberlistSelected);
                      }}
                    >Reset User Permission
                    </a>
                  </div>
                </div>
              )}
            </div>
            {departmentSelected == "Select Department" ? (
              <></>
            ) : (
              <>
                <div className="border selectpermission mt-4">
                  <div className="common-form-fields border-bottom  mt-3 p-2">
                    <div className="col-md-12 ms-2">
                      <label className="select-pemission">
                        Select Permission
                      </label>
                    </div>
                  </div>

                  <div className="designation-box">
                    <div className="row px-1 py-4">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="mt-0">
                            <div className="col-md-12 px-0">
                              <label className="accord-organization">
                                Organization
                              </label>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0 row mt-3">
                            <div className="col-md-3">
                              <label className="d-flex">
                                <input
                                  className="me-2"
                                  style={{ madginRight: "10px" }}
                                  type="checkbox"
                                  checked={firstorganizationchecked}
                                  onChange={(e) => {
                                    handleOnChangeALLorganazation(
                                      organisationlist,
                                      {
                                        action: "allColSelectedd",
                                        displayName: "All",
                                      },
                                      0,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span> All</span>
                              </label>
                              {organisationlist.map((item, i) => {
                                return i < 6 ? (
                                  <>
                                    <label className="d-flex">
                                      <input
                                        className="me-2"
                                        defaultChecked={item.permission}
                                        style={{ madginRight: "10px" }}
                                        type="checkbox"
                                        checked={item.permission}
                                        onChange={(e) => {
                                          handleOnChangeALLorganazation(
                                            organisationlist,
                                            item,
                                            i,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <span>{item.displayName}</span>
                                    </label>
                                  </>
                                ) : (
                                  <></>
                                );
                              })}
                            </div>
                            <div className="col-md-3">
                              {organisationlist.map((item, i) => {
                                return i >= 6 ? (
                                  <>
                                    <label className="d-flex">
                                      <input
                                        className="me-2"
                                        defaultChecked={item.permission}
                                        style={{ madginRight: "10px" }}
                                        type="checkbox"
                                        checked={item.permission}
                                        onChange={(e) => {
                                          handleOnChangeALLorganazation(
                                            organisationlist,
                                            item,
                                            i,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                      <span>{item.displayName}</span>
                                    </label>
                                  </>
                                ) : (
                                  <></>
                                );
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion className="mt-3">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="mt-0">
                            <div className="col-md-12 px-0">
                              <label className="accord-client">Clients</label>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0 row mt-3">
                            <div className="col-md-3">
                              <label className="d-flex">
                                <input
                                  className="me-2"
                                  style={{ madginRight: "10px" }}
                                  type="checkbox"
                                  checked={firstclientchecked}
                                  onChange={(e) => {
                                    handleOnChangeALLclients(
                                      clientsetuplist,
                                      {
                                        action: "allColSelecteddd",
                                        displayName: "All",
                                      },
                                      0,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span> All</span>
                              </label>
                              {clientsetuplist.map((item, i) => {
                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                      onChange={(e) => {
                                        handleOnChangeALLclients(
                                          clientsetuplist,
                                          item,
                                          i,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span>{item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <Accordion className="mt-3">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header className="mt-0">
                            <div className="col-md-12 px-0">
                              <label className="accord-projects">
                                Projects
                              </label>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="p-0 row mt-3">
                            <div className="col-md-3">
                              <label className="d-flex">
                                <input
                                  className="me-2"
                                  style={{ madginRight: "10px" }}
                                  type="checkbox"
                                  checked={firstcolumnchecked}
                                  onChange={(e) => {
                                    handleOnChangeALL(
                                      firstData,
                                      {
                                        action: "allColSelected",
                                        displayName: "All",
                                      },
                                      0,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span> All</span>
                              </label>
                              {firstData.map((item, i) => {
                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                      onChange={(e) => {
                                        handleOnChangeALL(
                                          firstData,
                                          item,
                                          i,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span> {item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                            <div className="col-md-3">
                              <label className="d-flex">
                                <input
                                  className="me-2"
                                  style={{ madginRight: "10px" }}
                                  type="checkbox"
                                  checked={secondcolumnchecked}
                                  onChange={(e) => {
                                    handleOnChange(
                                      secondData,
                                      {
                                        action: "allSecondColSelected",
                                        displayName: "All Modules",
                                      },
                                      0,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span> Complete Setup</span>
                              </label>
                              {secondData.map((item, index) => {
                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                      onChange={(e) => {
                                        handleOnChange(
                                          secondData,
                                          item,
                                          index,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span>{item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                            <div className="col-md-3">
                              <label className="d-flex">
                                <input
                                  className="me-2"
                                  style={{ madginRight: "10px" }}
                                  type="checkbox"
                                  checked={thirdcolumnchecked}
                                  onChange={(e) => {
                                    handleOnChangeThird(
                                      thirdData,
                                      {
                                        action: "allThirdColSelected",
                                        displayName: "All Modules",
                                      },
                                      0,
                                      e.target.checked
                                    );
                                  }}
                                />
                                <span>All Modules</span>
                              </label>
                              {thirdData.map((item, index) => {

                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                      onChange={(e) => {
                                        handleOnChangeThird(
                                          thirdData,
                                          item,
                                          index,
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span>{item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                            <div className="col-md-3">
                              {forthData.map((item, i) => {
                                return (
                                  <label className="d-flex">
                                    <input
                                      className="me-2"
                                      defaultChecked={item.permission}
                                      onChange={(e) => {
                                        let updatedList = [];
                                        forthData.map((p, n) => {
                                          if (n == i) {
                                            p["permission"] = !p["permission"];
                                          }
                                          updatedList.push(p);
                                        });
                                        setthirdcolumnchecked(updatedList.every((x) => x.permission) && thirdData.every((x) => x.permission));
                                        setForthData(updatedList);
                                        setforthcolumnchecked(
                                          updatedList.every((x) => x.permission)
                                        );
                                        setfirstcolumnchecked(
                                          firstData.every(
                                            (x) => x.permission
                                          ) &&
                                          secondData.every(
                                            (x) => x.permission
                                          ) &&
                                          thirdData.every(
                                            (x) => x.permission
                                          ) &&
                                          updatedList.every(
                                            (x) => x.permission
                                          )
                                        );
                                      }}
                                      style={{ madginRight: "10px" }}
                                      type="checkbox"
                                      checked={item.permission}
                                    />
                                    <span> {item.displayName}</span>
                                  </label>
                                );
                              })}
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                      <div className="col-md-2"></div>
                      <div className="col-md-2"></div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="portlet-footer portlet-footer-bordered mb-3">
            <div className="col-md-12 ">
              <button
                className="btn btn-primary"
                onClick={() => {
                  if (
                    memberlistSelected != "select" &&
                    isAdvancedControlOpen == false &&
                    memberlistSelected != "" &&
                    memberlistSelected != null
                  ) {
                    updateuserAccessPermissions();
                  } else {
                    updateAccessPermissions();
                  }
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div> NO ACCESS PERMISSIONS </div>
      )}
    </div>
  );
};

export default AccessPermissions;
