import moment from "moment";
import { Tag, message } from "antd";
import 'antd/dist/antd.css';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { savePDF } from "@progress/kendo-react-pdf";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { GET_FrontEnd_URL, GET_Server_URL } from "./Constant";
import { addDays } from "date-fns"; 

export const CompetitionLevel = {
  0: "Unspecified",
  1: "Unknown",
  2: "Low",
  3: "Medium",
  4: "High",
};

export function setDateFilter(type) {
  if (type === "Custom") {
    return 0;
  } else if (type === "Last7Days") {
    return 1;
  } else if (type === "Last28Days") {
    return 2;
  } else if (type === "Last3Months") {
    return 3;
  }
}

export function setViews(type) {
  if (type === "Day") {
    return 0;
  } else if (type === "Week") {
    return 1;
  } else if (type === "Month") {
    return 2;
  }
}

export function setFilters(type) {
  if (type === "Session") {
    return 0;
  } else if (type === "Users") {
    return 1;
  } else if (type === "NewUsers") {
    return 2;
  } else if (type === "BounceRate") {
    return 3;
  } else if (type === "Revenue") {
    return 4;
  } else if (type === "EcommerceConversionRate") {
    return 5;
  }
}

export function MemberStatusEmployee(id) {
  if (id === 0) {
    return "Active";
  } else if (id === 1) {
    return "Resigned";
  }
}

export function convertDate(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
}

export function mmDDyyyyFormateDate(date) {
  if (date.length > 19) {
    return moment(date.substring(0, 19) + ".000Z").format("DD/MM/YYYY");
  } else return moment(date + ".000Z").format("DD/MM/YYYY");
}
export function mmDDyyyyFormateForForm2(date) {
  return moment(date).utc().format("DD/MM/YYYY");
}

export function mmDDyyyyFormateForForm22(date) {
  return moment(date).format("DD/MM/YYYY");
}

export function mmDDyyyyFormateDateSet(date) {
  return moment(date).format("YYYY-MM-DD");
}

export function DDMMMyyyyFormateDateSet(date) {
  return moment(date).format("DD-MMM-YYYY");
}

export function DDMMyyyyFormateDateSet(date) {
  return moment(date).format("DD-MM-YYYY");
}

export function mmDDyyyyFormateForForm(date) {
  return moment(date).utc().format("YYYY-MM-DD");
}

export function mmDDyyyyFormateForForm23(date) {
  return moment(date).format("YYYY-MM-DD");
}
export function mmDDyyyyFormateForForm23time(date) {
  return moment(date).format("YYYY-MM-DD hh:mm");
}
export function formateDateIntoDatemonth(date) {
  return moment(date).format("DD MMM YYYY");
}

export function formateDateIntoDatemonthTime(date) {
  return moment(date).format("DD MMM YYYY, hh.mm A");
}

export function formatdatetime(date) {
  return moment(date).format("DD MMM, hh.mm A");
}

export function formatmonth(date) {
  return moment(date).utc().format("MMM");
}

export function formatmonthnumber(date) {
  return moment(date).utc().format("M");
}

export function formatRankDate(date) {
  return moment(date).format("DD MMM YYYY");
}

export function days(date) {
  return moment(date).fromNow();
}

export function Datetime(date) {
  return moment(date).format("DD.MM.YYYY HH:mm");;
}

export function formatWeekDate(date) {
  return moment(date).format("DD MMM");
}
export function DateWithDayName(date) {
  var mydate = moment(date).format("MM DD YYYY");
  var weekDayName = moment(mydate).format('dddd') + " ," + mydate;
  return weekDayName;
}
export function removeHttp(url) {
  return url
    .replace(/^https?:\/\//, "")
    .replace("/_", " ")
    .replace("/", "");
}

export function calanderDate(sDate, eDate) {
  return (
    <>
      {DDMMyyyyFormateDateSet(sDate)}&nbsp; to &nbsp;
      {DDMMyyyyFormateDateSet(eDate)}&nbsp;&nbsp;
      <i class="fa fa-chevron-down drop"></i>
    </>
  );
}

export function calanderDateforDashboard(sDate, eDate) {
  return (
    <>
      {formatRankDate(sDate)}&nbsp; to &nbsp;
      {formatRankDate(eDate)}&nbsp;&nbsp;
      <i class="fa fa-chevron-down drop"></i>
    </>
  );
}

export function calanderDate2(sDate, eDate) {
  return (
    <>
      {(sDate)}&nbsp; to &nbsp;
      {(eDate)}&nbsp;&nbsp;
      <i class="fa fa-chevron-down drop"></i>
    </>
  );
}

export function setPriority(priority) {
  if (priority === 0) {
    return "Low";
  } else if (priority === 1) {
    return "Medium";
  } else if (priority === 2) {
    return "High";
  } else {
    return "High";
  }
}

export function process(date) {
  var parts = date.split("/");
  return new Date(parts[2], parts[1] - 1, parts[0]);
}

// method for sum of elements in an array
export function sumOfArrayItems(arr) {
  let sum = 0; // initialize sum
  let i;
  // Iterate through all elements and add them to sum
  for (i = 0; i < arr.length; i++) sum += arr[i];
  return sum;
}

// method for sum of elements in an array
export function avgOfArrayItems(arr) {
  let sum = 0; // initialize sum
  let i;
  // Iterate through all elements and add them to sum
  for (i = 0; i < arr.length; i++) sum += arr[i];
  return arr.length === 0 ? 0 : sum / arr.length;
}

export function setMonths(monthNumber) {
  if (monthNumber === 1) {
    return "January";
  } else if (monthNumber === 2) {
    return "February";
  } else if (monthNumber === 3) {
    return "March";
  } else if (monthNumber === 4) {
    return "April";
  } else if (monthNumber === 5) {
    return "May";
  } else if (monthNumber === 6) {
    return "June";
  } else if (monthNumber === 7) {
    return "July";
  } else if (monthNumber === 8) {
    return "August";
  } else if (monthNumber === 9) {
    return "September";
  } else if (monthNumber === 10) {
    return "October";
  } else if (monthNumber === 11) {
    return "November";
  } else if (monthNumber === 12) {
    return "December";
  } else
    return "";
}

export function setTicketType(type) {
  if (type === 0) {
    return "Alert";
  } else if (type === 1) {
    return "Competitor Alert";
  } else if (type === 2) {
    return "ChangeRequest";
  } else if (type === 3) {
    return "Warning";
  }
}

export function setStatus(status) {
  if (status === 0) {
    return <Tag color="#ede84e">Open</Tag>;
  } else if (status === 1) {
    return <Tag color="#4f81bc">In Progress</Tag>;
  } else if (status === 2) {
    return <Tag color="#9bbb58">Resolved</Tag>;
  } else if (status === 3) {
    return <Tag color="#fd9931">Reopened</Tag>;
  } else if (status === 4) {
    return <Tag color="#7c7a7a">Closed</Tag>;
  } else if (status === 5) {
    return <Tag color="#c0504e">Overdue</Tag>;
  }
}

export function setExportStatus(status) {
  if (status === 0) {
    return "Open";
  } else if (status === 1) {
    return "In Progress";
  } else if (status === 2) {
    return "Resolved";
  } else if (status === 3) {
    return "Reopened";
  } else if (status === 4) {
    return "Closed";
  } else if (status === 5) {
    return "Overdue";
  }
}

export function dateFormate(date) {
  return moment(date).format("DD/MM/YYYY");
}

export function formatDate(date) {
  return moment(date).format("D MMM");
}

export function tableToCSV(data_table) {
  // Variable to store the final csv data
  var csv_data = [];
  // Get each row data
  var rows = document.querySelectorAll(`#${data_table} tr`);
  for (var i = 0; i < rows.length; i++) {
    // Get each column data
    var cols = rows[i].querySelectorAll(`#${data_table} td, #${data_table} th`);
    // Stores each csv row data
    var csvrow = [];
    for (var j = 0; j < cols.length; j++) {
      // Get the text data of each cell
      // of a row and push it to csvrow
      csvrow.push(cols[j].innerHTML);
    }
    // Combine each column value with comma
    csv_data.push(csvrow.join(",,"));
  }
  // Combine each row data with new line character
  csv_data = csv_data.join("\n");
  // create .csv file
  const CSVFile = new File([csv_data], "contact-us-data.csv", {
    type: "text/csv",
  });
  return CSVFile;
}

export function tableToCSVNew(data_table, type) {
  // Variable to store the final csv data
  var csv_data = [];
  // Get each row data
  var rows = data_table;
  var cols = Object.keys(rows[0]);

  var rowsNew = rows[0];
  // var rowCount = Object.keys(rowsNew).length;

  for (var i = 0; i < rows.length; i++) {
    // Get each column data
    var csvrow = [];
    var count = 1;

    if (csv_data.length === 0) {
      for (let j = 0; j < cols.length; j++) {
        let density = "density_" + count;
        let frequency = "frequency_" + count;
        if (rowsNew[cols[j]] != null && (rowsNew[cols[j]].hasOwnProperty(density) || rowsNew[cols[j]].hasOwnProperty(frequency))) {
          let colName = cols[j][0].toUpperCase() + cols[j].slice(1);
          csvrow.push(colName);
          csvrow.push("");
          count++;
        } else if (rowsNew[cols[j]] != null && (rowsNew[cols[j]].hasOwnProperty("Rank") || rowsNew[cols[j]].hasOwnProperty("URL"))) {
          let colName = cols[j][0].toUpperCase() + cols[j].slice(1);
          csvrow.push(colName);
          csvrow.push("");
          count++;
        } else {
          let colName = cols[j][0].toUpperCase() + cols[j].slice(1);
          csvrow.push(colName);
        }

        if (count > 3)
          count = 1;
      }
      if (type === "EXCEL") {
        csv_data.push(csvrow.join(",,"));
      } else {
        csv_data.push(csvrow.join(","));
      }

      csvrow = [];
      count = 1;
      if (Object.values(rowsNew).filter(a => typeof (a) == 'object').length > 0 && Object.values(rowsNew).filter(a => typeof (a) == 'object').findIndex(e => e !== null) > -1) {
        for (let j = 0; j < cols.length; j++) {
          let density = "density_" + count;
          let frequency = "frequency_" + count;
          if (rowsNew[cols[j]] != null && (rowsNew[cols[j]].hasOwnProperty(density) || rowsNew[cols[j]].hasOwnProperty(frequency))) {
            let densityColName = "density";
            let frequencyColName = "frequency";
            csvrow.push(densityColName);
            csvrow.push(frequencyColName);
            count++;
          } else if (rowsNew[cols[j]] != null && (rowsNew[cols[j]].hasOwnProperty("Rank") || rowsNew[cols[j]].hasOwnProperty("URL"))) {
            csvrow.push("Rank");
            csvrow.push("URL");
            count++;
          } else {
            csvrow.push("");
          }

          if (count > 3)
            count = 1;
        }
        if (type === "EXCEL") {
          csv_data.push(csvrow.join(",,"));
        } else {
          csv_data.push(csvrow.join(","));
        }
      }
    }

    csvrow = [];
    count = 1;
    // Stores each csv row data
    for (let j = 0; j < cols.length; j++) {
      // Get the text data of each cell
      // of a row and push it to csvrow
      let density = "density_" + count;
      let frequency = "frequency_" + count;
      if (rowsNew[cols[j]] != null && (rowsNew[cols[j]].hasOwnProperty(density) || rowsNew[cols[j]].hasOwnProperty(frequency))) {
        csvrow.push(rows[i][cols[j]][density]);
        csvrow.push(rows[i][cols[j]][frequency]);
        count++;
      } else if (rowsNew[cols[j]] != null && (rowsNew[cols[j]].hasOwnProperty("Rank") || rowsNew[cols[j]].hasOwnProperty("URL"))) {
        csvrow.push(rows[i][cols[j]]["Rank"]);
        csvrow.push(rows[i][cols[j]]["URL"]);
        count++;
      } else {
        csvrow.push(rows[i][cols[j]]);
      }

      if (count > 3)
        count = 1;
    }
    // Combine each column value with comma
    if (type === "EXCEL") {
      csv_data.push(csvrow.join(",,"));
    }
    else {
      csv_data.push(csvrow.join(","));
    }
  }
  // Combine each row data with new line character
  if (type === "EXCEL") {
    csv_data = csv_data.join("\n");
  } else {
    csv_data = csv_data.join("\n");
  }

  return csv_data;
}

export function exportToEXCEL(apiData, fileName) {
  if (apiData?.length > 0) {
    let list = apiData;

    let exportList = list?.map((data) => {
      const { key, ...rest } = data;
      return rest;
    });

    let exportListArray = tableToCSVNew(exportList, "EXCEL");
    let csvFileTxt = exportListArray.toString();
    let arrayOfArrayCsv = csvFileTxt.split("\n").map((row) => {
      return row.split(",,")
    });

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.aoa_to_sheet(arrayOfArrayCsv);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
}

export function exportInToCSV(data_table, filename) {

  if (data_table.length > 0) {
    let list = data_table;
    let exportList = list?.map((data) => {
      const { key, ...rest } = data;
      return rest;
    });
    const csv_data = tableToCSVNew(exportList, "CSV");

    // create .csv file
    const file = new File([csv_data], "contact-us-data.csv", {
      type: "text/csv",
    });

    // Create to temporary link to initiate
    // download process
    var temp_link = document.createElement("a");
    // Download csv file
    temp_link.download = `${filename}.csv`;
    var url = window.URL.createObjectURL(file);
    temp_link.href = url;
    // This link should not be displayed
    temp_link.style.display = "none";
    document.body.appendChild(temp_link);
    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  }
}

export function storeUserInformation(userData) {
  localStorage.setItem("timer", "00:00:00");
  localStorage.setItem("total", 0);

  localStorage.setItem("token", userData.token);
  localStorage.setItem("refreshToken", userData.refreshToken);
  localStorage.setItem("organizationId", userData.organizationId);
  localStorage.setItem("isForceChangePassword", userData.isForceChangePassword);

  localStorage.setItem(
    "accessPermission",
    JSON.stringify(userData.accessPermission)
  );

  let accessPermissionList = managedAccessPermissionList(
    userData.accessPermission
  );

  localStorage.setItem("client", JSON.stringify(accessPermissionList[0]));
  localStorage.setItem("team_member", JSON.stringify(accessPermissionList[1]));
  localStorage.setItem("modules", JSON.stringify(accessPermissionList[2]));
  localStorage.setItem("projects", JSON.stringify(accessPermissionList[3]));
  localStorage.setItem(
    "project_configurations",
    JSON.stringify(accessPermissionList[4])
  );

  localStorage.setItem("userId", userData.userId);
  localStorage.setItem("userName", userData.userName);
  localStorage.setItem("userRole", userData.userRole);
  localStorage.setItem("userDatadepartment", userData.departmentId);
  localStorage.setItem("userDatadepartmentName", userData.userDepartment);
  localStorage.setItem("userIcon", userData.userIcon);
  if (userData.clientProjects.length === 0) {
    localStorage.setItem("clientProjects", null);
    localStorage.setItem("projectGId", "");
    localStorage.setItem("projectGName", "");
    localStorage.setItem("clientGId", "");
    localStorage.setItem("clientGName", "");
  } else {
    localStorage.setItem(
      "clientProjects",
      JSON.stringify(userData.clientProjects)
    );
    localStorage.setItem(
      "projectGId",
      userData?.clientProjects[0]?.projects[0]?.projectId
    );
    localStorage.setItem(
      "projectGName",
      userData?.clientProjects[0]?.projects[0]?.projectCode
    );
    localStorage.setItem("clientGId", userData?.clientProjects[0]?.clientId);
    localStorage.setItem(
      "clientGName",
      userData?.clientProjects[0]?.clientCode
    );
  }
}

export function managedAccessPermissionList(list) {
  let client = [];
  let team_member = [];
  let modules = [];
  let projects = [];
  let project_configurations = [];
  for (let i = 0; i < list.length; i++) {
    if (list[i].module === "client") {
      client.push(list[i]);
    } else if (list[i].module === "team_member") {
      team_member.push(list[i]);
    } else if (list[i].module === "modules") {
      modules.push(list[i]);
    } else if (list[i].module === "projects") {
      projects.push(list[i]);
    } else if (list[i].module === "project_configurations") {
      project_configurations.push(list[i]);
    } else {
    }
  }
  return [client, team_member, modules, projects, project_configurations];
}

export function clearUserInformation() {
  localStorage.clear();
}

export function popUpDilouge(title, isConfirm) {
  window.confirm(title) ? isConfirm(true) : isConfirm(false);
}

export const routerLinks = {
  "Website Authority": "/main/module-expand-da/",
  "Organic Research": "/main/module-expand-organic-research/",
  "SERP Volatility": "/main/module-expand-seo-volatility/",
  Rankings: "/main/module-expand-rank-tracking/",
  "Rankings V2": "/main/ranking/",
  "Site Uptime Monitor": "/main/module-expand-site-uptime/",
  "Content Word Count on a Page": "/main/content-word-count/",
  "Return on Investment": "/main/module-expand-roi-calc/",
  Traffic: "/main/module-expand-google-analytics/",
  "Page Speed": "/main/module-expand-page-speed/",
  "Audit Findings": "/main/module-expand-seo-audit/",
  "Keyword Search": "/main/module-expand-keyword-research/",
  "Click Share": "/main/module-expand-click-share/",
  "Backlink Profile": "/main/module-expand-backlinks/",
  "Impressions & Clicks": "/main/module-expand-gsc/",
  "Google Trends": "/main/module-expand-google-trends/",
  //Reasearch
  "Research Organic Research": "/main/research/module-expand-organic-research/",
  "Research Keyword Search": "/main/research/module-expand-keyword-research/",
  "Research Backlink Profile": "/main/research/module-expand-backlinks/",

  "Ticket Dashboard": "/ticket/dashboard",
  "Team Evaluation": "/ticket/team-evaluation",
  "Team Tickets": "/ticket/view-all-tickets",
  "My Tickets": "/ticket/personal-tickets",
  "Create New Ticket": "/ticket/create-ticket",
};

export const moduleName = {
  "Website Authority": "Website Authority",
  "Organic Research": "Organic Research",
  "SERP Volatility": "SERP Volatility",
  "Google Trends": "Google Trends",
  Rankings: "Rankings",
  "Rankings V2": "Rankings V2",
  "Site Uptime Monitor": "Site Uptime Monitor",
  "Content Word Count on a Page": "Content Word Count on a Page",
  "Return on Investment": "Return on Investment",
  Traffic: "Traffic",
  "Page Speed": "Page Speed",
  "Audit Findings": "Audit Findings",
  "Keyword Search": "Keyword Research",
  "Click Share": "Click Share",
  "Backlink Profile": "Backlink Profile",
  "Impressions & Clicks": "Impressions & Clicks",
  "Ticket Dashboard": "Ticket Dashboard",
  "Team Evaluation": "Team Evaluation",
  "Team Tickets": "Team Tickets",
  "My Tickets": "My Tickets",
  "Create New Ticket": "Create New Ticket",
};

export const moduleNameList = [
  { value: "Website Authority", label: "Website Authority" },
  { value: "Organic Research", label: "Organic Research" },
  { value: "SERP Volatility", label: "SERP Volatility" },
  { value: "Google Trends", label: "Google Trends" },
  { value: "Rankings", label: "Rankings" },
  { value: "Rankings V2", label: "Rankings V2" },
  { value: "Site Uptime Monitor", label: "Site Uptime Monitor" },
  {
    value: "Content Word Count on a Page",
    label: "Content Word Count on a Page",
  },
  { value: "Return on Investment", label: "Return on Investment" },
  {
    value: "Traffic",
    label: "Traffic",
  },
  {
    value: "Page Speed",
    label: "Page Speed",
  },
  { value: "Audit Findings", label: "Audit Findings" },
  { value: "Keyword Search", label: "Keyword Search" },
  { value: "Click Share", label: "Click Share" },
  { value: "Backlink Profile", label: "Backlink Profile" },
  { value: "Impressions & Clicks", label: "Impressions & Clicks" },
  { value: "Ticket Dashboard", label: "Ticket Dashboard" },
  { value: "Team Evaluation", label: "Team Evaluation" },
  { value: "My Tickets", label: "My Tickets" },
  { value: "Team Tickets", label: "Team Tickets" },
  { value: "Create New Ticket", label: "Create New Ticket" },
];

export const moduleIcons = {
  "Website Authority": "fa fa-th-list",
  "Organic Research": "fa fa-leaf",
  "SERP Volatility": "fa fa-area-chart",
  Rankings: "fa fa-trophy",
  "Rankings V2": "fa fa-trophy",
  "Site Uptime Monitor": "fa-solid fa-clock",
  "Content Word Count on a Page": "fa fa-file",
  "Return on Investment": "fa fa-calculator",
  Traffic: "fa fa-bar-chart",
  "Page Speed": "fa fa-tachometer",
  "Audit Findings": "fa-solid fa-list-check",
  "Keyword Search": "fa-solid fa-keyboard",
  "Click Share": "fa fa-share",
  "Backlink Profile": "fa fa-link",
  "Impressions & Clicks": "fa fa-database",
  "Google Trends": "fa fa-line-chart",
  "Ticket Dashboard": "",
  "Team Evaluation": "",
  "Team Tickets": "",
  "My Tickets": "",
  "Create New Ticket": "",
};

export function findUnique(arr, predicate) {
  var found = {};
  arr.forEach((d) => {
    found[predicate(d)] = d;
  });
  return Object.keys(found).map((key) => found[key]);
}

export function getCheckPermission(action, module) {
  if (localStorage.getItem("accessPermission")) {
    let permissionArray = JSON.parse(localStorage.getItem("accessPermission"));
    let isTrue = false;
    permissionArray?.map((item) => {
      if (item.action.includes(action) && item.module.includes(module)) {
        isTrue = item.permission;
      }
    });
    return isTrue;
  }
}

export function getCheckPermissionByDisplayName(displayName) {
  if (localStorage.getItem("accessPermission")) {
    let permissionArray = JSON.parse(localStorage.getItem("accessPermission"));
    let isTrue = false;
    permissionArray.map((item) => {
      if (item.displayName.includes(displayName)) {
        isTrue = item.permission;
      }
    });
    return isTrue;
  }
}

export function checkValueInList(list, item1) {
  let isTrue = "";
  if (list) {
    list.map((item) => {
      if (item.name.includes(item1)) {
        isTrue = true;
      }
    });
  }
  if (isTrue === "") {
    isTrue = false;
  }
  return isTrue;
}

export function removeHTLMtag(HTMLtextData) {
  return HTMLtextData.replace(/(<([^>]+)>)/gi, "");
}

export function themeStyle() {
  let elements = document.getElementsByClassName("theme-dark");
  const styles = {
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isFocused
        ? "#C8C8C8"
        : state.isSelected
          ? "#2196f3"
          : elements.length
            ? "#575757"
            : "#FFFFFF",
      color: state.isFocused ? "#2196f3" : state.isSelected ? "#FFFFFF" : null,
      fontSize: "12px",
      textAlign: "start",
    }),
  };
  return styles;
}

export let Frequency = [
  {
    DisplayName: "Past Hour",
    Name: "now 1-H",
  },
  {
    DisplayName: "Past Day",
    Name: "now 1-d",
  },
  {
    DisplayName: "Past 4 Hours",
    Name: "now 4-H",
  },
  {
    DisplayName: "Past 7 Days",
    Name: "now 7-d",
  },
  {
    DisplayName: "Past 30 Days",
    Name: "today 1-m",
  },
  {
    DisplayName: "Past 90 Days",
    Name: "today 3-m",
  },
  {
    DisplayName: "Past 12 Months",
    Name: "today 12-m",
  },
  {
    DisplayName: "Past 5 Years",
    Name: "today 5-y",
  },
  {
    DisplayName: "Past Day",
    Name: "now 1-d",
  },
];

export let TypeOfSearch = [
  { value: "0", label: "WebSearch" },
  { value: "1", label: "NewsSearch" },
  { value: "2", label: "GoogleShopping" },
  { value: "3", label: "ImageSearch" },
  { value: "4", label: "YouTubeSearch" },
];

export let SiteUpTimeTypes = [
  { value: "0", label: "Title" },
  { value: "1", label: "Meta Description" },
  { value: "2", label: "H1 Tag" },
  { value: "3", label: "Canonical" },
  { value: "4", label: "NoIndex Tag" },
  { value: "5", label: "Status Code" },
  { value: "6", label: "SSL" },
  { value: "7", label: "Breadcrumbs" },
  { value: "8", label: "Structure Data" },
  { value: "9", label: "Hreflang Tag" },
  { value: "10", label: "Google Analytics" },
  { value: "11", label: "Robots.txt" },
  { value: "12", label: "Sitemap" },
];

export let SiteUpTimeTypeInt = [
  { value: 0, label: "Title" },
  { value: 1, label: "Meta Description" },
  { value: 2, label: "H1 Tag" },
  { value: 3, label: "Canonical" },
  { value: 4, label: "NoIndex Tag" },
  { value: 5, label: "Status Code" },
  { value: 6, label: "SSL" },
  { value: 7, label: "Breadcrumbs" },
  { value: 8, label: "Structure Data" },
  { value: 9, label: "Hreflang Tag" },
  { value: 10, label: "Google Analytics" },
  { value: 11, label: "Robots.txt" },
  { value: 12, label: "Sitemap" },
];

export let TimeZone = [
  { value: "IST", label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
  { value: "UEDT", label: "(UTC-05:00) Indiana (East" },
  { value: "DST", label: "(UTC-12:00) International Date Line West" },
  { value: "U", label: "(UTC-11:00) Coordinated Universal Time-11" },
  { value: "HST", label: "(UTC-10:00) Hawaii" },
  { value: "AKDT", label: "(UTC-09:00) Alaska" },
  { value: "PDT", label: "(UTC-08:00) Baja California" },
  { value: "PDT", label: "(UTC-07:00) Pacific Daylight Time (US & Canada" },
  { value: "PST", label: "(UTC-08:00) Pacific Standard Time (US & Canada" },
  { value: "UMST", label: "(UTC-07:00) Arizona" },
  { value: "MDT", label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan" },
  { value: "MDT", label: "(UTC-07:00) Mountain Time (US & Canada" },
  { value: "CAST", label: "(UTC-06:00) Central America" },
  { value: "CDT", label: "(UTC-06:00) Central Time (US & Canada" },
  { value: "CDT", label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey" },
  { value: "CCST", label: "(UTC-06:00) Saskatchewan" },
  { value: "SPST", label: "(UTC-05:00) Bogota, Lima, Quito" },
  { value: "EST", label: "(UTC-05:00) Eastern Time (US & Canada" },
  { value: "EDT", label: "(UTC-04:00) Eastern Daylight Time (US & Canada" },
  { value: "VST", label: "(UTC-04:30) Caracas" },
  { value: "PYT", label: "(UTC-04:00) Asuncion" },
  { value: "ADT", label: "(UTC-04:00) Atlantic Time (Canada" },
  { value: "CBST", label: "(UTC-04:00) Cuiaba" },
  { value: "SWST", label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan" },
  { value: "PSST", label: "(UTC-04:00) Santiago" },
  { value: "NDT", label: "(UTC-03:30) Newfoundland" },
  { value: "ESAST", label: "(UTC-03:00) Brasilia" },
  { value: "AST", label: "(UTC-03:00) Buenos Aires" },
  { value: "SEST", label: "(UTC-03:00) Cayenne, Fortaleza" },
  { value: "GDT", label: "(UTC-03:00) Greenland" },
  { value: "MST", label: "(UTC-03:00) Montevideo" },
  { value: "BST", label: "(UTC-03:00) Salvador" },
  { value: "U", label: "(UTC-02:00) Coordinated Universal Time-02" },
  { value: "MDT", label: "(UTC-02:00) Mid-Atlantic - Old" },
  { value: "ADT", label: "(UTC-01:00) Azores " },
  { value: "CVST", label: "(UTC-01:00) Cape Verde Is" },
  { value: "MDT", label: "(UTC) Casablanca" },
  { value: "UTC", label: "(UTC) Coordinated Universal Time" },
  { value: "GMT", label: "(UTC) Edinburgh, London" },
  { value: "BST", label: "(UTC+01:00) Edinburgh, London" },
  { value: "GDT", label: "(UTC) Dublin, Lisbon" },
  { value: "GST", label: "(UTC) Monrovia, Reykjavik" },
  {
    value: "WEDT",
    label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
  },
  {
    value: "CEDT",
    label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
  },
  { value: "RDT", label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
  { value: "CEDT", label: "(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
  { value: "WCAST", label: "( (UTC+01:00) West Central Africa" },
  { value: "NST", label: "(UTC+01:00) Windhoek" },
  { value: "GDT", label: "(UTC+02:00) Athens, Bucharest" },
  { value: "MEDT", label: "(UTC+02:00) Beirut" },
  { value: "EST", label: " (UTC+02:00) Cairo " },
  { value: "SDT", label: "(UTC+02:00) Damascus" },
  { value: "EEDT", label: "(UTC+02:00) E. Europe" },
  { value: "SAST", label: "(UTC+02:00) Harare, Pretoria" },
  {
    value: "FDT",
    label: "(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius",
  },
  { value: "TDT", label: "(UTC+03:00) Istanbul" },
  { value: "JDT", label: "(UTC+02:00) Jerusalem" },
  { value: "LST", label: "(UTC+02:00) Tripoli" },
  { value: "JST", label: "(UTC+03:00) Amman " },
  { value: "AST", label: "(UTC+03:00) Baghdad" },
  { value: "KST", label: "(UTC+02:00) Kaliningrad" },
  { value: "AST", label: "(UTC+03:00) Kuwait, Riyadh" },
  { value: "EAST", label: "(UTC+03:00) Nairobi" },
  {
    value: "MSK",
    label: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk",
  },
  { value: "SAMT", label: "(UTC+04:00) Samara, Ulyanovsk, Saratov" },
  { value: "IDT", label: "(UTC+03:30) Tehran " },
  { value: "AST", label: "(UTC+04:00) Abu Dhabi, Muscat" },
  { value: "ADT", label: "(UTC+04:00) Baku " },
  { value: "MST", label: "(UTC+04:00) Port Louis" },
  { value: "GET", label: "(UTC+04:00) Tbilisi" },
  { value: "CST", label: "(UTC+04:00) Yerevan" },
  { value: "AST", label: "(UTC+04:30) Kabul " },
  { value: "WAST", label: "(UTC+05:00) Ashgabat, Tashkent" },
  { value: "YEKT", label: "(UTC+05:00) Yekaterinburg" },
  { value: "PKT", label: "(UTC+05:00) Islamabad, Karachi" },
  { value: "SLST", label: "(UTC+05:30) Sri Jayawardenepura" },
  { value: "NST", label: " (UTC+05:45) Kathmandu" },
  { value: "CAST", label: "(UTC+06:00) Nur-Sultan (Astana" },
  { value: "BST", label: "(UTC+06:00) Dhaka" },
  { value: "MST", label: "(UTC+06:30) Yangon (Rangoon" },
  { value: "SAST", label: "(UTC+07:00) Bangkok, Hanoi, Jakarta" },
  { value: "NCAST", label: "(UTC+07:00) Novosibirsk" },
  { value: "CST", label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
  { value: "NAST", label: "(UTC+08:00) Krasnoyarsk" },
  { value: "MPST", label: "(UTC+08:00) Kuala Lumpur, Singapore" },
  { value: "WAST", label: "(UTC+08:00) Perth" },
  { value: "TST", label: "(UTC+08:00) Taipei" },
  { value: "UST", label: "(UTC+08:00) Ulaanbaatar" },
  { value: "NAEST", label: "(UTC+08:00) Irkutsk" },
  { value: "JST", label: "(UTC+09:00) Osaka, Sapporo, Tokyo" },
  { value: "KST", label: "(UTC+09:00) Seoul" },
  { value: "CAST", label: "(UTC+09:30) Adelaide" },
  { value: "ACST", label: "(UTC+09:30) Darwin" },
  { value: "EAST", label: "(UTC+10:00) Brisbane" },
  { value: "AEST", label: "(UTC+10:00) Canberra, Melbourne, Sydney" },
  { value: "WPST", label: "(UTC+10:00) Guam, Port Moresby" },
  { value: "TST", label: "(UTC+10:00) Hobart " },
  { value: "YST", label: "(UTC+09:00) Yakutsk" },
  { value: "CPST", label: "(UTC+11:00) Solomon Is., New Caledonia" },
  { value: "VST", label: "(UTC+11:00) Vladivostok" },
  { value: "NZST", label: "(UTC+12:00) Auckland, Wellington" },
  { value: "U", label: "(UTC+12:00) Coordinated Universal Time+12" },
  { value: "FST", label: "(UTC+12:00) Fiji" },
  { value: "MST", label: "(UTC+12:00) Magadan" },
  { value: "KDT", label: "(UTC+12:00) Petropavlovsk-Kamchatsky - Old" },
  { value: "TST", label: "(UTC+13:00) Nuku'alofa" },
  { value: "SST", label: "(UTC+13:00) Samoa " },
];

export let frequencyListt = [
  { value: "Daily", label: "Daily" },
  { value: "Weekly", label: "Weekly" },
  { value: "Fornightly", label: "Fornightly" },
  { value: "Monthly", label: "Monthly" },
];

export let frequencyListKeyValue = [
  { value: "0", label: "Daily" },
  { value: "1", label: "Weekly" },
  { value: "2", label: "Fornightly" },
  { value: "3", label: "Monthly" },
];

export let deviceType = [
  { value: "0", label: "Mobile" },
  { value: "1", label: "Desktop" },
  { value: "2", label: "Both" },
];

export let deviceType22 = [
  { value: "0", label: "Mobile" },
  { value: "1", label: "Desktop" },
];

export let Datasource = [
  { value: "0", label: "Google" },
  { value: "1", label: "Google sheets" },
];

export let searchTypeList = [
  { value: "0", label: "Web" },
  { value: "1", label: "Image" },
  { value: "2", label: "News" },
  { value: "3", label: "YouTube" },
  { value: "4", label: "Froogle" },
];

export let selectType = [
  { value: "url", label: "URL" },
  { value: "prefix", label: "Prefix" },
  { value: "domain1", label: "Domain/*" },
  { value: "domain2", label: "*.domain/*" },
];

export let uckList = [
  { value: "URL", label: "URL" },
  { value: "Competitors", label: "Competitors" },
  { value: "Keywords", label: "Keywords" },
];

export let competitorsType = [
  { value: "URL", label: "URL" },
  { value: "Prefix", label: "Prefix" },
  { value: "Domain/*", label: "Domain/*" },
  { value: "*.domain/*", label: "*.domain/*" },
];

export let authenticationType = [
  { value: "0", label: "Custom" },
  { value: "1", label: "Google" },
];

export let ticketType = [
  { value: "Alert", label: "Alert" },
  { value: "CompetitorAlert", label: "Competitor Alert" },
  { value: "ChangeRequest", label: "Change Request" },
  { value: "Warning", label: "Warning" },
];

export let StatusList = [
  { value: "3", label: "Appointment Scheduled" },
  { value: "4", label: "Qualified to Buy" },
  { value: "5", label: "Presentation Scheduled" },
  { value: "6", label: "Decision maker Bought-In" },
  { value: "7", label: "Contract Sent" },
  { value: "8", label: "Closed Won" },
  { value: "9", label: "Closed Lost" },
];

export let Fields = [
  { value: "0", label: "Select all" },
  { value: "1", label: "Meta Title" },
  { value: "2", label: "Meta Description" },
  { value: "3", label: "Canonical on the page" },
  { value: "4", label: "NoIndex" },
  { value: "5", label: "NoFollow" },
];
export let ArticleStatus = [
  { value: 0, text: "AssignWriter" },
  { value: 1, text: "WritingInProgress" },
  { value: 2, text: "WritingInProgressRework" },
  { value: 3, text: "AwaitingQC" },
  { value: 4, text: "AssignEditor" },
  { value: 5, text: "EditingInProgress" },
  { value: 6, text: "EditingInProgressRework" },
  { value: 7, text: "AwaitingCrossQC" },
  { value: 8, text: "SentForApproval" },
  { value: 9, text: "ArticleCompleted" }
]

export let DBStatus = [
  { value: 0, text: "In Progress" },
  { value: 1, text: "Rework" },
  { value: 2, text: "Awaiting Approval" },
  { value: 3, text: "Completed" },
  { value: 4, text: "Awaiting Cross QC" },
]

export function selectAllList(list, oldList, all) {
  if (list.length > -1) {
    let isSelectAll = false;
    let isSelectAllOld = false;
    if (oldList.length > 0) {
      oldList.map((i, index) => {
        if (i.value === "Select All") {
          isSelectAllOld = true;
        }
      });
    }
    list.map((i, index) => {
      if (i.value === "Select All") {
        isSelectAll = true;
      }
    });
    if (isSelectAll) {
      return all;
    } else {
      if (isSelectAllOld) {
        return [];
      } else {
        return list;
      }
    }
  }
}

export function fixTwoPlaceDecimal(value) {
  let number = Number(parseFloat(value).toFixed(2));
  return number;
}

export function urlDomainName(url) {
  let domain = new URL(url);
  return getDomainName(domain.hostname);
}

function getDomainName(hostName) {
  var domain = hostName.split('.');
  if (domain.length === 2)
    return domain[0];
  else if (domain.length === 3)
    return domain[1];
  else
    return domain;
}

export function getWeek(fromDate) {
  let arr = [];
  let fDate = new Date(
    fromDate.setDate(fromDate.getDate() - fromDate.getDay())
  );
  arr.push(fDate);
  var sunday = new Date(
    fromDate.setDate(fromDate.getDate() - fromDate.getDay())
  ),
    result = [sunday];
  while (sunday.setDate(sunday.getDate() + 1) && sunday.getDay() !== 0) {
    result.push(new Date(sunday));
    arr.push(new Date(sunday));
  }
  return arr;
}

export function convertYYYYMMDD(str) {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("/");
}

export function dateRagngeSelectWeek(item, state, isCompareVarNew) {
  if (item?.selection1) {
    let selectionDate = state?.selection1?.startDate.toDateString() === item?.selection1?.startDate.toDateString() ? item?.selection1?.endDate : item?.selection1?.startDate;
    let week = getWeek(new Date(selectionDate));

    return {
      selection1: {
        startDate: new Date(week[0]),
        endDate: new Date(week[week.length - 1]),
        key: "selection1",
      },
      selection2: {
        startDate: isCompareVarNew === "false" ? new Date(week[0]) : state?.selection2?.startDate,
        endDate: isCompareVarNew === "false" ? new Date(week[week.length - 1]) : state?.selection2?.endDate,
        key: "selection2",
      },
    };
  } else if (item?.selection2) {
    let selectionDate = state?.selection2?.startDate.toDateString() === item?.selection2?.startDate.toDateString() ? item?.selection2?.endDate : item?.selection2?.startDate;
    let week = getWeek(new Date(selectionDate));

    return {
      selection1: {
        startDate: state?.selection1?.startDate,
        endDate: state?.selection1?.endDate,
        key: "selection1",
      },
      selection2: {
        startDate: new Date(week[0]),
        endDate: new Date(week[week.length - 1]),
        key: "selection2",
      },
    };
  }
}

export function returnMagnitude(value) {
  value = value === undefined ? 0 : value;
  let newValue

  value < 0 ? newValue = Math.abs(value) : newValue = value;

  let finalValue;
  if (newValue >= 1000000000) {
    finalValue = (newValue / 1000000000).toFixed(0).replace(/\.0$/, '') + 'B';
  }
  if (newValue >= 1000000) {
    finalValue = (newValue / 1000000).toFixed(0).replace(/\.0$/, '') + 'M';
  }
  if (newValue >= 1000) {
    finalValue = (newValue / 1000).toFixed(0).replace(/\.0$/, '') + 'K';
  }
  if (newValue < 1000) {
    finalValue = newValue.toFixed(0);
  }

  if (value < 0) {
    return "-" + finalValue
  }
  else {
    return finalValue;
  }

}
export function returnMagnitude1(val) {
  return val.toFixed(0);
}

export function toMonthName(monthNumber, title) {
  if (title === "Months") {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    let year = date.getFullYear();
    let month = date.toLocaleString("en-US", {
      month: "short",
    });
    return month;//+ " " + year;
  }
  else if (title === "Weeks") {
    if (monthNumber != undefined) {
      return getSundayFromWeekNum(monthNumber, 2023);
    }
    else {
      return;
    }

  } else if (title === "Days") {
    return formatRankDate(monthNumber);
  } else {
    return monthNumber;
  }
}

export function TeamEvtoMonthName(monthNumber, title) {
  if (title === "Months") {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    var year = date.getFullYear();
    var month = date.toLocaleString("en-US", {
      month: "short",
    });
    return month;//+ " " + year;
  }
  else if (title === "Weeks") {
    if (monthNumber != undefined) {
      return getSundayFromWeekNum(monthNumber, 2023);
    }
    else {
      return;
    }

  } else if (title === "Days") {
    return DateWithDayName(monthNumber);
  } else {
    return monthNumber;
  }
}

function getWeekRange(weekNo, yearNo) {
  let firstDayofYear = new Date(yearNo, 0, 1);

  if (firstDayofYear.getDay() > 4) {
    let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8);
    let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 8 + 6);
    return formatWeekDate(weekStart) + "-" + formatWeekDate(weekEnd)
  }
  else {
    let weekStart = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1);
    let weekEnd = new Date(yearNo, 0, 1 + (weekNo - 1) * 7 - firstDayofYear.getDay() + 1 + 6);
    return formatWeekDate(weekStart) + "-" + formatWeekDate(weekEnd)
  }
}

function getSundayFromWeekNum(weekNum, year) {
  var sunday = new Date(year, 0, (1 + (weekNum - 1) * 7));
  while (sunday.getDay() !== 0) {
    sunday.setDate(sunday.getDate() - 1);
  }
  var lastDay = new Date(year, 0, (1 + (weekNum) * 7));
  while (lastDay.getDay() !== 0) {
    lastDay.setDate(lastDay.getDate() - 1);
  }
  lastDay.setDate(lastDay.getDate() - 1);
  let value = formatWeekDate(sunday) + "-" + formatWeekDate(lastDay);
  return value;
}

export function getSundayFromWeekNumOnly(weekNum) {
  var sunday = new Date(2023, 0, (1 + (weekNum - 1) * 7));
  while (sunday.getDay() !== 0) {
    sunday.setDate(sunday.getDate() - 1);
  }
  var lastDay = new Date(2023, 0, (1 + (weekNum) * 7));
  while (lastDay.getDay() !== 0) {
    lastDay.setDate(lastDay.getDate() - 1);
  }
  lastDay.setDate(lastDay.getDate() - 1);
  let value = formatWeekDate(sunday) + "-" + formatWeekDate(lastDay);
  return value;
}


export function getEstimatedClicksColor(value) {
  if (value > 0) return "text-successpage";
  else if (value === 0) return "text-black";
  else if (value < 0) return "text-danger";
}

export function dateConverter(d) {
  d = new Date(d)
  const monthList = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ];
  var hours = d.getHours();
  const yr = d.getFullYear();
  const mnt = monthList[d.getMonth()];
  const day = d.getDate() < 9 ? "0" + d.getDate() : d.getDate();
  const gmtHr = d.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const gmtMin =
    d.getMinutes() < 9 ? "0" + d.getMinutes() : d.getMinutes();
  return [day, mnt, yr].join(" ") + " " + [gmtHr, gmtMin].join(":") + [ampm].join(" ");
}

export function getChartColourPic(index) {
  let colourCSS
  if (index % 5 === 0) {
    colourCSS = "box"
  } else if (index % 5 === 1) {
    colourCSS = "box green-bx"
  } else if (index % 5 === 2) {
    colourCSS = "box yellow-bx"
  } else if (index % 5 === 3) {
    colourCSS = "box red-bx"
  } else if (index % 5 === 4) {
    colourCSS = "box purple-bx"
  }
  return colourCSS
}

export function getClassName(i) {
  if (i.isHighData === 2) {
    return "avg-val";
  } else if (i.isHighData === 0) {
    return "avg-val-positive";
  } else if (i.isHighData === 1) {
    return "avg-val-negative";
  }
}

export function getColorClassName(i) {
  if (i === 2) {
    return "avg-val";
  } else if (i === 0) {
    return "avg-val-positive";
  } else if (i === 1) {
    return "avg-val-negative";
  }
}

export function compareValues(values1, values2) {
  if (values1 > values2) {
    return 1;
  } else if (values1 < values2) {
    return 0;
  } else {
    return 2;
  }
}

export function lastDayOfMonth(y, m) {
  return new Date(y, m, 0).getDate() + "/";
}

export function getMonth(pastMonthCount, isEndDate) {
  var monthNames = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"];
  var today = new Date();
  var d = new Date(today.getFullYear(), today.getMonth() - pastMonthCount, 1);
  var month = monthNames[d.getMonth()] + "/" + d.getFullYear();
  let monthDays = isEndDate ? getMonthDays(d.getMonth(), d.getFullYear()) : "1/"
  let monthDate = (monthDays + month).split("/").reverse().join("/");
  return monthDate
}

function getMonthDays(month, year) {
  return new Date(year, month, 0).getDate() + "/";
}

export function getMonthName() {
  let month = moment(new Date()).format("MMM");
  return month;
}

export function getMonthYearName() {
  let month = moment(new Date()).format("MMM YYYY");
  return month;
}

export function getLastMonthYearName() {
  const current = new Date();
  current.setMonth(current.getMonth() - 1);
  let month = moment(current).format("MMM YYYY");
  return month;
}

export function getDaysInMonth(year, month) {
  return new Date(year, month, 0).getDate(); //return total number of days in a month
}

export async function downloadPDFfile(image, pdfFileName,GeneratePDF,idloop,drawer) {
  if (document.getElementById('audit-version-lbl'))
    document.getElementById('audit-version-lbl').style.marginLeft = '-110px';
    for(let i = 0; i<idloop;i++){
      if(document.getElementById(`pdf-doughnut-txt${i}`)){
        document.getElementById(`pdf-doughnut-txt${i}`).style.textAlign = 'end';
      }
    }
  return new Promise((resolve, reject) => {
    if (image?.current) {
      var isChanged = false;
      if (
        document.querySelector("body").classList.contains("theme-dark")
      ) {
        document.querySelector("body").classList.remove("theme-dark");
        document.querySelector("body").classList.add("theme-light");
        isChanged = true;
      }
     savePDF(
        image?.current,
        { paperSize: "A4", fileName: pdfFileName, margin: "2cm", keepTogether: ".table-keep", forcePageBreak: ".page-break", pageTemplate: GeneratePDF },
        () => {
          if (isChanged) {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
          if (document.getElementById('audit-version-lbl'))
          document.getElementById('audit-version-lbl').style.marginLeft = '0px';
          for(let i = 0; i<idloop;i++){
          if(document.getElementById(`pdf-doughnut-txt${i}`)){
            document.getElementById(`pdf-doughnut-txt${i}`).style.textAlign = 'center';
          }
        }
          message.success('File Downloaded Successfully')
          drawer(false)
          resolve(true);
        }
      );
      if (document.getElementById('audit-version-lbl') && document.getElementById('audit-version-lbl').style.marginLeft == '-110px')
        document.getElementById('audit-version-lbl').style.marginLeft = '0px';
    }
    else {
      reject(Error(false));
      message.error('Not able to download PDF at the moment')
    }
  });
}

export async function getBase64path(image) {

  return new Promise((resolve, reject) => {
    try {
      drawDOM(image?.current, {
        paperSize: "A1",
      })
        .then(async (group) => {
          return exportPDF(group);
        })
        .then(async (dataUri) => {
          resolve(dataUri.split(";base64,")[1]);
        });
    } catch {
      reject(Error(false));
    }
  });

}

export function removeTrailingSlash(str) {
  return str.replace(/\/+$/, '');
}

export let tickettypes = [
  { value: "0", label: "Universal Ticket" },
  { value: "1", label: "SOW Ticket" },
  { value: "2", label: "AdHoc Ticket" },
  { value: "3", label: "System Ticket" },
  { value: "4", label: "Pitch Deck Ticket" },
];
export let creattypes = [
  { value: "0", label: "Universal Ticket" },
  { value: "2", label: "AdHoc Ticket" },
  { value: "4", label: "Pitch Deck Ticket" },
];
export let tickettypes1 = [
  { value: "Select", label: "Select" },
  { value: "0", label: "Universal Ticket" },
  { value: "1", label: "SOW Ticket" },
  { value: "2", label: "AdHoc Ticket" },
  { value: "3", label: "System Ticket" },
  { value: "4", label: "Pitch Deck Ticket" },
];

export let etain = [
  { value: "0", label: "Next 7 Days" },
  { value: "1", label: "Next 14 Days" },
  { value: "2", label: "Next 21 Days" },
  { value: "3", label: "This Month" },
];

export let etain1 = [
  { value: "Select", label: "Select" },
  { value: "0", label: "Next 7 Days" },
  { value: "1", label: "Next 14 Days" },
  { value: "2", label: "Next 21 Days" },
  { value: "3", label: "This Month" },
];
export let ticketpriority = [
  { value: "0", label: "Low" },
  { value: "1", label: "Medium" },
  { value: "2", label: "High" },
];

export let ticketpriority1 = [
  { value: "Select", label: "Select" },
  { value: "0", label: "Low" },
  { value: "1", label: "Medium" },
  { value: "2", label: "High" },
];

export function calanderDateCompare(sDate1, eDate1, sDate2, eDate2) {
  return (
    <>
      {DDMMyyyyFormateDateSet(sDate1)}&nbsp; to &nbsp;
      {DDMMyyyyFormateDateSet(eDate1)}&nbsp;&nbsp;
      | &nbsp;&nbsp;
      {DDMMyyyyFormateDateSet(sDate2)}&nbsp; to &nbsp;
      {DDMMyyyyFormateDateSet(eDate2)}&nbsp;&nbsp;
      <i class="fa fa-chevron-down drop"></i>
    </>
  );
}

export function calanderDateCompareforDashboard(sDate1, eDate1, sDate2, eDate2) {
  return (
    <>
      {formatRankDate(sDate1)}&nbsp; to &nbsp;
      {formatRankDate(eDate1)}&nbsp;&nbsp;
      | &nbsp;&nbsp;
      {formatRankDate(sDate2)}&nbsp; to &nbsp;
      {formatRankDate(eDate2)}&nbsp;&nbsp;
      <i class="fa fa-chevron-down drop"></i>
    </>
  );
}

export function getActivityType(type) {
  if (type === "0") {
    return "Content"
  }
  else if (type === "1") {
    return "Off Page"
  }
  else if (type === "2") {
    return "Technical"
  }
  else if (type === "3") {
    return "On Page"
  }
}

export function getCategoryValue(type) {
  if (type === "OffPage") {
    return "0"
  }
  else if (type === "OnPage") {
    return "1"
  }
  else if (type === "Reports") {
    return "2"
  }
}

export function getImageConfig() {
  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",
      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "eraser",
      "copyformat",
      "|",
      "ol",
      "ul",
      "align",
      "|",
      "table",
      "indent",
      "outdent",
      "hr",
      "link",
      "image",
      "video",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "font", "fontsize", "paragraph"],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    }
  };

  return config
}

export function dateRagngeSelectFornightly(item, state, isCompareVarNew) {
  if (item?.selection1) {
    var selectionDate = state?.selection1?.startDate.toDateString() === item?.selection1?.startDate.toDateString() ? item?.selection1?.endDate : item?.selection1?.startDate;

    return {
      selection1: {
        startDate: addDays(selectionDate, -14),
        endDate: addDays(selectionDate, 0),
        key: "selection1",
      },
      selection2: {
        startDate: isCompareVarNew === "false" ? addDays(selectionDate, -14) : state?.selection2?.startDate,
        endDate: isCompareVarNew === "false" ? addDays(selectionDate, 0) : state?.selection2?.endDate,
        key: "selection2",
      },
    };
  } else if (item?.selection2) {
    var selectionDate = state?.selection2?.startDate.toDateString() === item?.selection2?.startDate.toDateString() ? item?.selection2?.endDate : item?.selection2?.startDate;

    return {
      selection1: {
        startDate: state?.selection1?.startDate,
        endDate: state?.selection1?.endDate,
        key: "selection1",
      },
      selection2: {
        startDate: addDays(selectionDate, -14),
        endDate: addDays(selectionDate, 0),
        key: "selection2",
      },
    };
  }
}

export function dateRagngeSelectMonthly(item, state, isCompareVarNew) {
  if (item?.selection1) {
    var selectionDate = state?.selection1?.startDate.toDateString() === item?.selection1?.startDate.toDateString() ? item?.selection1?.endDate : item?.selection1?.startDate;
    var firstDay = new Date(selectionDate.getFullYear(), selectionDate.getMonth(), 1);
    var lastDay = new Date(selectionDate.getFullYear(), selectionDate.getMonth() + 1, 0);

    return {
      selection1: {
        startDate: firstDay,
        endDate: lastDay,
        key: "selection1",
      },
      selection2: {
        startDate: isCompareVarNew === "false" ? firstDay : state?.selection2?.startDate,
        endDate: isCompareVarNew === "false" ? lastDay : state?.selection2?.endDate,
        key: "selection2",
      },
    };
  } else if (item?.selection2) {
    var selectionDate = state?.selection2?.startDate.toDateString() === item?.selection2?.startDate.toDateString() ? item?.selection2?.endDate : item?.selection2?.startDate;
    var firstDay = new Date(selectionDate.getFullYear(), selectionDate.getMonth(), 1);
    var lastDay = new Date(selectionDate.getFullYear(), selectionDate.getMonth() + 1, 0);

    return {
      selection1: {
        startDate: state?.selection1?.startDate,
        endDate: state?.selection1?.endDate,
        key: "selection1",
      },
      selection2: {
        startDate: firstDay,
        endDate: lastDay,
        key: "selection2",
      },
    };
  }
}

export function percentageCount(value1, value2) {
  if (value1 === undefined)
    value1 = 0;
  if (value2 === undefined)
    value2 = 0;
  var decreaseValue
  if (value1 > value2) {
    decreaseValue = value1 - value2;

    return (decreaseValue / value1) * 100;
  }
  else if (value1 < value2) {
    decreaseValue = value2 - value1;

    return (decreaseValue / value2) * 100;
  } else {
    return 0;
  }
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export function chartCenterTextPlugins(valueData) {
  return (
    [{
      beforeDraw: function (chart) {
        var width = chart.width,
          height = chart.height,
          ctx = chart.ctx;
        ctx.restore();
        var fontSize = (height / 70).toFixed(2);
        ctx.font = fontSize + "em Montserrat";
        ctx.textBaseline = "top";
        var text = valueData,
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }]
  )
}

Date.prototype.getWeek = function () {
  var target = new Date(this.valueOf());
  var dayNr = (this.getDay() + 6) % 7;
  target.setDate(target.getDate() - dayNr + 3);
  var firstThursday = target.valueOf();
  target.setMonth(0, 1);
  if (target.getDay() != 4) {
    target.setMonth(0, 1 + ((4 - target.getDay()) + 7) % 7);
  }
  return 1 + Math.ceil((firstThursday - target) / 604800000);
}

export function getDateRangeOfWeek(weekNo) {
  var d1 = new Date();
  var numOfdaysPastSinceLastMonday = eval(d1.getDay() - 1);
  d1.setDate(d1.getDate() - numOfdaysPastSinceLastMonday);
  var weekNoToday = d1.getWeek();
  var weeksInTheFuture = eval(weekNo - weekNoToday);
  d1.setDate((d1.getDate() - 1) + eval(7 * weeksInTheFuture));
  var rangeIsFrom = eval(d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear();
  d1.setDate(d1.getDate() + 6);
  var rangeIsTo = eval(d1.getMonth() + 1) + "/" + d1.getDate() + "/" + d1.getFullYear();
  return rangeIsFrom;
};