import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  addAuditmodule,
  getAuditModuleById,
  getcategory,
  GET_FrontEnd_URL,
  GET_Server_URL,
  updateAuditmodule,
} from "../../utility/Constant";
import { useLocation } from "react-router-dom";
import JoditEditor from "jodit-pro-react";
import { Table } from "antd";

const AuditModuleAddUpdate = ({ update }) => {
  const location = useLocation();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description1, setDescription] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [message, setMessage] = useState("");
  const [categoryList, setcategoryList] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  let description12 = "";
  const [isUpdate, setUpdate] = useState(false);
  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",

      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "eraser",
      "copyformat",
      "|",
      "ol",
      "ul",
      "align",
      "|",
      "table",
      "indent",
      "outdent",
      "hr",
      "link",
      "image",
      "video",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "font", "fontsize", "paragraph"],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    },
  };

  useEffect(() => {
    getCategoryData();
    setUpdate(location.state.isUpdate);
    setTimeout(() => {
      if (location.state.isUpdate) {
        setName(location.state.name);
        setId(location.state.id);
        getAuditDescriptionData();
      }
    }, 1000);
  }, []);

  function getAuditDescriptionData() {
    APIManager.getAPI(getAuditModuleById + location.state.id).then(
      (response) => {
        if (response && response.status == 200) {
          setDescription(response?.data?.value?.description);
          setCategoryid(response?.data?.value?.auditCategoryId);
          setCategoryName(response?.data?.value?.auditCategory);
          description12 = response?.data?.value?.description;
        }
      }
    );
  }

  function getCategoryData() {
    APIManager.getAPI(getcategory).then((response) => {
      setcategoryList(response?.data);
      setCategoryid(response?.data[0].id);
      setTimeout(() => {
      }, 500);
    });
  }

  function appModule() {
    if (name.trim() == "") {
      setMessage("Name is required!");
      return;
    }
    APIManager.postAPI(
      addAuditmodule,
      {
        auditModuleName: name.trim(),
        description: description1.trim(),
        AuditCategoryId: categoryid.trim(),
        organizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearData();
      }
    });
  }

  function updateModuleData() {
    if (name.trim() == "") {
      setMessage("Please enter name!");
      return;
    }
    APIManager.putAPI(
      updateAuditmodule,
      {
        id: id,
        auditModuleName: name,
        description: description1,
        AuditCategoryId: categoryid,
        organizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearData();
      }
    });
  }

  function clearData() {
    setDescription("");
    setName("");
    setId("");
    setCategoryid("");
    setCategoryName("");
    update("auditChecklist");
  }

  return (
    <>
      <div className="custom-column-70">
        <div class="portlet slideInUp setting-top">
          <div class="portlet-header portlet-header-bordered">
          <svg  onClick={() => {
                update("auditChecklist");
              }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {" "}
              {localStorage.getItem("organizationName")} -
              {isUpdate ? "Update " : "Add "}Audit Module
            </h3>
          </div>
          <div className="portlet-body">
            <div class="common-wrapper">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user">
                    <div class="col-md-12 mt-3">
                      <label className="form-label">Name</label>
                      <label class="field-required"> * </label>
                      <input
                        type="text"
                        value={name}
                        onChange={(text) => {
                          let textData = text.target.value;
                          setName(textData);
                        }}
                        placeholder="Enter Name"
                      />
                      <div class="ms-3" style={{ color: "red" }}>
                        {message}
                      </div>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label">Category</label>
                      <select
                        className="form-select w-100 mt-2"
                        value={categoryid}
                        onChange={(e) => {
                          let text = e.target.value;
                          setCategoryid(text);
                          categoryList?.map((item) => {
                            if (item?.id == e.target.value) {
                              setCategoryName(item.category);
                            }
                          });
                        }}
                      >
                        {categoryList?.map((item) => {
                          return (
                            <option value={item.id}>{item.category}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3 audit-editor">
                      <label>Description</label>
                      <JoditEditor
                        value={description1 || ""}
                        config={config}
                        tabIndex={1}
                        onBlur={(text) => {
                          setDescription(text);
                          description12 = text;
                          var e = document.getElementById("audit-1");
                          e.scrollIntoView({
                            block: "end",
                            behavior: "smooth",
                            inline: "center",
                          });
                        }}
                      ></JoditEditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="portlet-footer portlet-footer-bordered mb-4">
            <div className="col-md-12">
              <button
                class="btn btn-primary me-3"
                onClick={() => {
                  if (isUpdate) {
                    updateModuleData();
                  } else {
                    appModule();
                  }
                }}
              >
                {isUpdate ? "Update" : "Save"}
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  clearData();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <Table
              className="table table-bordered  table-hover mt-3"
              style={{
                visibility: "hidden",
              }}
              id="audit-1"
              columns={[]}
              dataSource={[]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AuditModuleAddUpdate;
