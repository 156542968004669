import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import {
  dashboardSeoHealthChart,
} from "../../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
  fixTwoPlaceDecimal,
  formatRankDate,
} from "../../utility/Globals";
import { DateObject } from "react-multi-date-picker";
import DoughnutCenterCountChart from "../seoaudit/DoughnutCenterCountChart";

function SEOHealth(props) {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [compareendDate, setcompareendDate] = useState("");

  useEffect(() => {

    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    setcompareendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    );

    let rowData = {
      projectId: localStorage.getItem("projectGId"),
      DashboardDateRange: {
        from: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.startDate
        ),
        to: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.endDate
        ),
      },
      CompareDashboardDateRange: {
        from: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.startDate
        ),
        to: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.endDate
        ),
      },
    };

    getSeoHealth(rowData);

  }, [props?.dataParentToChild]);

  useEffect(() => { }, []);
  const [statusData, setStatusData] = useState(["", "Remaining"]);
  const [percentage, setPercentage] = useState(0);
  const [finalScore, setfinalScore] = useState(0);
  const [finalCompScore, setfinalCompScore] = useState([]);

  function getColorOfData(value) {
    if (value > 0) {
      return "digit-green";
    } else if (value < 0) {
      return "digit-red";
    } else {
      return "";
    }
  }

  function getSeoHealth(rowData) {
    setfinalScore([]);
    setfinalCompScore([]);
    setPercentage([])
    APIManager.postAPI(dashboardSeoHealthChart, rowData, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          let list = [];
          let list1 = [];
          response.data.seoHealthResponse.map((item) => {
            let obj = {
              finalScore: item.finalScore,
            };
            list.push(obj);

            if (item.finalScore >= 0 && item.finalScore <= 30) {
              setStatusData(["Needs work", "Remaining"]);
            } else if (item.finalScore >= 30 && item.finalScore <= 75) {
              setStatusData(["Good", "Remaining"]);
            } else if (item.finalScore > 75) {
              setStatusData(["Excellent", "Remaining"]);
            }
          });
          response.data.seoHealthCompareResponse.map((item) => {
            let obj1 = {
              finalScore: item.finalScore,
            };
            list1.push(obj1);
          });
          let finalScoreData = response?.data.seoHealthResponse[0]?.finalScore;
          let finalScoreCompData = response?.data.seoHealthCompareResponse[0]?.finalScore;
          finalScoreData = finalScoreData == null ? 0 : finalScoreData;
          finalScoreCompData = finalScoreCompData == null ? 0 : finalScoreCompData;
          setfinalScore(finalScoreCompData.toFixed(0), () => {
            getDoughnutChar(data, finalScoreCompData.toFixed(0))
          });
          setfinalCompScore(finalScoreData.toFixed(0));
          setPercentage(fixTwoPlaceDecimal(finalScoreCompData));
        }
      }
    );
  }

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: statusData,
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
        borderColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
      },
    ],
  };

  function getDoughnutChar(data, finalScore) {
    return (
      <div>
        <DoughnutCenterCountChart data={data} finalScore={finalScore} />
      </div>
    )
  }
  return (
    <>
      <div className="title">
        <h4>SEO Health</h4>
      </div>
      <div className="dash-content py-5 my-4">
        <div className="d-flex align-items-center justify-content-center seo-health-doughnut">
          <Doughnut
            data={data}
            options={{
              cutoutPercentage: 80,
              responsive: true,
              maintainAspectRatio: true,
              tooltips: {
                enabled: false
              },
              legend: {
                display: false,
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
            }}
          />
          <span class="hover-img ranking-img">
            <div height="200" width="300" className="d-flex">
              <div class="row">
                <div className="d-flex align-items-center p-0">
                  <table>
                    <tr>
                      <td className="traffic-value border-rightside px-3">
                        {(finalScore, Math.round(finalScore))}
                      </td>
                      <td className="traffic-value border-rightside px-3">
                        {(finalCompScore, Math.round(finalCompScore))}
                      </td>
                      <td className="traffic-value  px-3 text-center">
                        <div
                          className={getColorOfData(
                            Math.round(finalScore) - Math.round(finalCompScore)
                          )}
                        >
                          {Math.round(finalScore) - Math.round(finalCompScore)}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="traffic-date border-rightside px-3">
                        {formatRankDate(startDate)}
                      </td>
                      <td className="traffic-date border-rightside px-3">
                        {formatRankDate(compareStartDate)}
                      </td>
                      <td className="traffic-date px-3 text-center">Change</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="percentage-text">
          <div className="text-name">{`${(finalScore, Math.round(finalScore))
            }`}</div>
          <div className="audit-total">
            <div
              className={getColorOfData(
                Math.round(finalScore) - Math.round(finalCompScore)
              )}
            >             
              {finalCompScore  == 0 ? 0 : (Math.round((finalScore - finalCompScore) * 100 / finalCompScore))}%
            </div>
          </div>
        </div>
        <div className="mt-3 dashboard-marker">
          <div className="d-flex align-items-center  justify-content-center">
            <div className="excellent-dot me-2 mt-0 mb-0"></div>
            <label className="me-3 pt-2">Excellent</label>
            <div className="good-dot me-2"></div>
            <label className="me-3 pt-2">Good</label>
            <div className="needwork-dot me-2"></div>
            <label className="pt-2">Needs Work</label>
          </div>
          <div className="mt-4"></div>
        </div>
      </div>
    </>
  );
}

export default SEOHealth;
