import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "antd/dist/antd.css";
import { Table, Input } from "antd";
import {
  exportInToCSV,
  exportToEXCEL,
  mmDDyyyyFormateDateSet,
  findUnique,
  mmDDyyyyFormateDate,
} from "../utility/Globals";
import {
  documentFileType,
  getCustomerByUserId,
  getMemberByOrgId,
  GET_Server_URL,
  memberImport,
  memberIsActive,
  customerImport,
  getCustomerByOrgId,
  getfreelancerByorg,
  delelefreelancerconfig,
  freelancerIsActive,
} from "../utility/Constant";
import APIManager from "../services/APIManager";
import { Sorter } from "../common/Sorter";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import EditEmployeeDir from "../components/EditEmployeeDir";
import CustomizeDashboard from "./configuration/CustomizeDashboard";
import TeamMembersSalesDirAddUpdateCustomer from "./TeamMembersSalesDirAddUpdateCustomer";
import { toast } from "react-hot-toast";

import TeamMembersSalesDirAddUpdate from "./TeamMembersSalesDirAddUpdate";
import { DnsRounded } from "@mui/icons-material";
import { saveAs } from "file-saver";

function TeamMembersSalesDir({ update }) {
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [selectedExportListCust, setSelectedExportListCust] = useState([]);
  const [selectedExportListfreelancer, setSelectedExportListfreelancer] = useState([]);
  const [exportListCust, setExportListCust] = useState([]);
  const [exportListfreelancer, setExportListfreelance] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [customerlist, setCustomerList] = useState([]);
  const [freelancerlist, setfreelancerlist] = useState([]);
  const [customerlistFilter, setCustomerListFilter] = useState([]);
  const [freelancerlistfilter, setfreelancerlistfilter] = useState([]);
  const [teamlistTemp, setteamListTemp] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [customercol, setcustomercol] = useState([]);
  const [freelancercol, setfreelancercol] = useState([]);
  const history = useHistory();
  const [memberlist, setmemberlist] = useState([]);
  const [TeamMembersalesTab, setTeamMembersalesTab] = useState(true);
  const [employeeTab, setemployeeTab] = useState(false);
  const [settings, setSetting] = useState("user");
  const notifyAdd = () => toast.success("Successfully Added!");
  const [freelancerTab, setfreelancerTab] = useState(false);
  useEffect(() => {
    getTeamMemberList();
    getCustomerList();
    getfreelancerList();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          code: item.code,
          name: item.name1,
          role: item.role,
          department: item.department,
          JoiningDate: item.JoiningDate,
          supervisor: item.supervisor,
          srSupervisor: item.srSupervisor,
          dob: item.dob,
          email: item.email,
          active: item.active,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  const rowSelectionCustomer = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let exportCustObj = {
          name: item.name,
          code: item.code,
          phoneNumber: item.phoneNumber,
          email: item.email,
          active: item.active,
        };
        list.push(exportCustObj);
      });
      setSelectedExportListCust(list);
    },
  };
  const rowSelectionfreelancer = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let exportfreelancerObj = {
          category: getfreelancercategory(item.category),
          type: getfreelancertype(item.type),
          phonenumber: item.phonenumber,
          email: item.email,
          artilcleday: item.articlesPerDay,
          costword: item.costword,
          costarticle: item.costarticle,
          niche: item.niche,
          samplework: item.samplework,
        };
        list.push(exportfreelancerObj);
      });
      setSelectedExportListfreelancer(list);
    },
  };
  function updateStatus(id, isActive) {
    APIManager.postAPI(
      memberIsActive + "?MemberId=" + id + "&isActive=" + isActive,
      {},
      null,
      true
    ).then((response) => {
      if (response.status == 200) {
        getTeamMemberList();
        getCustomerList();
      }
    });
  }

  function getUserbyClientIdData(clientId, item) {
    APIManager.getAPI(getCustomerByUserId + clientId).then((response) => {
      if (response && response.status == 200) {
        let projectid = [];
        if (response.data.customerProjectMappings.length > 0) {
          response.data.customerProjectMappings.map((item1) => {
            let obj = {
              id: item1.projectId,
              key: item1.projectId,
              label: item1.project.projectCode,
              project: item1.project.projectCode,
              value: item1.project.projectCode,
            };
            projectid.push(obj);
          });
        }
        history.push({
          // pathname: "/main/addupdate-customer-team-members-sales-dir",
          state: {
            isUpdate: true,
            set_SelectObj: item,
            set_Name: item.user.name,
            set_Customercode: item.user.code,
            set_CustomerEmailid: item.user.email,
            set_PhoneNumber: item.user.phoneNumber,
            set_AuthenticationType: item.user.authenticationType,
            set_clientId: item.clientId,
            set_projectId: projectid,
            set_Password: item.user.password,
            set_UserId: item.userId,
          },
        });
        setSetting("updateCustomer");
      }
    });
  }

  function getTeamMemberList() {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        // const _list = [];
        const exportList = [];
        const exportListCustomer = [];
        const list2 = [];
        list2.push({
          id: 0,
          name: "Select Supervisor",
        });
        const filterDes = [];
        const filterDept = [];
        const filterSup = [];
        const filterSrSup = [];
        response.data.map((item) => {
          if (item.role?.name != "Customer" && item.role?.name != "Freelancer") {
            let obj = {
              key: item.id,
              code: item.code,
              name1: item.name,
              name:
                item.role?.name != "Customer" ? (
                  <Link
                    onClick={() => {
                      setSetting("editEmployeeDir");
                    }}
                    to={{
                      // pathname: "/main/edit-employee-dir",
                      state: { userId: item.id },
                    }}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <Link
                    onClick={() => {
                      setSetting("customizeDashboard");
                    }}
                    to={{
                      // pathname: "/main/customize-dashboard",
                      state: { userId: item.id },
                    }}
                  >
                    {" "}
                    {item.name}
                  </Link>
                ),
              email: item.email,
              role: item.role?.name,
              department: item.departments?.name,
              active: item.active,
              actde: (
                <label class="switch">
                  <input
                    type="checkbox"
                    checked={item.active}
                    // disabled={
                    //   getCheckPermission("active", "team_member") ? false : true
                    // }
                    onClick={() => {
                      updateStatus(item.id, !item.active);
                    }}
                  />
                  <span class="slider round"></span>
                </label>
              ),
              act: (
                <div className="text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon"
                    onClick={() => {
                      if (item.role.name != "Customer") {
                        setSetting("addUpdateTeamMember");
                      }
                      item.role.name == "Customer"
                        ? getUserbyClientIdData(item.id, item)
                        : history.push({
                          // pathname: "/main/addupdate-team-members-sales-dir",
                          state: {
                            isUpdate: true,
                            set_SelectObj: item,
                            set_name: item.name,
                            set_code: item.code,
                            set_Status: item.active,
                            set_designation: item.roleId,
                            set_department: item.departments,
                            set_departmentId: item.departmentId,
                            set_joinedOn: mmDDyyyyFormateDateSet(item.joinedOn),
                            set_supervisor: item.supervisor,
                            set_srSupervisor: item.srSupervisor,
                            set_dob: mmDDyyyyFormateDateSet(item.dob),
                            set_phoneNumber: item.phoneNumber,
                            set_email: item.email,
                            set_salary: item.salary,
                            set_LastWorkingDate: mmDDyyyyFormateDateSet(
                              item.lastWorkingDate
                            ),
                            set_OrganizationId: item.organizationId,
                            set_RoleId: item.roleId,
                            set_memberlistSelected: item.supervisorId,
                            set_EmpSrSupervisorselected: item.srSupervisorId,
                            set_AuthenticationType: item.authenticationType,
                            set_password: item.password,
                          },
                        });
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </div>
              ),
              password: item.password,
              phoneNumber: item.phoneNumber,
              JoiningDate: mmDDyyyyFormateDate(item.joinedOn),
              dob: mmDDyyyyFormateDate(item.dob),
              supervisor: item.supervisor?.name,
              srSupervisor: item.srSupervisor?.name,
              phoneNumber: item.phoneNumber,
              salary: item.salary,
              authenticationType: item.authenticationType === 0 ? "Custom" : "Google",
              lastWorkingDate: item.lastWorkingDate != null ? mmDDyyyyFormateDateSet(item.lastWorkingDate) : ""
            };

            // if (item.role?.name == "Customer") {
            //   // _list.push(obj);
            //   let exportCustObj = {
            //     name: item.name,
            //     code: item.code,
            //     client: "Client Name",
            //     phoneNumber: item.phoneNumber,
            //     email: item.email,
            //     active: item.active,
            //   };
            //   exportListCustomer.push(exportCustObj);
            // }
            let exportObj;
            if (item.role?.name != "Customer" && item.role?.name != "FreeLancer") {
              list.push(obj);
              exportObj = {
                code: item.code,
                name: item.name,
                role: item.role?.name,
                department: item.departments?.name,
                JoiningDate: item.joinedOn,
                supervisor: item.supervisor?.name,
                srSupervisor: item.srSupervisor?.name,
                dob: item.dob,
                phoneNumber: item.phoneNumber,
                email: item.email,
                salary: item.salary,
                authenticationType: item.authenticationType === 0 ? "Custom" : "Google",
                lastWorkingDate: item.lastWorkingDate != null ? mmDDyyyyFormateDateSet(item.lastWorkingDate) : "",
                active: item.active,
              };
              exportList.push(exportObj);
            }
            let filterDesObj = {
              text: item.role?.name,
              value: item.role?.name,
            };
            if (filterDesObj?.value) filterDes.push(filterDesObj);
            let filterDeptObj = {
              text: item.departments?.name,
              value: item.departments?.name,
            };
            if (filterDeptObj?.value) filterDept.push(filterDeptObj);

            let filterSupObj = {
              text: item.supervisor?.name,
              value: item.supervisor?.name,
            };
            if (filterSupObj?.value) filterSup.push(filterSupObj);
            let filterSrSupObj = {
              text: item.srSupervisor?.name,
              value: item.srSupervisor?.name,
            };
            if (filterSrSupObj?.value) filterSrSup.push(filterSrSupObj);

            let obj2 = {
              id: item.id,
              name: item.name,
            };
            list2.push(obj2);
          }
        });
        setteamList(list);
        // setCustomerList(_list);
        // setCustomerListFilter(_list);

        setteamListTemp(list);
        setExportList(exportList);
        // setExportListCust(exportListCustomer);
        setmemberlist(list2);
        const columns = [
          {
            title: "Code",
            dataIndex: "code",
            key: "code",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.code, b.code),
              multiple: 3,
            },
          },
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name1, b.name1),
              multiple: 3,
            },
          },
          {
            title: "Designation",
            dataIndex: "role",
            key: "role",
            filters: findUnique(filterDes, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.role == value;
            },
          },
          {
            title: "Department",
            dataIndex: "department",
            key: "department",
            filters: findUnique(filterDept, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.department == value;
            },
          },
          {
            title: "joining Date",
            dataIndex: "JoiningDate",
            key: "JoiningDate",
          },
          {
            title: "Supervisor",
            dataIndex: "supervisor",
            key: "supervisor",
            filters: findUnique(filterSup, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.supervisor == value;
            },
          },
          {
            title: "Sr. Supervisor",
            dataIndex: "srSupervisor",
            key: "srSupervisor",
            filters: findUnique(filterSrSup, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.srSupervisor == value;
            },
          },
          {
            title: "DOB",
            dataIndex: "dob",
            key: "dob",
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.email, b.email),
              multiple: 3,
            },
          },
          {
            title: "Salary",
            dataIndex: "salary",
            key: "salary",
          },
          {
            title: "Authentication Type",
            dataIndex: "authenticationType",
            key: "authenticationType",
          },
          {
            title: "Last Working Date",
            dataIndex: "lastWorkingDate",
            key: "lastWorkingDate",
          },
          {
            title: "Activate / Deactivate",
            dataIndex: "actde",
            key: "actde",
          },
          {
            title: "Action",
            dataIndex: "act",
            key: "act",
          },
        ];
        setteamcol(columns);
      }
    });
  }

  function getCustomerList() {
    APIManager.getAPI(
      getCustomerByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        let exportList = [];
        let obj, expObj;
        response.data.map((item) => {
          obj = {
            name1: item.user.name,
            name: (
              <Link
                onClick={() => {
                  setSetting("customizeDashboard");
                }}
                to={{
                  // pathname: "/main/customize-dashboard",
                  state: { userId: item.id },
                }}
              >
                {" "}
                {item.user.name}
              </Link>
            ),
            code: item.user.code,
            client: item.client.name,
            phoneNumber: item.user.phoneNumber,
            email: item.user.email,
            authenticationType: getAuthenticationType(item.user.authenticationType),
            // actde: item.user.act,
            actde: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item.user.active}
                  // disabled={
                  //   getCheckPermission("active", "team_member") ? false : true
                  // }
                  onClick={() => {
                    updateStatus(item.userId, !item.user.active);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
            // act: "act",
            act: (
              <div className="text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit edit-icon"
                  onClick={() => {
                    getUserbyClientIdData(item.userId, item);
                  }}
                >
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
              </div>
            ),
          };

          list.push(obj);

          expObj = {
            name: item.user.name,
            code: item.user.code,
            client: item.client.name,
            phoneNumber: item.user.phoneNumber,
            email: item.user.email,
            active: item.user.active,
          };
          exportList.push(expObj);
        });

        setCustomerList(list);
        setCustomerListFilter(list);
        setExportListCust(exportList);
        const columnscust = [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name1, b.name1),
              multiple: 3,
            },
          },
          {
            title: "Code",
            dataIndex: "code",
            key: "code",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.code, b.code),
              multiple: 3,
            },
          },
          {
            title: "Client",
            dataIndex: "client",
            key: "client",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.client, b.client),
              multiple: 3,
            },
          },
          {
            title: "Phone Number",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.phoneNumber, b.phoneNumber),
              multiple: 3,
            },
          },
          {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.email, b.email),
              multiple: 3,
            },
          },
          {
            title: "Authentication Type",
            dataIndex: "authenticationType",
            key: "authenticationType",
          },
          {
            title: "Activate / Deactivate",
            dataIndex: "actde",
            key: "actde",
          },
          {
            title: "Action",
            dataIndex: "act",
            key: "act",
          },
        ];
        setcustomercol(columnscust);
      }
    });
  }
  function filterDataFromList(textData) {
    setteamList(
      teamlistTemp.filter(
        (person) =>
          person.name1
            .toLocaleLowerCase()
            .includes(textData.toLocaleLowerCase()) ||
          person.code.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
  }

  function filterDataFromListCustomer(textData) {
    setCustomerList(
      customerlistFilter.filter(
        (person) =>
          person.name1
            .toLocaleLowerCase()
            .includes(textData.toLocaleLowerCase()) ||
          person.code.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
  }
  function filterDataFromListfreelancer(textData) {
    setfreelancerlist(
      freelancerlistfilter.filter(
        (person) =>
          person?.name
            .toLocaleLowerCase()
            .includes(textData.toLocaleLowerCase()) ||
          person?.email.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
  }
  function uploadTeamMemberFile() {
    var input = document.getElementById("teamMemberFile");
    teammemberImport(input.files.item(0));
    resetFileInput();
  }
  function uploadCustomerFile() {
    var input = document.getElementById("customerFile");
    customerImportFun(input.files.item(0));
    resetFileInput1();
  }

  function teammemberImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      memberImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getTeamMemberList();
        getCustomerList();
      }
    });
  }

  function customerImportFun(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      customerImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getTeamMemberList();
        getCustomerList();
      }
    });
  }

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  const onBtnClick1 = () => {
    inputFileRef1.current.click();
  };
  const resetFileInput = () => {
    inputFileRef.current.value = null;
  };
  const resetFileInput1 = () => {
    inputFileRef1.current.value = null;
  };
  const inputFileRef = useRef(null);
  const inputFileRef1 = useRef(null);

  let onClickFunction = (arg) => {
    if (arg === 'userReset') {
      setSetting('user');
      setTeamMembersalesTab(true);
      setemployeeTab(false);
      setfreelancerTab(false);
    } else {
      setSetting(arg);
      getTeamMemberList();
      getCustomerList();
    }
  };
  function getComponent() {
    if (settings == "editEmployeeDir") {
      return <EditEmployeeDir update={onClickFunction} />;
    } else if (settings == "customizeDashboard") {
      return <CustomizeDashboard update={onClickFunction} />;
    } else if (settings == "updateCustomer") {
      return <TeamMembersSalesDirAddUpdateCustomer update={onClickFunction} />;
    } else if (settings == "addUpdateTeamMember") {
      return <TeamMembersSalesDirAddUpdate update={onClickFunction} />;
    }
  }

  function getAuthenticationType(value) {
    if (value == 0) {
      return "Custom"
    }
    else if (value == 1) {
      return "Google"
    }
  }
  const freelancertype = [
    {
      value: "0",
      lable: "Internal",
    },
    {
      value: "1",
      lable: "External",
    },
    {
      value: "2",
      lable: "Agency",
    },
  ];
  const freelancercategory = [
    {
      value: "0",
      lable: "Writer",
    },
    {
      value: "1",
      lable: "Editor",
    },
    {
      value: "2",
      lable: "Writer_Editor",
    },
  ];
  function getfreelancertype(val) {
    let finalVal = val;
    freelancertype.map((i) => {
      if (val == i.value) {
        finalVal = i.lable;
      }
    });
    return finalVal;
  }
  function getfreelancercategory(val) {
    let finalVal = val;
    freelancercategory.map((i) => {
      if (val == i.value) {
        finalVal = i.lable;
      }
    });
    return finalVal;
  }

  function updateFreelancerStatus(id, isActive) {
    APIManager.postAPI(
      freelancerIsActive + "?id=" + id + "&isActive=" + isActive,
      {},
      null,
      true
    ).then((response) => {
      if (response.status == 200) {
        getfreelancerList();
      }
    });
  }

  function getfreelancerList() {
    APIManager.getAPI(
      getfreelancerByorg + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        let exportList = [];
        let obj, expObj;
        response.data.map((item) => {
          obj = {
            key: item.id,
            name: item.firstName + " " + item.lastName,
            category: getfreelancercategory(item.freelancerCategory),
            type: getfreelancertype(item.freelancerType),
            phonenumber: item.mobile,
            email: item.email,
            artilcleday: item.articlesPerDay,
            costword: "RS." + (item.costPerWord != null ? item.costPerWord : 0),
            costarticle: "RS." + (item.costPerArticle != null ? item.costPerArticle : 0),
            samplework: <u className="cms-text"><a className="fs-18 fw-4 line-height24 fc-black2" href={GET_Server_URL +"/" +item.sampleWork} download  target="_blank" 
            onClick={() => {
              saveAs(
                GET_Server_URL + item.sampleWork,
                
              );
            }}>{(item.sampleWork == null || item.sampleWork == "null") ? '' : item.sampleWork}</a></u>,
            niche: item.niche.map((i) => {
              return i?.industries?.industriesName
            }).toString(),
            actde: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item.isActive}
                  // disabled={
                  //   getCheckPermission("active", "team_member") ? false : true
                  // }
                  onClick={() => {
                    updateFreelancerStatus(item.id, !item.isActive);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
            act: (
              <div className="text-center"
                onClick={() => {
                  deletefreelancer(item.id);
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2"
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          };
          list.push(obj);
          expObj = {
            name: item.firstName + " " + item.lastName,
            category: getfreelancercategory(item.freelancerCategory),
            type: getfreelancertype(item.freelancerType),
            phonenumber: item.mobile,
            email: item.email,
            artilcleday: item.articlesPerDay,
            costword: item.costPerWord,
            costarticle: item.costPerArticle,
            niche: item.niche.map((i) => {
              return i?.industries?.industriesName
            }).toString(),
            samplework: (item.sampleWork == null || item.sampleWork == "null") ? '' : item.sampleWork,
          };
          exportList.push(expObj);
        });
        setfreelancerlist(list);
        setfreelancerlistfilter(list);
        setExportListfreelance(exportList);
        const columnsfreelancer = [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name, b.name),
              multiple: 3,
            },
          },
          {
            title: "Category",
            dataIndex: "category",
            key: "category",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.category, b.category),
              multiple: 3,
            },
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.type, b.type),
              multiple: 3,
            },
          },
          {
            title: "Phone Number",
            dataIndex: "phonenumber",
            key: "phonenumber",
          },
          {
            title: "Email ID",
            dataIndex: "email",
            key: "email",
          },
          {
            title: "Articles/day",
            dataIndex: "artilcleday",
            key: "artilcleday",
          },
          {
            title: "Cost/Word",
            dataIndex: "costword",
            key: "costword",
          },
          {
            title: "Cost/article",
            dataIndex: "costarticle",
            key: "costarticle",
          },
          {
            title: "Niche",
            dataIndex: "niche",
            key: "niche",
          },
          {
            title: "Sample work",
            dataIndex: "samplework",
            key: "samplework",
          },
          {
            title: "Activate / Deactivate",
            dataIndex: "actde",
            key: "actde",
          },
          {
            title: "Action",
            dataIndex: "act",
            key: "act",
          },
        ];
        setfreelancercol(columnsfreelancer);
      }
    });
  }
  async function deletefreelancer(id) {
    try {
      const response = await APIManager.deleteAPI(delelefreelancerconfig + id);
      if (response == 200) {
        getfreelancerList();
      }
    } catch (e) { }
  }

  return (
    <>
      {settings === "user" ? (
        <>
          <div className="slideInUp team-setting-top">
            <div className="portlet mx-3 mb-0">
              <div class="portlet-header portlet-header-bordered">
                <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
                <h3 className="portlet-title main-title">
                  {" "}
                  {localStorage.getItem("organizationName")} - User
                </h3>

                {/* <button
                  type="button"
                  title="Close"
                  style={{ border: "0", background: "none" }}
                  class="close"
                  // data-dismiss="modal"
                  // aria-label="Close"
                  onClick={() => update("")}
                >
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>


              <div className="row project-dir user-tab mx-0">
                <Tabs>
                  {/* <div className="portlet mb-0"> */}
                  <div className="portlet-header portlet-header-bordered">
                    <TabList className="nav nav-lines portlet-nav">
                      <Tab
                        className={
                          TeamMembersalesTab
                            ? "nav-item nav-link  active "
                            : "nav-item nav-link "
                        }
                        onClick={() => {
                          setTeamMembersalesTab(true);
                          setemployeeTab(false);
                          setfreelancerTab(false);
                        }}
                      >
                        Team Members
                      </Tab>
                      <Tab
                        className={
                          employeeTab
                            ? "nav-item nav-link  active "
                            : "nav-item nav-link "
                        }
                        onClick={() => {
                          setemployeeTab(true);
                          setTeamMembersalesTab(false);
                          setfreelancerTab(false);
                        }}
                      >
                        Customer
                      </Tab>
                      <Tab
                        className={
                          freelancerTab
                            ? "nav-item nav-link  active "
                            : "nav-item nav-link "
                        }
                        onClick={() => {
                          setfreelancerTab(true);
                          setTeamMembersalesTab(false);
                          setemployeeTab(false);
                        }}
                      >
                        Freelancers
                      </Tab>
                    </TabList>
                  </div>
                  {/* </div> */}

                  <TabPanel
                    className={
                      TeamMembersalesTab
                        ? "teammember-content  active "
                        : "teammember-content"
                    }
                  >
                    <div className=" teammember-content">
                      <div className="portlet-body p-0">
                        <div class="common-table px-0">
                          <div class="row">
                            <div class="col-md-4  col-sm-12 search-box mt-3 d-flex teammember-search">
                              <div class="input-group-icon input-group-lg widget15-compact">
                                <div class="input-group-prepend">
                                  <i class="fa fa-search text-primary"></i>
                                </div>
                                <div
                                  class="input-group"
                                  style={{ display: "block" }}
                                >
                                  <Input
                                    allowClear
                                    style={{ height: 36 + "px" }}
                                    type="text"
                                    class="form-control"
                                    placeholder="Type to search..."
                                    onChange={(nameSearch) => {
                                      filterDataFromList(nameSearch.target.value);
                                    }}
                                    id="input-s"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8 col-sm-12 text-right mt-3 pe-0 teammember-tab">
                              {/* {getCheckPermission("add", "team_member") ? ( */}
                              <button
                                className="btn btn-primary add"
                                onClick={() => {
                                  setSetting("addUpdateTeamMember");
                                  history.push({
                                    // pathname:
                                    //   "/main/addupdate-team-members-sales-dir",
                                    state: {
                                      isUpdate: false,
                                    },
                                  });
                                }}
                              >
                                Add
                              </button>
                              {/* ) : (
                            <></>
                          )} */}
                              <input
                                style={{ display: "none" }}
                                type="file"
                                id="teamMemberFile"
                                name="teamMemberFile"
                                multiple={false}
                                accept={documentFileType}
                                ref={inputFileRef}
                                onChange={uploadTeamMemberFile}
                              />
                              <a
                                className="btn btn-primary mx-2 team-download download-temp"
                                target="_blank"
                                href={GET_Server_URL + "audit/Member.xlsx"}
                              >
                                Download Template
                              </a>
                              <span>
                                <button
                                  className="btn btn-primary import"
                                  onClick={onBtnClick}
                                >
                                  Import
                                </button>
                              </span>
                              <span class="dropdown">
                                {/* {getCheckPermission("export", "team_member") ? ( */}
                                <button
                                  class="btn btn-primary dropdown-toggle mx-2 export-btn"
                                  type="button"
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Team-members"
                                    );
                                  }}
                                >
                                  Export
                                </button>
                                {/* ) : (
                              <></>
                            )} */}
                                {/* <div
                                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : exportList,
                                        "Team-members"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">Excel</a>
                                  </div>
                                  <div
                                    onClick={() => {
                                      exportInToCSV(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : exportList,
                                        "Team-members"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">CSV</a>
                                  </div>
                                </div> */}
                              </span>
                            </div>
                          </div>

                          <div className="col-md-12 ">
                            <Table
                              className="table table-bordered table-hover mt-3 team-member-table"
                              columns={teamcol}
                              dataSource={teamlist}
                              rowSelection={{ type: "checkbox", ...rowSelection }}
                              pagination={{
                                position: ["bottomRight"],
                                showSizeChanger: true,
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    className={
                      employeeTab
                        ? "customer-content  active "
                        : "customer-content"
                    }
                  >
                    <div className=" customer-content">
                      <div className="portlet-body p-0 ">
                        <div class="common-table px-0">
                          <div class="row">
                            <div class="col-md-4  col-sm-12 search-box mt-3 d-flex employee-search">
                              <div class="search-box">
                                <div
                                  class="input-group"
                                  style={{ display: "block" }}
                                >
                                  <div class="input-group-icon input-group-lg widget15-compact">
                                    <div class="input-group-prepend">
                                      <i class="fa fa-search text-primary"></i>
                                    </div>
                                    <Input
                                      allowClear
                                      type="text"
                                      class="form-control"
                                      placeholder="Type to search..."
                                      onChange={(nameSearch) => {
                                        filterDataFromListCustomer(
                                          nameSearch.target.value
                                        );
                                      }}
                                      id="input-s"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8 text-end mt-3 pe-0 employee-tab">
                              <div class="data-export mb-3">
                                <span class="export mb-2">
                                  <button
                                    className="btn btn-primary mx-2 download-temp"
                                    onClick={() => {
                                      setSetting("updateCustomer");
                                      history.push({
                                        // pathname:
                                        //   "/main/addupdate-customer-team-members-sales-dir",
                                        state: {
                                          isUpdate: false,
                                        },
                                      });
                                    }}
                                  >
                                    Add
                                  </button>
                                  <a
                                    class="btn btn-primary download-temp"
                                    style={{ width: "auto" }}
                                    target="_blank"
                                    href={GET_Server_URL + "audit/Customer.xlsx"}
                                  >
                                    Download Template
                                  </a>
                                  <button
                                    class="btn btn-primary mb-0 mx-3 import"
                                    onClick={onBtnClick1}
                                  >
                                    Import
                                  </button>
                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="customerFile"
                                    name="customerFile"
                                    multiple={false}
                                    accept={documentFileType}
                                    ref={inputFileRef1}
                                    onChange={uploadCustomerFile}
                                  />
                                </span>
                                <span class="dropdown">
                                  <button
                                    class="btn btn-primary dropdown-toggle export-btn"
                                    type="button"
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedExportListCust?.length > 0
                                          ? selectedExportListCust
                                          : exportListCust,
                                        "Customers"
                                      );
                                    }}
                                  >
                                    Export
                                  </button>
                                  {/* <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedExportListCust?.length > 0
                                            ? selectedExportListCust
                                            : exportListCust,
                                          "Customers"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">Excel</a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        exportInToCSV(
                                          selectedExportListCust?.length > 0
                                            ? selectedExportListCust
                                            : exportListCust,
                                          "Customers"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">CSV</a>
                                    </div>
                                  </div> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Table
                          className="table table-bordered table-hover employee-table"
                          id="datatable-1"
                          columns={customercol}
                          dataSource={customerlist}
                          rowSelection={{
                            type: "checkbox",
                            ...rowSelectionCustomer,
                          }}
                          pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel
                    className={
                      freelancerTab
                        ? "customer-content  active "
                        : "customer-content"
                    }
                  >
                    <div className=" customer-content">
                      <div className="portlet-body p-0 ">
                        <div class="common-table px-0">
                          <div class="row">
                            <div class="col-md-4  col-sm-12 search-box mt-3 d-flex employee-search">
                              <div class="search-box">
                                <div
                                  class="input-group"
                                  style={{ display: "block" }}
                                >
                                  <div class="input-group-icon input-group-lg widget15-compact">
                                    <div class="input-group-prepend">
                                      <i class="fa fa-search text-primary"></i>
                                    </div>
                                    <Input
                                      allowClear
                                      type="text"
                                      class="form-control"
                                      placeholder="Type to search..."
                                      onChange={(nameSearch) => {
                                        filterDataFromListfreelancer(
                                          nameSearch.target.value
                                        );
                                      }}
                                      id="input-s"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-8 text-end mt-3 pe-0 employee-tab">
                              <div class="data-export mb-3">
                                <span class="export mb-2">

                                  <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="customerFile"
                                    name="customerFile"
                                    multiple={false}
                                    accept={documentFileType}
                                    ref={inputFileRef1}
                                    onChange={uploadCustomerFile}
                                  />
                                </span>
                                <span class="dropdown">
                                  <button
                                    class="btn btn-primary dropdown-toggle export-btn"
                                    type="button"
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedExportListfreelancer?.length > 0
                                          ? selectedExportListfreelancer
                                          : exportListfreelancer,
                                        "FreeLancer"
                                      );
                                    }}
                                  >
                                    Export
                                  </button>
                                  {/* <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedExportListfreelancer?.length > 0
                                            ? selectedExportListfreelancer
                                            : exportListfreelancer,
                                          "FreeLancer"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">Excel</a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        exportInToCSV(
                                          selectedExportListfreelancer?.length > 0
                                            ? selectedExportListfreelancer
                                            : exportListfreelancer,
                                          "FreeLancer"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">CSV</a>
                                    </div>
                                  </div> */}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <Table
                          className="table table-bordered table-hover employee-table freelancer-table"
                          id="datatable-1"
                          columns={freelancercol}
                          dataSource={freelancerlist}
                          rowSelection={{
                            type: "checkbox",
                            ...rowSelectionfreelancer,
                          }}
                          pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>{getComponent()}</>
      )}
    </>
  );
}

export default TeamMembersSalesDir;
