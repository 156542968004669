// local
// export const GET_FrontEnd_URL = "http://103.14.99.61:8080/";
// export const GET_Server_URL = "http://103.14.99.61/";

// import RequirementDetails from "../components/contentManagement/RequirementDetails";

// Staging (infidigit-server)
export const GET_FrontEnd_URL = "https://growthuatapi.infigrowth.com/";
export const GET_Server_URL = "https://growthuatapi.infigrowth.com/";

// live (infidigit-server)
// export const GET_FrontEnd_URL = "https://growth.infidigit.com/";
// export const GET_Server_URL = "https://growthapi.infidigit.com/";

// live (infidigit-server-USA)
// export const GET_FrontEnd_URL = "https://www.infigrowth.com/";
// export const GET_Server_URL = "https://growthapi.infigrowth.com/";

export const API_BASE_URL = GET_Server_URL + "api";
// export const SERP_API='http://localhost:4000/'
export const SERP_API='https://serp-api-infidigit.demomywebapp.com/'
export const sesson_time_out = 1800000;
export const remainsTimeWhereApiCall = 300000;

export const onlyFileType =
  " .pdf, .docx";

export const onlyPDF = ".pdf";

export const documentFileType =
  ".xlsx, .xls, .csv, .pdf, .docx, .png, .gif, .jpeg, .txt";

export const documentBugfileType = ".png, .jpg, .jpeg, .pdf";

export const imageFileType = "image/*";

export const onlyXlsFile =
  " .xlsx, .xls";

export const indexLoGoImage_URL =
  GET_Server_URL + "images/20220706112204903_1logo.png";
export const indexLoGoImage_URL_dark =
  "https://growth.infidigit.com/assets/images/download/20220706112204903_1logo.png";
export const indexImage1_URL =
  "https://growth.infidigit.com/assets/images/download/20220706112204901_2image.png";
export const indexImage2_URL =
  "https://growth.infidigit.com/assets/images/download/20220706112204902_3image.png";
export const indexImage3_URL =
  "https://growth.infidigit.com/assets/images/download/20220706112204895_4image.png";
export const indexImage4_URL =
  "https://growth.infidigit.com/assets/images/download/20220706112204900_5image.png";
export const indexImage5_URL =
  "https://growth.infidigit.com/assets/images/download/20220706112204889_6image.png";
export const indexImage6_URL =
  "https://growth.infidigit.com/assets/images/download/20220706112204886_7image.png";


export const moduleRankTracking = "RankTracking.pdf";
export const moduleClickShare = "ClickShare.pdf";
export const modulePageSpeed = "PageSpeed.pdf";
export const moduleContentWordCount = "ContentWordCount.pdf";
export const moduleGoogleAnalytics = "GoogleAnalytics.pdf";
export const moduleImpressionsClicksReport = "Impressions & Clicks Report.pdf";
export const moduleSiteUptime = "SiteUptime.pdf";
export const moduleWebsiteAuthority = "WebsiteAuthority.pdf";
export const moduleSERPVolatility = "SERPVolatility.pdf";
export const moduleROI = "ROI.pdf";
export const moduleGoogleTrends = "GoogleTrends.pdf";
export const moduleSEOAudit = "SEOAudit.pdf";
// export const module = ".pdf";

// ClickShare

// Api's
// GET API
export const getRoles = "/Role";
export const getRoleById = "/Role/";
export const getChartData = "/GSC/getChartData";
export const getChartDataCompare = "/GSC/getCompareChartData";
export const getGSCDataCompareDashboard = "/GSC/metricsCompareChart";
export const getTableDataa = "/GSC/GetTableData";
export const getRoleSearch = "/Role/search";
export const getRoleByOrg = "/Role/org/";
export const getrole = "/Role/dept/";
export const getRoleByDept = "/Role/dept/";
export const getCategoryByProject = "/KeywordCategory/project/";
export const getDepartmentById = "/Department/org/";
export const getRankTracking = "/RankTracking/getconfig/";
export const getRankTrackingReportConfig = "/RankTracking/getconfigreport/";
export const getclickshare = "/ClickShare/getClickShareData";
//export const getclicksharechart = "/Clickshare/getGraphData";
export const getAreaGraphData = "/Clickshare/getAreaGraphData";
export const getBarGraphData = "/Clickshare/getBarGraphData";
export const getCity = "/City";
export const getCityByOrgId = "/City/";
export const getClient = "/Client";
export const getCountryByOrg = "/CountryConfiguration/org/";
export const getcountrySEOvolatality = "/SEOVolatality/getCountry";
export const getcategorybycountry = "/SEOVolatality/getSEOCountry";
export const getClientByClientId = "/Client/";
export const getClientByOrgId = "/Client/clientsByOrgId/";
export const getOrganicResearchConfig = "/OrganicResearch/getconfig/";
export const getClientSearch = "/Client/search";
export const getProfile = "/Profile/";
export const getProject = "/Project";
export const getProjectById = "/Project/";
export const getProjectByOrgId = "/Project/org/";
export const getProjectByClientId = "/Project/projectByClientId/";
export const getProjectSearch = "/Project/search";
export const getProjectAssignment = "/ProjectAssignment";
export const getProjectAssignmentById = "/ProjectAssignment/Project/";
export const getProjectModule = "/ProjectModule";
export const getProjectModuleById = "/ProjectModule/";
export const getProjectModuleByProjectId = "/ProjectModule/project/";
export const getGoogleTrandGetConfig = "/GoogleTrend/getConfig/";
export const getSiteUptimeMonitorGetConfig = "/SiteUptime/getconfig/";
export const getContentWordGetConfig = "/ContentWord/getconfig/";
export const getPageSpeedGetConfig = "/PageSpeed/getConfig/";
export const getProjectModuleSearch = "/ProjectModule/search";
export const getProjectNotes = "/ProjectNotes";
export const getMemberIdByPieChartData = "/ProjectAssignment/getAllocation/";
export const getProjectNotesById = "/ProjectNotes/";
export const getAudit = "/Audit";
export const getAuditById = "/Audit/";
export const getAuditByOrgId = "/Audit/org/";
export const getAuditModule = "/AuditModule";
export const getAuditModuleByOrgId = "/AuditModule/Org/";
export const getAuditModuleByOrgId2 = "/auditmodule/auditByOrg/";
export const getAuditModuleById = "/AuditModule/";
export const getcategory = "/audit/category";
export const getAuditSearch = "/Audit/search";
export const getseodatachart = "/SEOVolatality/getChartData";
export const getseodatachartbydevice = "/SEOVolatality/getChartDataByDevice";
export const getSeoAuditList = "/SEOAudit/getSeoAuditList/";
export const getSeoAuditItem = "/SEOAudit/getSeoAuditItem/";
export const getSeoDetailedAuditItem = "/SEOAudit/getDetailAudit/";
export const getSeoAuditItemByModuleId = "/SEOAudit/getSeoAuditItemByModuleId?";
// export const getSeoAuditList = "/SEOAudit/getSeoAuditList/";
export const getDashboardConfigurationData = "/DashboardConfiguration/clients/";
export const getSeoAuditDataByProjectAuditandAuditId =
  "/SEOAudit/getSeoAuditDataByProjectAuditandAuditId?";
export const getSEOAudit = "/SEOAudit/";
export const getSEOAuditChartData = "/SEOAudit/implementationChart?";
export const getUrlByProject = "/url/project/";
export const getBacklinkConfigurationProjectId = "/backLink/getConfig/";
export const getBackLink = "/BackLink/config";
export const getCountry = "/Country";
export const getGoogleTrendCountry = "/GoogleTrend/country"
export const getGoogleTrendCategory = "/GoogleTrend/category"
export const getLanguage = "/Language";
export const getOrganizations = "/Organizations";
export const getOrganizationsById = "/Organizations/";
export const getOrganizationsSearch = "/Organizations/search";
export const getSearch = "/Search";
export const getSearchById = "/Search/";
export const getSearchSearch = "/Search/search";
export const getUrl = "/Url";
export const getUrlById = "/Url/";
export const getUrlByOrgId = "/Url/project/";
export const getLinkIntersect = "/backLink/getBackLinkIntersect";
export const getUrlByProjectId = "/DAPA/getURL/";
export const getCompetitorByProjectId = "/DAPA/getCompetitor/";
export const getAllDAPAData = "/DAPA/";
export const getDAPAconfig = "/DAPA/getConfig/";
export const getUrlSearch = "/Url/search";
export const getKeyWord = "/Search";
export const getKeyWordById = "/Search/";
export const getKeyWordByProjectId = "/Search/project/";
// export const getKeyWordByProjectId = "/search/keywordDetails/";
export const getKeyWordideabyuserid = "/KeywordResearch/getKeywordIdeas/";
export const getKeyWordlistbyuser = "/KeywordResearch/getuserconfig/";
export const getKeyWordSearch = "/Search/search";
export const getCompetitors = "/Competitors";
export const getCompetitorsById = "/Competitors/";
export const getCompetitorsByProjectId = "/competitors/project/";
export const contentWordConfigImport = "/ContentWord/configImport";
export const dapaConfigImport = "/DAPA/configImport";
export const siteUpTimeConfigImport = "/SiteUpTime/configImport/";
export const googleTrendsConfigImport = "/GoogleTrend/configImport/";
export const getCompetitorsSearch = "/Competitors/search";
export const getUrlProjectID = "/Url/project/";
export const getpagespeedurl = "/PageSpeed/getURL/";
export const getcontentwordurl = "/ContentWord/getURL/";
export const getkeywordurl = "/ContentWord/getKeyword/";
export const getAccountlist = "/googleAnalytics/account?projectId=";
export const getPropertylist = "/googleAnalytics/properties";
export const getViewSelectionlist = "/googleAnalytics/views";
export const googleAnalyticsConfig = "/googleAnalytics/config";
export const getMember = "/Member";
export const getMemberById = "/Member/";
export const getMemberByOrgId = "/Member/org/";
export const getCustomerByOrgId = "/customer/organization/";
export const getMemberByRoleId = "/Member/memberbyrole/";
export const getAssignMemberByRole = "/Member/assignMemberByRole/";
export const getUserAccess = "/userAccess/";
export const getMemberSearch = "/Member/search";
export const getMemberByDepartmentId = "/Tickets/members?departmentId=";
export const getmemberroleid = "/Member/memberbyrole/"
export const getDashboardConfiguration = "/DashboardConfiguration/";
export const getTicket = "/Ticket";
export const getTicketById = "/Ticket/Module/";
export const getTicketFetch = "/Ticket/Fetch";
export const getTicketNote = "/TicketNote";
export const getTicketNoteById = "/TicketNote/";
export const getSEOVolatality = "/SEOVolatality/Get";
export const getSEOAuditGetById = "/SEOAudit/";
export const getSeoAuditByProjectAuditId = "/SEOAudit/getSeoAuditByProjectAuditId?";
export const getSeoAuditByScoreChartId = "/SEOAudit/scoreChart?";
export const getDomianAuthoritychart = "/ClientDashboard/getDAChart";
export const getPageAuthoritychart = "/ClientDashboard/getPAChart";
export const getSeoAuditResponse = "/SEOAudit/getSeoAuditResponse?auditModuleId=";
export const getAllSeoAuditData = "/SEOAudit/getAllSeoAuditData/";
export const getSeoAuditCategoryData = "/SEOAudit/categoryWiseDetail?";
export const dashboardSiteUptimeChart = "/ClientDashboard/siteUptime?"
export const dashboardSeoHealthChart = "/ClientDashboard/seoHealth";
export const getCategoriesScoreData = "/SEOAudit/categoryScore?";
export const getSEOAuditDetail = "/SEOAudit/auditDetail?"
export const getSEOAuditModuleList = "/SEOAudit/module/"
export const getModule = "/Module";
export const gettabledata = "/Bug/";
export const getModuleById = "/Module/";
export const getRoleAccess = "/RoleAccess/";
export const getPageSpeedData = "/PageSpeed/search?";
export const getClientUser = "/Client/GetClientUser";
export const clientsUserByClientId = "/Client/clientsUserByClientId/";
export const getprojectbyId = "/Project/";
export const getTrendsDetails = "/GoogleTrend/getConfig/";
export const getUserbyClientId = "/customer/getCustomer/";
export const getCustomerByUserId = "/customer/getCustomerByUserId/";
export const getAllCategory = "/SEOVolatality/GetCategory";
export const googleAnalyticsChart = "/googleAnalytics/Get";
export const googleAnalyticsOSChart = "/googleAnalytics/GetOS";
export const googleAnalyticsOSChartCompare = "/googleAnalytics/GetCompareOS";
export const getTicketbyorgId = "/Ticket/org";
export const getClickShare = "/ClickShare/getconfig/";
export const getAllCountry = "/CountryConfiguration/Get/";
export const getAllCountryConfig = "/CountryConfiguration";
export const getAllCityByCountry = "/City/country/";
export const getGoogleAnalyticsConfig = "/googleAnalytics/getconfig/";
export const getGscData = "/GSC/getconfig/";
export const getKeywordResearchConfig = "/KeywordResearch/getconfig/";
export const getResearchkeyworduserconfig = "/KeywordResearch/getuserconfig/";
export const getKeywordideas = "/KeywordResearch/generateKeywordIdeas";
export const getROIConfig = "/ROICalculator/getConfig";
export const getGAConfig = "/ROICalculator/gaData";
export const getMonthlySearchVolumesById =
  "/KeywordResearch/getMonthlySearchVolumes/";
export const getTableDataModule = "/GSC";
export const getDashboardCount = "/DashboardConfiguration/Count/";
export const getOrgCustomizeDashboard = "/CustomizeDashboard/org/";
export const getUserCustomizeDashboard = "/UserCustomizeDashboard/user/";
export const getKeywordTypeAPI = "/KeywordType/project/";
export const getRTKeywordHistory =
  "/RankTracking/getRankTrackingKeywordHistory?projectId=";
export const getRankingTrendsTable = "/RankTracking/getTableDataByUrl/";
export const getRankingTrendsTableExport = "/RankTracking/getTableDataByUrlExport/";
export const getConfigData = "/auth/getClientProjects/";
export const getSiteUpTimeConfig = "/SiteUpTime/getConfig/";
export const getSiteUpTimeTable = "/SiteUpTime/getTableData";
export const getSiteUpTimeFieldTable = "/SiteUpTime/getFieldData";
export const getRoiTableData = "/ROICalculator/GetTableData/"
export const getSiteUp = "/SiteUpTime/getWidget";
export const getwebsitedata = "/SiteUpTime/getServerUptime/";
export const gecExportDataApi = "/GSC/getExportData";
export const getCreditRankingsData = "/project/";
export const getGoogleTrendsConfig = "/GoogleTrend/getConfig/";
export const getAllTicktes = "/Tickets";
export const getAllTicketNotes = "/Tickets/";
export const getTickestAllconversation = "/Tickets/";
export const getTickestSubticketlist = "/Tickets/";

export const getTicketListSOW = "/SOW/search"
export const getattached = "/Tickets/"
export const getTimeline = "/Tickets/"
export const getSOWMonth = "/sow/month"
export const getAllocationDetail = "/SOW/getAllocation/"
export const getdepartment = "/Department/org/"
export const getdashboardview = "/Member/isTeam/"
export const getRankTrackingCompareChart = "/ClientDashboard/RankTrackingCompareChart/"
export const getRankTrackingCompareTable = "/ClientDashboard/rankTrackingCompareTable/"
export const getActiveOrganizations = "/Organizations/activeOrg";
export const setStartRankApi = "/RankingWebsite/StartRanking";
export const getSeoPerformanceWidget = "/ClientDashboard/SEOPerformanceWidget"
export const getticketsdetails = "/Tickets/"
export const getIndustries = "/Industries";
export const ticketsdetails = "/tickets/ticketDetail/"
export const getAllIndustries = "/Industries";
export const getIndustriesById = "/Industries/org/";
export const getfreelancerByorg = "/Freelancer/org/";
//rankingv2API
export const getcompetitorsByRankingcampaignConfigId = "/Ranking/competitor/";
export const getViewCompetitorByRankingConfigId = "/Ranking/viewCompetitor/";
export const getcampaignviewdetail = "/Ranking/campaignViewList/";
export const getcampaignKeyword = "/Ranking/campaignKeyword/";
export const getcampaignViewDetails = "/Ranking/viewData/";
export const getCampaignList1 = "/Ranking/campaignList/";
export const getcampaignviewkeyword = "/Ranking/Keyword/";
export const getcampaignSendforApproval = "/Ranking/sendMailForApproval/";
export const getcampaignbyId = "/Ranking/campaignById/";
export const getcampaignviewbyId = "/Ranking/viewById/";



export const getAssignedMembersWithCustomer = "/Member/assignMembers/"
export const getAssignedMembers = "/Member/members/"
export const getaddkeyword = "/Ranking/ViewKeyword/";
export const getSowExist = "/sow/sowExists/";
export const getCountryCityByOrg = "/CountryConfiguration/getCountryCity/";
export const getconfigcheckdashboard = "/ClientDashboard/seoPerformanceWidgetCheck";




// POST API
export const createClient = "/Client";
export const addAuditmodule = "/auditmodule";
export const addAuditInstruction = "/audit/instruction";
export const getAuditInstruction = "/audit/getInstruction";
export const updateAuditmodule = "/auditmodule";
export const createRole = "/Role";
export const createRoleAccess = "/RoleAccess";
export const updateRoleAccess = "/RoleAccess";
export const useraccesslist = "/UserAccess";
export const userpermission = "/userAccess/";
export const createCategory = "/KeywordCategory";
export const createMember = "/Member";
export const memberImport = "/Member/import/";
export const credentialAddUpdate = "/credential";
export const getCredentialByOrgId = "/credential?organizationId=";
export const addMember = "/Member/AddMember";
export const createProject = "/Project";
export const createProjectAssignment = "/ProjectAssignment";
export const createProjectModule = "/ProjectModule/Add";
export const createProjectModuleUckMap = "/ProjectModuleUckMap/Add";
export const createProjectNotes = "/ProjectNotes";
export const createAudit = "/Audit";
export const auditImport = "/audit/import/";
export const auditImport1 = "/audit/import1/";
export const createOrganizations = "/Organizations";
export const addSearch = "/Search";
export const addSearchImport = "/Search/import";
export const createUrl = "/Url";
export const urlImport = "/url/import/";
export const createCompetitors = "/Competitors";
export const competitorsImport = "/competitors/import/";
export const createKeywords = "/Search";
export const keyWordImport = "/search/import/";
export const createDashboardConfiguration = "/DashboardConfiguration";
export const addTicketAssign = "/Ticket/Assign";
export const addTicket = "/Ticket";
export const addTicketNote = "/TicketNote";
export const clientIsActive = "/Client/IsActive";
export const memberIsActive = "/Member/IsActive";
export const addSEOAudit = "/SEOAudit";
export const postSEOAudit = "/SEOAudit";
export const backlinkinfo = "/backLink/getBackLinks";
export const getRoiCalculatorData = "/ROICalculator/getROICalculatorData";
export const getcpcGSCCalculatorData = "/ROICalculator/gscData";
export const getcpsGAsessionData = "/ROICalculator/gaSessionData";
export const getfilterallviewtickets = "/Tickets/search";
export const createGoogleTrendsData = "/GoogleTrend/config";
export const googletrendslinechart = "/GoogleTrend/trendChart";
export const googletrendsbarchart = "/GoogleTrend/subRegionChart";
export const getallviewtickets = "/Tickets/search";
export const addSOWTicket = "/SOW";
export const sowAllocationTicket = "/SOW/Allocation";
export const sowImport = "/SOW/sowImport";
export const dachart = "/DAPA/getDAChart";
export const pachart = "/DAPA/getPAChart";
export const customerImport = "/Customer/import/";
export const pageSeedSearchDashboard = "/PageSpeed/PageSpeedSearch";
export const getRoiDashboardChart = "/ClientDashboard/roiCompareTable"
export const backlinktable = "/ClientDashboard/backLinkTable";
export const campaignactive = "/Ranking/IsActive/";
export const campaignviewactive = "/ranking/showToClientView/";
//RankingV2API
export const RankngCompietitorImport = "/Ranking/competitorImport/";
export const addrankingcampaignCompetitor = "/Ranking/campaignCompetitor";
export const importrankingcampaignKeyword = "/Ranking/campaignKeywordImport/";
export const Addcampaignkeyword = "/Ranking/campaignKeyword";
export const Addcampaignviewkeyword = "/Ranking/ViewKeyword";
export const createCampingDetail = "/Ranking/campaign";
export const postcampaignview = "/Ranking/CampaignView";
export const getRankingCategoryWise = "/Ranking/categoryWise"
export const getRankingCategoryWiseExport = "/Ranking/categoryWiseExport"
export const getRankingKeywordWise = "/Ranking/keywordWise"
export const getRankingKeywordWiseExport = "/Ranking/keywordWiseExport"
export const importrankingcampaignViewKeyword = "/Ranking/CampaignViewKeyword/";
export const campaignViewsave = "/Ranking/ViewStatus";
export const keywordWiseTable = "/Ranking/keywordTable";
export const keywordWiseTableExport = "/Ranking/keywordTableExport";

export const categoryWiseTable = "/Ranking/CategoryTable ";
export const categoryWiseTableExport = "/Ranking/categoryTableExport";
export const rankingVisibilityAPI = "/Ranking/rankingVisibility";
// export const ticketassign = "/Ticket/Assign";
export const getTicketByOMPid = "/Ticket/Get";
export const updateGTconfig = "/GoogleTrend/config";
export const userAuth = "/Auth";
export const userForgotPassword = "/Auth/forgotPassword?email=";
export const changeReasonSOW = "/Sow/changeReason";
export const userResetPassword = "/Auth/resetPassword";
export const pageSpeedData = "/PageSpeed";
export const pageSpeedDataGet = "/PageSpeed/get";
export const addSEOVolatality = "/SEOVolatality/getData";
export const addClientUser = "/Client/ClientUser";
export const changePassword = "/Auth/changePassword?email=";
export const VerifyUserMembers = "/Member/VerifyUser?email=";
export const postKeywordResearch = "/KeywordResearch/getKeywordData/";
export const getKeywordResearchChart = "/KeywordResearch/getKeywordChartData";
export const getKeywordResearchChartbyuser =
  "/KeywordResearch/getKeywordUserChart";
export const createranktracking = "/RankTracking/config";
export const createClickShare = "/ClickShare/config";
export const createkeywordresearchconfig = "/KeywordResearch/config";
export const getGSCProperty = "/GSC/GetSite";
export const getGAdData = "/googleAnalytics/config";
export const creategscdata = "/GSC/config";
export const createDAPA = "/DAPA/config";
export const googleAnalyticsChannelData =
  "/GoogleAnalytics/GoogleAnalyticsChannelData";
export const googleAnalyticsSearch = "/googleAnalytics/search";
export const gscGetData = "/GSC/get";
export const ClickSharePieChart = "/ClickShare/getPieChartData";
export const GetTableData = "/GSC/GetTableData";
export const gscGetSite = "/GSC/GetSite";
export const addModule = "/Module";
export const addcontentwordcount = "/ContentWord/GetContentWordData";
export const addsiteupmonitor = "/SiteUptimeMonitor/Get";
export const getsiteupgetfielddata = "/SiteUptimeMonitor/GetFieldData/";
export const createCustomer = "/customer";
export const updateCustomer = "/customer";
export const getOrganic = "/OrganicResearch/getOrganicResearch";
export const getOrganicResearch = "/OrganicResearch/config";
//export const getOrganic = "/OrganicResearch/getOrganicResearch";
export const creatDepartment = "/Department";
export const getuserassignment = "/ProjectAssignment/userassignment";
export const getROICalculator = "/ROICalculator/getROICalculatorData";
export const createROIData = "/ROICalculator/config";
export const createBacklink = "/backLink/config";
export const orgnizationIsActive = "/Organizations/IsActive";
export const updatePageSpeedGetConfig = "/PageSpeed/config";
export const updateContentWordGetConfig = "/ContentWord/config";
export const postSiteUptimeMonitorConfig = "/SiteUptimeMonitor/config";
export const addCity = "/City";
export const addCountryConfiguration = "/CountryConfiguration";
export const createRankTrackingdata = "/RankTracking/getRankTrackingDataExcel?";
export const createRankTrackingdataByPage = "/RankTracking/getRankTrackingDataByPage";
export const createRankTrackingDataByCompititor =
  "/RankTracking/getRankTrackingDataByCompititor";
export const createRankTrackingDataByCompititortable =
  "/RankTracking/getRankTrackingDataByCompititorTable";
export const isShown = "/SEOAudit/IsShown/";
export const addNewSeoAudit = "/SEOAudit";
export const copySEOaudit = "/SEOAudit/copyAudit";
export const addUserCutomizeDashboard = "/UserCustomizeDashboard/";
export const addOrgCutomizeDashboard = "/CustomizeDashboard/";
export const addKeywordType = "/KeywordType/AddKeywordType";
export const addKeywordTypeAPI = "/KeywordType/AddKeywordType";
export const sendEmailApi = "/SendEmail";
export const GetTableDataaaa = "/ClickShare/GetTableData";
export const getCountryCode = "/GSC/getCountry";
export const roiConfigImport = "/ROICalculator/roiconfigImport/";
export const cpcConfigImport = "/ROICalculator/cpcconfigImport/";
export const cplConfigImport = "/ROICalculator/cplconfigImport/";
export const addBugReport = "/Bug";
export const googleAnalyticsSearchOS = "/googleAnalytics/search_os";
export const createSiteUpTimeMonitor = "/SiteUpTime/config"
export const createSiteUpchart = "/SiteUpTime/getChartData"
export const getROIchartData = "/ROICalculator/getChartData/"
export const getChartDataROI = "/ROICalculator/getChartData"
export const getTableDataROI = "/ROICalculator/getTableData"
export const getCompareChartDataROI = "/ROICalculator/GetCompareData"
export const getTicketDashboardSummery = "/Tickets/dashboard/summary"
export const getevaltiontabledata = "/Tickets/teamEvaluationClosed"
export const getticketcompleted = "/Tickets/teamEvaluationCompletedOnETA"
export const getTicketsRejected = "/Tickets/teamEvaluationRework"
export const getPristine = "/Tickets/teamEvaluationPristine"
export const getMissedETA = "/Tickets/teamEvaluationMissedETA"
export const getTeamEvaluationGraph = "/Tickets/TeamEvaluationGraph   "
export const getCreditRankings = "/RankTracking/creditUsed/"
export const createTickets = "/Tickets"
export const getTicketDashboard = "/tickets/dashboard/tickets/"
export const getProjectByMember = "/Tickets/Projects/"
export const addattachment = "/Tickets/attachment";
export const pickupticket = "/Tickets/assignment";
export const createTicketNotes = "/Tickets/notes";
export const Ticketassign = "/Tickets/status?";
export const sendApprovalEmail = "/SOW/sendApprovalMail";
export const createconversations = "/tickets/conversations";
export const createteamEvaluationgraph = "/Tickets/TeamEvaluationGraph";
export const CompareteamEvaluationgraph = "/Tickets/teamEvaluationCompareChart";
export const getDashboardROIChartData = "/ClientDashboard/roiChartData"
export const creatIndustry = "/Industries";
export const industryImport = "/Industries/industriesImport/";
export const getmemberbyproject = "/Member/memberList";
export const keywordDistributionHeader = "/Ranking/keywordHeader";


//view Ranking Config
export const addViewCompetitor = "/Ranking/viewCompetitor";
export const importViewCompetitor = "/Ranking/viewCompetitorImport/";
export const reviewRankingDetails = "/Ranking/reviewCampaign/";
export const getKeywordDistributionTable = "/Ranking/rankDistributionTabledata";
export const getKeywordDistributionChart = "/Ranking/rankDistributionChartdata";
export const getQueryData = "/Data";

// PUT API
export const updateRole = "/Role";
export const updateCategory = "/KeywordCategory";
export const updateAuditInstruction = "/Audit";
export const updateClient = "/Client";
export const updateProject = "/Project";
export const updateSeoAudit = "/SEOAudit/updateSeoAudit";
export const updateDAPAconfig = "/DAPA/config";
export const updateProjectAssignment = "/ProjectAssignment";
export const updateProjectModule = "/ProjectModule";
export const updateProjectNotes = "/ProjectNotes";
export const updateAudit = "/Audit";
export const updateCompetitors = "/Competitors";
export const updateOrganizations = "/Organizations";
export const updateSearch = "/Search";
export const updateMember = "/Member";
export const updateProfile = "/Profile";
export const updateTicket = "/Ticket";
export const updateTicketNote = "/TicketNote";
export const updateDepartment = "/Department";
export const updateCity = "/City";
export const updateCountry = "/CountryConfiguration";
export const updateModule = "/Module";
export const updateSEOAudit = "/SEOAudit";
export const seoCompleteSEOAudit = "/SEOAudit/complete/";
export const UpdateKeywordTypeAPI = "/KeywordType/UpdateKeywordType";
export const updateRoiCalculatorConfig = "/ROICalculator"
export const updateSiteUpTimeMonitor = "/SiteUpTime/config"
export const updateGoogleTrends = "/GoogleTrend/config";
export const prioritystatus = "/Tickets/priority?";
export const updateticket = "/Tickets";
export const updateTicketStatus = "/Tickets/status";
export const updateTicketETA = "/Tickets/eta";
export const dashboardTicketsStatus = "/ClientDashboard/TicketsWidget?";
export const dashboardBacklink = "/ClientDashboard/backLinkChart";
export const updateIndustry = "/Industries";
export const updateRankingCompetitor = "/Ranking/updateCompetitor";
export const updatecampaignview = "/Ranking/ViewCampaign";
export const updatecampaigKeyword = "/Ranking/updateKeyword";
export const updateCampingDetail = "/Ranking/campaign";
export const updateCampingviewDetail = "/Ranking/CampaignView";


// DELETE API
export const deleteRole = "/Role/Delete/";
export const deleteSiteuptime = "/SiteUpTime/";
export const deleteGoogleTrends = "/GoogleTrend/"
export const deleteCategory = "/KeywordCategory/delete/";
export const deleteClient = "/Client/Delete/";
export const deleteCity = "/City/Delete/";
export const deleteProject = "/Project/";
export const deleteProjects = "/Project/Delete/";
export const deleteProjectModule = "/ProjectModule/";
export const deleteProjectAssignment = "/ProjectAssignment/Delete/";
export const deleteProjectNotes = "/ProjectNotes/";
export const deleteAudit = "/Audit/";
export const deleteOrganizations = "/Organizations/Delete/";
export const deleteSearch = "/Search/";
export const deleteUrl = "/Url/Delete/";
export const deleteCompetitors = "/Competitors/Delete/";
export const deleteKeyWord = "/Search/Delete/";
export const deleteAllKeyWord = "/Search/allKeywordsDelete/";
export const deleteMember = "/Member/";
export const deleteMemberByMemberId = "/Member/DeleteMemberByMemberId";
export const deleteMemberById = "/Member/DeleteById";
export const deleteTicket = "/Ticket/";
export const deleteTicketNote = "/TicketNote/";
export const deleteModule = "/Module/";
export const deleteprojectModule = "/ProjectModule/Delete/";
export const deleteDepartment = "/Department/Delete/";
export const deleteAuditMainModule = "/AuditModule/Delete/";
export const deleteMultiUrls = "/url/deleteAll";
export const deleteMultikeywords = "/search/deleteAll";
export const deleteMultiKeywordCat = "/KeywordCategory/deleteAll";
export const deleteAllKeywordCat = "/KeywordCategory/AllKeywordCategoryDelete/"
export const deleteMultiCompetitors = "/Competitors/deleteAll";
export const deleteCountryById = "/CountryConfiguration/delete/";
export const deleteCityById = "/City/Delete/";
export const deleteSeoAudit = "/SEOAudit/DeleteById/";
export const deleteSupportingDoc = "/SEOAudit/supportingDoc/";
export const deleteTickets = "/Tickets/";
export const deleteSowAllocation = "/SOW/Delete/"
export const deleteRoleId = "/Role/Delete/"
export const userRefreshToken = "/Auth/checkToken?"
export const deleteSelectedIndustry = "/Industries/"
export const deleteMultiSelectIndustry = "/Industries/multipleDelete";
export const deleteAllIndustry = "/Industries/allIndustriesDelete/";
export const deleteViewCompetitorById = "/Ranking/deleteViewCompetitor/";
export const deleteAllViewCompetitor = "/Ranking/deleteAllViewCompetitor";
//rankingvAPI
export const deleteRankingCompitetorById = "/Ranking/deleteCampaignCompetitor/";
export const deleteAllCompetitors = "/Ranking/deleteAllCompetitor";
export const deletebyviewvampaignId = "/Ranking/deleteAllCompetitor";
export const deletekeywordById = "/Ranking/deleteCampaignKeyword/";
export const deletecampaign = "/Ranking/deleteView/";
export const deleteAllRankingkeyword = "/ranking/deleteAllKeywords";
export const deletecampaignviewkeyword = "/Ranking/deleteViewKeyword/";
export const deleteAllcampaignviewkeyword = "/Ranking/DeleteAllViewKeywords";


export const deletecampaignid = "/Ranking/deleteCampaign/";
export const deleteSOW = "/sow/DeleteDetail/";
export const delelefreelancerconfig = "/Freelancer/";

//RankingV2 Report API
export const getPriorityKeywords = "/Ranking/priorityKeywords";
export const getTopKeywords = "/Ranking/topKeywords";
export const getLatestRankings = "/Ranking/latestRanking";
export const getLatestRankingExport = "/Ranking/latestRankingExport";
export const getPriorityKeywordsExport = "/Ranking/priorityKeywordsExport";


// BandwidthMonitoring API
export const organizationdata = "/Bandwidth/BandwidthAllmember";
export const distributiondropdown = "/Bandwidth/BandwidthBilledTeamMember";
export const piechartbandwidth = "/Bandwidth/BandwidthChart";
export const projectprofiledata = "/Bandwidth/ProjectBandwidthSplit";
export const projectprofile = "/Bandwidth/BandwidthProjectProfile";
export const bandwidthanalysis = "/Bandwidth/BandwidthAnalysis";
export const bandwidthsplit = "/Bandwidth/BandwidthSplit";

//Content Management API
export const addFreelancer = "/Freelancer";
export const freelancerBulkImport = "/Freelancer/freelancerImport"
export const getFreelancers = '/Freelancer/'
export const updateFreelancer = "/Freelancer";
export const getnichelist = "/Industries/org/";
export const getFreelancerUser = "/Freelancer/user";
export const freelancerIsActive = "/Freelancer/IsActive"
// Requirement API
export const requirementProject = '/Requirement/getAllRequirements'
export const getallpoc = "/Requirement/getPOC";
export const getrequirementlist = "/Requirement/listRequirements";
export const requirementBulkImport = "/Requirement/requirementImport";
export const updateProjectPoc = "/Requirement/updatePOC";
export const getEditor = '/Requirement/getAllEditor';
export const getWriter = '/Requirement/getAllWriter';
export const AssignWriter = '/Requirement/assignWriter';
export const AssignEditor = '/Requirement/assignEditor';
export const updateWriterDetail = '/Requirement/updateWriterDetails';
export const updateEditorDetail = '/Requirement/updateEditorDetails';

//CMS Editor API
export const articleDetail = '/Requirement/getAllArticleDetails';
export const addcomment = "/Requirement/comment";
export const addsuggestion = "/Requirement/suggestion";
export const getcomment = "/Requirement/comment/";
export const getsuggesstion = "/Requirement/suggestion/";
export const getdescription = "/Requirement/description/";
export const getVersionHistory = "/Requirement/versionHistory?";
export const adddescription = "/Requirement/description";
export const articleStatus = "/Requirement/status";
export const updatecommentstatus = "/Requirement/commentStatus";
export const updatesuggesstionstatus = "/Requirement/suggestionStatus";
export const updateEditorETA = "/Requirement/editorETA?requirementId=";
export const updateRequirementStatus = "/Requirement/requirementStatus";
export const commentreply = "/Requirement/EditorCommentReply";
export const suggestionreply = "/Requirement/suggestionReply";

export const requirementById = "/Requirement/";
export const getTimelineDetails = "/Requirement/timeline/";
export const ArticleStatus = "/Requirement/getArticleStatus/";

//CMS dashboard API
export const getProjectOverview = "/Dashboard/dashboardWidget";
export const getAssignedProjects = "/Dashboard/getPOCProject?";

//SERPTracker API
export const getSerplistData = "/RankingWebsite/RankingList"
export const uploadDocument = "/RankingWebsite/upload_keywords";
export const downloadFileData="/RankingWebsite/download"