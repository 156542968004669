import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import {
  getCompetitorsByProjectId,
  getPageSpeedGetConfig,
  getUrlByOrgId,
  updatePageSpeedGetConfig,
  createUrl,
} from "../../utility/Constant";
import {
  deviceType,
  frequencyListKeyValue,
  selectAllList,
  themeStyle,
} from "../../utility/Globals";
import toast from "react-hot-toast";
const PageSpeed = ({ update }) => {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const history = useHistory();
  const [frequency2, setFrequency2] = useState("1");
  const [deviceType2, setDeviceType2] = useState("0");
  const [IsActive, SetIsActive] = useState(false);
  const [IsActive1, SetIsActive1] = useState(false);
  const [urlName, setURLName] = useState("");
  const [competionrs, setCompetionrsSelected] = useState([]);
  const [CompetitorsList, setCompetitorsList] = useState([]);
  const [urlList, setUrlList] = useState([]);
  const [selectedUrl, setUrlSelected] = useState([]);
  const [competitors, setCompetitors] = useState("");
  const [oldUrlList, setOldUrlList] = useState([]);
  const [oldCompUrlList, setOldCompUrlList] = useState([]);
  const notifyUpdate = () => toast.success("Successfully Updated!");

  useEffect(() => {
    getUrlList();
    getCompetitorsList();
    getPageSpeedData();
  }, []);

  function getUrlList() {
    APIManager.getAPI(getUrlByOrgId + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          list.push({
            count: 0,
            key: "key",
            value: "Select All",
            label: "Select All",
            id: 0,
            url: "Select All",
          });

          response.data.map((item) => {
            let tempArray = {
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              url: item.fieldValue,
            };
            list.push(tempArray);
          });
          setUrlList(list);
          getCompetitorsList();
        }
      }
    );
  }

  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setURLName("");
        getUrlList();
        SetIsActive(false);
      }
    });
  }

  function addCompetitors() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 1,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: competitors,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setCompetitors("");
        getCompetitorsList();
        SetIsActive1(false);
      }
    });
  }

  function getCompetitorsList() {
    APIManager.getAPI(
      getCompetitorsByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          count: 0,
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          url: "Select All",
        });
        response.data.map((item) => {
          let tempArray = {
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            url: item.fieldValue,
          };
          list.push(tempArray);
        });
        setCompetitorsList(list);
      }
    });
  }

  function getPageSpeedData() {
    APIManager.getAPI(
      getPageSpeedGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response?.data) {
          let selectedComp = [];
          response?.data?.pageSpeedUrlMappingConfigurations.map((item) => {
            let compObj = {
              comp: item.competitor.fieldValue,
              id: item.competitor.id,
              key: item.competitor.id,
              label: item.competitor.fieldValue,
              value: item.competitor.fieldValue,
            };
            selectedComp.push(compObj);
          });
          setCompetionrsSelected(selectedComp);
          let selectedURL2 = [];
          response?.data?.pageSpeedUrlMapping.map((item) => {
            let urlObj = {
              url: item.url.fieldValue,
              id: item.url.id,
              key: item.url.id,
              label: item.url.fieldValue,
              value: item.url.fieldValue,
            };
            selectedURL2.push(urlObj);
          });
          setUrlSelected(selectedURL2);
          setFrequency2(response?.data.frequency);
          setDeviceType2(response?.data.deviceType);
        }
      }
    });
  }

  function addUpdatePageSpped() {
    let arrId = [];
    selectedUrl.map((item) => {
      if (item.id != "0") {
        arrId.push({ urlid: item.id });
      }
    });
    let compId = [];
    competionrs.map((item) => {
      if (item.id != "0") {
        compId.push({ CompetitorId: item.id });
      }
    });
    APIManager.postAPI(
      updatePageSpeedGetConfig,
      {
        ProjectId: localStorage.getItem("projectGId"),
        PageSpeedUrlMapping: arrId,
        PageSpeedUrlMappingConfigurations: compId,
        DeviceType: deviceType2,
        Frequency: frequency2,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        notifyUpdate();
        update("");
      }
    });
  }

  return (
    <>
      <div class="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
        <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("projectGName")} - Page Speed
          </h3>
        </div>
        <div className="portlet-body">
          <div class="module-description">
            <div class="common-wrapper">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user">
                    <label className="form-label ms-3">URL</label>
                    <div class="col-md-12 d-flex justify-content-between w-set">
                      <ReactSelect
                        styles={themeStyle()}
                        className="w-200"
                        options={urlList}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        hideSelectedOptions={false}
                        components={{ Option }}
                        onChange={(selectedURL) => {
                          setUrlSelected(
                            selectAllList(selectedURL, oldUrlList, urlList)
                          );
                          setOldUrlList(selectedURL);
                        }}
                        allowSelectAll={true}
                        value={selectedUrl}
                      />
                      <button
                        className="border plus ms-3"
                        style={{ background: "none" }}
                        onClick={() => {
                          SetIsActive(true);
                        }}
                      >
                        {" "}
                        <i
                          class="fa fa-plus-circle"
                          aria-hidden="true"
                        ></i>{" "}
                      </button>
                    </div>
                    {IsActive ? (
                      <div class="col-md-12">
                        <label className="form-label"></label>
                        <div className="timecheck-icon d-flex">
                          <input
                            className="w-100"
                            type="text"
                            name=""
                            placeholder="Enter URL"
                            value={urlName}
                            onChange={(text) => {
                              setURLName(text.target.value);
                            }}
                          />
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() => {
                              SetIsActive(false);
                              setURLName("");
                            }}
                          >
                            {" "}
                            <i
                              class="fa fa-times d-flex ms-3"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                          <button
                            style={{ border: "none", background: "none" }}
                            id={"rightbtn"}
                            onClick={() => {
                              if (urlName) {
                                addUrl();
                              }
                            }}
                          >
                            {" "}
                            <i class="fa fa-check" aria-hidden="true"></i>{" "}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    <div class="col-md-12 mt-3 ">
                      <label className="form-label" id="addUrlPS2">
                        {" "}
                        Competitors URLs
                      </label>
                      <div class="col-md-12 d-flex justify-content-between w-set p-0">
                        <ReactSelect
                          styles={themeStyle()}
                          className="w-200"
                          options={CompetitorsList}
                          isMulti={true}
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={(selectedCompUrl) => {
                            setCompetionrsSelected(
                              selectAllList(
                                selectedCompUrl,
                                oldCompUrlList,
                                CompetitorsList
                              )
                            );
                            setOldCompUrlList(selectedCompUrl);
                          }}
                          value={competionrs}
                          allowSelectAll={true}
                        />
                        <button
                          className="border plus ms-3"
                          style={{ background: "none" }}
                          onClick={() => {
                            SetIsActive1(true);
                          }}
                        >
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                        </button>
                      </div>
                    </div>
                    {IsActive1 ? (
                      <div class="col-md-12">
                        <label className="form-label"></label>
                        <div className="timecheck-icon d-flex">
                          <input
                            type="text"
                            name=""
                            placeholder="Enter URL"
                            value={competitors}
                            onChange={(text) => {
                              setCompetitors(text.target.value);
                            }}
                          />
                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() => {
                              SetIsActive1(false);
                              setCompetitors("");
                            }}
                          >
                            {" "}
                            <i
                              class="fa fa-times d-flex ms-3"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                          <button
                            style={{ border: "none", background: "none" }}
                            id={"rightbtn"}
                            onClick={() => {
                              if (competitors) {
                                addCompetitors();
                              }
                            }}
                          >
                            {" "}
                            <i class="fa fa-check" aria-hidden="true"></i>{" "}
                          </button>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}{" "}
                    <div class="col-md-12 mt-3">
                      <label
                        className="form-label"
                        htmlFor=""
                        style={{ marginRight: 24 + "px" }}
                      >
                        Set Frequency
                      </label>
                      <br />
                      <select
                        value={frequency2}
                        onChange={(e) => {
                          setFrequency2(e.target.value);
                        }}
                      >
                        {frequencyListKeyValue.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label
                        className="form-label"
                        htmlFor=""
                        style={{ marginRight: 24 + "px" }}
                      >
                        Device Type
                      </label>
                      <br />
                      <select
                        value={deviceType2}
                        onChange={(e) => {
                          setDeviceType2(e.target.value);
                        }}
                      >
                        {deviceType.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              class="btn btn-primary"
              onClick={() => {
                addUpdatePageSpped();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageSpeed;
