import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import { addCity, getCountryByOrg, updateCity } from "../../utility/Constant";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const EditCityDir = ({ update }) => {
  const [countrySelected, setCountrySelected] = useState("");
  const [countryList, setCountryList] = useState([]);
  const location = useLocation();
  const [city, setCity] = useState("");
  const [cityId, setCityId] = useState("");
  const [CountryId, setCountryId] = useState("");
  const [cCity, setCcity] = useState(false);
  const [ccityMsg, setcityMsg] = useState("");
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    if (location.state.isEdit) {
      setCity(location.state.item.cityName);
      setCityId(location.state.item.cityId);
      setCountrySelected(location.state.item.countryId);
    } else {
    }
    getCountryList();
  }, []);

  function addCityData() {
    APIManager.postAPI(
      addCity,
      {
        CityName: city.trim(),
        CountryId: countrySelected,
        organizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        clearForm();
        notifyAdd();
      }
    });
  }

  function updateCityItem() {
    APIManager.putAPI(
      updateCity,
      {
        CityName: city.trim(),
        cityId: cityId,
        CountryId: countrySelected,
        organizationId: localStorage.getItem("organizationId"),
        id: cityId,
      },
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        clearForm();
      }
    });
  }

  function clearForm() {
    setCity("");
    setCountryId("");
    update("cityList");
  }

  function formValidation() {
    let isValidFields = true;
    if (city.trim() == "") {
      isValidFields = false;
      setCcity(true);
      setcityMsg("City Name is required!");
    } else {
      setCcity(false);
      setcityMsg("");
    }
    return isValidFields;
  }

  function setcountry(e) {
    var c = e.target.value;
    setCountrySelected(c);
  }

  function getCountryList() {
    APIManager.getAPI(
      getCountryByOrg + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.countryName,
          };
          list.push(obj);
        });
        setCountryList(list);
        setCountrySelected(
          location.state?.item?.countryId
            ? location.state?.item?.countryId
            : list[0].id
        );
      }
    });
  }

  return (
    <>
      <div className="row common-mb-26">
        <div class="dis-none align-items-center  p-0">
          <div class="portlet  slideInUp setting-top">
            <div class="portlet-header portlet-header-bordered">
            <svg  onClick={() => {
                  update("cityList");
                }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 class="portlet-title main-title">
                {localStorage.getItem("organizationName")} -{" "}
                {location.state.isEdit ? "Update" : "Add"} City
              </h3>
            </div>
            <div className="portlet-body">
              <div class="col-md-12 mt-3">
                <div>
                  <label className="form-label">Country</label>
                  <select
                    className="w-100"
                    style={{ height: 40 + "px" }}
                    required
                    value={countrySelected}
                    onChange={setcountry}
                  >
                    {countryList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <label className="form-label">City</label>
                <label class="field-required"> * </label>

                <input
                  className="form-control w-100"
                  type="text"
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                  placeholder="Enter City"
                ></input>
                {cCity ? (
                  <div className="field-required">
                    <span> {ccityMsg}</span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="portlet-footer portlet-footer-bordered mt-3">
              <div className="col-md-12">
                <button
                  onClick={() => {
                    if (formValidation()) {
                      location.state.isEdit ? updateCityItem() : addCityData();
                    }
                  }}
                  className="btn btn-primary me-3"
                >
                  {location.state.isEdit ? "Update" : "Save"}
                </button>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    update("cityList");
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCityDir;
