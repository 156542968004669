import React, { useEffect, useState } from "react";
import { Table, Typography } from "antd";
import APIManager from "../../services/APIManager";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
    mmDDyyyyFormateDateSet,
    DDMMyyyyFormateDateSet,
    findUnique,
    exportToEXCEL,
} from "../../utility/Globals";
import {
    getPriorityKeywords,
    getPriorityKeywordsExport,
} from "../../utility/Constant";

function PriorityKeywords(props) {

    const [PriorityTab, setPriorityTab] = useState(true);
    const [TopTab, setTopTab] = useState(false);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [compareStartDate, setCompareStartDate] = useState("");
    const [compareEndDate, setCompareEndDate] = useState("");

    const [priorityTabCol, setPriorityTabCol] = useState([]);
    const [priorityTabCompareCol, setPriorityTabCompareCol] = useState([]);
    const [priorityTabList, setPriorityTabList] = useState([]);
    const [priorityTabCompareList, setPriorityTabCompareList] = useState([]);
    const [topTabCol, setTopTabCol] = useState([]);
    const [topTabCompareCol, setTopTabCompareCol] = useState([]);
    const [topTabList, setTopTabList] = useState([]);
    const [topTabCompareList, setTopTabCompareList] = useState([]);
    const [priorityFilterCategory, setPriorityFilterCategory] = useState([]);
    const [priorityCompareFilterCategory, setPriorityCompareFilterCategory] = useState([]);
    const [topFilterCategory, setTopFilterCategory] = useState([]);
    const [topCompareFilterCategory, setTopCompareFilterCategory] = useState([]);
    const [priorityTabExportList, setPriorityTabExportList] = useState([]);
    const [priorityTabCompareExportList, setPriorityTabCompareExportList] = useState([]);
    const [topTabExportList, setTopTabExportList] = useState([]);
    const [topTabCompareExportList, setTopTabCompareExportList] = useState([]);

    const [selectedCampaign, setSelectedCampaign] = useState("");
    const [selectedCampaigView, setSelectedCampaigView] = useState("");
    const [rankingType, setRankingType] = useState(0);
    const [deviceType, setDeviceType] = useState(0);
    const [isDateRange, setIsDateRange] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [tablePageSize, setTablePageSize] = useState(10);
    const [tableParams, setTableParams] = useState({
        pagination: {
            position: ["bottomRight"],
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} keywords`
        },
    });

    const [pageNumber1, setPageNumber1] = useState(1);
    const [tablePageSize1, setTablePageSize1] = useState(10);
    const [tableParams1, setTableParams1] = useState({
        pagination: {
            position: ["bottomRight"],
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} keywords`
        },
    });

    const [sortListPriority, setSortListPriority] = useState([
        {
            columnName: "keyword",
            order: undefined
        }
    ])
    const [sortListPriorityCompare, setSortListPriorityCompare] = useState([
        {
            columnName: "keyword",
            order: undefined
        }
    ])

    const [sortListTop, setSortListTop] = useState([
        {
            columnName: "keyword",
            order: undefined
        }
    ])

    const [sortListTopCompare, setSortListTopCompare] = useState([
        {
            columnName: "keyword",
            order: undefined
        }
    ])
    const [categoryListFilter, setCategoryTypeFilter] = useState([]);
    const [filterListPriority, setFilterListPriority] = useState([])
    const [filterListTop, setFilterListTop] = useState([])
    const [filterListPriorityCompare, setFilterListPriorityCompare] = useState([])
    const [filterListTopCompare, setFilterListTopCompare] = useState([])

    useEffect(() => {
        localStorage.setItem("PriorityTab", true);
        localStorage.setItem("TopTab", false);
    }, []);

    useEffect(() => {
        getPriorityKeywordsData();
    }, [props?.rankingType]);

    useEffect(() => {
        getPriorityKeywordsData();
    }, [props?.deviceType]);

    useEffect(() => {
        getPriorityKeywordsData();
    }, [props?.selectedCampaign]);

    useEffect(() => {
        getPriorityKeywordsData();
    }, [props?.selectedCampaigView]);

    useEffect(() => {
        getPriorityKeywordsData();
    }, [props?.dataParentToChild]);

    function getPriorityKeywordsData() {
        setCategoryTypeFilter(props?.categoryFilterList)
        setRankingType(parseInt(props?.rankingType));
        setDeviceType(parseInt(props?.deviceType));
        setSelectedCampaign(props?.selectedCampaign);
        setSelectedCampaigView(props?.selectedCampaigView);
        setStartDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
        );
        setEndDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
        );
        setCompareStartDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
        );
        setCompareEndDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
        );
        let PriorityTab = localStorage.getItem("PriorityTab");

        let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
        let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
        let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
        let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
        let rowdata;
        if (props?.selectedCampaign != '' && props?.selectedCampaigView != '' && props?.isReady) {
            if (startDate == compareStartDate
                && endDate == compareEndDate) {
                setIsDateRange(false)
                rowdata = {
                    ProjectId: localStorage.getItem("projectGId"),
                    RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                    RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                    Device: parseInt(props?.deviceType),
                    Type: parseInt(props?.rankingType),
                    IsPriority: PriorityTab == "true" ? 0 : 1,  // PriorityTab-0, TopTab-1
                    DateRange: {
                        FROM: startDate,
                        TO: endDate,
                    },
                    CurrentPage: PriorityTab == "true" ? pageNumber : pageNumber1,
                    TablePageSize: PriorityTab == "true" ? tablePageSize : tablePageSize1,
                    sorter: PriorityTab == "true" ? sortListPriority : sortListTop,
                    isSorter: false,
                    filter: PriorityTab == "true" ? filterListPriority : filterListTop,
                };
            }
            else {
                setIsDateRange(true)
                rowdata = {
                    ProjectId: localStorage.getItem("projectGId"),
                    RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                    RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                    Device: parseInt(props?.deviceType),
                    Type: parseInt(props?.rankingType),
                    IsPriority: PriorityTab == "true" ? 0 : 1,  // PriorityTab-0, TopTab-1

                    DateRange: {
                        FROM: startDate,
                        TO: endDate,
                    },
                    CompareDateRange: {
                        FROM: compareStartDate,
                        TO: compareEndDate,
                    },
                    CurrentPage: PriorityTab == "true" ? pageNumber : pageNumber1,
                    TablePageSize: PriorityTab == "true" ? tablePageSize : tablePageSize1,
                    sorter: PriorityTab == "true" ? sortListPriorityCompare : sortListTopCompare,
                    isSorter: false,
                    filter: PriorityTab == "true" ? filterListPriorityCompare : filterListTopCompare,
                };
            }
            getPriorityKeywordsTableData(rowdata);
        }
    }

    function getPriorityKeywordsTableData(rowdata) {
        let PriorityTab = localStorage.getItem("PriorityTab");
        let TopTab = localStorage.getItem("TopTab");

        APIManager.postAPI(getPriorityKeywords, rowdata, null, true).then((response) => {
            if (response && response.status == 200) {
                response.data.table1.map((item) => {
                    if (PriorityTab == "true") {
                        setTableParams({
                            pagination: {
                                current: rowdata.CurrentPage,
                                pageSize: rowdata.TablePageSize,
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                total: item.totalCount,
                                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${item.totalCount} keywords`
                            },
                        });
                    }
                    if (TopTab == "true") {
                        setTableParams1({
                            pagination: {
                                current: rowdata.CurrentPage,
                                pageSize: rowdata.TablePageSize,
                                position: ["bottomRight"],
                                showSizeChanger: true,
                                total: item.totalCount,
                                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${item.totalCount} keywords`
                            },
                        })
                    }
                });
                let list = [];
                let compareList = [];
                let filtercCategoryList = [];
                let filtercComapreCategoryList = [];
                // let name = "Head";
                response.data.table.map((item) => {
                    let obj = {
                        keyword: (
                            <>
                                <div class="keyword-data d-flex align-tems-center">
                                    <span className="keyword-distribution-data"> {item.keyword} </span>
                                    {/* <div class="scope-H d-flex align-tems-center mx-2 my-2"><span >{name.charAt(0).toUpperCase()}</span></div> */}
                                    {item.keywordType ?
                                        <a
                                            href="#"
                                            class="text-dark"
                                            data-toggle="tooltip"
                                            data-html="true"
                                            title={item.keywordType}
                                        >
                                            <div class="scope-H d-flex align-tems-center ms-2 my-2">
                                                <span>{item.keywordType.charAt(0).toUpperCase()}
                                                </span>
                                            </div>
                                        </a>
                                        :
                                        ""
                                    }
                                    {item.priority == 1 ?
                                        <a
                                            href="#"
                                            class="text-dark"
                                            data-toggle="tooltip"
                                            data-html="true"
                                            title="Priority"
                                        >
                                            <div class=" d-flex align-tems-center my-2 ms-2">
                                                <i class="fa-solid fa-star"></i>
                                            </div>
                                        </a>
                                        :
                                        <></>
                                    }
                                </div>
                            </>
                        ),
                        categoryName: item.category,
                        searchVolume: item.searchVolume,
                        rank: item.rank,
                        url: item.url,
                    };
                    list.push(obj);

                    let filterCategoryListObj = {
                        text: item.category,
                        value: item.category,
                    };
                    filtercCategoryList.push(filterCategoryListObj);

                });

                if (PriorityTab == "true") {
                    setPriorityTabList(list);
                    setPriorityFilterCategory(filtercCategoryList);
                }

                if (TopTab == "true") {
                    setTopTabList(list);
                    setTopFilterCategory(filtercCategoryList);
                }

                var columns = [
                    {
                        title: "Keywords",
                        dataIndex: "keyword",
                        key: "keyword",
                        sorter: {
                            multiple: 3,
                        },
                    },
                    {
                        title: "Category",
                        dataIndex: "categoryName",
                        key: "categoryName",
                        filters: findUnique(categoryListFilter, (d) => d.value),
                        filterSearch: true,
                    },
                    {
                        title: "Search Volume",
                        dataIndex: "searchVolume",
                        key: "searchVolume",
                        sorter: {
                            multiple: 3,
                        },
                    },
                    {
                        title: (
                            <Typography.Text ellipsis={true}>
                                Rank<br></br><span className="rankdate">{DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)}</span>
                            </Typography.Text>
                        ),
                        dataIndex: "rank",
                        key: "rank",
                        sorter: {
                            multiple: 3,
                        },
                    },
                    {
                        title: "URL",
                        dataIndex: "url",
                        key: "url",
                    },
                ];

                if (PriorityTab == "true") {
                    setPriorityTabCol(columns);
                }

                if (TopTab == "true") {
                    setTopTabCol(columns);
                }
                response.data.table.map((item) => {
                    let obj = {
                        keyword: (<> <div class="keyword-data d-flex align-tems-center">
                            <span className="keyword-distribution-data">{item.keyword}</span>
                            {item.keywordType ? <a
                                href="#"
                                class="text-dark"
                                data-toggle="tooltip"
                                data-html="true"
                                title={item.keywordType}
                            > <div class="scope-H d-flex align-tems-center ms-2 my-2"><span>{item.keywordType.charAt(0).toUpperCase()}</span></div> </a> : ""}
                            {item.priority == 1 ?
                                <a
                                    href="#"
                                    class="text-dark"
                                    data-toggle="tooltip"
                                    data-html="true"
                                    title="Priority"
                                >  <div class=" d-flex align-tems-center my-2 ms-2"> <i class="fa-solid fa-star" ></i></div></a> : <></>}</div></>),
                        categoryName: item.category,
                        searchVolume: item.searchVolume,
                        rank: item.rank1,
                        compareRank: item.rank2,
                        change: item.change >= 0 ? <span className="improved-color ">{item.change}</span> : <span className="text-decline">{item.change}</span>,
                        url: item.url,
                    };
                    compareList.push(obj);

                    let filterCompareCategoryListObj = {
                        text: item.category,
                        value: item.category,
                    };
                    filtercComapreCategoryList.push(filterCompareCategoryListObj);
                });

                if (PriorityTab == "true") {
                    setPriorityTabCompareList(compareList);
                    setPriorityCompareFilterCategory(filtercComapreCategoryList);
                }

                if (TopTab == "true") {
                    setTopTabCompareList(compareList);
                    setTopCompareFilterCategory(filtercComapreCategoryList);
                }

                var columns = [
                    {
                        title: "Keywords",
                        dataIndex: "keyword",
                        key: "keyword",
                        sorter: {
                            multiple: 3,
                        },
                    },
                    {
                        title: "Category",
                        dataIndex: "categoryName",
                        key: "categoryName",
                        filters: findUnique(categoryListFilter, (d) => d.value),
                        filterSearch: true,
                    },
                    {
                        title: "Search Volume",
                        dataIndex: "searchVolume",
                        key: "searchVolume",
                        sorter: {
                            multiple: 3,
                        },
                    },
                    {
                        title: (
                            <Typography.Text ellipsis={true}>
                                Rank<br></br><span className="rankdate">{DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)}</span>
                            </Typography.Text>
                        ),
                        dataIndex: "rank",
                        key: "rank",
                        sorter: {
                            multiple: 3,
                        },
                    },
                    {
                        title: (
                            <Typography.Text ellipsis={true}>
                                Rank<br></br><span className="rankdate">{DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)}</span>
                            </Typography.Text>
                        ),
                        dataIndex: "compareRank",
                        key: "compareRank",
                        sorter: {
                            multiple: 3,
                        },
                    },
                    {
                        title: "Change",
                        dataIndex: "change",
                        key: "change",
                    },
                    {
                        title: "URL",
                        dataIndex: "url",
                        key: "url",
                    },
                ];

                if (PriorityTab == "true") {
                    setPriorityTabCompareCol(columns);
                }

                if (TopTab == "true") {
                    setTopTabCompareCol(columns);
                }
            }
        });
    }

    const handleTableChange = (pagination, filter, sorter) => {
        setSortListPriority(sortListPriority)
        let sortListVar = [];
        if (sorter.length > 0) {
            sorter.map((item) => {
                sortListVar.push({
                    columnName: item.columnKey,
                    order: item.order
                })
            })
        }
        else {
            if (sorter.columnKey != undefined) {
                sortListVar.push({
                    columnName: sorter.columnKey,
                    order: sorter.order
                })
            }
            if (sortListVar.length > 0) {
                setSortListPriority(sortListVar)
            }
        }
        let isSorterVar = false
        let filterList = [];
        if (filter.categoryName != null) {
            if (filter.categoryName.length > 0) {
                filter.categoryName.map((item) => {
                    filterList.push(item)
                })
                setFilterListPriority(filterList)
            }
        }

        setRequestForTable(pagination.current, pagination.pageSize, sortListVar, isSorterVar, filterList)
    }

    function getColName(value) {
        if (value == "rank") {
            return "rank1"
        }
        else if (value == "compareRank") {
            return "rank2"
        }
        else {
            return value
        }
    }

    const handleTableChangeCompare = (pagination, filter, sorter) => {
        setSortListPriorityCompare(sortListPriorityCompare)
        let sortListVar = [];
        if (sorter.length > 0) {
            sorter.map((item) => {
                sortListVar.push({
                    columnName: getColName(item.columnKey),
                    order: item.order
                })
            })
        }
        else {
            if (sorter.columnKey != undefined) {
                sortListVar.push({
                    columnName: getColName(sorter.columnKey),
                    order: sorter.order
                })
            }
            if (sortListVar.length > 0) {
                setSortListPriorityCompare(sortListVar)
            }
        }

        let isSorterVar = false
        let filterList = [];
        if (filter.categoryName != null) {
            if (filter.categoryName.length > 0) {
                filter.categoryName.map((item) => {
                    filterList.push(item)
                })
                setFilterListPriorityCompare(filterList)
            }
        }

        setRequestForTable(pagination.current, pagination.pageSize, sortListVar, isSorterVar, filterList)
    }
    const handleTableChange1 = (pagination, filter, sorter) => {
        setSortListTop(sortListTop)
        let sortListVar = [];
        if (sorter.length > 0) {
            sorter.map((item) => {
                sortListVar.push({
                    columnName: item.columnKey,
                    order: item.order
                })
            })
        }
        else {
            if (sorter.columnKey != undefined) {
                sortListVar.push({
                    columnName: sorter.columnKey,
                    order: sorter.order
                })
            }
            if (sortListVar.length > 0) {
                setSortListTop(sortListVar)
            }
        }

        let isSorterVar = false;
        let filterList = [];
        if (filter.categoryName != null) {
            if (filter.categoryName.length > 0) {
                filter.categoryName.map((item) => {
                    filterList.push(item)
                });
                setFilterListTop(filterList)
            }
        }
        setRequestForTable(pagination.current, pagination.pageSize, sortListVar, isSorterVar, filterList)
    }

    const handleTableChange1Compare = (pagination, filter, sorter) => {
        setSortListTopCompare(sortListTopCompare)
        let sortListVar = [];
        if (sorter.length > 0) {
            sorter.map((item) => {
                sortListVar.push({
                    columnName: getColName(item.columnKey),
                    order: item.order
                })
            })
        }
        else {
            if (sorter.columnKey != undefined) {
                sortListVar.push({
                    columnName: getColName(sorter.columnKey),
                    order: sorter.order
                })
            }
            if (sortListVar.length > 0) {
                setSortListTopCompare(sortListVar)
            }
        }
        let isSorterVar = false
        let filterList = [];
        if (filter.categoryName != null) {
            if (filter.categoryName.length > 0) {
                filter.categoryName.map((item) => {
                    filterList.push(item)
                })
                setFilterListTopCompare(filterList)
            }
        }
        setRequestForTable(pagination.current, pagination.pageSize, sortListVar, isSorterVar, filterList)
    }

    function setRequestForTable(pageNo, tablePageSize, sortListVar, isSorterVar, filterList) {
        let rowdata;
        if (props?.selectedCampaign != '' && props?.selectedCampaigView != '') {
            if (startDate == compareStartDate && endDate == compareEndDate) {
                setIsDateRange(false)
                rowdata = {
                    ProjectId: localStorage.getItem("projectGId"),
                    RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                    RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                    Device: parseInt(props?.deviceType),
                    Type: parseInt(props?.rankingType),
                    IsPriority: PriorityTab == "true" ? 0 : 1,  // PriorityTab-0, TopTab-1
                    DateRange: {
                        FROM: startDate,
                        TO: endDate,
                    },
                    CurrentPage: pageNo,
                    TablePageSize: tablePageSize,
                    sorter: PriorityTab == "true" ? (sortListVar.length > 0 ? sortListVar : sortListPriority) : (sortListVar.length > 0 ? sortListVar : sortListTop),
                    isSorter: isSorterVar,
                    filter: filterList,
                };
            }
            else {
                setIsDateRange(true)
                rowdata = {
                    ProjectId: localStorage.getItem("projectGId"),
                    RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                    RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                    Device: parseInt(props?.deviceType),
                    Type: parseInt(props?.rankingType),
                    IsPriority: PriorityTab == "true" ? 0 : 1,  // PriorityTab-0, TopTab-1
                    DateRange: {
                        FROM: startDate,
                        TO: endDate,
                    },
                    CompareDateRange: {
                        FROM: compareStartDate,
                        TO: compareEndDate,
                    },
                    CurrentPage: pageNo,
                    TablePageSize: tablePageSize,
                    sorter: PriorityTab == "true" ? (sortListVar.length > 0 ? sortListVar : sortListPriorityCompare) : (sortListVar.length > 0 ? sortListVar : sortListTopCompare),
                    isSorter: isSorterVar,
                    filter: filterList,
                };
            }
            getPriorityKeywordsTableData(rowdata);
        }
    }

    function getPriorityKeywordsTableDataExport(rowdata) {
        let PriorityTab = localStorage.getItem("PriorityTab");
        let TopTab = localStorage.getItem("TopTab");

        APIManager.postAPI(getPriorityKeywordsExport, rowdata, null, true).then((response) => {
            if (response && response.status == 200) {
                let exportList = [];
                let compareExportList = [];

                if (startDate == compareStartDate && endDate == compareEndDate) {
                    response?.data.map((item) => {
                        let exportObj = {
                            keywords: item.keyword,
                            category: item.category,
                            searchVolume: item.searchVolume,
                            rank: item.rank,
                            url: item.url,
                        };
                        exportList.push(exportObj);
                    });

                    if (PriorityTab == "true") {
                        setPriorityTabExportList(exportList);
                        exportToEXCEL(
                            exportList,
                            "Priority Keywords"
                        );
                    }

                    if (TopTab == "true") {
                        setTopTabExportList(exportList);
                        exportToEXCEL(
                            exportList,
                            "Top Keywords"
                        );
                    }
                }

                if (startDate != compareStartDate && endDate != compareEndDate) {
                    response?.data.map((item) => {
                        let exportObj = {
                            keywords: item.keyword,
                            category: item.category,
                            searchVolume: item.searchVolume,
                            rank: item.rank1,
                            compareRank: item.rank2,
                            change: item.change,
                            url: item.url,
                        };
                        compareExportList.push(exportObj);
                    });

                    if (PriorityTab == "true") {
                        setPriorityTabCompareExportList(compareExportList);
                        exportToEXCEL(
                            compareExportList,
                            "Priority Keywords"
                        );
                    }

                    if (TopTab == "true") {
                        setTopTabCompareExportList(compareExportList);
                        exportToEXCEL(
                            compareExportList,
                            "Top Keywords"
                        );
                    }
                }
            }
        });
    }

    return (
        <>
            <div>
                <div className="overall-rank">
                    <div className="overall-title">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="me-3 ranking-name my-1">Priority Keywords</h4>
                            </div>

                            <div className="d-flex justify-content-between align-items-center">
                                <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <button
                                    className="rank-export"
                                    onClick={() => {
                                        let PriorityTab = localStorage.getItem("PriorityTab");
                                        let rowDataExport;
                                        if (props?.selectedCampaign != '' && props?.selectedCampaigView != '') {
                                            if (startDate == compareStartDate && endDate == compareEndDate) {
                                                setIsDateRange(false)
                                                rowDataExport = {
                                                    ProjectId: localStorage.getItem("projectGId"),
                                                    RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                                                    RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                                                    Device: parseInt(props?.deviceType),
                                                    Type: parseInt(props?.rankingType),
                                                    IsPriority: PriorityTab == "true" ? 0 : 1,  // PriorityTab-0, TopTab-1
                                                    DateRange: {
                                                        FROM: startDate,
                                                        TO: endDate,
                                                    },
                                                };
                                            }
                                            else {
                                                setIsDateRange(true)
                                                rowDataExport = {
                                                    ProjectId: localStorage.getItem("projectGId"),
                                                    RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                                                    RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                                                    Device: parseInt(props?.deviceType),
                                                    Type: parseInt(props?.rankingType),
                                                    IsPriority: PriorityTab == "true" ? 0 : 1,  // PriorityTab-0, TopTab-1
                                                    DateRange: {
                                                        FROM: startDate,
                                                        TO: endDate,
                                                    },
                                                    CompareDateRange: {
                                                        FROM: compareStartDate,
                                                        TO: compareEndDate,
                                                    },
                                                };
                                            }
                                            getPriorityKeywordsTableDataExport(rowDataExport);
                                        }
                                    }}
                                >
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Tabs>
                    <div className="portlet-header portlet-header-bordered p-0">
                        <TabList className="nav nav-lines portlet-nav">
                            <Tab
                                className={
                                    PriorityTab
                                        ? "nav-item nav-link  active "
                                        : "nav-item nav-link "
                                }
                                onClick={() => {
                                    setTopTab(false);
                                    setPriorityTab(true);
                                    localStorage.setItem("PriorityTab", true);
                                    localStorage.setItem("TopTab", false);
                                    setSortListPriority([
                                        {
                                            columnName: "keyword",
                                            order: undefined
                                        }])

                                    setSortListPriorityCompare([
                                        {
                                            columnName: "keyword",
                                            order: undefined
                                        }
                                    ])
                                    setFilterListPriority([])
                                    setFilterListPriorityCompare([])
                                    let rowdata;
                                    if (startDate == compareStartDate && endDate == compareEndDate) {
                                        setIsDateRange(false)
                                        rowdata = {
                                            ProjectId: localStorage.getItem("projectGId"),
                                            RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                                            RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                                            Device: deviceType,
                                            Type: rankingType,
                                            IsPriority: 0,  // PriorityTab-0, TopTab-1
                                            DateRange: {
                                                FROM: startDate,
                                                TO: endDate,
                                            },
                                            CurrentPage: pageNumber,
                                            TablePageSize: tablePageSize,
                                            sorter: PriorityTab == "true" ? sortListPriority : sortListTop,
                                            isSorter: false,
                                            filter: PriorityTab == "true" ? filterListPriority : filterListTop,
                                        };
                                    }
                                    else {
                                        setIsDateRange(true);
                                        rowdata = {
                                            ProjectId: localStorage.getItem("projectGId"),
                                            RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                                            RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                                            Device: deviceType,
                                            Type: rankingType,
                                            IsPriority: 0,  // PriorityTab-0, TopTab-1
                                            DateRange: {
                                                FROM: startDate,
                                                TO: endDate,
                                            },
                                            CompareDateRange: {
                                                FROM: compareStartDate,
                                                TO: compareEndDate,
                                            },
                                            CurrentPage: pageNumber,
                                            TablePageSize: tablePageSize,
                                            sorter: PriorityTab == "true" ? sortListPriorityCompare : sortListTopCompare,
                                            isSorter: false,
                                            filter: PriorityTab == "true" ? filterListPriorityCompare : filterListTopCompare,
                                        };
                                    }
                                    getPriorityKeywordsTableData(rowdata);
                                }}
                            >Priority
                            </Tab>
                            <Tab
                                className={
                                    TopTab
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link  "
                                }
                                onClick={() => {
                                    setPriorityTab(false);
                                    setTopTab(true);
                                    localStorage.setItem("PriorityTab", false);
                                    localStorage.setItem("TopTab", true);
                                    setSortListTop([{
                                        columnName: "keyword",
                                        order: undefined
                                    }])
                                    setSortListTopCompare([{
                                        columnName: "keyword",
                                        order: undefined
                                    }])
                                    setFilterListTop([])
                                    setFilterListTopCompare([])

                                    let rowdata;
                                    if (startDate == compareStartDate && endDate == compareEndDate) {
                                        setIsDateRange(false);
                                        rowdata = {
                                            ProjectId: localStorage.getItem("projectGId"),
                                            RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                                            RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                                            Device: deviceType,
                                            Type: rankingType,
                                            IsPriority: 1,  // PriorityTab-0, TopTab-1
                                            DateRange: {
                                                FROM: startDate,
                                                TO: endDate,
                                            },
                                            CurrentPage: pageNumber1,
                                            TablePageSize: tablePageSize1,
                                            sorter: PriorityTab == "true" ? sortListPriority : sortListTop,
                                            isSorter: false,
                                            filter: PriorityTab == "true" ? filterListPriority : filterListTop,
                                        };
                                    }
                                    else {
                                        setIsDateRange(true);
                                        rowdata = {
                                            ProjectId: localStorage.getItem("projectGId"),
                                            RankingCampaignConfigurationId: (props?.selectedCampaign).toUpperCase(),
                                            RankingCampaignViewConfigurationId: (props?.selectedCampaigView).toUpperCase(),
                                            Device: deviceType,
                                            Type: rankingType,
                                            IsPriority: 1,  // PriorityTab-0, TopTab-1
                                            DateRange: {
                                                FROM: startDate,
                                                TO: endDate,
                                            },
                                            CompareDateRange: {
                                                FROM: compareStartDate,
                                                TO: compareEndDate,
                                            },
                                            CurrentPage: pageNumber1,
                                            TablePageSize: tablePageSize1,
                                            sorter: PriorityTab == "true" ? sortListPriorityCompare : sortListTopCompare,
                                            isSorter: false,
                                            filter: PriorityTab == "true" ? filterListPriorityCompare : filterListTopCompare,
                                        };
                                    }
                                    getPriorityKeywordsTableData(rowdata);
                                }}
                            >
                                Top
                            </Tab>

                        </TabList>
                    </div>
                    <TabPanel>
                        <div className="col-md-12 mt-3">
                            {isDateRange == false ?
                                <Table
                                    className=" priority-keyword-table ranking-table-design"
                                    id="url"
                                    columns={priorityTabCol}
                                    dataSource={priorityTabList}
                                    pagination={tableParams.pagination}
                                    onChange={handleTableChange}
                                /> :

                                <Table
                                    className=" priority-keyword-table ranking-table-design"
                                    id="url"
                                    columns={priorityTabCompareCol}
                                    dataSource={priorityTabCompareList}
                                    pagination={tableParams.pagination}
                                    onChange={handleTableChangeCompare}
                                />}
                        </div>
                    </TabPanel>

                    <TabPanel>
                        <div className="col-md-12 mt-3">
                            {isDateRange == false ?
                                <Table
                                    className="priority-keyword-top-table ranking-table-design"
                                    id="url"
                                    columns={topTabCol}
                                    dataSource={topTabList}
                                    pagination={tableParams1.pagination}
                                    onChange={handleTableChange1}
                                /> :
                                <Table
                                    className="priority-keyword-top-table ranking-table-design"
                                    id="url"
                                    columns={topTabCompareCol}
                                    dataSource={topTabCompareList}
                                    pagination={tableParams1.pagination}
                                    onChange={handleTableChange1Compare}
                                />}

                        </div>
                    </TabPanel>
                </Tabs>
            </div>
        </>
    );
}

export default PriorityKeywords;
