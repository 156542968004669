import React from 'react'
import { useEffect } from 'react'

function Loading(props) {
  useEffect(() => {
  }, [])
 
  return (
    <>
  <div style={{ display: props.isLoading ? "block" : "none" }}>
        <div
          className="loader loader-bg d-flex align-items-center justify-content-center"
          style={{ backgroundColor: "#00000085" }}
        ></div>
        <div className="loader loader-bg d-flex align-items-center justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>
    </>
  )
}
export default Loading
