import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Switch, Table } from "antd";
import { Card } from "react-bootstrap";
import { ModuleExpandTickets } from "./index";
import { Calendar } from "react-multi-date-picker";
import {
  calanderDate,
  exportInToCSV,
  exportToEXCEL,
  formateDateIntoDatemonth,
  // getCheckPermission,
  getEstimatedClicksColor,
  getMonth,
  lastDayOfMonth,
  setMonths,
  themeStyle,
  returnMagnitude,
  fixTwoPlaceDecimal,
  getDaysInMonth,
  downloadPDFfile,
  getBase64path,
  calanderDate2,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import APIManager from "../services/APIManager";
import {
  getROICalculator,
  getLanguage,
  getROIConfig,
  sendEmailApi,
  getRoiCalculatorData,
  getROIchartData,
  getChartDataROI,
  getTableDataROI,
  getCompareTableDataROI,
  getCompareChartDataROI,
  getRoiTableData,
  moduleROI,
} from "../utility/Constant";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import Chart from "react-apexcharts";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";

function ModuleExpandROICalculator() {

  var isFirefox = typeof InstallTrigger !== 'undefined';

  const [options1, setObject1] = useState({
    title: {
      text: "",
      align: "left",
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
    },
    yaxis: {
      logBase: 0,
      tickAmount: 9,
      min: 0,
    },
  });
  const [options11, setObject11] = useState([]);

  const [series1, setSeries1] = useState([]);
  const [series11, setSeries11] = useState([]);

  const [PaidCPCTable, setPaidCPCTable] = useState([]);
  const [PaidCTCCol, setPaidCTCCol] = useState([]);
  const [GSCTable, setGSCTable] = useState([]);
  const [GSCCol, setGSCCol] = useState([]);
  const [ClickShareCol, setClickShareCol] = useState([]);
  const [ROI, setROI] = useState([]);
  const [ROAS, setROAS] = useState([]);
  const [CostPerLead, setCostPerLead] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);

  const [roiCheck, setROICheck] = useState(true);
  const [cpcCheck, setCPCCheck] = useState(false);
  const [cpsCheck, setCPSCheck] = useState(false);
  const [cplCheck, setCPLCheck] = useState(false);
  const [optionsChart, setoptionsChart] = useState({});

  useEffect(() => {
    setChartData();
  }, [options1, roiCheck, cpcCheck, cpsCheck, cplCheck]);

  function setChartData() {
    let chartOBJ = [];
    // ROI BliilingMonth,Revenue,ROAS    CPC Clicks,CPC.  CPS MonthlySessions,CPS CPL OrganicLeads,CPL
    let tableColsList = [
      // {
      //   title: 'Billing Amount',
      //   dataIndex: 'bilingamount',
      //   sorter: {
      //     compare: (a, b) =>
      //       Sorter.DEFAULT(
      //         a.bilingamount,
      //         b.bilingamount
      //       ),
      //     multiple: 3,
      //   },
      // }
    ];
    if (roiCheck) {
      chartOBJ.push({
        name: "ROI",
        data: rolListData,
        color: "#42a6f5",
      });
      tableColsList.push({
        title: "Billing month",
        dataIndex: "bilingmonth",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.bilingmonth, b.bilingmonth),
          multiple: 3,
        },
      });
      tableColsList.push({
        title: "Revenue",
        dataIndex: "revenue",
        // width: "40%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.revenue, b.revenue),
          multiple: 3,
        },
      });
      tableColsList.push({
        title: "ROAS",
        dataIndex: "roas",
        // width: "40%",
        render: (val) => (
          <div className={getEstimatedClicksColor(val)}>{val}</div>
        ),
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.roas, b.roas),
          multiple: 3,
        },
      });
    }
    if (cpcCheck) {
      chartOBJ.push({
        name: "CPC",
        data: cpcListData,
        color: "#b58887",
      });
      tableColsList.push({
        title: "Clicks",
        dataIndex: "nonbrandclicks",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.nonbrandclicks, b.nonbrandclicks),
          multiple: 3,
        },
      });
      tableColsList.push({
        title: "CPC",
        dataIndex: "cpc",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.cpc, b.cpc),
          multiple: 3,
        },
      });
    }
    if (cpsCheck) {
      chartOBJ.push({
        name: "CPS",
        data: cpsListData,
        color: "#9db587",
      });
      tableColsList.push({
        title: "CPS",
        dataIndex: "cps",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.cps, b.cps),
          multiple: 3,
        },
      });
      tableColsList.push({
        title: "Sessions",
        dataIndex: "sessions",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.sessions, b.sessions),
          multiple: 3,
        },
      });
    }
    if (cplCheck) {
      chartOBJ.push({
        name: "CPL",
        data: cplListData,
        color: "#87b4b5",
      });
      tableColsList.push({
        title: "CPL",
        dataIndex: "cpl",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.cpl, b.cpl),
          multiple: 3,
        },
      });
      tableColsList.push({
        title: "Organic Leads",
        dataIndex: "leads",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.leads, b.leads),
          multiple: 3,
        },
      });
    }
    setSeries1(chartOBJ);
    let maxValue =
      cpcListDataCompTotal > rolListDataCompTotal
        ? cpcListDataCompTotal
        : rolListDataCompTotal;
    let maxChartData = Math.max(maxValue) * 0.3 + Math.max(maxValue);
    let option = {
      title: {
        text: "",
        align: "left",
      },
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
      },
      dataLabels: {
        formatter: () => {
          return "";
        },
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: options11,
      },
      yaxis: {
        logBase: 0,
        tickAmount: 9,
        min: 0,
        max: maxChartData,
        labels: {
          formatter: function (val) {
            // return (val.toFixed(2));
            return returnMagnitude(val);
          },
        },
      },
      tooltip: {
        y: {
          formatter: function (value) {
            return fixTwoPlaceDecimal(value);
          },
        },
      },
    };

    setoptionsChart(option);
    // setObject1(options1)
    setSeries11([
      {
        name: "Organic CPC",
        // data: rolListDataComp,
        data: cpcListDataComp,
        color: "#42a6f5",
      },
      {
        name: "Paid CPC",
        // data: cpcListDataComp,
        data: rolListDataComp,
        color: "#b58887",
      },
    ]);

    setTableColumns(tableColsList);
  }

  const [tableColumns, setTableColumns] = useState([
    {
      title: "Billing month",
      dataIndex: "bilingmonth",
      // width: "20%",
      sorter: {
        compare: (a, b) => Sorter.DEFAULT(a.bilingmonth, b.bilingmonth),
        multiple: 3,
      },
    },
    // {
    //   title: 'Billing Amount',
    //   dataIndex: 'bilingamount',
    //   sorter: {
    //     compare: (a, b) =>
    //       Sorter.DEFAULT(
    //         a.bilingamount,
    //         b.bilingamount
    //       ),
    //     multiple: 3,
    //   },
    // },
    {
      title: "Revenue",
      dataIndex: "revenue",
      // width: "20%",
      sorter: {
        compare: (a, b) => Sorter.DEFAULT(a.revenue, b.revenue),
        multiple: 3,
      },
    },
    {
      title: "ROAS",
      dataIndex: "roas",
      // width: "20%",
      render: (val) => (
        <div className={getEstimatedClicksColor(val)}>{val}</div>
      ),
      sorter: {
        compare: (a, b) => Sorter.DEFAULT(a.roas, b.roas),
        multiple: 3,
      },
    },
    {
      title: "Clicks",
      dataIndex: "nonbrandclicks",
      // width: "20%",
      sorter: {
        compare: (a, b) => Sorter.DEFAULT(a.nonbrandclicks, b.nonbrandclicks),
        multiple: 3,
      },
    },
    {
      title: "CPC",
      dataIndex: "cpc",
      // width: "20%",
      sorter: {
        compare: (a, b) => Sorter.DEFAULT(a.cpc, b.cpc),
        multiple: 3,
      },
    },
  ]);

  const [tableData, setTableData] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [isLoading, setLoading] = useState(false);


  const onChangeSwitch = (c) => {
    setswitchValue(c);
  };

  function handleCountryChange(selected) {
    setCountrySelected(selected);
    let getdata = {
      location: selected.id,
      // Language: selectedLanguageCode,
      // DataSource: selectDataSource,
      StartDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2),
      ProjectId: localStorage.getItem("projectGId"),
    };
    // createROICalculatorResearch(getdata);
  }

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };

  }, [isMenuOpen]);

  // let monthArr = new Date().getMonth().split()

  const [values, setValues] = useState([
    // new Date(getMonth(6, false)),
    // new Date(getMonth(0, true)),
    new Date(Date.now()).toString(),
    new Date(Date.now()).toString()
  ]);

  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(values[0]);
  const [head2, setHead2] = useState(values[1]);

  const [head11, setHead11] = useState(values[0]);
  const [head22, setHead22] = useState(values[1]);

  const [isChangeMonth, setIsChangeMonth] = useState(false)

  const [switchValue, setswitchValue] = useState(false);


  useEffect(() => {
    const d = new Date();
    let dateArry = [];
    dateArry.push(d.getMonth() + 1);
    dateArry.push(d.getFullYear());
    var daysInMonth = getDaysInMonth(d.getFullYear(), d.getMonth() + 1)
    // let dateObjArr = [
    //   new Date(dateArry[0] + "/01/" + dateArry[1]),
    //   new Date(dateArry[0] + "/" + daysInMonth + "/" + dateArry[1]),
    // ];


    let dateObjArr
    var arrSdate
    var arrEdate
    if (isFirefox) {

      let startDateObj = new Date(dateArry[0] + "/01/" + dateArry[1])
      let sDate = startDateObj.toString()
      arrSdate = sDate.split('/'); //m/d/y

      let endDateObj = new Date(dateArry[0] + "/" + daysInMonth + "/" + dateArry[1])
      let eDate = endDateObj.toString()
      arrEdate = eDate.split('/'); //m/d/y

      dateObjArr = [
        new Date(dateArry[1] + "/" + dateArry[0] + "/01"),
        new Date(dateArry[1] + "/" + dateArry[0] + "/" + arrEdate[0]),
      ];

      setHead11(arrSdate[0] + "-" + arrSdate[1] + "-" + arrSdate[2])
      setHead22(arrEdate[0] + "-" + arrEdate[1] + "-" + arrEdate[2])
    } else {
      dateObjArr = [
        new Date(dateArry[0] + "/01/" + dateArry[1]),
        new Date(dateArry[0] + "/" + daysInMonth + "/" + dateArry[1]),
      ];
    }

    setValues(dateObjArr);
    setHead1(dateObjArr[0].toString())
    setHead2(dateObjArr[1].toString())

    // var arrSdate 
    // var arrEdate
    let rowdata1 = {
      startDate:
        isFirefox ? (arrSdate[2] + "-" + arrSdate[1] + "-" + arrSdate[0]) :
          mmDDyyyyFormateDateSet(dateObjArr[0].toString()),
      endDate:
        isFirefox ? (arrEdate[2] + "-" + arrEdate[1] + "-" + arrEdate[0]) :
          mmDDyyyyFormateDateSet(dateObjArr[1].toString()),
      projectId: localStorage.getItem("projectGId"),
    };
    getChartData(rowdata1);
    getTableData(rowdata1);
    getCompareChartData(rowdata1);
  }, []);
  useEffect(() => {
    // getCountryList();
    var data = [];
    data = [
      {
        title: "Keyword List",
        dataIndex: "keyword",
        key: "keyword",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "Search Volume",
        dataIndex: "volume",
        key: "volume",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.volume, b.volume),
          multiple: 3,
        },
      },
      {
        title: "CPC",
        dataIndex: "ctc",
        key: "ctc",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.ctc, b.ctc),
          multiple: 3,
        },
      },
    ];
    setPaidCTCCol(data);
    data = [];
    setPaidCPCTable(data);
    data = [
      {
        title: "Keywords",
        dataIndex: "keyword",
        key: "keyword",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "Clicks",
        dataIndex: "click",
        key: "click",
        // width: "20%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.click, b.click),
          multiple: 3,
        },
      },
    ];
    setGSCCol(data);
    data = [
      {
        title: "Keywords",
        dataIndex: "keyword",
        key: "keyword",
      },
      {
        title: "EStd. Organic Traffic",
        dataIndex: "organictraffic",
        key: "organictraffic",
      },
      {
        title: "",
        dataIndex: "",
        key: "",
      },
    ];
    setClickShareCol(data);
    data = [];
    setGSCTable(data);


  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  function setheadvalues() {
    setHead1(values[0]);
    setHead2(values[1]);
  }

  const [roi, setroi] = useState(false);

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the ROI Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
  }, []);

  const applyBtn = useRef(null);
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("ROI Reports");

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    applyBtn.current.click();
    let fileBase64 = await getBase64path(image)
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleROI
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status == 200) {
          setReceiver("");
          setSubject("ROI Reports");
          setEmailMessage(EmailMessage1);
          if (theme == 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  const [cpcListData, setCPClist] = useState([]);
  const [cplListData, setCPLlist] = useState([]);
  const [cpsListData, setCPSlist] = useState([]);
  const [rolListData, setROIlist] = useState([]);

  const [cpcListDataAVG, setCPClistAVG] = useState(0);
  const [cplListDataAVG, setCPLlistAVG] = useState(0);
  const [cpsListDataAVG, setCPSlistAVG] = useState(0);
  const [rolListDataAVG, setROIlistAVG] = useState(0);
  function setCheckboxValue(chechBox, tag) {
    if (tag == 1) {
      if (cpcCheck == false && cpsCheck == false && cplCheck == false) {
        setROICheck(true)
      }
    }
    else if (tag == 2) {
      if (chechBox == false && cpsCheck == false && cplCheck == false) {
        setROICheck(true)
      }
    }
    else if (tag == 3) {
      if (cpcCheck == false && chechBox == false && cplCheck == false) {
        setROICheck(true)
      }
    } else if (tag == 4) {
      if (cpcCheck == false && cpsCheck == false && chechBox == false) {
        setROICheck(true)
      }
    }

  }
  function getChartData(rowdata1) {
    APIManager.postAPI(getChartDataROI, rowdata1, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let monthsList = [];
          let cpcList = [];
          let cpcListData = 0;
          response.data?.cpCs.map((item) => {
            monthsList.push(setMonths(item?.month));
            cpcList.push(item?.cpc);
            cpcListData = cpcListData + item?.cpc;
          });
          setObject1({
            title: {
              text: "",
              align: "left",
            },
            chart: {
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  customIcons: [],
                },
              },
            },
            dataLabels: {
              formatter: () => {
                return "";
              },
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
              },
            },
            xaxis: {
              tickPlacement: "on",
              categories: monthsList,
            },
            yaxis: {
              logBase: 0,
              tickAmount: 9,
              min: 0,
              max: (max) => {
                return max * 0.30 + max;
              },
              labels: {
                formatter: function (val) {
                  return returnMagnitude(val);
                },
              },
            },
            tooltip: {
              y: {
                formatter: function (value) {
                  return value;
                },
              },
            },
          });

          setROICheck(false);
          setTimeout(() => {
            setROICheck(true);
          }, 1000);

          let cplList = [];
          let cplListData = 0;
          response.data?.cpLs.map((item) => {
            cplList.push(item?.cpl);
            cplListData = cplListData + item?.cpl;
          });

          let cpsList = [];
          let cpsListData = 0;
          response.data?.cpSs.map((item) => {
            cpsList.push(item?.cps);
            cpsListData = cpsListData + item?.cps;
          });

          let rolList = [];
          let rolListData = 0;
          response.data?.roIs.map((item) => {
            rolList.push(item?.roi);
            rolListData = rolListData + item?.roi;
          });
          setCPClist(cpcList);
          setCPLlist(cplList);
          setCPSlist(cpsList);
          setROIlist(rolList);

          if (response.data?.cpCs.length > 0) {
            setCPClistAVG(cpcListData / response.data?.cpCs.length);
          } else {
            setCPClistAVG(0)
          }
          if (response.data?.cpLs.length > 0) {
            setCPLlistAVG(cplListData / response.data?.cpLs.length);
          } else {
            setCPLlistAVG(0)
          }
          if (response.data?.cpSs.length > 0) {
            setCPSlistAVG(cpsListData / response.data?.cpSs.length);
          } else {
            setCPSlistAVG(0)
          }

          if (response.data?.roIs.length > 0) {
            setROIlistAVG(rolListData / response.data?.roIs.length);
          } else { setROIlistAVG(0) }
        }
      }
    );
  }

  const [selectedQueryExportList, setSelectedQueryExportList] = useState([]);
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        list.push(item);
      });

      setSelectedQueryExportList(list);
    },
  };

  function getTableData(rowdata1) {
    APIManager.postAPI(getTableDataROI, rowdata1, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          response.data.map((item, index) => {
            list.push({
              key: index,
              bilingmonth: setMonths(item.billingMonth),
              // bilingamount: "0",
              revenue: item.revenue,
              roas: item.roas,
              nonbrandclicks: item.clicks,
              cpc: item.cpc,
              leads: item?.leads,
              cpl: item?.cpl,
              cps: item?.cps,
              sessions: item?.sessions,
            });
          });
          setTableData(list);
          setExportList(list);
        }
      }
    );
  }

  const [cpcListDataComp, setCPClistComp] = useState([]);
  const [rolListDataComp, setROIlistComp] = useState([]);
  const [cpcListDataCompTotal, setCPClistCompTotal] = useState(0);
  const [rolListDataCompTotal, setROIlistCompTotal] = useState(0);
  function getCompareChartData(rowdata1) {
    APIManager.postAPI(getCompareChartDataROI, rowdata1, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let monthsList = [];
          let organicCPCList = [];
          let paidCPCList = [];
          let organicCPCLTotal = 0;
          let paidCPCLTotal = 0;
          response.data.map((item) => {
            monthsList.push(setMonths(item?.month));
            organicCPCList.push(item?.organicCPC);
            paidCPCList.push(item?.paidCPC);
            organicCPCLTotal = organicCPCLTotal + item?.organicCPC;
            paidCPCLTotal = paidCPCLTotal + item?.paidCPC;
          });
          setObject11(monthsList);
          setCPClistComp(organicCPCList);
          setROIlistComp(paidCPCList);
          setCPClistCompTotal(
            response.data.length != 0
              ? organicCPCLTotal / response.data.length
              : 0
          );
          setROIlistCompTotal(
            response.data.length != 0 ? paidCPCLTotal / response.data.length : 0
          );
        }
      }
    );
  }


  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      <div className="row">
        <Tabs>
          <div className="portlet ">
            <div className="portlet-header portlet-header-bordered my-3">
              <div className="col-md-9 col-sm-12">
                <TabList className="nav nav-lines portlet-nav">
                  <Tab
                    className={
                      roi ? "nav-item nav-link " : "nav-item nav-link  active"
                    }
                    onClick={() => {
                      setroi(false);
                    }}
                  >
                    Return on Investment
                  </Tab>
                </TabList>
              </div>
              {!roi ? (
                <div ref={ref} class="col-md-3 col-sm-12">
                  <div className="calendar-main d-flex justify-content-end align-items-center ga-calender">
                    <div className="add-new-btnw">
                      <button
                        className="btn btn-primary"
                        style={{ width: "250px" }}
                        onClick={() => setOpen(!open)}
                      >
                        {isFirefox ? isChangeMonth ? calanderDate(head1, head2) : calanderDate2(head11, head22) : calanderDate(head1, head2)}
                      </button>
                    </div>

                    {open && (
                      <div id="example-collapse-text-calendar">
                        <Card body className="daterange-picker-card  mt-2">
                          <div className="d-flex justify-content-between">
                            <div className="calendar-col">
                              <Calendar
                                maxDate={new Date()}
                                onChange={(e) => {
                                  if (e.length > 1) {
                                    var date1 = e[0].format().split("/")
                                    var date2 = e[1].format().split("/")
                                    let dateObjArr = [
                                      new Date(date1[0] + "/01/" + date1[1]),
                                      new Date(date2[0] + "/" + getDaysInMonth(date2[1], date2[0]) + "/" + date2[1])
                                    ];
                                    setHead1(dateObjArr[0].toString())
                                    setHead2(dateObjArr[1].toString())
                                    setValues(dateObjArr);
                                    setIsChangeMonth(true)
                                  }
                                }}
                                value={values}
                                onlyMonthPicker
                                range
                              />
                            </div>
                            <div className="ms-3">
                              <button
                                onClick={() => {
                                  setheadvalues();
                                  setOpen(!open);
                                  let rowdata1 = {
                                    startDate: mmDDyyyyFormateDateSet(
                                      values[0]
                                    ),
                                    endDate: mmDDyyyyFormateDateSet(values[1]),

                                    projectId:
                                      localStorage.getItem("projectGId"),
                                  };
                                  getChartData(rowdata1);
                                  getTableData(rowdata1);
                                  getCompareChartData(rowdata1);
                                }}
                                className="btn-outline btn btn-primary"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </Card>
                      </div>
                    )}

                    <div className="d-flex 3-icons">
                      <div className="ms-3">
                        <a
                          className="share bar-link-icon"
                          style={{ color: "black" }}
                        >
                          <div class="dropdown">
                            <a
                              type="button"
                              ref={applyBtn}
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i class="fa fa-share-alt" aria-hidden="true"></i>
                            </a>
                            <form class="dropdown-menu p-4 filter-dropdown">
                              <div className="filter-popup">
                                <div className="portlet px-2 py-2">
                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">From</label>
                                    </div>
                                    <div className="col-md-10">
                                      {" "}
                                      <input
                                        type="text"
                                        required
                                        placeholder="Sender Email Id"
                                        value={Sender}
                                        className="w-100 form-control my-2"
                                        name="Sender"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">To</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      {" "}
                                      <input
                                        type="text"
                                        required
                                        placeholder="Recipient Email Id"
                                        className="w-100 form-control my-2"
                                        value={Receiver}
                                        name="Receiver"
                                        onChange={(text) =>
                                          setReceiver(text.target.value)
                                        }
                                      />
                                      <div style={{ color: "red" }}>
                                        {MessageReceiver}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">Subject</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      <input
                                        type="text"
                                        required
                                        placeholder="Subject"
                                        value={subject}
                                        onChange={(text) => {
                                          setSubject(text.target.value);
                                        }}
                                        className="w-100 form-control my-2"
                                        name="Subject"
                                      />
                                      <div style={{ color: "red" }}>
                                        {MessageSubject}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2 text-start">
                                      <label class="my-2 pt-2">Message</label>
                                      <label class="field-required"> * </label>
                                    </div>
                                    <div className="col-md-10">
                                      <textarea
                                        name=""
                                        id=""
                                        cols="80"
                                        rows="7"
                                        className="w-100 form-control my-2 gscmsg"
                                        value={EmailMessage}
                                        onChange={(text) => {
                                          setEmailMessage(text.target.value);
                                        }}
                                      ></textarea>
                                      <div style={{ color: "red" }}>
                                        {Message}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="col-md-2"></div>
                                    <div className="col-md-10 my-2">
                                      <button
                                        type="button"
                                        class="btn btn-primary"
                                        style={{
                                          width: 70 + "px",
                                          height: 30 + "px",
                                        }}
                                        onClick={() => {
                                          if (isValidEmailData()) {
                                            sendEmail();
                                          }
                                        }}
                                      >
                                        {" "}
                                        Send
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </a>
                      </div>

                      <div className="row">
                        <div className="col-md-12 download-filter">
                          <span
                            class="dropdown"
                            style={{
                              marginRight: 0 + "px",
                              textAlign: "right",
                              display: "block",
                            }}
                          >
                            {/* {getCheckPermission("export", "modules") ? ( */}
                            <button
                              class="outline-btn dropdown-toggle btn btn-primary"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                class="fa fa-download "
                                aria-hidden="true"
                              ></i>
                            </button>
                            {/* ) : (
                              <></>
                            )} */}
                            <div
                              class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <div
                                onClick={async () => {
                                  setLoading(true)
                                  const promise = await downloadPDFfile(image, moduleROI)
                                  try {
                                    if (promise) {
                                      setLoading(false);
                                    } else {
                                      setLoading(false);
                                    }
                                  } catch (e) {
                                    setLoading(false);
                                  }
                                }}
                              >
                                <a class="dropdown-item">PDF</a>
                              </div>
                            </div>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          <TabPanel>
            <PDFExport
              paperSize="A1"
              margin="2cm"
              fileName={moduleROI}
              ref={pdfExportComponent}
              forcePageBreak=".page-break"
            >
              <div ref={image} >
                <div className="roi-pdf" id="web-page-pdf">
                  <div className="ROI-outer">
                    <div
                      className={
                        roiCheck
                          ? "col-md-3 col-sm-12 d-flex ROI-inner active pt-2"
                          : "col-md-3 col-sm-12 d-flex ROI-inner pt-2"
                      }
                    >
                      <div className="text-start">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setROICheck(e.target.checked);
                            setCheckboxValue(e.target.checked, 1)
                          }}
                          checked={roiCheck}
                        />
                      </div>
                      <div className="ms-3">
                        <h4 className="mt-2">ROI</h4>
                        <p className="text-black">
                          {parseFloat(rolListDataAVG).toFixed(2)}
                        </p>
                      </div>
                    </div>

                    <div
                      className={
                        cpcCheck
                          ? "col-md-3 col-sm-12 d-flex ROI-inner active pt-2"
                          : "col-md-3 col-sm-12 d-flex ROI-inner pt-2"
                      }
                    >
                      <div className="text-start">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setCPCCheck(e.target.checked);
                            setCheckboxValue(e.target.checked, 2);
                          }}
                          checked={cpcCheck}
                        />
                      </div>
                      <div className="ms-3">
                        <h4 className="mt-2">CPC</h4>
                        <p className="text-black">
                          {parseFloat(cpcListDataAVG).toFixed(2)}
                        </p>
                      </div>
                    </div>
                    <div
                      className={
                        cpsCheck
                          ? "col-md-3 col-sm-12 d-flex ROI-inner active pt-2"
                          : "col-md-3 col-sm-12 d-flex ROI-inner pt-2"
                      }
                    >
                      <div className="text-start">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setCPSCheck(e.target.checked);
                            setCheckboxValue(e.target.checked, 3)
                          }}
                          checked={cpsCheck}
                        />
                      </div>
                      <div className="ms-3">
                        <h4 className="mt-2">CPS</h4>
                        <p className="text-black">
                          {parseFloat(cpsListDataAVG).toFixed(2)}
                        </p>
                        {/* <p className="text-black">0</p> */}
                      </div>
                    </div>

                    <div
                      className={
                        cplCheck
                          ? "col-md-3 col-sm-12 d-flex ROI-inner active pt-2"
                          : "col-md-3 col-sm-12 d-flex ROI-inner pt-2"
                      }
                    >
                      <div className="text-start">
                        <input
                          type="checkbox"
                          onChange={(e) => {
                            setCPLCheck(e.target.checked);
                            setCheckboxValue(e.target.checked, 4)
                          }}
                          checked={cplCheck}
                        />
                      </div>
                      <div className="ms-3">
                        <h4 className="mt-2">CPL</h4>
                        <p className="text-black">
                          {parseFloat(cplListDataAVG).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="portlet px-2 py-4">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="row">
                          <Chart
                            options={options1}
                            series={series1}
                            type="line"
                            height="380px"
                          />
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="portlet px-2 py-4">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="col-md-12 text-end estimate-table">
                          <div className="d-flex justify-content-end align-items-center">
                            <span class="dropdown">
                              {/* {getCheckPermission("export", "modules") ? ( */}
                              <button
                                class="btn btn-primary dropdown-toggle"
                                type="button"
                                onClick={() => {
                                  exportToEXCEL(
                                    selectedQueryExportList?.length > 0
                                      ? selectedQueryExportList
                                      : exportList,
                                    "ROI Calculator"
                                  );
                                }}
                              >
                                Export
                              </button>
                              {/* ) : (
                                <></>
                              )} */}
                              {/* <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedQueryExportList?.length > 0
                                        ? selectedQueryExportList
                                        : exportList,
                                      "ROI Calculator"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">Excel</a>
                                </div>
                                <div
                                  onClick={() => {
                                    exportInToCSV(
                                      selectedQueryExportList?.length > 0
                                        ? selectedQueryExportList
                                        : exportList,
                                      "ROI Calculator"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">CSV</a>
                                </div>
                              </div> */}
                            </span>
                          </div>
                        </div>
                        <div className="row">
                          <Table
                            className="table table-bordered  table-hover  mt-3"
                            id="datatable-2"
                            columns={tableColumns}
                            dataSource={tableData}
                            rowSelection={{ type: "checkbox", ...rowSelection }}
                          // pagination={{
                          // position: ["bottomRight"],
                          // showSizeChanger: true,
                          // }}
                          />
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="portlet px-2 py-3">
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <div className="d-flex">
                          <Switch size="small" onChange={onChangeSwitch} />
                          <h5 className="ms-3 compare-paid-organic">
                            Compare Paid CPC with Organic CPC
                          </h5>
                        </div>
                        <div className="row">
                          {switchValue ? (
                            <>
                              {/* <div className="portlet"> */}
                              <div className="organic ROI-outer d-flex justify-content-between w-auto">
                                <div className="cpc ROI-inner h-auto">
                                  <div className="mt-3">
                                    <h4>
                                      {parseFloat(cpcListDataCompTotal).toFixed(2)}
                                    </h4>
                                    <p className="text-black">Organic CPC</p>
                                  </div>
                                </div>

                                <div className="cpc ROI-inner h-auto">
                                  <div className="mt-3">
                                    <h4>
                                      {parseFloat(rolListDataCompTotal).toFixed(2)}
                                    </h4>
                                    <p>Paid CPC</p>
                                  </div>
                                </div>
                              </div>
                              {/* </div> */}

                              <Chart
                                options={optionsChart}
                                // options={{
                                //   title: {
                                //     text: "",
                                //     align: "left",
                                //   },
                                //   chart: {
                                //     toolbar: {
                                //       show: true,
                                //       offsetX: 0,
                                //       offsetY: 0,
                                //       tools: {
                                //         download: true,
                                //         zoom: false,
                                //         zoomin: true,
                                //         zoomout: true,
                                //         customIcons: [],
                                //       },
                                //     },
                                //   },
                                //   dataLabels: {
                                //     formatter: () => {
                                //       return "";
                                //     },
                                //   },
                                //   grid: {
                                //     row: {
                                //       colors: ["#f3f3f3", "transparent"],
                                //     },
                                //   },
                                //   xaxis: {
                                //     tickPlacement: "on",
                                //     categories: options11,
                                //   },
                                //   yaxis: {
                                //     logBase: 0,
                                //     tickAmount: 9,
                                //     min: 0,
                                //     labels: {
                                //       formatter: function (val) {
                                //         return (val.toFixed(2));
                                //       },
                                //     },
                                //   },
                                // }}

                                series={series11}
                                type="line"
                                height="380px"
                              />
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="row mt-4">
                          <div className="col-md-12">
                            <div className="d-flex justify-content-center"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div></PDFExport>
          </TabPanel>
        </Tabs>
      </div>
      <Loading isLoading={isLoading} />
    </>
  );
}

export default ModuleExpandROICalculator;
