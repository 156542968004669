import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { components } from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import {
  calanderDate,
  exportInToCSV,
  exportToEXCEL,
  // getCheckPermission,
  downloadPDFfile,
  getBase64path,
  dateRagngeSelectWeek,
  dateRagngeSelectFornightly,
  dateRagngeSelectMonthly,
  getWeek,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import {
  moduleWebsiteAuthority,
  sendEmailApi,
  dachart,
  pachart,
  getDAPAconfig,
} from "../utility/Constant";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";
import { head } from "superagent";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function ModuleExpandWebsiteAuthority() {
  const [options, setObject] = useState({});
  const [paoptions, setPAObject] = useState({});
  const [series, setSeries] = useState([]);
  const [paseries, setpaSeries] = useState([]); //Page Authority
  const [GraphDASelected, setGraphDASelected] = useState([0]);
  const [GraphDATabOptions, setGraphDATabOptions] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [paselectedExportList, setPASelectedExportList] = useState([]); //Page Authority
  const [paexportList, setPAExportList] = useState([]); // Page Authority
  const [DATable, setDATable] = useState([]);
  const [PATable, setPATable] = useState([]); //Page Authority
  const [DACol, setDACol] = useState([]);
  const [urlList, setURLList] = useState([]);
  const [PACol, setPACol] = useState([]); //Page Authority
  const [subject, setSubject] = useState("Website Authority Reports");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [selectedUrl, setSelectedUrl] = useState("");
  const [displayTable, setDisplayTable] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      pageSize: 9,
      pageSizeOptions: [9, 10, 20, 50, 100],
    },
  });
  // const [noContent, setNoContent] = useState(false);
  const [noConfig, setNoConfig] = useState(true);
  const [color, setcolor] = useState([
    "#4e73df",
    "#008000",
    "#ffc107",
    "#dc3545",
    "#a52a2a",
    "#deb887",
    "#5f9ea0",
    "#8b008b",
    "#e9967a",
    "#c71585",
  ]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getDAPAData();
  }, []);

  useEffect(() => {
    localStorage.setItem("isCompareVar2", false);
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Website Authority Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);

    var columns = [
      {
        title: "WebSite",
        dataIndex: "website",
        key: "website",
      },
      {
        title: "Domain Authority",
        dataIndex: "domainAuthority",
        key: "domainAuthority",
      },
    ];
    setDACol(columns);
    setDATable();
  }, []);

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Website Authority Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    var data = [];
    var columns1 = [
      {
        title: "WebSite",
        dataIndex: "website",
        key: "website",
      },
      {
        title: " Page Authority",
        dataIndex: "pageAuthority",
        key: "pageAuthority",
      },
    ];
    setPATable();
    setPACol(columns1);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          key: item.key,
          website: item.website,
          domainAuthority: item.domainAuthority,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  const parowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          key: item.key,
          website: item.website,
          pageAuthority: item.pageAuthority,
        };
        list.push(obj);
      });
      setPASelectedExportList(list);
    },
  };

  const handlePaginationChange = (pagination) => {
    setTableParams({
      pagination: {
        pageSize: pagination.pageSize,
        position: ["bottomRight"],
        showSizeChanger: true,
        pageSizeOptions: [9, 10, 20, 50, 100],
      },
    });
  };

  const applyBtn = useRef(null);
  const applyBtn2 = useRef(null);
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document?.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document?.removeEventListener("mousedown", checkIfClickedOutside);
      // let tag = localStorage.getItem("isCompareVar");
      // if (tag == "false") {
      //   console.log(document.getElementsByClassName("rdrDateDisplayWrapper").length);
      //   if (document.getElementsByClassName("rdrDateDisplayWrapper").length)
      //     document
      //       .getElementsByClassName("rdrDateDisplayWrapper")[0]
      //       .children[1].classList.add("display-none");
      // }
      if (!isMenuOpen) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  const [open, setOpen] = useState(false);
  const [orhead1, setORHead1] = useState(values[0].format());
  const [orhead2, setORHead2] = useState(values[1]?.format());
  const [orhead11, setORHead11] = useState(headValues[0].format());
  const [orhead22, setORHead22] = useState(headValues[1]?.format());
  const [head1, setHead1] = useState(values[0].format());
  const [head2, setHead2] = useState(values[1]?.format());
  const [head11, setHead11] = useState(headValues[0].format());
  const [head22, setHead22] = useState(headValues[1]?.format());
  const [headingofChart, setheadingofChart] = useState("");
  const [calendarType, setCalendarType] = useState("");
  const [chartcomp, setchartcomp] = useState([]);

  function getDAPAData() {
    APIManager.getAPI(getDAPAconfig + localStorage.getItem("projectGId")).then(
      (response) => {
        var chartStartDate, chartEndDate;
        if (response && response.status == 200) {
          setNoConfig(false);
          // setNoContent(true);
          setCalendarType(response?.data.frequency);

          if (response?.data.frequency == 1) {
            var todayDate = new Date();
            var week = getWeek(new Date(todayDate));

            let selection = {
              selection1: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection1",
              },
              selection2: {
                startDate: new Date(week[0]),
                endDate: new Date(week[week.length - 1]),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;

            setValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHeadValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());
          } else if (response?.data.frequency == 2) {
            var todayDate = new Date();

            let selection = {
              selection1: {
                startDate: addDays(todayDate, -14),
                endDate: addDays(todayDate, 0),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(todayDate, -14),
                endDate: addDays(todayDate, 0),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;

            setValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHeadValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());
          } else if (response?.data.frequency == 3) {
            var todayDate = new Date();
            var firstDay = new Date(
              todayDate.getFullYear(),
              todayDate.getMonth(),
              1
            );
            var lastDay = new Date(
              todayDate.getFullYear(),
              todayDate.getMonth() + 1,
              0
            );

            let selection = {
              selection1: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection1",
              },
              selection2: {
                startDate: firstDay,
                endDate: lastDay,
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;

            setValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHeadValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());
          } else {
            let selection = {
              selection1: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection1",
              },
              selection2: {
                startDate: addDays(new Date(), -7),
                endDate: addDays(new Date(), -1),
                key: "selection2",
              },
            };
            setState({ ...state, ...selection });
            chartStartDate = selection?.selection1?.startDate;
            chartEndDate = selection?.selection1?.endDate;

            setValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHeadValues([
              new DateObject(selection?.selection1?.startDate),
              new DateObject(selection?.selection1?.endDate),
            ]);
            setHead1(new DateObject(selection?.selection1?.startDate).format());
            setHead2(new DateObject(selection?.selection1?.endDate).format());
          }

          let urllist = [];
          if (response.data.dapaUrlConfigurations?.length) {
            response.data.dapaUrlConfigurations.map((item) => {
              let urlobj = {
                key: item.urls.id,
                id: item.urls.id,
                value: item.urls.fieldValue,
                label: item.urls.fieldValue,
                url: item.urls.fieldValue,
                competitors: item.dapaCompetitorConfigurations,
              };
              urllist.push(urlobj);
            });
            setSelectedUrl(urllist[0]?.id);
            setURLList(urllist);
            let darowChartData = {
              UrlId: urllist[0].id,
              StartDate: mmDDyyyyFormateDateSet(chartStartDate),
              EndDate: mmDDyyyyFormateDateSet(chartEndDate),
              projectId: localStorage.getItem("projectGId"),
            };
            let comps = [];
            urllist[0]?.competitors?.map((c) => {
              // let url = c.competitors.fieldValue.split('://')[1]
              comps.push(c.competitors.fieldValue.toLowerCase());
            });
            setchartcomp(comps);
            chartda(darowChartData, comps);
            chartpa(darowChartData, comps);
            // setNoContent(!response.data?.length);
          } else {
          }
          setCalendarType(response.data.frequency);
          // setNoConfig(false);
          // setNoContent(true);
        }
      }
    );
  }

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
    setHead11(headValues[0].format());
    setHead22(headValues[1].format());
    setORHead1(values[0].format());
    setORHead2(values[1].format());
    setORHead11(headValues[0].format());
    setORHead22(headValues[1].format());
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = "light";
    if (document.querySelector("body").classList.contains("theme-dark")) {
      theme = "dark";
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image);
    let rowData = {
      from: Sender.trim(),
      to: Receiver.trim(),
      subject: subject.trim(),
      body: EmailMessage.trim(),
      base64Data: fileBase64,
      filename: moduleWebsiteAuthority,
    };
    APIManager.postAPI(sendEmailApi, rowData, null, true).then((response) => {
      if (response?.status == 200) {
        applyBtn.current.click();
        setReceiver("");
        setSubject("Traffic Reports");
        setEmailMessage(EmailMessage1);
        if (theme == "dark") {
          document.querySelector("body").classList.remove("theme-light");
          document.querySelector("body").classList.add("theme-dark");
        }
      }
    });
  }

  function chartda(darowChartData, chartcomp) {
    APIManager.postAPI(dachart, darowChartData, null, true).then((response) => {
      if (
        response &&
        response.status == 200 &&
        response.data != "No Content Found"
      ) {
        let dataForGraph = [];
        let optiondate = {};
        let graphData = [];
        let graphData2 = [];
        let graphSeries = [];
        let grapphCategories = [];
        const startDate = darowChartData.StartDate;
        const endDate = darowChartData.EndDate;
        graphSeries.push();
        if (response.data.length) {
          let datagraph = [];
          let i = 0;
          let list = [];
          let exportList = [];
          chartcomp.map((c) => {
            response.data.map((j) => {
              if (chartcomp?.includes(j.website.toLowerCase())) {
                if (c.toLowerCase() == j.website.toLowerCase()) {
                  let obj = {
                    key: (i += 1),
                    website: j.website,
                    domainAuthority: j.da,
                  };
                  list.push(obj);
                  graphData.push(j.da);
                  let str = j.website;
                  var array = str.split("www.")[1];
                  grapphCategories.push(array.split(".")[0]);
                }
                exportList.push({
                  key: (i += 1),
                  website: j.website,
                  domainAuthority: j.da,
                });
              }
            });
          });
          setDATable(list);
          setExportList(exportList);
          optiondate = {
            chart: {
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  customIcons: [],
                },
              },
              stroke: {
                curve: "smooth",
              },
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
              },
            },
            xaxis: {
              tickPlacement: "on",
              categories: grapphCategories,
              title: {
                text: "Websites",
                style: {
                  color: "rgba(0, 0, 0, 0.36)",
                },
              },
            },
            yaxis: {
              logBase: 5,
              tickAmount: 5,
              min: 0,
              title: {
                text: "Domain Authority",
                style: {
                  color: "rgba(0, 0, 0, 0.36)",
                },
              },
            },
          };
          dataForGraph.push({
            name: "da",
            data: graphData,
          });
          setObject(optiondate);
          setSeries(dataForGraph);
        }
      }
    });
  }

  function name(str) {
    var array = str.split("www.")[1];
    return array.split(".")[0];
  }

  function chartpa(parowChartData, chartcomp) {
    APIManager.postAPI(pachart, parowChartData, null, true).then((response) => {
      if (
        response &&
        response.status == 200 &&
        response.data != "No Content Found"
      ) {
        let dataForGraph = [];
        let optiondate = {};
        let graphData = [];
        let graphData2 = [];
        let graphSeries = [];
        let grapphCategories = [];
        const startDate = parowChartData.StartDate;
        const endDate = parowChartData.EndDate;
        graphSeries.push();
        if (response.data.length) {
          let datagraph = [];
          let j = 0;
          let list = [];
          let exportList = [];
          chartcomp.map((c) => {
            response.data.map((i) => {
              if (chartcomp?.includes(i.website)) {
                if (c == i.website) {
                  let obj = {
                    key: (j += 1),
                    website: i.website,
                    pageAuthority: i.pa,
                  };
                  list.push(obj);
                  graphData.push(i.pa);
                  let str = i.website;
                  var array = str.split("www.")[1];
                  grapphCategories.push(array.split(".")[0]);
                }
                exportList.push({
                  key: (j += 1),
                  website: i.website,
                  pageAuthority: i.pa,
                });
              }
            });
          });
          setPATable(list);
          setPAExportList(exportList);
          optiondate = {
            chart: {
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: true,
                  zoom: false,
                  zoomin: true,
                  zoomout: true,
                  customIcons: [],
                },
              },
              stroke: {
                curve: "smooth",
              },
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
              },
            },
            xaxis: {
              tickPlacement: "on",
              categories: grapphCategories,
              title: {
                text: "Websites",
                style: {
                  color: "#000",
                },
              },
            },
            yaxis: {
              logBase: 5,
              tickAmount: 5,
              min: 0,
              title: {
                text: "Page Authority",
                style: {
                  color: "#000",
                },
              },
            },
          };
          dataForGraph.push({
            name: "pa",
            data: graphData,
          });
          setPAObject(optiondate);
          setpaSeries(dataForGraph);
        }
      }
    });
  }

  function chartHeading(value) {
    GraphDATabOptions.map((item) => {
      if (value == item.value) {
        setheadingofChart(item.value);
      }
    });
  }

  function handleUrlChange(selected) {
    setSelectedUrl(selected.target.value);
    let comps = [];
    urlList?.map((c) => {
      if (c.id.toLowerCase() == selected.target.value.toLowerCase()) {
        c.competitors.map((comp) => {
          // let url = comp.competitors.fieldValue.split('://')[1]
          comps.push(comp.competitors.fieldValue.toLowerCase());
        });
      }
    });
    setchartcomp(comps);
  }

  const [WebSiteAuthority, setWebSiteAuthority] = useState(false);

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      {noConfig ? (
        <>
          <div class=" message text-center ">
            <h4>
              The Website Authority configuration is not set. <br />
              Please contact your administrator to set configuration.
            </h4>
          </div>
        </>
      ) : (
        <Tabs>
          <div className="portlet">
            <div className="portlet-header portlet-header-bordered my-3">
              <div className="col-md-8 col-sm-12">
                <TabList className="nav nav-lines portlet-nav">
                  <Tab
                    className={
                      WebSiteAuthority
                        ? "nav-item nav-link"
                        : "nav-item nav-link  active"
                    }
                    onClick={() => {
                      setWebSiteAuthority(false);
                    }}
                  >
                    Domain Authority
                  </Tab>
                  <Tab
                    className={
                      WebSiteAuthority
                        ? "nav-item nav-link  active"
                        : "nav-item nav-link"
                    }
                    onClick={() => {
                      setWebSiteAuthority(true);
                    }}
                  >
                    Page Authority
                  </Tab>
                </TabList>
              </div>
              <div
                ref={ref}
                class="col-md-4 col-sm-12 calendar-main d-flex justify-content-end align-items-center ga-calender"
              >
                <div className="add-new-btnw">
                  <button
                    className="btn btn-primary"
                    style={{ width: "250px" }}
                    onClick={() => setIsMenuOpen(!open)}
                  >
                    {calanderDate(
                      state.selection1?.startDate,
                      state.selection1?.endDate
                    )}
                  </button>
                </div>
                {isMenuOpen && (
                  <div id="example-collapse-text-calendar">
                    <Card body className="daterange-picker-card  mt-2">
                      <div className="d-flex justify-content-between">
                        <div className="calendar-col">
                          <DateRangePicker
                            onChange={(item) => {
                              let isCompareVarNew =
                                localStorage.getItem("isCompareVar2");
                              // let selection = {};
                              // if (isCompareVarNew == "false") {
                              //   let selection23 = {
                              //     selection1: {
                              //       startDate: item?.selection1.startDate,
                              //       endDate: item?.selection1.endDate,
                              //       key: "selection1",
                              //     },
                              //   };
                              //   selection = dateRagngeSelectWeek(
                              //     selection23,
                              //     state
                              //   );
                              // }
                              // if (isCompareVarNew == "true") {
                              //   selection = dateRagngeSelectWeek(item, state);
                              // }
                              // setState({
                              //   ...state,
                              //   ...item,
                              //   ...selection,
                              // });
                              // setTimeout(() => {
                              // }, 1000);

                              if (calendarType == 1) {
                                let selection = {};

                                if (isCompareVarNew == "false") {
                                  let selection23 = {
                                    selection1: {
                                      startDate: item?.selection1
                                        ? item?.selection1?.startDate
                                        : item?.selection2?.startDate,
                                      endDate: item?.selection1
                                        ? item?.selection1?.endDate
                                        : item?.selection2?.endDate,
                                      key: "selection1",
                                    },
                                  };

                                  selection = dateRagngeSelectWeek(
                                    selection23,
                                    state,
                                    isCompareVarNew
                                  );

                                  setValues([
                                    new DateObject(
                                      selection?.selection1?.startDate
                                    ),
                                    new DateObject(
                                      selection?.selection1?.endDate
                                    ),
                                  ]);
                                  setHead1(
                                    new DateObject(
                                      selection?.selection1?.startDate
                                    ).format()
                                  );
                                  setHead2(
                                    new DateObject(
                                      selection?.selection1?.endDate
                                    ).format()
                                  );
                                }

                                // if (isCompareVarNew == "true") {
                                //   selection = dateRagngeSelectWeek(
                                //     item,
                                //     state,
                                //     isCompareVarNew
                                //   );

                                //   setValues([new DateObject(selection?.selection2?.startDate), new DateObject(selection?.selection2?.endDate)]);
                                //   setHead1(new DateObject(selection?.selection2?.startDate).format());
                                //   setHead2(new DateObject(selection?.selection2?.endDate).format());
                                // }

                                setState({
                                  ...state,
                                  ...item,
                                  ...selection,
                                });
                              } else if (calendarType == 2) {
                                let selection = {};

                                if (isCompareVarNew == "false") {
                                  let selection23 = {
                                    selection1: {
                                      startDate: item?.selection1
                                        ? item?.selection1?.startDate
                                        : item?.selection2?.startDate,
                                      endDate: item?.selection1
                                        ? item?.selection1?.endDate
                                        : item?.selection2?.endDate,
                                      key: "selection1",
                                    },
                                  };

                                  selection = dateRagngeSelectFornightly(
                                    selection23,
                                    state,
                                    isCompareVarNew
                                  );

                                  setValues([
                                    new DateObject(
                                      selection?.selection1?.startDate
                                    ),
                                    new DateObject(
                                      selection?.selection1?.endDate
                                    ),
                                  ]);
                                  setHead1(
                                    new DateObject(
                                      selection?.selection1?.startDate
                                    ).format()
                                  );
                                  setHead2(
                                    new DateObject(
                                      selection?.selection1?.endDate
                                    ).format()
                                  );
                                }

                                setState({
                                  ...state,
                                  ...item,
                                  ...selection,
                                });
                              } else if (calendarType == 3) {
                                let selection = {};

                                if (isCompareVarNew == "false") {
                                  let selection23 = {
                                    selection1: {
                                      startDate: item?.selection1
                                        ? item?.selection1?.startDate
                                        : item?.selection2?.startDate,
                                      endDate: item?.selection1
                                        ? item?.selection1?.endDate
                                        : item?.selection2?.endDate,
                                      key: "selection1",
                                    },
                                  };

                                  selection = dateRagngeSelectMonthly(
                                    selection23,
                                    state,
                                    isCompareVarNew
                                  );

                                  setValues([
                                    new DateObject(
                                      selection?.selection1?.startDate
                                    ),
                                    new DateObject(
                                      selection?.selection1?.endDate
                                    ),
                                  ]);
                                  setHead1(
                                    new DateObject(
                                      selection?.selection1?.startDate
                                    ).format()
                                  );
                                  setHead2(
                                    new DateObject(
                                      selection?.selection1?.endDate
                                    ).format()
                                  );
                                }

                                setState({
                                  ...state,
                                  ...item,
                                  ...selection,
                                });
                              } else {
                                if (isCompareVarNew == "false") {
                                  let selection23 = {};
                                  if (item?.selection1) {
                                    selection23 = {
                                      selection2: {
                                        startDate: item?.selection1?.startDate,
                                        endDate: item?.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    };

                                    setValues([
                                      new DateObject(
                                        item?.selection1?.startDate
                                      ),
                                      new DateObject(item?.selection1?.endDate),
                                    ]);
                                    setHead1(
                                      new DateObject(
                                        item?.selection1?.startDate
                                      ).format()
                                    );
                                    setHead2(
                                      new DateObject(
                                        item?.selection1?.endDate
                                      ).format()
                                    );
                                  } else if (item?.selection2) {
                                    selection23 = {
                                      selection1: {
                                        startDate: item?.selection2?.startDate,
                                        endDate: item?.selection2?.endDate,
                                        key: "selection1",
                                      },
                                    };

                                    setValues([
                                      new DateObject(
                                        item?.selection2?.startDate
                                      ),
                                      new DateObject(item?.selection2?.endDate),
                                    ]);
                                    setHead1(
                                      new DateObject(
                                        item?.selection2?.startDate
                                      ).format()
                                    );
                                    setHead2(
                                      new DateObject(
                                        item?.selection2?.endDate
                                      ).format()
                                    );
                                  }

                                  setState({
                                    ...state,
                                    ...item,
                                    ...selection23,
                                  });
                                }
                              }
                            }}
                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={[state?.selection1]}
                            direction="horizontal"
                            ariaLabels={{
                              dateInput: {
                                selection1: {
                                  startDate: "start date input of selction 1",
                                  endDate: "end date input of selction 1",
                                },
                                selection2: {
                                  startDate: "start date input of selction 2",
                                  endDate: "end date input of selction 2",
                                },
                              },
                              monthPicker: "month picker",
                              yearPicker: "year picker",
                              prevButton: "previous month button",
                              nextButton: "next month button",
                            }}
                          />
                        </div>
                        <div className="ms-3">
                          <button
                            onClick={() => {
                              setheadvalues();
                              setIsMenuOpen(!isMenuOpen);
                              let darowChartData = {
                                UrlId: selectedUrl,
                                StartDate: mmDDyyyyFormateDateSet(
                                  state.selection1.startDate
                                ),
                                EndDate: mmDDyyyyFormateDateSet(
                                  state.selection1.endDate
                                ),
                                projectId: localStorage.getItem("projectGId"),
                              };
                              chartda(darowChartData, chartcomp);
                              chartpa(darowChartData, chartcomp);
                              setRefreshTicketData(true);
                              setTimeout(() => {
                                setRefreshTicketData(false);
                              }, 1000);
                            }}
                            className="btn-outline btn btn-primary"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
                <div className="d-flex 3-icons">
                  <a className="bar-link-icon ms-3" style={{ color: "black" }}>
                    <div class="dropdown">
                      <a
                        type="button"
                        ref={applyBtn2}
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-filter" aria-hidden="true"></i>
                      </a>
                      <form class="dropdown-menu p-4">
                        <div className="filter-popup">
                          <div className="portlet px-2 py-2">
                            <div className="col-md-12 my-3">
                              <label style={{ fontSize: 14 + "px" }}>
                                Page
                              </label>
                              <select
                                className="w-100"
                                style={{ padding: 8 + "px" }}
                                value={selectedUrl}
                                onChange={handleUrlChange}
                              >
                                {urlList.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {item.value}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-12 text-left mt-3">
                            <button
                              type="button"
                              className="btn btn-primary me-3"
                              style={{
                                width: 70 + "px",
                                height: 30 + "px",
                              }}
                              onClick={() => {
                                applyBtn2.current.click();
                                let darowChartData = {
                                  UrlId: selectedUrl,
                                  StartDate: mmDDyyyyFormateDateSet(
                                    state.selection1.startDate
                                  ),
                                  EndDate: mmDDyyyyFormateDateSet(
                                    state.selection1.endDate
                                  ),
                                  projectId: localStorage.getItem("projectGId"),
                                };
                                chartda(darowChartData, chartcomp);
                                chartpa(darowChartData, chartcomp);
                                chartHeading(GraphDASelected);
                                setTimeout(() => {
                                  setRefreshTicketData(false);
                                }, 1000);
                              }}
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </a>
                  <div className="ms-3  ">
                    <a
                      className="share bar-link-icon"
                      style={{ color: "black" }}
                    >
                      <div class="dropdown">
                        <a
                          type="button"
                          ref={applyBtn}
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-share-alt" aria-hidden="true"></i>
                        </a>
                        <form class="dropdown-menu p-4 filter-dropdown">
                          <div className="filter-popup">
                            <div className="portlet px-2 py-2">
                              <div className="row">
                                <div className="col-md-2 text-start">
                                  <label class="my-2 pt-2">From</label>
                                </div>
                                <div className="col-md-10">
                                  {" "}
                                  <input
                                    type="text"
                                    required
                                    placeholder="Sender Email Id"
                                    value={Sender}
                                    className="w-100 form-control my-2"
                                    name="Sender"
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2 text-start">
                                  <label class="my-2 pt-2">To</label>
                                  <label class="field-required"> * </label>
                                </div>
                                <div className="col-md-10">
                                  {" "}
                                  <input
                                    type="text"
                                    required
                                    placeholder="Recipient Email Id"
                                    className="w-100 form-control form-control my-2"
                                    value={Receiver}
                                    name="Receiver"
                                    onChange={(text) =>
                                      setReceiver(text.target.value)
                                    }
                                  />
                                  <div style={{ color: "red" }}>
                                    {MessageReceiver}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2 text-start">
                                  <label class="my-2 pt-2">Subject</label>
                                  <label class="field-required"> * </label>
                                </div>
                                <div className="col-md-10">
                                  <input
                                    type="text"
                                    required
                                    placeholder="Subject"
                                    value={subject}
                                    onChange={(text) => {
                                      setSubject(text.target.value);
                                    }}
                                    className="w-100 form-control my-2"
                                    name="Subject"
                                  />
                                  <div style={{ color: "red" }}>
                                    {MessageSubject}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2 text-start">
                                  {" "}
                                  <label class="my-2 pt-2">Message</label>
                                  <label class="field-required"> * </label>
                                </div>
                                <div className="col-md-10">
                                  <textarea
                                    name=""
                                    id=""
                                    cols="80"
                                    rows="7"
                                    className="w-100 form-control my-2"
                                    value={EmailMessage}
                                    onChange={(text) => {
                                      setEmailMessage(text.target.value);
                                    }}
                                  ></textarea>
                                  <div class="ms-3" style={{ color: "red" }}>
                                    {Message}
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-10 my-2">
                                  <button
                                    type="button"
                                    class="btn btn-primary"
                                    style={{
                                      width: 70 + "px",
                                      height: 30 + "px",
                                    }}
                                    onClick={() => {
                                      if (isValidEmailData()) {
                                        sendEmail();
                                      }
                                    }}
                                  >
                                    {" "}
                                    Send
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </a>
                  </div>
                  <div className="row">
                    <div className="col-md-12 download-filter">
                      <span
                        class="dropdown"
                        style={{
                          marginRight: 0 + "px",
                          textAlign: "right",
                          display: "block",
                        }}
                      >
                        {/* {getCheckPermission("export", "modules") ? ( */}
                        <button
                          class="outline-btn dropdown-toggle btn btn-primary"
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-download " aria-hidden="true"></i>
                        </button>
                        {/* ) : (
                          <></>
                        )} */}
                        <div
                          class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div
                            onClick={async () => {
                              setLoading(true);
                              const promise = await downloadPDFfile(
                                image,
                                moduleWebsiteAuthority
                              );
                              try {
                                if (promise) {
                                  setLoading(false);
                                } else {
                                  setLoading(false);
                                }
                              } catch (e) {
                                setLoading(false);
                              }
                            }}
                          >
                            <a class="dropdown-item">PDF</a>
                          </div>
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <TabPanel>
            {/* <div className="portlet mb-0 py-3"> */}
            {/* <div className="col-md-12 d-flex justify-content-end ">
                <a className="bar-link-icon" style={{ color: "black" }}>
                </a>
              </div> */}
            {/* <div className="row">
                <div className="col-md-6 mt-3"></div>
              </div> */}
            {/* </div> */}
            <PDFExport
              paperSize="A1"
              margin="2cm"
              fileName={moduleWebsiteAuthority}
              ref={pdfExportComponent}
              forcePageBreak=".page-break"
            >
              <div ref={image}>
                {/* {displayTable && !noContent ? ( */}
                <>
                  <div className="portlet py-3">
                    <div className="col-md-12">
                      <Chart
                        options={options}
                        series={series}
                        type="bar"
                        height="300"
                        id="chartId"
                      />
                    </div>
                  </div>
                  <div className="portlet mb-0 bg-white"></div>
                  <div className="portlet-body p-5 bg-white">
                    <div class="container align-items-center my-3 mx-3"></div>
                    <div className="portlet mt-4">
                      <div className="portlet-body">
                        <div className="row">
                          <div className="col-md-12">
                            <span
                              class="dropdown"
                              style={{
                                marginRight: 0 + "px",
                                textAlign: "right",
                                display: "block",
                              }}
                            >
                              <div className="me-3">
                                {/* {getCheckPermission("export", "modules") ? ( */}
                                <button
                                  class="outline-btn dropdown-toggle btn btn-primary"
                                  type="button"
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Website Authority"
                                    );
                                  }}
                                >
                                  Export
                                </button>
                                {/* ) : (
                                  <></>
                                )} */}
                                {/* <div
                                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : exportList,
                                        "Website Authority"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">Excel</a>
                                  </div>
                                  <div
                                    onClick={() => {
                                      exportInToCSV(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : exportList,
                                        "Website Authority"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">CSV</a>
                                  </div>
                                </div> */}
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="ga-table">
                          <Table
                            className="table table-bordered  table-hover mt-3  google-analytics-table "
                            id="datatable-1"
                            columns={DACol}
                            dataSource={DATable}
                            rowSelection={{ type: "checkbox", ...rowSelection }}
                            pagination={{
                              position: ["bottomRight"],
                              showSizeChanger: true,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
                {/* ) : (
                  <></>
                )} */}
                {/* {noContent ? (
                  <>
                    <div class=" message text-center ">
                      <h4>
                        The Website Authority configuration is not set. <br />
                        Please contact your administrator to set
                        configuration.
                      </h4>
                    </div>
                  </>
                ) : (
                  <></>
                )} */}
              </div>
            </PDFExport>
          </TabPanel>

          <TabPanel>
            <div className="portlet mb-0">
              <div className="row px-3 py-3 align-items-center">
                <div className="col-md-9 col-sm-12 d-flex justify-content-end align-items-center">
                  <a className="bar-link-icon" style={{ color: "black" }}></a>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mt-3"></div>
              </div>
            </div>
            <PDFExport
              paperSize="A1"
              margin="2cm"
              fileName={moduleWebsiteAuthority}
              ref={pdfExportComponent}
              forcePageBreak=".page-break"
            >
              <div ref={image}>
                <div className="portlet py-3">
                  <div className="col-md-12">
                    <Chart
                      options={paoptions}
                      series={paseries}
                      type="bar"
                      height="300"
                      id="chartId"
                    />
                  </div>
                </div>
                <div className="portlet mb-0 bg-white"></div>
                <div className="portlet-body p-5 bg-white">
                  <div className="portlet mt-4">
                    <div className="portlet-body">
                      <div className="row">
                        <div className="col-md-12">
                          <span
                            class="dropdown"
                            style={{
                              marginRight: 0 + "px",
                              textAlign: "right",
                              display: "block",
                            }}
                          >
                            <div className="me-3">
                              {/* {getCheckPermission("export", "modules") ? ( */}
                              <button
                                class="outline-btn dropdown-toggle btn btn-primary"
                                type="button"
                                onClick={() => {
                                  exportToEXCEL(
                                    paselectedExportList?.length > 0
                                      ? paselectedExportList
                                      : paexportList,
                                    "Page Authority"
                                  );
                                }}
                              >
                                Export
                              </button>
                              {/* ) : (
                                <></>
                              )} */}
                              {/* <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={() => {
                                    exportToEXCEL(
                                      paselectedExportList?.length > 0
                                        ? paselectedExportList
                                        : paexportList,
                                      "Page Authority"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">Excel</a>
                                </div>
                                <div
                                  onClick={() => {
                                    exportInToCSV(
                                      paselectedExportList?.length > 0
                                        ? paselectedExportList
                                        : paexportList,
                                      "Page Authority"
                                    );
                                  }}
                                >
                                  <a class="dropdown-item">CSV</a>
                                </div>
                              </div> */}
                            </div>
                          </span>
                        </div>
                      </div>
                      <div className="ga-table google-analytics-table">
                        <Table
                          className="table table-bordered  table-hover mt-3 "
                          id="datatable-1"
                          columns={PACol}
                          dataSource={PATable}
                          rowSelection={{ type: "checkbox", ...parowSelection }}
                          pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PDFExport>
          </TabPanel>
        </Tabs>
      )}
      <Loading isLoading={isLoading} />
    </>
  );
}

export default ModuleExpandWebsiteAuthority;
