import { Stepper } from '@mui/material';
import React, { useState } from 'react'
import { useEffect } from 'react';
import APIManager from '../../../services/APIManager';
import { getcampaignviewbyId, postcampaignview, updateCampingviewDetail } from '../../../utility/Constant';
import Createcampaignkeyword from './Createcampaignkeyword';
import Createcampaingreview from './Createcampaingreview';
import Createviewcompetitor from './Createviewcompetitor';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

function Createcampaingview(props) {
    useEffect(() => {
        if (props?.campaignviewID) {
            getcampaignViewDetails();
        }
        if (props?.device) {
            props?.device == 2 ? setDeviceType("2") : (props.device != 1 ? setDeviceType("0") : setDeviceType("1"))
        }

    }, [props])
    const [inputFields, setInputFields] = useState([{ Minimum: "", Maximum: "" }])
    const [selectedDeviceType, setDeviceType] = useState("0");
    const [status, setstatus] = useState("");
    const [campaignName, setcampaignName] = useState("");
    const [viewid, setviewid] = useState("");
    const [AddkeywordOpen, setAddkeywordOpen] = useState(false);

    const handleFormChangeMinimum = (index, event) => {
        let data = [...inputFields];
        data[index]['Minimum'] = event.target.value;
        setInputFields(data);
    }

    const handleFormChangeMaximum = (index, event) => {
        let data = [...inputFields];
        data[index]['Maximum'] = event.target.value;
        setInputFields(data);
    }

    const addFields = () => {
        let newfield = { Minimum: "", Maximum: "" };
        setInputFields([...inputFields, newfield]);
    }

    const removeFields = (index) => {
        let data = [...inputFields];
        data.splice(index, 1);
        setInputFields(data);
    }
    const handleChildToProp = () => {
        props.dataChild(0);
    };

    const [view, setview] = useState(0);
    const steps = [
        {
            status: "Campaign View Detail",
        },
        {
            status: "Keywords",
        },
        {
            status: "Competitors",
        },
        {
            status: "Review",
        }
    ];
    const [save, setsave] = useState(false);
    const [errors, setErrors] = useState("");
    function formValidation() {
        let error = { ...errors };
        campaignName.trim() == ""
            ? (error["campaignName"] = "Campaign Name is required!")
            : delete error["campaignName"];

        inputFields.map((item, i) => {
            !item['Minimum'].toString() ? (error[`Minimum_${i}`] = "Minimum Bucket value is required!")
                : delete error[`Minimum_${i}`];
            !item['Maximum'].toString() ? (error[`Maximum_${i}`] = "Maximum Bucket value is required!")
                : delete error[`Maximum_${i}`];
            if (item['Minimum'] && item['Maximum']) {
                +item['Minimum'] > +item['Maximum'] ? (error[`bucketsLength_${i}`] = "Minimum buckets must be less than maximum")
                    : delete error[`bucketsLength_${i}`];
            } else {
                delete error[`bucketsLength_${i}`];
            }
        })
        setErrors(error);

        let err = Object.keys(error);
        return err?.length ? false : true;
    }

    function handleNextbtn(val) {
        if (val == 0) {
            debugger
            console.log(props.campaignviewID);
            setsave(true);
            if (formValidation()) {
                props.campaignviewID ? UpdatecampaingViewdetails() : addcampaignview();
            }
            return;
        }
        setview(val + 1);
    }

    function handlePrevbtn(val) {

        setview(val - 1);
    }

    function addcampaignview() {
        let minValid = true;
        let maxValid = true;

        inputFields.map((input, index) => {
            if (input.Minimum > input.Maximum) {
                minValid = false;
                return minValid;
            }

            if (input.Maximum < input.Minimum) {
                maxValid = false;
                return maxValid;
            }
        });
        let postdata = {
            CampaignViewName: campaignName,
            Device: parseInt(selectedDeviceType),
            RankingCampaignConfigurationId: props?.campaignparentId,
            RankingCampaignViewBuckets: inputFields
        }

        APIManager.postAPI(postcampaignview, postdata, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                    setviewid(response.data.viewId)
                    setview(1);
                } else {
                    setview(0);
                }
            }
        )
    }

    function getcampaignViewDetails() {
        APIManager.postAPI(getcampaignviewbyId + props?.campaignviewID).then((response) => {
            if (response && response.status == 200) {
                const list = [];
                let data = []
                setcampaignName(response.data.campaignViewName);
                setDeviceType(response.data.device);
                setstatus(response.data.status);
                response.data.rankingCampaignViewBuckets.map((item) => {
                    let obj = {
                        Minimum: item.minimum,
                        Maximum: item.maximum
                    }
                    list.push(obj);
                })
                setInputFields(list);
            }
        });
    }

    function UpdatecampaingViewdetails() {
        APIManager.putAPI(
            updateCampingviewDetail,
            {
                Id: props.campaignviewID,
                CampaignViewName: campaignName,
                Device: selectedDeviceType,
                Status: status,
                RankingCampaignConfigurationId: props?.campaignparentId,
                RankingCampaignViewBuckets: inputFields
            },
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                setviewid(props?.campaignviewID)
                setview(1);
            } else {
                setview(0);
            }
        });
    }

    return (
        <div className="ranking-create-campaign px-3 mx-2">
            <div className='create-campaign-form'>
                <div className="d-flex align-items-center mt-4">
                    <a onClick={handleChildToProp} className="text-dark">
                        <i class="fa fa-angle-left back-arroww text-dark"></i>
                    </a>
                    <h1 className='portlet-title main-titl'>Create Campaign View</h1>
                </div>
                <Stepper className="campaign-stepper w-75 m-auto mt-5" activeStep={view} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label.status}>
                            <StepLabel>{label.status}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <div></div>
                {(() => {
                    switch (view) {
                        case 0:
                            return (<>
                                <div className='portlet-body px-0'>
                                    <div class="row mt-5">
                                        <div class="col-md-12">
                                            <h5><b> View Details</b></h5>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-3 d-flex align-items-center text-box">
                                            <label for="inline-form-username">Campaign View Name :</label>
                                            <label class="field-required"> * </label>
                                        </div>
                                        <div className="col-lg-6 col-md-7 ">
                                            <input
                                                type="text"
                                                class="form-control w-100"
                                                placeholder="Ranking Campaign View Name"
                                                value={campaignName}
                                                onChange={(e) => {
                                                    setcampaignName(e.target.value);
                                                }}
                                            />
                                            <div className="require-box w-100">
                                                {save && errors?.campaignName && (
                                                    <>
                                                        <div className="field-required">
                                                            <span> {errors?.campaignName}</span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div class="col-md-3 d-flex align-items-center text-box">
                                            <label for="inline-form-username">Device :</label>
                                            <label class="field-required"> * </label>
                                        </div>
                                        <div class="col-lg-6 col-md-7">
                                            <select required
                                                className="form-select w-100 mt-2"
                                                value={selectedDeviceType}
                                                disabled={props.device != 2 ? true : false}
                                                onChange={(e) => {
                                                    setDeviceType(e.target.value);
                                                }}
                                            >
                                                <option value="" disabled selected hidden>Select Device</option>
                                                <option value="0">Mobile</option>
                                                <option value="1">Desktop</option>
                                                <option value="2">Both</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="row mt-4">
                                        <div className="col-md-3 d-flex align-items-top">
                                            <div class="text-box mt-4">
                                                <div className="lable-box d-flex align-items-center">
                                                    <label for="inline-form-username">Ranking Buckets:  </label>
                                                    <label class="field-required"> * </label>
                                                    <svg fill="#808080" width="15px" height="16px" viewBox="-1.28 -1.28 18.56 18.56" xmlns="http://www.w3.org/2000/svg" stroke="#808080" stroke-width="0.00016" className='ms-2'>
                                                        <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.032" />
                                                        <g id="SVGRepo_iconCarrier">
                                                            <path d="M7.37 6.7h1.25v5H7.37z" />
                                                            <circle cx="8" cy="4.85" r=".65" />
                                                            <path d="M8 .5A7.77 7.77 0 0 0 0 8a7.77 7.77 0 0 0 8 7.5A7.77 7.77 0 0 0 16 8 7.77 7.77 0 0 0 8 .5zm0 13.75A6.52 6.52 0 0 1 1.25 8 6.52 6.52 0 0 1 8 1.75 6.52 6.52 0 0 1 14.75 8 6.52 6.52 0 0 1 8 14.25z" />
                                                        </g>
                                                    </svg>
                                                </div>
                                                <div className="span-box"> <span>(Max 7)</span></div>
                                            </div>
                                        </div>
                                        <div class="input-box  col-md-9 p-0 fix-col-width">
                                            <div className="input-fieldss position-relative d-block">
                                                <div class="row">

                                                    {inputFields?.map((input, index) => {
                                                        return (
                                                            <>
                                                                <div class="col-md-3 mt-3">
                                                                    <div className={index == 0 ? "input-plus text-center position-relative width-set first-child" : "input-plus text-center position-relative width-set"}>
                                                                        <div class="d-flex align-items-center position-relative" key={index}>
                                                                            <input type="text" class="form-control"
                                                                                value={input.Minimum}
                                                                                min="0"
                                                                                name="Minimum"
                                                                                placeholder="0"
                                                                                onChange={(event) => {
                                                                                    handleFormChangeMinimum(index, event);
                                                                                    formValidation();
                                                                                }}
                                                                            />
                                                                            <span className='mx-3'>-</span>
                                                                            <input type="text" class="form-control"
                                                                                value={input.Maximum}
                                                                                min="0"
                                                                                name="Maximum"
                                                                                onChange={(event) => {
                                                                                    handleFormChangeMaximum(index, event)
                                                                                    formValidation();
                                                                                }}
                                                                                placeholder="1" />
                                                                        </div>
                                                                        <div class="d-flex align-items-center" key={index}>
                                                                            {save && errors[`Minimum_${index}`] && (
                                                                                <>
                                                                                    <div className="field-required">
                                                                                        <span> {errors[`Minimum_${index}`]}</span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                            <span className='mx-3'></span>
                                                                            {save && errors[`Maximum_${index}`] && (
                                                                                <>
                                                                                    <div className="field-required">
                                                                                        <span> {errors[`Maximum_${index}`]}</span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div>
                                                                            {save && errors[`bucketsLength_${index}`] && (
                                                                                <>
                                                                                    <div className="field-required">
                                                                                        <span> {errors[`bucketsLength_${index}`]}</span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                        <div className="plus-box ms-4">
                                                                            {index == inputFields.length - 1 && inputFields.length < 7 ? (
                                                                                <>
                                                                                    <span onClick={addFields}>
                                                                                        <svg width="15px" height="15px" viewBox="0 0 24.00 24.00" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000" stroke-width="0.00024000000000000003">
                                                                                            <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                                                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.096" />
                                                                                            <g id="SVGRepo_iconCarrier"> <path d="M12 6C12.5523 6 13 6.44772 13 7V11H17C17.5523 11 18 11.4477 18 12C18 12.5523 17.5523 13 17 13H13V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V13H7C6.44772 13 6 12.5523 6 12C6 11.4477 6.44772 11 7 11H11V7C11 6.44772 11.4477 6 12 6Z" fill="#808080" /> <path fill-rule="evenodd" clip-rule="evenodd" d="M2 4.5C2 3.11929 3.11929 2 4.5 2H19.5C20.8807 2 22 3.11929 22 4.5V19.5C22 20.8807 20.8807 22 19.5 22H4.5C3.11929 22 2 20.8807 2 19.5V4.5ZM4.5 4C4.22386 4 4 4.22386 4 4.5V19.5C4 19.7761 4.22386 20 4.5 20H19.5C19.7761 20 20 19.7761 20 19.5V4.5C20 4.22386 19.7761 4 19.5 4H4.5Z" fill="#808080" /> </g>
                                                                                        </svg>
                                                                                    </span>

                                                                                </>
                                                                            ) :
                                                                                (
                                                                                    <></>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        {inputFields?.length > 1 ?
                                                                            (
                                                                                <>
                                                                                    <div className="absolute-box d-flex align-items-center px-0 mt-4 pt-3">
                                                                                        <span className='add-on-svg' onClick={() => removeFields(index)}>
                                                                                            <svg width="15px" height="15px" viewBox="-1.92 -1.92 27.84 27.84" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                <g id="SVGRepo_bgCarrier" stroke-width="0" />
                                                                                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.192" />
                                                                                                <g id="SVGRepo_iconCarrier"> <path d="M15 9.00004L9 15M15 15L9 9.00004M6 20H18C19.1046 20 20 19.1046 20 18V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V18C4 19.1046 4.89543 20 6 20Z" stroke="#808080" stroke-width="1.3679999999999999" stroke-linecap="round" stroke-linejoin="round" /> </g>
                                                                                            </svg>
                                                                                        </span>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (<></>)}

                                                                        <div className="rank-name mt-3">
                                                                            <span>Rank</span>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </>
                                                        )
                                                    })}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>)
                        case 1:
                            return (<><Createcampaignkeyword campaignviewID={viewid} isEdit={(val) => {
                                setview(val);
                            }}
                                isOpen={(data) => {

                                    setAddkeywordOpen(data);
                                }}
                            /></>)
                        case 2:
                            return (<><Createviewcompetitor campaignviewID={viewid} isEdit={(val) => {
                                setview(val);
                            }} /></>)
                        case 3:
                            return (<><Createcampaingreview campaignviewID={viewid} dataChild={handleChildToProp} isEdit={(val) => {
                                setview(val);
                            }}
                                gettab={(val) => { }} /></>)
                        case 'error':
                            return (<></>)
                        default:
                            return null
                    }
                })()}
                <div className="d-flex justify-content-end m-4">
                    {(view != 0 && view != 3) && !AddkeywordOpen ? (<>
                        <button className="btn btn-outline me-3" onClick={() => {
                            handlePrevbtn(view);
                        }}>Back</button>
                    </>) : (<></>)}
                    {view != 3 && !AddkeywordOpen ? (<>
                        <button className="btn btn-primary" onClick={() => {
                            handleNextbtn(view);
                        }}>Next</button>
                    </>) : (<></>)}
                </div>

            </div>
        </div>
    )
}

export default Createcampaingview;