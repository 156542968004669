import React, { useEffect, useState, useRef } from "react";
import { DateRangePicker } from "react-date-range";
import { Card } from "react-bootstrap";
import APIManager from "../services/APIManager";
import {
  calanderDateCompareforDashboard,
  calanderDateforDashboard,
  getWeek,
  dateRagngeSelectWeek,
  dateRagngeSelectMonthly,
} from "../utility/Globals";
import {
  getCategoryByProject,
} from "../utility/Constant";
import RankingTrends from "./ranking-v2/RankingTrends";
import KeywordDistribution from "./ranking-v2/KeywordDistribution";
import PriorityKeywords from "./ranking-v2/PriorityKeywords"
import LatestRanking from "./ranking-v2/LatestRanking"
import {
  getCampaignList1
} from "../utility/Constant";
import { toast } from "react-hot-toast";

function RankingReporting(props) {
  const [campaignList, setCampaignList] = useState([]);
  const [viewLists, setViewLists] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [selectedCampaigView, setSelectedCampaigView] = useState("");
  const [open, setOpen] = useState(false);
  const [isCompare, setIsCompare] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [rankingType, setRankingType] = useState(2);
  const [deviceType, setDeviceType] = useState(0);
  const [frequency, setFrequency] = useState(0);
  const [isDeviceTypeActive, setIsDeviceTypeActive] = useState(0);
  const [domainName, setDomainName] = useState("");
  const [campaignViewName, setCampaignViewName] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [rankingCampaignViewConfigurationId, setRankingCampaignViewConfigurationId] = useState("");
  const [flag, setFlag] = useState(false);
  const [minDate, setMinDate] = useState();

  let todaysDate = new Date();
  let weekdays = getWeek(new Date(todaysDate));

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);
  const [state, setState] = useState({
    selection1: {
      startDate: new Date(weekdays[0]),
      endDate: new Date(weekdays[weekdays.length - 1]),
      key: "selection1",
    },
    selection2: {
      startDate: new Date(weekdays[0]),
      endDate: new Date(weekdays[weekdays.length - 1]),
      key: "selection2",
    },
  });

  const [parentToChild, setParentToChild] = useState({
    selection1: {
      startDate: new Date(weekdays[0]),
      endDate: new Date(weekdays[weekdays.length - 1]),
      key: "selection1",
    },
    selection2: {
      startDate: new Date(weekdays[0]),
      endDate: new Date(weekdays[weekdays.length - 1]),
      key: "selection2",
    },
  });

  const [stateRT, setStateRT] = useState(false)
  const [isReady, setIsReady] = useState(false)
  const [categoryListFilter, setCategoryTypeFilter] = useState([]);


  useEffect(() => {
    localStorage.setItem("isCompareVar", false);
    // setRankingType(1);
    setDeviceType(0);
    setIsDeviceTypeActive(0);
    getCampaign();
    getCategoryData();
  }, []);

  const ref1 = useRef();
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      const tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref1.current && !ref1.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  function handleCampaignChange(e) {
    let viewListData = [];
    setSelectedCampaign(e)
    campaignList?.map((i) => {
      if (i.id == e) {
        let data = i?.viewLists;
        data?.map((j) => {
          if (j.campaignViewName != "") {
            viewListData.push(j);
          }
        });
        setViewLists(viewListData);
        setSelectedCampaigView(viewListData[0]?.rankingCampaignViewConfigurationId)

        setFrequency(i.frequency);
        setDeviceType(i.device);
        setIsDeviceTypeActive(i.device);
        setDomainName(i.domain);

        if (i.frequency == 0) {
          setIsReady(true);
          const todayDate = state.selection1?.startDate;
          const week = getWeek(new Date(todayDate));

          let selection = {
            selection1: {
              startDate: new Date(week[0]),
              endDate: new Date(week[week.length - 1]),
              key: "selection1",
            },
            selection2: {
              startDate: new Date(week[0]),
              endDate: new Date(week[week.length - 1]),
              key: "selection2",
            },
          };

          setState({ ...state, ...selection });
        }
        else if (i.frequency == 1) {
          setIsReady(true);
          const todayDate = state.selection1?.startDate;
          const firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
          const lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

          let selection = {
            selection1: {
              startDate: firstDay,
              endDate: lastDay,
              key: "selection1",
            },
            selection2: {
              startDate: firstDay,
              endDate: lastDay,
              key: "selection2",
            },
          };

          setState({ ...state, ...selection });
        }
      }
    })
  }

  function handleCampaignViewChange(e) {
    setSelectedCampaigView(e)

    viewLists?.map((i) => {
      if (i.rankingCampaignViewConfigurationId == e) {
        setFrequency(i.frequency);
        setDeviceType(i.device);
        setIsDeviceTypeActive(i.device);

        if (i.frequency == 0) {
          setIsReady(true);
          const todayDate = state.selection1?.startDate;
          const week = getWeek(new Date(todayDate));

          let selection = {
            selection1: {
              startDate: new Date(week[0]),
              endDate: new Date(week[week.length - 1]),
              key: "selection1",
            },
            selection2: {
              startDate: new Date(week[0]),
              endDate: new Date(week[week.length - 1]),
              key: "selection2",
            },
          };

          setState({ ...state, ...selection });
        }
        else if (i.frequency == 1) {
          setIsReady(true);
          const todayDate = state.selection1?.startDate;
          const firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
          const lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

          let selection = {
            selection1: {
              startDate: firstDay,
              endDate: lastDay,
              key: "selection1",
            },
            selection2: {
              startDate: firstDay,
              endDate: lastDay,
              key: "selection2",
            },
          };

          setState({ ...state, ...selection });
        }
      }
    })
  }

  function getCampaign() {
    localStorage.setItem("maxSearchVolumeHead", 1);
    localStorage.setItem("maxSearchVolumeTail", 1);
    localStorage.setItem("maxSearchVolumeTorso", 1);
    localStorage.setItem("minSearchVolumeHead", 0);
    localStorage.setItem("minSearchVolumeTail", 0);
    localStorage.setItem("minSearchVolumeTorso", 0);
    localStorage.setItem("campaignId", "");
    localStorage.setItem("campaignName", "");
    setCampaignName("");
    setCampaignId("");
    setRankingCampaignViewConfigurationId("");
    setCampaignViewName("");
    localStorage.setItem("rankingCampaignConfigurationId", "");
    localStorage.setItem("campaignViewName", "");

    APIManager.getAPI(getCampaignList1 + localStorage.getItem("projectGId")).then((response) => {
      if (response && response.status == 200 &&
        response.data != "No Content Found") {
        const list1 = response.data;
        let viewListData = [];
        setCampaignList(list1);
        setSelectedCampaign(list1[0].id);

        let data = list1[0].viewLists;
        data?.map((j) => {
          if (j.campaignViewName != "") {
            viewListData.push(j);
          }
        });

        setViewLists(viewListData);
        setSelectedCampaigView(viewListData[0]?.rankingCampaignViewConfigurationId);
        setFrequency(list1[0].frequency);
        setDeviceType(list1[0].device);
        setIsDeviceTypeActive(list1[0].device);
        setDomainName(list1[0].domain);

        if (list1[0].frequency == 0) {
          setIsReady(true);
          const todayDate = new Date();
          const week = getWeek(new Date(todayDate));

          let selection = {
            selection1: {
              startDate: new Date(week[0]),
              endDate: new Date(week[week.length - 1]),
              key: "selection1",
            },
            selection2: {
              startDate: new Date(week[0]),
              endDate: new Date(week[week.length - 1]),
              key: "selection2",
            },
          };

          setState({ ...state, ...selection });
        }
        else if (list1[0].frequency == 1) {
          setIsReady(true);
          const todayDate = new Date();
          const firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
          const lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

          let selection = {
            selection1: {
              startDate: firstDay,
              endDate: lastDay,
              key: "selection1",
            },
            selection2: {
              startDate: firstDay,
              endDate: lastDay,
              key: "selection2",
            },
          };

          setState({ ...state, ...selection });
        }

        setParentToChild(state);
        localStorage.setItem("maxSearchVolumeHead", list1[0]?.maxSearchVolumeHead);
        localStorage.setItem("maxSearchVolumeTail", list1[0]?.maxSearchVolumeTail);
        localStorage.setItem("maxSearchVolumeTorso", list1[0]?.maxSearchVolumeTorso);
        localStorage.setItem("minSearchVolumeHead", list1[0]?.minSearchVolumeHead);
        localStorage.setItem("minSearchVolumeTail", list1[0]?.minSearchVolumeTail);
        localStorage.setItem("minSearchVolumeTorso", list1[0]?.minSearchVolumeTorso);
        localStorage.setItem("campaignId", list1[0]?.id);
        localStorage.setItem("campaignName", list1[0]?.campaignName);
        setCampaignName(list1[0]?.campaignName);
        setCampaignId(list1[0]?.id);
        setRankingCampaignViewConfigurationId((viewListData[0]?.rankingCampaignConfigurationId == undefined || viewListData[0]?.rankingCampaignConfigurationId == '00000000-0000-0000-0000-000000000000') ? "" : viewListData[0]?.rankingCampaignConfigurationId);
        // setCampaignViewName(viewListData[0]?.campaignViewName == undefined ? "" : viewListData[0]?.campaignViewName);
        localStorage.setItem("rankingCampaignConfigurationId", (viewListData[0]?.rankingCampaignConfigurationId == undefined || viewListData[0]?.rankingCampaignConfigurationId == '00000000-0000-0000-0000-000000000000') ? "" : viewListData[0]?.rankingCampaignConfigurationId);
        // localStorage.setItem("campaignViewName", viewListData[0]?.campaignViewName == undefined ? "" : viewListData[0]?.campaignViewName);

        if (list1[0].id) {
          setStateRT(true)
        }
        else {
          setStateRT(false)
        }
      }
    })
  }

  function getCategoryData() {
    // setIsReady(true);        
    APIManager.getAPI(
      getCategoryByProject + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        let categoryList = []
        response.data.map((item) => {
          let objFilter = {
            text: item.categoryType,
            value: item.categoryType,
          }
          categoryList.push(objFilter)
        })
        let sortedList = categoryList.sort((a, b) => a.value.localeCompare(b.value));
        setCategoryTypeFilter(sortedList);
      }
    });
  }


  return (
    <div className=" pb-3">
      <div className="portlet newranking">
        <div className="row ">
          <div className="col-md-12 col-sm-12 d-flex align-items-center justify-content-between">
            <div className=" d-flex align-items-center">
              <h5 className="ranking-name my-2">Ranking</h5>
              <div className="py-2 pb-1">
                <select
                  className="rankingbrand-select ranking-brand form-select"
                  style={{ width: "70px" }}
                  id="optsRank"
                  name="brand"
                  onChange={(e) => {
                    setRankingType(parseInt(e.target.value));
                    let ghostSelect = document.createElement('select');
                    const select = document.getElementById('optsRank');
                    const x = select.options[select.selectedIndex].text;
                    const ghostOption = document.createElement("option");
                    ghostOption.setAttribute("value", x);
                    const t = document.createTextNode(x);
                    ghostOption.appendChild(t);
                    ghostSelect.appendChild(ghostOption);
                    window.document.body.appendChild(ghostSelect)
                    select.style.width = ghostSelect.offsetWidth + 'px';
                    window.document.body.removeChild(ghostSelect)
                  }}
                >
                  <option value="2">All</option>
                  <option value="0">Brand</option>
                  <option value="1">Non Brand</option>
                </select>
              </div>
            </div>
            <div className=" d-flex align-items-center justify-content-between campaign-dropdown">
              <div ref={ref1} className='campaign d-flex align-items-center'>

                <label className="me-2 my-1" >Campaign</label>
                <label className="my-1">:</label>
                <div class="d-flex align-items-center header-container container-fluid">
                  <div class="header-wrap">
                    <div className="dashboard-seo-dropdown">
                      <button
                        className="campaign-multi-select topnav-modal text-capitalize d-flex px-2"
                        onClick={() => setIsMenuOpen((oldState) => !oldState)}
                      >
                        <span className="client-name ps-1">{""}
                          {localStorage.getItem("campaignName")}
                          {/* {campaignName} */}


                        </span>
                        {flag ? <>
                          <span style={{ fontSize: 18 + "px" }}> / </span>
                        </> : <></>}
                        <span class="topnav-text  mb-0 pe-1 ms-2">
                          {" "}
                          {localStorage.getItem("campaignViewName")}
                          {/* {campaignViewName} */}
                        </span>

                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-down my-2"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </button>
                      <div
                        id="campaign-menu"
                        className="row box-shadow admin-submenu ranking-submenu mt-2"
                        style={
                          isMenuOpen
                            ? {
                              position: "absolute",
                              transition: "0.5s",
                              width: "384px",
                              zIndex: "9999",
                              opacity: "1",
                            }
                            : {
                              position: "fixed",
                              transition: "0.5s",
                              width: "0px",
                              zIndex: "9999",
                              opacity: "0",
                            }
                        }
                      >
                        <div
                          className={isMenuOpen ? "col-md-6 p-0" : "d-none"}
                          style={{
                            borderRight: "1px solid rgba(0,0,0,.15)",
                            // backgroundColor: "#2196f3",
                            color: "#212121",
                          }}
                        >
                          <ul className="campaign-List global-scrollbar">
                            {campaignList.map((i) => {
                              return (
                                <li
                                  onClick={() => {
                                    localStorage.setItem("campaignId", i.id);
                                    localStorage.setItem("campaignName", i.campaignName);
                                    localStorage.setItem("rankingCampaignConfigurationId", '');
                                    localStorage.setItem("campaignViewName", '');
                                    setCampaignName(i.campaignName);
                                    setCampaignId(i.id);
                                    setRankingCampaignViewConfigurationId('');
                                    setCampaignViewName('');
                                    setIsReady(false);
                                    handleCampaignChange(i.id);
                                    setParentToChild(state);
                                    setFlag(false);
                                  }}
                                >
                                  <span>{i.campaignName}</span>
                                  <i class="fa fa-angle-right"></i>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                        <div
                          className={
                            isMenuOpen && viewLists.length > 0 ? "col-md-6 p-0 view-list" : "d-none"
                          }
                        >
                          <ul class="campaign-viewList global-scrollbar">
                            {viewLists.map((i) => {
                              return (
                                <li
                                  onClick={() => {
                                    // if (i.rankingCampaignViewConfigurationId != '00000000-0000-0000-0000-000000000000') {
                                    setIsMenuOpen(false);
                                    setIsReady(false);
                                    setFlag(true);
                                    localStorage.setItem("rankingCampaignConfigurationId", i.rankingCampaignViewConfigurationId);
                                    localStorage.setItem("campaignViewName", i.campaignViewName);
                                    // window.location.href = window.location.href;
                                    setRankingCampaignViewConfigurationId(i.rankingCampaignViewConfigurationId);
                                    setCampaignViewName(i.campaignViewName);
                                    handleCampaignViewChange(i.rankingCampaignViewConfigurationId);
                                    setParentToChild(state);
                                    // }
                                  }}
                                >
                                  <span>{i.campaignViewName}</span>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div ref={ref} className="ranking-date d-flex align-items-center ps-2 pe-1 me-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-calendar"
                >
                  <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                  <line x1="16" y1="2" x2="16" y2="6"></line>
                  <line x1="8" y1="2" x2="8" y2="6"></line>
                  <line x1="3" y1="10" x2="21" y2="10"></line>
                </svg>

                <button
                  className="btn btn-primary ps-1"
                  onClick={() => setOpen(!open)}
                >
                  {!isCompare
                    ? calanderDateforDashboard(
                      state.selection1?.startDate,
                      state.selection1?.endDate
                    )
                    : calanderDateCompareforDashboard(
                      state.selection1?.startDate,
                      state.selection1?.endDate,
                      state.selection2?.startDate,
                      state.selection2?.endDate
                    )}
                </button>
              </div>

              <div ref={ref} class="d-flex ms-2">
                {open && (
                  <div id="example-collapse-text-calendar">
                    <Card body className="daterange-picker-card mt-4">
                      <div className="d-flex calendar-flex">
                        <div className="calendar-col">
                          <DateRangePicker
                          minDate = {minDate}
                            onChange={(item) => {
                              let isCompareVarNew = localStorage.getItem("isCompareVar");

                              if (frequency == 0) {
                                let selection = {};

                                if (isCompareVarNew == "false") {
                                  let selection23 = {
                                    selection1: {
                                      startDate: item?.selection1
                                        ? item?.selection1?.startDate
                                        : item?.selection2?.startDate,
                                      endDate: item?.selection1
                                        ? item?.selection1?.endDate
                                        : item?.selection2?.endDate,
                                      key: "selection1",
                                    },
                                  };

                                  selection = dateRagngeSelectWeek(
                                    selection23,
                                    state,
                                    isCompareVarNew
                                  );
                                }

                                if (isCompareVarNew == "true") {
                                  selection = dateRagngeSelectWeek(
                                    item,
                                    state,
                                    isCompareVarNew
                                  );
                                  if(item?.selection1?.startDate){
                                    setMinDate(new Date(item?.selection1?.startDate -1))
                                  }  
                                }

                                setState({
                                  ...state,
                                  ...item,
                                  ...selection,
                                });
                                                              
                              }
                              else if (frequency == 1) {
                                let selection = {};

                                if (isCompareVarNew == "false") {
                                  let selection23 = {
                                    selection1: {
                                      startDate: item?.selection1
                                        ? item?.selection1?.startDate
                                        : item?.selection2?.startDate,
                                      endDate: item?.selection1
                                        ? item?.selection1?.endDate
                                        : item?.selection2?.endDate,
                                      key: "selection1",
                                    },
                                  };

                                  selection = dateRagngeSelectMonthly(
                                    selection23,
                                    state,
                                    isCompareVarNew
                                  );
                                }

                                if (isCompareVarNew == "true") {
                                  selection = dateRagngeSelectMonthly(
                                    item,
                                    state,
                                    isCompareVarNew
                                  );
                                }

                                setState({
                                  ...state,
                                  ...item,
                                  ...selection,
                                });
                              }
                            }}

                            showSelectionPreview={true}
                            moveRangeOnFirstSelection={false}
                            months={1}
                            ranges={[state?.selection1, state?.selection2]}
                            direction="horizontal"
                            ariaLabels={{
                              dateInput: {
                                selection1: {
                                  startDate: "start date input of selction 1",
                                  endDate: "end date input of selction 1",
                                },
                                selection2: {
                                  startDate: "start date input of selction 2",
                                  endDate: "end date input of selction 2",
                                },
                              },

                              monthPicker: "month picker",
                              yearPicker: "year picker",
                              prevButton: "previous month button",
                              nextButton: "next month button",
                            }}
                          />
                        </div>
                        <div className="mt-3 mt-lg-0 text-center">
                          <label className="mt-10 d-flex ms-3">
                            <input
                              className="me-3"
                              checked={isCompare}
                              onChange={(e) => {
                                localStorage.setItem("isCompareVar", e.target.checked);
                               setMinDate()
                                setIsCompare(e.target.checked);
                                if (!e.target.checked) {
                                  document.getElementsByClassName(
                                    "rdrDateDisplayWrapper"
                                  )[0].children[1].style.display = "none";

                                  if (frequency == 0) {
                                    setState({
                                      selection1: {
                                        startDate: state.selection1?.startDate,
                                        endDate: state.selection1?.endDate,
                                        key: "selection1",
                                      },
                                      selection2: {
                                        startDate: state.selection1?.startDate,
                                        endDate: state.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    });
                                  } else if (frequency == 1) {
                                    const todayDate = state.selection1?.startDate;
                                    const firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
                                    const lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

                                    setState({
                                      selection1: {
                                        startDate: firstDay,
                                        endDate: lastDay,
                                        key: "selection1",
                                      },
                                      selection2: {
                                        startDate: firstDay,
                                        endDate: lastDay,
                                        key: "selection2",
                                      },
                                    });
                                  }
                                } else {
                                  document.getElementsByClassName(
                                    "rdrDateDisplayWrapper"
                                  )[0].children[1].style.display = "flex";

                                  if (frequency == 0) {
                                    const todayDate = new Date();;
                                    const week = getWeek(new Date(todayDate));

                                    setState({
                                      selection1: {
                                        startDate: state.selection1?.startDate,
                                        endDate: state.selection1?.endDate,
                                        key: "selection1",
                                      },
                                      selection2: {
                                        startDate: new Date(week[0]),
                                        endDate: new Date(week[week.length - 1]),
                                        key: "selection2",
                                      },
                                    });
                                  } else if (frequency == 1) {
                                    const todayDate = state.selection1?.startDate;
                                    const firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth(), 1);
                                    const lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1, 0);

                                    setState({
                                      selection1: {
                                        startDate: firstDay,
                                        endDate: lastDay,
                                        key: "selection1",
                                      },
                                      selection2: {
                                        startDate: firstDay,
                                        endDate: lastDay,
                                        key: "selection2",
                                      },
                                    });
                                  }
                                }
                              }}
                              type="checkbox"
                            />
                            Compare
                          </label>
                          <button
                            onClick={() => {
                              if (state?.selection1?.startDate <= state?.selection2?.startDate) {
                                setParentToChild(state);
                                setOpen(!open);
                              } 
                              // else {
                              //   toast.error("First date range should be before second date range!")
                              // }

                            }}
                            className="btn btn-primary ms-3"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center justify-content-between px-2 py-2 rankv2-mobile-desktop">
                <div className="btn-box">
                  <div className="d-flex align-items-center">

                    <img
                      src={`${isDeviceTypeActive == 0
                        ? "/images/rankv2-mobile-black.svg"
                        : "/images/rankv2-desktop-black.svg"
                        }`}
                      alt="Device Type"
                    />

                    {/* <button
                      className={`${isDeviceTypeActive == 0 ? "active d-block" : "d-none"}`}
                      onClick={() => {
                        let type = 0;
                        setIsDeviceTypeActive(type);
                      }}
                    >
                      <img
                        src={`${isDeviceTypeActive == 0
                          ? "/images/rankv2-mobile-white.svg"
                          : "/images/rankv2-mobile-black.svg"
                          }`}
                        alt="rankv2-mobile-image"
                      />
                    </button>
                    <button
                      className={`${isDeviceTypeActive == 1 ? "active d-block" : "d-none"}`}
                      onClick={() => {
                        let type = 1;
                        setIsDeviceTypeActive(type);
                      }}
                    >
                      <img
                        src={`${isDeviceTypeActive == 1
                          ? "/images/rankv2-desktop-white.svg"
                          : "/images/rankv2-desktop-black.svg"
                          }`}
                        alt="rankv2-desktop-image"
                      />
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="portlet keyword-distribution">
        <KeywordDistribution
          dataParentToChild={parentToChild}
          rankingType={rankingType}
          deviceType={deviceType}
          selectedCampaign={selectedCampaign}
          selectedCampaigView={selectedCampaigView}
          isReady={isReady}
          categoryFilterList={categoryListFilter}
        />
      </div>

      <div className="portlet priority-keywords">
        <PriorityKeywords
          dataParentToChild={parentToChild}
          rankingType={rankingType}
          deviceType={deviceType}
          selectedCampaign={selectedCampaign}
          selectedCampaigView={selectedCampaigView}
          isReady={isReady}
          categoryFilterList={categoryListFilter}
        />
      </div>

      {/* {
        stateRT ?
          <> */}
      <div className="portlet ranking-trends">
        <RankingTrends
          dataParentToChild={parentToChild}
          rankingType={rankingType}
          deviceType={deviceType}
          selectedCampaign={selectedCampaign}
          selectedCampaigView={selectedCampaigView}
          // campaignId={selectedCampaign}
          domainName={domainName}
          categoryFilterList={categoryListFilter}
        />
      </div>
      {/* </>
          :
          <></>
      } */}

      <div className="portlet latest-ranking">
        <LatestRanking
          dataParentToChild={parentToChild}
          rankingType={rankingType}
          deviceType={deviceType}
          selectedCampaign={selectedCampaign}
          selectedCampaigView={selectedCampaigView}
          // campaignId={selectedCampaign}
          domainName={domainName}
        />
      </div>
    </div >
  )
}

export default RankingReporting;