import { BrowserRouter, Redirect, Switch, Route, useHistory } from "react-router-dom";
import NotFound from "./NotFound";
import routes from "./Routes";
import RouteWithSubRoutes from "./utils/RouteWithSubRoutes";
import "antd/dist/antd.css";
import { useEffect } from "react";
import jwt_decode from "jwt-decode";
import APIManager from "./services/APIManager";
import { userRefreshToken } from "./utility/Constant";
import { Toaster } from "react-hot-toast";
import html2pdf from 'html2pdf.js';


function App() {


  

  const history = useHistory();

  useEffect(() => {
    const interval = setInterval(() => {
      if (localStorage.getItem('token') && localStorage.getItem('userId') && localStorage.getItem('refreshToken')) {
        const decoded = jwt_decode(localStorage.getItem('token'));
        if ((+decoded.exp - 100) < Date.now() / 1000) {
          APIManager.getAPI(userRefreshToken + 'userId=' + localStorage.getItem('userId') + '&refreshToken=' + localStorage.getItem('refreshToken')).then((response) => {
            if (response && response.status == 200) {
              localStorage.setItem('token', response.data.token);
              localStorage.setItem('userId', response.data.userId);
              localStorage.setItem('refreshToken', response.data.refreshToken);
            }
          });
        }
      } else {
        if (history) {
          history.push("/login");
          localStorage.clear();
        }
      }
    }, 3 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div id="loader-main" className="d-none">
        <div className="loader loader-bg d-flex align-items-center justify-content-center">
          <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
      </div>

      <BrowserRouter>
        <Switch>
          <Redirect exact from="/" to="/login" />
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
      <div>
        <Toaster />
      </div>
    </>

  );

}

export default App;
