import React, { useState, useRef, useEffect } from "react";
import {
    downloadPDFfile,
    getDaysInMonth,
    mmDDyyyyFormateDateSet,
    setMonths,
    themeStyle
} from "../../utility/Globals";
import { Card } from "react-bootstrap";
import ProjectOverview from "./ProjectOverview";
import WriterOverview from "./WriterOverview";
import EditorOverview from "./EditorOverview";
import { Calendar } from "react-multi-date-picker";
import PocOverview from "./PocOverview";
import { useHistory } from "react-router-dom";
import { getProjectByOrgId } from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { default as ReactSelect, components } from "react-select";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../../utility/Loading";

function DashboardContent({ update }) {
    const history = useHistory();
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const ref = useRef();
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState([
        new Date(Date.now()).toLocaleDateString(),
        new Date(Date.now()).toLocaleDateString(),
    ]);
    const [projectList, setProjectList] = useState([]);
    const [projectSelected, setProjectSelected] = useState("00000000-0000-0000-0000-000000000000");
    const [valuesParentToChild, setValuesParentToChild] = useState([
        mmDDyyyyFormateDateSet(new Date(firstDay).toLocaleDateString()),
        mmDDyyyyFormateDateSet(new Date(lastDay).toLocaleDateString()),
    ]);
    const [monthYear, setMonthYear] = useState(setMonths(new Date().getMonth() + 1) + " " + new Date().getFullYear());
    const image = React.useRef(null);
    const pdfExportComponent = React.useRef(null);
    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [open]);

    useEffect(() => {
        getProjetList()
    }, [])

    function getProjetList() {
        APIManager.getAPI(
            getProjectByOrgId + localStorage.getItem("organizationId")
        ).then((response) => {
            if (response && response.status == 200) {
                let list = [];
                let object = {
                    value: "00000000-0000-0000-0000-000000000000",
                    label: "All Projects",
                }
                list.push(object);
                response.data.map((item) => {
                    object = {
                        label: item.projectCode,
                        value: item.id
                    }
                    list.push(object)
                })
                setProjectList(list)
            }
        });
    }


    return (
        <>
            <PDFExport
                paperSize="A1"
                margin="2cm"
                ref={pdfExportComponent}
                forcePageBreak=".page-break"
            >
                <div
                    ref={image}
                >
                    <div class="row new-dashboard content-dashboard">
                        <div class="header-holder header-holder-desktop portlet d-flex px-0 mb-0">
                            <div class="header-container container-fluid  w-100 d-flex justify-content-between">
                                <div>
                                    <h4 class="header-title">Welcome {localStorage.getItem("userName")}</h4>
                                    <h6>These are the updates for all your projects</h6>
                                </div>
                                <div className="side-cal d-flex align-items-center" ref={ref}>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="feather feather-calendar"
                                    >
                                        <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                        <line x1="16" y1="2" x2="16" y2="6"></line>
                                        <line x1="8" y1="2" x2="8" y2="6"></line>
                                        <line x1="3" y1="10" x2="21" y2="10"></line>
                                    </svg>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => setOpen(!open)}
                                    >
                                        {monthYear}
                                    </button>
                                    {open && (
                                        <div className="scope-calendar">
                                            <div id="example-collapse-text-calendar" style={{ right: '0' }}>
                                                <Card body className="daterange-picker-card  mt-2">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="calendar-col">
                                                            <Calendar
                                                                maxDate={new Date()}
                                                                onChange={(e) => {
                                                                    var date1 = e[0].format().split("/")
                                                                    var daysInMonth = getDaysInMonth(date1[1], date1[0])
                                                                    let dateObjArr = [
                                                                        new Date(date1[0] + "/01/" + date1[1]),
                                                                        new Date(date1[0] + "/" + daysInMonth + "/" + date1[1]),
                                                                    ];
                                                                    setValues(dateObjArr);
                                                                    setMonthYear(setMonths(new Date(date1[0]).getMonth() + 1) + " " + new Date(date1[1]).getFullYear());
                                                                }
                                                                }
                                                                value={values}
                                                                onlyMonthPicker
                                                                range
                                                            />
                                                        </div>
                                                        <div className="ms-3">
                                                            <button
                                                                className="btn-outline btn btn-primary"
                                                                onClick={() => {
                                                                    setValuesParentToChild(values);
                                                                    setOpen(!open);
                                                                }}
                                                            >
                                                                Apply
                                                            </button>
                                                        </div>
                                                    </div>
                                                </Card>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div></div>
                        </div>

                    </div>
                    <div className="title content-project-overview mt-3">
                        <div className="d-flex justify-content-between align-items-center project-overview">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="me-3 my-1">Project Overview</h4>

                                <div className="content-project-select">
                                    <select className="form-select "
                                        onChange={(e) => {
                                            setProjectSelected(e.target.value)
                                        }}>
                                        {projectList.map((item) => {
                                            return <option value={item.value}>{item.label}</option>
                                        })}
                                    </select>
                                </div>

                            </div>

                            <div className="d-flex align-items-center justify-content-between">
                                <div>
                                        <button className="content-download me-3"
                                            onClick={async () => {
                                                setLoading(true)
                                                const promise = await downloadPDFfile(image, "Report.pdf")
                                                try {
                                                    if (promise) {
                                                        setLoading(false);
                                                    } else {
                                                        setLoading(false);
                                                    }

                                                } catch (e) {
                                                    setLoading(false);
                                                }
                                            }}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                            <span class="ms-2"> Download Report</span>
                                        </button>
                                </div>
                                <div>
                                    <button className="content-viewdetails"
                                        onClick={() => {
                                            history.push({
                                                pathname: '/content-main/requirement-details',
                                                state: {
                                                    projectId: localStorage.getItem('projectGId'),
                                                    startDate: valuesParentToChild[0],
                                                    endDate: valuesParentToChild[1]
                                                }
                                            });
                                        }}> View Details</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="seo-per-box ga-seo">
                                <ProjectOverview
                                    dataParentToChild={valuesParentToChild}
                                    project={projectSelected} />
                            </div>
                        </div>
                    </div>

                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="ga-seo">
                                <WriterOverview
                                    dataParentToChild={valuesParentToChild}
                                    project={projectSelected} />
                            </div>
                        </div>
                    </div>

                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="ga-seo">
                                <EditorOverview
                                    dataParentToChild={valuesParentToChild}
                                    project={projectSelected} />
                            </div>
                        </div>
                    </div>

                    <div className="row px-0">
                        <div className="col-sm-12 px-0">
                            <div className="ga-seo">
                                <PocOverview
                                    dataParentToChild={valuesParentToChild}
                                    project={projectSelected} />
                            </div>
                        </div>
                    </div>
                </div>
            </PDFExport>
            <Loading isLoading={isLoading} />
        </>
    )
}
export default DashboardContent;