import React from "react";
import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "antd/dist/antd.css";
import "react-circular-progressbar/dist/styles.css";
import { savePDF } from "@progress/kendo-react-pdf";
import { downloadPDFfile } from "../utility/Globals";
import APIManager from "../services/APIManager";
import PieChart from "./seoaudit/PieChart";
import PieChart2 from "./seoaudit/PieChart2";
import HTMLdata from "./seoaudit/HTMLdata";
import DoughnutChart from "./seoaudit/DoughnutChart";
import DoughnutChart2 from "./seoaudit/DoughnutChart2";
import PieChart3 from "./seoaudit/PieChart3";
import DoughnutChart3 from "./seoaudit/DoughnutChart3";
import html2pdf from "html2pdf.js";
import { Drawer, message, Input, Spin, Button } from "antd";
import "antd/dist/antd.css";
// import {  } from "antd";
import { PDFExport } from "@progress/kendo-react-pdf";
import jsPDF from "jspdf";
import Logo1 from "../utils/1.png";
import Logo from "../utils/infi-logo.png";
import Logo2 from "../utils/2.png";
import InfiLogo from "../utils/Infidigit_Logo.png"; //work
import InfiLine from "../utils/Infidigit_line.png"; //work
import * as htmlToImage from "html-to-image";
import {
  GET_Server_URL,
  getSEOAuditDetail,
  moduleSEOAudit,
  getSEOAuditModuleList,
  indexLoGoImage_URL,
} from "../utility/Constant";
import { Doughnut } from "react-chartjs-2";
import "chart.piecelabel.js";
import GeneratePDF from "./Headercomponent";
import { Menu, MenuItem } from "@mui/material";
import _ from "lodash";
import axios from "axios";
import $, { data } from "jquery";
import html2canvas from "html2canvas";

function ModuleExpandSEOAuditV3(props) {
  const location = useLocation();
  const [categoriesScore2, setCategoriesScore2] = useState([]);
  const [libraryAPI, setLibraryAPI] = useState([]);
  const [exportData, setExportData] = useState([]);
  let globaleCount = 0;
  let itemCount = 0;

  const [categoryWiseScore, setCategoryWiseScore] = useState([]);
  const [categoriesScoreCopy, setCategoriesScoreCopy] = useState([]);
  const [instruction, setIinstruction] = useState("");
  const [imageURL, setImageURL] = useState([]);
  console.log(imageURL, "imageURL");
  const image = React.useRef(null);
  const chartRef = React.useRef([]);
  const pdfExportComponent = React.useRef(null);
  const exportref = React.useRef(null);
  console.log(exportref, "exportref");
  const printref = React.useRef(null);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isDownload, SetIsDownload] = useState(false);
  const [sectionListData, setSectionListData] = useState([
    { label: "All", value: false, auditModuleId: "All" },
  ]);
  const [updateDummy, setUpdateDummy] = useState()
  console.log(updateDummy, "sectionListData");
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isLoading, setLoading] = useState(false);
  const [sectionListCopyData, setSectionListCopyData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [active, setActive] = useState("inactive");

  useEffect(() => {
    getSEOAuditAuditDetail(props?.location?.state?.selectedAuditId);
    getSEOAuditModule(props?.location?.state?.selectedAuditId);
  }, []);

  useEffect(() => {
    if (searchText == "") {
      setSectionListData(sectionListCopyData);
    } else {
      let arr = [];
      sectionListData.map((i) => {
        if (
          i.label.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())
        ) {
          arr.push(i);
        }
      });
      setSectionListData(arr);
    }
    // categoriesScore2, setCategoriesScore2
  }, [searchText, categoriesScore2]);

  function getNodeChunks(htmlDocument) {
    var offscreenDiv = document.createElement("div");
    offscreenDiv.className = "page";
    offscreenDiv.style.position = "absolute";
    offscreenDiv.style.top = "-3000px";
    offscreenDiv.innerHTML = htmlDocument;
    offscreenDiv.display = "flex";
    offscreenDiv.flexWrap = "wrap";
    document.body.appendChild(offscreenDiv);
    let offscreenRect = offscreenDiv.getBoundingClientRect();
    // console.log('offscreenRect:', offscreenRect);
    var chunks = [];
    var currentChunk = [];
    for (var i = 0; i < offscreenDiv.children.length; i++) {
      var current = offscreenDiv.children[i];
      var currentRect = current.getBoundingClientRect();
      currentChunk.push(current);
      if (currentRect.bottom > offscreenRect.bottom) {
        // current element is overflowing offscreenDiv, remove it from current chunk
        currentChunk.pop();
        // remove all elements in currentChunk from offscreenDiv
        currentChunk.forEach((elem) => elem.remove());
        // since children were removed from offscreenDiv, adjust i to start back at current eleme on next iteration
        i -= currentChunk.length;
        // push current completed chunk to the resulting chunklist
        chunks.push(currentChunk);
        // initialise new current chunk
        currentChunk = [current];
        offscreenRect = offscreenDiv.getBoundingClientRect();
      }
    }
    // currentChunk may not be empty but we need the last elements
    if (currentChunk.length > 0) {
      currentChunk.forEach((elem) => elem.remove());
      chunks.push(currentChunk);
    }
    // offscreenDiv is not needed anymore
    offscreenDiv.remove();
    console.log(chunks, "chunks");
    return chunks;
  }

  function getSEOAuditAuditDetail(auditId) {
    APIManager.getAPI(
      getSEOAuditDetail +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      auditId
    ).then((response) => {
      if (response && response.status == 200) {
        console.log(response, "response");
        setExportData(response.data);
        setCategoriesScore2(response.data?.auditCategoryDatas);
        setCategoryWiseScore(response.data?.categoryWiseScore);
        setCategoriesScoreCopy(response.data?.auditCategoryDatas);
        setIinstruction(response.data?.auditInstruction?.instruction);
      }
    });
  }

  function getSEOAuditModule(auditId) {
    APIManager.getAPI(getSEOAuditModuleList + auditId).then((response) => {
      if (response && response.status == 200) {
        let arr = [];
        response?.data?.map((i) => {
          arr.push({
            label: i.auditModuleName,
            value: false,
            auditModuleId: i.auditModuleId,
          });
        });
        setSectionListData([...sectionListData, ...arr]);
        setSectionListCopyData([...sectionListData, ...arr]);
      }
    });
  }

  const [contentLoader, setContentLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [Loop, setLoop] = useState(11);
  const [defaultdata, setdefaultdata] = useState();
  const [canvas, setCanvas] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    SetIsDownload(false);
    setLoading(false);
  };
  const [activePdfClass, activePDFFullDoc] = useState(1);
  const [exportOption, setExportOption] = useState(false);

  console.log(exportOption, 'exportOptions');

  useEffect(() => {
    if (categoriesScore2.length <= 0 && exportOption == false) {
      setContentLoader(true);
    } else {
      setContentLoader(false);
    }
  }, [categoriesScore2, exportOption]);


  const Checkbox = (props) => {
    return (
      <>
        <div class="custom-control custom-checkbox">
          <div
            onClick={() => {
              let arr = [];
              if (props.auditModuleId == "All") {
                sectionListData.map((i) => {
                  i.value = !props.value;
                  arr.push(i);
                });
                setSectionListData(arr);
              } else {
                sectionListData.map((i) => {
                  if (i.auditModuleId === props.auditModuleId) {
                    i.value = !props.value;

                  }
                  setUpdateDummy(i)
                  console.log(i, 'i.value1');
                  arr.push(i);
                  console.log(arr, 'array12345');

                });
                setSectionListData(arr);
              }
            }}>
            <input
              class="form-check-input"
              type="checkbox"
              checked={props.value}
            />
            <label>{props.label}</label>
          </div>
          {console.log(sectionListData, 'check')}
        </div>
      </>
    );
  };

  const history = useHistory();

  function topFunction() {
    var e = document.getElementById("divToExport");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }
const[teststate,Setteststate] = useState("Your Tailored SEO Audit Report - Uncover strengths, spot opportunities, and supercharge your online presence. A comprehensive analysis for strategic growth and enhanced visibility.");
  //Get the content and send to Backend and Receive the Response to Download The PDF
  const exportPDF = (image) => {

    let htmlContentElement = image.current;
    let htmlContentOuterHtml = htmlContentElement.innerHTML;
    let coverpage = `<div class="coverpage">
    <div class="left-col">
    </div>
    <div class="right-col">
        <div class="content-wrapper">
            <p class="tag-line">Infidigit SEO</p>
            <h1 class="main-text">Audit</br>Report</h1>
            <p class="date-text">As of <span class="date">14th August 2023</span></p>
            <p class="description">${teststate}</p>
        </div>
    </div></div>`
    let finalhtml = coverpage + htmlContentOuterHtml
    if (htmlContentOuterHtml) {
      axios
        .post(
          "https://serp-api-infidigit.demomywebapp.com/api/v1/serp/convert",     
          {
            htmlContent: finalhtml,
            client: props?.location?.state?.selectedAuditName,
          }
        )
        .then(function (response) {
          if (response) {
            axios
              .get(
                "https://serp-api-infidigit.demomywebapp.com/" +
                response.data.filename,
                { responseType: "blob" }
              )
              .then((response) => {
                const blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url; // Create a href link to Download PDF
                link.setAttribute(
                  "download",
                  props?.location?.state?.selectedAuditName + ".pdf"
                ); // Append Response Filename to append PDF extension
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(url); //Download PDF
              })
              .catch((error) => {
                // console.error("Error downloading file:", error);
                message.error("Error downloading file:", error);
              });
            onClose();
          }
        })
        .catch(function (error) {
          console.log("error");
          console.log(error);
        });
    }
  };

  const [forexport, Setforexport] = useState();
  console.log(sectionListData, 'forexportforexport')
  //set the state once any changes done in SelectedListData
  useEffect(() => {
    const filter_data = sectionListData.filter((item) => item.value === true);
    const All_filter_data = sectionListData.filter((item) => {
      if (item.label !== 'All') {
        return item.value === true;
      }
    });
    Setforexport(All_filter_data);
    console.log(All_filter_data, "All_filter_data")
    console.log(sectionListData, "All_filter_data")
    if (
      sectionListData.length > 1 &&
      sectionListData.length - 1 == All_filter_data.length && sectionListData[0].label == 'All'
    ) {
      sectionListData[0].value = true;
    } else if (sectionListData.length > 0 && sectionListData[0].label == 'All') {
      sectionListData[0].value = false
    }
  }, [sectionListData]);

  return (
    <>
      <div id="divToExport" className="newseo-pdf">
        <>
          <PDFExport
            paperSize="A4"
            pageTemplate={GeneratePDF}
            margin="2cm"
            fileName={props?.location?.state?.selectedAuditName}
            ref={pdfExportComponent}
            forcePageBreak=".page-break"
          >
            <div>
              {/* <div>
                <img src={Logo} alt="Infidigit" className="logo-pdf" />
                <div className="doc-hr "></div>
              </div> */}
              {/* </div> */}
              <div id="pdfImage" ref={image}>
                <div className="row index-content">
                  {isLoading ? (
                    <></>
                  ) : (
                    <>
                      <div
                        className="col-md-12 d-flex mb-3 p-0"
                      // style={{ marginLeft: '95%' }}
                      >
                        <span>
                          <i
                            ref={printref}
                            class="fa fa-angle-left"
                            style={{ fontSize: 20 + "px" }}
                            onClick={() => {
                              history.goBack();
                            }}
                          ></i>
                          <span
                            style={{ fontSize: 20 + "px" }}
                            className="ml-3"
                          >
                            <b>Audit Report</b>
                          </span>
                        </span>
                      </div>
                      <div className="mt-2">
                        <img
                          src={InfiLogo}
                          alt="Infidigit"
                          height={75}
                          width={215}
                        />
                        <img
                          src={InfiLine}
                          alt="Infidigit"
                          className="mb-4 logo-pdf"
                        />

                        {/* <div className="doc-hr "></div> */}
                      </div>
                    </>
                  )}

                  <div className="col-md-11 d-flex mt-3 align-items-center p-0 module-title seo-pdf-title">
                    <h2 className="text-blue d-flex me-3 mb-0">Audit For</h2>
                    <p
                      id="audit-version-lbl"
                      className="text-capitalize  me-3 mb-0 p-0"
                      style={{ fontSize: 24, fontWeight: 600, color: 'black' }}
                    >
                      {props?.location?.state?.selectedAuditName}
                    </p>
                  </div>
                  {isLoading ? (
                    <></>
                  ) : (
                    <div className="col-sm-1 d-flex justify-content-between align-items-center my-4 py-2 ps-">
                      <div className="seo-title mt-0 d-flex align-items-center">
                        {/* Sorted By :<span className="fw-600 ms-2">SEO Categories</span> */}
                      </div>
                      <div className="seo-export-btn seo-pdf d-flex ">
                        {/* <Button
                          variant="contained"
                          onClick={handleClick}
                          style={{ height: '100%', marginRight: '4px' }}
                          aria-controls="dropdown-menu"
                          aria-haspopup="true"
                          className="btn btn-primary dropdown-item"
                        >
                          Filter
                        </Button>
                        <Menu
                          id="dropdown-menu"
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={() => SortFunction('highImpact', 'High', 'impact')}>Impact : High to Low</MenuItem>
                          <MenuItem onClick={() => SortFunction('highImpact', 'Low', 'impact')}>Impact : Low to High</MenuItem>
                          <MenuItem onClick={() => SortFunction('highPriority', 'High', 'priority')}>Priority : High to Low</MenuItem>
                          <MenuItem onClick={() => SortFunction('lowPriority', 'Low', 'priority')}>Priority : Low to High</MenuItem>
                          <MenuItem onClick={() => SortFunction('auditScore', 'High', 'auditScore')}>Score : High to Low</MenuItem>
                          <MenuItem onClick={() => SortFunction('auditScore', 'Low', 'auditScore')}>Score : Low to High</MenuItem>
                          <MenuItem onClick={() => {
                            getSEOAuditAuditDetail(props?.location?.state?.selectedAuditId)
                            handleClose()
                          }}>Default</MenuItem>
                        </Menu> */}
                        <div
                          ref={exportref}
                          className="seo-export-btn seo-pdf"
                          onClick={() => {
                            showDrawer();
                          }}
                        >
                          <button
                            disabled={contentLoader}
                            class="btn btn-primary dropdown-item"
                          >
                            Export PDF
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                {contentLoader ? (
                  <div className="mt-5 text-center">
                    {" "}
                    <Spin
                      tip="Please Wait, the Report contents are being loaded..."
                      size="Large"
                    ></Spin>
                    {" "}
                  </div>
                ) : (
                  <>
                    <div className="seo-index" style={{ marginLeft: "10px", marginTop: "20px" }}>
                      <label>Index</label>
                    </div>
                    <div className="row pdfpb">
                      <span
                        aria-label={`pdf-page-9999999`}
                        className="page-break-after"
                      >
                        <div>
                          {categoriesScore2 && categoriesScore2.length ? (
                            categoriesScore2.map((item, index) => {
                              return (
                                <div className="seo-main-sub-content">
                                  <div className="seo-title on-page-title mt-0 d-flex align-items-center">
                                    <h2 className="mt-3">{item?.category}</h2>
                                  </div>
                                  {item?.categoryWiseModuleScores.map(
                                    (i, idx) => {
                                      return (
                                        <div className="index-main-point mb-4">
                                          <span>
                                            {idx + 1}. {i?.auditModuleName}
                                          </span>
                                          {i?.auditResponseByCategories.map(
                                            (ii, idxx) => {
                                              globaleCount = globaleCount + 1;
                                              const a = globaleCount;
                                              return (
                                                <div className="index-sub-point">
                                                  <a
                                                    id="index-contenta"
                                                    href={
                                                      isDownload
                                                        ? "#audit_" +
                                                        a.toString()
                                                        : null
                                                    }
                                                    onClick={() => {
                                                      let idd =
                                                        "audit_" + a.toString();
                                                      var e =
                                                        document.getElementById(
                                                          idd
                                                        );
                                                      e.scrollIntoView({
                                                        behavior: "smooth",
                                                        inline: "center",
                                                      });
                                                    }}
                                                  >
                                                    {idx + 1}.{idxx + 1}-{" "}
                                                    {ii?.pointTitle}
                                                  </a>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              );
                            })
                          ) : (
                            <></>
                          )}
                        </div>

                        <div
                          className="bg-transparent introduction-font newseo-pdf span-wrap p-span-justify page-break-before-index"
                          dangerouslySetInnerHTML={{
                            __html: instruction,
                          }}
                        ></div>
                      </span>

                      {/* <div className="page-break" id={`is${active}`}>
                  <h1>Infidigit</h1>
                  <img src={Logo} alt="Infidigit" className="logo-pdf" />
                  <img src="https://www.infidigit.com/wp-content/uploads/2022/11/cropped-cropped-cropped-infidigit-logo.png" className="pdf-header-img" alt="Infidigit" />
                  <div className="doc-hr "></div>
                </div> */}
                      <div className="row seo-content-start">
                        <div className="col-sm-12">
                          <div className="seo-accordian-box mt-4 pt-2">
                            <div className="mt-2">
                              {categoriesScore2 && categoriesScore2.length ? (
                                categoriesScore2.map((item, indexnew) => {
                                  return (
                                    <div>
                                      {item.categoryWiseModuleScores.map(
                                        (i, inx) => {
                                          const index = inx;
                                          const arrayLength =
                                            i["auditResponseByCategories"]
                                              .length;
                                          return (
                                            <>
                                              {/* <span aria-label={`pdf-page-` + ++inx}> */}
                                              <>
                                                {inx === 0 ? (
                                                  <div className="accordian-title seo-on-page page-break-main">
                                                    <div className="left ">
                                                      <span>
                                                        {item?.category}
                                                      </span>
                                                    </div>
                                                    <div className=" categories-score">
                                                      <div className="text-center my-3">
                                                        <h2 style={{ fontWeight: 600 }}>
                                                          {" "}
                                                          {Math.round(
                                                            categoryWiseScore[
                                                              index
                                                            ]?.auditScore
                                                          )} <span>/ 100</span>
                                                        </h2>
                                                      </div>
                                                      <span>Your Score</span>
                                                      {/* <img src="/images/arrow-down.png" alt="#" /> */}
                                                    </div>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}

                                                <div className="accordian-title seo-content page-break-before">
                                                  <div className="left">
                                                    <span className="on-page-content">
                                                      {inx + 1}.{" "}
                                                      {i.auditModuleName}
                                                    </span>
                                                  </div>
                                                  <div className="right d-flex align-items-center">
                                                    {/* <span>Your Score : {Math.round(i.auditScore)}</span> */}
                                                  </div>
                                                </div>
                                                <div className="accordian-content">
                                                  <div className="main-accordian-box mt-4">
                                                    <div
                                                      style={{
                                                        position: "relative",
                                                      }}
                                                    >
                                                      <div
                                                        className="resultTag"
                                                        style={{
                                                          width: "100%",
                                                          display: "flex",
                                                        }}
                                                      >
                                                        {/* {imageURL && <img src={imageURL} style={{ width: '100%', display: isprint ? 'none' : 'flex' , marginBottom : '5%' }} alt="Chart" />} */}
                                                      </div>
                                                      <div className="row chartImageDiv">
                                                        <div className="col-10">
                                                          <div className="w-100 d-flex justify-content-between align-items-top">
                                                            {i.compliantPoints >
                                                              0 ||
                                                              i.nonCompliantPoints >
                                                              0 ? (
                                                              <div // style={{ width: 105 + "px" }}
                                                                className="chart-box col-3"
                                                              >
                                                                <div
                                                                  className="custom-chart-size"
                                                                  style={{
                                                                    position:
                                                                      "relative",
                                                                    marginLeft:
                                                                      "auto",
                                                                    marginRight:
                                                                      "auto",
                                                                  }}
                                                                >
                                                                  {/* {isDownload ? */}
                                                                  <DoughnutChart3
                                                                    title={
                                                                      "Points Checked"
                                                                    }
                                                                    id={
                                                                      "chartdiv" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    labels={[
                                                                      "Compliant",
                                                                      "Non compliant",
                                                                    ]}
                                                                    data={[
                                                                      i.nonCompliantPoints,
                                                                      i.compliantPoints,
                                                                    ]}
                                                                  />
                                                                  {/* :
                                                                  <>
                                                                    <DoughnutChart
                                                                      labels={[
                                                                        "Compliant",
                                                                        "Non compliant",
                                                                      ]}
                                                                      data={[
                                                                        i.nonCompliantPoints,
                                                                        i.compliantPoints,
                                                                      ]} />
                                                                    <div id={"pdf-doughnut-txt" + inx} style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', }}>
                                                                      <span><b>{i.nonCompliantPoints + i.compliantPoints}</b></span>
                                                                    </div>
                                                                  </>
                                                                } */}
                                                                </div>
                                                                {/* {isDownload == false ?
                                                                <div className="chart-title">
                                                                  <div className="text-center">
                                                                    <div className="d-flexx">
                                                                      Points Checked
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                                : <></>} */}
                                                              </div>
                                                            ) : (
                                                              <></>
                                                            )}
                                                            {i.highImpact > 0 ||
                                                              i.mediumImpact >
                                                              0 ||
                                                              i.lowImpact > 0 ? (
                                                              <>
                                                                {/* {isDownload ? */}
                                                                <div // style={{ width: 105 + "px" }}
                                                                  className="chart-box col-3"
                                                                >
                                                                  <PieChart3
                                                                    id={
                                                                      "chartImpact" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    others={
                                                                      "impact" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    title={
                                                                      "Impact"
                                                                    }
                                                                    labels={[
                                                                      "High",
                                                                      "Medium",
                                                                      "Low",
                                                                    ]}
                                                                    data={[
                                                                      i.highImpact,
                                                                      i.mediumImpact,
                                                                      i.lowImpact,
                                                                    ]}
                                                                  />
                                                                  <div
                                                                    id={
                                                                      "impact" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    className="chart-center"
                                                                  ></div>
                                                                </div>
                                                                {/* :

                                                                <PieChart
                                                                  id={"chartImpact" + inx + indexnew}
                                                                  others={"impact" + inx + indexnew}
                                                                  title={"Impact"}
                                                                  labels={[
                                                                    "High",
                                                                    "Medium",
                                                                    "Low",
                                                                  ]}
                                                                  data={[
                                                                    i.highImpact,
                                                                    i.mediumImpact,
                                                                    i.lowImpact,
                                                                  ]}
                                                                />
                                                              } */}
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                            {i.highPriority >
                                                              0 ||
                                                              i.mediumPriority >
                                                              0 ||
                                                              i.lowPriority >
                                                              0 ? (
                                                              <>
                                                                {/* {isDownload ? */}
                                                                <div // style={{ width: 105 + "px" }}
                                                                  className="chart-box col-3"
                                                                >
                                                                  <PieChart3
                                                                    id={
                                                                      "chartPriority" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    others={
                                                                      "priority" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    title={
                                                                      "Priority"
                                                                    }
                                                                    labels={[
                                                                      "High",
                                                                      "Medium",
                                                                      "Low",
                                                                    ]}
                                                                    data={[
                                                                      i.highPriority,
                                                                      i.mediumPriority,
                                                                      i.lowPriority,
                                                                    ]}
                                                                  />
                                                                  <div
                                                                    id={
                                                                      "priority" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    className="chart-center"
                                                                  ></div>
                                                                </div>
                                                                {/* :
                                                                <PieChart
                                                                  id={"chartPriority" + inx + indexnew}
                                                                  others={"priority" + inx + indexnew}
                                                                  title={"Priority"}
                                                                  labels={[
                                                                    "High",
                                                                    "Medium",
                                                                    "Low",
                                                                  ]}
                                                                  data={[
                                                                    i.highPriority,
                                                                    i.mediumPriority,
                                                                    i.lowPriority,
                                                                  ]}
                                                                />
                                                              } */}
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                            {i.onHold > 0 ||
                                                              i.inprogress > 0 ||
                                                              i.implemented >
                                                              0 ? (
                                                              <>
                                                                {/* {isDownload ? */}
                                                                <div className="chart-box col-3">
                                                                  <PieChart3
                                                                    id={
                                                                      "chartStatus" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    others={
                                                                      "status" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    title={
                                                                      "Status"
                                                                    }
                                                                    labels={[
                                                                      "Implemented",
                                                                      "In process",
                                                                      "On hold",
                                                                    ]}
                                                                    data={[
                                                                      i.implemented,
                                                                      i.inprogress,
                                                                      i.onHold,
                                                                    ]}
                                                                  />
                                                                  <div
                                                                    id={
                                                                      "status" +
                                                                      inx +
                                                                      indexnew
                                                                    }
                                                                    className="chart-center"
                                                                  ></div>
                                                                </div>
                                                                {/* :
                                                                <PieChart
                                                                  id={"chartStatus" + inx + indexnew}
                                                                  others={"status" + inx + indexnew}
                                                                  title={"Status"}
                                                                  labels={[
                                                                    "Implemented",
                                                                    "In process",
                                                                    "On hold",
                                                                  ]}
                                                                  data={[
                                                                    i.implemented,
                                                                    i.inprogress,
                                                                    i.onHold,
                                                                  ]}
                                                                />
                                                              } */}
                                                              </>
                                                            ) : (
                                                              <></>
                                                            )}
                                                          </div>
                                                        </div>
                                                        <div class="col-md-2 d-flex justify-content-end align-items-center">
                                                          <div class="accord-content-text ">
                                                            <span className="digit-span">
                                                              {Math.round(
                                                                i.auditScore
                                                              )}
                                                            </span> <span>/ 100</span>
                                                            <span class="text-span">
                                                              Your Score
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>

                                                    {/* <div className=".page-break">
                                            <h1>Infidigit</h1>
                                            {/* <img src="https://www.infidigit.com/wp-content/uploads/2022/11/cropped-cropped-cropped-infidigit-logo.png" className="pdf-header-img" alt="Infidigit"  /> */}
                                                    {/*   <div className="doc-hr "></div>
                                          </div> */}
                                                    <HTMLdata
                                                      staticClass="introductionclass"
                                                      title={"Introduction :"}
                                                      data={i.description}
                                                    />
                                                  </div>
                                                  {/**************** Inner Accordian ***********************************/}

                                                  {i.auditResponseByCategories &&
                                                    i.auditResponseByCategories
                                                      .length &&
                                                    i.auditResponseByCategories.map(
                                                      (innerItem, index) => {
                                                        itemCount =
                                                          itemCount + 1;
                                                        return (
                                                          <>
                                                            <div
                                                              className={
                                                                "mt-3 inside-accordian expand-active seo-submenu-content"
                                                              }
                                                            >
                                                              <div
                                                                id={
                                                                  "audit_" +
                                                                  itemCount
                                                                }
                                                              >
                                                                {/* {inx + 1}. */}
                                                                <label
                                                                  className="subpoint"
                                                                  htmlFor=""
                                                                >
                                                                  {" "}
                                                                  {inx + 1}.
                                                                  {index + 1}
                                                                  {" - "}{" "}
                                                                  {
                                                                    innerItem.pointTitle
                                                                  }
                                                                </label>
                                                              </div>
                                                              {innerItem.auditResponseValue ==
                                                                0 ? (
                                                                <div>
                                                                  {/* <div class="w-100 d-flex align-items-top seo-status">
                                                        <div className="left ">
                                                          <span>Audit Point:</span>
                                                        </div>
                                                        <div className="ms-5">
                                                          <span>{}</span>
                                                        </div>
                                                      </div> */}

                                                                  <table class="seo-status table-border-none">
                                                                    <tr>
                                                                      <td className="content-bold">
                                                                        Status :
                                                                      </td>

                                                                      <td
                                                                        className={
                                                                          innerItem.status ==
                                                                            4
                                                                            ? "seo-status-color-high ms-5 ps-2"
                                                                            : innerItem.status ==
                                                                              3
                                                                              ? "seo-status-color-medium ms-5 ps-2"
                                                                              : innerItem.status ==
                                                                                0
                                                                                ? "seo-status-color-low ms-5"
                                                                                : "seo-status-color-default text-left"
                                                                        }
                                                                      >
                                                                        {" "}
                                                                        {innerItem.status ==
                                                                          1
                                                                          ? "Completed"
                                                                          : innerItem.status ==
                                                                            3
                                                                            ? "WIP"
                                                                            : innerItem.status ==
                                                                              4
                                                                              ? "On Hold"
                                                                              : innerItem.status ==
                                                                                5
                                                                                ? "Pending"
                                                                                : innerItem.status ==
                                                                                  0
                                                                                  ? "-" //Active
                                                                                  : "-"}
                                                                      </td>
                                                                    </tr>

                                                                    <tr>
                                                                      <td className="content-bold">
                                                                        Impact :
                                                                      </td>

                                                                      <td
                                                                        className={
                                                                          innerItem.impact ==
                                                                            0
                                                                            ? "seo-status-color-high ms-5"
                                                                            : innerItem.impact ==
                                                                              1
                                                                              ? "seo-status-color-medium ms-5"
                                                                              : innerItem.impact ==
                                                                                2
                                                                                ? "seo-status-color-low ms-5"
                                                                                : "seo-status-color-default text-left"
                                                                        }
                                                                      >
                                                                        {" "}
                                                                        {innerItem.impact ==
                                                                          0
                                                                          ? "Low"
                                                                          : innerItem.impact ==
                                                                            1
                                                                            ? "Medium"
                                                                            : innerItem.impact ==
                                                                              2
                                                                              ? "High"
                                                                              : "-"}
                                                                      </td>
                                                                    </tr>

                                                                    <tr>
                                                                      <td className="content-bold">
                                                                        Priority
                                                                        :
                                                                      </td>

                                                                      <td
                                                                        className={
                                                                          innerItem.priority ==
                                                                            0
                                                                            ? "seo-status-color-high ms-5"
                                                                            : innerItem.priority ==
                                                                              1
                                                                              ? "seo-status-color-medium ms-5"
                                                                              : innerItem.priority ==
                                                                                2
                                                                                ? "seo-status-color-low ms-5"
                                                                                : "seo-status-color-default ms-5"
                                                                        }
                                                                      >
                                                                        {innerItem.priority ==
                                                                          0
                                                                          ? "Low"
                                                                          : innerItem.priority ==
                                                                            1
                                                                            ? "Medium"
                                                                            : innerItem.priority ==
                                                                              2
                                                                              ? "High"
                                                                              : "-"}
                                                                      </td>
                                                                    </tr>

                                                                    <tr>
                                                                      <td className="content-bold">
                                                                        Responsible
                                                                        :
                                                                      </td>

                                                                      <td>
                                                                        {" "}
                                                                        <span
                                                                          className="seo-status-responsible"
                                                                          style={{
                                                                            marginLeft:
                                                                              "20px",
                                                                          }}
                                                                        >
                                                                          {innerItem.responsible
                                                                            ? innerItem.responsible
                                                                            : "-"}
                                                                        </span>
                                                                      </td>
                                                                    </tr>
                                                                  </table>

                                                                  {/* <div class="w-100 d-flex align-items-top seo-status">
                                                                <div className="left">
                                                                  <span>
                                                                    Status:
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  // className="seo-status-color-high ms-5"
                                                                  className={
                                                                    innerItem.status ==
                                                                      4
                                                                      ? "seo-status-color-high ms-5 ps-2"
                                                                      : innerItem.status ==
                                                                        3
                                                                        ? "seo-status-color-medium ms-5 ps-2"
                                                                        : innerItem.status ==
                                                                          0
                                                                          ? "seo-status-color-low ms-5"
                                                                          : "seo-status-color-default ms-5 ps-5"
                                                                  }
                                                                >
                                                                  <span>
                                                                    {innerItem.status ==
                                                                      1
                                                                      ? "Completed"
                                                                      : innerItem.status ==
                                                                        3
                                                                        ? "WIP"
                                                                        : innerItem.status ==
                                                                          4
                                                                          ? "On Hold"
                                                                          : innerItem.status ==
                                                                            5
                                                                            ? "Pending"
                                                                            : innerItem.status ==
                                                                              0
                                                                              ? "-" //Active
                                                                              : "-"}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <div class="w-100 d-flex align-items-top seo-status">
                                                                <div className="left ">
                                                                  <span>
                                                                    Impact:
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    innerItem.impact ==
                                                                      0
                                                                      ? "seo-status-color-high ms-5"
                                                                      : innerItem.impact ==
                                                                        1
                                                                        ? "seo-status-color-medium ms-5"
                                                                        : innerItem.impact ==
                                                                          2
                                                                          ? "seo-status-color-low ms-5"
                                                                          : "seo-status-color-default ms-5"
                                                                  }
                                                                >
                                                                  <span>
                                                                    {innerItem.impact ==
                                                                      0
                                                                      ? "Low"
                                                                      : innerItem.impact ==
                                                                        1
                                                                        ? "Medium"
                                                                        : innerItem.impact ==
                                                                          2
                                                                          ? "High"
                                                                          : "-"}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <div class="w-100 d-flex align-items-top seo-status">
                                                                <div className="left ">
                                                                  <span>
                                                                    Priority:
                                                                  </span>
                                                                </div>
                                                                <div
                                                                  className={
                                                                    innerItem.priority ==
                                                                      0
                                                                      ? "seo-status-color-high ms-5"
                                                                      : innerItem.priority ==
                                                                        1
                                                                        ? "seo-status-color-medium ms-5"
                                                                        : innerItem.priority ==
                                                                          2
                                                                          ? "seo-status-color-low ms-5"
                                                                          : "seo-status-color-default ms-5"
                                                                  }
                                                                >
                                                                  <span>
                                                                    {innerItem.priority ==
                                                                      0
                                                                      ? "Low"
                                                                      : innerItem.priority ==
                                                                        1
                                                                        ? "Medium"
                                                                        : innerItem.priority ==
                                                                          2
                                                                          ? "High"
                                                                          : "-"}
                                                                  </span>
                                                                </div>
                                                              </div>
                                                              <div class="w-100 d-flex align-items-top seo-status">
                                                                <div className="left ">
                                                                  <span>
                                                                    Responsible:
                                                                  </span>
                                                                </div>
                                                                <div className="text-center">
                                                                  <span>
                                                                    {innerItem.responsible
                                                                      ? innerItem.responsible
                                                                      : "-"}
                                                                  </span>
                                                                </div>
                                                              </div> */}

                                                                  <div className="accordion-body  border-top-0 rounded accordian-identity">
                                                                    {innerItem.comment ? (
                                                                      <>
                                                                        {/* <div className="page-break" id={`is${active}`}>
                                                                 <h1>Infidigit</h1> 
                                                                <img src={Logo} alt="Infidigit" className="logo-pdf"/>
                                                                <img src="https://www.infidigit.com/wp-content/uploads/2022/11/cropped-cropped-cropped-infidigit-logo.png" className="pdf-header-img" alt="Infidigit"  />
                                                                <div className="doc-hr "></div>
                                                              </div> */}
                                                                        <HTMLdata
                                                                          className="observation"
                                                                          staticClass="recommendationclass"
                                                                          title={
                                                                            "Observation :"
                                                                          }
                                                                          data={
                                                                            innerItem.comment
                                                                          }
                                                                        />
                                                                      </>
                                                                    ) : (
                                                                      <></>
                                                                    )}
                                                                    {innerItem.suggestion ? (
                                                                      <>
                                                                        {/* <div className="page-break" id={`is${active}`}>
                                                                <h1>Infidigit</h1>
                                                                <img src={Logo} alt="Infidigit" className="logo-pdf"/>
                                                                <img src="https://www.infidigit.com/wp-content/uploads/2022/11/cropped-cropped-cropped-infidigit-logo.png" className="pdf-header-img" alt="Infidigit"  />
                                                                <div className="doc-hr "></div>
                                                              </div> */}
                                                                        <HTMLdata
                                                                          className="recommendation"
                                                                          staticClass="recommendationclass"
                                                                          title={
                                                                            "Recommendation :"
                                                                          }
                                                                          data={
                                                                            innerItem.suggestion
                                                                          }
                                                                        />
                                                                      </>
                                                                    ) : (
                                                                      <></>
                                                                    )}

                                                                    <div className="row para-box">
                                                                      {innerItem
                                                                        .supportingDocs
                                                                        .length ? (
                                                                        <div className="col-md-12 pdf-box mt-4">
                                                                          <div className="pdf-title">
                                                                            {/* {innerItem.supportingDocs.length}  */}
                                                                            Attachments
                                                                          </div>
                                                                          <div className="d-flex align-items-center justify-content-between">
                                                                            <div className="row w-100">
                                                                              {innerItem
                                                                                .supportingDocs
                                                                                .length &&
                                                                                innerItem.supportingDocs.map(
                                                                                  (
                                                                                    sd_Item
                                                                                  ) => {
                                                                                    return (
                                                                                      <div className="col-md-3 flex-pdf p-3 d-flex align-items-center mr-3">
                                                                                        <a
                                                                                          href={
                                                                                            GET_Server_URL +
                                                                                            sd_Item.documents
                                                                                          }
                                                                                          target="_blank"
                                                                                          download=""
                                                                                          className=" d-flex align-items-center"
                                                                                          rel="noreferrer"
                                                                                        >
                                                                                          <div className="icon">
                                                                                            {sd_Item?.documents?.includes(
                                                                                              ".jpg"
                                                                                            ) ||
                                                                                              sd_Item?.documents?.includes(
                                                                                                ".png"
                                                                                              ) ||
                                                                                              sd_Item?.documents?.includes(
                                                                                                ".jpeg"
                                                                                              ) ? (
                                                                                              <div>
                                                                                                <svg
                                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                                  width="24"
                                                                                                  height="24"
                                                                                                  viewBox="0 0 24 24"
                                                                                                  fill="none"
                                                                                                  stroke="currentColor"
                                                                                                  stroke-width="2"
                                                                                                  stroke-linecap="round"
                                                                                                  stroke-linejoin="round"
                                                                                                  class="feather feather-image"
                                                                                                >
                                                                                                  <rect
                                                                                                    x="3"
                                                                                                    y="3"
                                                                                                    width="18"
                                                                                                    height="18"
                                                                                                    rx="2"
                                                                                                    ry="2"
                                                                                                  ></rect>
                                                                                                  <circle
                                                                                                    cx="8.5"
                                                                                                    cy="8.5"
                                                                                                    r="1.5"
                                                                                                  ></circle>
                                                                                                  <polyline points="21 15 16 10 5 21"></polyline>
                                                                                                </svg>
                                                                                                {sd_Item?.documents.substr(
                                                                                                  27
                                                                                                )}
                                                                                              </div>
                                                                                            ) : (
                                                                                              <>

                                                                                              </>
                                                                                            )}
                                                                                            {sd_Item?.documents?.includes(
                                                                                              ".pdf"
                                                                                            ) ? (
                                                                                              <div className="d-flex align-items-center attach-img attach-document">
                                                                                                <i
                                                                                                  class="fa fa-file-pdf me-3"
                                                                                                  aria-hidden="true"
                                                                                                ></i>
                                                                                                <span className="ms-3 attach_text attachment-styles">
                                                                                                  {sd_Item?.documents.substr(
                                                                                                    27
                                                                                                  )}
                                                                                                </span>
                                                                                              </div>
                                                                                            ) : (
                                                                                              <>

                                                                                              </>
                                                                                            )}
                                                                                            {sd_Item?.documents?.includes(
                                                                                              ".txt"
                                                                                            ) ? (
                                                                                              <div>
                                                                                                <svg
                                                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                                                  width="24"
                                                                                                  height="24"
                                                                                                  viewBox="0 0 24 24"
                                                                                                  fill="none"
                                                                                                  stroke="#212121"
                                                                                                  stroke-width="2"
                                                                                                  stroke-linecap="round"
                                                                                                  stroke-linejoin="round"
                                                                                                  class="feather feather-file-text"
                                                                                                >
                                                                                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                                                                  <polyline points="14 2 14 8 20 8"></polyline>
                                                                                                  <line
                                                                                                    x1="16"
                                                                                                    y1="13"
                                                                                                    x2="8"
                                                                                                    y2="13"
                                                                                                  ></line>
                                                                                                  <line
                                                                                                    x1="16"
                                                                                                    y1="17"
                                                                                                    x2="8"
                                                                                                    y2="17"
                                                                                                  ></line>
                                                                                                  <polyline points="10 9 9 9 8 9"></polyline>
                                                                                                </svg>
                                                                                                {sd_Item?.documents.substr(
                                                                                                  27
                                                                                                )}
                                                                                              </div>
                                                                                            ) : (
                                                                                              <>

                                                                                              </>
                                                                                            )}
                                                                                            {sd_Item?.documents?.includes(
                                                                                              ".xlsx"
                                                                                            ) ? (
                                                                                              <div className="d-flex align-items-center attach-document">
                                                                                                <i
                                                                                                  class="fa fa-file-excel me-3"
                                                                                                  aria-hidden="true"
                                                                                                ></i>
                                                                                                <span className="attach_text  attachment-styles">
                                                                                                  {sd_Item?.documents.substr(
                                                                                                    27
                                                                                                  )}
                                                                                                </span>
                                                                                              </div>
                                                                                            ) : (
                                                                                              <>

                                                                                              </>
                                                                                            )}
                                                                                          </div>
                                                                                        </a>
                                                                                        {/* <div className="text ms-3">
                                                                        <h5>
                                                                          <a href={sd_Item.documents} download >{sd_Item.documents}</a>
                                                                        </h5>
                                                                        <p className="mb-0">
                                                                          2.4 MB
                                                                        </p>
                                                                      </div> */}
                                                                                      </div>
                                                                                    );
                                                                                  }
                                                                                )}{" "}
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      ) : (
                                                                        <></>
                                                                      )}
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              ) : (
                                                                <></>
                                                              )}
                                                            </div>
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                </div>
                                              </>
                                              {/* </span> */}
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* </div> */}
            </div>
          </PDFExport>
          <Drawer
            title="Export Report"
            className="export-report-modal seo-modal"
            placement="right"
            onClose={onClose}
            open={open}
          >
            <div className="portlet-body">
              <div className="radio-box">
                <div
                  className={
                    activePdfClass == 1
                      ? "custom-control custom-radio active"
                      : "custom-control custom-radio"
                  }
                  onClick={() => {
                    activePDFFullDoc(1);
                  }}
                >
                  <input
                    type="radio"
                    id="customRadio1"
                    name="customRadio"
                    class="custom-control-input"
                    checked={activePdfClass == 1 ? true : false}
                  />
                  <label class="custom-control-label" for="customRadio1">
                    Full Report
                  </label>
                  <p>Export whole report with all sections</p>
                </div>
                <div
                  className={
                    activePdfClass == 2
                      ? "custom-control custom-radio mt-4 active"
                      : "custom-control custom-radio mt-4"
                  }
                  onClick={() => {
                    activePDFFullDoc(2);
                  }}
                >
                  <input
                    type="radio"
                    id="customRadio2"
                    name="customRadio"
                    class="custom-control-input"
                    checked={activePdfClass == 2 ? true : false}
                  />
                  <label class="custom-control-label" for="customRadio2">
                    Selected Sections
                  </label>
                  <p>Export report for all the selected sections</p>
                </div>
              </div>
              {activePdfClass == 2 ? (
                <div className="div-collapse">
                  <div className="row">
                    <div class="col-md-10 col-sm-12">
                      <div class="input-box mt-5 w-100">
                        <Input
                          allowClear
                          type="text"
                          class="form-control"
                          placeholder="Search"
                          onChange={(nameSearch) => {
                            setSearchText(nameSearch.target.value);
                          }}
                          id="input-s"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="selection-box">
                    {sectionListData.map((i) => {
                      return (
                        <Checkbox
                          value={i.value}
                          label={i.label}
                          auditModuleId={i.auditModuleId}
                        />
                      );
                    })}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {/* Make the Condition for Enable & Disable Export button based on Checkbox click */}
              <button
                className="btn btn-primary seo-modal-btn"
                disabled={isDownload}
                onClick={() => {
                  // exportPDF();
                  setExportOption(true);
                  message.warning(
                    "Please wait, your PDF report is being prepared."
                  );
                  SetIsDownload(true);
                  // setButtonDisabled(true)
                  setLoading(true);
                  if (activePdfClass == 2) {
                    var selectedSection = [];
                    sectionListData.map((i) => {
                      if (i.value) {
                        selectedSection.push(i);
                      }
                    });
                    var categoriesScore21 = [];
                    let obj = {};
                    categoriesScoreCopy.map((i) => {
                      obj = {
                        category: i.category,
                        categoryId: i.categoryId,
                        categoryWiseModuleScores: [],
                      };
                      var categoriesScore22 = [];
                      let arr = [];
                      selectedSection.map((selectedItem) => {
                        i.categoryWiseModuleScores.map((ii) => {
                          if (
                            selectedItem.auditModuleId == ii?.auditModuleId
                          ) {
                            categoriesScore22.push(ii);
                            arr.push(ii);
                          }
                        });
                      });
                      obj.categoryWiseModuleScores = arr;
                      if (arr.length > 0) {
                        categoriesScore21.push(obj);
                      }
                    });
                    setCategoriesScore2(categoriesScore21);
                  } else {
                    setCategoriesScore2(categoriesScoreCopy);
                  }

                  setActive("active");
                  setTimeout(async () => {
                    exportPDF(image);
                  }, 2500);
                  // setTimeout
                  //   (async () => {
                  //     const promise = await downloadPDFfile(image, props?.location?.state?.selectedAuditName + ".pdf", GeneratePDF, Loop, setOpen)//props?.location?.state?.selectedAuditName
                  //     try {
                  //       if (promise) {
                  //         setLoading(false);
                  //       } else {
                  //         setLoading(false);
                  //       }
                  //       setActive('inactive')
                  //     } catch (e) {
                  //       setLoading(false);
                  //     }
                  //     // setOpen(false);
                  //   }, 2000);
                }}
              >
                Export
              </button>

            </div>
          </Drawer>
          {isLoading ? (
            <></>
          ) : (
            <div onClick={topFunction} className="scroll-topbutton">
              <i
                id="myBtn"
                title="Go to top"
                class="fa fa-angle-up"
                aria-hidden="true"
              ></i>
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default ModuleExpandSEOAuditV3;
