import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import {
  getCompetitorsByProjectId,
  createUrl,
  createKeywords,
  getContentWordGetConfig,
  getKeyWordByProjectId,
  getUrlByOrgId,
  updateContentWordGetConfig,
  GET_Server_URL,
  documentFileType,
  contentWordConfigImport,
} from "../../utility/Constant";
import {
  frequencyListKeyValue,
  selectAllList,
  themeStyle,
} from "../../utility/Globals";
import toast from "react-hot-toast";
const ContentWordCount = ({ update }) => {
  const history = useHistory();
  const [urlList, setURlList] = useState([]);
  const [frequencyCWC, setFrequencyCWC] = useState("0");
  const [competitorsList, setCompetitorsList] = useState([]);
  const [IsActive, SetIsActive] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState("");
  const [IsActive1, SetIsActive1] = useState(false);
  const [IsActive2, SetIsActive2] = useState(false);
  const [urlName, setURLName] = useState("");
  const [keywordValue, setKeywordValue] = useState("");
  const [keywordList, setKeywordList] = useState([]);
  const [competitors, setCompetitors] = useState("");
  const [oldKeywordList, setOldKeywordList] = useState([]);
  const [oldCompList, setOldCompList] = useState([]);
  const [isAccActive, setIsActiveAcc] = useState(false);
  const notifyUpdate = () => toast.success("Successfully Updated!");

  useEffect(() => {
    getUrlList();
  }, []);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  function getContentWordData() {
    APIManager.getAPI(
      getContentWordGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response?.data) {
          setFrequencyCWC(response.data.frequency);
          setIsActiveAcc(response.data.isScrapeFull);
          const List = [];
          if (response.data.contentWordCountUrlConfigurations.length != 0) {
            response.data.contentWordCountUrlConfigurations.map((item) => {
              let kList = [];
              item.contentWordCountCompetitorConfigurations.map((i1) => {
                kList.push({
                  id: i1?.competitors?.id,
                  key: i1?.competitors?.id,
                  label: i1?.competitors?.fieldValue,
                  value: i1?.competitors?.fieldValue,
                  comp: i1?.competitors?.fieldValue,
                });
              });
              let cList = [];
              item.contentWordCountKeywordConfigurations.map((i2) => {
                cList.push({
                  id: i2?.keywords?.id,
                  key: i2?.keywords?.id,
                  comp: i2?.keywords?.fieldValue,
                  label: i2?.keywords?.fieldValue,
                  value: i2?.keywords?.fieldValue,
                });
              });
              List.push({
                id: item.id,
                urlKeywordCWC: item.urlId,
                selectedKeyword: cList,
                CompetitorSelected: kList,
              });
            });
            setContentArray(List);
          }
        }
      }
    });
  }

  function addUpdateContentWordGetConfig() {
    let mainList = [];
    contentArray.map((item) => {
      let keywordsArr = [];
      item.selectedKeyword.map((a) => {
        if (a.id != "0") {
          keywordsArr.push({ keywordId: a.id });
        }
      });
      let competitorList = [];
      item.CompetitorSelected.map((b) => {
        if (b.id != "0") {
          competitorList.push({ CompetitorId: b.id });
        }
      });

      let chield1 = {
        UrlId: item.urlKeywordCWC,
        ContentWordCountKeywordConfigurations: keywordsArr,
        ContentWordCountCompetitorConfigurations: competitorList,
      };
      mainList.push(chield1);
    });
    let objMain = {
      Frequency: frequencyCWC,
      ProjectId: localStorage.getItem("projectGId"),
      ContentWordCountUrlConfigurations: mainList,
      isScrapeFull: isAccActive,
    };

    APIManager.postAPI(updateContentWordGetConfig, objMain, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          update("");
          notifyUpdate();
        }
      }
    );
  }

  function addkeyword() {
    APIManager.postAPI(
      createKeywords,
      {
        uckType: 2,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: keywordValue,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getKeywordlist();
        SetIsActive1(false);
        setSelectedIndex("");
        setKeywordValue("");
      }
    });
  }

  function getKeywordlist() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        getContentWordData();
        const list = [];
        let c = 1;
        list.push({
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          url: "Select All",
        });
        response.data.map((item) => {
          list.push({
            count: c,
            key: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            id: item.id,
          });
          c++;
        });
        setKeywordList(list);
      }
    });
  }

  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setURLName("");
        getUrlList(localStorage.getItem("projectGId"));
        SetIsActive(false);
        setSelectedIndex("");
      }
    });
  }

  function getUrlList() {
    APIManager.getAPI(getUrlByOrgId + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          getCompetitorsList();
          const list = [];
          const exportList = [];
          let c = 1;
          response.data.map((item) => {
            let obj = {
              count: c,
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              url: item.fieldValue,
            };
            c++;
            list.push(obj);
            let exportObj = {
              key: item.id,
              id: item.id,
              url: item.fieldValue,
            };
            exportList.push(exportObj);
          });
          setURlList(list);

          let temp = contentArray;
          temp[0].urlKeywordCWC = list[0].id;
          setContentArray(temp);
        }
      }
    );
  }

  function addCompetitors() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 1,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: competitors,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setCompetitors("");
        getCompetitorsList();
        SetIsActive2(false);
        setSelectedIndex("");
      }
    });
  }

  function getCompetitorsList() {
    APIManager.getAPI(
      getCompetitorsByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        getKeywordlist();
        const exportList = [];
        let c = 1;
        const list = [];
        list.push({
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          url: "Select All",
        });
        response.data.map((item) => {
          let obj = {
            count: c,
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            comp: item.fieldValue,
          };
          c++;
          list.push(obj);
          let exportObj = {
            key: item.id,
            id: item.id,
            comp: item.fieldValue,
          };
          exportList.push(exportObj);
        });
        setCompetitorsList(list);
      }
    });
  }

  const [contentArray, setContentArray] = useState([
    {
      id: 1,
      urlKeywordCWC: "",
      selectedKeyword: [],
      CompetitorSelected: [],
    },
  ]);

  function addContent() {
    var tempArray = JSON.parse(JSON.stringify(contentArray));
    tempArray.push({
      id: tempArray.length + 1,
      urlKeywordCWC: urlList[0]?.id,
      selectedKeyword: [],
      CompetitorSelected: [],
    });
    setContentArray(tempArray);
  }

  function subtrackContent(index) {
    var tempArray = JSON.parse(JSON.stringify(contentArray));
    tempArray.splice(index, 1);
    setContentArray(tempArray);
  }

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const resetFileInput = () => {
    // reset input value
    inputFileRef.current.value = null;
  };
  function importFile() {
    var input = document.getElementById("keywordFile");
    for (var i = 0; i < input.files.length; ++i) {}
    uploadImportFile(input.files.item(0));
    resetFileInput();
  }

  function uploadImportFile(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("frequency", frequencyCWC);
    bodyFormData.append("projectId", localStorage.getItem("projectGId"));
    APIManager.postAPI(contentWordConfigImport, {}, bodyFormData, false).then(
      (response) => {
        if (response.status == 200) {
          getUrlList();
        }
      }
    );
  }

  const [value, setValue] = useState();

  return (
    <div className="custom-column-70">
      <div className="portlet common-form-fields slideInUp">
        <div class="portlet-header portlet-header-bordered">
        <svg  onClick={() => update("")}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - Content Word Count on a
            Page
          </h3>
        </div>
        <div className="portlet-body px-4">
          <div className="col-md-12  d-flex justify-content-end tab-panel-url-options">
            <span class="export me-0">
              <input
                style={{ display: "none" }}
                type="file"
                id="keywordFile"
                name="keywordFile"
                multiple={false}
                accept={documentFileType}
                ref={inputFileRef}
                onChange={() => {
                  importFile();
                }}
              />
              <a
                class="btn btn-primary download-temp"
                style={{ marginLeft: 18 + "px" }}
                target="_blank"
                href={GET_Server_URL + "audit/ContentWord.xlsx"}
              >
                Download Template
              </a>
              <button
                class="btn btn-primary mx-3 me-0 import"
                onClick={onBtnClick}
              >
                Import
              </button>
            </span>
          </div>

          <div className="tab-panel-url-options mt-3"></div>
          <table class="contentword-table dash-table table-modules text-center table table-bordered  table-hover table-responsive">
            <thead class="table-modules-head">
              <tr>
                <th className="contenttable">URLs</th>
                <th className="contenttable">URL Level Keywords</th>
                <th className="contenttable">Competitors</th>
                <th className="contenttable"></th>
              </tr>
            </thead>
            <tbody>
              {contentArray.map((item, index) => {
                var tempArray = JSON.parse(JSON.stringify(contentArray));
                return (
                  <tr key={item.id}>
                    <td>
                      <div class="col-md-12 d-flex justify-content-between">
                        <select
                          className="w-100"
                          value={item.urlKeywordCWC}
                          onChange={(e) => {
                            var tempArray = JSON.parse(
                              JSON.stringify(contentArray)
                            );
                            tempArray[index].urlKeywordCWC = e.target.value;
                            setContentArray(tempArray);
                            setValue({});
                          }}
                        >
                          {urlList.map((item) => {
                            return <option value={item.id}>{item.url}</option>;
                          })}
                        </select>

                        <button
                          className="border plus ms-3"
                          style={{ background: "none" }}
                          onClick={() => {
                            SetIsActive(true);
                            setSelectedIndex(index);
                          }}
                        >
                          {" "}
                          <i
                            class="fa fa-plus-circle "
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      </div>
                      {IsActive && selectedIndex == index ? (
                        <div class="col-md-12">
                          <label></label>
                          <div className="timecheck-icon d-flex">
                            <input
                              className="w-100"
                              type="text"
                              name=""
                              placeholder="Enter URL"
                              value={urlName}
                              onChange={(text) => {
                                setURLName(text.target.value);
                              }}
                            />

                            <button
                              style={{ border: "none", background: "none" }}
                              onClick={() => {
                                SetIsActive(false);
                                setSelectedIndex("");
                                setURLName("");
                              }}
                            >
                              {" "}
                              <i
                                class="fa fa-times d-flex ms-3"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>

                            <button
                              style={{ border: "none", background: "none" }}
                              id={"rightbtn"}
                              onClick={() => {
                                if (urlName) {
                                  addUrl();
                                }
                              }}
                            >
                              {" "}
                              <i
                                class="fa fa-check"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <div class="col-md-12 d-flex justify-content-between">
                        <ReactSelect
                          styles={themeStyle()}
                          className="w-200"
                          options={keywordList}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(selected) => {
                            var tempArray = JSON.parse(
                              JSON.stringify(contentArray)
                            );
                            tempArray[index].selectedKeyword = selectAllList(
                              selected,
                              oldKeywordList,
                              keywordList
                            );
                            setContentArray(tempArray);
                            setOldKeywordList(tempArray[index].selectedKeyword);
                            setValue({});
                          }}
                          allowSelectAll={true}
                          value={item.selectedKeyword}
                        />

                        <button
                          className="border plus ms-3"
                          style={{ background: "none" }}
                          onClick={() => {
                            SetIsActive1(true);
                            setSelectedIndex(index);
                          }}
                        >
                          {" "}
                          <i
                            class="fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      </div>
                      {IsActive1 && selectedIndex == index ? (
                        <div class="col-md-12">
                          <label></label>
                          <div className="timecheck-icon d-flex">
                            <input
                              className="w-100"
                              type="text"
                              name=""
                              placeholder="Enter Keyword"
                              value={keywordValue}
                              onChange={(text) => {
                                setKeywordValue(text.target.value);
                              }}
                            />

                            <button
                              style={{ border: "none", background: "none" }}
                              onClick={() => {
                                SetIsActive1(false);
                                setSelectedIndex("");
                                setKeywordValue("");
                              }}
                            >
                              {" "}
                              <i
                                class="fa fa-times d-flex ms-3"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>

                            <button
                              style={{ border: "none", background: "none" }}
                              id={"rightbtn"}
                              onClick={() => {
                                if (keywordValue) {
                                  addkeyword();
                                }
                              }}
                            >
                              {" "}
                              <i
                                class="fa fa-check"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td>
                      <div class="col-md-12 d-flex justify-content-between px-3">
                        <ReactSelect
                          styles={themeStyle()}
                          className="w-200"
                          options={competitorsList}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          components={{ Option }}
                          onChange={(selected) => {
                            var tempArray = JSON.parse(
                              JSON.stringify(contentArray)
                            );
                            tempArray[index].CompetitorSelected = selectAllList(
                              selected,
                              oldCompList,
                              competitorsList
                            );
                            setContentArray(tempArray);
                            setOldCompList(tempArray[index].CompetitorSelected);
                            setValue({});
                          }}
                          value={item.CompetitorSelected}
                          allowSelectAll={true}
                        />

                        <button
                          className="border plus ms-3"
                          style={{ background: "none" }}
                          onClick={() => {
                            SetIsActive2(true);
                            setSelectedIndex(index);
                          }}
                        >
                          <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
                        </button>
                      </div>
                      {IsActive2 && selectedIndex == index ? (
                        <div class="col-md-12">
                          <label></label>
                          <div className="timecheck-icon d-flex">
                            <input
                              className="w-100 ms-3"
                              type="text"
                              name=""
                              placeholder="Enter URL"
                              value={competitors}
                              onChange={(text) => {
                                setCompetitors(text.target.value);
                              }}
                            />

                            <button
                              style={{ border: "none", background: "none" }}
                              onClick={() => {
                                SetIsActive2(false);
                                setSelectedIndex("");
                                setCompetitors("");
                              }}
                            >
                              {" "}
                              <i
                                class="fa fa-times d-flex"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>

                            <button
                              style={{ border: "none", background: "none" }}
                              id={"rightbtn"}
                              onClick={() => {
                                if (competitors) {
                                  addCompetitors();
                                }
                              }}
                            >
                              {" "}
                              <i
                                class="fa fa-check"
                                aria-hidden="true"
                              ></i>{" "}
                            </button>
                          </div>
                        </div>
                      ) : (
                        <></>
                      )}{" "}
                    </td>
                    <td>
                      <td class="plus-minus-btn d-flex pt-0">
                        {contentArray.length > 1 && (
                          <button
                            className="me-3"
                            onClick={() => subtrackContent(index)}
                          >
                            {" "}
                            <i
                              class="fa fa-minus-square"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                        )}

                        {contentArray.length - 1 === index && (
                          <button onClick={addContent} classNamems="ms-3">
                            {" "}
                            <i
                              class="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                        )}
                      </td>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div class="col-md-12 mt-3 px-0">
            <label htmlFor="" style={{ marginRight: 24 + "px" }}>
              Frequency
            </label>
            <br />

            <select
              style={{ padding: 8 + "px" }}
              class="w-100"
              value={frequencyCWC}
              onChange={(e) => {
                setFrequencyCWC(e.target.value);
              }}
            >
              {frequencyListKeyValue.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>
          <div class="col-md-12  mt-4 d-flex align-items-center px-0">
            <label className="me-3 mb-0">Scrape full page?</label>
            <input
              type="checkbox"
              style={{ width: 20 + "px", height: 20 + "px" }}
              checked={isAccActive}
              onClick={() => {
                setIsActiveAcc(!isAccActive);
              }}
            />
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered pb-4">
          <div class="col-md-12">
            <button
              class="btn btn-primary"
              onClick={() => {
                addUpdateContentWordGetConfig();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentWordCount;
