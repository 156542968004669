import { Input, Table } from "antd";
import APIManager from "../../services/APIManager";
import {
  getOrganizations,
  deleteOrganizations,
  orgnizationIsActive,
  updateOrganizations,
  createOrganizations,
} from "../../utility/Constant";
import React, { useState, useEffect } from "react";
import { Sorter } from "../../common/Sorter";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

const OrganizationsList = ({ update }) => {
  const [isUpdateOrg, setisUpdateOrg] = useState(false);
  const [isAccActive, setIsActiveAcc] = useState(0);
  const [orgId, setOrgId] = useState("");
  const [OrgnizationName1, setOrgnizationName1] = useState("");
  const [OrgnizationNameCopy1, setOrgnizationNameCopy1] = useState("");
  const [message, setMessage] = useState("");
  const [OrganizationList1, setOrganizationList1] = useState([]);
  const [userOrg, setuserOrg] = useState([]);
  const [organizationCol, setorganizationCol] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");
  const notifyDelete = () => toast.success("Successfully Deleted!");

  useEffect(() => {
    getOrganizationsList();
  }, []);

  useEffect(() => {
    var data = [
      {
        title: "Organization Name",
        dataIndex: "organizationname",
        key: "organizationname",
        sorter: {
          compare: (a, b) =>
            Sorter.DEFAULT(a.organizationname, b.organizationname),
          multiple: 3,
        },
      },
      {
        title: "Activate / Deactivate",
        dataIndex: "actde",
        key: "actde",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    setorganizationCol(data);
  }, []);

  function getOrganizationsList() {
    APIManager.getAPI(getOrganizations).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item, index) => {
          let obj = {
            value: item.organizationName,
            organizationname: item.organizationName,
            id: item.id,
            status: item.status,
            actde: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item.status}
                  onClick={() => {
                    updateStatus(item.id);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
            action: (
              <div className="text-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit edit-icon me-3"
                  onClick={() => {
                    setisUpdateOrg(true);
                    setIsActiveAcc(item.status);
                    setOrgId(item.id);
                    setOrgnizationName1(item.organizationName);
                    setOrgnizationNameCopy1(item.organizationName);
                    setMessage("");
                    var e = document.getElementById("input-s");
                    e.scrollIntoView({
                      block: "end",
                      behavior: "smooth",
                      inline: "center",
                    });
                  }}
                >
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon "
                  onClick={() => {
                    submit(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          };
          list.push(obj);
        });
        setOrganizationList1(list);
        setuserOrg(list);
      }
    });
  }

  function updateStatus(id) {
    APIManager.postAPI(
      orgnizationIsActive + "?organizationId=" + id,
      {},
      null,
      true
    ).then((response) => {
      if (response.status == 200) {
        getOrganizationsList();
      }
    });
  }

  function submit(id) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteOrgnizationById(id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  async function deleteOrgnizationById(id) {
    try {
      const response = await APIManager.deteletItem(deleteOrganizations + id);
      if (response && response.status == 200) {
        setOrgnizationName1("");
        getOrganizationsList();
        notifyDelete();
      }
    } catch (e) {}
  }

  function updateOrganization() {
    APIManager.putAPI(
      updateOrganizations,
      {
        Id: orgId,
        organizationName: OrgnizationName1.trim(),
        status: isAccActive,
      },
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        setIsActiveAcc(0);
        clearData();
      }
    });
  }
  function clearData() {
    setisUpdateOrg(false);
    setOrgnizationName1("");
    setOrgId("");
    getOrganizationsList();
  }

  function addOrganization() {
    APIManager.postAPI(
      createOrganizations,
      {
        OrganizationName: OrgnizationName1.trim(),
        status: isAccActive,
      },
      null,
      true
    ).then((response) => {
      if (response && response?.status == 200) {
        clearData();
        notifyAdd();
      }
    });
  }
  return (
    <>
      <div class="dropdown drop-organization">
        <div>
          <div className="portlet setting-popup new-organization-popup mt-4">
            <div class="portlet-header portlet-header-bordered">
            <svg  onClick={() => {
                  update("");
                }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 className="portlet-title main-title text-start">
                Organizations
              </h3>
            </div>
            <div className="portlet rounded-0 px-3">
              <div className="row">
                <div class="col-md-3  col-sm-12 search-box mt-3 d-flex employee-search">
                  <div class="input-group-icon input-group-lg widget15-compact">
                    <div class="input-group-prepend">
                      <i class="fa fa-search text-primary"></i>
                    </div>
                    <Input
                      allowClear
                      type="text"
                      class="form-control"
                      placeholder="Type to search"
                      onChange={(nameSearch) => {
                        setuserOrg(
                          OrganizationList1.filter((organization) =>
                            organization.organizationname
                              .toLocaleLowerCase()
                              .includes(
                                nameSearch.target.value.toLocaleLowerCase()
                              )
                          )
                        );
                      }}
                      id="input-s"
                    />
                  </div>
                </div>
                <div class="col-md-9 text-end mt-3 employee-tab">
                  <input
                    className="px-4"
                    placeholder="Organization name"
                    style={{
                      borderRight: 0,
                      borderRadius: 0,
                      width: 300 + "px",
                    }}
                    type="text"
                    id="department-name"
                    value={OrgnizationName1}
                    required
                    onChange={(inputText) => {
                      setOrgnizationName1(inputText.target.value);
                      setMessage("");
                    }}
                  />
                  <button
                    class=" btn btn-primary organization-add"
                    onClick={() => {
                      if (OrgnizationName1.trim() != "") {
                        if (
                          OrgnizationNameCopy1.trim() ==
                            OrgnizationName1.trim() &&
                          isUpdateOrg
                        ) {
                          setisUpdateOrg(false);
                          setOrgnizationName1("");
                          setOrgId("");
                          return;
                        }
                        let isEqualItem = false;
                        userOrg.map((i) => {
                          if (i.value == OrgnizationName1.trim()) {
                            isEqualItem = true;
                          }
                        });
                        if (isEqualItem) {
                          setMessage("Organization already exists");
                        } else {
                          setMessage("");
                          if (isUpdateOrg) {
                            updateOrganization();
                          } else {
                            addOrganization();
                          }
                        }
                      } else {
                        setMessage("Organization is required");
                      }
                    }}
                  >
                    {isUpdateOrg ? "Update" : "Add"}
                  </button>
                  <div class="ms-2 pt-2" style={{ color: "red" }}>
                    {message}
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-sm-12  organization-table">
                  <Table
                    className="table table-bordered table-hover mt-3 organizationtable"
                    id="sample-module-expand"
                    dataSource={userOrg}
                    columns={organizationCol}
                    pagination={{
                      position: ["bottomRight"],
                      showSizeChanger: true,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrganizationsList;
