import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import APIManager from "../../services/APIManager";
import { backlinktable, dashboardBacklink } from "../../utility/Constant";
import { compareValues, formatRankDate, getColorClassName, mmDDyyyyFormateDateSet } from "../../utility/Globals";

function Backlink(props) {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [compareendDate, setcompareendDate] = useState("");
  const [isChart, setIsChart] = useState(0);
  const [series, setSeries] = useState([]);
  const [options, setObject] = useState({
    legend: {
      show: true,
    },
    chart: {
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      stroke: {
        curve: "smooth",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
  });
  const [dropdownsVal, setdropdownVal] = useState("1");
  const [backlinkTotal, setbacklinksTotal] = useState(0);
  const [refferingDomainsTotal, setrefferingDomainsTotal] = useState(0);
  const [backlinksTotalcompare, setbacklinksTotalcompare] = useState(0);
  const [refferingDomainsTotalcompare, setrefferingDomainsTotalcompare] =
    useState(0);
  const [diffSearchVolume, setdiffSearchVolume] = useState(0);
  const [diffKeyword, setdiffKeyword] = useState(0);
  const [diffbacklinkchk, setdiffbacklinkchk] = useState(0);
  const [diffrefferingDomainchk, setdiffrefferingDomainchk] = useState(0);

  useEffect(() => {
    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    setcompareendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    );

    let rowChartData = {
      ProjectId: localStorage.getItem("projectGId"),
      DashboardDateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
      },
      CompareDashboardDateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
      },
    };

    getChartDataCompare(rowChartData, dropdownsVal);
    let data = {
      ProjectId: localStorage.getItem("projectGId"),
      DashboardDateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
      CompareDashboardDateRange: {
        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
      },
    };
    GetSiteup(data);

  }, [props?.dataParentToChild]);

  useEffect(() => {
    setIsChart(props?.isChart);
  }, [props?.isChart]);

  function handleClickChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }

  function getChartDataCompare(rowChartData, dropdownsVal) {
    APIManager.postAPI(dashboardBacklink, rowChartData, null, true).then(
      (response) => {
        if (response && response.status === 200) {
          let graphSeries = [];
          let dataForGraph = [];
          let backlinks = [];
          let backlinkComp = [];
          let refferingDomains = [];
          let refferingDomainsComp = [];
          response.data.backlinkList.map((item) => {
            backlinks.push(item.backlinks);
            refferingDomains.push(item.refferingDomains);
            graphSeries.push(item.months);
          });

          response.data.backlinkCompList.map((item) => {
            backlinkComp.push(item.backlinks);
            refferingDomainsComp.push(item.refferingDomains);
          });
          if (dropdownsVal === 0) {
            dataForGraph.push(
              {
                name: "",
                data: backlinks,
              },
              {
                name: "",
                data: backlinkComp,
              }

            );
          } else {
            dataForGraph.push(
              {
                name: "",
                data: refferingDomains,
              },
              {
                name: "",
                data: refferingDomainsComp,
                color: "#26e7a6"
              }
            );
          }
          setSeries(dataForGraph);
        }
      }
    );
  }

  function GetSiteup(data) {
    let getdata = data;
    let backlinks = 0;
    let refferingDomains = 0;

    APIManager.postAPI(backlinktable, getdata, null, true).then((response) => {
      if (response.status === 200) {
        response.data.backlinkList.map((item) => {
          backlinks += item.backlinks;
          refferingDomains += item.refferingDomains;
        });
        setbacklinksTotal(backlinks);
        setrefferingDomainsTotal(refferingDomains);
        let backlinkscompare = 0;
        let refferingDomainscompare = 0;

        response.data.backlinkCompList.map((item) => {
          backlinkscompare += item.backlinks;
          refferingDomainscompare += item.refferingDomains;
        });

        setbacklinksTotalcompare(backlinkscompare);
        setrefferingDomainsTotalcompare(refferingDomainscompare);

        let backlinkratio = backlinks < backlinkscompare
          ? (100 * (backlinkscompare - backlinks)) / backlinkscompare
          : (100 * (backlinks - backlinkscompare)) / backlinks;
        let refferingDomainsratio =
          refferingDomains < refferingDomainscompare
            ? (100 * (refferingDomainscompare - refferingDomains)) / refferingDomainscompare
            : (100 * (refferingDomains - refferingDomainscompare)) / refferingDomains;

        setdiffSearchVolume(backlinkratio.toFixed(2));
        setdiffKeyword(refferingDomainsratio.toFixed(2));
        setdiffbacklinkchk(compareValues(backlinks, backlinkscompare));
        setdiffrefferingDomainchk(compareValues(refferingDomains, refferingDomainscompare));
      }
    });
  }

  return (
    <>
      <div className="title pb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3">Backlink Profile</h4>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="me-2">
              {isChart === 0 ? (
                <select
                  style={{ padding: 8 + "px" }}
                  className="w-100 ms-3 reffering-backlink-select form-select"
                  value={dropdownsVal}
                  onChange={(e) => {
                    setdropdownVal(e.target.value);
                    let rowChartData = {
                      ProjectId: localStorage.getItem("projectGId"),
                      DashboardDateRange: {
                        FROM: startDate,
                        TO: endDate,
                      },
                      CompareDashboardDateRange: {
                        FROM: compareStartDate,
                        TO: compareendDate,
                      },
                    };

                    getChartDataCompare(rowChartData, e.target.value);
                  }}
                >
                  <option value="1">Reffering Domains Count</option>
                  <option value="0">Backlinks</option>
                </select>
              ) : (
                <></>
              )}
            </div>

            <div className="btn-box">
              <div className="d-flex align-items-center">
                <button
                  className={`${isChart === 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(0);
                  }}
                >
                  <img
                    src={`${isChart === 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isChart === 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(1);
                  }}
                >
                  <img
                    src={`${isChart === 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3">
        {isChart === 0 ? (
          <>
            <div className="col-md-12">
              <div>
                <div className="mt-3 round-chart">
                  <Chart
                    options={options}
                    series={series}
                    height="300"
                    id="chartId"
                  />
                  <div className="legend-box d-flex align-items-center justify-content-center w-100 mt-2">
                    <div className="d-flex align-items-center">
                      <div className="legend-dot blue"></div>
                      <div className="ml-2">{formatRankDate(props?.dataParentToChild?.selection1?.startDate)}</div>
                    </div>
                    <div className="d-flex align-items-center ms-5">
                      <div className="legend-dot"></div>
                      <div className="ml-2">{formatRankDate(props?.dataParentToChild?.selection2?.startDate)}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-12 text-right backlink-daterange">
              Date Range : {formatRankDate(startDate)} -{" "}
              {formatRankDate(compareStartDate)}
            </div>
            <div className="row">
              <div className="col-md-6 m-0 p-0">
                <div className=" reffering-domain mt-3 me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>Reffering Domains</h4>
                    <div className="grren-domain text-right">
                      {diffrefferingDomainchk != 1 ?
                        (<>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>)
                        :
                        (<>
                          <>
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                r="17"
                                transform="matrix(1 0 0 -1 17 17)"
                                fill="#FFC0C2"
                              />
                              <g clip-path="url(#clip0_4_4443)">
                                <path
                                  d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.167 22H26.167V17"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4443">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="matrix(1 0 0 -1 7 27)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </>
                        </>)}

                    </div>
                  </div>
                  <h3 className={getColorClassName(diffrefferingDomainchk)}>+{+diffKeyword ? +diffKeyword : 0}%</h3>

                  <div className="row mt-3">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-md-6 p-0">
                        <p>{refferingDomainsTotal.toFixed(2)}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>
                      <div className="col-md-6 total-compare">
                        <p>{refferingDomainsTotalcompare.toFixed(2)}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 m-0 p-0">
                <div className="reffering-domain mt-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>Backlinks</h4>
                    <div className="grren-domain text-right">
                      {diffbacklinkchk != 1 ?
                        (<>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>)
                        :
                        (
                          <>
                            <svg
                              width="35"
                              height="34"
                              viewBox="0 0 35 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                              <g clip-path="url(#clip0_4_4546)">
                                <path
                                  d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.667 12H26.667V17"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4546">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(7.5 7)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </>
                        )}
                    </div>
                  </div>
                  <h3 className={getColorClassName(diffbacklinkchk)}>+{+diffSearchVolume ? +diffSearchVolume : 0}%</h3>
                  <div className="row mt-3">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-md-6 p-0">
                        <p>{backlinkTotal.toFixed(2)}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>
                      <div className="col-md-6 total-compare">
                        <p>{backlinksTotalcompare.toFixed(2)}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Backlink;
