import Chart from "react-apexcharts";
import { useEffect } from "react";
import React, { useState } from "react";
import APIManager from "../../services/APIManager";
import {
  getSeoAuditByProjectAuditId,
  getSEOAuditChartData, getSEOAuditGetById,
} from "../../utility/Constant";

function ImplementationChart(props) {
  const [options, setObject] = useState({});
  const [changeRequestList, setchangeRequestList] = useState([]);
  const [implementedList, setimplementedList] = useState([]);
  const [wipList, setwipList] = useState([]);
  const [onHoldList, setonHoldList] = useState([]);
  const [pendingList, setpendingList] = useState([]);
  const [implementationList, setimplementationList] = useState([]);

  useEffect(() => {
    getAuditList();
  }, []);

  function getAuditList() {
    APIManager.getAPI(
      getSEOAuditGetById + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        let auditList = [];
        response.data?.audits.map((item) => {
          if (item.isShown == true) {
            auditList.push(item);
          }
        });
        if (auditList && auditList.length > 0) {
          getSeoAuditByProjectAuditIdList(auditList[0].seoAuditId);
        }
      }
    });
  }

  function getSeoAuditByProjectAuditIdList(projectAuditId) {
    APIManager.getAPI(
      getSeoAuditByProjectAuditId +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      projectAuditId +
      "&view="
    ).then((response) => {
      if (response && response.status == 200) {
        let totalNCP = 0;
        response.data?.seoAuditItems.map((item) => {
          totalNCP = totalNCP + item.nonCompliantPoints;
        });
        setNcp(totalNCP);
        getAuditChartData(2, projectAuditId, totalNCP);
      }
    });
  }

  const [ncp, setNcp] = useState(0);

  function getAuditChartData(id, auditId, totalNCP) {
    const createdDateListData = [];
    let changeRequestList = [];
    let changeRequestListTemp = [];
    let implementationList = [];
    let implementedList = [];
    let implementedListTemp = [];
    let onHoldList = [];
    let onHoldListTemp = [];
    let pendingList = [];
    let pendingListTemp = [];
    let wipList = [];
    let wipListTemp = [];
    APIManager.getAPI(
      getSEOAuditChartData +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      auditId +
      "&view=" +
      id
    ).then((response) => {
      if (response && response.status == 200) {
        response.data?.map((item) => {
          if (id == 0) {
            createdDateListData.push(item.quartersRange);
          }
          if (id == 1) {
            createdDateListData.push(item.halfYearlyRange);
          }
          if (id == 2) {
            createdDateListData.push(item.months + " " + item.years);
          }

          let crTotoal = 0;
          let implementedTotal = 0;
          let onHoldTotal = 0;
          let pendingTotal = 0;
          let wipTotal = 0;
          changeRequestListTemp.push(item.changeRequest);
          implementedListTemp.push(item.implemented);
          onHoldListTemp.push(item.onHold);
          pendingListTemp.push(item.pending);
          wipListTemp.push(item.wip);

          changeRequestListTemp?.map((i) => {
            crTotoal = crTotoal + i;
          });
          implementedListTemp?.map((i) => {
            implementedTotal = implementedTotal + i;
          });
          onHoldListTemp?.map((i) => {
            onHoldTotal = onHoldTotal + i;
          });
          pendingListTemp?.map((i) => {
            pendingTotal = pendingTotal + i;
          });
          wipListTemp?.map((i) => {
            wipTotal = wipTotal + i;
          });
          let NCP = totalNCP == 0 ? ncp : totalNCP;
          changeRequestList.push(Math.round(crTotoal * 100) / 100);
          implementedList.push(Math.round(implementedTotal * 100) / 100);
          onHoldList.push(Math.round(onHoldTotal * 100) / 100);
          pendingList.push(Math.round(pendingTotal * 100) / 100);
          wipList.push(Math.round(wipTotal * 100) / 100);
          implementationList.push(
            Math.round(((implementedTotal * 100) / NCP) * 100) / 100
          );
        });
        setchangeRequestList(changeRequestList);
        setimplementationList(implementationList);
        setimplementedList(implementedList);
        setonHoldList(onHoldList);
        setpendingList(pendingList);
        setwipList(wipList);
        setChartData(createdDateListData);
      }
    });
  }

  function setChartData(createdDateList) {
    setObject({
      chart: {
        height: 350,
        width: 500,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 0, 0, 0, 0, 3],
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        offsetY: -9,
        style: {
          fontSize: '12px',
          colors: ["#000000"],
          fontWeight: 'normal',
        },
        background: {
          enabled: false,
        }
      },
      labels: createdDateList,
      grid: {
        show: false,
        padding: {
          left:
            createdDateList.length == 3 || createdDateList.length == 2
              ? 150
              : 60,
          right:
            createdDateList.length == 3 || createdDateList.length == 2
              ? 150
              : 60,
        },
      },
      yaxis: [
        {
          seriesName: "Changes Requested",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#757575",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            // text: "Changes Requested",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#91E395",
          },
          labels: {
            style: {
              colors: "#91E395",
            },
          },
          title: {
            text: "Implemented",
            style: {
              color: "#91E395",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "WIP",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "On Hold",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Pending",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Implementation %",
          opposite: true,
          max: 100,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#735EC9",
          },
          labels: {
            style: {
              colors: "#735EC9",
            },
          },
          title: {
            // text: "Implementation %",
            style: {
              color: "#735EC9",
            },
          },
        },
      ],
    });
  }

  return (<>
    <div>
      <Chart
        options={options}
        series={[
          {
            name: "Changes Requested",
            type: "column",
            color: "#37b3f9",
            data: changeRequestList,
          },
          {
            name: "WIP",
            type: "column",
            color: "#ffa624",
            data: wipList,
          },
          {
            name: "On Hold",
            type: "column",
            color: "#ff4248",
            data: onHoldList,
          },
          {
            name: "Pending",
            type: "column",
            color: "#ffd957",
            data: pendingList,
          },
          {
            name: "Implemented",
            type: "column",
            color: "#91E395",
            data: implementedList,
          },          
          {
            name: "Implementation %",
            type: "line",
            color: "#735EC9",
            data: implementationList,
          },
        ]}
        type="line"
        height="300"
      />
    </div>
  </>)
}

export default ImplementationChart;