import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  creategscdata,
  getCountryByOrg,
  getGscData,
  getGSCProperty,
} from "../../utility/Constant";
import { components } from "react-select";
import { default as ReactSelect } from "react-select";

const GSCDataExtractor = ({ update }) => {
  const [DimensionsOptions, setDimensionsOptions] = useState();
  const [propertylist, setpropertylist] = useState([]);
  const [propertySelected, setpropertySelected] = useState([]);
  const [AggregationTypeSelected, setAggregationTypeSelected] = useState([]);
  const [SearchTypeSelected, setSearchTypeSelected] = useState([]);
  const [checkSearch, setcheckSearch] = useState(false);
  const [checkLinks, setcheckLinks] = useState(false);
  const [Enhancement, setEnhancement] = useState(false);
  const [filelistCustomer, setfilelistCustomer] = useState([]);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    var data = [
      { label: "Query", value: "query" },
      { label: "Page", value: "page" },
      { label: "Country", value: "country" },
      { label: "Device", value: "device" },
      { label: "Search Appearence", value: "searchappearence" },
      { label: "Date", value: "date" },
    ];

    const list = [];

    list.push({
      count: 0,
      key: "key",
      value: "Select All",
      label: "Select All",
      id: 0,
      dimension: "Select All",
    });
    data.map((item) => {
      let tempArray = {
        key: item.id,
        id: item.id,
        value: item.value,
        label: item.value,
        dimension: item.value,
      };
      list.push(tempArray);
    });
    setDimensionsOptions(list);
    getProperty();
    getCountryData();
  }, []);

  function getProperty() {
    APIManager.postAPI(
      getGSCProperty + "?projectId=" + localStorage.getItem("projectGId"),
      {},
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setpropertylist(response.data);
        setpropertySelected(response.data[0]?.siteUrl);
      }
    });
  }

  function updateList() {
    var input = document.getElementById("myfile");
    var data = [];
    for (var i = 0; i < input.files.length; ++i) {
      data.push(input.files.item(i).name);
    }
    setfilelistCustomer(input.files.item(0));
    addUpdateGSCData(input.files.item(0));
    setIsView(true);
  }

  function addUpdateGSCData(file) {
    const project_Id = localStorage.getItem("projectGId");
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    bodyFormData.append("insights", checkSearch);
    bodyFormData.append("links", checkLinks);
    bodyFormData.append("index", Enhancement);
    bodyFormData.append("Property", propertySelected);
    bodyFormData.append("projectId", project_Id);
    if (isView) {
      selectedCountry?.map((i, index) => {
        bodyFormData.append(
          `GSCDataExtractorCountryConfigurations[${index}].countryId`,
          i.id
        );
      });
    }
    APIManager.postAPI(creategscdata, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status == 200) {
          getProperty();
        }
      }
    );
  }

  function getGSCdata(countryListData) {
    APIManager.getAPI(getGscData + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          setcheckSearch(response.data.insights);
          setcheckLinks(response.data.links);
          setEnhancement(response.data.index);
          setfilelistCustomer({
            name: response.data.credentials.split("\\")[1],
          });
          setpropertySelected(response.data.property);
          setAggregationTypeSelected(response.data.aggregationType);
          setSearchTypeSelected(response.data.searchType);
          const countryList = [];
          response.data.gscDataExtractorCountryConfigurations.map((item) => {
            countryListData.map((a) => {
              if (a.id == item.countryId) countryList.push(a);
            });
          });
          setSelectedCountry(countryList);
          setIsView(true);
          getProperty();
        }
      }
    );
  }

  const [isView, setIsView] = useState(false);
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  function getCountryData() {
    APIManager.getAPI(
      getCountryByOrg + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            locationCode: item.locationCode,
            key: item.id,
            id: item.id,
            value: item.countryCode,
            label: item.countryName,
          };
          list.push(obj);
        });
        setCountry(list);
        getGSCdata(list);
      }
    });
  }

  return (
    <div className="custom-column-70">
      <div className="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
        <svg  onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - Impressions & Clicks
          </h3>
        </div>

        <div class="portlet-body">
          <div class="col-md-12 ">
            <label class="form-label me-2"> Credential Document Upload</label>
            <input
              type="file"
              id="myfile"
              onChange={updateList}
              name="myfile"
              multiple={false}
            />
            <label id="fileLabel">{filelistCustomer?.name}</label>
          </div>

          {isView ? (
            <div>
              <div className="col-md-12 gsc-checkbox mt-3 ">
                <input
                  type="checkbox"
                  checked={checkSearch}
                  onChange={(e) => setcheckSearch(!checkSearch)}
                ></input>
                <label className="form-label me-2 ms-3" for="search">
                  Search Insighsts{" "}
                </label>
              </div>

              <div className="col-md-12 gsc-checkbox my-2">
                <input
                  type="checkbox"
                  checked={checkLinks}
                  onChange={(e) => setcheckLinks(!checkLinks)}
                ></input>
                <label className="form-label me-2 ms-3" for="link">
                  Links and Index
                </label>
              </div>

              <div className="col-md-12 gsc-checkbox">
                <input
                  type="checkbox"
                  checked={Enhancement}
                  onChange={(e) => setEnhancement(!Enhancement)}
                ></input>
                <label className="form-label me-2 ms-3" for="enhance">
                  Enhancement
                </label>
              </div>

              <div class="col-md-12 mt-3">
                <label for="acc" className="form-label me-2">
                  Property
                </label>
                <select
                  name="acc"
                  id="acc"
                  class="w-100"
                  style={{ padding: 8 + "px" }}
                  value={propertySelected}
                  onChange={(e) => {
                    var property = e.target.value;
                    setpropertySelected(property);
                  }}
                >
                  <option value="" selected="selected">
                    Select Property
                  </option>
                  {propertylist.map((item) => {
                    return <option value={item.siteUrl}>{item.siteUrl}</option>;
                  })}
                </select>
              </div>

              <div class="col-md-12 mt-3">
                <label className="form-label me-2">Country</label>
                <ReactSelect
                  className="gsc-multiselect w-100"
                  options={country}
                  isMulti
                  closeMenuOnSelect={false}
                  hideSelectedOptions={false}
                  components={{
                    Option,
                  }}
                  onChange={(selected) => {
                    setSelectedCountry(selected);
                  }}
                  allowSelectAll={true}
                  value={selectedCountry}
                />
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              class="btn btn-primary"
              onClick={() => {
                addUpdateGSCData(filelistCustomer);
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GSCDataExtractor;
