import React, { useEffect } from "react";
import { useState } from "react";
import "antd/dist/antd.css";
import {
  getDashboardConfigurationData,
  getDashboardCount,
  getOrgCustomizeDashboard,
  getUserCustomizeDashboard,
} from "../utility/Constant";
import Chart from "react-apexcharts";
import APIManager from "../services/APIManager";
import { setMonths } from "../utility/Globals";

function Dashboard() {
  const [options, setObject] = useState({
    title: {
      text: "",
      align: "left",
    },

    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      type: "line",
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        "Oct`14",
        "Nov`14",
        "Dec`14",
        "Jan`15",
        "Feb`15",
        "Mar`15",
        "Apr`15",
        "May`15",
      ],
      title: {
        text: "Close Date",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      title: {
        text: "Total Clients",
        style: {
          color: "#000",
        },
      },
      logBase: 20,
      tickAmount: 8,
      min: 0,
    },
  });

  const [series, setSeries] = useState([]);

  const [options1, setObject1] = useState({
    title: {
      text: "",
      align: "left",
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        "OCT2019",
        "NOV",
        "DEC",
        "JAN2020",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
      ],
    },
    yaxis: {
      logBase: 0,
      tickAmount: 9,
      min: 0,
    },
  });

  const [series1, setSeries1] = useState([
    {
      name: "Report(S)",
      data: [35, 65, 30, 33, 45, 10, 25, 55, 80],
      color: "#42a6f5",
    },
  ]);

  useEffect(() => {
    getDashboardCounts();
    getDashboardConfigurationDataList();
    getDashboardConfig();
  }, []);

  function getDashboardConfig() {
    let userId = localStorage.getItem("loggedInUserId");
    let orgId = localStorage.getItem("loggedInOrgId");
    let url = userId
      ? getUserCustomizeDashboard + userId
      : getOrgCustomizeDashboard + orgId;
    APIManager.getAPI(url).then((response) => {
      if (
        response?.data != "No Content Found" &&
        response?.data &&
        response.status == 200
      ) {
      } else {
        APIManager.getAPI(getOrgCustomizeDashboard + orgId).then((orgDb) => {});
      }
    });
  }

  const [counts, setCounts] = useState({});
  function getDashboardCounts() {
    const orgId = localStorage.getItem("organizationId");
    APIManager.getAPI(getDashboardCount + orgId).then((response) => {
      setCounts(response.data);
    });
  }

  function getDashboardConfigurationDataList() {
    const orgId = localStorage.getItem("organizationId");
    APIManager.getAPI(getDashboardConfigurationData + orgId).then(
      (response) => {
        const cList = [];
        const mList = [];
        response.data.map((item) => {
          cList.push(item.clients);
          mList.push(setMonths(item.months));
        });

        setObject({
          title: {
            text: "",
            align: "left",
          },

          chart: {
            id: "basic-bar",
            stacked: true,
          },
          dataLabels: {
            formatter: () => {
              return "";
            },
          },
          xaxis: {
            tickPlacement: "on",
            categories: mList,
            title: {
              text: "Month",
              style: {
                color: "#000",
              },
            },
          },
          yaxis: {
            title: {
              text: "Total Clients",
              style: {
                color: "#000",
              },
            },
            logBase: 20,
            tickAmount: 8,
            min: 0,
          },
        });

        setSeries([
          {
            name: "Client(s)",
            data: cList,
            color: "#42a6f5",
          },
        ]);
      }
    );
  }

  return (
    <>
      <div class="row">
        <div class="header-holder header-holder-desktop mb-4 portlet">
          <div class="header-container container-fluid ps-3">
            <h4 class="header-title">Dashboard</h4>
          </div>
        </div>
      </div>

      <div class="row mb-4 portlet">
        <div className="widget10 widget10-vertical-md">
          <div class="widget10-item">
            <div class="widget10-content">
              <span class="widget10-title">
                {counts?.clientCount ? counts?.clientCount : 0}
              </span>
              <span class="widget10-subtitle d-block ">Clients</span>
            </div>
            <div class="widget10-addon">
              <div class="avatar avatar-label-info avatar-circle widget8-avatar m-0">
                <div class="avatar-display">
                  +{counts?.clientDiff ? counts?.clientDiff : 0}%
                </div>
              </div>
            </div>
          </div>
          <div class="widget10-item">
            <div class="widget10-content">
              <span class="widget10-title ">
                {counts?.projectCount ? counts?.projectCount : 0}
              </span>
              <span class="widget10-subtitle d-block">Projects</span>
            </div>
            <div class="widget10-addon">
              <div class="avatar avatar-label-primary  avatar-circle widget8-avatar m-0">
                <div class="avatar-display">
                  +{counts?.projectDiff ? counts?.projectDiff : 0}%
                </div>
              </div>
            </div>
          </div>
          <div class="widget10-item">
            {" "}
            <div class="widget10-content">
              <span class="widget10-title">
                {counts?.memberCount ? counts?.memberCount : 0}
              </span>
              <span class="widget10-subtitle d-block">Members</span>
            </div>
            <div class="widget10-addon">
              <div class="avatar avatar-label-success avatar-circle widget8-avatar m-0">
                <div class="avatar-display">
                  +{counts?.memberDiff ? counts?.memberDiff : 0}%
                </div>
              </div>
            </div>
          </div>
          <div class="widget10-item">
            {" "}
            <div class="widget10-content">
              <span class="widget10-title">100</span>
              <span class="widget10-subtitle d-block">Reports</span>
            </div>
            <div class="widget10-addon">
              <div class="avatar avatar-label-danger avatar-circle widget8-avatar m-0">
                <div class="avatar-display">+10%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div className="col-md-6 dashboard-client ps-0">
          <div className="portlet">
            <div className="portlet-header portlet-header-bordered">
              <h3 className="portlet-title">Clients</h3>
            </div>
            <div className="portlet-body position-relative">
              <Chart
                options={options}
                series={series}
                type="bar"
                height="380px"
              />
            </div>
          </div>
        </div>
        <div className="col-md-6 dashboard-report pe-0">
          <div className="portlet">
            <div className="portlet-header portlet-header-bordered">
              <h3 className="portlet-title">Reports</h3>
            </div>
            <div className="portlet-body position-relative">
              <Chart
                options={options1}
                series={series1}
                type="line"
                height="380px"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
