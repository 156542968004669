import { Input, Table } from "antd";
import React, { useEffect, useRef, useState } from "react";
import APIManager from "../../services/APIManager";
import { Sorter } from "../../common/Sorter";
import {
  deleteAudit,
  deleteAuditMainModule,
  GET_Server_URL,
  getAuditModuleByOrgId2,
} from "../../utility/Constant";
import { useHistory } from "react-router-dom";
import {
  exportInToCSV,
  exportToEXCEL,
} from "../../utility/Globals";
import AuditModuleAddUpdate from "./AuditModuleAddUpdate";
import AuditInstruction from "./AuditInstruction";
import AuditAddUpdate from "./AuditAddUpdate";
import AuditUpdate from "./AuditUpdate";

const AuditChecklist = ({ update }) => {
  const history = useHistory();
  useEffect(() => {
    var columns = [
      {
        title: "Audit Module",
        dataIndex: "modulename",
        key: "modulename",
        width: "45%",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.modulename, b.modulename),
          multiple: 3,
        },
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        width: "10%",
      },
    ];
    columns = [
      {
        title: "Modules",
        dataIndex: "modulename",
        key: "modulename",
        width: "50%",
      },
    ];
    setAuditCol(columns);
    getAuditList();
  }, []);

  const [AuditCol, setAuditCol] = useState([]);
  const [DataAudit, setDataAudit] = useState([]);
  const [restDataAudit, setrestDataAudit] = useState([]);
  const [searchdataAuditTab, setsearchAuditTab] = useState([]);
  const [exportDataAudit, setExportDataAudit] = useState([]);
  const [settings, setSetting] = useState("auditChecklist");

  const accordion = useRef(null);

  function mappingData(list) {
    return list.reduce(function (acc, curr) {
      var findIfNameExist = acc.findIndex(function (item) {
        return item.name === curr.name;
      });
      if (findIfNameExist === -1) {
        let o = {
          name: curr.pointTitle,
          id: curr.id,
          listItem: curr,
        };
        let obj = {
          name: curr.name,
          title: curr.pointTitle,
          listItem: curr,
          value: [o],
        };
        acc.push(obj);
      } else {
        let o = {
          name: curr.pointTitle,
          id: curr.id,
          listItem: curr,
        };
        acc[findIfNameExist].value.push(o);
      }
      return acc;
    }, []);
  }

  const [AuditImport, setAuditImport] = useState(false);
  function handleAuditImport() {
    setAuditImport(!AuditImport);
  }

  async function deleteAuditItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteAudit + id);
      if (response == 200) {
        getAuditList();
      }
    } catch (e) { }
  }

  async function deleteAuditModule(id) {
    try {
      const response = await APIManager.deleteAPI(deleteAuditMainModule + id);
      if (response == 200) {
        getAuditList();
      }
    } catch (e) { }
  }

  function getAuditList() {
    var id = localStorage.getItem("organizationId");
    APIManager.getAPI(getAuditModuleByOrgId2 + id).then((response) => {
      if (response && response.status == 200) {
        const updatedList = mappingData(response.data);
        const list = [];
        response.data.map((item, index) => {
          let obj = {
            key: item.auditModuleId,
            name1: item.auditModuleName,
            auditChkResponses: item.auditChkResponses,
            modulename: (
              <>
                <div
                  ref={accordion}
                  class={
                    item.auditChkResponses?.length == 0
                      ? "accordion-item d-flex justify-content-between collapsed test-class"
                      : "accordion-item d-flex justify-content-between collapsed"
                  }
                  data-toggle="collapse"
                  data-target={"#accordion1-collapse-" + index}
                  style={{ border: "none" }}
                >
                  <div>{item.auditModuleName}</div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon me-3"
                      onClick={() => {
                        setSetting("addUpdateAuditModule");
                        history.push({
                          state: {
                            isUpdate: true,
                            name: item.auditModuleName,
                            description: item?.description,
                            id: item.auditModuleId,
                          },
                        });
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon me-3"
                      onClick={() => {
                        deleteAuditModule(item.auditModuleId);
                      }}
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>

                    {item.auditChkResponses?.length != 0 ? (
                      <button class="acco-btn">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <button class="acco-btn" style={{ visibility: "hidden" }}>
                        <i class="fa fa-angle-up"></i>
                      </button>
                    )}
                  </div>
                </div>
                {item.auditChkResponses.map((i) => {
                  return (
                    <div id={"accordion1-collapse-" + index} class="collapse">
                      <ul class="audit-titles">
                        <li className="d-flex justify-content-between">
                          {i.pointTitle}
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-edit edit-icon me-3"
                              onClick={() => {
                                setSetting("auditUpdate");
                                history.push({
                                  state: {
                                    listItem: i,
                                    name: item.auditModuleName,
                                  },
                                });
                              }}
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trash-2 trash-icon text-right"
                              onClick={() => {
                                deleteAuditItem(i.id);
                              }}
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </>
            ),
          };
          list.push(obj);
        });
        setDataAudit(list);
        setrestDataAudit(list);
        setsearchAuditTab(list);

        const exportList = [];
        updatedList.map((item, index) => {
          let obj = {
            key: item.id,
            name1: item.name,
            modulename: (
              <>
                <div
                  ref={accordion}
                  class="accordion-item d-flex justify-content-between collapsed"
                  data-toggle="collapse"
                  data-target={"#accordion1-collapse-" + index}
                  style={{ border: "none" }}
                >
                  <div>{item.name}</div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon"
                      onClick={() => {
                        setSetting("addUpdateAuditModule");
                        history.push({
                          state: {
                            isUpdate: true,
                            name: item.name,
                            id: response.data[index].auditModule.id,
                            description:
                              response.data[index].auditDescription.description,
                          },
                        });
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon mx-3"
                      onClick={() => {
                        deleteAuditModule(item.listItem.auditModuleId);
                      }}
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                    {item.auditChkResponses?.length != 0 ? (
                      <button class="acco-btn">
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <button class="acco-btn" style={{ visibility: "hidden" }}>
                        <i class="fa fa-angle-up"></i>
                      </button>
                    )}
                  </div>
                </div>
              </>
            ),
          };
          list.push(obj);
          item.value.map((i) => {
            let exportObj = {
              name: item.name,
              nameAudit: item.nameAudit,
              pointTitle: i.name,
            };
            exportList.push(exportObj);
          });
        });
        setExportDataAudit(exportList);
      }
    });
  }

  function filterAuditChecklist(textData) {
    let tempList = [...searchdataAuditTab];
    let list1 = [];
    setDataAudit([]);
    let list = [];
    tempList.map((item, index) => {
      if (
        item.name1?.toLowerCase().includes(textData.toLowerCase()) ||
        item.auditChkResponses?.some((x) =>
          x.pointTitle?.toLowerCase().includes(textData.toLowerCase())
        )
      ) {
        if (item.name1?.toLowerCase().includes(textData.toLowerCase())) {
          let obj = {
            key: item.key,
            name1: item.name1,
            auditChkResponses: item.auditChkResponses,
            modulename: (
              <>
                <div
                  ref={accordion}
                  class="accordion-item d-flex justify-content-between collapsed"
                  data-toggle="collapse"
                  data-target={"#accordion1-collapse-" + index}
                  style={{ border: "none" }}
                >
                  <div>{item.name1}</div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon"
                      onClick={() => {

                        setSetting("addUpdateAuditModule");
                        history.push({
                          state: {
                            isUpdate: true,
                            name: item?.name1,
                            description: item?.description,
                            id: item?.key,
                          },
                        });
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon mx-3"
                      onClick={() => {
                        deleteAuditModule(item?.key);
                      }}
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>
                    {item.auditChkResponses?.length != 0 ? (
                      <button class="acco-btn">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <button class="acco-btn" style={{ visibility: "hidden" }}>
                        <i class="fa fa-angle-up"></i>
                      </button>
                    )}
                  </div>
                </div>
                {item.auditChkResponses.map((i) => {
                  return (
                    <div id={"accordion1-collapse-" + index} class="collapse">
                      <ul class="audit-titles">
                        <li className="d-flex justify-content-between">
                          {i.pointTitle}
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-edit edit-icon me-3"
                              onClick={() => {
                                setSetting("auditUpdate");
                                history.push({
                                  state: {
                                    listItem: i,
                                    name: item.auditModuleName,
                                  },
                                });
                              }}
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>

                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trash-2 trash-icon text-right"
                              onClick={() => {
                                deleteAuditItem(i.id);
                              }}
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </>
            ),
          };
          list1.push(obj);
        } else if (
          item.auditChkResponses?.some((x) =>
            x.pointTitle.toLowerCase().includes(textData.toLowerCase())
          )
        ) {
          let points = [];
          let filterItem = { ...item };
          filterItem.auditChkResponses?.map((pt) => {
            if (pt.pointTitle.toLowerCase().includes(textData.toLowerCase())) {
              points.push(pt);
            }
          });
          filterItem.auditChkResponses = points?.length
            ? points
            : item.auditChkResponses;
          let obj = {
            key: filterItem.key,
            name1: filterItem.name1,
            auditChkResponses: filterItem.auditChkResponses,
            modulename: (
              <>
                <div
                  ref={accordion}
                  class="accordion-item d-flex justify-content-between collapsed"
                  data-toggle="collapse"
                  data-target={"#accordion1-collapse-" + index}
                  style={{ border: "none" }}
                >
                  <div>{filterItem.name1}</div>
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon me-3"
                      onClick={() => {
                        setSetting("addUpdateAuditModule");
                        history.push({
                          state: {
                            isUpdate: true,
                            name: filterItem.auditModuleName,
                            description: filterItem?.description,
                            id: filterItem.auditModuleId,
                          },
                        });
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-trash-2 trash-icon mx-3"
                      onClick={() => {
                        deleteAuditModule(filterItem.auditModuleId);
                      }}
                    >
                      <polyline points="3 6 5 6 21 6"></polyline>
                      <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                      <line x1="10" y1="11" x2="10" y2="17"></line>
                      <line x1="14" y1="11" x2="14" y2="17"></line>
                    </svg>

                    {filterItem.auditChkResponses?.length != 0 ? (
                      <button class="acco-btn">
                        <i class="fa fa-angle-up" aria-hidden="true"></i>
                        <i class="fa fa-angle-down" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <button class="acco-btn" style={{ visibility: "hidden" }}>
                        <i class="fa fa-angle-up"></i>
                      </button>
                    )}
                  </div>
                </div>
                {filterItem.auditChkResponses.map((i) => {
                  return (
                    <div id={"accordion1-collapse-" + index} class="collapse">
                      <ul class="audit-titles">
                        <li className="d-flex justify-content-between">
                          {i.pointTitle}
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-edit edit-icon  me-3"
                              onClick={() => {
                                setSetting("auditUpdate");
                                history.push({
                                  state: {
                                    listItem: i,
                                    name: filterItem.auditModuleName,
                                  },
                                });
                              }}
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trash-2 trash-icon text-right"
                              onClick={() => {
                                deleteAuditItem(i.id);
                              }}
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </div>
                        </li>
                      </ul>
                    </div>
                  );
                })}
              </>
            ),
          };
          list1.push(obj);
        }
      }

      list.push(item.auditChkResponses);
    });
    setDataAudit(list1);
    if (textData == "" && textData?.length == 0) {
      setDataAudit(searchdataAuditTab);
    }
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
    getAuditList();
  };

  function getComponent() {
    if (settings == "addUpdateAuditModule") {
      return <AuditModuleAddUpdate update={onClickFunction} />;
    } else if (settings == "auditIntroduction") {
      return <AuditInstruction update={onClickFunction} />;
    } else if (settings == "addAuditPoint") {
      return <AuditAddUpdate update={onClickFunction} />;
    } else if (settings == "auditUpdate") {
      return <AuditUpdate update={onClickFunction} />;
    }
  }

  return (
    <>
      <div className="custom-column-70" id="audit">
        {settings === "auditChecklist" ? (
          <div className="portlet slideInUp setting-top">
            <div class="portlet-header portlet-header-bordered">
              <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 class="portlet-title main-title">
                {localStorage.getItem("organizationName")} - Audit Checklist
              </h3>
            </div>
            <div className="portlet-body p-0">
              <div class="common-table">
                <div class="row mt-3 auditchecklist">
                  <div class=" col-md-3 search ">
                    <div class="input-group" style={{ display: "block" }}>
                      <div class="input-group-icon input-group-lg widget15-compact">
                        <div class="input-group-prepend">
                          <i class="fa fa-search text-primary"></i>
                        </div>
                        <Input
                          allowClear
                          type="text"
                          class="form-control"
                          placeholder="Type to search..."
                          onChange={(nameSearch) => {
                            filterAuditChecklist(nameSearch.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-9">
                    <div class="col-md-12 col-sm-12 text-right audit-btn">
                      <button
                        style={{ color: "#fff" }}
                        class="btn btn-primary mx-1 download-temp"
                        onClick={() => {
                          setSetting("auditIntroduction");
                          history.push({});
                        }}
                      >
                        Add Audit Introduction
                      </button>
                      <button
                        style={{ color: "#fff" }}
                        class="btn btn-primary mx-1 download-temp"
                        onClick={() => {
                          setSetting("addUpdateAuditModule");
                          history.push({
                            state: {
                              isUpdate: false,
                            },
                          });
                        }}
                      >
                        Add Audit Module
                      </button>
                      <button
                        style={{ color: "#fff" }}
                        class="btn btn-primary mx-1 download-temp"
                        onClick={() => {
                          setSetting("addAuditPoint");
                          history.push({
                            state: null,
                          });
                        }}
                      >
                        Add Audit Point
                      </button>

                      <a
                        class="btn btn-primary mx-1 download-temp"
                        target="_blank"
                        href={GET_Server_URL + "audit/Audit.xlsx"}
                      >
                        Download Template
                      </a>
                      <button
                        className="btn btn-primary mx-1 import"
                        for="auditImportFile"
                        onClick={() => {
                          handleAuditImport();
                          history.push({
                            pathname: "/main/audit-checklist-import",
                          });
                        }}
                      >
                        Import
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row common-mb-24">
                  <div class={"col-md-12"}>
                    <Table
                      className="table table-bordered table-hover accordian-table auditchecklist-table"
                      id="datatable-4"
                      columns={AuditCol}
                      dataSource={DataAudit}
                      pagination={false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>{getComponent()}</>
        )}
      </div>
    </>
  );
};

export default AuditChecklist;
