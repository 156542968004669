import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Drawer, Table } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import APIManager from "../../services/APIManager";
import { default as ReactSelect } from "react-select";
import {
  deleteTickets,
  getClientByOrgId,
  getfilterallviewtickets,
  getMemberByOrgId,
  pickupticket,
  prioritystatus,
} from "../../utility/Constant";
import {
  DDMMyyyyFormateDateSet,
  etain1,
  mmDDyyyyFormateDateSet,
  ticketpriority,
  ticketpriority1,
  tickettypes,
  tickettypes1,
} from "../../utility/Globals";
import { Link } from "react-router-dom";
import CreateNewTicket from "./CreateNewTicket";

function ViewAllTickets() {
  const history = useHistory();

  const [tabSelected, setTabSelected] = useState("1");
  const [assigndTicketCols, setAssigndTicketCol] = useState([]);
  const [assigndTicketList, setAssigndTicketList] = useState([]);
  const [overdueTicketList, setOverdueTicketList] = useState([]);
  const [approvalTicketList, setApprovalTicketList] = useState([]);
  const [closedTicketCols, setClosedTicketCol] = useState([]);
  const [closedTicketList, setClosedicketList] = useState([]);
  const [availableTicketCols, setAvailableTicketCol] = useState([]);
  const [availableTicketList, setAvailableTicketList] = useState([]);
  const [isFilterModel, setFilterModel] = useState(false);
  const [searchvlue, setsearchvlue] = useState();
  const viewonclose = () => {
    setviewopen(false);
    clearForm();
  };
  const [viewopen, setviewopen] = useState(false);
  const viewshowDrawer = () => {
    setviewopen(true);
  };
  const [createTicketsviewopen, setcreateTicketsviewopen] = useState(false);
  const ticketdrawer = () => {
    setcreateTicketsviewopen(true);
  };
  const ticketviewclose = () => {
    setcreateTicketsviewopen(false);
  };

  const [priority, setpriority] = useState("");
  const [ticketid, setticketid] = useState("");
  const [selectdate, setselectdate] = useState();
  const [selectdatecreated, setselectdatecreated] = useState("");
  const [tickettype, settickettype] = useState("");
  const [EtaInlist, setEtaInlist] = useState("");
  const [clientlist, setclientlist] = useState();
  const [memberlist, setmemberlist] = useState([]);
  const [memberlistSelected, setmemberlistSelected] = useState("");
  const [assignmemberselected, setassignmemberselected] = useState();
  const [clientseleted, setclientseleted] = useState("");
  const [countdata, setcountdata] = useState(0);
  const [isActive, setIsActive] = useState(0);
  const [payload, setpayload] = useState();
  const [selectassignedmember, setselectassignedmember] = useState(null);
  const [availablecounts, setavailablecounts] = useState(0);
  const location = useLocation();
  const location1 = useLocation();
  const location2 = useLocation();

  const customStyles = {
    control: base => ({
      ...base,
      border: "1px solid #212121",
      // This line disable the blue border
      boxShadow: 'none',
      height: 43,
      fontSize: '1.1rem'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121" // Custom colour
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
    })
  };

  const customStylesFilter = {
    control: base => ({
      ...base,
      border: "1px solid #ced4da",
      // This line disable the blue border
      boxShadow: 'none',
      height: 40,
      fontSize: '1.1rem'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '40px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      fill: '505050',
      color: '757575' // Custom colour
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
    })
  };

  useEffect(() => {
    const columns = [
      {
        title: "Projects",
        dataIndex: "projects",
        key: "projects",
        // width: "10%",
      },
      {
        title: "Subjects",
        dataIndex: "subjects",
        key: "subjects",
        // width: "20%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        // width: "10%",
      },
      {
        title: tabSelected == "5" ? "Closed Date" : "ETA",
        dataIndex: "eta",
        key: "eta",
        // width: "10%",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        // width: "10%",
      },
      {
        title: "Assigned To",
        dataIndex: "assignedto",
        key: "assignedto",
        // width: "10%",
      },
      {
        title: "Subtickets",
        dataIndex: "subtickets",
        key: "subtickets",
        // width: "10%",
      },
      {
        title: "Created On",
        dataIndex: "createdon",
        key: "createdon",
        // width: "10%",
      },
      {
        title: "Created By",
        dataIndex: "createdby",
        key: "createdby",
        // width: "10%",
      },
    ];
    const closecolumns = [
      {
        title: "Projects",
        dataIndex: "projects",
        key: "projects",
        // width: "10%",
      },
      {
        title: "Subjects",
        dataIndex: "subjects",
        key: "subjects",
        // width: "20%",
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
        // width: "10%",
      },
      {
        title: "Closed Date",
        dataIndex: "eta",
        key: "eta",
        // width: "10%",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        // width: "10%",
      },
      {
        title: "Assigned To",
        dataIndex: "assignedto",
        key: "assignedto",
        // width: "10%",
      },
      {
        title: "Subtickets",
        dataIndex: "subtickets",
        key: "subtickets",
        // width: "10%",
      },
      {
        title: "Created On",
        dataIndex: "createdon",
        key: "createdon",
        // width: "10%",
      },
      {
        title: "Created By",
        dataIndex: "createdby",
        key: "createdby",
        // width: "10%",
      },
    ];
    setAssigndTicketCol(columns);
    setClosedTicketCol(closecolumns);
    setAvailableTicketCol(columns);
    const l2 = [];
    const l3 = [];
    const l4 = [];
    const l5 = [];
    getmembers();
    getClientList();
    setOverdueTicketList(l2);
    setApprovalTicketList(l3);
    setClosedicketList(l4);
    setAvailableTicketList(l5);
    localStorage.setItem("ticketstatus", "1");
  }, []);

  function handleClick(tickettype) {
    if (isActive != tickettype) {
      setIsActive(tickettype);
      getallticktes(
        {
          ticketstatus: localStorage.getItem("ticketstatus"),
          ViewTicket: tickettype,
          organizationId: localStorage.getItem("organizationId"),
          IsTeam: 1,
          CreatedBy: localStorage.getItem("userId"),
        },
        memberlist
      );
    }
  }
  function Gettickettypename(val) {
    var finalVal = val;
    tickettypes.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }
  function prioritylist(val) {
    var finalVal = val;
    ticketpriority.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }
  function clearForm() {
    setselectdatecreated("");
    setmemberlistSelected("")
    settickettype("");
    setclientseleted("");
    setEtaInlist("");
    setassignmemberselected("");
    setselectdatecreated("");
    setpriority("");
    setsearchvlue("");
  }

  function getallticktes(data, memberlist) {
    const d = data;
    setpayload(data);
    setAssigndTicketList([]);
    setcountdata(0);
    setavailablecounts(0);
    APIManager.postAPI(getfilterallviewtickets, d, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const list1 = [];
          let a = 0;
          response.data.table.map((item) => {

            let obj1 = {
              countdata: (a += 1),
              projects: <th scope="row">{item.projectCode}</th>,
              subjects: (
                <>
                  <Link
                    to={{
                      pathname: "/ticket/ticket-view",
                      state: { item: item, viewtickets: { IsTeam: 1 } },
                    }}
                  >
                    {item.subject}
                  </Link>
                  <span className="d-block assign-t-span">
                    {item.description}
                  </span>
                </>
              ),
              type: (
                <span
                  class={`type-${+item.ticketTypeId > 0 ? "other" : "universal"
                    }`}
                >
                  {Gettickettypename(item.ticketTypeId)}
                </span>
              ),
              eta: (
                <a
                  class={`eta-text ${DDMMyyyyFormateDateSet(item.eta) &&
                    localStorage.getItem("ticketstatus") == 2
                    ? "overdue-date"
                    : localStorage.getItem("ticketstatus") == 5
                      ? "closed-date"
                      : "normal-date"
                    }`}
                >
                  {DDMMyyyyFormateDateSet(
                    localStorage.getItem("ticketstatus") == "5" ? item.closedOn : item.eta
                  )}
                </a>
              ),
              priority: (
                <select
                  class={`form-select ${prioritylist(
                    item.priorityId
                  ).toLowerCase()}-selected`}
                  aria-label="Default select example"
                  value={item.priorityId}
                  onChange={(i) => {
                    item.priorityId = i.target.value;
                    setpriority(i.target.value);
                    setticketid(item.id);
                    let getdata = {
                      ticketid: item.id,
                      priorityList: i.target.value,
                    };
                    prooritystatuschange(getdata);
                  }}
                >
                  {ticketpriority?.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>
              ),
              assignedto: (
                <ReactSelect
                  options={memberlist}
                  isMulti={false}
                  styles={customStyles}
                  components={{
                    IndicatorSeparator: () => null
                  }}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  allowSelectAll={true}
                  value={memberlist.map(x => {
                    if (x.id == item.assignedMemberId) {
                      return x;
                    }
                  })}
                  onChange={(b) => {
                    item.assignedMemberId = b.value;
                    setselectassignedmember(b.value);
                    let data = {
                      TicketId: item.id,
                      MemberId: b.value,
                      AssignedOn: mmDDyyyyFormateDateSet(new Date()),
                      Status: 1,
                      CreatedBy: localStorage.getItem("userId"),
                    };
                    AssignTickets(data);
                  }}
                >
                </ReactSelect>
              ),
              createdon: (
                <span class="fw-bold">
                  {DDMMyyyyFormateDateSet(item.createdOn)}
                </span>
              ),
              createdby: (<div class="d-flex justify-content-between align-items-center">
                <span class="fw-bold">{item.createdMember}</span>
                <div>
                  <span class="dropdown">
                    {item.createdBy == localStorage.getItem("userId") ||
                      item.assignedMemberId == localStorage.getItem("userId") ? (
                      <button
                        type="kabeb button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                      </button>
                    ) : (
                      <></>
                    )}
                    <div
                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                      aria-labelledby="dropdownMenuButton"
                    >
                      {item.createdBy == localStorage.getItem("userId") ||
                        item.assignedMemberId ==
                        localStorage.getItem("userId") ? (
                        <div
                          onClick={() => {
                            history.push({
                              pathname: "/ticket/create-ticket",
                              state: {
                                id: item.id,
                                isUpdate: true,
                                ClientId: item.clientId,
                                projectId: item.projectId,
                                Subject: item.subject,
                                Description: item.description,
                                TicketTypeId: item.ticketTypeId,
                                ETA: item.eta,
                                PriorityId: item.priorityId,
                                DepartmentId: item.departmentId,
                                AssignedTo: item.assignedMemberId,
                                status: item.status,
                                createdby: item.createdBy,
                              },
                            });
                          }}
                        >
                          <a class="dropdown-item d-flex align-items-center dot-drop-color edit">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-edit"
                            >
                              <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                              <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                            </svg>
                            <span className="ms-3">Edit</span>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item.createdBy == localStorage.getItem("userId") ? (
                        <div
                          onClick={() => {
                            daleteticktes(item.id);
                          }}
                        >
                          <a class="dropdown-item d-flex align-items-center dot-drop-color delete">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-trash-2"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                            <span className="ms-3">delete</span>
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </span>
                </div>
              </div>),
              subtickets: (
                <div class="d-flex justify-content-center align-items-center">
                  <div class="number">
                    <img class="ticket-icon" src="/images/ticket.png" />
                    <span class="fw-bold ms-2">{item.subTicketTotal}</span>
                  </div>
                </div>
              ),
            };
            list1.push(obj1);
            list1.map((Available) => { });
            setcountdata(response.data.table.length);
            setavailablecounts(response.data.table1[0].availableCount);
          });
          setAssigndTicketList(list1);
          setAvailableTicketList();
        }
      }
    );
  }

  function getClientList() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "",
          name: "Select",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setclientlist(list);
        setclientseleted(list[0].id);
      }
    });
  }

  function getmembers() {
    APIManager.getAPI(
      getMemberByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "",
          name: "Select",
          key: "",
          value: "",
          label: "Select",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
            key: item.id,
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
        setmemberlistSelected(list[0].id);
        setassignmemberselected(list[0].id);
        getallticktes(
          {
            ticketstatus: localStorage.getItem("ticketstatus"),
            ViewTicket: isActive ?? 0,
            organizationId: localStorage.getItem("organizationId"),
            IsTeam: 1,
            CreatedBy: localStorage.getItem("userId"),
          },
          list
        );
      }
    });
  }

  function AssignTickets(getdata) {
    APIManager.postAPI(pickupticket, getdata, null, true).then((response) => {
      if (response && response.status == 200) {
        let payload = {
          ticketstatus: 1,
          ViewTicket: isActive,
          organizationId: localStorage.getItem("organizationId"),
          CreatedBy: localStorage.getItem("userId"),
        };
        getmembers();
      }
    });
  }

  function prooritystatuschange(getdata) {
    APIManager.putAPI(
      prioritystatus +
      "ticketId=" +
      getdata.ticketid +
      "&memberId=" +
      localStorage.getItem("userId") +
      "&priorityList=" +
      getdata.priorityList,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        let payload = {
          ticketstatus: tabSelected,
          ViewTicket: isActive,
          organizationId: localStorage.getItem("organizationId"),
          CreatedBy: localStorage.getItem("userId"),
        };
        getmembers();
      }
    });
  }

  async function daleteticktes(id) {
    try {
      const response = await APIManager.deleteAPI(deleteTickets + id);
      if (response == 200) {
        getmembers();
      }
    } catch (e) { }
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      let getdata = {
        searchText: searchvlue,
        organizationId: localStorage.getItem("organizationId"),
        IsTeam: 1,
        CreatedBy: localStorage.getItem("userId"),
        ticketStatus: tabSelected
      };
      getallticktes(getdata, memberlist);
      clearForm();
    }
  }
  return (
    <>
      <div class="row">
        <div class="col-sm-12 p-0">
          <div class="top-title d-flex ticket-bredcrumb-top">
            <ul>
              <li>
                <h6 class="top-tickets mb-0 text-capitalize ticket-dashboard">tickets</h6>
              </li>
              <li className="mx-4"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
              <li>
                {" "}
                <h6 class="ticket-dashboard mb-0 text-capitalize active">
                  view tickets
                </h6>
              </li>
            </ul>
          </div>

          <div class="all-ticket-box">
            <div class="d-flex align-items-center justify-content-between">
              <div class="px-0 allticket-search">
                <form class="search-box w-100 d-flex active">
                  <button
                    type="button"
                    onClick={() => {
                      let getdata = {
                        searchText: searchvlue,
                        organizationId:
                          localStorage.getItem("organizationId"),
                        IsTeam: 1,
                        CreatedBy: localStorage.getItem("userId"),
                        ticketStatus: tabSelected
                      };
                      getallticktes(getdata, memberlist);
                      clearForm();
                    }}
                  >
                    <i class="fa fa-search"></i>
                  </button>
                  <input
                    value={searchvlue}
                    type="text"
                    class="w-100"
                    placeholder="Search tickets"
                    name="search"
                    onKeyDown={(e) => {
                      _handleKeyDown(e);
                    }}
                    onChange={(item) => {
                      setsearchvlue(item.target.value);
                    }}
                  />
                </form>
              </div>
              <div class="px-0 filter-create-btn">
                <div class="d-flex justify-content-between align-tems-center">
                  <div
                    class="filter ms-3 w-50"
                    onClick={() => {
                      setFilterModel(true);
                    }}
                  >
                    <div
                      class="btn btn-filter d-flex align-items-center"
                      onClick={viewshowDrawer}
                    >
                      {" "}
                      <img
                        src="/images/vector.png"
                      />
                      <span class="ms-2 ps-1">filters</span>{" "}
                    </div>
                  </div>
                  <div
                    class="create-btn ms-3 w-100"
                    onClick={() => {
                      ticketdrawer(true);
                    }}
                  >
                    <div class="btn btn-primary d-block">
                      <div class="d-flex align-items-center">
                        <span class="plus">+ </span>
                        <span class="ms-2 ps-1">create ticket</span>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="all-ticket-bread-crumb p-0 view-breadcrum">
            <ul class="d-flex px-0">
              <li
                class={` ${isActive == 0 ? "active" : ""}`}
                onClick={() => {
                  handleClick(0);
                }}
              >
                <span>
                  {isActive == 0 ? (
                    <i class="fa fa-check me-2" aria-hidden="true"></i>
                  ) : (
                    <></>
                  )}
                  all tickets
                </span>
              </li>
              <li
                class={`mx-3 ${isActive == 1 ? "active" : ""}`}
                onClick={() => {
                  handleClick(1);
                }}
              >
                <span>
                  {isActive == 1 ? (
                    <i class="fa fa-check me-2" aria-hidden="true"></i>
                  ) : (
                    <></>
                  )}
                  client tickets
                </span>
              </li>
              <li
                class={` ${isActive == 2 ? "active" : ""}`}
                onClick={() => {
                  handleClick(2);
                }}
              >
                <span>
                  {isActive == 2 ? (
                    <i class="fa fa-check me-2" aria-hidden="true"></i>
                  ) : (
                    <></>
                  )}
                  internal tickets
                </span>
              </li>
            </ul>
          </div>

          <div class="all-ticket-table-box mt-3 minus-margin">
            <div class="title mb-4">
              <h4 class="fw-bold text-capitalize d-flex mt-5">
                <span>
                  {isActive == 0 ? (
                    <>
                      {" "}
                      {tabSelected == 1 ? (<>
                        <span>Team tickets</span></>)
                        : (<></>)}
                      {tabSelected == 2 ? (<>
                        <span>Overdue Tickets</span></>)
                        : (<></>)}
                      {tabSelected == 4 ? (<>
                        <span>Awaiting Approval Tickets</span></>)
                        : (<></>)}
                      {tabSelected == 5 ? (<>
                        <span>Closed Tickets</span></>)
                        : (<></>)}
                      <span class="ticket-count"> ({countdata})</span>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span>
                  {isActive == 1 ? (
                    <>
                      {" "}
                      {tabSelected == 1 ? (<>
                        <span>Team tickets</span></>)
                        : (<></>)}
                      {tabSelected == 2 ? (<>
                        <span>Overdue Tickets</span></>)
                        : (<></>)}
                      {tabSelected == 4 ? (<>
                        <span>Awaiting Approval Tickets</span></>)
                        : (<></>)}
                      {tabSelected == 5 ? (<>
                        <span>Closed Tickets</span></>)
                        : (<></>)}
                      <span class="ticket-count"> ({countdata})</span>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
                <span>
                  {isActive == 2 ? (
                    <>
                      {" "}
                      {tabSelected == 1 ? (<>
                        <span>Team tickets</span></>)
                        : (<></>)}
                      {tabSelected == 2 ? (<>
                        <span>Overdue Tickets</span></>)
                        : (<></>)}
                      {tabSelected == 4 ? (<>
                        <span>Awaiting Approval Tickets</span></>)
                        : (<></>)}
                      {tabSelected == 5 ? (<>
                        <span>Closed Tickets</span></>)
                        : (<></>)}
                      <span class="ticket-count"> ({countdata})</span>
                    </>
                  ) : (
                    <></>
                  )}
                </span>
              </h4>
              <span>
                {tabSelected == 0 ? (
                  <>
                    {" "}
                    <span class="available-ticket text-capitalize mt-2">
                      All Available Tickets are listed below
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {tabSelected == 1 ? (
                  <>
                    {" "}
                    <span class="available-ticket text-capitalize mt-2">
                      All Assigned Tickets are listed below
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {tabSelected == 2 ? (
                  <>
                    {" "}
                    <span class="available-ticket text-capitalize mt-2">
                      All Overdue Tickets are listed below
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {tabSelected == 4 ? (
                  <>
                    {" "}
                    <span class="available-ticket text-capitalize mt-2">
                      All Awaiting Approval are listed below
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {tabSelected == 5 ? (
                  <>
                    {" "}
                    <span class="available-ticket text-capitalize mt-2">
                      All Closed Tickets are listed below
                    </span>
                  </>
                ) : (
                  <></>
                )}
              </span>
            </div>

            <Tabs>
              <div className="portlet py-3 mb-0">
                <div className="ticketlist col-md-12 col-sm-12  ">
                  <TabList className="nav nav-lines portlet-nav">
                    <Tab
                      className={
                        tabSelected == "1"
                          ? "nav-item nav-link  active"
                          : "nav-item nav-link"
                      }
                      onClick={() => {
                        setTabSelected("1");
                        if (tabSelected != 1) {
                          localStorage.setItem("ticketstatus", "1");
                          getallticktes(
                            {
                              ticketstatus: 1,
                              ViewTicket: isActive,
                              organizationId:
                                localStorage.getItem("organizationId"),
                              IsTeam: 1,
                              CreatedBy: localStorage.getItem("userId"),
                            },
                            memberlist
                          );
                        }
                      }}
                    >
                      Team Tickets
                    </Tab>
                    <Tab
                      className={
                        tabSelected == "2"
                          ? "nav-item nav-link  active"
                          : "nav-item nav-link"
                      }
                      onClick={() => {
                        setTabSelected("2");
                        if (tabSelected != 2) {
                          localStorage.setItem("ticketstatus", "2");
                          getallticktes(
                            {
                              ticketstatus: 2,
                              ViewTicket: isActive,
                              organizationId:
                                localStorage.getItem("organizationId"),
                              IsTeam: 1,
                              CreatedBy: localStorage.getItem("userId"),
                            },
                            memberlist
                          );
                        }
                      }}
                    >
                      Overdue Tickets
                    </Tab>
                    <Tab
                      className={
                        tabSelected == "4"
                          ? "nav-item nav-link  active"
                          : "nav-item nav-link"
                      }
                      onClick={() => {
                        setTabSelected("4");
                        if (tabSelected != 4) {
                          localStorage.setItem("ticketstatus", "4");
                          getallticktes(
                            {
                              ticketstatus: 4,
                              ViewTicket: isActive,
                              organizationId:
                                localStorage.getItem("organizationId"),
                              IsTeam: 1,
                              CreatedBy: localStorage.getItem("userId"),
                            },
                            memberlist
                          );
                        }
                      }}
                    >
                      Awaiting Approval
                    </Tab>
                    <Tab
                      className={
                        tabSelected == "5"
                          ? "nav-item nav-link  active"
                          : "nav-item nav-link"
                      }
                      onClick={() => {
                        setTabSelected("5");
                        if (tabSelected != 5) {
                          localStorage.setItem("ticketstatus", "5");
                          getallticktes(
                            {
                              ticketstatus: 5,
                              ViewTicket: isActive,
                              organizationId:
                                localStorage.getItem("organizationId"),
                              IsTeam: 1,
                              CreatedBy: localStorage.getItem("userId"),
                            },
                            memberlist
                          );
                        }
                      }}
                    >
                      Closed Tickets
                    </Tab>
                  </TabList>
                </div>
              </div>
              <TabPanel>
                <div className="row ">
                  <div className="col-md-12 text-start align-items-center px-0">
                    <label
                      style={{
                        fontSize: 14 + "px",
                        fontWeight: 500,
                      }}
                    >
                    </label>
                    <Table
                      className="portlet table table-hover ticket-table view-all-tickets"
                      columns={assigndTicketCols}
                      dataSource={assigndTicketList}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <div className="col-md-12 text-start px-0 ">
                    <label
                      style={{
                        fontSize: 14 + "px",
                        fontWeight: 500,
                      }}
                    >
                    </label>
                    <Table
                      className="portlet table table-hover ticket-table view-all-tickets"
                      columns={assigndTicketCols}
                      dataSource={assigndTicketList}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <div className="col-md-12 text-start align-items-center px-0">
                    <label
                      style={{
                        fontSize: 14 + "px",
                        fontWeight: 500,
                      }}
                    >
                    </label>
                    <Table
                      className="portlet table table-hover ticket-table view-all-tickets"
                      columns={assigndTicketCols}
                      dataSource={assigndTicketList}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <div className="col-md-12 text-start align-items-center px-0">
                    <label
                      style={{
                        fontSize: 14 + "px",
                        fontWeight: 500,
                      }}
                    >
                    </label>
                    <Table
                      className="portlet table table-hover ticket-table view-all-tickets"
                      columns={closedTicketCols}
                      dataSource={assigndTicketList}
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div>
                  <div className="col-md-12 text-start align-items-center px-0">
                    <label
                      style={{
                        fontSize: 14 + "px",
                        fontWeight: 500,
                      }}
                    >
                    </label>
                    <Table
                      className="portlet table table-hover ticket-table view-all-tickets"
                      columns={assigndTicketCols}
                      dataSource={assigndTicketList}
                    />
                  </div>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>

      <div className="portlet">
        <Drawer
          title={<>
            <div class="d-flex align-items-center">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-filter"><polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon></svg>
              <span className="ms-2">Filter</span>
            </div>
          </>}
          className="search-team-member-modal filter-closed filter-modal"
          placement="right"
          onClose={viewonclose}
          open={viewopen}
        >
          <div class="container">
            <div class="portlet-body pt-0 p-0">
              <form class="ticket-filter-form">
                <div class="col-md-12 mt-3 ">
                  <label for="Target" class="form-label mb-2">
                    created on
                  </label>
                  <input
                    class="w-100 date-input"
                    type="date"
                    id="date"
                    name="date"
                    value={selectdatecreated}
                    onChange={(item) => {
                      setselectdatecreated(item.target.value);
                    }}
                  />
                </div>

                <div class="col-md-12 mt-3">
                  <div class="form-group">
                    <label for="Target" class="form-label mb-2">
                      closed on
                    </label>
                    <input
                      class="w-100 date-input"
                      type="date"
                      id="date"
                      name="date"
                      value={selectdate}
                      onChange={(item) => {
                        setselectdate(item.target.value);
                      }}
                    />
                  </div>
                </div>
                <div class="col-md-12 mt-3">
                  <label for="exampleInputcode1" class="form-label">
                    priority
                  </label>
                  <select
                    class="w-100 form-select"
                    aria-label="Default select example"
                    value={priority}
                    onChange={(item) => {
                      if (item.target.value == "Select") {
                        setpriority("");
                      } else {
                        setpriority(item.target.value);
                      }
                    }}
                  >
                    {ticketpriority1?.map((item) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                  </select>
                </div>

                <div class="col-md-12 mt-3">
                  <label for="exampleInputcode1" class="form-label">
                    assigned to
                  </label>
                  <ReactSelect
                    isMulti={false}
                    styles={customStylesFilter}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    options={memberlist}
                    value={memberlist?.map(x => {
                      if (x.id == assignmemberselected) {
                        return x;
                      }
                    })}
                    onChange={(item) => {
                      setassignmemberselected(item.value);
                    }}
                  >
                  </ReactSelect>
                </div>

                <div class="col-md-12 mt-3">
                  <label for="exampleInputcode1" class="form-label">
                    ETA
                  </label>
                  <select
                    class="w-100 form-select"
                    aria-label="Default select example"
                    value={EtaInlist}
                    onChange={(item) => {
                      setEtaInlist(item.target.value);
                    }}
                  >
                    {etain1?.map((item) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                  </select>
                </div>

                <div class="col-md-12 mt-3">
                  <label for="exampleInputcode1" class="form-label">
                    client
                  </label>
                  <select
                    class="w-100 form-select"
                    aria-label="Default select example"
                    value={clientseleted}
                    onChange={(item) => {
                      if (item.target.value == "Select") {
                        setclientseleted("");
                      } else {
                        setclientseleted(item.target.value);
                      }
                    }}
                  >
                    {clientlist?.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                </div>

                <div class="col-md-12 mt-3">
                  <label for="exampleInputcode1" class="form-label">
                    ticket type
                  </label>
                  <select
                    class="w-100 form-select"
                    aria-label="Default select example"
                    value={tickettype}
                    onChange={(e) => {
                      if (e.target.value == "Select") {
                        settickettype("");
                      } else {
                        settickettype(e.target.value);
                      }
                    }}
                  >
                    {tickettypes1.map((i) => {
                      return <option value={i.value}>{i.label}</option>;
                    })}
                  </select>
                </div>

                <div
                  class="col-md-12 mt-3"
                  onClick={() => {
                  }}
                ></div>
              </form>
            </div>
            <div class="portlet-footer portlet-footer-bordered mt-3">
              <div
                class="col-md-12 p-0"
                onClick={() => {
                }}
              >
                <div class=" d-flex align-items-center justify-content-center">
                  <button
                    onClick={() => {
                      let getdata = {
                        createdOn: selectdatecreated,
                        closedOn: selectdate,
                        priority: priority,
                        assignedTo:
                          assignmemberselected == "Select"
                            ? ""
                            : assignmemberselected,
                        etaIn: EtaInlist == "Select" ? "" : EtaInlist,
                        clientId: clientseleted,
                        ticketType: tickettype == "Select" ? "" : tickettype,
                        organizationId: localStorage.getItem("organizationId"),
                        IsTeam: 1,
                        CreatedBy: localStorage.getItem("userId"),
                        ticketStatus: tabSelected
                      };
                      getallticktes(getdata, memberlist);
                      clearForm();
                      setviewopen(false);
                    }}
                    className="btn btn-primary w-100 "
                  >
                    <i class="fa fa-check me-3" aria-hidden="true"></i>
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
      <Drawer
        className="search-team-member-modal filter-closed create-ticket-modal"
        placement="right"
        onClose={ticketviewclose}
        open={createTicketsviewopen}
      >
        <CreateNewTicket
          isModel={true}
          closeModel={() => {
            ticketviewclose();
          }}
        />
      </Drawer>
    </>
  );
}

export default ViewAllTickets;
