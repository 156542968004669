import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  addOrgCutomizeDashboard,
  addUserCutomizeDashboard,
  getModule,
  getOrgCustomizeDashboard,
  getUserCustomizeDashboard,
} from "../../utility/Constant";
import { useLocation } from "react-router-dom";

const CustomizeDashboard = ({ update }) => {
  const location = useLocation();
  var userId = "";
  useEffect(() => {
    userId = location?.state ? location?.state?.userId : "";
    setuser(location?.state ? location?.state?.userId : "");
    getModules();
  }, [location]);

  const [modules, setModules] = useState([]);
  const [user, setuser] = useState([]);
  const [themeName, setThemeName] = useState("");
  const [submit, setSubmit] = useState(false);
  const [contentArray, setContentArray] = useState([
    { ChartType: "bar", ModuleId: "" },
    { ChartType: "bar", ModuleId: "" },
  ]);

  function getModules() {
    APIManager.getAPI(getModule).then((response) => {
      if (response && response.status == 200) {
        setModules(response?.data);
        let list = [...contentArray];
        list.map((item) => {
          item.ModuleId = response?.data[0]?.id;
        });
        getDashboardConfig();
      }
    });
  }

  function getDashboardConfig() {
    let url = userId
      ? getUserCustomizeDashboard + userId
      : getOrgCustomizeDashboard + localStorage.getItem("organizationId");
    APIManager.getAPI(url).then((response) => {
      setThemeName(response?.data.themeName);
      let dataList = [];
      if (userId) {
        response.data.userCustomizeDashboardCharts.map((i) => {
          dataList.push({
            ModuleId: i.moduleId,
            ChartType: i.chartType,
          });
        });
      } else {
        response.data.customizeDashboardCharts.map((i) => {
          dataList.push({
            ModuleId: i.moduleId,
            ChartType: i.chartType,
          });
        });
      }
      if (dataList.length > 0) {
        setContentArray(dataList);
      }
    });
  }

  function addContent() {
    var tempArray = JSON.parse(JSON.stringify(contentArray));
    tempArray.push({
      ChartType: "bar",
      ModuleId: modules[0]?.id,
    });
    setContentArray(tempArray);
  }

  function removeContent(i) {
    let list = [...contentArray];
    if (list?.length > 2) {
      list.splice(i, 1);
      setContentArray(list);
    }
  }

  function clearForm() {
    location?.state ? update("userdashboard") : update("");
  }

  function addDashboardConfig() {
    userId = user;
    setSubmit(true);
    if (!themeName?.length) {
      return;
    } else {
      let rowData = user
        ? {
            ThemeName: themeName.trim(),
            UserId: user,
            UserCustomizeDashboardCharts: contentArray,
          }
        : {
            ThemeName: themeName.trim(),
            OrganizationId: localStorage.getItem("organizationId"),
            customizeDashboardCharts: contentArray,
          };
      APIManager.postAPI(
        user ? addUserCutomizeDashboard : addOrgCutomizeDashboard,
        rowData,
        {},
        true
      ).then((res) => {
        location?.state ? update("userdashboard") : update("");
      });
    }
  }

  return (
    <>
      <div className="config-dashboard">
        <div className="portlet slideInUp setting-top">
          <div class="portlet-header portlet-header-bordered">
          <svg  onClick={() => (location?.state ? update("userReset") : update(""))} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("organizationName")} - Customize Dashboard
            </h3>
          </div>
          <div className="portlet-body">
            <div className="my-3 mt-3 customize-themename">
              <label className="form-label">Theme Name{submit}</label>
              <label class="field-required me-3"> * </label>
              <input
                className="customize-name"
                type="text"
                placeholder="Enter Theme Name"
                value={themeName}
                onChange={(text) => {
                  setSubmit(false);
                  setThemeName(text.target.value);
                }}
                style={{ width: 16 + "%", borderRadius: 0 }}
              />
              {submit && !themeName?.length && (
                <>
                  <div className="field-required">
                    <span>Theme Name is required</span>
                  </div>
                </>
              )}
            </div>
            <div className="my-3 mt-4 mb-3">
              <div className="row mt-4">
                <div className="col-md-3 col-sm-12">
                  <div className="row">
                    <div className="col-md-12 ps-0">
                      <label>Layout</label>
                    </div>
                    <div className="col-lg-12 border border-3 p-4 mt-3">
                      <div className="row">
                        {contentArray.map((item, i) => {
                          return (
                            <>
                              <div className="col-6 d-flex my-2 justify-content-center">
                                <div
                                  className="layout-col"
                                  style={{ color: "#222" }}
                                >
                                  {i + 1}
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className=" col-md-9 col-sm-12 my-3">
                  <table class="dash-table table-modules text-center table table-bordered  table-hover table-responsive">
                    <thead class="table-modules-head">
                      <tr>
                        <th
                          classname="left-right-border"
                          style={{ width: 10 + "%" }}
                        >
                          Placeholder
                        </th>
                        <th>Chart</th>
                        <th>Module</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {contentArray.map((item, i) => {
                        return (
                          <tr key={i + 1}>
                            <td classname="left-right-border">{i + 1}</td>
                            <td>
                              <select className="form-select"
                                style={{ paddingRight: 30 + "%" }}
                                value={item.ChartType}
                                onChange={(e) => {
                                  let list = [...contentArray];
                                  list[i].ChartType = e.target.value;
                                  setContentArray(list);
                                }}
                              >
                                <option value="bar">bar chart</option>
                                <option value="line">line chart</option>
                              </select>
                            </td>
                            <td>
                              <select className="form-select"
                                style={{ paddingRight: 30 + "%" }}
                                value={item.ModuleId}
                                onChange={(e) => {
                                  let list = [...contentArray];
                                  list[i].ModuleId = e.target.value;
                                  setContentArray(list);
                                }}
                              >
                                {modules.map((item) => {
                                  return (
                                    <option value={item.id}>
                                      {" "}
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                            <td>
                              {i == contentArray?.length - 1 ? (
                                <>
                                  <button onClick={addContent}>
                                    <i
                                      class="fa fa-plus-square"
                                      aria-hidden="true"
                                    ></i>
                                  </button>{" "}
                                </>
                              ) : (
                                <>
                                  <button
                                    disabled={contentArray?.length <= 2}
                                    onClick={() => removeContent(i)}
                                  >
                                    <i
                                      style={
                                        contentArray?.length <= 2
                                          ? { cursor: "not-allowed" }
                                          : {}
                                      }
                                      class="fa fa-minus-square"
                                      aria-hidden="true"
                                    ></i>
                                  </button>{" "}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="portlet-footer portlet-footer-bordered">
            <div className="col-md-12 d-flex">
              <button
                className="btn btn-primary me-3"
                onClick={() => {
                  addDashboardConfig();
                }}
              >
                Save
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  clearForm();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomizeDashboard;
