import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  createSiteUpTimeMonitor,
  getUrlByOrgId,
  updateSiteUpTimeMonitor,
  createUrl,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { SiteUpTimeTypes, themeStyle } from "../../utility/Globals";
import { default as ReactSelect } from "react-select";
import { useLocation } from "react-router-dom";

function SiteUpTimeMonitor1({ update }) {
  const [url, setUrl] = useState("");
  const [reuslt1, setResult1] = useState("");
  const [reuslt2, setResult2] = useState("");
  const [reuslt3, setResult3] = useState("");
  const [reuslt4, setResult4] = useState("");
  const [reuslt5, setResult5] = useState("");
  const [urlList, setURlList] = useState([]);
  const [selectedType, setType] = useState("0");
  const [message, setMessage] = useState("");
  const [message2, setMessage2] = useState("");
  const [siteUpTimeConfigurationId, setSiteUpTimeConfigurationId] = useState(
    []
  );
  const location = useLocation();
  const history = useHistory();
  const [contentArray, setContentArray] = useState([{ expectedresult1: "" }]);
  const [IsActive, SetIsActive] = useState(false);
  const [urlName, setURLName] = useState("");

  useEffect(() => {
    getUrlList(localStorage.getItem("projectGId"));
    if (location.state.isEdit) {
      setType(location.state.item.Type);
      setUrl(location.state.item.URL);
      setResult1(location.state.item.result1);
      setResult2(location.state.item.result2);
      setResult3(location.state.item.result3);
      setResult4(location.state.item.result4);
      setResult5(location.state.item.result5);
      setSiteUpTimeConfigurationId(
        location.state.item.siteUpTimeConfigurationId
      );

      let arr = [];
      arr.push({ expectedresult1: location.state.item.result1 });
      if (location.state.item.result2) {
        arr.push({ expectedresult1: location.state.item.result2 });
      }
      if (location.state.item.result3) {
        arr.push({ expectedresult1: location.state.item.result3 });
      }
      if (location.state.item.result4) {
        arr.push({ expectedresult1: location.state.item.result4 });
      }
      if (location.state.item.result5) {
        arr.push({ expectedresult1: location.state.item.result5 });
      }
      setContentArray(arr);
    }
  }, []);

  function clearForm() {
    setType("");
    setUrl("");
    setResult1("");
    setResult2("");
    setResult3("");
    setResult4("");
    setResult5("");
    update("siteuptimeMonitor");
  }

  function addContent() {
    var tempArray = JSON.parse(JSON.stringify(contentArray));
    tempArray.push({
      expectedresult1: "",
    });
    setContentArray(tempArray);
  }

  function removeContent(i) {
    let list = [...contentArray];
    list.splice(i, 1);
    setContentArray(list);
  }

  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setURLName("");
        getUrlList(localStorage.getItem("projectGId"));
        SetIsActive(false);
      }
    });
  }

  function getUrlList(projectIdd) {
    APIManager.getAPI(getUrlByOrgId + projectIdd).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        let c = 1;
        response.data.map((item) => {
          let obj = {
            count: c,
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            url: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => { }}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          };
          c++;
          list.push(obj);
        });
        setURlList(list);
      }
    });
  }

  function addSiteUpTime(rowdata) {
    APIManager.postAPI(createSiteUpTimeMonitor, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          let count = 1;
          response?.data?.siteUptimeConfigurationMappings?.map((item) => {
            let obj = {
              type: item.type,
              url: item.url,
              result1: item.result1,
              result2: item.result2,
              result3: item.result3,
              result4: item.result4,
              result5: item.result5,
            };
            list.push(obj);
            count++;
          });
        }
        clearForm();
      }
    );
  }

  function UpdateSite(item) {
    APIManager.putAPI(
      updateSiteUpTimeMonitor,
      {
        id: item.id,
        type: item.type,
        url: item.url,
        result1: item.result1,
        result2: item.result2,
        result3: item.result3,
        result4: item.result4,
        result5: item.result5,
        siteUpTimeConfigurationId: item.siteUpTimeConfigurationId,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearForm();
      }
    });
  }

  function formValidation() {
    let isValidFields = true;
    if (url == "") {
      setMessage("Url is required!");
      isValidFields = false;
    } else {
      setMessage("");
    }

    if (contentArray[0].expectedresult1 == "") {
      setMessage2("Expected Result is required");
      isValidFields = false;
    } else {
      setMessage2("");
    }

    return isValidFields;
  }

  return (
    <div className="custom-column-70">
      <div className="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => {
            update("siteuptimeMonitor");
          }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} -{" "}
            {location.state.isEdit ? "Update" : "Add"} Site Uptime Metric
          </h3>
        </div>
        <div className="portlet-body">
          <div class="common-form-fields">
            <div className="col-md-12">
              <label className="form-label">Type</label>
              <select
                className="w-100"
                value={selectedType}
                onChange={(e) => {
                  setType(e.target.value);
                }}
              >
                {SiteUpTimeTypes.map((item) => {
                  return <option value={item.value}>{item.label}</option>;
                })}
              </select>
            </div>

            <div className="col-md-12 mt-3">
              <label className="form-label">URL</label>
              <label class="field-required"> * </label>
              <div className="d-flex justify-content-between mb-3">
                <ReactSelect
                  styles={themeStyle()}
                  className="w-200"
                  options={urlList}
                  isMulti={false}
                  closeMenuOnSelect={true}
                  hideSelectedOptions={false}
                  controlShouldRenderValue={true}
                  onChange={(selectedUrl) => {
                    setUrl(selectedUrl);
                  }}
                  value={url}
                  allowSelectAll={true}
                />
                <button
                  className="border plus ms-3"
                  style={{ background: "none" }}
                  onClick={() => {
                    SetIsActive(true);
                  }}
                >
                  {" "}
                  <i class="fa fa-plus-circle " aria-hidden="true"></i>{" "}
                </button>
              </div>
              <div class="ms-3" style={{ color: "red" }}>
                {message}
              </div>
              {IsActive ? (
                <div class="col-md-12 timecheck-icon d-flex mt-3 p-0">
                  <label></label>
                  <input
                    className="w-100"
                    type="text"
                    name=""
                    placeholder="Enter URL"
                    value={urlName}
                    onChange={(text) => {
                      setURLName(text.target.value);
                    }}
                  />

                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => {
                      SetIsActive(false);
                      setURLName("");
                    }}
                  >
                    {" "}
                    <i
                      class="fa fa-times d-flex ms-3"
                      aria-hidden="true"
                    ></i>{" "}
                  </button>

                  <button
                    style={{ border: "none", background: "none" }}
                    id={"rightbtn"}
                    onClick={() => {
                      if (urlName) {
                        addUrl();
                      }
                    }}
                  >
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>{" "}
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className="col-md-12 mt-3">
              {contentArray.map((item, index) => (
                <div className="mt-3" key={index}>
                  <label htmlFor=""> Expected Result {index + 1}</label>
                  <label class="field-required"> * </label>
                  <div className="d-flex justify-content-between">
                    <div className="w-100 me-3">
                      <input
                        className=" form-control w-100"
                        style={{ height: "36px" }}
                        type="text"
                        value={item.expectedresult1}
                        onChange={(text) => {
                          let list = [...contentArray];
                          list[index].expectedresult1 = text.target.value;
                          setContentArray(list);
                        }}
                      />
                      <>
                        <div style={{ color: "red" }}>{message2}</div>
                      </>
                    </div>

                    <div className="plus-minus-btn d-flex">
                      {contentArray.length - 1 === index ? (
                        contentArray.length < 5 && (
                          <>
                            <button className="me-2" onClick={addContent}>
                              <i
                                class="fa fa-plus-circle"
                                aria-hidden="true"
                              ></i>
                            </button>
                            {contentArray.length > 1 ? (
                              <button onClick={() => removeContent(index)}>
                                <i
                                  class="fa fa-minus-circle"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            ) : (
                              <></>
                            )}
                          </>
                        )
                      ) : (
                        <button onClick={() => removeContent(index)}>
                          <i class="fa fa-minus-circle" aria-hidden="true"></i>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              onClick={() => {
                let obj = {};
                contentArray.map((val, i) => {
                  obj[`Result${i + 1}`] = val.expectedresult1;
                });
                let siteUptimeObj = {
                  ...obj,
                  Type: selectedType,
                  URL: url?.value,
                };
                let rowdata = {
                  ProjectId: localStorage.getItem("projectGId"),
                  SiteUptimeConfigurationMappings: [siteUptimeObj],
                };
                let rowdata1 = {
                  id: location.state?.item?.id,
                  type: selectedType,
                  url: url?.value,
                  result1: obj?.Result1,
                  result2: obj?.Result2,
                  result3: obj?.Result3,
                  result4: obj?.Result4,
                  result5: obj?.Result5,
                  siteUpTimeConfigurationId:
                    location.state?.item?.siteUpTimeConfigurationId,
                };

                if (formValidation()) {
                  {
                    location.state.isEdit
                      ? UpdateSite(rowdata1)
                      : addSiteUpTime(rowdata);
                  }
                }
              }}
              className=" btn btn-primary me-3"
            >
              {location.state.isEdit ? "Update" : "Save"}
            </button>

            <button
              class="btn btn-outline-secondary"
              onClick={() => {
                update("siteuptimeMonitor");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteUpTimeMonitor1;
