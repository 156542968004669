import React from "react";
import { DateObject } from "react-multi-date-picker";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Card } from "react-bootstrap";
import "bootstrap-daterangepicker/daterangepicker.css";
import Chart from "react-apexcharts";
import { Table } from "antd";
import APIManager from "../services/APIManager";
import {
  addSEOVolatality,
  getseodatachartbydevice,
  getcountrySEOvolatality,
  getcategorybycountry,
  sendEmailApi,
  moduleSERPVolatility,
} from "../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
  formateDateIntoDatemonth,
  mmDDyyyyFormateForForm,
  calanderDate,
  // getCheckPermission,
  formatRankDate,
  themeStyle,
  downloadPDFfile,
  getBase64path
} from "../utility/Globals";
import { default as ReactSelect, components } from "react-select";
import Loading from "../utility/Loading";
import { PDFExport } from "@progress/kendo-react-pdf";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};
function ModuleExpandSEOVolatality() {
  const [options1, setObject1] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
    },
    yaxis: {
      logBase: 0,
      min: 0,
    },
  });

  const [series2, setSeries2] = useState([]);
  const [categorylist, setcategorylist] = useState([]);
  const [enhanceactive, setenhanceactive] = useState("");
  const [enhancerating, setenhancerating] = useState("");
  const [selectedDeviceType, setDeviceType] = useState("Mobile");
  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("US");
  const [categorylists, setcategorylists] = useState([]);
  const [categorylist1, setcategorylist1] = useState([]);
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [col, setCol] = useState([
    {
      dataIndex: "category",
      key: "category",
    },
    {
      dataIndex: "value",
      key: "value",
      width: "30",
      render: (val) => (
        <div className={getSeoColor(val)} style={{ textAlign: "start" }}>
          {val}{" "}
        </div>
      ),
    },
  ]);
  const [selectcategorylists, setselectcategorylists] = useState("");
  const [selectcategorylists1, setselectcategorylists1] = useState([0]);
  const [isNull, SetIsNull] = useState(false);
  let selectcategorylists5 = "0";

  const [defaultValue, setdefaultValue] = useState(
    formatRankDate(new Date().setDate(new Date().getDate() - 1))
  );

  const [defaultValue1, setdefaultValue1] = useState(
    mmDDyyyyFormateDateSet(new Date().setDate(new Date().getDate() - 1))
  );

  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [categoryHeading, setcategoryHeading] = useState("");
  const [categoryValue, setcategoryValue] = useState("");
  const [CategoryText, setCategoryText] = useState("");
  const [CategoryRange, setCategoryRange] = useState("");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("SERP Volatility Reports");
  const [isLoading, setLoading] = useState(false);

  const applyBtn = useRef(null);


  useEffect(() => {
    getCountryList();
    let uid = localStorage.getItem("UserEmail");
    localStorage.setItem("isCompareVar", false);
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the SERP Volatility Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
  }, []);


  function getSeoColor(value) {
    if (value >= 0.0 && value <= 2.0) return "text-primary";
    else if (value >= 2.1 && value <= 5.0) return "text-success";
    else if (value >= 5.1 && value <= 8.0) return "text-warning";
    else if (value >= 8.1 && value <= 10.0) return "text-danger";
  }

  function getSeoRangeColor(value) {
    if (value >= 0.0 && value <= 2.0)
      return "category-range ps-2 w-50 category-low";
    else if (value >= 2.1 && value <= 5.0)
      return "category-range ps-2 w-50 category-normal";
    else if (value >= 5.1 && value <= 8.0)
      return "category-range ps-2 w-50 category-high";
    else if (value >= 8.1 && value <= 10.0)
      return "category-range ps-2 w-50 category-vhigh";
  }

  function getCategoryImage() {
    if (categoryValue >= 0.0 && categoryValue <= 2.0) {
      return "/images/Low.png";
    } else if (categoryValue >= 2.1 && categoryValue <= 5.0) {
      return "/images/Medium.png";
    } else if (categoryValue >= 5.1 && categoryValue <= 8.0) {
      return "/images/High.png";
    } else if (categoryValue >= 8.1 && categoryValue <= 10.0) {
      return "/images/Very-High.png";
    }
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    applyBtn.current.click();
    let fileBase64 = await getBase64path(image)
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleSERPVolatility
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status == 200) {
          setReceiver("");
          setSubject("SEO Volatility Reports");
          setEmailMessage(EmailMessage1);
          if (theme == 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  function getCountryList() {
    APIManager.getAPI(getcountrySEOvolatality).then((response) => {
      if (response && response.status == 200) {
        let sortedCountryList = [];
        sortedCountryList = response.data;

        var sortedArray = sortedCountryList.sort((a, b) =>
          a.countryName > b.countryName ? 1 : -1
        );
        setCountryList(sortedArray);
        sortedArray.forEach((element) => {
          if ("US" == element.countryCode) {
            getCategorylist(element.id, element.countryCode);
          }
        });
      }
    });
  }

  const [title, setTitle] = useState("");
  const [info, setInfo] = useState("");

  function chooseenhancelist(a, b, title, text, value) {
    setenhanceactive(a);
    setenhancerating(b);
    setTitle(title);
    setInfo(text);
  }

  function generatereportseo(rowdata) {
    APIManager.postAPI(addSEOVolatality, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let volatalityList = [];
          volatalityList.push({
            text: response.data?.seoVolatilityDetails?.text,
            title: response.data?.seoVolatilityDetails?.title,
            value: response.data?.seoVolatility?.value,
          });
          volatalityList.push({
            text: response.data?.seoVolatilityDetails?.text,
            title: response.data?.seoVolatilityDetails?.title,
            value: response.data?.seoVolatility?.value,
          });
          chooseenhancelist(
            response.data?.seoVolatility?.value,
            response.data?.seoVolatilityDetails?.title,
            response.data?.seoVolatilityDetails?.text
          );

          var list = [];
          response.data.map((item) => {
            list.push({
              id: item.seoVolatility.seoVolatilityCategory.id,
              categoryName:
                item.seoVolatility.seoVolatilityCategory.categoryName,
              value: item.seoVolatility.value,
              text: item.seoVolatilityDetails.text,
              title: item.seoVolatilityDetails.title,
              category: (
                <div
                  onClick={() => {
                    setcategoryHeading(
                      item.seoVolatility.seoVolatilityCategory.categoryName
                    );
                    setcategoryValue(item.seoVolatility.value);
                    setCategoryText(item.seoVolatilityDetails.text);
                    setCategoryRange(item.seoVolatilityDetails.title);

                    let getdata1 = {
                      startDate: mmDDyyyyFormateDateSet(head1),
                      EndDate: mmDDyyyyFormateDateSet(head2),
                      categoryId1: item.seoVolatility.seoVolatilityCategory.id,
                      categoryId2:
                        selectcategorylists5 != "0"
                          ? selectcategorylists5
                          : item.seoVolatility.seoVolatilityCategory.id,
                      DeviceType: selectedDeviceType,
                      Country: countrySelected,
                    };
                    getseochartdata2(getdata1);
                    setselectcategorylists(
                      item.seoVolatility.seoVolatilityCategory.id
                    );
                  }}
                >
                  {item.seoVolatility.seoVolatilityCategory.categoryName}
                </div>
              ),
            });
          });
          let sortedList = list?.sort((a, b) =>
            a.categoryName > b.categoryName
              ? 1
              : b.categoryName > a.categoryName
                ? -1
                : 0
          );
          setcategorylist1(sortedList);
          setcategorylist(sortedList)
          setcategoryHeading(sortedList[0].categoryName);
          setcategoryValue(sortedList[0].value);
          setCategoryText(sortedList[0].text);
          setCategoryRange(sortedList[0].title);
        }
      }
    );
  }

  function getseochartdata2(rowdata1) {
    APIManager.postAPI(getseodatachartbydevice, rowdata1, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const startDate = rowdata1.startDate;
          const endDate = rowdata1.EndDate;
          const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
          const diffDays = 0 | (diffTime / 864e5);
          let dataForGraph2 = [];
          if (response.data.length) {
            response.data.map((data) => {
              let graphData2 = [];
              let option = [];
              let graphSeries2 = [];
              let count = 0;
              setSeries2([]);
              let obj = {
                key: count,
                categoryId1: data.categoryId1,
                categoryId2: data.categoryId2,
                categoryName: data.categoryName,
                deviceType: data.deviceType,
                country: data.country,
              };
              for (let i = 0; i <= diffDays; i++) {
                const newdate = mmDDyyyyFormateForForm(
                  new Date(new Date(startDate).getTime() + i * 864e5)
                );
                obj[newdate] = data[newdate] ? data[newdate] : 0;
                graphData2.push(data[newdate] ? data[newdate] : 0);
                graphSeries2.push(
                  formateDateIntoDatemonth(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  )
                );
              }
              option = {
                annotations: {
                  position: "back",
                  yaxis: [
                    {
                      label: {
                        text: " ",
                      },
                      y: 0.0,
                      y2: 2.0,
                      fillColor: "#358ac3",
                    },
                    {
                      label: {
                        text: " ",
                      },
                      y: 2.0,
                      y2: 5.0,
                      fillColor: "#539b3e",
                    },
                    {
                      label: {
                        text: " ",
                      },
                      y: 5.0,
                      y2: 8.0,
                      fillColor: "#dc8a1f",
                    },
                    {
                      label: {
                        text: " ",
                      },
                      y: 8.0,
                      y2: 10.0,
                      fillColor: "#ba4636",
                    },
                  ],
                },

                chart: {
                  toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      zoom: false,
                      zoomin: true,
                      zoomout: true,
                      customIcons: [],
                    },
                  },
                },

                xaxis: {
                  tickPlacement: "on",
                  categories: graphSeries2,
                },
                yaxis: {},

                dataLabels: {
                  enabled: false,
                },
                legend: {
                  show: false,
                },

                markers: {
                  size: 5,
                  colorField: [
                    function ({ value }) {
                      if (value >= 0.0 && value <= 2.0) return "#358ac3";
                      else if (value >= 2.1 && value <= 5.0) return "#539b3e";
                      else if (value >= 5.1 && value <= 8.0) return "#dc8a1f";
                      else if (value >= 8.1 && value <= 10.0) return "#ba4636";
                    },
                  ],

                  strokeColors: "#fff",
                  strokeWidth: 2,
                  strokeOpacity: 0.9,
                  strokeDashArray: 0,
                  fillOpacity: 10,
                  discrete: [],
                  shape: "circle",
                  radius: 2,
                  offsetX: 0,
                  offsetY: 0,
                },
              };
              setObject1(option);
              dataForGraph2.push({
                name: data.categoryName,
                data: graphData2,
              });
              setSeries2(dataForGraph2);
              count++;
            });
          } else {
            setSeries2([]);
          }
        }
      }
    );
  }

  function getCategorylist(countryId, countryCode) {
    APIManager.getAPI(getcategorybycountry + "?countryId=" + countryId).then(
      (response) => {
        if (response && response.status == 200) {
          var list = [];
          response.data.seoVolatilityCategories.map((item) => {
            list.push({
              id: item.id,
              id2: item.id,
              categoryName: item.categoryName,
              googleTrendCategoryValue: item.googleTrendCategoryValue,
              category: item.categoryName,
              value: item.categoryName,
              label: item.categoryName,
            });
          });

          let sortedList = list?.sort((a, b) =>
            a.categoryName > b.categoryName
              ? 1
              : b.categoryName > a.categoryName
                ? -1
                : 0
          );
          setcategorylists(sortedList);
          setselectcategorylists(sortedList[0].id)

          let getdata2 = {
            Country: countryCode,
            categoryId: list[0]?.id,
            DeviceType: selectedDeviceType,
            Date: mmDDyyyyFormateDateSet(defaultValue),
          };

          generatereportseo(getdata2);

          let getdata1 = {
            startDate: mmDDyyyyFormateDateSet(head1),
            EndDate: mmDDyyyyFormateDateSet(head2),
            categoryId1: list[0]?.id,
            DeviceType: selectedDeviceType,
            Country: countryCode,
          };
          getseochartdata2(getdata1);
        }
      }
    );
  }
  
  function categoryChange(item) {
    setselectcategorylists1(item);

    let getdata = {
      Country: countrySelected,
      categoryId: selectcategorylists,
      DeviceType: selectedDeviceType,
      Date: mmDDyyyyFormateDateSet(
        defaultValue
      ),
    };

    generatereportseo(getdata);

    if (item == null) {
      selectcategorylists5 = "0";
      SetIsNull(true);
      let getdata1 = {
        startDate: mmDDyyyyFormateDateSet(head1),
        EndDate: mmDDyyyyFormateDateSet(head2),
        categoryId1: selectcategorylists,
        DeviceType: selectedDeviceType,
        Country: countrySelected,
      };

      getseochartdata2(getdata1);

      let getdata2 = {
        startDate: mmDDyyyyFormateDateSet(head1),
        EndDate: mmDDyyyyFormateDateSet(head2),
        categoryId1: selectcategorylists == "" ? item?.id : selectcategorylists,
        categoryId2: selectcategorylists == "" ? item?.id : selectcategorylists,
        DeviceType: selectedDeviceType,
        Country: countrySelected,
      };
      getseochartdata2(getdata2);
    }
    else {
      selectcategorylists5 = item.id;
      setheadvalues();

      if (item.id == "0") {
        let getdata1 = {
          startDate: mmDDyyyyFormateDateSet(head1),
          EndDate: mmDDyyyyFormateDateSet(head2),
          categoryId1: selectcategorylists,
          DeviceType: selectedDeviceType,
          Country: countrySelected,
        };
        getseochartdata2(getdata1);
      }
      else {
        let getdata1 = {
          startDate: mmDDyyyyFormateDateSet(head1),
          EndDate: mmDDyyyyFormateDateSet(head2),
          categoryId1: selectcategorylists == "" ? item.id : selectcategorylists,
          categoryId2: item.id,
          DeviceType: selectedDeviceType,
          Country: countrySelected,
        };
        getseochartdata2(getdata1);
      }
    }
  }

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());
  const [CountrySelectedId, setCountrySelectedId] = useState("");

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);
  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }
  function setheadvalues() {
    setHead1(values[0].format());
    if (values[1] != null) {
      setHead2(values[1].format());
    } else {
      setHead2(head1);
    }
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }     
    };
  }, [open]);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  const [SeoVolatality, setSeoVolatality] = useState(false);
  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      <div class="row">
        <Tabs>
          <div className="portlet seovolatality-tab ">
            <div className="portlet-header portlet-header-bordered my-3">
              <div className="col-md-8 col-sm-12">
                <TabList className="nav nav-lines portlet-nav">
                  <Tab
                    className={
                      SeoVolatality
                        ? "nav-item nav-link "
                        : "nav-item nav-link  active"
                    }
                    onClick={() => {
                      setSeoVolatality(false);
                    }}
                  >
                    SERP Volatility
                  </Tab>
                </TabList>
              </div>
              {!SeoVolatality ? (
                <div
                  ref={ref}
                  class="col-md-4 col-sm-12 calendar-main d-flex justify-content-end align-items-center ga-calender"
                >
                  <div className="add-new-btnw">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px" }}
                      onClick={() => setOpen(!open)}
                    >
                      {calanderDate(head1, head2)}
                    </button>
                  </div>

                  {open && (
                    <div id="example-collapse-text-calendar">
                      <Card body className="daterange-picker-card  mt-2">
                        <div className="d-flex justify-content-between">
                          <div className="calendar-col">  
                              <DateRangePicker
                                onChange={(item) => {
                                  let isCompareVarNew = localStorage.getItem("isCompareVar");                                                                                 
                                    if (isCompareVarNew == "false") {
                                      let selection23 = {};
                                      if (item?.selection1) {
                                        selection23 = {
                                          selection2: {
                                            startDate: item?.selection1?.startDate,
                                            endDate: item?.selection1?.endDate,
                                            key: "selection2",
                                          },
                                        };

                                        setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                        setHead1(new DateObject(item?.selection1?.startDate).format());
                                        setHead2(new DateObject(item?.selection1?.endDate).format());

                                      } else if(item?.selection2) {
                                        selection23 = {
                                          selection1: {
                                            startDate: item?.selection2?.startDate,
                                            endDate: item?.selection2?.endDate,
                                            key: "selection1",
                                          },
                                        };

                                        setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                        setHead1(new DateObject(item?.selection2?.startDate).format());
                                        setHead2(new DateObject(item?.selection2?.endDate).format());
                                      }

                                      setState({
                                        ...state,
                                        ...item,
                                        ...selection23,
                                      });
                                    } 
                                }}
                                    
                                showSelectionPreview={true}
                                moveRangeOnFirstSelection={false}
                                months={1}
                                ranges={[state?.selection1, state?.selection2]}
                                direction="horizontal"
                                ariaLabels={{
                                  dateInput: {
                                    selection1: {
                                      startDate: "start date input of selction 1",
                                      endDate: "end date input of selction 1",
                                    },
                                    selection2: {
                                      startDate: "start date input of selction 2",
                                      endDate: "end date input of selction 2",
                                    },
                                  },

                                  monthPicker: "month picker",
                                  yearPicker: "year picker",
                                  prevButton: "previous month button",
                                  nextButton: "next month button",
                                }}
                                maxDate={
                                  new Date(
                                    new Date().getFullYear(),
                                    new Date().getMonth(),
                                    new Date().getDate() - 1
                                  )
                                }
                              />
                          </div>
                          <div className="ms-3">
                            <button
                              onClick={() => {
                                setheadvalues();
                                setOpen(!open);
                                let getdata1 = {
                                  startDate: mmDDyyyyFormateDateSet(head1),
                                  EndDate: mmDDyyyyFormateDateSet(head2),
                                  categoryId1: selectcategorylists,
                                  categoryId2:
                                    selectcategorylists1.id2 != "0"
                                      ? selectcategorylists1.id2
                                      : selectcategorylists,
                                  DeviceType: selectedDeviceType,
                                  Country: countrySelected,
                                };
                                getseochartdata2(getdata1);
                                setRefreshTicketData(true);
                                setTimeout(() => {
                                  setRefreshTicketData(false);
                                }, 1000);
                              }}
                              className="btn-outline btn btn-primary"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                  <div className="d-flex 3-icons">
                    <a
                      className="bar-link-icon ms-3"
                      style={{ color: "black" }}
                    >
                      <div class="dropdown">
                        <a
                          type="button"
                          ref={applyBtn}
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-filter" aria-hidden="true"></i>
                        </a>
                        <form class="dropdown-menu p-4 filter-dropdown">
                          <div className="filter-popup">
                            <div className="portlet px-2 py-2">
                              <div className="col-md-12 my-2">
                                <label style={{ fontSize: 14 + "px" }}>
                                  Location
                                </label>
                                <select
                                  style={{ padding: 8 + "px" }}
                                  className="w-100"
                                  required
                                  value={countrySelected}
                                  onChange={(e) => {
                                    countryList?.map((c) => {
                                      if (c.countryCode == e.target.value) {
                                        getCategorylist(c.id, c.countryCode);
                                        setCountrySelectedId(c.id);
                                        setCountrySelected(c.countryCode);
                                      }
                                    });
                                  }}
                                >
                                  {countryList.map((item) => {
                                    return (
                                      <option value={item.countryCode}>
                                        {item.countryName}
                                      </option>
                                    );
                                  })}
                                </select>
                                <label
                                  className="mt-3"
                                  style={{ fontSize: 14 + "px" }}
                                >
                                  Device Type
                                </label>
                                <select
                                  style={{ padding: 8 + "px" }}
                                  className="w-100"
                                  value={selectedDeviceType}
                                  onChange={(e) => {
                                    setDeviceType(e.target.value);
                                    let getdata1 = {
                                      startDate: mmDDyyyyFormateDateSet(head1),
                                      EndDate: mmDDyyyyFormateDateSet(head2),
                                      categoryId1: selectcategorylists,
                                      categoryId2:
                                        selectcategorylists1.id2 != "0"
                                          ? selectcategorylists1.id2
                                          : selectcategorylists,
                                      DeviceType: e.target.value,
                                      Country: countrySelected,
                                    };
                                    getseochartdata2(getdata1);
                                    let getdata = {
                                      Country: countrySelected,
                                      categoryId: selectcategorylists,
                                      DeviceType: e.target.value,
                                      Date: mmDDyyyyFormateDateSet(
                                        defaultValue
                                      ),
                                    };
                                    generatereportseo(getdata);
                                  }}
                                >
                                  <option value="Mobile">Mobile</option>
                                  <option value="Desktop">Desktop</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </a>

                    <div className="mx-2  ">
                      <a
                        className="share bar-link-icon"
                        style={{ color: "black" }}
                      >
                        <div class="dropdown">
                          <a
                            type="button"
                            ref={applyBtn}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa fa-share-alt" aria-hidden="true"></i>
                          </a>
                          <form class="dropdown-menu p-4 filter-dropdown">
                            <div className="filter-popup">
                              <div className="portlet px-2 py-2">
                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">From</label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Sender Email Id"
                                      value={Sender}
                                      className="w-100 form-control my-2"
                                      name="Sender"
                                    />
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">To</label>
                                    <label class="field-required"> * </label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Recipient Email Id"
                                      className="w-100 form-control my-2"
                                      value={Receiver}
                                      name="Receiver"
                                      onChange={(text) =>
                                        setReceiver(text.target.value)
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageReceiver}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">Subject</label>
                                    <label class="field-required"> * </label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="text"
                                      required
                                      placeholder="Subject"
                                      value={subject}
                                      onChange={(text) => {
                                        setSubject(text.target.value);
                                      }}
                                      className="w-100 form-control my-2"
                                      name="Subject"
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageReceiver}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">Message</label>
                                    <label class="field-required"> * </label>
                                  </div>
                                  <div className="col-md-10">
                                    <textarea
                                      name=""
                                      id=""
                                      cols="80"
                                      rows="7"
                                      className="w-100 form-control my-2 gscmsg"
                                      value={EmailMessage}
                                      onChange={(text) => {
                                        setEmailMessage(text.target.value);
                                      }}
                                    ></textarea>
                                    <div style={{ color: "red" }}>
                                      {Message}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-2"></div>
                                  <div className="col-md-10 my-2">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style={{
                                        width: 70 + "px",
                                        height: 30 + "px",
                                      }}
                                      onClick={() => {
                                        if (isValidEmailData()) {
                                          sendEmail();
                                        }
                                      }}
                                    >
                                      {" "}
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </a>
                    </div>

                    <span
                      class="dropdown"
                      style={{
                        marginRight: 0 + "px",
                        textAlign: "right",
                        display: "block",
                      }}
                    >
                      {/* {getCheckPermission("export", "modules") ? ( */}
                        <button
                          class="outline-btn dropdown-toggle btn btn-primary "
                          type="button"
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-download " aria-hidden="true"></i>
                        </button>
                      {/* ) : (
                        <></>
                      )} */}
                      <div
                        class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div
                          onClick={async () => {
                            setLoading(true)
                            const promise = await downloadPDFfile(image, moduleSERPVolatility)
                            try {
                              if (promise) {
                                setLoading(false);
                              } else {
                                setLoading(false);
                              }
                            } catch (e) {
                              setLoading(false);
                            }
                          }}
                        >
                          <a class="dropdown-item">PDF</a>
                        </div>
                      </div>
                    </span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>

          <TabPanel>
            <PDFExport
              paperSize="A1"
              margin="2cm"
              fileName={moduleSERPVolatility}
              ref={pdfExportComponent}
              forcePageBreak=".page-break"
            >
              <div ref={image} >
                <div className="row " id="web-page-pdf">
                  <div className="col-md-9 col-sm-12 ps-0 seovolatality-tab ">
                    {categorylist.length == 0 ? (
                      <div
                        className="col-md-6 res-100"
                        style={{ textAlign: "center" }}
                      >
                        No data
                      </div>
                    ) : (
                      <div className="row res-100 ">
                        {" "}
                        <div className="portlet p-0">
                          <div className="portlet-header d-inline">
                            <div className="row align-items-center">
                              <h3
                                className="col-md-4 portlet-title "
                                style={{ fontSize: 20 + "px", fontWeight: 400 }}
                              >
                                {categoryHeading}
                              </h3>

                              <h2
                                className="col-md-8 portlet-title text-right seo-date"
                                style={{
                                  fontSize: 12 + "px",
                                  fontWeight: 400,
                                  color: "#757575",
                                }}
                              >
                                Last Updated: {defaultValue}
                              </h2>
                            </div>
                          </div>{" "}
                          <div className="portlet-body mt-3">
                            <div className="row p-0 mt-3">
                              <div className="col-md-3 col-sm-12 p-0">
                                <div className="range-text">
                                  {" "}
                                  {categoryValue}/10
                                  <div className="cloud-img mt-3">
                                    <img src={getCategoryImage()} alt="" />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-9 col-sm-12 ps-5 range">
                                <div className={getSeoRangeColor(categoryValue)}>
                                  {" "}
                                  {CategoryRange}
                                </div>
                                <div className="description-rating mt-4 pe-5">
                                  {CategoryText}
                                </div>
                                <div
                                  className="ms-3"
                                  style={{ fontSize: 14 + "px" }}
                                >
                                  {title}
                                </div>
                                <div
                                  className="ms-3"
                                  style={{ fontSize: 14 + "px" }}
                                >
                                  {info}
                                </div>{" "}
                              </div>
                            </div>{" "}
                          </div>{" "}
                        </div>
                      </div>
                    )}

                    <div className="row">
                      <div className="col-md-12 col-sm-12 res-100 pe-0 ps-0">
                        <div className="portlet">
                          <div className="portlet-header ">
                            <h3
                              className="portlet-title"
                              style={{ fontSize: 20 + "px", fontWeight: 400 }}
                            >
                              Volatility Trend
                            </h3>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-4 col-sm-12">
                              <div className=" text-left pe-3">                               
                                <ReactSelect
                                  styles={themeStyle()}
                                  className="SearchSelectMain mt-2"
                                  placeholder="Compare Categories"
                                  options={categorylists}
                                  closeMenuOnSelect={true}
                                  onChange={categoryChange}
                                  value={selectcategorylists1}
                                  components={{ Option }}
                                  isClearable
                                />
                              </div>
                            </div>
                            <div className="col-md-8 col-sm-12 d-flex align-items-center justify-content-end">
                              <div className="low-box me-2"></div>
                              <label className="me-3">Low</label>
                              <div className="normal-box me-2"></div>
                              <label className="me-3">Normal</label>
                              <div className="high-box me-2"></div>
                              <label className="me-3">High</label>
                              <div className="veryhigh-box me-2"></div>
                              <label className="me-3">Very High</label>
                            </div>
                          </div>

                          {series2?.length ? (
                            <Chart
                              options={options1}
                              series={series2}
                              type="area"
                              height="300"
                            />
                          ) : (
                            <div
                              className="col-md-6"
                              style={{ textAlign: "center", marginTop: "10%" }}
                            >
                              No data
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-sm-12 py-2 pt-0 pe-0 seo-volatality align-items-center">
                    <div className="seo-port portlet">
                      <Table
                        className="table  table-hover sevolatality-border"
                        dataSource={categorylist1}
                        columns={col}
                        pagination={false}
                      />
                    </div>
                  </div>
                </div>
              </div></PDFExport>
          </TabPanel>
        </Tabs>
      </div>
      <Loading isLoading={isLoading} />
    </>
  );
}

export default ModuleExpandSEOVolatality;