import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import { dashboardTicketsStatus } from "../../utility/Constant";
import {
  fixTwoPlaceDecimal,
  getMonthName,
  mmDDyyyyFormateDateSet,
} from "../../utility/Globals";
import DoughnutCenterCountChart from "../seoaudit/DoughnutCenterCountChart";

function DashboardTicketTest(props) {
  // const [startDate, setstartDate] = useState("");
  // const [endDate, setendDate] = useState("");
  // const [compareStartDate, setcompareStartDate] = useState("");
  // const [compareendDate, setcompareendDate] = useState("");

  useEffect(() => {

    // setstartDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    // );
    // setendDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    // );
    // setcompareStartDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    // );
    // setcompareendDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    // );

    let sDate = mmDDyyyyFormateDateSet(
      props?.dataParentToChild?.selection1?.startDate
    );

    getDashboardTcketTest(sDate);

  }, [props?.dataParentToChild]);

  // const [implemented, setImplemented] = useState([]);
  // const [inProgress, setInProgress] = useState([]);
  // const [onHold, setonHold] = useState([]);
  const [statusData, setStatusData] = useState([
    "Completed",
    "InProgress",
    "On Hold",
  ]);

  // const [percentage, setPercentage] = useState(0);
  const [total, setTotal] = useState(0);
  const [arr, setArr] = useState([])

  useEffect(() => {
    setArr(
      [{
        beforeDraw: function (chart) {
          var width = chart.width,
            height = chart.height,
            ctx = chart.ctx;
          ctx.restore();
          var fontSize = (height / 160).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "top";
          var text = 10,
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2;
          ctx.fillText(text, textX, textY);
          ctx.save();
        }
      }])

  }, [total]);

  const [table, settable] = useState(0);
  const [table1, settable1] = useState(0);
  const [table2, settable2] = useState(0);
  let data;
  if (table + table1 + table2 > 0) {
    data = {
      maintainAspectRatio: false,
      responsive: false,
      labels: statusData,
      datasets: [
        {
          data: [table, table1, table2],
          backgroundColor: ["#91E395", "#FFAA46", "#F64F53"],
          // backgroundColor: [
          //   table > 0 ? "#91E395" : "#F2F2F2",         
          //   table1 > 0 ? "#FFAA46" : "#F2F2F2",
          //   table2 > 0 ? "#F64F53" : "#F2F2F2",         
          // ],
          borderColor: ["#91E395", "#FFAA46", "#F64F53"],
          // borderColor: [         
          //   table > 0 ? "#91E395" : "#F2F2F2",         
          //   table1 > 0 ? "#FFAA46" : "#F2F2F2",
          //   table2 > 0 ? "#F64F53" : "#F2F2F2",         
          // ],      
        },
      ],
    };
  }
  else {
    data = {
      maintainAspectRatio: false,
      responsive: false,
      labels: statusData,
      datasets: [
        {
          data: [100],
          backgroundColor: ["#F2F2F2"],
          // backgroundColor: [
          //   table > 0 ? "#91E395" : "#F2F2F2",         
          //   table1 > 0 ? "#FFAA46" : "#F2F2F2",
          //   table2 > 0 ? "#F64F53" : "#F2F2F2",         
          // ],
          borderColor: ["#F2F2F2"],
          // borderColor: [         
          //   table > 0 ? "#91E395" : "#F2F2F2",         
          //   table1 > 0 ? "#FFAA46" : "#F2F2F2",
          //   table2 > 0 ? "#F64F53" : "#F2F2F2",         
          // ],      
        },
      ],
    };
  }

  let options;
  if (table + table1 + table2 > 0) {
    options = {
      cutoutPercentage: 80,
      responsive: true,
      maintainAspectRatio: true,
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      subtitles: [{
        text: "71%",
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true
      }],
    }
  }
  else {

    options = {
      cutoutPercentage: 80,
      responsive: true,
      maintainAspectRatio: true,
      tooltips: {
        enabled: false
      },
      legend: {
        display: false,
        position: "bottom",
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
        },
      },
      subtitles: [{
        text: "71%",
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true
      }],
    }
  }

  function getDashboardTcketTest(sDate) {
    APIManager.postAPI(
      dashboardTicketsStatus +
      "startDate=" +
      sDate +
      "&projectId=" +
      localStorage.getItem("projectGId"),
      {},
      null,
      true
    ).then((response) => {
      if (
        response &&
        response.status == 200 &&
        response.data != "No Content Found"
      ) {

        settable(fixTwoPlaceDecimal(response.data.table[0].implemented));
        settable1(fixTwoPlaceDecimal(response.data.table1[0].inProgress));
        settable2(fixTwoPlaceDecimal(response.data.table2[0].onHold));
        // setPercentage(
        //   [response.data.table[0].implemented],
        //   [response.data.table1[0].inProgress],
        //   [response.data.table2[0].onHold]
        // );
        // setImplemented(response.data.table[0].implemented);
        // setInProgress(response.data.table1[0].inProgress);
        // setonHold(response.data.table2[0].onHold);
        let totalData =
          response.data.table[0].implemented +
          response.data.table1[0].inProgress +
          response.data.table2[0].onHold;
        setTotal(totalData.toFixed(0), () => {
          getDoughnutChart(data, total.toFixed(0))
        });
      }
    });
  }


  function getDoughnutChart(data, total) {
    return (
      <div>
        <DoughnutCenterCountChart data={data} finalScore={total} />
      </div>
    )
  }

  return (
    <>
      <div className="title d-flex">
        <h4>Tickets</h4>
        <h4 className="dashborad-ticket-date ms-2"> ({getMonthName()} - {new Date().getFullYear()})</h4>
      </div>
      <div className="dash-content">
        <div className="d-flex align-items-center justify-content-center py-5 my-4">
          <Doughnut
            type="doughnut"
            data={data}
            options={options}
          />
        </div>
        <div className="percentage-text">
          <div className="text-name">{total}</div>
        </div>
        <div className="mt-3 dashbordticket dashboard-marker">
          <div className="d-flex align-items-center  justify-content-center">
            <div className="excellent-dot me-2 mt-0 mb-0"></div>
            <label className="me-3 pt-2">Completed</label>
            <div className="good-dot me-2"></div>
            <label className="me-3 pt-2">In progress</label>
            <div className="needwork-dot me-2"></div>
            <label className="pt-2">On hold</label>
          </div>
          <div className="mt-4"></div>
        </div>
      </div>
    </>
  );
}

export default DashboardTicketTest;
