import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GoogleLogin } from "react-google-login";
import { Input } from "antd";
import "antd/dist/antd.css";
import FormControl from "@mui/material/FormControl";
import {
    userAuth,
    userForgotPassword,
} from "../utility/Constant";
import { storeUserInformation } from "../utility/Globals";
import APIManager from "../services/APIManager";

function LatestLogin() {
    const history = useHistory();
    const configData = require("../config.json");
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");
    const [values, setValues] = React.useState({
        amount: "",
        password: "",
        weight: "",
        weightRange: "",
        showPassword: false,
    });

    useEffect(() => { }, []);

    const inputDataValidation = () => {
        if (userId == "") {
            return false;
        } else if (password == "") {
            return false;
        } else return true;
    };

    const responseGoogleSuccess = (response) => {
        setUserId(response.profileObj.email);
        userLogin(response.profileObj.email, true);
    };

    // Error Handler
    const responseGoogleError = () => { };

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    function userLogin(userId, isGoogle) {
        localStorage.setItem("is1stTime", true);
        let rowData;
        if (isGoogle) {
            rowData = { email: userId };
        } else {
            rowData = {
                email: userId,
                password: password,
            };
        }
        APIManager.postAPI(userAuth, rowData, null, true).then((response) => {
            if (response && response.status == 200) {
                setMessage(response?.data?.message);
                if (response.data.token) {
                    setUserId("");
                    setPassword("");
                    setMessage("");
                    if (response.data.isForceChangePassword) {
                        history.push("/new-password-set");
                    } else {
                        history.push("/home/dashboard");
                    }
                }
                storeUserInformation(response.data);
            }
        });
    }

    function forgotPassword(email) {
        APIManager.postAPI(userForgotPassword + email, {}, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                }
            }
        );
    }

    return (
        <>
            <div class="user-moudle">
                <div class="user-common-card">
                    <div class="alogo">
                        <div class="alogo">
                            <a href="">
                                <img src="images/infidigit-logo.png" />
                            </a>
                            <span>
                                <strong>Growth</strong>
                            </span>
                        </div>
                    </div>
                    <form>
                        <ul class="list-unstyled form-fields">
                            <li>
                                <form id="login-form">
                                    <div class="col-md-12">
                                        <div class="alogo form-group">
                                            <FormControl
                                                class="w-100 mr-2 ml-2 float-label float-label-lg"
                                                variant="standard"
                                                sx={{ m: 1, mt: 3, width: "25ch" }}
                                            >
                                                <Input
                                                    className="form-control form-control-lg"
                                                    type="email"
                                                    id="email standard-adornment-weight"
                                                    name="email"
                                                    placeholder="Please insert your email"
                                                    value={userId}
                                                    onChange={(text) => {
                                                        setUserId(text.target.value);
                                                    }}
                                                    aria-describedby="standard-weight-helper-text"
                                                    inputProps={{
                                                        "aria-label": "weight",
                                                    }}
                                                />
                                                <label for="email">Email</label>
                                            </FormControl>

                                            <FormControl
                                                className="w-100 mt-3"
                                                sx={{ m: 1, width: "25ch" }}
                                                variant="outlined"
                                            >
                                                <div class="float-label float-label-lg position-relative mt-2">
                                                    <input
                                                        class="form-control form-control-lg"
                                                        type={!values.showPassword ? "password" : "text"}
                                                        id="password"
                                                        name="password"
                                                        placeholder="Please insert your password"
                                                        onChange={(text) => {
                                                            setPassword(text.target.value);
                                                        }}
                                                    />
                                                    <label for="password">Password</label>
                                                    <div
                                                        className="icon-btn position-absolute"
                                                        onClick={handleClickShowPassword}
                                                    >
                                                        {values.showPassword ? (
                                                            <i class="fa fa-eye" aria-hidden="true"></i>
                                                        ) : (
                                                            <i class="fa fa-eye-slash" aria-hidden="true"></i>
                                                        )}
                                                    </div>
                                                </div>
                                            </FormControl>

                                            <div
                                                class="btn-sn mb-3"
                                                onClick={() => {
                                                    if (inputDataValidation()) {
                                                        userLogin(userId, false);
                                                    } else {
                                                        setMessage("Email and Password are required.");
                                                    }
                                                }}
                                            >
                                                <a
                                                    class="btn btn-label-primary btn-lg btn-widest mt-4"
                                                    style={{
                                                        background: "#e8f4fe",
                                                        color: "#2196f3",
                                                    }}
                                                >
                                                    {" "}
                                                    Login
                                                </a>
                                            </div>
                                            <div style={{ color: "red" }}>{message}</div>
                                            <div
                                                style={{ color: "#0d6efd" }}
                                                onClick={() => {
                                                    if (userId == "") {
                                                        setMessage("Enter your email id");
                                                    } else {
                                                        forgotPassword(userId);
                                                    }
                                                }}
                                            >
                                                <a> Forgot password?</a>
                                            </div>
                                            <div
                                                class="modal fade"
                                                id="staticBackdrop"
                                                data-backdrop="static"
                                                data-keyboard="false"
                                                tabindex="-1"
                                                aria-labelledby="staticBackdropLabel"
                                                aria-hidden="true"
                                            >
                                                <div class="modal-dialog modal-dialog-centered">
                                                    <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h4 class="text-capitalize text-bold">
                                                                check your mail
                                                            </h4>
                                                            <button
                                                                type="button"
                                                                class="close"
                                                                data-dismiss="modal"
                                                                aria-label="Close"
                                                            >
                                                                <span aria-hidden="true">&times;</span>
                                                            </button>
                                                        </div>
                                                        <div class="modal-body">
                                                            <p>
                                                                We have sent a password recover instruction to
                                                                your mail
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <br />
                                            <GoogleLogin
                                                class="btn-label-primary btn btn-lg btn-widest"
                                                clientId={configData.CLIENT_ID}
                                                redirectUri={configData.redirectUri}
                                                render={(renderProps) => (
                                                    <a>
                                                        <img
                                                            className="border"
                                                            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQP5gUULZ-oIzeqWBezPyuJHZzpC6B7q7k1-8c3Cb1S2Uaq4VPluBvrPpr5vLXq9S_JjKo&usqp=CAU"
                                                            onClick={renderProps.onClick}
                                                            disabled={renderProps.disabled}
                                                        />
                                                    </a>
                                                )}
                                                buttonText="Login"
                                                onSuccess={responseGoogleSuccess}
                                                onFailure={responseGoogleError}
                                                cookiePolicy={"single_host_origin"}
                                            />
                                        </div>
                                    </div>
                                </form>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </>
    );
}

export default LatestLogin;
