import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Table, Input } from "antd";
import { Link } from "react-router-dom";
import {
  documentFileType,
  GET_Server_URL,
  getGoogleTrendsConfig,
  googleTrendsConfigImport,
  deleteGoogleTrends,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import {
  exportInToCSV,
  exportToEXCEL,
} from "../../utility/Globals";
import { searchTypeList } from "../../utility/Globals";
import { confirmAlert } from "react-confirm-alert";
import { Sorter } from "../../common/Sorter";
import toast from "react-hot-toast";
import AddGoogleTrends from "./AddGoogleTrends";

function GoogleTrendsnew({ update }) {
  useEffect(() => {
    getGoogleTrendsItemlist();
  }, []);

  const inputFileRef = useRef(null);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [googleTemp, setGoogleTemp] = useState([]);
  const [googletrendslist, setgoogletrendslist] = useState([]);
  const [IsGoogleTrends, setIsGoogleTrends] = useState(false);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [selectionTypeGoogleTrendtab, setselectionTypeGoogleTrendtab] =
    useState("checkbox");
  const [settings, setSetting] = useState("googleTrends");
  const notifyNotDelete = () =>
    toast.success("You can't delete this Keyword because it is in use");

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {
          fieldValue: item.fieldValue,
          SearchType: item?.SearchType,
          categoryName: item?.categoryName,
          countryName: item?.countryName,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function uploadgoogletrendsFile() {
    var input = document.getElementById("GoogleTrendsImportFile");
    uploadImportFile(input.files.item(0));
  }

  function uploadImportFile(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      googleTrendsConfigImport + localStorage.getItem("projectGId"),
      {},
      bodyFormData,
      false
    ).then(() => {
      getGoogleTrendsItemlist();
    });
  }
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const history = useHistory();

  function searchTypes(val) {
    var finalVal = val;
    searchTypeList.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }

  function getGoogleTrendsItemlist() {
    APIManager.getAPI(
      getGoogleTrendsConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const filterType = [];
        const filterUrl = [];
        let count = 1;
        let exportList = [];
        response.data.map((item) => {
          let obj = {
            key: count,
            id: item?.id,
            categoryName: item?.category?.categoryName,
            fieldValue: item?.keyword?.fieldValue,
            projectId: localStorage.getItem("projectId"),
            countryName: item?.country?.countryName,
            SearchType: searchTypes(item?.searchType),
            action: (
              <div>
                <Link
                  to={{
                    state: {
                      item: {
                        id: item?.id,
                        categoryId: item?.categoryId,
                        categoryName: item?.category?.categoryName,
                        fieldValueId: item?.keywordId,
                        fieldValue: item?.keyword?.fieldValue,
                        countryId: item?.countryId,
                        countryName: item?.country?.countryName,
                        SearchType: item?.searchType,
                        SearchTypeId: item?.id,
                      },
                      isEdit: true,
                    },
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon"
                    onClick={() => {
                      setIsGoogleTrends(true);
                      setSetting("googleTrendsAddUpdate");
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </Link>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon ms-3"
                  onClick={() => {
                    submit(item?.id);
                    deleteGoogleTrendsItem(item?.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          };
          list.push(obj);
          count++;
          exportList.push({
            key: count,
            fieldValue: item?.keyword?.fieldValue,
            SearchType: searchTypes(item?.searchType),
            categoryName: item?.category?.categoryName,
            countryName: item?.country?.countryName,
          });
          setExportList(exportList);
        });
        const columns = [
          {
            title: "Keywords",
            dataIndex: "fieldValue",
            key: "fieldValue",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.fieldValue, b.fieldValue),
              multiple: 3,
            },
          },

          {
            title: "Type Of Search",
            dataIndex: "SearchType",
            key: "SearchType",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.SearchType, b.SearchType),
              multiple: 3,
            },
          },
          {
            title: "Category",
            dataIndex: "categoryName",
            key: "categoryName",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.categoryName, b.categoryName),
              multiple: 3,
            },
          },
          {
            title: "Country",
            dataIndex: "countryName",
            key: "countryName",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.countryName, b.countryName),
              multiple: 3,
            },
          },
          {
            title: "Action",
            dataIndex: "action",
            key: "action",
          },
        ];
        setteamcol(columns);
        setteamList(list);
        setGoogleTemp(list);
        setgoogletrendslist(list);
      } else if (response && response.status == 204) {
        setteamcol([]);
        setteamList([]);
        setGoogleTemp([]);
      }
    });
  }

  async function deleteGoogleTrendsItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteGoogleTrends + id);
      if (response == 200) {
        exportDelete(id);
        getGoogleTrendsItemlist();
      } else if (response == 500) {
        notifyNotDelete();
      }
    } catch (e) {}
  }

  function exportDelete(id) {
    exportList.filter((item) => item.id != id);
  }
  function submit(item) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteGoogleTrendsItem(item.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
    getGoogleTrendsItemlist();
  };

  function getComponent() {
    if (settings == "googleTrendsAddUpdate") {
      return <AddGoogleTrends update={onClickFunction} />;
    }
  }

  return (
    <>
      {settings === "googleTrends" ? (
        <div className="portlet slideInUp">
          <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {" "}
              {localStorage.getItem("projectGName")} - Keywords Trends
            </h3>
          </div>
          <div className="portlet-body p-0">
            <div class="common-table px-3">
              <div class="row">
                <div class="col-md-7 col-sm-12 text-right teammember-tab ">
                  <div class="input-group-icon input-group-lg widget15-compact ">
                    <div class="input-group-prepend">
                      <i class="fa fa-search text-primary"></i>
                    </div>
                    <span>
                      <Input
                        allowClear
                        type="text"
                        class="form-control w-50"
                        placeholder="Type to search..."
                        onChange={(nameSearch) => {
                          setteamList(
                            googletrendslist.filter((person) =>
                              person.fieldValue
                                .toLocaleLowerCase()
                                .includes(
                                  nameSearch.target.value.toLocaleLowerCase()
                                )
                            )
                          );
                        }}
                        id="input-s"
                      />
                    </span>
                  </div>
                </div>
                <div className="col-md-5 text-end">
                  <button
                    className="btn btn-primary add"
                    onClick={() => {
                      setSetting("googleTrendsAddUpdate");
                      history.push({
                        state: {
                          isEdit: false,
                        },
                      });
                    }}
                  >
                    Add
                  </button>

                  <input
                    style={{ display: "none" }}
                    type="file"
                    id="GoogleTrendsImportFile"
                    name="GoogleTrendsImportFile"
                    multiple={false}
                    accept={documentFileType}
                    ref={inputFileRef}
                    onChange={uploadgoogletrendsFile}
                  />

                  <a
                    className="btn btn-primary mx-2 download-temp"
                    target="_blank"
                    href={GET_Server_URL + "audit/GoogleTrends.xlsx"}
                  >
                    Download Template
                  </a>

                  <button
                    className="btn btn-primary me-2 import"
                    onClick={onBtnClick}
                  >
                    Import
                  </button>

                  <span class="dropdown d-inline">
                    <button
                      class="btn btn-primary dropdown-toggle text-right export-btn"
                      type="button"
                      onClick={() => {
                        exportToEXCEL(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Google Trends"
                        );
                      }}
                    >
                      Export
                    </button>
                    {/* <div
                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        onClick={() => {
                          exportToEXCEL(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Google Trends"
                          );
                        }}
                      >
                        <a class="dropdown-item">Excel</a>
                      </div>
                      <div
                        onClick={() => {
                          exportInToCSV(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Google Trends"
                          );
                        }}
                      >
                        <a class="dropdown-item">CSV</a>
                      </div>
                    </div> */}
                  </span>
                </div>
              </div>
              <div className="col-md-12">
                <Table
                  className="table table-bordered table-hover mt-3"
                  columns={teamcol}
                  dataSource={teamlist}
                  rowSelection={{
                    type: selectionTypeGoogleTrendtab,
                    ...rowSelection,
                  }}
                  pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>{getComponent()}</>
      )}
    </>
  );
}

export default GoogleTrendsnew;
