import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import APIManager from "../../services/APIManager";
import {
    distributiondropdown,
    organizationdata,
    piechartbandwidth
}
    from "../../utility/Constant";
import { fixTwoPlaceDecimal, mmDDyyyyFormateDateSet } from "../../utility/Globals";

function BandwidthMonitoring(props) {

    const [List, setList] = useState([]);
    const [noofproject, setNoOfProject] = useState(0);
    const [teamMembers, setTeamMembers] = useState(0);
    const [billedTeamMembers, setBilledTeamMembers] = useState(0);
    const [allocatedTeamMembers, setAllocatedTeamMembers] = useState(0);
    const [series, setSeries] = useState([0, 0])
    const [flag, setFlag] = useState(false)
    const [organizationName,setOrganizationName] = useState("")
    useEffect(() => {
        let rowData = {
            StartDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[0]),
            EndDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[1]),
            OrganizationId: localStorage.getItem("organizationId"),
        }
        let rowData1 = {
            StartDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[0]),
            EndDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[1]),
            OrganizationId: localStorage.getItem("organizationId"),
            IsBilled: 0
        }
        getOrganizationData(rowData)
        getDistributionData(rowData1);
        getpieChartData(rowData);
    }, [props?.dataParentToChild]);

    function getOrganizationData(rowData) {
        APIManager.postAPI(organizationdata, rowData, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                    setNoOfProject(response.data[0]?.noOfProjects);
                    setTeamMembers(response.data[0]?.teamMembers);
                    setBilledTeamMembers(response.data[0]?.billedTeamMembers);
                    setAllocatedTeamMembers(response.data[0]?.allocatedTeamMembers);
                    setOrganizationName(response.data[0]?.organization)
                };
            }
        );
    };

    function getDistributionData(rowData1) {
        APIManager.postAPI(distributiondropdown, rowData1, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                    let list = [];
                    response.data.map((item) => {
                        list.push({
                            point: item.counts,
                            name: item.roleName,
                        })
                    });
                    setList(list);
                };
            }
        );
    };


    function getpieChartData(chartrowData) {
        APIManager.postAPI(piechartbandwidth, chartrowData, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                    let list = []
                    if (response.data.length > 0) {
                        response.data.map((item) => {
                            list.push(fixTwoPlaceDecimal(item.available), fixTwoPlaceDecimal(item.billed))
                            if (item.available == null && item.billed == null) {
                                setFlag(false)
                            }
                            else {
                                setFlag(true)
                            }
                        })
                        setSeries(list)
                    }
                };
            }
        );
    };

    return (
        <>
            <div className="portlet mt-4 bandwidth-monitorning">
                <div className="portlet-header portlet-header-bordered">
                    <h4 className="portlet-title">Bandwidth Monitoring </h4>
                </div>
                <div className="data-chart-box py-4 px-3">
                    <div className="row overflow-hidden">
                        <div className="col-md-9 col-sm-12">
                            <div className="band-organization-data">
                                <div className="d-flex align-items-top border-bottom pb-4">
                                    <img
                                        class="band-infi-img"
                                        src="../../../images/infi.png"
                                        alt="" />
                                         {/* <div className='band-icon-circle'>
                                            <span>{organizationName.charAt(0).toUpperCase()}</span>
                                        </div> */}
                                        
                                    <div className="infi-organization ms-4">
                                        <h3>{organizationName}</h3>

                                        <div className='d-flex align-items-end my-details'>
                                            <div className="digit-text">
                                                <p>{noofproject}</p>
                                                <span>No. Of Projects</span>
                                            </div>

                                            <div className="digit-text">
                                                <p>{teamMembers}</p>
                                                <span>Team Members</span>
                                            </div>

                                            <div className="digit-text">
                                                <p>{billedTeamMembers}</p>
                                                <span>Billed Team Members</span>
                                            </div>

                                            <div className="digit-text">
                                                <p>{allocatedTeamMembers}</p>
                                                <span>Allocated Team Members</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex align-items-center bandwidth-distribution my-4">
                                    <div className="text">
                                        <h3 className="mb-0">Bandwidth Distribution for</h3>
                                    </div>
                                    <div className="select-box">
                                        <select
                                         className="form-select" id="band" 
                                            onChange={(e) => {
                                                let rowData1 = {
                                                    StartDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[0]),
                                                    EndDate: mmDDyyyyFormateDateSet(props?.dataParentToChild[1]),
                                                    OrganizationId: localStorage.getItem("organizationId"),
                                                    IsBilled: +e.target.value
                                                }
                                                getDistributionData(rowData1);

                                                let ghostSelect = document.createElement('select');
                                                const select = document.getElementById('band');
                                                var x = select.options[select.selectedIndex].text;

                                                const ghostOption = document.createElement("option");
                                                ghostOption.setAttribute("value", x);
                                                var t = document.createTextNode(x);
                                                ghostOption.appendChild(t);
                                                ghostSelect.appendChild(ghostOption);
                                                window.document.body.appendChild(ghostSelect)
                                                select.style.width = ghostSelect.offsetWidth + 'px';
                                                window.document.body.removeChild(ghostSelect)
                                            }}
                                        >
                                            <option value="0">Billed Team Members</option>
                                            <option value="1">Available Team Members</option>
                                        </select>
                                    </div>
                                </div>

                                <div className="d-flex align-items-top band-dist-data table-responsive global-scrollbar" >
                                    {List.map((item) => {
                                        return (<>
                                            <div className="dist-border ">
                                                <p>{item.point}</p>
                                                <span>{item.name}</span>
                                            </div>
                                        </>)
                                    })}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 col-sm-12">
                            <div className="band-organization-chart d-flex align-items-center justify-content-center">
                                {flag ?
                                    (<Chart
                                        options={{
                                            legend: "true",
                                            labels: ['Available', 'Billed'],
                                            colors: [
                                                "#FFBC4A",
                                                "#59D866"
                                            ],
                                            width: "100%",
                                            // foreColor:"#fff",
                                            dataLabels: {
                                                enabled: true,
                                                // style: {
                                                //     fontSize: '16px',
                                                //     fontWeight: 'bold',
                                                //     position:'absolute',
                                                //     bottom:'5px',
                                                // },
                                                formatter: function (value, opts) {
                                                    return [opts.w.globals.labels[opts.seriesIndex], value + '%']
                                                }
                                            },
                                            states: {
                                                hover: {
                                                 filter: {
                                                    type: 'none'
                                                  }
                                                }
                                              },
                                              stroke: {
                                                show: false,
                                                   
                                            }
                                            // responsive: [{
                                            //     breakpoint: 1300,
                                            //     options: {
                                            //       chart: {
                                            //         width: 300
                                            //       }
                                            //     },
                                            //     breakpoint: 480,
                                            //     options: {
                                            //       chart: {
                                            //         width: 200
                                            //       }
                                            //     }
                                            //   }]
                                        }}
                                        series={series}
                                        type="pie"
                                        width={290}
                                    >
                                    </Chart>
                                    ) : (
                                        <div className="my-5 no-data">
                                            <svg
                                                class="ant-empty-img-simple"
                                                width="64"
                                                height="41"
                                                viewBox="0 0 64 41"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <g
                                                    transform="translate(0 1)"
                                                    fill="none"
                                                    fill-rule="evenodd"
                                                >
                                                    <ellipse
                                                        class="ant-empty-img-simple-ellipse"
                                                        cx="32"
                                                        cy="33"
                                                        rx="32"
                                                        ry="7"
                                                    ></ellipse>
                                                    <g
                                                        class="ant-empty-img-simple-g"
                                                        fill-rule="nonzero"
                                                    >
                                                        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                                        <path
                                                            d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
                                                            class="ant-empty-img-simple-path"
                                                        ></path>
                                                    </g>
                                                </g>
                                            </svg>
                                            <span className="d-block text-center"> No data</span>
                                        </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BandwidthMonitoring