import React from "react";
import { useState, useEffect, useRef } from "react";
import { Calendar } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import APIManager from "../../services/APIManager";
import { Table, Drawer } from "antd";
import { ProgressBar } from "react-bootstrap";
import {
  getDaysInMonth,
  convertDate,
  setMonths,
  findUnique,
  removeTrailingSlash,
  ArticleStatus,
  mmDDyyyyFormateDateSet
} from "../../utility/Globals";
import {
  getEditor,
  getrequirementlist,
  getWriter,
  onlyXlsFile,
  requirementBulkImport,
  AssignWriter,
  AssignEditor,
  GET_Server_URL,
  updateRequirementStatus,
  requirementById,
  getProjectById,
} from "../../utility/Constant";
import { Link, useHistory } from "react-router-dom";

function RequirementDetails(props) {
  const ref = useRef();
  const [isPOCAssigned, setIsPOCAssigned] = useState(props.history.location?.state?.poc ? false : true);
  const [projectname, setProjectName] = useState(props.history.location?.state?.projectname);
  const [open, setOpen] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [className1, setClassName1] = useState("");
  const [url, setUrl] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [fileSetelctedMsg, setFileSetelctedMsg] = useState("No File Selected");
  const [fileName, setFileName] = useState("");
  const [requirementData, setRequirementData] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [size, setSize] = useState();
  const [sizeEdit, setSizeEdit] = useState();
  const [writeropen, setWriterOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [RequirementColumns, setRequirementColumns] = useState("")
  const [projectID, setProjectId] = useState(props.location.state?.projectId ?? '');
  const [head1, setHead1] = useState(convertDate((new Date().getMonth() + 1) + "-01-" + (new Date().getFullYear())));
  const [head2, setHead2] = useState(convertDate(convertDate((new Date().getMonth() + 1) + "-" + getDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1) + "-" + (new Date().getFullYear()))));
  const [monthYear, setMonthYear] = useState();
  const [List, setList] = useState("");
  const [RequirementRaised, setRequirementRaised] = useState("");
  const [ArticlesCompleted, setArticlesCompleted] = useState("");
  const [Rework, setRework] = useState("");
  const [errors, setErrors] = useState();
  const [editorList, setEditorList] = useState([]);
  const [writerList, setWriterList] = useState([]);
  const [writerId, setWriterID] = useState('');
  const [editorId, setEditorID] = useState('');
  const [costPerWord, setCostPerWord] = useState(0);
  const [eta, setETA] = useState("");
  const [requirementId, setRequirementId] = useState(props.location.state?.requirementId);
  const history = useHistory();
  var param = []
  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    param = props.location.state;
    setProjectId(props.location.state?.projectId)
    setMonthYear(setMonths(new Date(param?.startDate ?? new Date()).getMonth() + 1) + " " + new Date(param?.startDate ?? new Date()).getFullYear());
    if(param.requirementId)
    {
      getRequirementDetail();
    }
    else{
      setHead1(param?.startDate)
      setHead2(param?.endDate);
      requirementlist();
    }
    
  }, []);

  const onClose = () => {
    setOpen1(false);
    setWriterOpen(false);
    setEditorOpen(false);
    setIsBulk(false);
    setClassName1("");
    setEditorList([]);
    setWriterList([]);
  };

  const showLargeDrawer = () => {
    setSize("large");
    setOpen1(true);
  };

  const showLargeDrawer1 = () => {
    setSize("large");
    setWriterOpen(true);
  };

  const showLargeDrawerEdit = () => {
    setSizeEdit("large");
    setEditorOpen(true);
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => { },
  };

  const getRequirementDetail = () => {
    APIManager.getAPI(requirementById + requirementId).then((res) => {
      setProjectId(res.data.value.projectId);
      requirementlist(res.data.value.projectId);
      getProjectByID(res.data.value.projectId);
    });
  }

  const getProjectByID = (id) => {
    APIManager.getAPI(getProjectById + id).then((res) => {
      setIsPOCAssigned(true);
      setProjectName(res.data.value.projectCode);  
    })
  }

  function uploadRequirementFile() {
    var input = document.getElementById("requirementFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileName(file.name);
  }

  function requirementlist(id = null) {
    if (head1) {
      APIManager.postAPI(
        getrequirementlist + '?projectId=' + (id ?? projectID) + '&startDate=' + head1 + '&endDate=' + head2
      ).then((response) => {
        if (response.status == 200) {
          const list = [];
          setList(response.data.table[0].targetForTheMonth);
          setRequirementRaised(response.data.table1[0].requirementRaised);
          setArticlesCompleted(response.data.table2[0].articlesCompleted);
          setRework(response.data.table3[0].rework);
          response.data.table4.map((item, index) => {
            let obj = {
              key: item.id,
              id: '#' + item.id,
              keyword: (

                <>
                  <Link
                    to={{
                      pathname: "/content-main/requirement-keyword-details",
                      state: {
                        isEdit: true,
                        requirementId: item.requirementId,
                        keywordId: item.id,
                        writerId: item.writerId,
                        editorId: item.editorId,
                      },
                    }}
                  >
                    <span className="keyword_detail">
                      {item.keyword}</span>
                  </Link>
                </>
              ),
              requirementId: item.requirementId,
              editer: item.editor,
              writer:
                <div className="d-flex align-items-center writer-select input_outer">
                  <input className=" medium-selected assign-t-span"
                    aria-label="Default select example"
                    value={item.writer && item.writer != " " ? item.writer : "Select"}
                    onClick={(i) => {
                      showLargeDrawer1();
                      setCloseButton(false);
                      getAllWriter(item.writerId);
                      setWriterID(item?.writerId);
                      setRequirementId(item.requirementId ?? '');
                    }}
                  />

                  <img src="/images/chevron-right.svg" alt="#" />
                </div>,

              editor: <div className="d-flex align-items-center writer-select">
                <input className="medium-selected"
                  aria-label="Default select example"
                  disabled={item.status != null ? (item.status > 3 ? false : true ) : true}
                  value={item.editor && item.editor != " " ? item.editor : "Select"}
                  onClick={(i) => {
                    let flag = item.writer ? true : false
                    if (flag) {
                      showLargeDrawerEdit();
                      setCloseButton(false);
                      getAllEditor(item.editorId);
                      setEditorID(item?.editorId);
                      setRequirementId(item.requirementId ?? '');
                    }
                  }}
                />
                <img src="/images/chevron-right.svg" alt="#" />
              </div>,
              status: item.status,
              statusbar: <div className="d-flex align-items-center justify-content-between progress_bar">
                <label>
                  <div className="progress_report mb-1">
                    <p className="p-0 m-0"> {ArticleStatus.map((article) => (article.value == item.status) ? article.text : '')} </p>
                    <div><svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock me-2 ms-4"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                      <span className="">ETA : 24 Jan 2023</span></div>
                  </div>
                  <ProgressBar className="assign-writer-progress requirement-progreesbar">
                    <ProgressBar
                      now={(item.status) * 10}
                      key={1} />
                  </ProgressBar>
                </label></div>,
              repeatMenu:
                <>
                  <div className="d-flex align-items-center require-repeat">
                    <img src="/images/black-repeat.svg" alt="repeat menu"></img>
                    <span className="ms-2">00</span>
                  </div>

                </>,
              kababMenu:
                <img className="require-kabab" src="/images/more-vertical.svg" alt="kabab menu" />
            }
            list.push(obj);
          });
          setRequirementData(list);

          var data = [
            {
              title: "ID",
              dataIndex: "id",
              key: "id",
              width: "10%",
            },
            {
              title: "KEYWORD",
              dataIndex: "keyword",
              key: "keyword",
              width: "15%",
            },
            {
              title: "WRITER",
              dataIndex: "writer",
              key: "writer",
              width: "15%",
            },
            {
              title: "EDITOR",
              dataIndex: "editor",
              key: "editor",
              width: "15%",
            },
            {
              title: "STATUS",
              dataIndex: "statusbar",
              key: "statusbar",
              filters: findUnique(ArticleStatus, (d) => d.text),
              filterSearch: true,
              onFilter: (value, record) => {
                return record.status == value;
              },

            },
            {
              title: "",
              dataIndex: "repeatMenu"
            },
            {
              title: "",
              dataIndex: "kababMenu"
            }
          ];
          setRequirementColumns(data);

        }
      });
    }

  }

  function uploadRequirementFile() {
    var input = document.getElementById("requirementFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      document.querySelector(
        "#textInputUrl"
      ).disabled = true;
      setCloseButton(true)
    }
    else {
      document.querySelector(
        "#textInputUrl"
      ).disabled = false;
      setCloseButton(false)
    }
    setFileName(file.name);
  }


  function addRequirementInBulk() {
    var bodyFormData = new FormData();
    var input = document.getElementById("requirementFile");
    let file = input.files[0];
    let isImport = true;
    bodyFormData.append("ProjectId", projectID)
    bodyFormData.append('CreatedBy', localStorage.getItem('userId'));
    if (document.querySelector("#textInputUrl").disabled == false && isValidURL()) {
      bodyFormData.append("url", removeTrailingSlash(url));
      isImport = true;
    }
    else if (document.querySelector("#requirementFile").disabled == false) {
      isImport = true;
      bodyFormData.append("file", file);
    }
    else {
      isImport = false;
    }

    if (isImport) {
      APIManager.postAPI(
        requirementBulkImport,
        {},
        bodyFormData,
        false
      ).then((response) => {
        if (response.status == 200) {
          onClose()
          setErrors("")
          setUrl("")
          requirementlist();
        }
      });
    }
  }
  function isValidURL() {
    let error = { ...errors };
    if (url.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
        // "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?$"
      );
      if (!pattern.test(url.trim())) {
        error["url"] = "Please enter valid URL";
      } else {
        delete error["url"];
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  const getAllEditor = (id) => {
    APIManager.getAPI(
      getEditor + '?organizationId=' + localStorage.getItem('organizationId')
    ).then((response) => {
      if (response.status == 200) {
        setEditorList(response.data);
        response.data.map((item) => {
          if (item.id == id) {
            setCostPerWord(item.costPerWord ?? 0);
            setETA(item.eta ?? new Date());
          }
        })
      }
    })
  }

  const getAllWriter = (id) => {
    APIManager.getAPI(
      getWriter + '?organizationId=' + localStorage.getItem('organizationId')
    ).then((response) => {
      if (response.status == 200) {

        setWriterList(response.data);
        response.data.map((item) => {
          if (item.id == id) {
            setCostPerWord(item.costPerWord ?? 0);
            setETA(item.eta ?? new Date());
          }
        })
      }
    });
  }

  function formValidation() {
    let error = { ...errors };
    eta.trim() == ""
      ? (error["eta"] = "ETA is required!")
      : delete error["eta"];
    mmDDyyyyFormateDateSet(eta.trim()) < mmDDyyyyFormateDateSet(new Date())
      ? (error["eta"] = "ETA is not vaild!")
      : delete error["eta"];

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setCostPerWord("");
    setETA("");
  }

  function assignWriterData(rowdata) {
    APIManager.postAPI(AssignWriter, rowdata, null, true).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        onClose();
        requirementlist();
        requirementStatusUpdate(0);
      }
    })
  }

  function assignEditorData(rowData) {
    APIManager.postAPI(AssignEditor, rowData, null, true).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        onClose();
        requirementlist();
        requirementStatusUpdate(4);
      }
    })
  }

  function getcostandETA(id, selectedIndex) {
    if (editorList != []) {
      editorList.map((item, index) => {
        if (id == item.id && index == selectedIndex - 1) {
          setCostPerWord(item.costPerWord ?? 0);
          setETA(item.eta ?? new Date());
        }
      })
    }
    if (writerList != []) {
      writerList.map((item) => {
        if (id == item.id) {
          setCostPerWord(item.costPerWord ?? 0);
          setETA(item.eta ?? new Date());

        }
      })
    }

  }

  const requirementStatusUpdate = (status) => {
    APIManager.putAPI(updateRequirementStatus + "?requirementId=" + requirementId + "&status=" + status).then((res) => {
    })
  }
  return (
    <>
      {/* <div className="title"> */}
      <div className="portlet breadcum_top p-3 ps-4 ms-1" onClick={() => { history.goBack(); }}>{projectname}</div>

      <div class="portlet requirement-details mb-3">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-start ">
            <div ref={ref} className="d-flex align-items-center  requirement-details-cal" >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-calendar me-2"
              >
                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="16" y1="2" x2="16" y2="6"></line>
                <line x1="8" y1="2" x2="8" y2="6"></line>
                <line x1="3" y1="10" x2="21" y2="10"></line>
              </svg>
              <div
                onClick={() => setOpen(!open)}
              >

                {monthYear}
              </div>
              {open && (
                <div className="scope-calendar">
                  <div id="example-collapse-text-calendar">
                    <Card body className="daterange-picker-card  mt-2">
                      <div className="d-flex justify-content-between">
                        <div className="calendar-col">
                          <Calendar
                            date={new Date()}
                            onlyMonthPicker
                            onChange={(e) => {

                              var date1 = e.format().split("/");
                              var daysInMonth = getDaysInMonth(
                                date1[1],
                                date1[0]
                              );
                              let dateObjArr = [
                                new Date(date1[0] + "/01/" + date1[1]),
                                new Date(
                                  date1[0] +
                                  "/" +
                                  daysInMonth +
                                  "/" +
                                  date1[1]
                                ),
                              ];
                              setMonthYear(setMonths(dateObjArr[0].getMonth() + 1) + " " + date1[1]);
                              setHead1(
                                convertDate(dateObjArr[0].toLocaleDateString())
                              );
                              setHead2(
                                convertDate(dateObjArr[1].toLocaleDateString())
                              );
                            }}

                          />
                        </div>
                        <div className="ms-3">
                          <button
                            className="btn-outline btn btn-primary"
                            onClick={() => {
                              setOpen(!open);
                              requirementlist();
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              )}

              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end raise-btn ">
            <a
              class="btn btn-primary scope-download h-auto me-3 gray_btn"
              target="_blank"
              href={GET_Server_URL + "audit/Requirement.xlsx"}
            >
              Download Template{" "}
            </a>
            <button
              class="d-flex align-items-center btn-outline btn btn-primary"
              type="button"
              disabled={isPOCAssigned}
              onClick={() => {
                setIsBulk(false);
                setFileName("");
                showLargeDrawer();
                setUrl("");
                setClassName1("");
                setCloseButton(false);
                const option1Btn = document.getElementById("option1");
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus me-1"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              <div>Raise Requirement</div>
            </button>
            <Drawer
              title=" Raise Requirement"
              className=" industries-modal add-industries freelancer-drawer"
              placement="right"
              size={size}
              onClose={onClose}
              open={open1}
            >
              <div class="modal-dialog scope-modal" role="document">
                <div class="modal-content h-100">
                  <div class="modal-body p-0">
                    <div className="portlet common-form-fields border-0">
                      <div className="">
                        <div className={className1}>
                          <div className="radio">
                            <div className="ms-3">
                              <input
                                className="me-3"
                                type="radio"
                                value="option1"
                                id="option1"
                                name="uploadType"
                                checked={isBulk}
                                onClick={(e) => {
                                  setIsBulk(e.target.checked);
                                  setClassName1("active");
                                }}
                              />
                              <label className="bulk-upload  main-title mb-1">
                                Bulk Upload
                              </label>
                            </div>
                            <div className="ms-5">
                              <span className="text-grey ">
                                Setup multiple (bulk) upload with the details by
                                uploading Google Sheet link/.xlsx/.csv file
                              </span>
                            </div>
                            {isBulk ? (
                              <>
                                <div className="border-top mt-3 pt-3 industries-box freelancer-box ">
                                  <span className="ms-4 industries-csv-file">
                                    Add URL (Google Sheet Link)
                                  </span>
                                  <div className="mt-2 ms-4 me-3">
                                    <input
                                      className="form-control w-100"
                                      placeholder="URL"
                                      id="textInputUrl"
                                      value={url}
                                      onChange={(e) => {
                                        setUrl(e.target.value);
                                        if (e.target.value.length > 0) {
                                          document.querySelector(
                                            "#requirementFile"
                                          ).disabled = true;
                                        } else {
                                          setErrors("");

                                          document.querySelector(
                                            "#requirementFile"
                                          ).disabled = false;
                                        }
                                      }}
                                    ></input>
                                  </div>
                                  <div className="my-3 text-center or">
                                    <span>OR</span>
                                  </div>
                                  <span className="ms-4 industries-csv-file">
                                    Add Attachment (Only .xlsx or .csv file)
                                  </span>
                                </div>
                                <div className="industries-choose-file d-flex align-items-center p-2 mt-1 ms-4 me-3 ps-3">
                                  <input
                                    className="w-100"
                                    style={{ display: "none" }}
                                    type="file"
                                    id="requirementFile"
                                    name="requirementFile"
                                    multiple={false}
                                    accept={onlyXlsFile}
                                    ref={inputFileRef}
                                    onChange={uploadRequirementFile}
                                  />
                                  <a
                                    onClick={onBtnClick}
                                    id="btnChooseFile"
                                    className="btn btn-primary me-3"
                                  >
                                    Choose File
                                  </a>
                                  <span className="file-not-select ">
                                    {fileName.length ? fileName : fileSetelctedMsg}
                                  </span>
                                  {closeButton ? (
                                    <>
                                      <button
                                        type="button"
                                        title="Close"
                                        style={{
                                          border: "0",
                                          background: "none",
                                        }}
                                        class="close"
                                        onClick={() => {
                                          setCloseButton(false);
                                        }}
                                      >
                                        {" "}
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="btn-box-bottom">
                        <div className="col-md-12  d-flex justify-content-end">
                          <div class="portlet-footer portlet-footer-bordered mt-3 text-end scopebtn">
                            <button
                              className="btn btn-outline-secondary close me-3"
                              type="button"
                              onClick={onClose}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary w-auto"
                              onClick={() => {
                                addRequirementInBulk();
                              }}
                            > 
                              <div> 
                              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                              Raise Requirement</div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="d-flex align-items-center justify-content-between p-0">
          <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-target"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="6"></circle>
                <circle cx="12" cy="12" r="2"></circle>
              </svg>
            </div>
            <div>
              <label className="form-label">Target for the Month</label>
              <h3>
                {List}
              </h3>
            </div>
          </div>

          <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3 mx-3">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-trending-up"
              >
                <polyline points="23 6 13.5 15.5 8.5 10.5 1 18"></polyline>
                <polyline points="17 6 23 6 23 12"></polyline>
              </svg>
            </div>
            <div>
              <label className="form-label">Requirement Raised </label>
              <h3>
                {RequirementRaised}
              </h3>
            </div>
          </div>
          <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-file-text"
              >
                <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" y1="13" x2="8" y2="13"></line>
                <line x1="16" y1="17" x2="8" y2="17"></line>
                <polyline points="10 9 9 9 8 9"></polyline>
              </svg>
            </div>
            <div>
              <label className="form-label">Articles Completed </label>
              <h3>
                {ArticlesCompleted}
              </h3>
            </div>
          </div>
          <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3 ms-3">
            <div className="raise-svg me-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#0691EF"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-repeat"
              >
                <polyline points="17 1 21 5 17 9"></polyline>
                <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                <polyline points="7 23 3 19 7 15"></polyline>
                <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
              </svg>
            </div>
            <div>
              <label class="form-label">Rework </label>
              <h3>{Rework}</h3>
            </div>
          </div>
        </div>
        {requirementData.length > 0 ? (
          <div class="col-md-12 mt-3  p-0">
            <Table
              className="table table-hover requirement-content-table custome_table "
              id="sample-module-expand"
              columns={RequirementColumns}
              dataSource={requirementData}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 10,
                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} keywords`
              }}
            />
            {/* Writer */}
            <Drawer
              title="Assign Writer"
              className=" industries-modal add-industries assign-writer-drawer"
              placement="right"
              size={size}
              onClose={onClose}
              open={writeropen}
            >
              <div
                class="modal-dialog scope-modal industries-form"
                role="document"
              >
                <div class="modal-content">
                  <div className="col-md-12  industries-ticket-btn">
                    <>
                      <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                        <button
                          className="btn btn-outline-secondary close me-3"
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary w-auto"
                          onClick={() => {
                            let rowdata;
                            rowdata = {
                              RequirementId: requirementId,
                              WriterId: writerId,
                              CostPerWord: costPerWord,
                              ETA: eta,
                              CreatedBy: localStorage.getItem('userId')
                            };
                            assignWriterData(rowdata);
                            requirementlist();
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                          Assign Writer
                        </button>
                      </div>
                    </>
                  </div>
                  <div class="modal-body p-0 drawer-box">
                    <div className="portlet common-form-fields border-0">
                      <div className="px-0">
                        <div className="portlet-body industries-individual px-1">
                          <div class="col-md-12 ">
                            <label className="form-label mb-0">Writer</label>
                            <select className=" w-100 form-select writerselect"
                              value={writerId}
                              onChange={(e) => {
                                let selectedIndex = e.target.selectedIndex;
                                writerList.map((item, index) => {
                                  if (e.target.value == item.id && index == selectedIndex - 1) {
                                    setCostPerWord(item.costPerWord != null ? item.costPerWord : "");
                                    setETA(item.eta != null ? item.eta : new Date());
                                  }
                                })
                                setWriterID(e.target.value);

                              }}
                            >
                              <option value=''>--Select--</option>
                              {writerList.map((item) => {
                                return <option value={item.id}>{item.name}</option>
                              })}

                            </select>
                          </div>
                          <div class="col-md-12 mt-3">
                            <label className="form-label mb-0">Cost per Word</label>
                            <input className="w-100 "
                              type="text"
                              value={writerId ? costPerWord : 0}
                              onChange={(e) => {
                                setCostPerWord(e.target.value);
                              }}
                            />
                          </div>
                          <div class="col-md-12 mt-3 writer-eta">
                            <label className="form-label mb-0">ETA</label>
                            <input className="w-100 "
                              type="date"
                              name="eta"
                              value={writerId ? convertDate(eta) : ""}
                              min={mmDDyyyyFormateDateSet(new Date())}
                              onBlur={() => {
                                formValidation();
                              }}
                              onChange={(e) => {
                                setETA(e.target.value);
                                formValidation();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>

            {/* editor */}
            <Drawer
              title="Assign Editor"
              className=" industries-modal add-industries assign-editor-drawer"
              placement="right"
              size={sizeEdit}
              onClose={onClose}
              open={editorOpen}
            >
              <div
                class="modal-dialog scope-modal industries-form"
                role="document"
              >
                <div class="modal-content">
                  <div className="col-md-12  industries-ticket-btn">
                    <>
                      <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                        <button
                          className="btn btn-outline-secondary close me-3"
                          type="button"
                          onClick={onClose}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary w-auto"
                          onClick={() => {
                            let rowData;
                            rowData = {
                              RequirementId: requirementId,
                              EditorId: editorId,
                              CostPerWord: costPerWord,
                              ETA: eta,
                              CreatedBy: localStorage.getItem('userId')
                            };
                            assignEditorData(rowData);
                            requirementlist();
                          }}

                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                          Assign Editor
                        </button>
                      </div>
                    </>
                  </div>
                  <div class="modal-body p-0 drawer-box">
                    <div className="portlet common-form-fields border-0">
                      <div className="px-0">
                        <div className="portlet-body industries-individual px-1">
                          <div class="col-md-12">
                            <label className="form-label mb-0">Editor</label>
                            <select className=" w-100 form-select writerselect"
                              value={editorId}
                              onChange={(e) => {
                                setEditorID(e.target.value);
                                getcostandETA(e.target.value, e.target.selectedIndex);
                              }}
                            >
                              <option value=''>--Select--</option>
                              {editorList.map((item) => {
                                return <option value={item.id}>{item.name}</option>
                              })}

                            </select>
                          </div>
                          <div class="col-md-12 mt-3">
                            <label className="form-label mb-0">Cost per Word</label>
                            <input className="w-100 "
                              type="text"
                              value={editorId ? costPerWord : 0}
                              onChange={(e) => {
                                setCostPerWord(e.target.value);
                              }}
                            />
                          </div>
                          <div class="col-md-12 mt-3">
                            <label className="form-label mb-0">ETA</label>
                            <input className="w-100 "
                              type="date"
                              name="eta"
                              value={editorId ? convertDate(eta) : ""}
                              min={mmDDyyyyFormateDateSet(new Date())}
                              onBlur={() => {
                                formValidation();
                              }}
                              onChange={(e) => {
                                setETA(e.target.value);
                                formValidation();
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
          </div>
        ) : (
          <>
            <div className="text-center no-requirement-msg">
              <div>
                <span className="text-center">No Requirements Found </span>
              </div>
              <button
                class="btn btn-primary text-center mt-2"
                type="button"
                disabled={isPOCAssigned}
                onClick={() => {
                  setIsBulk(false);
                  setFileSetelctedMsg("No File Selected");
                  setFileName("");
                  showLargeDrawer();
                  showLargeDrawerEdit();
                  setUrl("");
                  setClassName1("");
                  setCloseButton(false);
                  const option1Btn = document.getElementById("option1");
                }}
              >
                <div className="blue-plus-icon d-flex align-items-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus me-1"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
                  <div>Raise Requirement</div>
                </div>

              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default RequirementDetails;
