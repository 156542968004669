import { useState, useEffect } from "react";
import APIManager from "../../../services/APIManager";
import { changeReasonSOW } from "../../../utility/Constant";


function SOWApproval() {
    useEffect(() => {
        const paramss = new URLSearchParams(window.location.search);
        let sowId = paramss.get("sowDetailId");
        setSowDetailId(sowId);        
      }, []);
    const [approvalReason, setApprovalReason] = useState("");
    const [sowDetailId, setSowDetailId] = useState("")
    
    function  sendApprovalReason() {
        let rowData ={
            sowDetailId : sowDetailId,
            reason : approvalReason
        }
        APIManager.postAPI(changeReasonSOW, rowData, null, true).then(
            (response) => {
                if (response && response.status == 200) {
                }
            }
        );
    }

    return (
        <>
            <div className="custom-column-70">
                <div class="portlet-header portlet-header-bordered">
                    <h3 class="portlet-title main-title">
                        Reason
                    </h3>
                </div>
                <div className="portlet-body px-0">
                    <div className="col-md-6 mt-3">
                        <label className="form-label">Add Reason Here</label>

                        <textarea
                            className="form-control"
                            name="name"
                            value={approvalReason}
                            onChange={(text) => {
                                setApprovalReason(text.target.value);
                            }}
                        >
                        </textarea>
                    </div>
                    <div className="row">
                        <div className="col-md-10 my-2">
                            <button
                                type="button"
                                class="btn btn-primary"
                                style={{
                                    width: 70 + "px",
                                    height: 30 + "px",
                                }}
                                onClick={sendApprovalReason}
                            >
                                Send
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SOWApproval;