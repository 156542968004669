import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "antd/dist/antd.css";
import {
  createCustomer,
  updateCustomer,
  getClient,
  getProjectByClientId,
} from "../utility/Constant";
import APIManager from "../services/APIManager";
import { default as ReactSelect, components } from "react-select";
import { themeStyle, selectAllList } from "../utility/Globals";
import { toast } from "react-hot-toast";

function TeamMembersSalesDirAddUpdateCustomer({ update }) {
  const [Name, setName] = useState("");
  const [Customercode, setCustomercode] = useState("");
  const [CustomerEmailid, setCustomerEmailid] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [CustomerList, setCustomerList] = useState([]);
  const [ProjectList, setProjectList] = useState([]);
  const [Clientselected, setClientselected] = useState("");
  const [Projectselected, setProjectselected] = useState([]);
  const [errors, setErrors] = useState({});
  const [AuthenticationType, setAuthenticationType] = useState("0");
  const history = useHistory();
  const location = useLocation();
  const [isUpdateTM, setUpdateTMflag] = useState(false);
  const [save, setsave] = useState(false);
  const [oldProjectList, setOldProjectList] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  useEffect(() => {
    getClientList();
    if (location.state.isUpdate) {
      let selectedListData = location.state.set_projectId
        ? location.state.set_projectId
        : Projectselected;
      setUpdateTMflag(true);
      setClientselected(location.state.set_clientId);
      setProjectselected(selectedListData);
      setName(location.state.set_Name);
      setCustomercode(location.state.set_Customercode);
      setCustomerEmailid(location.state.set_CustomerEmailid);
      setPhoneNumber(location.state.set_PhoneNumber);
      setAuthenticationType(location.state.set_AuthenticationType);
      getProjectlist(location.state.set_clientId);
    } else {
      setUpdateTMflag(false);
    }
  }, []);

  function formValidation() {
    let error = { ...errors };
    Name.trim() == ""
      ? (error["Name"] = "Customer name is required!")
      : delete error["Name"];
    // if (Name.trim()?.length) {
    //   //var pattern = new RegExp(/^[a-zA-Z-\'\\s]*$/);
    //   var pattern = new RegExp(/^[a-zA-Z-\s]+([']?\w+)*$/);
    //   // var pattern = new RegExp(/^[a-zA-Z\s]*$/);

    //   !pattern.test(Name.trim())
    //     ? (error["Name"] = "Please enter valid Customer name")
    //     : delete error["Name"];
    // }
    Customercode.trim() == ""
      ? (error["Customercode"] = "Customer code is required!")
      : delete error["Customercode"];

    CustomerEmailid.trim() == ""
      ? (error["email"] = "Employee email is required!")
      : delete error["email"];
    if (CustomerEmailid?.length) {
      var pattern = new RegExp(/^\w+([-+.']?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
      // var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
      if (pattern?.test(CustomerEmailid) == false) {
        error["email"] = "Please enter valid Email!";
      }
    }
    PhoneNumber.toString().trim() == ""
      ? (error["PhoneNumber"] = "Customer phone number is required!")
      : delete error["PhoneNumber"];
    if (PhoneNumber?.length) {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(PhoneNumber)) {
        error["PhoneNumber"] = "Please enter only numbers!";
      } else if (PhoneNumber.length >= 15) {
        error["PhoneNumber"] =
          "Phone number must not exceed more than 15 characters!";
      } else if (PhoneNumber.length < 10) {
        error["PhoneNumber"] =
          "Phone number must be of at least 10 characters!S";
      } else {
        delete error["PhoneNumber"];
      }
    }

    AuthenticationType.toString().trim() == ""
      ? (error["AuthenticationType"] = "Please select AuthenticationType!")
      : delete error["AuthenticationType"];

    Projectselected.length == 0
      ? (error["Projectselected"] = "Please select a project!")
      : delete error["Projectselected"];

    Clientselected.trim() == 0
      ? (error["Clientselected"] = "Please select a client!")
      : delete error["Clientselected"];

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function getClientList() {
    APIManager.getAPI(getClient).then((response) => {
      if (response && response.status == 200) {
        setCustomerList(response.data);
      }
    });
  }

  function getProjectlist(projId) {
    APIManager.getAPI(getProjectByClientId + projId).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          count: 0,
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          project: "Select All",
        });

        response.data.map((item) => {
          let tempArray = {
            key: item.id,
            id: item.id,
            value: item.projectCode,
            label: item.projectCode,
            project: item.projectCode,
          };
          list.push(tempArray);
        });

        setProjectList(list);
      }
    });
  }

  function addUser() {
    let projectId = [];
    Projectselected.map((item) => {
      if (item.id != "0") {
        projectId.push(item.id);
      }
    });
    APIManager.postAPI(
      createCustomer,
      {
        clientID: Clientselected,
        projectIds: projectId,
        organizationId: localStorage.getItem("organizationId"),
        customerName: Name.trim(),
        customerCode: Customercode.trim(),
        customerEmail: CustomerEmailid,
        AuthenticationType: AuthenticationType,
        PhoneNumber: PhoneNumber.trim(),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        notifyAdd();
      }
    });
  }

  function updateUser() {
    let projectId = [];
    Projectselected.map((item) => {
      if (item.id != "0") {
        projectId.push(item.id);
      }
    });
    APIManager.putAPI(
      updateCustomer,
      {
        clientID: Clientselected,
        projectIds: projectId,
        organizationId: localStorage.getItem("organizationId"),
        customerName: Name.trim(),
        customerCode: Customercode.trim(),
        customerEmail: CustomerEmailid.trim(),
        AuthenticationType: AuthenticationType,
        PhoneNumber: parseInt(PhoneNumber),
        password: location.state?.set_Password,
        userId: location.state.set_UserId,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearForm();
      }
    });
  }

  function clearForm() {
    setName("");
    setCustomercode("");
    setCustomerEmailid("");
    setAuthenticationType("0");
    setPhoneNumber("");
    // history.goBack();
    update("user");
  }

  return (
    <>
      {/* <div class="row">
        <div class="col-sm-5 pad-lzero p-0">
          <div className="col-md-12 d-flex mb-3 p-0">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                update('user')
                // history.goBack();
              }}
            ></i>
          </div>
        </div>
        <div class="col-sm-7 add-new-btnw"></div>
      </div> */}

      <div className="portlet slideInUp setting-top">
        <div class="portlet-header portlet-header-bordered">
        <svg  onClick={() => {
              update("user");
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("organizationName")} -{" "}
            {isUpdateTM ? "Update " : "Add "}Customer
          </h3>

          {/* <button
            type="button"
            title="Close"
            style={{ border: "0", background: "none" }}
            class="close"
            // data-dismiss="modal"
            // aria-label="Close"
            onClick={() => {
              update("user");
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>
        <div className="portlet-body">
          <div class="common-wcard">
            <div class="common-form-fields">
              <div class="add-user">
                <div class="col-md-12">
                  <label> Name</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    value={Name}
                    onChange={(text) => {
                      setName(text.target.value);
                      formValidation();
                    }}
                    placeholder=""
                  />
                </div>
                {save && errors?.Name && (
                  <>
                    <div className="field-required">
                      <span> {errors?.Name}</span>
                    </div>
                  </>
                )}
                <div class="col-md-12 mt-3">
                  <label>Code</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    value={Customercode}
                    onChange={(text) => {
                      setCustomercode(text.target.value);
                      formValidation();
                    }}
                    placeholder=""
                  />
                </div>
                {save && errors?.Customercode && (
                  <>
                    <div className="field-required">
                      <span> {errors?.Customercode}</span>
                    </div>
                  </>
                )}
                <div class="col-md-12 mt-3">
                  <label>Phone Number</label>
                  <label class="field-required"> * </label>
                  <br />
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      value={PhoneNumber}
                      required
                      onChange={(text) => {
                        setPhoneNumber(text.target.value);
                        formValidation();
                      }}
                      name=""
                      placeholder=""
                    />
                  </div>
                </div>
                {save && errors?.PhoneNumber && (
                  <>
                    <div className="field-required">
                      <span> {errors?.PhoneNumber}</span>
                    </div>
                  </>
                )}
                <div class="col-md-12 mt-3">
                  <label>Email</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    value={CustomerEmailid}
                    onChange={(text) => {
                      setCustomerEmailid(text.target.value);
                      formValidation();
                    }}
                    placeholder=""
                  />
                </div>
                {save && errors?.email && (
                  <>
                    <div className="field-required">
                      <span> {errors?.email}</span>
                    </div>
                  </>
                )}
                <div class="col-md-12 mt-3">
                  <label>Client</label>
                  <label class="field-required"> * </label>
                  <select className="form-select"
                    value={Clientselected}
                    onChange={(item) => {
                      setClientselected(item.target.value);
                      getProjectlist(item.target.value);
                    }}
                  >
                    <option value="">Select Client</option>
                    {CustomerList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                  </select>
                  {save && errors?.Clientselected && (
                    <>
                      <div className="field-required">
                        <span> {errors?.Clientselected}</span>
                      </div>
                    </>
                  )}
                </div>
                <div class="col-md-12 mt-3">
                  <label>Project</label>
                  <label class="field-required"> * </label>
                  <ReactSelect
                    styles={themeStyle()}
                    className="w-200"
                    options={ProjectList}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{ Option }}
                    onChange={(Projectselected) => {
                      setProjectselected(
                        selectAllList(
                          Projectselected,
                          oldProjectList,
                          ProjectList
                        )
                      );
                      setOldProjectList(Projectselected);
                    }}
                    allowSelectAll={true}
                    value={Projectselected}
                  />
                  {save && errors?.Projectselected && (
                    <>
                      <div className="field-required">
                        <span> {errors?.Projectselected}</span>
                      </div>
                    </>
                  )}
                </div>
                <div class="col-md-12 mt-3">
                  <label>Authentication Type</label>
                  <select className="form-select"
                    value={AuthenticationType}
                    onChange={(item) => {
                      setAuthenticationType(item.target.value);
                    }}
                  >
                    <option value="">Select Authentication Type</option>
                    <option value="0">Custom</option>
                    <option value="1">Google</option>
                  </select>
                  {save && errors?.AuthenticationType && (
                    <>
                      <div className="field-required">
                        <span> {errors?.AuthenticationType}</span>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered mb-4">
          <div className="col-md-12">
            <button
              onClick={() => {
                setsave(true);
                if (formValidation()) {
                  if (isUpdateTM) {
                    updateUser();
                  } else {
                    addUser();
                  }
                }
              }}
              className="btn btn-primary  me-3"
            >
              {isUpdateTM ? "Update" : "Save"}
            </button>
            <button
              type="button"
              onClick={() => {
                clearForm();
              }}
              className="btn btn-outline-secondary"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamMembersSalesDirAddUpdateCustomer;
