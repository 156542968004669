import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import { getBarGraphData } from "../../utility/Constant";
import { mmDDyyyyFormateDateSet, urlDomainName, getClassName, compareValues, getColorClassName, formatRankDate } from "../../utility/Globals";
import Chart from "react-apexcharts";

function Clicksharechart(props) {
  const [barURLs, setBarURLs] = useState([]);
  const [barURLsVal, setBarURLsVal] = useState([]);
  const [barURLsVal2, setBarURLsVal2] = useState([]);
  const [totalKeywordsbarchart1, settotalKeywordsbarchart1] = useState(0);
  const [totalSearchVolumebarchart1, settotalSearchVolumebarchart1] =
    useState(0);
  const [totalestimatedOrganicSharebarchart1, settotalestimatedOrganicSharebarchart1] = useState(0);
  const [totalKeywordsbarchart2, settotalKeywordsbarchart2] = useState(0);
  const [totalSearchVolumebarchart2, settotalSearchVolumebarchart2] = useState(0);
  const [
    totalestimatedOrganicSharebarchart2,
    settotalestimatedOrganicSharebarchart2,
  ] = useState(0);
  const [listUrltbl, setlistUrltbl] = useState([]);
  const [diffSearchVolume, setdiffSearchVolume] = useState(0);
  const [diffKeyword, setdiffKeyword] = useState(0);
  const [diffEstimate, setdiffEstimate] = useState(0);
  const [diffSearchVolumechk, setdiffSearchVolumechk] = useState(0);
  const [diffKeywordchk, setdiffKeywordchk] = useState(0);
  const [diffEstimatechk, setdiffEstimatechk] = useState(0);
  const [startDate, setstartDate] = useState("");
  // const [endDate, setendDate] = useState("");
  // const [compareendDate, setcompareendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [selectedDeviceType, setDeviceType] = useState("0");
  const [isChart, setIsChart] = useState(0);

  useEffect(() => {
    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    // setendDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    // );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    // setcompareendDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    // );

    let rowData = {
      ClickShareDateRange: {
        from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
      },
      ClickShareCompareToRange: {
        from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
        to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
      },
      projectId: localStorage.getItem("projectGId"),
      device: "0"
    };
    getBarChartData(rowData);


  }, [props?.dataParentToChild]);

  useEffect(() => {
    setIsChart(props?.IsSeoChart);
  }, [props?.IsSeoChart]);

  function handleClickChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }

  function getBarChartData(rowBarChartData) {
    let listURL = [];
    let list = [];
    let barChartValue1 = [];
    let barChartValue2 = [];
    let totalKeywords1 = 0;
    let totalEstimateOrganicSearch1 = 0;
    let totalSearchVolume1 = 0;
    let totalKeywords2 = 0;
    let totalEstimateOrganicSearch2 = 0;
    let totalSearchVolume2 = 0;

    APIManager.postAPI(getBarGraphData, rowBarChartData, null, true).then(
      (response) => {
        if (response && response.status === 200) {
          response.data.map((item) => {
            listURL.push(urlDomainName(item.url));
            barChartValue1.push(item.clickShare1 + "%");
            barChartValue2.push(item.clickShare2 + "%");

            totalKeywords1 += item.totalKeyword1 ? item.totalKeyword1 : 0;
            totalEstimateOrganicSearch1 += item.estimatedOrganicShare1;
            totalSearchVolume1 += item.totalSearchVolume1
              ? item.totalSearchVolume1
              : 0;

            totalKeywords2 += item.totalKeyword2 ? item.totalKeyword2 : 0;
            totalEstimateOrganicSearch2 += item.estimatedOrganicShare2;
            totalSearchVolume2 += item.totalSearchVolume2
              ? item.totalSearchVolume2
              : 0;
          });

          response.data.map((item) => {
            list.push({
              url: urlDomainName(item.url),
              clickshare1: item.clickShare1,
              clickshare2: item.clickShare2,
              totalSearchVolume2: item.totalSearchVolume2,
              totalSearchVolume1: item.totalSearchVolume1,
              totalEstimateOrganicSearch1: item.estimatedOrganicShare1,
              totalEstimateOrganicSearch2: item.estimatedOrganicShare2,
              isHighData: compareValues(item.clickShare2, item.clickShare1),
              isHighData1: compareValues(
                item.totalSearchVolume2,
                item.totalSearchVolume1
              ),
              isHighData2: compareValues(
                item.totalKeywords2,
                item.totalKeywords1
              ),
              isHighData3: compareValues(
                item.totalEstimateOrganicSearch2,
                item.totalEstimateOrganicSearch2
              ),
            });
          });
        }
        let diffSE = (+totalSearchVolume1 / +totalSearchVolume2) * 100;
        let diffKeyword = (+totalKeywords1 / +totalKeywords2) * 100;

        setdiffEstimatechk(compareValues(totalEstimateOrganicSearch2, totalEstimateOrganicSearch1));
        setdiffKeywordchk(compareValues(totalKeywords2, totalKeywords1));
        setdiffSearchVolumechk(compareValues(totalSearchVolume2, totalSearchVolume1));
        setdiffSearchVolume(diffSE.toFixed(2));
        setdiffKeyword(diffKeyword.toFixed(2));
        setdiffEstimate(diffEstimate.toFixed(2));
        setlistUrltbl(list);
        setBarURLs(listURL);
        setBarURLsVal(barChartValue1);
        setBarURLsVal2(barChartValue2);
        settotalSearchVolumebarchart1(totalSearchVolume1);
        settotalKeywordsbarchart1(totalKeywords1);
        settotalestimatedOrganicSharebarchart1(
          parseFloat((totalEstimateOrganicSearch1 / totalKeywords1).toFixed(2))
        );
        settotalKeywordsbarchart2(totalKeywords2);
        settotalSearchVolumebarchart2(totalSearchVolume2);
        settotalestimatedOrganicSharebarchart2(
          parseFloat((totalEstimateOrganicSearch2 / totalKeywords2).toFixed(2))
        );
      }
    );
  }

  return (
    <>
      <div className="title pb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3">Click Share</h4>
          </div>
          <div className="d-flex  justify-content-between align-items-center">
            <div>
              <select
                style={{ padding: 8 + "px" }}
                className="form-select w-100"
                value={selectedDeviceType}
                onChange={(e) => {
                  setDeviceType(e.target.value);

                  let rowData = {
                    ClickShareDateRange: {
                      from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                      to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                    },
                    ClickShareCompareToRange: {
                      from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                      to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                    },
                    projectId: localStorage.getItem("projectGId"),
                    device: e.target.value
                  };
                  getBarChartData(rowData);
                }}
              >
                <option value="0">Mobile</option>
                <option value="1">Desktop</option>
              </select>
            </div>
            <div className="btn-box ms-2">
              <div className="d-flex align-items-center">
                <button
                  className={`${isChart === 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(0);
                  }}
                >
                  <img
                    src={`${isChart === 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isChart === 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(1);
                  }}
                >
                  <img
                    src={`${isChart === 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="portlet-body text-center">
        {isChart === 0 ? (
          <>
            <Chart
              options={{
                legend: {
                  show: false,
                },
                chart: {
                  type: "bar",
                  height: 350,
                  fontFamily: "Montserrat, sans- serif ",
                  toolbar: {
                    show: false,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                      download: true,
                      zoomin: true,
                      zoomout: true,
                    },
                  },
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    columnWidth: "55%",
                    endingShape: "rounded",
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  show: true,
                  width: 2,
                  colors: ["transparent"],
                },
                xaxis: {
                  categories: barURLs,
                },
                yaxis: [
                  {
                    labels: {
                      formatter: function (val) {
                        return val.toFixed(0) + "%";
                      },
                    },
                  },
                ],
                fill: {
                  opacity: 1,
                },
                tooltip: {
                  y: {
                    formatter: function (val) {
                      return Number(parseFloat(val).toFixed(2)) + "%";
                    },
                  },
                },
              }}
              series={[
                {
                  name: "ClickShare 1",
                  data: barURLsVal,
                },
                {
                  name: "ClickShare 2",
                  data: barURLsVal2,
                },
              ]}
              type="bar"
              height="300"
            />
            <div className="legend-box d-flex align-items-center justify-content-center w-100">
              <div className="d-flex align-items-center">
                <div className="legend-dot blue"></div>
                <div className="ml-2">{formatRankDate(startDate)}</div>
              </div>
              <div className="d-flex align-items-center ms-5">
                <div className="legend-dot"></div>
                <div className="ml-2">{formatRankDate(compareStartDate)}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-12 text-right backlink-daterange mt-3">
              Date Range : {formatRankDate(startDate)} -{" "}
              {formatRankDate(compareStartDate)}
            </div>
            {listUrltbl.map((i) => {
              return (
                <>
                  <div className="col-md-12 d-flex mt-3 ps-0">
                    <div className="col-md-3 m-0 p-0">
                      <div className="click   me-2">
                        <div className="d-flex align-items-center justify-content-between ">
                          <h4> {i.url} </h4>
                          <div className="grren-domain text-right">
                            <svg
                              width="35"
                              height="34"
                              viewBox="0 0 35 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                              <g clip-path="url(#clip0_4_4546)">
                                <path
                                  d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.667 12H26.667V17"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4546">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(7.5 7)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </div>
                        </div>

                        <h3
                          className={
                            getClassName(i)
                          }
                        >
                          +{((i.clickshare1 / i.clickshare2) * 100).toFixed(2)}%
                        </h3>

                        <div className="row mt-3 p-0">
                          <div className="d-flex align-items-center justify-content-between p-0">
                            <div className="col-md-6 p-0 text-left">
                              <p>{i.clickshare2.toFixed(2)}</p>
                              <span>{formatRankDate(startDate)}</span>
                            </div>

                            <div className="col-md-6 total-compare">
                              <p>{i.clickshare1.toFixed(2)}</p>
                              <span>{formatRankDate(compareStartDate)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <div className="row clickshare-dashboard">
              <div className="col-md-4  m-0 p-0">
                <div className="click-domain2 mt-3 me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>Total Search Volume</h4>
                  </div>

                  <h3 className={getColorClassName(diffSearchVolumechk)}>
                    +{+diffSearchVolume ? +diffSearchVolume : 0}%
                  </h3>

                  <div className="row">
                    <div className="d-flex align-items-center p-0 ">
                      <span className="click-date "> {formatRankDate(startDate)} :</span>
                      <span className="click-chart ms-1">
                        {+totalSearchVolumebarchart2 ? +totalSearchVolumebarchart2 : 0}
                      </span>
                    </div>
                    <div className="d-flex align-items-center p-0">
                      <span className="click-date "> {formatRankDate(compareStartDate)} :</span>
                      <span className="click-chart ms-1">
                        {+totalSearchVolumebarchart1 ? +totalSearchVolumebarchart1 : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 m-0 p-0">
                <div className="click-domain2 mt-3 me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>Total Keywords</h4>
                  </div>

                  <h3 className={getColorClassName(diffKeywordchk)}>
                    +{+diffKeyword ? +diffKeyword : 0}%
                  </h3>

                  <div className="row">
                    <div className="d-flex align-items-center p-0 ">
                      <span className="click-date "> {formatRankDate(startDate)} :</span>
                      <span className="click-chart ms-1">
                        {+totalKeywordsbarchart2 ? +totalKeywordsbarchart2 : 0}
                      </span>
                    </div>
                    <div className="d-flex align-items-center p-0 ">
                      <span className="click-date"> {formatRankDate(compareStartDate)} :</span>
                      <span className="click-chart ms-1">
                        {" "}
                        {+totalKeywordsbarchart1 ? +totalKeywordsbarchart1 : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4 m-0 p-0">
                <div className="click-domain2 mt-3 me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>Estimate Organic Share</h4>
                  </div>

                  <h3 className={getColorClassName(diffEstimatechk)}>
                    +{+diffEstimate ? +diffEstimate : 0}%
                  </h3>

                  <div className="row">
                    <div className="d-flex align-items-center p-0 ">
                      <span className="click-date">{formatRankDate(startDate)} :</span>
                      <span className="click-chart ms-1">
                        {+totalestimatedOrganicSharebarchart2 ? totalestimatedOrganicSharebarchart2 : 0}
                      </span>
                    </div>
                    <div className="d-flex align-items-center p-0 ">
                      <span className="click-date "> {formatRankDate(compareStartDate)} :</span>
                      <span className="click-chart ms-1">
                        {+totalestimatedOrganicSharebarchart1 ? +totalestimatedOrganicSharebarchart1 : 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Clicksharechart;

