import { Input } from 'antd'
import React, { useEffect, useState } from 'react'
import { Table } from 'antd';
import { getCountryByOrg, reviewRankingDetails } from '../../../utility/Constant';
import APIManager from '../../../services/APIManager';
import { Sorter } from '../../../common/Sorter';

function Campaingreview(props) {

    const Prioritylist = [
        {
            value: "0",
            lable: "Low",
        },
        {
            value: "1",
            lable: "Medium",
        },
        {
            value: "2",
            lable: "High",
        },
    ];

    const Devicelist = [
        {
            value: "0",
            lable: "Mobile",
        },
        {
            value: "1",
            lable: "Desktop",
        },
    ];

    const Frequencylist = [
        {
            value: "0",
            lable: "Weekly",
        },
        {
            value: "1",
            lable: "Monthly",
        },
    ];

    const keywordtype = [
        {
            value: "0",
            lable: "Brand",
        },
        {
            value: "1",
            lable: "Non Brand ",
        },
        // {
        //     value: "2",
        //     lable: "All",
        // },
    ]

    const columns = [
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'keyword',
            sorter: {
                compare: (a, b) =>
                    Sorter.DEFAULT(
                        a.keyword.toLocaleLowerCase(),
                        b.keyword.toLocaleLowerCase()
                    ),
                multiple: 3,
            },
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.category, b.category),
                multiple: 3,
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.type, b.type),
                multiple: 3,
            },
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.priority, b.priority),
                multiple: 3,
            },
        },
        {
            title: 'SearchVolume',
            dataIndex: 'searchvolume',
            key: 'searchvolume',
            sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.searchvolume, b.searchvolume),
                multiple: 3,
            },

        },
    ];
    const [dataSource, setdataSource] = useState([]);
    const columnsset = [
        {
            title: 'Competitors',
            dataIndex: 'competitors',
            key: 'competitors',
            sorter: {
                compare: (a, b) =>
                    Sorter.DEFAULT(
                        a.competitors.toLocaleLowerCase(),
                        b.competitors.toLocaleLowerCase()
                    ),
                multiple: 3,
            },
        },
    ];
    const [dataSourceset, setdataSourceset] = useState([
    ]);


    useEffect(() => {
        getRankingDetails();
    }, [props?.reivewcampId]);

    const handleChangeEdit = (val) => {
        props.isEdit(val);
    };

    function getFrequencyName(val) {
        let finalVal = val;
        Frequencylist.map((i) => {
            if (val == i.value) {
                finalVal = i.lable;
            }
        });
        return finalVal;
    }

    function getDeviceName(val) {
        let finalVal = val;
        Devicelist.map((i) => {
            if (val == i.value) {
                finalVal = i.lable;
            }
        });
        return finalVal;
    }

    const [CampaignName, setCampaignName] = useState("");
    const [Device, setDevice] = useState("");
    const [frequency, setfrequency] = useState("");
    const [NumberOfCompetitors, setNumberOfCompetitors] = useState("");
    const [RankingBukets, setRankingBukets] = useState("");
    const [NumberofKeywords, setNumberofKeywords] = useState("");
    const [credits, setcredits] = useState("");
    const [domain, setdomain] = useState("");
    const [searchEngine, setsearchEngine] = useState("");
    const [country, setcountry] = useState("");
    const [KeywordTemp, setKeywordTemp] = useState([]);
    const [comptrlist, setcomptrlist] = useState([]);
    const [projectCredits, setProjectCredits] = useState("");

    function getCountryList(countryId) {
        APIManager.getAPI(getCountryByOrg + localStorage.getItem("organizationId")).then((response) => {
            if (response && response.status == 200) {
                const list = [];
                let name = '';
                response.data.map((item) => {
                    if (countryId == item.id) {
                        name = item.countryName;
                    }
                });
                setcountry(name);
            }
        });
    }

    function filterDataFromList(textData) {
        setdataSource(
            KeywordTemp.filter((person) =>
                person.keyword
                    .toLocaleLowerCase()
                    .includes(textData.toLocaleLowerCase())
            )
        );
    }

    function getRankingDetails() {
        APIManager.getAPI(
            reviewRankingDetails + props?.reivewcampId).then((response) => {
                if (response && response.status == 200) {
                    setCampaignName(response.data.detailsResponse[0]?.campaignName);
                    setDevice(getDeviceName(response.data.detailsResponse[0]?.device));
                    setNumberOfCompetitors(response.data.detailsResponse[0]?.noOfCompetitors);
                    setRankingBukets(response.data.detailsResponse[0]?.rankingBuckets);
                    setNumberofKeywords(response.data.detailsResponse[0]?.noOfKeywords);
                    setsearchEngine(response.data.detailsResponse[0]?.searchEngine);
                    setdomain(response.data.detailsResponse[0]?.domain);
                    setfrequency(getFrequencyName(response.data.detailsResponse[0]?.frequency));
                    setcredits(response.data.detailsResponse[0]?.credits);
                    setProjectCredits(response.data.detailsResponse[0]?.creditForRankings ?? 0);
                    getCountryList(response.data.detailsResponse[0]?.location);
                    let keywordList = [];
                    response.data.keywordsResponse.map((item) => {
                        keywordList.push({
                            keyword: item.keyword,
                            category: item.category,
                            type: getKeywordTypeName(item.type),
                            priority: item.priority == 0 ? "No" : "Yes",
                            searchvolume: item.searchVolume
                        });
                    });
                    setdataSource(keywordList);
                    setKeywordTemp(keywordList);

                    let competitorList = [];
                    response.data.competitorsResponse.map((item) => {
                        competitorList.push({
                            competitors: item.competitor
                        });
                    });

                    setdataSourceset(competitorList);
                    setcomptrlist(competitorList);

                }
            });
    }

    function getKeywordTypeName(val) {
        var finalVal = val;
        keywordtype.map((i) => {
            if (val == i.value) {
                finalVal = i.lable;
            }
        });
        return finalVal;
    }

    return (
        <div className='container-fluid ranking-campaign-review'>
            <h1 className='portlet-title main-title my-5'>Review Campaning</h1>
            <div className='portlet mx-3'>
                <div className='d-flex justify-content-between mt-4'>
                    <h2 className='portlet-title main-title'>Campaign Details</h2>
                    <span onClick={() => handleChangeEdit(0)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit edit-icon me-3"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    </span>
                </div>
                <div className="d-flex mt-3">
                    <div className='campaign-details-box width-50'>
                        <div className="d-flex align-items-center">
                            <p>Campaign View Name :</p>
                            <p className='texxt-dark ms-2'> {CampaignName}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>Credits :</p>
                            <p className='texxt-dark ms-2'> {credits}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>Domain :</p>
                            <p className='texxt-dark ms-2'> {domain}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>Country / City :</p>
                            <p className='texxt-dark ms-2'> {country}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className='mb-0'>No.of Keywords :</p>
                            <p className='texxt-dark mb-0 ms-2'> {NumberofKeywords}</p>
                        </div>
                    </div>
                    <div className='campaign-details-box  width-50'>
                        <div className="d-flex align-items-center">
                            <p>Search Engine :</p>
                            <p className='texxt-dark ms-2'> {searchEngine}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>Frequency :</p>
                            <p className='texxt-dark ms-2'> {frequency}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>Device :</p>
                            <p className='texxt-dark ms-2'> {Device}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p>Ranking Buckets:</p>
                            <p className='texxt-dark ms-2'> {RankingBukets}</p>
                        </div>
                        <div className="d-flex align-items-center">
                            <p className='mb-0'>No.of Competitors:</p>
                            <p className='texxt-dark mb-0 ms-2'> {NumberOfCompetitors}</p>
                        </div>
                    </div>

                </div>
            </div>
            <div className='portlet mt-5 mx-3'>
                <div className='d-flex justify-content-between mt-4'>
                    <h1 className='portlet-title main-title'>Keywords Details:</h1>
                    <span onClick={() => handleChangeEdit(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit edit-icon me-3"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    </span>
                </div>
                <div className="d-flex px-2 mx-3 my-3 keyword-points">
                    <span>Keyword Added :</span>
                    <p className='fw-bold ms-2 mb-0 text-dark'>{NumberofKeywords ? NumberofKeywords : 0}</p>

                    <span class="ms-5">Credit Required :</span>
                    {credits > projectCredits ? (
                        <p className='fw-bold ms-2 mb-0 text-dark keyword-msg'><span className='fw-bold'>{credits}</span> / {projectCredits}</p>
                    ) : (
                        <p className='fw-bold ms-2 mb-0 text-dark'>{credits} / {projectCredits}</p>
                    )}
                </div>
                {credits > projectCredits ? (
                    <div className='keyword-msg ms-3 ps-1'>
                        <span>Note: You have exceeded the maximum credit limit assigned to this project</span>
                    </div>
                ) :
                    (<></>)
                }
                <div className="mx-2">
                    <div class="d-flex input-group-icon input-group-lg widget15-compact mt-5 mx-3 align-items-center justify-content-between">
                        <div class="d-flex input-group-prepend ">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        </div>
                        <span>
                            <Input
                                allowClear
                                style={{ padding: 7 + "px" }}
                                type="text"
                                class="form-control"
                                placeholder="Type to search"
                                onChange={(nameSearch) => {
                                    filterDataFromList(nameSearch.target.value);
                                }}
                                id="input-s"
                            />
                        </span>

                        <div className="d-flex">
                            <span>No. of Keywords:</span>
                            <p className='fw-bold ms-2 mb-0'>{NumberofKeywords}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <Table
                        className="table table-bordered table-hover border-0 keyword-detail-table"
                        id="url"
                        columns={columns}
                        dataSource={dataSource}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} Keywords`
                        }}
                    />
                </div>
            </div>
            <div className='portlet mt-5 mx-3'>
                <div className='d-flex justify-content-between mt-4'>
                    <h1 className='portlet-title main-title'>Competitors Details:</h1>
                    <span onClick={() => handleChangeEdit(2)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit edit-icon me-3"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    </span>
                </div>
                <div className="mx-2">
                    <div class="input-group-icon input-group-lg widget15-compact mt-5 mx-3 d-flex justify-content-between align-items-center">
                        <div class="input-group-prepend">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                        </div>
                        <span>
                            <Input
                                allowClear
                                style={{ padding: 7 + "px" }}
                                type="text"
                                class="form-control"
                                placeholder="Type to search"
                                onChange={(nameSearch) => {
                                    setdataSourceset(
                                        comptrlist.filter((person) =>
                                            person.competitors
                                                .toLocaleLowerCase()
                                                .includes(
                                                    nameSearch.target.value.toLocaleLowerCase()
                                                )
                                        )
                                    );
                                }}
                                id="input-s"
                            />
                        </span>

                        <div className="d-flex">
                            <span>No. of Competitors:</span>
                            <p className='fw-bold ms-2 mb-0'>{NumberOfCompetitors}</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <Table
                        className="table table-bordered table-hover border-0 competitors-detail-table"
                        id="url"
                        columns={columnsset}
                        dataSource={dataSourceset}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} Competitors`
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default Campaingreview