import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  createGoogleTrendsData,
  updateGoogleTrends,
  getGoogleTrendCountry,
  getGoogleTrendCategory,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { themeStyle, searchTypeList } from "../../utility/Globals";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";

function AddGoogleTrends({ update }) {
  const [message, setMessage] = useState("");
  const [countrySelected, setCountrySelected] = useState("");
  const [categorySelected, setCategorySelected] = useState("");
  const [countryList, setCountryList] = useState([]);
  const location = useLocation();
  const history = useHistory();
  const [fieldValue, SetfieldValue] = useState("");
  const [selectedSearchType, setSearchType] = useState("0");
  const [categorylists, setcategorylists] = useState([]);
  const [id, setId] = useState([]);
  const [fieldValueId, setfieldValueId] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const [categoryId, setCategoryId] = useState([]);
  const [searchType, setSearchTypeId] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    getCountryList();
    getCategorylist();

    setTimeout(() => {
      if (location.state.isEdit) {
        SetfieldValue(location.state.item.fieldValue);
        setfieldValueId(location.state.item.fieldValueId);
        setCategorySelected(location.state.item.categoryId);
        setCategoryId(location.state.item.categoryId);
        setSearchType(location.state.item.SearchType);
        setSearchTypeId(location.state.item.SearchTypeId);
        setId(location.state.item.id);
        setTimeout(() => {
          setCountrySelected(location.state.item.countryId);
          setCountryId(location.state.item.countryId);
        }, 100);
      }
    }, 1500);
  }, []);

  function clearForm() {
    SetfieldValue("");
    setSearchType("");
    setCountryList([]);
    setcategorylists([]);
    setSearchType("");
    update("googleTrends");
  }

  function addGoogleTrends(rowdata) {
    APIManager.postAPI(createGoogleTrendsData, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          let obj = {
            categoryId: categorySelected,
            keyword: fieldValue,
            projectId: localStorage.getItem("projectGId"),
            countryId: countrySelected,
            SearchType: selectedSearchType,
          };
          list.push(obj);
        }
        clearForm();
        notifyAdd();
      }
    );
  }

  function UpdateGoogle(item) {
    APIManager.putAPI(
      updateGoogleTrends,
      {
        id: location?.state?.item?.id,
        CountryId: countrySelected,
        keyword: fieldValue,
        ProjectId: localStorage.getItem("projectGId"),
        CategoryId: categorySelected,
        SearchType: selectedSearchType,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearForm();
      }
    });
  }

  function formValidation() {
    let isValidFields = true;
    if (fieldValue.trim() == "") {
      setMessage("Keyword is required!");
      isValidFields = false;
    } else {
      setMessage("");
    }
    return isValidFields;
  }

  function getCountryList() {
    APIManager.getAPI(getGoogleTrendCountry).then((response) => {
      if (response && response.status == 200) {
        const cList = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            countryName: item.countryName,
            countryCode: item.countryCode,
            subRegion: item.subRegion,
          };
          if (item.countryName.toLowerCase() == "india") {
            setCountrySelected(item.id);
          }
          cList.push(obj);
        });
        setCountryList(cList);
      }
    });
  }

  function getCategorylist() {
    APIManager.getAPI(getGoogleTrendCategory).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            categoryName: item.categoryName,
            categoryCode: item.categoryCode,
            parentCategoryId: item.parentCategoryId,
          };
          if (item.categoryName == "All Categories") {
            setCategorySelected(item.id);
          }
          list.push(obj);
        });
        setcategorylists(list);
      }
    });
  }

  return (
    <div className="custom-column-70">
      <div className="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => {
            update("googleTrends");
          }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} -
            {location?.state?.isEdit ? "Update" : "Add"} Keywords
          </h3>
        </div>
        <div className="portlet-body">
          <div class="common-form-fields">
            <div class="col-md-12">
              <label className="form-label">Keyword</label>
              <label class="field-required"> * </label>
              <input
                className="form-control w-100"
                type="text"
                name=""
                placeholder="Enter Keyword"
                value={fieldValue}
                onChange={(text) => {
                  SetfieldValue(text.target.value);
                }}
              ></input>
              <div class="ms-3" style={{ color: "red" }}>
                {message}
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="form-label">Search Type</label>
              <div className="d-flex justify-content-between mb-3">
                <select
                  style={{ padding: 8 + "px" }}
                  className="w-100"
                  value={selectedSearchType}
                  onChange={(item) => {
                    setSearchType(item.target.value);
                  }}
                >
                  {searchTypeList.map((item) => {
                    return <option value={item.value}>{item.label}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="form-label">Categories</label>
              <div className="d-flex justify-content-between mb-3">
                <select
                  required
                  styles={themeStyle()}
                  className="w-100"
                  options={categorylists}
                  value={categorySelected}
                  onChange={(item) => {
                    setCategorySelected(item.target.value);
                  }}
                >
                  {categorylists?.map((item) => {
                    return <option value={item.id}>{item.categoryName}</option>;
                  })}
                </select>
              </div>
            </div>

            <div className="col-md-12 mt-3">
              <label className="form-label">Country</label>
              <div className="d-flex justify-content-between mb-3">
                <select
                  required
                  value={countrySelected}
                  onChange={(item) => {
                    setCountrySelected(item?.target?.value);
                  }}
                >
                  {countryList?.map((item) => {
                    return (
                      <option value={item?.id}>{item?.countryName}</option>
                    );
                  })}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              onClick={() => {
                let rowdata = {
                  id: location.state?.item?.id,
                  ProjectId: localStorage.getItem("projectGId"),
                  countryId: countrySelected,
                  keyword: fieldValue,
                  categoryId: categorySelected,
                  SearchType: selectedSearchType,
                };

                let rowdataa = {
                  id: location.state?.item?.id,
                  ProjectId: localStorage.getItem("projectGId"),
                  countryId: countrySelected,
                  keyword: fieldValue,
                  categoryId: categorySelected,
                  SearchType: selectedSearchType,
                };

                if (formValidation()) {
                  {
                    location.state.isEdit
                      ? UpdateGoogle(rowdataa)
                      : addGoogleTrends(rowdata);
                  }
                }
              }}
              className=" btn btn-primary me-3"
            >
              {location?.state?.isEdit ? "Update" : "Save"}
            </button>

            <button
              class="btn btn-outline-secondary"
              onClick={() => {
                update("googleTrends");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddGoogleTrends;
