import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";

import {
  createProject,
  documentFileType,
  getClientByOrgId,
  GET_Server_URL,
  updateProject,
  getCountryByOrg,
  getAllCityByCountry,
  getSowExist,
} from "../utility/Constant";

import APIManager from "../services/APIManager";
import { useLocation } from "react-router-dom";
import { removeTrailingSlash } from "../utility/Globals";

function ProjectsListSalesDirAddUpdate({ update }) {
  const [isUpdateProjectData, setIsUpdateProjectData] = useState(false);
  const [selecteItem, setSelectedItem] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [projectCode, setprojectCode] = useState("");
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [domainName, setdomainName] = useState("");
  const [cost, setcost] = useState("");
  const [poc, setpoc] = useState("");
  const [creditForRankings, setcreditForRankings] = useState("0");
  const [wordCount, setWordCount] = useState("0");
  const [contact, setcontact] = useState("");
  const [filelist, setfilelist] = useState([]);
  const [sowfilelist, setSOWfilelist] = useState([]);
  const [errors, setErrors] = useState({});
  const [save, setsave] = useState(false);
  const [countrySelected, setCountrySelected] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [cityList, setcityList] = useState([]);
  const [cityselected, setcityselected] = useState("");
  // const notifyAdd = () => toast.success("Successfully Add!");
  const [statueList, setStatueList] = useState([
    { name: "Active", id: "0" },
    { name: "Lead", id: "1" },
    { name: "Prospect", id: "2" },
    { name: "Completed", id: "3" },
  ]);
  const [statusySelected, setStatusySelected] = useState("0");
  const [documentURL, setDocumentURL] = useState("");
  const [industry, setIndustry] = useState("");
  const [sowDocumentFile, setSowDocumentFile] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [documentHolder, setDocumentHolder] = useState(0);
  const [isSowExist, setIsSOWExist] = useState(false);

  function changeStatus(e) {
    let value = e.target.value;
    setStatusySelected(value);
  }
  const [customerName, setCustomerName] = useState([]);
  const [selectedClient, setClientsSelected] = useState("");
  const locationforUpdate = useLocation();

  function changeCustomer(e) {
    let value = e.target.value;
    setClientsSelected(value);
  }

  useEffect(() => {
    if (locationforUpdate.state.isUpdateProjectData) {
      setIsUpdateProjectData(true);
      setprojectCode(locationforUpdate.state.set_ProjectCode);
      setClientsSelected(locationforUpdate.state.set_CustomerName);
      setstartDate(locationforUpdate.state.set_StartDate);
      setendDate(locationforUpdate.state.set_EndDate);
      setdomainName(locationforUpdate.state.set_DomainName);
      setcost(locationforUpdate.state.set_Cost);
      setCountrySelected(locationforUpdate.state.set_Country);
      setpoc(locationforUpdate.state.set_Poc);
      setcontact(locationforUpdate.state.set_Contact);
      setStatusySelected(locationforUpdate.state.set_StatusySelected);
      setSelectedProjectId(locationforUpdate.state.set_SelectedProjectId);
      setSelectedItem(locationforUpdate.state?.set_SelectedItem);
      setcreditForRankings(locationforUpdate.state?.set_CreditForRankings);
      setDocumentURL(locationforUpdate.state.set_DocumentURL);
      setIndustry(locationforUpdate.state.set_Industry);
      isSowExistFunction(locationforUpdate.state.set_SelectedProjectId);
      setWordCount(locationforUpdate.state?.set_SelectedItem?.wordCount);
    } else {
      setIsUpdateProjectData(false);
      setIsSOWExist(true);
    }
    getClientList();
    getCountryList();
  }, []);

  function isSowExistFunction(projectId) {
    APIManager.getAPI(getSowExist + projectId).then((response) => {
      if (response && response.status === 200) {
        setIsSOWExist(response.data);
      }
    });
  }
  function getClientList() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            name: item.name,
            id: item.id,
          };
          list.push(obj);
        });
        setCustomerName(list);
        if (locationforUpdate.state.isUpdateProjectData) {
        } else {
          setClientsSelected(list[0]?.id);
        }
      }
    });
  }

  function getCountryList() {
    const orgId = localStorage.getItem("organizationId");
    APIManager.getAPI(getCountryByOrg + orgId).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.countryName,
          };
          list.push(obj);
        });
        setCountryList(list);
        if (locationforUpdate.state.isUpdateProjectData) {
          if (locationforUpdate.state.set_Country) {
            getCityList(locationforUpdate.state.set_Country);
          }
        } else {
          setCountrySelected(list[0].id);
          getCityList(list[0].id);
        }
      }
    });
  }

  function getCityList(countryId) {
    APIManager.getAPI(getAllCityByCountry + countryId).then((response) => {
      if (response && response.status === 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.cityName,
          };
          list.push(obj);
        });
        setcityList(list);
        if (locationforUpdate.state.isUpdateProjectData) {
          list.map((i) => {
            if (i.id === locationforUpdate.state.set_SelectedItem?.city?.id) {
              setcityselected(i.id);
            }
          });
        } else {
          setcityselected(list[0]?.id);
        }
      }
    });
  }

  function createProjects() {
    var bodyFormData = new FormData();
    bodyFormData.append("ClientId", selectedClient);
    bodyFormData.append("ProjectCode", projectCode);
    bodyFormData.append("StartDate", startDate);
    bodyFormData.append("EndDate", endDate ? endDate : "");
    bodyFormData.append("DomainName", removeTrailingSlash(domainName));
    bodyFormData.append("Cost", cost);
    bodyFormData.append("CountryId", countrySelected);
    bodyFormData.append("CityId", cityselected);
    bodyFormData.append("POC", poc);
    bodyFormData.append("Contact", contact);
    bodyFormData.append("Status", statusySelected);
    bodyFormData.append("creditForRankings", creditForRankings);
    bodyFormData.append("WordCount", wordCount);
    bodyFormData.append("SowFile", sowDocumentFile);
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );

    if (documentFile) {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.DocumentType", documentHolder);
      bodyFormData.append("Document.File", documentFile);
    }
    APIManager.postAPI(createProject, {}, bodyFormData, false).then(
      (response) => {
        if (response && response?.status === 200) {
          clearForm();
          setIsUpdateProjectData(false);
          update("projectList");
        }
      }
    );
  }

  function setcountry(e) {
    var countryVar = e.target.value;
    setCountrySelected(countryVar);
    getCityList(countryVar);
  }

  function setcity(e) {
    setcityselected(e.target.value);
  }

  function validateFields() {
    let error = { ...errors };

    projectCode.trim() === ""
      ? (error["projectCode"] = "Project name is required!")
      : delete error["projectCode"];

    selectedClient.trim() === ""
      ? (error["selectedClient"] = "Customer name is required!")
      : delete error["selectedClient"];

    startDate.trim() === ""
      ? (error["startDate"] = "Start date is required!")
      : delete error["startDate"];

    endDate.trim() === ""
      ? (error["endDate"] = "Estimated Completion Date is required!")
      : delete error["endDate"];

    if (startDate !== "" && endDate !== "") {
      if (endDate < startDate) {
        error["startDate"] = "Start Date should be less than End Date!";
      } else {
        delete error["startDate"];
      }
    }

    domainName.trim() === ""
      ? (error["domainName"] = "Domain URL is required!")
      : delete error["domainName"];

    if (domainName.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );
      if (!pattern.test(domainName.trim())) {
        error["domainName"] = "Please enter valid domain name!";
      } else {
        delete error["domainName"];
      }
    }

    cost.toString().trim() === ""
      ? (error["cost"] = "Cost is required!")
      : delete error["cost"];

    poc.trim() === ""
      ? (error["poc"] = "POC is required!")
      : delete error["poc"];

    creditForRankings === ""
      ? (error["creditForRankings"] = "Credit is required!")
      : delete error["creditForRankings"];

    wordCount === ""
      ? (error["wordCount"] = "Word Count is required!")
      : delete error["wordCount"];

    if (locationforUpdate.state.isUpdate === false) {
      sowDocumentFile === ""
        ? (error["sowDocumentFile"] = "SowFile is required!")
        : delete error["sowDocumentFile"];
    }

    contact.trim() === ""
      ? (error["contact"] = "Contact is required!")
      : delete error["contact"];

    if (contact.trim()?.length) {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(contact.trim())) {
        error["contact"] = "Please enter only number";
      } else if (contact.trim().length >= 15) {
        error["contact"] =
          "Contact number must not exceed more than 15 characters.";
      } else if (contact.trim().length < 10) {
        error["contact"] = "Contact number must be of at least 10 characters.";
      } else {
        delete error["contact"];
      }
    }

    setErrors(error);
    let keys = Object.keys(error);
    return keys?.length ? false : true;
  }

  function updateProjects() {
    setIsUpdateProjectData(true);
    var bodyFormData = new FormData();
    bodyFormData.append("id", selectedProjectId);
    bodyFormData.append("ProjectCode", projectCode);
    bodyFormData.append("StartDate", startDate);
    bodyFormData.append("EndDate", endDate);
    bodyFormData.append("DomainName", removeTrailingSlash(domainName));
    bodyFormData.append("Cost", cost);
    bodyFormData.append("CountryId", countrySelected);
    bodyFormData.append("CityId", cityselected);
    bodyFormData.append("POC", poc);
    bodyFormData.append("Contact", contact);
    bodyFormData.append("Status", statusySelected);
    bodyFormData.append("CreditForRankings", creditForRankings);
    bodyFormData.append("WordCount", wordCount);
    bodyFormData.append("ClientId", selectedClient);
    if (isSowExist) {
      bodyFormData.append("SowFile", sowDocumentFile);
    }
    if (documentFile) {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.File", documentFile);
    }
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );

    APIManager.putAPI(updateProject, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status === 200) {
          clearForm();
        }
      }
    );
  }

  function clearForm() {
    setprojectCode("");
    setstartDate("");
    setendDate("");
    setdomainName("");
    setcost("");
    setpoc("");
    setcreditForRankings("");
    setWordCount("");
    setSowDocumentFile("");
    setDocumentFile("");
    setDocumentURL("");
    setIndustry("");
    setfilelist("");
    setSOWfilelist("");
    setcontact("");
    setSelectedProjectId("");
    setSelectedItem({});
    setStatusySelected("0");
    setIsUpdateProjectData(false);
    update("projectList");
  }

  function updateList() {
    var input = document.getElementById("myfile2");
    var output = document.getElementById("fileList2");
    var data = [];
    var children = "";
    for (var i = 0; i < input.files.length; ++i) {
      children += "<li id=filename" + i + ">" + input.files.item(i).name;
      data.push(input.files.item(i).name);
      documentFile(input?.files?.item(i));
    }
    setfilelist(data);
    output.innerHTML = "<ul>" + children + "</ul>";
  }

  function updateListsow() {
    var input = document.getElementById("myfile");
    var output = document.getElementById("sowfileList");
    var data = [];
    var children = "";
    for (var i = 0; i < input.files.length; ++i) {
      children += "<li id=filename" + i + ">" + input.files.item(i).name;
      data.push(input.files.item(i).name);
      setSowDocumentFile(input.files.item(i));
    }
    setSOWfilelist(data);
    output.innerHTML = "<ul>" + children + "</ul>";
  }

  return (
    <>
      {/* <div class="row">
        <div class="col-sm-5 pad-lzero p-0">
          <div className="col-md-12 d-flex mb-3 p-0">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                update("projectList");
              }}
            ></i>
          </div>
        </div>
        <div class="col-sm-7 add-new-btnw"></div>
      </div> */}

      <div className="portlet project-dir slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg
            onClick={() => {
              update("projectList");
            }}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left close-left-icon"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("organizationName")} -{" "}
            {isUpdateProjectData ? "Update " : "Add "}Project{" "}
          </h3>
          {/* <button
            type="button"
            title="Close"
            style={{ border: "0", background: "none" }}
            class="close"
            onClick={() => {
              update("projectList");
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>
        <div className="portlet-body">
          <div class="common-wrapper project-form">
            <div class="common-wcard">
              <div class="common-form-fields">
                <div class="add-user" style={{ width: 100 + "%" }}>
                  <div class="col-md-12">
                    <label className="form-label">Project Name</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter Project Name"
                      required
                      value={projectCode}
                      onChange={(text) => {
                        setprojectCode(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.projectCode && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.projectCode}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Customer Name</label>
                    <label class="field-required"> * </label>
                    <select
                      className="form-select "
                      value={selectedClient}
                      onChange={changeCustomer}
                    >
                      {customerName.map((item) => {
                        return (
                          <option className="customer-name" value={item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  {save && errors?.selectedClient && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.selectedClient}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3 strat-date">
                    <label className="form-label">Start Date</label>
                    <label class="field-required"> * </label>
                    <input
                      type="date"
                      name=""
                      value={startDate}
                      required
                      onChange={(text) => {
                        setstartDate(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.startDate && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.startDate}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3 strat-date completion-date">
                    <label className="form-label">
                      Estimated Completion Date
                    </label>
                    <label class="field-required"> * </label>
                    <input
                      type="date"
                      className="from-control"
                      name=""
                      required
                      value={endDate}
                      onChange={(text) => {
                        setendDate(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.endDate && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.endDate}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Domain URL</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter Domain URL"
                      required
                      value={domainName}
                      onChange={(text) => {
                        setdomainName(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.domainName && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.domainName}</span>
                      </div>
                    </>
                  )}
                  {locationforUpdate.state.isUpdateProjectData ? (
                    <div class="col-md-12 mt-3">
                      <label className="form-label">Industry</label>
                      <div className="col-md-12 d-flex p-0">
                        <input
                          type="text"
                          name=""
                          placeholder=""
                          value={industry}
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Cost</label>
                    <label class="field-required"> * </label>
                    <div className="col-md-12 d-flex p-0">
                      <input
                        className="text-center"
                        value={"$"}
                        disabled={true}
                        style={{
                          width: 30 + "px",
                          border: "1px solid #d4dee7",
                        }}
                      />
                      <input
                        type="text"
                        name=""
                        placeholder="Enter Cost"
                        required
                        value={cost}
                        onChange={(text) => {
                          setcost(text.target.value);
                          validateFields();
                        }}
                      />
                    </div>
                  </div>
                  {save && errors?.cost && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.cost}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Country</label>
                    <select
                      className="form-select w-100 "
                      required
                      value={countrySelected}
                      onChange={setcountry}
                    >
                      {countryList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">City</label>
                    <select
                      className="form-select w-100 "
                      required
                      value={cityselected}
                      onChange={setcity}
                    >
                      {cityList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">POC</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter POC"
                      required
                      value={poc}
                      onChange={(text) => {
                        setpoc(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.poc && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.poc}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Contact</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      required
                      placeholder="Enter Contact"
                      value={contact}
                      onChange={(text) => {
                        setcontact(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.contact && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.contact}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Status</label>
                    <select
                      className="form-select "
                      value={statusySelected}
                      onChange={changeStatus}
                    >
                      {statueList.map((item) => {
                        return <option value={item.id}>{item.name}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-md-12 mt-3 project-credit">
                    <label className="form-label">Credits for Rankings</label>
                    <label class="field-required"> * </label>
                    <input
                      className="w-100 mb-2"
                      type="number"
                      name=""
                      placeholder="Enter Credits for Rankings"
                      required
                      value={creditForRankings}
                      onChange={(text) => {
                        setcreditForRankings(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.creditForRankings && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.creditForRankings}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3 project-credit">
                    <label className="form-label">Word Count</label>
                    <label class="field-required"> * </label>
                    <input
                      className="w-100 mb-2"
                      type="number"
                      name=""
                      placeholder="Enter Word Count"
                      required
                      value={wordCount}
                      onChange={(text) => {
                        setWordCount(text.target.value);
                        validateFields();
                      }}
                    />
                  </div>
                  {save && errors?.wordCount && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.wordCount}</span>
                      </div>
                    </>
                  )}

                  <div
                    class="col-md-12 mt-3"
                    style={
                      isUpdateProjectData
                        ? isSowExist
                          ? { display: "block" }
                          : { display: "none" }
                        : { display: "block" }
                    }
                  >
                    <label className="form-label">
                      Upload Scope of work (SOW)
                    </label>
                    <label class="field-required"> * </label>
                    <br />
                    <input
                      className="project-choose-file p-3"
                      type="file"
                      id="myfile"
                      name="myfile"
                      multiple={false}
                      accept={documentFileType}
                      onChange={() => {
                        updateListsow();
                        validateFields();
                      }}
                    />
                    <br />
                    <a
                      class="scope-download h-auto"
                      target="_blank"
                      href={GET_Server_URL + "audit/SOW.xlsx"}
                    >
                      Download Template{" "}
                    </a>
                    {/* {documentURL ? (
                      <label id="fileLabel">
                        <a
                          target={"_blank"}
                          href={GET_Server_URL + documentURL}
                        >
                          {documentURL}
                        </a>
                      </label>
                    ) : null} */}

                    <label id="fileLabel">
                      {sowfilelist.length > 0
                        ? `${sowfilelist.length} files`
                        : ""}
                    </label>
                  </div>
                  <span className="pt-5 ps-3" style={{ color: "#757575" }}>
                    {isSowExist === false ? "SOW file already uploaded" : ""}
                  </span>

                  <div id="sowfileList">
                    <ul>
                      {sowfilelist &&
                        sowfilelist.map((i) => {
                          return (
                            <li id={i}>
                              {i} <i class="fa fa-trash" onClick={() => { }}></i>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  {save && errors?.sowDocumentFile && (
                    <>
                      <div className="field-required ms-3 mt-2">
                        <span> {errors?.sowDocumentFile}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label"> Document Upload</label>
                    <br />
                    <input
                      className="project-choose-file p-3"
                      type="file"
                      id="myfile2"
                      name="myfile2"
                      multiple={false}
                      accept={documentFileType}
                      onChange={updateList}
                    />
                    {documentURL ? (
                      <label id="fileLabel">
                        <a
                          target={"_blank"}
                          href={GET_Server_URL + documentURL}
                        >
                          {documentURL}
                        </a>
                      </label>
                    ) : null}

                    <label id="fileLabel">
                      {filelist.length > 0 ? `${filelist.length} files` : ""}
                    </label>
                  </div>
                  <div id="fileList2">
                    <ul>
                      {filelist &&
                        filelist.map((i) => {
                          return (
                            <li id={i}>
                              {i} <i class="fa fa-trash" onClick={() => { }}></i>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered mb-4">
          <div className="col-md-12 ps-0">
            <button
              class="btn btn-primary me-3"
              onClick={() => {
                setsave(true);
                if (validateFields()) {
                  isUpdateProjectData ? updateProjects() : createProjects();
                }
              }}
            >
              {isUpdateProjectData ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                clearForm();
                setIsUpdateProjectData(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectsListSalesDirAddUpdate;
