import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "antd/dist/antd.css";
import { Table, Input } from "antd";
import {
  clientIsActive,
  deleteClient,
  getClientByOrgId,
} from "../utility/Constant";
import {
  exportToEXCEL,
  findUnique,
  getCheckPermission,
} from "../utility/Globals";
import { exportInToCSV } from "../utility/Globals";
import APIManager from "../services/APIManager";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Sorter } from "../common/Sorter";
import EditClientDir from "./EditClientDir";

function ClientsSalesDir({ update }) {
  const [exportList, setExportList] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [teamlistCli, setteamListCli] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [settings, setSetting] = useState("clients");
  useEffect(() => {
    getClientList();
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {
          name: item.name1,
          clientid: item.clientid,
          poc: item.poc,
          country: item.country,
          city: item.city,
          status: item.status,
          active: item.active,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function updateStatus(id, index) {
    APIManager.postAPI(clientIsActive + "?ClientId=" + id, {}, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          getClientList();
        }
      }
    );
  }

  const [filterStatusList, setFilterStatusList] = useState([]);

  const statusList = [
    { text: "Active", value: 0, isActive: false },
    { text: "Lead", value: 1, isActive: false },
    { text: "Prospect", value: 2, isActive: false },
    { text: "Completed", value: 3, isActive: false },
    { text: "Appointment Scheduled", value: 4, isActive: false },
    { text: "Qualified to Buy", value: 5, isActive: false },
    { text: "Presentation Scheduled", value: 6, isActive: false },
    { text: "Decision maker Bought-In", value: 7, isActive: false },
    { text: "Contract Sent", value: 8, isActive: false },
    { text: "Closed Won", value: 9, isActive: false },
    { text: "Closed Lost", value: 10, isActive: false },
  ];

  function getStatus(statusdId) {
    let status = "";
    switch (statusdId) {
      case 0:
        status = "Active";
        break;
      case 1:
        status = "Lead";
        break;
      case 2:
        status = "Prospect";
        break;
      case 3:
        status = "Completed";
        break;
      case 4:
        status = "Appointment Scheduled";
        break;
      case 5:
        status = "Qualified to Buy";
        break;
      case 6:
        status = "Presentation Scheduled";
        break;
      case 7:
        status = "Decision maker Bought-In";
        break;
      case 8:
        status = "Contract Sent";
        break;
      case 9:
        status = "Closed Won";
        break;
      case 10:
        status = "Closed Lost";
        break;
      default:
        break;
    }
    return status;
  }

  function getClientList() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response.status == 200) {
        const list = [];
        const exportList = [];
        const filtercity = [];
        const filtercountry = [];
        const filterstatus = [];
        response.data.map((item, index) => {
          let obj = {
            key: item.id,
            name1: item.name,
            name: getCheckPermission("clients_edit", "clients_setup") ? (
              <Link
                onClick={() => {
                  setSetting("editClient");
                }}
                to={{
                  state: { item, isEdit: true },
                }}
              >
                {item.name}
              </Link>
            ) : (
              <div>{item.name}</div>
            ),
            nameId: `${item.name}`,
            poc: item.keyPOC,
            country: item.country,
            city: item.city,
            status: getStatus(item.status),
            active: item.active,
            industriesId:item.industriesId,
            actde: (
              <label class="switch">
                <input
                  type="checkbox"
                  checked={item.active}
                  disabled={
                    getCheckPermission("clients_edit", "clients_setup")
                      ? false
                      : true
                  }
                  onClick={() => {
                    updateStatus(item.id, index);
                  }}
                />
                <span class="slider round"></span>
              </label>
            ),
            view: (
              <div className="text-center">
                {getCheckPermission("clients_edit", "clients_setup") ? (
                  <Link
                    to={{
                      state: { item, isEdit: true },
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-edit edit-icon me-3"
                      onClick={() => {
                        setSetting("editClient");
                      }}
                    >
                      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                    </svg>
                  </Link>
                ) : (
                  <></>
                )}
              
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                  onClick={() => {
                    deleteClientItem(item.id, item.index);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          };
          list.push(obj);
          let exportObj = {
            name: item.name,
            poc: item.keyPOC,
            country: item.country,
            city: item.city,
            status:
              item.status == "0"
                ? "Active"
                : item.status == "1"
                ? "Lead"
                : item.status == "2"
                ? "Prospect"
                : "Active",
            active: item.active,
          };
          let filterCityObj = {
            text: item.city,
            value: item.city,
          };
          if (filtercity?.length) {
            if (!filtercity?.some((x) => x.value == item.city)) {
              filtercity.push(filterCityObj);
            }
          } else {
            filtercity.push(filterCityObj);
          }
          let filterContryObj = {
            text: item.country,
            value: item.country,
          };
          filtercountry.push(filterContryObj);
          filterstatus.push(item.status);
          exportList.push(exportObj);
        });
        let filterStatusListData = [];
        statusList.map((status) => {
          if (filterstatus.includes(status.value)) {
            filterStatusList.push({
              value: status.text,
              text: status.text,
            });
          }
        });
        setFilterStatusList(filterStatusListData);
        setteamList(list);
        setteamListCli(list);
        setExportList(exportList);
        const columns = [
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.name1, b.name1),
              multiple: 3,
            },
          },
          {
            title: "POC",
            dataIndex: "poc",
            key: "poc",
            sorter: {
              compare: (a, b) => Sorter.DEFAULT(a.poc, b.poc),
              multiple: 3,
            },
          },
          {
            title: "Country",
            dataIndex: "country",
            key: "country",
            filters: findUnique(filtercountry, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.country.indexOf(value) === 0,
          },
          {
            title: "City",
            dataIndex: "city",
            key: "city",
            filters: findUnique(filtercity, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => {
              return record.city == value;
            },
          },
          {
            title: "Status",
            dataIndex: "status",
            key: "status",
            filters: findUnique(filterStatusList, (d) => d.value),
            filterSearch: true,
            onFilter: (value, record) => record.status.indexOf(value) === 0,
          },
          {
            title: "Activate / Deactivate",
            dataIndex: "actde",
            key: "actde",
          },
          {
            title: "Action",
            dataIndex: "view",
            key: "view",
          },
        ];
        setteamcol(columns);
      }
    });
  }

  async function deleteClientItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteClient + id);
      if (response == 200) {
        getClientList();
      }
    } catch (e) {}
  }

  function filterDataFromList(textData) {
    setteamList(
      teamlistCli.filter((person) =>
        person.name1.toLocaleLowerCase().includes(textData.toLocaleLowerCase())
      )
    );
  }

  let onClickFunction = (arg) => {
    setSetting(arg);
    getClientList();
  };
  function getComponent() {
    if (settings == "editClient") {
      return <EditClientDir update={onClickFunction} />;
    }
  }
  return (
    <>
      <div className="portlet p-0 slideInUp">
        {settings === "clients" ? (
          <>
            <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => {
                  update("");
                }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 class="portlet-title main-title">
                {" "}
                {localStorage.getItem("organizationName")} - Clients
              </h3>
            </div>

            <div class="common-table">
              <div class="row mt-3">
                <div class="col-md-4 search-box d-flex justify-content-start ">
                  <div class="input-group-icon input-group-lg widget15-compact">
                    <div class="input-group-prepend">
                      <i class="fa fa-search text-primary"></i>
                    </div>
                    {getCheckPermission("view_only", "clients_setup") ? (
                      <Input
                        allowClear
                        type="text"
                        class="form-control"
                        placeholder="Type to search..."
                        onChange={(nameSearch) => {
                          filterDataFromList(nameSearch.target.value);
                        }}
                        id="input-s"
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div class="col-md-8 data-export-client">
                  <span class="count-drop"></span>
                  {getCheckPermission("clients_edit", "clients_setup") ? (
                    <Link
                      onClick={() => {
                        setSetting("editClient");
                      }}
                      class="btn btn-primary add"
                      to={{
                        state: { isEdit: false },
                      }}
                    >
                      Add
                    </Link>
                  ) : (
                    <></>
                  )}
                  <span class="dropdown d-inline ">
                    {getCheckPermission("view_only", "clients_setup") ? (
                      <button
                        class="btn btn-primary dropdown-toggle ms-3 export-btn"
                        type="button"
                        onClick={() => {
                          exportToEXCEL(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Clients"
                          );
                        }}
                      >
                        Export
                      </button>
                    ) : (
                      <></>
                    )}

                    {/* <div
                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                      aria-labelledby="dropdownMenuButton"
                    >
                      <div
                        onClick={() => {
                          exportToEXCEL(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Clients"
                          );
                        }}
                      >
                        <a class="dropdown-item">Excel</a>
                      </div>
                      <div
                        onClick={() => {
                          exportInToCSV(
                            selectedExportList?.length > 0
                              ? selectedExportList
                              : exportList,
                            "Clients"
                          );
                        }}
                      >
                        <a class="dropdown-item">CSV</a>
                      </div>
                    </div> */}
                  </span>
                </div>
              </div>
              {getCheckPermission("view_only", "clients_setup") ? (
                <Table
                  className="table table-bordered  table-hover  mt-3 client-table"
                  id="datatable-2"
                  columns={teamcol}
                  dataSource={teamlist}
                  rowSelection={{ type: "checkbox", ...rowSelection }}
                  pagination={{
                    position: ["bottomRight"],
                    showSizeChanger: true,
                  }}
                />
              ) : (
                <></>
              )}
            </div>
          </>
        ) : (
          <>{getComponent()}</>
        )}
      </div>
    </>
  );
}
export default ClientsSalesDir;
