import React, { useState, useEffect } from "react";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import {
  getCompetitorsByProjectId,
  getKeyWordByProjectId,
  getUrlByOrgId,
  createUrl,
  createKeywords,
  createClickShare,
  getClickShare,
} from "../../utility/Constant";
import {
  frequencyListt,
  selectAllList,
  themeStyle,
} from "../../utility/Globals";
import toast from "react-hot-toast";
const ClickShare = ({ update }) => {
  const [competitorsList, setCompetitorsList] = useState([]);
  const [urlList, setURlList] = useState([]);
  const [url, seturl] = useState([]);
  const [keywordSelected, setkeywordSelected] = useState([]);
  const [competitorSelected, setcompetitorSelected] = useState([]);
  const [OrganicShare, setOrganicShare] = useState("");
  const [keywordLIST, setKEYWORDList] = useState([]);
  const [frequency, setFrequency] = useState([]);
  const [IsActive, SetIsActive] = useState(false);
  const [IsActive1, SetIsActive1] = useState(false);
  const [IsActive2, SetIsActive2] = useState(false);
  const [urlName, setURLName] = useState("");
  const [keywordValue, setKeywordValue] = useState("");
  const [competitors, setCompetitors] = useState("");
  const [oldKeywordList, setoldKeywordList] = useState([]);
  const [oldCompUrlList, setOldCompUrlList] = useState([]);
  const notifyUpdate = () => toast.success("Successfully Updated!");

  const [CTRTableInputs, setCTRTableInputs] = useState([
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
    { Ctr: null },
  ]);

  useEffect(() => {
    getUrlList();
    getKeywordlist();
    getCompetitorsList();
    getClickShareData(localStorage.getItem("projectGId"));
  }, []);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  function getUrlList() {
    APIManager.getAPI(getUrlByOrgId + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          const exportList = [];
          let c = 1;
          response.data.map((item) => {
            let obj = {
              count: c,
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              url: item.fieldValue,
              delete: (
                <div style={{ marginLeft: 24 + "px" }} onClick={() => {}}>
                  <i class="fa fa-trash"></i>
                </div>
              ),
            };
            c++;
            list.push(obj);
            let exportObj = {
              key: item.id,
              id: item.id,
              url: item.fieldValue,
            };
            exportList.push(exportObj);
          });
          setURlList(list);
        }
      }
    );
  }
  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setURLName("");
        getUrlList();
        SetIsActive(false);
      }
    });
  }

  function addkeyword() {
    APIManager.postAPI(
      createKeywords,
      {
        uckType: 2,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: keywordValue,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getKeywordlist();
        setKeywordValue("");
        SetIsActive1(false);
      }
    });
  }

  function addCompetitors() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 1,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: competitors,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setCompetitors("");
        getCompetitorsList(localStorage.getItem("projectGId"));
        SetIsActive2(false);
      }
    });
  }

  function getKeywordlist() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          count: 0,
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          keyword: "Select All",
        });
        let c = 1;
        const exportList = [];
        response.data.map((item) => {
          list.push({
            count: c,
            key: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            id: item.id,
            keyword: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => {}}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          });
          exportList.push({
            key: item.id,
            id: item.id,
            keyword: item.fieldValue,
          });
          c++;
        });
        setKEYWORDList(list);
      }
    });
  }

  function getCompetitorsList() {
    APIManager.getAPI(
      getCompetitorsByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const exportList = [];
        let c = 1;
        const list = [];
        list.push({
          count: 0,
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          keyword: "Select All",
        });
        response.data.map((item) => {
          let obj = {
            count: c,
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            comp: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => {}}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          };
          c++;
          list.push(obj);
          let exportObj = {
            key: item.id,
            id: item.id,
            comp: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => {}}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          };
          exportList.push(exportObj);
        });
        setCompetitorsList(list);
      }
    });
  }

  function getClickShareData(projectIdd) {
    APIManager.getAPI(getClickShare + projectIdd).then((response) => {
      if (response) {
        setOrganicShare(response.data.organicShare);
        setFrequency(response.data.frequency);
        let urlList = [];
        urlList.push({
          key: response.data.urLs.id,
          id: response.data.urLs.id,
          value: response.data.urLs.fieldValue,
          label: response.data.urLs.fieldValue,
        });

        let obj = {
          key: response.data?.urLs?.id,
          id: response.data?.urLs?.id,
          value: response.data?.urLs?.fieldValue,
          label: response.data?.urLs?.fieldValue,
          url: response.data?.urLs?.fieldValue,
        };
        seturl(obj);

        const complist = [];
        const keywordslist = [];
        response.data.clickShareConfigurationCompetitorMappings.map((item) => {
          complist.push({
            key: item.competitors.id,
            id: item.competitors.id,
            value: item.competitors.fieldValue,
            label: item.competitors.fieldValue,
          });
        });
        setcompetitorSelected(complist);

        response.data.clickShareConfigurationKeywordMappings.map((item) => {
          keywordslist.push({
            key: item.keywords.id,
            id: item.keywords.id,
            value: item.keywords.fieldValue,
            label: item.keywords.fieldValue,
          });
        });
        setkeywordSelected(keywordslist);
        let list = [...CTRTableInputs];
        response.data.clickShareCTRConfigurations.map((crt, i) => {
          list[i].Ctr = crt.clickShareCTRValue;
        });
        setCTRTableInputs(list);
      }
    });
  }

  function addUpdateClickShare() {
    let keyword = [];
    let competitor = [];
    let crtValues = [];
    keywordSelected.map((item) => {
      if (item.id != "0") {
        keyword.push({ keywordId: item.id });
      }
    });

    competitorSelected.map((item) => {
      if (item.id != "0") {
        competitor.push({ CompetitorId: item.id });
      }
    });

    CTRTableInputs?.map((crt) => {
      if (crt.Ctr) {
        crtValues.push({
          clickShareCTRValue: parseInt(crt.Ctr),
        });
      }
    });
    APIManager.postAPI(
      createClickShare,
      {
        urlId: url.id,
        Frequency: frequency,
        OrganicShare: OrganicShare,
        ProjectId: localStorage.getItem("projectGId"),
        ClickShareConfigurationKeywordMappings: keyword,
        ClickShareConfigurationCompetitorMappings: competitor,
        ClickShareCTRConfigurations: crtValues,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        notifyUpdate();
        update("");
      }
    });
  }

  return (
    <div className="custom-column-70">
      <div class="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
        <svg  onClick={() => update("")}  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("projectGName")} - Click Share
          </h3>
        </div>
        <div className="portlet-body">
          <div class="col-md-12">
            <label className="form-label">URL</label>

            <div class="col-md-12 d-flex justify-content-between w-set mt-3 p-0">
              <ReactSelect
                styles={themeStyle()}
                className="w-200"
                options={urlList}
                isMulti={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(selectedUrl) => {
                  seturl(selectedUrl);
                }}
                value={url}
                allowSelectAll={true}
              />

              <button
                className="border plus ms-3"
                style={{ background: "none" }}
                onClick={() => {
                  SetIsActive(true);
                }}
              >
                {" "}
                <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
              </button>
            </div>
            {IsActive ? (
              <div class="col-md-12 p-0">
                <label className="form-label"></label>
                <div className="timecheck-icon d-flex">
                  <input
                    className="w-100"
                    type="text"
                    name=""
                    placeholder="Enter URL"
                    value={urlName}
                    onChange={(text) => {
                      setURLName(text.target.value);
                    }}
                  />
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => {
                      SetIsActive(false);
                      setURLName("");
                    }}
                  >
                    {" "}
                    <i
                      class="fa fa-times d-flex ms-3"
                      aria-hidden="true"
                    ></i>{" "}
                  </button>
                  <button
                    style={{ border: "none", background: "none" }}
                    id={"rightbtn"}
                    onClick={() => {
                      if (urlName) {
                        addUrl();
                      }
                    }}
                  >
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>{" "}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div class="col-md-12 mt-3">
            <label className="form-label">Keyword</label>
            <div class="col-md-12 d-flex justify-content-between w-set mt-3 p-0">
              <ReactSelect
                styles={themeStyle()}
                options={keywordLIST}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={(selectedkeyword) => {
                  setkeywordSelected(
                    selectAllList(selectedkeyword, oldKeywordList, keywordLIST)
                  );
                  setoldKeywordList(selectedkeyword);
                }}
                value={keywordSelected}
                allowSelectAll={true}
              />
              <button
                className="border plus ms-3"
                style={{ background: "none" }}
                onClick={() => {
                  SetIsActive1(true);
                }}
              >
                {" "}
                <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
              </button>
            </div>
            {IsActive1 ? (
              <div class="col-md-12 p-0">
                <label className="label"></label>
                <div className="timecheck-icon d-flex">
                  <input
                    className="w-100"
                    type="text"
                    name=""
                    placeholder="Enter Keyword"
                    value={keywordValue}
                    onChange={(text) => {
                      setKeywordValue(text.target.value);
                    }}
                  />
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => {
                      SetIsActive1(false);
                      setKeywordValue("");
                    }}
                  >
                    {" "}
                    <i
                      class="fa fa-times d-flex ms-3"
                      aria-hidden="true"
                    ></i>{" "}
                  </button>

                  <button
                    style={{ border: "none", background: "none" }}
                    id={"rightbtn"}
                    onClick={() => {
                      if (keywordValue) {
                        addkeyword();
                      }
                    }}
                  >
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>{" "}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div class="col-md-12 mt-3">
            <label className="form-label">Competitors URLs</label>
            <div class="col-md-12 d-flex justify-content-between w-set mt-3 p-0">
              <ReactSelect
                styles={themeStyle()}
                className="w-200"
                options={competitorsList}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={(selectedCompetitor) => {
                  setcompetitorSelected(
                    selectAllList(
                      selectedCompetitor,
                      oldCompUrlList,
                      competitorsList
                    )
                  );
                  setOldCompUrlList(selectedCompetitor);
                }}
                value={competitorSelected}
                allowSelectAll={true}
              />
              <button
                className="border plus ms-3"
                style={{ background: "none" }}
                onClick={() => {
                  SetIsActive2(true);
                }}
              >
                <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
              </button>
            </div>
            {IsActive2 ? (
              <div class="col-md-12 p-0">
                <label className="form-label"></label>
                <div className="timecheck-icon d-flex">
                  <input
                    className="w-100"
                    type="text"
                    name=""
                    placeholder="Enter URL"
                    value={competitors}
                    onChange={(text) => {
                      setCompetitors(text.target.value);
                    }}
                  />
                  <button
                    style={{ border: "none", background: "none" }}
                    onClick={() => {
                      SetIsActive2(false);
                      setCompetitors("");
                    }}
                  >
                    {" "}
                    <i
                      class="fa fa-times d-flex ms-3"
                      aria-hidden="true"
                    ></i>{" "}
                  </button>
                  <button
                    style={{ border: "none", background: "none" }}
                    id={"rightbtn"}
                    onClick={() => {
                      if (competitors) {
                        addCompetitors();
                      }
                    }}
                  >
                    {" "}
                    <i class="fa fa-check" aria-hidden="true"></i>{" "}
                  </button>
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div class="col-md-12 mt-3">
            <label class="form-label me-2">Organic Share %</label>
            <input
              type="text"
              value={OrganicShare}
              onChange={(text) => {
                setOrganicShare(text.target.value);
              }}
              name=""
              class="w-100"
            ></input>
          </div>
          <div class="col-md-12 mt-3">
            <label
              className="form-label"
              htmlFor=""
              style={{ marginRight: 24 + "px" }}
            >
              Set Frequency
            </label>
            <select
              value={frequency}
              onChange={(e) => {
                setFrequency(e.target.value);
              }}
              class="w-100"
            >
              {frequencyListt.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>
          <div class="col-md-5 mt-3">
            <p>CTR TABLE</p>
            <table class="table table-bordered text-center">
              <thead>
                <tr>
                  <th scope="col" style={{ width: 10 + "%" }}>
                    Rank
                  </th>
                  <th scope="col">CTR</th>
                </tr>
              </thead>
              <tbody>
                {CTRTableInputs.map((item, i) => {
                  return (
                    <tr>
                      <td scope="row">{i + 1}</td>
                      <td scope="row" className="ps-4 pe-4 pt-2 pb-2">
                        <input
                          type="text"
                          value={item.Ctr}
                          onChange={(text) => {
                            let list = [...CTRTableInputs];
                            list[i].Ctr = text.target.value;
                            setCTRTableInputs(list);
                          }}
                          className="w-100"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="col-md-12 mt-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                addUpdateClickShare();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClickShare;
