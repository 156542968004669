import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  formatdatetime,
  formateDateIntoDatemonth,
  convertDate,
} from "../../utility/Globals";
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Stepper } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Link, useHistory, useLocation } from "react-router-dom";
import APIManager from "../../services/APIManager";
import { articleDetail, getEditor, getWriter, updateEditorDetail, updateWriterDetail, getTimelineDetails, getProjectById, requirementById } from "../../utility/Constant";
import { Drawer } from "antd";

const RequirementKeywordDetails = (props) => {
  const id = props.location.state.requirementId;
  const [projectname, setProjectName] = useState();
  const keywordid = props.history.location.state.keywordId;
  const [status, setStatus] = useState();
  const [ArticleTab, setArticleTab] = useState(true);
  const [EditorTab, setEditorTab] = useState(false);
  const [TimelineTab, setTimelineTab] = useState(false);
  const history = useHistory();
  const [primaryKeyword, setPrimaryKeyword] = useState('');
  const [editorDetailList, setEditorDetailList] = useState();
  const [writreDetailList, setWritorDetailList] = useState();
  const [keywordDetailList, setKeywordDetailList] = useState();
  const [size, setSize] = useState();
  const [writeropen, setWriterOpen] = useState(false);
  const [editorOpen, setEditorOpen] = useState(false);
  const [sizeEdit, setSizeEdit] = useState();
  const [writerList, setWriterList] = useState([]);
  const [costPerWord, setCostPerWord] = useState(0);
  const [eta, setETA] = useState("");
  const [costPerWordEditor, setCostPerWordEditor] = useState(0);
  const [etaEditor, setETAEditor] = useState("");
  const [writerId, setWriterID] = useState('');
  const [editorId, setEditorID] = useState('');
  const [editorList, setEditorList] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [isChangeEditor, setIsChangeEditor] = useState(false);
  const [requirementWriterId, setRequirementWriterId] = useState("")
  const [requirementEditorId, setRequirementEditorId] = useState("")
  const [timeline, setTimeline] = useState([]);
  const [isWriterUpdate, setIsWriterUpdate] = useState(false)
  const [isEditorUpdate, setIsEditorUpdate] = useState(false)

  const showLargeDrawer1 = () => {
    setSize("large");
    setWriterOpen(true);
  };

  const showLargeDrawerEdit = () => {
    setSizeEdit("large");
    setEditorOpen(true);
  };

  const onClose = () => {
    setWriterOpen(false);
    setEditorOpen(false);
  };


  const ref = useRef();
  const [view, setview] = useState();
  const steps = [
    {
      status: "Assign Writer",
    },
    {
      status: (status == 2) ?
      <>
      Writing in progress
      <div className="text-center mt-2 px-4">
        <p className="fs-14 fw-6  rework">Rework</p>
      </div>  
      </>
      :"Writing in progress"
    },
    {
      status: "Awaiting QC",
    },
    {
      status: "Assign Editor",
    },
    {
      status: (status == 6) ?
      <>
      Editing in Progress
      <div className="text-center mt-2 px-4">
        <p className="fs-14 fw-6  rework">Rework</p>
      </div>  
      </>
      :"Editing in Progress"
    },
    {
      status: "Awaiting Cross QC",
    },
    {
      status: "Sent for Approval",
    },
    {
      status: "Article Completed",
    },
  ];
  const location = useLocation();

  useEffect(() => {
    getRequirementDetail();
    getData()
    getArticleDetailsAPI();
    setTimelineAPI();
  }, [])

  function getData() {
    getArticleDetailsAPI();
    getAllWriter();
    getAllEditor();
    if (isWriterUpdate == false) {
      if (location.state.isEdit) {
        setWriterID(location.state.writerId)
      }
      else {
        setWriterID(writerId)
      }
    }

    if (isEditorUpdate == false) {
      if (location.state.isEdit) {
        setEditorID(location.state.editorId)
      }
      else {
        setEditorID(editorId)
      }
    }
  }

  const getArticleDetailsAPI = () => {
    APIManager.postAPI(articleDetail, { 'RequirementId': id }, {}, true).then((response) => {
      if (response && response.status == 200) {
        setEditorDetailList(response.data.editorDetailsResponse[0]);
        setWritorDetailList(response.data.writerDetailsResponse[0]);
        setKeywordDetailList(response.data.keywordDetailsResponse[0]);
        setPrimaryKeyword(response.data.keywordDetailsResponse[0].primaryKeywords)
        setETA(response.data.writerDetailsResponse[0].eta)
        setCostPerWord(response.data.writerDetailsResponse[0].cost)
        setETAEditor(response.data.editorDetailsResponse[0].eta)
        setCostPerWordEditor(response.data.editorDetailsResponse[0].cost)
        setRequirementWriterId(response.data.writerDetailsResponse[0].requirementWriterId)
        setRequirementEditorId(response.data.editorDetailsResponse[0].requirementEditorId)
      }
    })
  }

  const getAllWriter = () => {
    APIManager.getAPI(
      getWriter + '?organizationId=' + localStorage.getItem('organizationId')
    ).then((response) => {
      if (response.status == 200) {
        setWriterList(response.data);
        setIsWriterUpdate(true)
        response.data.map((item) => {
        })
      }
    });
  }

  const getAllEditor = () => {
    APIManager.getAPI(
      getEditor + '?organizationId=' + localStorage.getItem('organizationId')
    ).then((response) => {
      if (response.status == 200) {
        setEditorList(response.data);
        setIsEditorUpdate(true)
      }
    })
  }

  function setTimelineAPI() {
    APIManager.getAPI(getTimelineDetails + id).then((response) => {
      if (response && response.status == 200) {
        setTimeline(response.data);
      }
    })
  }

  function updateWriterData() {
    APIManager.putAPI(updateWriterDetail, {
      RequirementWriterId: requirementWriterId,
      RequirementId: id,
      WriterId: writerId,
      CostPerWord: costPerWord,
      ETA: eta
    }, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          setIsChange(false);
          getArticleDetailsAPI();
          onClose();
        }
      }
    );

  }

  function updateEditorData() {
    APIManager.putAPI(updateEditorDetail, {
      RequirementEditorId: requirementEditorId,
      RequirementId: id,
      EditorId: editorId,
      CostPerWord: costPerWordEditor,
      ETA: etaEditor
    }, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          setIsChangeEditor();
          getArticleDetailsAPI();
          onClose();
        }
      }
    );
  }

  const getRequirementDetail = () => {
    APIManager.getAPI(requirementById + id).then((res) => {
      getProjectByID(res.data.value.projectId);
      setStatus(res.data.value.status);
      setview((res.data.value.status > 1) ? (res.data.value.status > 5) ? res.data.value.status - 2  : res.data.value.status - 1 : res.data.value.status);
    });
  }

  const getProjectByID = (id) => {
    APIManager.getAPI(getProjectById + id).then((res) => {
      setProjectName(res.data.value.projectCode);  
    })
  }

  return (
    <>
      <div className="breadcum_top bg-white p-3 cms-breadcum" onClick={() => { history.goBack(); }}>
        <small class="mx-2 ">{projectname}</small>/<small class="mx-2 ">#{keywordid}</small>/<small class="ms-2 ">{primaryKeyword}</small></div>
      <div class="portlet portlet-header portlet-header-bordered  assign-breadcum mt-4">
        <div ref={ref} className="px-0 py-2 pb-20 m-25 w-100 cms-stepper">
          <Stepper
            className="w-100 m-auto mt-40"
            activeStep={view}
            alternativeLabel
          >
            {steps?.map((label, index) => (
              <Step key={label.status}>
                <StepLabel>{label.status}</StepLabel>
                {(index < view ) ?
                    <>
                  <div className="text-center mt-2">
                  <p className="fw-6 fs-14 assign-date mb-0">14 Jan 2022</p>
                  <p className="fw-6 fs-14 assign-date">2:00 pm</p>
                  </div>

                  
                  </>
                  : 
                  <></>
                }
                
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
      <div>
        <Tabs>
          <div className="portlet">
            <div className="portlet-header portlet-header-bordered">
              <div className="col-md-12 px-0">
                <TabList className="nav nav-lines portlet-nav ">
                  <Tab className={
                    ArticleTab
                      ? "nav-item nav-link  active "
                      : "nav-item nav-link "
                  }
                    onClick={() => {
                      setEditorTab(false);
                      setTimelineTab(false);
                      setArticleTab(true);
                    }}>Article Details</Tab>
                  <Tab className={
                    EditorTab
                      ? "nav-item nav-link  active "
                      : "nav-item nav-link "
                  }
                    onClick={() => {
                      setEditorTab(true);
                      setTimelineTab(false);
                      setArticleTab(false);
                    }}>
                    <>
                      <Link className="editor-link"
                        to={{
                          pathname: "/content-main/editorContent",
                          state: { requirementid: id, keywordid: keywordid }
                        }}
                      >
                        Editor
                      </Link>
                    </>
                  </Tab>
                  <Tab className={
                    TimelineTab
                      ? "nav-item nav-link  active "
                      : "nav-item nav-link "
                  }
                    onClick={() => {
                      setEditorTab(false);
                      setTimelineTab(true);
                      setArticleTab(false);
                    }}>Timeline</Tab>
                </TabList>
              </div>
            </div>

            <TabPanel>
              <div className="seo-accordian-box keyword-details-accordian  border-dark px-3 mt-3 ">
                <div class="accordian-title  portlet-title p-15">
                  Keyword Details
                </div>
                <div className="assign-details-table bg-white border ">
                  <div class="row">
                    <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">Primary Keyword</div>
                    <div class="col-md-10 py-2 px-3">
                      <span className="website-btn py-1 px-2 me-3 fs-6 fw-5 fc-black2">
                        { keywordDetailList?.primaryKeywords }</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">Secondary Keyword</div>
                    <div class="col-md-10 py-2 px-3">
                    {
                      keywordDetailList?.secondaryKeywords.map((item) => {  
                          return (<span className="website-btn py-1 px-2 me-3 fs-6 fw-5 fc-black2"> {item.secondaryKeyword} </span>);
                      })
                    }
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">Include Keyword</div>
                    <div class="col-md-10 py-2 px-3">
                      {keywordDetailList?.includeKeyword.map((item) => {
                          return(<span className="website-btn py-1 px-2 me-3 fs-6 fw-5 fc-black2">{item.includeKeyword}</span>);
                        })
                      }
                    </div>
                  </div>
                  <div class="row assign-url">
                    <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">URL</div>
                    <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">
                      {keywordDetailList?.url }
                    </div>
                  </div>
                  <div class="row assign-ur">
                    <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">Category</div>
                    <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">
                      {keywordDetailList?.category}
                    </div>
                  </div>
                </div>
              </div>
              {writreDetailList ?
                <>
                  <div className="seo-accordian-box keyword-details-accordian border-dark px-3 my-3">
                    <div class="accordian-title  portlet-title px-0">
                      <div className="col-md-12 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-betwwen">
                          Writer Details
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <svg xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#424242"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            onClick={() => {
                              if (!isChange) {
                                getArticleDetailsAPI()
                              }
                              showLargeDrawer1()
                            }}
                            class="feather feather-edit edit-icon me-3">
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                            <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z">
                            </path></svg>
                        </div>
                      </div>
                    </div>
                    <div className="writer-details-table bg-white border">
                      <div class="row ">
                        <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">Name</div>
                        <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">{writreDetailList?.name}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">Cost</div>
                        <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">{writreDetailList?.cost ? "Rs." + writreDetailList?.cost + "/word" : 0}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">ETA</div>
                        <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">{writreDetailList?.eta ? formateDateIntoDatemonth(writreDetailList?.eta) : ''}</div>
                      </div>
                    </div>
                  </div>
                </>
                : ''}
              {editorDetailList ?
                <>
                  <div className="seo-accordian-box keyword-details-accordian border-dark px-3 my-3">
                    <div class="accordian-title  portlet-title px-0">
                      <div className="col-md-12 d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center justify-content-betwwen">
                          Editor Details
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24"
                            height="24" viewBox="0 0 24 24" fill="none"
                            stroke="#424242" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-edit edit-icon me-3"
                            onClick={() => {
                              if (isChangeEditor) {
                                getArticleDetailsAPI()
                              }
                              showLargeDrawerEdit()
                            }}>
                            <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7">
                            </path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                        </div>
                      </div>
                    </div>
                    <div className="writer-details-table bg-white border">
                      <div class="row">
                        <div class="col-md-2 py-2 fc-black2 fs-6 fw-5 ">Name</div>
                        <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">{editorDetailList?.name}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 py-2 fc-black2 fs-6 fw-5 ">Cost</div>
                        <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">{editorDetailList?.cost ? "Rs." + editorDetailList?.cost + "/word" : 0}</div>
                      </div>
                      <div class="row">
                        <div class="col-md-2 py-2 fc-black2 fs-6 fw-5">ETA</div>
                        <div class="col-md-10 py-2 px-3 fc-black1 fs-6 fw-5">{editorDetailList?.eta ? formateDateIntoDatemonth(editorDetailList?.eta) : ''}</div>
                      </div>
                    </div>
                  </div>
                </> : ''}
            </TabPanel>
            <TabPanel>
              <>
                <Link
                  to={{
                    pathname: "/content-main/editorContent",
                  }}
                ></Link></>
            </TabPanel>
            <TabPanel>
              <div className=" row">
                <div className="col-md-6">
              <div class=" editor-timeline-box timeline-box border-0 global-scrollbar mt-3">
                <div class="timeline-list editor-timelinelist pt-0">
                  <div class="outer">
                    {timeline?.map((item) => {
                      return (
                        <>
                          <div class="card">
                            <div class="icon">
                              {item.timelineType == "0" ? (
                                <i
                                  class="fa fa-ticket"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "1" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#0691EF"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-clock"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <polyline points="12 6 12 12 16 14"></polyline>
                                </svg>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "2" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#0691EF"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-user-check"
                                >
                                  <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                  <circle cx="8.5" cy="7" r="4"></circle>
                                  <polyline points="17 11 19 13 23 9"></polyline>
                                </svg>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "3" ? (
                                <i
                                  class="far fa-comment-alt"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "4" ? (
                                <i
                                  class="fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "5" ? (
                                <i
                                  class="fa fa-ticket"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "6" ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#0691EF"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-clock"
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <polyline points="12 6 12 12 16 14"></polyline>
                                </svg>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "7" ? (
                                <i
                                  class="fa fa-check"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="info">
                              <h6 class="title fw-bold fs-6 fc-black1">
                                {formatdatetime(item.createdOn)}
                              </h6>
                              <span class="gray_font fs-6 fw-5">
                                {item.comment
                                  ? item.comment
                                  : "No commmnets"}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>     
              </div>
              </div>
              <div className="col-md-6"></div>
              </div>

              <div className="row mb-4 mt-4 ms-5">
                <div className="col-md-6  cms-timeline-border p-3">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center justify-content-between">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-text mb-2 me-3">
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                      <polyline points="14 2 14 8 20 8"></polyline>
                      <line x1="16" y1="13" x2="8" y2="13"></line>
                    <line x1="16" y1="17" x2="8" y2="17"></line>
                    <polyline points="10 9 9 9 8 9"></polyline>
                    </svg>
                    <label className="d-flex align-items-center fw-6 fc-black1 fs-18 ">Shoes for Women</label>
                    </div>
                
                    <div className="d-flex align-items-center justify-content-between">
                  <button className="d-flex align-items-center view-content  bg-white ">
                    <span className="fs-6 fw-6">View Content</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-external-lin ms-2">
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                  <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                    </button>
                 
                  </div>
                  </div>
              
                  <div className="border-bottom my-3"></div>

              
                    <div className="row">
                    <div className="d-flex align-items-center justify-content-between accept-request px-0">
                    <div className="col-md-12 d-flex align-items-center justify-content-between px-0">
                      <div className="col-md-6 d-flex align-items-center justify-content-between ps-0">
                    <button className="btn btn-primary fs-6 fw-6 line-height24 w-100">Accept Work</button>
                    </div>
                    <div className="col-md-6 pe-0">
                    <button className="request-rework border-radius6 fs-6 fw-6 w-100">Request Rework</button>
                    </div>
                    </div>
                    </div>
                    {/* <div className="col-md-6"></div> */}
                    </div>
                  
                </div>

                <div className="col-md-6"></div>
                </div>
            </TabPanel>
          </div>
        </Tabs>
      </div>
      {/* Writer */}
      <Drawer
        title="Assign Writer"
        className=" industries-modal add-industries assign-writer-drawer"
        placement="right"
        size={size}
        onClose={onClose}
        open={writeropen}
      >
        <div
          class="modal-dialog scope-modal industries-form"
          role="document"
        >
          <div class="modal-content">
            <div className="col-md-12  industries-ticket-btn">
              <>
                <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                  <button
                    className="btn btn-outline-secondary close me-3"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary w-auto"
                    onClick={() => {
                      setIsChange(true)
                      updateWriterData();
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    Assign Writer
                  </button>
                </div>
              </>
            </div>
            <div class="modal-body p-0 drawer-box">
              <div className="portlet common-form-fields border-0">
                <div className="px-0">
                  <div className="portlet-body industries-individual px-1">
                    <div class="col-md-12 ">
                      <label className="form-label mb-0">Writer</label>
                      <select className=" w-100 form-select writerselect"
                        value={writerId}
                        onChange={(e) => {
                          let selectedIndex = e.target.selectedIndex;
                          writerList.map((item, index) => {
                            if (e.target.value != '') {
                              if (e.target.value == item.id && index == selectedIndex - 1) {
                                setCostPerWord(item.costPerWord != null ? item.costPerWord : "");
                                setETA(item.eta != null ? item.eta : new Date());
                              }
                            }
                            else {
                              setCostPerWord("");
                              setETA("");
                            }
                          })
                          setWriterID(e.target.value);

                        }}
                      >
                        <option value=''>--Select--</option>
                        {writerList.map((item) => {
                          return <option value={item.id}>{item.name}</option>
                        })}

                      </select>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label mb-0">Cost per Word</label>
                      <input className="w-100 "
                        type="text"
                        value={costPerWord}
                        onChange={(e) => {
                          setCostPerWord(e.target.value);
                        }}
                      />
                    </div>
                    <div class="col-md-12 mt-3 writer-eta">
                      <label className="form-label mb-0">ETA</label>
                      <input className="w-100 "
                        type="date"
                        value={convertDate(eta)}
                        onChange={(e) => {
                          setETA(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer >

      {/* editor */}
      < Drawer
        title="Assign Editor"
        className=" industries-modal add-industries assign-editor-drawer"
        placement="right"
        size={sizeEdit}
        onClose={onClose}
        open={editorOpen}
      >
        <div
          class="modal-dialog scope-modal industries-form"
          role="document"
        >
          <div class="modal-content">
            <div className="col-md-12  industries-ticket-btn">
              <>
                <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                  <button
                    className="btn btn-outline-secondary close me-3"
                    type="button"
                    onClick={onClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary w-auto"
                    onClick={() => {
                      setIsChangeEditor(true)
                      updateEditorData()
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                    Assign Editor
                  </button>
                </div>
              </>
            </div>
            <div class="modal-body p-0 drawer-box">
              <div className="portlet common-form-fields border-0">
                <div className="px-0">
                  <div className="portlet-body industries-individual px-1">
                    <div class="col-md-12">
                      <label className="form-label mb-0">Editor</label>
                      <select className=" w-100 form-select writerselect"
                        value={editorId}
                        onChange={(e) => {
                          setEditorID(e.target.value);
                          let selectedIndex = e.target.selectedIndex;
                          editorList.map((item, index) => {
                            if (e.target.value != '') {
                              if (e.target.value == item.id && index == selectedIndex - 1) {
                                setCostPerWordEditor(item.costPerWord != null ? item.costPerWord : "");
                                setETAEditor(item.eta != null ? item.eta : new Date());
                              }
                            }
                            else {
                              setCostPerWordEditor("");
                              setETAEditor("")
                            }
                          })
                        }}
                      >
                        <option value=''>--Select--</option>
                        {editorList.map((item) => {
                          return <option value={item.id}>{item.name}</option>
                        })}

                      </select>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label mb-0">Cost per Word</label>
                      <input className="w-100 "
                        type="text"
                        value={costPerWordEditor}
                        onChange={(e) => {
                          setCostPerWordEditor(e.target.value);
                        }}
                      />
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label mb-0">ETA</label>
                      <input className="w-100 "
                        type="date"
                        value={convertDate(etaEditor)}
                        onChange={(e) => {
                          setETAEditor(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Drawer >
    </>
  );
};

export default RequirementKeywordDetails;
