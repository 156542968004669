import { Input, Table, Drawer } from "antd";
import { useState, useEffect, useRef } from "react";
import APIManager from "../../services/APIManager";
import {
  creatIndustry,
  deleteSelectedIndustry,
  documentFileType,
  deleteMultiSelectIndustry,
  industryImport,
  deleteAllIndustry,
  updateIndustry,
  GET_Server_URL,
  getIndustriesById,
} from "../../utility/Constant";
import { confirmAlert } from "react-confirm-alert";
import toast from "react-hot-toast";

const Industries = ({ update }) => {
  const [isIndividual, setIsIndividual] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [industryData, setIndustryData] = useState([]);
  const [industryDataFilter, setIndustryDataFilter] = useState([]);
  const [industryColumns, setIndustryColumns] = useState("");
  const [isAddIndustry, setIsAddIndusry] = useState(false);
  const [fileSetelctedMsg, setFileSetelctedMsg] = useState("No File Selected");
  const [fileName, setFileName] = useState("");
  const [industryName, setIndustryName] = useState("");
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [SelectedIndustryCheckList, setSelectedIndustryCheckList] = useState(
    []
  );
  const [message, setMessage] = useState("");
  const [messageRequired, setMessageRequired] = useState("");
  const [messageEdit, setMessageEdit] = useState("");
  const [messageEditExist, setMessageEditExist] = useState("");
  const [industryNameEdit, setIndustryNameEdit] = useState("");
  const [industryNameCompare, setIndustryNameCompare] = useState("");
  const [industryId, setIndustryId] = useState("");
  const [className1, setClassName1] = useState("");
  const [className2, setClassName2] = useState("");
  const [url, setUrl] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);

  useEffect(() => {
    getIndustryListFun();
    var data = [
      {
        title: "Industries",
        dataIndex: "industry",
        key: "industry",
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    setIndustryColumns(data);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      if (info.type == "all") {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        industryData.map((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            industry: item.industry,
          };
          list.push(obj);
        });
        setSelectedExportList(list);
      } else {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        selectedRows.map((item, index) => {
          selectedRowKeys.push(item.key);
          let obj = {
            key: item.key,
            industry: item.industry,
          };
          list.push(obj);
        });
        setSelectedExportList(list);
      }
      const list1 = [];
      selectedRows.map((item) => {
        list1.push(item.key);
      });
      setSelectedIndustryCheckList(list1);
    },
  };

  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();
  const onClose = () => {
    setOpen(false);
  };

  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const [openEdit, setOpenEdit] = useState(false);
  const [sizeEdit, setSizeEdit] = useState();
  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const showLargeDrawerEdit = () => {
    setSizeEdit("large");
    setOpenEdit(true);
  };

  function getIndustryListFun() {
    let list = [];
    APIManager.getAPI(
      getIndustriesById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response.status == 200) {
        response.data.map((item) => {
          let object = {
            industry: item.industriesName,
            key: item.id,
            action: (
              <div className="d-flex text-center px-2">
                <div
                  class=" edit-department-btn"
                  type="button"
                  onClick={() => {
                    setIndustryNameEdit(item.industriesName);
                    setIndustryId(item.id);
                    setIndustryNameCompare(item.industriesName);
                    setMessageEdit("");
                    setMessageEditExist("");
                    showLargeDrawerEdit();
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon "
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon ms-2 mt-1"
                  onClick={() => {
                    deleteSingleIndustry(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          };
          list.push(object);
        });
        setIndustryData(list);
        setIndustryDataFilter(list);
      }
    });
  }

  function isValid() {
    let valid = true;
    if (industryName.trim().length == 0) {
      setMessageRequired("Industry is required!");
      valid = false;
    } else {
      setMessageRequired("");
    }
    industryData.map((item) => {
      if (item.industry === industryName.trim()) {
        setMessage("Industry already exist!");
        valid = false;
      } else {
        setMessage("");
      }
    });
    return valid;
  }

  function addIndustry() {
    APIManager.postAPI(
      creatIndustry,
      {
        IndustriesName: industryName.trim(),
        OrganizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setIndustryName("");
        getIndustryListFun();
        onClose();
        notifyAdd();
      }
    });
  }

  async function deleteSingleIndustry(industryId) {
    try {
      const response = await APIManager.deleteAPI(
        deleteSelectedIndustry + industryId
      );
      if (response == 200) {
        getIndustryListFun();
      }
    } catch (e) { }
  }

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function uploadkeywordFile() {
    setIsAddIndusry(true);
    var input = document.getElementById("industryFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileSetelctedMsg("");
    setFileName(file.name);
  }

  function deleteMultiSelectedIndustry() {
    if (SelectedIndustryCheckList != 0) {
      let rowData = {
        ids: SelectedIndustryCheckList,
      };
      return new Promise((resolve, reject) => {
        confirmAlert({
          title: "Confirm to Delete",
          message: "Are you sure to do this.",
          buttons: [
            {
              label: "Yes",
              onClick: () => {
                APIManager.postAPIForDelete(
                  deleteMultiSelectIndustry,
                  rowData,
                  {},
                  true
                ).then((response) => {
                  if (response && response.status == 200) {
                    getIndustryListFun();
                  }
                });
              },
            },
            {
              label: "No",
              onClick: () => {
                reject("No");
              },
            },
          ],
        });
      });
    }
  }
  function deleteAllIndustryFun() {
    return new Promise((resolve, reject) => {
      APIManager.deleteAPI(
        deleteAllIndustry + localStorage.getItem("organizationId")
      ).then((response) => {
        if (response == 200) {
          getIndustryListFun();
        }
      });
    });
  }

  const notifyAdd = () => toast.success("Successfully Added!");

  function addIndustryInBulk() {
    setCloseButton(false);
    var bodyFormData = new FormData();
    var input = document.getElementById("industryFile");
    let file = input.files.item(0);
    let isImport = true;
    bodyFormData.append(
      "organizationId",
      localStorage.getItem("organizationId")
    );
    if (
      document.querySelector("#textInputUrl").disabled == false &&
      isValidURL()
    ) {
      bodyFormData.append("url", url);
      isImport = true;
    } else if (document.querySelector("#industryFile").disabled == false) {
      isImport = true;
      bodyFormData.append("file", file);
    } else {
      isImport = false;
    }
    if (isImport) {
      APIManager.postAPI(industryImport, {}, bodyFormData, false).then(
        (response) => {
          if (response.status == 200) {
            notifyAdd();
            getIndustryListFun();
            onClose();
            setErrors("");
            setUrl("");
          }
        }
      );
    }
  }

  function updateIndustryFun() {
    APIManager.putAPI(
      updateIndustry,
      {
        Id: industryId,
        IndustriesName: industryNameEdit,
        OrganizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response && response?.status == 200 && response?.data) {
        getIndustryListFun();
        setMessageEdit("");
        setMessageEditExist("");
        onCloseEdit();
      }
    });
  }

  function isValidUpdate() {
    let valid = true;
    if (industryNameEdit.trim().length == 0) {
      setMessageEdit("Category is required!");
      valid = false;
    } else {
      setMessageEdit("");
    }

    industryData.map((item) => {
      if (
        item.industry === industryNameEdit.trim() &&
        item.industry != industryNameCompare.trim()
      ) {
        setMessageEditExist("Industry already exist!");
        valid = false;
      } else {
        setMessageEditExist("");
      }
    });
    return valid;
  }

  function isValidURL() {
    let error = { ...errors };
    if (url.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );
      if (!pattern.test(url.trim())) {
        error["url"] = "Please enter valid URL";
      } else {
        delete error["url"];
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  return (
    <>
      <div className="portlet p-0  roles-setting-top ">
        <div class="portlet-header portlet-header-bordered">
          <svg
            onClick={() => update("")}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left close-left-icon"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("organizationName")} - Industries
          </h3>
        </div>
        <div className="portlet-body">
          <div className="row">
            <div className="col-md-3  col-sm-12  d-flex justify-content-start align-items-center">
              <div class="input-group-icon input-group-lg widget15-compact">
                <div class="input-group-prepend">
                  <i class="fa fa-search text-primary"></i>
                </div>
                <div className="search-box">
                  <span>
                    <Input
                      allowClear
                      style={{ height: 36 + "px" }}
                      type="text"
                      class="form-control"
                      placeholder="Type to search..."
                      onChange={(industrySearch) => {
                        setIndustryData(
                          industryDataFilter.filter((person) =>
                            person.industry
                              .toLocaleLowerCase()
                              .includes(
                                industrySearch.target.value.toLocaleLowerCase()
                              )
                          )
                        );
                      }}
                      id="input-s"
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-9  d-flex justify-content-end align-items-center  industries-tab">
              <button
                class="btn btn-primary delete-btn"
                onClick={() => {
                  if (localStorage.getItem("selectionInfoType") !== "all") {
                    deleteMultiSelectedIndustry();
                  } else {
                    deleteAllIndustryFun();
                  }
                }}
              >
                Delete
              </button>
              <button
                class="btn btn-primary mx-3 add"
                type="button"
                onClick={() => {
                  setIsBulk(false);
                  setIsIndividual(false);
                  setFileSetelctedMsg("No File Selected");
                  setFileName("");
                  setMessage("");
                  setMessageRequired("");
                  setIsAddIndusry(false);
                  showLargeDrawer();
                  setUrl("");
                  setErrors("");
                  setClassName1("");
                  setClassName2("");
                  setCloseButton(false);
                  const option1Btn = document.getElementById("option1");
                  const option2Btn = document.getElementById("option2");
                  option1Btn.checked = false;
                  option2Btn.checked = false;
                }}
              >
                Add
              </button>
              <span>
                <a
                  class="btn btn-primary download-temp "
                  target="_blank"
                  href={GET_Server_URL + "audit/Industries.xlsx"}
                >
                  Download Template{" "}
                </a>
              </span>

              <Drawer
                title=" Add Industries"
                className=" industries-modal add-industries"
                placement="right"
                size={size}
                onClose={onClose}
                open={open}
              >
                <div
                  class="modal-dialog scope-modal industries-form"
                  role="document"
                >
                  <div class="modal-content">
                    <div className="col-md-12  industries-ticket-btn">
                      {isAddIndustry ? (
                        <>
                          <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                            <button
                              className="btn btn-outline-secondary close me-3"
                              type="button"
                              onClick={onClose}
                            >
                              Cancel
                            </button>
                            <button
                              type="button"
                              class="btn btn-primary w-auto"
                              onClick={() => {
                                setFileName("");
                                if (isBulk) {
                                  addIndustryInBulk();
                                } else if (isIndividual) {
                                  if (isValid()) {
                                    addIndustry();
                                  }
                                }
                              }}
                            >
                              <div>Add Industry</div>
                            </button>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div class="modal-body p-0">
                      <div className="portlet common-form-fields border-0">
                        <div className="mx-3">
                          <div className={className1}>
                            <div className="radio">
                              <div className="ms-3">
                                <input
                                  className="me-3"
                                  type="radio"
                                  value="option1"
                                  id="option1"
                                  name="uploadType"
                                  onClick={(e) => {
                                    setIsBulk(e.target.checked);
                                    setIsIndividual(false);
                                    setIsAddIndusry(false);
                                    setIndustryName("");
                                    setClassName1("active");
                                    setClassName2("");
                                  }}
                                />
                                <label className="bulk-upload  main-title my-3">
                                  Bulk Upload
                                </label>
                              </div>
                              <div className="ms-5">
                                <span className="text-grey ">
                                  Setup multipe (bulk) upload with the details
                                  by uploading Google Sheet link/.xlsx/.csv file
                                </span>
                              </div>
                              {isBulk ? (
                                <>
                                  <div className="border-top mt-3 pt-3 industries-box ">
                                    <span className="ms-5 industries-csv-file">
                                      Add URL (Google Sheet Link)
                                    </span>
                                    <div className="mt-2 ms-5 me-3">
                                      <input
                                        className="form-control w-100"
                                        placeholder="URL"
                                        id="textInputUrl"
                                        value={url}
                                        onChange={(e) => {
                                          setUrl(e.target.value);
                                          if (e.target.value.length > 0) {
                                            setIsAddIndusry(true);
                                            document.querySelector(
                                              "#industryFile"
                                            ).disabled = true;
                                          } else {
                                            setErrors("");
                                            setIsAddIndusry(false);
                                            document.querySelector(
                                              "#industryFile"
                                            ).disabled = false;
                                          }
                                        }}
                                      ></input>
                                      {save && errors?.url && (
                                        <>
                                          <div className="field-required mt-2">
                                            <span> {errors?.url}</span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="my-3 text-center or">
                                      <span>OR</span>
                                    </div>
                                    <span className="ms-5 industries-csv-file">
                                      Add attachment (Only .xlsx or .csv file)
                                    </span>
                                  </div>
                                  <div className="industries-choose-file d-flex align-items-center p-2 mt-3 ms-5 me-3">
                                    <input
                                      className="w-100"
                                      style={{ display: "none" }}
                                      type="file"
                                      id="industryFile"
                                      name="industryFile"
                                      multiple={false}
                                      accept={documentFileType}
                                      ref={inputFileRef}
                                      onChange={uploadkeywordFile}
                                    />
                                    <a
                                      onClick={onBtnClick}
                                      id="btnChooseFile"
                                      className="btn btn-primary me-3"
                                    >
                                      Choose File
                                    </a>
                                    <span className="file-not-select ">
                                      {fileSetelctedMsg}
                                    </span>
                                    {fileName.length ? fileName : ""}
                                    {closeButton ? (
                                      <>
                                        <button
                                          type="button"
                                          title="Close"
                                          style={{
                                            border: "0",
                                            background: "none",
                                          }}
                                          class="close"
                                          onClick={() => {
                                            setFileName("");
                                            setCloseButton(false);
                                            setIsAddIndusry(false);
                                            document.querySelector(
                                              "#textInputUrl"
                                            ).disabled = false;
                                          }}
                                        >
                                          {" "}
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className={className2}>
                            <div className="radio mt-3">
                              <div>
                                <input
                                  className="ms-3"
                                  type="radio"
                                  value="option2"
                                  id="option2"
                                  name="uploadType"
                                  onClick={(e) => {
                                    setIsIndividual(e.target.checked);
                                    setIsBulk(false);
                                    setIsAddIndusry(true);
                                    setFileSetelctedMsg("No File Selected");
                                    setFileName("");
                                    setMessage("");
                                    setMessageRequired("");
                                    setClassName2("active");
                                    setClassName1("");
                                  }}
                                />
                                <label className=" individual-upload  ms-3 main-title">
                                  Individual Upload
                                </label>
                              </div>
                              <span className="mt-3 ms-5 text-grey">
                                Add category by manually adding details
                              </span>
                              <div>
                                {isIndividual ? (
                                  <div className="portlet-body industries-individual">
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label">
                                        Category
                                      </label>
                                      <input
                                        className="w-100 "
                                        value={industryName}
                                        onChange={(e) => {
                                          setIndustryName(e.target.value);
                                          setMessage("");
                                          setMessageRequired("");
                                        }}
                                      />
                                      <div
                                        class="ms-2 pt-2"
                                        style={{ color: "red" }}
                                      >
                                        {message}
                                        {messageRequired}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>

          <div class="col-md-12 mt-3 industries-table">
            <Table
              className="table table-bordered table-hover mt-3 "
              id="sample-module-expand"
              dataSource={industryData}
              columns={industryColumns}
              rowSelection={{
                type: "checkbox",
                ...rowSelection,
              }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>

        <Drawer
          title="Edit Industry"
          className="industries-modal edit-industries"
          placement="right"
          size={sizeEdit}
          onClose={onCloseEdit}
          open={openEdit}
        >
          <div class="modal-dialog industries-form" role="document">
            <div class="modal-content">
              <div className="col-md-12 industries-ticket-btn">
                <div class="portlet-footer portlet-footer-bordered mt-3 text-end">
                  <button
                    className="btn btn-outline-secondary close me-3"
                    type="button"
                    onClick={onCloseEdit}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary w-auto"
                    onClick={() => {
                      if (isValidUpdate()) {
                        updateIndustryFun();
                      }
                    }}
                  >
                    <div>Update</div>
                  </button>
                </div>
              </div>
              <div class="modal-body">
                <div className="common-form-fields ">
                  <div className="portlet-body  ">
                    <div class="col-md-12">
                      <label className="form-label">Industry</label>
                      <input
                        className="form-control form-control-lg"
                        value={industryNameEdit}
                        onChange={(e) => {
                          setIndustryNameEdit(e.target.value);
                          setMessageEdit("");
                          setMessageEditExist("");
                        }}
                      />
                      <div class="ms-2 pt-2" style={{ color: "red" }}>
                        {messageEdit}
                        {messageEditExist}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Drawer>
      </div>
    </>
  );
};
export default Industries;
