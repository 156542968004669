import { Progress, Space } from "antd";
import React, { useEffect } from "react";
import { useState } from "react";
import Chart from "react-apexcharts";
import APIManager from "../../services/APIManager";
import { getProjectOverview } from "../../utility/Constant";

function ProjectOverview(props) {
  const [options, setObject] = useState({});
  const [series, setSeries] = useState([]);
  const [projectCompleted, setProjectCompleted] = useState(35)
  const [turnaroundTime, setTurnaroundTime] = useState(30)

  useEffect(() => {
    getProjectOverviewFun()
  }, [props])

  useEffect(() => {
    let list = [500, 320, 200, 150, 300, 100, 300, 200, 120];

    let maxChartData = Math.max(...list) * 0.3 + Math.max(...list);
    setSeries([
      {
        name: "",
        data: list,
        color: "#42a6f5",
      },
    ]);

    let object = {
      title: {
        text: "",
        align: "left",
      },
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            zoom: false,
            zoomin: true,
            zoomout: true,
            customIcons: [],
          },
        },
        type: "line",
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          dataLabels: {
            position: 'top', // top, center, bottom
          },
          columnWidth: '88%'
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          return val;
        },
        offsetY: -20,
        style: {
          fontSize: '12px',
          colors: ["#304758"]
        }
      },
      xaxis: {
        tickPlacement: "on",
        categories: [
          "Requirement Raised",
          "Writer Assigned",
          "Writing in Progress",
          "Awaiting Content QC",
          "Editor Assigned",
          "Editing in Progress",
          "Awaiting Content QC",
          "Sent for Approval",
          "Articles Completed"
        ],
      },
      yaxis: {
        max: maxChartData,
        logBase: 20,
        tickAmount: 8,
        min: 0,
        show: false,
      },
      grid: {
        show: false,
      },
    }
    setObject(object)
  }, [])

  function getProjectOverviewFun() {
    APIManager.postAPI(getProjectOverview +
      "&projectId=" + props?.project +
      "&startDate=" + props?.dataParentToChild[0] +
      "&endDate=" + props?.dataParentToChild[1], {}, null, true).then(
        (response) => {
          if (response && response.status == 200) {            
            setProjectCompleted(response.data.table.projectCompletionStatus)
            setTurnaroundTime(response.data.table1.turnAroundTime)
          }
        }
      );
  }
  return (
    <>
      <div className="title content-project-overview mt-3">
        <div className="content-status-time">
          {/* <div class="container align-items-center"> */}
          {/* <div className="row html2pdf__page-break all-channel-table"> */}
          <div className="row p-0">
            <div className="col-md-12 d-flex align-items-center pt-0">
              <div className="col-md-3 ">
                <div className="completion-status p-4 d-flex">
                  <div className="cms-dashboard-progress me-3">
                    <Progress 
                    type="circle" 
                    percent={projectCompleted} 
                    // size={50} 
                    strokeWidth = {13}
                    showInfo={false} style={{ height: "50px" }} />
                  </div>
                  <div>
                    <span>Project Completed</span>
                    <p>{projectCompleted}%</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3  ms-4">
                <div className="turnaround-time p-4 d-flex">
                  <div className="cms-dashboard-progress me-3">
                    <Progress type="circle" 
                    percent={turnaroundTime} 
                    // size={2}
                     showInfo={false} 
                    strokeWidth = {13}
                    style={{ height: "50px" }} />
                  </div>
                  <div>
                  </div>
                  <div>
                    <span>Turnaround Time</span>
                    <p>24 Jan 2022</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          {/* </div> */}

          <div className="portlet-body position-relative content-chart mt-3">
            <Chart
              options={options}
              series={series}
              type="bar"
              height="380px"
            />
          </div>
        </div>
      </div>
    </>
  )
}
export default ProjectOverview;