import React from "react";
import { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import { NavLink, useHistory } from "react-router-dom";
import { Doughnut, Pie, Line } from "react-chartjs-2";

const PieChart = (props) => {
    return (
        <>
            <div
                // style={{ width: 200 + "px" }}
                className="chart-box col-md-3"
            >
                <Pie
                    data={{
                        labels: props.labels, // ["High", "Medium", "Low"],
                        datasets: [
                            {
                                label: props.title,
                                data: props.data,
                                backgroundColor: [
                                    "#91e395",
                                    "#ffaa46",
                                    "#ef5350",
                                ],
                                borderColor: [
                                    "#91e395",
                                    "#ffaa46",
                                    "#ef5350",
                                ],
                                borderWidth: 0,
                            },
                        ],
                    }}
                    options={{
                        responsive: [
                            {
                                breakpoint: 200,
                                options: {
                                    chart: {
                                        // width: 50,
                                    },
                                    legend: {
                                        show: false,
                                    },
                                },
                            },
                        ],
                        pieceLabel: {
                            render: "value",
                            fontColor: "#fff",
                        },
                        legend: false,
                    }}
                />
                <div className="chart-title">
                    {props.title}
                </div>
            </div>
        </>
    )
}

export default PieChart;