import React from "react";
import { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import { Input } from "antd";
import Chart from "react-apexcharts";
import { NavLink, useHistory, Link } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import Truncate from 'react-truncate-html';
import {
  exportInToCSV,
  exportToEXCEL,
  getCheckPermission,
  mmDDyyyyFormateDate,
  mmDDyyyyFormateForForm22,
  mmDDyyyyFormateDateSet,
  setMonths,
  setPriority,
  downloadPDFfile,
  chartCenterTextPlugins,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import { PDFExport } from "@progress/kendo-react-pdf";
import {
  getSeoAuditByProjectAuditId,
  getSEOAuditGetById,
  getSeoAuditResponse,
  getSeoAuditCategoryData,
  getCategoriesScoreData,
  getSeoAuditByScoreChartId,
  getSEOAuditChartData,
  GET_Server_URL,
} from "../utility/Constant";
import { Sorter } from "../common/Sorter";
import Accordion from "react-bootstrap/Accordion";
// import { Chart as ChartJS } from 'chart.js';
import { Doughnut, Pie, Line } from "react-chartjs-2";
import { ProgressBar } from "react-bootstrap";
import "chart.piecelabel.js";
import html2canvas from "html2canvas";
import { Drawer } from "antd";
import jsPDF from "jspdf";
import PieChart from "./seoaudit/PieChart";
import HTMLdata from "./seoaudit/HTMLdata";
import DoughnutChart from "./seoaudit/DoughnutChart";
import ModuleExpandSEOAuditV3 from "./ModuleExpandSEOAuditV3";

function ModuleExpandSEOAudit() {
  const [options, setObject] = useState({});
  const [auditListData, setSEOAuditData] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [listcol, setlistcol] = useState([]);
  const [listData, setlistData] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [auditDate, setAuditDate] = useState(mmDDyyyyFormateForForm22);
  const [progressColor, setProgressColor] = useState("");
  const [progressColor2, setProgressColor2] = useState("#dc3545");
  const [progress, setProgress] = useState("Active");
  const [changeRequestList, setchangeRequestList] = useState([]);
  const [implementationList, setimplementationList] = useState([]);
  const [implementedList, setimplementedList] = useState([]);
  const [onHoldList, setonHoldList] = useState([]);
  const [pendingList, setpendingList] = useState([]);
  const [wipList, setwipList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [modulesexportList, setModulesExportList] = useState([]);
  const [showInfoData, setShowInfoData] = useState(false);
  const history = useHistory();
  const [accordianData, setAccordianData] = useState({});
  const [chartdropdown, setChartDropdown] = useState([]);
  const [projectAuditIdd, setProjectAuditIdd] = useState([]);
  const [auditScore, setAuditScore] = useState([]);
  const [projectAuditName, setProjectAuditName] = useState([]);
  const [projectId, setProjectId] = useState([]);
  const [categoriesScore, setCategoriesScore] = useState({});
  const [categorySelected, setCategorySelected] = useState({});
  const [categoryAlreaddSelecteSelected, setcategoryAlreaddSelecteSelected] = useState(true);

  const [isExpanded, setIsExpanded] = useState(true);
  const closeAll = (value) => {
    setIsExpanded(value);
    // let tempArr = accordianData.item.auditResponseByCategories.map((i) => {
    //   i.isActive = value
    // })
  };

  function showAllAccordionTabs(auditModuleName, index) {
    const expandBtn = document.querySelectorAll(".isExpanded-btn");
    for (var j = 0; j < expandBtn.length; j++) {
      if (j == index) {
        expandBtn[j].innerHTML = "Collapse All";
      } else {
        expandBtn[j].innerHTML = "Expand All";
      }
    }

    const getCollapsedAccordionHeaders = document.getElementsByClassName("accordion-header");
    for (var i = 0; i < getCollapsedAccordionHeaders.length; i++) {
      if (getCollapsedAccordionHeaders[i].getAttribute("id") == auditModuleName) {
        if (getCollapsedAccordionHeaders[i].getElementsByClassName("accordion-button")[0].classList.contains('collapsed')) {
          getCollapsedAccordionHeaders[i].getElementsByClassName("accordion-button")[0].classList.remove("collapsed");
          getCollapsedAccordionHeaders[i].getElementsByClassName("accordion-button")[0].attributes['aria-expanded'].value = "true";
        }

        const getCollapsedAccordionSections = getCollapsedAccordionHeaders[i].parentNode.getElementsByClassName("accordian-identity");
        for (var j = 0; j < getCollapsedAccordionSections.length; j++) {
          getCollapsedAccordionSections[j].parentNode.classList.remove("collapse");
          getCollapsedAccordionSections[j].parentNode.classList.add("show");
        }
      }
    }
  }

  function hideAllAccordionTabs(auditModuleName, index) {
    const expandBtn = document.querySelectorAll(".isExpanded-btn");
    for (var j = 0; j < expandBtn.length; j++) {
      expandBtn[j].innerHTML = "Expand All";
    }

    const getCollapsedAccordionHeaders = document.getElementsByClassName("accordion-header");
    for (var i = 0; i < getCollapsedAccordionHeaders.length; i++) {
      if (getCollapsedAccordionHeaders[i].getAttribute("id") == auditModuleName) {
        getCollapsedAccordionHeaders[i].getElementsByClassName("accordion-button")[0].classList.add("collapsed");
        getCollapsedAccordionHeaders[i].getElementsByClassName("accordion-button")[0].attributes['aria-expanded'].value = "false";

        const getCollapsedAccordionSections = getCollapsedAccordionHeaders[i].parentNode.getElementsByClassName("accordian-identity");
        for (var j = 0; j < getCollapsedAccordionSections.length; j++) {
          getCollapsedAccordionSections[j].parentNode.classList.remove("show");
          getCollapsedAccordionSections[j].parentNode.classList.add("collapse");
        }
      }
    }
  }

  useEffect(() => {

    const columns = [
      {
        title: "Module",
        dataIndex: "module",
        key: "module",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.module, b.module),
          multiple: 3,
        },
      },
      {
        title: "Points Checked",
        dataIndex: "pointsChecked",
        key: "pointsChecked",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.pointsChecked, b.pointsChecked),
          multiple: 3,
        },
      },
      {
        title: "Points Pending",
        dataIndex: "pointsPending",
        key: "pointsPending",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.pointsPending, b.pointsPending),
          multiple: 3,
        },
      },
      {
        title: "Compliant Points",
        dataIndex: "compliantPoints",
        key: "compliantPoints",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) =>
            Sorter.DEFAULT(a.compliantPoints, b.compliantPoints),
          multiple: 3,
        },
      },
      {
        title: "Non Compliant",
        dataIndex: "nonCompliants",
        key: "nonCompliants",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.nonCompliants, b.nonCompliants),
          multiple: 3,
        },
      },
      {
        title: "High Impact",
        dataIndex: "highImpact",
        key: "highImpact",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.highImpact, b.highImpact),
          multiple: 3,
        },
      },
      {
        title: "Medium Impact",
        dataIndex: "mediumImpact",
        key: "mediumImpact",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.mediumImpact, b.mediumImpact),
          multiple: 3,
        },
      },
      {
        title: "Low Impact",
        dataIndex: "lowImpact",
        key: "lowImpact",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.lowImpact, b.lowImpact),
          multiple: 3,
        },
      },
      {
        title: "P0",
        dataIndex: "p0",
        key: "p0",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.P0, b.P0),
          multiple: 3,
        },
      },
      {
        title: "P1",
        dataIndex: "p1",
        key: "p1",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.P1, b.P1),
          multiple: 3,
        },
      },
      {
        title: "P2",
        dataIndex: "p2",
        key: "p2",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.P2, b.P2),
          multiple: 3,
        },
      },
    ];

    const custcolumns = [
      {
        title: "Module",
        dataIndex: "module",
        key: "module",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.module, b.module),
          multiple: 3,
        },
      },
      {
        title: "High Impact",
        dataIndex: "highImpact",
        key: "highImpact",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.highImpact, b.highImpact),
          multiple: 3,
        },
      },
      {
        title: "Medium Impact",
        dataIndex: "mediumImpact",
        key: "mediumImpact",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.mediumImpact, b.mediumImpact),
          multiple: 3,
        },
      },
      {
        title: "Low Impact",
        dataIndex: "lowImpact",
        key: "lowImpact",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.lowImpact, b.lowImpact),
          multiple: 3,
        },
      },
      {
        title: "P0",
        dataIndex: "p0",
        key: "p0",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.P0, b.P0),
          multiple: 3,
        },
      },
      {
        title: "P1",
        dataIndex: "p1",
        key: "p1",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.P1, b.P1),
          multiple: 3,
        },
      },
      {
        title: "P2",
        dataIndex: "p2",
        key: "p2",
        width: "10%",
        sorter: true,
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.P2, b.P2),
          multiple: 3,
        },
      },
    ];

    if (localStorage.getItem("userRole") == "Customer") {
      setteamcol(custcolumns);
    } else {
      setteamcol(columns);
    }

    const columns2 = [
      {
        title: "Module",
        dataIndex: "module",
        key: "module",
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Impact",
        dataIndex: "impact",
        key: "impact",
        width: "8%",
      },
      {
        title: "Priority",
        dataIndex: "priority",
        key: "priority",
        width: "8%",
      },
    ];

    setlistcol(columns2);

    getAuditList();
  }, []);

  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: ["Completed", "Remaining"],
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
        borderColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
      },
    ],
  };

  const labelsHover = ["January", "February", "March"];

  const [aditDateList, setAuditDateList] = useState([]);
  const [selectedAuditId, setAuditId] = useState("");

  const [cp, setCp] = useState(0);
  const [ncp, setNcp] = useState(0);
  const [naPoints, setNaPoints] = useState(0);
  const [totalCheckedPoints, setTotalCheckedPoints] = useState(0);
  const [auditType, setAuditType] = useState("");
  const [chartLabelsID, setChartLabelsID] = useState(0);
  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  function getAuditList() {
    APIManager.getAPI(
      getSEOAuditGetById + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        let auditList = [];
        response.data?.audits.map((item) => {
          if (item.isShown == true) {
            auditList.push(item);
          }
        });

        setAuditDateList(auditList);

        if (auditList && auditList.length > 0) {
          projectAuditId = auditList[0].seoAuditId;
          //getSeoAuditByScoreChart(auditList[0].seoAuditId, auditList);
          getSeoAuditByProjectAuditIdList(auditList[0].seoAuditId, auditList);
          setAuditDate(auditList[0].seoAuditDate);
        }

        setTimeout(() => {
          if (auditList && auditList.length > 0) {
            projectAuditId = auditList[0].seoAuditId;
            getSeoAuditByScoreChart(auditList[0].seoAuditId, auditList);
          }
        }, 2000);

        getCategoriesScoreDetails(projectAuditId);
        if (auditList.length)
          setAuditId(auditList[0].seoAuditId);
        setProgress(response?.data?.status);

        //getAuditChartData(2, projectAuditId);
        localStorage.setItem("projectAuditId", projectAuditId);
        const list = [];
        const exportList = [];
        let i = 0;
        response.data?.seoAuditItems.map((item) => {
          exportList.push({
            key: (i += 1),
            module: item.module,
            pointsChecked: item.checkedPoints,
            pointsPending: item.pendingPoints,
            compliantPoints: item.compliantPoints,
            nonCompliants: item.nonCompliantPoints,
            highImpact: item.highImpact,
            mediumImpact: item.mediumImpact,
            lowImpact: item.lowImpact,
            p0: item.highPriority,
            p1: item.mediumPriority,
            p2: item.lowPriority,
          });

          list.push({
            key: (i += 1),
            module: item.module,
            pointsChecked: item.checkedPoints,
            pointsPending: item.pendingPoints,
            compliantPoints: item.compliantPoints,
            nonCompliants:
              item.nonCompliantPoints == 0 ? (
                item.nonCompliantPoints
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "nonCompliants");
                  }}
                >
                  {item.nonCompliantPoints}
                </div>
              ),
            highImpact:
              item.highImpact == 0 ? (
                item.highImpact
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "highImpact");
                  }}
                >
                  {item.highImpact}
                </div>
              ),
            mediumImpact:
              item.mediumImpact == 0 ? (
                item.mediumImpact
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "mediumImpact");
                  }}
                >
                  {item.mediumImpact}
                </div>
              ),
            lowImpact:
              item.lowImpact == 0 ? (
                item.lowImpact
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "lowImpact");
                  }}
                >
                  {item.lowImpact}
                </div>
              ),
            p0:
              item.highPriority == 0 ? (
                item.highPriority
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "p0");
                  }}
                >
                  {item.highPriority}
                </div>
              ),
            p1:
              item.mediumPriority == 0 ? (
                item.mediumPriority
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "p1");
                  }}
                >
                  {item.mediumPriority}
                </div>
              ),
            p2:
              item.lowPriority == 0 ? (
                item.lowPriority
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "p2");
                  }}
                >
                  {item.lowPriority}
                </div>
              ),
          });
          i++;
        });

        let createdDateList = [];
        let changeRequestList = [];
        let implementationList = [];
        let implementedList = [];
        let onHoldList = [];
        let pendingList = [];
        let wipList = [];
        response.data?.seoChartDatas.map((item) => {
          createdDateList.push(setMonths(item.months));
          changeRequestList.push(item.changeRequest);
          implementationList.push(item.implementation);
          implementedList.push(item.implemented);
          onHoldList.push(item.onHold);
          pendingList.push(item.pending);
          wipList.push(item.wip);
        });
      }
    });
  }

  function setChartData(createdDateList) {
    setObject({
      chart: {
        height: 350,
        width: 500,
        type: "line",
        stacked: false,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 0, 0, 0, 0, 3],
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'middle',
        formatter: function (value, { seriesIndex, dataPointIndex, w }) {
          if (seriesIndex == 5) {
            return value + "%";
          } else {
            return value;
          }
        },
        offsetY: -9,
        style: {
          fontSize: '12px',
          colors: ["#000000"],
          fontWeight: 'normal',
        },
        background: {
          enabled: false,
        }
      },
      labels: createdDateList,
      grid: {
        show: false,
        padding: {
          left:
            createdDateList.length == 3 || createdDateList.length == 2
              ? 150
              : 60,
          right:
            createdDateList.length == 3 || createdDateList.length == 2
              ? 150
              : 60,
        },
      },
      yaxis: [
        {
          seriesName: "Changes Requested",
          axisTicks: {
            show: true,
          },
          axisBorder: {
            show: true,
            color: "#757575",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            // text: "Changes Requested",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "WIP",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "On Hold",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#008FFB",
          },
          labels: {
            style: {
              colors: "#008FFB",
            },
          },
          title: {
            text: "Pending",
            style: {
              color: "#008FFB",
            },
          },
        },
        {
          seriesName: "Changes Requested",
          show: false,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#91E395",
          },
          labels: {
            style: {
              colors: "#91E395",
            },
          },
          title: {
            text: "Implemented",
            style: {
              color: "#91E395",
            },
          },
        },
        {
          seriesName: "Implementation %",
          opposite: true,
          max: 100,
          axisTicks: {
            show: false,
          },
          axisBorder: {
            show: false,
            color: "#735EC9",
          },
          labels: {
            show: false,
            style: {
              colors: "#735EC9",
            },
          },
          title: {
            //text: "Implementation %",
            style: {
              color: "#735EC9",
            },
          },
        },
      ],
      tooltip: {
        y: {
          formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
            if (seriesIndex == 5) {
              return value + "%";
            } else {
              return value;
            }
          }
        }
      }
    });
  }

  function setStatusMSG(percentageValue) {
    let intVal = parseInt(percentageValue, 10);
    if (intVal >= 0 && intVal <= 30) {
      setProgressColor("red");
      setProgressColor2("#dc3545");
    } else if (intVal >= 30 && intVal <= 75) {
      setProgressColor("yellow");
      setProgressColor2("#ffc107");
    } else if (intVal > 75) {
      setProgressColor("green");
      setProgressColor2("#198754");
    }
  }

  function onPageDetails(categoryId) {
    if (categoryId == categorySelected) {
      setAccordianData({});
      setCategorySelected({});
      setcategoryAlreaddSelecteSelected(true);
    }
    else {
      setcategoryAlreaddSelecteSelected(false);

      setCategorySelected(categoryId);
      APIManager.getAPI(
        getSeoAuditCategoryData +
        "projectId=" +
        localStorage.getItem("projectGId") +
        "&projectAuditId=" +
        selectedAuditId +
        "&categoryId=" +
        categoryId
      ).then((response) => {
        if (response && response.status == 200) {
          var updatedValue;
          updatedValue = { item: response.data };
          setAccordianData((accordianData) => ({
            ...accordianData,
            ...updatedValue,
          }));

          if (
            accordianData &&
            accordianData.item &&
            accordianData.item.auditResponseByCategories
          )
            getAuditModules(
              accordianData.item.auditResponseByCategories[0].auditModuleName
            );
        }
      });
    }
  }

  function getCategoriesScoreDetails(auditId) {
    APIManager.getAPI(
      getCategoriesScoreData +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      auditId
    ).then((response) => {
      if (response && response.status == 200) {
        var updatedCategoryValue = { item: response.data };
        setCategoriesScore((categoryData) => ({
          ...categoryData,
          ...updatedCategoryValue,
        }));

        let l = []
        let v = []
        response.data.categoryScore.map((j) => {
          response.data.categoryMonthWiseResponses.map((i) => {
            let label = []
            let values = []
            if (j.category == i.category) {
              i.auditScoreMonthResponses.map((ii) => {
                label.push(setMonths(ii.months))
                values.push(ii.auditScore)
              })
              l.push(["", ...label])
              v.push([0, ...values])
            }
          })
        })
        setLabelsList(l)
        setValueList(v)
      }
    });
  }

  function getAuditChartData(id, auditId, totalNCP) {
    const createdDateListData = [];
    let changeRequestList = [];
    let changeRequestListTemp = [];
    let implementationList = [];
    let implementedList = [];
    let implementedListTemp = [];
    let onHoldList = [];
    let onHoldListTemp = [];
    let pendingList = [];
    let pendingListTemp = [];
    let wipList = [];
    let wipListTemp = [];
    APIManager.getAPI(
      getSEOAuditChartData +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      auditId +
      "&view=" +
      id
    ).then((response) => {
      if (response && response.status == 200) {
        response.data?.map((item) => {
          if (item.changeRequest == 0 && item.implemented == 0 && item.onHold == 0 && item.pending == 0 && item.wip == 0) { }
          else {
            if (id == 0) {
              createdDateListData.push(item.quartersRange);
            }
            if (id == 1) {
              createdDateListData.push(item.halfYearlyRange);
            }
            if (id == 2) {
              createdDateListData.push(item.months + " " + item.years);
            }

            let crTotoal = 0;
            let implementedTotal = 0;
            let onHoldTotal = 0;
            let pendingTotal = 0;
            let wipTotal = 0;
            changeRequestListTemp.push(item.changeRequest);
            implementedListTemp.push(item.implemented);
            onHoldListTemp.push(item.onHold);
            pendingListTemp.push(item.pending);
            wipListTemp.push(item.wip);

            changeRequestListTemp?.map((i) => {
              crTotoal = crTotoal + i;
            });
            implementedListTemp?.map((i) => {
              implementedTotal = implementedTotal + i;
            });
            onHoldListTemp?.map((i) => {
              onHoldTotal = onHoldTotal + i;
            });
            pendingListTemp?.map((i) => {
              pendingTotal = pendingTotal + i;
            });
            wipListTemp?.map((i) => {
              wipTotal = wipTotal + i;
            });
            let NCP = totalNCP == 0 ? ncp : totalNCP;
            changeRequestList.push(Math.round(crTotoal * 100) / 100);
            implementedList.push(Math.round(implementedTotal * 100) / 100);
            onHoldList.push(Math.round(onHoldTotal * 100) / 100);
            pendingList.push(Math.round(pendingTotal * 100) / 100);
            wipList.push(Math.round(wipTotal * 100) / 100);
            implementationList.push(
              Math.round(((implementedTotal * 100) / NCP) * 100) / 100
            );
          }
        });
        setchangeRequestList(changeRequestList);
        setimplementationList(implementationList);
        setimplementedList(implementedList);
        setonHoldList(onHoldList);
        setpendingList(pendingList);
        setwipList(wipList);
        setChartData(createdDateListData);
      }
    });
  }

  function getAuditModules(item) {
    if (
      accordianData &&
      accordianData.item &&
      accordianData.item.auditResponseByCategories
    ) {
      var sorted = {};
      for (
        var i = 0, max = accordianData.item.auditResponseByCategories.length;
        i < max;
        i++
      ) {

        if (
          sorted[
          accordianData.item.auditResponseByCategories[i].auditModuleName
          ] == undefined
        ) {
          sorted[
            accordianData.item.auditResponseByCategories[i].auditModuleName
          ] = [];
        }
        // accordianData.item.auditResponseByCategories[i].isActive = false
        sorted[
          accordianData.item.auditResponseByCategories[i].auditModuleName
        ].push(accordianData.item.auditResponseByCategories[i]);
      }
      return sorted[item];
    }
  }

  function getAuditModulesExport(auditModuleName, item) {
    let exportsection = [];
    for (var i = 0, max = accordianData.item.auditResponseByCategories.length; i < max; i++) {
      if (item[i].auditModuleName == auditModuleName && item[i].status) {
        exportsection.push({
          AuditPoint: item[i].pointTitle,
          Status: item[i].status == 1
            ? "Completed"
            : item[i].status == 3
              ? "WIP"
              : item[i].status == 4
                ? "On Hold"
                : item[i].status == 5
                  ? "Pending"
                  : "-",
          Impact: item[i].impact == 0
            ? "Low"
            : item[i].impact == 1
              ? "Medium"
              : item[i].impact == 2
                ? "High"
                : "-",
          Priority: item[i].priority == 0
            ? "Low"
            : item[i].priority == 1
              ? "Medium"
              : item[i].priority == 2
                ? "High"
                : "-",
          Responsible: item[i].responsible
            ? item[i].responsible
            : "-",
        });
      }
    }
    return exportsection;
  }

  function getResponseData(id, clickColumn) {
    APIManager.getAPI(
      getSeoAuditResponse + id + "&projectAuditId=" + projectAuditId
    ).then((response) => {
      if (response && response.status == 200) {
        var e = document.getElementById("audit-1");
        e.scrollIntoView({
          block: "end",
          behavior: "smooth",
          inline: "center",
        });

        let list = [];
        let i = 0;
        const exportListModule = [];
        response?.data.map((item) => {
          exportListModule.push({
            module: item.auditModuleName,
            title: item.pointTitle,
            impact: item.impact,
            priority: item.priority,
          });

          let obj = {
            key: (i += 1),
            module: item.auditModuleName,
            title: (
              <div>
                <div
                  className="table-text-hover"
                  onClick={() => {
                    history.push({
                      pathname: "/main/modules",
                      state: {
                        projectAuditId: projectAuditId,
                        pointTitle: item.pointTitle,
                      },
                    });
                  }}
                >
                  {item.pointTitle}
                </div>
              </div>
            ),
            impact: setPriority(item.impact),
            priority: setPriority(item.priority),
          };
          if (clickColumn == "nonCompliants") {
            list.push(obj);
          } else if (clickColumn == "highImpact") {
            if (item.impact == 2) {
              list.push(obj);
            }
          } else if (clickColumn == "mediumImpact") {
            if (item.impact == 1) {
              list.push(obj);
            }
          } else if (clickColumn == "lowImpact") {
            if (item.impact == 0) {
              list.push(obj);
            }
          } else if (clickColumn == "p0") {
            if (item.priority == 2) {
              list.push(obj);
            }
          } else if (clickColumn == "p1") {
            if (item.priority == 1) {
              list.push(obj);
            }
          } else if (clickColumn == "p2") {
            if (item.priority == 0) {
              list.push(obj);
            }
          }
        });
        setlistData(list);
        setModulesExportList(exportListModule);
      }
    });
  }

  function getSeoAuditByScoreChart(projectAuditId) {
    setProjectAuditIdd(projectAuditId);
    APIManager.getAPI(
      getSeoAuditByScoreChartId +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      projectAuditId
    ).then((response) => {
      if (
        response &&
        response.status == 200 &&
        response.data != "No Content Found"
      ) {
        setAuditScore(response.data[0]?.auditScore);
        setProjectAuditName(response.data[0]?.projectAuditName);
        setProjectId(response?.data[0]?.projectId);
        setPercentage(response.data[0]?.auditScore);
        setStatusMSG(response.data[0]?.auditScore);
      }
    });
  }

  let is1stTime = true;
  function getSeoAuditByProjectAuditIdList(seoAuditDateId, aditDateListt) {
    let selectedTotalPoints = 0;
    aditDateList.map((i) => {
      if (i.seoAuditId == seoAuditDateId) {
        selectedTotalPoints = i.totalPoints;
      }
    });

    APIManager.getAPI(
      getSeoAuditByProjectAuditId +
      "projectId=" +
      localStorage.getItem("projectGId") +
      "&projectAuditId=" +
      seoAuditDateId +
      "&view="
    ).then((response) => {
      if (response && response.status == 200) {
        setAuditDate(response.data?.lastAuditedDate);
        setProgress(response?.data?.status);
        setAuditType(response?.data?.auditType);
        const list = [];
        const exportList = [];
        let i = 0;
        let totalCP = 0;
        let totalNCP = 0;
        let totalNApoints = 0;
        let checkPoint = 0;
        response.data?.seoAuditItems.map((item) => {
          totalCP = totalCP + item.compliantPoints;
          totalNCP = totalNCP + item.nonCompliantPoints;
          totalNApoints = totalNApoints + item.naPoints;

          let itemClicable;
          if (item.nonCompliantPoints == 0) {
            itemClicable = item.nonCompliantPoints;
          } else {
            itemClicable = (
              <div
                className="table-text-hover"
                onClick={() => {
                  setShowInfoData(true);
                  getResponseData(item.auditModuleId);
                }}
              >
                {item.nonCompliantPoints}
              </div>
            );
          }

          checkPoint =
            checkPoint + item.checkedPoints;

          list.push({
            key: (i += 1),
            module: item.module,
            pointsChecked: item.checkedPoints,
            pointsPending: item.pendingPoints,
            compliantPoints: item.compliantPoints,
            nonCompliants:
              item.nonCompliantPoints == 0 ? (
                item.nonCompliantPoints
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "nonCompliants");
                  }}
                >
                  {item.nonCompliantPoints}
                </div>
              ),
            highImpact:
              item.highImpact == 0 ? (
                item.highImpact
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "highImpact");
                  }}
                >
                  {item.highImpact}
                </div>
              ),
            mediumImpact:
              item.mediumImpact == 0 ? (
                item.mediumImpact
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "mediumImpact");
                  }}
                >
                  {item.mediumImpact}
                </div>
              ),
            lowImpact:
              item.lowImpact == 0 ? (
                item.lowImpact
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "lowImpact");
                  }}
                >
                  {item.lowImpact}
                </div>
              ),
            nonCompliants1: item.nonCompliantPoints,
            highImpact1: item.highImpact,
            mediumImpact1: item.mediumImpact,
            lowImpact1: item.lowImpact,
            p0:
              item.highPriority == 0 ? (
                item.highPriority
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "p0");
                  }}
                >
                  {item.highPriority}
                </div>
              ),
            p1:
              item.mediumPriority == 0 ? (
                item.mediumPriority
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "p1");
                  }}
                >
                  {item.mediumPriority}
                </div>
              ),
            p2:
              item.lowPriority == 0 ? (
                item.lowPriority
              ) : (
                <div
                  className="table-text-hover"
                  onClick={() => {
                    setShowInfoData(true);
                    getResponseData(item.auditModuleId, "p2");
                  }}
                >
                  {item.lowPriority}
                </div>
              ),
            p01: item.highPriority,
            p02: item.mediumPriority,
            p03: item.lowPriority,
          });

          let listItem2 = {
            module: item.module,
            pointsChecked: item.checkedPoints,
            pointsPending: item.pendingPoints,
            compliantPoints: item.compliantPoints,
            nonCompliants: item.nonCompliantPoints,
            highImpact: item.highImpact,
            mediumImpact: item.mediumImpact,
            lowImpact: item.lowImpact,
            p0: item.highPriority,
            p1: item.mediumPriority,
            p2: item.lowPriority,
          };
          exportList.push(listItem2);
          i++;
        });
        setNaPoints(totalNApoints);
        setSEOAuditData(list);
        setExportList(exportList);
        setCp(totalCP);
        setNcp(totalNCP);
        setTotalCheckedPoints(checkPoint);
        if (is1stTime) {
          selectedTotalPoints = aditDateListt[0].totalPoints;
        }
        let noPointsData = parseInt(
          (totalCP * 100) / (selectedTotalPoints - naPoints)
        );

        setPercentage(noPointsData);
        setStatusMSG(noPointsData);

        getAuditChartData(2, projectAuditId, totalNCP);

        if (localStorage.getItem(is1stTime)) {
          localStorage.setItem("is1stTime", false);
          setAuditId(response.data?.audits[0]?.seoAuditId);
        }
      }
    });
  }

  let projectAuditId = "";

  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };

    return (
      <div>
        <p className="text"
          style={
            isReadMore
              ? { height: 14.9 + "rem", overflow: "hidden", background: "#fff" }
              : { overflow: "hidden", background: "#fff" }}
        >
          {/* {isReadMore ? (
          <div
            dangerouslySetInnerHTML={{
              __html: text.props.dangerouslySetInnerHTML.__html.slice(0, 1200),
            }}
          />
        ) : ( */}
          {text}
          {/* )} */}
        </p>
        {text.length > 0 ?
          <span onClick={toggleReadMore} className="read-or-hide">
            <div
              className="w-100 d-flex justify-content-center text-primary btn-see-more"
              style={{ cursor: "pointer" }}
            >
              {isReadMore ? "See More" : "See Less"}
            </div>
          </span> : <></>}
      </div>
    );
  };

  const [labelList, setLabelList] = useState([])
  const [labelsList, setLabelsList] = useState([])
  const [valueList, setValueList] = useState([])
  return (
    <div id="divToExport">
      <div className="common-mb-24">
        {aditDateList.length == 0 ? (
          <></>
        ) : (
          <>
            <div className="d-flex align-items-center justify-content-between seo-dash-header mb-4">
              <div className="portlet width-fill-available mb-0">
                <div className="row seo-header-box p-4">
                  <div className="col-md-4  border-right ps-0">
                    <div className="d-flex justify-content-start align-items-center">
                      <div className="left-box w-100">
                        <div className="d-flex align-items-center">
                          <span className="d-block">Select Audit :</span>
                          <select
                            className="fw-600 form-select"
                            required
                            id="optAudit"
                            onChange={(e) => {
                              setAccordianData({});
                              projectAuditId = e.target.value;
                              localStorage.setItem("projectAuditId", projectAuditId);
                              setAuditId(e.target.value);
                              getSeoAuditByProjectAuditIdList(
                                e.target.value,
                                aditDateList
                              );
                              getAuditChartData(2, projectAuditId);
                              setTimeout(() => {
                                getSeoAuditByScoreChart(
                                  e.target.value,
                                  aditDateList
                                );
                              }, 2000);
                              let ghostSelect = document.createElement('select');
                              const select = document.getElementById('optAudit');
                              const x = select.options[select.selectedIndex].text;
                              const ghostOption = document.createElement("option");
                              ghostOption.setAttribute("value", x);
                              const t = document.createTextNode(x);
                              ghostOption.appendChild(t);
                              ghostSelect.appendChild(ghostOption);
                              window.document.body.appendChild(ghostSelect)
                              select.style.width = ghostSelect.offsetWidth + 'px';
                              window.document.body.removeChild(ghostSelect)
                              getCategoriesScoreDetails(e.target.value);
                              setShowInfoData(false);
                            }}
                            value={selectedAuditId}
                          >
                            {aditDateList.map((item) => {
                              return (
                                <option value={item.seoAuditId}>
                                  {item.seoAuditName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                        <div className="d-flex align-items-center mt-2">
                          <span className="ms-5 ps-4 me-3 seo-type"> Type :</span>
                          <span className="fw-700">
                            {auditType == 0
                              ? "Full Audit"
                              : auditType == 1
                                ? "Migration Audit"
                                : auditType == 2
                                  ? "Technical Audit"
                                  : "Others"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 border-right d-flex justify-content-center align-items-center">
                    <div className="d-block">
                      <div className="center-box">
                        <div className="d-flex justify-content-start">
                          <span className="me-3"> Total Points Checked : </span>
                          <span className="fw-700"> {totalCheckedPoints}</span>
                        </div>
                        <ProgressBar className="d-flex seo-progress mt-2">
                          <ProgressBar
                            className="seo-green"
                            // data-toggle="tooltip"
                            // title={"Compliant - " + cp}
                            now={`${cp * 100}`}
                            key={1}
                          />
                          <ProgressBar
                            className="seo-orange"
                            // data-toggle="tooltip"
                            // title={"Non Compliant - " + ncp}
                            now={`${ncp * 100}`}
                            key={2}
                          />

                          <div className="hover-box-flex">
                            <div className="hover-progress green-bx">
                              <div className="d-flex">
                                <div className="dot">.</div>
                                <span>Compliant</span>
                                <span className="key ms-2">- {cp}</span>
                              </div>
                            </div>

                            <div className="hover-progress orange-bx">
                              <div className="d-flex">
                                <div className="dot">.</div>
                                <span>Non Compliant</span>
                                <span className="key ms-2">- {ncp}</span>
                              </div>
                            </div>
                          </div>
                        </ProgressBar>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 d-flex align-items-center justify-content-center">
                    <div className="d-flex justify-content-center align-items-center">
                      <div className="right-box">
                        <div className="d-flex align-items-center">
                          <span className="me-2">Audit Status : </span>
                          <div className="d-flex align-items-center">
                            <span className="fw-700"> {progress} </span>
                            {progress == "Completed" ? (
                              <img src="/images/green-star.png" alt="#" />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="me-2 seo-date-label"> Last Audited On : </span>
                          <span className="fw-700">
                            {mmDDyyyyFormateForForm22(auditDate)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="hover-progress green">
                          <div className="d-flex">
                            <span>Non Compliant</span>
                            <span className="key ms-3">120</span>
                          </div>
                        </div> */}
              {/* <div className="hover-progress orange">
                          <div className="d-flex">
                            <span>Non Compliant</span>
                            <span className="key ms-3">72</span>
                          </div>
                        </div> */}
              {getCheckPermission("detailed_audit_edit", "projects_setup") ? (
                <>
                  <div
                    className="btn btn-primary ms-2 btn-box"
                    onClick={() => {
                      if (
                        getCheckPermission(
                          "detailed_audit_edit",
                          "projects_setup"
                        )
                      ) {
                        history.push({
                          state: {
                            moduleSettings: "seoAuditPopup",
                          },
                        });
                      }
                    }}
                  >
                    <NavLink to="/main/configuration">
                      <div className="d-flex align-items-center flex-box">
                        <span className="text-white">SEO Audit</span>
                        <span className="i-tag ms-3">
                          <i
                            class="fa fa-angle-right text-white"
                            aria-hidden="true"
                          ></i>
                        </span>
                      </div>
                    </NavLink>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )}
      </div>
      {aditDateList.length == 0 ? (
        <>
          <div class=" message text-center ">
            <h4>
              The Audit is yet in progress. <br />
              The status will be visible here once the Audit is completed.
            </h4>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex responsive-none">
            <div className="col-md-4 px-0 Audit-chart">
              <div className="text-center progress-circle">
                <div className="portlet mb-0" style={{ height: 415 + "px" }}>
                  <div className="portlet-header portlet-header-bordered text-start h-auto mx-3">
                    <h3 className="portlet-title ps-0">Audit Score</h3>
                  </div>
                  <div className="portlet-body text-center position-relative">
                    <div className="round-chart d-flex align-items-center h-100">
                      <div className="chart-center w-100">
                        <div className="chart-relative position-relative">
                          <Doughnut
                            data={data}
                            options={{
                              cutoutPercentage: 70,
                              responsive: true,
                              maintainAspectRatio: true,
                              legend: {
                                display: false,
                                position: "bottom",
                                labels: {
                                  usePointStyle: true,
                                  pointStyle: "circle",
                                },
                              },
                            }}
                          // plugins={ chartCenterTextPlugins(percentage)} 
                          />

                          <div className="percentage-textt">
                            <div className="text-name">{`${(percentage, Math.round(percentage))
                              }`}</div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="dashboard-markerr">
                      <div className="d-flex align-items-center  justify-content-center">
                        <div className="excellent-dot me-2 mt-0 mb-0"></div>
                        <label className="me-3 mb-0">Excellent</label>
                        <div className="good-dot me-2"></div>
                        <label className="me-3 mb-0">Good</label>
                        <div className="needwork-dot me-2"></div>
                        <label className="mb-0">Needs Work</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="ms-3 w-100 pe-0 implementation-box">
              <div className=" ps-3 pe-5  mt-md-0 progress-text"></div>
              <div className="portlet mb-0">
                <div className="portlet-header portlet-header-bordered h-auto py-2 mx-3">
                  <h3 className="portlet-title ps-0">Implementation Status</h3>
                  <div className="select-box">
                    <select
                      className="form-select"
                      value={chartdropdown}
                      onChange={(e) => {
                        setChartDropdown(e.target.value);
                        setChartLabelsID(e.target.value);
                        getAuditChartData(
                          e.target.value,
                          localStorage.getItem("projectAuditId"), 0
                        );
                      }}
                    >
                      <option value="2">Monthly</option>
                      <option value="1">Half Yearly</option>
                      <option value="0">Quarterly</option>
                    </select>
                  </div>
                </div>
                <div>
                  {(changeRequestList.length || wipList.length || onHoldList.length || pendingList.length || implementedList.length || implementationList.length) ? <Chart
                    options={options}
                    series={[
                      {
                        name: "Changes Requested",
                        type: "column",
                        color: "#37b3f9",
                        data: changeRequestList,
                      },
                      {
                        name: "WIP",
                        type: "column",
                        color: "#ffa624",
                        data: wipList,
                      },
                      {
                        name: "On Hold",
                        type: "column",
                        color: "#ff4248",
                        data: onHoldList,
                      },
                      {
                        name: "Pending",
                        type: "column",
                        color: "#ffd957",
                        data: pendingList,
                      },
                      {
                        name: "Implemented",
                        type: "column",
                        color: "#74e68c",
                        data: implementedList,
                      },
                      {
                        name: "Implementation %",
                        type: "line",
                        color: "#775dd0",
                        data: implementationList,
                      },
                    ]}
                    type="line"
                    width="100%"
                    height="350"
                  /> : <>
                    <div className="no-record-auditImp d-flex justify-content-center align-items-center ">NO RECORD FOUND</div>
                  </>}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {categoriesScore.item &&
              categoriesScore.item.categoryScore.length ? (
              <div className="col-sm-12 d-flex justify-content-between align-items-center my-4 py-2 pe-0">
                <div className="seo-title mt-0 d-flex align-items-center">
                  Sorted By :<span className="fw-600 ms-2">SEO Categories</span>
                </div>

                <div className="seo-export-btn" onClick={() => {
                  let selectedAudit = aditDateList.find(element => element.seoAuditId == selectedAuditId);
                  history.push({
                    pathname: "/main/module-expand-seo-audit-view/",
                    state: {
                      selectedAuditId: selectedAuditId,
                      selectedAuditName: selectedAudit?.seoAuditName
                    },
                  });
                }}>
                  <div className="btn btn-primary">View Report</div>
                </div>
              </div>) : (<></>)}
            {categoriesScore.item &&
              categoriesScore.item.categoryScore.length &&
              categoriesScore.item.categoryScore.map((item, index) => {
                return (
                  <div className="col-md-4 col-sm-12 ps-0">
                    <div
                      className={`${(!categoryAlreaddSelecteSelected && categorySelected == item.categoryId) ? "seo-category-box active" : "seo-category-box"}`}
                      onClick={() => {
                        onPageDetails(item.categoryId);
                      }}>
                      <div className="left">
                        <div className="title">{item.category}</div>
                        <a className="seo-category-imgbox">
                          {/* <img
                            class="deafult-img"
                            src="/images/seo-ste.png"
                            alt="#"
                          /> */}
                          <Line
                            options={{
                              responsive: true,
                              plugins: {
                              },
                              legend: { display: false },
                              scales: {
                                xAxes: [{
                                  display: false,
                                  gridLines: {
                                    display: false
                                  }
                                }],
                                yAxes: [{
                                  display: false,
                                  gridLines: {
                                    display: false
                                  }
                                }]
                              },
                            }}
                            data={{
                              labels: labelsList[index],
                              datasets: [
                                {
                                  label: item.category,
                                  data: valueList[index],
                                  borderColor: "rgb(53, 162, 235)",
                                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                                },
                              ],
                            }}
                            height="50px"
                            width={"150px"}
                          />

                          <span class="hover-img">
                            <div className="d-block text-center">
                              <span>Progress Chart</span>
                            </div>

                            {/* <Line
                            options={options}
                            data={{
                              labelsHover,
                              datasets: [
                                {
                                  fill: true,
                                  label: item.category,
                                  data: [
                                    0,
                                    categoriesScore.item
                                      .categoryMonthWiseResponses[index]
                                      .auditScoreMonthResponses[0].auditScore,
                                  ],
                                  borderColor: "rgb(53, 162, 235)",
                                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                                },
                              ],
                            }}
                          /> */}
                            <Line options={{
                              responsive: true,
                              plugins: {
                                // legend: {
                                //   position: 'top',
                                // },
                                // title: {
                                //   display: true,
                                //   text: 'Chart.js Line Chart',
                                // },
                              },
                              legend: { display: false },
                              scales: {
                                xAxes: [{
                                  gridLines: {
                                    display: false
                                  }
                                }],
                                yAxes: [{
                                  gridLines: {
                                    display: false
                                  },
                                  ticks: {
                                    min: 0,
                                    max: 100,
                                    stepSize: 20
                                  }
                                }]
                              },
                            }}
                              data={{
                                labels: labelsList[index],
                                datasets: [
                                  {
                                    label: item.category,
                                    data: valueList[index],//labels.map(() => faker.datatype.number({ min: 0, max: 100 })),
                                    borderColor: "rgb(53, 162, 235)",
                                    backgroundColor: "rgba(53, 162, 235, 0.5)",
                                  },
                                ],
                              }} />
                          </span>
                        </a>
                      </div>
                      <div className="right">
                        <span class="digit d-block">
                          {Math.round(item.auditScore)}
                        </span>
                        <span className="title">Your Score</span>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>

          <PDFExport
            paperSize="A1"
            margin="2cm"
            fileName="download.pdf"
            ref={pdfExportComponent}
            forcePageBreak=".page-break"
          >
            <div className="row" ref={image}>
              <div className="col-sm-12 px-0">
                <div className="seo-accordian-box mt-4 pt-2">
                  {accordianData.item &&
                    accordianData.item.moduleScoreData.length ? (
                    <div className="accordian-title">
                      <div className="left">
                        <span>Section</span>
                      </div>
                      <div className="right d-flex align-items-center">
                        <span>Your Score</span>
                        <img src="/images/arrow-down.png" alt="#" />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}

                  <Accordion className="mt-2">
                    {accordianData.item &&
                      accordianData.item.moduleScoreData.length &&
                      accordianData.item.moduleScoreData.map((item, index) => {
                        return (
                          <Accordion.Item eventKey={index}>
                            {/* {item.auditScore > 0 ? ( */}
                            <Accordion.Header>
                              <div className="w-100 d-flex justify-content-between align-items-center">
                                <div class="acco-title">
                                  {item.auditModuleName}
                                </div>
                                <div class="acco-digit">
                                  {Math.round(item.auditScore)}
                                </div>
                              </div>
                            </Accordion.Header>
                            {/* ) : (
                              <></>
                            )} */}
                            <Accordion.Body className="accordian-content">
                              <div className="main-accordian-box mt-4">
                                <div className="row">
                                  <div className="col-md-8 col-sm-12">
                                    <div className="row">
                                      {item.compliantPoints > 0 ||
                                        item.nonCompliantPoints > 0 ? (
                                        <div
                                          // style={{ width: 200 + "px" }}
                                          className="chart-box col-md-3"
                                        >
                                          <div className="custom-chart-size" style={{
                                            position: 'relative', marginLeft: "auto", marginRight: "auto"
                                          }}>

                                            <DoughnutChart
                                              labels={[
                                                "Compliant",
                                                "Non compliant",
                                              ]}
                                              data={[
                                                item.nonCompliantPoints,
                                                item.compliantPoints,
                                              ]} />
                                            <div style={{ position: 'absolute', width: '100%', top: '38%', left: 0, textAlign: 'center', }}>
                                              <span><b>{item.nonCompliantPoints + item.compliantPoints}</b></span>
                                            </div>
                                          </div>
                                          <div className="chart-title">
                                            <div className="text-center">
                                              <div className="d-flexx">
                                                Points Checked
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      {item.highImpact > 0 ||
                                        item.mediumImpact > 0 ||
                                        item.lowImpact > 0 ? (

                                        <PieChart
                                          title={"Impact"}
                                          labels={["High", "Medium", "Low"]}
                                          data={[
                                            item.highImpact,
                                            item.mediumImpact,
                                            item.lowImpact,
                                          ]}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      {item.highPriority > 0 ||
                                        item.mediumPriority > 0 ||
                                        item.lowPriority > 0 ? (
                                        <PieChart
                                          title={"Priority"}
                                          labels={["High", "Medium", "Low"]}
                                          data={[
                                            item.highPriority,
                                            item.mediumPriority,
                                            item.lowPriority,
                                          ]}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                      {item.onHold > 0 ||
                                        item.inprogress > 0 ||
                                        item.implemented > 0 ? (
                                        <PieChart
                                          title={"Status"}
                                          labels={["Implemented", "In process", "On hold"]}
                                          data={[
                                            item.implemented,
                                            item.inprogress,
                                            item.onHold,
                                          ]}
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  </div>
                                  <div class="col-md-4 col-sm-12 d-flex justify-content-end align-items-center">
                                    <div class="accord-content-text me-5">
                                      <span className="digit-span">
                                        {Math.round(item.auditScore)}
                                      </span>
                                      <span class="text-span">Your Score</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-sm-12 para-box mt-5 pe-0">
                                  <div className="title">Introduction : </div>
                                  {/* {item.description.length > 1200 ? */}
                                  <ReadMore>
                                    <div
                                      id="text-overflow"
                                      className="bg-transparent"
                                      dangerouslySetInnerHTML={{
                                        __html: item.description,
                                      }}
                                    ></div>
                                  </ReadMore>
                                  {/* // : <> <div
                                    //   id="text-overflow"
                                    //   className="bg-transparent"
                                    //   dangerouslySetInnerHTML={{
                                    //     __html: item.description,
                                    //   }}
                                    // ></div></>} */}
                                  <div className="col-sm-12 pe-0">
                                    <div className="d-flex justify-content-end align-items-center accordian-content pb-3 pe-0">
                                      <div
                                        className="btn btn-light mr-2"
                                        onClick={() => {
                                          exportToEXCEL(
                                            getAuditModulesExport(item.auditModuleName, accordianData.item.auditResponseByCategories),
                                            item.auditModuleName
                                          );
                                        }}

                                      >
                                        Export
                                      </div>
                                      {/* {isExpanded ? (<div
                                        className="btn btn-primary isExpanded-btn"
                                        onClick={() => {
                                          closeAll(false);
                                          showAllAccordionTabs(item.auditModuleName, index);
                                        }}
                                      >
                                        Expand All
                                      </div>) : (<div
                                        className="btn btn-primary isExpanded-btn"
                                        onClick={() => {
                                          closeAll(true);
                                          hideAllAccordionTabs(item.auditModuleName, index);
                                        }}
                                      >
                                        Collapse All
                                      </div>)} */}
                                      <div
                                        className="btn btn-primary isExpanded-btn"
                                        onClick={() => {
                                          if (isExpanded) {
                                            closeAll(false);
                                            showAllAccordionTabs(item.auditModuleName, index);
                                          } else {
                                            closeAll(true);
                                            hideAllAccordionTabs(item.auditModuleName, index);
                                          }
                                        }}
                                      >
                                        Expand All
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/**************** Inner Accordian ***********************************/}

                              <div className="seo-accordian-box">
                                <div className="accordian-title w-100 d-flex justify-content-between">
                                  <div className="left w-max">
                                    <span>Audit Point</span>
                                  </div>
                                  <div className="left w-50">
                                    <span>Status</span>
                                  </div>
                                  <div className="left w-50">
                                    <span>Impact</span>
                                  </div>
                                  <div className="left w-50">
                                    <span>Priority</span>
                                  </div>
                                  <div className="left w-50">
                                    <span>Responsible</span>
                                  </div>
                                </div>
                              </div>

                              <Accordion className={isExpanded ? "mt-2 inside-accordian" : "mt-2 inside-accordian expand-active"}>
                                {accordianData.item &&
                                  accordianData.item.auditResponseByCategories.length
                                  &&
                                  getAuditModules(item.auditModuleName).map(
                                    (innerItem, index) => {
                                      return (
                                        <>
                                          {innerItem.auditResponseValue == 0 ? (
                                            <Accordion.Item
                                              eventKey={index}
                                            // className={isExpanded ?"expand-col-off":"expand-col-active"}
                                            >
                                              <Accordion.Header
                                                // className={isExpanded ?"":"expand-collapsed"}
                                                id={innerItem.auditModuleName}
                                                onClick={(e) => {
                                                  // let tempArr = accordianData.item.auditResponseByCategories.map((i) => {
                                                  //   if (i.pointTitle == innerItem.pointTitle) {                                                  
                                                  //     i.isActive = !i.isActive                                                  
                                                  //   }
                                                  // })

                                                  const getCollapsedAccordionHeaders = document.getElementsByClassName("accordion-header");
                                                  for (var i = 0; i < getCollapsedAccordionHeaders.length; i++) {
                                                    if (getCollapsedAccordionHeaders[i].getAttribute("id") == innerItem.auditModuleName) {
                                                      // getCollapsedAccordionHeaders[i].onclick = function (event) {
                                                      getCollapsedAccordionHeaders[i].addEventListener('click', function (event) {
                                                        event.preventDefault();
                                                        event.stopPropagation();

                                                        const expandBtn = document.querySelectorAll(".isExpanded-btn");
                                                        for (var j = 0; j < expandBtn.length; j++) {
                                                          expandBtn[j].innerHTML = "Expand All";
                                                        }

                                                        if (this.getElementsByClassName("accordion-button")[0].classList.contains('collapsed')) {
                                                          setIsExpanded(false);
                                                          this.getElementsByClassName("accordion-button")[0].classList.remove("collapsed");
                                                          this.getElementsByClassName("accordion-button")[0].attributes['aria-expanded'].value = "true";

                                                          const getCollapsedAccordionSections = this.parentNode.getElementsByClassName("accordian-identity");
                                                          for (var j = 0; j < getCollapsedAccordionSections.length; j++) {
                                                            getCollapsedAccordionSections[j].parentNode.classList.remove("collapse");
                                                            getCollapsedAccordionSections[j].parentNode.classList.add("show");
                                                          }
                                                        } else {
                                                          setIsExpanded(true);
                                                          this.getElementsByClassName("accordion-button")[0].classList.add("collapsed");
                                                          this.getElementsByClassName("accordion-button")[0].attributes['aria-expanded'].value = "false";

                                                          const getCollapsedAccordionSections = this.parentNode.getElementsByClassName("accordian-identity");
                                                          for (var j = 0; j < getCollapsedAccordionSections.length; j++) {
                                                            getCollapsedAccordionSections[j].parentNode.classList.add("collapse");
                                                            getCollapsedAccordionSections[j].parentNode.classList.remove("show");
                                                          }
                                                        }
                                                      });
                                                    }
                                                  }
                                                }}
                                              >
                                                <div className="w-100 d-flex justify-content-between">
                                                  <div class="acco-inside-title w-max">
                                                    {innerItem.pointTitle}
                                                  </div>
                                                  <div class="acco-inside-title w-50">
                                                    {innerItem.status == 1
                                                      ? "Completed"
                                                      : innerItem.status == 3
                                                        ? "WIP"
                                                        : innerItem.status == 4
                                                          ? "On Hold"
                                                          : innerItem.status == 5
                                                            ? "Pending"
                                                            : "-"}
                                                  </div>
                                                  <div class="acco-inside-title w-50 ps-3">
                                                    {innerItem.impact == 0
                                                      ? "Low"
                                                      : innerItem.impact == 1
                                                        ? "Medium"
                                                        : innerItem.impact == 2
                                                          ? "High"
                                                          : "-"}
                                                  </div>

                                                  <div class="acco-inside-title w-50 ps-4">
                                                    {innerItem.priority == 0
                                                      ? "Low"
                                                      : innerItem.priority == 1
                                                        ? "Medium"
                                                        : innerItem.priority == 2
                                                          ? "High"
                                                          : "-"}
                                                  </div>
                                                  <div class="acco-inside-title w-50 ps-4">
                                                    {innerItem.responsible
                                                      ? innerItem.responsible
                                                      : "-"}
                                                  </div>
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body
                                                className="accordion-body border border-top-0 rounded accordian-identity"
                                              // "accordion-collapse collapse show"
                                              >
                                                <div>
                                                  {innerItem.comment ? (
                                                    <div className="row para-box">
                                                      <div className="col-md-10 col-sm-12">
                                                        <div className="title">
                                                          <ul>
                                                            <li> Observation :{" "}</li>
                                                          </ul>
                                                        </div>
                                                        <div
                                                          className="bg-transparent ms-4 ps-3"
                                                          dangerouslySetInnerHTML={{
                                                            __html:
                                                              innerItem.comment,
                                                          }}
                                                        ></div>
                                                      </div>
                                                      <div className="col-md-2 text-end">
                                                        <div className="mt-4">
                                                          {innerItem.ticketId ? (<a
                                                            onClick={() => {
                                                              // history.push({
                                                              //   pathname: "/ticket/ticket-view",
                                                              //   state: {
                                                              //     item: { id: innerItem.ticketId },
                                                              //   },
                                                              // });
                                                              localStorage.setItem("state", JSON.stringify({ item: { id: innerItem.ticketId } }));
                                                            }}
                                                            className="btn-blue-outline text-primary"
                                                          >
                                                            <Link
                                                              target="_blank"
                                                              to="/ticket/ticket-view"
                                                            >
                                                              View Ticket
                                                            </Link>
                                                          </a>) : <></>}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {innerItem.suggestion ? (
                                                    <div className="col-md-10 col-sm-12 para-box">
                                                      <div className="title">
                                                        <ul>
                                                          <li> Recommendation :{" "}</li>
                                                        </ul>
                                                      </div>
                                                      <div
                                                        className="bg-transparent ms-4 ps-3"
                                                        dangerouslySetInnerHTML={{
                                                          __html:
                                                            innerItem.suggestion,
                                                        }}
                                                      ></div>
                                                    </div>
                                                  ) : (
                                                    <></>
                                                  )}

                                                  <div className="row para-box">
                                                    {innerItem.supportingDocs.length ? (<div className="col-md-7 pdf-box mt-4">
                                                      <div className="pdf-title">
                                                        {innerItem.supportingDocs.length} Attachments
                                                      </div>
                                                      <div className="d-flex justify-content-between">
                                                        {innerItem.supportingDocs.length && innerItem.supportingDocs.map(item => {
                                                          return (<div className="flex-pdf p-3 d-flex align-items-center mr-3">
                                                            {item.documents ?
                                                              <a href={GET_Server_URL + item.documents} target="_blank" download="" className=" d-flex align-items-center" >
                                                                <div className="icon" >
                                                                  {item.documents.includes(".jpg") ||
                                                                    item.documents.includes(".png") ||
                                                                    item.documents.includes(".jpeg") ? (
                                                                    <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width="24"
                                                                      height="24"
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      stroke="currentColor"
                                                                      stroke-width="2"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                      class="feather feather-image"
                                                                    >
                                                                      <rect
                                                                        x="3"
                                                                        y="3"
                                                                        width="18"
                                                                        height="18"
                                                                        rx="2"
                                                                        ry="2"
                                                                      ></rect>
                                                                      <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                                                      <polyline points="21 15 16 10 5 21"></polyline>
                                                                    </svg>
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                  {item.documents.includes(".pdf") ? (
                                                                    <img src="/images/pdf-img.png" alt="#" />
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                  {item.documents.includes(".txt") ? (
                                                                    <svg
                                                                      xmlns="http://www.w3.org/2000/svg"
                                                                      width="24"
                                                                      height="24"
                                                                      viewBox="0 0 24 24"
                                                                      fill="none"
                                                                      stroke="#212121"
                                                                      stroke-width="2"
                                                                      stroke-linecap="round"
                                                                      stroke-linejoin="round"
                                                                      class="feather feather-file-text"
                                                                    >
                                                                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                                                      <polyline points="14 2 14 8 20 8"></polyline>
                                                                      <line x1="16" y1="13" x2="8" y2="13"></line>
                                                                      <line x1="16" y1="17" x2="8" y2="17"></line>
                                                                      <polyline points="10 9 9 9 8 9"></polyline>
                                                                    </svg>
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                  {item.documents.includes(".xlsx") ? (
                                                                    <i
                                                                      class="fa fa-file-excel"
                                                                      aria-hidden="true"
                                                                    ></i>
                                                                  ) : (
                                                                    <></>
                                                                  )}
                                                                </div>
                                                                <div className="text ms-3">
                                                                  <h5>
                                                                    {item.documents.substr(27)}
                                                                  </h5>
                                                                  {/* <p className="mb-0">
                                                            2.4 MB
                                                          </p> */}
                                                                </div>
                                                              </a> :
                                                              <></>}
                                                            {/* <div className="icon">
                                                          <img
                                                            src="/images/pdf-img.png"
                                                            alt="#"
                                                          />
                                                        </div>
                                                        <div className="text ms-3">
                                                          <h5>
                                                            <a href={item.documents} download />
                                                          </h5>
                                                          <p className="mb-0">
                                                            2.4 MB
                                                          </p>
                                                        </div> */}
                                                          </div>)
                                                        })}
                                                      </div>
                                                    </div>) : <></>}
                                                  </div>
                                                </div>
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          ) : (
                                            <></>
                                          )}
                                        </>
                                      );
                                    }
                                  )}
                              </Accordion>
                            </Accordion.Body>
                          </Accordion.Item>
                        );
                      })}
                  </Accordion>
                </div>
              </div>
            </div>
          </PDFExport>
        </>
      )}
    </div>
  );
}

export default ModuleExpandSEOAudit;