import { useEffect, useState, useRef } from "react";
import JoditEditor from "jodit-pro-react";
import {
  GET_FrontEnd_URL,
  GET_Server_URL,
  addcomment,
  addsuggestion,
  getcomment,
  getdescription,
  getsuggesstion,
  getVersionHistory,
  updatecommentstatus,
  updatesuggesstionstatus,
  adddescription,
  articleStatus,
  updateRequirementStatus,
  commentreply,
  suggestionreply,
  requirementById,
  getProjectById,
  ArticleStatus,
  updateEditorETA
} from "../../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
  formateDateIntoDatemonthTime,
} from "../../utility/Globals";
import { useHistory } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { useAsync } from "react-select/async";
import APIManager from "../../services/APIManager";
import { Space } from "antd";
import { toast } from "react-hot-toast";
import { TabPanel } from "react-tabs";
import DatePicker, { DateObject } from "react-multi-date-picker";

function EditorContent(props) {
  const [Editor, setEditor] = useState(true);
  const [complete, setComplete] = useState(false);
  const [instruction1, setInstruction] = useState("");
  const [buttonclick, setbuttonclick] = useState(0);
  const [versionhistory, setversionhistory] = useState(0);
  const [projectname, setProjectName] = useState();
  const id = props.history.location.state.keywordid;
  const requirementId = props.history.location?.state?.requirementid;
  const history = useHistory();
  const notifyAdd = () => toast.success("Successfully Added!");
  const [suggestionlist, setsuggestionlist] = useState([]);
  const [commentlist, setcommentlist] = useState([]);
  const [versionHistoryList, setVersionHistoryList] = useState([]);
  const [commentupdatestatus, setcommentupdatestatus] = useState(false);
  const [suggestionupdatestatus, setsuggestionupdatestatus] = useState(false);
  const [isMultiSelect, setisMultiSelect] = useState(false);
  const [comment, setComment] = useState("");
  const [suggestion, setsuggestion] = useState("");
  const [completedate, setCompletedate] = useState("");
  const [primaryKeyword, setPrimaryKeyword] = useState();
  const format = "DD MMM YYYY";
  const [dates, setDates] = useState(new DateObject());
  const [status, setStatus] = useState();
  //const [commentreply, setcommentreply] = useState(false);
  const [dateChange, setDateChange] = useState(false);
  const [Commentreply, setCommentreply] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const closeBtn = useRef(null);
  const closesugBtn = useRef(null);
  const [addreply, setaddreply] = useState(false);
  const [suggestionraply, setsuggestionraply] = useState(false);
  const [CommentReply, setCommentReply] = useState("");

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",

      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      "fontsize",
      "font",
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "pencil",
      "|",
      "image",
      "link",
      "ol",
      "ul",
      "align",
      "|",
      "indent",
      "outdent",
      "hr",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "paragraph"],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    },
    pasteHTMLActionList: [
      { text: "insert_only_text", value: "insert_only_text" },
    ],
    pasteFromWordActionList: [
      { text: "insert_only_text", value: "insert_only_text" },
    ],
  };

  useEffect(() => {
    // getsuggestion();
    getDescription();
    if (status == 0) {
      requirementStatusUpdate(1);
      setStatus(1);
    } else if (status == 4) {
      requirementStatusUpdate(5);
      setStatus(5);
    }
    getRequirementDetail();
    getArticleStatus();
  }, []);

  function Addcomments() {
    let rawData = {
      RequirementId: requirementId,
      Comment: comment,
      CommentBy: localStorage.getItem("userId"),
    };
    APIManager.postAPI(addcomment, rawData, {}, true).then((response) => {
      if (response && response.status == 200) {
        notifyAdd();
        getcommentlist();
        clearForm();
      }
    });
  }

  function commentsreply(id) {
    let rawData = {
      CommentId: id,
      CommentReply: CommentReply,
    };
    APIManager.postAPI(commentreply, rawData, {}, true).then((response) => {
      if (response && response.status == 200) {
        notifyAdd();
        getcommentlist();
        clearForm();
      }
    });
  }

  function suggestionsreply(id) {
    let rawData = {
      SuggestionId: id,
      SuggestionsReply: suggestion,
    };
    APIManager.postAPI(suggestionreply, rawData, {}, true).then((response) => {
      if (response && response.status == 200) {
        notifyAdd();
        getsuggestion();
        clearForm();
      }
    });
  }
  function Addsuggestion() {
    let rawData = {
      RequirementId: requirementId,
      Suggestion: suggestion,
      SuggestionBy: localStorage.getItem("userId"),
    };
    APIManager.postAPI(addsuggestion, rawData, {}, true).then((response) => {
      if (response && response.status == 200) {
        notifyAdd();
        getsuggestion();
        clearForm();
      }
    });
  }

  function getcommentlist() {
    APIManager.getAPI(getcomment + requirementId).then((response) => {
      if (response && response.status == 200) {
        setcommentlist(response.data);
      }
    });
  }

  function getsuggestion() {
    APIManager.getAPI(getsuggesstion + requirementId).then((response) => {
      if (response && response.status == 200) {
        setsuggestionlist(response.data);
      }
    });
  }

  function getDescription() {
    APIManager.getAPI(getdescription + requirementId).then((response) => {
      if (response && response.status == 200) {
        setInstruction(response.data[0]?.description);
        let desc = response.data[0]?.description
        if(desc)
        {
          let txt = desc.replace(/(<([^>]+)>)/gi, "");
          let newTxt = txt.replace(/\s/g, "");
          setWordCount(newTxt?.length);
        }
      }
    });
  }

  function clearForm() {
    setComment("");
    setsuggestion("");
    setCommentReply("");
  }

  function getVersionHistoryData() {
    APIManager.getAPI(
      getVersionHistory + "&requirementId=" + requirementId
    ).then((response) => {
      if (response.data.length > 0 && response.status == 200) {
        setVersionHistoryList(response.data);
      }
    });
  }

  function commentstatus(id) {
    APIManager.getAPI(
      updatecommentstatus + "?id=" + id + "&requirementId=" + requirementId
    ).then((response) => {
      if (response && response.status == 200) {
        setcommentupdatestatus(true);
        setCompletedate(response.data.createdAt);
      }
    });
  }

  function suggestionstatus(id) {
    APIManager.getAPI(
      updatesuggesstionstatus + "?id=" + id + "&requirementId=" + requirementId
    ).then((response) => {
      if (response && response.status == 200) {
        setCompletedate(response.data.createdAt);
        setsuggestionupdatestatus(true);
        console.log(response.data);
      }
    });
  }

  function AddDescription() {
    APIManager.postAPI(
      adddescription,
      {
        RequirementId: requirementId,
        Description: instruction1,
        CreatedBy: localStorage.getItem("userId"),
        WordCount: wordCount,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getDescription();
        requirementStatusUpdate(3);
      }
    });
  }

  function updateETA(date) {
    APIManager.putAPI(
      updateEditorETA + "B446EF4F-EA4F-4701-BA85-08DB3102EA3B&eta=" + date,
      {},
      null,
      false
    ).then((response) => {
      if (response && response.status === 200) {
        // clearForm();
      }
    });
  }
  const articleStatusUpdate = () => {
    APIManager.putAPI(
      articleStatus,
      { requirementId: requirementId, status: 2 },
      {},
      true
    ).then((res) => {
      console.log(res);
    });
  };

  const requirementStatusUpdate = (status) => {
    APIManager.putAPI(
      updateRequirementStatus +
        "?requirementId=" +
        requirementId +
        "&status=" +
        status
    ).then((res) => {
      if (status != 1 && status != 5) {
        history.push({
          pathname: "/content-main/requirement-details",
          state: { requirementId: requirementId },
        });
      }
    });
  };

  const getRequirementDetail = () => {
    APIManager.getAPI(requirementById + requirementId).then((res) => {
      getProjectByID(res.data.value.projectId);
      setStatus(res.data.value.status);
      setPrimaryKeyword(res.data.value.primaryKeyword);
    });
  };

  const getProjectByID = (id) => {
    APIManager.getAPI(getProjectById + id).then((res) => {
      setProjectName(res.data.value.projectCode);
    })
  }

  const getArticleStatus = () => {
    APIManager.getAPI(ArticleStatus + requirementId).then((res)=> {
      console.log(res);
    })
  }


  return (
    <>
      <div className="row">
        <div className="p-0 cmseditor">
          <div className="portlet-header portlet-header-bordered px-0 pb-0">
            <div className="col-md-12 d-flex align-items-center bg-white p-3">
              <div className="cms-breadcum">
                <span
                  className="me-4"
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <small class="mx-2  fs-6">{projectname}</small>/
                  <small class="mx-2  fs-6">#{id}</small>/
                  <small class="ms-2  fs-6">{primaryKeyword}</small>
                </span>
                {/* <span className="mx-4 fs-6 fw-bold">Myntra</span>/
                                <span className="mx-4 fs-6 fw-bold">#0001 </span>/
                                <span className="mx-4 fs-6 fw-bold">Shoes for Women</span> */}
              </div>
              <Space size="middle" class="ml-minus">
                <a class="px-2 name-icon" aria-hidden="true" type="button">
                  <span>V</span>
                </a>
              </Space>
              <Space size="middle" class="ml-minus">
                <a class="px-2 name-icon" aria-hidden="true" type="button">
                  <span>S</span>
                </a>
              </Space>
              <Space size="middle" class="ml-minus">
                <a class="px-2 name-icon" aria-hidden="true" type="button">
                  <span>B</span>
                </a>
              </Space>
              <div className="mx-4 d-flex">
                <div className="version-history">
                  <a
                    class="fs-5 text-decoration-underline fw-bold"
                    onClick={() => {
                      getVersionHistoryData();
                      setversionhistory(true);
                    }}
                  >
                    Version History
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div className="col-md-8 col-sm-12 editor-border ps-0">
              <div className="left-side bg-white px-0 h-100vh">
                <div class="d-flex justify-content-between align-items-center flex-wrap">
                  <div class="switch-tab switching_tab p-2 mb-3 mt-2 mb-md-0 ms-2">
                    <button
                      className={Editor ? "btn active btn-primary" : "btn mr-2"}
                      onClick={() => {
                        setEditor(true);
                      }}
                    >
                      {Editor ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#ffff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-edit-3"
                        >
                          <path d="M12 20h9"></path>
                          <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                        </svg>
                      ) : (
                        // <svg
                        //   xmlns="http://www.w3.org/2000/svg"
                        //   // width="24"
                        //   // height="24"
                        //   viewBox="0 0 24 24"
                        //   fill="none"
                        //   stroke="currentColor"
                        //   stroke-width="2"
                        //   stroke-linecap="round"
                        //   stroke-linejoin="round"
                        //   className="feather feather-edit edit-icon fs-5 text-white"
                        // >
                        //   {/* <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path> */}
                        //   <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        // </svg>
                        ""
                      )}

                      <span class="ps-2">Editing View</span>
                    </button>
                    <button
                      className={!Editor ? "btn active btn-primary" : "btn"}
                      onClick={() => {
                        setEditor(false);
                      }}
                    >
                      {!Editor ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-users me-2"
                        >
                          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                          <circle cx="9" cy="7" r="4"></circle>
                          <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                        </svg>
                      ) : (
                        ""
                      )}
                      <span className="ms-1">Suggestion View</span>
                    </button>
                  </div>
                  {/* <TabList className="nav nav-tabs portlet-nav">
                                    <Tab
                                        className={
                                            Editor
                                                ? "nav-item nav-link"
                                                : "nav-item nav-link  active"
                                        }
                                        onClick={() => {
                                            setEditor(false);
                                        }}
                                    >
                                        Editing View
                                    </Tab>
                                    <Tab
                                        className={
                                            Editor
                                                ? "nav-item nav-link  active"
                                                : "nav-item nav-link"
                                        }
                                        onClick={() => {
                                            setEditor(true);
                                        }}
                                    >
                                        Suggestion View
                                    </Tab>
                                </TabList> */}

                  <div className="condition_accept mb-3 mb-md-0 me-3 my-2">
                    {status == 3 || status == 7 ? (
                      <>
                        <button
                          class="btn border"
                          onClick={() => {
                            articleStatusUpdate(1);
                            if (status == 3) {
                              requirementStatusUpdate(2);
                              setStatus(2);
                            } else {
                              requirementStatusUpdate(6);
                              setStatus(6);
                            }
                          }}
                        >
                          <span className="request-rework">
                            Request for Rework
                          </span>
                        </button>
                        <button
                          class="btn btn-primary  ms-2"
                          onClick={() => {
                            articleStatusUpdate(4);
                            if (status == 3) {
                              requirementStatusUpdate(4);
                              setStatus(2);
                            } else {
                              requirementStatusUpdate(8);
                              setStatus(8);
                            }
                          }}
                        >
                          <i class="fa fa-check me-2" aria-hidden="true"></i>
                          <span> Accept</span>
                        </button>
                      </>
                    ) : (
                      <button
                        type="button"
                        class="btn btn-primary"
                        id="editorButton"
                        disabled={((status == 1 || status == 5) && instruction1 != '') ? false : true}
                        onClick={() => {
                          setComplete(!complete);
                          articleStatusUpdate(2);
                          AddDescription();
                          if (status < 3) {
                            setStatus(3);
                            requirementStatusUpdate(3);
                          } else {
                            console.log(status);
                            requirementStatusUpdate(7);
                            setStatus(7);
                          }
                        }}
                      >
                        <i class="fa fa-check me-2" aria-hidden="true"></i>
                        <span>Mark as Complete</span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="border-bottom mt-2"></div>
                <div>
                  {Editor ? (
                    <div className="d-flex mt-3">
                      <div>
                        <JoditEditor
                          value={instruction1 || ""}
                          config={config}
                          tabIndex={1}
                          onBlur={(text) => {                          
                            setInstruction(text);
                            let txt = text.replace(/(<([^>]+)>)/gi, "");                          
                            let length = txt.match(/[\w\d\’\'-]+/gi).length;
                            setWordCount(length);
                            let word = txt.match(/[\w\d\’\'-]+/gi);
                            console.log(word.findIndex('test'));
                          }}
                        ></JoditEditor>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="portlet-header">
                        <div>
                          <div
                            dangerouslySetInnerHTML={{ __html: instruction1 }}
                          />
                          {/* <h5 className=" dark_color mtb-11 ms-3"> */}
                          {/* Shoes for WomenENN */}
                          {/* </h5> */}
                          <p className="pb-0 dark_color fw-500 px-3"></p>
                        </div>
                      </div>
                      {/* <div className="portlet-header">
                        <div>
                          <h5 className="fw-bold dark_color mtb-11 ms-3">
                            Explore Gorgeous, Stylish, and Comfy Shoes for Women
                          </h5>
                          <p className="pb-0 dark_color fw-500">
                            Explore Gorgeous, Stylish, and Comfy Shoes for Women
                          </p>
                        </div>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 pe-0">
              <div className="right_side bg-white p-3 pt-4 mt-md-0 h-100">
                {versionhistory == 0 ? (
                  <>
                    <div class="position-relative progress_report">
                      {buttonclick == 0 && Editor ? (
                        <>
                          <div class="switch-tab p-3 d-flex justify-content-between align-items-center">
                            <span
                              // type="button"
                              class=""
                            >
                              Article Status
                            </span>
                            <span class="badge-article rounded-2 skyblue_border">
                              In Progress
                            </span>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {buttonclick == 0 && Editor ? (
                        <>
                          <div className="mt-3 d-flex justify-content-between align-items-center switch-tab p-3">
                            <span
                              // type="button"
                              class=""
                            >
                              ETA
                            </span>
                            <div className="eta-calendar">
                              <div className="d-flex align-items-center justify-content-between editot-eta">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="15"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-calendar mt-1"
                                >
                                  <rect
                                    x="3"
                                    y="4"
                                    width="15"
                                    height="15"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <line x1="16" y1="2" x2="16" y2="6"></line>
                                  <line x1="8" y1="2" x2="8" y2="6"></line>
                                  <line x1="3" y1="10" x2="21" y2="10"></line>
                                </svg>

                                <span className="ms-2">24 Dec 2022</span>
                                {/* < DatePicker
                                value={dates}
                                currentDate={dates}
                                multiple={isMultiSelect}
                                onChange={(e) => {
                                  setDates(e);
                                  let date = mmDDyyyyFormateDateSet(
                                    new Date(e)
                                  );
                                  if (date) {
                                    updateETA(date);
                                  }
                                }}
                                format={format}
                                calendarPosition="bottom-center"
                              /> */}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {buttonclick == 0 && Editor ? (
                        <>
                          <div className="mt-3 d-flex justify-content-between align-items-center switch-tab p-3">
                            <div>
                              <span
                                // type="button"
                                class=""
                              >
                                Word Count
                              </span>
                            </div>
                            <div className="word-count-box">
                              <div className="d-flex align-items-center justify-content-between">
                                <span className="me-2">
                                  {wordCount}/500 word
                                </span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-chevron-down my-2"
                                >
                                  <polyline points="6 9 12 15 18 9"></polyline>
                                </svg>
                              </div>
                            </div>
                            {/* <div class="">
                              <select className="border_dark badge-article">
                                <option>400/500 Words</option>
                                <option>400 Words</option>
                                <option>400 Words</option>
                              </select>
                            </div> */}
                          </div>
                        </>
                      ) : (
                        <></>
                      )}

                      {buttonclick == 2 || buttonclick == 0 ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section">
                            <div
                              class="accordion w-100 bg-white"
                              id="accordionExample"
                            >
                              <div class="accordion-item bg-transparent border-0">
                                <h2
                                  class="accordion-header"
                                  id="primarykeyword"
                                >
                                  <button
                                    class="accordion-button collapsed bg-white border-0 mt-3"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#primarykeywords"
                                    aria-expanded="true"
                                    aria-controls="primarykeywords"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 2 : 0);
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className=" fs-6 m-0">
                                        {" "}
                                        Checklist{" "}
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                      />
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id="primarykeywords"
                                  class="accordion-collapse collapse mt-2"
                                  aria-labelledby="primarykeyword"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body bg-white p-0">
                                    <div
                                      class="accordion inner_accordian"
                                      id="primarykeyword"
                                    >
                                      <div class="accordion-item">
                                        <h2
                                          class="accordion-header"
                                          id="primarykeywrdd"
                                        >
                                          <button
                                            class="accordion-button collapsed keyword_btn"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#primarykeywrd"
                                            aria-expanded="true"
                                            aria-controls="primarykeywrd"
                                          >
                                            <div className="checkmark">
                                              <FeatherIcon icon="check" />
                                            </div>
                                            Primary Keyword :
                                          </button>
                                        </h2>
                                        <div
                                          id="primarykeywrd"
                                          class="accordion-collapse collapse"
                                          aria-labelledby="primarykeywrdd"
                                          data-bs-parent="#primarykeyword"
                                        >
                                          <div class="accordion-body p-0">
                                            <div className="box_content_green">
                                              <ul>
                                                <li>
                                                  <div className="sub_box keyword_btn d-flex align-items-center ">
                                                    <div className="checkmark">
                                                      <FeatherIcon icon="check" />
                                                    </div>
                                                    <span>
                                                      Shoes for Women (01) :
                                                      0.002%
                                                    </span>
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="accordion inner_accordian"
                                      id="primarykeyword"
                                    >
                                      <div class="accordion-item">
                                        <h2
                                          class="accordion-header"
                                          id="secondarykeywrdd"
                                        >
                                          <button
                                            class="accordion-button collapsed light_color"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#secondarykeywrd"
                                            aria-expanded="true"
                                            aria-controls="secondarykeywrd"
                                          >
                                            <div className="checkmark light"></div>
                                            Secondary Keyword:
                                          </button>
                                        </h2>
                                        <div
                                          id="secondarykeywrd"
                                          class="accordion-collapse collapse"
                                          aria-labelledby="secondarykeywrdd"
                                          data-bs-parent="#primarykeyword"
                                        >
                                          <div class="accordion-body p-0">
                                            <div className="box_content_green">
                                              <ul>
                                                <li>
                                                  <div className="sub_box keyword_btn d-flex align-items-center">
                                                    <div className="checkmark">
                                                      <FeatherIcon icon="check" />
                                                    </div>
                                                    Casual Shoes (04) : 0.008%
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="sub_box keyword_btn d-flex align-items-center">
                                                    <div className="checkmark">
                                                      <FeatherIcon icon="check" />
                                                    </div>
                                                    Mens Clothing (03) : 0.006%
                                                  </div>
                                                </li>
                                                <li>
                                                  <div className="sub_box sub_block_light light_color d-flex align-items-center">
                                                    <div className="checkmark">
                                                      <FeatherIcon icon="check" />
                                                    </div>
                                                    Shoes for Women (00)
                                                  </div>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="accordion inner_accordian"
                                      id="includekeyword"
                                    >
                                      <div class="accordion-item">
                                        <h2
                                          class="accordion-header"
                                          id="secondarykeywrdd"
                                        >
                                          <button
                                            class="accordion-button collapsed light_color"
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target="#includekeywords"
                                            aria-expanded="true"
                                            aria-controls="includekeywords"
                                          >
                                            <div className="checkmark light"></div>
                                            Secondary Keyword:
                                          </button>
                                        </h2>
                                        <div
                                          id="includekeywords"
                                          class="accordion-collapse collapse"
                                          aria-labelledby="secondarykeywrdd"
                                          data-bs-parent="#includekeyword"
                                        >
                                          <div class="accordion-body p-0">
                                            <div className="box_content_green"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {buttonclick == 3 || buttonclick == 0 ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section mt-3">
                            <div class="accordion w-100" id="accordionExample">
                              <div class="accordion-item bg-transparent border-0">
                                <h2 class="accordion-header" id="comment">
                                  <button ref={closeBtn}
                                    class="accordion-button collapsed border-0"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#commentblock"
                                    aria-expanded="true"
                                    aria-controls="commentblock"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 3 : 0);
                                      getcommentlist();
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className=" fs-6 m-0">
                                        {" "}
                                        Comments (01){" "}
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                      />
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id="commentblock"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="comment"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body bg-white p-0">
                                    <div className="Comment_section">
                                      <div className="inner_version_history">
                                        <div className="history">
                                          <div className="d-flex align-items-center history_detail fs-6 fw-bold gray_font">
                                            {/* <i class="fa fa-clock-o mr-2" aria-hidden="true"></i> */}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="13"
                                              height="13"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke=" #757575"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-clock"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                              ></circle>
                                              <polyline points="12 6 12 12 16 14"></polyline>
                                            </svg>
                                            <span className="ms-1">
                                              View History
                                            </span>
                                          </div>
                                        </div>
                                        <div className="addcoment_block border_blue mt-2">
                                          <div className="comenter_name d-flex justify-content-between align-items-center">
                                            <div class="w-100">
                                              <h4 className="dark_color fw-bold fs-6 m-0">
                                                {localStorage.getItem(
                                                  "userName"
                                                )}
                                                <span className="date_time  ml-2">
                                                  {formateDateIntoDatemonthTime(
                                                    new Date()
                                                  )}{" "}
                                                </span>
                                                <i class="fa-solid fa-ellipsis-vertical dark_gray cursor-pointer"></i>
                                              </h4>
                                              <div className="input_data mt-3"></div>
                                              <div className="button_group mt-3 text-right">
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  placeholder="Write your reply"
                                                  value={comment}
                                                  onChange={(e) => {
                                                    setComment(e.target.value);
                                                  }}
                                                />
                                                <button
                                                  className="btn btn-primary fw-bold"
                                                  onClick={() => {
                                                    Addcomments();
                                                    getcommentlist();
                                                    clearForm();
                                                    setCommentreply(true);
                                                    setcommentupdatestatus(false);
                                                  }}
                                                >
                                                  Add Comment
                                                </button>
                                                <button
                                                  className="btn btn-light ml-3 fw-bold"
                                                  onClick={() => {
                                                    clearForm();
                                                    closeBtn.current.click();
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                              </div>

                                              {commentlist?.map(
                                                (item) => {
                                                  //  setcommentid(item.id);

                                                  return (
                                                    <>
                                                      <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                        <div className="pt-2">
                                                          <h4 className="dark_color fw-bold fs-6 m-0">
                                                            {
                                                              item?.members
                                                                ?.name
                                                            }
                                                            <span className="date_time  ml-2">
                                                              {formateDateIntoDatemonthTime(
                                                                item.createdAt
                                                              )}
                                                            </span>
                                                          </h4>
                                                          <span>
                                                            {item.comment}
                                                          </span>
                                                        </div>
                                                        <div className="icon_line me-3 mt-2">
                                                          <span
                                                            class="mx-3"
                                                            onClick={() => {
                                                              commentstatus(
                                                                item.id
                                                              );
                                                              setcommentupdatestatus(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            {commentupdatestatus ==
                                                            false  ? <>
                                                              {item.comment ? <>
                                                              <i
                                                                class="fa fa-check ms-1"
                                                                aria-hidden="true"
                                                              ></i></> :''}</>
                                                            :
                                                              ""
                                                            }
                                                          </span>

                                                          <i class="fa-solid fa-ellipsis-vertical dark_gray cursor-pointer"></i>
                                                        </div>
                                                      </div>
                                                      {item.editorCommentReplies ? (
                                                        <>
                                                          {item.editorCommentReplies.map(
                                                            (itemreply) => {
                                                              return (
                                                                <>
                                                                  <div className="pt-2">
                                                                    <h4 className="dark_color fw-bold fs-6 m-0">
                                                                      {
                                                                        item
                                                                          ?.members
                                                                          ?.name
                                                                      }
                                                                      <span className="date_time  ml-2">
                                                                        {formateDateIntoDatemonthTime(
                                                                          itemreply.createdAt
                                                                        )}
                                                                      </span>
                                                                    </h4>
                                                                    <span>
                                                                      {
                                                                        itemreply.commentReply
                                                                      }
                                                                    </span>
                                                                  </div>
                                                                </>
                                                              );
                                                            }
                                                          )}
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                    {  commentupdatestatus == false ?<>
                                                      {addreply != true ? (
                                                        <>
                                                          <div className="button_group mt-3 text-right">
                                                            <button
                                                              className="btn btn-primary fw-bold"
                                                              onClick={() => {
                                                                getcommentlist();
                                                                clearForm();
                                                                // setCommentreply(true);
                                                                setaddreply(
                                                                  true
                                                                );
                                                              }}
                                                            >
                                                              Reply
                                                            </button>
                                                            <button
                                                              className="btn btn-light ml-3 fw-bold"
                                                              onClick={() => {
                                                                clearForm();
                                                              }}
                                                            >
                                                              Cancel
                                                            </button>
                                                          </div>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {addreply == true ? (
                                                        <>
                                                          <input
                                                            type="text"
                                                            class="form-control"
                                                            placeholder="Write your reply"
                                                            value={CommentReply}
                                                            onChange={(e) => {
                                                              setCommentReply(
                                                                e.target.value
                                                              );
                                                            }}
                                                          />
                                                          <button
                                                            className="btn btn-primary fw-bold"
                                                            onClick={() => {
                                                              commentsreply(
                                                                item.id
                                                              );
                                                              getcommentlist();
                                                              clearForm();
                                                              setcommentupdatestatus(
                                                                false
                                                              );
                                                              setCommentreply(
                                                                true
                                                              );
                                                            }}
                                                          >
                                                            Add Reply
                                                          </button>
                                                          <button
                                                            className="btn btn-light ml-3 fw-bold"
                                                            onClick={() => {
                                                              clearForm();
                                                            }}
                                                          >
                                                            Cancel
                                                          </button>
                                                        </>
                                                      ) : (
                                                        ""
                                                      )}
                                         </>  : <>
                                              <div>
                                                <spna>
                                                  <i
                                                    class="fa fa-check ms-1"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  Completed On{" "}
                                                  {formateDateIntoDatemonthTime(
                                                    completedate
                                                  )}{" "}
                                                </spna>
                                              </div>
                                            </>   }
                                                    </>
                                                  );
                                                }
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {buttonclick == 4 || buttonclick == 0 ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section">
                            <div
                              class="accordion w-100 bg-white"
                              id="accordionExample"
                            >
                              <div class="accordion-item bg-transparent border-0">
                                <h2 class="accordion-header" id="suggestion">
                                  <button
                                    ref={closesugBtn}
                                    class="accordion-button collapsed bg-white border-0 mt-3"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#suggestionblock"
                                    aria-expanded="true"
                                    aria-controls="suggestionblock"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 4 : 0);
                                      getsuggestion();
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className=" fs-6 m-0">
                                        {" "}
                                        Suggestions (01){" "}
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                      />
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id="suggestionblock"
                                  class="accordion-collapse collapse"
                                  aria-labelledby="suggestion"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body bg-white p-0">
                                    <div className="Comment_section">
                                      <div className="inner_version_history">
                                        <div className="history">
                                          <div className="d-flex align-items-center history_detail fs-6  gray_font">
                                            {/* <i
                                              class="fa fa-clock-o mr-2"
                                              aria-hidden="true"
                                            ></i> */}
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="13"
                                              height="13"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              stroke=" #757575"
                                              stroke-width="2"
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              class="feather feather-clock"
                                            >
                                              <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                              ></circle>
                                              <polyline points="12 6 12 12 16 14"></polyline>
                                            </svg>
                                            <span className="ms-1">
                                              View History
                                            </span>
                                          </div>
                                        </div>
                                        <div className="addcoment_block border_blue mt-2">
                                          <div className="comenter_name d-flex justify-content-between align-items-center ">
                                            <div class="w-100">
                                              {suggestionlist == "" ? (
                                                <>
                                                  <h4 className="dark_color fw-bold fs-6 m-0">
                                                    {localStorage.getItem(
                                                      "userName"
                                                    )}
                                                    <span className="date_time  ml-2">
                                                      {formateDateIntoDatemonthTime(
                                                        new Date()
                                                      )}{" "}
                                                    </span>
                                                    <i class="fa-solid fa-ellipsis-vertical dark_gray cursor-pointer"></i>
                                                  </h4>
                                                </>
                                              ) : (
                                                <>
                                                  {suggestionlist?.map(
                                                    (item, index) => {
                                                      return (
                                                        <>
                                                          <div className="d-flex align-items-center justify-content-between border-bottom pb-2">
                                                            <div>
                                                              <h4 className="dark_color fw-bold fs-6 m-0">
                                                                {
                                                                  item?.members
                                                                    ?.name
                                                                }
                                                                <span className="date_time dark_gray ml-2">
                                                                  {formateDateIntoDatemonthTime(
                                                                    item.createdAt
                                                                  )}{" "}
                                                                </span>
                                                              </h4>
                                                              {item.suggestion}
                                                            </div>
                                                            <div className="icon_line me-3 mt-2">
                                                              <span
                                                                className={
                                                                  index == 0
                                                                    ? "mx-3"
                                                                    : "d-none"
                                                                }
                                                                onClick={() => {
                                                                  suggestionstatus(
                                                                    item.id
                                                                  );
                                                                  suggestionupdatestatus(
                                                                    true
                                                                  );
                                                                }}
                                                              >
                                                                {" "}
                                                                {suggestionupdatestatus ==
                                                                false ? (
                                                                  <i
                                                                    class="fa fa-check ms-1"
                                                                    aria-hidden="true"
                                                                  ></i>
                                                                ) : (
                                                                  ""
                                                                )}
                                                              </span>
                                                              <i class="fa-solid fa-ellipsis-vertical dark_gray cursor-pointer"></i>
                                                            </div>
                                                          </div>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                                </>
                                              )}
                                            </div>
                                          </div>
                                          {suggestionupdatestatus == false ? (
                                            <>
                                              <div className="input_data mt-3">
                                                <input
                                                  type="text"
                                                  class="form-control"
                                                  value={suggestion}
                                                  onChange={(e) => {
                                                    setsuggestion(
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                              </div>
                                              <div className="button_group mt-3 text-right">
                                                {suggestionraply == false ? (
                                                  <>
                                                    {" "}
                                                    <button
                                                      className="btn btn-primary fw-bold"
                                                      onClick={() => {
                                                        Addsuggestion();
                                                        clearForm();
                                                        setsuggestionraply(
                                                          true
                                                        );
                                                        setsuggestionupdatestatus(
                                                          false
                                                        );
                                                      }}
                                                    >
                                                      Add Suggestion
                                                    </button>
                                                  </>
                                                ) : (
                                                  <>
                                                    {" "}
                                                    <button
                                                      className="btn btn-primary fw-bold"
                                                      onClick={() => {
                                                        suggestionsreply(
                                                          suggestionlist[0].id
                                                        );

                                                        clearForm();
                                                        setsuggestionraply(
                                                          true
                                                        );
                                                      }}
                                                    >
                                                      Add Reply
                                                    </button>
                                                  </>
                                                )}
                                                <button
                                                  className="btn btn-light ml-3 fw-bold"
                                                  onClick={() => {
                                                    clearForm();
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </>
                                          ) : (
                                            <>
                                              <div>
                                                <spna>
                                                  <i
                                                    class="fa fa-check ms-1"
                                                    aria-hidden="true"
                                                  ></i>{" "}
                                                  Completed On{" "}
                                                  {formateDateIntoDatemonthTime(
                                                    completedate
                                                  )}{" "}
                                                </spna>
                                              </div>
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      {(buttonclick == 5 || buttonclick == 0) && Editor ? (
                        <>
                          <div className="switch-tab d-flex justify-content-between align-items-center suggestion_section">
                            <div
                              class="accordion w-100 bg-white"
                              id="accordionExample"
                            >
                              <div class="accordion-item bg-transparent border-0">
                                <h2 class="accordion-header" id="keywordpoxi">
                                  <button
                                    class="accordion-button collapsed bg-white border-0 mt-3"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#keywordproxy"
                                    aria-expanded="true"
                                    aria-controls="keywordproxy"
                                    onClick={() => {
                                      setbuttonclick(buttonclick == 0 ? 5 : 0);
                                    }}
                                  >
                                    <div className="d-flex w-100 align-items-center justify-content-between">
                                      <span className=" fs-6 m-0">
                                        {" "}
                                        Keyword Proximity (01)
                                      </span>
                                      <FeatherIcon
                                        class="cmseditor-close-set"
                                        icon="x"
                                      />
                                    </div>
                                  </button>
                                </h2>
                                <div
                                  id="keywordproxy"
                                  class="accordion-collapse collapse mt-2"
                                  aria-labelledby="keywordpoxi"
                                  data-bs-parent="#accordionExample"
                                >
                                  <div class="accordion-body bg-white p-0">
                                    <div className="Comment_section">
                                      <div className="inner_version_history">
                                        <div className="addcoment_block red_border mt-3">
                                          <div className="comenter_name d-flex justify-content-between align-items-center">
                                            <h4 className="dark_color fw-bold fs-6 m-0">
                                              Keyword Proximity{" "}
                                            </h4>
                                            <div className="icon_line">
                                              <i class="fa-solid fa-ellipsis-vertical dark_gray cursor-pointer"></i>
                                            </div>
                                          </div>
                                          <p className="p-0  dark_color">
                                            <span className="fst-italic">
                                              keywords
                                            </span>{" "}
                                            are close to each other, consider
                                            spacing them out
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {versionhistory == 1 ? (
                  <>
                    <div className="version_history">
                      <div className="inner_version_history">
                        <div className="top_header d-flex justify-content-between align-items-center pl-3">
                          <h4 className="fw-6 fs-18 fc-black2 m-0">
                            Version History
                          </h4>
                          <i
                            class="fa fa-close me-3 fs-6"
                            aria-hidden="true"
                            onClick={() => {
                              setversionhistory(0);
                            }}
                          ></i>
                        </div>
                        <div className="cureent_version d-flex align-items-center py-3 px-3 rounded mt-4">
                          <div className="dot"></div>
                          <div className="current_text fs-18 text-white fw-6 pl-3">
                            Current Version
                          </div>
                        </div>
                        <div class="accordion mt-4  " id="accordionExample">
                          <div class="accordion-item border-0">
                            <h2 class="accordion-header" id="headingOne">
                              <button
                                class="accordion-button border-0 version-accord"
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                {/* <div className="dot_blue mr-3 fs-6"></div> */}
                                <span className="fs-18 color_blue ms-4 ps-4 total-version fw-5">
                                  Total 5 Version
                                </span>
                              </button>
                            </h2>
                            <div
                              id="collapseOne"
                              class="accordion-collapse collapse show"
                              aria-labelledby="headingOne"
                              data-bs-parent="#accordionExample"
                            >
                              <div class="accordion-body pl-5">
                                {versionHistoryList?.map((item, index) => {
                                  return (
                                    <>
                                      <div className="date_detail pt-3">
                                        <h3 className="fs-6 fc-black1 fw-5">
                                          {/* 24 Dec 2022, 11:30 am */}
                                          {formateDateIntoDatemonthTime(
                                            item.createdAt
                                          )}
                                        </h3>
                                        <p className="p-0 fw-6 fs-14 gray_font">
                                          {/* Deepen */}
                                          {item?.members?.name}
                                        </p>
                                      </div>
                                    </>
                                  );
                                })}
                                {/* <div className="date_detail mt-5">
                                  <h3 className="fs-6 fw-bold">
                                    24 Dec 2022, 11:30 am
                                  </h3>
                                  <p className="p-0 gray_font fw-bold">
                                    Deepen Mavani
                                  </p>
                                </div>
                                <div className="date_detail mt-5">
                                  <h3 className="fs-6 fw-bold">
                                    24 Dec 2022, 11:30 am
                                  </h3>
                                  <p className="p-0 gray_font fw-bold">
                                    Deepen Mavani
                                  </p>
                                </div>
                                <div className="date_detail mt-5">
                                  <h3 className="fs-6 fw-bold">
                                    24 Dec 2022, 11:30 am
                                  </h3>
                                  <p className="p-0 gray_font fw-bold">
                                    Deepen Mavani
                                  </p>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {/* Start All project */}
          <TabPanel>
            <div class="row view-ticket-tab-row w-100">
              <div class="col-md-9 col-sm-12 ps-0 newticket-view">
                <div className="portlet-header">
                  <div>
                    <h5 className="fw-bold dark_color mtb-11">
                      Shoes for Women
                    </h5>
                    <p className="pb-0 dark_color fw-500">
                      Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                      Sunt amet recusandae minus itaque quia architecto
                      doloribus repudiandae, enim facere laudantium quis dolorem
                      nemo ullam asperiores atque nihil sint distinctio nulla
                    </p>
                  </div>
                </div>
                <div className="portlet-header">
                  <div>
                    <h5 className="fw-bold dark_color mtb-11">
                      Explore Gorgeous, Stylish, and Comfy Shoes for Women
                    </h5>
                    <p className="pb-0 dark_color fw-500">
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Quod, excepturi harum rem nulla fuga, hic laborum sapiente
                      accusantium doloribus officiis quos! Consequatur nostrum
                      exercitationem,
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-sm-12 pe-0">
                <div className="mt-3">
                  <span
                    // type="button"
                    class="btn btn-primary"
                  >
                    Comment
                  </span>
                </div>
                <div className="mt-3">
                  <span
                    // type="button"
                    class="btn btn-primary"
                  >
                    Suggestions
                  </span>
                </div>
                <div className="mt-3">
                  <span
                    // type="button"
                    class="btn btn-primary"
                  >
                    Checklist
                  </span>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
      </div>
    </>
  );
}

export default EditorContent;
