import React, { useEffect, useRef, useState } from 'react'
import { Drawer, Input, Table } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import APIManager from '../../../services/APIManager';
import {
    addrankingcampaignCompetitor,
    deleteAllCompetitors,
    deleteRankingCompitetorById,
    documentFileType,
    getcompetitorsByRankingcampaignConfigId,
    GET_Server_URL,
    RankngCompietitorImport,
    updateRankingCompetitor,
} from '../../../utility/Constant';
import { toast } from 'react-hot-toast';
import { confirmAlert } from 'react-confirm-alert';
import { Sorter } from '../../../common/Sorter';
import { removeTrailingSlash } from '../../../utility/Globals';

function Viewcompetitor(props) {

    const [tabcomplist, settabcompList] = useState([]);
    const [complist, setcompList] = useState([]);
    const [complistIndex, setcomplistIndex] = useState(0);
    const [comptrlist, setcomptrlist] = useState([]);
    const CompTabcol = [
        {
            title: "Competitors",
            dataIndex: "comp",
            key: "comp",
            width: "90%",
            sorter: {
                compare: (a, b) =>
                    Sorter.DEFAULT(
                        a.comp.toLocaleLowerCase(),
                        b.comp.toLocaleLowerCase()
                    ),
                multiple: 3,
            },
        },
        {
            title: "Action",
            dataIndex: "delete",
            key: "delete",
            width: "10%",
        },
    ];
    const [selectedCompExportList, setSelectedCompExportList] = useState([]);
    const [compExportList, setCompExportList] = useState([]);
    const [errors, setErrors] = useState("");
    const [save, setsave] = useState(false);
    const [IsEditcompetitior, setIsEditcompetitiors] = useState(false);
    const [TempisUpdate, setTempisUpdate] = useState(false);
    const [CompetitiorsId, setCompetitorsId] = useState("");
    const [RankingCompetitorConfigId, setRankingCompetitorConfigId] = useState("");
    const [CompetitorsName, setCompetitorsName] = useState("");
    const [CompetitorsNameCopy, setCompetitorsNameCopy] = useState("");
    const [SelectedCompCheckList, setSelectedCompCheckList] = useState([]);
    const [SelectedCompetitors, setSelectedCompetitors] = useState(0);
    const [TotalCompetitors, setTotalCompetitors] = useState(0);
    const notifyAdd = () => toast.success("Successfully Added!");

    useEffect(() => {
        getCompetitorsList();
    }, [props?.CompetitorcampId]);

    const [open, setOpen] = useState(false);

    const showDrawer = () => {
        setcompList([]);
        setCompetitorsName("")
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
        setcompList([]);
    };

    const comprowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const list = [];
            selectedRows.map((item, index) => {
                let obj = {
                    key: item.key,
                    competitors: item.comp,
                };
                list.push(obj);
            });
            setSelectedCompExportList(list);

            const list1 = [];
            selectedRows.map((item, index) => {
                let obj = {
                    key: item.id,
                    competitors: item.fieldValue,
                };
                list1.push(item.id);
            });
            setSelectedCompCheckList(list1);
            setSelectedCompetitors(list1?.length)
        },
    };

    function submit() {
        confirmAlert({
            title: "Confirm to Delete",
            message:
                SelectedCompetitors +
                " of " +
                TotalCompetitors +
                " Competitors selected will be deleted.",
            buttons: [
                {
                    label: "Yes",
                    onClick: () => {
                        deleteMultiCompId();
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    }

    function addCompetitors() {
        APIManager.postAPI(
            addrankingcampaignCompetitor,
            complist,
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                setCompetitorsName("");
                getCompetitorsList();
                notifyAdd();
            }
        });
    }

    function addTmpCompetitors() {
        let obj = complist;

        if (TempisUpdate) {
            obj[complistIndex].Competitor = CompetitorsName.trim();
        } else {
            obj.push({
                RankingCampaignConfigurationId: props?.CompetitorcampId,
                Competitor: removeTrailingSlash(CompetitorsName),
            });
        }
        setcompList(obj);
        setCompetitorsName("");
        setTempisUpdate(false);
    }

    function updateCompetitiors() {
        APIManager.putAPI(
            updateRankingCompetitor,
            {
                Id: CompetitiorsId,
                RankingCampaignConfigurationId: RankingCompetitorConfigId,
                Competitor: removeTrailingSlash(CompetitorsName)
            },
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                setIsEditcompetitiors(false);
                onClose();
                setCompetitorsName("");
                getCompetitorsList();
            }
        });
    }

    function deleteMultiCompId() {
        return new Promise((resolve, reject) => {
            APIManager.postAPIForDelete(
                deleteAllCompetitors,
                SelectedCompCheckList,
                {},
                true
            ).then((response) => {
                if (response && response.status == 200) {
                    getCompetitorsList();
                    setSelectedCompCheckList(null);
                    if (response.status === 200)
                        if (response?.data?.message) {
                        } else {
                            // notifyDelete();
                        }
                    resolve(response.status);
                }
            });
        });
    }

    function getCompetitorsList() {
        APIManager.getAPI(
            getcompetitorsByRankingcampaignConfigId + props?.CompetitorcampId
        ).then((response) => {
            if (response && response.status == 200) {
                const exportList = [];
                let c = 1;
                const list = [];
                const clist = [];

                response.data.map((item) => {
                    let exportObj = {
                        key: item.id,
                        competitors: item.competitor,
                    };
                    setRankingCompetitorConfigId(item.rankingCampaignConfigurationId);
                    exportList.push(exportObj);
                    list.push({
                        key: item.id,
                        id: item.id,
                        value: item.competitor,
                        label: item.competitor,
                        comp: item.competitor,
                        delete: (
                            <div className="px-0">
                                <Link
                                    to={{
                                        state: { item: item, isEdit: true },
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit edit-icon me-3"
                                        onClick={() => {
                                            showDrawer();
                                            setIsEditcompetitiors(true);
                                            setCompetitorsId(item.id);
                                            setCompetitorsName(item.competitor);
                                            setCompetitorsNameCopy(item.competitor);
                                            var e = document.getElementById("input-s");
                                            e.scrollIntoView({
                                                block: "end",
                                                behavior: "smooth",
                                                inline: "center",
                                            });
                                        }}
                                    >
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                    </svg>
                                </Link>

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trash-2 trash-icon"
                                    onClick={() => {
                                        deleteCompetitorsItem(item.id);
                                    }}
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                            </div>
                        ),
                    });
                    c++;
                });
                setCompExportList(exportList);
                settabcompList(list);
                setcomptrlist(list);
                setTotalCompetitors(list?.length);
            }
        });
    }

    async function deleteCompetitorsItem(id) {
        try {
            const response = await APIManager.deleteAPI(deleteRankingCompitetorById + id);
            if (response == 200) {
                getCompetitorsList();
            }
        } catch (e) { }
    }

    function uploadCompetitorsFile() {
        var input = document.getElementById("competitorFile");
        competitorImport(input.files.item(0));
    }

    function competitorImport(file) {
        inputFileRef.current.value = null;
        var bodyFormData = new FormData();
        bodyFormData.append("file", file);
        
        APIManager.postAPI(
            RankngCompietitorImport + props?.CompetitorcampId,
            {},
            bodyFormData,
            false
        ).then((response) => {
            if (response && response.status == 200) {
                getCompetitorsList();
            }
        });
    }
    const onBtnClick = () => {
        inputFileRef.current.click();
    };
    const inputFileRef = useRef(null);
    const history = useHistory();

    function isValidURL() {
        let error = { ...errors };
        CompetitorsName.trim() == ""
            ? (error["CompetitorsName"] = "Competitors is required!")
            : delete error["CompetitorsName"];

        if (CompetitorsName.trim()?.length) {
            var pattern = new RegExp(
                "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
            );

            if (!pattern.test(CompetitorsName.trim())) {
                error["CompetitorsName"] = "Please enter valid Competitors!";
            } else {
                delete error["CompetitorsName"];
                let isEqualItem = false;
                tabcomplist.map((item) => {
                    if (item.value == CompetitorsName.trim()) {
                        isEqualItem = true;
                    }
                });
                if (isEqualItem) {
                    error["CompetitorsName"] = "Competitors is already exist!";
                } else {
                    delete error["CompetitorsName"];
                }
            }
        }
        setErrors(error);
        let err = Object.keys(error);
        return err?.length ? false : true;
    }

    const removeFields = (index) => {
        let data = [...complist];
        data.splice(index, 1)
        setcompList(data);
    }

    return (
        <div className="custom-column-70">
            <div className="portlet border-0 mb-0 pb-0">
                <div class="portlet-header">
                    <h3 class="portlet-title main-title">
                        Add Competitors
                    </h3>
                </div>
                <div className="portlet-body px-3 mx-2 mt-4 pb-0">
                    <div className="row">
                        <div className="col-md-4 tab-panel-url-options  d-flex justify-content-start url-tab mt-3">
                            <div class="input-group-icon input-group-lg widget15-compact">
                                <div class="input-group-prepend">
                                    <i class="fa fa-search text-primary"></i>
                                </div>
                                <span>
                                    <Input
                                        allowClear
                                        style={{ padding: 7 + "px" }}
                                        type="text"
                                        class="form-control"
                                        placeholder="Type to search"
                                        onChange={(nameSearch) => {
                                            settabcompList(
                                                comptrlist.filter((person) =>
                                                    person.comp
                                                        .toLocaleLowerCase()
                                                        .includes(
                                                            nameSearch.target.value.toLocaleLowerCase()
                                                        )
                                                )
                                            );
                                        }}
                                        id="input-s"
                                    />
                                </span>
                            </div>
                        </div>

                        <div className="col-md-8 tab-panel-options text-end mt-3 competitors-tab">
                            <span class="export">
                                <input
                                    style={{ display: "none" }}
                                    type="file"
                                    id="competitorFile"
                                    name="competitorFile"
                                    multiple={false}
                                    accept={documentFileType}
                                    ref={inputFileRef}
                                    onChange={uploadCompetitorsFile}
                                />
                                <button
                                    disabled={!SelectedCompCheckList?.length}
                                    class="btn btn-primary download-temp me-3"
                                    onClick={() => {
                                        submit();
                                    }}
                                >
                                    Delete
                                </button>
                                <button
                                    class="btn btn-primary download-temp me-3"
                                    onClick={() => {
                                        showDrawer();
                                    }}
                                >
                                    Add
                                </button>
                                <button
                                    class="competitors-import btn btn-primary me-3 import"
                                    onClick={onBtnClick}
                                >
                                    Import
                                </button>
                                <a
                                    class="btn btn-outline download-temp"
                                    target="_blank"
                                    href={GET_Server_URL + "audit/Competitors.xlsx"}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                    <span className='ms-1'> Download Template</span>
                                </a>
                            </span>
                        </div>
                    </div>
                    <div className="ranking-campaing-table table-bordered border-0 mt-3 pt-2">
                        <div className="col-md-12 p-0">
                            <Table
                                className="table table-hover"
                                id="url"
                                columns={CompTabcol}
                                dataSource={tabcomplist}
                                rowSelection={{ type: "checkbox", ...comprowSelection }}
                                pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                    showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} Competitors`
                                }}
                            />
                        </div>
                    </div>
                    <Drawer
                        title="Add New Competitors"
                        className="ranking-box-start rankv2-modal"
                        placement="right"
                        size="636px"
                        onClose={onClose}
                        open={open}>
                        <div className="portlet-body py-4">
                            <div className="row">
                                <div className="d-flex align-items-center justify-content-between">

                                    <label className='mb-0 fw-bold text-dark'>Competitor</label>
                                    <div className="input-box w-100 mx-3">
                                        <input
                                            className=" border d-block w-100"
                                            type="text"
                                            value={CompetitorsName}
                                            onChange={(text) => {
                                                setCompetitorsName(text.target.value);
                                            }}
                                            placeholder="Enter Competitors"
                                        />
                                        {save && errors?.CompetitorsName && (
                                            <>
                                                <div className="field-required mt-2">
                                                    <span> {errors?.CompetitorsName}</span>
                                                </div>
                                            </>
                                        )}
                                        {save && !errors?.CompetitorsName &&CompetitorsName === localStorage.getItem('rankingCampaignDomain') && (
                                            <>
                                                <div className="field-required mt-2">
                                                    <span> Competitor URL and Campaign Domain not be same</span>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <button
                                        class="btn btn-primary"
                                        onClick={() => {
                                            setsave(true);
                                            if (
                                                CompetitorsNameCopy.trim() == CompetitorsName.trim() &&
                                                IsEditcompetitior
                                            ) {
                                                setCompetitorsName("");
                                                setErrors("");
                                                return;
                                            }
                                            if (isValidURL()) {
                                                if(CompetitorsName === localStorage.getItem('rankingCampaignDomain')) {
                                                    return;
                                                }
                                                if (IsEditcompetitior) {
                                                    updateCompetitiors();
                                                } else {
                                                    addTmpCompetitors()
                                                }
                                            } else {
                                            }
                                        }}
                                    >
                                        {IsEditcompetitior || TempisUpdate ? "Update" : "Add"}
                                    </button>
                                </div>
                            </div>
                            <div className="onclick-display mt-5">
                                <div className="row">
                                    <h5 className='fw-bold text-dark mb-4'>{!IsEditcompetitior ? "Competitors List:" : ""}</h5>
                                    <div className="list-boxx mt-3">
                                        {complist.map((item, index) => {
                                            return (<>
                                                <div className="d-flex justify-content-between align-items-center">

                                                    <div className="border-bottom pb-2 w-100">
                                                        <span className='text-dark'>{item.Competitor}</span>
                                                    </div>
                                                    <div className="d-flex align-items-center ms-4">
                                                        <Link
                                                            to={{
                                                                state: { item: item, isEdit: true },
                                                            }}
                                                        >
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="currentColor"
                                                                stroke-width="2"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                                class="feather feather-edit edit-icon me-3"
                                                                onClick={() => {
                                                                    if (item.id) {
                                                                        setIsEditcompetitiors(true);
                                                                        setCompetitorsId(item.id);
                                                                        setCompetitorsName(item.value);
                                                                        setCompetitorsNameCopy(item.value);
                                                                        var e = document.getElementById("input-s");
                                                                        e.scrollIntoView({
                                                                            block: "end",
                                                                            behavior: "smooth",
                                                                            inline: "center",
                                                                        });
                                                                    } else {
                                                                        setTempisUpdate(true);
                                                                        setcomplistIndex(index)
                                                                        setCompetitorsName(item.Competitor);
                                                                    }
                                                                }}
                                                            >
                                                                <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                                                <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                            </svg>
                                                        </Link>

                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            stroke-width="2"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                            class="feather feather-trash-2 trash-icon"
                                                            onClick={() => {

                                                                if (item.id) {
                                                                    onClose();
                                                                    deleteCompetitorsItem(item.id);
                                                                } else {
                                                                    removeFields(index);
                                                                }
                                                            }}
                                                        >
                                                            <polyline points="3 6 5 6 21 6"></polyline>
                                                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                                            <line x1="10" y1="11" x2="10" y2="17"></line>
                                                            <line x1="14" y1="11" x2="14" y2="17"></line>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="button-down">
                                {!IsEditcompetitior ?
                                    (<>
                                        <button
                                            onClick={() => {
                                                addCompetitors();
                                                onClose();
                                            }}
                                            disabled={complist.length ? false : true}
                                            className={`${complist.length ? "btn btn-primary" : "btn btn-primary bgg-light"}`}>Save</button>
                                    </>)
                                    : (<></>)}
                            </div>
                        </div>
                    </Drawer>
                </div>
            </div>
        </div>
    );
}

export default Viewcompetitor