// .jsx

import React from "react";
import "antd/dist/antd.css";
import { Doughnut } from "react-chartjs-2";

const DoughnutChart = (props) => {

    return (
        <>
            <Doughnut data={{
                labels: props.labels,
                datasets: [
                    {
                        label: "Points checked",
                        data: props.data,
                        backgroundColor: [
                            "#91e395",
                            "#ffaa46",
                        ],
                        borderColor: [
                            "#91e395",
                            "#ffaa46",
                        ],
                        borderWidth: 0,
                    },
                ],
                text: "56"
            }}
                options={{
                    responsive: [
                        {
                            breakpoint: 200,
                            options: {
                                chart: {
                                    //width: 50,
                                },
                                legend: {
                                    show: false,
                                },
                            },
                        },
                    ],
                    legend: false,
                    pieceLabel: {
                        render: "value",
                        fontColor: "#fff",
                    },
                }} />
        </>
    )
}

export default DoughnutChart;