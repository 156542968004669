import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import {
  createkeywordresearchconfig,
  getKeyWordByProjectId,
  getKeywordResearchConfig,
  getUrlByOrgId,
  getLanguage,
  getCountryByOrg,
} from "../../utility/Constant";
import {
  DDMMyyyyFormateDateSet,
  selectAllList,
  themeStyle,
} from "../../utility/Globals";

const KeywordResearch = (props) => {
  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };
  const history = useHistory();
  const [urlList, setURlList] = useState([]);
  const [countrySelected, setCountrySelected] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [languageSelected, setLanguageSelected] = useState("");
  const [languageList, setLanguageList] = useState([]);
  const [IsActive2, SetIsActive2] = useState(false);
  const [leadsClientDocs, setLeadsClientDocs] = useState([]);
  const [documentFile, setDocumentFile] = useState("");

  useEffect(() => {
    getUrlList();
    getCountryList();
    getKeywordlist();
    getLanguageList();
  }, []);
  const [keywordLIST, setKEYWORDList] = useState("");
  const [selectkeyword, setselectkeyword] = useState([]);

  function handleCountryChange(selected) {
    setCountrySelected(selected);
  }

  function updateLeadsClientDocs() {
    var input = document.getElementById("LeadsClientDocs");
    const a = [];
    for (var i = 0; i < input.files.length; ++i) {
      a.push(input.files.item(i).name);
    }
    setLeadsClientDocs(input.files.item(0));
  }

  function getUrlList() {
    APIManager.getAPI(getUrlByOrgId + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          response.data.map((item) => {
            let obj = {
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              url: item.fieldValue,
            };
            list.push(obj);
          });
          setURlList(list);
        }
      }
    );
  }

  function getCountryList() {
    APIManager.getAPI(
      getCountryByOrg + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          if (item.locationCode) {
            list.push({
              key: item.locationCode,
              id: item.locationCode,
              value: item.countryName,
              label: item.countryName,
            });
          }
        });
        setCountryList(list);
        getKeywordResearchData(list);
      }
    });
  }

  function getKeywordlist() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          count: 0,
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          keyword: "Select All",
        });
        let c = 1;
        const exportlist = [];
        response.data.map((item) => {
          list.push({
            count: c,
            key: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            id: item.id,
            keyword: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => {}}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          });
          exportlist.push({
            key: item.id,
            id: item.id,
            keyword: item.fieldValue,
          });
          c++;
        });

        setKEYWORDList(list);
      }
    });
  }
  const [selectedDataSource, setSelectedDataSource] = useState([0]);
  function onChangeDataSource(e) {
    var c = e.target.value;
    setSelectedDataSource(c);
  }

  function createkeywords() {
    let newDate = new Date().toISOString().slice(0, 10);
    var myCurrentDate = new Date().toISOString().slice(0, 10);
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 7);

    let projectId = localStorage.getItem("projectGId");
    var bodyFormData = new FormData();
    bodyFormData.append("File", leadsClientDocs);
    bodyFormData.append("projectId", projectId);
    bodyFormData.append("Language", languageSelected);
    bodyFormData.append("DataSource", selectedDataSource);
    bodyFormData.append("StartDate", DDMMyyyyFormateDateSet(myPastDate));
    bodyFormData.append("EndDate", DDMMyyyyFormateDateSet(newDate));

    let arrList = selectkeyword.splice(
      selectkeyword.findIndex((e) => e.value != "Select All"),
      selectkeyword.length
    );
    arrList.map((item, i) => {
      bodyFormData.append(
        `KeywordResearchConfigurationKeywordMappings[${i}].KeywordId`,
        item.id
      );
    });
    countrySelected.map((item, i) => {
      bodyFormData.append(
        `KeywordResearchConfigurationLocationMappings[${i}].LocationId`,
        item.id
      );
    });
    if (documentFile) {
      bodyFormData.append("File", leadsClientDocs);
    }
    APIManager.postAPI(
      createkeywordresearchconfig,
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response?.status == 200) {
      }
    });
  }

  function getKeywordResearchData(list) {
    APIManager.getAPI(
      getKeywordResearchConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const keywordList = [];
        response.data.keywordResearchConfigurationKeywordMappings.map(
          (item) => {
            keywordList.push({
              key: item.keywords.id,
              id: item.keywords.id,
              label: item.keywords.fieldValue,
              value: item.keywords.fieldValue,
              keyword: item.keywords.fieldValue,
            });
          }
        );
        setselectkeyword(keywordList);

        const cList = [];
        response.data.keywordResearchConfigurationLocationMappings.map(
          (item) => {
            list.map((data) => {
              if (item.locationId == data.id) {
                cList.push({
                  key: data.key,
                  id: data.id,
                  label: data.label,
                  value: data.value,
                });
              }
            });
          }
        );
        setCountrySelected(cList);
        setSelectedDataSource(response.data.dataSource);
        setLanguageSelected(response.data.language);
      }
    });
  }

  function getLanguageList() {
    APIManager.getAPI(getLanguage).then((response) => {
      if (response && response.status == 200) {
        setLanguageList(response.data);
        response.data?.map((item) => {
          if (item.languageName == "English") {
            setLanguageSelected(item.languageCode);
          }
        });
      }
    });
  }
  const [oldList, setOldList] = useState([]);

  return (
    <div className="custom-column-70">
      <div className="col-md-12 d-flex mb-3 p-0">
        <i
          class="fa fa-chevron-circle-left"
          style={{ fontSize: 20 + "px", color: "#2196f3" }}
          onClick={() => {
            history.goBack();
          }}
        ></i>
        <div className="main-title">Keyword Research</div>
      </div>

      <div className="box-shadow p-4 bg-white common-form-fields">
        <div class="col-md-12 p-0">
          <label>Credential</label>
          <br />
          <input
            type="file"
            id="LeadsClientDocs"
            name="LeadsClientDocs"
            multiple={false}
            onChange={updateLeadsClientDocs}
          />
          <label id="fileLabel">{leadsClientDocs?.name}</label>
        </div>

        <div className="col-md-12 mt-3 p-0">
          <label id="addUrlPS2">KeyWords</label>
          <div class="col-md-12 d-flex justify-content-between w-set p-0">
            <ReactSelect
              styles={themeStyle()}
              className="w-200"
              options={keywordLIST}
              isMulti={true}
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{ Option }}
              onChange={(selectedkeyword) => {
                setselectkeyword(
                  selectAllList(selectedkeyword, oldList, keywordLIST)
                );
                setOldList(selectedkeyword);
              }}
              value={selectkeyword}
            />
          </div>
          {IsActive2 ? (
            <div class="col-md-12 d-flex mt-3 p-0">
              <label></label>
              <input
                className="w-50"
                type="text"
                name=""
                placeholder="Enter URL"
                value={""}
                onChange={(text) => {}}
              />

              <button
                style={{ border: "none", background: "none" }}
                onClick={() => {
                  SetIsActive2(false);
                }}
              >
                {" "}
                <i class="fa fa-times d-flex" aria-hidden="true"></i>{" "}
              </button>

              <button
                style={{ border: "none", background: "none" }}
                id={"rightbtn"}
              >
                {" "}
                <i class="fa fa-check" aria-hidden="true"></i>{" "}
              </button>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="col-md-12 mt-3 p-0">
          <label>Location</label>
          <br />
          <ReactSelect
            styles={themeStyle()}
            className="w-200"
            options={countryList}
            isMulti={false}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            components={{ Option }}
            onChange={handleCountryChange}
            value={countrySelected}
            allowSelectAll={true}
          />
        </div>

        <div className="col-md-12 mt-3 p-0">
          <label id="addUrlPS2">Language</label>
          <div class="col-md-12 d-flex justify-content-between w-set p-0">
            <select
              className="w-100"
              style={{ padding: 8 + "px" }}
              required
              value={languageSelected}
              onChange={(e) => {
                var c = e.target.value;
                setLanguageSelected(c);
              }}
            >
              {languageList?.map((item) => {
                return (
                  <option value={item.languageCode}>{item.languageName}</option>
                );
              })}
            </select>
          </div>

          <div className="col-md-12 mt-3 p-0">
            <label id="addUrlPS2">Data Source</label>
            <select
              className="w-100"
              style={{ padding: 8 + "px" }}
              value={selectedDataSource}
              onChange={onChangeDataSource}
            >
              <option value="0">Google</option>
              <option value="1">Google Sheets</option>
            </select>
          </div>

          <div class="col-md-12 mt-3 text-right">
            <button
              class="btn btn-primary"
              onClick={() => {
                createkeywords();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordResearch;
