import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import {
  dashboardSiteUptimeChart,
} from "../../utility/Constant";
import {
  fixTwoPlaceDecimal,
  mmDDyyyyFormateDateSet,
} from "../../utility/Globals";
import { DateObject } from "react-multi-date-picker";

function SiteUpTime(props) {

  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [compareendDate, setcompareendDate] = useState("");

  useEffect(() => {
    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    setcompareendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    );

    let sDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate);
    let eDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate);

    getSiteUptimeChart(sDate, eDate);
  }, [props?.dataParentToChild]);

  useEffect(() => {
  }, []);
  const [statusData, setStatusData] = useState(["", "Remaining"]);
  const [percentage, setPercentage] = useState(0);

  const [score, setScore] = useState(0);
  function getSiteUptimeChart(sDate, eDate) {

    APIManager.postAPI(
      dashboardSiteUptimeChart +
      "startDate=" +
      sDate +
      "&projectId=" +
      localStorage.getItem("projectGId") +
      "&endDate=" +
      eDate,
      {},
      null,
      true
    ).then((response) => {
      if (
        response &&
        response.status == 200 &&
        response.data != "No Content Found"
      ) {
        if (response.data.length == 0) {
          setScore(0);
        } else {
          setScore(response.data[0]?.score.toFixed(0));
        }
        setPercentage(fixTwoPlaceDecimal([response.data[0]?.score]));
        if (response.data[0]?.score >= 0 && response.data[0]?.score <= 30) {
          setStatusData(["Needs work", "Remaining"]);
        } else if (
          response.data[0]?.score >= 30 &&
          response.data[0]?.score <= 75
        ) {
          setStatusData(["Good", "Remaining"]);
        } else if (response.data[0]?.score > 75) {
          setStatusData(["Excellent", "Remaining"]);
        }
      }
    });
  }
  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: statusData,
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
        borderColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
      },
    ],
  };
  return (
    <>
      <div className="title">
        <div className="d-flex mb-1 py-2">
          <h4 className="me-3">Site Uptime</h4>
        </div>
      </div>
      <div className="dash-content dashboard-siteuptime">
        <div className="d-flex align-items-center justify-content-center py-5 my-5 siteuptime">
          <Doughnut
            data={data}
            options={{
              cutoutPercentage: 80,
              responsive: true,
              maintainAspectRatio: true,
              legend: {
                display: false,
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
            }}
          />
        </div>

        <div className="siteuptime-per percentage-text">
          <div className="text-name score-text">{score}%</div>
        </div>

        <div className="mt-3 marker-dot">
          <div className="d-flex align-items-center  justify-content-center">
            <div className="excellent-dot me-2 mt-0 mb-0"></div>
            <label className="me-3 pt-2">Excellent</label>
            <div className="good-dot me-2"></div>
            <label className="me-3 pt-2">Good</label>
            <div className="needwork-dot me-2"></div>
            <label className="me-3 pt-2">Needs Work</label>
          </div>
          <div className="mt-4"></div>
        </div>
      </div>
    </>
  );
}

export default SiteUpTime;
