import { configureStore } from "@reduxjs/toolkit";
import serpReducer from "./reducer/serptracker";
export const reducer = {
    serptracker:serpReducer
};

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export default store;
