import { Drawer } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import { Switch } from "react-router-dom";
import TopNavBarCustom from "./../components/TopNavBar";
import APIManager from "./../services/APIManager";
import { getModule } from "./../utility/Constant";
import {
  getCheckPermissionByDisplayName,
  moduleIcons,
  moduleName,
  routerLinks,
} from "./../utility/Globals";
import RouteWithSubRoutes from "./../utils/RouteWithSubRoutes";
import CreateNewTicket from "./ticket/CreateNewTicket";

axios.interceptors.request.use(
  function (response) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.remove("d-none");
    return response;
  },
  function (error) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.add("d-none");
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.add("d-none");
    return response;
  },
  function (error) {
    var element = document.getElementById("loader-main");
    if (element) element.classList.add("d-none");
    return error;
  }
);

const Main = ({ routes }) => {
  const history = useHistory();
  const location = useLocation();

  const [reportMenuList, setReportMenuList] = useState([]);
  const [healthMenuList, setHealthMenuList] = useState([]);
  const [researchMenuList, setResearchMenuList] = useState([]);
  const [ticketsMenuList, setTicketsMenuList] = useState([
    {
      name: "Ticket Dashboard",
      link: routerLinks["Ticket Dashboard"]
        ? routerLinks["Ticket Dashboard"]
        : "",
      iconClass: moduleIcons["Ticket Dashboard"],
    }, {
      name: "Team Evaluation",
      link: routerLinks["Team Evaluation"]
        ? routerLinks["Team Evaluation"]
        : "",
      iconClass: moduleIcons["Team Evaluation"],
    },
    {
      name: "My Tickets",
      link: routerLinks["My Tickets"]
        ? routerLinks["My Tickets"]
        : "",
      iconClass: moduleIcons["My Tickets"],
    }, {
      name: "Team Tickets",
      link: routerLinks["Team Tickets"]
        ? routerLinks["Team Tickets"]
        : "",
      iconClass: moduleIcons["Team Tickets"],
    }, {
      name: "Create New Ticket",
      link: routerLinks["Create New Ticket"]
        ? routerLinks["Create New Ticket"]
        : "",
      iconClass: moduleIcons["Create New Ticket"],
    },
  ]);

  useEffect(() => {
    let pathname = location.pathname.split('/');
    pathname = "/" + pathname[1] + "/" + pathname[2];
    setview(pathname == '/main/module-expand-seo-audit-view');
  }, [location])

  useEffect(() => {
    if (!localStorage.getItem("loggedInUserId")) {
      history.push("/");
    }
    if (window.innerWidth < 1025) {
      document.querySelector(".bar-icon").classList.remove("d-none");
      document
        .querySelector(".header-holder")
        .classList.remove("header-holder-desktop");
      document
        .querySelector(".header-holder")
        .classList.add("header-holder-mobile");
    } else {
      document.querySelector(".bar-icon").classList.add("d-none");
      document
        .querySelector(".header-holder")
        .classList.add("header-holder-desktop");
      document
        .querySelector(".header-holder")
        .classList.remove("header-holder-mobile");
    }
    getModulesData();
  }, []);
  const [reportsExpand, setReportsExpand] = useState(false);
  const [researchExpand, setResearchExpand] = useState(false);
  const [ticketsExpand, setTicketsExpand] = useState(false);
  const [healthExpand, setHalthExpand] = useState(false);
  const [view, setview] = useState(false);

  const [createTicketsviewopen, setcreateTicketsviewopen] = useState(false);

  const ticketviewclose = () => {
    setcreateTicketsviewopen(false);
  }

  function getModulesData() {
    APIManager.getAPI(getModule).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const researchList = [];
        const healthList = [];
        response.data.map((data) => {
          let obj = {
            name: data.name,
            link: routerLinks[data.name]
              ? routerLinks[data.name] + data.id
              : "",
            iconClass: moduleIcons[data.name],
          };

          if (data.active) {
            var module = obj.name;
            if (obj.name == "Rankings V2") {
              module = "Rankings";
            }
            if (getCheckPermissionByDisplayName(module)) {
              if (
                obj.name == "Keyword Search" ||
                obj.name == "Organic Research" ||
                obj.name == "Backlink Profile" ||
                obj.name == "Content Word Count on a Page" ||
                obj.name == "SERP Volatility" ||
                obj.name == "Google Trends"
              ) {

                if (
                  obj.name == "Keyword Search" ||
                  obj.name == "Organic Research" ||
                  obj.name == "Backlink Profile") {
                  let researchObj = {
                    name: data.name,
                    link: routerLinks["Research " + data.name]
                      ? routerLinks["Research " + data.name] + data.id
                      : "",
                    iconClass: moduleIcons[data.name],
                  };
                  researchList.push(researchObj);
                }
                else {
                  researchList.push(obj);
                }
              }
              obj = {
                name: data.name,
                link: routerLinks[data.name]
                  ? routerLinks[data.name] + data.id
                  : "",
                iconClass: moduleIcons[data.name],
              };
              if (
                obj.name == "Rankings" ||
                obj.name == "Rankings V2" ||
                obj.name == "Traffic" ||
                obj.name == "Impressions & Clicks" ||
                obj.name == "Click Share" ||
                obj.name == "Return on Investment"
              ) {
                list.push(obj);
              }
              else if (
                obj.name == "Audit Findings" ||
                obj.name == "Page Speed" ||
                obj.name == "Site Uptime Monitor" ||
                obj.name == "Website Authority" ||
                obj.name == "Backlink Profile"
              ) {
                healthList.push(obj);
              }
            }
          }
        });
        setResearchMenuList(researchList);
        setReportMenuList(list);
        setHealthMenuList(healthList);
      }
    });
  }

  function clodeSideNav() {
    document.querySelector("body").classList.remove("aside-mobile-maximized");
    document.querySelector("body").classList.add("aside-mobile-minimized");
    document.getElementById("aside-backdrop")?.remove();
  }

  return (
    <>
      <div className="home">
        <div className={`aside`}>
          <div class="aside-header d-flex justify-content-between">
            <NavLink to="/main/dashboard">
              {/* <div className="aside-title aside-logo"></div> */}
              <div className="aside-title logo-text">InfiGrowth</div>
            </NavLink>
            {/* {view ? (<i class="fa fa-angle-left back-arrow"
              onClick={() => {
                history.push("/main/dashboard");
                setview(false);
                localStorage.removeItem("isticketsview");
                setTicketsExpand(false);
              }}></i>) : (<></>)} */}
            <div class="aside-addon">
              <button
                class="btn btn-label-primary btn-icon btn-lg"
                data-toggle="aside"
              >
                <i
                  class="fa fa-angle-left aside-icon-minimize"
                  aria-hidden="true"
                ></i>
                <i
                  class="fa fa-angle-right aside-icon-maximize"
                  aria-hidden="true"
                ></i>
              </button>
            </div>
          </div>
          <div className="aside-body" data-simplebar="data-simplebar">
            <div className="menu">
              <>
                <ul className="p-0">
                  <li
                    onClick={() => clodeSideNav()}
                    key="home"
                    className="menu-item"
                  >
                    <NavLink to="/main/dashboard" className="menu-item-link">
                      <div className="menu-item-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                      </div>
                      <span className="menu-item-text">Dashboard</span>
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <button class="menu-item-link menu-item-toggle">
                      <a
                        className="menu-item-link p-0 w-100"
                        onClick={() => {
                          setReportsExpand(!reportsExpand);
                        }}
                      >
                        <div class="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-cpu"><rect x="4" y="4" width="16" height="16" rx="2" ry="2"></rect><rect x="9" y="9" width="6" height="6"></rect><line x1="9" y1="1" x2="9" y2="4"></line><line x1="15" y1="1" x2="15" y2="4"></line><line x1="9" y1="20" x2="9" y2="23"></line><line x1="15" y1="20" x2="15" y2="23"></line><line x1="20" y1="9" x2="23" y2="9"></line><line x1="20" y1="14" x2="23" y2="14"></line><line x1="1" y1="9" x2="4" y2="9"></line><line x1="1" y1="14" x2="4" y2="14"></line></svg>
                        </div>
                        <span className="menu-item-text">SEO Performance</span>
                        <span class="icon">
                          {reportsExpand ? (
                            <i class="fa fa-angle-up"></i>
                          ) : (
                            <i class="fa fa-angle-down"></i>

                          )}
                        </span>
                      </a>
                    </button>
                  </li>
                  {reportsExpand ? (
                    <div className="mob-mod menu-submenu">
                      {reportMenuList?.map((item, key) => (
                        <li
                          onClick={() => clodeSideNav()}
                          key={item.name}
                          className="menu-item"
                        >
                          <NavLink to={item.link} className="menu-item-link">
                            <div className="menu-item-icon">
                            </div>
                            <span className="menu-item-text">
                              {moduleName[item.name]}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                    </div>
                  ) : (
                    <div style={{ marginTop: "0" }}></div>
                  )}

                  {healthMenuList.length != 0 ? (
                    <li className="menu-item">
                      <button class="menu-item-link menu-item-toggle">
                        <a
                          className="menu-item-link p-0 w-100"
                          onClick={() => {
                            setHalthExpand(!healthExpand);
                          }}
                        >
                          <div class="menu-item-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity"><polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline></svg>
                          </div>
                          <span className="menu-item-text">SEO Health</span>
                          <span class="icon">
                            {healthExpand ? (
                              <i class="fa fa-angle-up"></i>
                            ) : (

                              <i class="fa fa-angle-down"></i>
                            )}
                          </span>
                        </a>
                      </button>
                    </li>
                  ) : (
                    <></>
                  )}
                  {healthExpand ? (
                    <div className="mob-mod menu-submenu">
                      {healthMenuList?.map((item, key) => (
                        <li
                          onClick={() => clodeSideNav()}
                          key={item.name}
                          className="menu-item"
                        >
                          <NavLink to={item.link} className="menu-item-link">
                            <div className="menu-item-icon">
                            </div>
                            <span className="menu-item-text">
                              {moduleName[item.name]}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                    </div>
                  ) : (
                    <div style={{ marginTop: "0" }}></div>
                  )}

                  {getCheckPermissionByDisplayName("Tickets") ?
                    (
                      <li className="menu-item">
                        <button class="menu-item-link menu-item-toggle">
                          <a
                            href="/ticket/dashboard"
                            target="_blank"
                            className="menu-item-link p-0 w-100"
                          >
                            <div class="menu-item-icon">
                              <svg className="t-icon-white" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_5031_5584)">
                                  <path d="M16.6665 10.0007C16.6665 9.36732 17.0248 8.81732 17.5498 8.53398C18.0498 8.25898 18.3332 7.69232 18.3332 7.11732V5.00065C18.3332 4.08398 17.5832 3.33398 16.6665 3.33398H3.33317C2.4165 3.33398 1.67484 4.07565 1.67484 4.99232V7.11732C1.67484 7.69232 1.94984 8.25898 2.45817 8.52565C2.98317 8.81732 3.33317 9.36732 3.33317 10.0007C3.33317 10.634 2.97484 11.1923 2.44984 11.4673C1.94984 11.7423 1.6665 12.309 1.6665 12.884V15.0007C1.6665 15.9173 2.4165 16.6673 3.33317 16.6673H16.6665C17.5832 16.6673 18.3332 15.9173 18.3332 15.0007V12.884C18.3332 12.309 18.0498 11.7423 17.5498 11.4673C17.0248 11.184 16.6665 10.634 16.6665 10.0007ZM12.0832 13.4173L9.99984 12.084L7.9165 13.4257C7.59984 13.6257 7.1915 13.334 7.2915 12.9673L7.9165 10.5673L5.99984 9.00065C5.70817 8.75898 5.85817 8.28398 6.2415 8.25898L8.70817 8.11732L9.60817 5.82565C9.74984 5.47565 10.2498 5.47565 10.3832 5.82565L11.2832 8.12565L13.7498 8.26732C14.1248 8.29232 14.2832 8.76732 13.9915 9.00898L12.0748 10.5757L12.7082 12.959C12.8082 13.334 12.3998 13.6257 12.0832 13.4173Z" fill="white" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5031_5584">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                              <svg className="t-icon-dark" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_5031_14180)">
                                  <path d="M16.9409 10.0007C16.9409 9.36732 17.2981 8.81732 17.8213 8.53398C18.3197 8.25898 18.6021 7.69232 18.6021 7.11732V5.00065C18.6021 4.08398 17.8546 3.33398 16.9409 3.33398H3.65186C2.73823 3.33398 1.99903 4.07565 1.99903 4.99232V7.11732C1.99903 7.69232 2.27312 8.25898 2.77976 8.52565C3.30302 8.81732 3.65186 9.36732 3.65186 10.0007C3.65186 10.634 3.29471 11.1923 2.77146 11.4673C2.27312 11.7423 1.99072 12.309 1.99072 12.884V15.0007C1.99072 15.9173 2.73823 16.6673 3.65186 16.6673H16.9409C17.8546 16.6673 18.6021 15.9173 18.6021 15.0007V12.884C18.6021 12.309 18.3197 11.7423 17.8213 11.4673C17.2981 11.184 16.9409 10.634 16.9409 10.0007ZM12.3728 13.4173L10.2964 12.084L8.21997 13.4257C7.90436 13.6257 7.49738 13.334 7.59705 12.9673L8.21997 10.5673L6.30967 9.00065C6.01897 8.75898 6.16847 8.28398 6.55054 8.25898L9.00901 8.11732L9.90603 5.82565C10.0472 5.47565 10.5456 5.47565 10.6785 5.82565L11.5755 8.12565L14.0339 8.26732C14.4077 8.29232 14.5655 8.76732 14.2748 9.00898L12.3645 10.5757L12.9957 12.959C13.0954 13.334 12.6884 13.6257 12.3728 13.4173Z" fill="#757575" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_5031_14180">
                                    <rect width="19.9336" height="20" fill="white" transform="translate(0.32959)" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </div>
                            <span className="menu-item-text">Tickets</span>
                            <span class="icon">
                              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="view-ticket me-0 ">
                                <path d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                <path d="M12.5 2.5H17.5V7.5" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                <path d="M8.33398 11.6667L17.5007 2.5" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                              </svg>
                            </span>
                          </a>
                        </button>
                      </li>
                    ) : (<></>)}


                  <li
                    onClick={() => clodeSideNav()}
                    key="configuration"
                    className="menu-item"
                  >
                    {/* {localStorage.getItem("userRole") != "Customer" ? (<> */}
                    {getCheckPermissionByDisplayName("Bandwidth Monitoring") ? (<>
                      <NavLink
                        to="/main/bandwidth"
                        className="menu-item-link bandwidth-link"
                      >
                        <div className="menu-item-icon band-icon">
                          <img
                            class="band-img"
                            src="../../../images/band-icon.png"
                            alt=""
                          />
                        </div>
                        <span className="menu-item-text">Bandwidth Monitoring</span>
                      </NavLink>
                    </>) : (<></>)}

                  </li>
                  {researchMenuList.length != 0 ? (
                    <li className="menu-item">
                      <button class="menu-item-link menu-item-toggle">
                        <a
                          className="menu-item-link p-0 w-100"
                          onClick={() => {
                            setResearchExpand(!researchExpand);
                          }}
                        >
                          <div class="menu-item-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                          </div>
                          <span className="menu-item-text">Research</span>
                          <span class="icon">
                            {researchExpand ? (
                              <i class="fa fa-angle-up"></i>

                            ) : (
                              <i class="fa fa-angle-down"></i>
                            )}
                          </span>
                        </a>
                      </button>
                    </li>
                  ) : (
                    <></>
                  )}
                  {researchExpand ? (
                    <div className="mob-mod menu-submenu">
                      {researchMenuList?.map((item, key) => (
                        <li
                          onClick={() => clodeSideNav()}
                          key={item.name}
                          className="menu-item"
                        >
                          <NavLink to={item.link} className="menu-item-link">
                            <div className="menu-item-icon">
                            </div>
                            <span className="menu-item-text">
                              {moduleName[item.name]}
                            </span>
                          </NavLink>
                        </li>
                      ))}
                    </div>
                  ) : (
                    <div style={{ marginTop: "0" }}></div>
                  )}
                  <li
                    onClick={() => clodeSideNav()}
                    key="configuration"
                    className="menu-item"
                  >
                    {localStorage.getItem("userRole") != "Customer" ? (<>
                      <NavLink
                        to="/main/configuration"
                        className="menu-item-link"
                      >
                        <div className="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sliders"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                        </div>
                        <span className="menu-item-text">Configuration</span>
                      </NavLink>
                    </>) : (<></>)}

                  </li>
                  <li
                    onClick={() => clodeSideNav()}
                    key="configuration"
                    className="menu-item"
                  >
                    {localStorage.getItem("userRole") != "Customer" ? (<>
                      <NavLink
                        to="/main/serptracker"
                        className="menu-item-link"
                      >
                        <div className="menu-item-icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-sliders"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                        </div>
                        <span className="menu-item-text">SERPTracker</span>
                      </NavLink>
                    </>) : (<></>)}

                  </li>
                </ul>
              </>
            </div>
          </div>
        </div>
        <div className="holder">
          <div className="wrapper">
            <TopNavBarCustom
              view={view}
              routing={ticketsMenuList} />

            <div className={`content ${location.pathname == '/main/configuration' ? 'height-hiddenn overflow-hiddenn' : ''}`}>
              <div className="container-fluid">
                <Switch>
                  {routes.map((route, i) => (
                    <RouteWithSubRoutes key={i} {...route} />
                  ))}
                </Switch>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        className="search-team-member-modal filter-closed"
        placement="right"
        onClose={ticketviewclose}
        open={createTicketsviewopen}>
        <CreateNewTicket isModel={true} closeModel={() => {
          ticketviewclose()
        }} />
      </Drawer >
    </>
  );
};

export default Main;
