import { Table } from 'antd';
import React from 'react'
import { useState } from 'react';
import APIManager from "../services/APIManager";
import { getQueryData } from '../utility/Constant';
import { useEffect } from 'react';

function QueryExecutor() {

    const [query, setQuery] = useState("");
    const [dataSource, setDataSource] = useState([]);
    const [columns, setColumns] = useState([]);
    const [permission, setPermission] = useState(true);

    useEffect(() => {
        let value = localStorage.getItem("userDatadepartmentName");
        if (value == "Product") {
            setPermission(true)
        }
        else {
            setPermission(false)
        }
    }, [])

    function handleClickQueryExecute() {
        APIManager.postAPI(getQueryData, { Query: query }, null, true).then(
            (response) => {
                if (response?.status === 200) {
                    let columns = [];

                    let keys = Object.keys(response?.data[0]);
                    keys.map((i) => {
                        columns.push({
                            title: i,
                            dataIndex: i,
                            key: i,
                            width: "20px"
                        })
                    })

                    setColumns(columns);
                    setDataSource(response.data);
                }
            }
        );
    }

    return (
        <>
            {permission ?
                (
                    <div className="row">
                        <div className="col-sm-12" id="sql-page">
                            <div className="portlet border rounded">
                                <div className="portlet-header portlet-header-bordered">
                                    <h5>SQL Query Executor</h5>
                                </div>
                                <div className="portlet-body">
                                    <textarea className="w-100" placeholder="Enter SQL Query" cols="80" rows="8"
                                        onChange={(e) => {
                                            setQuery(e.target.value);
                                        }}
                                    ></textarea>
                                    <button className='btn btn-primary mt-4'
                                        onClick={() => {
                                            handleClickQueryExecute();
                                        }}
                                    >Execute Query</button>

                                    <div className="custom-query mt-4">
                                        <h5 className="mb-4">Query Result</h5>
                                        <Table dataSource={dataSource} columns={columns} />;
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                ) :
                (
                    <>
                    <div class="message text-center">
                      <h4>
                      {localStorage.getItem("userName")} ,You don't have access permission!
                      </h4>
                    </div>
                  </>                    
                )}
        </>
    )
}

export default QueryExecutor