import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { useHistory, useLocation } from "react-router-dom";
import APIManager from "../../services/APIManager";
import { default as ReactSelect } from "react-select";
import {
  createTickets,
  getClientByOrgId,
  getConfigData,
  getDepartmentById,
  getMemberByDepartmentId,
  getMemberByOrgId,
  getmemberbyproject,
  getProjectByClientId,
  updateticket,
} from "../../utility/Constant";
import {
  creattypes,
  mmDDyyyyFormateDateSet,
} from "../../utility/Globals";
import { toast } from "react-hot-toast";
function CreateNewTicket(props) {
  const [description, setdescription] = useState("");
  const [subject, setsubjects] = useState("");
  const [ETA, setETA] = useState("");
  const [ticket, setticket] = useState("0");
  const [prioriry, setprioriry] = useState("");
  const [selectedprioriry, setselectedprioriry] = useState("0");
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);
  const location2 = useLocation();
  const [memberlist, setmemberlist] = useState([]);
  const [selectedMember, setSelectedMember] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [clientlist, setclientlist] = useState([]);
  const [clientseleted, setclientseleted] = useState("");
  const [projectlist, setprojectlist] = useState([]);
  const [selectpoject, setselectpoject] = useState("");
  const [status, setstatus] = useState("");
  const [createdBy, setcreartedBy] = useState("");
  const notifyAdd = () => toast.success(" Create Ticket Successfully ");

  const CreateStyles = {
    control: base => ({
      ...base,
      boxShadow: 'none',
      height: 43,
      fontSize: '1.1rem',

    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '43px',
      padding: '0 12px',

    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#606060"
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",

    }),
    menuList: styles => ({
      ...styles,
      Padding: '0',
      Margin: '0'
    }),
  };

  function Tickettype(e) {
    let value = e.target.value;
    setticket(value);
    setErrors("");
  }
  function prioritytype(e) {
    let value = e.target.value;
    setselectedprioriry(value);
  }

  let history = useHistory();

  useEffect(() => {
    if (location2.state?.isUpdate) {
      setETA(mmDDyyyyFormateDateSet(location2?.state?.ETA));
      setsubjects(location2?.state?.Subject);
      setticket(location2?.state?.TicketTypeId);
      setselectedprioriry(location2?.state?.PriorityId);
      setdescription(location2?.state?.Description);
      setstatus(location2?.state?.status);
      setcreartedBy(location2?.state?.createdby);
      setclientseleted(location2?.state?.ClientId);
      setselectpoject(location2?.state?.projectId)
    } else {
    }

    getClientlist();
    getDepartmentData();
  }, []);

  const Prioritylist = [
    {
      value: "0",
      lable: "Low",
    },
    {
      value: "1",
      lable: "Medium",
    },
    {
      value: "2",
      lable: "High",
    },
  ];


  const [ClientsProjectChosen, setClientsProjectChosen] = useState([]);

  useEffect(() => { }, [ClientsProjectChosen]);

  const [departmentlist, setDepartmentlist] = useState([]);

  function formValidation() {

    let error = { ...errors };
    subject.trim() == ""
      ? (error["subject"] = "Subject is required!")
      : delete error["subject"];

    ETA.trim() == ""
      ? (error["ETA"] = "ETA is required!")
      : delete error["ETA"];
    mmDDyyyyFormateDateSet(ETA.trim()) < mmDDyyyyFormateDateSet(new Date())
      ? (error["ETA"] = "ETA is not vaild!")
      : delete error["ETA"];

    if (ticket == 2) {
      clientseleted.trim() == ""
        ? (error["clientseleted"] = "Client is required!")
        : delete error["clientseleted"];

      selectpoject.trim() == ""
        ? (error["selectpoject"] = "Project is required!")
        : delete error["selectpoject"];
    }

    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setsubjects("");
    setdescription("");
    setprioriry("");
    setticket("");
    setETA("");
    setclientseleted("");
    setselectpoject("");
    setSelectedMember("");
    setSelectedDepartment("");
    setselectedprioriry("");
  }

  function createNewTicketData() {
    let rowData = {
      ClientId: clientseleted,
      ProjectId: selectpoject,
      Subject: subject,
      Description: description,
      TicketTypeId: ticket,
      ETA: ETA,
      PriorityId: selectedprioriry,
      DepartmentId: selectedDepartment,
      AssignedTo: selectedMember,
      createdby: localStorage.getItem("userId"),
    };

    APIManager.postAPI(createTickets, rowData, null, true).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        notifyAdd();
        if (props?.isModel) {
          props?.closeModel();
        }
        history.push("/ticket/personal-tickets");
      }
    });
  }

  function updateTickets() {
    APIManager.putAPI(
      updateticket,
      {
        id: location2.state.id,
        ClientId: clientseleted,
        ProjectId: selectpoject,
        Subject: subject,
        Description: description,
        TicketTypeId: ticket,
        ETA: ETA,
        PriorityId: selectedprioriry,
        DepartmentId: selectedDepartment,
        AssignedTo: selectedMember,
        status: status,
        createdby: createdBy,
      },
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        clearForm();
        if (props?.isModel) {
          props?.closeModel();
        }
        history.push("/ticket/personal-tickets");
      }
    });
  }

  function getClientlist() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setclientlist(list);
      }
    });
  }

  function getprojetclistclientwise(clientid) {
    if (clientid) {
      APIManager.getAPI(getProjectByClientId + clientid).then((response) => {
        if (response && response.status == 200) {
          const list = [];
          response.data.map((item) => {
            list.push({
              id: item.id,
              projectCode: item.projectCode,
            });
          });
          setprojectlist(list);
        }
      });
    }
  }


  function getDepartmentData() {
    APIManager.getAPI(
      getDepartmentById + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          id: "",
          name: "Select Department",
          key: "",
          value: "",
          label: "Select Department",
        });
        response.data.map((item) => {
          let obj = {
            id: item.id,
            role: item.name,
            key: item.id,
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        setDepartmentlist(list);
        getMemberlist(location2?.state?.DepartmentId ?? list[0].id);
        setSelectedDepartment(location2?.state?.DepartmentId ?? list[0].id);
      }
    });
  }

  function getMemberlist(departmentId) {

    if (departmentId) {
      APIManager.getAPI(getMemberByDepartmentId + departmentId).then(
        (response) => {
          if (response && response.status == 200) {
            const list = [];
            response.data.map((item) => {
              list.push({
                id: item.id,
                role: item.name,
                key: item.id,
                value: item.id,
                label: item.name,
              });
            });
            setmemberlist(list);
            setSelectedMember(location2?.state?.AssignedTo ?? list[0].id);
          }
        }
      );
    }
    else {
      APIManager.getAPI(getMemberByOrgId + localStorage.getItem("organizationId")).then(
        (response) => {
          if (response && response.status == 200) {
            const list = [];
            list.push({
              id: "",
              name: "Select Member",
              key: "",
              value: "",
              label: "Select Member",
            });
            response.data.map((item) => {
              list.push({
                id: item.id,
                role: item.name,
                key: item.id,
                value: item.id,
                label: item.name,
              });
            });
            setmemberlist(list);
          }
        }
      );
    }
  }
  function getprojetclistbyMember(depmentid, projectid) {

    APIManager.getAPI(getmemberbyproject + "?projectId=" + projectid + "&departmentId=" + depmentid).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          list.push({
            id: item.memberId,
            role: item.name,
            key: item.memberId,
            value: item.memberId,
            label: item.name,
          });
        });
        setmemberlist(list);
        setSelectedMember(location2?.state?.AssignedTo ?? list[0].id);
      }
    });

  }
  return (
    <>
      <div className="portlet border-0 px-0">
        <div class="portlet-header portlet-header-bordered  b-border-dark mb-0">
          <h3 class="portlet-title main-title ps-2">Create Ticket</h3>
        </div>
        <div className="portlet-body px-0">
          <div class="common-wrapper">
            <div class="common-wcard">
              <div class="common-form-fields create-ticket-form">
                <div class="add-user" style={{ width: 100 + "%" }}>
                  <div class="container create-new-ticket">
                    <div class="col-md-12">
                      <label className="form-label">Type</label>
                      <select
                        class=" w-100 form-select"
                        aria-label="Default select example"
                        value={ticket}
                        onChange={Tickettype}
                      >
                        {creattypes.map((item) => {
                          return <option value={item.value}>{item.label}</option>;
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-4">
                      <label className="form-label">Client</label>
                      {ticket == 2 ? (<label class="field-required"> * </label>) : (<></>)}
                      <select
                        class=" w-100 form-select"
                        aria-label="Default select example"
                        value={clientseleted}
                        onChange={(e) => {
                          setclientseleted(e.target.value);
                          formValidation();
                          getprojetclistclientwise(e.target.value);
                        }}
                      >
                        <option value="">Select Client</option>;
                        {clientlist.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                      {save && errors?.clientseleted && (
                        <>
                          <div className="field-required ms-3 mt-2">
                            <span> {errors?.clientseleted}</span>
                          </div>
                        </>
                      )}
                    </div>

                    <div class="col-md-12 mt-4">
                      <label className="form-label">Project</label>
                      {ticket == 2 ? (<label class="field-required"> * </label>) : (<></>)}
                      <select
                        class=" w-100 form-select"
                        aria-label="Default select example"
                        value={selectpoject}
                        onChange={(e) => {
                          setselectpoject(e.target.value);
                          formValidation();
                          if (selectedDepartment) {
                            getprojetclistbyMember(selectedDepartment, e.target.value)
                          }
                        }}
                      >
                        <option value="">Select Project</option>;
                        {projectlist.map((item) => {
                          return (
                            <option value={item.id}>{item.projectCode}</option>
                          );
                        })}
                      </select>
                      {save && errors?.selectpoject && (
                        <>
                          <div className="field-required ms-3 mt-2">
                            <span> {errors?.selectpoject}</span>
                          </div>
                        </>
                      )}
                    </div>

                    <div class="col-md-12 mt-4">
                      <label className="form-label">Subject{save}</label>
                      <label class="field-required"> * </label>
                      <input
                        type="text"
                        className="form-control form-control-lg text-capitalize"
                        placeholder="Enter Subject"
                        required
                        value={subject}
                        onChange={(text) => {
                          setsubjects(text.target.value);
                          formValidation();
                        }}
                      />
                    </div>
                    {save && errors?.subject && (
                      <>
                        <div className="field-required ms-3 mt-2">
                          <span> {errors?.subject}</span>
                        </div>
                      </>
                    )}

                    <div class="col-md-12 mt-4">
                      <label className="form-label">Description{save}</label>
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        placeholder="Enter Description"
                        required
                        maxLength={1000}
                        value={description}
                        onChange={(text) => {
                          setdescription(text.target.value);
                        }}
                      />
                    </div>
                    {save && errors?.description && (
                      <>
                        <div className="field-required ms-3 mt-2">
                          <span> {errors?.description}</span>
                        </div>
                      </>
                    )}

                    <div class="col-md-12 mt-4">
                      <label className="form-label">ETA</label>
                      <label class="field-required"> * </label>
                      <input
                        type="date"
                        name="ETA"
                        min={mmDDyyyyFormateDateSet(new Date())}
                        onBlur={() => {
                          formValidation();
                        }}

                        class="ticket-date w-100"
                        value={ETA}
                        onChange={(text) => {
                          setETA(text.target.value);
                          formValidation();
                        }}
                      />
                    </div>
                    {save && errors?.ETA && (
                      <>
                        <div className="field-required ms-3 mt-2">
                          <span> {errors?.ETA}</span>
                        </div>
                      </>
                    )}

                    <div class="col-md-12 mt-4">
                      <label className="form-label">Priority</label>
                      <select
                        class=" w-100 form-select"
                        aria-label="Default select example"
                        value={selectedprioriry}
                        onChange={prioritytype}
                      >
                        {Prioritylist.map((item) => {
                          return <option value={item.value}>{item.lable}</option>;
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-4">
                      <label className="form-label">Department</label>
                      <ReactSelect
                        className="create-ticket-style"
                        isMulti={false}
                        styles={CreateStyles}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        options={departmentlist}
                        value={departmentlist?.map(x => {
                          if (x.id == selectedDepartment) {
                            return x;
                          }
                        })}
                        onChange={(e) => {
                          setSelectedDepartment(e.value);
                          if (selectpoject) {
                            getprojetclistbyMember(e.value, selectpoject)
                          }
                          else {
                            getMemberlist(e.value);
                            setSelectedMember(e.value);
                          }
                        }}
                      >
                      </ReactSelect>
                    </div>

                    <div class="col-md-12 mt-4">
                      <label className="form-label">Assigned to</label>
                      <ReactSelect
                        className="create-ticket-style"
                        isMulti={false}
                        styles={CreateStyles}
                        components={{
                          IndicatorSeparator: () => null
                        }}
                        closeMenuOnSelect={true}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                        options={memberlist}
                        value={memberlist?.map(x => {
                          if (x.id == selectedMember) {
                            return x;
                          }
                        })}
                        onChange={(item) => {
                          setSelectedMember(item.value);
                        }}
                      >
                      </ReactSelect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered  mt-4 pt-4 create-ticket-btns 
        b-border-dark position-relative">
          <div class="col-md-12 text-end">
            <button
              className="btn btn-outline-secondary me-3"
              onClick={() => {
                clearForm();
                setErrors("");
                if (props?.isModel) {
                  props?.closeModel();
                }
                else {
                  history.goBack();
                }
              }}
            >
              Cancel
            </button>
            <button
              class="btn btn-primary w-auto"
              onClick={() => {
                setsave(true);
                if (formValidation()) {
                  location2.state?.isUpdate
                    ? updateTickets()
                    : createNewTicketData();
                }
              }}
            >
              {location2.state?.isUpdate ? "Update" : "Create Ticket"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateNewTicket;
