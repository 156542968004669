import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Calendar } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import { ProgressBar } from "react-bootstrap";
import { convertDate, formateDateIntoDatemonth, getDaysInMonth, setMonths } from "../../utility/Globals";
import APIManager from "../../services/APIManager";
import { requirementProject, getallpoc, updateProjectPoc } from "../../utility/Constant";
import { useHistory } from "react-router-dom";


function RequirementContent({ }) {

    var isFirefox = typeof InstallTrigger !== 'undefined';
    const history = useHistory();
    const [requirement, setRequirement] = useState("2");
    const [open, setOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [head1, setHead1] = useState(
        new Date((new Date().getMonth() + 1) + "-01-" +
            (new Date().getFullYear())).toDateString());
    const [head2, setHead2] = useState(
        new Date((new Date().getMonth() + 1) + "-" +
            getDaysInMonth(new Date().getFullYear(), new Date().getMonth() + 1) + "-" +
            (new Date().getFullYear())).toDateString());
    const [monthYear, setMonthYear] = useState(setMonths(new Date().getMonth() + 1) + " " + new Date().getFullYear());

    const [list, setList] = useState([]);
    const [list1, setList1] = useState([]);
    const [filterList, setFilterList] = useState([]);
    const [poclist, setPocList] = useState([]);


    useEffect(() => {
        getProjectList(requirement);
        getPoc();
    }, []);


    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
                setIsMenuOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };

    }, [isMenuOpen]);

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            if (open && ref.current && !ref.current.contains(e.target)) {
                setOpen(false);
            }
        };
        document.addEventListener("mousedown", checkIfClickedOutside);
        return () => {
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [open]);

    const getProjectList = (req) => {
        if (req === "1") {
            APIManager.postAPI(requirementProject + "?startDate=" + convertDate(head1) + "&endDate=" + convertDate(head2) + "&isAll=true").then((res) => {
                setList1(res.data);
                setList([]);
                setFilterList(res.data);
            });
        }
        else {
            if (localStorage.getItem('userId') == null) {
                setList([]);
                setFilterList([]);
            }
            else {

                APIManager.postAPI(requirementProject + "?userId=" + localStorage.getItem('userId') + "&startDate=" + convertDate(head1) + "&endDate=" + convertDate(head2) + "&isAll=false").then((res) => {
                    setList(res.data);
                    setList1([]);
                    setFilterList(res.data);
                });
            }
        }
    }

    const seachProject = (name) => {
        if (list.length > 0) {
            let searchList = list.filter((item) => { return item.projectName.toLocaleLowerCase().includes(name.toLocaleLowerCase()) });

            if (searchList.length > 0)
                setFilterList(searchList);
            else
                setFilterList(list.filter((item) => { return item.niche?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) }));

        }
        else if (list1.length > 0) {

            let searchList = list1.filter((item) => { return item.projectName.toLocaleLowerCase().includes(name.toLocaleLowerCase()) });

            if (searchList.length > 0)
                setFilterList(searchList);
            else
                setFilterList(list1.filter((item) => { return item.niche?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) }));

        }
    }

    function getPoc() {

        APIManager.getAPI(
            getallpoc

        ).then((response) => {
            if (response.status == 200) {
                let list = [];
                response.data.map((item) => {
                    list.push({
                        value: item.id,
                        label: item.name,
                    });
                })
                setPocList(list);
            }
        });
    }

    const updatePoc = (id, projectID) => {

        let rawData = {
            ProjectId: projectID,
            PocId: id
        }
        APIManager.putAPI(updateProjectPoc, rawData, {}, true).then((response) => {
            if (response.status == 200) {
                getProjectList(requirement);
            }
        });
    }

    function clearForm() {
        document.getElementById("search").value = "";
    }

    return (
        <>

            {/* <h1>Requirement</h1> */}
            <Tabs>
                {/* <div className="portlet"> */}
                <div className="portlet requirement-content portlet-header portlet-header-bordered px-0">
                    <div className="col-md-12 col-sm-12">
                        <TabList className="nav nav-lines portlet-nav">
                            <Tab
                                className={
                                    (requirement === "2")
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                                onClick={() => {
                                    setRequirement("2");
                                    getProjectList("2");
                                    clearForm();
                                }}
                            >
                                My Projects
                            </Tab>
                            <Tab
                                className={
                                    (requirement === "1")
                                        ? "nav-item nav-link active"
                                        : "nav-item nav-link"
                                }
                                onClick={() => {
                                    setRequirement("1");
                                    getProjectList("1");
                                    clearForm();
                                }}
                            >
                                All Projects
                            </Tab>
                        </TabList>
                    </div>
                </div>


                <div class="all-ticket-box mt-3">
                    <div class="row px-0">
                        <div className="d-flex  align-items-center position-relative px-0">

                            <div class="sow-search-box require-search-box d-flex  w-100 me-3 position-relative">
                                <i class="fa fa-search"></i>
                                <input
                                    type="text"
                                    class="scope-input w-100"
                                    placeholder="Search"
                                    name="search"
                                    id="search"
                                    autoComplete="false"
                                    onChange={(e) => {
                                        seachProject(e.target.value);
                                    }}
                                />
                            </div>
                            <div ref={ref} className="d-flex align-items-center require-cal" >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15"
                                    height="15"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-calendar me-2"
                                >
                                    <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                    <line x1="16" y1="2" x2="16" y2="6"></line>
                                    <line x1="8" y1="2" x2="8" y2="6"></line>
                                    <line x1="3" y1="10" x2="21" y2="10"></line>
                                </svg>
                                <div
                                    onClick={() => setOpen(!open)}
                                    style={{ width: "max-content" }}
                                >
                                    {monthYear}
                                </div>

                                {open && (

                                    <div>
                                        <div id="example-collapse-text-calendar">
                                            <Card body className="daterange-picker-card  mt-2">
                                                <div className="d-flex calendar-flex">
                                                    <div className="calendar-cols">
                                                        <Calendar
                                                            date={new Date()}
                                                            onlyMonthPicker
                                                            onChange={(e) => {

                                                                var date1 = e.format().split("/");
                                                                var daysInMonth = getDaysInMonth(
                                                                    date1[1],
                                                                    date1[0]
                                                                );
                                                                let dateObjArr = [
                                                                    new Date(date1[0] + "/01/" + date1[1]),
                                                                    new Date(
                                                                        date1[0] +
                                                                        "/" +
                                                                        daysInMonth +
                                                                        "/" +
                                                                        date1[1]
                                                                    ),
                                                                ];
                                                                setMonthYear(setMonths(dateObjArr[0].getMonth() + 1) + " " + date1[1]);
                                                                setHead1(
                                                                    dateObjArr[0].toLocaleDateString()
                                                                );
                                                                setHead2(
                                                                    dateObjArr[1].toLocaleDateString()
                                                                );
                                                            }}

                                                        />
                                                    </div>

                                                    <div className="mt-3 mt-lg-0 text-center">
                                                        <button
                                                            onClick={() => {
                                                                setOpen(!open);
                                                                getProjectList(requirement);
                                                            }}
                                                            className="btn btn-primary ms-3"
                                                        >
                                                            Apply
                                                        </button>
                                                    </div>
                                                </div>
                                            </Card>
                                        </div>
                                    </div>
                                )}

<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></svg>
                            </div>

                        </div>
                    </div>
                </div>

                <TabPanel>
                    <div class="align-items-center">
                        <div className="row html2pdf__page-break all-channel-table requirementcontent-box">
                            <div className="d-flex px-0">
                                <div className="row allchannel-table">
                                    {filterList.map((item) => {
                                        return (<>
                                            <div className="col-sm-6 mt-3 p-0 require-box">
                                                <div className="requirement">
                                                    <div onClick={() => {
                                                        history.push({
                                                            pathname: '/content-main/requirement-details',
                                                            state: { projectId: item.projectId, startDate: head1, endDate: head2, projectname: item.projectName, poc: item.pocId}
                                                        });
                                                    }}>
                                                        <div className="d-flex align-items-center justify-content-between article-btn mb-3 px-3">
                                                            <div className="d-flex align-items-center">
                                                                <h3 className="my-1">{item.projectName}</h3>
                                                                {item.niche ?
                                                                    <button
                                                                        className="requirement-site-btn ml-2"
                                                                        type="button"
                                                                    >
                                                                        {item.niche}
                                                                    </button>
                                                                    : ''}
                                                            </div>
                                                            <div className="d-flex align-items-center">

                                                                <button class="words-article-btn">{item['words/article']} Words/Article </button>
                                                            </div>
                                                        </div>

                                                        <div className="requirement-border-bottom"></div>
                                                        <div className="px-3 pb-1">
                                                            <div className="d-flex align-items-center justify-content-between requirement-complete my-2">
                                                                <span><h5 className="mb-0">Completed : {item.status ?? 0}/10</h5></span>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="align-items-center">
                                                                        {item.status != 0 ?
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                            :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FC3D39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                        }
                                                                    </div>
                                                                    {item.turnAroundTime != 0 ?
                                                                        <>
                                                                            <h6 className="mb-0 ms-1 me-1">Turn around : {formateDateIntoDatemonth(item.turnAroundTime)}</h6>
                                                                            <div className="align-items-center">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    // width="24"
                                                                                    // height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    class="feather feather-edit edit-icon"
                                                                                >
                                                                                    {/* <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path> */}
                                                                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                                                </svg>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <h6 className="red-require-msg ms-1 mb-0">Requirement yet to be raised </h6>}
                                                                </div>

                                                            </div>

                                                            <ProgressBar className="d-flex mb-2 requirement-progreesbar">
                                                                <ProgressBar
                                                                    now={item.status * 10}
                                                                    key={1}
                                                                />
                                                            </ProgressBar>
                                                        </div>
                                                        <div className="requirement-border-bottom mb-2"></div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center point-contact pt-1 px-3">
                                                        <h5 className="mt-2">Point of Contact</h5>
                                                        <select className="form-select text-center" id="optsMain1" value={item.pocId}
                                                            onChange={(e) => {
                                                                updatePoc(e.target.value, item.projectId);
                                                                let ghostSelect = document.createElement('select');
                                                                const select = document.getElementById('optsMain1');
                                                                var x = select.options[select.selectedIndex].text;

                                                                const ghostOption = document.createElement("option");
                                                                ghostOption.setAttribute("value", x);
                                                                var t = document.createTextNode(x);
                                                                ghostOption.appendChild(t);
                                                                ghostSelect.appendChild(ghostOption);
                                                                window.document.body.appendChild(ghostSelect)
                                                                select.style.width = ghostSelect.offsetWidth + 'px';
                                                                window.document.body.removeChild(ghostSelect)
                                                            }}>
                                                            <option value="">--Select--</option>
                                                            {poclist.map((poc) => {

                                                                return (
                                                                    <option value={poc.value}>{poc.label}</option>);
                                                            })}

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>


                {/* Start All project */}
                <TabPanel>
                    <div class="align-items-center">
                        <div className="row html2pdf__page-break all-channel-table requirementcontent-box">
                            <div className="d-flex px-0">
                                <div className="row allchannel-table">
                                    {filterList.map((item) => {
                                        return (<>
                                            <div className="col-sm-6 mt-3 p-0 require-box" >
                                                <div className="requirement">
                                                    <div onClick={() => {
                                                        history.push({
                                                            pathname: '/content-main/requirement-details',
                                                            state: { projectId: item.projectId, startDate: head1, endDate: head2, projectname: item.projectName, poc: item.pocId}
                                                        });
                                                    }}>
                                                        <div className="d-flex align-items-center justify-content-between article-btn mb-3 px-3">
                                                            <div className="d-flex align-items-center">
                                                                <h3 className="my-1">{item.projectName}</h3>
                                                                {item.niche ?
                                                                    <button
                                                                        className="requirement-site-btn ml-2"
                                                                        type="button"
                                                                    >
                                                                        {item.niche ?? ''}
                                                                    </button>
                                                                    : ''}
                                                            </div>
                                                            <div className="d-flex align-items-center">

                                                                <button class="words-article-btn">{item['words/article']} Words/Article </button>
                                                            </div>
                                                        </div>

                                                        <div className="requirement-border-bottom"></div>

                                                        <div className="px-3 pb-1">
                                                            <div className="d-flex align-items-center justify-content-between requirement-complete my-2">
                                                                <span><h5 className="mb-0">Completed : {item.status ?? 0}/10</h5></span>
                                                                <div className="d-flex align-items-center">
                                                                    <div className="align-items-center">
                                                                        {item.status != 0 ?
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                            :
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#FC3D39" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clock"><circle cx="12" cy="12" r="10"></circle><polyline points="12 6 12 12 16 14"></polyline></svg>
                                                                        }
                                                                    </div>
                                                                    {item.status != 0 ?
                                                                        <>
                                                                            <h6 className="mb-0 ms-1 me-1">Turn around : {formateDateIntoDatemonth(item.turnAroundTime)}</h6>
                                                                            <div className="align-items-center">
                                                                                <svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    // width="24"
                                                                                    // height="24"
                                                                                    viewBox="0 0 24 24"
                                                                                    fill="none"
                                                                                    stroke="currentColor"
                                                                                    stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    class="feather feather-edit edit-icon"
                                                                                >
                                                                                    {/* <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path> */}
                                                                                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                                                                </svg>
                                                                            </div>
                                                                        </> :
                                                                        <h6 className="red-require-msg ms-1 mb-0">Requirement yet to be raised </h6>}

                                                                    <div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <ProgressBar className="d-flex mb-2 requirement-progreesbar">
                                                                <ProgressBar
                                                                    now={item.status * 10}
                                                                    key={1}
                                                                />
                                                            </ProgressBar>
                                                        </div>

                                                        <div className="requirement-border-bottom mb-2"></div>
                                                    </div>
                                                    <div className="d-flex justify-content-between align-items-center point-contact pt-1 px-3">
                                                        <h5 className="mt-2">Point of Contact</h5>
                                                        <select className="form-select text-center" id="optsMain" value={item.pocId}
                                                            onChange={(e) => {
                                                                updatePoc(e.target.value, item.projectId);
                                                                let ghostSelect = document.createElement('select');
                                                                const select = document.getElementById('optsMain');
                                                                var x = select.options[select.selectedIndex].text;

                                                                const ghostOption = document.createElement("option");
                                                                ghostOption.setAttribute("value", x);
                                                                var t = document.createTextNode(x);
                                                                ghostOption.appendChild(t);
                                                                ghostSelect.appendChild(ghostOption);
                                                                window.document.body.appendChild(ghostSelect)
                                                                select.style.width = ghostSelect.offsetWidth + 'px';
                                                                window.document.body.removeChild(ghostSelect)
                                                            }}>
                                                            <option value="">--Select--</option>
                                                            {poclist.map((poc) => {

                                                                return (
                                                                    <option value={poc.value}>{poc.label}</option>);
                                                            })}

                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>

                {/* </div> */}
            </Tabs>
        </>
    )
}
export default RequirementContent;