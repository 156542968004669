import React from "react";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-circular-progressbar/dist/styles.css";
import {
    downloadPDFfile,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import PieChart from "./seoaudit/PieChart";
import HTMLdata from "./seoaudit/HTMLdata";
import DoughnutChart from "./seoaudit/DoughnutChart";
import { Drawer, message, Input, Spin } from "antd";
import "antd/dist/antd.css";
// import {  } from "antd";
import { PDFExport, savePDF } from '@progress/kendo-react-pdf';
import Logo from '../utils/logo.png'
import Content from "./Content";



const GeneratePDF = ({ page, totalPages }) => {


    return (
        <>
           
            <div className="page-template" >
                <div className="header" >
                    <img src={Logo} alt="Infidigit" className="resize" />
                    <div className="doc-hr "></div>
                    <br/>
                </div>
            </div>
        </>       
    );

}


export default GeneratePDF;
