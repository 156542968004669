import { API_BASE_URL, setStartRankApi, SERP_API } from "../utility/Constant";
import axios from "axios";
import {
    getSerplistData,
    uploadDocument,
    downloadFileData
} from "../utility/Constant";
export const getSerpTableData = (postData) => {
    const url = 'serp-api-infidigit.demomywebapp.com/'
    // axios.defaults.headers.common["Authorization"] =
    //     `Bearer ` + localStorage.getItem("token");
    return axios.get(SERP_API + `api/v1/serp/`)
};

export const uploadFile = (postData) => {
    axios.defaults.headers.common["Authorization"] =
        `Bearer ` + localStorage.getItem("token");
    return axios.post(SERP_API + `api/v1/serp/upload_serp`, postData)
};

export const setStartRankScript = (postData) => {
    axios.defaults.headers.common["Authorization"] =
        `Bearer ` + localStorage.getItem("token");
    return axios.post(SERP_API + `api/v1/serp/start_ranking`, postData)
};

export const downloadDocument = (postData) => {
    // console.log(postData, 'test123456');
    // axios.defaults.headers.common["Authorization"] =
    //     `Bearer ` + localStorage.getItem("token");
    window.open(SERP_API +`api/v1/serp/download/${postData}`)
    return axios.get(SERP_API + `api/v1/serp/download/${postData}`)
};