import React, { useEffect, useState, useRef } from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import {
  addBugReport,
  GET_FrontEnd_URL,
  GET_Server_URL,
  imageFileType,
  remainsTimeWhereApiCall,
  userRefreshToken,
} from "../utility/Constant";
import {
  clearUserInformation,
  mmDDyyyyFormateForForm,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import { mySingleton } from "../singleton/LocalDataStore";
import jwt_decode from "jwt-decode";
import { Drawer } from "antd";
import ModelCreateTicket from "./ticket/components/ModelCreateTicket";

function TopNavBarCustom(props) {
  const search = useLocation().pathname;
  const [user, setuser] = useState("");
  const [bugMessage, setBugMessage] = useState("");
  const [bugMessage1, setBugMessage1] = useState([]);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userRole, setuserRole] = useState("");
  const [message, setMessage] = useState("");
  const [timer, setTimer] = useState("00:00:00");
  const location = useLocation();
  const [viewopen, setviewopen] = useState(false);
  const viewshowDrawer = () => {
    setviewopen(true);
  };
  const viewonclose = () => {
    setviewopen(false);
  };
  useEffect(() => {
    setEmail(localStorage.getItem("UserEmail"));
    setName(localStorage.getItem("userName"));
    setuserRole(localStorage.getItem("userRole"));
  });
  const currentUrl = window.location.href;
  console.log(currentUrl, "currentUrkl");

  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",
      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
  };

  const history = useHistory();

  useEffect(() => {
    var u = localStorage.getItem("state");
    var clientProjects = JSON.parse(localStorage.getItem("clientProjects"));
    let list = [];
    let projectsList = [];
    clientProjects?.map((item) => {
      if (item.projects.length > 0) {
        item?.projects.map((i) => {
          projectsList.push({
            projname: i.projectCode,
            id: i.projectId,
          });
        });

        list.push({
          id: item.clientId,
          title: item.clientName,
          projects: projectsList,
        });
      }
      setclientchosen(projectsList);
      projectsList = [];
    });
    if (list.length === 0) {
      if (localStorage.getItem("clientGId") == "undefined") {
        localStorage.setItem("clientGId", list[0].id);
        localStorage.setItem("clientGName", list[0].title);
      } else {
      }
      setprojectslisttop([]);
    } else {
      if (localStorage.getItem("clientGName") != "undefined") {
      } else {
        localStorage.setItem("clientGId", list[0].id);
        localStorage.setItem("clientGName", list[0].title);
      }
      setprojectslisttop(list);
    }
    setuser(u);
  }, []);
  const [breadcrumname, setbreadcrumname] = useState("");
  useEffect(() => {
    let name = "";
    let pathname = location.pathname.split("/");
    pathname = "/" + pathname[1] + "/" + pathname[2];
    switch (pathname) {
      case "/ticket/dashboard":
        name = "Ticket Dashboard";
        break;
      case "/ticket/personal-tickets":
        name = "My Tickets";
        break;
      case "/ticket/team-evaluation":
        name = "Team Evaluation";
        break;
      case "/ticket/view-all-tickets":
        name = "Team Tickets";
        break;
      case "/ticket/ticket-view":
        name = "Ticket Details";
        break;
      case "/ticket/subticket-view":
        name = "Ticket Details";
        break;
      case "/content-main/personalDetails":
        name = "My Profile";
        break;
      case "/content-main/dashboardContent":
        name = "Dashboard";
        break;

      case "/content-main/databaseContent":
        name = "Database";
        break;
      case "/content-main/FreelancerProfile":
        name = "Database";
        break;
      case "/content-main/requirementContent":
        name = "Requirement";
        break;
      case "/content-main/requirement-keyword-details":
        name = "Requirement";
        break;
      case "/content-main/requirement-details":
        name = "Requirement";
        break;
      case "/content-main/editorContent":
        name = "Editor";
        break;
      default:
        break;
    }
    setbreadcrumname(name);
  }, [location.pathname]);
  const inputRef = useRef(null);
  const [sidenav, setsidenav] = useState(false);
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clientchosen, setclientchosen] = useState([]);
  const [projectslisttop, setprojectslisttop] = useState([]);
  const [createTicket, setCreateTicket] = useState(false);
  function showProjects(a) {
    var proj = projectslisttop.filter((item) => item.title == a);
    localStorage.setItem("projectGId", proj[0].projects[0].id);
    localStorage.setItem("projectGName", proj[0].projects[0].projname);
    setclientchosen(proj[0].projects);
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  function handleChangebugReport() {
    if (bugMessage.length == 0) {
      setMessage("Comments are required!");
    } else {
      setMessage("");
      var bodyFormData = new FormData();
      bodyFormData.append("comment", bugMessage);
      bodyFormData.append("pageUrl", search);
      bodyFormData.append("postDate", mmDDyyyyFormateForForm(new Date()));
      bodyFormData.append("userId", localStorage.getItem("userId"));
      bodyFormData.append("status", "0");
      if (bugMessage1.length > 0) {
        bugMessage1.map((i) => {
          bodyFormData.append(`Files`, i);
        });
      }
      APIManager.postAPI(addBugReport, {}, bodyFormData, false).then(
        (response) => {
          if (response?.status == 200) {
            setBugMessage("");
            setBugMessage1([]);
            setFileList([]);
            toast.success("Thanks. Your response has been submitted!");
          }
        }
      );
    }
  }

  const [themee, setThemee] = useState("fa fa-moon");

  useEffect(() => {}, [themee]);

  function appThemeChange() {
    if (themee == "fa fa-moon") {
      localStorage.setItem("theme-variant", "dark");
      setThemee("fa fa-sun");
    } else {
      localStorage.setItem("theme-variant", "light");
      setThemee("fa fa-moon");
    }
    if (document.querySelector("body").classList.contains("theme-light")) {
      document.querySelector("body").classList.remove("theme-light");
      document.querySelector("body").classList.add("theme-dark");
    } else {
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
  }

  const [fileList, setFileList] = useState([]);

  // We need ref in this, because we are dealing
  // with JS setInterval to keep track of it and
  // stop it when needed
  const Ref = useRef(null);

  // The state for our timer
  const getTimeRemaining = (e) => {
    if (localStorage.getItem("token")) {
      let t = localStorage.getItem("token");
      let tokenDecode = jwt_decode(t);
      let diff = (tokenDecode.exp - tokenDecode.nbf) * 1000;
      const total =
        Date.parse(e) -
        Date.parse(new Date()) +
        (diff - 10000 - remainsTimeWhereApiCall);
      const seconds = Math.floor((total / 1000) % 60);
      const minutes = Math.floor((total / 1000 / 60) % 60);
      const hours = Math.floor((total / 1000 / 60 / 60) % 24);
      localStorage.setItem("total", total);
      return {
        total,
        hours,
        minutes,
        seconds,
      };
    }
  };

  const startTimer = (e) => {
    localStorage.setItem("e", e);
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      localStorage.setItem("total", total);
      let time =
        (hours > 9 ? hours : "0" + hours) +
        ":" +
        (minutes > 9 ? minutes : "0" + minutes) +
        ":" +
        (seconds > 9 ? seconds : "0" + seconds);
      setTimer(time);
      mySingleton.getInstance().setTimer(time);
      localStorage.setItem("timer", time);
      if (time == "00:00:00") {
        clearTimer(getDeadTime());
        refreshTokenAPIcall();
      }
    }
  };

  const clearTimer = (e) => {
    // If you adjust it you should also need to
    // adjust the Endtime formula we are about
    // to code next
    setTimer("00:00:00");
    mySingleton.getInstance().setTimer("00:00:00");
    localStorage.setItem("timer", "00:00:00");
    // If you try to remove this line the
    // updating of timer Variable will be
    // after 1000ms or 1sec
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      // startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  function refreshTokenAPIcall() {
    let uId = localStorage.getItem("userId");
    let rToken = localStorage.getItem("refreshToken");
    let subURL = "userId=" + uId + "&refreshToken=" + rToken;
    APIManager.getAPI(userRefreshToken + subURL).then((response) => {
      if (response && response.status == 200) {
        localStorage.setItem("token", response?.data?.token);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
      }
    });
  }

  const getDeadTime = () => {
    let deadline = new Date();
    // This is where you need to adjust if
    // you entend to add more time
    deadline.setSeconds(deadline.getSeconds() + 10);
    return deadline;
  };

  // We can use useEffect so that when the component
  // mount the timer will start as soon as possible

  // We put empty array to act as componentDid
  // mount only
  useEffect(() => {
    if (localStorage.getItem("timer") == "00:00:00") {
      clearTimer(getDeadTime());
    } else {
      if (Ref.current) clearInterval(Ref.current);
      const id = setInterval(() => {
        // startTimer(localStorage.getItem("e"));
      }, 1000);
      Ref.current = id;
    }
  }, []);

  // Another way to call the clearTimer() to start
  // the countdown is via action event from the
  // button first we create function to be called
  // by the button
  const onClickReset = () => {
    clearTimer(getDeadTime());
  };

  return (
    <>
      <div class="header">
        <div
          class="header-holder header-holder-desktop sticky-header"
          id="sticky-header-desktop"
        >
          <div class="header-container container-fluid px-4">
            <div class="header-wrap bar-icon header-holder-mobile ">
              <button
                class="btn btn-flat-primary btn-icon p-2"
                data-toggle="aside"
              >
                <i class="fa fa-bars"></i>
              </button>
            </div>
            {!props.view ? (
              <>
                <div
                  class="header-wrap"
                  style={{
                    display: currentUrl?.match("serptracker")
                      ? "none"
                      : "block",
                  }}
                >
                  <div className="dashboard-seo-dropdown" ref={ref}>
                    <button
                      className="topnav-modal text-capitalize d-flex ps-0"
                      onClick={() => setIsMenuOpen((oldState) => !oldState)}
                    >
                      <span className="client-name ps-0">
                        {localStorage.getItem("clientGName")}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-chevron-down"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                      <span style={{ fontSize: 18 + "px" }}> / </span>
                      <span class="topnav-text  mb-0 ms-2">
                        {" "}
                        {localStorage.getItem("projectGName")}
                      </span>
                    </button>
                    <div
                      className="row box-shadow admin-submenu  mt-2 ms-2"
                      style={
                        isMenuOpen
                          ? {
                              position: "fixed",
                              transition: "0.5s",
                              width: "384px",
                              zIndex: "9999",
                              opacity: "1",
                            }
                          : {
                              position: "fixed",
                              transition: "0.5s",
                              width: "0px",
                              zIndex: "9999",
                              opacity: "0",
                            }
                      }
                    >
                      <div
                        className={isMenuOpen ? "col-md-6 p-0" : "d-none"}
                        style={{
                          borderRight: "1px solid rgba(0,0,0,.15)",
                          backgroundColor: "#2196f3",
                          color: "#fff",
                        }}
                      >
                        <ul className="Clients-list global-scrollbar">
                          {projectslisttop.map((i) => {
                            return (
                              <li
                                onClick={() => {
                                  localStorage.setItem("clientGId", i.id);
                                  localStorage.setItem("clientGName", i.title);
                                  showProjects(i.title);
                                }}
                              >
                                <span>{i.title}</span>{" "}
                                <i class="fa fa-angle-right"></i>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div
                        className={
                          isMenuOpen ? "col-md-6 p-0 project-list" : "d-none"
                        }
                      >
                        <ul class="projectsList global-scrollbar">
                          {clientchosen.map((i) => {
                            return (
                              <li
                                onClick={() => {
                                  setIsMenuOpen(false);
                                  localStorage.setItem("projectGId", i.id);
                                  localStorage.setItem(
                                    "projectGName",
                                    i.projname
                                  );
                                  window.location.href = window.location.href;
                                  // history.push({
                                  //   pathname: "/main/dashboard",
                                  // });
                                }}
                              >
                                <span>{i.projname}</span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="header-wrap" style={{display: currentUrl?.match('serptracker') ? 'block' : 'none'}}>
                  <b>SERPTracker</b>
                </div>
              </>
            ) : (
              <>
                <span className="ticket-header-title"> {breadcrumname}</span>
              </>
            )}
            <div class="header-wrap header-wrap-block"></div>
            <div class="header-wrap pe-3">
              <div className="bell dropdown">
                <Dropdown id="notification-dropdown">
                  <Dropdown.Toggle id="dropdown-basic">
                    {/* <i class="far fa-bell m-0"></i> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="feather feather-bell"
                    >
                      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
                      <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
                    </svg>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="simplebar-wrapper portlet border-0 portlet-scroll px-0 box-shadow">
                    <div class="portlet-header rounded-0 notification">
                      <div class="portlet-icon text-white">
                        <i class="far fa-bell"></i>
                      </div>
                      <h3 class="portlet-title text-white notification-title">
                        Notification
                      </h3>
                      <div class="portlet-addon">
                        <span class="badge badge-warning badge-square badge-lg">
                          9+
                        </span>
                      </div>
                    </div>
                    <Dropdown.Item
                      href=""
                      className="rich-list  rich-list-action px-0"
                    >
                      <div className="notification-item rich-list-item">
                        <div class="rich-list-prepend">
                          <div class="avatar avatar-label-info">
                            <div class="avatar-display">
                              <i class="fa fa-file-invoice"></i>
                            </div>
                          </div>
                        </div>
                        <div class="rich-list-content">
                          <h4 class="rich-list-title">
                            New report has been received
                          </h4>
                          <span class="rich-list-subtitle">2 min ago</span>
                        </div>
                        <div class="rich-list-append">
                          <i class="caret mx-2"></i>
                        </div>
                      </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href=""
                      className="rich-list  rich-list-action px-0"
                    >
                      <div className="notification-item rich-list-item">
                        <div class="rich-list-prepend">
                          <div class="avatar avatar-label-info">
                            <div class="avatar-display">
                              <i class="fa fa-file-invoice"></i>
                            </div>
                          </div>
                        </div>
                        <div class="rich-list-content">
                          <h4 class="rich-list-title">
                            New report has been received
                          </h4>
                          <span class="rich-list-subtitle">2 min ago</span>
                        </div>
                        <div class="rich-list-append">
                          <i class="caret mx-2"></i>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <span class="dropdown d-inline">
                <button
                  onClick={() => {
                    setsidenav(!sidenav);
                  }}
                  class="btn btn-default dropdown-toggle profilebtn"
                  type="button"
                  id={
                    localStorage.getItem("userIcon")
                      ? "dropdownMenuButton2"
                      : "dropdownMenuButton"
                  }
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div class="profile-pic   avatar  widget13-avatar">
                    <div className="avatar-display">
                      {localStorage.getItem("userIcon") ? (
                        <img
                          src={
                            GET_Server_URL + localStorage.getItem("userIcon")
                          }
                          alt=""
                        />
                      ) : (
                        <div>
                          {localStorage
                            ?.getItem("userName")
                            ?.substring(0, 1)
                            ?.toUpperCase()}
                        </div>
                      )}
                    </div>
                  </div>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div>
                    <a className="dropdown-item">
                      <div
                        onClick={() => {
                          history.push("/main/profile");
                        }}
                      >
                        Profile
                      </div>
                    </a>
                  </div>
                  <div>
                    <a className="dropdown-item">
                      <div
                        onClick={() => {
                          clearUserInformation();
                          history.push("/");
                        }}
                      >
                        Log Out
                      </div>
                    </a>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="float-btn float-btn-right">
        <div id="notification-dropdown" className="d-grid">
          <button
            class="btn btn-flat-primary btn-icon mb-2 dark-theme-icon"
            data-toggle="tooltip"
            data-placement="right"
            title="Change theme"
          >
            <i onClick={() => appThemeChange()} class={themee}></i>
          </button>
          <button
            class="btn btn-flat-primary btn-icon"
            type="button"
            data-toggle="modal"
            data-target="#myModal2"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              preserveAspectRatio="xMidYMid meet"
              viewBox="0 0 1024 1024"
              class="bug"
            >
              <path
                fill="currentColor"
                d="M304 280h56c4.4 0 8-3.6 8-8c0-28.3 5.9-53.2 17.1-73.5c10.6-19.4 26-34.8 45.4-45.4C450.9 142 475.7 136 504 136h16c28.3 0 53.2 5.9 73.5 17.1c19.4 10.6 34.8 26 45.4 45.4C650 218.9 656 243.7 656 272c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8c0-40-8.8-76.7-25.9-108.1c-17.2-31.5-42.5-56.8-74-74C596.7 72.8 560 64 520 64h-16c-40 0-76.7 8.8-108.1 25.9c-31.5 17.2-56.8 42.5-74 74C304.8 195.3 296 232 296 272c0 4.4 3.6 8 8 8z"
              />
              <path
                fill="currentColor"
                d="M940 512H792V412c76.8 0 139-62.2 139-139c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8c0 34.8-28.2 63-63 63H232c-34.8 0-63-28.2-63-63c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8c0 76.8 62.2 139 139 139v100H84c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h148v96c0 6.5.2 13 .7 19.3C164.1 728.6 116 796.7 116 876c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8c0-44.2 23.9-82.9 59.6-103.7c6 17.2 13.6 33.6 22.7 49c24.3 41.5 59 76.2 100.5 100.5S460.5 960 512 960s99.8-13.9 141.3-38.2c41.5-24.3 76.2-59 100.5-100.5c9.1-15.5 16.7-31.9 22.7-49C812.1 793.1 836 831.8 836 876c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8c0-79.3-48.1-147.4-116.7-176.7c.4-6.4.7-12.8.7-19.3v-96h148c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM716 680c0 36.8-9.7 72-27.8 102.9c-17.7 30.3-43 55.6-73.3 73.3C584 874.3 548.8 884 512 884s-72-9.7-102.9-27.8c-30.3-17.7-55.6-43-73.3-73.3C317.7 752 308 716.8 308 680V412h408v268z"
              />
            </svg>
          </button>
          {/* <button
            class="btn btn-flat-primary btn-icon"
            onClick={() => {
              setCreateTicket(true);
            }}

          >

          </button> */}
          <div
            class="modal right fade bug-modal"
            id="myModal2"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel2"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-body">
                  <div className="portlet common-form-fields ">
                    <div class="portlet-header portlet-header-bordered">
                      <h3 class="portlet-title main-title">Report Bug</h3>
                      <button
                        type="button"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="portlet-body">
                      <div className="col-md-12"></div>
                      <div className="col-md-12 mt-3"></div>
                      <div className="col-md-12 mt-3"></div>
                      <div class="col-md-12 mt-3">
                        <label className="form-label">Comments</label>
                        <label class="field-required"> * </label>
                        <textarea
                          className="form-control w-100"
                          rows="7"
                          cols="70"
                          placeholder="Please share your feedback here"
                          name="name"
                          value={bugMessage}
                          tabIndex={1}
                          onChange={(text) => {
                            setBugMessage(text.target.value);
                            if (text.target.value.length > 0) {
                              setMessage("");
                            }
                          }}
                          required
                        ></textarea>
                        <div style={{ color: "red" }}>{message}</div>
                      </div>

                      <div class="col-md-12 mt-3">
                        <label className="form-label">Screenshots</label>
                      </div>
                      <div class="col-md-12 mt-3">
                        <input
                          style={{ display: "none" }}
                          ref={inputRef}
                          type="file"
                          id="urlFile"
                          name="urlFile"
                          inputProps={{ accept: imageFileType }}
                          accept={imageFileType}
                          multiple={true}
                          onChange={(item) => {
                            var input = document.getElementById("urlFile");
                            let fList = bugMessage1;
                            let fileNameList = [],
                              fileList = [];
                            for (var i = 0; i < input.files.length; ++i) {
                              fileList.push(input.files.item(i).name);
                              fList.push(input.files.item(i));
                            }
                            setFileList(fileList);
                            setBugMessage1(fList);
                            item.target.value = null;
                          }}
                        />
                        <button
                          className="form-control mb-2 bug-choosefile"
                          style={{
                            width: "110px",
                            border: "1px solid #a7a9ab",
                          }}
                          onClick={(item) => {
                            inputRef.current.click();
                          }}
                        >
                          Choose file
                        </button>
                        {bugMessage1 &&
                          bugMessage1.map((item, index) => {
                            return (
                              <div className="d-flex justify-content-between align-items-center report-file">
                                <span className="bug-file">{item.name}</span>
                                <i
                                  onClick={() => {
                                    setFileList(
                                      fileList.filter((e) => e !== item)
                                    );
                                    if (index > -1) {
                                      bugMessage1.splice(index, 1);
                                    }
                                    setBugMessage1(bugMessage1);
                                  }}
                                  class="fa fa-times"
                                  style={{ color: "#757575" }}
                                ></i>
                              </div>
                            );
                          })}
                      </div>
                      <div class="portlet-footer portlet-footer-bordered mt-3">
                        <div className="col-md-12 d-flex justify-content-end bug-btn2">
                          <div>
                            <button
                              type="button"
                              class="btn btn-outline-secondary me-3"
                              data-dismiss="modal"
                              style={{
                                width: 70 + "px",
                                height: 30 + "px",
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                          <div>
                            <button
                              type="button"
                              class="btn btn-primary"
                              style={{
                                width: 70 + "px",
                                height: 30 + "px",
                              }}
                              data-dismiss={
                                bugMessage.length == 0 ? "" : "modal"
                              }
                              onClick={() => {
                                handleChangebugReport();
                              }}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ModelCreateTicket
            modelShow={createTicket}
            modelCloseClick={() => {
              setCreateTicket(false);
              // getallticktes(
              //   {
              //     ticketstatus: tabSelected,
              //     ViewTicket: isActive,
              //     organizationId: localStorage.getItem("organizationId"),
              //     // CreatedBy: localStorage.getItem("userId")
              //   },
              //   memberlist
              // );
            }}
          /> */}
        </div>
      </div>
    </>
  );
}

export default TopNavBarCustom;
