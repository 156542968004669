import React, { useEffect } from "react";
import { useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { Pagination } from 'antd';

function EditorOverview({ }) {

    const [userList, setUserList] = useState([])
    const [listTotal, setListTotal] = useState("0")

    useEffect(() => {
        let list = [
            { name: "Aaraf Sheikh", units: 10 },
            { name: "Doyal Mathai", units: 4 },
            { name: "Robin Fernandes", units: 4 },
            { name: "Anuj Sharma", units: 4 },
            { name: "Depen Mavani", units: 2 },
            { name: "Nehal Patel", units: 5 },
            { name: "Ketan Prajapati", units: 3 },
            { name: "Sachin Prajapati", units: 6 },
        ]
        setUserList(list)
        setListTotal(list.length)
    }, [])

    const [current, setCurrent] = useState(3);

    const onChange = (page) => {
        
        setCurrent(page);
    };
    return (
        <>
            <div className="title">
                {/* <div className="d-flex justify-content-between align-items-center"> */}
                    <div className="d-flex justify-content-between align-items-center editor-overview">
                        <h4 className="me-3">Editor's Overview ({listTotal})</h4>
                    </div>


                {/* </div> */}
                <div class="align-items-center editor-status">
                    <div className="row html2pdf__page-break all-channel-table">
                        <div className="d-flex">
                            <div className="row allchannel-table">
                                {userList.map((item) => {
                                    return (<>
                                        <div className=" col-md-3 m-0 p-0  ">
                                            <div className="me-3 content-units units-completed mt-3">
                                                <div className="d-flex align-items-center  mb-2">
                                                    <div class="d-flex align-items-center unit-first-latter">
                                                        <h4>{(item.name).charAt(0).toUpperCase()}</h4>
                                                    </div>
                                                    <div>
                                                        <p className="align-items-center my-2"> &nbsp; &nbsp;{item.name}</p>
                                                    </div>
                                                </div>
                                                <div className="content-name border-bottom"></div>
                                                <div className="d-flex justify-content-start mt-1">
                                                    <p className="me-3 mb-0"><span>Units Completed : {item.units}/10</span> </p>
                                                </div>
                                                <ProgressBar className="d-flex seo-progress mt-2 content-progress">
                                                    <ProgressBar
                                                        now={item.units * 10}
                                                        key={1}
                                                    />
                                                </ProgressBar>
                                               

                                            </div>
                                        </div>

                                    </>
                                    )
                                })}
                               
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row editor-pagination">
                <div className="col-md-12 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-start content-page">Showing 1 to 4 of 08 Writers</div>
                                    <div className="d-flex align-items-end"> <Pagination defaultCurrent={1} total={20} /></div>
                                </div>
                                </div>
            </div>
        </>
    )
}
export default EditorOverview;