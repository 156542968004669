import {
  getProjectById,
  getRankTrackingCompareChart,
  getRankTrackingCompareTable,
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import {
  DDMMMyyyyFormateDateSet,
  DDMMyyyyFormateDateSet,
  formatRankDate,
  mmDDyyyyFormateDateSet,
  percentageCount,
  compareValues,
  getClassName,
} from "../../utility/Globals";

function RankingsChart(props) {
  const [series, setSeries] = useState([]);
  const [options, setObject] = useState({});
  const [isChart, setIsChart] = useState(0);
  const [selectedDeviceType, setSelectedDeviceType] = useState(0);
  const [rankingsTabularDataList, setRankingsTabularDataList] = useState([]);
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [compareendDate, setcompareendDate] = useState("");

  useEffect(() => {
    getRanktrackData();
  }, [props?.dataParentToChild]);

  const [selectedProject, setselectedProject] = useState("");

  function getRanktrackData() {
    APIManager.getAPI(
      getProjectById + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        setselectedProject(
          response?.data?.value?.domainName
        );

        setstartDate(
          mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
        );
        setendDate(
          mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
        );
        setcompareStartDate(
          mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
        );
        setcompareendDate(
          mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
        );

        let ids = [];
        ids.push(selectedProject);

        let rowdata = {
          Urls: ids,
          FromTrackDate: mmDDyyyyFormateDateSet(
            props?.dataParentToChild?.selection1?.startDate
          ),
          ToTrackDate: mmDDyyyyFormateDateSet(
            props?.dataParentToChild?.selection1?.endDate
          ),
          ProjectId: localStorage.getItem("projectGId"),
          DeviceType: 0,
          CompareTrackDateRange: {
            from: mmDDyyyyFormateDateSet(
              props?.dataParentToChild?.selection2?.startDate
            ),
            to: mmDDyyyyFormateDateSet(
              props?.dataParentToChild?.selection2?.endDate
            ),
          },
        };

        getRankingChartData(rowdata);

        let rowdata1 = {
          Urls: ids,
          FromTrackDate: mmDDyyyyFormateDateSet(
            props?.dataParentToChild?.selection1?.startDate
          ),
          ToTrackDate: mmDDyyyyFormateDateSet(
            props?.dataParentToChild?.selection1?.endDate
          ),
          ProjectId: localStorage.getItem("projectGId"),
          DeviceType: 0,
          CompareTrackDateRange: {
            from: mmDDyyyyFormateDateSet(
              props?.dataParentToChild?.selection2?.startDate
            ),
            to: mmDDyyyyFormateDateSet(
              props?.dataParentToChild?.selection2?.endDate
            ),
          },
        };
        getRankingTableData(rowdata1);
      }
    });
  }

  useEffect(() => {
    setIsChart(props?.IsSeoChart);
  }, [props?.IsSeoChart]);

  function getRankingChartData(rowdata) {
    APIManager.postAPI(getRankTrackingCompareChart, rowdata, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let createdDateList1 = [];
          let createdDateList2 = [];
          let maxVal = 0;
          let values1 = [];
          let values2 = [];
          let values11 = [];
          let values22 = [];
          var categories = [];
          response.data.rankTrackingResponse.map((item) => {
            var keys = Object.keys(item);
            values1 = Object.values(item);
            let index1;
            keys.map((item, index) => {
              if (item == "trackDate") {
                index1 = index;
              } else {
                categories.push(item);
              }
            });
            values1.map((item, index) => {
              if (index != index1) {
                values11.push(item);
              }
              if (item > maxVal) {
                maxVal = item;
              }
            });
            createdDateList1.push(DDMMyyyyFormateDateSet(item.trackDate));
          });
          response.data.rankTrackCompareResponse.map((item, index) => {
            var keys = Object.keys(item);
            values2 = Object.values(item);
            let index1;
            keys.map((item, index) => {
              if (item == "trackDate") {
                index1 = index;
              }
            });
            values2.map((item, index) => {
              if (index != index1) {
                values22.push(item);
              }
              if (item > maxVal) {
                maxVal = item;
              }
            });

            createdDateList2.push(DDMMyyyyFormateDateSet(item.trackDate));
          });
          let option = {
            chart: {
              id: "basic-bar",
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: ["20%"],
              },
            },
            xaxis: {
              tickPlacement: "on",
              title: {
                // text: "Rank Date",
                style: {
                  color: "#000",
                },
              },
              categories: categories,
            },
            yaxis: {
              max: maxVal,
            },
            dataLabels: {
              style: {
                colors: ["#e0e0e0"],
              },
            },
            grid: {
              show: true,
              padding: {
                left: 50,
                right: 50,
              },
            },
            dataLabels: {
              enabled: false,
            },
            legend: {
              show: false,
            },
          };
          setObject(option);
          var nseries = [
            {
              name: createdDateList1,
              type: "column",
              data: values11,
            },
            {
              name: createdDateList2,
              type: "column",
              data: values22,
            },
          ];
          setSeries(nseries);
        }
      }
    );
  }

  function getRankingTableData(rowData) {
    APIManager.postAPI(getRankTrackingCompareTable, rowData, null, true).then(
      (response) => {
        let keys = [];
        let keys1 = [];
        let keysCompare = [];
        let keysCompare1 = [];
        let values = [];
        let values1 = [];
        let valuesCompare = [];
        let valuesCompare1 = [];
        let rankList = [];
        let date, compareDate;
        let listForTabular = [];
        let list1 = [];
        let list2 = [];

        if (response && response.status == 200) {
          response.data.rankTrackingResponse.map((item) => {
            keys = Object.keys(item);
            values = Object.values(item);
            let index1;
            keys.map((key, index) => {
              if (key != "trackDate") {
                keys1.push(key);
              } else {
                index1 = index;
              }
            });
            values.map((value, index) => {
              if (index != index1) {
                values1.push(value);
                list1.push(value);
              } else {
                date = value;
              }
            });
          });

          response.data.rankTrackCompareResponse.map((item) => {
            keysCompare = Object.keys(item);
            valuesCompare = Object.values(item);
            let index1;
            keysCompare.map((key, index) => {
              if (key != "trackDate") {
                keysCompare1.push(key);
              } else {
                index1 = index;
              }
            });
            valuesCompare.map((value, index) => {
              if (index != index1) {
                valuesCompare1.push(value);
                list2.push(value);
              } else {
                compareDate = value;
              }
            });
          });

          let length = keys1.length;
          let lengthCompare = keysCompare1.length;
          let mainLength;
          length > lengthCompare
            ? (mainLength = length)
            : (mainLength = lengthCompare);
          length > lengthCompare
            ? (rankList = keys1)
            : (rankList = keysCompare1);

          let ranklist2 = [];
          rankList.map((item, index) => {
            if (index <= 2) {
              ranklist2.push(item);
            }
          });
          let rankList3 = ["rank 1", "rank 2-5", "rank 6-10"];
          for (let i = 0; i <= 2; i++) {
            let obj = {
              rank:
                rankList3[i].charAt(0).toUpperCase() + rankList3[i].slice(1),
              avgData: percentageCount(list1[i], list2[i]).toFixed(1) + "%",
              data: list1[i],
              compareData: list2[i],
              date: DDMMMyyyyFormateDateSet(date),
              compareDate: DDMMMyyyyFormateDateSet(compareDate),
              isHighData: compareValues(list1[i], list2[i]),
            };
            listForTabular.push(obj);
          }
          setRankingsTabularDataList(listForTabular);
        }
      }
    );
  }

  function handleClickRankingChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }

  return (
    <>
      <div className="title pb-4">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3 ranking-name">Rankings</h4>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="mx-2">
              <select
                className="form-select"
                value={selectedDeviceType}
                onChange={(type) => {
                  setSelectedDeviceType(type.target.value);

                  let ids = [];
                  ids.push(selectedProject);

                  let rowdata1 = {
                    Urls: ids,
                    FromTrackDate: startDate,
                    ToTrackDate: endDate,
                    ProjectId: localStorage.getItem("projectGId"),
                    DeviceType: type.target.value,
                    CompareTrackDateRange: {
                      from: compareStartDate,
                      to: compareendDate,
                    },
                  };
                  getRankingTableData(rowdata1);

                  let rowdata2 = {
                    Urls: ids,
                    FromTrackDate: startDate,
                    ToTrackDate: endDate,
                    ProjectId: localStorage.getItem("projectGId"),
                    DeviceType: type.target.value,
                    CompareTrackDateRange: {
                      from: compareStartDate,
                      to: compareendDate,
                    },
                  };

                  getRankingChartData(rowdata2);
                }}
              >
                <option value="0">Mobile</option>
                <option value="1">Desktop</option>
              </select>
            </div>
            <div className="btn-box">
              <div className="d-flex align-items-center">
                <button
                  className={`${isChart == 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickRankingChart(0);
                  }}
                >
                  <img
                    src={`${isChart == 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isChart == 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickRankingChart(1);
                  }}
                >
                  <img
                    src={`${isChart == 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-3 pb-4">
        {isChart == 0 ? (
          <>
            {" "}
            <Chart options={options} series={series} type="bar" height="300" />
          </>
        ) : (
          <>
            <div className="col-md-12 text-right backlink-daterange">
              Date Range : {formatRankDate(startDate)} -{" "}
              {formatRankDate(compareStartDate)}
            </div>

            <div className="row  ranking-scroll">
              {rankingsTabularDataList.map((i) => {
                return (
                  <div className="col-lg-12 col-xl-6 m-0 p-0 res-100">
                    <div className="ranks me-2">
                      <div className="d-flex align-items-center justify-content-between">
                        <h4>{i.rank}</h4>
                        <div className="grren-domain text-right">
                          {i.isHighData != 1 ? (<>
                            <svg
                              width="35"
                              height="34"
                              viewBox="0 0 35 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                              <g clip-path="url(#clip0_4_4546)">
                                <path
                                  d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.667 12H26.667V17"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4546">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(7.5 7)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>

                          </>) : (<>
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                r="17"
                                transform="matrix(1 0 0 -1 17 17)"
                                fill="#FFC0C2"
                              />
                              <g clip-path="url(#clip0_4_4443)">
                                <path
                                  d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.167 22H26.167V17"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4443">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="matrix(1 0 0 -1 7 27)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </>)}
                        </div>
                      </div>
                      <h3 className={getClassName(i)}>{i.avgData}</h3>
                      <div className="row mt-3">
                        <div className="d-flex align-items-center justify-content-between p-0">
                          <div className="col-md-6 p-0">
                            <p>{i.data}</p>
                            <span>{formatRankDate(startDate)}</span>
                          </div>
                          <div className="col-md-6 roi-total-compare p-0">
                            <p>{i.compareData}</p>
                            <span>{formatRankDate(compareStartDate)}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default RankingsChart;
