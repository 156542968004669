import React from "react";
import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { Calendar, DateObject } from "react-multi-date-picker";
import { Card } from "react-bootstrap";
import APIManager from "../../services/APIManager";
import { Table, Space, Drawer } from "antd";
import toast from "react-hot-toast";
import {
  documentFileType,
  getClientByOrgId,
  addSOWTicket,
  getTicketListSOW,
  sowAllocationTicket,
  GET_Server_URL,
  sowImport,
  sendApprovalEmail,
  getSOWMonth,
  getAllocationDetail,
  deleteSowAllocation,
  getAssignedMembersWithCustomer,
  deleteSOW,
} from "../../utility/Constant";
import {
  getDaysInMonth,
  mmDDyyyyFormateDateSet,
  getActivityType,
  removeTrailingSlash,
} from "../../utility/Globals";

function ScopeOfWork({ update }) {
  const history = useHistory();
  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [assignedArray, setassignedArray] = useState([]);
  const [activityHeading, setactivityHeading] = useState("");
  const [isIndividual, setIsIndividual] = useState(false);
  const [isBulk, setIsBulk] = useState(false);
  const [monthName, setMonthName] = useState("");
  const [className1, setClassName1] = useState("");
  const [className2, setClassName2] = useState("");
  const [ticketLength, setTicketLength] = useState(0);
  const [addSow, setAddSow] = useState(false);
  const [monthSelected, setMonthSelected] = useState(false);
  const [indexData, setIndexData] = useState(0);
  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState("");

  useEffect(() => {
    const d = new Date();
    const month =
      d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();
    setMonthName(month);
    let string = "Upload Scope Of Work(SOW) for " + month;
    setTitle(string);
    let dateArry = [];
    dateArry.push(d.getMonth() + 1);
    dateArry.push(d.getFullYear());
    var daysInMonth = getDaysInMonth(d.getFullYear(), d.getMonth() + 1);
    let dateObjArr = [
      new Date(dateArry[0] + "/01/" + dateArry[1]),
      new Date(dateArry[0] + "/" + daysInMonth + "/" + dateArry[1]),
    ];
    setValues(dateObjArr);
    setHead1(dateObjArr[0]);
    setHead2(dateObjArr[1]);
    let rowData = {
      searchText: searchText,
      startDate: mmDDyyyyFormateDateSet(dateObjArr[0]),
      endDate: mmDDyyyyFormateDateSet(dateObjArr[1]),
      projectId: localStorage.getItem("projectGId"),
    };
    getTicketList(rowData);
    getClientList();
    getMonth();
  }, []);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    const data = [
      {
        title: "Categories",
        dataIndex: "category",
        key: "category",
      },

      {
        title: <span>Responsibilities</span>,
        dataIndex: "responsibilities",
        key: "responsibilities",
      },
      {
        title: "Activities",
        dataIndex: "activity",
        key: "activity",
      },
      {
        title: "Units",
        dataIndex: "units",
        key: "units",
      },
      {
        title: "Frequency",
        dataIndex: "frequency",
        key: "frequency",
      },
      // {
      //   title: "Word Count",
      //   dataIndex: "wordcount",
      //   key: "wordcount",
      // },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
      },
      {
        title: "Assigned",
        dataIndex: "assignedto",
        key: "assignedto",
      },
      {
        title: "Uploaded by",
        dataIndex: "uploadedby",
        key: "uploadedby",
      },
      {
        title: "",
        dataIndex: "edit",
        key: "edit",
        render: (_, record) => (
          <>
            {(record.ticketId && record.status.props.children === 'In Progress') ? (
              <>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit scope-edit-icon edit-icon  ms-1"
                  onClick={() => {
                    if (record.ticketId != null) {
                      setSelectedCategoryEdit(
                        getCategoryValues(record.category)
                      );
                      setClientSelectedEdit(record.responsibilitiesId);
                      setActivityEdit(record.activity);
                      setUnitsEdit(record.units);
                      setUnitsEdit1(record.units);
                      setTicketId(record.ticketId);
                      setSowDetailIdd(record.sowDetailIdd);
                      setFrequencyEdit(record.frequency);
                      // setWordCountEdit(record.wordcount);
                      setStatus(record.statusValue);
                      showLargeDrawer3();
                    }
                  }}
                  style={{ color: "#757575" }}
                >
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>
              </>
            ) : (
              <></>
            )}
            {(record.ticketId && record.status.props.children === 'In Progress') ? (<svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-trash-2 scope-trash-icon trash-icon  ms-3"
              onClick={() => {
                if (record.ticketId != null) {
                  deleteEditproj(record.sowDetailIdd);
                }
              }}
            >
              <polyline points="3 6 5 6 21 6"></polyline>
              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
              <line x1="10" y1="11" x2="10" y2="17"></line>
              <line x1="14" y1="11" x2="14" y2="17"></line>
            </svg>) : <></>}
          </>
        ),
      },
    ];
    setTableColumns(data);
  }, []);

  useEffect(() => { }, [assignedArray]);
  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [head1, setHead1] = useState(values[0]);
  const [head2, setHead2] = useState(values[1]);
  const [clientList, SetclientList] = useState([]);
  const [clientSelected, setClientSelected] = useState("");
  const [clientListEdit, SetclientListEdit] = useState([]);
  const [clientSelectedEdit, setClientSelectedEdit] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [selectedCategoryEdit, setSelectedCategoryEdit] = useState(0);
  const [activity, setActivity] = useState("");
  const [units, setUnits] = useState();
  const [frequency, setFrequency] = useState(0);
  // const [wordCount, setWordCount] = useState();
  const [activityEdit, setActivityEdit] = useState("");
  const [unitsEdit, setUnitsEdit] = useState("");
  const [unitsEdit1, setUnitsEdit1] = useState("");
  const [frequencyEdit, setFrequencyEdit] = useState();
  // const [wordCountEdit, setWordCountEdit] = useState();
  const [unitsData, setUnitsData] = useState(); //Create Allocation
  const [searchText, setSearchText] = useState("");
  const [memberlist, setmemberlist] = useState([]);
  const [addSowTicketButton, setaddSowTicketButton] =
    useState("Add SOW Ticket");
  const [message, setMessage] = useState("");
  const [message1, setMessage1] = useState("");
  const [messageUnits, setMessageUnits] = useState("");
  const [messageCount, setMessageCount] = useState("");
  const [messageUnits1, setMessageUnits1] = useState("");
  const [messageCount1, setMessageCount1] = useState("");
  const [color, setColor] = useState();
  const [ticketId, setTicketId] = useState("");
  const [sowDetailIdd, setSowDetailIdd] = useState("");

  const [fileName, setFileName] = useState("");
  const [fileSetelctedMsg, setFileSetelctedMsg] = useState("No File Selected");
  const [monthListChips, setmonthListChips] = useState([]);
  const [status, setStatus] = useState();
  const [title, setTitle] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [save, setsave] = useState(false);

  let isModelDismiss = false;
  let isModelDismiss1 = false;
  let isModelDismissEdit = false;

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => { },
  };

  const [open1, setOpen1] = useState(false);
  const [size1, setSize1] = useState();
  const onClose1 = () => {
    setOpen1(false);
  };
  const showLargeDrawer1 = () => {
    setSize1("large");
    setOpen1(true);
  };

  const [open2, setOpen2] = useState(false);
  const [size2, setSize2] = useState();
  const onClose2 = () => {
    setOpen2(false);
  };
  const showLargeDrawer2 = () => {
    setSize2("large");
    setOpen2(true);
  };

  const [open3, setOpen3] = useState(false);
  const [size3, setSize3] = useState();
  const onClose3 = () => {
    setOpen3(false);
  };
  const showLargeDrawer3 = () => {
    setSize3("large");
    setOpen3(true);
  };

  function getClientList() {
    let list = [];
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response.status == 200) {
        response.data.map((item) => {
          let object = {
            name: item.name,
            id: item.id,
          };
          list.push(object);
        });
      }
      SetclientList(list);
      SetclientListEdit(list);
      setClientSelected(list[0].id);
    });
  }

  function getMonth() {
    APIManager.getAPI(getSOWMonth).then((response) => {
      if (response.status == 200) {
        let list = [];
        let obj;
        const month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
        let index1;
        const d = new Date();
        let currentMonth = month[d.getMonth()];
        let year = d.getFullYear();
        let monthYr = currentMonth + " " + year;
        response.data.map((item, index) => {
          obj = {
            monthName: item.column1,
            key: index,
          };
          list.push(obj);
          if (item.column1 == monthYr) {
            index1 = index;
          }
        });
        let list1 = [];
        list.map((item, index) => {
          if (index <= 3) {
            list1.push(item);
          }
        });
        setmonthListChips(list1);
        setIndexData(index1);
        setMonthSelected(true);
      }
    });
  }

  function getCategoryValues(category) {
    if (category === 3) {
      return "OnPage";
    } else if (category === 0) {
      return "Content";
    } else if (category === 1) {
      return "OffPage";
    } else if (category === 2) {
      return "Technical";
    }
  }
  function getMonthNumberFromName(monthName, year) {
    return new Date(`${monthName} 1, ${year}`).getMonth() + 1;
  }

  function addTicket() {
    let rowData = {
      ProjectId: localStorage.getItem("projectGId"),
      CreatedBy: localStorage.getItem("userId"),
      SowDetails: [
        {
          Category: selectedCategory,
          Activities: activity,
          Responsibilities: clientSelected,
          Units: units,
          Frequency: frequency,
          // WordCount: wordCount,
        },
      ],
    };
    APIManager.postAPI(addSOWTicket, rowData, null, true).then((response) => {
      if (response && response.status == 200) {
        setSelectedCategory(0);
        setActivity("");
        setUnits("");
        // setWordCount("");
        setTimeout(() => {
          getTicketList();
        }, 500);
        onClose2();
        toast.success("Email Send for Approval!");
      }
    });
  }

  function sendForApprovalFn() {
    let rowData = {
      Id: sowDetailIdd,
      TicketId: ticketId,
      Category: selectedCategoryEdit,
      Activities: activityEdit,
      Responsibilities: clientSelectedEdit,
      Units: unitsEdit,
      Status: status,
      Frequency: frequencyEdit,
      // WordCount: wordCountEdit,
      FinalUnits: unitsEdit1,
    };
    APIManager.postAPI(sendApprovalEmail, rowData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          getTicketList();
          toast.success("Email Send for Approval!");
          onClose3();
        }
      }
    );
  }

  function getFrequency(frequency) {
    if (frequency == 0) {
      return "Monthly";
    } else if (frequency == 1) {
      return "Quarterly";
    } else if (frequency == 2) {
      return "Yearly";
    }
  }
  const [classSOW, setClassSOW] = useState("btn btn-light active");

  function getClassName(index, indexData, isMonthSelected) {
    setClassSOW("");
    setMonthSelected(isMonthSelected);
    if (isMonthSelected && indexData == index) {
      setClassSOW("btn btn-light active");
    } else {
      setClassSOW("btn btn-light");
    }
  }

  function getStatusFun(status) {
    if (status == 0) {
      return (
        <div class="sow btn btn-warning text-worning ">Awaiting Approval</div>
      );
    } else if (status == 1) {
      return <div class="sow btn btn-info">In Progress</div>; //change design
    } else if (status == 2) {
      return <div class="sow btn btn-success">Completed</div>;
    }
  }

  function getTicketList(rowData) {
    document.getElementById('sowFile').value = '';
    let data;
    if (rowData) {
      data = rowData;
    } else {
      data = {
        searchText: searchText,
        startDate: head1,
        endDate: head2,
        projectId: localStorage.getItem("projectGId"),
      };
    }
    APIManager.postAPI(getTicketListSOW, data, null, true).then((response) => {
      if (response && response.status == 200) {
        let object;
        let ticketList = [];
        let assignedList = [];
        let countListArray = [];
        if (assignedList.length <= 3) {
          assignedList.map((item, index) => {
            if (index < 3) {
              countListArray.push(<span>{item.charAt(0).toUpperCase()}</span>);
            }
          });
        }
        response.data.map((item) => {
          object = {
            key: item.sowDetailId,
            id: item.sowDetailId,
            category: <span>{getCategoryValues(item.category)}</span>,
            responsibilities: item.responsibility,
            activity: item.activities,
            units: item.units,
            sowDetailIdd: item.sowDetailId,
            assignedto: item.assignedTo,
            frequency: getFrequency(item.frequency),
            // wordcount: item.wordCount,
            assignedto: (
              <div>
                {item.ticketId ? (
                  <Space
                    size="middle"
                    class=" display-flex align-items-center justify-content-center"
                  >
                    {(item.status === 1) ? <a
                      id="plusIcon"
                      class="scope-A d-flex"
                      onClick={() => {
                        if (item.ticketId != null) {
                          setMessage1("");
                          setMessage("");
                          setactivityHeading(item.activities);
                          setUnitsData(item.units);
                          setSowDetailIdd(item.sowDetailId);
                          setTicketId(item.ticketId);
                          getAllocationDetailFun(item.sowDetailId);
                          setaddSowTicketButton("Add SOW Ticket");
                          showLargeDrawer1();
                        }
                      }}
                    >
                      {getIconFun(item?.assignedMember)}
                    </a> : <></>}
                    {(item.status === 1) ? <a
                      id="plusIcon1"
                      class="scope-plus margin-left-minus py-0"
                      aria-hidden="true"
                      type="button"
                      onClick={() => {
                        if (item.ticketId != null) {
                          setMessage1("");
                          setMessage("");
                          setactivityHeading(item.activities);
                          setUnitsData(item.units);
                          setSowDetailIdd(item.sowDetailId);
                          setTicketId(item.ticketId);
                          getAllocationDetailFun(item.sowDetailId);
                          setaddSowTicketButton("Add SOW Ticket");
                          showLargeDrawer1();
                        }
                      }}
                    >
                      <span>+ </span>
                    </a> : <></>}
                  </Space>
                ) : (
                  <>
                    {/* <Space
                      size="middle"
                      class=" display-flex align-items-center justify-content-start ms-3"
                    >
                      <a class="scope-A d-flex">
                        {getIconFun(item?.assignedMember)}
                      </a>
                      <a
                        class="scope-plus margin-left-minus py-0"
                        aria-hidden="true"
                        type="button"
                      >
                        <span>+ </span>{" "}
                      </a>
                    </Space> */}
                  </>
                )}
              </div>
            ),
            ticketId: item.ticketId,
            responsibilitiesId: item.responsibilityId,
            assignedMember: item?.assignedMember,
            status: getStatusFun(item.status),
            uploadedby: item.uploadedBy,
            statusValue: item.status,
          };
          ticketList.push(object);
        });
        setTableData([]);
        setTableData(ticketList);
        setTableData1(ticketList);
        setTicketLength(ticketList.length);
      }
    });
    getmembersData();
  }

  function totalUnitsFun(array) {
    var i = 0;
    array.map((item) => {
      i = i + parseInt(item.AssignedUnit == "" ? "0" : item.AssignedUnit);
    });
    let unitsRemaining = unitsData - i;
    if (unitsRemaining < 0) {
      setMessage("Total should not increase " + unitsData);
      setColor({ color: "red" });
      document.querySelector("#addSOWTicket").disabled = true;
      document.querySelector("#createAllocationBtn").disabled = true;
    } else if (unitsRemaining == 0) {
      setMessage("Units Remaining : " + unitsRemaining);
      document.querySelector("#addSOWTicket").disabled = false;
      document.querySelector("#createAllocationBtn").disabled = true;
    } else {
      setMessage("Units Remaining : " + unitsRemaining);
      setColor({ color: "blue" });
      document.querySelector("#addSOWTicket").disabled = false;
      document.querySelector("#createAllocationBtn").disabled = false;
    }
  }

  function addContent() {
    var tempArray = JSON.parse(JSON.stringify(assignedArray));
    tempArray.push({
      AssignedTo: memberlist[0].id,
      AssignedUnit: "",
      AssignedId: "",
      isNew: true,
      AllocationId: "",
    });
    setassignedArray(tempArray);
    var text = "Generate " + tempArray.length + " Sub Tickets";
    setaddSowTicketButton(text);
    totalUnitsFun(tempArray);
  }

  function removeContent(i) {
    let list = [...assignedArray];
    list.splice(i, 1);
    setassignedArray(list);
    if (list.length != 0) {
      var text = "Generate " + list.length + " Sub Tickets";
    } else {
      text = "Add SOW Ticket";
      setMessage("");
    }
    setaddSowTicketButton(text);
    totalUnitsFun(list);
  }

  async function deleteSowFun(sowId) {
    try {
      const response = await APIManager.deteletItem(
        deleteSowAllocation + sowId
      );
      if (response && response.status == 200) {
        let rowData = {
          searchText: searchText,
          startDate: mmDDyyyyFormateDateSet(head1),
          endDate: mmDDyyyyFormateDateSet(head2),
          projectId: localStorage.getItem("projectGId"),
        };
        getTicketList(rowData);
      }
    } catch (e) { }
  }

  function addAllocation() {
    let totalUNITS = 0;
    let assignedArray1 = [];
    let flag;
    flag = assignedArray.some((code) => code.AssignedUnit === "");
    assignedArray.map((i) => {
      totalUNITS = totalUNITS + parseInt(i.AssignedUnit);
      let obj = {
        AssignedTo: i.AssignedTo,
        AssignedUnit: i.AssignedUnit ? parseInt(i.AssignedUnit) : "",
        subticketId: i.isNew ? null : ticketId,
        AllocationId: i.AssignedId,
      };
      assignedArray1.push(obj);
    });
    if (unitsData >= totalUNITS) {
      isModelDismiss = true;
    } else {
      isModelDismiss = false;
    }
    let rowData = {
      SowDetailId: sowDetailIdd,
      TicketId: ticketId,
      MembersAssign: assignedArray1,
    };
    if (flag == false) {
      setMessage1("");
      APIManager.postAPI(sowAllocationTicket, rowData, null, true).then(
        (response) => {
          if (response && response.status == 200) {
            isModelDismiss = true;
            setSowDetailIdd(sowDetailIdd);
            getTicketList();
            onClose1();
          }
        }
      );
    } else {
      isModelDismiss = false;
      setMessage1("Please enter units!");
    }
  }

  function getAllocationDetailFun(sowDetailId) {
    APIManager.getAPI(getAllocationDetail + sowDetailId).then((response) => {
      if (response.status == 200) {
        var tempArray = [];
        response.data.map((item) => {
          tempArray.push({
            AssignedTo: item.assignedTo,
            AssignedUnit: item.assignedUnit,
            AssignedId: item.id,
            isNew: false,
            AllocationId: item.id,
          });
        });
        setassignedArray([]);
        setassignedArray(tempArray);
      }
    });
  }

  function getmembersData() {
    APIManager.getAPI(
      getAssignedMembersWithCustomer + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
      }
    });
  }

  function uploadkeywordFile() {
    setAddSow(true);
    setFileName("");
    document.querySelector("#sowFile").disabled = false;
    var input = document.getElementById("sowFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileSetelctedMsg("");
    setFileName(file.name);
    clearFile();
  }

  const clearFile = () => {
    document.querySelector(
      "#textInputUrl"
    ).disabled = false;
  }

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function sowListImport() {
    var input = document.getElementById("sowFile");
    let file = input.files.item(0);
    var bodyFormData = new FormData();
    bodyFormData.append("projectId", localStorage.getItem("projectGId"));
    bodyFormData.append("memberId", localStorage.getItem("userId"));
    let isImport = true;

    if (url &&
      document.querySelector("#textInputUrl").disabled == false &&
      isValidURL()
    ) {
      bodyFormData.append("url", removeTrailingSlash(url));
      isImport = true;
    } else if (document.querySelector("#sowFile").disabled == false) {
      isImport = true;
      bodyFormData.append("file", file);
    } else {
      isImport = false;
    }
    if (isImport) {
      APIManager.postAPI(sowImport, {}, bodyFormData, false).then(
        (response) => {
          if (response.status == 200) {
            getTicketList();
            onClose2();
            setCloseButton(false);
            setIsBulk(false);
            setUrl("");
          }
        }
      );
    }
  }

  function getIconFun(asignList) {
    let list = [];
    if (asignList.length) {
      asignList.map((item) => {
        list.push(<span>{item.charAt(0).toUpperCase()}</span>);
      });
      if (list.length < 4) {
        return list;
      } else {
        return <span>{asignList.length}</span>;
      }
    } else {
      return null;
    }
  }

  function isValid() {
    let flag = true;
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (units.trim() == "") {
      setMessageUnits("Please enter units!");
      flag = false;
    } else if (units.trim() != "") {
      if (!pattern.test(units.trim())) {
        setMessageUnits("Please enter only number!");
        flag = false;
      } else {
        setMessageUnits("");
        flag = true;
      }
    } else {
      setMessageUnits("");
      flag = true;
    }
    // if (wordCount.trim() == "") {
    //   setMessageCount("Please enter word count!");
    //   flag = false;
    // } else if (wordCount.trim() != "") {
    //   if (!pattern.test(wordCount.trim())) {
    //     setMessageCount("Please enter only number!");
    //     flag = false;
    //   } else {
    //     setMessageCount("");
    //     flag = true;
    //   }
    // } else {
    //   setMessageCount("");
    //   flag = true;
    // }
    isModelDismiss1 = flag;
    return flag;
  }

  function isValid1() {
    let flag = true;
    var pattern = new RegExp(/^[0-9\b]+$/);
    if (unitsEdit == "") {
      setMessageUnits1("Please enter units!");
      flag = false;
    } else if (unitsEdit != "") {
      if (!pattern.test(unitsEdit)) {
        setMessageUnits1("Please enter only number!");
        flag = false;
      } else {
        setMessageUnits1("");
        flag = true;
      }
    } else {
      setMessageUnits1("");
      flag = true;
    }
    // if (wordCountEdit == "") {
    //   setMessageCount1("Please enter word count!");
    //   flag = false;
    // } else if (wordCountEdit != "") {
    //   if (!pattern.test(wordCountEdit)) {
    //     setMessageCount1("Please enter only number!");
    //     flag = false;
    //   } else {
    //     setMessageCount1("");
    //     flag = true;
    //   }
    // } else {
    //   setMessageCount1("");
    //   flag = true;
    // }
    isModelDismissEdit = flag;
    return flag;
  }

  function isValidURL() {
    let error = { ...errors };
    if (url.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );
      if (!pattern.test(url.trim())) {
        error["url"] = "Please enter valid URL";
      } else {
        delete error["url"];
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  async function deleteEditproj(id) {
    try {
      const response = await APIManager.deleteAPI(deleteSOW + id);
      if (response == 200) {
        const d = new Date();
        const month =
          d.toLocaleString("default", { month: "short" }) + " " + d.getFullYear();
        setMonthName(month);
        let string = "Upload Scope Of Work(SOW) for " + month;
        setTitle(string);
        let dateArry = [];
        dateArry.push(d.getMonth() + 1);
        dateArry.push(d.getFullYear());
        var daysInMonth = getDaysInMonth(d.getFullYear(), d.getMonth() + 1);
        let dateObjArr = [
          new Date(dateArry[0] + "/01/" + dateArry[1]),
          new Date(dateArry[0] + "/" + daysInMonth + "/" + dateArry[1]),
        ];
        setValues(dateObjArr);
        setHead1(dateObjArr[0]);
        setHead2(dateObjArr[1]);
        let rowData = {
          searchText: searchText,
          startDate: mmDDyyyyFormateDateSet(dateObjArr[0]),
          endDate: mmDDyyyyFormateDateSet(dateObjArr[1]),
          projectId: localStorage.getItem("projectGId"),
        };
        getTicketList(rowData);
        getMonth();
      }
    } catch (e) { }
  }

  return (
    <>
      <div class="portlet portlet-header portlet-header-bordered ms-3 me-1 sow-heading">
        <svg
          onClick={() => update("")}
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="feather feather-chevron-left close-left-icon ms-3"
        >
          <polyline points="15 18 9 12 15 6"></polyline>
        </svg>
        <h3 class="portlet-title main-title">
          Scope Of Work
        </h3>
      </div>
      <div className="row ">
        <div className="col-md-12 col-sm-12 d-flex align-items-center sow-month p-0">
          {monthListChips.map((item, index) => (
            <div className="  ms-3 pb-0">
              <button
                type="button"
                class={
                  monthSelected && indexData == index
                    ? classSOW
                    : "btn btn-light"
                }
                onClick={() => {
                  setMonthSelected(true);
                  setIndexData(index);
                  let isMonthSelected = true;
                  getClassName(index, index, isMonthSelected);
                  let text = item.monthName;
                  let myArray = text.split(" ");
                  let monthNum = getMonthNumberFromName(myArray[0], myArray[1]);
                  var daysInMonth = getDaysInMonth(myArray[1], monthNum);
                  let dateObjArr = [
                    new Date(monthNum + "/01/" + myArray[1]),
                    new Date(monthNum + "/" + daysInMonth + "/" + myArray[1]),
                  ];
                  setHead1(dateObjArr[0]);
                  setHead2(dateObjArr[1]);
                  let rowData = {
                    searchText: searchText,
                    startDate: mmDDyyyyFormateDateSet(
                      dateObjArr[0]
                    ),
                    endDate: mmDDyyyyFormateDateSet(
                      dateObjArr[1]
                    ),
                    projectId: localStorage.getItem("projectGId"),
                  };
                  getTicketList(rowData);
                }}
              >
                {monthSelected && indexData == index ? (
                  <i class="fa fa-check me-3" aria-hidden="true"></i>
                ) : (
                  <></>
                )}
                {item.monthName}
              </button>
            </div>
          ))}
          <div ref={ref} className="px-3  pb-0">
            <button
              type="button"
              class=" btn btn-light"
              onClick={() => setOpen(!open)}
            >
              <i class="fa fa-calendar me-3" aria-hidden="true"></i>
              Pick a Month
            </button>
            {open && (
              <div className="scope-calendar">
                <div id="example-collapse-text-calendar">
                  <Card body className="daterange-picker-card  mt-2">
                    <div className="d-flex justify-content-between">
                      <div className="calendar-col">
                        <Calendar
                          maxDate={new Date()}
                          onChange={(e) => {
                            if (e.length > 1) {
                              var date1 = e[0].format().split("/");
                              var date2 = e[1].format().split("/");
                              var daysInMonth = getDaysInMonth(
                                date2[1],
                                date2[0]
                              );
                              let dateObjArr = [
                                new Date(date1[0] + "/01/" + date1[1]),
                                new Date(
                                  date2[0] + "/" + daysInMonth + "/" + date2[1]
                                ),
                              ];
                              setHead1(dateObjArr[0]);
                              setHead2(dateObjArr[1]);
                              setValues(dateObjArr);
                            }
                          }}
                          value={values}
                          onlyMonthPicker
                          range
                        />
                      </div>
                      <div className="ms-3">
                        <button
                          className="btn-outline btn btn-primary"
                          onClick={() => {
                            setClassSOW("");
                            setMonthSelected(false);
                            setClassSOW("btn btn-light");
                            setMonthSelected(false);
                            getTicketList();
                            setOpen(!open);
                          }}
                        >
                          Apply
                        </button>
                      </div>
                    </div>
                  </Card>
                </div>
              </div>
            )}
          </div>
        </div>
        <div class=" d-flex align-items-center justify-content-between">
          <div className="right-box d-flex align-items-center">
            <div className="calendar-main d-flex justify-content-end align-items-center ga-calender ms-3"></div>
          </div>
        </div>
      </div>

      <div class="all-ticket-box mt-4">
        <div class="row scope-search px-0">
          <div className="d-flex justify-content-between align-items-center position-relative">
            <div class="sow-search-box d-flex  w-100 me-3 position-relative">
              <i class="fa fa-search"></i>
              <input
                type="text"
                class="scope-input w-100"
                placeholder="Search"
                name="search"
                onChange={(industrySearch) => {
                  setTableData(
                    tableData1.filter((person) =>
                      person.activity
                        .toLocaleLowerCase()
                        .includes(
                          industrySearch.target.value.toLocaleLowerCase()
                        )
                    )
                  );
                }}
              />
            </div>
            <div className="scope-work-btn d-flex 3-icons align-items-center">
              <div class="scope-work-btn scope-work me-3">
                <span>
                  <input
                    className="w-100"
                    style={{ display: "none" }}
                    type="file"
                    id="sowFile"
                    name="sowFile"
                    multiple={false}
                    accept={documentFileType}
                    ref={inputFileRef}
                    onChange={uploadkeywordFile}
                  />
                  <a
                    class="btn btn-primary scope-download h-auto"
                    target="_blank"
                    href={GET_Server_URL + "audit/SOW.xlsx"}
                  >
                    Download Template{" "}
                  </a>
                </span>
              </div>

              <button
                class=" btn btn-light btn-icon h-auto"
                type="button"
                onClick={() => {
                  setSelectedCategory(0);
                  setActivity("");
                  setUnits("");
                  setFileName("");
                  // setWordCount("");
                  setIsBulk(false);
                  setIsBulk(false);
                  setIsIndividual(false);
                  setAddSow(false);
                  showLargeDrawer2();
                  setCloseButton(false);
                  setUrl("");
                  setErrors("");
                  setClassName1("");
                  setClassName2("");
                  const option1Btn = document.getElementById("option1");
                  const option2Btn = document.getElementById("option2");
                  option1Btn.checked = false;
                  option2Btn.checked = false;
                }}
              >
                + Upload SOW
              </button>
              <Drawer
                title={title}
                className="create-subticket industries-modal upload-scope-modal"
                placement="right"
                size={size2}
                onClose={onClose2}
                open={open2}
              >
                <div class="modal-dialog scope-modal" role="document">
                  <div class="modal-content">
                    <div class="modal-body p-4">
                      <div className="portlet common-form-fields border-0">
                        <div className="">
                          <div className={className1}>
                            <div className="radio">
                              <div className="ms-3">
                                <input
                                  className="me-3"
                                  type="radio"
                                  value="option1"
                                  id="option1"
                                  name="uploadType"
                                  onClick={(e) => {
                                    setIsBulk(e.target.checked);
                                    setIsIndividual(false);
                                    setClassName1("active");
                                    setClassName2("");
                                    setAddSow(false);
                                  }}
                                />
                                <label className="bulk-upload  main-title">
                                  Bulk Upload
                                </label>
                              </div>

                              <span className="ms-5 text-grey ">
                                Setup multiple(bulk) upload with the details
                                through CSV file
                              </span>

                              {isBulk ? (
                                <>
                                  <div className="border-top mt-3 pt-3 ">
                                    <span className="ms-5 industries-csv-file">
                                      Add URL (Google Sheet Link)
                                    </span>
                                    <div className="mt-2 ms-5 me-3">
                                      <input
                                        className="form-control w-100"
                                        placeholder="URL"
                                        id="textInputUrl"
                                        value={url}
                                        onChange={(e) => {
                                          setUrl(e.target.value);
                                          if (e.target.value.length > 0) {
                                            setAddSow(true);
                                            document.querySelector(
                                              "#sowFile"
                                            ).disabled = true;
                                          } else {
                                            setAddSow(false);
                                            setErrors("");
                                            document.querySelector(
                                              "#sowFile"
                                            ).disabled = false;
                                          }
                                        }}
                                      ></input>
                                      {save && errors?.url && (
                                        <>
                                          <div className="field-required mt-2">
                                            <span> {errors?.url}</span>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                    <div className="my-3 text-center or">
                                      <span>OR</span>
                                    </div>
                                    <span className="ms-5 csv-file">
                                      Add attachment (Only CSV file)
                                    </span>
                                  </div>
                                  <div className="scope-choose-file d-flex align-items-center p-2 mt-3 ms-5 me-3">
                                    <button
                                      onClick={onBtnClick}
                                      id="btnChooseFile"
                                      className="btn btn-primary me-3"
                                    >
                                      Choose File
                                    </button>
                                    <span className="file-not-select ">
                                      {fileSetelctedMsg}
                                    </span>
                                    {fileName.length ? fileName : ""}
                                    {closeButton ? (
                                      <>
                                        <button
                                          type="button"
                                          title="Close"
                                          style={{
                                            border: "0",
                                            background: "none",
                                          }}
                                          class="close"
                                          onClick={() => {
                                            setFileName("");
                                            setCloseButton(false);
                                            document.getElementById('sowFile').value = '';
                                            document.querySelector(
                                              "#textInputUrl"
                                            ).disabled = false;
                                          }}
                                        >
                                          {" "}
                                          <span aria-hidden="true">
                                            &times;
                                          </span>
                                        </button>
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <div className={className2}>
                            <div className="radio mt-2">
                              <div>
                                <input
                                  className="ms-3"
                                  type="radio"
                                  value="option2"
                                  id="option2"
                                  name="uploadType"
                                  onClick={(e) => {
                                    setIsIndividual(e.target.checked);
                                    setIsBulk(false);
                                    setClassName2("active");
                                    setClassName1("");
                                    setMessageCount("");
                                    setMessageUnits("");
                                    setAddSow(true);
                                  }}
                                />
                                <label className=" individual-upload  ms-3 main-title">
                                  Individual Upload
                                </label>
                              </div>
                              <span className="mt-3 ms-5 text-grey">
                                Setup individual SOW tasks with details
                              </span>
                              <div>
                                {isIndividual ? (
                                  <div className="portlet-body  individual-SOW ">
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label">
                                        Category
                                      </label>
                                      <select
                                        style={{ padding: 8 + "px" }}
                                        className="w-100 rounded form-select"
                                        value={selectedCategory}
                                        onChange={(e) => {
                                          setSelectedCategory(e.target.value);
                                        }}
                                      >
                                        <option value="0">Content</option>
                                        <option value="1">Off Page</option>
                                        <option value="2">Technical</option>
                                        <option value="3">On Page</option>
                                      </select>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label">
                                        Responsibilities
                                      </label>
                                      <select
                                        className="rounded form-select"
                                        required
                                        value={clientSelected}
                                        onChange={(e) => {
                                          setClientSelected(e.target.value);
                                        }}
                                      >
                                        {clientList.map((item) => {
                                          return (
                                            <option value={item.id}>
                                              {item.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label">
                                        Activities
                                      </label>
                                      <label class="field-required"> * </label>
                                      <input
                                        type="text"
                                        value={activity}
                                        onChange={(text) => {
                                          setActivity(text.target.value);
                                        }}
                                      />
                                    </div>

                                    <div class="col-md-12 unit mt-3">
                                      <label className="form-label">
                                        Total Units
                                      </label>
                                      <label class="field-required"> * </label>
                                      <input
                                        className="form-control form-control-lg"
                                        value={units}
                                        onChange={(e) => {
                                          setUnits(e.target.value);
                                        }}
                                      />
                                      <div style={{ color: "red" }}>
                                        {messageUnits}
                                      </div>
                                    </div>

                                    <div class="col-md-12 unit mt-3">
                                      <label className="form-label">
                                        Frequency
                                      </label>

                                      <select
                                        style={{ padding: 8 + "px" }}
                                        className="w-100 rounded form-select"
                                        value={frequency}
                                        onChange={(e) => {
                                          setFrequency(e.target.value);
                                        }}
                                      >
                                        <option value="0">Monthly</option>
                                        <option value="1">Quarterly</option>
                                        <option value="2">Yearly</option>
                                      </select>
                                    </div>

                                    {/* <div class="col-md-12 unit mt-3">
                                      <label className="form-label">
                                        Word Count
                                      </label>
                                      <label class="field-required"> * </label>
                                      <input
                                        className="form-control form-control-lg"
                                        value={wordCount}
                                        onChange={(e) =>
                                          setWordCount(e.target.value)
                                        }
                                      />
                                      <div style={{ color: "red" }}>
                                        {messageCount}
                                      </div>
                                    </div> */}
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="btn-box-bottom">
                          {addSow ? (
                            <div className="col-md-12 d-flex justify-content-end">
                              <div class="portlet-footer portlet-footer-bordered mt-3 text-end scopebtn">
                                <button
                                  className="btn btn-outline-secondary me-3"
                                  onClick={onClose2}
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn btn-primary w-auto"
                                  data-dismiss={isModelDismiss1 ? "modal" : ""}
                                  onClick={() => {
                                    if (isBulk) {
                                      setsave(true);
                                      sowListImport();
                                    } else {
                                      if (isValid()) {
                                        addTicket();
                                      }
                                    }
                                  }}
                                >
                                  <div>Create SOW Tasks</div>
                                </button>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
              <Drawer
                title="Scope Of Work"
                className="industries-modal create-subticket-modal sow-modal"
                placement="right"
                size={size3}
                onClose={onClose3}
                open={open3}
              >
                <div class="modal-dialog scopofwork-modal " role="document">
                  <div class="modal-content">
                    <div class="modal-body p-0 scopeofwork-modal">
                      <div className="portlet common-form-fields border-0">
                        <div className="portlet-body">
                          <div class="col-md-12 ">
                            <label className="form-label">Category</label>
                            <select
                              style={{ padding: 8 + "px" }}
                              className="w-100 rounded form-select"
                              value={selectedCategoryEdit}
                              onChange={(e) => {
                              }}
                            >
                              <option value="0">Content</option>
                              <option value="1">Off Page</option>
                              <option value="2">Technical</option>
                              <option value="3">On Page</option>
                            </select>
                          </div>

                          <div class="col-md-12 mt-3">
                            <label className="form-label">
                              Responsibilities
                            </label>
                            <select
                              className="rounded form-select"
                              required
                              value={clientSelectedEdit}
                              onChange={(e) => {
                              }}
                            >
                              {clientListEdit.map((item) => {
                                return (
                                  <option value={item.id}>{item.name}</option>
                                );
                              })}
                            </select>
                          </div>
                          <div class="col-md-12 mt-3">
                            <label className="form-label">Activities</label>
                            <label class="field-required"> * </label>
                            <input
                              className="form-control form-control-lg"
                              value={activityEdit}
                            />
                          </div>

                          <div class="col-md-12 mt-3">
                            <label className="form-label">Total Units</label>
                            <label class="field-required"> * </label>
                            <input
                              className="form-control form-control-lg"
                              value={unitsEdit}
                              onChange={(e) => setUnitsEdit(e.target.value)}
                            />
                            <div style={{ color: "red" }}>{messageUnits1}</div>
                          </div>

                          <div class="col-md-12 mt-3">
                            <label className="form-label">Frequency</label>
                            <select
                              style={{ padding: 8 + "px" }}
                              className="w-100 rounded form-select"
                              value={frequencyEdit}
                              onChange={(e) => {
                              }}
                            >
                              <option value="0">Monthly</option>
                              <option value="1">Quarterly</option>
                              <option value="2">Yearly</option>
                            </select>
                          </div>

                          {/* <div class="col-md-12 mt-3">
                            <label className="form-label">Word Count</label>
                            <label class="field-required"> * </label>
                            <input
                              className="form-control form-control-lg"
                              value={wordCountEdit}
                            />
                            <div style={{ color: "red" }}>{messageCount1}</div>
                          </div> */}
                        </div>
                        <div className="btn-box-bottom">
                          <div className="col-md-12  ">
                            <div class="portlet-footer portlet-footer-bordered mt-3 send-approval w-100">
                              <button
                                type="button"
                                class="btn btn-primary w-100"
                                data-dismiss={isModelDismissEdit ? "modal" : ""}
                                style={{
                                  width: 200 + "px",
                                  height: 30 + "px",
                                }}
                                onClick={() => {
                                  if (isValid1()) {
                                    sendForApprovalFn();
                                  }
                                }}
                              >
                                <div>
                                  <i class="fa fa-check me-3"></i>Send for
                                  Approval
                                </div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
              <Drawer
                title="Create Sub Ticket"
                className="create-subticket-modal industries-modal p-0 align-items-center"
                placement="right"
                size={size1}
                onClose={onClose1}
                open={open1}
              >
                <div class="modal-dialog subticket-modal" role="document">
                  <div class="modal-content">
                    <div className="col-md-12 scope-ticket-btn">
                      <div class="portlet-footer portlet-footer-bordered mt-3 add-SOW-Ticket w-100">
                        <button
                          id="addSOWTicket"
                          type="button"
                          class="btn btn-primary w-100"
                          data-dismiss={isModelDismiss ? "modal" : ""}
                          onClick={() => {
                            addAllocation();
                          }}
                        >
                          {/* Add SOW Ticket */}
                          {addSowTicketButton}
                        </button>
                        <div class="mt-3 text-center unit-remmaining">
                          {message}
                        </div>
                      </div>
                    </div>
                    <div class="modal-body p-0">
                      <div className="portlet common-form-fields border-0 ">
                        <div className="portlet-body mt-2">
                          <div className="row">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-top justify-content-start">
                                <div className="subticket-svg">
                                  <svg
                                    width="26"
                                    height="20"
                                    viewBox="0 0 26 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="sub-addticket-icon"
                                  >
                                    <path
                                      d="M23 10C23 9.05 23.5375 8.225 24.325 7.8C25.075 7.3875 25.5 6.5375 25.5 5.675V2.5C25.5 1.125 24.375 0 23 0H3C1.625 0 0.5125 1.1125 0.5125 2.4875V5.675C0.5125 6.5375 0.925 7.3875 1.6875 7.7875C2.475 8.225 3 9.05 3 10C3 10.95 2.4625 11.7875 1.675 12.2C0.925 12.6125 0.5 13.4625 0.5 14.325V17.5C0.5 18.875 1.625 20 3 20H23C24.375 20 25.5 18.875 25.5 17.5V14.325C25.5 13.4625 25.075 12.6125 24.325 12.2C23.5375 11.775 23 10.95 23 10ZM16.125 15.125L13 13.125L9.875 15.1375C9.4 15.4375 8.7875 15 8.9375 14.45L9.875 10.85L7 8.5C6.5625 8.1375 6.7875 7.425 7.3625 7.3875L11.0625 7.175L12.4125 3.7375C12.625 3.2125 13.375 3.2125 13.575 3.7375L14.925 7.1875L18.625 7.4C19.1875 7.4375 19.425 8.15 18.9875 8.5125L16.1125 10.8625L17.0625 14.4375C17.2125 15 16.6 15.4375 16.125 15.125Z"
                                      fill="#0691EF"
                                    />
                                  </svg>
                                </div>
                                <h6 className="form-label text-capitalize activity-heading ms-3 w-75">
                                  {activityHeading}
                                </h6>
                              </div>
                              <button
                                className="btn btn-primary d-flex align-items-center scope-addteam-member"
                                onClick={addContent}
                                id="createAllocationBtn">
                                <i class="fa fa-plus" aria-hidden="true" ></i>
                                <span>Add Team Member</span>
                              </button>
                            </div>
                          </div>
                          <div className="d-flex mt-1 align-items-center scope-total-unit">
                            <label className="form-label me-3">
                              Total Units
                            </label>
                            <input
                              className="form-control"
                              value={unitsData}
                            />
                          </div>
                        </div>
                        {assignedArray.map((item, index) => (
                          <div className="portlet-body border-top pt-2">
                            <div className="d-flex align-items-center sow-create justify-content-between ">
                              <div className="d-flex align-items-center width-available">
                                <div className="me-2 w-100">
                                  <label className="form-label justify-content-between assignto">
                                    Assigned to
                                  </label>
                                  <select
                                    className="form-select w-100"
                                    value={assignedArray[index].AssignedTo}
                                    onChange={(e) => {
                                      const updateArrayObject =
                                        assignedArray.map((item1, i) => {
                                          if (i === index) {
                                            let obj = {
                                              AssignedTo: e.target.value,
                                              AssignedUnit: item1.AssignedUnit,
                                              AssignedId: item.AssignedId,
                                              isNew: item.isNew,
                                              AllocationId: item.AllocationId,
                                            };
                                            return obj;
                                          } else {
                                            return item1;
                                          }
                                        });
                                      setassignedArray(updateArrayObject);
                                    }}
                                  >
                                    {memberlist.map((item) => {
                                      return (
                                        <option value={item.id}>
                                          {item.name}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                                <div className=" scope-unit w-100">
                                  <label className="form-label justify-content-between assignto">
                                    Units
                                  </label>
                                  <input
                                    className=" form-control w-100"
                                    value={assignedArray[index].AssignedUnit}
                                    onChange={(e) => {
                                      var pattern = new RegExp(/^[0-9\b]+$/);
                                      if (e.target.value) {
                                        if (!pattern.test(e.target.value)) {
                                          setMessage1(
                                            "Please enter only numbers"
                                          );
                                          document.querySelector(
                                            "#addSOWTicket"
                                          ).disabled = true;
                                        } else {
                                          document.querySelector(
                                            "#addSOWTicket"
                                          ).disabled = false;
                                          setMessage1("");
                                        }
                                      }
                                      const updateArrayObject =
                                        assignedArray.map((c, i) => {
                                          if (i === index) {
                                            let obj = {
                                              AssignedTo: c.AssignedTo,
                                              AssignedUnit: e.target.value,
                                              AssignedId: c.AssignedId,
                                              isNew: c.isNew,
                                              AllocationId: item.AllocationId,
                                            };
                                            return obj;
                                          } else {
                                            return c;
                                          }
                                        });
                                      setassignedArray(updateArrayObject);
                                      totalUnitsFun(updateArrayObject);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="svg-box mt-4 pt-3 ms-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-trash-2 scope-trash-icon trash-icon text-danger my-3"
                                  onClick={() => {
                                    removeContent(index);
                                    deleteSowFun(item.AssignedId);
                                  }}
                                >
                                  <polyline points="3 6 5 6 21 6"></polyline>
                                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                  <line x1="10" y1="11" x2="10" y2="17"></line>
                                  <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                              </div>
                            </div>
                          </div>
                        ))}
                        <div style={{ color: "red" }}>{message1}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </Drawer>
            </div>
          </div>
        </div>
        <div className="col-md-12 sow-table-info mt-3 ms-2">
          <h4>SOW Tickets ({ticketLength})</h4>
          <span>All the SOW tickets are listed below</span>
        </div>
        <div class="col-md-12 mt-3">
          <Table
            className="table table-hover mt-3 scope-table bg-white "
            id="sample-module-expand"
            columns={tableColumns}
            dataSource={tableData}
            rowSelection={{ type: "checkbox", ...rowSelection }}
            pagination={{
              position: ["bottomRight"],
              showSizeChanger: true,
            }}
          />
        </div>
      </div>
    </>
  );
}

export default ScopeOfWork;
