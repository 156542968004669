import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import APIManager from "../../../services/APIManager";
import { Addcampaignviewkeyword, getCategoryByProject, getKeywordTypeAPI, updateSearch } from "../../../utility/Constant";

const Addcampaignviewkeword = (props) => {
  const location = useLocation();
  const [categoryType, setCategoryType] = useState([]);
  const [fieldValue, SetfieldValue] = useState("");
  const [SearchVolume, setSearchVolume] = useState();
  const [CategorySelected, setCategorySelected] = useState("");
  const [keywordtypeselected, setkeywordtypeselected] = useState("");
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);
  const notifyAdd = () => toast.success("Successfully Added!");
  const [selectedprioriry, setselectedprioriry] = useState("1");
  const [selectedtype, setselectedtype] = useState("0");
  const [checked, setChecked] = useState(true);

  const keywordtype = [
    {
      value: "0",
      lable: "Brand",
    },
    {
      value: "1",
      lable: "Non Brand ",
    },
    // {
    //   value: "2",
    //   lable: "All",
    // },
  ]

  useEffect(() => {
    getCategoryData();
    getKeywordType();
    if (location.state.isEdit) {
      SetfieldValue(location.state.item.fieldValue);
      setSearchVolume(location.state.item.searchVolume);
      setCategorySelected(location.state.item.categoryId);
    }
  }, []);

  function formValidation() {
    let error = { ...errors };
    fieldValue.trim() == ""
      ? (error["fieldValue"] = "Keyword is required!")
      : delete error["fieldValue"];

    !SearchVolume
      ? (error["SearchVolume"] = "SearchVolume is required!")
      : delete error["SearchVolume"];

    setErrors(error);

    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function addkeyword() {

    APIManager.postAPI(
      Addcampaignviewkeyword,
      {
        Keyword: fieldValue.trim(),
        KeywordCategoryId: CategorySelected,
        KeywordTypeId: keywordtypeselected,
        RankingCampaignViewConfigurationId: props.campaignviewId,
        Priority: selectedprioriry,
        SearchVolume: SearchVolume,
        Types: selectedtype
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        props.update("");
        clearForm();
        notifyAdd();
      }
    });
  }

  function updatekeyword() {
    APIManager.putAPI(
      updateSearch,
      {
        id: location.state.item.id,
        Keyword: fieldValue.trim(),
        KeywordCategoryId: CategorySelected,
        KeywordTypeId: keywordtypeselected,
        RankingCampaignConfigurationId: props.campaignviewId,
        Priority: selectedprioriry,
        SearchVolume: SearchVolume,
        Types: selectedtype
      },
      null,
      true
    ).then((response) => {

      if (response && response.status == 200) {
        clearForm();
      }
    });
  }

  function getCategoryData() {
    APIManager.getAPI(
      getCategoryByProject + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        setCategoryType(response.data);
        setCategorySelected(response.data[0]?.id);
      }
    });
  }

  function getKeywordType() {
    APIManager.getAPI(
      getKeywordTypeAPI + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        setkeywordtypeselected(response.data[0]?.id);
      }
    });
  }

  function clearForm() {
    SetfieldValue("");
    setSearchVolume("");
    setCategorySelected("");
  }
  function prioritytype(e) {
    let value = e.target.value;
    setselectedprioriry(value);
  }
  return (
    <>
      <div className="common-form-fields slideInUp">
        <div className="portlet-body ranking-keywordadd-form p-0">
          <div class="col-md-12">
            <label className="form-label mb-2">Keyword</label>
            <label class="field-required"> * </label>
            <input
              className="form-control w-100"
              type="text"
              name=""
              placeholder="Enter Keyword"
              value={fieldValue}
              onChange={(text) => {
                SetfieldValue(text.target.value);
              }}
            ></input>
            {save && errors?.fieldValue && (
              <>
                <div className="field-required">
                  <span> {errors?.fieldValue}</span>
                </div>
              </>
            )}
          </div>

          <div className="col-md-12 mt-4 ">
            <label className="form-label mb-2">Category</label>
            <br />
            <select
              className="w-100 form-select"
              style={{ padding: 8 + "px" }}
              required
              value={CategorySelected}
              onChange={(e) => {
                var c = e.target.value;
                setCategorySelected(c);
              }}
            >
              {categoryType?.map((item) => {
                return <option value={item.id}>{item.categoryType}</option>;
              })}
            </select>
          </div>
          <div className="col-md-12 mt-3 ">
            <div className="d-flex align-items-center">
              <label className="form-label">Priority</label>
              <input
                className="ms-3"
                type="checkbox"
                checked={checked}
                onChange={(e) => {
                  if (e.target.checked) {
                    setselectedprioriry("1");
                    setChecked(true)
                  } else {
                    setselectedprioriry("0");
                    setChecked(false)
                  }
                }}
              />
            </div>
          </div>
          <div className="col-md-12 mt-3 ">
            <label className="form-label mb-2">Search Volume</label>
            <input
              className="form-control w-100"
              type="number"
              name=""
              placeholder="Enter Search Volume"
              value={SearchVolume}
              onChange={(text) => {
                const re = /^[0-9\b]+$/;
                if (text.target.value === '' || re.test(text.target.value)) {
                  setSearchVolume(text.target.value);
                }
              }}
            ></input>
            {save && errors?.SearchVolume && (
              <>
                <div className="field-required">
                  <span> {errors?.SearchVolume}</span>
                </div>
              </>
            )}
          </div>
          <div className="col-md-12 mt-4 ">
            <label className="form-label mb-2">Type</label>
            <br />
            <select
              className="w-100 form-select"
              style={{ padding: 8 + "px" }}
              required
              value={selectedtype}
              onChange={(e) => {
                var c = e.target.value;
                setselectedtype(c);
              }}
            >
              {keywordtype?.map((item) => {
                return <option value={item.value}>{item.lable}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered mt-4 pb-0">
          <div className="col-md-12 d-flex justify-content-end px-1">
            <button
              onClick={() => {
                setsave(true);
                if (formValidation()) {
                  location.state.isEdit ? updatekeyword() : addkeyword();
                }
              }}
              className="btn btn-primary me-3"
            >
              {location.state.isEdit ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-outline"
              onClick={() => {
                props.update("");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Addcampaignviewkeword;
