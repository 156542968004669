import React, { useEffect, useRef, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  updateAudit,
  getAuditModule,
  getAuditById,
  GET_Server_URL,
  GET_FrontEnd_URL,
} from "../../utility/Constant";
import { useLocation } from "react-router-dom";
// import JoditEditor from "jodit-pro-react";
import JoditEditor from 'jodit-react';
import { Table } from "antd";
import Loading from "../../utility/Loading";
import { getCheckPermission } from "../../utility/Globals";

const AuditUpdate = ({ update }) => {
  const location = useLocation();
  const [auditTitle, setAuditTitle] = useState("");
  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",

      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "eraser",
      "copyformat",
      "|",
      "ol",
      "ul",
      "align",
      "|",
      "table",
      "indent",
      "outdent",
      "hr",
      "link",
      "image",
      "video",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "font", "fontsize", "paragraph"],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    },
  };

  useEffect(() => {
    getAuditModuleList();
    setAuditTitle(location.state.listItem?.pointTitle);
  }, []);

  const [isLoading, setLoading] = useState(false);
  const [valueData, setValueData] = useState({});
  function getAuditDescriptionData() {
    APIManager.getAPI(getAuditById + location.state.listItem.id).then(
      (response) => {
        if (response && response.status == 200) {
          setDescriptionData(response?.data?.value?.description);
          setValueData(response.data.value);
          setAuditSelected(response?.data?.value?.auditModuleId);
          setWaitageSelected(response?.data?.value?.waitage);
          setRecommendation(response?.data?.value?.recommendation);
        }
      }
    );
  }

  function updateAuditItem(auditDescription) {
    var bodyFormData = new FormData();
    bodyFormData.append("id", location.state.listItem.id);
    bodyFormData.append(
      "organizationId",
      localStorage.getItem("organizationId")
    );
    bodyFormData.append("pointTitle", auditTitle?.trim());
    bodyFormData.append("AuditModuleId", auditSelected);
    bodyFormData.append("waitage", waitageSelected);
    bodyFormData.append("Description", auditDescription?.trim());
    bodyFormData.append("Recommendation", recommendation?.trim());
    bodyFormData.append("AuditId", location.state.listItem.id);
    APIManager.putAPI(updateAudit, {}, bodyFormData, false).then((response) => {
      if (response && response.status == 200) {
        clearData();
      }
    });
  }

  function clearData() {
    setDescriptionData("");
    setAuditTitle("");
    setWaitageSelected("");
    update("auditChecklist");
  }

  const [auditList, setAuditList] = useState([]);
  const [auditSelected, setAuditSelected] = useState("");
  const [waitageSelected, setWaitageSelected] = useState(10);

  function getAuditModuleList() {
    APIManager.getAPI(getAuditModule).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.auditModuleName,
          };
          list.push(obj);
          setAuditSelected(response?.data[0]?.id);
          if (item?.auditModuleName == location.state?.listItem?.name) {
            setAuditSelected(item?.id);
          } else {
          }
        });
        setAuditList(list);
      }
      getAuditDescriptionData();
    });
  }

  const [descriptionData, setDescriptionData] = useState("");
  const [recommendation, setRecommendation] = useState("");
  const [onBlurStatus, setonBlur] = useState(false);
  const editor = useRef(null);
  const editor1 = useRef(null);

  useEffect(() => { }, [onBlurStatus]);

  const changeHandler = (event) => {
    setAuditTitle(event.target.value);
  };

  return (
    <>
      <div className="custom-column-70">
        <div class="portlet slideInUp setting-top">
          <div class="portlet-header portlet-header-bordered">
            <svg onClick={() => {
              update("auditChecklist");
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
            <h3 class="portlet-title main-title">
              {localStorage.getItem("organizationName")} - Update Audit Point
            </h3>
          </div>
          <div className="portlet-body">
            <div class="common-wrapper">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user">
                    <div class="col-md-12 mt-3">
                      <label>Module</label>
                      <select
                        required
                        value={auditSelected}
                        onChange={(e) => {
                          setAuditSelected(e.target.value);
                        }}
                      >
                        {auditList.map((item) => {
                          return <option value={item.id}>{item.name}</option>;
                        })}
                      </select>
                    </div>

                    <div
                      style={getCheckPermission(
                        "audit_checklist",
                        "organization_setup"
                      ) ? { display: "block" } : { display: "none" }}
                      class="col-md-12 mt-3"
                    >
                      <label className="form-label">Audit Weightage</label>
                      <input
                        type="number"
                        className="form-control w-100"
                        placeholder="0"
                        min="0"
                        max="20"
                        value={waitageSelected}
                        onChange={(e) => {
                          setWaitageSelected(e.target.value);
                        }}
                      />
                    </div>

                    <div class="col-md-12 mt-3">
                      <label>Audit Point</label>
                      <input
                        type="text"
                        value={auditTitle}
                        onChange={changeHandler}
                        placeholder="Please enter your name"
                      />
                    </div>

                    <div class="col-md-12 mt-3 audit-editor">
                      <label>Description</label>
                      <JoditEditor
                        ref={editor}
                        value={descriptionData ? descriptionData : ''}
                        config={config}
                        tabIndex={1}
                        onBlur={(text) => {
                          setonBlur(true);
                          setDescriptionData(text);
                          var e = document.getElementById("audit-1");
                          e.scrollIntoView({
                            block: "end",
                            behavior: "smooth",
                            inline: "center",
                          });
                        }}
                        onChange={() => {
                          setonBlur(false);
                        }}
                      ></JoditEditor>
                    </div>

                    <div class="col-md-12 mt-3 audit-editor">
                      <label>Recommendation</label>
                      <JoditEditor
                        ref={editor1}
                        value={recommendation ? recommendation : ''}
                        config={config}
                        tabIndex={1}
                        onBlur={(text) => {

                          setonBlur(true);
                          setRecommendation(text);
                          var e = document.getElementById("audit-1");
                          e.scrollIntoView({
                            block: "end",
                            behavior: "smooth",
                            inline: "center",
                          });
                        }}
                        onChange={() => {
                          setonBlur(false);
                        }}
                      ></JoditEditor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="portlet-footer portlet-footer-bordered mb-4">
            <div className="col-md-12">
              <button
                class="btn btn-primary me-3"
                onClick={() => {
                  if (descriptionData != "") {
                    updateAuditItem(descriptionData);
                  }
                }}
              >
                Update
              </button>
              <button
                className="btn btn-outline-secondary"
                onClick={() => {
                  clearData();
                }}
              >
                Cancel
              </button>
            </div>
          </div>

          <div className="col-md-12">
            <Table
              className="table table-bordered  table-hover mt-3"
              style={{
                visibility: "hidden",
              }}
              id="audit-1"
              columns={[]}
              dataSource={[]}
            />
          </div>
        </div>
      </div>
      <Loading isLoading={isLoading} />
    </>
  );
};

export default AuditUpdate;
