import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  getUrlByOrgId,
  postSiteUptimeMonitorConfig,
  getSiteUptimeMonitorGetConfig,
  createUrl,
} from "../../utility/Constant";
import {
  Fields,
  frequencyListKeyValue,
  frequencyListt,
  selectAllList,
  themeStyle,
} from "../../utility/Globals";
import { default as ReactSelect } from "react-select";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";

const SiteUptimeMonitor = (props) => {
  const history = useHistory();
  const [frequency, setFrequency] = useState("");
  const [fields, setFields] = useState("0");
  const [urlList, setURlList] = useState([]);
  const [optionSelected1, setoptionSelected1] = useState("");
  const [IsActive, SetIsActive] = useState(false);
  const [urlName, setURLName] = useState("");
  const [oldUrlList, setOldUrlList] = useState([]);
  const notifyUpdate = () => toast.success("Successfully Updated!");

  useEffect(() => {
    getUrlList();
    setFrequency(frequencyListt[0].value);
  }, []);

  function postSiteUptimeMonitorConfigData() {
    let arr = [];

    optionSelected1.map((item) => {
      if (item.id != "0") {
        arr.push({ UrlId: item.id });
      }
    });
    APIManager.postAPI(
      postSiteUptimeMonitorConfig,
      {
        siteUptimeURLs: arr,
        ProjectId: localStorage.getItem("projectGId"),
        Frequency: frequency,
        Field: fields,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        history.goBack();
        notifyUpdate();
      }
    });
  }
  function addUrl() {
    APIManager.postAPI(
      createUrl,
      {
        uckType: 0,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: urlName,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        if (IsActive) {
          setURLName("");
          getUrlList();
          SetIsActive(false);
        }
      }
    });
  }
  function getUrlList() {
    APIManager.getAPI(getUrlByOrgId + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          const list = [];
          list.push({
            count: 0,
            key: "key",
            value: "Select All",
            label: "Select All",
            id: 0,
            url: "Select All",
          });

          response.data.map((item) => {
            let obj = {
              key: item.id,
              id: item.id,
              value: item.fieldValue,
              label: item.fieldValue,
              url: item.fieldValue,
            };
            setFields();
            list.push(obj);
          });
          setURlList(list);
          setoptionSelected1(list[0].id);
          getSiteUptimeMonitorData();
        }
      }
    );
  }
  function getSiteUptimeMonitorData() {
    APIManager.getAPI(
      getSiteUptimeMonitorGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response?.data) {
          setFrequency(response?.data.frequency);
          setFields(response?.data.field);

          let urlList = [];
          response?.data.siteUptimeURLs.map((item) => {
            urlList.push({
              id: item.urls.id,
              key: item.urls.id,
              label: item.urls.fieldValue,
              url: item.urls.fieldValue,
              value: item.urls.fieldValue,
            });
          });
          setoptionSelected1(urlList);
        }
      }
    });
  }

  return (
    <div className="custom-column-70">
      <div className="col-md-12 d-flex mb-3 p-0">
        <i
          class="fa fa-angle-left back-arrow"
          onClick={() => {
            history.goBack();
          }}
        ></i>
      </div>

      <div className="portlet  common-form-fields">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Site Uptime Monitor</h3>
        </div>

        <div class="portlet-body">
          <div class="col-md-12">
            <label className="form-label">URL</label>
            <div class="col-md-12 d-flex justify-content-between w-set ps-0">
              <ReactSelect
                styles={themeStyle()}
                className="w-200"
                options={urlList}
                isMulti={true}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                onChange={(selectedDomainUrl) => {
                  setoptionSelected1(
                    selectAllList(selectedDomainUrl, oldUrlList, urlList)
                  );
                  setOldUrlList(selectedDomainUrl);
                }}
                value={optionSelected1}
                allowSelectAll={true}
              />

              <button
                style={{ border: "none", background: "none" }}
                onClick={() => {
                  SetIsActive(true);
                }}
              >
                {" "}
                <i class="fa fa-plus-circle" aria-hidden="true"></i>{" "}
              </button>
            </div>
            {IsActive ? (
              <div class="col-md-12 d-flex mt-3">
                <label className="form-label"></label>
                <input
                  className="w-50"
                  type="text"
                  name=""
                  placeholder="Enter URL"
                  value={urlName}
                  onChange={(text) => {
                    setURLName(text.target.value);
                  }}
                />

                <button
                  style={{ border: "none", background: "none" }}
                  onClick={() => {
                    SetIsActive(false);
                    setURLName("");
                  }}
                >
                  {" "}
                  <i class="fa fa-times d-flex" aria-hidden="true"></i>{" "}
                </button>

                <button
                  style={{ border: "none", background: "none" }}
                  id={"rightbtn"}
                  onClick={() => {
                    if (urlName) {
                      addUrl();
                    }
                  }}
                >
                  {" "}
                  <i class="fa fa-check" aria-hidden="true"></i>{" "}
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div class="col-md-12 mt-3">
            <label
              className="form-label"
              htmlFor=""
              style={{ marginRight: 24 + "px" }}
            >
              Fields
            </label>

            <select
              class="w-100"
              style={{ padding: 8 + "px" }}
              value={fields}
              onChange={(e) => {
                var c = e.target.value;
                setFields(c);
              }}
            >
              {Fields.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>

          <div class="col-md-12 mt-3">
            <label
              className="form-label"
              htmlFor=""
              style={{ marginRight: 24 + "px" }}
            >
              Set Frequency
            </label>
            <select
              class="w-100"
              style={{ padding: 8 + "px" }}
              value={frequency}
              onChange={(e) => {
                var c = e.target.value;
                setFrequency(c);
              }}
            >
              {frequencyListKeyValue.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div class="col-md-12">
            <button
              class="btn btn-primary"
              onClick={() => {
                postSiteUptimeMonitorConfigData();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteUptimeMonitor;
