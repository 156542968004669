import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import APIManager from "../../services/APIManager";
import {
  getAccountlist,
  getGAdData,
  getGoogleAnalyticsConfig,
  getPropertylist,
  getViewSelectionlist,
} from "../../utility/Constant";

const GoogleAnalytics = ({ update }) => {
  const [viewSelection, setviewSelection] = useState([]);
  const [propertylist, setpropertylist] = useState([]);
  const [accountlist, setaccountlist] = useState([]);
  const [accountTypeSelected, setaccountTypeSelected] = useState("");
  const [propertyTypeSelected, setpropertyTypeSelected] = useState("");
  const [viewselected, setviewselected] = useState("");
  const [fileUpload, setfileUpload] = useState(null);
  const [isView, setIsView] = useState(false);
  const [acquistionCheckbox, setacquistionCheckbox] = useState(false);

  useEffect(() => {
    getConfigData();
  }, []);

  function getAccountlistData() {
    APIManager.getAPI(getAccountlist + localStorage.getItem("projectGId")).then(
      (response) => {
        if (response && response.status == 200) {
          if (response.data.result.items != null) {
            setaccountlist(response.data.result.items);
            let acid = response.data?.result?.items[0]?.id;
          }
        }
      }
    );
  }

  function getaccountselected(e) {
    var acc_selected = e.target.value;
    getPropertylistData(acc_selected);
    setaccountTypeSelected(acc_selected);
  }

  function getPropertylistData(accountId) {
    APIManager.getAPI(
      getPropertylist +
        "?accountId=" +
        accountId +
        "&projectId=" +
        localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response.data.result.items != null) {
          setpropertylist(response.data.result.items);
        }
      }
    });
  }

  function getpropertyselected(e) {
    var propr_selected = e.target.value;
    setpropertyTypeSelected(propr_selected);
    getViewSelectionlistData(accountTypeSelected, propr_selected);
  }

  function getViewSelectionlistData(a_id, p_id) {
    APIManager.getAPI(
      getViewSelectionlist +
        "?accountId=" +
        a_id +
        "&properyId=" +
        p_id +
        "&projectId=" +
        localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response.data.result.items != null) {
          setviewSelection(response.data.result.items);
        }
      }
    });
  }

  function getViewselected(e) {
    var view_selected = e.target.value;
    setviewselected(view_selected);
  }

  function updateFile() {
    var input = document.getElementById("myfile");
    var data = [];
    for (var i = 0; i < input.files.length; ++i) {
      data.push(input.files.item(i).name);
    }
    setfileUpload(input.files.item(0));
    addUpdateGoogleAnalytics(input.files.item(0));
  }

  function getConfigData() {
    APIManager.getAPI(
      getGoogleAnalyticsConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        setacquistionCheckbox(response.data.acquistionCheckbox);
        setfileUpload({ name: response.data.credentials.split("\\")[1] });
        setaccountTypeSelected(response.data.accounts);
        getPropertylistData(response.data.accounts);
        setpropertyTypeSelected(response.data.property);
        getViewSelectionlistData(
          response.data.accounts,
          response.data.property
        );
        setviewselected(response.data.view);
        setIsView(true);
        getAccountlistData();
      }
    });
  }

  function addUpdateGoogleAnalytics(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("File", file);
    bodyFormData.append("AcquistionCheckbox", acquistionCheckbox);
    bodyFormData.append("Accounts", accountTypeSelected);
    bodyFormData.append("Property", propertyTypeSelected);
    bodyFormData.append("View", viewselected);
    bodyFormData.append("projectId", localStorage.getItem("projectGId"));
    APIManager.postAPI(getGAdData, {}, bodyFormData, false).then((response) => {
      if (response && response.status == 200) {
        setIsView(true);
        getAccountlistData();
        update("");
      }
    });
  }

  const history = useHistory();
  return (
    <div className="custom-column-70">
      <div className="portlet common-form-fields slideInUp">
        <div class="portlet-header portlet-header-bordered">
        <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - Traffic
          </h3>
        </div>
        <div className="portlet-body">
          <div class="col-md-12">
            <label class="form-label me-2"> Credential Document Upload</label>
            <input
              type="file"
              id="myfile"
              name="myfile"
              multiple={false}
              onChange={updateFile}
            />
            <label className="ga-file ms-0" id="fileLabel">
              {fileUpload?.name}
            </label>
          </div>

          {isView ? (
            <div>
              <div className="col-md-12 googleanalytics mt-3">
                <input
                  type="checkbox"
                  checked={acquistionCheckbox}
                  onChange={(e) => setacquistionCheckbox(!acquistionCheckbox)}
                ></input>
                <label className="form-label ms-2">Acquistion </label>
              </div>

              <div class="col-md-12 mt-3">
                <label for="acc" className="form-label me-2">
                  Account
                </label>
                <select
                  style={{ padding: 8 + "px" }}
                  value={accountTypeSelected}
                  onChange={getaccountselected}
                  name="acc"
                  id="acc"
                  class="w-100"
                >
                  <option value="" selected="selected">
                    Select Account
                  </option>
                  {accountlist.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>

              <div class="col-md-12 mt-3">
                <label className="form-label" for="acc">
                  Property
                </label>
                <select
                  style={{ padding: 8 + "px" }}
                  value={propertyTypeSelected}
                  onChange={getpropertyselected}
                  name="acc"
                  id="acc"
                  class="w-100"
                >
                  <option value="" selected="selected">
                    Select Property
                  </option>
                  {propertylist.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>

              <div class="col-md-12 mt-3">
                <label className="form-label" for="acc">
                  View Selection
                </label>
                <select
                  style={{ padding: 8 + "px" }}
                  value={viewselected}
                  onChange={getViewselected}
                  class="w-100"
                >
                  <option value="" selected="selected">
                    Select View
                  </option>
                  {viewSelection.map((item) => {
                    return <option value={item.id}>{item.name}</option>;
                  })}
                </select>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div class="portlet-footer portlet-footer-bordered">
          <div className="col-md-12 ">
            <button
              class="btn btn-primary"
              onClick={() => {
                addUpdateGoogleAnalytics(fileUpload);
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoogleAnalytics;
