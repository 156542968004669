import React, { useState } from "react";
import APIManager from "../../services/APIManager";
import { auditImport } from "../../utility/Constant";
import { useHistory } from "react-router-dom";

const AuditChecklistImport = () => {
  const history = useHistory();
  const [filelist, setfilelist] = useState([]);
  const [auditFile, setAuditFile] = useState([]);
  function updateList() {
    var input = document.getElementById("myfile1");
    const a = [];
    const auditFiles = [];
    for (var i = 0; i < input.files.length; ++i) {
      a.push(input.files.item(i).name);
      auditFiles.push(input.files.item(i));
    }
    setfilelist(a);
    setAuditFile(auditFiles);
  }

  const [imageList, setImageList] = useState([]);
  const [imageFiles, setImageFile] = useState([]);

  function auditImportFileNew(files, imageFiles1) {
    var bodyFormData = new FormData();
    bodyFormData.append("auditFile", files[0]);
    APIManager.postAPI(
      auditImport + localStorage.getItem("organizationId"),
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        history.goBack();
      }
    });
  }

  return (
    <div className="custom-column-70">
      <div class="row mb-3">
        <div className="col-md-12 d-flex p-0">
          <i
            class="fa fa-angle-left back-arrow"
            onClick={() => {
              history.goBack();
            }}
          ></i>
        </div>
      </div>

      <div className="portlet mt-3">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Import Audit Points</h3>
        </div>
        <div className="portlet-body">
          <div class="common-table p-0">
            <div class="common-wrapper">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user ">
                    <div class="col-md-12">
                      <label className="form-label">Import File</label>

                      <br />
                      <input
                        type="file"
                        id="myfile1"
                        name="myfile1"
                        multiple={false}
                        onChange={updateList}
                      />
                    </div>
                    <label id="fileLabel1">
                      {filelist.length > 0 ? `${filelist.length} files` : " "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              class="btn btn-primary me-3"
              onClick={() => {
                if (auditFile.length == 1) {
                  auditImportFileNew(auditFile, imageFiles);
                }
              }}
            >
              Save
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                setfilelist([]);
                setImageList([]);
                setAuditFile([]);
                setImageFile([]);
                history.goBack();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditChecklistImport;
