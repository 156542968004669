import React, { useEffect, useRef, useState } from "react";
import { Drawer, Input, Table } from "antd";
import { Link, useHistory } from "react-router-dom";
import APIManager from "../../../services/APIManager";
import {
  addViewCompetitor,
  deleteAllViewCompetitor,
  deleteViewCompetitorById,
  documentFileType,
  getViewCompetitorByRankingConfigId,
  GET_Server_URL,
  importViewCompetitor,
  updateRankingCompetitor,
} from "../../../utility/Constant";

import { exportToEXCEL, removeTrailingSlash } from "../../../utility/Globals";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import { Sorter } from "../../../common/Sorter";

function Createviewcompetitor(props) {
  const [tabcomplist, settabcompList] = useState([]);
  const [complist, setcompList] = useState([]);
  const [complistIndex, setcomplistIndex] = useState(0);
  const [tabdata, settabdata] = useState([]);
  const [comptrlist, setcomptrlist] = useState([]);
  const CompTabcol = [
    {
      title: "Competitors",
      dataIndex: "comp",
      key: "comp",
      width: "90%",
      sorter: {
        compare: (a, b) =>
          Sorter.DEFAULT(
            a.comp.toLocaleLowerCase(),
            b.comp.toLocaleLowerCase()
          ),
        multiple: 3,
      },
    },
    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
      width: "10%",
    },
  ];
  const NewCompTabcol = [
    {
      title: "Competitors",
      dataIndex: "comp",
      key: "comp",
      width: "95%",
      sorter: {
        compare: (a, b) =>
          Sorter.DEFAULT(
            a.comp.toLocaleLowerCase(),
            b.comp.toLocaleLowerCase()
          ),
        multiple: 3,
      },
    },
  ];
  const [selectedCompExportList, setSelectedCompExportList] = useState([]);
  const [compExportList, setCompExportList] = useState([]);
  const [downloadlist, setdownloadlist] = useState([]);
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);
  const [IsEditcompetitior, setIsEditcompetitiors] = useState(false);
  const [TempisUpdate, setTempisUpdate] = useState(false);
  const [CompetitiorsId, setCompetitorsId] = useState("");
  const [RankingCompetitorConfigId, setRankingCompetitorConfigId] =
    useState("");
  const [CompetitorsName, setCompetitorsName] = useState("");
  const [CompetitorsNameCopy, setCompetitorsNameCopy] = useState("");
  const [SelectedCompCheckList, setSelectedCompCheckList] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    getCompetitorsList();
  }, []);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setcompList([]);
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setCompetitorsName("");
    setTempisUpdate(false);
    setcompList([]);
  };

  const comprowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          key: item.key,
          competitors: item.comp,
        };
        list.push(obj);
      });
      setSelectedCompExportList(list);
      
      const list1 = [];
      selectedRows.map((item) => {
        list1.push(item.id);
      });
      setSelectedCompCheckList(list1);
      
    },
  };

  function submit() {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteMultiCompId();
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function addCompetitors() {
    APIManager.postAPI(addViewCompetitor, complist, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          setCompetitorsName("");
          getCompetitorsList();
          notifyAdd();
        }
      }
    );
  }

  function addTmpCompetitors() {
    let obj = complist;

    if (TempisUpdate) {
      obj[complistIndex].Competitor = CompetitorsName.trim();
    } else {
      obj.push({
        RankingCampaignViewConfigurationId: props?.campaignviewID,
        Competitor: removeTrailingSlash(CompetitorsName),
      });
    }
    setcompList(obj);
    setCompetitorsName("");
    setTempisUpdate(false);
  }

  function updateCompetitiors() {
    APIManager.putAPI(
      updateRankingCompetitor,
      {
        Id: CompetitiorsId,
        RankingCampaignConfigurationId: props.campaignviewID,
        Competitor: removeTrailingSlash(CompetitorsName),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setIsEditcompetitiors(false);
        setCompetitorsName("");
        getCompetitorsList();
      }
    });
  }

  function deleteMultiCompId() {
    return new Promise((resolve) => {
      APIManager.postAPIForDelete(
        deleteAllViewCompetitor,
        SelectedCompCheckList,
        {},
        true
      ).then((response) => {
        if (response && response.status == 200) {
          getCompetitorsList();
          if (response.status === 200)
            if (response?.data?.message) {
            } else {
              // notifyDelete();
            }
          resolve(response.status);
        }
      });
    });
  }
  const [viewcompetitorcount, setviewcompetitorcount] = useState("");
  function getCompetitorsList() {
    APIManager.getAPI(
      getViewCompetitorByRankingConfigId + props?.campaignviewID
    ).then((response) => {
      if (response && response.status == 200) {
        setviewcompetitorcount(response?.data?.counts[0].competitorsCount);
        const exportList = [];
        const exportListtable = [];
        let c = 1;
        let i = 0;
        const list = [];
        const tablelist = [];
        response.data.table.map((item) => {
          let exportObj = {
            key: item.id,
            competitors: item.competitor,
          };
          setRankingCompetitorConfigId(item.rankingCampaignConfigurationId);
          exportList.push(exportObj);
          list.push({
            key: item.competitorId,
            id: item.competitorId,
            value: item.competitor,
            label: item.competitor,
            comp: item.competitor,
            delete: (
              <div className="px-3">
                <Link
                  to={{
                    state: { item: item, isEdit: true },
                  }}
                ></Link>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                  onClick={() => {
                    deleteCompetitorsItem(item.competitorId);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          });
          c++;
        });

        response.data.table1.map((item) => {
          let exportObjtable = {
            key: item.competitorId,
            competitors: item.competitor,
          };
          setRankingCompetitorConfigId(item.rankingCampaignConfigurationId);
          exportListtable.push(exportObjtable);
          tablelist.push({
            key: i++,
            id: item.competitorId,
            comp: item.competitor,
          });
          c++;
        });
        setCompExportList(exportList);
        setdownloadlist(exportListtable);
        settabdata(tablelist);
        settabcompList(list);
        setcomptrlist(list);
      }
    });
  }

  async function deleteCompetitorsItem(id) {
    try {
      const response = await APIManager.deleteAPI(
        deleteViewCompetitorById + id
      );
      if (response == 200) {
        getCompetitorsList();
      }
    } catch (e) { }
  }

  function uploadCompetitorsFile() {
    var input = document.getElementById("competitorFile");
    competitorImport(input.files.item(0));
  }

  function competitorImport(file) {
    inputFileRef.current.value = null;
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      importViewCompetitor + props?.campaignviewID,
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getCompetitorsList();
      }
    });
  }
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const inputFileRef = useRef(null);

  function isValidURL() {
    let error = { ...errors };
    CompetitorsName.trim() == ""
      ? (error["CompetitorsName"] = "Competitors is required!")
      : delete error["CompetitorsName"];

    if (CompetitorsName.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );

      if (!pattern.test(CompetitorsName.trim())) {
        error["CompetitorsName"] = "Please enter valid Competitors!";
      } else {
        delete error["CompetitorsName"];
        let isEqualItem = false;
        tabcomplist.map((item) => {
          if (item.value == CompetitorsName.trim()) {
            isEqualItem = true;
          }
        });
        if (isEqualItem) {
          error["CompetitorsName"] = "Competitors is already exist!";
        } else {
          delete error["CompetitorsName"];
        }
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  const removeFields = (index) => {
    let data = [...complist];
    data.splice(index, 1);
    setcompList(data);
  };

  return (
    <div className="custom-column-70">
      <div className="portlet border-0">
        <div class="portlet-header px-3">
          <h3 class="portlet-title main-title mt-5">Add Competitors</h3>
        </div>
        <div className="portlet-body">
          <div className="row px-2">
            <div className="col-md-12 mb-4 mt-4">
              <div className="keyword-select text-dark fw-bold">
                <>Competitors Added :{viewcompetitorcount}</>
              </div>
            </div>
          </div>
          <div className="row px-2">
            <div className="col-md-4 tab-panel-url-options  d-flex justify-content-start url-tab mt-3">
              <div class="input-group-icon input-group-lg widget15-compact">
                <div class="input-group-prepend">
                  <i class="fa fa-search text-primary"></i>
                </div>
                <span>
                  <Input
                    allowClear
                    style={{ padding: 7 + "px" }}
                    type="text"
                    class="form-control"
                    placeholder="Type to search"
                    onChange={(nameSearch) => {
                      settabcompList(
                        comptrlist.filter((person) =>
                          person.comp
                            .toLocaleLowerCase()
                            .includes(
                              nameSearch.target.value.toLocaleLowerCase()
                            )
                        )
                      );
                    }}
                    id="input-s"
                  />
                </span>
              </div>
            </div>

            <div className="col-md-8 tab-panel-options text-end mt-3 competitors-tab">
              <span class="export">
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="competitorFile"
                  name="competitorFile"
                  multiple={false}
                  accept={documentFileType}
                  ref={inputFileRef}
                  onChange={uploadCompetitorsFile}
                />
                <button
                  disabled={!SelectedCompCheckList?.length}
                  class="btn btn-primary download-temp me-3"
                  onClick={() => {
                    submit();
                  }}
                >
                  Delete
                </button>
                <button
                  class="btn btn-primary download-temp me-3"
                  onClick={() => {
                    showDrawer();
                  }}
                >
                  Add
                </button>
                <button
                  class="competitors-import btn btn-primary me-3 import"
                  onClick={onBtnClick}
                >
                  Import
                </button>
                <a
                  class="btn btn-outline download-temp"
                  target="_blank"
                  href={GET_Server_URL + "audit/Competitors.xlsx"}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#0691EF"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-download"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                  <span className="ms-2">Download Template</span>
                </a>
              </span>
            </div>
          </div>
          <div className="ranking-campaing-table  table-bordered border-0  mt-4 pt-2">
            <div className="col-md-12">
              <Table
                className="table table-hover"
                id="url"
                columns={CompTabcol}
                dataSource={tabcomplist}
                rowSelection={{ type: "checkbox", ...comprowSelection }}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `Showing ${range[0]} to ${range[1]} of ${total} Competitors`,
                }}
              />
            </div>
            {tabdata.length ? (
              <>
                <div className="borderr-red px-3 py-4">
                  <div className="d-flex justify-content-between me-3">
                    <div className="d-flex align-items-center title-bar">
                      <h1 class="portlet-title main-title me-3">
                        New Competitors
                      </h1>
                      <a
                        href="#"
                        class="text-dark"
                        data-toggle="tooltip"
                        data-html="true"
                        title="These are the set of Competitors that aren't part of the main campaign and they won't be tracked."
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                            stroke="#E2E8F0"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 16V12"
                            stroke="#E2E8F0"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M12 8H12.01"
                            stroke="#E2E8F0"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </a>
                    </div>
                    <div className="d-flex align-items-center">
                      <button
                        disabled={!SelectedCompCheckList?.length}
                        style={{ marginLeft: 18 + "px" }}
                        class="btn btn-primary download-temp"
                        onClick={() => {
                          submit();
                        }}
                      >
                        Delete
                      </button>
                      <button
                        class="ms-3 btn btn-outline"
                        onClick={() => {
                          exportToEXCEL(
                            selectedCompExportList?.length > 0
                              ? downloadlist
                              : downloadlist,
                            "competitor"
                          );
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="#0691EF"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-download"
                        >
                          <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                          <polyline points="7 10 12 15 17 10"></polyline>
                          <line x1="12" y1="15" x2="12" y2="3"></line>
                        </svg>
                        <span className="ms-2">Download List</span>
                      </button>
                    </div>
                  </div>
                  <div className="col-md-12 mt-3">
                    <Table
                      className="table table-hover thead-yellow mb-0"
                      id="url"
                      columns={NewCompTabcol}
                      dataSource={tabdata}
                      rowSelection={{
                        type: "checkbox",
                        ...comprowSelection,
                      }}
                      pagination={{
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} Competitors`,
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <Drawer
            title="Add New Competitors"
            className="ranking-box-start rankv2-modal"
            placement="right"
            size="636px"
            onClose={onClose}
            open={open}
          >
            <div className="portlet-body py-4">
              <div className="row">
                <div className="d-flex align-items-center justify-content-between">
                  <label className="mb-0 fw-bold text-dark">Competitor</label>
                  <div className="input-box w-100 mx-3">
                    <input
                      className=" border d-block w-100"
                      type="text"
                      value={CompetitorsName}
                      onChange={(text) => {
                        setCompetitorsName(text.target.value);
                      }}
                      placeholder="Enter Competitors"
                    />
                    {save && errors?.CompetitorsName && (
                      <>
                        <div className="field-required mt-2">
                          <span> {errors?.CompetitorsName}</span>
                        </div>
                      </>
                    )}
                  </div>
                  <button
                    class="btn btn-primary"
                    onClick={() => {
                      setsave(true);
                      if (
                        CompetitorsNameCopy.trim() == CompetitorsName.trim() &&
                        IsEditcompetitior
                      ) {
                        setCompetitorsName("");
                        setErrors("");
                        return;
                      }
                      if (isValidURL()) {
                        if (IsEditcompetitior) {
                          updateCompetitiors();
                        } else {
                          addTmpCompetitors();
                        }
                      } else {
                      }
                    }}
                  >
                    {IsEditcompetitior || TempisUpdate ? "Update" : "Add"}
                  </button>
                </div>
              </div>
              <div className="onclick-display mt-5">
                <div className="row">
                  <h5 className="fw-bold text-dark mb-4">
                    {!IsEditcompetitior ? "Competitors List:" : ""}
                  </h5>
                  <div className="list-boxx mt-3">
                    {complist.map((item, index) => {
                      return (
                        <>
                          <div className="d-flex justify-content-between align-items-center">
                            <div className="border-bottom pb-2 w-100">
                              <span className="text-dark">
                                {item.Competitor}
                              </span>
                            </div>
                            <div className="d-flex align-items-center ms-4">
                              <Link
                                to={{
                                  state: { item: item, isEdit: true },
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-edit edit-icon me-3"
                                  onClick={() => {
                                    if (item?.id) {
                                      setIsEditcompetitiors(true);
                                      setCompetitorsId(item.id);
                                      setCompetitorsName(item.value);
                                      setCompetitorsNameCopy(item.value);
                                      var e =
                                        document.getElementById("input-s");
                                      e.scrollIntoView({
                                        block: "end",
                                        behavior: "smooth",
                                        inline: "center",
                                      });
                                    } else {
                                      setTempisUpdate(true);
                                      setcomplistIndex(index);
                                      setCompetitorsName(item.Competitor);
                                    }
                                  }}
                                >
                                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                </svg>
                              </Link>

                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="feather feather-trash-2 trash-icon"
                                onClick={() => {
                                  if (item.id) {
                                    onClose();
                                    deleteCompetitorsItem(item.id);
                                  } else {
                                    removeFields(index);
                                  }
                                }}
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="button-down">
                {!IsEditcompetitior ? (
                  <>
                    <button
                      onClick={() => {
                        addCompetitors();
                        onClose();
                      }}
                      disabled={complist.length ? false : true}
                      className={`${complist.length
                        ? "btn btn-primary"
                        : "btn btn-primary bgg-light"
                        }`}
                    >
                      Save
                    </button>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </div>
  );
}

export default Createviewcompetitor;
