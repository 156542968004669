import React, { useEffect, useState } from "react";
import { Table, Typography } from "antd";
import APIManager from "../../services/APIManager";
import Chart from "react-apexcharts";
import {
    mmDDyyyyFormateDateSet,
    DDMMyyyyFormateDateSet,
    formatRankDate,
    returnMagnitude,
    findUnique,
    fixTwoPlaceDecimal,
} from "../../utility/Globals";
import {
    getCategoryByProject,
    getKeywordDistributionChart,
    rankingVisibilityAPI,
    keywordDistributionHeader
} from "../../utility/Constant";

function KeywordDistribution(props) {
    const [isChart, setIsChart] = useState(0);
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [compareStartDate, setCompareStartDate] = useState("");
    const [compareEndDate, setCompareEndDate] = useState("");
    const [series, setSeries] = useState([]);
    const [isOverallTab, setIsOverallTab] = useState(1);
    const [isHeadTab, setIsHeadTab] = useState(2);
    const [isTorsoTab, setIsTorsoTab] = useState(3);
    const [isTailTab, setIsTailTab] = useState(4);
    const [isOverallTabActive, setIsOverallTabActive] = useState(0);
    const [isHeadTabActive, setIsHeadTabActive] = useState(1);
    const [isTorsoTabActive, setIsTorsoTabActive] = useState(1);
    const [isTailTabActive, setIsTailTabActive] = useState(1);
    const [isAvgCompareTabChange, setIsAvgCompareTabChange] = useState("");
    const [isRankCompareTabChange, setIsRankCompareTabChange] = useState("");
    const [isOverallCompareTabChange, setIsOverallCompareTabChange] = useState("");
    const [isHeadCompareTabChange, setIsHeadCompareTabChange] = useState("");
    const [isTorsoCompareTabChange, setIsTorsoCompareTabChange] = useState("");
    const [isTailCompareTabChange, setIsTailCompareTabChange] = useState("");
    const [isWeekProgressCompareTabChange, setIsWeekProgressCompareTabChange] = useState(0);
    const [isRankCompareTabChange1, setIsRankCompareTabChange1] = useState(0);
    const [categoryType, setCategoryType] = useState([]);
    const [categoryListFilter, setCategoryTypeFilter] = useState([]);
    const [CategorySelected, setCategorySelected] = useState();
    const [keyDistributionTabCol, setKeyDistributionTabCol] = useState([]);
    const [keyDistributionTabCompareCol, setKeyDistributionTabCompareCol] = useState([]);
    const [keyDistributionTabList, setKeyDistributionTabList] = useState([]);
    const [keyDistributionTabCompareList, setKeyDistributionTabCompareList] = useState([]);
    const [visibilityTableColumn, setVisibilityTableColumn] = useState([])
    const [visibilityTableData, setVisibilityTableData] = useState([])
    const [pageSize, setPageSize] = useState(100);
    const [options, setObject] = useState({});
    const [isDateRange, setIsDateRange] = useState(false)
    const [totalVisibility, setTotalVisibility] = useState(0)
    const [keywordType, setKeywordType] = useState(0);
    const [week1Progress, setweek1Progress] = useState(0)
    const [week2Progress, setweek2Progress] = useState(0)
    const [visibility1, setVisibility1] = useState(0)
    const [visibility2, setVisibility2] = useState(0)
    const [rankValue, setRankValue] = useState("")
    const [tableParams1, setTableParams1] = useState({
        pagination: {
            position: ["bottomRight"],
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} keywords`
        },
    });
    let columnsArray = [];
    const [pageNumber1, setPageNumber1] = useState(1);
    const [tablePageSize1, setTablePageSize1] = useState(10);
    const [minValue, setMinValue] = useState(0)
    const [maxValue, setMaxValue] = useState(0)
    const [isCompareOverallValueTab, setIsCompareOverallValueTab] = useState(0);
    const [isOverallValueTab, setIsOverallValueTab] = useState(0);
    const [avgPositionHead, setAvgPositionHead] = useState(0);
    const [rankingVisibilityHead, setrankingVisibilityHead] = useState(0);
    const [noOfKeywordsHead, setnoOfKeywordsHead] = useState(0);
    const [avgPositionTorso, setAvgPositionTorso] = useState(0);
    const [noOfKeywordsTorso, setnoOfKeywordsTorso] = useState(0);
    const [rankingVisibilityTorso, setrankingVisibilityTorso] = useState(0);
    const [avgPositionTail, setAvgPositionTail] = useState(0);
    const [rankingVisibilityTail, setrankingVisibilityTail] = useState(0);
    const [noOfKeywordsTail, setnoOfKeywordsTail] = useState(0);
    const [noOfKeywordsHeadComp, setnoOfKeywordsHeadComp] = useState(0);
    const [noOfKeywordsTorsoComp, setnoOfKeywordsTorsoComp] = useState(0);
    const [noOfKeywordsTailComp, setnoOfKeywordsTailComp] = useState(0);
    const [avgPosition11, setAvgPosition11] = useState(0);
    const [avgPosition22, setAvgPosition22] = useState(0);
    const [rankingVisibility11, setrankingVisibility11] = useState(0);
    const [rankingVisibility22, setrankingVisibility22] = useState(0);
    const [backgroundColor, setBackgroundColor] = useState("")
    const [sortList, setSortList] = useState([
        {
            columnName: "keyword",
            order: undefined
        }
    ])
    const [filterList, setFilterList] = useState([])
    useEffect(() => {
        getKeywordDistributionData();
    }, [props?.rankingType]);

    useEffect(() => {
        getKeywordDistributionData();
    }, [props?.deviceType]);

    useEffect(() => {
        getKeywordDistributionData();
    }, [props?.selectedCampaign]);

    useEffect(() => {
        getKeywordDistributionData();
    }, [props?.selectedCampaigView]);

    useEffect(() => {
        getKeywordDistributionData();
    }, [props?.dataParentToChild]);

    function getKeywordDistributionData() {
        setStartDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
        );
        setEndDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
        );
        setCompareStartDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
        );
        setCompareEndDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
        );
        setIsOverallTab(1);
        setIsOverallTabActive(0);
        setIsHeadTabActive(1);
        setIsTorsoTabActive(1);
        setIsTailTabActive(1);

        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
            document.getElementById("overall-tab").classList.remove("keyword-distribution");
        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
            document.getElementById("head-tab").classList.remove("keyword-distribution");
        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
            document.getElementById("torso-tab").classList.remove("keyword-distribution");
        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
            document.getElementById("tail-tab").classList.remove("keyword-distribution");
        }
        document.getElementById("overall-tab").classList.add("keyword-distribution");

        if (props?.selectedCampaign != '' && props?.selectedCampaigView != '' && props?.isReady) {
            localStorage.setItem('pageSize', 100);
            localStorage.setItem('keywordType', 0);
            getCategoryData();
        }
    }

    function getCategoryData() {
        APIManager.getAPI(
            getCategoryByProject + localStorage.getItem("projectGId")
        ).then((response) => {
            if (response && response.status == 200) {
                let list = [];
                let categoryList = []
                let object = {
                    id: "",
                    categoryType: "All"
                }

                list.push(object)

                response.data.map((item) => {
                    let object = {
                        id: item.id,
                        categoryType: item.categoryType
                    }

                    list.push(object)

                    let objFilter = {
                        text: item.categoryType,
                        value: item.categoryType,
                    }
                    categoryList.push(objFilter)

                })

                let sortedList = categoryList.sort((a, b) => a.value.localeCompare(b.value));
                setCategoryTypeFilter(sortedList);

                localStorage.setItem("CategoryListFilter", sortedList);
                setCategoryType(list);
                setCategorySelected(list[0].id);
                localStorage.setItem('categoryID', list[0].id);

                let rowdata;
                let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
                let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
                let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)

                if (props?.selectedCampaign != '' && props?.selectedCampaigView != '') {
                    if (startDate == compareStartDate
                        && endDate == compareEndDate) {
                        setIsDateRange(false)
                        rowdata = {
                            ProjectId: localStorage.getItem("projectGId"),
                            RankingCampaignConfigurationId: props?.selectedCampaign,
                            RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                            Type: parseInt(props?.rankingType),
                            Device: parseInt(props?.deviceType),
                            PageSize: pageSize,
                            KeywordCategoryId: list[0].id,
                            DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                            },
                            KeywordType: keywordType,
                        };
                    }
                    else {
                        setIsDateRange(true)
                        rowdata = {
                            ProjectId: localStorage.getItem("projectGId"),
                            RankingCampaignConfigurationId: props?.selectedCampaign,
                            RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                            Type: parseInt(props?.rankingType),
                            Device: parseInt(props?.deviceType),
                            PageSize: pageSize,
                            KeywordCategoryId: CategorySelected,
                            DateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                            },
                            CompareDateRange: {
                                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                            },
                            KeywordType: keywordType,
                        };
                    }
                    getKeywordDistributionChartType(rowdata);
                    getKeywordDistributiontabledata(rowdata);
                    getKeywordDistributionHeader(rowdata);
                }
            }
        });
    }

    function getKeywordDistributiontabledata(rowdata) {
        setKeyDistributionTabCol([]);
        setKeyDistributionTabList([]);
        setKeyDistributionTabCompareCol([]);
        setKeyDistributionTabCompareList([]);
        columnsArray = [];
        APIManager.postAPI(getKeywordDistributionChart, rowdata, null, true).then((response) => {
            if (response && response.status == 200) {
                let cols = [];
                let compareCols = [];
                let list = [];
                let list1 = [];
                let compareList = [];
                let changeList = [];
                let minRank, maxRank;
                let keys, keysMain;
                let compareKeys;
                let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
                let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
                let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)

                if (startDate == compareStartDate
                    && endDate == compareEndDate) {
                    if (response?.data?.tableContent?.table?.length > 0) {
                        keys = [];
                        keysMain = [];
                        let sortedList = []
                        let mainList = [];

                        keysMain = Object.keys(response?.data?.tableContent?.table[0]);
                        keysMain.map((item, index) => {
                            var array = item.split('-');
                            mainList.push({
                                key: item,
                                valueSort: array[0]
                            })
                        })
                        sortedList = mainList.sort(function (a, b) {
                            return a.valueSort.localeCompare(b.valueSort);
                        });

                        sortedList.map((item) => {
                            keys.push(item.key);
                        })

                        keys.splice(0, 0, "trackDate");
                        keys?.map((key, keyIndex) => {
                            let objCols = {
                                title: key == "trackDate" ? "Date" : key != "total" && key != "Date" ? "Rank " + key : key,
                                dataIndex: key == "trackDate" ? "Date" : key,
                                key: key == "trackDate" ? "Date" : key,
                                className: `${keyIndex == 1 && key != "trackDate" ? "overall-table-highlight-grey" : ""}`,
                                onHeaderCell: (record, rowIndex) => {
                                    if (key != "trackDate") {
                                        return {
                                            onClick: (event) => {
                                                let cols = columnsArray;
                                                if (cols?.length) {
                                                    cols?.map(x => {
                                                        if (x.title == record.title) {
                                                            x.className = "overall-table-highlight-grey"
                                                        } else {
                                                            x.className = "";
                                                        }
                                                    });
                                                }
                                                setBackgroundColor(record.title)

                                                var array = (record.title)?.split(' ');
                                                let rank = array[1];
                                                let rankArray = rank?.split('-');
                                                minRank = rankArray[0]
                                                maxRank = rankArray[1]
                                                setVisibilityTableRawData(minRank, maxRank)
                                            },
                                        };
                                    }
                                },
                            };
                            cols.push(objCols);
                            columnsArray.push(objCols);
                        });

                        response?.data?.tableContent?.table.map((item, index) => {
                            for (let i = 0; i < cols.length; i++) {
                                item[keys[i] == "trackDate" ? "Date" : keys[i]] = i == 0 ? DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate) : item[keys[i]];
                            }
                            list.push(item);
                        });

                        setKeyDistributionTabCol(cols);
                        setKeyDistributionTabList(list);
                    }
                }
                else {
                    keys = [];
                    keysMain = [];
                    compareKeys = [];

                    if (response?.data?.tableContent?.table?.length > 0) {
                        let sortedList = []
                        let mainList = [];

                        response?.data?.tableContent?.table.map((item, index) => {
                            keysMain = Object.keys(item);
                        });

                        keysMain.map((item, index) => {
                            var array = item.split('-');
                            mainList.push({
                                key: item,
                                valueSort: array[0]
                            })
                        })

                        sortedList = mainList.sort(function (a, b) {
                            return a.valueSort.localeCompare(b.valueSort);
                        });

                        sortedList.map((item) => {
                            keys.push(item.key);
                        })
                        keys.splice(0, 0, "trackDate");

                        // keys?.map((key) => {
                        //     let objCols = {
                        //         title: key == "trackDate" ? "Date" : key != "total" && key != "Date" ? "Rank " + key : key,
                        //         dataIndex: key == "trackDate" ? "Date" : key,
                        //         key: key == "trackDate" ? "Date" : key,
                        //         onHeaderCell: (record, rowIndex) => {
                        //             if (key != "trackDate") {
                        //                 return {
                        //                     onClick: (ev) => {
                        //                         let cols = columnsArray;
                        //                         if (cols?.length) {
                        //                             cols?.map(x => {
                        //                                 if (x.title == record.title) {
                        //                                     x.className = "ant-table-cell ranking-table-background-Color"
                        //                                 } else {
                        //                                     x.className = "ant-table-cell";
                        //                                 }
                        //                             })
                        //                             // setKeyDistributionTabCompareCol(cols);
                        //                         }

                        //                         ev.currentTarget.background = "#000000 !important";
                        //                         var array = (record.title).split(' ');
                        //                         let rank = array[1];
                        //                         let rankArray = rank.split('-');
                        //                         minRank = rankArray[0]
                        //                         maxRank = rankArray[1]
                        //                         setVisibilityTableRawData(minRank, maxRank)
                        //                     },
                        //                 };
                        //             }
                        //         },
                        //     };
                        //     cols.push(objCols);
                        // });

                        response?.data?.tableContent?.table.map((item, index) => {
                            for (let i = 0; i < cols.length; i++) {
                                item[keys[i] == "trackDate" ? "Date" : keys[i]] = i == 0 ? DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate) : item[keys[i]];
                            }
                            list.push(item);
                        });
                    }

                    if (response?.data?.compareTableContent?.table?.length > 0) {
                        response?.data?.compareTableContent?.table.map((item, index) => {
                            compareKeys = Object.keys(item);
                        });
                        compareKeys.splice(0, 0, "trackDate");

                        // compareKeys?.map((key) => {
                        //     let objCols = {
                        //         title: key == "trackDate" ? "Date" : key != "total" && key != "Date" ? "Rank " + key : key,
                        //         dataIndex: key == "trackDate" ? "Date" : key,
                        //         key: key == "trackDate" ? "Date" : key,
                        //         onHeaderCell: (record, rowIndex) => {
                        //             if (key != "trackDate") {
                        //                 return {
                        //                     onClick: (ev) => {
                        //                         let cols = columnsArray;
                        //                         if (cols?.length) {
                        //                             cols?.map(x => {
                        //                                 if (x.title == record.title) {
                        //                                     x.className = "ant-table-cell ranking-table-background-Color"
                        //                                 } else {
                        //                                     x.className = "ant-table-cell";
                        //                                 }
                        //                             })
                        //                             // setKeyDistributionTabCompareCol(cols);
                        //                         }

                        //                         ev.currentTarget.background = "#000000 !important";
                        //                         var array = (record.title).split(' ');
                        //                         let rank = array[1];
                        //                         let rankArray = rank.split('-');
                        //                         minRank = rankArray[0]
                        //                         maxRank = rankArray[1]
                        //                         setVisibilityTableRawData(minRank, maxRank)
                        //                     },
                        //                 };
                        //             }
                        //         },
                        //     };
                        //     compareCols.push(objCols);
                        // });

                        response?.data?.compareTableContent?.table.map((item, index) => {
                            for (let i = 0; i < compareCols.length; i++) {
                                item[compareKeys[i] == "trackDate" ? "Date" : compareKeys[i]] = i == 0 ? DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate) : item[compareKeys[i]];
                            }
                            list1.push(item);
                        });
                    }

                    let changeValue;
                    let compareListFinal = [];
                    let newKeys = keys.length > compareKeys.length ? keys : compareKeys;

                    for (let i = 0; i < (list.length > list1.length ? list.length : list1.length); i++) {
                        compareList = [];
                        for (let j = 0; j < newKeys.length; j++) {
                            compareList[newKeys[j] == "trackDate" ? "Date" : newKeys[j]] = j == 0 ? ([newKeys[j]] != undefined ? DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate) : DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)) : (list.length > 0 ? (list[i][newKeys[j]] != undefined ? list[i][newKeys[j]] : 0) : 0);
                        }
                        compareListFinal.push(compareList);

                        compareList = [];

                        for (let j = 0; j < newKeys.length; j++) {
                            compareList[newKeys[j] == "trackDate" ? "Date" : newKeys[j]] = j == 0 ? ([newKeys[j]] != undefined ? DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate) : DDMMyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)) : (list1.length > 0 ? (list1[i][newKeys[j]] != undefined ? list1[i][newKeys[j]] : 0) : 0);
                        }
                        compareListFinal.push(compareList);

                        changeList = [];
                        for (let k = 0; k < newKeys.length; k++) {
                            if (newKeys[k] != "trackDate") {
                                changeValue = compareListFinal[i + 1][newKeys[k]] - compareListFinal[i][newKeys[k]];
                            }

                            changeList[newKeys[k] == "trackDate" ? "Date" : newKeys[k]] = k == 0 ? "Change" : (<span className={`${changeValue >= 0 ? "text-success" : "text-decline"}`}>{changeValue}</span>);
                        }
                        compareListFinal.push(changeList);
                    }

                    newKeys?.map((key, keyIndex) => {
                        let objCols = {
                            title: key == "trackDate" ? "Date" : key != "total" && key != "Date" ? "Rank " + key : key,
                            dataIndex: key == "trackDate" ? "Date" : key,
                            key: key == "trackDate" ? "Date" : key,
                            className: `${keyIndex == 1 && key != "trackDate" ? compareListFinal[0][key] > compareListFinal[1][key] ? "overall-table-highlight-red" : "overall-table-highlight-green" : ""}`,
                            onHeaderCell: (record, rowIndex) => {
                                if (key != "trackDate") {
                                    return {
                                        onClick: (event) => {
                                            let cols = columnsArray;
                                            if (cols?.length) {
                                                cols?.map(x => {
                                                    if (x.title == record.title) {
                                                        if (compareListFinal[0][key] > compareListFinal[1][key]) {
                                                            x.className = "overall-table-highlight-red";
                                                        } else {
                                                            x.className = "overall-table-highlight-green";
                                                        }
                                                    } else {
                                                        x.className = "";
                                                    }
                                                })
                                                // setKeyDistributionTabCompareCol(cols);
                                            }

                                            event.currentTarget.background = "#000000 !important";
                                            var array = (record.title).split(' ');
                                            let rank = array[1];
                                            let rankArray = rank.split('-');
                                            minRank = rankArray[0]
                                            maxRank = rankArray[1]
                                            setVisibilityTableRawData(minRank, maxRank)
                                        },
                                    };
                                }
                            },
                        };
                        cols.push(objCols);
                    });
                    console.log(cols);

                    // setKeyDistributionTabCompareCol(compareCols.length > cols.length ? compareCols : cols);
                    // columnsArray = compareCols.length > cols.length ? compareCols : cols;
                    setKeyDistributionTabCompareCol(cols);
                    columnsArray = cols;
                    setKeyDistributionTabCompareList(compareListFinal);
                }
            }
        });
    }

    function visibilityTableColumnFunction(keywordList, categoryList) {
        let object;
        let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
        let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
        let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
        let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)

        if (startDate == compareStartDate
            && endDate == compareEndDate) {
            object = [
                {
                    title: "Keywords",
                    dataIndex: "keyword",
                    key: "keyword",
                    sorter: {
                        multiple: 3,
                    },
                },
                {
                    title: "Search Volume",
                    dataIndex: "searchVolume",
                    key: "searchVolume",
                    sorter: {
                        multiple: 3,
                    },
                },
                {
                    title: "Category",
                    dataIndex: "category",
                    key: "category",
                    filters: findUnique(categoryListFilter, (d) => d.value),
                    filterSearch: true,
                    onFilter: (value, record) => record.category.indexOf(value) === 0,
                },
                {
                    title: "Rank",
                    dataIndex: "rank",
                    key: "rank",
                    sorter: {
                        multiple: 3,
                    },
                },
                {
                    title: "URLs",
                    dataIndex: "url",
                    key: "url",
                },
            ]
        }
        else {

            object = [
                {
                    title: "Keywords",
                    dataIndex: "keyword",
                    key: "keyword",
                    sorter: {
                        multiple: 3,
                    },
                },
                {
                    title: "Status",
                    dataIndex: "status",
                    key: "status",
                },
                {
                    title: "Category",
                    dataIndex: "category",
                    key: "category",
                    filters: findUnique(categoryListFilter, (d) => d.value),
                    filterSearch: true,
                    // onFilter: (value, record) => record.category.indexOf(value) === 0,
                },
                {
                    title: "Search Volume",
                    dataIndex: "searchVolume",
                    key: "searchVolume",
                    sorter: {
                        multiple: 3,
                    },
                },

                {
                    title: (<Typography.Text ellipsis={true}>
                        Rank<br></br><span className="rankdate">{DDMMyyyyFormateDateSet(startDate)}</span>
                    </Typography.Text>),
                    dataIndex: "rank1",
                    key: "rank1",
                },
                {
                    title: (<Typography.Text ellipsis={true}>
                        Rank<br></br><span className="rankdate">{DDMMyyyyFormateDateSet(compareStartDate)}</span>
                    </Typography.Text>),
                    dataIndex: "rank2",
                    key: "rank2",
                },
                {
                    title: "Change",
                    dataIndex: "change",
                    key: "change",
                },
            ]
        }
        setVisibilityTableColumn(object)
    }

    function getKeywordDistributionChartType(rowdata) {
        setSeries([]);
        APIManager.postAPI(getKeywordDistributionChart, rowdata, null, true).then((response) => {
            if (response && response.status == 200) {
                let list = [];
                let list1 = [];
                let list1Main = [];
                let barGraphData1Main = [];
                let barGraphData = [];
                let barGraphData1 = [];
                let minValue, maxValue;
                let listCategory1 = [];
                let listCategory2 = [];
                let mainList = []
                let sortedList = []
                let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
                let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
                let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)

                if (startDate == compareStartDate
                    && endDate == compareEndDate) {
                    response?.data?.tableContent?.table?.map((item, index) => {
                        list1 = Object.keys(item);
                        barGraphData1 = Object.values(item);

                    });
                    list1.map((item, index) => {
                        var array = item.split('-');
                        mainList.push({
                            key: item,
                            value: barGraphData1[index],
                            valueSort: array[0]
                        })
                    })
                    sortedList = mainList.sort(function (a, b) {
                        return a.valueSort.localeCompare(b.valueSort);
                    });

                    sortedList.map((item) => {
                        list1Main.push(item.key);
                        barGraphData1Main.push(item.value)
                    })

                    var array = list1Main[0].split('-');
                    minValue = array[0];
                    maxValue = array[1];

                    let totalIndex;
                    list1Main.map((item, index) => {
                        if (item != "total") {
                            list.push(item);
                            listCategory1.push("Rank " + item)
                        } else {
                            totalIndex = index;
                        }
                    });

                    barGraphData1Main.map((item, index) => {
                        if (index != totalIndex) {
                            barGraphData.push(item)
                        }
                    })

                    setObject({
                        legend: {
                            show: true,
                        },
                        title:
                        {
                            text: "",
                            align: "left",
                        },
                        chart: {
                            id: "basic-bar",
                            stacked: true,
                            events:
                            {
                                mouseMove: function (event, chartContext, config) {
                                    list.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            let highlightedElement = document.getElementsByClassName("overall-chart")[0].getElementsByClassName("apexcharts-xcrosshairs")[0];
                                            if (highlightedElement.classList.contains('apexcharts-active')) {
                                                highlightedElement.classList.add("overall-chart-highlight-grey");
                                            }
                                        }
                                    })
                                },
                                dataPointSelection: function (event, chartContext, config) {
                                    list.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            let highlightedElement = event.target.parentNode.parentNode.parentNode.childNodes[1];
                                            if (highlightedElement.classList.contains('apexcharts-xcrosshairs')) {
                                                highlightedElement.classList.add("active");
                                                highlightedElement.classList.add("apexcharts-active");
                                                highlightedElement.classList.add("overall-chart-highlight-grey");
                                            }
                                        }
                                    })
                                },
                                click: function (event, chartContext, config) {
                                    list.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            var array = item.split('-');
                                            minValue = array[0];
                                            maxValue = array[1];
                                        }
                                    })
                                    setVisibilityTableRawData(minValue, maxValue)
                                }
                            },
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: "10%",
                            },
                        },
                        dataLabels:
                        {
                            formatter: () => { return ""; },
                        },
                        xaxis: {
                            tickPlacement: "on",
                            categories: listCategory1,
                            title: {
                                text: "",
                                style: { color: "#000", },
                            },
                        },
                        yaxis:
                        {
                            title:
                            {
                                text: "",
                                style: { color: "#000", },
                            },
                            logBase: 20,
                            tickAmount: 8,
                            min: 0,
                            show: true,
                        },

                    });

                    setSeries([
                        {
                            name: "Value",
                            data: barGraphData,
                            color: "#59d866",
                            backgroundColor: '#f1f1f1'
                        }
                    ])

                    setVisibilityTableRawData(minValue, maxValue)
                }
                else {
                    let listCompare1 = [];
                    let listCompare1Main = [];
                    let listCompare11 = [];
                    let listCompare2 = [];
                    let listCompare2Main = [];
                    let listCompare22 = [];
                    let barGraphDataCompare1 = []
                    let barGraphDataCompare1Main = []
                    let barGraphDataCompare11 = []
                    let barGraphDataCompare2 = []
                    let barGraphDataCompare2Main = []
                    let barGraphDataCompare22 = []
                    let mainListCompare1 = []
                    let mainListCompare2 = []
                    let sortedList1 = []
                    let sortedList2 = []
                    let listMain = []

                    if (response?.data?.tableContent?.table?.length > 0) {
                        response?.data?.tableContent?.table.map((item, index) => {
                            listCompare1 = Object.keys(item);
                            barGraphDataCompare1 = Object.values(item);

                        });

                        listCompare1.map((item, index) => {
                            var array = item.split('-');
                            mainListCompare1.push({
                                key: item,
                                value: barGraphDataCompare1[index],
                                valueSort: array[0]
                            })
                        })
                        sortedList1 = mainListCompare1.sort(function (a, b) {
                            return a.valueSort.localeCompare(b.valueSort);
                        });

                        sortedList1.map((item) => {
                            listCompare1Main.push(item.key);
                            barGraphDataCompare1Main.push(item.value)
                        })

                        var array = listCompare1Main[0].split('-');
                        minValue = array[0];
                        maxValue = array[1];

                        let totalIndex1;
                        listCompare1Main.map((item, index) => {
                            if (item != "total") {
                                listCompare11.push(item);
                                listCategory1.push("Rank " + item)
                            } else {
                                totalIndex1 = index;
                            }
                        });

                        barGraphDataCompare1Main.map((item, index) => {
                            if (index != totalIndex1) {
                                barGraphDataCompare11.push(item)
                            }
                        })


                        listMain.push(
                            {
                                name: "Value",
                                data: barGraphDataCompare11,
                                color: "#59d866"
                            }
                        )
                    } else {
                        let objKeys = [];
                        response?.data?.compareTableContent?.table.map((item, index) => {
                            objKeys = Object.keys(item);
                        });

                        objKeys.map((item, index) => {
                            if (index != "total")
                                barGraphDataCompare11.push(0);
                        });

                        listMain.push(
                            {
                                name: "Value",
                                data: barGraphDataCompare11,
                                color: "#59d866"
                            }
                        )
                    }

                    if (response?.data?.compareTableContent?.table?.length > 0) {
                        response?.data?.compareTableContent?.table.map((item, index) => {
                            listCompare2 = Object.keys(item);
                            barGraphDataCompare2 = Object.values(item);
                        });


                        listCompare2.map((item, index) => {
                            var array = item.split('-');
                            mainListCompare2.push({
                                key: item,
                                value: barGraphDataCompare2[index],
                                valueSort: array[0]
                            })
                        })
                        sortedList2 = mainListCompare2.sort(function (a, b) {
                            return a.valueSort.localeCompare(b.valueSort);
                        });

                        sortedList2.map((item) => {
                            listCompare2Main.push(item.key);
                            barGraphDataCompare2Main.push(item.value)
                        })
                        let totalIndex2;
                        listCompare2Main.map((item, index) => {
                            if (item != "total") {
                                listCompare22.push(item);
                                listCategory2.push("Rank " + item)
                            } else {
                                totalIndex2 = index;
                            }
                        });

                        barGraphDataCompare2Main.map((item, index) => {
                            if (index != totalIndex2) {
                                barGraphDataCompare22.push(item)
                            }
                        })

                        listMain.push(
                            {
                                name: "Value",
                                data: barGraphDataCompare22,
                                color: "#0483E1",
                                backgroundColor: '#f1f1f1'
                            }
                        )
                    } else {
                        let objKeys = [];
                        response?.data?.tableContent?.table.map((item, index) => {
                            objKeys = Object.keys(item);
                        });

                        objKeys.map((item, index) => {
                            if (index != "total")
                                barGraphDataCompare22.push(0);
                        });

                        listMain.push(
                            {
                                name: "Value",
                                data: barGraphDataCompare22,
                                color: "#0483E1",
                                backgroundColor: '#f1f1f1'
                            }
                        )
                    }

                    setSeries(listMain)

                    setObject({
                        legend: {
                            show: false,
                        },
                        title:
                        {
                            text: "",
                            align: "left",
                        },
                        dataLabels: {
                            enabled: false,
                        },
                        chart: {
                            id: "basic-bar",
                            stacked: false,
                            events:
                            {
                                mouseMove: function (event, chartContext, config) {
                                    let listCompareNew = listCompare11.length > listCompare22 ? listCompare11 : listCompare22;
                                    listCompareNew.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            let chartSeries1Value = config.config.series[0].data[config.dataPointIndex];
                                            let chartSeries2Value = config.config.series[1].data[config.dataPointIndex];

                                            let highlightedElement = document.getElementsByClassName("overall-chart")[0].getElementsByClassName("apexcharts-xcrosshairs")[0];
                                            if (highlightedElement.classList.contains('apexcharts-active')) {
                                                if (chartSeries1Value > chartSeries2Value)
                                                    highlightedElement.classList.add("overall-chart-highlight-red");
                                                else
                                                    highlightedElement.classList.add("overall-chart-highlight-green");
                                            }
                                        }
                                    })
                                },
                                dataPointSelection: function (event, chartContext, config) {
                                    let listCompareNew = listCompare11.length > listCompare22 ? listCompare11 : listCompare22;
                                    listCompareNew.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            let chartSeries1Value = config.w.config.series[0].data[config.dataPointIndex];
                                            let chartSeries2Value = config.w.config.series[1].data[config.dataPointIndex];

                                            let highlightedElement = event.target.parentNode.parentNode.parentNode.childNodes[1];
                                            if (highlightedElement.classList.contains('apexcharts-xcrosshairs')) {
                                                if (chartSeries1Value > chartSeries2Value) {
                                                    highlightedElement.classList.add("active");
                                                    highlightedElement.classList.add("apexcharts-active");
                                                    highlightedElement.classList.add("overall-chart-highlight-red");
                                                } else {
                                                    highlightedElement.classList.add("active");
                                                    highlightedElement.classList.add("apexcharts-active");
                                                    highlightedElement.classList.add("overall-chart-highlight-green");
                                                }
                                            }
                                        }
                                    })
                                },
                                click: function (event, chartContext, config) {
                                    let listCompareNew = listCompare11.length > listCompare22 ? listCompare11 : listCompare22;
                                    listCompareNew.map((item, index) => {
                                        if (index == config.dataPointIndex) {
                                            var array = item.split('-');
                                            minValue = array[0];
                                            maxValue = array[1];
                                        }
                                    })
                                    setVisibilityTableRawData(minValue, maxValue)
                                }
                            },
                        },
                        plotOptions: {
                            bar: {
                                columnWidth: "10%",
                            },
                        },
                        xaxis: {
                            tickPlacement: "on",
                            categories: listCategory1.length > listCategory2.length ? listCategory1 : listCategory2,
                            title: {
                                text: "",
                                style: { color: "#000", },
                            },
                        },
                        yaxis:
                        {
                            title:
                            {
                                text: "",
                                style: { color: "#000", },
                            },
                            labels: {
                                formatter: function (value) {
                                    return returnMagnitude(value);
                                },
                            },
                            logBase: 20,
                            tickAmount: 8,
                            min: 0,
                            show: true,
                        },
                    });

                    setVisibilityTableRawData(minValue, maxValue)
                }
            }
        });
    }

    function setVisibilityTableRawData(minValueOrg, maxValueOrg) {
        let minValue = minValueOrg == undefined ? 0 : minValueOrg;
        let maxValue = maxValueOrg == undefined ? 1 : maxValueOrg;
        setRankValue("Rank " + minValue + "-" + maxValue)
        setMinValue(minValue)
        setMaxValue(maxValue)
        let rowData;

        let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
        let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
        let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
        let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)

        if (startDate == compareStartDate
            && endDate == compareEndDate) {
            rowData = {
                projectId: localStorage.getItem("projectGId"),
                RankingCampaignConfigurationId: props?.selectedCampaign,
                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                KeywordCategoryId: localStorage.getItem('categoryID'),//CategorySelected
                Minimum: minValue,
                Maximum: maxValue,
                DateRange: {
                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                },
                Type: parseInt(props?.rankingType),
                Device: parseInt(props?.deviceType),
                PageSize: localStorage.getItem('pageSize'),// pageSize,
                KeywordType: localStorage.getItem('keywordType'),//keywordType,
                CurrentPage: pageNumber1,
                TablePageSize: tablePageSize1,
                sorter: sortList,
                isSorter: false,
                filter: filterList,
            }
        }
        else {
            rowData = {
                projectId: localStorage.getItem("projectGId"),
                RankingCampaignConfigurationId: props?.selectedCampaign,
                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                KeywordCategoryId: localStorage.getItem('categoryID'),
                Minimum: minValue,
                Maximum: maxValue,
                DateRange: {
                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                },
                CompareDateRange: {
                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                },
                Type: parseInt(props?.rankingType),
                Device: parseInt(props?.deviceType),
                PageSize: localStorage.getItem('pageSize'),// pageSize,
                KeywordType: localStorage.getItem('keywordType'),//keywordType,
                CurrentPage: pageNumber1,
                TablePageSize: tablePageSize1,
                sorter: sortList,
                isSorter: false,
                filter: filterList,
            }
        }
        visibilityTableDataFunction(rowData)
    }

    function visibilityTableDataFunction(rowData) {
        APIManager.postAPI(
            rankingVisibilityAPI,
            rowData,
            null,
            true
        ).then((response) => {
            let filterKeywordList = [];
            let statusList = [];
            let categoryList = [];
            let totalCount;
            let categoryListFilter = [];
            let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
            let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
            let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
            let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)

            if (startDate == compareStartDate
                && endDate == compareEndDate) {
                response?.data?.table3?.map((item) => {
                    totalCount = item.totalCount;
                })
                setTableParams1({
                    pagination: {
                        current: rowData.CurrentPage,
                        pageSize: rowData.TablePageSize,
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        total: totalCount,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${totalCount} keywords`
                    },
                });

                if (response?.data?.table?.length > 0) {

                    let list = [];
                    response?.data?.table?.map((item) => {
                        let object = {
                            keyword1: item.keyword,

                            keyword: (<><div class="keyword-data d-flex align-tems-center"><span className="keyword-distribution-data">{item.keyword}</span>

                                {item.keywordType ?
                                    <a
                                        href="#"
                                        class="text-dark"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        title={item.keywordType}
                                    >
                                        <div class="scope-H d-flex align-tems-center ms-2 my-2">
                                            <span>{item.keywordType.charAt(0).toUpperCase()}
                                            </span></div></a> : ""}
                                {item.priority == 1 ?
                                    <a
                                        href="#"
                                        class="text-dark"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        title="Priority"
                                    > <div class=" d-flex align-tems-center my-2 ms-2"> <i class="fa-solid fa-star "></i></div></a> : <></>}</div></>),
                            searchVolume: item.searchVolume,
                            category: item.category,
                            rank: item.rank,
                            url: item.urls,
                        }
                        list.push(object)

                        let filterKeywordListObj = {
                            text: item.keyword,
                            value: item.keyword,
                        };
                        filterKeywordList.push(filterKeywordListObj);

                    })
                    setVisibilityTableData(list)
                    response?.data?.table1?.map((item) => {
                        setTotalVisibility(item.totalRankingVisibility)
                    })
                }
                else {
                    setVisibilityTableData([])
                }
            }


            else {
                response?.data?.table3?.map((item) => {
                    totalCount = item.totalCount;
                })
                setTableParams1({
                    pagination: {
                        current: rowData.CurrentPage,
                        pageSize: rowData.TablePageSize,
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        total: totalCount,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${totalCount} keywords`

                    },
                });
                if (response?.data?.table?.length > 0) {
                    let list = [];
                    response.data.table.map((item) => {
                        let object = {
                            keyword1: item.keyword,
                            keyword: (<><div class="keyword-data d-flex align-tems-center"> <span className="keyword-distribution-data">{item.keyword}</span>
                                {item.keywordType ?
                                    <a
                                        href="#"
                                        class="text-dark"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        title={item.keywordType}
                                    >
                                        <div class="scope-H d-flex align-tems-center ms-2 my-2">
                                            <span>{item.keywordType.charAt(0).toUpperCase()}
                                            </span>
                                        </div> </a> : ""}
                                {item.priority == 1 ?
                                    <a
                                        href="#"
                                        class="text-dark"
                                        data-toggle="tooltip"
                                        data-html="true"
                                        title="Priority"
                                    > <div class=" d-flex align-tems-center my-2 ms-2"> <i class="fa-solid fa-star"></i> </div> </a> : <></>}</div></>),
                            searchVolume: item.searchVolume,
                            category: item.category,
                            rank1: item.rank1,
                            rank2: item.rank2,
                            url: item.urls,
                            status: item.change == 0 ? (<span className="stable-color" style={{ color: "#179ef6" }}>Stable</span>) : (
                                item.change < 0 ?
                                    (<span className="dropped-color" style={{ color: "#F64F53" }}>Dropped</span>)
                                    : (<span className="improved-color" style={{ color: "#1C9525" }}>Improved</span>)),
                            change: item.change == 0 ? (<span className="stable-color" style={{ color: "#179ef6" }}>0</span>) : (
                                item.change < 0 ?
                                    (<span className="dropped-color" style={{ color: "#F64F53" }}>{item.change}</span>)
                                    : (<span className="improved-color" style={{ color: "#1C9525" }}>+{item.change}</span>)
                            ),
                            status1: item.status
                        }
                        list.push(object)
                        let filterKeywordListObj = {
                            text: item.keyword,
                            value: item.keyword,
                        };
                        filterKeywordList.push(filterKeywordListObj);

                        let statusObj = {
                            text: item.status,
                            value: item.status
                        }
                        statusList.push(statusObj);

                        let categoryObj = {
                            text: item.category,
                            value: item.category,
                        }
                        categoryList.push(categoryObj)
                    })
                    setVisibilityTableData(list)
                    let week1Data, week2Data;
                    if (response?.data?.table1) {
                        response?.data?.table1?.map((item) => {
                            setweek1Progress(item.weekOnProgress1)
                            setweek2Progress(item.weekOnProgress2)
                            week1Data = item.weekOnProgress1
                            week2Data = item.weekOnProgress2
                        })
                    }
                    let per = (week1Data - week2Data) / week2Data * 100.0;
                    if (per >= 0 || per <= 0) {
                        setIsWeekProgressCompareTabChange(per)
                    } else {
                        setIsWeekProgressCompareTabChange(0)
                    }

                    let visibility1, visibility2;
                    response?.data?.table2?.map((item) => {
                        setVisibility1(item.rankingVisibilityForRank1)
                        setVisibility2(item.rankingVisibilityForRank2)
                        visibility1 = item.rankingVisibilityForRank1;
                        visibility2 = item.rankingVisibilityForRank2;
                    })
                    let visibilityPercentage = (visibility1 - visibility2) / visibility2 * 100.0;
                    if (visibilityPercentage >= 0 || visibilityPercentage <= 0) {
                        setIsRankCompareTabChange1(visibilityPercentage)
                    }
                    else {
                        setIsRankCompareTabChange1(0)
                    }
                }
                else {
                    setVisibilityTableData([])
                }
            }

            visibilityTableColumnFunction(filterKeywordList, categoryList)
        });
    }

    function getKeywordDistributionHeader(rowdata) {
        APIManager.postAPI(keywordDistributionHeader, rowdata, null, true).then((response) => {
            if (response && response.status == 200) {
                let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
                let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
                let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
                let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
                let rankingVisibilityHeadVar;
                let rankingVisibilityTorsoVar;
                let rankingVisibilityTailVar;
                let rankingVisibilityTailCompVar;
                let rankingVisibilityTorsoCompVar;
                let rankingVisibilityHeadCompVar;

                let noOfKeywordsHeadVar;
                let noOfKeywordsTorsoVar;
                let noOfKeywordsTailVar;
                let noOfKeywordsHeadCompVar;
                let noOfKeywordsTorsoCompVar;
                let noOfKeywordsTailCompVar;


                let avgPosition11Var, avgPosition22Var;
                let rankingVisibility11Var, rankingVisibility22Var;
                if (startDate == compareStartDate
                    && endDate == compareEndDate) {
                    if (response?.data?.tableContent?.table.length > 0) {
                        let headSet = false;
                        let torsoSet = false;
                        let tailSet = false;

                        response?.data?.tableContent?.table.map((item, index) => {
                            if (item.keywordType == "Head") {
                                setAvgPositionHead(item.avgPosition);
                                setnoOfKeywordsHead(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                                setrankingVisibilityHead(item.rankingVisibility == null ? 0 : item.rankingVisibility);
                                headSet = true;
                            }
                            else if (item.keywordType == "Torso") {
                                setAvgPositionTorso(item.avgPosition);
                                setnoOfKeywordsTorso(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                                setrankingVisibilityTorso(item.rankingVisibility == null ? 0 : item.rankingVisibility);
                                torsoSet = true;
                            } else if (item.keywordType == "Tail") {
                                setAvgPositionTail(item.avgPosition);
                                setnoOfKeywordsTail(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                                setrankingVisibilityTail(item.rankingVisibility == null ? 0 : item.rankingVisibility);
                                tailSet = true;
                            }
                        });
                        if (headSet == false) {
                            setAvgPositionHead(0);
                            setnoOfKeywordsHead(0);
                            setrankingVisibilityHead(0);
                        }
                        if (tailSet == false) {
                            setAvgPositionTail(0);
                            setnoOfKeywordsTail(0);
                            setrankingVisibilityTail(0);
                        }
                        if (torsoSet == false) {
                            setAvgPositionTorso(0);
                            setnoOfKeywordsTorso(0);
                            setrankingVisibilityTorso(0);
                        }
                    }
                    else {
                        setAvgPositionHead(0);
                        setnoOfKeywordsHead(0);
                        setrankingVisibilityHead(0)
                        rankingVisibilityHeadVar = 0;
                        setAvgPositionTorso(0);
                        setnoOfKeywordsTorso(0);
                        setrankingVisibilityTorso(0)
                        rankingVisibilityTorsoVar = 0;
                        setAvgPositionTail(0);
                        setnoOfKeywordsTail(0);
                        setrankingVisibilityTail(0)
                        rankingVisibilityTailVar = 0;
                    }
                    response?.data?.tableContent?.table1.map((item) => {
                        setAvgPosition11(item.avgPosition);
                        setrankingVisibility11(item.rankingVisibility == null ? 0 : item.rankingVisibility)
                    });
                }
                else {
                    let headSet = false;
                    let torsoSet = false;
                    let tailSet = false;
                    let headSetCmp = false;
                    let torsoSetCmp = false;
                    let tailSetCmp = false;

                    if (response?.data?.tableContent?.table.length > 0) {
                        response?.data?.tableContent?.table.map((item, index) => {
                            if (item.keywordType == "Head") {
                                headSet = true;
                                setAvgPositionHead(item.avgPosition);
                                setnoOfKeywordsHead(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                                noOfKeywordsHeadVar = item.noOfKeywords == null ? 0 : item.noOfKeywords;
                            }
                            else if (item.keywordType == "Torso") {
                                torsoSet = true;
                                setAvgPositionTorso(item.avgPosition);
                                setnoOfKeywordsTorso(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                                noOfKeywordsTorsoVar = item.noOfKeywords == null ? 0 : item.noOfKeywords;
                            } else if (item.keywordType == "Tail") {
                                tailSet = true;
                                setAvgPositionTail(item.avgPosition);
                                setnoOfKeywordsTail(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                                noOfKeywordsTailVar = item.noOfKeywords == null ? 0 : item.noOfKeywords;
                            }
                        });
                        if (headSet == false) {
                            setAvgPositionHead(0);
                            setnoOfKeywordsHead(0);
                            noOfKeywordsHeadVar = 0;
                        }
                        if (tailSet == false) {
                            setAvgPositionTail(0);
                            setnoOfKeywordsTail(0);
                            noOfKeywordsTailVar = 0;
                        }
                        if (torsoSet == false) {
                            setAvgPositionTorso(0);
                            setnoOfKeywordsTorso(0);
                            noOfKeywordsTorsoVar = 0;
                        }
                    }
                    else {
                        noOfKeywordsHeadVar = 0;
                        noOfKeywordsTailVar = 0;
                        noOfKeywordsTorsoVar = 0;
                        setAvgPositionHead(0);
                        setnoOfKeywordsHead(0);
                        setrankingVisibilityHead(0)
                        rankingVisibilityHeadVar = 0;
                        setAvgPositionTorso(0);
                        setnoOfKeywordsTorso(0);
                        setrankingVisibilityTorso(0)
                        rankingVisibilityTorsoVar = 0;
                        setAvgPositionTail(0);
                        setnoOfKeywordsTail(0);
                        setrankingVisibilityTail(0)
                        rankingVisibilityTailVar = 0;
                    }
                    response?.data?.tableContent?.table1.map((item) => {
                        setAvgPosition11(item.avgPosition == null ? 0 : item.avgPosition);
                        avgPosition11Var = item.avgPosition == null ? 0 : item.avgPosition;
                        setrankingVisibility11(item.rankingVisibility == null ? 0 : item.rankingVisibility)
                        rankingVisibility11Var = item.rankingVisibility == null ? 0 : item.rankingVisibility;
                    });

                    response.data.compareTableContent.table.map((item, index) => {
                        if (item.keywordType == "Head") {
                            headSetCmp = true;
                            setnoOfKeywordsHeadComp(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                            noOfKeywordsHeadCompVar = item.noOfKeywords == null ? 0 : item.noOfKeywords;
                        }
                        else if (item.keywordType == "Torso") {
                            torsoSetCmp = true
                            setnoOfKeywordsTorsoComp(item.noOfKeywords == null ? 0 : item.noOfKeywords);
                            noOfKeywordsTorsoCompVar = item.noOfKeywords == null ? 0 : item.noOfKeywords;
                        } else if (item.keywordType == "Tail") {
                            tailSetCmp = true;
                            setnoOfKeywordsTailComp(item.noOfKeywords == null ? 0 : item.noOfKeywords,);
                            noOfKeywordsTailCompVar = item.noOfKeywords == null ? 0 : item.noOfKeywords;
                        }
                    });
                    if (headSetCmp == false) {
                        setnoOfKeywordsHeadComp(0);
                        noOfKeywordsHeadCompVar = 0;
                    }
                    if (tailSetCmp == false) {
                        setnoOfKeywordsTailComp(0);
                        noOfKeywordsTailCompVar = 0;
                    }
                    if (torsoSetCmp == false) {
                        setnoOfKeywordsTorsoComp(0);
                        noOfKeywordsTorsoCompVar = 0;
                    }
                    response.data.compareTableContent.table1.map((item) => {
                        setAvgPosition22(item.avgPosition == null ? 0 : item.avgPosition);
                        avgPosition22Var = item.avgPosition == null ? 0 : item.avgPosition;
                        setrankingVisibility22(item.rankingVisibility == null ? 0 : item.rankingVisibility)
                        rankingVisibility22Var = item.rankingVisibility == null ? 0 : item.rankingVisibility;
                    });

                    var setIsHeadCompareTabChangeValue = noOfKeywordsHeadVar == 0 && noOfKeywordsHeadCompVar == 0 ? 0 : noOfKeywordsHeadVar < noOfKeywordsHeadCompVar
                        ? (100 * (noOfKeywordsHeadCompVar - noOfKeywordsHeadVar)) / noOfKeywordsHeadCompVar
                        : (100 * (noOfKeywordsHeadVar - noOfKeywordsHeadCompVar)) / noOfKeywordsHeadVar;
                    let value = setIsHeadCompareTabChangeValue == NaN ? 0 : setIsHeadCompareTabChangeValue
                    setIsHeadCompareTabChange(setIsHeadCompareTabChangeValue == NaN ? 0 : setIsHeadCompareTabChangeValue);

                    var setIsTorsoCompareTabChangeValue = noOfKeywordsTorsoVar == 0 && noOfKeywordsTorsoCompVar == 0 ? 0 : noOfKeywordsTorsoVar < noOfKeywordsTorsoCompVar
                        ? (100 * (noOfKeywordsTorsoCompVar - noOfKeywordsTorsoVar)) / noOfKeywordsTorsoCompVar
                        : (100 * (noOfKeywordsTorsoVar - noOfKeywordsTorsoCompVar)) / noOfKeywordsTorsoVar;
                    setIsTorsoCompareTabChange(setIsTorsoCompareTabChangeValue);

                    var setIsTailCompareTabChangeValue = noOfKeywordsTailVar == 0 && noOfKeywordsTailCompVar == 0 ? 0 : noOfKeywordsTailVar < noOfKeywordsTailCompVar
                        ? (100 * (noOfKeywordsTailCompVar - noOfKeywordsTailVar)) / noOfKeywordsTailCompVar
                        : (100 * (noOfKeywordsTailVar - noOfKeywordsTailCompVar)) / noOfKeywordsTailVar;
                    setIsTailCompareTabChange(setIsTailCompareTabChangeValue);

                    let isCompareOverallValueTab = ((noOfKeywordsHeadCompVar) + (noOfKeywordsTorsoCompVar) + (noOfKeywordsTailCompVar));
                    let isOverallValueTab = ((noOfKeywordsHeadVar) + (noOfKeywordsTorsoVar) + (noOfKeywordsTailVar));
                    setIsOverallValueTab(isOverallValueTab);
                    setIsCompareOverallValueTab(isCompareOverallValueTab);

                    var setIsOverallCompareTabChangeValue = isOverallValueTab == 0 && isCompareOverallValueTab == 0 ? 0 : isOverallValueTab < isCompareOverallValueTab
                        ? (100 * (isCompareOverallValueTab - isOverallValueTab)) / isCompareOverallValueTab
                        : (100 * (isOverallValueTab - isCompareOverallValueTab)) / isOverallValueTab;
                    setIsOverallCompareTabChange(setIsOverallCompareTabChangeValue);

                    var AvgPositionTableValue = avgPosition11Var == 0 && avgPosition22Var == 0 ? 0 : avgPosition11Var < avgPosition22Var
                        ? (100 * (avgPosition22Var - avgPosition11Var)) / avgPosition22Var
                        : (100 * (avgPosition11Var - avgPosition22Var)) / avgPosition11Var;
                    setIsAvgCompareTabChange(AvgPositionTableValue);

                    var rankingVisibilityTableValue = rankingVisibility11Var == 0 && rankingVisibility22Var == 0 ? 0 : rankingVisibility11Var < rankingVisibility22Var
                        ? (100 * (rankingVisibility22Var - rankingVisibility11Var)) / rankingVisibility22Var
                        : (100 * (rankingVisibility11Var - rankingVisibility22Var)) / rankingVisibility11Var;
                    setIsRankCompareTabChange(rankingVisibilityTableValue);
                }
            }
        });
    }

    function getChartAndTableCall(keywordTypeValue) {
        setKeywordType(keywordTypeValue)
        localStorage.setItem('keywordType', keywordTypeValue);
        let rowdata;
        if (props?.selectedCampaign != '' && props?.selectedCampaigView != '') {
            let startDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
            let endDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
            let compareStartDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
            let compareEndDate = mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)

            if (startDate == compareStartDate
                && endDate == compareEndDate) {
                setIsDateRange(false)
                rowdata = {
                    ProjectId: localStorage.getItem("projectGId"),
                    RankingCampaignConfigurationId: props?.selectedCampaign,
                    RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                    Type: parseInt(props?.rankingType),
                    Device: parseInt(props?.deviceType),
                    PageSize: pageSize,
                    KeywordCategoryId: CategorySelected,
                    DateRange: {
                        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                    },
                    KeywordType: keywordTypeValue,
                };
            }
            else {
                setIsDateRange(true)
                rowdata = {
                    ProjectId: localStorage.getItem("projectGId"),
                    RankingCampaignConfigurationId: props?.selectedCampaign,
                    RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                    Type: parseInt(props?.rankingType),
                    Device: parseInt(props?.deviceType),
                    PageSize: pageSize,
                    KeywordCategoryId: CategorySelected,
                    DateRange: {
                        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                    },
                    CompareDateRange: {
                        FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                        TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                    },
                    KeywordType: keywordTypeValue,
                };
            }
            getKeywordDistributiontabledata(rowdata);
            getKeywordDistributionChartType(rowdata);
            setKeywordType(keywordTypeValue)
            localStorage.setItem('keywordType', keywordTypeValue);
            getKeywordDistributionHeader(rowdata)
        }
    }

    const handleTableChange1 = (pagination, filter, sorter) => {
        let sortListVar = [];
        setSortList(sortList);
        if (sorter.length > 0) {
            sorter.map((item) => {
                sortListVar.push({
                    columnName: item.columnKey,
                    order: item.order
                })
            })
        }
        else {
            if (sorter.columnKey != undefined) {
                sortListVar.push({
                    columnName: sorter.columnKey,
                    order: sorter.order
                })
            }
            if (sortListVar.length > 0) {
                setSortList(sortListVar)
            }
        }
        let filterList = [];
        if (filter.category != null) {
            if (filter.category.length > 0) {
                filter.category.map((item) => {
                    filterList.push(item)
                })
                setFilterList(filterList)
            }
        }

        let rowData;
        if (isDateRange == false) {
            rowData = {
                projectId: localStorage.getItem("projectGId"),
                RankingCampaignConfigurationId: props?.selectedCampaign,
                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                KeywordCategoryId: CategorySelected,
                Minimum: minValue,
                Maximum: maxValue,
                DateRange: {
                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                },
                Type: parseInt(props?.rankingType),
                Device: parseInt(props?.deviceType),
                PageSize: pageSize,
                KeywordType: keywordType,
                CurrentPage: pagination.current,
                TablePageSize: pagination.pageSize,
                sorter: sortListVar.length > 0 ? sortListVar : sortList,
                isSorter: false,
                filter: filterList,
            }
        }
        else {
            rowData = {
                projectId: localStorage.getItem("projectGId"),
                RankingCampaignConfigurationId: props?.selectedCampaign,
                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                KeywordCategoryId: CategorySelected,
                Minimum: minValue,
                Maximum: maxValue,
                DateRange: {
                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                },
                CompareDateRange: {
                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                },
                Type: parseInt(props?.rankingType),
                Device: parseInt(props?.deviceType),
                PageSize: pageSize,
                KeywordType: keywordType,
                CurrentPage: pagination.current,
                TablePageSize: pagination.pageSize,
                sorter: sortListVar.length > 0 ? sortListVar : sortList,
                isSorter: false,
                filter: filterList,
            }
        }
        visibilityTableDataFunction(rowData)
    }

    return (
        <>
            <div>
                <div className="keyword-destribution">
                    <div className="row ">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center justify-content-start  ">

                                <h5 className="keyword-distribution-name">Keyword Distribution</h5>
                                <select className="keyword-top form-select pe-4"
                                    name="brand"
                                    id="optsMain"
                                    value={pageSize}
                                    onChange={(e) => {
                                        setPageSize(parseInt(e.target.value));
                                        localStorage.setItem('pageSize', e.target.value);
                                        let ghostSelect = document.createElement('select');
                                        const select = document.getElementById('optsMain');
                                        var x = select.options[select.selectedIndex].text;

                                        const ghostOption = document.createElement("option");
                                        ghostOption.setAttribute("value", x);
                                        var t = document.createTextNode(x);
                                        ghostOption.appendChild(t);
                                        ghostSelect.appendChild(ghostOption);
                                        window.document.body.appendChild(ghostSelect)
                                        select.style.width = ghostSelect.offsetWidth + 'px';
                                        window.document.body.removeChild(ghostSelect)
                                        let rowdata;
                                        if (isDateRange == false) {
                                            setIsDateRange(false)
                                            rowdata = {
                                                ProjectId: localStorage.getItem("projectGId"),
                                                RankingCampaignConfigurationId: props?.selectedCampaign,
                                                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                                                Type: parseInt(props?.rankingType),
                                                Device: parseInt(props?.deviceType),
                                                PageSize: parseInt(e.target.value),
                                                KeywordCategoryId: CategorySelected,
                                                DateRange: {
                                                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                                                },
                                                KeywordType: keywordType,
                                            };
                                        }
                                        else {
                                            setIsDateRange(true)
                                            rowdata = {
                                                ProjectId: localStorage.getItem("projectGId"),
                                                RankingCampaignConfigurationId: props?.selectedCampaign,
                                                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                                                Type: parseInt(props?.rankingType),
                                                Device: parseInt(props?.deviceType),
                                                PageSize: parseInt(e.target.value),
                                                KeywordCategoryId: CategorySelected,
                                                DateRange: {
                                                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                                                },
                                                CompareDateRange: {
                                                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                                                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                                                },
                                                KeywordType: keywordType,
                                            };
                                        }
                                        getKeywordDistributiontabledata(rowdata);
                                        getKeywordDistributionChartType(rowdata);
                                        getKeywordDistributionHeader(rowdata);
                                    }}>
                                    <option value={5}>Top 5</option>
                                    <option value={10}>Top 10</option>
                                    <option value={100}>Top 100</option>
                                </select>
                            </div>

                            <div className="d-flex align-items-center justify-content-end category-select">
                                <label className="category-name my-2">Category :</label>
                                <select
                                    className="form-select ms-2"
                                    id="opts"
                                    value={CategorySelected}
                                    style={{ width: "72px" }}
                                    onChange={(e) => {
                                        var categoryId = e.target.value;
                                        setCategorySelected(categoryId);
                                        localStorage.setItem('categoryID', categoryId);

                                        let ghostSelect = document.createElement('select');
                                        const select = document.getElementById('opts');
                                        var x = select.options[select.selectedIndex].text;

                                        const ghostOption = document.createElement("option");
                                        ghostOption.setAttribute("value", x);
                                        var t = document.createTextNode(x);
                                        ghostOption.appendChild(t);
                                        ghostSelect.appendChild(ghostOption);
                                        window.document.body.appendChild(ghostSelect)
                                        select.style.width = ghostSelect.offsetWidth + 'px';
                                        window.document.body.removeChild(ghostSelect)

                                        let rowdata;
                                        if (isDateRange == false) {
                                            setIsDateRange(false)
                                            rowdata = {
                                                ProjectId: localStorage.getItem("projectGId"),
                                                RankingCampaignConfigurationId: props?.selectedCampaign,
                                                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                                                Type: parseInt(props?.rankingType),
                                                Device: parseInt(props?.deviceType),
                                                PageSize: pageSize,
                                                KeywordCategoryId: categoryId,
                                                DateRange: {
                                                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                                                },
                                                KeywordType: keywordType,
                                            };
                                        }
                                        else {
                                            setIsDateRange(true)
                                            rowdata = {
                                                ProjectId: localStorage.getItem("projectGId"),
                                                RankingCampaignConfigurationId: props?.selectedCampaign,
                                                RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                                                Type: parseInt(props?.rankingType),
                                                Device: parseInt(props?.deviceType),
                                                PageSize: pageSize,
                                                KeywordCategoryId: categoryId,
                                                DateRange: {
                                                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                                                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
                                                },
                                                CompareDateRange: {
                                                    FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
                                                    TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
                                                },
                                                KeywordType: keywordType,
                                            };
                                        }
                                        getKeywordDistributiontabledata(rowdata);
                                        getKeywordDistributionChartType(rowdata);
                                        getKeywordDistributionHeader(rowdata);
                                    }}
                                >
                                    {categoryType?.map((item) => {
                                        return <option value={item.id}>{item.categoryType}</option>;
                                    })}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                {isDateRange == false ?
                    (
                        <>
                            <div className="row ranking-keyword-distrbution">
                                <div
                                    id="overall-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer keyword-distribution"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }

                                        document.getElementById("overall-tab").classList.add("keyword-distribution");
                                        setIsOverallTab(1);
                                        setKeywordType(0)
                                        localStorage.setItem('keywordType', 0);
                                        getChartAndTableCall(0)
                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="keyword-distribution-tab">
                                            <div className="d-flex align-items-center justify-content-between ">
                                                <h4
                                                    className={`${isOverallTab == 1 ? "overall-name" : ""}`}
                                                >
                                                    Overall
                                                </h4>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="row">
                                                    <div className="d-flex align-items-center p-0 ">
                                                        <span className="avg-position"> Avg. Position :</span>
                                                        <span className="avg-position ms-1">{(avgPositionHead) + (avgPositionTorso) + (avgPositionTail)}</span>
                                                    </div>

                                                    <div className="d-flex align-items-center p-0 ">
                                                        <span className="ranking-visibility "> Visibility :</span>
                                                        <span className="ranking-visibility ms-1">{(rankingVisibilityHead) + (rankingVisibilityTorso) + (rankingVisibilityTail)}</span>
                                                    </div>
                                                </div>
                                                <p className="no-keyword text-end my-2">
                                                    {(noOfKeywordsHead) + (noOfKeywordsTorso) + (noOfKeywordsTail)}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    id="head-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }

                                        document.getElementById("head-tab").classList.add("keyword-distribution");
                                        setIsOverallTab(2);
                                        setKeywordType(1)
                                        localStorage.setItem('keywordType', 1);
                                        getChartAndTableCall(1)
                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="d-flex align-items-center  ">
                                            <h4
                                                className={`${isOverallTab == 2 ? "me-2 my-1 overall-name" : "me-2 my-1"}`}
                                            >
                                                Head
                                            </h4>
                                            <a
                                                href="#"
                                                class="text-dark "
                                                data-toggle="tooltip"
                                                data-html="true"
                                                title={`High search volume keywords between ${localStorage.getItem("minSearchVolumeHead")} and ${localStorage.getItem("maxSearchVolumeHead")}`}
                                            >
                                                <img
                                                    class="ticket-info"
                                                    src="/images/rankv2-info.svg"
                                                />
                                            </a>
                                            <defs>
                                                <clipPath id="clip0_5_3564">
                                                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                </clipPath>
                                            </defs>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row">
                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-position"> Avg. Position :</span>
                                                    <span className="avg-position ms-1">{avgPositionHead}</span>
                                                </div>

                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="ranking-visibility "> Visibility :</span>
                                                    <span className="ranking-visibility ms-1">{rankingVisibilityHead}</span>
                                                </div>
                                            </div>
                                            <p className="no-keyword text-end my-2">
                                                {noOfKeywordsHead}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    id="torso-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }

                                        document.getElementById("torso-tab").classList.add("keyword-distribution");
                                        setIsOverallTab(3);
                                        getChartAndTableCall(2)
                                        localStorage.setItem('keywordType', 2);
                                        setKeywordType(2)

                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="d-flex align-items-center  ">
                                            <h4
                                                className={`${isOverallTab == 3 ? "me-2 my-1 overall-name" : "me-2 my-1"}`}
                                            >
                                                Torso
                                            </h4>
                                            <a
                                                href="#"
                                                class="text-dark"
                                                data-toggle="tooltip"
                                                data-html="true"
                                                title={`Medium search volume keywords between ${localStorage.getItem("minSearchVolumeTorso")} and ${localStorage.getItem("maxSearchVolumeTorso")}`}

                                            >
                                                <img
                                                    class="ticket-info"
                                                    src="/images/rankv2-info.svg"
                                                />
                                            </a>
                                            <defs>
                                                <clipPath id="clip0_5_3564">
                                                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                </clipPath>
                                            </defs>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row">
                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-position"> Avg. Position :</span>
                                                    <span className="avg-position ms-1">{avgPositionTorso}</span>
                                                </div>

                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="ranking-visibility "> Visibility :</span>
                                                    <span className="ranking-visibility ms-1">{rankingVisibilityTorso}</span>
                                                </div>
                                            </div>
                                            <p className="no-keyword text-end my-2">
                                                {noOfKeywordsTorso}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    id="tail-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }

                                        document.getElementById("tail-tab").classList.add("keyword-distribution");
                                        setIsOverallTab(4);
                                        getChartAndTableCall(3);
                                        localStorage.setItem('keywordType', 3);
                                        setKeywordType(3);
                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="d-flex align-items-center ">
                                            <h4
                                                className={`${isOverallTab == 4 ? "me-2 my-1 overall-name" : "me-2 my-1"}`}
                                            >
                                                Tail
                                            </h4>
                                            <a
                                                href="#"
                                                class="text-dark"
                                                data-toggle="tooltip"
                                                data-html="true"
                                                title={`Low search volume keywords between ${localStorage.getItem("minSearchVolumeTail")} and ${localStorage.getItem("maxSearchVolumeTail")}`}
                                            >
                                                <img
                                                    class="ticket-info"
                                                    src="/images/rankv2-info.svg"
                                                />
                                            </a>
                                            <defs>
                                                <clipPath id="clip0_5_3564">
                                                    <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                </clipPath>
                                            </defs>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row">
                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-position"> Avg. Position :</span>
                                                    <span className="avg-position ms-1 ">{avgPositionTail}</span>
                                                </div>

                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="ranking-visibility "> Visibility :</span>
                                                    <span className="ranking-visibility ms-1">{rankingVisibilityTail}</span>
                                                </div>
                                            </div>
                                            <p className="no-keyword text-end my-2">
                                                {noOfKeywordsTail}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            < div className="overall-rank mt-2">
                                <div className="overall-title">
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h4 className="me-3 ranking-name my-2">Overall Rank Distribution</h4>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="btn-box px-2 py-2">
                                                <div className="d-flex align-items-center">
                                                    <button
                                                        className={`${isChart == 0 ? "active" : ""}`}
                                                        onClick={() => {
                                                            let type = 0;
                                                            setIsChart(type);
                                                        }}
                                                    >
                                                        <img
                                                            src={`${isChart == 0
                                                                ? "/images/graph-icon-white.svg"
                                                                : "/images/graph-icon-black.svg"
                                                                }`}
                                                            alt=""
                                                        />
                                                    </button>
                                                    <button
                                                        className={`${isChart == 1 ? "active" : ""}`}
                                                        onClick={() => {
                                                            let type = 1;
                                                            setIsChart(type);
                                                        }}
                                                    >
                                                        <img
                                                            src={`${isChart == 1
                                                                ? "/images/table-icon-white.svg"
                                                                : "/images/table-icon-black.svg"
                                                                }`}
                                                            alt=""
                                                        />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div className="row ranking-keyword-distrbution compare-date">
                                <div
                                    id="overall-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer keyword-distribution"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }

                                        document.getElementById("overall-tab").classList.add("keyword-distribution");
                                        setIsOverallTab(1);
                                        setIsOverallTabActive(0);
                                        setIsHeadTabActive(1);
                                        setIsTorsoTabActive(1);
                                        setIsTailTabActive(1);
                                        getChartAndTableCall(0)
                                        localStorage.setItem('keywordType', 0);
                                        setKeywordType(0)
                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="keyword-distribution-tab date-wise-select">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4
                                                        className={`${isOverallTabActive == 0 ? "overall-name me-2 my-1 active" : "overall-name"}`}
                                                    >
                                                        Overall
                                                    </h4>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="btn-box">
                                                        <div className="d-flex align-items-center">
                                                            {isOverallTab == 1 && isOverallTabActive == 0 ?
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="overall-tab-active-trending-img"
                                                                                src={`${parseFloat(isCompareOverallValueTab) == 0 && parseFloat(isOverallValueTab) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-green-circle.svg" :

                                                                                    parseFloat(isCompareOverallValueTab) > parseFloat(isOverallValueTab)
                                                                                        ? "/images/keyword-distribution-trending-up-green-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-red-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="overall-tab-inactive-trending-img"
                                                                                src={`${parseFloat(isCompareOverallValueTab) == 0 && parseFloat(isOverallValueTab) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-grey-circle.svg" :
                                                                                    parseFloat(isCompareOverallValueTab) > parseFloat(isOverallValueTab)
                                                                                        ? "/images/keyword-distribution-trending-up-grey-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-grey-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 className="ROIComparechk">
                                                <span className={`${isOverallTabActive == 0 ? parseFloat(isCompareOverallValueTab) == 0 && parseFloat(isOverallValueTab) == 0 ? "text-success" : parseFloat(isCompareOverallValueTab) > parseFloat(isOverallValueTab) ? "text-success" : "text-decline" : "text-plain"}`}>
                                                    {parseFloat(isCompareOverallValueTab) == 0 && parseFloat(isOverallValueTab) == 0 || (parseFloat(isCompareOverallValueTab) == parseFloat(isOverallValueTab)) ? "" : parseFloat(isCompareOverallValueTab) > parseFloat(isOverallValueTab) ? "+" : "-"}
                                                    {fixTwoPlaceDecimal(isOverallCompareTabChange)}%
                                                </span>
                                            </h3>
                                            <div className="row ">
                                                <div className="d-flex align-items-center justify-content-between p-0">
                                                    <div className="col-md-6 p-0">
                                                        <p>{isCompareOverallValueTab}</p>
                                                        <span>{formatRankDate(compareStartDate)}</span>
                                                    </div>

                                                    <div className="col-md-6 roi-total-compare">
                                                        <p>{isOverallValueTab}</p>
                                                        <span>{formatRankDate(startDate)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    id="head-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }

                                        document.getElementById("head-tab").classList.add("keyword-distribution");
                                        setIsHeadTab(2);
                                        setIsOverallTabActive(1);
                                        setIsHeadTabActive(0);
                                        setIsTorsoTabActive(1);
                                        setIsTailTabActive(1);
                                        getChartAndTableCall(1)
                                        setKeywordType(1)
                                        localStorage.setItem('keywordType', 1);

                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="keyword-distribution-tab date-wise-select">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4
                                                        className={`${isHeadTabActive == 0 ? "overall-name me-2 my-1 active" : "overall-name me-2 my-1"}`}
                                                    >
                                                        Head
                                                    </h4>

                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        title={`High search volume keywords between ${localStorage.getItem("minSearchVolumeHead")} and ${localStorage.getItem("maxSearchVolumeHead")}`}
                                                    >
                                                        <img
                                                            class="ticket-info"
                                                            src="/images/rankv2-info.svg"
                                                        />
                                                    </a>
                                                    <defs>
                                                        <clipPath id="clip0_5_3564">
                                                            <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="btn-box">
                                                        <div className="d-flex align-items-center">
                                                            {isHeadTab == 2 && isHeadTabActive == 0 ?
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="head-tab-active-trending-img"
                                                                                src={`${parseFloat(noOfKeywordsHeadComp) == 0 && parseFloat(noOfKeywordsHead) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-green-circle.svg" :
                                                                                    parseFloat(noOfKeywordsHeadComp) > parseFloat(noOfKeywordsHead)
                                                                                        ? "/images/keyword-distribution-trending-up-green-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-red-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="head-tab-inactive-trending-img"
                                                                                src={`${parseFloat(noOfKeywordsHeadComp) == 0 && parseFloat(noOfKeywordsHead) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-grey-circle.svg" :
                                                                                    parseFloat(noOfKeywordsHeadComp) > parseFloat(noOfKeywordsHead)
                                                                                        ? "/images/keyword-distribution-trending-up-grey-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-grey-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 className="ROICoparechk">
                                                <span className={`${isHeadTabActive == 0 ? parseFloat(noOfKeywordsHeadComp) == 0 && parseFloat(noOfKeywordsHead) == 0 ? "text-success" : parseFloat(noOfKeywordsHeadComp) > parseFloat(noOfKeywordsHead) ? "text-success" : "text-decline" : "text-plain"}`}>
                                                    {parseFloat(noOfKeywordsHeadComp) == 0 && parseFloat(noOfKeywordsHead) == 0 || (parseFloat(noOfKeywordsHeadComp) == parseFloat(noOfKeywordsHead)) ? "" : parseFloat(noOfKeywordsHeadComp) > parseFloat(noOfKeywordsHead) ? "+" : "-"}
                                                    {fixTwoPlaceDecimal(isHeadCompareTabChange)}%
                                                </span>
                                            </h3>
                                            <div className="row">
                                                <div className="d-flex align-items-center justify-content-between p-0">
                                                    <div className="col-md-6 p-0">
                                                        <p>{noOfKeywordsHeadComp}</p>
                                                        <span>{formatRankDate(compareStartDate)}</span>
                                                    </div>

                                                    <div className="col-md-6 roi-total-compare">
                                                        <p>{noOfKeywordsHead}</p>
                                                        <span>{formatRankDate(startDate)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    id="torso-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }
                                        document.getElementById("torso-tab").classList.add("keyword-distribution");
                                        setIsTorsoTab(3);
                                        setIsOverallTabActive(1);
                                        setIsHeadTabActive(1);
                                        setIsTorsoTabActive(0);
                                        setIsTailTabActive(1);
                                        getChartAndTableCall(2)
                                        setKeywordType(2)
                                        localStorage.setItem('keywordType', 2);

                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="keyword-distribution-tab date-wise-select">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4
                                                        className={`${isTorsoTabActive == 0 ? "overall-name me-2 my-1 active" : "overall-name me-2 my-1"}`}
                                                    >
                                                        Torso
                                                    </h4>
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        title={`Medium search volume keywords between ${localStorage.getItem("minSearchVolumeTorso")} and ${localStorage.getItem("maxSearchVolumeTorso")}`}
                                                    >
                                                        <img
                                                            class="ticket-info"
                                                            src="/images/rankv2-info.svg"
                                                        />
                                                    </a>
                                                    <defs>
                                                        <clipPath id="clip0_5_3564">
                                                            <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="btn-box">
                                                        <div className="d-flex align-items-center">
                                                            {isTorsoTab == 3 && isTorsoTabActive == 0 ?
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="torso-tab-active-trending-img"
                                                                                src={`${parseFloat(noOfKeywordsTorsoComp) == 0 && parseFloat(noOfKeywordsTorso) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-green-circle.svg" :
                                                                                    parseFloat(noOfKeywordsTorsoComp) > parseFloat(noOfKeywordsTorso)
                                                                                        ? "/images/keyword-distribution-trending-up-green-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-red-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="torso-tab-inactive-trending-img"
                                                                                src={`${parseFloat(noOfKeywordsTorsoComp) == 0 && parseFloat(noOfKeywordsTail) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-grey-circle.svg" :
                                                                                    parseFloat(noOfKeywordsTorsoComp) > parseFloat(noOfKeywordsTorso)
                                                                                        ? "/images/keyword-distribution-trending-up-grey-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-grey-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 className="ROIComparechk">
                                                <span className={`${isTorsoTabActive == 0 ? parseFloat(noOfKeywordsTorsoComp) == 0 && parseFloat(noOfKeywordsTorso) == 0 ? "text-success" : parseFloat(noOfKeywordsTailComp) > parseFloat(noOfKeywordsTail) ? "text-success" : "text-decline" : "text-plain"}`}>
                                                    {parseFloat(noOfKeywordsTorsoComp) == 0 && parseFloat(noOfKeywordsTorso) == 0 || (parseFloat(noOfKeywordsTorsoComp) == parseFloat(noOfKeywordsTorso)) ? "" : parseFloat(noOfKeywordsTorsoComp) > parseFloat(noOfKeywordsTorso) ? "+" : "-"}
                                                    {fixTwoPlaceDecimal(isTorsoCompareTabChange)}%
                                                </span>
                                            </h3>
                                            <div className="row">
                                                <div className="d-flex align-items-center justify-content-between p-0">
                                                    <div className="col-md-6 p-0">
                                                        <p>{noOfKeywordsTorsoComp}</p>
                                                        <span>{formatRankDate(compareStartDate)}</span>
                                                    </div>

                                                    <div className="col-md-6 roi-total-compare">
                                                        <p>{noOfKeywordsTorso}</p>
                                                        <span>{formatRankDate(startDate)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div
                                    id="tail-tab"
                                    className="col-md-3 col-sm-12 m-0 cursor-pointer"
                                    onClick={() => {
                                        if (document.getElementById("overall-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("overall-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("head-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("head-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("torso-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("torso-tab").classList.remove("keyword-distribution");
                                        } else if (document.getElementById("tail-tab").classList.contains('keyword-distribution')) {
                                            document.getElementById("tail-tab").classList.remove("keyword-distribution");
                                        }

                                        document.getElementById("tail-tab").classList.add("keyword-distribution");
                                        setIsTailTab(4);
                                        setIsOverallTabActive(1);
                                        setIsHeadTabActive(1);
                                        setIsTorsoTabActive(1);
                                        setIsTailTabActive(0);
                                        getChartAndTableCall(3)
                                        setKeywordType(3)
                                        localStorage.setItem('keywordType', 3);

                                    }}
                                >
                                    <div className="keyword-distribution-tab">
                                        <div className="keyword-distribution-tab date-wise-select">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <h4
                                                        className={`${isTailTabActive == 0 ? "overall-name me-2 my-1 active" : "overall-name me-2 my-1"}`}
                                                    >
                                                        Tail
                                                    </h4>
                                                    <a
                                                        href="#"
                                                        class="text-dark"
                                                        data-toggle="tooltip"
                                                        data-html="true"
                                                        title={`Low search volume keywords between ${localStorage.getItem("minSearchVolumeTail")} and ${localStorage.getItem("maxSearchVolumeTail")}`}
                                                    >
                                                        <img
                                                            class="ticket-info"
                                                            src="/images/rankv2-info.svg"
                                                        />
                                                    </a>
                                                    <defs>
                                                        <clipPath id="clip0_5_3564">
                                                            <rect width="16" height="16" fill="white" transform="translate(0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="btn-box">
                                                        <div className="d-flex align-items-center">
                                                            {isTailTab == 4 && isTailTabActive == 0 ?
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="tail-tab-active-trending-img"
                                                                                src={`${parseFloat(noOfKeywordsTailComp) == 0 && parseFloat(noOfKeywordsTail) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-green-circle.svg" :
                                                                                    parseFloat(noOfKeywordsTailComp) > parseFloat(noOfKeywordsTail)
                                                                                        ? "/images/keyword-distribution-trending-up-green-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-red-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                                :
                                                                (
                                                                    <>
                                                                        <div>
                                                                            <img
                                                                                id="tail-tab-inactive-trending-img"
                                                                                src={`${parseFloat(noOfKeywordsTailComp) == 0 && parseFloat(noOfKeywordsTail) == 0 ?
                                                                                    "/images/keyword-distribution-trending-up-grey-circle.svg" :
                                                                                    parseFloat(noOfKeywordsTailComp) > parseFloat(noOfKeywordsTail)
                                                                                        ? "/images/keyword-distribution-trending-up-grey-circle.svg"
                                                                                        : "/images/keyword-distribution-trending-down-grey-circle.svg"
                                                                                    }`}
                                                                                alt=""
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <h3 className="">
                                                <span className={`${isTailTabActive == 0 ? parseFloat(noOfKeywordsTailComp) == 0 && parseFloat(noOfKeywordsTail) == 0 ? "text-success" : parseFloat(noOfKeywordsTailComp) > parseFloat(noOfKeywordsTail) ? "text-success" : "text-decline" : "text-plain"}`}>
                                                    {parseFloat(noOfKeywordsTailComp) == 0 && parseFloat(noOfKeywordsTail) == 0 || (parseFloat(noOfKeywordsTailComp) == parseFloat(noOfKeywordsTail)) ? "" : parseFloat(noOfKeywordsTailComp) > parseFloat(noOfKeywordsTail) ? "+" : "-"}
                                                    {fixTwoPlaceDecimal(isTailCompareTabChange)}%

                                                </span>
                                            </h3>
                                            <div className="row">
                                                <div className="d-flex align-items-center justify-content-between p-0">
                                                    <div className="col-md-6 p-0">
                                                        <p>{noOfKeywordsTailComp}</p>
                                                        <span>{formatRankDate(compareStartDate)}</span>
                                                    </div>

                                                    <div className="col-md-6 roi-total-compare">
                                                        <p>{noOfKeywordsTail}</p>
                                                        <span>{formatRankDate(startDate)}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {isDateRange ? <> <div className="row avg-ranking ranking-keyword-distrbution mt-3">
                                <div className="col-md-3  m-0  mx-2 ms-3">
                                    <div className="keyword-distribution-tab">
                                        <div className="d-flex align-items-center ">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="me-2 my-1">Average Position</h4>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="btn-box">
                                                    <div className="d-flex align-items-center">
                                                        <div >
                                                            <img
                                                                id="overall-tab-active-trending-img"
                                                                src={`${parseFloat(avgPosition22) == 0 && parseFloat(avgPosition11) == 0 ?
                                                                    "/images/keyword-distribution-trending-up-green-circle.svg" :
                                                                    parseFloat(avgPosition22) > parseFloat(avgPosition11)
                                                                        ? "/images/keyword-distribution-trending-up-green.svg"
                                                                        : "/images/keyword-distribution-trending-down-red.svg"
                                                                    }`}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row">
                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date">{formatRankDate(compareStartDate)} :</span>
                                                    <span className="avg-score ms-1">{avgPosition22}</span>
                                                </div>

                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date">{formatRankDate(startDate)} :</span>
                                                    <span className="avg-score ms-1">{avgPosition11}</span>
                                                </div>
                                            </div>
                                            <p className="no-keyword text-end my-2">
                                                <span className={`${parseFloat(avgPosition22) == 0 && parseFloat(avgPosition11) == 0 ? "text-success"
                                                    : parseFloat(avgPosition22) > parseFloat(avgPosition11) ? "text-success" : "text-decline"}`}>
                                                    {fixTwoPlaceDecimal(isAvgCompareTabChange)}%
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3  m-0 ">
                                    <div className="keyword-distribution-tab">
                                        <div className="d-flex align-items-center ">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="me-2 my-1">Ranking Visibility</h4>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="btn-box">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <img
                                                                id="overall-tab-active-trending-img"
                                                                src={`${parseFloat(rankingVisibility22) == 0 && parseFloat(rankingVisibility11) == 0 ?
                                                                    "/images/keyword-distribution-trending-up-green-circle.svg" :
                                                                    parseFloat(rankingVisibility22) > parseFloat(rankingVisibility11)
                                                                        ? "/images/keyword-distribution-trending-up-green.svg"
                                                                        : "/images/keyword-distribution-trending-down-red.svg"
                                                                    }`}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row">
                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date "> {formatRankDate(compareStartDate)} :</span>
                                                    <span className="avg-score ms-1">{rankingVisibility22}</span>
                                                </div>

                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date">{formatRankDate(startDate)} :</span>
                                                    <span className="avg-score ms-1">{rankingVisibility11}</span>
                                                </div>
                                            </div>
                                            <p className="no-keyword text-end my-2">
                                                <span className={`${parseFloat(rankingVisibility22) == 0 && parseFloat(rankingVisibility11) == 0 ? "text-success" :
                                                    parseFloat(rankingVisibility22) > parseFloat(rankingVisibility11) ? "text-success" : "text-decline"}`}>
                                                    {fixTwoPlaceDecimal(isRankCompareTabChange)}%
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                < div className="overall-rank mt-2">
                                    <div className="overall-title">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex justify-content-between align-items-center">
                                                <h4 className="me-3 ranking-name my-2">Overall Rank Distribution</h4>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="btn-box px-2 py-2">
                                                    <div className="d-flex align-items-center">
                                                        <button
                                                            className={`${isChart == 0 ? "active" : ""}`}
                                                            onClick={() => {
                                                                let type = 0;
                                                                setIsChart(type);
                                                            }}
                                                        >
                                                            <img
                                                                src={`${isChart == 0
                                                                    ? "/images/graph-icon-white.svg"
                                                                    : "/images/graph-icon-black.svg"
                                                                    }`}
                                                                alt=""
                                                            />
                                                        </button>
                                                        <button
                                                            className={`${isChart == 1 ? "active" : ""}`}
                                                            onClick={() => {
                                                                let type = 1;
                                                                setIsChart(type);
                                                            }}
                                                        >
                                                            <img
                                                                src={`${isChart == 1
                                                                    ? "/images/table-icon-white.svg"
                                                                    : "/images/table-icon-black.svg"
                                                                    }`}
                                                                alt=""
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div></> : <></>}

                        </>
                    )
                }

                {isChart == 0 ?
                    (
                        <>
                            <div className="col-md-12 col-sm-12 mt-3 overall-chart">
                                <Chart
                                    options={options}
                                    series={series}
                                    type="bar"
                                    height="300"
                                    id="chartId"
                                />
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <div className="col-md-12 mt-3">
                                <Table className="compare-keyword-distribution-table ranking-table-design"
                                    columns={(isDateRange == false) ? keyDistributionTabCol : keyDistributionTabCompareCol}
                                    dataSource={(isDateRange == false) ? keyDistributionTabList : keyDistributionTabCompareList}
                                    pagination={false}
                                ></Table>
                            </div>
                        </>
                    )
                }

                {isDateRange ?
                    (
                        <>
                            <div className="row avg-ranking ranking-keyword-distrbution mt-3 ms-3">
                                <div className="col-md-4  m-0 me-2">
                                    <div className="keyword-distribution-tab">
                                        <div className="d-flex align-items-center ">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="me-2 my-1">Week on Week Progress</h4>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="btn-box">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <img
                                                                id="overall-tab-active-trending-img"
                                                                src={`${parseFloat(isWeekProgressCompareTabChange) == 0 ? "" :
                                                                    isWeekProgressCompareTabChange
                                                                        ? "/images/keyword-distribution-trending-up-green.svg"
                                                                        : "/images/keyword-distribution-trending-down-red.svg"
                                                                    }`}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row">
                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date">{formatRankDate(compareStartDate)} :</span>
                                                    <span className="avg-score ms-1"> {week2Progress}</span>
                                                </div>

                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date">{formatRankDate(startDate)} :</span>
                                                    <span className="avg-score ms-1"> {week1Progress}</span>
                                                </div>
                                            </div>
                                            <p className="no-keyword text-end my-2">
                                                <span className={`${parseFloat(isWeekProgressCompareTabChange) == 0 ? "" : isWeekProgressCompareTabChange > 0 ? "text-success" : "text-decline"}`}>
                                                    {isWeekProgressCompareTabChange}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4  m-0 ">
                                    <div className="keyword-distribution-tab">
                                        <div className="d-flex align-items-center ">
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="me-2 my-1">Ranking Visibility for {rankValue}</h4>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <div className="btn-box">
                                                    <div className="d-flex align-items-center">
                                                        <div>
                                                            <img
                                                                id="overall-tab-active-trending-img"
                                                                src={`${parseFloat(isRankCompareTabChange1) == 0 ? "" :
                                                                    isRankCompareTabChange1
                                                                        ? "/images/keyword-distribution-trending-up-green.svg"
                                                                        : "/images/keyword-distribution-trending-down-red.svg"
                                                                    }`}
                                                                alt=""
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="row">
                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date">{formatRankDate(compareStartDate)} :</span>
                                                    <span className="avg-score ms-1"> {visibility2}</span>
                                                </div>

                                                <div className="d-flex align-items-center p-0 ">
                                                    <span className="avg-date "> {formatRankDate(startDate)} :</span>
                                                    <span className="avg-score ms-1">{visibility1}</span>
                                                </div>
                                            </div>
                                            <p className="no-keyword text-end my-2">
                                                <span className={`${parseFloat(isRankCompareTabChange1) == 0 ? "" : isRankCompareTabChange1 > 0 ? "text-success" : "text-decline"}`}>
                                                    {isRankCompareTabChange1}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                        </>
                    )
                }

                < div className="row mt-3">
                    <div className="d-flex align-items-center ms-3">
                        <span className="total-rank">
                            Total Ranking Visibility for {rankValue} :
                        </span>
                        <span className="total-rank-no ms-1">
                            {totalVisibility}
                        </span>
                    </div>

                    <div className="col-md-12 mt-3 ">
                        <Table className="ps-3 pe-3 keyword-distribution-table  ranking-table-design"
                            columns={visibilityTableColumn}
                            dataSource={visibilityTableData}
                            pagination={tableParams1.pagination}
                            onChange={handleTableChange1}
                        >
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default KeywordDistribution;