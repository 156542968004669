import React, { useEffect, useState, useRef } from "react";
import { Table } from "antd";
import APIManager from "../../services/APIManager";
import {
    mmDDyyyyFormateDateSet,
    exportToEXCEL,
    DDMMyyyyFormateDateSet,
    urlDomainName,
} from "../../utility/Globals";
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import {
    getLatestRankings,
    getLatestRankingExport,
    getcompetitorsByRankingcampaignConfigId,
} from "../../utility/Constant";

function LatestRanking(props) {
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [compareStartDate, setCompareStartDate] = useState("");
    const [compareendDate, setCompareEndDate] = useState("");
    const [columnList, setColumnList] = useState([])
    const [columnSelected, setColumnSelected] = useState([])
    const [competitorsList, setCompetitorsList] = useState([])
    const [competitorsSelected, setCompetitorsSelected] = useState([])
    const [tableColumn, setTableColumn] = useState([])
    const [tableColumn1, setTableColumn1] = useState([])
    const [tableData, setTableData] = useState([])
    const [campaignId, setCampaignId] = useState("")
    const [mainDomainName, setMainDomainName] = useState("")
    const [viewId, setViewId] = useState("")
    const [deviceType, setDeviceType] = useState(0)
    const [rankingType, setRankingType] = useState(0)
    const [exportList, setExportList] = useState([]);
    const [totalCount, setTotalCount] = useState(0)
    const [pageNumber, setPageNumber] = useState(1);
    const [tablePageSize, setTablePageSize] = useState(10);
    const [tableParams, setTableParams] = useState({
        pagination: {
            position: ["bottomRight"],
            showSizeChanger: true,
            current: 1,
            pageSize: 10,
            total: 10,
            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} keywords`
        },
    });

    useEffect(() => {
        getLatestRankingData();
    }, [props?.dataParentToChild]);

    useEffect(() => {
        getLatestRankingData();
    }, [props?.rankingType]);

    useEffect(() => {
        getLatestRankingData();
    }, [props?.deviceType]);

    useEffect(() => {
        getLatestRankingData();
    }, [props?.selectedCampaign]);

    useEffect(() => {
        getLatestRankingData();
    }, [props?.selectedCampaigView]);

    useEffect(() => {
        let list = [
            { label: 'Search Volume', value: 1 },
            { label: 'Category', value: 2 },
            { label: 'Type', value: 3 },
            { label: 'Country', value: 4 },
            { label: 'Rank', value: 5 },
            { label: 'My URL', value: 6 },
            { label: 'Competitors URL', value: 7 },
        ]
        setColumnList(list);
        setColumnSelected(list);
    }, [])

    function setAddCompetitorsSelectDropdown(selectedCampaign) {
        setCompetitorsList([]);
        setCompetitorsSelected([]);

        APIManager.getAPI(getcompetitorsByRankingcampaignConfigId + selectedCampaign).then(
            (response) => {
                if (response && response.status == 200) {
                    if (response?.data) {
                        let competitorList = [];
                        let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

                        response?.data.map((item) => {
                            let domainName = urlDomainName(item.competitor); // .replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());                            

                            if (mainDomainNameNew != domainName) {
                                let competitorObj = {
                                    label: domainName,
                                    value: item.competitor,
                                }
                                competitorList.push(competitorObj);
                            }
                        });

                        setCompetitorsList(competitorList);
                    }
                }
            }
        );
    }

    function handleColumnChange(selected) {
        setTableColumn([]);
        let column = [];
        let column1 = [];
        let childrenMain = [];
        let children = [];

        column.push({
            title: "",
            children: [
                {
                    title: "Keywords",
                    dataIndex: "keyword",
                    key: "keyword",
                }
            ]
        })

        tableColumn1.map((item1) => {
            if (item1.title == "") {
                item1.children.map((item2) => {
                    selected.map((item) => {
                        if (item.value == 1) {
                            if (item2.dataIndex == "searchVolume") {
                                column[0].children.push(item2)
                            }
                        }
                        else if (item.value == 2) {
                            if (item2.dataIndex == "category") {
                                column[0].children.push(item2)
                            }
                        }
                        else if (item.value == 3) {
                            if (item2.dataIndex == "types") {
                                column[0].children.push(item2)
                            }
                        }
                        else if (item.value == 4) {
                            if (item2.dataIndex == "location") {
                                column[0].children.push(item2)
                            }
                        }
                    });
                })
            }
        });

        let selectCompetitors;
        let mainDomainNameNew;
        tableColumn1.map((item1) => {

            if (item1.title != "") {
                childrenMain = [];
                mainDomainNameNew = urlDomainName(props?.domainName) //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

                if (competitorsSelected.length > 0) {
                    competitorsSelected.map((competitorSelected) => {
                        children = [];
                        selectCompetitors = urlDomainName(competitorSelected.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

                        selected.map((item) => {
                            if (item.value == 5 || item.value == 6 || item.value == 7) {
                                let domainName = urlDomainName(item1.children[0].dataIndex); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                                let domainURL = item1.children[1].dataIndex;

                                if (item1.title == selectCompetitors && mainDomainNameNew != domainName && item.value == 5) {
                                    children.push({
                                        title: "Rank",
                                        dataIndex: item1.children[0].dataIndex,
                                        key: item1.children[0].dataIndex,
                                    });
                                }

                                if (item1.title == selectCompetitors && mainDomainNameNew != domainName && item.value == 7) {
                                    children.push({
                                        title: "Competitors URL",
                                        dataIndex: domainURL,
                                        key: domainURL,
                                    });
                                }
                            }
                        })

                        if (item1.title == selectCompetitors) {
                            let object = {
                                title: item1.title,
                                children: children
                            }
                            column1.push(object);
                        }
                    });
                }

                if (item1.title == mainDomainNameNew) {
                    selected.map((item) => {
                        if (item.value == 5 || item.value == 6 || item.value == 7) {
                            mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                            let domainName = urlDomainName(item1.children[0].dataIndex); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                            let domainURL = item1.children[1].dataIndex;

                            if (mainDomainNameNew == domainName && item.value == 5) {
                                childrenMain.push({
                                    title: "Rank",
                                    dataIndex: item1.children[0].dataIndex,
                                    key: item1.children[0].dataIndex,
                                });
                            }

                            if (mainDomainNameNew == domainName && item.value == 6) {
                                childrenMain.push({
                                    title: "URL",
                                    dataIndex: domainURL,
                                    key: domainURL,
                                });
                            }
                        }
                    })

                    let object = {
                        title: item1.title,
                        children: childrenMain
                    }
                    column1.push(object);
                }
            }
        });

        children = column.concat(column1);
        setTableColumn(children.length > 0 ? children : column)
    }

    function handleCompetitorsChange(selected) {
        setTableColumn([]);
        let column = [];
        let column1 = [];
        let childrenMain = [];
        let children = [];

        column.push({
            title: "",
            children: [
                {
                    title: "Keywords",
                    dataIndex: "keyword",
                    key: "keyword",
                }
            ]
        })

        tableColumn1.map((item1) => {
            let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

            if (item1.title == "") {
                item1.children.map((item2) => {
                    columnSelected.map((item) => {
                        if (item.value == 1 && item2.dataIndex == "searchVolume") {
                            column[0].children.push(item2)
                        }
                        else if (item.value == 2 && item2.dataIndex == "category") {
                            column[0].children.push(item2)
                        }
                        else if (item.value == 3 && item2.dataIndex == "types") {
                            column[0].children.push(item2)
                        }
                        else if (item.value == 4 && item2.dataIndex == "location") {
                            column[0].children.push(item2)
                        }
                    });
                });
            }
            else {
                if (item1.title == mainDomainNameNew) {
                    columnSelected.map((item) => {
                        let domainURL = item1.children[1].dataIndex.split('_');

                        if (domainURL[0] != "URL" && item.value == 5) {
                            childrenMain.push({
                                title: "Rank",
                                dataIndex: item1.children[0].dataIndex,
                                key: item1.children[0].dataIndex,
                            });
                        }

                        if (domainURL[1] == "URL" && item.value == 6) {
                            childrenMain.push({
                                title: "URL",
                                dataIndex: item1.children[1].dataIndex,
                                key: item1.children[1].dataIndex,
                            });
                        }
                    });

                    let object = {
                        title: item1.title,
                        children: childrenMain
                    }
                    column.push(object);
                }
            }
        });

        let selectCompetitors;
        tableColumn1.map((item1) => {
            children = [];

            if (item1.title != "") {
                if (selected.length > 0) {
                    selected.map((item) => {
                        selectCompetitors = urlDomainName(item.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

                        columnSelected.map((item2) => {
                            if (item1.title == selectCompetitors) {
                                let domainURL = item1.children[1].dataIndex.split('_');

                                if (domainURL[0] != "URL" && item2.value == 5) {
                                    children.push({
                                        title: "Rank",
                                        dataIndex: item1.children[0].dataIndex,
                                        key: item1.children[0].dataIndex,
                                    });
                                }

                                if (domainURL[1] == "URL" && item2.value == 7) {
                                    children.push({
                                        title: "Competitors URL",
                                        dataIndex: item1.children[1].dataIndex,
                                        key: item1.children[1].dataIndex,
                                    });
                                }
                            }
                        });

                        if (item1.title == selectCompetitors) {
                            let object = {
                                title: item1.title,
                                children: children
                            }
                            column1.push(object);
                        }
                    });
                }
            }
        });

        children = column.concat(column1);
        setTableColumn(children.length > 0 ? children : column)
    }

    function getLatestRankingData() {
        setStartDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
        );
        setEndDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
        );
        setCompareStartDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
        );
        setCompareEndDate(
            mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
        );
        setRankingType(props?.rankingType)
        setDeviceType(props?.deviceType)
        setViewId(props?.selectedCampaigView)
        setCampaignId(props?.selectedCampaign)
        setMainDomainName(props?.domainName);

        if (props?.selectedCampaign != '' && props?.selectedCampaigView != '' && props?.domainName != "") {
            setAddCompetitorsSelectDropdown(props?.selectedCampaign);
            setLatestRankingTableColumn();
        }
    }

    function setLatestRankingTableColumn() {
        const columns = [
            {
                title: "",
                children: [
                    {
                        title: "Keyword",
                        dataIndex: "keyword",
                        key: "keyword",
                    },
                    {
                        title: "Search Volume",
                        dataIndex: "searchVolume",
                        key: "searchVolume",
                    },
                    {
                        title: "Category",
                        dataIndex: "category",
                        key: "category",
                        className: "category-cols",
                        width: "10%",
                    },
                    {
                        title: "Type",
                        dataIndex: "type",
                        key: "type",
                        className: "type-cols",
                        width: "10%",
                    },
                    {
                        title: "Country",
                        dataIndex: "location",
                        key: "location",
                    },
                ],
            },
            {
                title: "Domain",
                children: [
                    {
                        title: "Rank",
                    },
                    {
                        title: "URL",
                    },
                    {
                        title: "Competitors URL",
                    },
                ],
            },
        ];
        setTableColumn(columns)
        setTableColumn1(columns)
        let rowData = {
            RankingCampaignConfigurationId: props?.selectedCampaign,
            RankingCampaignViewConfigurationId: props?.selectedCampaigView,
            projectId: localStorage.getItem("projectGId"),
            device: props?.deviceType,
            type: props?.rankingType,
            CurrentPage: pageNumber,
            TablePageSize: tablePageSize,
            DateRange: {
                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
            },
        }
        getTableData(rowData);
    }

    function getTableData(rowData) {
        APIManager.postAPI(
            getLatestRankings,
            rowData,
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                setTableData([]);
                setColumnSelected([]);
                let list = [];
                let totalCount;

                response?.data?.table.map((item) => {
                    setTotalCount(item.totalCount)
                    totalCount = item.totalCount;
                });

                setTableParams({
                    pagination: {
                        current: rowData.CurrentPage,
                        pageSize: rowData.TablePageSize,
                        position: ["bottomRight"],
                        showSizeChanger: true,
                        total: totalCount,
                        showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${totalCount} keywords`
                    },
                });

                if (response?.data?.table1.length > 0) {
                    let keys = [];
                    let columnsMain = [];
                    let columns = [];
                    let colChildArray = [];
                    let childrenArray = [];
                    let competitorsList = [];
                    let selectedColumnList = [];
                    let totalColumnKey = 0;
                    let totalColumnValue = 0;

                    keys = Object.keys(response?.data?.table1[0]);
                    keys.map((key, index) => {
                        let colName;

                        if (
                            key != "keyword" &&
                            key != "trackDate" &&
                            key != "country" &&
                            key != "category" &&
                            key != "types" &&
                            key != "searchVolume" &&
                            key != "location"
                        ) {
                            let domainName;
                            let mainDomainNameNew;
                            let domainURL;

                            mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                            domainName = urlDomainName(key); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                            domainURL = key.split('_');

                            if (domainName == mainDomainNameNew) {
                                if (totalColumnKey == 0 && domainURL[1] == undefined) {
                                    childrenArray.push({
                                        title: "Rank",
                                        dataIndex: key,
                                        key: key,
                                        width: "5% !important"
                                    });

                                    totalColumnKey++;
                                } else if (totalColumnKey == 1 && domainURL[1] == "URL") {
                                    childrenArray.push({
                                        title: "URL",
                                        dataIndex: key,
                                        key: key,
                                    });

                                    totalColumnKey++;
                                }
                            }
                            else {
                                if (totalColumnKey == 0 && domainURL[1] == undefined) {
                                    childrenArray.push({
                                        title: "Rank",
                                        dataIndex: key,
                                        key: key,
                                        width: "5%  !important"
                                    });

                                    totalColumnKey++;
                                } else if (totalColumnKey == 1 && domainURL[1] == "URL") {
                                    childrenArray.push({
                                        title: "Competitors URL",
                                        dataIndex: key,
                                        key: key,
                                    });

                                    totalColumnKey++;
                                }
                            }

                            if (totalColumnKey == 2) {
                                let col = {
                                    title: domainName,
                                    children: childrenArray,
                                };

                                if (domainName == mainDomainNameNew) {
                                    columnsMain.push(col);
                                }

                                if (domainName != mainDomainNameNew) {
                                    competitorsList.push({
                                        label: domainName,
                                        value: domainURL[0],
                                    });
                                }

                                columns.push(col);
                                totalColumnKey = 0;
                                childrenArray = [];
                            }
                        } else {
                            colName = key;
                            if (key != "trackDate") {
                                colChildArray.push({
                                    title: colName == "types" ? "Type" : colName == "location" ? "Country" : colName,
                                    dataIndex: colName,
                                    width: colName == "category" || colName == "types" ? "10%" : "",
                                    className: colName == "category" ? "category-cols" : colName == "types" ? "type-cols" : "",
                                    key: colName,
                                });

                                if (key != "keyword") {
                                    let selectedColumnLabel;
                                    let selectedColumnValue;
                                    if (key == "searchVolume") {
                                        selectedColumnLabel = "Search Volume";
                                        selectedColumnValue = 1;
                                    } else if (key == "category") {
                                        selectedColumnLabel = "Category";
                                        selectedColumnValue = 2;
                                    } else if (key == "types") {
                                        selectedColumnLabel = "Type";
                                        selectedColumnValue = 3;
                                    } else if (key == "location") {
                                        selectedColumnLabel = "Country";
                                        selectedColumnValue = 4;
                                    }

                                    selectedColumnList.push({
                                        label: selectedColumnLabel,
                                        value: selectedColumnValue,
                                    });
                                }
                            }
                        }
                    });

                    let col = {
                        title: "",
                        children: colChildArray,
                    };
                    columns.push(col);
                    columnsMain.push(col);

                    columns.sort((a, b) =>
                        a.title > b.title ? 1 : -1
                    );

                    columnsMain.sort((a, b) =>
                        a.title > b.title ? 1 : -1
                    );

                    selectedColumnList.push({
                        label: "Rank",
                        value: 5,
                    });

                    selectedColumnList.push({
                        label: "My URL",
                        value: 6,
                    });

                    selectedColumnList.push({
                        label: "Competitors URL",
                        value: 7,
                    });
                    setTableColumn(columnsMain);
                    setTableColumn1(columns);
                    setColumnSelected(selectedColumnList);

                    response.data.table1.map((item, index1) => {
                        let obj = {};
                        totalColumnValue = 0;

                        keys.map((key, index) => {
                            obj["key"] = index1;
                            if (
                                key != "keyword" &&
                                key != "trackDate" &&
                                key != "country" &&
                                key != "category" &&
                                key != "types" &&
                                key != "searchVolume" &&
                                key != "location"
                            ) {
                                let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                                let domainName = urlDomainName(key); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                                let domainURL = key.split('_');

                                if (domainName == mainDomainNameNew) {
                                    if (totalColumnValue == 0 && domainURL[1] == undefined) {
                                        obj[key] = item[key];
                                        totalColumnValue++;
                                    } else if (totalColumnValue == 1 && domainURL[1] == "URL") {
                                        obj[key] = item[key];
                                        totalColumnValue++;
                                    }
                                } else {
                                    if (totalColumnValue == 0 && domainURL[1] == undefined) {
                                        obj[key] = item[key];
                                        totalColumnValue++;
                                    } else if (totalColumnValue == 1 && domainURL[1] == "URL") {
                                        obj[key] = item[key];
                                        totalColumnValue++;
                                    }
                                }

                                if (totalColumnValue == 2) {
                                    totalColumnValue = 0;
                                }
                            } else {
                                if (key != "trackDate") {
                                    if (key == "types") {
                                        obj[key] = item[key] == 2 ? "All" : item[key] == 1 ? "Non Brand" : "Brand";
                                    } else {
                                        obj[key] = item[key];
                                    }
                                }
                            }
                        });

                        list.push(obj);
                    });
                }

                setTableData(list);

                if (competitorsSelected.length > 0) {
                    handleCompetitorsChange(competitorsSelected);
                }
            }
        });
    }

    function getTableDataExport(rowData, competitorsSelected) {
        let rowData1 = {
            RankingCampaignConfigurationId: props?.selectedCampaign,
            RankingCampaignViewConfigurationId: props?.selectedCampaigView,
            projectId: localStorage.getItem("projectGId"),
            device: props?.deviceType,
            type: props?.rankingType,
            DateRange: {
                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
            },
        }
        APIManager.postAPI(
            getLatestRankingExport,
            rowData1,
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                let exportList = [];

                if (response.data?.length > 0) {
                    let keys = [];
                    let totalColumnValue = 0;

                    keys = Object.keys(response.data[0]);
                    response.data.map((item, index) => {
                        let exportObj = {};
                        let selectCompetitors;
                        let rankValue;
                        let urlValue;
                        totalColumnValue = 0;

                        keys.map((key, index) => {
                            if (
                                key != "keyword" &&
                                key != "trackDate" &&
                                key != "country" &&
                                key != "category" &&
                                key != "types" &&
                                key != "searchVolume" &&
                                key != "location"
                            ) {
                                let mainDomainNameNew = urlDomainName(props?.domainName); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                                let domainName = urlDomainName(key); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());
                                let domainURL = key.split('_');

                                if (domainName == mainDomainNameNew) {
                                    if (totalColumnValue == 0 && domainURL[1] == undefined) {
                                        rankValue = item[key];
                                        totalColumnValue++;
                                    } else if (totalColumnValue == 1 && domainURL[1] == "URL") {
                                        urlValue = item[key];
                                        totalColumnValue++;
                                    }
                                } else {
                                    if (competitorsList.length > 0) {
                                        competitorsList.map((item1, index1) => {
                                            selectCompetitors = urlDomainName(item1.value); //.replace(/.+\/\/|www.|\..+/g, '').replace(/(^\w|\s\w)/g, m => m.toUpperCase());

                                            if (selectCompetitors == domainName && totalColumnValue == 0 && domainURL[1] == undefined) {
                                                rankValue = item[key];
                                                totalColumnValue++;
                                            } else if (selectCompetitors == domainName && totalColumnValue == 1 && domainURL[1] == "URL") {
                                                urlValue = item[key];
                                                totalColumnValue++;
                                            }
                                        });
                                    }
                                }

                                if (totalColumnValue == 2) {
                                    exportObj[domainName] = {
                                        "Rank": rankValue,
                                        "URL": urlValue,
                                    };
                                    totalColumnValue = 0;
                                }
                            } else {
                                if (key != "trackDate") {
                                    if (key == "types") {
                                        exportObj[key] = item[key] == 2 ? "All" : item[key] == 1 ? "Non Brand" : "Brand";
                                    } else {
                                        exportObj[key] = item[key];
                                    }
                                }
                            }
                        });

                        exportList.push(exportObj);
                    });
                }
                setExportList(exportList);
                exportToEXCEL(
                    exportList,
                    "Latest Rankigs"
                );
            }
        });
    }

    function handlePagination(pagination) {
        let rowData = {
            RankingCampaignConfigurationId: props?.selectedCampaign,
            RankingCampaignViewConfigurationId: props?.selectedCampaigView,
            projectId: localStorage.getItem("projectGId"),
            device: props?.deviceType,
            type: props?.rankingType,
            CurrentPage: pagination.current,
            TablePageSize: pagination.pageSize,
            DateRange: {
                FROM: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
                TO: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
            },
        }
        getTableData(rowData);
    }

    return (
        <>
            <div>
                <div className="overall-rank">
                    <div className="overall-title">
                        <div className="d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-between align-items-center">
                                <h4 className="me-3 ranking-name">Rankings for <span>{DDMMyyyyFormateDateSet(startDate)}</span></h4>

                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                                <svg className="export-download me-3 ms-2" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 12.5V15.8333C17.5 16.2754 17.3244 16.6993 17.0118 17.0118C16.6993 17.3244 16.2754 17.5 15.8333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V12.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M5.83337 8.3335L10 12.5002L14.1667 8.3335" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M10 12.5V2.5" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <button
                                    className="rank-export"
                                    onClick={() => {
                                        getTableDataExport();
                                    }}
                                >
                                    Export
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex align-items-center column-competitor mt-3">
                    <div className="d-flex align-items-center latest-ranking">
                        <label className="me-2 my-2">Columns : </label>
                        <div className="latest-column-select">
                            <ReactMultiSelectCheckboxes className="d-flex align-items-center"
                                options={columnList}
                                onChange={(selected) => {
                                    setColumnSelected(selected);
                                    handleColumnChange(selected);
                                }}
                                value={columnSelected}
                                hideSearch />
                        </div>
                    </div>

                    <div className="d-flex align-items-center latest-ranking ms-3">
                        <label className="me-2 my-2" >Competitors : </label>
                        <div className="latest-column-select">
                            <ReactMultiSelectCheckboxes className="d-flex align-items-center"
                                options={competitorsList}
                                onChange={(selected) => {
                                    setCompetitorsSelected(selected);
                                    handleCompetitorsChange(selected);

                                    let rowData1 = {
                                        RankingCampaignConfigurationId: props?.selectedCampaign,
                                        RankingCampaignViewConfigurationId: props?.selectedCampaigView,
                                        projectId: localStorage.getItem("projectGId"),
                                        device: props?.deviceType,
                                        type: props?.rankingType,
                                    }
                                }}
                                value={competitorsSelected}
                                hideSearch />
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-3 px-3">
                    <Table className=" latest-table  table-bordered border-0"
                        columns={tableColumn}
                        dataSource={tableData}
                        pagination={tableParams.pagination}
                        onChange={handlePagination}
                    ></Table>
                </div>
            </div>
        </>
    );
}

export default LatestRanking;
