import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import APIManager from "../../services/APIManager";
import {
  getChartData,
  getGSCDataCompareDashboard,
} from "../../utility/Constant";
import {
  avgOfArrayItems,
  fixTwoPlaceDecimal,
  formateDateIntoDatemonth,
  mmDDyyyyFormateDateSet,
  returnMagnitude,
  sumOfArrayItems,
  toMonthName,
  compareValues,
  formatRankDate,
} from "../../utility/Globals";

function Gacchart(props) {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [compareendDate, setcompareendDate] = useState("");

  useEffect(() => {

    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    setcompareendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    );

    let getdata = {
      projectId: localStorage.getItem("projectGId"),
      GSCDateRange: {
        from: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.startDate
        ),
        to: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.endDate
        ),
      },
      GSCCompareToRange: {
        from: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.startDate
        ),
        to: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.endDate
        ),
      },
      currentPage: 1,
      pageSize: 30,
      Dimension: 2,
      View: 0,
      Metrics: selectgsc,
      DeviceType: selectgscview,
    };
    gscGetChartDataCompare(getdata);

    let rowData2 = {
      startdate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection2?.startDate
      ),
      enddate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection2?.endDate
      ),
      projectId: localStorage.getItem("projectGId"),
      Dimension: 0,
      SearchType: "Web",
      View: 0,
    };

    let rowdat1 = {
      startdate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection1?.startDate
      ),
      enddate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection1?.endDate
      ),
      projectId: localStorage.getItem("projectGId"),
      Dimension: [
        "Query",
        "Page",
        "Country",
        "Device",
        "Search Appearence",
        "Date",
      ],
      SearchType: "Web",
      View: 0,
    };
    gscGetDataGetForRange2(rowdat1, rowData2);
  }, [props?.dataParentToChild]);

  useEffect(() => {
  }, []);
  const [selectgsc, setselectgsc] = useState("Impressions");
  const [selectgscview, setselectgscview] = useState("2");
  const [isgaChart, setIsgaChart] = useState(0);
  const [ChartView, setChartView] = useState(0);
  const [mainChartArr, setMainChartArr] = useState([]);
  const [listArrDateIds, setListArrDateIds] = useState([]);
  const [performanceMetricsCardList1, setCardListData] = useState([]);
  const [series, setSeries] = useState([
    {
      name: "Total Impressions",
      data: [],
      color: "#008FFB",
    },
  ]);
  const [isDateRage, setIsDateRage] = useState(false);

  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
    },
    yaxis: {
      logBase: 0.5,
      tickAmount: 5,
      min: -1,
      max: 1.5,
    },
  });
  function isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  useEffect(() => {
    setIsgaChart(props?.IsSeoChart);
  }, [props?.IsSeoChart]);

  function handleClickgaChart(type) {
    if (isgaChart != type) {
      setIsgaChart(type);
    }
  }

  function gscGetChartDataCompare(rowData) {
    let viewOfChart1 = rowData.View;
    let maxImp;
    let maxClk;
    let maxPos;
    let maxCtr;
    let maxImp1;
    let maxClk1;
    let maxPos1;
    let maxCtr1;
    let list = [];
    let list1 = [];
    let list2 = [];
    let list3 = [];

    APIManager.postAPI(getGSCDataCompareDashboard, rowData, null, true).then(
      (response) => {

        if (response.status == 200 && response) {
          let listArrDatas = [];
          let listArrIds = [];
          response.data.tableContent.map((item, idx) => {
            let keys = Object.keys(item);
            let values = Object.values(item);
            if (idx == 0) {
              keys.map((i, index) => {
                listArrIds.push({
                  keys: i,
                  values: [],
                });
              });
            }

            setListArrDateIds(listArrIds);

            let listArrValues = [];
            values.map((i, index) => {
              if (i == null) {
                listArrValues.push(0);
              } else {
                listArrValues.push(i);
              }
            });

            listArrDatas.push(listArrValues);
          });

          var a = listArrDatas;
          let mainArr = [];
          if (viewOfChart1 != 0) {
            for (var i = 0; i < a.length; i++) {
              var b = a[i];
              let subArr = [];
              for (var j = 0; j < b.length - 1; j++) {
                subArr.push(b[j]);
              }
              mainArr.push(subArr);
            }
          } else {
            for (var i = 0; i < a.length; i++) {
              var b = a[i];
              let subArr = [];
              for (var j = 1; j < b.length; j++) {
                subArr.push(b[j]);
              }
              mainArr.push(subArr);
            }
          }

          let cols = [];

          listArrIds.map((i) => {
            if (i.keys != "fields") {
              if (viewOfChart1 == 0) {
                cols.push(formateDateIntoDatemonth(i.keys));
              } else {
                cols.push(i.keys);
              }
            }
          });
          let titleText = "";
          if (viewOfChart1 == 0) {
            titleText = "Days";
          } else if (viewOfChart1 == 1) {
            titleText = "Weeks";
          } else if (viewOfChart1 == 2) {
            titleText = "Months";
          }
          setMainChartArr(mainArr);
          let tcArr = [];
          let tcArr1 = [];
          let tcArr2 = [];
          let tcArr3 = [];
          listArrDatas.map((itemListData) => {
            let itemList = itemListData;
            let key;
            let arr = [];
            for (let i = 0; i < itemList.length; i++) {
              let oneItem = itemList[i];
              if (isNumber(oneItem)) {
                arr.push(oneItem);
              } else {
                key = oneItem;
              }
            }
            if (key == "Clicks") {
              tcArr = arr;
            } else if (key == "Ctr") {
              tcArr3 = arr;
            } else if (key == "Impressions") {
              tcArr1 = arr;
            } else if (key == "Position") {
              tcArr2 = arr;
            }
          });

          let tcArr21 = [];
          tcArr2.map((item) => {
            tcArr21.push(item.toFixed(2));
          });
          let tcArr31 = [];
          tcArr3.map((item) => {
            tcArr31.push((item * 100).toFixed(2));
          });

          maxClk = Math.max(...tcArr) * 0.3 + Math.max(...tcArr); //click
          maxImp = Math.max(...tcArr1) * 0.3 + Math.max(...tcArr1); //imp
          maxCtr = Math.max(...tcArr31) * 0.3 + Math.max(...tcArr31); //ctr
          maxPos = Math.max(...tcArr21) * 0.3 + Math.max(...tcArr21); //pos

          list1.push({
            name: "Total Clicks",
            data: tcArr,
            // color: "#3366cc",
          });
          list.push({
            name: "Total Impressions",
            data: tcArr1,
            //color: "#008FFB",
          });
          list2.push({
            name: "Position",
            data: tcArr21,
            // color: "#C0504E",
          });
          list3.push({
            name: "Average CTR",
            data: tcArr31,
            // color: "#77A033",
          });
          let listArrDatas1 = [];
          let listArrIds1 = [];
          response.data.compareTableContent.map((item, idx) => {
            let keys1 = Object.keys(item);
            let values1 = Object.values(item);
            if (idx == 0) {
              keys1.map((i, index) => {
                listArrIds1.push({
                  keys: i,
                  values: [],
                });
              });
            }

            setListArrDateIds(listArrIds1);

            let listArrValues1 = [];
            values1.map((i, index) => {
              if (i == null) {
                listArrValues1.push(0);
              } else {
                listArrValues1.push(i);
              }
            });
            listArrDatas1.push(listArrValues1);
          });
          var a1 = listArrDatas1;
          let mainArr1 = [];
          if (viewOfChart1 != 0) {
            for (var i = 0; i < a1.length; i++) {
              var b1 = a1[i];
              let subArr1 = [];
              for (var j = 0; j < b1.length - 1; j++) {
                subArr1.push(b1[j]);
              }
              mainArr1.push(subArr1);
            }
          } else {
            for (var i = 0; i < a1.length; i++) {
              var b1 = a1[i];
              let subArr1 = [];
              for (var j = 1; j < b1.length; j++) {
                subArr1.push(b1[j]);
              }
              mainArr1.push(subArr1);
            }
          }
          let cols1 = [];
          listArrIds1.map((i, index) => {
            if (i.keys != "fields") {
              if (viewOfChart1 == 0) {
                cols1.push(formateDateIntoDatemonth(i.keys));
              } else {
                cols1.push(i.keys);
              }
            }
          });
          let _tcArr = [];
          let _tcArr1 = [];
          let _tcArr2 = [];
          let _tcArr3 = [];

          listArrDatas1.map((itemListData) => {
            let itemList = itemListData;
            let key;
            let arr = [];
            for (let i = 0; i < itemList.length; i++) {
              let oneItem = itemList[i];
              if (isNumber(oneItem)) {
                arr.push(oneItem);
              } else {
                key = oneItem;
              }
            }
            if (key == "Clicks") {
              _tcArr = arr;
            } else if (key == "Ctr") {
              _tcArr3 = arr;
            } else if (key == "Impressions") {
              _tcArr1 = arr;
            } else if (key == "Position") {
              _tcArr2 = arr;
            }
          });

          let _tcArr21 = [];
          _tcArr2.map((item) => {
            _tcArr21.push(item.toFixed(2));
          });
          let _tcArr31 = [];
          _tcArr3.map((item) => {
            _tcArr31.push((item * 100).toFixed(2));
          });

          maxClk1 = Math.max(..._tcArr) * 0.3 + Math.max(..._tcArr); //click
          maxImp1 = Math.max(..._tcArr1) * 0.3 + Math.max(..._tcArr1); //imp
          maxCtr1 = Math.max(..._tcArr31) * 0.3 + Math.max(..._tcArr31); //ctr
          maxPos1 = Math.max(..._tcArr21) * 0.3 + Math.max(..._tcArr21); //pos

          list1.push({
            name: "Total Clicks",
            data: _tcArr,
            // color: "#3366cc",
          });
          list.push({
            name: "Total Impressions",
            data: _tcArr1,
            // color: "#008FFB",
          });

          list2.push({
            name: "Position",
            data: _tcArr21,
            // color: "#C0504E",
          });
          list3.push({
            name: "Average CTR",
            data: _tcArr31,
            // color: "#77A033",
          });

          let categories = [];    
          if (rowData.Metrics == "Impressions") {
            if (list[0].data.length > list[1].data.length) {
              for (i = 1; i <= list[0].data.length; i++) {
                categories.push(i);
              }
            } else {
              for (i = 1; i <= list[1].data.length; i++) {
                categories.push(i);
              }
            }
            setObject({
              chart: {
                toolbar: {
                  show: false,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    customIcons: [],
                  },
                },
              },
              xaxis: {
                tickPlacement: "on",
                categories: categories,
                title: {
                  text: titleText,
                  style: {
                    color: "#000",
                  },
                },
                labels: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
              yaxis: {
                min: 0,
                max: maxImp > maxImp1 ? maxImp : maxImp1,
                labels: {
                  formatter: function (val) {
                    return returnMagnitude(val);
                  },
                },
              },
              tooltip: {
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  let date = "-";
                  const hoverXaxis =
                    w.globals.seriesX[seriesIndex][dataPointIndex];
                  const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                    return seriesX.findIndex((xData) => xData === hoverXaxis);
                  });

                  let hoverList = "";
                  hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                    if (seriesEachIndex == 0) {
                      if (viewOfChart1 == 0) {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    } else {
                      if (viewOfChart1 == 0) {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    }
                  });
                  const formatHoverX = hoverXaxis;

                  return `<div class="card">
                    <!--<div class="card-header p-10">${formatHoverX}</div>-->
                    <div class="card-body p-10">
                      ${hoverList}
                    </div>
                  </div>`;
                },
              },
              markers: {
                size: 5,
              },
              legend: { show: false },
            });
          } else if (rowData.Metrics == "Clicks") {
            if (list1[0].data.length > list1[1].data.length) {
              for (i = 1; i <= list1[0].data.length; i++) {
                categories.push(i);
              }
            } else {
              for (i = 1; i <= list1[1].data.length; i++) {
                categories.push(i);
              }
            }
            setObject({
              chart: {
                toolbar: {
                  show: false,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    customIcons: [],
                  },
                },
              },
              xaxis: {
                tickPlacement: "on",
                // categories: cols,
                categories: categories,
                title: {
                  text: titleText,
                  style: {
                    color: "#000",
                  },
                },
                labels: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
              yaxis: {
                min: 0,
                max: maxClk > maxClk1 ? maxClk : maxClk1,
                labels: {
                  formatter: function (val) {
                    return returnMagnitude(val);
                  },
                },
              },
              tooltip: {
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  let date = "-";
                  const hoverXaxis =
                    w.globals.seriesX[seriesIndex][dataPointIndex];
                  const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                    return seriesX.findIndex((xData) => xData === hoverXaxis);
                  });

                  let hoverList = "";
                  hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                    if (seriesEachIndex == 0) {
                      if (viewOfChart1 == 0) {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    } else {
                      if (viewOfChart1 == 0) {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    }
                  });
                  const formatHoverX = hoverXaxis;

                  return `<div class="card">
                    <!--<div class="card-header p-10">${formatHoverX}</div>-->
                    <div class="card-body p-10">
                      ${hoverList}
                    </div>
                  </div>`;
                },
              },
              markers: {
                size: 5,
              },
              legend: { show: false },
            });
          } else if (rowData.Metrics == "Position") {
            if (list2[0].data.length > list2[1].data.length) {
              for (i = 1; i <= list2[0].data.length; i++) {
                categories.push(i);
              }
            } else {
              for (i = 1; i <= list2[1].data.length; i++) {
                categories.push(i);
              }
            }
            setObject({
              chart: {
                toolbar: {
                  show: false,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    customIcons: [],
                  },
                },
              },
              xaxis: {
                tickPlacement: "on",
                // categories: cols,
                categories: categories,
                title: {
                  text: titleText,
                  style: {
                    color: "#000",
                  },
                },
                labels: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
              yaxis: {
                min: 0,
                max: maxPos > maxPos1 ? maxPos : maxPos1,
              },
              tooltip: {
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  let date = "-";
                  const hoverXaxis =
                    w.globals.seriesX[seriesIndex][dataPointIndex];
                  const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                    return seriesX.findIndex((xData) => xData === hoverXaxis);
                  });

                  let hoverList = "";
                  hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                    if (seriesEachIndex == 0) {
                      if (viewOfChart1 == 0) {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    } else {
                      if (viewOfChart1 == 0) {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    }
                  });
                  const formatHoverX = hoverXaxis;

                  return `<div class="card">
                    <!--<div class="card-header p-10">${formatHoverX}</div>-->
                    <div class="card-body p-10">
                      ${hoverList}
                    </div>
                  </div>`;
                },
              },
              markers: {
                size: 5,
              },
            });
          } else if (rowData.Metrics == "ctr") {
            if (list3[0].data.length > list3[1].data.length) {
              for (i = 1; i <= list3[0].data.length; i++) {
                categories.push(i);
              }
            } else {
              for (i = 1; i <= list3[1].data.length; i++) {
                categories.push(i);
              }
            }
            setObject({
              chart: {
                toolbar: {
                  show: false,
                  offsetX: 0,
                  offsetY: 0,
                  tools: {
                    download: true,
                    zoom: false,
                    zoomin: true,
                    zoomout: true,
                    customIcons: [],
                  },
                },
              },
              xaxis: {
                tickPlacement: "on",
                categories: categories,
                title: {
                  text: titleText,
                  style: {
                    color: "#000",
                  },
                },
                labels: {
                  formatter: function (val) {
                    return val;
                  },
                },
              },
              yaxis: {
                min: 0,
                max: maxCtr > maxCtr1 ? maxCtr : maxCtr1,
              },
              tooltip: {
                custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                  let date = "-";
                  const hoverXaxis =
                    w.globals.seriesX[seriesIndex][dataPointIndex];
                  const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                    return seriesX.findIndex((xData) => xData === hoverXaxis);
                  });

                  let hoverList = "";
                  hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                    if (seriesEachIndex == 0) {
                      if (viewOfChart1 == 0) {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date} <br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    } else {
                      if (viewOfChart1 == 0) {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = item;
                          }
                        });
                      } else {
                        cols1.map((item, index) => {
                          if (dataPointIndex == index) {
                            date = toMonthName(item, titleText);
                          }
                        });
                      }

                      if (
                        hoverIndex >= 0 &&
                        series[seriesEachIndex][hoverIndex] != undefined
                      ) {
                        hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                      }
                    }
                  });
                  const formatHoverX = hoverXaxis;

                  return `<div class="card">
                    <!--<div class="card-header p-10">${formatHoverX}</div>-->
                    <div class="card-body p-10">
                      ${hoverList}
                    </div>
                  </div>`;
                },
              },
              markers: {
                size: 5,
              },
            });
          }
          if (rowData.Metrics == "Impressions") {
            setSeries(list);
          } else if (rowData.Metrics == "Clicks") {
            setSeries(list1);
          } else if (rowData.Metrics == "Position") {
            setSeries(list2);
          } else if (rowData.Metrics == "ctr") {
            setSeries(list3);
          }
        } else {
          // setgscPageTable([]);
        }
      }
    );
  }
  function gscGetDataGetForRange2(rowData1, rowData2) {
    let viewOfChart = rowData1.View;

    var myCurrentDate = new Date().toISOString().slice(0, 10);
    var myPastDate = new Date(myCurrentDate);
    myPastDate.setDate(myPastDate.getDate() - 7);

    let viewOfChart1 = rowData1.View;
    let clickAvgVar;
    let impressionAvgVar;
    let ctrAvgVar;
    let positionvgVar;
    let maxImp;
    let maxClk;
    let maxPos;
    let maxCtr;

    APIManager.postAPI(getChartData, rowData1, null, true).then((response) => {
      if (response.status == 200 && response) {
        let listArrDatas = [];
        let listArrIds = [];
        response.data.map((item, idx) => {
          let keys = Object.keys(item);
          let values = Object.values(item);
          if (idx == 0) {
            keys.map((i, index) => {
              listArrIds.push({
                keys: i,
                values: [],
              });
            });
          }

          setListArrDateIds(listArrIds);
          setChartView(rowData1.View);

          let listArrValues = [];
          values.map((i, index) => {
            if (i == null) {
              listArrValues.push(0);
            } else {
              listArrValues.push(i);
            }
          });

          listArrDatas.push(listArrValues);
        });

        var a = listArrDatas;
        let mainArr = [];
        if (viewOfChart1 != 0) {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 0; j < b.length - 1; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        } else {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 1; j < b.length; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        }

        let cols = [];

        listArrIds.map((i, index) => {
          if (i.keys != "fields") {
            if (viewOfChart1 == 0) {
              cols.push(formateDateIntoDatemonth(i.keys));
            } else {
              cols.push(i.keys);
            }
          }
        });
        let titleText = "";
        if (viewOfChart1 == 0) {
          titleText = "Days";
        } else if (viewOfChart1 == 1) {
          titleText = "Weeks";
        } else if (viewOfChart1 == 2) {
          titleText = "Months";
        }
        setMainChartArr(mainArr);
        let tcArr = [];
        let tcArr1 = [];
        let tcArr2 = [];
        let tcArr3 = [];
        listArrDatas.map((itemListData) => {
          let itemList = itemListData;
          let key;
          let arr = [];
          for (let i = 0; i < itemList.length; i++) {
            let oneItem = itemList[i];
            if (isNumber(oneItem)) {
              arr.push(oneItem);
            } else {
              key = oneItem;
            }
          }
          if (key == "Clicks") {
            tcArr = arr;
          } else if (key == "Ctr") {
            tcArr3 = arr;
          } else if (key == "Impressions") {
            tcArr1 = arr;
          } else if (key == "Position") {
            tcArr2 = arr;
          }
        });

        let tcArr21 = [];
        tcArr2.map((item) => {
          tcArr21.push(item.toFixed(2));
        });
        let tcArr31 = [];
        tcArr3.map((item) => {
          tcArr31.push((item * 100).toFixed(2));
        });

        maxClk = Math.max(...tcArr) * 0.3 + Math.max(...tcArr); //click
        maxImp = Math.max(...tcArr1) * 0.3 + Math.max(...tcArr1); //imp
        maxCtr = Math.max(...tcArr31) * 0.3 + Math.max(...tcArr31); //ctr
        maxPos = Math.max(...tcArr21) * 0.3 + Math.max(...tcArr21); //pos

        clickAvgVar = sumOfArrayItems(tcArr);
        impressionAvgVar = sumOfArrayItems(tcArr1);
        ctrAvgVar = avgOfArrayItems(tcArr3);
        positionvgVar = avgOfArrayItems(tcArr2);

        gscGetDataGetForRange22(
          rowData2,
          clickAvgVar,
          impressionAvgVar,
          ctrAvgVar,
          positionvgVar,
          viewOfChart
        );
      }
    });
  }

  function gscGetDataGetForRange22(
    rowData,
    clickAvgVar,
    impressionAvgVar,
    ctrAvgVar,
    positionvgVar,
    viewOfChart
  ) {
    APIManager.postAPI(getChartData, rowData, null, true).then((response) => {
      if (response.status == 200 && response) {
        let listArrDatas = [];
        let listArrIds = [];
        response.data.map((item, idx) => {
          let keys = Object.keys(item);
          let values = Object.values(item);
          if (idx == 0) {
            keys.map((i, index) => {
              listArrIds.push({
                keys: i,
                values: [],
              });
            });
          }

          let listArrValues = [];
          values.map((i, index) => {
            if (i == null) {
              listArrValues.push(0);
            } else {
              listArrValues.push(i);
            }
          });

          listArrDatas.push(listArrValues);
        });

        var a = listArrDatas;
        let mainArr = [];
        if (viewOfChart != 0) {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 0; j < b.length - 1; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        } else {
          for (var i = 0; i < a.length; i++) {
            var b = a[i];
            let subArr = [];
            for (var j = 1; j < b.length; j++) {
              subArr.push(b[j]);
            }
            mainArr.push(subArr);
          }
        }
        let cols = [];

        listArrIds.map((i, index) => {
          if (i.keys != "fields") {
            if (viewOfChart == 0) {
              cols.push(formateDateIntoDatemonth(i.keys));
            } else {
              cols.push(i.keys);
            }
          }
        });

        setMainChartArr(mainArr);

        let tcArr = [];
        let tcArr1 = [];
        let tcArr2 = [];
        let tcArr3 = [];
        listArrDatas.map((itemListData) => {
          let itemList = itemListData;
          let key;
          let arr = [];
          for (let i = 0; i < itemList.length; i++) {
            let oneItem = itemList[i];
            if (isNumber(oneItem)) {
              arr.push(oneItem);
            } else {
              key = oneItem;
            }
          }
          if (key == "Clicks") {
            tcArr = arr;
          } else if (key == "Ctr") {
            tcArr3 = arr;
          } else if (key == "Impressions") {
            tcArr1 = arr;
          } else if (key == "Position") {
            tcArr2 = arr;
          }
        });

        let clickAvg = clickAvgVar;
        let impressionAvg = impressionAvgVar;
        let ctrAvg = ctrAvgVar;
        let positionvg = positionvgVar;

        let clickCmpAvg = sumOfArrayItems(tcArr);
        let impressionCmpAvg = sumOfArrayItems(tcArr1);
        let ctrCmpAvg = avgOfArrayItems(tcArr3);
        let positionCmpvg = avgOfArrayItems(tcArr2);

        let pclickAvg =
          clickAvg < clickCmpAvg
            ? (100 * (clickCmpAvg - clickAvg)) / clickCmpAvg
            : (100 * (clickAvg - clickCmpAvg)) / clickAvg;

        let pimpressionAvg =
          impressionAvg < impressionCmpAvg
            ? (100 * (impressionCmpAvg - impressionAvg)) / impressionCmpAvg
            : (100 * (impressionAvg - impressionCmpAvg)) / impressionAvg;

        let pctrAvg =
          ctrAvg < ctrCmpAvg
            ? (100 * (ctrCmpAvg - ctrAvg)) / ctrCmpAvg
            : (100 * (ctrAvg - ctrCmpAvg)) / ctrAvg;

        let ppositionvg =
          positionvg < positionCmpvg
            ? (100 * (positionCmpvg - positionvg)) / positionCmpvg
            : (100 * (positionvg - positionCmpvg)) / positionvg;

        let listData = [
          {
            avgData: pimpressionAvg.toFixed(2),
            user: "Impressions",
            data: fixTwoPlaceDecimal(impressionAvg),
            compareData: fixTwoPlaceDecimal(impressionCmpAvg),
            isHighData: compareValues(impressionAvg, impressionCmpAvg),
          },
          {
            avgData: pclickAvg.toFixed(2),
            user: "Clicks",
            data: fixTwoPlaceDecimal(clickAvg),
            compareData: fixTwoPlaceDecimal(clickCmpAvg),
            isHighData: compareValues(clickAvg, clickCmpAvg),
          },
          {
            avgData: pctrAvg.toFixed(2),
            user: "Average CTR",
            data: fixTwoPlaceDecimal(ctrAvg * 100),
            compareData: fixTwoPlaceDecimal(ctrCmpAvg * 100),
            isHighData: compareValues(ctrAvg, ctrCmpAvg),
          },
          {
            avgData: ppositionvg.toFixed(2),
            user: "Average Position",
            data: fixTwoPlaceDecimal(positionvg),
            compareData: fixTwoPlaceDecimal(positionCmpvg),
            isHighData: compareValues(positionvg, positionCmpvg),
          },
        ];

        if (viewOfChart == 0) {
          setCardListData(listData);
        }
      } else {
        // setgscPageTable([]);
      }
    });
  }

  return (
    <>
      <div className="title pb-4">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3">Impressions & Clicks </h4>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="impression-select">
              {isgaChart == 0 ? (
                <select
                  className="form-select"
                  onChange={(i) => {
                    setselectgsc(i.target.value);
                    let getdata = {
                      projectId: localStorage.getItem("projectGId"),
                      GSCDateRange: {
                        from: startDate,
                        to: endDate,
                      },
                      GSCCompareToRange: {
                        from: compareStartDate,
                        to: compareendDate,
                      },
                      currentPage: 1,
                      pageSize: 30,
                      Dimension: 2,
                      View: 0,
                      Metrics: i.target.value,
                      DeviceType: selectgscview,
                    };
                    gscGetChartDataCompare(getdata);
                  }}
                >
                  <option value="Impressions">Impressions</option>
                  <option value="Clicks">Clicks</option>
                  <option value="ctr">Average CTR</option>
                  <option value="Position">Average Position</option>
                </select>
              ) : (
                <></>
              )}
            </div>

            <div>
              <select
                className="form-select mx-2"
                onChange={(i) => {
                  setselectgscview(i.target.value);
                  let getdata = {

                    projectId: localStorage.getItem("projectGId"),
                    GSCDateRange: {
                      from: startDate,
                      to: endDate,
                    },
                    GSCCompareToRange: {
                      from: compareStartDate,
                      to: compareendDate,
                    },
                    currentPage: 1,
                    pageSize: 30,
                    Dimension: 2,
                    View: 0,
                    Metrics: selectgsc,
                    DeviceType: i.target.value,
                  };
                  gscGetChartDataCompare(getdata);

                  let rowData2 = {
                    startdate: compareStartDate,
                    enddate: compareendDate,
                    projectId: localStorage.getItem("projectGId"),
                    Dimension: 0,
                    SearchType: "Web",
                    View: 0,
                  };

                  let rowdat1 = {
                    startdate: startDate,
                    enddate: endDate,
                    projectId: localStorage.getItem("projectGId"),
                    Dimension: [
                      "Query",
                      "Page",
                      "Country",
                      "Device",
                      "Search Appearence",
                      "Date",
                    ],
                    SearchType: "Web",
                    View: 0,
                  };
                  gscGetDataGetForRange2(rowdat1, rowData2);

                }}
              >
                <option value="2">All</option>
                <option value="0">Mobile</option>
                <option value="1">Desktop</option>
                <option value="3">Mobile+Tablet</option>
              </select>
            </div>
            <div className="btn-box">
              <div className="d-flex align-items-center">
                <button
                  className={`${isgaChart == 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickgaChart(0);
                  }}
                >
                  <img
                    src={`${isgaChart == 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isgaChart == 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickgaChart(1);
                  }}
                >
                  <img
                    src={`${isgaChart == 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-3 round-chart">
        {isgaChart == 0 ? (
          <>
            <Chart
              options={options}
              series={series}
              type="line"
              height="300"
            />
            <div className="legend-box d-flex align-items-center justify-content-center w-100 mt-4">
              <div className="d-flex align-items-center">
                <div className="legend-dot blue"></div>
                <div className="ml-2">{formatRankDate(startDate)}</div>
              </div>
              <div className="d-flex align-items-center ms-5">
                <div className="legend-dot"></div>
                <div className="ml-2">{formatRankDate(compareStartDate)}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-12 text-right backlink-daterange mt-3">
              Date Range : {formatRankDate(startDate)} -{" "}
              {formatRankDate(compareStartDate)}
            </div>
            <div className="row search-results-gsc-main">
              <div className="row gac-table">
                {performanceMetricsCardList1.map((i) => {
                  return (
                    <div className="col-md-3 m-0 p-0 ">
                      <div className="gac mt-3 me-2">
                        <div className="d-flex align-items-center justify-content-between ">
                          <h4>{i.user}</h4>
                          <div className="grren-domain text-right">
                            {i.isHighData != 1 ? (
                              <>
                                <svg
                                  width="35"
                                  height="34"
                                  viewBox="0 0 35 34"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                                  <g clip-path="url(#clip0_4_4546)">
                                    <path
                                      d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                      stroke="#59D866"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                    <path
                                      d="M21.667 12H26.667V17"
                                      stroke="#59D866"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_4_4546">
                                      <rect
                                        width="20"
                                        height="20"
                                        fill="white"
                                        transform="translate(7.5 7)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                              </>
                            ) :
                              (
                                <>
                                  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle r="17" transform="matrix(1 0 0 -1 17 17)" fill="#FFC0C2" />
                                    <g clip-path="url(#clip0_4_4443)">
                                      <path d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12" stroke="#F64F53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      <path d="M21.167 22H26.167V17" stroke="#F64F53" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </g>
                                    <defs>
                                      <clipPath id="clip0_4_4443"><rect width="20" height="20" fill="white" transform="matrix(1 0 0 -1 7 27)" />
                                      </clipPath> </defs>
                                  </svg>

                                </>
                              )}
                          </div>
                        </div>
                        <h3
                          className={
                            i.isHighData == 2
                              ? "avg-val"
                              : i.isHighData == 1
                                ? "avg-val-negative text-left"
                                : "avg-val-positive text-left"
                          }
                        >
                          {+i.avgData ? +i.avgData : 0}
                          {isDateRage ? "" : "%"}
                        </h3>
                        <div className="row ">
                          <div className="d-flex align-items-center justify-content-between p-0">
                            <div className="col-md-6 p-0">
                              <p>{i.data}</p>
                              <span>{formatRankDate(startDate)}</span>
                            </div>
                            <div className="col-md-6 roi-total-compare">
                              <p>{i.compareData}</p>
                              <span>{formatRankDate(compareStartDate)}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Gacchart;
