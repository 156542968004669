import { useEffect, useState, useRef } from "react";
import { default as ReactSelect } from "react-select";
import {
  addFreelancer,
  getFreelancers,
  GET_Server_URL,
  freelancerBulkImport,
  getnichelist,
  onlyFileType,
  onlyXlsFile,
} from "../../utility/Constant";
import { Drawer } from "antd";
import APIManager from "../../services/APIManager";
import { useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";
import { themeStyle } from "../../utility/Globals";

function DatabaseContent() {
  
  const [List, setList] = useState([]);
  const [filterList, setFilterList] = useState([]);
  const [isBulk, setIsBulk] = useState('');
  const [isIndividual, setIsIndividual] = useState(false);
  const [isAddFreelancer, setIsAddFreelancer] = useState(false);
  const [fileSelectedMsg, setFileSelectedMsg] = useState("No File Selected");
  const [fileName, setFileName] = useState("");
  const [message, setMessage] = useState("");
  const [messageRequired, setMessageRequired] = useState("");
  const [url, setUrl] = useState("");
  const [errors, setErrors] = useState();
  const [className1, setClassName1] = useState("");
  const [className2, setClassName2] = useState("");
  const [closeButton, setCloseButton] = useState(false);
  const [save, setsave] = useState(false);
  const [open, setOpen] = useState(false);
  const [size, setSize] = useState();

  const [freelancerCatagory, setFreelancerCategory] = useState("");
  const [freelancerType, setFreelancerType] = useState("0");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [articlePerDay, setArticlePerDay] = useState("");
  const [costPerWord, setCostPerWord] = useState("");
  const [costPerArticle, setCostPerArticle] = useState("");
  const [selectedNiche, setSelectedNiche] = useState([]);
  const [nicheList, setNicheList] = useState([]);
  const [filelist, setfilelist] = useState([]);
  const [documentURL, setDocumentURL] = useState("");
  const [documentFile, setDocumentFile] = useState("");
  const [isActive, setIsActive] = useState([0, 1, 2]);
  const [comptrlist, setcomptrlist] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");
  let nichestr = '';
  const history = useHistory();

  useEffect(() => {
    getNichelist();
    getFreelancerList();
  
  }, []);
 
  function isValidURL() {
    let error = { ...errors };
    if (url.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
       
      );
      if (!pattern.test(url.trim())) {
        error["url"] = "Please enter valid URL";
      } else {
        delete error["url"];
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setFreelancerCategory("");
    setFreelancerType("");
    setFirstName("");
    setLastName("");
    setMobileNumber("");
    setEmail("");
    setArticlePerDay("");
    setCostPerArticle("");
    setCostPerWord("");
    setfilelist("");
    setSelectedNiche([]);
  }

  const onClose = () => {
    setIsBulk('');
    setIsIndividual(false);
    setOpen(false);
    clearForm();
  };
  const showLargeDrawer = () => {
    setSize("large");
    setOpen(true);
  };

  const customStylesSearch = {
    control: base => ({
      ...base,
      border: "1px solid #212121",
      boxShadow: 'none',
      height: 38,
      minHeight: 38,
      fontSize: '1.2rem',
      borderRadius: '8px'
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '38px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121"
    }),
  };

  function addFreelancerInBulk() {
    setCloseButton(false);
    var bodyFormData = new FormData();
    var input = document.getElementById("freelancerFile");
    let file = input.files[0];
    let isImport = true;
    bodyFormData.append(
      "organizationId",
      localStorage.getItem("organizationId")
    );
    if (
      document.querySelector("#textInputUrl").disabled == false &&
      isValidURL()
    ) {
      bodyFormData.append("url", url);
      isImport = true;
    } else if (document.querySelector("#freelancerFile").disabled == false) {
      isImport = true;
      bodyFormData.append("file", file);
    } else {
      isImport = false;
    }
    if (isImport) {
      APIManager.postAPI(freelancerBulkImport, {}, bodyFormData, false).then(
        (response) => {
          if (response.status == 200) {
            notifyAdd();
            getFreelancerList();
            onClose();
            setErrors("");
            setUrl("");
           
          }
        }
      );
    }
  }

  function updateList() {
    var input = document.getElementById("myfile1");
    var output = document.getElementById("fileList");
    var data = [];
    var children = "";
    for (var i = 0; i < input.files.length; ++i) {
      children += "<li id=filename" + i + ">" + input.files.item(i).name;
      data.push(input.files.item(i).name);
      setDocumentFile(input?.files?.item(i));
    }
    setfilelist(data);
    setCloseButton(true);
    output.innerHTML = "<ul>" + children + "</ul>";
  }

  function formValidation() {
    let error = { ...errors };
    
    freelancerCatagory.trim() == ""
      ? (error["freelancerCatagory"] = "Freelancer Catagory is required!")
      : delete error["freelancerCatagory"];

    firstName.trim() == ""
      ? (error["firstName"] = "First Name is required!")
      : !firstName.trim().match(/^[a-zA-Z0-9]*$/)
      ? (error["firstName"] = "Please enter alphabet characters only.")
      : delete error["firstName"];

    lastName.trim() == ""
      ? (error["lastName"] = "Last Name is required!")
      : !lastName.trim().match(/^[a-zA-Z0-9]*$/)
      ? (error["LastName"] = "Please enter alphabet characters only.")
      : delete error["lastName"];

    mobileNumber.trim() == ""
      ? (error["mobileNumber"] = "Mobile Number is required!")
      : !mobileNumber.trim().match(/^[0-9]{10,16}$/)
      ? (error["mobileNumber"] = "Please enter number only.")
      : delete error["mobileNumber"];

    email.trim() == ""
      ? (error["email"] = "Email is required!")
      : (!email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i))
      ? (errors["email"] = "Please enter valid email-ID.")
      : delete error["email"];
    
    selectedNiche.length == 0
      ? (error["niche"] = "Niche is required!")
      : delete error["niche"];

    setErrors(error);

    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function addFreelancerData() {
    let bodyFormData = new FormData();

    bodyFormData.append("freelancerCategory", freelancerCatagory);
    bodyFormData.append("freelancerType", freelancerType);
    bodyFormData.append("firstName", firstName);
    bodyFormData.append("lastName", lastName);
    bodyFormData.append("mobile", mobileNumber);
    bodyFormData.append("email", email);
    bodyFormData.append("articlesPerDay", articlePerDay);
    bodyFormData.append("costPerWord", costPerWord);
    bodyFormData.append("costPerArticle", costPerArticle);
    bodyFormData.append("SampleWorkFile", documentFile);
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );
    selectedNiche.map((item, index) => {
      bodyFormData.append("niche[" + index + "].industriesId", item.value);
    });

    APIManager.postAPI(addFreelancer, {}, bodyFormData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          onClose();
          getFreelancerList();
          clearForm();
          notifyAdd();
        }
      }
    );
  }

  const getFreelancerList = () => {
    APIManager.getAPI(getFreelancers).then((response) => {
      if (response && response.status == 200) {
        setList(response.data);
        setcomptrlist(response.data);
        setFilterList(response.data);
        setIsActive([0, 1, 2]);
       
      }
    });
  };

  const getNichelist = () => {
    let organizationId = localStorage.getItem("organizationId");
    APIManager.getAPI(getnichelist + organizationId).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        response.data.map((item) => {
          list.push({ value: item.id, label: item.industriesName });
        });

        setNicheList(list);
      }
    });
  };

  const inputFileRef = useRef(null);
  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function uploadFreelancerFile() {
    setIsAddFreelancer(true);
    var input = document.getElementById("freelancerFile");
    let file = input.files.item(0);
    if (file.name.length > 0) {
      document.querySelector("#textInputUrl").disabled = true;
      setCloseButton(true);
    } else {
      document.querySelector("#textInputUrl").disabled = false;
      setCloseButton(false);
    }
    setFileSelectedMsg("");
    setFileName(file.name);
  }


  const filterByCategory = (selectedfilter, category) => {
    
    if (selectedfilter.includes(category) && selectedfilter.length > 1)
      selectedfilter.splice(selectedfilter.indexOf(category), 1);
    else 
      selectedfilter.push(category);
    
    setIsActive(selectedfilter);
    setFilterList(
      List.filter((item) => isActive.includes(item.freelancerCategory))
    );
    setcomptrlist(List.filter((item) => isActive.includes(item.freelancerCategory)));
  };

  return (
    <>
      <div class="row scope-search ps-0 pe-2 database_page">
        <div className="d-flex justify-content-between align-items-center position-relative px-0">
          <div class="sow-search-box databse-box d-flex w-100 position-relative">
            <i class="fa fa-search"></i>
            <input
              type="text"
              class="scope-input w-100"
              placeholder="Search"
              name="search"
              onChange={(nameSearch) => {
                if(nameSearch != "")
                {
                setFilterList(
                  comptrlist.filter((item) =>
                    item.firstName
                      .toLocaleLowerCase()
                      .includes(nameSearch.target.value.toLocaleLowerCase())
                  ).concat(
                    comptrlist.filter((item) =>
                    item.lastName
                      .toLocaleLowerCase()
                      .includes(nameSearch.target.value.toLocaleLowerCase())
                  )
                ))
                }
                else{
                  setFilterList(comptrlist);
                }
              }}
            />
          </div>
          {/* <div className="btn_inner V"> */}
          
           {/* <div className="custom_download_btn d-flex align-items-center justify-content-end"> */}
           <div className="scope-work-btn d-flex 3-icons align-items-center">
              <div class="scope-work-btn scope-work me-3">
              <span>
           <a
                    class="btn btn-primary scope-download h-auto ms-3 gray_btn"
                    target="_blank"
                    href={GET_Server_URL + "audit/Freelancer.xlsx"}
                  >
                    Download Template{" "}
                  </a>
                  </span>
                  </div>
            <button
              className=" btn btn-primary"
              type="button"
              onClick={() => {
                setIsBulk(false);
                setIsIndividual(false);
                setFileSelectedMsg("No File Selected");
                setIsAddFreelancer(false);
                setFileName("");
                setMessage("");
                setMessageRequired("");
                showLargeDrawer();
                setUrl("");
                setErrors("");
                setClassName1("");
                setClassName2("");
                setCloseButton(false);
              }}
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-plus me-1"><line x1="12" y1="5" x2="12" y2="19"></line><line x1="5" y1="12" x2="19" y2="12"></line></svg>
              Add Freelancers
            </button>
            <Drawer
              title=" Add Freelancer"
              className=" industries-modal add-industries freelancer-drawer"
              placement="right"
              size={size}
              onClose={onClose}
              open={open}
            >
              <div class="modal-dialog scope-modal keyword-details-modal" role="document">
                <div class="modal-content h-100">
                  <div className="col-md-12 industries-ticket-btn"></div>
                  <div class="modal-body p-0">
                    <div className="portlet common-form-fields border-0">
                      <div className="mx-3">
                        <div className={className1}>
                          <div className="radio">
                            <div className="d-flex align-items-center ms-3">
                              <input
                                className="me-3"
                                type="radio"
                                value="option1"
                                id="option1"
                                name="uploadType"
                                checked={isBulk == "bulk" ?? "false" }
                                onClick={(e) => {
                                  setIsBulk("bulk");
                                  setIsIndividual(false);
                                  setIsAddFreelancer(false);
                                  // setIndustryName("");
                                  setClassName1("active");
                                  setClassName2("");
                                }}
                              />
                              <label className={isBulk == "bulk" ? "bulk-upload mb-1 text-primary" : "bulk-upload main-title mb-1"}>
                                Bulk Upload
                              </label>
                            </div>
                            <div className="ms-5">
                              <span className="text-grey ">
                                Setup multiple (bulk) upload with the details by
                                uploading Google Sheet link/.xlsx/.csv file
                              </span>
                            </div>
                            {isBulk == "bulk" ? (
                              <>
                                <div className="border-top mt-3 pt-3 industries-box freelancer-box ">
                                  <span className="ms-4 industries-csv-file">
                                    Add URL (Google Sheet Link)
                                  </span>
                                  <div className="mt-2 ms-4 me-3">
                                    <input
                                      className="form-control w-100"
                                      placeholder="URL"
                                      id="textInputUrl"
                                      value={url}
                                      onChange={(e) => {
                                        setUrl(e.target.value);
                                        if (e.target.value.length > 0) {
                                          setIsAddFreelancer(true);
                                          document.querySelector(
                                            "#freelancerFile"
                                          ).disabled = true;
                                        } else {
                                          setErrors("");
                                          setIsAddFreelancer(false);
                                          document.querySelector(
                                            "#freelancerFile"
                                          ).disabled = false;
                                        }
                                      }}
                                    ></input>
                                    {save && errors?.url && (
                                      <>
                                        <div className="field-required mt-2">
                                          <span> {errors?.url}</span>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                  <div className="my-3 text-center or">
                                    <span>OR</span>
                                  </div>
                                  <span className="ms-4 industries-csv-file">
                                    Add Attachments (Only .xlsx or .CSV Files)
                                  </span>
                                </div>
                                <div className="industries-choose-file d-flex align-items-center justify-content-between p-2 mt-1 ms-4 me-3 ps-3">
                                  <div className="btn-choosefile d-flex align-items-center">
                                      <input
                                        className="w-100"
                                        style={{ display: "none" }}
                                        type="file"
                                        id="freelancerFile"
                                        name="freelancerFile"
                                        multiple={false}
                                        accept={onlyXlsFile}
                                        ref={inputFileRef}
                                        onChange={uploadFreelancerFile}
                                      />
                                      <a
                                        onClick={onBtnClick}
                                        id="btnChooseFile"
                                        className="btn btn-primary me-3"
                                      >
                                        Choose File
                                      </a>
                                      <span className="file-not-select ">
                                        {fileSelectedMsg}
                                      </span>
                                      <span>
                                      {fileName.length ? fileName : ""}
                                      </span>
                                    </div>
                         
                                  {closeButton ? (
                                    <>
                                      <button
                                        type="button"
                                        title="Close"
                                        style={{
                                          border: "0",
                                          background: "none",
                                        }}
                                        class="close"
                                        // data-dismiss="modal"
                                        // aria-label="Close"
                                        onClick={() => {
                                          setFileName("");
                                          setCloseButton(false);
                                          setIsAddFreelancer(false);
                                          document.querySelector(
                                            "#textInputUrl"
                                          ).disabled = false;
                                        }}
                                      >
                                        {" "}
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className={className2}>
                          <div className="radio mt-3 database-individual">
                              <div className="d-flex align-items-center">
                              <input
                                className="ms-3"
                                type="radio"
                                value="option2"
                                id="option2"
                                name="uploadType"
                                checked={isBulk == "individual" ?? "false" }
                                onClick={(e) => {
                                  setIsIndividual(e.target.checked);
                                  setIsBulk("individual");
                                  setIsAddFreelancer(true);
                                  setFileSelectedMsg("No File Selected");
                                  setFileName("");
                                  setMessage("");
                                  setMessageRequired("");
                                  setClassName2("active");
                                  setClassName1("");
                                  getNichelist();
                                }}
                              />
                              <label className={isBulk == "individual" ? "individual-upload ms-3 text-primary mb-1" : "individual-upload ms-3 main-title mb-1"}>
                                Individual Upload
                              </label>
                            </div>
                            <span className="mt-3 ms-5 text-grey">
                              Add freelancer by manually adding details
                            </span>
                            <div>
                              {isIndividual ? (
                                <div className="border-top mt-3 pt-3 industries-box freelancer-box global-scrollbar ">
                                  <div className="industries-individual freelancer-modal ms-3">
                                    <div class="col-md-12">
                                      <label className="form-label">
                                        Freelancer Category
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <div>
                                        <div class="d-flex align-items-center mb-3">
                                          <input
                                            type="radio"
                                            name="freelancerCatagory"
                                            id="writer"
                                            value="0"
                                            class="h-auto"
                                            onChange={(e) => {
                                              setFreelancerCategory(
                                                e.target.value
                                              );
                                              formValidation();
                                            }}
                                          />
                                          <label
                                            class="mb-0 ms-3"
                                            htmlfor="writer"
                                          >
                                            Writer
                                          </label>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                          <input
                                            type="radio"
                                            name="freelancerCatagory"
                                            value="1"
                                            class="h-auto"
                                            id="editor"
                                            onChange={(e) => {
                                              setFreelancerCategory(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <label
                                            class="mb-0 ms-3"
                                            htmlfor="editor"
                                          >
                                            Editor
                                          </label>
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                          <input
                                            type="radio"
                                            name="freelancerCatagory"
                                            value="2"
                                            class="h-auto"
                                            id="both"
                                            onChange={(e) => {
                                              setFreelancerCategory(
                                                e.target.value
                                              );
                                            }}
                                          />
                                          <label
                                            class="mb-0 ms-3"
                                            htmlfor="both"
                                          >
                                            Writer + Editor
                                          </label>
                                        </div>
                                      </div>

                                      {save && errors?.freelancerCatagory ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span>
                                              {" "}
                                              {errors?.freelancerCatagory}
                                            </span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Freelancer Type
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <select
                                        name="freeclancerType"
                                        id="freeclancerType"
                                        class="form-select"
                                        onChange={(e) => {
                                          setFreelancerType(e.target.value);
                                        }}
                                      >
                                        <option value="0">Internal</option>
                                        <option value="1">External</option>
                                        <option value="2">Agency</option>
                                      </select>
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        First Name
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => {
                                          setFirstName(e.target.value);
                                          formValidation();
                                        }}
                                      />
                                      {save && errors?.firstName ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.firstName}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Last Name
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => {
                                          setLastName(e.target.value);
                                          formValidation();
                                        }}
                                      />
                                      {save && errors?.lastName ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.lastName}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Mobile Number
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        type="text"
                                        value={mobileNumber}
                                        onChange={(e) => {
                                          setMobileNumber(e.target.value);
                                        }}
                                      />
                                      {save && errors?.mobileNumber ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.mobileNumber}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-0">
                                        Email ID
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <input
                                        type="text"
                                        value={email}
                                        onChange={(e) => {
                                          setEmail(e.target.value);
                                        }}
                                      />
                                      {save && errors?.email ? (
                                        <>
                                          <div className="field-required  mt-1">
                                            <span> {errors?.email}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-2">
                                        Article per day
                                      </label>
                                      <input
                                        type="text"
                                        value={articlePerDay}
                                        onChange={(e) => {
                                          setArticlePerDay(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-2">
                                        Cost per Word
                                      </label>
                                      <input
                                        type="text"
                                        value={costPerWord}
                                        onChange={(e) => {
                                          setCostPerWord(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div class="col-md-12 mt-3">
                                      <label className="form-label mb-2">
                                        Cost per Article
                                      </label>
                                      <input
                                        type="text"
                                        value={costPerArticle}
                                        onChange={(e) => {
                                          setCostPerArticle(e.target.value);
                                        }}
                                      />
                                    </div>
                                    <div class="col-md-12 mt-3 mb-4">
                                      <label className="form-label mb-0">
                                        Niche
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </label>
                                      <ReactSelect
                                        // styles={themeStyle()}
                                        styles={customStylesSearch}
                                        isMulti
                                        components={{
                                          IndicatorSeparator: () => null,
                                        }}
                                        // className="border-0"
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        allowSelectAll={true}
                                        options={nicheList}
                                        value={selectedNiche}
                                        onChange={(item) => {
                                          setSelectedNiche(item);
                                        }}
                                      ></ReactSelect>
                                      
                                      {save && errors?.niche ? (
                                        <>
                                          <div className="field-required mt-1">
                                            <span> {errors?.niche}</span>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    <div class="profile-portlet personal-doc col-md-12 mt-3">
                                      <label className="form-label">
                                        {" "}
                                        Upload Sample Work <small>(Only word & .pdf Files)</small>
                                      </label>
                                      <br />
                                      <input
                                        className="personal-choose-file p-3 h-auto w-100"
                                        type="file"
                                        id="myfile1"
                                        name="myfile1"
                                        multiple={false}
                                        accept={onlyFileType}
                                        ref={inputFileRef}
                                        onChange={updateList}
                                      />
                                      {documentURL ? (
                                        <>
                                        <span>{closeButton}</span>
                                        <label id="fileLabel">
                                          <a
                                            target={"_blank"}
                                            href={GET_Server_URL + documentURL +"-"}
                                          >
                                            {documentURL}
                                          </a>
                                        </label>
                                        {closeButton ? 
                                        <button
                                        type="button"
                                        title="Close"
                                        style={{
                                          border: "0",
                                          background: "none",
                                        }}
                                        class="close"
                                        // data-dismiss="modal"
                                        // aria-label="Close"
                                        onClick={() => {
                                          setDocumentFile("");
                                          setCloseButton(false);
                                          setIsAddFreelancer(false);
                                          document.querySelector(
                                            "#textInputUrl"
                                          ).disabled = false;
                                        }}
                                        >
                                          {" "}
                                        <span aria-hidden="true">&times;</span>
                                      </button>
                                        : <></>}
                                      </>
                                      ) : null}

                                      <label id="fileLabel">
                                        {filelist.length > 0
                                          ? `${filelist.length} files`
                                          : ""}
                                      </label>                                     
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          {isAddFreelancer ? (
                            <>
                            <div className="btn-box-bottom w-50">
                            <div className="col-md-12 ">
                              <div class="portlet-footer portlet-footer-bordered mt-3 text-end px-0">
                                <button
                                  className="btn btn-outline-secondary close me-3"
                                  type="button"
                                  onClick={onClose}
                                  //   class="close pe-0"
                                  // aria-label="Close"
                                  // data-dismiss="modal"
                                >
                                  Cancel
                                </button>
                                <button
                                  type="button"
                                  class="btn-outline btn btn-primary w-auto"
                                  onClick={() => {
                                    setsave(true);
                                    setFileName("");
                                    if (isBulk == "bulk") {
                                      addFreelancerInBulk();
                                    } else if (isIndividual) {
                                      if (formValidation()) {
                                        addFreelancerData();
                                      }
                                    }
                                  }}
                                  // data-dismiss={
                                  //     isModelDismiss1 ? "modal" : ""
                                  // }
                                > 
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke=" #FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check me-1"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                  Add Freelancer
                                </button>
                              </div>
                              </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Drawer>
            </div>
           {/* </div> */}
          {/* </div> */}
        </div>
      </div>

      <div className="das-content-filter mt-4 ps-1">
        <h4>Filter by</h4>
      </div>
      <div class="writer-editors-tab all-ticket-bread-crumb p-0 view-breadcrum">
        <ul class="d-flex mb- px-0 mb-3">
          <li
            class={` ${isActive.includes(0) ? "active" : ""}`}
            onClick={() => {
              filterByCategory(isActive, 0);
            }}
          >
            <span className="personal-tabview">
              {isActive.includes(0) ? (
                <i class="fa fa-check me-2" aria-hidden="true"></i>
              ) : (
                <></>
              )}
              Writers
            </span>
          </li>
          <li
            class={`mx-3 ${isActive.includes(1) ? "active" : ""}`}
            onClick={() => {
              filterByCategory(isActive, 1);
            }}
          >
            <span className="personal-tabview">
              {isActive.includes(1) ? (
                <i class="fa fa-check me-2" aria-hidden="true"></i>
              ) : (
                <></>
              )}
              Editors
            </span>
          </li>
          <li
            class={` ${isActive.includes(2) ? "active" : ""}`}
            onClick={() => {
              filterByCategory(isActive, 2);
            }}
          >
            <span className="personal-tabview">
              {isActive.includes(2) ? (
                <i class="fa fa-check me-2" aria-hidden="true"></i>
              ) : (
                <></>
              )}
              Writes & Editors
            </span>
          </li>
        </ul>
      </div>
      <div className="row html2pdf__page-break all-channel-table px-0 pe-0 pb-4">
        {/* <div className="d-flex"> */}
        <div className="row d-flex py-0 allchannel-table px-0">
          {filterList.map((item) => {
            nichestr = ''
            return (
              <>
                <div
                  className="col-sm-6 m-0 p-0"
                  key={item.id}
                  onClick={() => {
                    history.push({
                      pathname: "/content-main/FreelancerProfile/" + item.id,
                    });
                  }}
                >
                  <div className="db-content-box me-3 mt-3">
                    <div className="d-flex align-items-center  justify-content-between db-card">
                      <div className="d-flex align-items-center justify-content-start ms-3">
                        <div className="profile_detail">
                        <div className="writer-box position-relative">
                          {item.imageURL ? (
                            <>
                              <img
                                alt="profile-image"
                                src={
                                  item.imageURL != "null" || !item.imageURL
                                    ? GET_Server_URL + item.imageURL
                                    : "/images/profile-pic.jpeg"
                                }
                                className="writer-img"
                              />
                            </>
                          ) : (
                            <>
                              <img
                                alt="profile-image"
                                src="/images/profile-pic.jpeg"
                                className="writer-img"
                              />
                            </>
                          )}
                        </div>
                        {
                          item.isActive == true  ? 
                            ( <div className="red-green-status green"></div>)  :
                            ( <div className="red-green-status red"></div>)
                        }
                       
                        </div>
                        <div className="d-flex my-0 content-client-name px-0">
                          <div>
                            <h3 className="ms-3 mb-0">
                              {item.firstName + " " + item.lastName}
                            </h3>
                            <span class="ms-3 text-blue-light">
                              {item.freelancerCategory == 0
                                ? "Writer"
                                : item.freelancerCategory == 1
                                ? "Editor"
                                : "Writer & Editors"}
                            </span>
                          </div>

                          <button className="db-client-btn ms-3 " type="button">
                            {item.freelancerType == 0
                              ? "Internal"
                              : item.freelancerType == 1
                              ? "External"
                              : "Agency"}
                          </button>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between align-items-end me-3 require-sucess">
                        <button
                          type="button"
                          class="database-success ms-3"
                        >
                          <img
                            className="success-img me-2"
                            src="/images/green-trending-up.svg"
                            alt=""
                          />
                          <span>Success: 100%</span>
                        </button>
                      </div>
                    </div>
                    <div className="border-bottom my-3"></div>

                    <div className="d-flex align-items-center writer-link ms-3">
                      <span className="">{item.edit}</span>
                    </div>

                    <div className="d-flex justify-content-start align-items-center writer-website-name px-3">
                      <h5 className="my-1 me-3">Niche</h5>

                      {item.niche.map((item , index) => {
                          
                          return nicheList.map((list) => { 
                            if(item.industriesId.toLocaleLowerCase().trim() ==
                              list.value.toLocaleLowerCase().trim())
                              {
                                nichestr = (nichestr != "") ? nichestr + ', ' : ''
                                if(index < 3)
                                {
                                  nichestr = nichestr + list.label
                                  return (<button className="website-btn mx-1" type="button" >
                                      {list.label}
                                    </button>);

                                }else
                                {
                                  nichestr = nichestr + list.label 
                                }
                              }  
                              })
                            })
                          }
                          {item.niche.length > 3 ? <span title={nichestr} >...</span> : ''}
                    </div>

                    <div className="border-bottom my-3"></div>

                    <div className="d-flex justify-content-start content-price px-3 pb-1">
                      <span className=" my-2 me-4">Price</span>
                      {item.costPerWord ? (
                        <>
                          <p className="ps-1 me-3  my-2 ">
                            Rs.{item.costPerWord}/Word
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                      {item.costPerWord && item.costPerArticle ? (
                        <div className="border-rightside"></div>
                      ) : (
                        ""
                      )}
                      {item.costPerArticle ? (
                        <>
                          <p className="ms-3 my-2">
                            Rs.{item.costPerArticle}/Article
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>

        {/* </div> */}
      </div>
    </>
  );
}

export default DatabaseContent;
