const NotFound = () => {
	return (
		<>
			<div className='login'>
				<h1 className='text-center'>
					😰
					<br />
					404 Not Found
				</h1>
			</div>
		</>
	);
};

export default NotFound;
