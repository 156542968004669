import React, { useRef } from "react";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import "react-circular-progressbar/dist/styles.css";
import { useLocation } from "react-router-dom";
import APIManager from "../services/APIManager";
import { useHistory } from "react-router-dom";
import { default as ReactSelect, components } from "react-select";
import {
  deleteSupportingDoc,
  documentFileType,
  getSeoAuditDataByProjectAuditandAuditId,
  getSeoAuditItem,
  getSeoAuditItemByModuleId,
  GET_FrontEnd_URL,
  GET_Server_URL,
  seoCompleteSEOAudit,
  updateSeoAudit,
} from "../utility/Constant";
import { mmDDyyyyFormateDateSet, themeStyle } from "../utility/Globals";
import { confirmAlert } from "react-confirm-alert";
import JoditEditor from "jodit-pro-react";
import { Table } from "antd";
import AuditAddUpdate from "./configuration/AuditAddUpdate";

function ModuleExpandSEOAuditSelection({ update }) {
  const history = useHistory();
  const [impact, setImpact] = useState("0");
  const [priority, setPriority] = useState("0");
  const [suggestion, setSuggestion] = useState("");
  const [supportingDocs, setSupportingDocs] = useState([]);
  const [comment, setComment] = useState("");
  const [seletedId, setSeletedId] = useState("");
  const [status1, setStatus1] = useState("5");
  const [isImpactView, setImpactView] = useState(false);
  const [buttonActive, setButtonActive] = useState(4);
  const [statusUpdateDate, setStatusUpdateDate] = useState(
    new Date().toISOString().slice(0, 10)
  );

  const locationforAudit = useLocation();
  const config = {
    readonly: false,
    uploader: {
      insertImageAsBase64URI: false,
      url: GET_Server_URL + "api/imageupload",
      isSuccess: function (resp) {
        return !resp.error;
      },
      getMessage: function (resp) {
        return resp.msg;
      },
      process: function (resp) {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg,
        };
      },
      defaultHandlerSuccess: function (data, resp) {
        var i,
          field = "files";
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            this.s.insertImage(
              GET_FrontEnd_URL + "/assets/images/" + data[field][i]
            );
          }
        }
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
    buttons: [
      "undo",
      "redo",
      "|",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "brush",
      "eraser",
      "copyformat",
      "|",
      "ol",
      "ul",
      "align",
      "|",
      "table",
      "indent",
      "outdent",
      "hr",
      "link",
      "image",
      "video",
      "source",
      "|",
    ],
    removeButtons: ["fullsize", "font", "fontsize", "paragraph"],
    toolbarSticky: true,
    toolbarAdaptive: false,
    addNewLineDeltaShow: false,
    cleanHTML: {
      replaceNBSP: true,
      removeEmptyElements: true,
      fillEmptyParagraph: false,
    },
  };

  useEffect(() => {
    getModuleList(locationforAudit?.state?.seoAuditId);
    setAuditSelected(locationforAudit?.state?.seoAuditId);
  }, []);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <div className="d-flex justify-content-between">
            <label>{props.data.pointTitle}</label>
            <div style={{ color: "green" }}>
              {props.data.statusCheck == 1 ? "✔" : ""}
            </div>
          </div>
        </components.Option>
      </div>
    );
  };

  function completeSEOAudit() {
    var bodyFormData = new FormData();
    APIManager.putAPI(
      seoCompleteSEOAudit + projectAuditIdd,
      {},
      null,
      true
    ).then(() => {
      history.goBack();
    });
  }

  const [seoAuditSelected, setAuditSelected] = useState("");
  const [moduleName1, setModuleName1] = useState("");
  const [isChangeModuleName, setIsChangeModuleName] = useState(false);
  const [moduleList, setModule] = useState([]);
  const [auditId, setId] = useState("");
  const [title, setTitle] = useState({ index: 0 });
  const [titleIndex, setTitleIndex] = useState("");
  const [titleList, setTitleList] = useState([]);
  const [titleListTemp, setTitleListTemp] = useState([]);
  const [description, setDescription] = useState([]);
  const [submitButtonClick, onSubmitButtonClick] = useState(false);
  const [auditResponseIdd, setAuditResponseIds] = useState("");
  const [descriptionList2, setDescriptionList2] = useState([]);
  const [commentData, setCommentData] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [suggestionData, setSuggestionData] = useState("");
  const [isAuditCompletedActive, setAuditCompletedActive] = useState(false);
  const [settings, setSettings] = useState("seoAuditSelection");

  function getModuleList(id) {
    APIManager.getAPI(getSeoAuditItem + id).then((response) => {
      if (response && response.status == 200) {
        let total_cp = 0,
          total_p = 0;
        response.data.map((item) => {
          total_cp = total_cp + item.checkedPoints;
          total_p = total_p + item.totalPoints;
        });
        if (total_cp == total_p) {
          setAuditCompletedActive(true);
        } else {
          setAuditCompletedActive(false);
        }
        setModule(response.data);

        let remmaingModuleIds = [];
        response.data.map((item) => {
          if (item.totalPoints === item.checkedPoints) {
            setIsChangeModuleName(false);
            return item;
          }
          remmaingModuleIds.push(item.moduleId);
        });

        if (locationforAudit?.state?.seoAuditModuleId) {
          getTitleList(id, locationforAudit?.state?.seoAuditModuleId);
          setModuleName1(locationforAudit?.state?.seoAuditModuleId);
        } else {
          if (remmaingModuleIds.length != 0) {
            if (isChangeModuleName) {
              var seoAuditSelectedId = "";
              response.data.map((itm, index) => {
                if (
                  itm.moduleId == moduleName1 &&
                  itm.checkedPoints == itm.totalPoints
                ) {
                  if (response.data.length > index) {
                    for (let i = index; i < response.data.length; i++) {
                      if (
                        response.data[i].checkedPoints !=
                        response.data[i].totalPoints
                      ) {
                        seoAuditSelectedId = response.data[i].moduleId;
                        return;
                      }
                    }
                  }
                }
              });
              if (seoAuditSelectedId != "") {
                getTitleList(seoAuditSelected, seoAuditSelectedId);
                setModuleName1(seoAuditSelectedId);
              } else {
                getTitleList(seoAuditSelected, moduleName1);
              }
            } else {
              getTitleList(id, remmaingModuleIds[0]);
              setModuleName1(remmaingModuleIds[0]);
            }
          } else {
            getTitleList(id, response.data[0].moduleId);
            setModuleName1(response.data[0].moduleId);
          }
        }
      }
    });
  }

  const [projectAuditIdd, setProjectAuditIdd] = useState("");
  function getTitleList(projectAuditId, auditModuleId) {
    setProjectAuditIdd(projectAuditId);
    APIManager.getAPI(
      getSeoAuditItemByModuleId +
      "auditModuleId=" +
      auditModuleId +
      "&projectAuditId=" +
      projectAuditId
    ).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        let i = 0;
        response?.data.map((item) => {
          list.push({
            index: i,
            key: item.projectAuditDataId,
            value: item.pointTitle,
            label: item.pointTitle,
            auditId: item.auditId,
            pointTitle: item.pointTitle,
            projectAuditDataId: item.projectAuditDataId,
            statusCheck: item.statusCheck,
          });
          i++;
        });
        setTitleList(list);
        setTitleListTemp(list);
        let remmaingAuditIds = [];
        response.data.map((item) => {
          if (item.statusCheck === 1) {
            return item;
          }
          remmaingAuditIds.push({
            key: item.projectAuditDataId,
            value: item.pointTitle,
            label: item.pointTitle,
            auditId: item.auditId,
            pointTitle: item.pointTitle,
            projectAuditDataId: item.projectAuditDataId,
            statusCheck: item.statusCheck,
          });
        });

        if (locationforAudit?.state?.seoAuditModulePointId && auditModuleId == locationforAudit?.state?.seoAuditModulePointId) {
          list.map((item, index) => {
            if (
              item.auditId == locationforAudit?.state?.seoAuditModulePointId
            ) {
              setTitle({
                key: item.key,
                value: item.value,
                label: item.label,
                auditId: item.auditId,
                pointTitle: item.pointTitle,
                projectAuditDataId: item.projectAuditDataId,
                statusCheck: item.statusCheck,
              });
            }
          });

          setId(locationforAudit?.state?.seoAuditModulePointId);
          getSeoAuditDataByProjectAuditandAuditIdList(
            projectAuditId,
            locationforAudit?.state?.seoAuditModulePointId
          );
        } else {
          if (remmaingAuditIds.length != 0) {
            let nextIndex = 0;
            let is1stOne = true;
            titleListTemp.map((im, index) => {
              if (index > titleIndex) {
                if (im.statusCheck == 0 && is1stOne) {
                  nextIndex = im.index;
                  is1stOne = false;
                }
              }
            });
            setTitle(remmaingAuditIds[0]);
            setId(remmaingAuditIds[0]);
            getSeoAuditDataByProjectAuditandAuditIdList(
              projectAuditId,
              remmaingAuditIds[0].auditId
            );
            setTitleIndex(0);
          } else {
            setTitle({
              key: response?.data[0].projectAuditDataId,
              value: response?.data[0].pointTitle,
              label: response?.data[0].pointTitle,
              auditId: response?.data[0].auditId,
              pointTitle: response?.data[0].pointTitle,
              projectAuditDataId: response?.data[0].projectAuditDataId,
              statusCheck: response?.data[0].statusCheck,
            });
            setId(response?.data[0].auditId);
            getSeoAuditDataByProjectAuditandAuditIdList(
              projectAuditId,
              response?.data[0].auditId
            );
          }
        }
      }
    });
  }
  const [projectAuditIdddd, setprojectAuditId] = useState("");

  function getSeoAuditDataByProjectAuditandAuditIdList(
    projectAuditId,
    auditId
  ) {
    APIManager.getAPI(
      getSeoAuditDataByProjectAuditandAuditId +
      "auditId=" +
      auditId +
      "&projectAuditId=" +
      projectAuditId
    ).then((response) => {
      if (response && response.status == 200) {
        setSupportingDocs([]);
        setId(auditId);
        setDescriptionList2(
          response.data?.projectAuditData?.audits?.description
        );
        setCommentData(response.data.projectAuditData?.comment ?? "");
        setprojectAuditId(response.data.projectAuditData.projectAuditId);
        setDescription(response.data.projectAuditData);
        setAuditResponseIds(response?.data?.projectAuditData?.auditResponseId);

        setComment(response.data.projectAuditData?.comment);
        setSeletedId(response.data.projectAuditData?.id);
        setSuggestion(
          response.data?.auditDescriptions[0]?.recommendation ?? ""
        );

        if (
          response.data.projectAuditData?.auditResponseValue != null &&
          response.data.projectAuditData?.auditResponseValue != 4
        ) {
          setButtonActive(response.data?.projectAuditData?.auditResponseValue);

          clickButtonStatus(
            response.data?.projectAuditData?.auditResponseValue
          );
          setImpact(
            response.data?.projectAuditData?.auditResponse?.impact ?? "0"
          );
          setPriority(
            response.data?.projectAuditData?.auditResponse?.priority ?? "0"
          );
          setStatus1(
            response.data?.projectAuditData?.auditResponse?.status ?? "5"
          );
          let date = mmDDyyyyFormateDateSet(
            response.data?.projectAuditData?.auditResponse?.statusUpdatedDate ??
            new Date().toISOString().slice(0, 10)
          );
          setStatusUpdateDate(date);
          setSuggestion(
            response.data?.projectAuditData?.auditResponse?.suggestion ?? ""
          );
          let temp = description;
          if (temp.auditResponse != null) {
            temp.auditResponse.suggestion =
              response.data?.projectAuditData?.auditResponse?.suggestion ?? "";
            setSuggestionData(temp);
          }
          setSupportingDocs(
            response.data?.projectAuditData?.auditResponse?.auditSupportingDocs
          );
        } else {
          setButtonActive(4);
          setImpactView(false);
        }
      }
    });
  }

  function updateAudit(description) {
    var bodyFormData = new FormData();
    bodyFormData.append("auditId", auditId);
    if (description.auditResponseValue == 0) {
      if (description.auditResponseId != undefined) {
        bodyFormData.append(
          "auditResponseId",
          description.auditResponse.auditResponseId
        );
      }
      bodyFormData.append(
        "auditResponse.impact",
        impact
      );
      bodyFormData.append(
        "auditResponse.priority",
        priority
      );
      bodyFormData.append(
        "auditResponse.suggestion",
        description.auditResponse.suggestion
      );

      bodyFormData.append("auditResponse.status", status1);
      bodyFormData.append(
        "auditResponse.statusUpdatedDate",
        description.auditResponse.statusUpdatedDate
      );
      if (auditResponseIdd || auditResponseIdd != null) {
        bodyFormData.append("auditResponse.id", auditResponseIdd);
      }
    }
    bodyFormData.append("auditResponseValue", description.auditResponseValue);
    bodyFormData.append("id", seletedId);

    if (leadsClientDocs.length > 0) {
      leadsClientDocs.map((item, index) => {
        bodyFormData.append(
          `auditResponse.AuditSupportingDocs[${index}].Documents`,
          " "
        );
        bodyFormData.append(
          `auditResponse.AuditSupportingDocs[${index}].file`,
          item
        );
      });
    }

    bodyFormData.append("comment", commentData);
    bodyFormData.append("projectAuditId", projectAuditIdddd);

    APIManager.putAPI(updateSeoAudit, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status == 200) {
          getModuleList(seoAuditSelected);
          setImpact("0");
          setPriority("0");
          setSuggestion("");
          setStatus1("5");
          setComment("");
          setCommentData("");
          setStatusUpdateDate(new Date().toISOString().slice(0, 10));
          setSupportingDocs([]);
          setLeadsClientDocs([]);
          setFileList([]);
          setErrorMessage("");

          let temp;
          temp = {
            comment: "",
          };
          setDescription(temp);
          // update("");
        }
      }
    );
  }

  function clickButtonStatus(status) {
    setButtonActive(status);
    let temp = description;
    temp.auditResponseValue = status;
    setComment(temp.comment);
    if (status == 0) {
      setImpactView(true);
      if (description.auditResponse == null) {
        temp.auditResponse = {
          impact: "0",
          priority: "0",
          suggestion: suggestion,
          comment: "",
          status: "1",
          statusUpdatedDate: new Date().toISOString().slice(0, 10),
        };
      }
    } else {
      setImpactView(false);
      temp.auditResponse = null;
    }
    setDescription(temp);
  }

  function handleChangeOrganization(selected) {
    let temp;
    temp = {
      comment: "",
    };
    setDescription(temp);
    setSuggestion("");
    setTitle(selected);
    setTitleIndex(selected.index);
    getSeoAuditDataByProjectAuditandAuditIdList(
      seoAuditSelected,
      selected.auditId
    );
  }

  const [leadsClientDocs, setLeadsClientDocs] = useState([]);

  function showPopUp() {
    confirmAlert({
      title: "Are you sure you want to add a completely new audit point?",
      message: "",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            setSettings("addUpdateAudit");
            history.push({
              // pathname: "/main/add-audit",
              state: {
                seoAuditId: locationforAudit?.state?.seoAuditId,
                moduleName1: moduleName1,
              },
            });
          },
        },
        {
          label: "No",
          onClick: () => { },
        },
      ],
    });
  }

  async function deleteDocumentsById(id) {
    try {
      const response = await APIManager.deleteAPI(deleteSupportingDoc + id);
      if (response == 200) {
        let tempSupportingDocs = [];
        supportingDocs.map((item) => {
          if (item.id == id) {
          } else {
            tempSupportingDocs.push(item);
          }
        });
        setSupportingDocs(tempSupportingDocs);
      }
    } catch (e) { }
  }

  let onClickFunction = (arg) => {
    setSettings(arg);
  };

  function getComponent() {
    if (settings == "addUpdateAudit") {
      return <AuditAddUpdate update={onClickFunction} />;
    }
  }

  const [fileList, setFileList] = useState([]);
  const inputRef = useRef(null);
  const editorObservation = useRef(null);
  const editorSuggestion = useRef(null);

  return (
    <>
      <div className="pop-over">
        {/* <div className="d-flex justify-content-between">
          <div class="dis-none">
            <i
              class="fa fa-chevron-circle-left"
              style={{ fontSize: 20 + "px", color: "#2196f3" }}
              onClick={() => {
                update("");
              }}
            ></i>
          </div>
        </div> */}
        {settings === "seoAuditSelection" ? (
          <div className="portlet">
            <div class="portlet-header portlet-header-bordered">
              <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
              <h3 class="portlet-title main-title">
                {localStorage.getItem("projectGName")} - Audit Findings Details
              </h3>

              {/* <button
                type="button"
                title="Close"
                style={{ border: "0", background: "none" }}
                class="close"
                // data-dismiss="modal"
                // aria-label="Close"
                onClick={() => update("")}
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>
            <div className="px-4 pb-5 mt-4">
              <div className="row">
                {" "}
                {/* VIEW AUDIT TAB START */}
                <div class="audit-detail-box ps-0">
                  <div className="row">
                    <div className="col-md-2 col-sm-12 mt-5">
                      <div className="module-title">
                        <h5 className="text-dark fw-bold">Audit Name</h5>
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12 mt-5 name">
                      <h6>{locationforAudit?.state?.seoAuditName}</h6>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 col-sm-12 mt-4 d-flex align-items-center">
                      <div className="module-title">
                        <h5 className="text-dark mb-0 fw-bold">Module Name</h5>
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12 mt-4">
                      <select
                        class="form-select w-100"
                        required
                        value={moduleName1}
                        onChange={(e) => {
                          setModuleName1(e.target.value);
                          setIsChangeModuleName(true);
                          getTitleList(seoAuditSelected, e.target.value);
                        }}
                      >
                        {moduleList.map((item) => {
                          return (
                            <option value={item.moduleId}>
                              {item.module} ({item.checkedPoints}/
                              {item.totalPoints})
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 mt-4 d-flex align-items-center">
                      <div className="module-title">
                        <h5 className="text-dark mb-0 fw-bold">Audit Point</h5>
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12 mt-4 d-flex expand-select">
                      <ReactSelect
                        styles={themeStyle()}
                        className="SearchSelectMain w-100 me-2"
                        placeholder="Select or search module"
                        options={titleList}
                        isMulti={false}
                        components={{ Option }}
                        closeMenuOnSelect={true}
                        onChange={handleChangeOrganization}
                        value={title}
                        hideSelectedOptions={false}
                        allowSelectAll={true}
                      />
                      <button
                        style={{ color: "#fff" }}
                        class="btn btn-primary mx-1"
                        onClick={() => {
                          showPopUp();
                        }}
                      >
                        +
                      </button>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 col-sm-12 mt-4 d-flex align-items-center">
                      <div className="module-title">
                        <h5 className="text-dark mb-0 fw-bold">Description</h5>
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12 mt-4">
                      <div class="border p-4">
                        <p className="mb-0">
                          <div
                            className="textarea-box audit-textarea"
                            dangerouslySetInnerHTML={{
                              __html: descriptionList2,
                            }}
                          ></div>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-2 col-sm-12 mt-4 d-flex align-items-center">
                      <div className="module-title">
                        <h5 className="text-dark mb-0 fw-bold">
                          Observation
                          <label class="field-required"> * </label>
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12 mt-4 audit-editor">
                      <JoditEditor
                        ref={editorObservation}
                        value={commentData}
                        config={config}
                        tabIndex={1}
                        onBlur={(text) => {
                          let temp = description;
                          temp.comment = text;
                          setDescription(temp);
                          setCommentData(text);
                          var e = document.getElementById("audit-1");
                          // e.scrollIntoView({
                          //   block: "end",
                          //   behavior: "smooth",
                          //   inline: "center",
                          // });
                        }}
                        onChange={(text) => { }}
                      ></JoditEditor>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-2 col-sm-12 mt-4 d-flex align-items-center">
                      <div className="module-title">
                        <h5 className="text-dark mb-0 fw-bold issue-present">
                          {" "}
                          Is the issue present?
                        </h5>
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12 mt-4 issue-present">
                      <div className="d-flex justify-content-start align-items-center pe-0">
                        <button
                          className={
                            buttonActive == 0
                              ? "btn btn-danger"
                              : "btn btn-outline-danger"
                          }
                          onClick={() => {
                            clickButtonStatus(0);
                          }}
                        >
                          {" "}
                          Yes{" "}
                        </button>
                        <button
                          className={
                            buttonActive == 1
                              ? "btn btn-success ms-3"
                              : "btn btn-outline-success ms-3"
                          }
                          onClick={() => {
                            clickButtonStatus(1);
                          }}
                        >
                          {" "}
                          No{" "}
                        </button>
                        <button
                          className={
                            buttonActive == 2
                              ? "btn btn-dark ms-3 "
                              : "btn btn-outline-dark ms-3 "
                          }
                          onClick={() => {
                            clickButtonStatus(2);
                          }}
                        >
                          {" "}
                          Not Applicable{" "}
                        </button>
                      </div>
                    </div>
                  </div>

                  {buttonActive == 0 ? (
                    <div className="col-sm-12 mt-5 mb-5 pb-5">
                      <div class="accordion" id="accordion1">
                        <div class="card">
                          <div
                            id="accordion1-collapse1"
                            class={
                              buttonActive == 0
                                ? "collapse show border-red"
                                : buttonActive == 1
                                  ? "collapse show border-green"
                                  : buttonActive == 2
                                    ? "collapse show border-darrk"
                                    : ""
                            }
                            data-parent="#accordion1"
                          >
                            <div class="card-body">
                              <div className="row">
                                {isImpactView ? (
                                  <div className="col-md-12 mt-4 yes-show">
                                    <div className="row">
                                      <div className="col-md-6">
                                        <h5 className="text-dark text-capitalize fw-bold seo-form">
                                          impact
                                        </h5>
                                        <select
                                          class="form-select w-100"
                                          aria-label="Default select example"
                                          value={impact}
                                          onChange={(text) => {
                                            let temp = description;
                                            temp.auditResponse.impact =
                                              text.target.value;
                                            setDescription(temp);
                                            setImpact(text.target.value);
                                          }}
                                        >
                                          <option value="0">Low</option>
                                          <option value="1">Medium</option>
                                          <option value="2">High</option>
                                        </select>
                                      </div>

                                      <div className="col-md-6 col-sm-12">
                                        <h5 className="text-dark text-capitalize fw-bold  seo-form">
                                          priority
                                        </h5>
                                        <select
                                          class="form-select w-100"
                                          aria-label="Default select example"
                                          value={priority}
                                          onChange={(text) => {
                                            let temp = description;
                                            temp.auditResponse.priority =
                                              text.target.value;
                                            setDescription(temp);
                                            setPriority(text.target.value);
                                          }}
                                        >
                                          <option value="0">Low</option>
                                          <option value="1">Medium</option>
                                          <option value="2">High</option>
                                        </select>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-6 col-sm-12 mt-4">
                                        <h5 className="text-dark text-capitalize fw-bold  seo-form">
                                          status
                                        </h5>
                                        <select
                                          class="form-select w-100"
                                          aria-label="Default select example"
                                          value={status1}
                                          onChange={(text) => {
                                            let temp = description;
                                            temp.auditResponse.status =
                                              text.target.value;
                                            setDescription(temp);
                                            setStatus1(text.target.value);
                                          }}
                                        >
                                          <option value="1">Completed</option>
                                          <option value="3">WIP</option>
                                          <option value="4">On Hold</option>
                                          <option value="5">Pending</option>
                                        </select>
                                      </div>
                                      <div className="col-md-6 mt-4 seo-form">
                                        <h5 className="text-dark text-capitalize fw-bold seo-form">
                                          {" "}
                                          Date
                                        </h5>
                                        <input
                                          className="w-100 device-date date-form "
                                          style={{ padding: 9 + "px" }}
                                          type="date"
                                          value={statusUpdateDate}
                                          onChange={(text) => {
                                            let temp = description;
                                            temp.auditResponse.statusUpdatedDate =
                                              text.target.value;
                                            setDescription(temp);
                                            setStatusUpdateDate(
                                              text.target.value
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-sm-12 mt-4">
                                        <div class="form-floating audit-issue-form audit-editor ">
                                          <h5 className="text-dark text-capitalize fw-bold  seo-form">
                                            {" "}
                                            Recommendation
                                            <label class="field-required">
                                              {" "}
                                              *{" "}
                                            </label>
                                          </h5>
                                          <JoditEditor
                                            ref={editorSuggestion}
                                            value={suggestion}
                                            config={config}
                                            tabIndex={1}
                                            onBlur={(text) => {
                                              let temp = description;
                                              temp.auditResponse.suggestion =
                                                text;
                                              setDescription(temp);
                                              setSuggestionData(text);
                                              var e =
                                                document.getElementById(
                                                  "audit-1"
                                                );
                                              // e.scrollIntoView({
                                              //   block: "end",
                                              //   behavior: "smooth",
                                              //   inline: "center",
                                              // });
                                            }}
                                            onChange={(text) => { }}
                                          >
                                            <div className="my-editing-area" />
                                          </JoditEditor>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-sm-12 mt-4">
                                        <div class="form-floating">
                                          <h5 className="text-dark text-capitalize fw-bold  seo-form my-4">
                                            Supporting Documents
                                          </h5>
                                          <input
                                            style={{ display: "none" }}
                                            ref={inputRef}
                                            type="file"
                                            id="LeadsClientDocs"
                                            name="LeadsClientDocs"
                                            inputProps={{
                                              accept: documentFileType,
                                            }}
                                            accept={documentFileType}
                                            multiple={true}
                                            onChange={(item) => {
                                              var input =
                                                document.getElementById(
                                                  "LeadsClientDocs"
                                                );
                                              let fList = leadsClientDocs;
                                              let fileNameList = [],
                                                fileList = [];
                                              for (
                                                var i = 0;
                                                i < input.files.length;
                                                ++i
                                              ) {
                                                fileList.push(
                                                  input.files.item(i).name
                                                );
                                                fList.push(input.files.item(i));
                                              }
                                              setFileList(fileList);
                                              setLeadsClientDocs(fList);
                                              item.target.value = null;
                                            }}
                                          />
                                          <button
                                            className="form-control mb-2 bug-choosefile"
                                            onClick={(item) => {
                                              inputRef.current.click();
                                            }}
                                          >
                                            Choose file
                                          </button>
                                          {leadsClientDocs &&
                                            leadsClientDocs.map(
                                              (item, index) => {
                                                return (
                                                  <div className="d-flex justify-content-between align-items-center report-file w-50">
                                                    <span className="bug-file">
                                                      {item.name}
                                                    </span>
                                                    <i
                                                      onClick={() => {
                                                        setFileList(
                                                          fileList.filter(
                                                            (e) => e !== item
                                                          )
                                                        );
                                                        if (index > -1) {
                                                          leadsClientDocs.splice(
                                                            index,
                                                            1
                                                          );
                                                        }
                                                        setLeadsClientDocs(
                                                          leadsClientDocs
                                                        );
                                                      }}
                                                      class="fa fa-times"
                                                      style={{
                                                        color: "#757575",
                                                      }}
                                                    ></i>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                        {supportingDocs?.map((i, index) => {
                                          return (
                                            <div
                                              class="mt-3 w-50 d-flex justify-content-between align-items-center"
                                              onClick={() => { }}
                                            >
                                              <a
                                                target="_blank"
                                                href={
                                                  GET_Server_URL + i.documents
                                                }
                                              >
                                                {i?.documents}
                                              </a>
                                              <i
                                                class="fa fa-trash ms-3"
                                                onClick={() => {
                                                  deleteDocumentsById(
                                                    i.id,
                                                    index
                                                  );
                                                }}
                                              ></i>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="row">
                  <div className="col-sm-12 pe-0 d-block text-center">
                    <div className="text-danger">{errorMessage}</div>
                    <button
                      className="btn btn-primary me-3"
                      onClick={() => {
                        if (description.auditResponseValue == null) {
                          setErrorMessage("Set issue status.");
                        } else if (
                          commentData == "" ||
                          commentData == null ||
                          commentData == "<p></br></p>"
                        ) {
                          setErrorMessage("Observation is required.");
                        } else if (
                          description.auditResponseValue == 0 &&
                          (description.auditResponse.suggestion == "" ||
                            description.auditResponse.suggestion == null ||
                            description.auditResponse.suggestion ==
                            "<p></br></p>")
                        ) {
                          setErrorMessage("Suggestion is required.");
                        } else {
                          setErrorMessage("");
                          updateAudit(description);
                        }
                      }}
                    >
                      Submit
                    </button>
                    {isAuditCompletedActive ? (
                      <button
                        className="btn btn-primary "
                        onClick={() => {
                          completeSEOAudit();
                        }}
                      >
                        Complete
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-12">
                    <Table
                      className="table table-bordered  table-hover mt-3"
                      style={{
                        visibility: "hidden",
                      }}
                      id="audit-1"
                      columns={[]}
                      dataSource={[]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <>{getComponent()}</>
        )}
      </div>
    </>
  );
}

export default ModuleExpandSEOAuditSelection;
