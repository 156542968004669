import { Input, Table } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import { Link } from 'react-router-dom';
import { Sorter } from '../../../common/Sorter';
import APIManager from '../../../services/APIManager';
import {
    deleteAllKeyWord,
    deleteAllRankingkeyword,
    deletekeywordById,
    documentFileType,
    getcampaignKeyword,
    GET_Server_URL,
    importrankingcampaignKeyword
} from '../../../utility/Constant';

import CampaignaddKeyword from './CampaignaddKeyword';

function ViewKeywords(props) {

    const keywordtype = [
        {
            value: "0",
            lable: "Brand",
        },
        {
            value: "1",
            lable: "Non Brand ",
        },
        // {
        //     value: "2",
        //     lable: "All",
        // },
    ]

    const [KeywordTabcol, setKeywordTabcol] = useState([]);
    const [tabkeywordlist, settabkeywordList] = useState([]);
    const [Totalkeyword, setTotalKeyword] = useState(0);
    const [Selectedkeyword, setSelectedkeyword] = useState(0);
    const [selectedKeyExportList, setSelectedKeyExportList] = useState([]);
    const [keyexportList, setKeyExportList] = useState([]);
    const [KeywordTemp, setKeywordTemp] = useState([]);
    const [keywordId, setkeywordId] = useState("");
    const [fieldValue, SetfieldValue] = useState("");
    const [searchVolume, setSearchVolume] = useState("");
    const [keywordlist, setKeywordlist] = useState("");
    const [categoryName, setCategoryName] = useState("");
    const [SelectedkeywordCheckList, setSelectedkeywordkList] = useState([]);
    const [type, setType] = useState("");
    const [settings, setSetting] = useState("");
    const [listOfKeywords, setlistOfKeywords] = useState(null);
    const [ConfigId, setConfigId] = useState("");
    const [keywordCount, setkeywordCount] = useState("");
    const [credits, setcredits] = useState("");
    const [projectCredits, setProjectCredits] = useState("");

    useEffect(() => {
        var columns = [
            {
                title: "Keyword",
                dataIndex: "keyword",
                key: "keyword",
                sorter: {
                    compare: (a, b) =>
                        Sorter.DEFAULT(
                            a.keyword.toLocaleLowerCase(),
                            b.keyword.toLocaleLowerCase()
                        ),
                    multiple: 3,
                },
            },
            {
                title: "Category",
                dataIndex: "categoryName",
                key: "categoryName",
                width: "15%",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.categoryName, b.categoryName),
                    multiple: 3,
                },
            },
            {
                title: "Type",
                dataIndex: "type",
                key: "type",
                width: "15%",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.type, b.type),
                    multiple: 3,
                },
            },
            {
                title: "Priority",
                dataIndex: "Priority",
                key: "Priority",
                width: "15%",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.Priority, b.Priority),
                    multiple: 3,
                },
            },
            {
                title: "Search Volume",
                dataIndex: "searchVolume",
                key: "searchVolume",
                width: "15%",
                sorter: {
                    compare: (a, b) => Sorter.DEFAULT(a.searchVolume, b.searchVolume),
                    multiple: 3,
                },
            },
            {
                title: "Action",
                dataIndex: "delete",
                key: "delete",
                width: "9%",
            },
        ];
        setKeywordTabcol(columns);
    }, []);

    const handleChangeOpen = (val) => {
        props.isOpen(val);
    };

    useEffect(() => {
        setConfigId(props?.campId);
        getKeywordlist();
    }, [props?.campId])

    const keyrowSelection = {
        onChange: (selectedRowKeys, selectedRows, info) => {
            if (selectedRows?.length > 1) {
                localStorage.setItem("selectionInfoType", "all");
                const list = [];
                selectedRowKeys = [];
                tabkeywordlist.map((item) => {
                    selectedRowKeys.push(item.key);
                    let obj = {
                        key: item.key,
                        count: item.count,
                        keyword: item.keyword,
                        searchVolume: item.searchVolume,
                        categoryName: item.categoryName,
                        type: item.type,
                    };
                    list.push(obj);
                });
                setSelectedKeyExportList(list);
            } else {
                localStorage.setItem("selectionInfoType", info.type);
                const list = [];
                selectedRowKeys = [];
                selectedRows.map((item) => {
                    selectedRowKeys.push(item.key);
                    let obj = {
                        key: item.key,
                        keyword: item.keyword,
                        searchVolume: item.searchVolume,
                        categoryName: item.categoryName,
                        type: item.type,
                    };
                    list.push(obj);
                });
                setSelectedKeyExportList(list);
            }
            const list1 = [];
            selectedRows.map((item) => {
                list1.push(item.id);
            });
            setSelectedkeywordkList(list1);
            if (info.type == "all") {
                setSelectedkeyword(tabkeywordlist.length);
            } else {
                setSelectedkeyword(list1.length);
            }
        },
    };

    function getKeywordTypeName(val) {
        var finalVal = val;
        keywordtype.map((i) => {
            if (val == i.value) {
                finalVal = i.lable;
            }
        });
        return finalVal;
    }


    function getKeywordlist() {
        APIManager.getAPI(
            getcampaignKeyword + props?.campId,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                const list = [];
                let c = 1;
                const exportList = [];
                response.data.rankingCampaignKeywords.map((item) => {
                    exportList.push({
                        key: item.id,
                        keyword: item.keyword,
                        searchVolume: item.searchVolume,
                        categoryName: item.categoryName,
                        Priority: item.priority == 1 ? "Yes" : "No",
                        type: item.keywordType,
                    });

                    list.push({
                        count: c,
                        key: item.id,
                        value: item.keyword,
                        label: item.keyword,
                        id: item.id,
                        keyword: item.keyword,
                        searchVolume: item.searchVolume,
                        categoryName: item.keywordCategory.categoryType,
                        type: getKeywordTypeName(item.types),
                        Priority: item.priority == 1 ? "Yes" : "No",
                        delete: (
                            <div className="d-flex text-centet">
                                <div
                                    onClick={() => {
                                        setlistOfKeywords(item)
                                        setSetting("addUpdateCampaignKeywords");
                                    }}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        class="feather feather-edit edit-icon me-3"
                                        onClick={() => {
                                            setkeywordId(item.id);
                                            SetfieldValue(item.fieldValue);
                                            setSearchVolume(item.searchVolume);
                                            setCategoryName(item.categoryName);
                                            setType(item.type);
                                            handleChangeOpen(true);
                                        }}
                                    >
                                        <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                                        <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                                    </svg>
                                </div>

                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-trash-2 trash-icon mt-1"
                                    onClick={() => {
                                        deletekeywordItem(item.id);
                                    }}
                                >
                                    <polyline points="3 6 5 6 21 6"></polyline>
                                    <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                    <line x1="10" y1="11" x2="10" y2="17"></line>
                                    <line x1="14" y1="11" x2="14" y2="17"></line>
                                </svg>
                            </div>
                        ),
                    });
                    c++;
                });
                settabkeywordList(list);
                setKeywordTemp(list);
                setKeyExportList(exportList);
                setKeywordlist(list);
                setTotalKeyword(list.length);
                setkeywordCount(response.data?.keywordsCount[0]?.keywordsCount);
                setcredits(response.data?.keywordsCount[0]?.credits);
                setProjectCredits(response.data?.keywordsCount[0]?.projectCredit ?? 0);
            }
        });
    }

    async function deletekeywordItem(id) {
        try {
            const response = await APIManager.deleteAPI(deletekeywordById + id);
            if (response == 200) {
                getKeywordlist();
            }
        } catch (e) { }
    }

    function filterDataFromList(textData) {
        settabkeywordList(
            KeywordTemp.filter((person) =>
                person.keyword
                    .toLocaleLowerCase()
                    .includes(textData.toLocaleLowerCase())
            )
        );
    }

    function uploadkeywordFile() {
        var input = document.getElementById("keywordFile");
        keyWordimport(input.files.item(0));
    }

    function keyWordimport(file) {
        inputFileRef.current.value = null;
        var bodyFormData = new FormData();
        bodyFormData.append("file", file);
        APIManager.postAPI(
            importrankingcampaignKeyword + props?.campId,
            {},
            bodyFormData,
            false
        ).then(() => {
            getKeywordlist();
        });
    }

    function deleteMultikeywordId() {
        return new Promise((resolve) => {
            APIManager.postAPIForDelete(
                deleteAllRankingkeyword,
                SelectedkeywordCheckList,
                {},
                true
            ).then((response) => {
                if (response && response.status == 200) {
                    getKeywordlist();
                    setSelectedkeywordkList(null);
                    if (response.status === 200)
                        if (response?.data?.message) {
                        } else {
                            // notifyDelete();
                        }
                    resolve(response.status);
                }
            });
        });
    }

    function deleteMultipleKeyWord() {
        return new Promise((resolve) => {
            APIManager.deteletItem(
                deleteAllKeyWord + localStorage.getItem("projectGId")
            ).then((response) => {
                if (response && response.status == 200) {
                    getKeywordlist();
                    if (response.status === 200)
                        if (response?.data?.message) {
                        } else {
                            // notifyDelete();
                        }
                    resolve(response.status);
                }
            });
        });
    }

    function submit() {
        confirmAlert({
            title: "Confirm to Delete",
            message:
                Selectedkeyword +
                " of " +
                Totalkeyword +
                " keywords selected will be deleted.",
            buttons: [
                {
                    label: "Delete",
                    onClick: () => {

                        if (localStorage.getItem("selectionInfoType") !== "all") {
                            deleteMultipleKeyWord();
                        } else {
                            deleteMultikeywordId();
                        }
                    },
                },
                {
                    label: "Cancel",
                },
            ],
        });
    }

    const inputFileRef = useRef(null);
    const onBtnClick = () => {
        inputFileRef.current.click();
    };
    let onClickFunction = (arg) => {
        setSetting(arg);
        handleChangeOpen(false);
        getKeywordlist();
    };
    function getComponent() {
        if (settings == "addUpdateCampaignKeywords") {
            return <CampaignaddKeyword items={listOfKeywords} configId={props?.campId} update={onClickFunction} />;
        }
    }

    return (
        <>
            <div className="custom-column-70">
                <div className="portlet border-0 mb-0 pb-0">
                    <div class="portlet-header portlet-header-bordered border-0">
                        <h3 class="portlet-title main-title">
                            Add Keywords
                        </h3>
                    </div>
                    <div className="portlet-body">
                        {!settings ? (
                            <>
                                <div className="d-flex px-2 mx-3 my-3 keyword-points">
                                    <span>Keyword Added :</span>
                                    <p className='fw-bold ms-2 mb-0 text-dark'>{keywordCount}</p>

                                    <span class="ms-5">Credit Required :</span>
                                    {credits > projectCredits ? (
                                        <p className='fw-bold ms-2 mb-0 text-dark keyword-msg'><span className='fw-bold'>{credits}</span> / {projectCredits}</p>
                                    ) : (
                                        <p className='fw-bold ms-2 mb-0 text-dark'>{credits} / {projectCredits}</p>
                                    )}
                                </div>
                                {credits > projectCredits ? (
                                    <div className='keyword-msg ms-3 ps-1'>
                                        <span>Note: You have exceeded the maximum credit limit assigned to this project</span>
                                    </div>) :
                                    (<></>)
                                }
                                <div className="row mt-3 px-2">
                                    <div
                                        className="col-md-3 col-sm-12 keyword-search d-flex justify-content-start align-items-center">
                                        <div class="input-group-icon input-group-lg widget15-compact">
                                            <div class="input-group-prepend">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                                            </div>
                                            <Input
                                                type="text"
                                                style={{ padding: 7 + "px" }}
                                                class="form-control"
                                                placeholder="Type to search"
                                                allowClear
                                                onChange={(nameSearch) => {
                                                    filterDataFromList(nameSearch.target.value);
                                                }}
                                                id="input-s"
                                            />
                                        </div>
                                    </div>{" "}
                                    <div className="col-md-9 d-flex justify-content-end align-items-center tab-panel-url-options keyword-tab">
                                        <button
                                            disabled={!SelectedkeywordCheckList?.length}
                                            class="btn btn-primary"
                                            onClick={() => {
                                                submit();
                                            }}
                                        >
                                            Delete
                                        </button>
                                        <div>
                                            <div class="data-export-client">
                                                <Link
                                                    class="btn btn-primary ms-3 add"
                                                    to={{
                                                        state: { isEdit: false },
                                                    }}
                                                    onClick={() => {
                                                        setlistOfKeywords(null);
                                                        setSetting("addUpdateCampaignKeywords");
                                                        handleChangeOpen(true);
                                                    }}
                                                >
                                                    Add
                                                </Link>
                                            </div>
                                        </div>
                                        <span class="export me-0 ">
                                            <input
                                                style={{ display: "none" }}
                                                type="file"
                                                id="keywordFile"
                                                name="keywordFile"
                                                multiple={false}
                                                accept={documentFileType}
                                                ref={inputFileRef}
                                                onChange={uploadkeywordFile}
                                            />

                                            <button
                                                class="btn btn-primary import mx-3"
                                                style={{ width: 70 + "px" }}
                                                onClick={onBtnClick}
                                            >
                                                Import
                                            </button>
                                            <a
                                                class="btn btn-outline download-temp "
                                                target="_blank"
                                                href={GET_Server_URL + "audit/RankingKeyword.xlsx"}
                                            >
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                                <span class="ms-1"> Download Template</span>
                                            </a>
                                        </span>
                                    </div>
                                </div>
                                <div className="ranking-campaing-table table-bordered border-0 mt-4 pt-2">
                                    <div className="col-md-12">
                                        <Table
                                            className="table table-hover keyword-table"
                                            id="url"
                                            columns={KeywordTabcol}
                                            dataSource={tabkeywordlist}
                                            rowSelection={{
                                                type: "checkbox",
                                                ...keyrowSelection,
                                            }}
                                            pagination={{
                                                defaultPageSize: 100,
                                                position: ["bottomRight"],
                                                showSizeChanger: true,
                                                showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} Keywords`
                                            }}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>{getComponent()}</>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewKeywords