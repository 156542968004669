import React, { useEffect, useState } from "react";
import { ProgressBar, Step } from "react-step-progress-bar";
import APIManager from "../../services/APIManager";
import { createClient, getCountry } from "../../utility/Constant";
import { StatusList } from "../../utility/Globals";

const steps = [
  {
    status: "Appointment Scheduled",
  },
  {
    status: "Qualified to Buy",
  },
  {
    status: "Presentation Scheduled",
  },
  {
    status: "Decision maker Bought-In",
  },
  {
    status: "contract Sent",
  },
  {
    status: "Closed Won",
  },
  {
    status: "Closed Lost",
  },
];

const AddLeads = ({ update }) => {
  useEffect(() => {
    getCountryList();
  }, []);

  const [name, setName] = useState("");
  const [keyPOC, setKeyPOC] = useState("");
  const [status, setStatus] = useState(0);
  const [country, setCountry] = useState("");
  const [countrySelected, setCountrySelected] = useState("India");
  const [countryList, setCountryList] = useState([]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [save, setsave] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [errors, setErrors] = useState("");

  function getCountryList() {
    APIManager.getAPI(getCountry).then((response) => {
      if (response && response.status == 200) {
        setCountryList(response.data.data);
      }
    });
  }
  function setcountry(e) {
    var c = e.target.value;
    setCountrySelected(c);
  }

  const [filelist, setfilelist] = useState([]);
  const [leadsClientDocs, setLeadsClientDocs] = useState([]);

  function updateLeadsClientDocs() {
    var input = document.getElementById("LeadsClientDocs");
    const a = [];
    for (var i = 0; i < input.files.length; ++i) {
      a.push(input.files.item(i).name);
    }
    setLeadsClientDocs(a);
  }

  const [leadClientStatusselected, setleadClientStatusselected] = useState("0");

  function onChangeStatus(e) {
    setleadClientStatusselected(e.target.value);
  }

  function createLeadClient() {
    var bodyFormData = new FormData();
    bodyFormData.append("Name", name.trim());
    bodyFormData.append("KeyPOC", keyPOC.trim());
    bodyFormData.append("Status", leadClientStatusselected);
    bodyFormData.append("Active", isAccActive);
    bodyFormData.append("Country", countrySelected);
    bodyFormData.append("State", state.trim());
    bodyFormData.append("City", city.trim());
    bodyFormData.append("PhoneNumber", phoneNumber);
    bodyFormData.append("Email", email);
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );
    bodyFormData.append("CreatedBy", localStorage.getItem("userId"));
    if (filelist != "") {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.File", filelist);
    }
    APIManager.postAPI(createClient, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status == 200) {
          clearForm();
        }
      }
    );
  }

  function formValidation() {
    let error = { ...errors };
    name.trim() == ""
      ? (error["name"] = "Client name is required!")
      : delete error["name"];

    if (name.trim()?.length) {
      var pattern = new RegExp(/^[a-zA-Z\s]*$/);

      !pattern.test(name.trim())
        ? (error["name"] = "Please enter valid client name!")
        : delete error["name"];
    }

    keyPOC.trim() == ""
      ? (error["keyPOC"] = "Key point of contact is required!")
      : delete error["keyPOC"];

    email.trim() == ""
      ? (error["email"] = "Email is required!")
      : delete error["email"];

    if (email?.length) {
      var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

      if (pattern?.test(email) == false) {
        error["email"] = "Please enter valid Email!";
      }
    }

    phoneNumber.trim() == ""
      ? (error["phoneNumber"] = "Phone number is required!")
      : delete error["phoneNumber"];

    if (phoneNumber?.length) {
      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(phoneNumber)) {
        error["phoneNumber"] = "Please enter only number!";
      } else if (phoneNumber.length >= 15) {
        error["phoneNumber"] =
          "Phone number must not exceed more than 15 characters!";
      } else if (phoneNumber.length < 10) {
        error["phoneNumber"] =
          "Phone number must not exceed less than 10 characters!";
      } else {
        delete error["phoneNumber"];
      }
    }

    countrySelected.trim() == ""
      ? (error["countrySelected"] = "Please select country!")
      : delete error["countrySelected"];

    setErrors(error);

    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setName("");
    setKeyPOC("");
    setStatus("");
    setCountry("");
    setState("");
    setCity("");
    setPhoneNumber("");
    setEmail("");
    setCreatedBy("");
    setIsActiveAcc(false);
    update("");
  }

  const [isAccActive, setIsActiveAcc] = useState(false);

  return (
    <>
      <div className="portlet mt-3 slideInUp leads">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 className="portlet-title main-title">
            {" "}
            {localStorage.getItem("organizationName")} - Leads
          </h3>
        </div>
        <div className="portlet-body">
          <div
            className="progress-bar-lead-add mt-3"
            style={{
              marginTop: 25,
              marginRight: 50,
              marginBottom: 100,
              marginLeft: 50,
            }}
          >
            <ProgressBar
              percent={(100 / 6) * (leadClientStatusselected - 3)}
              filledBackground="linear-gradient(to right, #41ad49, #41ad49)"
            >
              {steps.map((step, index, arr) => {
                return (
                  <Step
                    transition="scale"
                    children={({ accomplished }) => (
                      <div
                        style={{
                          borderRadius: "50%",
                          width: 20,
                          height: 20,
                          color: "black",
                          backgroundColor: accomplished ? "green" : "gray",
                        }}
                      >
                        <br />
                        <br />
                        <div className="step-status">{step.status}</div>
                      </div>
                    )}
                  />
                );
              })}
            </ProgressBar>
          </div>

          <div class="common-wrapper">
            <div class="common-wcard">
              <div class="common-form-fields">
                <div class="add-user w-100">
                  <div class="col-md-12">
                    <label className="form-label">Client</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      required
                      name=""
                      placeholder="Enter Client"
                      value={name}
                      onChange={(text) => {
                        setName(text.target.value);
                        formValidation();
                      }}
                    />
                  </div>
                  {save && errors?.name && (
                    <>
                      <div className="field-required">
                        <span> {errors?.name}</span>
                      </div>
                    </>
                  )}

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Key Point of Contact</label>
                    <label class="field-required"> * </label>
                    <input
                      type="text"
                      name=""
                      required
                      placeholder="Enter Key Point of Contact"
                      value={keyPOC}
                      onChange={(text) => {
                        setKeyPOC(text.target.value);
                        formValidation();
                      }}
                    />
                  </div>
                  {save && errors?.keyPOC && (
                    <>
                      <div className="field-required">
                        <span> {errors?.keyPOC}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Status</label>
                    <select
                      value={leadClientStatusselected}
                      onChange={onChangeStatus}
                    >
                      {StatusList.map((item) => {
                        return <option value={item.value}>{item.label}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Country</label>
                    <label class="field-required"> * </label>
                    <select
                      required
                      value={countrySelected}
                      onChange={setcountry}
                    >
                      {countryList.map((item) => {
                        return <option value={item.name}>{item.name}</option>;
                      })}
                    </select>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">State</label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter State"
                      value={state}
                      onChange={(text) => {
                        setState(text.target.value);
                      }}
                    />
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">City</label>
                    <input
                      type="text"
                      name=""
                      placeholder="Enter City"
                      value={city}
                      onChange={(text) => {
                        setCity(text.target.value);
                      }}
                    />
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Phone Number</label>
                    <label class="field-required"> * </label>
                    <br />
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        required
                        placeholder="Enter Phone Number"
                        value={phoneNumber}
                        onChange={(text) => {
                          setPhoneNumber(text.target.value);
                          formValidation();
                        }}
                      />
                    </div>
                  </div>
                  {save && errors?.phoneNumber && (
                    <>
                      <div className="field-required">
                        <span> {errors?.phoneNumber}</span>
                      </div>
                    </>
                  )}
                  <div class="col-md-12 mt-3">
                    <label className="form-label">Email</label>
                    <br />
                    <div style={{ display: "flex" }}>
                      <input
                        type="text"
                        required
                        placeholder="Enter Email"
                        value={email}
                        onChange={(text) => {
                          setEmail(text.target.value);
                          formValidation();
                        }}
                      />
                    </div>
                  </div>
                  {save && errors?.email && (
                    <>
                      <div className="field-required">
                        <span> {errors?.email}</span>
                      </div>
                    </>
                  )}
                  <div className="col-md-12 mt-3">
                    <label className="form-label">Account Activation</label>
                    <br />
                    <label class="switch">
                      <input
                        type="checkbox"
                        checked={isAccActive}
                        onClick={() => {
                          setIsActiveAcc(!isAccActive);
                        }}
                      />
                      <span class="slider round"></span>
                    </label>
                  </div>

                  <div class="col-md-12 mt-3">
                    <label className="form-label">Document Upload </label>
                    <br />
                    <input
                      type="file"
                      id="LeadsClientDocs"
                      name="LeadsClientDocs"
                      multiple={false}
                      onChange={updateLeadsClientDocs}
                    />
                    <label id="fileLabel">
                      {leadsClientDocs.length > 0
                        ? `${leadsClientDocs.length} files`
                        : ""}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered mb-4">
          <div class="col-md-12">
            <button
              className="btn btn-primary me-3"
              onClick={() => {
                setsave(true);
                if (formValidation()) {
                  createLeadClient();
                }
              }}
            >
              Save
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                clearForm();
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLeads;
