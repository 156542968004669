import React from "react";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { ModuleExpandTickets } from "./index";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import APIManager from "../services/APIManager";
import {
  calanderDate,
  // getCheckPermission,
  mmDDyyyyFormateDateSet,
} from "../utility/Globals";
import { getOrganic } from "../utility/Constant";
import { exportInToCSV, exportToEXCEL } from "../utility/Globals";
import { Sorter } from "../common/Sorter";

function ModuleExpandOrganicResearch() {
  const [QueryORTabOptions, setQueryORTabOptions] = useState([]);

  const [ORtabtable, setORtabtable] = useState([]);
  const [ORTableCol, setORTableCol] = useState([]);

  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [keywords, setkeywords] = useState([]);

  const [selectedKeyExportList, setSelectedKeyExportList] = useState([]);
  const [keyexportList, setKeyExportList] = useState([]);

  const [selectedOrgExportList, setSelectedOrgExportList] = useState([]);
  const [orgexportList, setOrgExportList] = useState([]);
  const [refreshTicketData, setRefreshTicketData] = useState(false);

  useEffect(() => {
    var data = [
      { label: "Query", value: "Query" },
      { label: "Page", value: "Page" },
      { label: "Country", value: "Country" },
      { label: "Device", value: "Device" },
      { label: "Search Appearence", value: "Search Appearence" },
      { label: "Date", value: "Date" },
    ];
    data = [];
    setteamList(data);
    const col = [
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },
      {
        title: "Traffic",
        dataIndex: "traffic",
        key: "traffic",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.traffic, b.traffic),
          multiple: 3,
        },
      },
      {
        title: "Traffic %",
        dataIndex: "traff",
        key: "traff",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.traff, b.traff),
          multiple: 3,
        },
      },
      {
        title: "Keywords",
        dataIndex: "keywords",
        key: "keywords",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keywords, b.keywords),
          multiple: 3,
        },
      },
      {
        title: "Ads Keywords",
        dataIndex: "ads",
        key: "ads",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.ads, b.ads),
          multiple: 3,
        },
      },
    ];
    setteamcol(col);

    setQueryORTabOptions(data);
    var columns = [
      {
        title: "Keyword",
        dataIndex: "keyword",
        key: "keyword",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.keyword, b.keyword),
          multiple: 3,
        },
      },
      {
        title: "SF",
        dataIndex: "sf",
        key: "sf",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.sf, b.sf),
          multiple: 3,
        },
      },
      {
        title: "Pos.",
        dataIndex: "pos",
        key: "pos",
        sorter: {
          compare: (a, b) => (a.pos, b.pos),
          multiple: 3,
        },
      },
      {
        title: "Diff.",
        dataIndex: "diff",
        key: "diff",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.diff, b.diff),
          multiple: 3,
        },
      },
      {
        title: "Traffic%",
        dataIndex: "traffic",
        key: "traffic",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.traffic, b.traffic),
          multiple: 3,
        },
      },
      {
        title: "Volume",
        dataIndex: "volume",
        key: "volume",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.volume, b.volume),
          multiple: 3,
        },
      },
      {
        title: "KD%",
        dataIndex: "kd",
        key: "kd",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.kd, b.kd),
          multiple: 3,
        },
      },
      {
        title: "CPC (USD)",
        dataIndex: "cpc",
        key: "cpc",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.cpc, b.cpc),
          multiple: 3,
        },
      },
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },

      {
        title: "Upd.",
        dataIndex: "upd",
        key: "upd",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.upd, b.upd),
          multiple: 3,
        },
      },
    ];
    setORTableCol(columns);

    setORtabtable(data);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          key: item.key,
          keyword: item.keyword,
          sf: item.sf,
          pos: item.pos,
          diff: item.diff,
          traffic: item.traffic,
          volume: item.volume,
          kd: item.kd,
          cpc: item.cpc,
          url: item.url,
          upd: item.upd,
        };
        list.push(obj);
      });
      setSelectedKeyExportList(list);
    },
  };

  const orgrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      let i = 0;
      selectedRows.map((item) => {
        let obj = {
          key: (i = +1),
          url: item.url,
          traffic: item.traffic,
          traff: item.traff,
          keywords: item.keywords,
          ads: item.ads,
        };
        list.push(obj);
      });
      setSelectedOrgExportList(list);
    },
  };

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());
  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  useEffect(() => {
    let getdata = {
      StartDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2),
      projectId: localStorage.getItem("projectGId"),
    };
    createorganicResearchSearch(getdata);
  }, []);

  function createorganicResearchSearch(rowdata) {
    APIManager.postAPI(getOrganic, rowdata, null, true).then((response) => {
      if (response && response.status == 200) {
        const list0 = [];
        response.data.organicResearchSummaries.map((item) => {
          let obj = {
            keywords: item.fieldName,
            fieldValue: item.fieldValue,
            percentage: item.percentage,
          };
          list0.push(obj);
        });
        setkeywords(list0);

        const list1 = [];
        response.data.organicResearchModules.map((item) => {
          let obj = {
            key: item.id,
            keyword: item.keyword,
            sf: item.sf,
            pos: item.pos,
            diff: item.diff,
            traffic: item.traffic,
            volume: item.volume,
            kd: item.kd,
            cpc: item.cpc,
            url: item.url,
            upd: item.updatedAt,
          };
          list1.push(obj);
        });
        setORtabtable(list1);
        setKeyExportList(list1);
        const list2 = [];
        response.data.organicResearchPages.map((item) => {
          let obj2 = {
            key: item.id,
            url: item.url,
            traffic: item.traffic,
            traff: item.trafficPercentage,
            keywords: item.keywords,
            ads: item.adsKeywords,
          };
          list2.push(obj2);
        });
        setteamList(list2);
        setOrgExportList(list2);
      }
    });
  }

  const [Organicesearch, setOrganicesearch] = useState(false);

  return (
    <>
      <div className="row">
        <div ref={ref} class="calendar-main">
          <div className="add-new-btnw">
            <button
              className="btn btn-primary"
              style={{ width: "250px" }}
              onClick={() => setOpen(!open)}
            >
              {calanderDate(head1, head2)}
            </button>
          </div>

          {open && (
            <div id="example-collapse-text-calendar">
              <Card body className="daterange-picker-card  mt-2">
                <div className="d-flex justify-content-between">
                  <div className="calendar-col">
                    <Calendar
                      className="custom-calendar"
                      value={values}
                      onChange={(e) => {
                        setValues(e);
                        setHead1(e[0].format());
                        setHead2(e[1].format());
                      }}
                      range
                      numberOfMonths={1}
                      showOtherDays
                    />
                  </div>
                  <div className="ms-3">
                    <button
                      onClick={() => {
                        setheadvalues();
                        setOpen(!open);
                        let getdata = {
                          StartDate: mmDDyyyyFormateDateSet(head1),
                          EndDate: mmDDyyyyFormateDateSet(head2),
                          projectId: localStorage.getItem("projectGId"),
                        };
                        createorganicResearchSearch(getdata);
                        setRefreshTicketData(true);
                        setTimeout(() => {
                          setRefreshTicketData(false);
                        }, 1000);
                      }}
                      className="btn-outline btn btn-primary"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
        <Tabs>
          <TabList className="tab-buttons mb-4 mt-5">
            <Tab
              className={
                Organicesearch ? "btn btn-outline-primary" : "btn btn-primary"
              }
              onClick={() => {
                setOrganicesearch(false);
              }}
            >
              Organic Research
            </Tab>
            <Tab
              className={
                Organicesearch ? "btn btn-primary" : "btn btn-outline-primary"
              }
              onClick={() => {
                setOrganicesearch(true);
              }}
            >
              Tickets
            </Tab>
          </TabList>
          <TabPanel>
            <div class="container ps-0">
              <div className="row">
                <div className="col  mt-4 mb-4">
                  <div className="bg-light-blue p-3 text-black font-weight-bolder text-center or-box">
                    Keywords
                    <h1>
                      <strong>{keywords[0]?.fieldValue}</strong>
                    </h1>
                    <p>{keywords[0]?.percentage}</p>
                  </div>
                </div>
                <div className="col  mt-4 mb-4">
                  <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                    Traffic
                    <h1>
                      <strong>{keywords[1]?.fieldValue}</strong>
                    </h1>
                    <p>{keywords[1]?.percentage}</p>
                  </div>
                </div>
                <div className="col  mt-4 mb-4">
                  <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                    Traffic Cost
                    <h1>
                      <strong>{keywords[2]?.fieldValue}</strong>
                    </h1>
                    <p>{keywords[2]?.percentage}</p>
                  </div>
                </div>
                <div className="col  mt-4 mb-4">
                  <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                    Branded traffic
                    <h1>
                      <strong>{keywords[3]?.fieldValue}</strong>
                    </h1>
                    <p>{keywords[3]?.percentage}</p>
                  </div>
                </div>
                <div className="col  mt-4 mb-4">
                  <div className="bg-light-blue p-3 text-black  font-weight-bolder text-center  or-box">
                    Non-Branded traffic
                    <h1>
                      <strong>{keywords[4]?.fieldValue}</strong>
                    </h1>
                    <p>{keywords[4]?.percentage}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-right mt-4">
              <h4 className="table-title">Keywords</h4>
              <div class="add-new-btnw">
                <span class="dropdown">
                  {/* {getCheckPermission("export", "modules") ? ( */}
                    <button
                      class="btn btn-primary dropdown-toggle ms-3"
                      type="button"
                      onClick={() => {
                        exportToEXCEL(
                          selectedKeyExportList?.length > 0
                            ? selectedKeyExportList
                            : keyexportList,
                          "Keywords"
                        );
                      }}
                    >
                      Export
                    </button>
                  {/* ) : (
                    <></>
                  )} */}
                  {/* <div
                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      onClick={() => {
                        exportToEXCEL(
                          selectedKeyExportList?.length > 0
                            ? selectedKeyExportList
                            : keyexportList,
                          "Keywords"
                        );
                      }}
                    >
                      <a class="dropdown-item">Excel</a>
                    </div>
                    <div
                      onClick={() => {
                        exportInToCSV(
                          selectedKeyExportList?.length > 0
                            ? selectedKeyExportList
                            : keyexportList,
                          "Keywords"
                        );
                      }}
                    >
                      <a class="dropdown-item">CSV</a>
                    </div>
                  </div> */}
                </span>
              </div>
            </div>
            <div>
              <Table
                className="table table-bordered table-hover mt-3"
                id="datatable-1"
                columns={ORTableCol}
                dataSource={[...ORtabtable]}
                rowSelection={{ type: "checkbox", ...rowSelection }}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                }}
              />
            </div>

            <div className="d-flex justify-content-between mt-3">
              <h4 className="table-title">Organic Pages</h4>
              <div class="add-new-btnw">
                <span class="dropdown">
                  {/* {getCheckPermission("export", "modules") ? ( */}
                    <button
                      class="btn btn-primary dropdown-toggle ms-3"
                      type="button"
                      onClick={() => {
                        exportToEXCEL(
                          selectedOrgExportList?.length > 0
                            ? selectedOrgExportList
                            : orgexportList,
                          "Organic Pages"
                        );
                      }}
                    >
                      Export
                    </button>
                  {/* ) : (
                    <></>
                  )} */}
                  {/* <div
                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      onClick={() => {
                        exportToEXCEL(
                          selectedOrgExportList?.length > 0
                            ? selectedOrgExportList
                            : orgexportList,
                          "Organic Pages"
                        );
                      }}
                    >
                      <a class="dropdown-item">Excel</a>
                    </div>
                    <div
                      onClick={() => {
                        exportInToCSV(
                          selectedOrgExportList?.length > 0
                            ? selectedOrgExportList
                            : orgexportList,
                          "Organic Pages"
                        );
                      }}
                    >
                      <a class="dropdown-item">CSV</a>
                    </div>
                  </div> */}
                </span>
              </div>
            </div>
            <div>
              <Table
                className="table table-bordered table-hover mt-3"
                id="datatable-1"
                columns={teamcol}
                dataSource={teamlist}
                rowSelection={{ type: "checkbox", ...orgrowSelection }}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                }}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <ModuleExpandTickets
              startDate={head1}
              endDate={head2}
              refreshTicketData={refreshTicketData}
            />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
}

export default ModuleExpandOrganicResearch;
