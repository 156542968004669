import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

import {
  calanderDate,
  downloadPDFfile,
  exportInToCSV,
  exportToEXCEL,
  getBase64path,
  // getCheckPermission,
  mmDDyyyyFormateDate,
  mmDDyyyyFormateForForm,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import {
  addsiteupmonitor,
  getsiteupgetfielddata,
  getSiteUptimeMonitorGetConfig,
  getUrlProjectID,
  moduleSiteUptime,
  sendEmailApi,
} from "../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
  formateDateIntoDatemonth,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import Loading from "../utility/Loading";
import { PDFExport } from "@progress/kendo-react-pdf";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.data.label}</label>
      </components.Option>
    </div>
  );
};
const animatedComponents = makeAnimated();
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

function ModuleExpandSiteUptime() {
  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
    },
    yaxis: {
      logBase: 2,
      tickAmount: 5,
      min: 0,
    },
  });

  const [series, setSeries] = useState([]);
  const applyBtn = useRef(null);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [keytablelist, setkeytablelist] = useState([]);
  const [exportTable1, setexportTable1] = useState([]);
  const [keyTableCol, setkeyTableCol] = useState([]);
  const [selectedUrlExportList, setSelectedUrlExportList] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [noConfig, setNoConfig] = useState(true);
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("Site Uptime Reports");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);

  const urlrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          url: item.url,
          [mmDDyyyyFormateForForm(
            new Date().setDate(new Date().getDate() - 6)
          )]:
            item[
            [
              mmDDyyyyFormateForForm(
                new Date().setDate(new Date().getDate() - 6)
              ),
            ]
            ],
          [mmDDyyyyFormateForForm(
            new Date().setDate(new Date().getDate() - 5)
          )]:
            item[
            [
              mmDDyyyyFormateForForm(
                new Date().setDate(new Date().getDate() - 5)
              ),
            ]
            ],
          [mmDDyyyyFormateForForm(
            new Date().setDate(new Date().getDate() - 4)
          )]:
            item[
            [
              mmDDyyyyFormateForForm(
                new Date().setDate(new Date().getDate() - 4)
              ),
            ]
            ],
          [mmDDyyyyFormateForForm(
            new Date().setDate(new Date().getDate() - 3)
          )]:
            item[
            [
              mmDDyyyyFormateForForm(
                new Date().setDate(new Date().getDate() - 3)
              ),
            ]
            ],
          [mmDDyyyyFormateForForm(
            new Date().setDate(new Date().getDate() - 2)
          )]:
            item[
            [
              mmDDyyyyFormateForForm(
                new Date().setDate(new Date().getDate() - 2)
              ),
            ]
            ],
          [mmDDyyyyFormateForForm(
            new Date().setDate(new Date().getDate() - 1)
          )]:
            item[
            [
              mmDDyyyyFormateForForm(
                new Date().setDate(new Date().getDate() - 1)
              ),
            ]
            ],

          [mmDDyyyyFormateForForm(new Date())]:
            item[[mmDDyyyyFormateForForm(new Date())]],
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          key: item.key,
          fields: item.fields,
          MetaTitle: item.MetaTitleExp,
          MetaDescription: item.MetaDescriptionExp,
          Canonicalonthepage: item.CanonicalonthepageExp,
          NoFollow: item.NoFollowExp,
          NoIndex: item.NoIndexExp,
          Redirection: item.RedirectionExp,
          StatusCode: item.StatusCodeExp,
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Site UpTime Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    var color = [];
    getUrlList();
    color = [
      { value: "Meta Title", label: "Meta Title" },
      { value: "Meta Description", label: "Meta Description" },
      { value: "Canonical on the page", label: "Canonical on the page" },
      { value: "NoIndex", label: "NoIndex" },
      { value: "NoFollow", label: "NoFollow" },
      { value: "Redirection", label: "Redirection" },
      { value: "Status Code", label: "Status Code" },
    ];
    var columns = [
      {
        title: "URL ",
        dataIndex: "fields",
        key: "fields ",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.fields, b.fields),
          multiple: 3,
        },
      },

      {
        title: "Meta Title ",
        dataIndex: "MetaTitle",
        key: "MetaTitle ",
      },

      {
        title: "Meta Description ",
        dataIndex: "MetaDescription",
        key: "MetaDescription ",
      },

      {
        title: " Canonical on the page ",
        dataIndex: "Canonicalonthepage",
        key: "Canonicalonthepage ",
      },
      {
        title: "NoIndex",
        dataIndex: "NoIndex",
        key: "NoIndex ",
      },
      {
        title: "NoFollow",
        dataIndex: "NoFollow",
        key: "NoFollow ",
      },
      {
        title: " Redirection ",
        dataIndex: "Redirection",
        key: "Redirection",
      },
      {
        title: " Status Code ",
        dataIndex: "StatusCode",
        key: "StatusCode",
      },
    ];
    setteamcol(columns);
    columns = [
      {
        title: "URL",
        dataIndex: "url",
        key: "url",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.url, b.url),
          multiple: 3,
        },
      },
      {
        title: formateDateIntoDatemonth(
          new Date().setDate(new Date().getDate() - 6)
        ),
        dataIndex: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 6)
        ),
        key: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 6)
        ),
      },
      {
        title: formateDateIntoDatemonth(
          new Date().setDate(new Date().getDate() - 5)
        ),
        dataIndex: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 5)
        ),
        key: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 5)
        ),
      },
      {
        title: formateDateIntoDatemonth(
          new Date().setDate(new Date().getDate() - 4)
        ),
        dataIndex: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 4)
        ),
        key: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 4)
        ),
      },
      {
        title: formateDateIntoDatemonth(
          new Date().setDate(new Date().getDate() - 3)
        ),
        dataIndex: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 3)
        ),
        key: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 3)
        ),
      },
      {
        title: formateDateIntoDatemonth(
          new Date().setDate(new Date().getDate() - 2)
        ),
        dataIndex: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 2)
        ),
        key: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 2)
        ),
      },
      {
        title: formateDateIntoDatemonth(
          new Date().setDate(new Date().getDate() - 1)
        ),
        dataIndex: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 1)
        ),
        key: mmDDyyyyFormateForForm(
          new Date().setDate(new Date().getDate() - 1)
        ),
      },
      {
        title: formateDateIntoDatemonth(new Date()),
        dataIndex: mmDDyyyyFormateForForm(new Date()),
        key: mmDDyyyyFormateForForm(new Date()),
      },
    ];
    setkeyTableCol(columns);
    setteamList([]);
    setkeytablelist([{}]);
    setDisplayTable(true);
  }, []);

  const ref = useRef();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(6, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [open, setOpen] = useState(false);
  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  const [totalExecuted, settotalExecuted] = useState("");
  const [totalPassed, settotalPassed] = useState("");
  const [totalFailed, settotalFailed] = useState("");
  const [overallResult, setoverallResult] = useState("");

  function updatecolumn(dates) {
    if (dates.length) {
      const startDate = dates[0].format("MM/DD/YYYY");
      const endDate = dates[1].format("MM/DD/YYYY");
      const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
      const diffDays = 0 | (diffTime / 864e5);
      let series = [];
      let options = {};
      let columns = [
        {
          title: "URL",
          dataIndex: "url",
          key: "url",
        },
      ];
      for (let i = 0; i <= diffDays; i++) {
        const newdate = formateDateIntoDatemonth(
          new Date(new Date(startDate).getTime() + i * 864e5)
        );
        const datedata = mmDDyyyyFormateDateSet(
          new Date(new Date(startDate).getTime() + i * 864e5)
        );
        series.push(
          formateDateIntoDatemonth(
            new Date(new Date(startDate).getTime() + i * 864e5)
          )
        );

        let obj = {
          title: newdate,
          dataIndex: datedata,
          key: datedata,
        };
        columns.push(obj);
      }
      setkeyTableCol(columns);
      options = {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          tickPlacement: "on",
          categories: series,
        },
        yaxis: {
          logBase: 2,
          tickAmount: 5,
          min: 0,
        },
      };
      setObject(options);
      let getdata = {
        projectId: localStorage.getItem("projectGId"),
        startDate: mmDDyyyyFormateDateSet(head1),
        EndDate: mmDDyyyyFormateDateSet(head2),
      };
      gscGetDataGet(getdata);
    }
  }

  useEffect(() => {
    getSiteUptimeConfig();
  }, []);

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image)
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleSiteUptime
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status == 200) {
          setReceiver("");
          setSubject("Site UpTime Reports");
          setEmailMessage(EmailMessage1);
          if (theme == 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  function getSiteUptimeConfig() {
    APIManager.getAPI(
      getSiteUptimeMonitorGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response?.data) {
          setNoConfig(false);
          setNoContent(true);
          let getdata = {
            projectId: localStorage.getItem("projectGId"),
            startDate: mmDDyyyyFormateDateSet(head1),
            EndDate: mmDDyyyyFormateDateSet(head2),
          };
          gscGetDataGet(getdata);
          GetfieldDataGet();
        }
      }
    });
  }

  function gscGetDataGet(rowdata) {
    APIManager.postAPI(addsiteupmonitor, rowdata, null, true).then(
      (response) => {
        if (response.status == 200 && response?.data.chartData) {
          settotalExecuted(response?.data.totalExecuted);
          settotalPassed(response?.data.totalPassed);
          settotalFailed(response?.data.totalFailed);
          setoverallResult(response?.data.overallResult);

          const startDate = rowdata.startDate;
          const endDate = rowdata.EndDate;

          const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
          const diffDays = 0 | (diffTime / 864e5);
          let tableList = [];
          let expTableList = [];
          let dataForGraph = [];
          let jsonchartData = response?.data.chartData;
          setNoContent(!jsonchartData?.length);
          if (jsonchartData.length) {
            jsonchartData.map((data) => {
              let graphData = [];
              let graphData2 = [];
              if (diffDays) {
                let obj = { url: data.urLs };
                let obj2 = { url: data.urLs };
                for (let i = 0; i <= diffDays; i++) {
                  const newdate = mmDDyyyyFormateForForm(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  );
                  obj[newdate] = isDataAvailable(data[newdate]);
                  obj2[newdate] = data[newdate]
                    ? data[newdate] == 6
                      ? "Pass"
                      : "Fail"
                    : " - ";
                  graphData.push(isDataAvailable(data[newdate]));
                  graphData2.push(data[newdate] ? data[newdate] : 0);
                }
                tableList.push(obj);
                expTableList.push(obj2);
                dataForGraph.push({
                  name: data.urLs,
                  data: graphData2,
                });
                setSeries(dataForGraph);
                setkeytablelist(tableList);
                setexportTable1(expTableList);
              }
            });
          }
        } else {
        }
      }
    );
  }

  function isDataAvailable(data) {
    return data ? (
      <>
        <Tooltip title={data}>
          <span>
            <i class="fa fa-check" aria-hidden="true"></i>
          </span>
        </Tooltip>
      </>
    ) : (
      <>
        <i class="fa fa-times"></i>
      </>
    );
  }

  const [lastUpdateDate, setDate] = useState("");
  const [tooltipData, setTooltipData] = useState("");

  function GetfieldDataGet() {
    const url = getsiteupgetfielddata + localStorage.getItem("projectGId");
    APIManager.getAPI(url, true).then((response) => {
      if (response.status == 200 && response) {
        let jsonData = response?.data;
        setTooltipData(jsonData);
        let list = [];
        let list2 = [];
        let i = 0;
        setDate(response.data[0]?.LatestDate);
        setNoContent(!jsonData?.length);
        jsonData.map((data) => {
          list.push({
            key: (i += 1),
            fields: data.URL,
            MetaTitle: isDataAvailable(data.MetaTitle),
            MetaDescription: isDataAvailable(data.RedirectionRedirection),
            Canonicalonthepage: isDataAvailable(data.CanonicalUrl),
            NoFollow: isDataAvailable(data.NoFollow),
            NoIndex: isDataAvailable(data.NoIndex),
            Redirection: isDataAvailable(data.Redirection),
            StatusCode: isDataAvailable(data.StatusCode),

            MetaTitleExp: data.MetaTitle,
            MetaDescriptionExp: data.RedirectionRedirection,
            CanonicalonthepageExp: data.CanonicalUrl,
            NoFollowExp: data.NoFollow,
            NoIndexExp: data.NoIndex,
            RedirectionExp: data.Redirection,
            StatusCodeExp: data.StatusCode,
          });

          let obj = {
            fields: data.URL,
            MetaTitle: data.MetaTitle,
            MetaDescription: data.RedirectionRedirection,
            Canonicalonthepage: data.CanonicalUrl,
            NoFollow: data.NoFollow,
            NoIndex: data.NoIndex,
            Redirection: data.Redirection,
            StatusCode: data.StatusCode,
          };
          list2.push(obj);
        });
        setteamList(list);
        setExportList(list2);
      }
    });
  }

  function getUrlList() {
    const url = getUrlProjectID + localStorage.getItem("projectGId");
    APIManager.getAPI(url, true).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          list.push({
            id: item.id,
            value: item.id,
            label: item.fieldValue,
            url: item.fieldValue,
            uckType: item.uckType,
            projectId: item.projectId,
          });
        });
      }
    });
  }

  const [SiteUptimeMonitor, setSiteUptimeMonitor] = useState(false);
  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      {noConfig ? (
        <>
          <div class=" message text-center ">
            <h4>
              The Site Uptime configuration is not set. <br />
              Please contact your administrator to set configuration.
            </h4>
          </div>
        </>
      ) : (
        <>
          <div className="row">
            <Tabs>
              <div className="portlet ">
                <div className="portlet-header portlet-header-bordered my-4">
                  <div className="col-md-9 col-sm-12">
                    <TabList className="nav nav-tabs portlet-nav">
                      <Tab
                        className={
                          SiteUptimeMonitor
                            ? "nav-item nav-link "
                            : "nav-item nav-link  active"
                        }
                        onClick={() => {
                          setSiteUptimeMonitor(false);
                        }}
                      >
                        Site Uptime Monitor
                      </Tab>
                    </TabList>
                  </div>

                  {!SiteUptimeMonitor ? (
                    <div
                      ref={ref}
                      class="col-md-3 col-sm-12 calendar-main  d-flex justify-content-end align-items-center ga-calender"
                    >
                      <div className="add-new-btnw">
                        <button
                          className="btn btn-primary"
                          style={{ width: "250px" }}
                          onClick={() => setOpen(!open)}
                        >
                          {calanderDate(head1, head2)}
                        </button>
                      </div>
                      {open && (
                        <div id="example-collapse-text-calendar">
                          <Card body className="daterange-picker-card  mt-3">
                            <div className="d-flex calendar-flex">
                              <div className="calendar-col">
                                <Calendar
                                  className="custom-calendar"
                                  value={values}
                                  onChange={(e) => {
                                    setValues(e);
                                    setHead1(e[0].format());
                                    setHead2(e[1]?.format());
                                  }}
                                  range
                                  numberOfMonths={1}
                                  showOtherDays
                                />
                              </div>
                              <div className="mt-3 mt-lg-0 text-center">
                                <button
                                  onClick={() => {
                                    let dates = [];
                                    dates.push(values[0]);
                                    dates.push(values[1]);
                                    updatecolumn(dates);
                                    setheadvalues();
                                    setOpen(!open);
                                    setRefreshTicketData(true);
                                    setTimeout(() => {
                                      setRefreshTicketData(false);
                                    }, 1000);
                                  }}
                                  className="outline-btn btn btn-primary"
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                      <div className="d-flex 3-icons">
                        <div className="ms-3  ">
                          <a
                            className="share bar-link-icon"
                            style={{ color: "black" }}
                          >
                            <div class="dropdown">
                              <a
                                type="button"
                                ref={applyBtn}
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <i
                                  class="fa fa-share-alt"
                                  aria-hidden="true"
                                ></i>
                              </a>
                              <form class="dropdown-menu p-4 filter-dropdown">
                                <div className="filter-popup">
                                  <div className="portlet px-2 py-2">
                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">From</label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Sender Email Id"
                                          value={Sender}
                                          className="w-100 form-control my-2"
                                          name="Sender"
                                        />
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">To</label>
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        {" "}
                                        <input
                                          type="text"
                                          required
                                          placeholder="Recipient Email Id"
                                          className="w-100 form-control my-2"
                                          value={Receiver}
                                          name="Receiver"
                                          onChange={(text) =>
                                            setReceiver(text.target.value)
                                          }
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageReceiver}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Subject</label>
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <input
                                          type="text"
                                          required
                                          placeholder="Subject"
                                          value={subject}
                                          onChange={(text) => {
                                            setSubject(text.target.value);
                                          }}
                                          className="w-100 form-control my-2"
                                          name="Subject"
                                        />
                                        <div style={{ color: "red" }}>
                                          {MessageSubject}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2 text-start">
                                        <label class="my-2 pt-2">Message</label>
                                        <label class="field-required">
                                          {" "}
                                          *{" "}
                                        </label>
                                      </div>
                                      <div className="col-md-10">
                                        <textarea
                                          name=""
                                          id=""
                                          cols="80"
                                          rows="7"
                                          className="w-100 form-control my-2 gscmsg"
                                          value={EmailMessage}
                                          onChange={(text) => {
                                            setEmailMessage(text.target.value);
                                          }}
                                        ></textarea>
                                        <div style={{ color: "red" }}>
                                          {Message}
                                        </div>
                                      </div>
                                    </div>

                                    <div className="row">
                                      <div className="col-md-2"></div>
                                      <div className="col-md-10 my-2">
                                        <button
                                          type="button"
                                          class="btn btn-primary"
                                          style={{
                                            width: 70 + "px",
                                            height: 30 + "px",
                                          }}
                                          onClick={() => {
                                            if (isValidEmailData()) {
                                              sendEmail();
                                            }
                                          }}
                                        >
                                          {" "}
                                          Send
                                        </button>
                                      </div>
                                    </div>

                                  </div>
                                </div>
                              </form>
                            </div>
                          </a>
                        </div>

                        <div className="row">
                          <div className="col-md-12 download-filter">
                            <span
                              class="dropdown"
                              style={{
                                marginRight: 0 + "px",
                                textAlign: "right",
                                display: "block",
                              }}
                            >
                              {/* {getCheckPermission("export", "modules") ? ( */}
                                <button
                                  class="outline-btn dropdown-toggle btn btn-primary"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    class="fa fa-download "
                                    aria-hidden="true"
                                  ></i>
                                </button>
                              {/* ) : (
                                <></>
                              )} */}
                              <div
                                class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  onClick={async () => {
                                    setLoading(true)
                                    const promise = await downloadPDFfile(image, moduleSiteUptime)
                                    try {
                                      if (promise) {
                                        setLoading(false);
                                      } else {
                                        setLoading(false);
                                      }
                                    } catch (e) {
                                      setLoading(false);
                                    }
                                  }}
                                >
                                  <a class="dropdown-item">PDF</a>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
              <TabPanel>
                <br />
                {displayTable && !noContent ? (
                  <>
                    <PDFExport
                      paperSize="A1"
                      margin="2cm"
                      fileName={moduleSiteUptime}
                      ref={pdfExportComponent}
                      forcePageBreak=".page-break"
                    >
                      <div ref={image} >
                        <div
                          className="portlet portlet-header-bordered"
                          id="web-page-pdf"
                        >
                          <div className="site-uptime-box common-mb-24">
                            <div className="row">
                              <div className="col siteuptime-col">
                                <div className="box-outer bg-light-blue">
                                  <h4 className="box-heading font-weight-bolder">
                                    Total Test Executeds
                                  </h4>
                                  <p className="box-value">{totalExecuted}</p>
                                </div>
                              </div>

                              <div className="col siteuptime-col">
                                <div className="box-outer bg-light-blue">
                                  <h4 className="box-heading font-weight-bolder">
                                    Total Passed
                                  </h4>
                                  <p className="box-value">{totalPassed}</p>
                                </div>
                              </div>

                              <div className="col siteuptime-col">
                                <div className="box-outer bg-light-blue">
                                  <h4 className="box-heading font-weight-bolder">
                                    Total Failed
                                  </h4>
                                  <p className="box-value">{totalFailed}</p>
                                </div>
                              </div>

                              <div className="col siteuptime-col">
                                <div className="box-outer bg-light-blue">
                                  <h4 className="box-heading font-weight-bolder">
                                    Overall Result
                                  </h4>
                                  <p className="box-value">{overallResult}</p>
                                </div>
                              </div>

                              <div className="col siteuptime-col">
                                <div className="box-outer bg-light-blue">
                                  <h4 className="box-heading font-weight-bolder">
                                    Total test with other status
                                  </h4>
                                  <p className="box-value">0</p>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="portlet">
                            <div className="portlet-header portlet-header-bordered">
                              <h3 className="portlet-title">Site Uptime</h3>
                            </div>
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="rank-track-graph text-end">
                                  <div className="rank-track-graph-input-2"></div>
                                </div>
                                <div className="row common-mt-24">
                                  <div className="col-md-12"></div>
                                  <div className="col-md-1"></div>
                                </div>
                                <div className="col-12 justify-content-center mt-3">
                                  {series ? (
                                    <Chart
                                      options={options}
                                      series={series}
                                      type="line"
                                      height="300"
                                    />
                                  ) : (
                                    <div>No data</div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="portlet">
                            <div className="portlet-header portlet-header-bordered">
                              <h3 className="portlet-title">Site Uptime</h3>
                            </div>
                            <div className="portlet-body">
                              <div className="col-md-12">
                                <div className="text-right mt-3 mb-3">
                                  <span class="dropdown">
                                    {/* {getCheckPermission("export", "modules") ? ( */}
                                      <button
                                        class="btn btn-primary dropdown-toggle ms-3"
                                        type="button"
                                        onClick={() => {
                                          exportToEXCEL(
                                            selectedUrlExportList?.length > 0
                                              ? selectedUrlExportList
                                              : exportTable1,
                                            "Site Up Time"
                                          );
                                        }}
                                      >
                                        Export
                                      </button>
                                    {/* ) : (
                                      <></>
                                    )} */}
                                    {/* <div
                                      class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                      aria-labelledby="dropdownMenuButton"
                                    >
                                      <div
                                        onClick={() => {
                                          exportToEXCEL(
                                            selectedUrlExportList?.length > 0
                                              ? selectedUrlExportList
                                              : exportTable1,
                                            "Site Up Time"
                                          );
                                        }}
                                      >
                                        <a class="dropdown-item">Excel</a>
                                      </div>
                                      <div
                                        onClick={() => {
                                          exportInToCSV(
                                            selectedUrlExportList?.length > 0
                                              ? selectedUrlExportList
                                              : exportTable1,
                                            "Site Up Time"
                                          );
                                        }}
                                      >
                                        <a class="dropdown-item">CSV</a>
                                      </div>
                                    </div> */}
                                  </span>
                                </div>
                                <Table
                                  className="table table-bordered table-hover mt-3"
                                  id="datatable-1"
                                  columns={keyTableCol}
                                  dataSource={[...keytablelist]}
                                  rowSelection={{
                                    type: "checkbox",
                                    ...urlrowSelection,
                                  }}
                                  pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                  }}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="portlet">
                            <div className="portlet-header portlet-header-bordered">
                              <h3 className="portlet-title">Latest Site Uptime</h3>
                            </div>
                            <div className="portlet-body">
                              <div className="col-md-12 d-flex justify-content-end mt-3">
                                <div>
                                  Last update date :{" "}
                                  {lastUpdateDate
                                    ? mmDDyyyyFormateDate(lastUpdateDate)
                                    : " - "}
                                </div>
                                <span class="dropdown">
                                  <button
                                    class="btn btn-primary dropdown-toggle ms-3"
                                    type="button"
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : exportList,
                                        "Site Up Time"
                                      );
                                    }}
                                  >
                                    Export
                                  </button>
                                  {/* <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedExportList?.length > 0
                                            ? selectedExportList
                                            : exportList,
                                          "Site Up Time"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">Excel</a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        exportInToCSV(
                                          selectedExportList?.length > 0
                                            ? selectedExportList
                                            : exportList,
                                          "Site Up Time"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">CSV</a>
                                    </div>
                                  </div> */}
                                </span>
                              </div>

                              <div className="col-md-12">
                                <Table
                                  className="table table-bordered  table-hover mt-3"
                                  id="datatable-1"
                                  columns={teamcol}
                                  dataSource={teamlist}
                                  rowSelection={{
                                    type: "checkbox",
                                    ...rowSelection,
                                  }}
                                  pagination={{
                                    position: ["bottomRight"],
                                    showSizeChanger: true,
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div></PDFExport>
                  </>
                ) : (
                  <></>
                )}

                {noContent ? (
                  <>
                    <div class=" message text-center ">
                      <h4>
                        The Site Uptime is yet in progress. <br />
                        The status will be visible here once the Site Uptime
                        completed.
                      </h4>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </TabPanel>
            </Tabs>
          </div>
        </>
      )}
      <Loading isLoading={isLoading} />
    </>
  );
}

export default ModuleExpandSiteUptime;
