// .jsx

import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";

const PieChart2 = (props) => {
    console.log(props, 'props')
    useEffect(() => {
        // Create chart instance

        // Add data

        var chart = am4core.create(props.id, am4charts.PieChart);
        chart.preloader.disabled = true;
        chart.hiddenState.properties.opacity = 0; // This creates initial fade-in effect
        chart.logo.disabled = true;
        chart.height= 100

        // Add data
        chart.data = [{
            "label": "High",
            "value": props.data[0],
            "color": am4core.color('#91e395')
        }, {
            "label": "Medium",
            "value": props.data[1],
            "color": am4core.color('#ffaa46')
        }, {
            "label": "Low",
            "value": props.data[2],
            "color": am4core.color('#ef5350')
        }]

        // Set inner radius
        // chart.innerRadius = am4core.percent(25);
        const filteredData = chart.data.filter((item) => item.value !== 0);
        // Add and configure Series
        var pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "label";
        pieSeries.slices.template.stroke = am4core.color("#fff");
        pieSeries.slices.template.strokeWidth = 2;
        pieSeries.slices.template.strokeOpacity = 1;
        pieSeries.slices.template.propertyFields.fill = 'color'; // Use the custom color defined in the data

        // This creates initial animation
        pieSeries.hiddenState.properties.opacity = 1;
        pieSeries.hiddenState.properties.endAngle = -90;
        pieSeries.hiddenState.properties.startAngle = -90;

        pieSeries.ticks.template.disabled = true;
        pieSeries.alignLabels = false;
        // pieSeries.labels.template.text = "{value.percent.formatNumber('#.0')}%";
        pieSeries.labels.template.text = "{value}";
        pieSeries.labels.template.radius = am4core.percent(-40);
        pieSeries.labels.template.fill = am4core.color("white");

        // // Set tooltip       
        pieSeries.slices.template.tooltipText = "{category}: {value}";

        // chart.responsive.enabled = true;
        chart.data = filteredData;
        // chart.chartContainer.hideCredits = true;
        // const externalTitleElement = document.getElementById(props.others);
        // externalTitleElement.style.textAlign = "center";
        // externalTitleElement.style.fontSize = "12px";
        // externalTitleElement.textContent = props.title;
        var labelContainer = chart.chartContainer.createChild(am4core.Label);
        labelContainer.text = props.title;
        labelContainer.dy = 15
        labelContainer.align = "center";
        labelContainer.valign = "bottom";
        labelContainer.fontSize = 13;
    });

    return (
       
            <div id={props.id} style={{ width: '100%', height: '122px' }}></div>
            
    );
    /*
return (
        <div
            // style={{ width: 200 + "px" }}
            className="chart-box col-md-3"
        >
            <div id={props.id} style={{ width: '100%', height: '100px' }}></div>
            <div className="chart-title">
                {props.title}
            </div>
        </div>
    );
    */
}

export default PieChart2;