import React from "react";
import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Table, Input } from "antd";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import APIManager from "../services/APIManager";
import {
  getClientUser,
  getMember,
  getClientByOrgId,
  getProjectAssignment,
  createProjectAssignment,
} from "../utility/Constant";
import { themeStyle } from "../utility/Globals";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const rowSelection = {
  onChange: () => {},
};

function ViewClientDetailsSalesDir() {
  const [optionSelected, setoptionSelected] = useState(null);
  function handleChange(selected) {
    setoptionSelected(selected);
  }
  const [colourOptions, setcolor] = useState([]);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [searchdata, setsearch] = useState([]);

  useEffect(() => {
    var columns = [
      {
        title: "Team Member",
        dataIndex: "teammember",
        key: "teammember",
        width: "20%",
      },
      {
        title: "Date Added",
        dataIndex: "date",
        key: "date",
        width: "20%",
      },
      {
        title: "",
        dataIndex: "",
        key: "",
        width: "50%",
      },
      {
        title: "Action",
        dataIndex: "delete",
        key: "delete",
        width: "10%",
      },
    ];
    setteamcol(columns);

    getTeamMembersListData();
    getClientList();
    getClientUserData();
  }, []);

  function assign() {
    let teamMemberObj = [];
    let arrayOfObj = optionSelected;
    arrayOfObj.map((item) => {
      teamMemberObj.push({
        memberId: item.key,
      });
    });
    APIManager.postAPI(
      createProjectAssignment,
      {
        projectId: projectId,
        memberId: memberId,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getProjectAssignmentData();
      }
    });
  }

  function getTeamMembersListData() {
    APIManager.getAPI(getMember).then((response) => {
      if (response && response.status == 200) {
        let list = [];
        response.data.map((item) => {
          list.push({
            id: item.id,
            label: item.name,
            value: item.id,
          });
        });

        setcolor(list);
      }
    });
  }

  function getClientList() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = { name: item.name, id: item.id };
          list.push(obj);
        });
      }
    });
  }

  const [modulelisr, setmodulelist] = useState([]);
  const [memberId, setMemberId] = useState("");
  const [projectId, setprojectId] = useState("");

  function getClientUserData() {
    APIManager.getAPI(getClientUser).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          list.push({
            key: item.memberId,
            value: item.members.name,
            label: item.members.name,
          });
        });
        setcolor(list);
      }
    });
  }

  function getProjectAssignmentData() {
    APIManager.getAPI(getProjectAssignment).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        let c = 1;
        response.data.map((item) => {
          list.push({
            count: c,
            key: item.id,
            id: item.id,
            modulename: item.name,
            delete: (
              <a style={{ marginLeft: 24 + "px" }}>
                <i class="fa fa-trash"></i>
              </a>
            ),
          });
          c++;
        });
        setmodulelist(list);

        let list2 = [];
        response.data.map((item) => {
          list2.push({
            key: item.id,
            teammember: item.teamMembers[0]?.memberId,

            delete: (
              <div onClick={() => {}}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          });
        });
        setsearch(list2);
        setteamList(list2);
      }
    });
  }
  return (
    <>
      <div className="row">
        <div class="row">
          <div class="col-sm-5 pad-lzero">
            <div class="main-title">Assign team members</div>
          </div>
          <div class="col-sm-7 add-new-btnw"></div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <ReactSelect
              styles={themeStyle()}
              options={colourOptions}
              isMulti
              closeMenuOnSelect={false}
              hideSelectedOptions={false}
              components={{
                Option,
              }}
              onChange={handleChange}
              allowSelectAll={true}
              value={optionSelected}
            />
          </div>
          <div className="col-md-3">
            <button onClick={() => assign()} class="btn btn-primary">
              Assign
            </button>
          </div>
        </div>
        <hr />

        {teamlist && teamlist.length > 0 ? (
          <>
            <div class="add-new-btnw" style={{ textAlign: "left" }}></div>

            <div className="row">
              <div className="col-sm-5"></div>
              <div className="col-sm-7 add-new-btnw">
                <div
                  class="search"
                  style={{ marginLeft: 0 + "px", width: 100 + "%" }}
                >
                  <div class="input-group" style={{ display: "block" }}>
                    <Input.Search
                      allowClear
                      placeholder="Search"
                      onSearch={() => {}}
                      id="input-s"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="common-table">
              <div class="row">
                <div class="col-md-5"></div>
                <div class="col-md-7">
                  <div
                    class="data-export"
                    style={{ textAlign: "end", marginLeft: "unset" }}
                  >
                    <span class="export">
                      <button
                        class="btn btn-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Export
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <div onClick={() => {}}>
                          <a class="dropdown-content">Excel</a>
                        </div>
                        <a class="dropdown-content">CSV</a>
                      </div>
                    </span>

                    <span></span>
                  </div>
                </div>
              </div>
              <Table
                id="sample"
                columns={teamcol}
                dataSource={teamlist}
                rowSelection={{ type: "checkbox", ...rowSelection }}
                pagination={{
                  position: ["bottomRight"],
                  showSizeChanger: true,
                }}
              />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default ViewClientDetailsSalesDir;
