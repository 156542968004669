import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";

function Moduleexpandcommentdata() {
  const location = useLocation();
  const [comment, setcomment] = useState("");
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    setEmail(localStorage.getItem("UserEmail"));
    setName(localStorage.getItem("userName"));
    let div1 = document.createElement("div");
    if (location.state) {
      setcomment(location.state.item.comment);
      setUrl(location.state.item.pageUrl);
      div1.innerHTML = location.state.item.comment;
      document.getElementById("comment").appendChild(div1)
    }
  }, []);


  const history = useHistory();
  return (
    <>
      <div className="col-md-12 ps-0 pe-0 rank-tracking"></div>
      <div className="portlet-header portlet-header-bordered p-0">
        <i
          class="fa fa-chevron-circle-left"
          style={{ fontSize: 20 + "px", color: "#2196f3" }}
          onClick={() => {
            history.goBack();
          }}
        ></i>
      </div>
      <div class="portlet">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Bug Report</h3>
        </div>
        <div className="portlet-body">

          <div className="col-md-12">
            <label className="form-label">Name</label>
            <input
              className="w-100"
              type="text"
              value={name}
              name="user_name"
              disabled="true"
            />
          </div>

          <div className="col-md-12 mt-3">
            <label className="form-label">Email</label>
            <input
              className="w-100"
              type="text"
              value={email}
              name="user_email"
              disabled="true"
            />
          </div>

          <div className="col-md-12 mt-3">
            <label className="form-label">URL</label>
            <input
              className="w-100"
              type="text"
              value={url}
              name="user_email"
              disabled="true"
            />
          </div>

          <div className="col-md-12 mt-3">
            <label className="form-label">Comments</label>
            <div id="comment" className="col-md-12w-100">
            </div>
            <div id="pageUrl" className="col-md-12w-100"></div>
          </div>
        </div>
      </div>
    </>
  );
}


export default Moduleexpandcommentdata;