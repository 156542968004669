import { Table, Tabs } from "antd";
import { useState, useEffect } from "react"; 
import { useHistory, useLocation } from "react-router-dom";
import APIManager from "../../services/APIManager";
import { getFreelancerUser, GET_Server_URL, imageFileType, onlyPDF, updateFreelancer } from "../../utility/Constant";
import { DDMMyyyyFormateDateSet } from "../../utility/Globals";


function PersonalDetails() {
    const [projectCols, setProjectCol] = useState([]);
    const [projectWorkList, setProjectWorkList] = useState([]);
    const [projectseleted, setprojectseleted] = useState({
        value: "",
        label: "",
      });
    const [projectList, setprojectList] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [articlePerDay, setArticlePerDay] = useState("");
    const [costPerWord, setCostPerWord] = useState("");
    const [costPerArticle, setCostPerArticle] = useState("");
    const [JoiningData, setJoiningData] = useState("");
    const [errors, setErrors] = useState("");
    const [documentFile, setDocumentFile] = useState(null);
    const [images, setimages] = useState("");
    const [documentURL, setDocumentURL] = useState("");
    const [inputimg, setinputimg] = useState([]);
    const [selectImageFile, setImageFile] = useState(null);
    const [activeTab, setactiveTab] = useState("personalinfo");
    const location = useLocation();
    const history = useHistory();
    
    useEffect(() => {       
        const columns = [
        {
            title: "ID",
            dataIndex: "id",
            key: "id",
            // width: "20%",
        },
        {
            title: "PROJECT",
            dataIndex: "project",
            key: "project",
            // width: "10%",
        },
        
        {
            title: "KEYWORD",
            dataIndex: "keyword",
            key: "keyword",
            // width: "10%",
        },
        {
            title: "COST",
            dataIndex: "cost",
            key: "cost",
            // width: "10%",
        },
        {
            title: "START DATE",
            dataIndex: "start_date",
            key: "start_date",
            // width: "10%",
        },
        {
            title: "REWORK",
            dataIndex: "rework",
            key: "rework",
            // width: "10%",
        },
        {
            title: "STATUS",
            dataIndex: "status",
            key: "status",
            // width: "10%",
        }];
        setProjectCol(columns);
        getFreelancer();
    },[]);

    function addnewimg1(event) {
        setinputimg(URL.createObjectURL(event.target.files[0]));
        var input = document.getElementById("fileMy1");
        setimages("");
        setImageFile(input.files[0]);
    }

    const getFreelancer = () => {
        let id = localStorage.getItem('userId'); 
        APIManager.getAPI(getFreelancerUser + "/" + id).then((response) => {
          if (response && response.status == 200) {
            setDataList(response.data[0]);
            setFirstName(response.data[0].firstName);
            setLastName(response.data[0].lastName);
            setMobileNumber(response.data[0].mobile);
            setEmail(response.data[0].email);
            setArticlePerDay(response.data[0].articlesPerDay);
            setCostPerWord(response.data[0].costPerWord);
            setCostPerArticle(response.data[0].costPerArticle);
            setJoiningData(response.data[0].createdAt);
            setinputimg(
             ( response.data[0].imageURL == "null" || response.data[0].imageURL == null)
                ?  "/images/profile-pic.jpeg"
                : GET_Server_URL + response.data[0].imageURL
            );
            setimages(response.data[0].imageURL);
            setDocumentURL(
              response.data[0].sampleWork == "null"
                ? ""
                : response.data[0].sampleWork
            );
          }
        });
    };

    function updateFreelancerData() {
        var bodyFormData = new FormData();
    
        bodyFormData.append("freelancerCategory", dataList.freelancerCategory);
        bodyFormData.append("freelancerType", dataList.freelancerType);
        bodyFormData.append("firstName", firstName);
        bodyFormData.append("lastName", lastName);
        bodyFormData.append("mobile", mobileNumber);
        bodyFormData.append("email", email);
        bodyFormData.append("articlesPerDay", articlePerDay ?? '');
        bodyFormData.append("costPerWord", costPerWord ?? '');
        bodyFormData.append("costPerArticle", costPerArticle ?? '');
        bodyFormData.append("niche", dataList.niche);
        bodyFormData.append("isActive", dataList.isActive);
        bodyFormData.append(
          "OrganizationId",
          localStorage.getItem("organizationId")
        );
        if (documentFile) {
          bodyFormData.append("SampleWorkFile", documentFile);
        } else {
          bodyFormData.append("sampleWork", documentURL);
        }
    
        if (selectImageFile) {
          bodyFormData.append("File", selectImageFile);
        } else {
          bodyFormData.append("imageURL", images);
        }
        bodyFormData.append("id", dataList.id);
        bodyFormData.append("userId", dataList.userId);
        
        if (
          firstName != "" &&
          lastName != "" &&
          mobileNumber != "" &&
          email != "" &&
          JoiningData != ""
        ) {
          APIManager.putAPI(updateFreelancer, {}, bodyFormData, null, true).then(
            (response) => {
              if (response && response.status == 200) {
                    getFreelancer();
              }
            }
          );
        } else {
          formValidation();
        }
    }

    function formValidation() {
        let error = { ...errors };
    
        firstName.trim() == ""
          ? (error["firstName"] = "First Name is required!")
          : !firstName.trim().match(/^[a-zA-Z\s]*$/)
            ? (error["firstName"] = "Please enter alphabet characters only.")
            : delete error["firstName"];
    
        lastName.trim() == ""
          ? (error["lastName"] = "Last Name is required!")
          : !lastName.trim().match(/^[a-zA-Z\s]*$/)
            ? (error["lastName"] = "Please enter alphabet characters only.")
            : delete error["lastName"];
    
        mobileNumber.trim() == ""
          ? (error["mobileNumber"] = "Mobile Number is required !")
          : !mobileNumber.trim().match(/^[0-9]{10}$/)
            ? (error["mobileNumber"] = "Please enter number only.")
            : delete error["mobileNumber"];
    
        if (mobileNumber?.length) {
          var pattern = new RegExp(/^[0-9\b]+$/);
    
          if (!pattern.test(mobileNumber)) {
            error["mobileNumber"] = "Please enter only number";
          } else if (mobileNumber.length >= 15) {
            error["mobileNumber"] =
              "Phone number must not exceed more than 15 letters.";
          } else if (mobileNumber.length < 10) {
            error["mobileNumber"] = "Phone number must not  less than 10 letters.";
          } else {
            delete error["mobileNumber"];
          }
        }
    
        email.trim() == ""
          ? (error["email"] = "Email Id is required !")
          : !email.trim().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
            ? (error["email"] = "Please enter valid email-ID.")
            : delete error["email"];
    
        JoiningData.trim() == ""
          ? (error["JoiningData"] = "Joining Data is required !")
          : delete error["JoiningData"];
    
        setErrors(error);
    
        let err = Object.keys(error);
        return err?.length ? false : true;
    }

    function updateList() {
        var input = document.getElementById("myfile1");
        setDocumentFile(input?.files?.item[0]);
    }

    return (
        <>
            <Tabs   className="nav nav-lines  portlet-nav myrequirement"
                activeKey={activeTab}
                onChange={(e) => {
                    if (e == "personalinfo") {
                        setactiveTab("personalinfo");
                    }
                    else{
                        setactiveTab("workhistory");
                    }
                }}>
                <Tabs.TabPane
                tab="Personal Info"
                key="personalinfo">
                <div className="portlet mb-0 rounded-0">
                    <div className="px-2 py-2 profile-portlet">
                    <div className="row ">
                        <div className="d-flex align-items-center ps-4">
                        <div className="">
                            <div className="personal-box position-relative mt-3">
                                <div className="profile_detail">
                            {inputimg ? (
                                <img
                                class="w-100"
                                // src="/images/profileInageSample.jpg"
                                src={inputimg }
                                className="personal-img"
                                />
                            ) : (

                                <>
                                <img
                                    class="w-100"
                                    src="/images/profile-pic.jpeg"
                                    className="personal-img"
                                />
                                </>
                            )}
                            </div>
                            <div className="online-status"></div>
                            </div>
                        </div>
                        <div className=" pic-change ms-4">
                            <button
                            className="btn btn-primary"
                            onClick={() => {
                                document.getElementById("fileMy1").click();
                            }}
                            >
                            <img
                                className="camera-img me-2"
                                src="/images/camera.svg"
                                alt=""
                            />
                            Change Photo
                            </button>
                            <input
                            type="file"
                            inputProps={{ accept: imageFileType }}
                            accept={imageFileType}
                            id="fileMy1"
                            class="none"
                            multiple={false}
                            onChange={(e) => {
                                addnewimg1(e);
                            }}
                            ></input>
                        </div>
                        </div>
                        <div className="row personal-info my-3">
                        <div className="col-md-6 text-start">
                            <label>First Name<label class="field-required"> * </label></label>
                            <input
                            className="w-100"
                            type="text"
                            value={firstName}
                            onChange={(text) => {
                                setFirstName(text.target.value);
                                formValidation();
                            }}
                            />
                            {errors?.firstName ? (
                            <>
                                <div className="field-required ms-3 mt-2">
                                <span> {errors?.firstName}</span>
                                </div>
                            </>
                            ) : (
                            <></>
                            )}
                        </div>
                        <div className="col-md-6 text-start">
                            <label>Last Name<label class="field-required"> * </label></label>
                            <input
                            className="w-100"
                            type="text"
                            value={lastName}
                            onChange={(e) => {
                                setLastName(e.target.value);
                                formValidation();
                            }}
                            />
                            {errors?.lastName ? (
                            <>
                                <div className="field-required ms-3 mt-2">
                                <span> {errors?.lastName}</span>
                                </div>
                            </>
                            ) : (
                            <></>
                            )}
                        </div>
                        </div>
                        <div className="row personal-info">
                        <div className="col-md-6 text-start">
                            <label>Mobile No<label class="field-required"> * </label></label>
                            <input
                            className="w-100"
                            type="text"
                            value={mobileNumber}
                            onChange={(e) => {
                                setMobileNumber(e.target.value);
                                formValidation();
                            }}
                            />
                            {errors?.mobileNumber ? (
                            <>
                                <div className="field-required ms-3 mt-2">
                                <span> {errors?.mobileNumber}</span>
                                </div>
                            </>
                            ) : (
                            <></>
                            )}
                        </div>
                        <div className="col-md-6 text-start">
                            <label>Email Id<label class="field-required"> * </label></label>
                            <input
                            className="w-100"
                            type="text"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                                formValidation();
                            }}
                            />
                            {errors?.email ? (
                            <>
                                <div className="field-required ms-3 mt-2">
                                <span> {errors?.email}</span>
                                </div>
                            </>
                            ) : (
                            <></>
                            )}
                        </div>
                        </div>
                        <div className="row personal-info my-3">
                        <div className="col-md-6 text-start">
                            <label>Joining Date<label class="field-required"> * </label></label>
                            <input
                            className="w-100"
                            type="text"
                            readonly
                            value={DDMMyyyyFormateDateSet(JoiningData)}
                            onChange={() => {
                                formValidation();
                            }}
                            />
                            {errors?.JoiningData ? (
                            <>
                                <div className="field-required ms-3 mt-2">
                                <span> {errors?.JoiningData}</span>
                                </div>
                            </>
                            ) : (
                            <></>
                            )}
                        </div>
                        <div className="col-md-6 text-start">
                            <label className="mb-3">Articles per Day</label>
                            <input
                            className="w-100"
                            type="text"
                            value={articlePerDay}
                            onChange={(e) => {
                                setArticlePerDay(e.target.value);
                            }}
                            />
                        </div>
                        </div>
                        <div className="row personal-info">
                        <div className="col-md-6 text-start">
                            <label className="mb-3">Cost Per Word</label>
                            <input
                            className="w-100"
                            type="text"
                            value={costPerWord}
                            onChange={(e) => {
                                setCostPerWord(e.target.value);
                            }}
                            />
                        </div>
                        <div className="col-md-6 text-start">
                            <label className="mb-3">Cost Per Article</label>
                            <input
                            className="w-100"
                            type="text"
                            value={costPerArticle}
                            onChange={(e) => {
                                setCostPerArticle(e.target.value);
                            }}
                            />
                        </div>
                        </div>
                    </div>

                    <div>
                        <div className="personal-doc ms-4 mt-3">
                        <p>
                            Upload Sample Work <span>(Only pdf files)</span>
                        </p>

                        <div className="end-box ms-0">
                            <div className="d-flex align-items-center justify-content-between personal-choose-file  p-3 w-50">
                            <input
                                className=""
                                type="file"
                                id="myfile1"
                                name="myfile1"
                                multiple={false}
                                accept={onlyPDF}
                                onChange={(e) => {
                                formValidation();
                                updateList();
                                }}

                            />
                            <div className="text-end">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>
                            </div>
                            </div>

                            {documentURL ? (
                            <a
                                className="ms-3 fs-6"
                                target={"_blank"}
                                href={GET_Server_URL + documentURL}
                            >
                                {documentURL}
                            </a>
                            ) : null}
                        </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="text-right">
                        <button
                            className="btn btn-outline-secondary"
                            onClick={() => {
                            
                            // history.push({ pathname: "/content-main/databaseContent/" });
                            }}
                        >
                            Cancel
                        </button>
                        <button
                            class="btn btn-primary ms-3"
                            onClick={() => {
                            if(formValidation())
                            {
                                updateFreelancerData();
                            }
                            }}
                        >
                            Update Details
                        </button>
                        </div>
                    </div>
                    </div>
                </div>
                </Tabs.TabPane>
                <Tabs.TabPane
                tab="Work History"
                key="workhistory">
              
              <div className="portlet work-history-cal mt-3">
              <div className="work-hisory-select">
              <select
                        class=" form-select "
                        placeholder="Myntra"
                        aria-label="Default select"
                        value={projectseleted}
                        onChange={(item) => {
                            if (item.target.value == "Select") {
                            setprojectseleted("");
                            } else {
                            setprojectseleted(item.target.value);
                            }
                        }}
                        >
                        {projectList?.map((item) => {
                            return <option value={item.id}>{item.name}</option>;
                        })}
                        </select>
                   </div>    
              </div>
                     
                    {/* <div class="row px-0">
                    <div class="col-md-4 ps-0">
                        <div className="ga">
                        <div>
                            <h4>Articles Completed</h4>
                            <h3>06</h3>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div className="ga">
                        <div>
                            <h4>Rework Nedded</h4>
                            <h3>03</h3>
                        </div>
                        </div>
                    </div>
                    <div class="col-md-4 pe-0">
                        <div className="ga">
                        <div>
                            <h4>Success Rate</h4>
                            <h3>50%</h3>
                        </div>
                        </div>
                    </div>
                    </div> */}

<div className="d-flex align-items-center justify-content-between p-0">
         
          
         <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3">
           <div className="raise-svg me-4">
             <svg
               xmlns="http://www.w3.org/2000/svg"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               stroke="#0691EF"
               stroke-width="2"
               stroke-linecap="round"
               stroke-linejoin="round"
               class="feather feather-file-text"
             >
               <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
               <polyline points="14 2 14 8 20 8"></polyline>
               <line x1="16" y1="13" x2="8" y2="13"></line>
               <line x1="16" y1="17" x2="8" y2="17"></line>
               <polyline points="10 9 9 9 8 9"></polyline>
             </svg>
           </div>
           <div>
             <label className="form-label">Articles Completed </label>
             <h3>
              06
             </h3>
           </div>
         </div>
         <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3 ms-3">
           <div className="raise-svg me-4">
             <svg
               xmlns="http://www.w3.org/2000/svg"
               width="24"
               height="24"
               viewBox="0 0 24 24"
               fill="none"
               stroke="#0691EF"
               stroke-width="2"
               stroke-linecap="round"
               stroke-linejoin="round"
               class="feather feather-repeat"
             >
               <polyline points="17 1 21 5 17 9"></polyline>
               <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
               <polyline points="7 23 3 19 7 15"></polyline>
               <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
             </svg>
           </div>
           <div>
             <label class="form-label">Rework Needed</label>
             <h3>03</h3>
           </div>
         </div>     

         <div className="raise-tab d-flex align-items-center jusify-content-between w-100 p-3 ms-3">
           <div className="raise-svg me-4">
           <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#0691EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-award"><circle cx="12" cy="8" r="7"></circle><polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline></svg>
           </div>
           <div>
             <label class="form-label">Success Rate</label>
             <h3>50%</h3>
           </div>
         </div>     
         </div> 

                    {/* <div class="all-ticket-box px-0 my-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="px-0 allticket-search">
                        <form class="search-box w-100 d-flex">
                            <button type="button">
                            <i class="fa fa-search"></i>
                            </button>
                            <input
                            value=""
                            type="text"
                            class="w-100"
                            placeholder="Search..."
                            name="search"
                            />
                        </form>
                        </div>
                        <div class="px-0 filter-create-btn">
                        <div class="create-btn ms-3 w-100">
                            <div class="btn btn-primary d-block">
                            <div class="d-flex align-items-center">
                                <span class="plus"> </span>
                                <span class="ms-2 ps-1">Export</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div> */}

<div class="work-history-box px-0 my-4">
                    <div class="d-flex align-items-center justify-content-between">
                        <div class="px-0 work-history-search">
                        <form class="search-box w-100 d-flex">
                            <button type="button">
                            <i class="fa fa-search"></i>
                            </button>
                            <input
                            value=""
                            type="text"
                            class="w-100"
                            placeholder="Search..."
                            name="search"
                            />
                        </form>
                        </div>
                        <div class="px-0 filter-create-btn">
                        <div class="create-btn ms-3 w-100">
                            <div class="btn btn-primary fs-6 fw-6 d-block">
                            <div class="d-flex align-items-center">
                                {/* <span class="plus"> </span> */}
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="#FFFFFF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10">
                                    </polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                <span class="ms-2 ps-1">Export</span>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>

                    <div className="col-md-12 px-0">
                    <Table
                    className="portlet table table-hover work-history-table "
                    columns={projectCols}
                    dataSource={projectWorkList}
                    />
                    </div>
                  
              
                </Tabs.TabPane>
            </Tabs>
        </>
    )
}
export default PersonalDetails