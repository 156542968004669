import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import APIManager from "../../services/APIManager";
import {
  createClient,
  getClientByOrgId,
  getCountry,
} from "../../utility/Constant";

const AddClients = (props) => {
  useEffect(() => {
    getCountryList();
    getClientList();
  }, []);

  const [filelistCustomer, setfilelistCustomer] = useState([]);
  function updateList() {
    var input = document.getElementById("createCustomerDocs");
    var data = [];
    for (var i = 0; i < input.files.length; ++i) {
      data.push(input.files.item(i).name);
    }
    setfilelistCustomer(input.files.item(0));
  }
  const [errors, setErrors] = useState("");
  const [Name, setName] = useState("");
  const [KeyPOC, setKeyPOC] = useState("");
  const [email, setEmail] = useState("");
  const [countrySelected, setCountrySelected] = useState("India");
  const [statusySelected, setStatusySelected] = useState("Active");
  const [countryList, setCountryList] = useState([]);
  const [statueList, setStatueList] = useState([
    { name: "Active", id: "0" },
    { name: "Lead", id: "1" },
    { name: "Prospect", id: "2" },
  ]);
  const [State, setState] = useState("");
  const [City, setCity] = useState("");
  const [PhoneNumber, setPhoneNumber] = useState("");
  const [CreatedBy, setCreatedBy] = useState("");
  const [Active, setActive] = useState(true);
  const [save, setsave] = useState(false);
  let history = useHistory();
  function getCountryList() {
    APIManager.getAPI(getCountry).then((response) => {
      if (response && response.status == 200) {
        setCountryList(response.data.data);
      }
    });
  }

  const [clientList, setClientList] = useState([]);
  function getClientList() {
    APIManager.getAPI(
      getClientByOrgId + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response.status == 200) {
        const list = [];
        response.data.map((item, index) => {
          let obj = {
            key: item.id,
            clientid: item.name,
          };
          list.push(obj);
        });
        setClientList(list);
      }
    });
  }
  function setcountry(e) {
    var c = e.target.value;
    setCountrySelected(c);
  }

  function formValidation() {
    let error = { ...errors };
    Name.trim() == ""
      ? (error["Name"] = "Client name is required!")
      : delete error["Name"];

    if (Name?.length) {
      var pattern = new RegExp(/^[a-zA-Z\s]*$/);

      !pattern.test(Name)
        ? (error["Name"] = "Please enter valid client name")
        : delete error["Name"];
    }

    clientList.map((i) => {
      let isFlag = false;
      if (i.clientid == Name) {
        error["Name"] = "Client name is Already Exist!";
        isFlag = true;
      }
    });
    KeyPOC.trim() == ""
      ? (error["KeyPOC"] = "Key point of contact is required!")
      : delete error["KeyPOC"];

    PhoneNumber.trim() == ""
      ? (error["PhoneNumber"] = "Phone number is required!")
      : delete error["PhoneNumber"];

    if (PhoneNumber?.length) {
      var pattern = new RegExp(/^[0-9\b]+$/);

      if (!pattern.test(PhoneNumber)) {
        error["PhoneNumber"] = "Please enter only number";
      } else if (PhoneNumber.length >= 15) {
        error["PhoneNumber"] =
          "Phone number must not exceed more than 15 characters.";
      } else if (PhoneNumber.length < 10) {
        error["PhoneNumber"] =
          "Phone number must not exceed less than 10 characters.";
      } else {
        delete error["PhoneNumber"];
      }
    }

    email.trim() == ""
      ? (error["email"] = "Email is required!")
      : delete error["email"];

    if (email?.length) {
      var pattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);

      if (pattern?.test(email) == false) {
        error["email"] = "Please enter valid Email!";
      }
    }

    countrySelected.trim() == ""
      ? (error["countrySelected"] = "Please select country!")
      : delete error["countrySelected"];

    setErrors(error);

    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  function clearForm() {
    setName("");
    setKeyPOC("");
    setState("");
    setCity("");
    setPhoneNumber("");
    setEmail("");
    setCreatedBy("");
    setAccounActive("");
    history.goBack();
  }

  function createClientItem() {
    var bodyFormData = new FormData();
    bodyFormData.append("Name", Name);
    bodyFormData.append("KeyPOC", KeyPOC);
    bodyFormData.append("Status", statusySelected);
    bodyFormData.append("Active", Active);
    bodyFormData.append("Country", countrySelected);
    bodyFormData.append("State", State);
    bodyFormData.append("City", City);
    bodyFormData.append("PhoneNumber", PhoneNumber);
    bodyFormData.append("Email", email);
    bodyFormData.append(
      "OrganizationId",
      localStorage.getItem("organizationId")
    );
    bodyFormData.append("CreatedBy", localStorage.getItem("userId"));
    if (filelistCustomer != "") {
      bodyFormData.append("Document.HolderId", localStorage.getItem("userId"));
      bodyFormData.append("Document.File", filelistCustomer);
    }

    APIManager.postAPI(createClient, {}, bodyFormData, false).then(
      (response) => {
        if (response && response.status == 200) {
          clearForm();
        }
      }
    );
  }

  function changeStatus(e) {
    let value = e.target.value;
    setStatusySelected(value);
  }

  const [isctiveAccount, setAccounActive] = useState(true);

  return (
    <>
      <div class="row">
        <div class="col-sm-5 pad-lzero">
          <div className="col-md-12 d-flex mb-3 p-0">
            <i
              class="fa fa-chevron-circle-left"
              style={{ fontSize: 20 + "px", color: "#2196f3" }}
              onClick={() => {
                history.goBack();
              }}
            ></i>
            <div class="main-title ms-3">Create Client</div>
          </div>
        </div>
        <div class="col-sm-7 add-new-btnw"></div>
      </div>

      <div className="box-shadow p-4 bg-white">
        <div class="common-wrapper">
          <div class="common-wcard">
            <div class="common-form-fields">
              <div class="add-user">
                <div class="col-md-12">
                  <label>Client {save}</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    required
                    placeholder="Enter Client"
                    value={Name}
                    onChange={(text) => {
                      setName(text.target.value);
                      formValidation();
                    }}
                  />
                </div>
                {save && errors?.Name && (
                  <>
                    <div className="field-required">
                      <span> {errors?.Name}</span>
                    </div>
                  </>
                )}

                <div class="col-md-12 mt-3">
                  <label>Key Point of Contact</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    required
                    placeholder="Enter Key Point of Contact"
                    value={KeyPOC}
                    onChange={(text) => {
                      setKeyPOC(text.target.value);
                      formValidation();
                    }}
                  />
                </div>
                {save && errors?.KeyPOC && (
                  <>
                    <div className="field-required">
                      <span> {errors?.KeyPOC}</span>
                    </div>
                  </>
                )}
                <div class="col-md-12 mt-3">
                  <label>Status</label>
                  <select value={statusySelected} onChange={changeStatus}>
                    {statueList.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </div>
                <div class="col-md-12 mt-3">
                  <label>Country</label>
                  <label class="field-required"> * </label>
                  <select
                    required
                    value={countrySelected}
                    onChange={setcountry}
                  >
                    {countryList.map((item) => {
                      return <option value={item.name}>{item.name}</option>;
                    })}
                  </select>
                </div>
                <div class="col-md-12 mt-3">
                  <label>State</label>
                  <input
                    type="text"
                    placeholder="Enter State"
                    value={State}
                    onChange={(text) => {
                      setState(text.target.value);
                    }}
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <label>City</label>
                  <input
                    type="text"
                    placeholder="Enter City"
                    value={City}
                    onChange={(text) => {
                      setCity(text.target.value);
                    }}
                  />
                </div>
                <div class="col-md-12 mt-3">
                  <label>Phone Number</label>
                  <label class="field-required"> * </label>
                  <br />
                  <div style={{ display: "flex" }}>
                    <input
                      type="text"
                      required
                      placeholder="Enter Phone Number"
                      value={PhoneNumber}
                      onChange={(text) => {
                        setPhoneNumber(text.target.value);
                        formValidation();
                      }}
                    />
                  </div>
                </div>
                {save && errors?.PhoneNumber && (
                  <>
                    <div className="field-required">
                      <span> {errors?.PhoneNumber}</span>
                    </div>
                  </>
                )}
                <div class="col-md-12 mt-3">
                  <label>Email</label>
                  <label class="field-required"> * </label>
                  <input
                    type="text"
                    value={email}
                    required
                    placeholder="Enter Email"
                    onChange={(text) => {
                      setEmail(text.target.value);
                      formValidation();
                    }}
                  />
                </div>
                {save && errors?.email && (
                  <>
                    <div className="field-required">
                      <span> {errors?.email}</span>
                    </div>
                  </>
                )}

                <div class="col-md-12 mt-3">
                  <label>Account Activation</label>
                  <br />
                  <label class="switch">
                    <input
                      type="checkbox"
                      checked={!isctiveAccount}
                      onClick={() => {
                        setAccounActive(!isctiveAccount);
                      }}
                    />
                    <span class="slider round"></span>
                  </label>
                </div>
                <div class="col-md-12 mt-3">
                  <label>Document Upload</label>
                  <br />
                  <input
                    type="file"
                    id="createCustomerDocs"
                    name="createCustomerDocs"
                    multiple={false}
                    onChange={updateList}
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 text-right mt-3 pb-5">
            <button
              className="btn btn-primary me-3"
              onClick={() => {
                history.goBack();
                clearForm();
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                setsave(true);
                if (formValidation()) {
                  createClientItem();
                }
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddClients;