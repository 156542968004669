import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSerpTableData, setStartRankScript, uploadFile, downloadDocument } from "../../services/api";
// import { API_STATUS } from "../../utils/constants";
import EncryptDecrypt from './../../utils/Encrypt&Decrypt';
import { toast } from "react-hot-toast";


const namespace = "serptracker";

const initialState = {
    loading: "initial",
    errorMessage: null,
    serpListData: null,
    uploadData: null,
    downloadData: null,
    count:null,

};

export const getSerplist = createAsyncThunk(
    `/main/serptracker`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        console.log('test');
        try {
            console.log(postData, 'register reducer');
            const data = await getSerpTableData(postData);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);

export const setStartRank = createAsyncThunk(
    `/main/serptracker`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        console.log('test');
        try {
            console.log(postData, 'register reducer');
            const data = await setStartRankScript(postData);
            if (data?.data == 'Status Updated') {
                toast.success('Ranking Script Started Successfully.')
                setTimeout((function () {
                    window.location.reload();
                }), 1000);
            }
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }

)

export const uploadKeyWord = createAsyncThunk(
    `/main/serptracker`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        console.log('test');
        try {
            console.log(postData, 'register reducer');
            const data = await uploadFile(postData);
            return data;
        } catch (error) {
            console.log("getScanCount error--->", error);
            return rejectWithValue(error.response);
        }
    }
);
export const downloadFile = createAsyncThunk(
    `/main/serptracker`,
    async ({ postData }, { rejectWithValue, dispatch }) => {
        console.log('test');
        try {
            console.log(postData, 'registerrrrr');
            const data = await downloadDocument(postData);
            return data;
        } catch (error) {
            console.log("error5555", error);
            return rejectWithValue(error.response);
        }
    }
);
const serptrackerSlice = createSlice({
    name: namespace,
    initialState,
    reducers: {
        clearData: () => {
            return initialState;
        },
    },
    extraReducers: {
        [getSerplist.pending](state) {
            // state.loading = API_STATUS.PENDING;
        },
        [getSerplist.fulfilled](state, { payload }) {
            // state.loading = API_STATUS.FULFILLED;
            console.log(payload, 'payload');
            let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'));
            // let payloaddatas = JSON.parse(decryptdata(payload?.data?.datas, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'));
            console.log(payloaddatas, 'payloaddatas');
            state.serpListData = payloaddatas.data;
state.count=payloaddatas.count
            // localStorage.setItem('authToken',payload?.data?.accessToken)
            // localStorage.setItem('isAuthenticated',false)
            // localStorage.setItem('username',payload?.data?.username)
        },
        [getSerplist.rejected](state, action) {
            // state.loading = API_STATUS.REJECTED;
            state.errorMessage = action?.payload?.data.message;
        },
        // [uploadKeyWord.pending](state) {
        //     // state.loading = API_STATUS.PENDING;
        // },
        // [uploadKeyWord.fulfilled](state, { payload }) {
        //     // state.loading = API_STATUS.FULFILLED;
        //     console.log(payload, 'payload');
        //     let payloaddatas = JSON.parse(EncryptDecrypt.decryptdata(payload?.data?.datas, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'));
        //     // let payloaddatas = JSON.parse(decryptdata(payload?.data?.datas, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'));
        //     console.log(payloaddatas, 'payloaddatas');
        //     state.uploadData = payloaddatas.data;
        //     // localStorage.setItem('authToken',payload?.data?.accessToken)
        //     // localStorage.setItem('isAuthenticated',false)
        //     // localStorage.setItem('username',payload?.data?.username)
        // },
        // [uploadKeyWord.rejected](state, action) {
        //     // state.loading = API_STATUS.REJECTED;
        //     state.errorMessage = action?.payload?.data.message;
        // },

    },
});

export const { clearData } = serptrackerSlice.actions;

export const serpTrackerSelector = (state) => state.serptracker;

export default serptrackerSlice.reducer;
