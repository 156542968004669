import React, { useState, useEffect, useRef } from "react";
import { Tabs, Image, Modal, Drawer } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import {
  days,
  DDMMyyyyFormateDateSet,
  formatdatetime,
  formateDateIntoDatemonth,
  formateDateIntoDatemonthTime,
  mmDDyyyyFormateDateSet,
  mmDDyyyyFormateForForm,
  mmDDyyyyFormateForForm23time,
  ticketpriority,
} from "../../utility/Globals";
import APIManager from "../../services/APIManager";
import { saveAs } from "file-saver";
import {
  addattachment,
  createconversations,
  createTicketNotes,
  getAllTicketNotes,
  getattached,
  getMember,
  getTickestAllconversation,
  getTimeline,
  GET_Server_URL,
  pickupticket,
  prioritystatus,
  ticketsdetails,
  updateTicketETA,
  updateTicketStatus,
} from "../../utility/Constant";
import toast from "react-hot-toast";
import Picker from "emoji-picker-react";
import { default as ReactSelect } from "react-select";

function SubTicketView() {
  const [notesArr, setnotesArr] = useState([]);
  const [notes, setnotes] = useState("");
  const [notesErr, setnotesErr] = useState(false);
  const [notesErrMsg, setnotesErrMsg] = useState("");
  const [conversationErr, setconversationErr] = useState(false);
  const [conversationErrMsg, setconversationErrMsg] = useState("");
  const [assignedStatus, setassignedStatus] = useState("");
  const location = useLocation();
  const history = useHistory();

  const [visible, setVisible] = useState(false);
  const [scaleStep, setScaleStep] = useState(0.5);
  const [fileName, setfilename] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");
  const [assignId, setassignId] = useState("");
  const [ETA, setETA] = useState("");
  const timelineenumlist = [
    { value: "0", lable: "Tickets Create" },
    { value: "1", lable: "Tickets Update " },
    { value: "2", lable: "Tickets Assign" },
    { value: "3", lable: "Tickets Conversation" },
    { value: "4", lable: "Tickets Attachment " },
    { value: "5", lable: "Tickets Notes" },
    { value: "6", lable: "Tickets ETA" },
  ];

  const ref = useRef(null);
  const [Emojiopen, setEmojiOpen] = useState(false);
  const [draweropen, setdraweropen] = useState(false);
  const [linklist, setlinklist] = useState("");
  const [linkname, setlinkname] = useState([]);
  const [size, setSize] = useState();
  const [ticketnumber, setticketnumber] = useState("");
  const showDrawer = () => {
    setdraweropen(true);
  }
  const drawerclose = () => {
    setlinklist("");
    setdraweropen(false);
  }
  useEffect(() => {
    getAllNotes();
    getalltiketsconversation();
    getattachement();
    if (location.state) {

      setticketsubject(location.state.item.subject);
      setticketId(location.state.item.ticketid);
      setassignedStatus(location.state.item.assignedStatus);
      setTicketStatus(location.state.item.status);
      setassignId(location.state.item.assignedMemberId);
      setETA(mmDDyyyyFormateDateSet(location.state.item.eta));
      setticketnumber(location.state.viewtickets.ticketnumber)

    }
    getmembers();
    getTimelineData();
    gettciketsdetails();
  }, []);
  const inputFileRef = useRef(null);
  const inputFileRefs = useRef(null);

  const onBtnClick = () => {
    inputFileRef.current.click();
  };

  function formValidation() {
    let isValidFields = true;
    if (notes.trim() == "") {
      isValidFields = false;
      setnotesErr(true);
      setnotesErrMsg("Notes is required!");
    } else {
      setnotesErr(false);
      setnotesErrMsg("");
    }
    return isValidFields;
  }

  function ConversationformValidation() {
    let isValidFields = true;
    if (conversation.trim() == "") {
      isValidFields = false;
      setconversationErr(true);
      setconversationErrMsg("Conversation Message is required!");
    } else {
      setconversationErr(false);
      setconversationErrMsg("");
    }
    return isValidFields;
  }
  const [ticketId, setticketId] = useState("");
  const [ticketsubject, setticketsubject] = useState("");
  const [priority, setpriority] = useState("0");
  const [fileUpload, setfileUpload] = useState([]);
  const [memberlist, setmemberlist] = useState();
  const [memberlistSelected, setmemberlistSelected] = useState();
  const [conversation, setconversation] = useState("");
  const [ticketsconversationlist, setticketsconversationlist] = useState([]);
  const [attachedlist, setattachedlist] = useState([]);
  const [timeline, settimeline] = useState([]);
  const notifyAdd = () => toast.success("Successfully Add!");
  const [prirotyid, setprirotyid] = useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [openpopup, setopenpopup] = useState(false);
  const [modalText, setModalText] = useState("Content of the modal");
  const [tickettypeid, settickettypeid] = useState("");
  const [units, setunits] = useState("");
  const showModal = () => {
    setopenpopup(true);
    setnotesErr(false);
  };
  const handleCancel = () => {
    setopenpopup(false);
    setnotesErr(false);
  };
  const handleOk = () => {
    setModalText("The modal will be closed after two seconds");
    setConfirmLoading(true);
    setTimeout(() => {
      setopenpopup(false);
      setConfirmLoading(false);
    }, 2000);
    if (formValidation()) {
      let noteData = {
        TicketId: location.state.item.ticketid,
        Notes: notes,
        NotesBy: localStorage.getItem("userId"),
      };
      createNotes(noteData);
    }
  };
  const customStyles = {
    control: base => ({
      ...base,
      border: "1px solid #212121",
      // This line disable the blue border
      boxShadow: 'none',
      height: 43,
      fontSize: '1.1rem'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '43px',
      padding: '0 12px'
    }),
    dropdownIndicator: base => ({
      ...base,
      color: "#212121" // Custom colour
    }),
    singleValue: (provided) => ({
      ...provided,
      color: '212121',
      fontWeight: "500",
    })
  };
  function updateFile() {
    var input = document.getElementById("fileUpload");
    const a = [];
    for (var i = 0; i < input.files.length; ++i) {
      a.push(input.files.item(i));
    }
    setfileUpload(a);
  }

  function createNotes(rowData) {
    APIManager.postAPI(createTicketNotes, rowData, null, true).then(
      (response) => {
        if (response?.status == 200) {
          clearForm();
          setnotes("");
          notifyAdd();
          getAllNotes();
        }
      }
    );
  }

  function getAllNotes() {
    APIManager.getAPI(
      getAllTicketNotes + location.state.item.ticketid + "/notes"
    ).then((response) => {
      if (response?.status == 200) {
        const notesList = [];
        response.data.map((item) => {
          let obj = {
            createdon: DDMMyyyyFormateDateSet(item.createdon),
            createBy: item.member.name,
            notes: item.notes,
          };
          notesList.push(obj);
        });
        setnotesArr(notesList);
      }
    });
  }

  function getmembers() {
    APIManager.getAPI(getMember).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            name: item.name,
            key: item.id,
            value: item.id,
            label: item.name,
          };
          list.push(obj);
        });
        setmemberlist(list);
        setmemberlistSelected(list[0].id);
      }
    });
  }

  function createClientData() {
    const current = new Date();
    var bodyFormData = new FormData();
    bodyFormData.append("TicketId", location.state.item.ticketid);
    bodyFormData.append("Comment", conversation);
    bodyFormData.append("CommentBy", localStorage.getItem("userId"));
    setfileUpload([]);
    if (fileUpload?.length) {
      fileUpload.map((file) => {
        bodyFormData.append("Files", file);
      });
    }
    APIManager.postAPI(createconversations, {}, bodyFormData, false).then(
      (response) => {
        if (response?.status == 200) {
          clearForm();
          getalltiketsconversation();

        }
      }
    );
  }
  function clearForm() {
    setconversation("");
  }
  function getalltiketsconversation() {
    APIManager.getAPI(
      getTickestAllconversation + location.state.item.ticketid + "/conversation"
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            comment: item.comment,
            commentBy: item.commentBy,
            createdOn: item.createdOn,
            member: item.member,
            ticketsConversationAttachments: item.ticketsConversationAttachments,
          };
          list.push(obj);
        });

        setticketsconversationlist(list);
        getattachement();
      }
    });
  }

  function handleaddattachment(dataFile) {
    setlinkname([]);
    var bodyFormData = new FormData();
    bodyFormData.append("TicketId", location.state.item.ticketid);
    linkname.map((item, i) => {
      bodyFormData.append(`links[${i}]`, item);
    })
    bodyFormData.append("CreatedOn", mmDDyyyyFormateForForm23time(new Date()));
    setfileUpload([]);
    if (fileUpload?.length) {
      fileUpload.map((file) => {
        bodyFormData.append("Files", file);
      });
    }

    APIManager.postAPI(addattachment, {}, bodyFormData, false).then(
      (response) => {
        if (response?.status == 200) {
          getattachement();
          drawerclose();
        }
      }
    );
  }

  function getattachement() {
    APIManager.getAPI(
      getattached + location.state.item.ticketid + "/attachment"
    ).then((response) => {
      if (response && response.status == 200) {
        const attached = [];
        response.data.map((item) => {
          let obj = {
            fileName: item.fileName,
            createdOn: item.createdOn,
            fileType: item.fileType
          };
          attached.push(obj);
        });
        setattachedlist(attached);
      }
    });
  }

  function pickupData(sts) {
    let rowData = {
      TicketId: location.state.item.ticketid,
      MemberId: location.state.item.assignedMemberId,
      AssignedOn: mmDDyyyyFormateForForm(new Date()),
      Status: sts,
      CreatedBy: location.state.item.createdBy
    };
    APIManager.postAPI(pickupticket, rowData, null, true).then((response) => {
      if (response && response.status == 200) {
        if (response.data.ticketsAssignmentId) {
          setassignedStatus("1");
          setTicketStatus("5");
        } else {
          setassignedStatus("0");
        }
      }
    });
  }

  function getTimelineData() {
    APIManager.getAPI(
      getTimeline + location.state.item.ticketid + "/timeline"
    ).then((response) => {
      if (response && response.status == 200) {
        const timeline = [];
        response.data.map((item) => {
          let obj = {
            comments: item.comment,
            membername: item.member?.name,
            etaDate: item.tickets.eta,
            createdOn: item.createdOn,
            timelineType: item.timelineType,
          };
          timeline.push(obj);
        });
        settimeline(timeline.reverse());
      }
    });
  }

  function updateTicketSts(sts) {
    APIManager.putAPI(
      updateTicketStatus +
      "?ticketId=" +
      location.state.item.ticketid +
      "&ticketsStatus=" +
      sts,
      {
        ticketId: location.state.item.ticketid,
        ticketsStatus: sts,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        if (response.data == true) {
          setTicketStatus(sts);
        }
      }
    });
  }

  function AssignTickets(getdata) {
    APIManager.postAPI(pickupticket, getdata, null, true).then((response) => {
      if (response && response.status == 200) {
        setassignId(getdata.MemberId);
        const stateCopy = { ...location.state };
        stateCopy.item.assignedMemberId = getdata.MemberId;
        history.replace({ state: stateCopy });
      }
    });
  }

  function _handleKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      if (ConversationformValidation()) {
        createClientData();
      }
    }
  }
  function _handleKeyDownNote(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      if (formValidation()) {
        let noteData = {
          TicketId: location.state.item.ticketid,
          Notes: notes,
          NotesBy: localStorage.getItem("userId"),
        };
        createNotes(noteData);
        setopenpopup(false);
      }
    }
  }

  function _handleKeyLinkAdd(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      addurl();
    }
  }

  function prooritystatuschange(getdata) {
    APIManager.putAPI(
      prioritystatus +
      "ticketId=" +
      getdata.ticketid +
      "&memberId=" +
      localStorage.getItem("userId") +
      "&priorityList=" +
      getdata.priorityList,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setprirotyid(getdata.priorityList);
        const stateCopy = { ...location.state };
        stateCopy.item.priorityId = getdata.priorityList;
        history.replace({ state: stateCopy });
      }
    });
  }

  function prioritylist(val) {
    var finalVal = val;
    ticketpriority.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }

  const onEmojiClick = (event, emojiObject) => {
    const cursor = ref.current.selectionStart;
    const text =
      conversation.slice(0, cursor) +
      emojiObject.emoji +
      conversation.slice(cursor);
    setconversation(text);
  };

  function changeETA(getdata) {
    APIManager.putAPI(
      updateTicketETA +
      "?ticketId=" +
      getdata.ticketid +
      "&etaDate=" +
      getdata.etaDate,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setprirotyid(getdata.priorityList);
        const stateCopy = { ...location.state };
        stateCopy.item.etaDate = getdata.etaDate;
        history.replace({ state: stateCopy });
      }
    });
  }

  function addurl() {
    let url = linkname;
    url.push(linklist);
    setlinkname(url);
    setlinklist("");
  }

  function gettciketsdetails() {
    APIManager.getAPI(ticketsdetails + location.state.item.ticketid, null, true).
      then((response) => {
        if (response?.status == 200) {
          settickettypeid(response.data[0].ticketTypeId);
          setunits(response.data[0].units);
        }
      });
  }

  return (
    <>
      <div class="top-title d-flex ticket-bredcrumb-top">
        <ul>
          <li>
            <a class="top-tickets mb-0 fw-bold text-capitalize" onClick={() => {
              history.goBack();
            }}>{location?.state?.viewtickets?.IsTeam == 1 ? (<>Team Tickets</>) : (<>My Tickets</>)}</a>
          </li>
          <li className="mx-4"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
          <li>
            <a class="mb-0 text-capitalize fw-bold active" onClick={() => {
              history.goBack();
            }}>
              #{ticketnumber}
            </a>
          </li>
          <li className="mx-4"><i class="fa fa-angle-right" aria-hidden="true"></i></li>
          <li class="fw-bold">
            #{location.state.item.ticketnumber}
          </li>
        </ul>
      </div>
      <div class="row view-ticket-tab-row">
        <div class="col-md-9 col-sm-12 ps-0 newticket-view">
          <div class="portlet view-ticket-box">
            <div class="d-flex ticket-name-box">
              <div class="icon">
                <img class="ticket-icon" src="/images/sub-tic.png" />
              </div>
              <div class="text ms-3">
                <h5 class="title fw-bold">{location.state.item.ticketsub}</h5>
                <div class="d-flex number-date grey-text">
                  <span class="number"> #{location.state.item.ticketnumber}</span>
                  <span class="date-time ms-4">
                    {formateDateIntoDatemonthTime(location.state.item.createdOn)}
                  </span>
                </div>
                <div class="d-flex mt-3">
                  <div class="subticket-lable" >
                    <span>Subticket {location.state.item.count}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Tabs className="portlet main-view-ticket">
            <Tabs.TabPane
              tab={"Conversations (" + ticketsconversationlist?.length + ")"}
              key="item-1"
            >
              <div class="title-content-tab view-ticket-box-tabs">
                <div class="tab-content p-0 mt-0" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    <div class="conversation-tab">
                      {ticketsconversationlist.map((item) => {
                        let firstltter = item.member.name;
                        return (
                          <>
                            <div class="profile-box portlet mb-0">
                              <div class="d-flex">
                                <div class="profile">
                                  <div class="name-first-latter">
                                    {firstltter.charAt(0)}
                                  </div>
                                </div>
                                <div class="text ms-3 w-100">
                                  <h5
                                    class="name-title fw-bold 
                                                     text-capitalize mb-1"
                                  >
                                    {item.member.name}
                                  </h5>
                                  <span class="grey-text">
                                    {days(item.createdOn)}
                                  </span>
                                  <p class="mt-4 mb-3 pb-4 border-bottom">
                                    {item.comment}
                                  </p>
                                  <div class="d-flex justify-content-between align-items-center mb-3">
                                    <span class="attachment fw-bold text-capitalize">
                                      {item.ticketsConversationAttachments.length == "" ? (<></>) : (<>{
                                        item.ticketsConversationAttachments
                                          ?.length
                                      }{" "}attachments</>)}
                                    </span>
                                    <div className="download-all">
                                      <a
                                        onClick={() => {
                                          item.ticketsConversationAttachments?.map(
                                            (i) => {
                                              i.fileName.includes(".jpg") ||
                                                i.fileName.includes(".png") ||
                                                i.fileName.includes(".jpeg") ||
                                                i.fileName.includes(".txt") ||
                                                i.fileName.includes(".pdf")
                                                ? saveAs(
                                                  GET_Server_URL + i.fileName,
                                                  i.fileName.split(`\\`)[1]
                                                )
                                                : window.open(
                                                  GET_Server_URL + i.fileName
                                                );
                                            }
                                          );
                                        }}
                                        class="download-btn text-capitalize"
                                      >
                                        {item.ticketsConversationAttachments
                                          ?.length > 1
                                          ? "download all"
                                          : "download"}
                                      </a>
                                    </div>
                                  </div>
                                  <div>
                                    <div className="row mt-3 me-3">
                                      {item.ticketsConversationAttachments?.map(
                                        (i) => {
                                          return (
                                            <>
                                              <div class="col-md-4 col-sm-12 ps-0">
                                                <div class="d-flex attchment align-items-center text-break">
                                                  {i.fileName.includes(".jpg") ||
                                                    i.fileName.includes(".png") ||
                                                    i.fileName.includes(".jpeg") ? (
                                                    <i class="fa fa-image me-3"></i>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {i.fileName.includes(".pdf") ? (
                                                    <i class="fa fa-file-pdf me-3"></i>
                                                  ) : (
                                                    <></>
                                                  )}
                                                  {i.fileName.includes(".txt") ? (
                                                    <img
                                                      src="/images/sub-pdf.png"
                                                      class="me-3"
                                                      alt="#"
                                                    />
                                                  ) : (
                                                    <></>
                                                  )}
                                                  <label
                                                    id="fileLabel"
                                                    class="m-0"
                                                  >
                                                    {i.fileName.split(`\\`)[1]}
                                                  </label>
                                                </div>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })}
                      {Emojiopen ? (
                        <>
                          <Picker onEmojiClick={onEmojiClick} />
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div class="conversation-type-box pt-4">
                      <form className="mx-3">
                        <div class="portlet form-group">
                          <input
                            type="text"
                            class="form-control"
                            ref={ref}
                            placeholder="Write your message here"
                            value={conversation}
                            onKeyDown={(e) => {
                              _handleKeyDown(e);
                            }}
                            onChange={(item) => {
                              setconversation(item.target.value);
                            }}
                          />
                          {conversationErr ? (
                            <div className="field-required">
                              <span className="conversation-msg">{conversationErrMsg}</span>
                            </div>
                          ) : (
                            <></>
                          )}

                          <div class="d-flex icon-box">
                            <a
                              type="file"
                              onClick={onBtnClick}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round"
                                class="feather feather-paperclip"><path d="M21.44 11.05l-9.19 9.19a6 6 0 0 1-8.49-8.49l9.19-9.19a4 4 0 0 1 5.66 5.66l-9.2 9.19a2 2 0 0 1-2.83-2.83l8.49-8.48"></path></svg>

                              <input
                                style={{ display: "none" }}
                                type="file"
                                id="fileUpload"
                                name="fileUpload"
                                multiple={true}
                                onChange={updateFile}
                                ref={inputFileRef}
                              />
                            </a>
                            <a className="mx-3"
                              onClick={() => {
                                setEmojiOpen(!Emojiopen);
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-smile"><circle cx="12" cy="12" r="10"></circle><path d="M8 14s1.5 2 4 2 4-2 4-2"></path><line x1="9" y1="9" x2="9.01" y2="9"></line><line x1="15" y1="9" x2="15.01" y2="9"></line></svg>
                            </a>
                            <a
                              onClick={() => {
                                if (ConversationformValidation()) {
                                  createClientData();
                                }
                              }}>
                              {" "}
                              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-send"><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></svg>

                            </a>

                          </div>
                        </div>
                      </form>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  ></div>

                  <div
                    class="tab-pane fade"
                    id="pills-timeline"
                    role="tabpanel"
                    aria-labelledby="pills-timeline-tab"
                  ></div>
                  <div
                    class="tab-pane fade show active"
                    id="pills-notes"
                    role="tabpanel"
                    aria-labelledby="pills-notes-tab"
                  ></div>
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Attachments" key="item-2">
              <div class="portlet attachment-tab border-0">
                <div class="title">
                  <div class="d-flex justify-content-between">
                    <div class="text">
                      <h5 class="text-capitalize mb-1">
                        attachments for tickets
                      </h5>
                      <span class="grey-text">
                        All the attachment of the tickets are listed below
                      </span>
                    </div>
                    <div class="outline-attch-btn mt-2 pt-1">
                      <a onClick={showDrawer}>
                        {" "}
                        + add attachment

                      </a>
                    </div>
                  </div>
                </div>
                <div class="attach-content-box">
                  {attachedlist.map((item) => {

                    return (
                      <>
                        <div class="content-list d-flex justify-content-between align-items-center border-top">
                          <div class="text-box d-flex align-items-center">
                            <div class="icon-single">
                              {item.fileName.includes(".jpg") ||
                                item.fileName.includes(".png") ||
                                item.fileName.includes(".jpeg") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-image"
                                >
                                  <rect
                                    x="3"
                                    y="3"
                                    width="18"
                                    height="18"
                                    rx="2"
                                    ry="2"
                                  ></rect>
                                  <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                  <polyline points="21 15 16 10 5 21"></polyline>
                                </svg>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".pdf") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-file-text"
                                >
                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                  <polyline points="14 2 14 8 20 8"></polyline>
                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                  <polyline points="10 9 9 9 8 9"></polyline>
                                </svg>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".txt") ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="#212121"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  class="feather feather-file-text"
                                >
                                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                                  <polyline points="14 2 14 8 20 8"></polyline>
                                  <line x1="16" y1="13" x2="8" y2="13"></line>
                                  <line x1="16" y1="17" x2="8" y2="17"></line>
                                  <polyline points="10 9 9 9 8 9"></polyline>
                                </svg>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".xlsx") ? (
                                <i
                                  class="fa fa-file-excel"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <>
                                </>
                              )}
                              {item.fileType.includes("Url") ? (<>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#212121" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg></>) : (<>
                                </>)}
                            </div>

                            <div class="text ms-3">
                              <h5 class="text-capitalize">
                                {item.fileType.includes("Url") ? item.fileName : item.fileName.split(`\\`)[1]}
                              </h5>
                              <span class="grey-text date-time">
                                {formateDateIntoDatemonthTime(item.createdOn)}
                              </span>

                              <Image
                                width={100}
                                style={{ display: "none" }}
                                src={fileName}
                                preview={{
                                  visible,
                                  scaleStep,
                                  src: fileName,
                                  onVisibleChange: (value) => {
                                    setVisible(value);
                                  },
                                }}
                              />
                            </div>
                          </div>
                          <div class="icon">
                            <div class="d-flex align-items-center justify-content-between">
                              {item.fileName.includes(".jpg") ||
                                item.fileName.includes(".png") ||
                                item.fileName.includes(".jpeg") ? (

                                <a
                                  onClick={() => {
                                    setfilename(GET_Server_URL + item.fileName);
                                    setVisible(true);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#212121"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </a>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".pdf") || item.fileType.includes("Url") ? (
                                <a
                                  href={item.fileType.includes("Url") ? item.fileName : GET_Server_URL + item.fileName}
                                  target="_blank"
                                >

                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#212121"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    class="feather feather-eye"
                                  >
                                    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                    <circle cx="12" cy="12" r="3"></circle>
                                  </svg>
                                </a>
                              ) : (
                                <></>
                              )}
                              {item.fileName.includes(".jpg") ||
                                item.fileName.includes(".png") ||
                                item.fileName.includes(".jpeg") ||
                                item.fileName.includes(".pdf") ? (
                                <a
                                  target="_blank"
                                  onClick={() => {
                                    saveAs(
                                      GET_Server_URL + item.fileName,
                                      item.fileName.split(`\\`)[1]
                                    );
                                  }}
                                >
                                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-download"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg>
                                </a>
                              ) : (
                                <> {!item.fileType.includes("Url") ? (<>
                                  <a
                                    href={GET_Server_URL + item.fileName}
                                    target="_blank"
                                    download
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="#212121"
                                      stroke-width="2"
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      class="feather feather-download"
                                    >
                                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                      <polyline points="7 10 12 15 17 10"></polyline>
                                      <line
                                        x1="12"
                                        y1="15"
                                        x2="12"
                                        y2="3"
                                      ></line>
                                    </svg>
                                  </a>
                                </>) : (<></>)
                                }</>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    );
                  })}
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Timeline" key="item-3">
              <div class="portlet timeline-box border-0">
                <div class="timeline-list">
                  <div class="outer">
                    {timeline.map((item) => {
                      return (
                        <>
                          <div class="card">
                            <div class="icon">
                              {item.timelineType == "0" ? (
                                <i class="fa fa-ticket" aria-hidden="true"></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "1" ? (
                                <i
                                  class="bi bi-ticket-detailed-fill"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "2" ? (
                                <i class="fa fa-user" aria-hidden="true"></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "3" ? (
                                <i
                                  class="far fa-comment-alt"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "4" ? (
                                <i
                                  class="fa fa-paperclip"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "5" ? (
                                <i class="fa fa-ticket" aria-hidden="true"></i>
                              ) : (
                                ""
                              )}
                              {item.timelineType == "6" ? (
                                <i class="fa fa-clock" aria-hidden="true"></i>
                              ) : (
                                ""
                              )}
                            </div>
                            <div class="info">
                              <h6 class="title">
                                {formatdatetime(item.createdOn)}
                              </h6>
                              <span class="grey-text">
                                {item.comments ? item.comments : "No commmnets"}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Tabs.TabPane>

            <Tabs.TabPane tab="Notes" key="item-4">
              <div class="portlet notes-tab border-0">
                <div class="note-box d-flex justify-content-between align-items-top">
                  <div class="form-group mb-0">
                    <h6 class="title">notes</h6>
                    <span class="grey-text">
                      All the notes made for this ticket is listed below
                    </span>
                  </div>
                  <div class="outline-attch-btn">
                    <a className="mt-1 d-block"
                      onClick={() => {
                        showModal();
                      }}
                    >
                      {" "}
                      + add notes
                    </a>
                  </div>
                </div>
                <Modal
                  className="create-note-modal"
                  title="Create Note"
                  open={openpopup}
                  onOk={handleOk}
                  okText="Create Note"
                  confirmLoading={confirmLoading}
                  onCancel={handleCancel}
                ><textarea
                    type="text"
                    class="form-control mb-0 m-h-100"
                    maxLength={1000}
                    value={notes}
                    onKeyDown={(e) => {
                      _handleKeyDownNote(e);
                    }}
                    onChange={(e) => {
                      setnotes(e.target.value);
                    }}
                    aria-describedby="emailHelp"
                    placeholder="Start writing to leave note"
                  /> {notesErr ? (
                    <div className="field-required mt-3">
                      <span>{notesErrMsg}</span>
                    </div>
                  ) : (
                    <></>
                  )}</Modal>
                {notesArr.map((item) => {
                  return (
                    <>
                      <div class="note-posted mt-4">
                        <div class="d-flex justify-content-between mb-2">
                          <div class="note-by d-flex">
                            <div class="note-btn">
                              <a href="#">note</a>
                            </div>
                            <div class="by ms-3">
                              <span class="grey-text text-capitalize">
                                by {item.createBy}
                              </span>
                            </div>
                          </div>

                          <span class="grey-text posted-date text-capitalize">
                            {formateDateIntoDatemonthTime(item.createdOn)}
                          </span>
                        </div>
                        <p class="mb-0 mt-3">{item.notes}</p>
                      </div>
                    </>
                  );
                })}
              </div>
            </Tabs.TabPane>
          </Tabs>
        </div>

        <div class="col-md-3 col-sm-12 pe-0">
          <div class="view-ticket-filters-box">
            <div class="create-btn">
              {(ticketStatus == "1" && location.state.item.assignedMemberId ==
                localStorage.getItem("userId")) || (ticketStatus == "4" && location.state.item.assignedMemberId ==
                  localStorage.getItem("userId")) ? (
                <>
                  <div class="d-inline-block d-flex align-items-center justify-content-center mb-4">
                    <button
                      class="btn btn-primary w-100 py-3"
                      onClick={() => {
                        pickupData("5");
                      }}
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>{" "}
                      <span class="ms-2 text-capitalize"> pickup ticket</span>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}

              {location.state.item.assignedMemberId ==
                localStorage.getItem("userId") && ticketStatus == "5" ?
                (
                  <>
                    <div class="d-inline-block d-flex align-items-center justify-content-center mb-4">
                      <button
                        class="btn btn-success w-100 py-3"
                        onClick={() => {
                          updateTicketSts("2");
                        }}
                      >
                        <i class="fa fa-check" aria-hidden="true"></i>{" "}
                        <span class="ms-2 text-capitalize"> close ticket</span>
                      </button>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              {location.state.item.assignedMemberId ==
                localStorage.getItem("userId") &&
                location.state.item.createdBy != localStorage.getItem("userId") &&
                ticketStatus == "2" ? (
                <>
                  <div class=" d-inline-block d-flex align-items-center justify-content-center mb-4 awaiting-box">
                    <div className="btn-box">
                      <div className="d-flex justify-content-center align-items-center">
                        <div className="text-successs text-start">
                          <span>Awaiting for Approval </span>
                          <p className="text-complete mb-0">
                            Submitted on{" "}
                            {formateDateIntoDatemonthTime(
                              location.state.item.updateStatusDate
                            )}
                          </p>
                        </div>
                        <div className="btn-successs">
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                class="fa fa-ellipsis-v text-secondary"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  updateTicketSts("4");
                                }}
                              >
                                Reopen
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {location.state.item.createdBy ==
                localStorage.getItem("userId") && ticketStatus == "2" ? (
                <>
                  <div class="portlet w-100 ticket-approval-btn">
                    <div className="   d-flex align-items-center justify-content-between">
                      <div className="ticket-approval-text">
                        <span className="text-dark fw-bold">
                          Ticket Approval
                        </span>
                        <p className="text-secondary mt-2 mb-0">
                          on{" "}
                          {formateDateIntoDatemonth(
                            location.state.updateStatusDate
                          )}
                        </p>
                      </div>
                      <div className="ticket-approval">
                        <i
                          class="fa fa-check me-2 border border-success bg-success text-white"
                          onClick={() => {
                            updateTicketSts("3");
                          }}
                          aria-hidden="true"
                        ></i>
                        <i
                          class="fa fa-times border border-danger bg-danger text-white"
                          onClick={() => {
                            updateTicketSts("1");
                          }}
                          aria-hidden="true"
                        ></i>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {(localStorage.getItem("userId") ==
                location.state.item.createdBy ||
                location.state.item.assignedMemberId ==
                localStorage.getItem("userId")) &&
                ticketStatus == "3" ? (
                <>
                  <div class=" d-inline-block d-flex align-items-center justify-content-center mb-4">
                    <button className="btn btn-outline-success w-100 ticket-approval-btn bg-white">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="text-successs text-start">
                          <span className="text-success fw-bold">
                            Tickets Closed
                          </span>
                          <p className="text-complete mt-2 mb-0">
                            Closed on{" "}
                            {formateDateIntoDatemonthTime(
                              location.state.updateStatusDate
                            )}
                          </p>
                        </div>
                        <div className="btn-successs">
                          <div class="dropdown">
                            <button
                              class="btn btn-secondary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i
                                class="fa fa-ellipsis-v text-secondary"
                                aria-hidden="true"
                              ></i>
                            </button>
                            <div
                              class="dropdown-menu"
                              aria-labelledby="dropdownMenuButton"
                            >
                              <a
                                class="dropdown-item"
                                onClick={() => {
                                  updateTicketSts("1");
                                }}
                              >
                                Reopen
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
            <div class="portlet client-detail-box my-3">
              <div class="title">
                <h5 class=" text-capitalize mb-0">client details</h5>
              </div>
              <div class="details ">
                <div class="texts">
                  <span>client</span>
                  <strong>@{location.state.item.clientName}</strong>
                </div>
                <div class="texts my-3">
                  <span>created by</span>
                  <strong>{location.state.item.createdMember}</strong>
                </div>
                <div class="texts">
                  <span>points of contact</span>
                  <strong>{location.state.item.clientContact}</strong>
                </div>
              </div>
            </div>
            <div class="portlet ticket-detail-box my-3">
              <div class="title">
                <h5 class="text-capitalize mb-0">ticket details</h5>
              </div>
              <div class="details">
                {tickettypeid == 1 ?
                  (<>
                    <div class="texts mb-4">
                      <span>units</span>
                      <input className="form-control mt-2" value={units} disabled>
                      </input>
                    </div>
                  </>
                  ) : (<></>)}
                <div class="texts">
                  <span>assigned</span>
                  <ReactSelect
                    className="mt-2"
                    isMulti={false}
                    styles={customStyles}
                    components={{
                      IndicatorSeparator: () => null
                    }}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    allowSelectAll={true}
                    options={memberlist}
                    value={memberlist?.map(x => {
                      if (x.id == assignId) {
                        return x;
                      }
                    })}
                    onChange={(item) => {
                      setmemberlistSelected(item.value);
                      setassignId(item.value);
                      let data = {
                        TicketId: location.state.item.ticketid,
                        MemberId: item.value,
                        AssignedOn: mmDDyyyyFormateDateSet(new Date()),
                        Status: 1,
                        CreatedBy: localStorage.getItem("userId"),
                      };
                      AssignTickets(data);
                    }}
                  >
                  </ReactSelect>
                </div>
                <div class="texts my-4">
                  <span>priority</span>
                  <select
                    class={`form-select ${prioritylist(
                      location.state.item.priorityId
                    ).toLowerCase()}-selected`}
                    value={location.state.item.priorityId}
                    onChange={(i) => {
                      setpriority(i.target.value);
                      let getdata = {
                        ticketid: location.state.item.ticketid,
                        priorityList: i.target.value,
                      };
                      prooritystatuschange(getdata);
                    }}
                  >
                    {ticketpriority?.map((item) => {
                      return <option value={item.value}>{item.label}</option>;
                    })}
                  </select>
                </div>
                <div class="texts">
                  <span>ETA</span>
                  <input
                    type="date"
                    name=""
                    min={mmDDyyyyFormateDateSet(new Date())}
                    class="ticket-date w-100 mt-2 position-relative"
                    value={ETA}
                    onChange={(text) => {
                      setETA(text.target.value);
                      let getdata = {
                        ticketid: location.state.item.ticketid,
                        etaDate: text.target.value,
                      };
                      changeETA(getdata);
                      formValidation();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer
        title="Add Attachment"
        className="add-attch-modal"
        placement="right"
        size={size}
        onClose={drawerclose}
        open={draweropen}
      >
        <div class="dticket-name-box">
          <div class="row">
            <div className="col-md-12 px-0">
              <div className="input-box w-100">
                <label>Upload Links(multiple Links can be added)</label>
                <div className="position-relative">
                  <div className="d-flex align-items-top">
                    <div className="url-input-box">
                      <input
                        className="form-control"
                        type="text"
                        onKeyDown={(e) => {
                          _handleKeyLinkAdd(e);
                        }}
                        value={linklist}
                        onChange={(text) => {
                          setlinklist(text.target.value);
                        }}
                        placeholder="Add url"

                      />
                      <ul>
                        {linkname.map((item, index) => {
                          return (<> <li>
                            {item}
                          </li></>)

                        })}
                      </ul>
                    </div>
                    <button className="btn btn-primary py-4 ms-2" onClick={addurl}>Add</button>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-12 create-btn text-start ps-0 mt-4 border-top pt-3">

              <div className="btn-box">

                <div className="upload-text">Upload Files (.pdf, .docx, .jpeg, .png, .xlsx & .csv )</div>
                <a className="btn btn-outline mt-2" onClick={onBtnClick} >Browse File</a>
                {fileUpload.map((item, index) => {
                  return (<>
                    <div className="d-flex justify-content-between align-items-center report-fil mt-3">
                      <div className="left d-flex align-items-center">
                        <div class="icon-single">
                          {item.name.includes(".jpg") ||
                            item.name.includes(".png") ||
                            item.name.includes(".jpeg") ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-image"
                            >
                              <rect
                                x="3"
                                y="3"
                                width="18"
                                height="18"
                                rx="2"
                                ry="2"
                              ></rect>
                              <circle cx="8.5" cy="8.5" r="1.5"></circle>
                              <polyline points="21 15 16 10 5 21"></polyline>
                            </svg>
                          ) : (
                            <></>
                          )}
                          {item.name.includes(".pdf") ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#212121"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-file-text"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                              <polyline points="14 2 14 8 20 8"></polyline>
                              <line x1="16" y1="13" x2="8" y2="13"></line>
                              <line x1="16" y1="17" x2="8" y2="17"></line>
                              <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                          ) : (
                            <></>
                          )}
                          {item.name.includes(".txt") ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#212121"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              class="feather feather-file-text"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                              <polyline points="14 2 14 8 20 8"></polyline>
                              <line x1="16" y1="13" x2="8" y2="13"></line>
                              <line x1="16" y1="17" x2="8" y2="17"></line>
                              <polyline points="10 9 9 9 8 9"></polyline>
                            </svg>
                          ) : (
                            <></>
                          )}
                          {item.name.includes(".xlsx") ? (
                            <i
                              class="fa fa-file-excel"
                              aria-hidden="true"
                            ></i>
                          ) : (
                            <>
                            </>
                          )}
                        </div>
                        <span className="ms-3">{item.name}</span>
                      </div>

                      <i
                        onClick={() => {
                          setfileUpload(
                            fileUpload.filter((e) => e !== item)
                          );
                          if (index > -1) {
                            fileUpload.splice(index, 1);
                          }
                          setfileUpload(fileUpload);
                        }}
                        class="fa fa-times"
                        style={{ color: "#757575" }}
                      ></i>
                    </div>
                  </>)

                })}
              </div>
            </div>
            <div className="col-sm-12 attach-btn-box px-0">
              <hr />
              <div className="d-flex justify-content-end mt-4">
                <button className="btn btn-outline" onClick={drawerclose}>Cancel</button>
                <button className="btn btn-primary ms-3 d-flex align-items-center" onClick={() => {
                  handleaddattachment();
                }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-check"><polyline points="20 6 9 17 4 12"></polyline></svg>
                  <span className="ms-3">Upload Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default SubTicketView;
