import React from "react";
import { useState, useEffect, useRef } from "react";
import "antd/dist/antd.css";
import { NavLink, useHistory } from "react-router-dom";

const HTMLdata = (props) => {
    return (<>
        <div className={"col-md-12 col-sm-12 para-box seo-table " + props.staticClass}>
            <div className="title">
                
                    <label>{props.title}</label>
                
            </div>
            <div className="bg-transparent observe-recommendation-data"
                 dangerouslySetInnerHTML={{ __html: props.data }}
            ></div>
        </div>
    </>)
}

export default HTMLdata