import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useHistory } from "react-router-dom";
import APIManager from "../../services/APIManager";
import {
  addKeywordTypeAPI,
  getKeywordTypeAPI,
} from "../../utility/Constant";

const KeywordType = ({ update }) => {
  useEffect(() => {
    getKeywordType();
  }, []);

  const history = useHistory();

  const [contentArray, setContentArray] = useState([
    { StartRange: "", EndRange: "", Type: "" },
  ]);

  const [message, setMessage] = useState("");
  const notifyUpdate = () => toast.success("Successfully Updated!");

  function addContent() {
    var tempArray = JSON.parse(JSON.stringify(contentArray));
    tempArray.push({
      StartRange: "", EndRange: "", Type: "",
    });
    setContentArray(tempArray);
  }

  function removeContent(i) {
    let list = [...contentArray];
    list.splice(i, 1);
    setContentArray(list);
  }

  function isValid() {
    let valid = true;
    var pattern = new RegExp(/^[0-9\b]+$/);
    contentArray.map((item) => {
      if (+item.StartRange < 0 || +item.EndRange < 0 || item.Type == "") {
        setMessage("Please enter values in Type , Lower Limit and Higher Limit!");
        valid = false;
        return valid;
      }
      else {
        if (!pattern.test(parseInt(item.StartRange)) && !pattern.test(parseInt(item.EndRange))) {
          setMessage("Please enter only numbers in Lower Limit and Higher Limit!");
          valid = false;
          return valid;
        }
        else {
          if (parseInt(item.StartRange) >= parseInt(item.EndRange)) {
            setMessage("Please enter Lower Limit less then Higher Limit!");
            valid = false;
            return valid;
          }

        }
      }
    }
    );
    return valid;
  }

  function addKeywordType() {
    APIManager.postAPI(addKeywordTypeAPI,
      {
        ProjectId: localStorage.getItem("projectGId"),
        KeywordTypeRequestDetails: contentArray,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        notifyUpdate();
        update("")
      }
    });
  }

  function getKeywordType() {
    APIManager.getAPI(getKeywordTypeAPI + localStorage.getItem("projectGId")).then((response) => {
      let dataList = [];
      let dataList1 = [];
      if (response && response.status == 200) {
        response.data.map((i) => {
          dataList.push({
            StartRange: i.startRange,
            EndRange: i.endRange,
            Type: i.type,
          });
        });
        response.data.map((i) => {
          dataList1.push({
            StartRange: i.startRange,
            EndRange: i.endRange,
            Type: i.type,
            Id: i.id,
          });
        });
      }
      if (dataList.length > 0) {
        setContentArray(dataList);
      }
    });
  }

  return (
    <>
      <div className="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">{localStorage.getItem("projectGName")} - Keyword Type</h3>
        </div>
        <div className="portlet-body">
          <div className="col-md-12 col-sm-12  mt-4">
            <table class="keyword-type-table table-modules  table table-bordered  table-hover table-responsive">
              <thead class="table-modules-head">
                <tr>
                  <th rowSpan="2" >Type</th>
                  <th colspan="2"  >Range</th>
                  <th rowSpan="2" className="keyword-hash" > #</th>
                </tr>
                <tr>
                  <th>Lower Limit </th>
                  <th> Higher Limit</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className="border-right w-50" >
                    {contentArray.map((item, index) => (
                      <div key={index}>
                        <input
                          className="form-control w-100v mt-3"
                          type="text"
                          placeholder="Type"
                          value={item.Type}
                          onChange={(text) => {
                            let list = [...contentArray];
                            list[index].Type = text.target.value;
                            setContentArray(list);
                            setMessage("");
                          }}
                        />
                      </div>
                    ))}
                  </td>

                  <td className="border-right w-50" >
                    {contentArray.map((item, index) => (
                      <div key={index}>
                        <input
                          className="form-control mt-3"
                          type="text"
                          placeholder="Lower Limit"
                          value={item.StartRange}
                          onChange={(text) => {
                            let list = [...contentArray];
                            list[index].StartRange = text.target.value;
                            setContentArray(list);
                            setMessage("");
                          }}
                        />
                      </div>
                    ))}
                  </td>

                  <td className="border-right"  >
                    {contentArray.map((item, index) => (
                      <div key={index}>
                        <input
                          className="form-control  mt-3"
                          type="text"
                          placeholder="Higher Limit"
                          value={item.EndRange}
                          onChange={(text) => {
                            let list = [...contentArray];
                            list[index].EndRange = text.target.value;
                            setContentArray(list);
                            setMessage("");
                          }}
                        />
                      </div>
                    ))}
                  </td>
                  <td class="plus-minus-btn border-end">
                    {contentArray.map((item, index) => (
                      <div key={index} className="d-flex plusminus">
                        {contentArray.length - 1 === index && (
                          <button
                            className="me-3"
                            onClick={addContent}
                          >
                            {" "}
                            <i
                              class="fa fa-plus-circle"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                        )}
                        {contentArray.length > 1 && (
                          <button
                            onClick={() => removeContent(index)}
                          >
                            {" "}
                            <i
                              class="fa fa-minus-square"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>
                        )}
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="ms-2 pt-2" style={{ color: "red" }}>
              {message}
            </div>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              class=" btn btn-primary"
              onClick={() => {
                if (isValid()) {
                  addKeywordType();
                  setMessage("");
                }
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default KeywordType;
