import React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "antd/dist/antd.css";
import { Table, Tooltip } from "antd";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import { Calendar } from "react-multi-date-picker";
import { components } from "react-select";
import makeAnimated from "react-select/animated";

import {
  calanderDate,
  downloadPDFfile,
  exportInToCSV,
  exportToEXCEL,
  findUnique,
  getBase64path,
  // getCheckPermission,
  mmDDyyyyFormateForForm,
  SiteUpTimeTypeInt,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import {
  addsiteupmonitor,
  getSiteUpTimeFieldTable,
  getSiteUptimeMonitorGetConfig,
  moduleSiteUptime,
  sendEmailApi,
} from "../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
  formateDateIntoDatemonth,
} from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import { useLocation } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";
// import Loading from "../utility/Loading";
// import { PDFExport } from "@progress/kendo-react-pdf";
import { DateRangePicker } from "react-date-range";
import { addDays } from "date-fns";

const animatedComponents = makeAnimated();
const MultiValue = (props) => (
  <components.MultiValue {...props}>
    <span>{props.data.label}</span>
  </components.MultiValue>
);

function SiteUptimeMonitorTestData() {
  const location = useLocation();
  const history = useHistory();

  const [options, setObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
    },
    yaxis: {
      logBase: 2,
      tickAmount: 5,
      min: 0,
    },
  });

  const [series, setSeries] = useState([]);
  const applyBtn = useRef(null);
  const [teamlist, setteamList] = useState([]);
  const [teamcol, setteamcol] = useState([]);
  const [keytablelist, setkeytablelist] = useState([]);
  const [exportTable1, setexportTable1] = useState([]);
  const [keyTableCol, setkeyTableCol] = useState([]);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [displayTable, setDisplayTable] = useState(false);
  const [noContent, setNoContent] = useState(false);
  const [noConfig, setNoConfig] = useState(true);
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [subject, setSubject] = useState("Site Uptime Reports");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [isLoading, setLoading] = useState(false);


  const [expectedTitle, setexpectedTitle] = useState("");
  const [actualTitle, setactualTitle] = useState("");
  const [fieldId, setfieldId] = useState("");
  const [fieldName, setfieldName] = useState({});

  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    localStorage.setItem("isCompareVar", false);
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Site UpTime Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    var color = [];
    if (location.state) {
      setfieldId(location.state.item.field);
      let fieldId = location.state.item.field;
      let startDate = location.state.getdata.startDate;
      let endDate = location.state.getdata.endDate;
      setfieldName(location.state.item.field)
      localStorage.setItem("fieldName", location.state.item.field)
      setHead1(startDate);
      setHead2(endDate);
      values[0] = startDate;
      values[1] = endDate;

      setValues([new DateObject(startDate), new DateObject(endDate)]);
      let selection23 = {
        selection1: {
          startDate: addDays(new Date(startDate), 0),
          endDate: addDays(new Date(endDate), 0),
          key: "selection1",
        },
        selection2: {
          startDate: addDays(new Date(startDate), 0),
          endDate: addDays(new Date(endDate), 0),
          key: "selection2",
        },
      }

      setState({
        ...state,
        ...selection23,
      });

      let getData = {
        projectId: localStorage.getItem("projectGId"),
        // type:fieldId,
        startDate: startDate,
        EndDate: endDate
      }
      getFieldTableData(getData);
    }
    // getSiteUptimeConfig();
  }, []);

  useEffect(() => {
    var columns = [
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Urls",
        dataIndex: "url",
        key: "url",
      },
      {
        title: expectedTitle,
        dataIndex: "expected_title",
        key: "expected_title",
      },
      {
        title: actualTitle,
        dataIndex: "actual_title",
        key: "actual_title",
      },
      {
        title: "Result",
        dataIndex: "overall_result",
        key: "overall_result",
      },
    ];
    setkeyTableCol(columns);
    setkeytablelist([{}]);
    setDisplayTable(true);
  }, []);

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);
  const [open, setOpen] = useState(false);
  const [refreshTicketData, setRefreshTicketData] = useState(false);

  const [head1, setHead1] = useState(headValues[0].format());
  const [head2, setHead2] = useState(headValues[1].format());

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }


  const onChange = (pagination, filters, sorter, extra) => {
    setfieldName(filters.type);
    localStorage.setItem("fieldName", filters.type);
    let getData = {
      projectId: localStorage.getItem("projectGId"),
      startDate: mmDDyyyyFormateDateSet(head1),
      EndDate: mmDDyyyyFormateDateSet(head2)
    }
    getFieldTableData(getData);
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }

      // if (!open) {
      //   document
      //     .getElementsByClassName("rdrDefinedRangesWrapper")[0]
      //     .classList.add("display-none");
      // }
    };
  }, [open]);

  const [totalExecuted, settotalExecuted] = useState("");
  const [totalPassed, settotalPassed] = useState("");
  const [totalFailed, settotalFailed] = useState("");
  const [overallResult, setoverallResult] = useState("");

  function updatecolumn(dates) {
    if (dates.length) {
      const startDate = dates[0].format("MM/DD/YYYY");
      const endDate = dates[1].format("MM/DD/YYYY");
      const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
      const diffDays = 0 | (diffTime / 864e5);
      let series = [];
      let options = {};
      let columns = [
        {
          title: "URL",
          dataIndex: "url",
          key: "url",
        },
      ];
      for (let i = 0; i <= diffDays; i++) {
        const newdate = formateDateIntoDatemonth(
          new Date(new Date(startDate).getTime() + i * 864e5)
        );
        const datedata = mmDDyyyyFormateDateSet(
          new Date(new Date(startDate).getTime() + i * 864e5)
        );
        series.push(
          formateDateIntoDatemonth(
            new Date(new Date(startDate).getTime() + i * 864e5)
          )
        );

        let obj = {
          title: newdate,
          dataIndex: datedata,
          key: datedata,
        };
        columns.push(obj);
      }
      setkeyTableCol(columns);
      options = {
        chart: {
          id: "basic-bar",
        },
        xaxis: {
          tickPlacement: "on",
          categories: series,
        },
        yaxis: {
          logBase: 2,
          tickAmount: 5,
          min: 0,
        },
      };
      setObject(options);
      let getdata = {
        projectId: localStorage.getItem("projectGId"),
        startDate: mmDDyyyyFormateDateSet(head1),
        EndDate: mmDDyyyyFormateDateSet(head2),
      };
      gscGetDataGet(getdata);
    }
  }

  function GetTypeName(val) {
    var finalVal = val;
    SiteUpTimeTypeInt.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }
  function GetTypeName1(val) {
    var finalVal = val;
    SiteUpTimeTypeInt.map((item) => {
      if (item.value == val) {
        finalVal = item.label;
      }
    });
    return finalVal;
  }
  function GetTypeValue(name) {
    var finalVal;
    SiteUpTimeTypeInt.map((item) => {
      if (item.label == name) {
        finalVal = item.value;
      }
    });
    return finalVal;
  }

  function getFieldTableData(data) {
    let getData = data;
    let colms = [];
    let additionalCols = [];
    const filterType = [];
    let filtertypelistobj;
    let name = ""

    APIManager.postAPI(
      getSiteUpTimeFieldTable, getData, null, true
    ).then((response) => {
      if (response && response.status == 200) {
        let i = 0;
        let obj = [];
        let excelobj = [];
        response.data.map((item) => {
          obj.push({
            key: i += 1,
            type: GetTypeName(item.type),
            url: item.url,
            actual_title: item.actualResult,
            expected_title: item.expectedResult,
            overall_result: item.result
          });

          let list = {
            type: GetTypeName(item.type),
            url: item.url,
            actual_title: item.actualResult,
            expected_title: item.expectedResult,
            overall_result: item.result
          }
          excelobj.push(list);

          filtertypelistobj = {
            text: GetTypeName(item.type),
            value: item.type,
          };
          // if (filtertypelistobj?.value) 
          filterType.push(filtertypelistobj);
        });

        var fieldNameVar = localStorage.getItem("fieldName").split(",").map(Number);
        if (isNaN(fieldNameVar[0]))
          fieldNameVar = null;

        let type = {
          title: "Type",
          dataIndex: "type",
          key: "type",
          // defaultFilteredValue: [GetTypeName1(fieldNameVar)],
          filteredValue: fieldNameVar,
          filters: findUnique(filterType, (d) => d.value),
          filterSearch: true,
          // filteredValue:[GetTypeName(fieldName)],
          onFilter: (value, record) => {
            fieldNameVar = value;
            return GetTypeValue(record.type) == value;
          },
        }

        let col1 = {
          title: "Url",
          dataIndex: "url",
          key: "url",
        };

        let colN = {
          title: "Result",
          dataIndex: "overall_result",
          key: "overall_result",
          sorter: true,
          sorter: {
            compare: (a, b) =>
              Sorter.DEFAULT(a.overall_result, b.overall_result),
            multiple: 3,
          },
          render: function (item) {
            return (
              <div className="d-flex justify-content-around align-items-center">{item}  {(item == "fail") ? <i className="fa fa-times" style={{ color: "red" }}></i> : <i className="fa fa-check" style={{ color: "green" }}></i>}</div>
            );
          }
        };

        if (fieldNameVar == 0) {
          additionalCols = [
            {
              title: "Expected Title",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual Title",
              dataIndex: "actual_title",
              key: "actual_title",
            },
          ]

        } else if (fieldNameVar == 1) {
          additionalCols = [
            {
              title: "Expected Description",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual Description",
              dataIndex: "actual_title",
              key: "actual_title",
            },
          ]

        } else if (fieldNameVar == 3) {
          additionalCols = [
            {
              title: "Expected Canonical",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual Canonical",
              dataIndex: "actual_title",
              key: "actual_title",
            },
          ]

        } else if (fieldNameVar == 4) {
          additionalCols = [
            {
              title: "Expected NoIndex Tag",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual NoIndex Tag",
              dataIndex: "actual_title",
              key: "actual_title",
            },
          ]

        } else if (fieldNameVar == 5) {
          additionalCols = [
            {
              title: "Expected Status Code",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual Status Code",
              dataIndex: "actual_title",
              key: "actual_title",
            },
            {
              title: "Redirected URL",
              dataIndex: "redirect_url",
              key: "redirect_url",
            },
          ]

        } else if (fieldNameVar == 7) {
          additionalCols = [
            {
              title: "Expected Result",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual Result",
              dataIndex: "actual_title",
              key: "actual_title",
            },
          ]

        } else if (fieldNameVar == 8) {
          additionalCols = [
            {
              title: "Expected Schema 1",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual Schema 1",
              dataIndex: "actual_title",
              key: "actual_title",
            },
            {
              title: "Expected Schema 2",
              dataIndex: "expected_title2",
              key: "expected_title2",
            },
            {
              title: "Actual Schema 2",
              dataIndex: "actual_title2",
              key: "actual_title2",
            },
          ]
        } else if (fieldNameVar == 9) {
          additionalCols = [
            {
              title: "Hreflang Tag",
              dataIndex: "hreflang_tag",
              key: "hreflang_tag",
            },
          ]
        }
        else {
          additionalCols = [
            {
              title: "Expected Result",
              dataIndex: "expected_title",
              key: "expected_title",
            },
            {
              title: "Actual Result",
              dataIndex: "actual_title",
              key: "actual_title",
            },
          ]
          setexpectedTitle("Expected Title");
          setactualTitle("Actual Title");
        }
        colms.push(type, col1, ...additionalCols, colN);
        setteamcol(colms);
        setteamList(obj);
        setExportList(excelobj);
      }
    });
  }
  const overalltableSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item) => {
        let obj = {
          type: GetTypeName(item.type),
          url: item.url,
          actual_title: item.actualResult,
          expected_title: item.expectedResult,
          overall_result: item.overall_result
        };
        list.push(obj);
      });
      setSelectedExportList(list);
    },
  };

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = 'light';
    if (
      document.querySelector("body").classList.contains("theme-dark")
    ) {
      theme = 'dark';
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image)
    let rowData = {
      "from": Sender.trim(),
      "to": Receiver.trim(),
      "subject": subject.trim(),
      "body": EmailMessage.trim(),
      "base64Data": fileBase64,
      "filename": moduleSiteUptime
    }
    APIManager.postAPI(sendEmailApi, rowData, null, true).then(
      (response) => {
        if (response?.status == 200) {
          setReceiver("");
          setSubject("Site UpTime Reports");
          setEmailMessage(EmailMessage1);
          if (theme == 'dark') {
            document.querySelector("body").classList.remove("theme-light");
            document.querySelector("body").classList.add("theme-dark");
          }
        }
      }
    );
  }

  // function getSiteUptimeConfig() {
  //   APIManager.getAPI(
  //     getSiteUptimeMonitorGetConfig + localStorage.getItem("projectGId")
  //   ).then((response) => {
  //     if (response && response.status == 200) {
  //       if (response?.data) {
  //         setNoConfig(false);
  //         setNoContent(true);
  //         let getdata = {
  //           projectId: localStorage.getItem("projectGId"),
  //           startDate: mmDDyyyyFormateDateSet(head1),
  //           EndDate: mmDDyyyyFormateDateSet(head2),
  //         };
  //         gscGetDataGet(getdata);
  //       }
  //     }
  //   });
  // }

  function gscGetDataGet(rowdata) {
    APIManager.postAPI(addsiteupmonitor, rowdata, null, true).then(
      (response) => {
        if (response.status == 200 && response?.data.chartData) {
          settotalExecuted(response?.data.totalExecuted);
          settotalPassed(response?.data.totalPassed);
          settotalFailed(response?.data.totalFailed);
          setoverallResult(response?.data.overallResult);

          const startDate = rowdata.startDate;
          const endDate = rowdata.EndDate;

          const diffTime = Math.abs(new Date(endDate) - new Date(startDate));
          const diffDays = 0 | (diffTime / 864e5);
          let tableList = [];
          let expTableList = [];
          let dataForGraph = [];
          let jsonchartData = response?.data.chartData;
          setNoContent(!jsonchartData?.length);
          if (jsonchartData.length) {
            jsonchartData.map((data) => {
              let graphData = [];
              let graphData2 = [];
              if (diffDays) {
                let obj = { url: data.urLs };
                let obj2 = { url: data.urLs };
                for (let i = 0; i <= diffDays; i++) {
                  const newdate = mmDDyyyyFormateForForm(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  );
                  obj[newdate] = isDataAvailable(data[newdate]);
                  obj2[newdate] = data[newdate]
                    ? data[newdate] == 6
                      ? "Pass"
                      : "Fail"
                    : " - ";
                  graphData.push(isDataAvailable(data[newdate]));
                  graphData2.push(data[newdate] ? data[newdate] : 0);
                }
                tableList.push(obj);
                expTableList.push(obj2);
                dataForGraph.push({
                  name: data.urLs,
                  data: graphData2,
                });
                setSeries(dataForGraph);
                setkeytablelist(tableList);
                setexportTable1(expTableList);
              }
            });
          }
        } else {
        }
      }
    );
  }

  function isDataAvailable(data) {
    return data ? (
      <>
        <Tooltip title={data}>
          <span>
            <i class="fa fa-check" aria-hidden="true"></i>
          </span>
        </Tooltip>
      </>
    ) : (
      <>
        <i class="fa fa-times"></i>
      </>
    );
  }

  const [SiteUptimeMonitor, setSiteUptimeMonitor] = useState(false);

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  return (
    <>
      <>
        <div className="row">
          <div className="col-md-12 d-flex justify-content-between pb-3 p-0">
            <i
              class="fa fa-angle-left back-arrow"
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </div>
          <Tabs>
            <div className="portlet ">
              <div className="portlet-header portlet-header-bordered my-4">
                <div className="col-md-9 col-sm-12">
                  <TabList className="nav nav-tabs portlet-nav">
                    <Tab
                      className={
                        SiteUptimeMonitor
                          ? "nav-item nav-link "
                          : "nav-item nav-link  active"
                      }
                      onClick={() => {
                        setSiteUptimeMonitor(false);
                      }}
                    >
                      Site Uptime Monitor
                    </Tab>
                  </TabList>
                </div>

                <div
                  ref={ref}
                  class="col-md-3 col-sm-12 calendar-main  d-flex justify-content-end align-items-center ga-calender"
                >
                  <div className="add-new-btnw">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px" }}
                      onClick={() => setOpen(!open)}
                    >
                      {calanderDate(head1, head2)}
                    </button>
                  </div>
                  {open && (
                    <div id="example-collapse-text-calendar">
                      <Card body className="daterange-picker-card  mt-3">
                        <div className="d-flex calendar-flex">
                          <div className="calendar-col">
                            {/* <Calendar
                              className="custom-calendar"
                              value={values}
                              onChange={(e) => {
                                setValues(e);
                                setHead1(e[0].format());
                                setHead2(e[1]?.format());
                              }}
                              range
                              numberOfMonths={1}
                              showOtherDays
                            /> */}

                            <DateRangePicker
                              onChange={(item) => {
                                let isCompareVarNew = localStorage.getItem("isCompareVar");

                                // if(calendarType == "daily") {
                                // if (item?.selection1) {
                                //   let selection23 = {
                                //     selection2: {
                                //       startDate: item?.selection1?.startDate,
                                //       endDate: item?.selection1?.endDate,
                                //       key: "selection2",
                                //     },
                                //   };
                                //   setState({
                                //     ...state,
                                //     ...item,
                                //     ...selection23,
                                //   });
                                // } else if (item?.selection2 && isCompare) {
                                //   setState({ ...state, ...item });
                                // }

                                if (isCompareVarNew == "false") {
                                  let selection23 = {};
                                  if (item?.selection1) {
                                    selection23 = {
                                      selection2: {
                                        startDate: item?.selection1?.startDate,
                                        endDate: item?.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection1?.startDate), new DateObject(item?.selection1?.endDate)]);
                                    setHead1(new DateObject(item?.selection1?.startDate).format());
                                    setHead2(new DateObject(item?.selection1?.endDate).format());

                                  } else if (item?.selection2) {
                                    selection23 = {
                                      selection1: {
                                        startDate: item?.selection2?.startDate,
                                        endDate: item?.selection2?.endDate,
                                        key: "selection1",
                                      },
                                    };

                                    setValues([new DateObject(item?.selection2?.startDate), new DateObject(item?.selection2?.endDate)]);
                                    setHead1(new DateObject(item?.selection2?.startDate).format());
                                    setHead2(new DateObject(item?.selection2?.endDate).format());
                                  }

                                  setState({
                                    ...state,
                                    ...item,
                                    ...selection23,
                                  });
                                }

                                // if (isCompareVarNew == "true") {
                                //   setState({ ...state, ...item });
                                // }
                                // } else if(calendarType == "weekly") {
                                //   let isCompareVarNew = localStorage.getItem("isCompareVar");
                                //   let selection = {};

                                //   if (isCompareVarNew == "false") {
                                //     let selection23 = {
                                //       selection1: {
                                //         startDate: item?.selection1
                                //           ? item?.selection1?.startDate
                                //           : item?.selection2?.startDate,
                                //         endDate: item?.selection1
                                //           ? item?.selection1?.endDate
                                //           : item?.selection2?.endDate,
                                //         key: "selection1",
                                //       },
                                //     };
                                //     selection = dateRagngeSelectWeek(
                                //       selection23,
                                //       state
                                //     );
                                //   }

                                //   if (isCompareVarNew == "true") {
                                //     selection = dateRagngeSelectWeek(item, state);
                                //   }

                                //   setState({
                                //     ...state,
                                //     ...item,
                                //     ...selection,
                                //   });
                                // }
                              }}

                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={[state?.selection1, state?.selection2]}
                              direction="horizontal"
                              ariaLabels={{
                                dateInput: {
                                  selection1: {
                                    startDate: "start date input of selction 1",
                                    endDate: "end date input of selction 1",
                                  },
                                  selection2: {
                                    startDate: "start date input of selction 2",
                                    endDate: "end date input of selction 2",
                                  },
                                },

                                monthPicker: "month picker",
                                yearPicker: "year picker",
                                prevButton: "previous month button",
                                nextButton: "next month button",
                              }}
                              maxDate={
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  new Date().getDate() - 1
                                )
                              }
                            />
                          </div>
                          <div className="mt-3 mt-lg-0 text-center">
                            <button
                              onClick={() => {
                                let dates = [];
                                dates.push(values[0]);
                                dates.push(values[1]);
                                // updatecolumn(dates);
                                setheadvalues();
                                setOpen(!open);
                                setRefreshTicketData(true);
                                setTimeout(() => {
                                  setRefreshTicketData(false);
                                }, 1000);
                                let getData = {
                                  projectId: localStorage.getItem("projectGId"),
                                  // type:fieldId,
                                  startDate: mmDDyyyyFormateDateSet(head1),
                                  EndDate: mmDDyyyyFormateDateSet(head2)
                                }
                                getFieldTableData(getData);
                              }}
                              className="outline-btn btn btn-primary"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                  <div className="d-flex 3-icons">
                    <div className="ms-3  ">
                      <a
                        className="share bar-link-icon"
                        style={{ color: "black" }}
                      >
                        <div class="dropdown">
                          <a
                            type="button"
                            ref={applyBtn}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              class="fa fa-share-alt"
                              aria-hidden="true"
                            ></i>
                          </a>
                          <form class="dropdown-menu p-4 filter-dropdown">
                            <div className="filter-popup">
                              <div className="portlet px-2 py-2">
                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">From</label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Sender Email Id"
                                      value={Sender}
                                      className="w-100 form-control my-2"
                                      name="Sender"
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">To</label>
                                    <label class="field-required">
                                      {" "}
                                      *{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Recipient Email Id"
                                      className="w-100 form-control my-2"
                                      value={Receiver}
                                      name="Receiver"
                                      onChange={(text) =>
                                        setReceiver(text.target.value)
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageReceiver}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">Subject</label>
                                    <label class="field-required">
                                      {" "}
                                      *{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="text"
                                      required
                                      placeholder="Subject"
                                      value={subject}
                                      onChange={(text) => {
                                        setSubject(text.target.value);
                                      }}
                                      className="w-100 form-control my-2"
                                      name="Subject"
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageSubject}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">Message</label>
                                    <label class="field-required">
                                      {" "}
                                      *{" "}
                                    </label>
                                  </div>
                                  <div className="col-md-10">
                                    <textarea
                                      name=""
                                      id=""
                                      cols="80"
                                      rows="7"
                                      className="w-100 form-control my-2 gscmsg"
                                      value={EmailMessage}
                                      onChange={(text) => {
                                        setEmailMessage(text.target.value);
                                      }}
                                    ></textarea>
                                    <div style={{ color: "red" }}>
                                      {Message}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2"></div>
                                  <div className="col-md-10 my-2">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style={{
                                        width: 70 + "px",
                                        height: 30 + "px",
                                      }}
                                      onClick={() => {
                                        if (isValidEmailData()) {
                                          sendEmail();
                                        }
                                      }}
                                    >
                                      {" "}
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </a>
                    </div>

                    <div className="row">
                      <div className="col-md-12 download-filter">
                        <span
                          class="dropdown"
                          style={{
                            marginRight: 0 + "px",
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          {/* {getCheckPermission("export", "modules") ? ( */}
                          <button
                            class="outline-btn dropdown-toggle btn btn-primary"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i
                              class="fa fa-download "
                              aria-hidden="true"
                            ></i>
                          </button>
                          {/* ) : (
                            <></>
                          )} */}
                          <div
                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              onClick={async () => {
                                setLoading(true)
                                const promise = await downloadPDFfile(image, moduleSiteUptime)
                                try {
                                  if (promise) {
                                    setLoading(false);
                                  } else {
                                    setLoading(false);
                                  }
                                } catch (e) {
                                  setLoading(false);
                                }

                              }}
                            >
                              <a class="dropdown-item">PDF</a>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TabPanel>
              <br />
              {displayTable && !noContent ? (
                <>
                  <PDFExport
                    paperSize="A1"
                    margin="2cm"
                    fileName={moduleSiteUptime}
                    ref={pdfExportComponent}
                    forcePageBreak=".page-break"
                  >
                    <div ref={image} >
                      <div
                        className="portlet portlet-header-bordered"
                        id="web-page-pdf"
                      >
                        <div className="portlet">
                          <div className="portlet-body siteup-date">
                            <div className="col-md-12 d-flex justify-content-end mt-3">
                              <span class="dropdown">
                                <button
                                  class="btn btn-primary dropdown-toggle ms-3"
                                  type="button"
                                  onClick={() => {
                                    exportToEXCEL(
                                      selectedExportList?.length > 0
                                        ? selectedExportList
                                        : exportList,
                                      "Site Up Time"
                                    );
                                  }}
                                >
                                  Export
                                </button>
                                {/* <div
                                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <div
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : exportList,
                                        "Site Up Time"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">Excel</a>
                                  </div>
                                  <div
                                    onClick={() => {
                                      exportInToCSV(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : exportList,
                                        "Site Up Time"
                                      );
                                    }}
                                  >
                                    <a class="dropdown-item">CSV</a>
                                  </div>
                                </div> */}
                              </span>
                            </div>

                            <div className="col-md-12">
                              <Table
                                className="table table-bordered  table-hover mt-3 siteup-date "
                                id="datatable-1"
                                columns={teamcol}
                                dataSource={teamlist}
                                rowSelection={{
                                  type: "checkbox",
                                  ...overalltableSelection,
                                }}
                                pagination={{
                                  position: ["bottomRight"],
                                  showSizeChanger: true,
                                }}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div></PDFExport>
                </>
              ) : (
                <></>
              )}
            </TabPanel>
          </Tabs>
        </div>
      </>
      <Loading isLoading={isLoading} />
    </>
  );
}

export default SiteUptimeMonitorTestData;

