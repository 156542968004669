import { useHistory } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { Table, Input } from "antd";
import "react-step-progress-bar/styles.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "antd/dist/antd.css";
import {
  GET_Server_URL,
  competitorsImport,
  getCompetitorsByProjectId,
  deleteCompetitors,
  documentFileType,
  createCompetitors,
  deleteMultiCompetitors,
} from "../../utility/Constant";
import {
  exportToEXCEL, removeTrailingSlash,
} from "../../utility/Globals";
import { exportInToCSV } from "../../utility/Globals";
import APIManager from "../../services/APIManager";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";

const Competitors = ({ update }) => {
  const [tabcomplist, settabcompList] = useState([]);
  const [comptrlist, setcomptrlist] = useState([]);
  const CompTabcol = [
    {
      title: "Competitors",
      dataIndex: "comp",
      key: "comp",
    },
    {
      title: "Action",
      dataIndex: "delete",
      key: "delete",
    },
  ];
  const [selectedCompExportList, setSelectedCompExportList] = useState([]);
  const [compExportList, setCompExportList] = useState([]);
  const [errors, setErrors] = useState("");
  const [save, setsave] = useState(false);
  const [IsEditcompetitior, setIsEditcompetitiors] = useState("");
  const [CompetitiorsId, setCompetitorsId] = useState("");
  const [CompetitorsName, setCompetitorsName] = useState("");
  const [CompetitorsNameCopy, setCompetitorsNameCopy] = useState("");
  const [SelectedCompCheckList, setSelectedCompCheckList] = useState([]);
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    getCompetitorsList();
  }, []);

  const comprowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      selectedRows.map((item, index) => {
        let obj = {
          key: item.key,
          competitors: item.comp,
        };
        list.push(obj);
      });
      setSelectedCompExportList(list);

      const list1 = [];
      selectedRows.map((item, index) => {
        let obj = {
          key: item.id,
          competitors: item.fieldValue,
        };
        list1.push(item.id);
      });
      setSelectedCompCheckList(list1);
    },
  };

  function submit() {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteMultiCompId();
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  function addCompetitors() {
    APIManager.postAPI(
      createCompetitors,
      {
        uckType: 1,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: removeTrailingSlash(CompetitorsName),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setCompetitorsName("");
        getCompetitorsList();
        notifyAdd();
      }
    });
  }

  function updateCompetitiors() {
    APIManager.putAPI(
      createCompetitors,
      {
        uckType: 1,
        fieldValue: removeTrailingSlash(CompetitorsName),
        projectId: localStorage.getItem("projectGId"),
        id: CompetitiorsId,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        setIsEditcompetitiors(false);
        setCompetitorsName("");
        getCompetitorsList();
      }
    });
  }

  function deleteMultiCompId() {
    return new Promise((resolve, reject) => {
      APIManager.postAPIForDelete(
        deleteMultiCompetitors,
        SelectedCompCheckList,
        {},
        true
      ).then((response) => {
        if (response && response.status == 200) {
          getCompetitorsList();
          if (response.status === 200)
            if (response?.data?.message) {
            } else {
              // notifyDelete();
            }
          resolve(response.status);
        }
      });
    });
  }

  function getCompetitorsList() {
    APIManager.getAPI(
      getCompetitorsByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const exportList = [];
        const list = [];
        response.data.map((item) => {
          let exportObj = {
            key: item.id,
            competitors: item.fieldValue,
          };
          exportList.push(exportObj);
          list.push({
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            comp: item.fieldValue,
            delete: (
              <div className="">
                <Link
                  to={{
                    state: { item: item, isEdit: true },
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-edit edit-icon me-3"
                    onClick={() => {
                      setIsEditcompetitiors(true);
                      setCompetitorsId(item.id);
                      setCompetitorsName(item.fieldValue);
                      setCompetitorsNameCopy(item.fieldValue);
                      var e = document.getElementById("input-s");
                      e.scrollIntoView({
                        block: "end",
                        behavior: "smooth",
                        inline: "center",
                      });
                    }}
                  >
                    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                  </svg>
                </Link>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon"
                  onClick={() => {
                    deleteCompetitorsItem(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          });
        });
        setCompExportList(exportList);
        settabcompList(list);
        setcomptrlist(list);
      }
    });
  }

  async function deleteCompetitorsItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteCompetitors + id);
      if (response == 200) {
        getCompetitorsList();
      }
    } catch (e) { }
  }

  function uploadCompetitorsFile() {
    var input = document.getElementById("competitorFile");
    competitorImport(input.files.item(0));
  }

  function competitorImport(file) {
    var bodyFormData = new FormData();
    bodyFormData.append("file", file);
    APIManager.postAPI(
      competitorsImport + localStorage.getItem("projectGId"),
      {},
      bodyFormData,
      false
    ).then((response) => {
      if (response && response.status == 200) {
        getCompetitorsList();
      }
    });
  }
  const onBtnClick = () => {
    inputFileRef.current.click();
  };
  const inputFileRef = useRef(null);
  const history = useHistory();

  function isValidURL() {
    let error = { ...errors };
    CompetitorsName.trim() == ""
      ? (error["CompetitorsName"] = "Competitors is required!")
      : delete error["CompetitorsName"];

    if (CompetitorsName.trim()?.length) {
      var pattern = new RegExp(
        "^(http|https)://[a-zA-Z0-9-.]+.[a-zA-Z]{2,3}(:[a-zA-Z0-9]*)?/?([a-zA-Z0-9-._?,'/\\+&amp;%$#=~])*$"
      );

      if (!pattern.test(CompetitorsName.trim())) {
        error["CompetitorsName"] = "Please enter valid Competitors!";
      } else {
        delete error["CompetitorsName"];
        let isEqualItem = false;
        tabcomplist.map((item) => {
          if (item.value == CompetitorsName.trim()) {
            isEqualItem = true;
          }
        });
        if (isEqualItem) {
          error["CompetitorsName"] = "Competitors is already exist!";
        } else {
          delete error["CompetitorsName"];
        }
      }
    }
    setErrors(error);
    let err = Object.keys(error);
    return err?.length ? false : true;
  }

  return (
    <div className="custom-column-70">
      <div className="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg
            onClick={() => update("")}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-chevron-left close-left-icon"
          >
            <polyline points="15 18 9 12 15 6"></polyline>
          </svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - Competitors
          </h3>
        </div>
        <div className="portlet-body px-0">
          <div className="col-md-5">
            <div className="d-flex w-100  mb-3">
              <span className="competitors-add w-50">
                <input
                  className="w-100"
                  type="text"
                  value={CompetitorsName}
                  onChange={(text) => {
                    setCompetitorsName(text.target.value);
                  }}
                  placeholder="Enter Competitors"
                />
              </span>
              <span>
                <button
                  style={{
                    borderLeft: 0,
                    borderRadius: 0,
                    height: 100 + "%",
                    width: 100 + "%",
                  }}
                  class="btn btn-primary"
                  onClick={() => {
                    setsave(true);
                    if (
                      CompetitorsNameCopy.trim() == CompetitorsName.trim() &&
                      IsEditcompetitior
                    ) {
                      setCompetitorsName("");
                      setErrors("");
                      return;
                    }
                    if (isValidURL()) {
                      if (IsEditcompetitior) {
                        updateCompetitiors();
                      } else {
                        addCompetitors();
                      }
                    } else {
                    }
                  }}
                >
                  {IsEditcompetitior ? "Update" : "Add"}
                </button>
              </span>
            </div>
            {save && errors?.CompetitorsName && (
              <>
                <div className="field-required mt-2">
                  <span> {errors?.CompetitorsName}</span>
                </div>
              </>
            )}
          </div>
          <div className="row">
            <div className="col-md-4 tab-panel-url-options  d-flex justify-content-start url-tab mt-3">
              <div class="input-group-icon input-group-lg widget15-compact">
                <div class="input-group-prepend">
                  <i class="fa fa-search text-primary"></i>
                </div>
                <span>
                  <Input
                    allowClear
                    style={{ padding: 7 + "px" }}
                    type="text"
                    class="form-control"
                    placeholder="Type to search..."
                    onChange={(nameSearch) => {
                      settabcompList(
                        comptrlist.filter((person) =>
                          person.comp
                            .toLocaleLowerCase()
                            .includes(
                              nameSearch.target.value.toLocaleLowerCase()
                            )
                        )
                      );
                    }}
                    id="input-s"
                  />
                </span>
              </div>
            </div>

            <div className="col-md-8 tab-panel-options text-end mt-3 competitors-tab">
              <span class="export">
                <input
                  style={{ display: "none" }}
                  type="file"
                  id="competitorFile"
                  name="competitorFile"
                  multiple={false}
                  accept={documentFileType}
                  ref={inputFileRef}
                  onChange={uploadCompetitorsFile}
                />
                <button
                  disabled={!SelectedCompCheckList?.length}
                  style={{ marginLeft: 18 + "px" }}
                  class="btn btn-primary download-temp"
                  onClick={() => {
                    submit();
                  }}
                >
                  Delete Selected
                </button>
                <a
                  class="btn btn-primary mx-3 download-temp"
                  style={{ marginLeft: 18 + "px" }}
                  target="_blank"
                  href={GET_Server_URL + "audit/Competitors.xlsx"}
                >
                  Download Template
                </a>
                <button
                  class="competitors-import btn btn-primary me-3 import"
                  onClick={onBtnClick}
                >
                  Import
                </button>
              </span>
              <span class="export">
                <button
                  class="btn btn-primary dropdown-toggle export-btn me-3"
                  type="button"
                  onClick={() => {
                    exportToEXCEL(
                      selectedCompExportList?.length > 0
                        ? selectedCompExportList
                        : compExportList,
                      "Competitors"
                    );
                  }}
                >
                  Export
                </button>
                {/* <div
                  class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                  aria-labelledby="dropdownMenuButton"
                >
                  <div
                    onClick={() => {
                      exportToEXCEL(
                        selectedCompExportList?.length > 0
                          ? selectedCompExportList
                          : compExportList,
                        "Competitors"
                      );
                    }}
                  >
                    <a class="dropdown-item">Excel</a>
                  </div>
                  <div
                    onClick={() => {
                      exportInToCSV(
                        selectedCompExportList?.length > 0
                          ? selectedCompExportList
                          : compExportList,
                        "Competitors"
                      );
                    }}
                  >
                    <a class="dropdown-item">CSV</a>
                  </div>
                </div> */}
              </span>
            </div>
          </div>
          <div className="col-md-12 mt-3">
            <Table
              className="table table-bordered table-hover competitors-table"
              id="url"
              columns={CompTabcol}
              dataSource={tabcomplist}
              rowSelection={{ type: "checkbox", ...comprowSelection }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Competitors;
