import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";

function Widget(props) {
  const WidgetTypeText = props.WidgetTypeText;
  const TotalTickets = props.TotalTickets;
  const ToolTip = props.ToolTip;
  const [list, setList] = useState([]);

  useEffect(() => {}, []);

  return (
    <>
      <div class="col-md col-sm-6 mt-3">
      <div class="ticketoverviews-box">
        <div class="blue-box d-flex justify-content-between align-items-top">
          <div class="detail">
            <span>{WidgetTypeText}</span>
            <h5 class="fw-bold mt-1">{TotalTickets}</h5>
          </div>
          <div class="icon">
            <a
              href="#"
              class="text-dark"
              data-toggle="tooltip"
              data-html="true"
              title={ToolTip}
            >
              <img class="ticket-info" src="/images/info.png" />
            </a>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

export default Widget;
