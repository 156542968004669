import React from "react";
import Chart from "react-apexcharts";
import { useState, useEffect, useRef } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import "antd/dist/antd.css";
import { Table } from "antd";
import { Card } from "react-bootstrap";
import { ModuleExpandTickets } from "./index";
import { DateObject } from "react-multi-date-picker";
import {
  calanderDate,
  calanderDateCompare,
  convertDate,
  dateRagngeSelectWeek,
  exportInToCSV,
  exportToEXCEL,
  formateDateIntoDatemonth,
  mmDDyyyyFormateForForm22,
  sumOfArrayItems,
  avgOfArrayItems,
  themeStyle,
  fixTwoPlaceDecimal,
  returnMagnitude,
  toMonthName,
  downloadPDFfile,
  getBase64path,
  DDMMyyyyFormateDateSet,
} from "../utility/Globals";
import APIManager from "../services/APIManager";
import {
  googleAnalyticsChart,
  googleAnalyticsOSChart,
  googleAnalyticsSearch,
  googleAnalyticsSearchOS,
  moduleGoogleAnalytics,
  sendEmailApi,
  googleAnalyticsOSChartCompare,
  getGoogleAnalyticsConfig,
} from "../utility/Constant";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import { Sorter } from "../common/Sorter";
import { DateRangePicker } from "react-date-range";

import { addDays } from "date-fns";
import { DensityLarge } from "@mui/icons-material";
import { PDFExport } from "@progress/kendo-react-pdf";
import Loading from "../utility/Loading";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

function ModuleExpandGoogleAnalytics() {
  const [ChartView, setChartView] = useState(0);
  let titleText = "";
  if (ChartView == 0) {
    titleText = "Days";
  } else if (ChartView == 1) {
    titleText = "Weeks";
  } else if (ChartView == 2) {
    titleText = "Months";
  }

  const [options, setObject] = useState({
    legend: {
      show: true,
    },
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      stroke: {
        curve: "smooth",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: titleText,
        style: {
          color: "#000",
        },
      },
      labels: {
        formatter: function (val) {
          return toMonthName(val, titleText);
        },
      },
    },
    yaxis: {
      logBase: 5,
      tickAmount: 5,
      min: 0,
      labels: {
        formatter: function (val) {
          return returnMagnitude(val);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });

  //Organic Search
  const [oroptions, setORObject] = useState({
    chart: {
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          zoom: false,
          zoomin: true,
          zoomout: true,
          customIcons: [],
        },
      },
      stroke: {
        curve: "smooth",
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 6)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 5)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 4)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 3)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 2)),
        formateDateIntoDatemonth(new Date().setDate(new Date().getDate() - 1)),
        formateDateIntoDatemonth(new Date()),
      ],
      title: {
        text: titleText,
        style: {
          color: "#000",
        },
      },
      labels: {
        formatter: function (val) {
          return toMonthName(val, titleText);
        },
      },
    },
    yaxis: {
      logBase: 5,
      tickAmount: 5,
      min: 0,
      labels: {
        formatter: function (val) {
          return returnMagnitude(val);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
  });

  // let isCompareVar = false;

  const [series, setSeries] = useState([]);
  const [orseries, setORSeries] = useState([]); //Organic search
  const [GraphGASelected, setGraphGASelected] = useState([0]);
  const [isCompare, setIsCompare] = useState(false);
  const [GraphORSelected, setGraphORSelected] = useState([0]); //Organic search
  const [GraphGATabOptions, setGraphGATabOptions] = useState([]);
  const [GraphORTabOptions, setGraphORTabOptions] = useState([]); //Organic search
  const [daterange2withGraph, setdaterange2withGraph] = useState(false);
  const [ordaterange2withGraph, setordaterange2withGraph] = useState(false); //Organic search
  const [refreshTicketData, setRefreshTicketData] = useState(false);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [exportListCompare, setExportListCompare] = useState([]);

  const [orselectedExportList, setORSelectedExportList] = useState([]); //Organic search
  const [orexportList, setORExportList] = useState([]); // Organic Search
  const [orexportListCompare, setOrexportListCompare] = useState([]); //Organic Search
  const [GATable, setGATable] = useState([]);
  const [ORTable, setORTable] = useState([]); //Organic search
  const [GATableCompare, setGATableCompare] = useState([]);
  const [ORTableCompare, setORTableCompare] = useState([]); //Organic search
  const [GACol, setGACol] = useState([]);

  const [ORCol, setORCol] = useState([]); //Organic search
  const [subject, setSubject] = useState("Traffic Reports");
  const [Sender, setSender] = useState("");
  const [Receiver, setReceiver] = useState("");
  const [MessageReceiver, setMessageReceiver] = useState("");
  const [MessageSubject, setMessageSubject] = useState("");
  const [Message, setMessage] = useState("");
  const [selectedDeviceType, setDeviceType] = useState("2");
  const [EmailMessage, setEmailMessage] = useState("");
  const [EmailMessage1, setEmailMessage1] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["bottomRight"],
      showSizeChanger: true,
      pageSize: 9,
      pageSizeOptions: [9, 10, 20, 50, 100],
    },
  });

  const [color, setcolor] = useState([
    "#4e73df",
    "#008000",
    "#ffc107",
    "#dc3545",
    "#a52a2a",
    "#deb887",
    "#5f9ea0",
    "#8b008b",
    "#e9967a",
    "#c71585",
  ]);

  const [startDate1, setStartDate1] = useState("");
  const [startDate2, setStartDate2] = useState("");
  const [EndDate1, setEndDate1] = useState("");
  const [EndDate2, setEndDate2] = useState("");
  const [ChartView1, setChartView1] = useState(0);
  const [ORChartView, setORChartView] = useState(0); //Organic Search
  const [isLoading, setLoading] = useState(false);
  const [noConfig, setNoConfig] = useState(true);

  function getClassName(view) {
    switch (view) {
      case 0:
        if (ChartView == 0) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      case 1:
        if (ChartView == 1) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      case 2:
        if (ChartView == 2) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      default:
        if (ChartView == 0) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
    }
  }
  function getClassName1(view) {
    switch (view) {
      case 0:
        if (ChartView1 == 0) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      case 1:
        if (ChartView1 == 1) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      case 2:
        if (ChartView1 == 2) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
      default:
        if (ChartView1 == 0) return "btn btn-flat-primary active";
        else return "btn btn-flat-primary";
    }
  }

  useEffect(() => {
    localStorage.setItem("isCompareVar", false);

    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Traffic Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
    getGoogleAnalyticsConfigFn();
  }, []);

  function getGoogleAnalyticsConfigFn() {
    APIManager.getAPI(
      getGoogleAnalyticsConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response?.data) {
          setNoConfig(false);

          var data = [
            { label: "All users", value: "All" },
            { label: "Desktop Visits", value: "Desktop" },
            { label: "Mobile", value: "Mobile" },
            { label: "Tablet", value: "Tablet" },
          ];

          var columns1 = tableColGA();
          if (isDateRage) {
            setGACol(columns1);
          }

          data = [
            { label: "Sessions", value: "Session", key: 0 },
            { label: "Users", value: "Users", key: 1 },
            { label: "New Users", value: "NewUsers", key: 3 },
            { label: "Bounce Rate", value: "BounceRate", key: 2 },
            { label: "Revenue", value: "Revenue", key: 4 },
            {
              label: "Ecommerce Conversion Rate",
              value: "EcommerceConversionRate",
              key: 5,
            },
          ];

          const list = [];
          data.map((item) => {
            let tempArray = {
              key: item.id,
              keyIndex: item.key,
              id: item.id,
              value: item.value,
              label: item.label,
              dimension: item.value,
            };
            list.push(tempArray);
          });

          setGraphGATabOptions(list);
          setGraphGASelected("Session");
          setGraphORSelected(list[0]);
          let filterData = list[0];
          setChartView(0);
          if (isDateRage) {
            let getdata = {
              DateRange: 0,
              View: 0,
              DEVICETYPE: selectedDeviceType,
              projectId: localStorage.getItem("projectGId"),
              GOOGLEANALYTICSDATERANGE: {
                FROM: mmDDyyyyFormateDateSet(head1),
                TO: mmDDyyyyFormateDateSet(head2),
              },
            };
            creategoogleAnalyticsSearch(getdata);
            let chartData = {
              DATERANGE: 0,
              VIEW: 0,
              FILTER: [filterData.keyIndex],
              DEVICETYPE: selectedDeviceType,
              PROJECTID: localStorage.getItem("projectGId"),
              GOOGLEANALYTICSDATERANGE: {
                FROM: mmDDyyyyFormateDateSet(head1),
                TO: mmDDyyyyFormateDateSet(head2),
              },
            };
            getChartData(chartData, ChartView);
          } else {
            let getdata = {
              DATERANGE: 0,
              COMAPARETO: 0,
              DEVICETYPE: selectedDeviceType,
              PROJECTID: localStorage.getItem("projectGId"),
              GOOGLEANALYTICSDATERANGE: {
                FROM: mmDDyyyyFormateDateSet(head1),
                TO: mmDDyyyyFormateDateSet(head2),
              },
              GOOGLEANALYTICSCOMPARETORANGE: {
                FROM: mmDDyyyyFormateDateSet(head11),
                TO: mmDDyyyyFormateDateSet(head22),
              },
            };
            creategoogleAnalyticsSearchCompare(getdata);

            let chartData = {
              ComapareTo: 0, //New Added
              DATERANGE: 0,
              VIEW: 0,
              FILTER: [GraphGASelected],
              DEVICETYPE: selectedDeviceType,
              PROJECTID: localStorage.getItem("projectGId"),
              GOOGLEANALYTICSDATERANGE: {
                FROM: convertDate(state.selection1.startDate),
                TO: convertDate(state.selection1.endDate),
              },
              GoogleAnalyticsCompareToRange: {
                FROM: convertDate(state.selection2.startDate),
                TO: convertDate(state.selection2.endDate),
              },
            };
            getChartDataCompare(chartData, ChartView);
          }

          getOrganicSearchCconfigFn();
        }
      }
    });
  }

  function getOrganicSearchCconfigFn() {
    var data = [];
    var columns1 = tableColOSGA();
    setORCol(columns1);

    data = [
      { label: "Sessions", value: "Session", key: 0 },
      { label: "Users", value: "Users", key: 1 },
      { label: "Bounce Rate", value: "BounceRate", key: 2 },
      { label: "New Users", value: "NewUsers", key: 3 },
      { label: "Revenue", value: "Revenue", key: 4 },
      {
        label: "Ecommerce Conversion Rate",
        value: "EcommerceConversionRate",
        key: 5,
      },
    ];

    const list = [];

    data.map((item) => {
      let tempArray = {
        key: item.id,
        keyIndex: item.key,
        id: item.id,
        value: item.value,
        label: item.value,
        dimension: item.value,
      };
      list.push(tempArray);
    });

    setGraphORTabOptions(list);
  }

  //Organic Search
  useEffect(() => {
    let uid = localStorage.getItem("UserEmail");
    setSender(uid);
    var UserId = uid.toString();
    var message =
      UserId +
      " " +
      "has shared the Traffic Reports with you. Please find the attachment below.";
    setEmailMessage(message);
    setEmailMessage1(message);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      const ecomList = [];
      const revenueList = [];
      selectedRows.map((item) => {
        let obj = {
          defaultchannel: item?.defaultchannel,
          sessions: item?.sessions,
          users: item?.users,
          newusers: item?.newusers,
          bouncerate: item?.bouncerate,
          ecommerecerate: item?.ecommerecerate,
          revenue: item?.revenue,
        };
        list.push(obj);
        ecomList.push(item?.ecommerecerate);
        revenueList.push(item?.revenue);
      });
      var sumEcom = sumOfArrayItems(ecomList);
      var sumRevenue = sumOfArrayItems(revenueList);
      if (sumEcom == 0) {
        list.forEach((object) => {
          delete object["ecommerecerate"];
        });
      }
      if (sumRevenue == 0) {
        list.forEach((object) => {
          delete object["revenue"];
        });
      }
      setSelectedExportList(list);
    },
  };

  const comparerowSelection = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      let rows = [];
      if (info.type != "all") {
        let rowData = [];
        let rowChildrenData = [];
        for (let i = 0; i < selectedRows?.length; i++) {
          rowData = {
            key: selectedRows[i]["key"],
            defaultchannel: selectedRows[i]["defaultchannel"],
            sessions: selectedRows[i]["sessions"],
            users: selectedRows[i]["users"],
            newusers: selectedRows[i]["newusers"],
            bouncerate: selectedRows[i]["bouncerate"],
            ecommerecerate: selectedRows[i]["ecommerecerate"],
            revenue: selectedRows[i]["revenue"],
          };
          rows.push(rowData);

          rowChildrenData = selectedRows[i]["children"];
          for (let j = 0; j < 2; j++) {
            let record = {
              key: rowChildrenData[j]["key"],
              defaultchannel: rowChildrenData[j]["defaultchannel"],
              sessions: rowChildrenData[j]["sessions"],
              users: rowChildrenData[j]["users"],
              newusers: rowChildrenData[j]["newusers"],
              bouncerate: rowChildrenData[j]["bouncerate"],
              ecommerecerate: rowChildrenData[j]["ecommerecerate"],
              revenue: rowChildrenData[j]["revenue"],
            };
            rows.push(record);
          }
        }
        selectedRows = rows;
      } else {
        let rowData = [];
        for (let i = 0; i < selectedRows?.length; i++) {
          rowData = {
            key: selectedRows[i]["key"],
            defaultchannel: selectedRows[i]["defaultchannel"],
            sessions: selectedRows[i]["sessions"],
            users: selectedRows[i]["users"],
            newusers: selectedRows[i]["newusers"],
            bouncerate: selectedRows[i]["bouncerate"],
            ecommerecerate: selectedRows[i]["ecommerecerate"],
            revenue: selectedRows[i]["revenue"],
          };
          rows.push(rowData);
        }
        selectedRows = rows;
      }
      const list = [];
      const ecomList = [];
      const revenueList = [];
      selectedRows.map((item) => {
        let obj = {
          defaultchannel: item?.defaultchannel,
          sessions: item?.sessions,
          users: item?.users,
          newusers: item?.newusers,
          bouncerate: item?.bouncerate,
          ecommerecerate: item?.ecommerecerate,
          revenue: item?.revenue,
        };
        list.push(obj);
        ecomList.push(item?.ecommerecerate == "" ? 0 : item?.ecommerecerate);
        revenueList.push(item?.revenue == "" ? 0 : item?.revenue);
      });
      var sumEcom = sumOfArrayItems(ecomList);
      var sumRevenue = sumOfArrayItems(revenueList);
      if (sumEcom == 0) {
        list.forEach((object) => {
          delete object["ecommerecerate"];
        });
      }
      if (sumRevenue == 0) {
        list.forEach((object) => {
          delete object["revenue"];
        });
      }
      setSelectedExportList(list);
    },
    getCheckboxProps: (record) => ({
      className: record.bouncerate || record.bouncerate === 0 ? "d-none" : "", // Column configuration not to be checked
      bouncerate: record.bouncerate,
    }),
  };

  //Organic Search
  const orrowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      const list = [];
      const ecomList = [];
      const revenueList = [];

      selectedRows.map((item) => {
        let obj = {
          landingpage: item?.landingpage,
          sessions: item?.sessions,
          users: item?.users,
          newusers: item?.newusers,
          bouncerate: item?.bouncerate,
          ecommerecerate: item?.ecommerecerate,
          revenue: item?.revenue,
        };
        list.push(obj);
        ecomList.push(item?.ecommerecerate);
        revenueList.push(item?.revenue);
      });
      var sumEcom = sumOfArrayItems(ecomList);
      var sumRevenue = sumOfArrayItems(revenueList);

      if (sumEcom == 0) {
        list.forEach((object) => {
          delete object["ecommerecerate"];
        });
      }
      if (sumRevenue == 0) {
        list.forEach((object) => {
          delete object["revenue"];
        });
      }
      setORSelectedExportList(list);
    },
  };

  const compareorrowSelection = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      let rows = [];
      if (info.type != "all") {
        let rowData = [];
        let rowChildrenData = [];
        for (let i = 0; i < selectedRows?.length; i++) {
          rowData = {
            key: selectedRows[i]["key"],
            landingpage: selectedRows[i]["landingpage"],
            sessions: selectedRows[i]["sessions"],
            users: selectedRows[i]["users"],
            newusers: selectedRows[i]["newusers"],
            bouncerate: selectedRows[i]["bouncerate"],
            ecommerecerate: selectedRows[i]["ecommerecerate"],
            revenue: selectedRows[i]["revenue"],
          };
          rows.push(rowData);

          rowChildrenData = selectedRows[i]["children"];
          for (let j = 0; j < 2; j++) {
            let record = {
              key: rowChildrenData[j]["key"],
              landingpage: rowChildrenData[j]["landingpage"],
              sessions: rowChildrenData[j]["sessions"],
              users: rowChildrenData[j]["users"],
              newusers: rowChildrenData[j]["newusers"],
              bouncerate: rowChildrenData[j]["bouncerate"],
              ecommerecerate: rowChildrenData[j]["ecommerecerate"],
              revenue: rowChildrenData[j]["revenue"],
            };
            rows.push(record);
          }
        }
        selectedRows = rows;
      } else {
        let rowData = [];
        for (let i = 0; i < selectedRows?.length; i++) {
          rowData = {
            key: selectedRows[i]["key"],
            landingpage: selectedRows[i]["landingpage"],
            sessions: selectedRows[i]["sessions"],
            users: selectedRows[i]["users"],
            newusers: selectedRows[i]["newusers"],
            bouncerate: selectedRows[i]["bouncerate"],
            ecommerecerate: selectedRows[i]["ecommerecerate"],
            revenue: selectedRows[i]["revenue"],
          };
          rows.push(rowData);
        }
        selectedRows = rows;
      }
      const list = [];
      const ecomList = [];
      const revenueList = [];
      selectedRows.map((item) => {
        let obj = {
          landingpage: item?.landingpage,
          sessions: item?.sessions,
          users: item?.users,
          newusers: item?.newusers,
          bouncerate: item?.bouncerate,
          ecommerecerate: item?.ecommerecerate,
          revenue: item?.revenue,
        };
        list.push(obj);
        ecomList.push(item?.ecommerecerate == "" ? 0 : item?.ecommerecerate);
        revenueList.push(item?.revenue == "" ? 0 : item?.revenue);
      });
      var sumEcom = sumOfArrayItems(ecomList);
      var sumRevenue = sumOfArrayItems(revenueList);
      if (sumEcom == 0) {
        list.forEach((object) => {
          delete object["ecommerecerate"];
        });
      }
      if (sumRevenue == 0) {
        list.forEach((object) => {
          delete object["revenue"];
        });
      }

      setORSelectedExportList(list);
    },
    getCheckboxProps: (record) => ({
      className: record.bouncerate || record.bouncerate === 0 ? "d-none" : "", // Column configuration not to be checked
      bouncerate: record.bouncerate,
    }),
  };

  const handlePaginationChange = (pagination) => {
    setTableParams({
      pagination: {
        pageSize: pagination.pageSize,
        position: ["bottomRight"],
        showSizeChanger: true,
        pageSizeOptions: [9, 10, 20, 50, 100],
      },
    });
  };

  const [lastUpdateDate, setDate] = useState("");
  const [orlastUpdateDate, setORDate] = useState(""); //Organic Search
  const applyBtn = useRef(null);
  const applyBtn2 = useRef(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [orisMenuOpen, setORIsMenuOpen] = useState(false); //Organic Search

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  //Organic Search
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (orisMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setORIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [orisMenuOpen]);

  const [values, setValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [headValues, setHeadValues] = useState([
    new DateObject().subtract(7, "days"),
    new DateObject().add(-1, "days"),
  ]);

  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);

  const [oropen, setOROpen] = useState(false); //Organic Research
  const [orhead1, setORHead1] = useState(values[0].format()); //Organic Research
  const [orhead2, setORHead2] = useState(values[1].format()); //Organic Research
  const [orhead11, setORHead11] = useState(headValues[0].format()); //Organic Research
  const [orhead22, setORHead22] = useState(headValues[1].format()); //Organic Research

  const [head1, setHead1] = useState(values[0].format());
  const [head2, setHead2] = useState(values[1].format());
  const [head11, setHead11] = useState(headValues[0].format());
  const [head22, setHead22] = useState(headValues[1].format());
  const [headingofChart, setheadingofChart] = useState("Session");

  //Organic Research
  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
    setHead11(headValues[0].format());
    setHead22(headValues[1].format());
    setORHead1(values[0].format());
    setORHead2(values[1].format());
    setORHead11(headValues[0].format());
    setORHead22(headValues[1].format());
  }

  function isValidEmailData() {
    let isvalid = true;
    if (Receiver.trim() == "") {
      setMessageReceiver("Receiver Email id is required!");
      isvalid = false;
    } else {
      setMessageReceiver("");
    }
    if (subject.trim() == "") {
      setMessageSubject("Subject is required!");
      isvalid = false;
    } else {
      setMessageSubject("");
    }
    if (EmailMessage.trim() == "") {
      setMessage("Message is required!");
      isvalid = false;
    } else {
      setMessage("");
    }
    return isvalid;
  }

  async function sendEmail() {
    let theme = "light";
    if (document.querySelector("body").classList.contains("theme-dark")) {
      theme = "dark";
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
    }
    let fileBase64 = await getBase64path(image);
    let rowData = {
      from: Sender.trim(),
      to: Receiver.trim(),
      subject: subject.trim(),
      body: EmailMessage.trim(),
      base64Data: fileBase64,
      filename: moduleGoogleAnalytics,
    };
    APIManager.postAPI(sendEmailApi, rowData, null, true).then((response) => {
      if (response?.status == 200) {
        applyBtn.current.click();
        setReceiver("");
        setSubject("Traffic Reports");
        setEmailMessage(EmailMessage1);
        if (theme == "dark") {
          document.querySelector("body").classList.remove("theme-light");
          document.querySelector("body").classList.add("theme-dark");
        }
      }
    });
  }

  function handleView(value) {
    setSeries("");
    let filterSelected = GraphGASelected;
    if (isDateRage) {
      let chartData = {
        DATERANGE: 0,
        VIEW: value,
        FILTER: [GraphGASelected],
        DEVICETYPE: selectedDeviceType,
        PROJECTID: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: convertDate(state.selection1.startDate),
          TO: convertDate(state.selection1.endDate),
        },
      };
      getChartData(chartData, value);
    } else {
      let chartData = {
        ComapareTo: 0, //New Added
        DATERANGE: 0,
        VIEW: value,
        FILTER: [GraphGASelected],
        DEVICETYPE: selectedDeviceType,
        PROJECTID: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: convertDate(state.selection1.startDate),
          TO: convertDate(state.selection1.endDate),
        },
        GoogleAnalyticsCompareToRange: {
          FROM: convertDate(state.selection2.startDate),
          TO: convertDate(state.selection2.endDate),
        },
      };
      getChartDataCompare(chartData, value);
    }
    setChartView(value);
  }

  function chartHeading(value) {
    GraphGATabOptions.map((item) => {
      if (value == item.value) {
        setheadingofChart(item.label);
      }
    });
  }
  //Organic Search
  function handleViewor(value) {
    setORSeries("");
    let filterSelected = GraphGASelected;
    if (isDateRage) {
      let orchartData = {
        DATERANGE: 0,
        VIEW: value,
        FILTER: [GraphGASelected],
        DEVICETYPE: selectedDeviceType,
        PROJECTID: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: convertDate(state.selection1.startDate),
          TO: convertDate(state.selection1.endDate),
        },
      };
      getORChartData(orchartData, value);
    } else {
      let orchartData = {
        DATERANGE: 0,
        VIEW: value,
        ComapareTo: 0,
        FILTER: [GraphGASelected],
        DEVICETYPE: selectedDeviceType,
        PROJECTID: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: convertDate(state.selection1.startDate),
          TO: convertDate(state.selection1.endDate),
        },
        GoogleAnalyticsCompareToRange: {
          FROM: convertDate(state.selection2.startDate),
          TO: convertDate(state.selection2.endDate),
        },
      };
      getORChartDataCompare(orchartData, value);
    }

    setORChartView(value);
  }

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);

      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
    };
  }, [open]);

  //Organic Search
  useEffect(() => {
    const orcheckIfClickedOutside = (e) => {
      if (oropen && ref.current && !ref.current.contains(e.target)) {
        setOROpen(false);
      }
    };

    document.addEventListener("mousedown", orcheckIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", orcheckIfClickedOutside);
    };
  }, [oropen]);

  //Organic Search
  useEffect(() => {
    setORChartView(0);

    if (isDateRage) {
      let getdata = {
        DateRange: 0,
        View: 0,
        DEVICETYPE: selectedDeviceType,
        projectId: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: mmDDyyyyFormateDateSet(orhead1),
          TO: mmDDyyyyFormateDateSet(orhead2),
        },
      };
      createorganicResearchSearch(getdata);
      let chartData = {
        DATERANGE: 0,
        VIEW: 0,
        FILTER: ["Session"],
        DEVICETYPE: selectedDeviceType,
        PROJECTID: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: mmDDyyyyFormateDateSet(orhead1),
          TO: mmDDyyyyFormateDateSet(orhead2),
        },
      };
      getORChartData(chartData, ORChartView);
    } else {
      let getdata = {
        DATERANGE: 0,
        COMAPARETO: 0,
        DEVICETYPE: selectedDeviceType,
        PROJECTID: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: mmDDyyyyFormateDateSet(orhead1),
          TO: mmDDyyyyFormateDateSet(orhead2),
        },
        GOOGLEANALYTICSCOMPARETORANGE: {
          FROM: mmDDyyyyFormateDateSet(orhead11),
          TO: mmDDyyyyFormateDateSet(orhead22),
        },
      };
      createorganicResearchSearchCompare(getdata);

      let orchartData = {
        DATERANGE: 0,
        VIEW: 0,
        ComapareTo: 0,
        FILTER: [GraphGASelected],
        DEVICETYPE: selectedDeviceType,
        PROJECTID: localStorage.getItem("projectGId"),
        GOOGLEANALYTICSDATERANGE: {
          FROM: mmDDyyyyFormateDateSet(orhead1),
          TO: mmDDyyyyFormateDateSet(orhead2),
        },
        GoogleAnalyticsCompareToRange: {
          FROM: mmDDyyyyFormateDateSet(orhead11),
          TO: mmDDyyyyFormateDateSet(orhead22),
        },
      };
      getORChartDataCompare(orchartData, ORChartView);
    }
  }, []);

  function tableColGA() {
    var columns1 = [
      {
        title: "Default Channel",
        dataIndex: "defaultchannel",
        key: "defaultchannel",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.defaultchannel, b.defaultchannel),
          multiple: 3,
        },
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.sessions, b.sessions),
          multiple: 3,
        },
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.users, b.users),
          multiple: 3,
        },
      },
      {
        title: "New Users",
        dataIndex: "newusers",
        key: "newusers",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.newusers, b.newusers),
          multiple: 3,
        },
      },
      {
        title: "Bounce Rate",
        dataIndex: "bouncerate",
        key: "bouncerate",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.bouncerate, b.bouncerate),
          multiple: 3,
        },
      },
      {
        title: "Ecommerce Conversion Rate",
        dataIndex: "ecommerecerate",
        key: "ecommerecerate",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.ecommerecerate, b.ecommerecerate),
          multiple: 3,
        },
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.revenue, b.revenue),
          multiple: 3,
        },
      },
    ];
    return columns1;
  }

  function tableColGACompare() {
    var columns1 = [
      {
        title: "Default Channel",
        dataIndex: "defaultchannel",
        key: "defaultchannel",
        sorter: (a, b) => a.defaultchannel.localeCompare(b.defaultchannel),
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
      },
      {
        title: "New Users",
        dataIndex: "newusers",
        key: "newusers",
      },
      {
        title: "Bounce Rate",
        dataIndex: "bouncerate",
        key: "bouncerate",
      },
      {
        title: "Ecommerce Conversion Rate",
        dataIndex: "ecommerecerate",
        key: "ecommerecerate",
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
      },
    ];
    return columns1;
  }

  function tableColOSGA() {
    var columns1 = [
      {
        title: "Landing Pages",
        dataIndex: "landingpage",
        key: "landingpage",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.landingpage, b.landingpage),
          multiple: 3,
        },
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.sessions, b.sessions),
          multiple: 3,
        },
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.users, b.users),
          multiple: 3,
        },
      },
      {
        title: "New Users",
        dataIndex: "newusers",
        key: "newusers",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.newusers, b.newusers),
          multiple: 3,
        },
      },
      {
        title: "Bounce Rate",
        dataIndex: "bouncerate",
        key: "bouncerate",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.bouncerate, b.bouncerate),
          multiple: 3,
        },
      },

      {
        title: "Ecommerce Conversion Rate",
        dataIndex: "ecommerecerate",
        key: "ecommerecerate",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.ecommerecerate, b.ecommerecerate),
          multiple: 3,
        },
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.revenue, b.revenue),
          multiple: 3,
        },
      },
    ];
    return columns1;
  }

  function tableColGAOSCompare() {
    var columns1 = [
      {
        title: "Landing Pages",
        dataIndex: "landingpage",
        key: "landingpage",
        sorter: (a, b) => a.landingpage.localeCompare(b.landingpage),
      },
      {
        title: "Sessions",
        dataIndex: "sessions",
        key: "sessions",
      },
      {
        title: "Users",
        dataIndex: "users",
        key: "users",
      },
      {
        title: "New Users",
        dataIndex: "newusers",
        key: "newusers",
      },
      {
        title: "Bounce Rate",
        dataIndex: "bouncerate",
        key: "bouncerate",
      },

      {
        title: "Ecommerce Conversion Rate",
        dataIndex: "ecommerecerate",
        key: "ecommerecerate",
      },
      {
        title: "Revenue",
        dataIndex: "revenue",
        key: "revenue",
      },
    ];
    return columns1;
  }
  function getChartData(rowChartData, ChartView) {
    let viewValue = ChartView;
    let listArrIds = [];
    let keys = [];
    let values = [];
    APIManager.postAPI(googleAnalyticsChart, rowChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let option;
          let fields = [];
          let dataForGraph = [];
          response.data.tableContent.map((item, index) => {
            fields.push({
              label: item.fields,
              value: item.fields,
            });
            keys = Object.keys(item);
            values = Object.values(item);
          });
          keys.map((item) => {
            if (item != "fields") {
              listArrIds.push(item);
            }
          });
          response.data.tableContent.map((item) => {
            let graphData = [];
            let seriesData = [];
            const startDate = rowChartData.GOOGLEANALYTICSDATERANGE.FROM;
            const endDate = rowChartData.GOOGLEANALYTICSDATERANGE.TO;

            let sdate = new Date(state.selection1.startDate);
            let eDate = new Date(state.selection1.endDate);

            if (viewValue == 0) {
              if (startDate == endDate) {
                listArrIds.map((item1) => {
                  graphData.push(item[item1] ? item[item1] : 0);
                  seriesData.push(item1);
                });
              } else {
                const diffTime = Math.abs(
                  new Date(endDate) - new Date(startDate)
                );
                const diffDays = 0 | (diffTime / 864e5);
                if (diffDays) {
                  for (let i = 0; i <= diffDays; i++) {
                    const datedata = mmDDyyyyFormateDateSet(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    );
                    graphData.push(item[datedata] ? item[datedata] : 0);
                    seriesData.push(
                      formateDateIntoDatemonth(
                        new Date(new Date(startDate).getTime() + i * 864e5)
                      )
                    );
                  }
                }
              }
            } else if (viewValue == 1) {
              listArrIds.map((item1) => {
                graphData.push(item[item1] ? item[item1] : 0);
                seriesData.push(item1);
              });
            } else if (viewValue == 2) {
              let sMonth = sdate.getMonth() + 1;
              let eMonth = eDate.getMonth() + 1;
              for (let month = sMonth; month <= eMonth; month++) {
                graphData.push(item[month] ? item[month] : 0);
                seriesData.push(month);
              }
            }
            let titleText = "";
            if (viewValue == 0) {
              titleText = "Days";
            } else if (viewValue == 1) {
              titleText = "Weeks";
            } else if (viewValue == 2) {
              titleText = "Months";
            }
            let maxChartData =
              Math.max(...graphData) * 0.3 + Math.max(...graphData);
            option = {
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                tickPlacement: "on",
                categories: seriesData,
                title: {
                  text: titleText,
                  style: {
                    color: "#000",
                  },
                },
                labels: {
                  formatter: function (val) {
                    return toMonthName(val, titleText);
                  },
                },
              },
              yaxis: {
                logBase: 5,
                tickAmount: 5,
                min: 0,
                max: maxChartData,
                labels: {
                  formatter: function (val) {
                    return returnMagnitude(val);
                  },
                },
              },
              tooltip: {
                y: {
                  formatter: function (value) {
                    return value;
                  },
                },
              },
              markers: {
                size: 5,
                colorField: ["#ba4636"],
              },
            };
            dataForGraph.push({
              name: item.fields,
              data: graphData,
            });
          });

          setObject(option);
          setSeries(dataForGraph);
        }
      }
    );
  }

  function getChartDataCompare(rowChartData, ChartView) {
    let titleText1 = "";
    if (ChartView == 0) {
      titleText1 = "Days";
    } else if (ChartView == 1) {
      titleText1 = "Weeks";
    } else if (ChartView == 2) {
      titleText1 = "Months";
    }
    let viewValue = ChartView;
    let listArrIds1 = [];
    let listArrIdsNum1 = [];
    let listArrIds2 = [];
    let listArrIdsNum2 = [];
    let keys1 = [];
    let keys2 = [];
    let values1 = [];
    let values2 = [];
    let fields1 = [];
    let fields2 = [];
    let graphData1 = [];
    let graphData2 = [];
    let seriesData1 = [];
    let seriesData2 = [];
    let seriesData = [];
    let dataForGraph = [];
    let option;
    APIManager.postAPI(googleAnalyticsChart, rowChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          response.data.tableContent.map((item) => {
            fields1.push({
              label: item.fields,
              value: item.fields,
            });
            keys1 = Object.keys(item);
            values1 = Object.values(item);
          });
          let i = 1;
          keys1.map((item, index) => {
            if (item != "fields") {
              listArrIds1.push(item);
              listArrIdsNum1.push(i);
              i++;
            }
          });
          response.data.tableContent.map((item) => {
            const startDate = rowChartData.GOOGLEANALYTICSDATERANGE.FROM;
            const endDate = rowChartData.GOOGLEANALYTICSDATERANGE.TO;

            let sdate = new Date(state.selection1.startDate);
            let eDate = new Date(state.selection1.endDate);

            if (viewValue == 0) {
              if (startDate == endDate) {
                listArrIds1.map((item1) => {
                  graphData1.push(item[item1] ? item[item1] : 0);
                  seriesData1.push(item1);
                });
              } else {
                const diffTime = Math.abs(
                  new Date(endDate) - new Date(startDate)
                );
                const diffDays = 0 | (diffTime / 864e5);
                if (diffDays) {
                  for (let i = 0; i <= diffDays; i++) {
                    const datedata = mmDDyyyyFormateDateSet(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    );
                    graphData1.push(item[datedata] ? item[datedata] : 0);
                    seriesData1.push(
                      formateDateIntoDatemonth(
                        new Date(new Date(startDate).getTime() + i * 864e5)
                      )
                    );
                  }
                }
              }
            } else if (viewValue == 1) {
              listArrIds1.map((item1) => {
                graphData1.push(item[item1] ? item[item1] : 0);
                seriesData1.push(item1);
              });
            } else if (viewValue == 2) {
              let sMonth = sdate.getMonth() + 1;
              let eMonth = eDate.getMonth() + 1;
              for (let month = sMonth; month <= eMonth; month++) {
                graphData1.push(item[month] ? item[month] : 0);
                seriesData1.push(month);
              }
            }
            dataForGraph.push({
              name: item.fields,
              data: graphData1,
            });
          });

          // Compare Table Content

          response.data.compareTableContent.map((item) => {
            fields2.push({
              label: item.fields,
              value: item.fields,
            });
            keys2 = Object.keys(item);
            values2 = Object.values(item);
          });
          let j = 1;
          keys2.map((item) => {
            if (item != "fields") {
              listArrIds2.push(item);
              listArrIdsNum2.push(j);
              j++;
            }
          });
          response.data.compareTableContent.map((item) => {
            const startDate = rowChartData.GoogleAnalyticsCompareToRange.FROM;
            const endDate = rowChartData.GoogleAnalyticsCompareToRange.TO;

            let sdate = new Date(state.selection2.startDate);
            let eDate = new Date(state.selection2.endDate);

            if (viewValue == 0) {
              if (startDate == endDate) {
                listArrIds2.map((item1) => {
                  graphData2.push(item[item1] ? item[item1] : 0);
                  seriesData2.push(item1);
                });
              } else {
                const diffTime = Math.abs(
                  new Date(endDate) - new Date(startDate)
                );
                const diffDays = 0 | (diffTime / 864e5);
                if (diffDays) {
                  for (let i = 0; i <= diffDays; i++) {
                    const datedata = mmDDyyyyFormateDateSet(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    );
                    graphData2.push(item[datedata] ? item[datedata] : 0);
                    seriesData2.push(
                      formateDateIntoDatemonth(
                        new Date(new Date(startDate).getTime() + i * 864e5)
                      )
                    );
                  }
                }
              }
            } else if (viewValue == 1) {
              listArrIds2.map((item1) => {
                graphData2.push(item[item1] ? item[item1] : 0);
                seriesData2.push(item1);
              });
            } else if (viewValue == 2) {
              let sMonth = sdate.getMonth() + 1;
              let eMonth = eDate.getMonth() + 1;
              for (let month = sMonth; month <= eMonth; month++) {
                graphData2.push(item[month] ? item[month] : 0);
                seriesData2.push(month);
              }
            }
            dataForGraph.push({
              name: item.fields,
              data: graphData2,
            });
          });
          let maxChartData;
          if (listArrIds1.length > listArrIds2.length) {
            if (ChartView == 0) {
              seriesData = listArrIdsNum1;
            } else {
              seriesData = seriesData1;
            }
          } else {
            if (ChartView == 0) {
              seriesData = listArrIdsNum2;
            } else {
              seriesData = seriesData2;
            }
          }
          if (sumOfArrayItems(graphData1) > sumOfArrayItems(graphData2)) {
            maxChartData =
              Math.max(...graphData1) * 0.3 + Math.max(...graphData1);
          }
          else {
            maxChartData =
              Math.max(...graphData2) * 0.3 + Math.max(...graphData2);
          }

          option = {
            chart: {
              id: "basic-bar",
            },
            xaxis: {
              tickPlacement: "on",
              categories: seriesData,
              title: {
                text: titleText1,
                style: {
                  color: "#000",
                },
              },
              labels: {
                formatter: function (val) {
                  if (ChartView == 0) {
                    return val;
                  } else {
                    return val;
                  }
                },
              },
            },
            yaxis: {
              logBase: 5,
              tickAmount: 5,
              min: 0,
              max: maxChartData,
              labels: {
                formatter: function (val) {
                  return returnMagnitude(val);
                },
              },
            },
            tooltip: {
              // shared: true,
              // intersect: false,
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                let date = "-";
                const hoverXaxis =
                  w.globals.seriesX[seriesIndex][dataPointIndex];
                const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                  return seriesX.findIndex((xData) => xData === hoverXaxis);
                });

                let hoverList = "";
                hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                  if (seriesEachIndex == 0) {
                    if (viewValue == 0) {
                      seriesData1.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = item;
                        }
                      });
                    } else {
                      listArrIds1.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = toMonthName(item, titleText1);
                        }
                      });
                    }

                    if (
                      hoverIndex >= 0 &&
                      series[seriesEachIndex][hoverIndex] != undefined
                    ) {
                      hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  } else {
                    if (viewValue == 0) {
                      seriesData2.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = item;
                        }
                      });
                    } else {
                      listArrIds2.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = toMonthName(item, titleText1);
                        }
                      });
                    }

                    if (
                      hoverIndex >= 0 &&
                      series[seriesEachIndex][hoverIndex] != undefined
                    ) {
                      hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  }
                });
                const formatHoverX = hoverXaxis;

                return `<div class="card">
                  
                  <div class="card-body p-10">
                    ${hoverList}
                  </div>
                </div>`;
              },
            },
            markers: {
              size: 5,
              colorField: ["#ba4636"],
            },
          };
          setSeries("");
          setTimeout(() => {
            setSeries(dataForGraph);
          }, 100);
          setObject(option);
        }
      }
    );
  }

  function getORChartDataCompare(rowChartData, ChartView) {
    let titleText1 = "";
    if (ChartView == 0) {
      titleText1 = "Days";
    } else if (ChartView == 1) {
      titleText1 = "Weeks";
    } else if (ChartView == 2) {
      titleText1 = "Months";
    }
    let viewValue = ChartView;
    let listArrIds1 = [];
    let listArrIdsNum1 = [];
    let listArrIds2 = [];
    let listArrIdsNum2 = [];
    let keys1 = [];
    let keys2 = [];
    let values1 = [];
    let values2 = [];
    let fields1 = [];
    let fields2 = [];
    let graphData1 = [];
    let graphData2 = [];
    let seriesData1 = [];
    let seriesData2 = [];
    let seriesData = [];
    let dataForGraph = [];
    let option;

    APIManager.postAPI(
      googleAnalyticsOSChartCompare,
      rowChartData,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        response.data.tableContent.map((item) => {
          fields1.push({
            label: item.fields,
            value: item.fields,
          });
          keys1 = Object.keys(item);
          values1 = Object.values(item);
        });
        let i = 1;
        keys1.map((item) => {
          if (item != "fields") {
            listArrIds1.push(item);
            listArrIdsNum1.push(i);
            i++;
          }
        });
        response.data.tableContent.map((item) => {
          const startDate = rowChartData.GOOGLEANALYTICSDATERANGE.FROM;
          const endDate = rowChartData.GOOGLEANALYTICSDATERANGE.TO;

          let sdate = new Date(state.selection1.startDate);
          let eDate = new Date(state.selection1.endDate);

          if (viewValue == 0) {
            if (startDate == endDate) {
              listArrIds1.map((item1) => {
                graphData1.push(item[item1] ? item[item1] : 0);
                seriesData1.push(item1);
              });
            } else {
              const diffTime = Math.abs(
                new Date(endDate) - new Date(startDate)
              );
              const diffDays = 0 | (diffTime / 864e5);
              if (diffDays) {
                for (let i = 0; i <= diffDays; i++) {
                  const datedata = mmDDyyyyFormateDateSet(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  );
                  graphData1.push(item[datedata] ? item[datedata] : 0);
                  seriesData1.push(
                    formateDateIntoDatemonth(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    )
                  );
                }
              }
            }
          } else if (viewValue == 1) {
            listArrIds1.map((item1) => {
              graphData1.push(item[item1] ? item[item1] : 0);
              seriesData1.push(item1);
            });
          } else if (viewValue == 2) {
            let sMonth = sdate.getMonth() + 1;
            let eMonth = eDate.getMonth() + 1;
            for (let month = sMonth; month <= eMonth; month++) {
              graphData1.push(item[month] ? item[month] : 0);
              seriesData1.push(month);
            }
          }
          dataForGraph.push({
            name: item.fields,
            data: graphData1,
          });
        });

        // Compare Table Content

        response.data.compareTableContent.map((item) => {
          fields2.push({
            label: item.fields,
            value: item.fields,
          });
          keys2 = Object.keys(item);
          values2 = Object.values(item);
        });
        let j = 1;
        keys2.map((item) => {
          if (item != "fields") {
            listArrIds2.push(item);
            listArrIdsNum2.push(j);
            j++;
          }
        });
        response.data.compareTableContent.map((item) => {
          const startDate = rowChartData.GoogleAnalyticsCompareToRange.FROM;
          const endDate = rowChartData.GoogleAnalyticsCompareToRange.TO;

          let sdate = new Date(state.selection2.startDate);
          let eDate = new Date(state.selection2.endDate);

          if (viewValue == 0) {
            if (startDate == endDate) {
              listArrIds2.map((item1) => {
                graphData2.push(item[item1] ? item[item1] : 0);
                seriesData2.push(item1);
              });
            } else {
              const diffTime = Math.abs(
                new Date(endDate) - new Date(startDate)
              );
              const diffDays = 0 | (diffTime / 864e5);
              if (diffDays) {
                for (let i = 0; i <= diffDays; i++) {
                  const datedata = mmDDyyyyFormateDateSet(
                    new Date(new Date(startDate).getTime() + i * 864e5)
                  );
                  graphData2.push(item[datedata] ? item[datedata] : 0);
                  seriesData2.push(
                    formateDateIntoDatemonth(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    )
                  );
                }
              }
            }
          } else if (viewValue == 1) {
            listArrIds2.map((item1) => {
              graphData2.push(item[item1] ? item[item1] : 0);
              seriesData2.push(item1);
            });
          } else if (viewValue == 2) {
            let sMonth = sdate.getMonth() + 1;
            let eMonth = eDate.getMonth() + 1;
            for (let month = sMonth; month <= eMonth; month++) {
              graphData2.push(item[month] ? item[month] : 0);
              seriesData2.push(month);
            }
          }
          dataForGraph.push({
            name: item.fields,
            data: graphData2,
          });
        });

        let maxChartData;
        if (listArrIds1.length > listArrIds2.length) {
          if (ChartView == 0) {
            seriesData = listArrIdsNum1;
          } else {
            seriesData = seriesData1;
          }
        } else {
          if (ChartView == 0) {
            seriesData = listArrIdsNum2;
          } else {
            seriesData = seriesData2;
          }
        }
        if (sumOfArrayItems(graphData1) > sumOfArrayItems(graphData2)) {
          maxChartData =
            Math.max(...graphData1) * 0.3 + Math.max(...graphData1);
        }
        else {
          maxChartData =
            Math.max(...graphData2) * 0.3 + Math.max(...graphData2);
        }

        option = {
          chart: {
            id: "basic-bar",
          },

          xaxis: {
            tickPlacement: "on",
            categories: seriesData,
            title: {
              text: titleText1,
              style: {
                color: "#000",
              },
            },
            labels: {
              formatter: function (val) {
                if (ChartView == 0) {
                  return val;
                } else {
                  return val;
                }
              },
            },
          },
          yaxis: {
            logBase: 5,
            tickAmount: 5,
            min: 0,
            max: maxChartData,
            labels: {
              formatter: function (val) {
                return returnMagnitude(val);
              },
            },
          },

          tooltip: {
            custom: ({ series, seriesIndex, dataPointIndex, w }) => {
              let date = "-";
              const hoverXaxis = w.globals.seriesX[seriesIndex][dataPointIndex];
              const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                return seriesX.findIndex((xData) => xData === hoverXaxis);
              });

              let hoverList = "";
              hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                if (seriesEachIndex == 0) {
                  if (viewValue == 0) {
                    seriesData1.map((item, index) => {
                      if (dataPointIndex == index) {
                        date = item;
                      }
                    });
                  } else {
                    listArrIds1.map((item, index) => {
                      if (dataPointIndex == index) {
                        date = toMonthName(item, titleText1);
                      }
                    });
                  }

                  if (
                    hoverIndex >= 0 &&
                    series[seriesEachIndex][hoverIndex] != undefined
                  ) {
                    hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                } else {
                  if (viewValue == 0) {
                    seriesData2.map((item, index) => {
                      if (dataPointIndex == index) {
                        date = item;
                      }
                    });
                  } else {
                    listArrIds2.map((item, index) => {
                      if (dataPointIndex == index) {
                        date = toMonthName(item, titleText1);
                      }
                    });
                  }

                  if (
                    hoverIndex >= 0 &&
                    series[seriesEachIndex][hoverIndex] != undefined
                  ) {
                    hoverList += `<span>${date}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                  }
                }
              });
              const formatHoverX = hoverXaxis;

              return `<div class="card">
                  <!--<div class="card-header p-10">${formatHoverX}</div>-->
                  <div class="card-body p-10">
                    ${hoverList}
                  </div>
                </div>`;
            },
          },
          markers: {
            size: 5,
            colorField: ["#ba4636"],
          },
        };

        setTimeout(() => {
          setORSeries(dataForGraph);
        }, 100);
        setORObject(option);
      }
    });
  }

  function exportListOrder(exportList) {
    let expList1 = [];
    let expList2 = [];

    exportList.map((item1) => {
      if (item1.defaultchannel == "Organic Search") {
        expList1.push(item1);
      } else {
        expList2.push(item1);
      }
    });
    let expListMain = expList1.concat(expList2);
    return expListMain;
  }
  //OrganicResearch
  function getORChartData(rowChartData, ChartView) {
    let viewValue = ChartView;
    let listArrIds = [];
    let keys = [];
    let values = [];
    APIManager.postAPI(googleAnalyticsOSChart, rowChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let option;
          let fields = [];
          let dataForGraph = [];
          response.data.map((item) => {
            fields.push({
              label: item.fields,
              value: item.fields,
            });
            keys = Object.keys(item);
            values = Object.values(item);
          });
          keys.map((item) => {
            if (item != "fields") {
              listArrIds.push(item);
            }
          });

          response.data.map((item) => {
            let graphData = [];
            let seriesData = [];
            const startDate = rowChartData.GOOGLEANALYTICSDATERANGE.FROM;
            const endDate = rowChartData.GOOGLEANALYTICSDATERANGE.TO;

            let sdate = new Date(state.selection1.startDate);
            let eDate = new Date(state.selection1.endDate);

            if (viewValue == 0) {
              if (startDate == endDate) {
                listArrIds.map((item1) => {
                  graphData.push(item[item1] ? item[item1] : 0);
                  seriesData.push(item1);
                });
              } else {
                const diffTime = Math.abs(
                  new Date(endDate) - new Date(startDate)
                );
                const diffDays = 0 | (diffTime / 864e5);
                if (diffDays) {
                  for (let i = 0; i <= diffDays; i++) {
                    const datedata = mmDDyyyyFormateDateSet(
                      new Date(new Date(startDate).getTime() + i * 864e5)
                    );
                    graphData.push(item[datedata] ? item[datedata] : 0);
                    seriesData.push(
                      formateDateIntoDatemonth(
                        new Date(new Date(startDate).getTime() + i * 864e5)
                      )
                    );
                  }
                }
              }
            } else if (viewValue == 1) {
              listArrIds.map((item1) => {
                graphData.push(item[item1] ? item[item1] : 0);
                seriesData.push(item1);
              });
            } else if (viewValue == 2) {
              let sMonth = sdate.getMonth() + 1;
              let eMonth = eDate.getMonth() + 1;

              for (let month = sMonth; month <= eMonth; month++) {
                graphData.push(item[month] ? item[month] : 0);
                seriesData.push(month);
              }
            }

            let titleText = "";

            if (viewValue == 0) {
              titleText = "Days";
            } else if (viewValue == 1) {
              titleText = "Weeks";
            } else if (viewValue == 2) {
              titleText = "Months";
            }

            let maxChartData =
              Math.max(...graphData) * 0.3 + Math.max(...graphData);
            option = {
              chart: {
                id: "basic-bar",
              },
              xaxis: {
                tickPlacement: "on",
                categories: seriesData,
                title: {
                  text: titleText,
                  style: {
                    color: "#000",
                  },
                },
                labels: {
                  formatter: function (val) {
                    return toMonthName(val, titleText);
                  },
                },
              },
              yaxis: {
                logBase: 5,
                tickAmount: 5,
                min: 0,
                max: maxChartData,
                labels: {
                  formatter: function (val) {
                    return returnMagnitude(val);
                  },
                },
              },
              tooltip: {
                y: {
                  formatter: function (value) {
                    return value;
                  },
                },
              },
              markers: {
                size: 5,
                colorField: ["#ba4636"],
              },
            };
            dataForGraph.push({
              name: item.fields,
              data: graphData,
            });
          });
          setORObject(option);
          setORSeries(dataForGraph);
        }
      }
    );
  }

  function creategoogleAnalyticsSearch(rowdata) {
    APIManager.postAPI(googleAnalyticsSearch, rowdata, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          setDate(response.data[0]?.createdAt);
          const list = [];
          const exportList = [];
          let i = 0;
          let sessionMainList = [];
          let usersMainList = [];
          let newUsersMainList = [];
          let revenueMainList = [];
          let bounceRateMainList = [];
          let ecommerceConversionRateMainList = [];

          response.data.tableContent.map((item) => {
            sessionMainList.push(item.session);
            usersMainList.push(item.users);
            newUsersMainList.push(item.newUsers);
            bounceRateMainList.push(item.bounceRate);
            ecommerceConversionRateMainList.push(item.ecommerceConversionRate);
            revenueMainList.push(item.revenue);

            exportList.push({
              defaultchannel: item?.channel,
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            });
            let obj = {
              key: (i += 1),
              defaultchannel: item?.channel,
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            list.push(obj);
          });
          let list1 = [];
          let list2 = [];
          list.map((item1) => {
            if (item1.defaultchannel == "Organic Search") {
              list1.push(item1);
            } else {
              list2.push(item1);
            }
          });
          let list3 = list1.concat(list2);
          setGATable(list3);

          let sessionAvg = sumOfArrayItems(sessionMainList);
          let usersAvg = sumOfArrayItems(usersMainList);
          let newUsersAvg = sumOfArrayItems(newUsersMainList);
          let revenueAvg = sumOfArrayItems(revenueMainList);
          let bounceRateAvg = avgOfArrayItems(bounceRateMainList);
          let ecommerceConversionRateAvg = sumOfArrayItems(
            ecommerceConversionRateMainList
          );

          let listData = [
            {
              avgData: fixTwoPlaceDecimal(usersAvg),
              user: "Users",
              data: usersAvg.toFixed(2),
              compareData: usersAvg.toFixed(2),
              isHighData: 2,
            },
            {
              avgData: fixTwoPlaceDecimal(newUsersAvg),
              user: "New Users",
              data: newUsersAvg.toFixed(2),
              compareData: newUsersAvg.toFixed(2),
              isHighData: 2,
            },
            {
              avgData: fixTwoPlaceDecimal(sessionAvg),
              user: "Sessions",
              data: sessionAvg.toFixed(2),
              compareData: sessionAvg.toFixed(2),
              isHighData: 2,
            },
            {
              avgData: bounceRateAvg.toFixed(2) + "%",
              user: "Bounce Rate",
              data: bounceRateAvg.toFixed(2),
              compareData: bounceRateAvg.toFixed(2),
              isHighData: 2,
            },
          ];
          var columns1 = tableColGA();
          let newList = [];
          let index1, index2;
          columns1.map((item) => {
            newList.push(item);
          });
          if (ecommerceConversionRateAvg != 0) {
            var obj = {
              avgData: fixTwoPlaceDecimal(ecommerceConversionRateAvg),
              user: "Ecommerce Conversion Rate",
              data: ecommerceConversionRateAvg.toFixed(2),
              compareData: ecommerceConversionRateAvg.toFixed(2),
              isHighData: 2,
            };
            listData.push(obj);
          } else {
            columns1.map((item, index) => {
              if (item.dataIndex == "ecommerecerate") {
                index1 = index;
              }
            });
            exportList.forEach((object) => {
            });
          }
          if (revenueAvg != 0) {
            var obj = {
              avgData: fixTwoPlaceDecimal(revenueAvg),
              user: "Revenue",
              data: revenueAvg.toFixed(2),
              compareData: revenueAvg.toFixed(2),
              isHighData: 2,
            };
            listData.push(obj);
          } else {
            newList.map((item, index) => {
              if (item.dataIndex == "revenue") {
                index2 = index;
              }
            });
            exportList.forEach((object) => {
            });
          }
          setCardListData(listData);
          setGACol(newList);

          setExportList(exportListOrder(exportList));
        } else {
          setGATable([]);
          setGATableCompare([]);
        }
      }
    );
  }

  //OrganicResearch
  function createorganicResearchSearch(rowdata) {
    APIManager.postAPI(googleAnalyticsSearchOS, rowdata, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          setORDate(response.data[0]?.createdAt);
          const list = [];
          const exportList = [];
          let i = 0;
          let sessionMainList = [];
          let usersMainList = [];
          let newUsersMainList = [];
          let revenueMainList = [];
          let bounceRateMainList = [];
          let ecommerceConversionRateMainList = [];

          response.data.tableContent.map((item) => {
            sessionMainList.push(item.session);
            usersMainList.push(item.users);
            newUsersMainList.push(item.newUsers);
            revenueMainList.push(item.revenue);
            bounceRateMainList.push(item.bounceRate);
            ecommerceConversionRateMainList.push(item.ecommerceConversionRate);
            exportList.push({
              landingpage: item?.path.toString().replace(",", " "),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            });

            let obj = {
              key: (i += 1),
              landingpage: item?.path,
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              revenue: item?.revenue,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
            };
            list.push(obj);
          });

          setORTable(list);
          let sessionAvg = sumOfArrayItems(sessionMainList);
          let usersAvg = sumOfArrayItems(usersMainList);
          let newUsersAvg = sumOfArrayItems(newUsersMainList);
          let revenueAvg = sumOfArrayItems(revenueMainList);
          let bounceRateAvg = avgOfArrayItems(bounceRateMainList);
          let ecommerceConversionRateAvg = sumOfArrayItems(
            ecommerceConversionRateMainList
          );

          let listData = [
            {
              avgData: fixTwoPlaceDecimal(usersAvg),
              user: "Users",
              data: usersAvg.toFixed(2),
              compareData: usersAvg.toFixed(2),
              isHighData: 2,
            },
            {
              avgData: fixTwoPlaceDecimal(newUsersAvg),
              user: "New Users",
              data: newUsersAvg.toFixed(2),
              compareData: newUsersAvg.toFixed(2),
              isHighData: 2,
            },
            {
              avgData: fixTwoPlaceDecimal(sessionAvg),
              user: "Sessions",
              data: sessionAvg.toFixed(2),
              compareData: sessionAvg.toFixed(2),
              isHighData: 2,
            },
            {
              avgData: bounceRateAvg.toFixed(2) + "%",
              user: "Bounce Rate",
              data: bounceRateAvg.toFixed(2) + "%",
              compareData: bounceRateAvg.toFixed(2) + "%",
              isHighData: 2,
            },
          ];
          var columns1 = tableColOSGA();
          let newList = [];
          let index1, index2;
          columns1.map((item) => {
            newList.push(item);
          });

          if (ecommerceConversionRateAvg != 0) {
            var obj = {
              avgData: fixTwoPlaceDecimal(ecommerceConversionRateAvg),
              user: "Ecommerce Conversion Rate",
              data: ecommerceConversionRateAvg.toFixed(2),
              compareData: ecommerceConversionRateAvg.toFixed(2),
              isHighData: 2,
            };
            listData.push(obj);
          } else {
            columns1.map((item, index) => {
              if (item.dataIndex == "ecommerecerate") {
                index1 = index;
              }
            });

            exportList.forEach((object) => {
            });
          }
          if (revenueAvg != 0) {
            var obj = {
              avgData: fixTwoPlaceDecimal(revenueAvg),
              user: "Revenue",
              data: revenueAvg.toFixed(2),
              compareData: revenueAvg.toFixed(2),
              isHighData: 2,
            };
            listData.push(obj);
          } else {
            newList.map((item, index) => {
              if (item.dataIndex == "revenue") {
                index2 = index;
              }
            });

            exportList.forEach((object) => {
            });
          }
          setORCardListData(listData);
          setORCol(newList);
          setORExportList(exportList);
        } else {
          setORTable([]);
          setORTableCompare([]);
        }
      }
    );
  }

  function creategoogleAnalyticsSearchCompare(rowdata) {
    APIManager.postAPI(googleAnalyticsSearch, rowdata, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          setDate(response.data[0]?.createdAt);
          const list = [];
          const listCompareTableContent = [];
          const exportList = [];
          const compareexportList = [];
          let i = 0;

          let sessionMainList = [];
          let usersMainList = [];
          let newUsersMainList = [];
          let revenueMainList = [];
          let bounceRateMainList = [];
          let ecommerceConversionRateMainList = [];

          let sessionCompareLst = [];
          let usersCompareLst = [];
          let newUsersCompareLst = [];
          let revenueCompareLst = [];
          let bounceRateCompareLst = [];
          let ecommerceConversionRateCompareLst = [];

          response.data.tableContent.sort((a, b) =>
            a.channel.toLowerCase() > b.channel.toLowerCase() ? 1 : -1
          );
          response.data.tableContent.map((item) => {
            sessionMainList.push(item.session);
            usersMainList.push(item.users);
            newUsersMainList.push(item.newUsers);
            revenueMainList.push(item.revenue);
            bounceRateMainList.push(item.bounceRate);
            ecommerceConversionRateMainList.push(item.ecommerceConversionRate);

            let obj = {
              key: (i += 1),
              defaultchannel: item?.channel,
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            list.push(obj);
          });

          let list1 = [];
          let list2 = [];
          list.map((item1) => {
            if (item1.defaultchannel == "Organic Search") {
              list1.push(item1);
            } else {
              list2.push(item1);
            }
          });
          let list3 = list1.concat(list2);
          setGATable(list3);

          let objData = [];
          response.data.compareTableContent.sort((a, b) =>
            a.channel.toLowerCase() > b.channel.toLowerCase() ? 1 : -1
          );
          response.data.compareTableContent.map((item, index) => {
            sessionCompareLst.push(item.session);
            usersCompareLst.push(item.users);
            newUsersCompareLst.push(item.newUsers);
            revenueCompareLst.push(item.revenue);
            bounceRateCompareLst.push(item.bounceRate);
            ecommerceConversionRateCompareLst.push(
              item.ecommerceConversionRate
            );

            const filteredTableContent = Object.values(
              response?.data?.tableContent
            ).filter(
              (e) => e.channel.toLowerCase() == item.channel.toLowerCase()
            );

            let obj = {
              key: (i += 1),
              defaultchannel: item.channel,
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            listCompareTableContent.push(obj);

            let expObj = {
              key: (i += 1),
              defaultchannel: item.channel,
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            compareexportList.push(expObj);

            let obj1 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
              revenue: filteredTableContent[0]?.revenue,
            };
            listCompareTableContent.push(obj1);

            let expObj1 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
              revenue: filteredTableContent[0]?.revenue,
            };
            compareexportList.push(expObj1);

            let obj2 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            listCompareTableContent.push(obj2);
            objData[index] = [obj, obj1, obj2];

            let expObj2 = {
              key: (i += 1),
              defaultchannel:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            compareexportList.push(expObj2);
          });

          let sessionAvg = sumOfArrayItems(sessionMainList);
          let sessionCompareAvg = sumOfArrayItems(sessionCompareLst);
          let usersAvg = sumOfArrayItems(usersMainList);
          let usersCompareAvg = sumOfArrayItems(usersCompareLst);
          let newUsersAvg = sumOfArrayItems(newUsersMainList);
          let newUsersCompareAvg = sumOfArrayItems(newUsersCompareLst);
          let revenueAvg = sumOfArrayItems(revenueMainList);
          let revenueCompareAvg = sumOfArrayItems(revenueCompareLst);
          let bounceRateAvg = avgOfArrayItems(bounceRateMainList);
          let bounceRateCompareAvg = avgOfArrayItems(bounceRateCompareLst);
          let ecommerceConversionRateAvg = sumOfArrayItems(
            ecommerceConversionRateMainList
          );
          let ecommerceConversionRateCompareAvg = sumOfArrayItems(
            ecommerceConversionRateCompareLst
          );

          let pSessionAvg =
            sessionAvg < sessionCompareAvg
              ? (100 * (sessionCompareAvg - sessionAvg)) / sessionCompareAvg
              : (100 * (sessionAvg - sessionCompareAvg)) / sessionAvg;

          let pUsersAvg =
            usersAvg < usersCompareAvg
              ? (100 * (usersCompareAvg - usersAvg)) / usersCompareAvg
              : (100 * (usersAvg - usersCompareAvg)) / usersAvg;
          let pNewUsersAvg =
            newUsersAvg < newUsersCompareAvg
              ? (100 * (newUsersCompareAvg - newUsersAvg)) / newUsersCompareAvg
              : (100 * (newUsersAvg - newUsersCompareAvg)) / newUsersAvg;
          let pRevenueAvg =
            revenueAvg < revenueCompareAvg
              ? (100 * (revenueCompareAvg - revenueAvg)) / revenueCompareAvg
              : (100 * (revenueAvg - revenueCompareAvg)) / revenueAvg;
          let pBounceRateAvg =
            bounceRateAvg < bounceRateCompareAvg
              ? (100 * (bounceRateCompareAvg - bounceRateAvg)) /
              bounceRateCompareAvg
              : (100 * (bounceRateAvg - bounceRateCompareAvg)) / bounceRateAvg;
          let pEcommerceConversionRateAvg =
            ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg
              ? (100 *
                (ecommerceConversionRateCompareAvg -
                  ecommerceConversionRateAvg)) /
              ecommerceConversionRateCompareAvg
              : (100 *
                (ecommerceConversionRateAvg -
                  ecommerceConversionRateCompareAvg)) /
              ecommerceConversionRateAvg;

          let listData = [
            {
              avgData: pUsersAvg.toFixed(2),
              user: "Users",
              data: fixTwoPlaceDecimal(usersAvg),
              compareData: fixTwoPlaceDecimal(usersCompareAvg),
              isHighData: usersAvg < usersCompareAvg,
            },
            {
              avgData: pNewUsersAvg.toFixed(2),
              user: "New Users",
              data: fixTwoPlaceDecimal(newUsersAvg),
              compareData: fixTwoPlaceDecimal(newUsersCompareAvg),
              isHighData: newUsersAvg < newUsersCompareAvg,
            },
            {
              avgData: pSessionAvg.toFixed(2),
              user: "Sessions",
              data: fixTwoPlaceDecimal(sessionAvg),
              compareData: fixTwoPlaceDecimal(sessionCompareAvg),
              isHighData: sessionAvg < sessionCompareAvg,
            },
            {
              avgData: pBounceRateAvg.toFixed(2),
              user: "Bounce Rate",
              data: fixTwoPlaceDecimal(bounceRateAvg) + "%",
              compareData: fixTwoPlaceDecimal(bounceRateCompareAvg) + "%",
              isHighData: bounceRateAvg < bounceRateCompareAvg,
            },
          ];
          var columns1 = tableColGACompare();
          let newList = [];
          let index11, index22;
          columns1.map((item) => {
            newList.push(item);
          });

          if (
            ecommerceConversionRateAvg != 0 ||
            ecommerceConversionRateCompareAvg != 0
          ) {
            var obj = {
              avgData: pEcommerceConversionRateAvg.toFixed(2),
              user: "Ecommerce Conversion Rate",
              data: fixTwoPlaceDecimal(ecommerceConversionRateAvg),
              compareData: fixTwoPlaceDecimal(
                ecommerceConversionRateCompareAvg
              ),
              isHighData:
                ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg,
            };
            listData.push(obj);
          } else {
            columns1.map((item, index) => {
              if (item.dataIndex == "ecommerecerate") {
                index11 = index;
              }
            });

            compareexportList.forEach((object) => {
            });
          }

          if (revenueAvg != 0 || revenueCompareAvg != 0) {
            var obj = {
              avgData: pRevenueAvg.toFixed(2),
              user: "Revenue",
              data: fixTwoPlaceDecimal(revenueAvg),
              compareData: fixTwoPlaceDecimal(revenueCompareAvg),
              isHighData: revenueAvg < revenueCompareAvg,
            };
            listData.push(obj);
          } else {
            newList.map((item, index) => {
              if (item.dataIndex == "revenue") {
                index22 = index;
              }
            });
            compareexportList.forEach((object) => {
            });
          }
          setCardListData(listData);
          setGACol(newList);
          let index1;
          let orlist = [];
          let orlist1 = [];

          objData.map((item, index) => {
            if (item[0].defaultchannel == "Organic Search") {
              index1 = index;
            }

            if (index == index1) {
              orlist.push(item);
            } else {
              orlist1.push(item);
            }
          });
          let mainList = orlist.concat(orlist1);

          let newObjData = [];
          let objListData = [];
          let childrenData = [];
          for (let i = 0; i < mainList.length; i++) {
            let newListData = mainList[i];
            let listKeys = Object.keys(newListData);
            let count = 0;
            childrenData = [];
            for (let j = 0; j < listKeys.length; j++) {
              if (parseInt(listKeys[j]) != 0) {
                childrenData[count] = {
                  key: newListData[listKeys[j]]["key"],
                  defaultchannel: newListData[listKeys[j]]["defaultchannel"],
                  sessions: newListData[listKeys[j]]["sessions"],
                  users: newListData[listKeys[j]]["users"],
                  newusers: newListData[listKeys[j]]["newusers"],
                  bouncerate: newListData[listKeys[j]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[j]]["ecommerecerate"],
                  revenue: newListData[listKeys[j]]["revenue"],
                };

                objListData = {
                  key: newListData[listKeys[0]]["key"],
                  defaultchannel: newListData[listKeys[0]]["defaultchannel"],
                  sessions: newListData[listKeys[0]]["sessions"],
                  users: newListData[listKeys[0]]["users"],
                  newusers: newListData[listKeys[0]]["newusers"],
                  bouncerate: newListData[listKeys[0]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[0]]["ecommerecerate"],
                  revenue: newListData[listKeys[0]]["revenue"],
                  children: childrenData,
                };

                count++;
              }
            }
            newObjData[i] = objListData;
          }

          setGATableCompare([]);
          setGATableCompare(newObjData);
          setExportListCompare(compareexportList);
        } else {
          setGATable([]);
          setGATableCompare([]);
        }
      }
    );
  }

  //OrganicResearch
  function createorganicResearchSearchCompare(rowdata) {
    APIManager.postAPI(googleAnalyticsSearchOS, rowdata, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          setORDate(response.data[0]?.createdAt);
          const list = [];
          const listCompareTableContent = [];
          const compareorexportList = [];
          let j = 0;

          let sessionMainList = [];
          let usersMainList = [];
          let newUsersMainList = [];
          let revenueMainList = [];
          let bounceRateMainList = [];
          let ecommerceConversionRateMainList = [];

          let sessionCompareLst = [];
          let usersCompareLst = [];
          let newUsersCompareLst = [];
          let revenueCompareLst = [];
          let bounceRateCompareLst = [];
          let ecommerceConversionRateCompareLst = [];

          response.data.tableContent.sort((a, b) =>
            a.path.toLowerCase() > b.path.toLowerCase() ? 1 : -1
          );
          response.data.tableContent.map((item) => {
            sessionMainList.push(item.session);
            usersMainList.push(item.users);
            newUsersMainList.push(item.newUsers);
            revenueMainList.push(item.revenue);
            bounceRateMainList.push(item.bounceRate);
            ecommerceConversionRateMainList.push(item.ecommerceConversionRate);

            let obj = {
              key: (j += 1),
              landingpage: item?.path,
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
              revenue: item?.revenue,
            };
            list.push(obj);
          });

          let objData = [];
          response.data.compareTableContent.sort((a, b) =>
            a.path.toLowerCase() > b.path.toLowerCase() ? 1 : -1
          );
          response.data.compareTableContent.map((item, index) => {
            sessionCompareLst.push(item.session);
            usersCompareLst.push(item.users);
            newUsersCompareLst.push(item.newUsers);
            revenueCompareLst.push(item.revenue);
            bounceRateCompareLst.push(item.bounceRate);
            ecommerceConversionRateCompareLst.push(
              item.ecommerceConversionRate
            );

            const filteredTableContent = Object.values(
              response?.data?.tableContent
            ).filter((e) => e.path.toLowerCase() == item.path.toLowerCase());

            let obj = {
              key: (j += 1),
              landingpage: item.path,
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            listCompareTableContent.push(obj);

            let expObj = {
              key: (j += 1),
              landingpage: item.path.toString().replace(",", " "),
              sessions: "",
              users: "",
              newusers: "",
              bouncerate: "",
              ecommerecerate: "",
              revenue: "",
            };
            compareorexportList.push(expObj);

            let obj1 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              revenue: filteredTableContent[0]?.revenue,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
            };
            listCompareTableContent.push(obj1);

            let expObj1 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSDATERANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(rowdata.GOOGLEANALYTICSDATERANGE.TO),
              sessions: filteredTableContent[0]?.session,
              users: filteredTableContent[0]?.users,
              newusers: filteredTableContent[0]?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(
                  parseFloat(
                    filteredTableContent[0]?.bounceRate == undefined
                      ? 0
                      : filteredTableContent[0]?.bounceRate
                  )
                ) + "%",
              revenue: filteredTableContent[0]?.revenue,
              ecommerecerate: filteredTableContent[0]?.ecommerceConversionRate,
            };
            compareorexportList.push(expObj1);

            let obj2 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              revenue: item?.revenue,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              ecommerecerate: item?.ecommerceConversionRate,
            };
            listCompareTableContent.push(obj2);
            objData[index] = [obj, obj1, obj2];

            let expObj2 = {
              key: (j += 1),
              landingpage:
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.FROM
                ) +
                " - " +
                mmDDyyyyFormateForForm22(
                  rowdata.GOOGLEANALYTICSCOMPARETORANGE.TO
                ),
              sessions: item?.session,
              users: item?.users,
              newusers: item?.newUsers,
              bouncerate:
                fixTwoPlaceDecimal(parseFloat(item?.bounceRate)) + "%",
              revenue: item?.revenue,
              ecommerecerate: item?.ecommerceConversionRate,
            };
            compareorexportList.push(expObj2);
          });

          let sessionAvg = sumOfArrayItems(sessionMainList);
          let sessionCompareAvg = sumOfArrayItems(sessionCompareLst);
          let usersAvg = sumOfArrayItems(usersMainList);
          let usersCompareAvg = sumOfArrayItems(usersCompareLst);
          let newUsersAvg = sumOfArrayItems(newUsersMainList);
          let newUsersCompareAvg = sumOfArrayItems(newUsersCompareLst);
          let revenueAvg = sumOfArrayItems(revenueMainList);
          let revenueCompareAvg = sumOfArrayItems(revenueCompareLst);
          let bounceRateAvg =
            sumOfArrayItems(bounceRateMainList) / bounceRateMainList.length;
          let bounceRateCompareAvg =
            sumOfArrayItems(bounceRateCompareLst) / bounceRateCompareLst.length;
          let ecommerceConversionRateAvg = sumOfArrayItems(
            ecommerceConversionRateMainList
          );
          let ecommerceConversionRateCompareAvg = sumOfArrayItems(
            ecommerceConversionRateCompareLst
          );

          let pSessionAvg =
            sessionAvg < sessionCompareAvg
              ? (100 * (sessionCompareAvg - sessionAvg)) / sessionCompareAvg
              : (100 * (sessionAvg - sessionCompareAvg)) / sessionAvg;

          let pUsersAvg =
            usersAvg < usersCompareAvg
              ? (100 * (usersCompareAvg - usersAvg)) / usersCompareAvg
              : (100 * (usersAvg - usersCompareAvg)) / usersAvg;
          let pNewUsersAvg =
            newUsersAvg < newUsersCompareAvg
              ? (100 * (newUsersCompareAvg - newUsersAvg)) / newUsersCompareAvg
              : (100 * (newUsersAvg - newUsersCompareAvg)) / newUsersAvg;
          let pRevenueAvg =
            revenueAvg < revenueCompareAvg
              ? (100 * (revenueCompareAvg - revenueAvg)) / revenueCompareAvg
              : (100 * (revenueAvg - revenueCompareAvg)) / revenueAvg;
          let pBounceRateAvg =
            bounceRateAvg < bounceRateCompareAvg
              ? (100 * (bounceRateCompareAvg - bounceRateAvg)) /
              bounceRateCompareAvg
              : (100 * (bounceRateAvg - bounceRateCompareAvg)) / bounceRateAvg;
          let pEcommerceConversionRateAvg =
            ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg
              ? (100 *
                (ecommerceConversionRateCompareAvg -
                  ecommerceConversionRateAvg)) /
              ecommerceConversionRateCompareAvg
              : (100 *
                (ecommerceConversionRateAvg -
                  ecommerceConversionRateCompareAvg)) /
              ecommerceConversionRateAvg;

          let listData = [
            {
              avgData: pUsersAvg.toFixed(2),
              user: "Users",
              data: fixTwoPlaceDecimal(usersAvg),
              compareData: fixTwoPlaceDecimal(usersCompareAvg),
              isHighData: usersAvg < usersCompareAvg,
            },
            {
              avgData: pNewUsersAvg.toFixed(2),
              user: "New Users",
              data: fixTwoPlaceDecimal(newUsersAvg),
              compareData: fixTwoPlaceDecimal(newUsersCompareAvg),
              isHighData: newUsersAvg < newUsersCompareAvg,
            },
            {
              avgData: pSessionAvg.toFixed(2),
              user: "Sessions",
              data: fixTwoPlaceDecimal(sessionAvg),
              compareData: fixTwoPlaceDecimal(sessionCompareAvg),
              isHighData: sessionAvg < sessionCompareAvg,
            },
            {
              avgData: pBounceRateAvg.toFixed(2),
              user: "Bounce Rate",
              data: bounceRateAvg.toFixed(2) + "%",
              compareData: fixTwoPlaceDecimal(bounceRateCompareAvg) + "%",
              isHighData: bounceRateAvg < bounceRateCompareAvg,
            },
          ];
          var columns1 = tableColGAOSCompare();
          let newList = [];
          let index1, index2;
          columns1.map((item) => {
            newList.push(item);
          });

          if (
            ecommerceConversionRateAvg != 0 ||
            ecommerceConversionRateCompareAvg != 0
          ) {
            var obj = {
              avgData: pEcommerceConversionRateAvg.toFixed(2),
              user: "Ecommerce Conversion Rate",
              data: fixTwoPlaceDecimal(ecommerceConversionRateAvg),
              compareData: fixTwoPlaceDecimal(
                ecommerceConversionRateCompareAvg
              ),
              isHighData:
                ecommerceConversionRateAvg < ecommerceConversionRateCompareAvg,
            };
            listData.push(obj);
          } else {
            columns1.map((item, index) => {
              if (item.dataIndex == "ecommerecerate") {
                index1 = index;
              }
            });
            compareorexportList.forEach((object) => {
            });
          }
          if (revenueAvg != 0 || revenueCompareAvg != 0) {
            var obj = {
              avgData: pRevenueAvg.toFixed(2),
              user: "Revenue",
              data: fixTwoPlaceDecimal(revenueAvg),
              compareData: fixTwoPlaceDecimal(revenueCompareAvg),
              isHighData: revenueAvg < revenueCompareAvg,
            };
            listData.push(obj);
          } else {
            newList.map((item, index) => {
              if (item.dataIndex == "revenue") {
                index2 = index;
              }
            });
            compareorexportList.forEach((object) => {
            });
          }
          setORCardListData(listData);
          setORCol(newList);

          let newORlistCompareTableContent = [];
          let indexForORCompareTable;
          objData.map((item, index) => {
            indexForORCompareTable = index;
            if (index == indexForORCompareTable) {
              newORlistCompareTableContent.push(item);
            } else if (index == indexForORCompareTable + 1) {
              newORlistCompareTableContent.push(item);
            } else if (index == indexForORCompareTable + 2) {
              newORlistCompareTableContent.push(item);
            }
          });

          let newORObjData = [];
          let objORListData = [];
          let ORChildrenData = [];
          for (let i = 0; i < newORlistCompareTableContent.length; i++) {
            let newListData = newORlistCompareTableContent[i];
            let listKeys = Object.keys(newListData);
            let count = 0;
            ORChildrenData = [];
            for (let j = 0; j < listKeys.length; j++) {
              if (parseInt(listKeys[j]) != 0) {
                ORChildrenData[count] = {
                  key: newListData[listKeys[j]]["key"],
                  landingpage: newListData[listKeys[j]]["landingpage"],
                  sessions: newListData[listKeys[j]]["sessions"],
                  users: newListData[listKeys[j]]["users"],
                  newusers: newListData[listKeys[j]]["newusers"],
                  bouncerate: newListData[listKeys[j]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[j]]["ecommerecerate"],
                  revenue: newListData[listKeys[j]]["revenue"],
                };

                objORListData = {
                  key: newListData[listKeys[0]]["key"],
                  landingpage: newListData[listKeys[0]]["landingpage"],
                  sessions: newListData[listKeys[0]]["sessions"],
                  users: newListData[listKeys[0]]["users"],
                  newusers: newListData[listKeys[0]]["newusers"],
                  bouncerate: newListData[listKeys[0]]["bouncerate"],
                  ecommerecerate: newListData[listKeys[0]]["ecommerecerate"],
                  revenue: newListData[listKeys[0]]["revenue"],
                  children: ORChildrenData,
                };

                count++;
              }
            }
            newORObjData[i] = objORListData;
          }

          setORTableCompare(newORObjData);
          setOrexportListCompare(compareorexportList);
        } else {
          setORTable([]);
          setORTableCompare([]);
        }
      }
    );
  }
  const [GoogleAnalytics, setGoogleAnalytics] = useState(false);
  const [isDateRage, setIsDateRage] = useState(true);
  const [performanceMetricsCardList1, setCardListData] = useState([]);
  const [orperformanceMetricsCardList1, setORCardListData] = useState([]);

  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -7),
      endDate: addDays(new Date(), -1),
      key: "selection2",
    },
  });

  const { Column } = Table;

  const image = React.useRef(null);
  const pdfExportComponent = React.useRef(null);

  return (
    <>
      {noConfig ? (
        <>
          <div class="message text-center">
            <h4>
              The Google Analytics configuration is not set. <br />
              Please contact your administrator to set configuration.
            </h4>
          </div>
        </>
      ) : (
        <div className="row">
          <Tabs>
            <div className="portlet">
              <div className="portlet-header portlet-header-bordered my-3">
                <div className="col-md-9 col-sm-12">
                  <TabList className="nav nav-lines portlet-nav">
                    <Tab
                      className={
                        GoogleAnalytics
                          ? "nav-item nav-link"
                          : "nav-item nav-link  active"
                      }
                      onClick={() => {
                        setGoogleAnalytics(false);
                      }}
                    >
                      All Channels
                    </Tab>
                    <Tab
                      className={
                        GoogleAnalytics
                          ? "nav-item nav-link  active"
                          : "nav-item nav-link"
                      }
                      onClick={() => {
                        setGoogleAnalytics(true);
                      }}
                    >
                      Organic Search
                    </Tab>
                  </TabList>
                </div>
                <div
                  ref={ref}
                  class="col-md-3 col-sm-12 calendar-main d-flex justify-content-end align-items-center ga-calender"
                >
                  <div className="add-new-btnw">
                    <button
                      className="btn btn-primary"
                      style={{ width: "250px" }}
                      onClick={() => {
                        setOpen(!open);
                      }}
                    >
                      {!isCompare
                        ? calanderDate(
                          state.selection1?.startDate,
                          state.selection1?.endDate
                        )
                        : calanderDateCompare(
                          state.selection1?.startDate,
                          state.selection1?.endDate,
                          state.selection2?.startDate,
                          state.selection2?.endDate
                        )}
                    </button>
                  </div>
                  {open && (
                    <div id="example-collapse-text-calendar">
                      <Card body className="daterange-picker-card mt-2">
                        <div className="d-flex calendar-flex">
                          <div className="calendar-col">
                            <DateRangePicker
                              onChange={(item) => {
                                let isCompareVarNew =
                                  localStorage.getItem("isCompareVar");

                                if (isCompareVarNew == "false") {
                                  let selection23 = {};
                                  if (item?.selection1) {
                                    selection23 = {
                                      selection2: {
                                        startDate: item?.selection1?.startDate,
                                        endDate: item?.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    };
                                  } else if (item?.selection2) {
                                    selection23 = {
                                      selection1: {
                                        startDate: item?.selection2?.startDate,
                                        endDate: item?.selection2?.endDate,
                                        key: "selection1",
                                      },
                                    };
                                  }
                                  setState({
                                    ...state,
                                    ...item,
                                    ...selection23,
                                  });
                                }

                                if (isCompareVarNew == "true") {
                                  setState({ ...state, ...item });
                                }
                              }}
                              showSelectionPreview={true}
                              moveRangeOnFirstSelection={false}
                              months={1}
                              ranges={[state?.selection1, state?.selection2]}
                              direction="horizontal"
                              ariaLabels={{
                                dateInput: {
                                  selection1: {
                                    startDate: "start date input of selction 1",
                                    endDate: "end date input of selction 1",
                                  },
                                  selection2: {
                                    startDate: "start date input of selction 2",
                                    endDate: "end date input of selction 2",
                                  },
                                },

                                monthPicker: "month picker",
                                yearPicker: "year picker",
                                prevButton: "previous month button",
                                nextButton: "next month button",
                              }}
                              maxDate={
                                new Date(
                                  new Date().getFullYear(),
                                  new Date().getMonth(),
                                  new Date().getDate() - 1
                                )
                              }
                            />
                          </div>
                          <div className="mt-5 mt-lg-0 text-center">
                            <label className="mt-3 d-flex ms-3">
                              <input
                                className="me-3"
                                checked={isCompare}
                                onChange={(e) => {
                                  localStorage.setItem(
                                    "isCompareVar",
                                    e.target.checked
                                  );
                                  setIsCompare(e.target.checked);
                                  if (!e.target.checked) {
                                    document
                                      .getElementsByClassName(
                                        "rdrDateDisplayWrapper"
                                      )[0]
                                      .children[1].classList.remove(
                                        "display-flex"
                                      );
                                    document
                                      .getElementsByClassName(
                                        "rdrDateDisplayWrapper"
                                      )[0]
                                      .children[1].classList.add(
                                        "display-none"
                                      );
                                    setState({
                                      selection1: {
                                        startDate: state.selection1?.startDate,
                                        endDate: state.selection1?.endDate,
                                        key: "selection1",
                                      },
                                      selection2: {
                                        startDate: state.selection1?.startDate,
                                        endDate: state.selection1?.endDate,
                                        key: "selection2",
                                      },
                                    });
                                  } else {
                                    document
                                      .getElementsByClassName(
                                        "rdrDateDisplayWrapper"
                                      )[0]
                                      .children[1].classList.remove(
                                        "display-none"
                                      );
                                    document
                                      .getElementsByClassName(
                                        "rdrDateDisplayWrapper"
                                      )[0]
                                      .children[1].classList.add(
                                        "display-flex"
                                      );
                                    setState({
                                      selection1: {
                                        startDate: state.selection1?.startDate,
                                        endDate: state.selection1?.endDate,
                                        key: "selection1",
                                      },
                                      selection2: {
                                        startDate: addDays(new Date(), -7),
                                        endDate: addDays(new Date(), -1),
                                        key: "selection2",
                                      },
                                    });
                                  }
                                }}
                                type="checkbox"
                              />
                              Compare
                            </label>
                            <button
                              onClick={() => {
                                setheadvalues();
                                setOpen(false);
                                if (
                                  convertDate(state.selection1.startDate) ==
                                  convertDate(state.selection2.startDate) &&
                                  convertDate(state.selection1.endDate) ==
                                  convertDate(state.selection2.endDate)
                                ) {
                                  setIsDateRage(true);
                                  var columns1 = tableColGA();
                                  setGACol(columns1);

                                  let getdata = {
                                    DateRange: 0,
                                    View: 0,
                                    projectId:
                                      localStorage.getItem("projectGId"),
                                    DEVICETYPE: selectedDeviceType,
                                    GOOGLEANALYTICSDATERANGE: {
                                      FROM: convertDate(
                                        state.selection1.startDate
                                      ),
                                      TO: convertDate(state.selection1.endDate),
                                    },
                                  };
                                  creategoogleAnalyticsSearch(getdata);
                                  createorganicResearchSearch(getdata);

                                  let chartData = {
                                    DATERANGE: 0,
                                    VIEW: ChartView,
                                    FILTER: [GraphGASelected],
                                    DEVICETYPE: selectedDeviceType,
                                    PROJECTID:
                                      localStorage.getItem("projectGId"),
                                    GOOGLEANALYTICSDATERANGE: {
                                      FROM: convertDate(
                                        state.selection1.startDate
                                      ),
                                      TO: convertDate(state.selection1.endDate),
                                    },
                                  };
                                  getChartData(chartData, ChartView);

                                  let chartData1 = {
                                    DATERANGE: 0,
                                    VIEW: ChartView1,
                                    FILTER: [GraphGASelected],
                                    DEVICETYPE: selectedDeviceType,
                                    PROJECTID:
                                      localStorage.getItem("projectGId"),
                                    GOOGLEANALYTICSDATERANGE: {
                                      FROM: convertDate(
                                        state.selection1.startDate
                                      ),
                                      TO: convertDate(state.selection1.endDate),
                                    },
                                  };
                                  getORChartData(chartData1, ORChartView);
                                } else {
                                  setIsDateRage(false);
                                  var columns1 = tableColGACompare();
                                  setGACol(columns1);
                                  let getdata = {
                                    DATERANGE: 0,
                                    COMAPARETO: 0,
                                    DEVICETYPE: selectedDeviceType,
                                    PROJECTID:
                                      localStorage.getItem("projectGId"),
                                    GOOGLEANALYTICSDATERANGE: {
                                      FROM: convertDate(
                                        state.selection1.startDate
                                      ),
                                      TO: convertDate(state.selection1.endDate),
                                    },
                                    GOOGLEANALYTICSCOMPARETORANGE: {
                                      FROM: convertDate(
                                        state.selection2.startDate
                                      ),
                                      TO: convertDate(state.selection2.endDate),
                                    },
                                  };
                                  creategoogleAnalyticsSearchCompare(getdata);
                                  createorganicResearchSearchCompare(getdata);
                                  let filterSelected = GraphGASelected;
                                  let chartData = {
                                    ComapareTo: 0, //New Added
                                    DATERANGE: 0,
                                    VIEW: ChartView,
                                    FILTER: [GraphGASelected],
                                    DEVICETYPE: selectedDeviceType,
                                    PROJECTID:
                                      localStorage.getItem("projectGId"),
                                    GOOGLEANALYTICSDATERANGE: {
                                      FROM: convertDate(
                                        state.selection1.startDate
                                      ),
                                      TO: convertDate(state.selection1.endDate),
                                    },
                                    GoogleAnalyticsCompareToRange: {
                                      FROM: convertDate(
                                        state.selection2.startDate
                                      ),
                                      TO: convertDate(state.selection2.endDate),
                                    },
                                  };
                                  getChartDataCompare(chartData, ChartView);

                                  let chartData1 = {
                                    DATERANGE: 0,
                                    VIEW: ChartView1,
                                    ComapareTo: 0,
                                    FILTER: [GraphGASelected],
                                    DEVICETYPE: selectedDeviceType,
                                    PROJECTID:
                                      localStorage.getItem("projectGId"),
                                    GOOGLEANALYTICSDATERANGE: {
                                      FROM: convertDate(
                                        state.selection1.startDate
                                      ),
                                      TO: convertDate(state.selection1.endDate),
                                    },
                                    GoogleAnalyticsCompareToRange: {
                                      FROM: convertDate(
                                        state.selection2.startDate
                                      ),
                                      TO: convertDate(state.selection2.endDate),
                                    },
                                  };
                                  getORChartDataCompare(
                                    chartData1,
                                    ORChartView
                                  );
                                }

                                setGraphGASelected(GraphGASelected);
                                setOpen(false);
                              }}
                              className="btn btn-primary ms-3 mt-3"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </Card>
                    </div>
                  )}
                  <div className="d-flex 3-icons">
                    <a
                      className="bar-link-icon ms-3"
                      style={{ color: "black" }}
                    >
                      <div class="dropdown">
                        <a
                          type="button"
                          ref={applyBtn2}
                          id="dropdownMenuButton"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i class="fa fa-filter" aria-hidden="true"></i>
                        </a>
                        <form class="dropdown-menu p-4">
                          <div className="filter-popup">
                            <div className="portlet px-2 py-2">
                              <div className="col-md-12 my-2">
                                <label style={{ fontSize: 14 + "px" }}>
                                  Metrics
                                </label>
                                <select
                                  className="w-200"
                                  value={GraphGASelected}
                                  onChange={(e) => {
                                    setGraphGASelected(e.target.value);
                                  }}
                                >
                                  {GraphGATabOptions.map((item) => {
                                    return (
                                      <option value={item.value} key={item.key}>
                                        {item.label}
                                      </option>
                                    );
                                  })}
                                </select>
                              </div>

                              <div className="col-md-12 my-3">
                                <label style={{ fontSize: 14 + "px" }}>
                                  Device Type
                                </label>
                                <select
                                  style={{ padding: 8 + "px" }}
                                  className="w-100"
                                  value={selectedDeviceType}
                                  onChange={(e) => {
                                    setDeviceType(e.target.value);
                                  }}
                                >
                                  <option value="2">All devices</option>
                                  <option value="1">Desktop</option>
                                  <option value="0">Mobile</option>
                                  <option value="3">Mobile + Tablet</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-12 text-left mt-3">
                              <button
                                type="button"
                                className="btn btn-primary me-3"
                                style={{
                                  width: 70 + "px",
                                  height: 30 + "px",
                                }}
                                onClick={() => {
                                  chartHeading(GraphGASelected);
                                  if (isDateRage) {
                                    let getdata = {
                                      DateRange: 0,
                                      View: 0,
                                      DEVICETYPE: selectedDeviceType,
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                    };
                                    creategoogleAnalyticsSearch(getdata);
                                  } else {
                                    let getdata = {
                                      DATERANGE: 0,
                                      COMAPARETO: 0,
                                      DEVICETYPE: selectedDeviceType,
                                      PROJECTID:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                      GOOGLEANALYTICSCOMPARETORANGE: {
                                        FROM: convertDate(
                                          state.selection2.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection2.endDate
                                        ),
                                      },
                                    };
                                    creategoogleAnalyticsSearchCompare(getdata);
                                  }
                                  if (isDateRage) {
                                    let getdata = {
                                      DateRange: 0,
                                      View: 0,
                                      DEVICETYPE: selectedDeviceType,
                                      projectId:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                    };
                                    createorganicResearchSearch(getdata);
                                    let chartData = {
                                      DATERANGE: 0,
                                      VIEW: ChartView,
                                      FILTER: [GraphGASelected],
                                      DEVICETYPE: selectedDeviceType,
                                      PROJECTID:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                    };
                                    getChartData(chartData, ChartView);

                                    let chartData1 = {
                                      DATERANGE: 0,
                                      VIEW: ChartView1,
                                      FILTER: [GraphGASelected],
                                      DEVICETYPE: selectedDeviceType,
                                      PROJECTID:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                    };
                                    getORChartData(chartData1, ChartView1);
                                  } else {
                                    let getdata = {
                                      DATERANGE: 0,
                                      COMAPARETO: 0,
                                      DEVICETYPE: selectedDeviceType,
                                      PROJECTID:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                      GOOGLEANALYTICSCOMPARETORANGE: {
                                        FROM: convertDate(
                                          state.selection2.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection2.endDate
                                        ),
                                      },
                                    };
                                    createorganicResearchSearchCompare(getdata);
                                    let chartData = {
                                      ComapareTo: 0, //New Added
                                      DATERANGE: 0,
                                      VIEW: ChartView,
                                      FILTER: [GraphGASelected],
                                      DEVICETYPE: selectedDeviceType,
                                      PROJECTID:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                      GoogleAnalyticsCompareToRange: {
                                        FROM: convertDate(
                                          state.selection2.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection2.endDate
                                        ),
                                      },
                                    };
                                    getChartDataCompare(chartData, ChartView);

                                    let chartData1 = {
                                      DATERANGE: 0,
                                      VIEW: ChartView1,
                                      FILTER: [GraphGASelected],
                                      ComapareTo: 0,
                                      DEVICETYPE: selectedDeviceType,
                                      PROJECTID:
                                        localStorage.getItem("projectGId"),
                                      GOOGLEANALYTICSDATERANGE: {
                                        FROM: convertDate(
                                          state.selection1.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection1.endDate
                                        ),
                                      },
                                      GoogleAnalyticsCompareToRange: {
                                        FROM: convertDate(
                                          state.selection2.startDate
                                        ),
                                        TO: convertDate(
                                          state.selection2.endDate
                                        ),
                                      },
                                    };
                                    getORChartDataCompare(
                                      chartData1,
                                      ChartView1
                                    );
                                  }

                                  applyBtn2.current.click();
                                  let dateRange = [];
                                  dateRange.push({
                                    From: mmDDyyyyFormateDateSet(head1),
                                    To: mmDDyyyyFormateDateSet(head2),
                                  });
                                  let filterSelected = GraphGASelected;

                                  setRefreshTicketData(true);
                                  setTimeout(() => {
                                    setRefreshTicketData(false);
                                  }, 1000);
                                }}
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </a>

                    <div className="ms-3  ">
                      <a
                        className="share bar-link-icon"
                        style={{ color: "black" }}
                      >
                        <div class="dropdown">
                          <a
                            type="button"
                            ref={applyBtn}
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa fa-share-alt" aria-hidden="true"></i>
                          </a>
                          <form class="dropdown-menu p-4 filter-dropdown">
                            <div className="filter-popup">
                              <div className="portlet px-2 py-2">
                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">From</label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Sender Email Id"
                                      value={Sender}
                                      className="w-100 form-control my-2"
                                      name="Sender"
                                    />
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">To</label>
                                    <label class="field-required"> * </label>
                                  </div>
                                  <div className="col-md-10">
                                    {" "}
                                    <input
                                      type="text"
                                      required
                                      placeholder="Recipient Email Id"
                                      className="w-100 form-control form-control my-2"
                                      value={Receiver}
                                      name="Receiver"
                                      onChange={(text) =>
                                        setReceiver(text.target.value)
                                      }
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageReceiver}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    <label class="my-2 pt-2">Subject</label>
                                    <label class="field-required"> * </label>
                                  </div>
                                  <div className="col-md-10">
                                    <input
                                      type="text"
                                      required
                                      placeholder="Subject"
                                      value={subject}
                                      onChange={(text) => {
                                        setSubject(text.target.value);
                                      }}
                                      className="w-100 form-control my-2"
                                      name="Subject"
                                    />
                                    <div style={{ color: "red" }}>
                                      {MessageSubject}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2 text-start">
                                    {" "}
                                    <label class="my-2 pt-2">Message</label>
                                    <label class="field-required"> * </label>
                                  </div>
                                  <div className="col-md-10">
                                    <textarea
                                      name=""
                                      id=""
                                      cols="80"
                                      rows="7"
                                      className="w-100 form-control my-2"
                                      value={EmailMessage}
                                      onChange={(text) => {
                                        setEmailMessage(text.target.value);
                                      }}
                                    ></textarea>
                                    <div class="ms-3" style={{ color: "red" }}>
                                      {Message}
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-2"></div>
                                  <div className="col-md-10 my-2">
                                    <button
                                      type="button"
                                      class="btn btn-primary"
                                      style={{
                                        width: 70 + "px",
                                        height: 30 + "px",
                                      }}
                                      onClick={() => {
                                        if (isValidEmailData()) {
                                          sendEmail();
                                        }
                                      }}
                                    >
                                      {" "}
                                      Send
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </a>
                    </div>

                    <div className="row">
                      <div className="col-md-12 download-filter">
                        <span
                          class="dropdown"
                          style={{
                            marginRight: 0 + "px",
                            textAlign: "right",
                            display: "block",
                          }}
                        >
                          <button
                            class="outline-btn dropdown-toggle btn btn-primary"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i class="fa fa-download " aria-hidden="true"></i>
                          </button>
                          <div
                            class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <div
                              onClick={async () => {
                                setLoading(true);
                                const promise = await downloadPDFfile(
                                  image,
                                  moduleGoogleAnalytics
                                );
                                try {
                                  if (promise) {
                                    setLoading(false);
                                  } else {
                                    setLoading(false);
                                  }
                                } catch (e) {
                                  setLoading(false);
                                }
                              }}
                            >
                              <a class="dropdown-item">PDF</a>
                            </div>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <TabPanel>
              <PDFExport
                paperSize="A1"
                margin="2cm"
                fileName={moduleGoogleAnalytics}
                ref={pdfExportComponent}
                forcePageBreak=".page-break"
              >
                <div ref={image}>
                  <div className="portlet mb-0 py-3">
                    <div className="row px-3 py-3 align-items-center">
                      <div className="col-md-3 text-start align-items-center">
                        <label style={{ fontSize: 14 + "px", fontWeight: 500 }}>
                          {" "}
                          <h3 className="portlet-title "> {headingofChart} </h3>
                        </label>
                      </div>
                      <div className="col-md-9">
                        <div className="header-wrap d-flex justify-content-end ">
                          <div class="btn-group btn-group-toggle dwm-chart">
                            <label class={getClassName(0)} role="presentatiosn">
                              <input
                                class="btn-check"
                                checked="checked"
                                id="pills-home-tab"
                                data-toggle="pill"
                                data-target="#pills-home"
                                type="radio"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => {
                                  setChartView(0);
                                  handleView(0);
                                }}
                              />
                              Day
                            </label>

                            <label class={getClassName(1)} role="presentation">
                              <input
                                class="btn-check"
                                checked="checked"
                                id="pills-profile-tab"
                                data-toggle="pill"
                                data-target="#pills-profile"
                                type="radio"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                                onClick={() => {
                                  setChartView(1);
                                  handleView(1);
                                }}
                              />
                              Week
                            </label>

                            <label class={getClassName(2)} role="presentation">
                              <input
                                class="btn-check"
                                checked="checked"
                                id="pills-contact-tab"
                                data-toggle="pill"
                                data-target="#pills-contact"
                                type="radio"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                                onClick={() => {
                                  setChartView(2);
                                  handleView(2);
                                }}
                              />
                              Month
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-12 d-flex justify-content-end ">
                      <a className="bar-link-icon" style={{ color: "black" }}>
                        <div class="dropdown">
                          <form class="dropdown-menu p-4 filter-dropdown">
                            <div className="filter-popup">
                              <div className="portlet px-2">
                                <div className="col-md-12 my-2">
                                  <ReactSelect
                                    className="multiselect-graph-ga w-100"
                                    options={GraphORTabOptions}
                                    isMulti={false}
                                    closeMenuOnSelect={false}
                                    hideSelectedOptions={false}
                                    controlShouldRenderValue={false}
                                    components={{
                                      Option,
                                    }}
                                    styles={themeStyle()}
                                    allowSelectAll={true}
                                    value={GraphGASelected}
                                  />
                                </div>
                                <div className="col-md-12 my-3"></div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </a>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-3"></div>
                    </div>
                    {daterange2withGraph ? (
                      <div className="dates">
                        <div className="date-inner">
                          <div>
                            {startDate1} - {EndDate1}
                          </div>
                          {GraphGASelected &&
                            GraphGASelected.map((i, index) => {
                              return (
                                <>
                                  <div
                                    className="date-legend-circle"
                                    style={{
                                      backgroundColor: `${color[index]}`,
                                    }}
                                  ></div>
                                  <div className="date-legend-title">
                                    {i.value}
                                  </div>
                                </>
                              );
                            })}
                          {!GraphGASelected ? (
                            <>
                              <div
                                className="date-legend-circle"
                                style={{ backgroundColor: `${color[0]}` }}
                              ></div>
                              <div className="date-legend-title">Users</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="date-inner">
                          <div>
                            {startDate2} - {EndDate2}
                          </div>
                          {GraphGASelected &&
                            GraphGASelected.map((i, index) => {
                              return (
                                <>
                                  <div
                                    className="date-legend-circle"
                                    style={{
                                      backgroundColor: `${color[index + GraphGASelected.length]
                                        }`,
                                    }}
                                  ></div>
                                  <div className="date-legend-title">
                                    {i.value}
                                  </div>
                                </>
                              );
                            })}
                          {!GraphGASelected ? (
                            <>
                              <div
                                className="date-legend-circle"
                                style={{ backgroundColor: `${color[1]}` }}
                              ></div>
                              <div className="date-legend-title">Users</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div id="web-page-pdf">
                    <div className="portlet py-3">
                      <div className="col-md-12 session-chart">
                        {series?.length ? (
                          <Chart
                            options={options}
                            series={series}
                            type="line"
                            height="300"
                            id="chartId"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="portlet mb-0 bg-white">
                      <div className="portlet-header portlet-header-bordered">
                        <h3 className="portlet-title">Performance Metrics</h3>
                      </div>{" "}
                    </div>
                    <div className="portlet-body p-3 bg-white">
                      <div class="container align-items-center my-3">
                        <div className="row   html2pdf__page-break">
                          {performanceMetricsCardList1.map((i) => {
                            return (
                              <div className="col-md-3">
                                <div className="gabox-shadow">
                                  <h2
                                    className={
                                      i.isHighData == 2
                                        ? "avg-val"
                                        : i.isHighData == 1
                                          ? "avg-val-negative"
                                          : "avg-val-positive"
                                    }
                                  >
                                    {i.avgData}
                                    {isDateRage ? "" : "%"}
                                  </h2>
                                  <span>{i.user}</span>
                                  <p>
                                    {isDateRage
                                      ? ""
                                      : i.data + " vs " + i.compareData}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>

                      <div className="portlet mt-4">
                        <div className="portlet-header portlet-header-bordered">
                          <h3 className="portlet-title">Traffic </h3>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <span
                                class="dropdown"
                                style={{
                                  marginRight: 0 + "px",
                                  textAlign: "right",
                                  display: "block",
                                }}
                              >
                                <div className="me-3">
                                  <button
                                    class="outline-btn dropdown-toggle btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      exportToEXCEL(
                                        selectedExportList?.length > 0
                                          ? selectedExportList
                                          : isDateRage
                                            ? exportList
                                            : exportListCompare,
                                        "Traffic"
                                      );
                                    }}
                                  >
                                    Export
                                  </button>
                                  {/* <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        exportToEXCEL(
                                          selectedExportList?.length > 0
                                            ? selectedExportList
                                            : isDateRage
                                              ? exportList
                                              : exportListCompare,
                                          "Traffic"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">Excel</a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        exportInToCSV(
                                          selectedExportList?.length > 0
                                            ? selectedExportList
                                            : isDateRage
                                              ? exportList
                                              : exportListCompare,
                                          "Traffic"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">CSV</a>
                                    </div>
                                  </div> */}
                                </div>
                              </span>
                            </div>
                          </div>

                          {isDateRage ? (
                            <div className="ga-table">
                              <Table
                                className="table table-bordered  table-hover mt-3  google-analytics-table "
                                id="datatable-1"
                                columns={GACol}
                                dataSource={GATable}
                                rowSelection={{
                                  type: "checkbox",
                                  ...rowSelection,
                                }}
                                pagination={{
                                  position: ["bottomRight"],
                                  showSizeChanger: true,
                                }}
                              />
                            </div>
                          ) : (
                            <div className="ga-table ">
                              <Table
                                className="table table-bordered  table-hover mt-3 google-analytics-table"
                                columns={GACol}
                                dataSource={GATableCompare}
                                rowSelection={{
                                  type: "checkbox",
                                  ...comparerowSelection,
                                }}
                                key={GATableCompare.key}
                                expandable={{
                                  expandedRowKeys: GATableCompare.map(
                                    (o) => o.key
                                  ),
                                }}
                                pagination={tableParams.pagination}
                                onChange={handlePaginationChange}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PDFExport>
            </TabPanel>
            {/* Start Organic Search */}
            <TabPanel>
              <PDFExport
                paperSize="A1"
                margin="2cm"
                fileName={moduleGoogleAnalytics}
                ref={pdfExportComponent}
                forcePageBreak=".page-break"
              >
                <div ref={image}>
                  <div className="portlet mb-0 py-3">
                    <div className="row px-3 py-3 align-items-center">
                      <div className="col-md-3 col-sm-12 text-start align-items-center">
                        <label style={{ fontSize: 14 + "px", fontWeight: 500 }}>
                          {" "}
                          <h3 className="portlet-title "> {headingofChart} </h3>
                        </label>
                      </div>
                      <div className="col-md-9 col-sm-12 d-flex justify-content-end align-items-center">
                        <div className="tab-divs d-flex justify-content-end">
                          <div class="btn-group btn-group-toggle dwm-chart">
                            <label
                              class={getClassName1(0)}
                              role="presentatiosn"
                            >
                              <input
                                class="btn-check"
                                checked="checked"
                                id="pills-home-tab"
                                data-toggle="pill"
                                data-target="#pills-home"
                                type="radio"
                                role="tab"
                                aria-controls="pills-home"
                                aria-selected="true"
                                onClick={() => {
                                  setChartView1(0);
                                  handleViewor(0);
                                }}
                              />
                              Day
                            </label>

                            <label class={getClassName1(1)} role="presentation">
                              <input
                                class="btn-check"
                                checked="checked"
                                id="pills-profile-tab"
                                data-toggle="pill"
                                data-target="#pills-profile"
                                type="radio"
                                role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false"
                                onClick={() => {
                                  setChartView1(1);
                                  handleViewor(1);
                                }}
                              />
                              Week
                            </label>

                            <label class={getClassName1(2)} role="presentation">
                              <input
                                class="btn-check"
                                checked="checked"
                                id="pills-contact-tab"
                                data-toggle="pill"
                                data-target="#pills-contact"
                                type="radio"
                                role="tab"
                                aria-controls="pills-contact"
                                aria-selected="false"
                                onClick={() => {
                                  setChartView1(2);
                                  handleViewor(2);
                                }}
                              />
                              Month
                            </label>
                          </div>
                        </div>

                        <a className="bar-link-icon" style={{ color: "black" }}>
                          <div class="dropdown">
                            <form class="dropdown-menu p-4 filter-dropdown">
                              <div className="filter-popup">
                                <div className="portlet px-2">
                                  <div className="col-md-12 my-2">
                                    <ReactSelect
                                      className="multiselect-graph-ga w-100"
                                      options={GraphORTabOptions}
                                      isMulti={false}
                                      closeMenuOnSelect={false}
                                      hideSelectedOptions={false}
                                      controlShouldRenderValue={false}
                                      components={{
                                        Option,
                                      }}
                                      styles={themeStyle()}
                                      allowSelectAll={true}
                                      value={GraphGASelected}
                                    />
                                  </div>
                                  <div className="col-md-12 my-3"></div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </a>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 mt-3"></div>
                    </div>
                    {ordaterange2withGraph ? (
                      <div className="dates">
                        <div className="date-inner">
                          <div>
                            {startDate1} - {EndDate1}
                          </div>
                          {GraphORSelected &&
                            GraphORSelected.map((i, index) => {
                              return (
                                <>
                                  <div
                                    className="date-legend-circle"
                                    style={{
                                      backgroundColor: `${color[index]}`,
                                    }}
                                  ></div>
                                  <div className="date-legend-title">
                                    {i.value}
                                  </div>
                                </>
                              );
                            })}
                          {!GraphORSelected ? (
                            <>
                              <div
                                className="date-legend-circle"
                                style={{ backgroundColor: `${color[0]}` }}
                              ></div>
                              <div className="date-legend-title">Users</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="date-inner">
                          <div>
                            {startDate2} - {EndDate2}
                          </div>
                          {GraphORSelected &&
                            GraphORSelected.map((i, index) => {
                              return (
                                <>
                                  <div
                                    className="date-legend-circle"
                                    style={{
                                      backgroundColor: `${color[index + GraphORSelected.length]
                                        }`,
                                    }}
                                  ></div>
                                  <div className="date-legend-title">
                                    {i.value}
                                  </div>
                                </>
                              );
                            })}
                          {!GraphORSelected ? (
                            <>
                              <div
                                className="date-legend-circle"
                                style={{ backgroundColor: `${color[1]}` }}
                              ></div>
                              <div className="date-legend-title">Users</div>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  <div id="web-page-pdf">
                    <div className="portlet py-3">
                      <div className="col-md-12 session-chart">
                        {orseries?.length ? (
                          <Chart
                            options={oroptions}
                            series={orseries}
                            type="line"
                            height="300"
                            id="chartId"
                          />
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="portlet mb-0 bg-white">
                      <div className="portlet-header portlet-header-bordered">
                        <h3 className="portlet-title">Performance Metrics</h3>
                      </div>{" "}
                    </div>

                    <div className="portlet p-3 bg-white">
                      <div class="container align-items-center my-3">
                        <div className="row">
                          {orperformanceMetricsCardList1.map((i) => {
                            return (
                              <div className="col-md-3">
                                <div className="gabox-shadow">
                                  <h2
                                    className={
                                      i.isHighData == 2
                                        ? "avg-val"
                                        : i.isHighData == 1
                                          ? "avg-val-negative"
                                          : "avg-val-positive"
                                    }
                                  >
                                    {i.avgData}
                                    {isDateRage ? "" : "%"}
                                  </h2>
                                  <span>{i.user}</span>
                                  <p>
                                    {isDateRage
                                      ? ""
                                      : i.data + " vs " + i.compareData}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="portlet mt-3">
                        <div className="portlet-header portlet-header-bordered">
                          <h3 className="portlet-title">Traffic</h3>
                        </div>
                        <div className="portlet-body">
                          <div className="row">
                            <div className="col-md-12">
                              <span
                                class="dropdown"
                                style={{
                                  marginRight: 0 + "px",
                                  textAlign: "right",
                                  display: "block",
                                }}
                              >
                                <div className="me-3">
                                  <button
                                    class="outline-btn dropdown-toggle btn btn-primary"
                                    type="button"
                                    onClick={() => {
                                      exportToEXCEL(
                                        orselectedExportList?.length > 0
                                          ? orselectedExportList
                                          : isDateRage
                                            ? orexportList
                                            : orexportListCompare,
                                        "Organic Search"
                                      );
                                    }}
                                  >
                                    Export
                                  </button>
                                  {/* <div
                                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                                    aria-labelledby="dropdownMenuButton"
                                  >
                                    <div
                                      onClick={() => {
                                        exportToEXCEL(
                                          orselectedExportList?.length > 0
                                            ? orselectedExportList
                                            : isDateRage
                                              ? orexportList
                                              : orexportListCompare,
                                          "Organic Search"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">Excel</a>
                                    </div>
                                    <div
                                      onClick={() => {
                                        exportInToCSV(
                                          orselectedExportList?.length > 0
                                            ? orselectedExportList
                                            : isDateRage
                                              ? orexportList
                                              : orexportListCompare,
                                          "Organic Search"
                                        );
                                      }}
                                    >
                                      <a class="dropdown-item">CSV</a>
                                    </div>
                                  </div> */}
                                </div>
                              </span>
                            </div>
                          </div>

                          <div className="ga-table google-analytics-table">
                            {isDateRage ? (
                              <Table
                                className="table table-bordered  table-hover mt-3 "
                                id="datatable-1"
                                columns={ORCol}
                                dataSource={ORTable}
                                rowSelection={{
                                  type: "checkbox",
                                  ...orrowSelection,
                                }}
                                pagination={{
                                  position: ["bottomRight"],
                                  showSizeChanger: true,
                                }}
                              />
                            ) : (
                              <Table
                                className="table table-bordered  table-hover mt-3"
                                columns={ORCol}
                                dataSource={ORTableCompare}
                                rowSelection={{
                                  type: "checkbox",
                                  ...compareorrowSelection,
                                }}
                                key={ORTableCompare.key}
                                expandable={{
                                  expandedRowKeys: ORTableCompare.map(
                                    (o) => o.key
                                  ),
                                }}
                                pagination={tableParams.pagination}
                                onChange={handlePaginationChange}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PDFExport>
            </TabPanel>
          </Tabs>
        </div>
      )}

      <Loading isLoading={isLoading} />
    </>
  );
}

export default ModuleExpandGoogleAnalytics;
