import React, { useEffect, useState } from "react";
import { default as ReactSelect, components } from "react-select";
import APIManager from "../../services/APIManager";
import {
  getOrganicResearch,
  getOrganicResearchConfig,
  getUrlByOrgId,
} from "../../utility/Constant";
import { deviceType, selectType, themeStyle } from "../../utility/Globals";

const OrganicResearch = ({ update }) => {
  const [urlList, setURlList] = useState([]);
  const [urlByProject, seturlByProject] = useState({});
  const [selectType2, setSelectType2] = useState("");
  const [deviceType2, setDeviceType2] = useState("0");
  const [selectCurrency, setselectCurrency] = useState("0");

  useEffect(() => {
    getOrganic();
    getUrlList(localStorage.getItem("projectGId"));
  }, []);

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  function getOrganic() {
    APIManager.getAPI(
      getOrganicResearchConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        if (response?.data) {
          const list = {
            key: response.data.urls.id,
            id: response.data.urls.id,
            value: response.data.urls.fieldValue,
            label: response.data.urls.fieldValue,
          };
          seturlByProject(list);
          setSelectType2(response?.data.type);
          setDeviceType2(response?.data.device);
          setselectCurrency(response?.data.currency);
        }
      }
    });
  }

  function getUrlList(projectIdd) {
    APIManager.getAPI(getUrlByOrgId + projectIdd).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        const exportList = [];
        let c = 1;
        response.data.map((item) => {
          let obj = {
            count: c,
            key: item.id,
            id: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            url: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => {}}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          };
          c++;
          list.push(obj);
          let exportObj = {
            key: item.id,
            id: item.id,
            url: item.fieldValue,
          };
          exportList.push(exportObj);
        });
        setURlList(list);
      }
    });
  }

  function addUpdateOrganicResearch() {
    APIManager.postAPI(
      getOrganicResearch,
      {
        UrlId: urlByProject.id,
        ProjectId: localStorage.getItem("projectGId"),
        Type: selectType2,
        Device: deviceType2,
        Currency: selectCurrency,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        update("");
      }
    });
  }
  return (
    <div className="custom-column-70">
      <div className="portlet common-form-fields mt-3 slideInUp">
        <div class="portlet-header portlet-header-bordered">
        <svg  onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {" "}
            {localStorage.getItem("projectGName")} - Organic Research
          </h3>
        </div>
        <div className="portlet-body">
          <div class="col-md-12">
            <label className="form-label">Domain/URL</label>
            <div className="justify-content-between mb-3">
              <ReactSelect
                styles={themeStyle()}
                options={urlList}
                isMulti={false}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                components={{ Option }}
                onChange={(selectedDomainUrl) => {
                  seturlByProject(selectedDomainUrl);
                }}
                value={urlByProject}
                allowSelectAll={true}
              />
            </div>
            <div className="none" id={"UrlPS13"}>
              <div class="form-wrappers" className="w-50">
                <label className="form-label"></label>
                <input
                  type="text"
                  name=""
                  placeholder="Value"
                  id={"inputaddUrlPS13"}
                />
                <button
                  style={{ border: "none", background: "none" }}
                  id={"addkeywordGT1"}
                >
                  {" "}
                  <i class="fa fa-times d-flex" aria-hidden="true"></i>{" "}
                </button>

                <button
                  style={{ border: "none", background: "none" }}
                  id={"addkeywordGT1"}
                >
                  {" "}
                  <i class="fa fa-check" aria-hidden="true"></i>{" "}
                </button>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <label className="form-label" htmlFor="">
              Select Type
            </label>
            <br />
            <select
              class="w-100"
              style={{ padding: 8 + "px" }}
              value={selectType2}
              onChange={(e) => {
                setSelectType2(e.target.value);
              }}
            >
              {selectType.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>

          <div class="col-md-12 mt-3">
            <label className="form-label" htmlFor="">
              Device Type
            </label>
            <br />
            <select
              style={{ padding: 8 + "px" }}
              class="w-100"
              value={deviceType2}
              onChange={(e) => {
                setDeviceType2(e.target.value);
              }}
            >
              {deviceType.map((item) => {
                return <option value={item.value}>{item.label}</option>;
              })}
            </select>
          </div>

          <div class="col-md-12 mt-3">
            <label className="form-label" htmlFor="">
              Currency
            </label>
            <br />
            <select
              style={{ padding: 8 + "px" }}
              class="w-100"
              value={selectCurrency}
              onChange={(e) => {
                setselectCurrency(e.target.value);
              }}
              name=""
            >
              <option value="INR">INR</option>
              <option value="USD">USD</option>
            </select>
          </div>
        </div>
        <div className="portlet-footer portlet-footer-bordered">
          <div class="col-lg-12">
            <button
              class="btn btn-primary"
              onClick={() => {
                addUpdateOrganicResearch();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganicResearch;
