import React, { useState, useEffect } from "react";
import toast from "react-hot-toast";
import { useHistory } from "react-router-dom";
import { Input, Table } from "antd";
import "react-step-progress-bar/styles.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "antd/dist/antd.css";
import { Sorter } from "../../common/Sorter";
import {
  createCategory,
  deleteCategory,
  getCategoryByProject,
  updateCategory,
  deleteMultiKeywordCat,
  deleteAllKeywordCat
} from "../../utility/Constant";
import APIManager from "../../services/APIManager";
import {
  exportInToCSV,
  exportToEXCEL,
} from "../../utility/Globals";

const KeywordCategory = ({ update }) => {
  const [KeywordCatTabcol, setKeywordCatTabcol] = useState([]);
  const [tabkeywordlist, settabkeywordList] = useState([]);
  const [Categorykeywordlist, setCategorykeywordList] = useState([]);
  const [userrolesList, setuserrolesList] = useState([]);
  const [count, setCount] = useState(0);
  const [selectedExportList, setSelectedExportList] = useState([]);
  const [exportList, setExportList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryNameCopy, setCategoryNameCopy] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [isEditCategory, setIsEditCategory] = useState(false);
  const [categoryMassage, setCategoryMessage] = useState("");
  const [SelectedkeywordCatCheckList, setSelectedkeywordCatCheckList] = useState([]);
  const [Selectedkeyword, setSelectedkeyword] = useState(0);
  const [Totalkeyword, setTotalkeyword] = useState(0);
  const notifyAdd = () => toast.success("Successfully Added!");
  
  useEffect(() => {
    var data = [
      {
        title: "Keyword Category",
        dataIndex: "categoryType",
        key: "categoryType",
        sorter: {
          compare: (a, b) => Sorter.DEFAULT(a.categoryType, b.categoryType),
          multiple: 3,
        },
      },
      {
        title: "Action",
        dataIndex: "action",
        key: "action",
      },
    ];
    settabkeywordList(data);
    getCategoryData();
  }, []);

  function submit(item) {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            deleteCategoryItem(item.id);
          },
        },
        {
          label: "No",
        },
      ],
    });
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows, info) => {
      if (info.type == "all") {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        selectedRows.map((item) => {
          let obj = {
            key: item.key,
            count: item.count,
            keywordcategory: item.categoryType,
          };
          list.push(obj);
        });
        setSelectedExportList(list);
      } else {
        localStorage.setItem("selectionInfoType", info.type);
        const list = [];
        selectedRowKeys = [];
        selectedRows.map((item) => {
          let obj = {
            key: item.key,
            keywordcategory: item.categoryType,
          };
          list.push(obj);
        });
        setSelectedExportList(list);
      }

      const list1 = [];
      selectedRows.map((item) => {
        list1.push(item.categoryId);
      });
  
      setSelectedkeywordCatCheckList(list1);
      if (info.type == "all") {
        setSelectedkeyword(Categorykeywordlist.length);
      } else {
        setSelectedkeyword(list1.length);
      }
    },
  };

  function getCategoryData() {
    APIManager.getAPI(
      getCategoryByProject + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        let c = 1;
        let exportList = [];
        let count = 1;
        setCount(count);
        response.data.map((item) => {
          exportList.push({
            key: item.id,
            keywordcategory: item.categoryType,
          });
          let obj = {
            count: c,
            // key: count,
            key: item.id,
            id: item.id,
            categoryId: item.id,
            categoryType: item.categoryType,
            action: (
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-edit edit-icon"
                  onClick={() => {
                    setIsEditCategory(true);
                    setCategoryId(item.id);
                    setCategoryName(item.categoryType);
                    setCategoryNameCopy(item.categoryType);
                    setCategoryMessage("");
                    var e = document.getElementById("input-s");
                    e.scrollIntoView({
                      block: "end",
                      behavior: "smooth",
                      inline: "center",
                    });
                  }}
                >
                  <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                  <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  class="feather feather-trash-2 trash-icon ms-3"
                  onClick={() => {
                    submit(item);
                    deleteCategoryItem(item.id);
                  }}
                >
                  <polyline points="3 6 5 6 21 6"></polyline>
                  <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                  <line x1="10" y1="11" x2="10" y2="17"></line>
                  <line x1="14" y1="11" x2="14" y2="17"></line>
                </svg>
              </div>
            ),
          };
          list.push(obj);
          count++;
        });
        setCount(count);
        setKeywordCatTabcol(list);
        setuserrolesList(list);
        setExportList(exportList);
        setTotalkeyword(list.length);
        setCategorykeywordList(list);
      }
    });
  }

  function clearData() {
    setIsEditCategory(false);
    setCategoryName("");
    setCategoryId("");
    getCategoryData();
  }

  function addCategory() {
    if (categoryName.trim() === "") {
      toast.error("Keyword category Name is required!");
      return;
    }
    APIManager.postAPI(
      createCategory,
      {
        CategoryType: categoryName.trim(),
        ProjectId: localStorage.getItem("projectGId"),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearData();
        notifyAdd();
      }
    });
  }

  function updateCategoryItem() {
    if (categoryName === "") {
      toast.error("Keyword Category name is required!");
      return;
    }
    APIManager.putAPI(
      updateCategory,
      {
        CategoryType: categoryName.trim(),
        ProjectId: localStorage.getItem("projectGId"),
        Id: categoryId,
      },
      null,
      true
    ).then((response) => {
      if (response?.status == 200) {
        clearData();
      }
    });
  }

  async function deleteCategoryItem(id) {
    try {
      const response = await APIManager.deleteAPI(deleteCategory + id);
      if (response == 200) {
        setCategoryName("");
        getCategoryData();
      }
    } catch (e) { }
  }

  function deleteAllKeywordCategory() {
    return new Promise((resolve, reject) => {
      APIManager.deteletItem(
        deleteAllKeywordCat + localStorage.getItem("projectGId")
      ).then((response) => {
        if (response && response.status == 200) {
          getCategoryData();
          if (response.status === 200)
            if (response?.data?.message) {
            } else {
              // notifyDelete();
            }
          resolve(response.status);
        }
      });
    });
  }

  function deleteMultiKeywordCatId() {
    return new Promise((resolve, reject) => {
      APIManager.postAPIForDelete(
        deleteMultiKeywordCat,
        SelectedkeywordCatCheckList,
        {},
        true
      ).then((response) => {
        if (response && response.status == 200) {
          getCategoryData();
          if (response.status === 200)
            if (response?.data?.message) {
            } else {
              // notifyDelete();
            }
          resolve(response.status);
        }
      });
    });
  }

  function submitAll() {
    confirmAlert({
      title: "Confirm to Delete",
      message:
        Selectedkeyword +
        " of " +
        Totalkeyword +
        " keywords selected will be deleted.",
      buttons: [
        {
          label: "Delete",
          onClick: () => {
            if (localStorage.getItem("selectionInfoType") !== "all") {
              deleteMultiKeywordCatId();
            } else {
              deleteAllKeywordCategory();
            }
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  }

  const history = useHistory();

  return (
    <>
      <div className="portlet slideInUp">
        <div class="portlet-header portlet-header-bordered">
          <svg onClick={() => update("")} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("projectGName")} - Keyword Category
          </h3>
        </div>
        <div className="portlet-body">
          <div className="col-md-12 col-sm-12">
            <div className="d-flex justify-content-between roles-tab">
              <div className="d-flex justify-content-start align-items-center category-add">
                <input
                  placeholder="Enter Category Name"
                  class=" w-30"
                  style={{ borderRight: 0, borderRadius: 0, padding: 8 + "px" }}
                  type="text"
                  id="category-name"
                  value={categoryName}
                  required
                  onChange={(text) => {
                    setCategoryName(text.target.value);
                    setCategoryMessage("");
                  }}
                />
                <button
                  class="btn btn-primary"
                  style={{ borderLeft: 0, borderRadius: 0, height: 36 + "px" }}
                  onClick={() => {
                    if (categoryName != "") {
                      if (categoryNameCopy == categoryName && isEditCategory) {
                        setIsEditCategory(false);
                        setCategoryName("");
                        setCategoryId("");
                        return;
                      }
                      let isEqualItem = false;
                      userrolesList.map((i) => {
                        if (i.categoryType == categoryName) {
                          isEqualItem = true;
                        }
                      });
                      if (isEqualItem) {
                        setCategoryMessage(
                          "Keyword Category name already exist"
                        );
                      } else {
                        setCategoryMessage("");
                        if (isEditCategory) {
                          updateCategoryItem();
                        } else {
                          addCategory();
                        }
                      }
                    } else {
                      setCategoryMessage("Keyword Category name is required");
                    }
                  }}
                >
                  {isEditCategory ? "Update" : "Add"}
                </button>
              </div>
            </div>
            <>
              <div className="field-required">
                <span>{categoryMassage}</span>
              </div>
            </>
          </div>
          <div className="row">
            <div className="col-md-4 mt-3">
              <div className="d-flex justify-content-start">
                <div class="input-group-icon input-group-lg widget15-compact">
                  <div class="input-group-prepend">
                    <i class="fa fa-search text-primary"></i>
                  </div>
                  <div className="search-box">
                    <span>
                      <Input
                        allowClear
                        type="text"
                        class="form-control"
                        placeholder="Type to search..."
                        style={{ height: 36 + "px" }}
                        onChange={(nameSearch) => {
                          setKeywordCatTabcol(
                            userrolesList.filter((person) =>
                              person.categoryType
                                .toLocaleLowerCase()
                                .includes(
                                  nameSearch.target.value.toLocaleLowerCase()
                                )
                            )
                          );
                        }}
                        id="input-s"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              <div className="add-new-btnw mt-3">
                <span class="dropdown d-inline">

                  <button
                    disabled={!SelectedkeywordCatCheckList?.length}
                    style={{ marginLeft: 18 + "px" }}
                    class="btn btn-primary mx-2 download-temp"
                    onClick={() => {
                      submitAll();
                    }}
                  >
                    Delete Selected
                  </button>
                  <button
                    class="btn btn-primary dropdown-toggle export-btn me-3"
                    type="button"
                    onClick={() => {
                      exportToEXCEL(
                        selectedExportList?.length > 0
                          ? selectedExportList
                          : exportList,
                        "Keyword Category"
                      );
                    }}
                  >
                    Export
                  </button>
                  {/* <div
                    class="dropdown-menu dropdown-menu-left dropdown-menu-animated"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <div
                      onClick={() => {
                        exportToEXCEL(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Keyword Category"
                        );
                      }}
                    >
                      <a class="dropdown-item">Excel</a>
                    </div>
                    <div
                      onClick={() => {
                        exportInToCSV(
                          selectedExportList?.length > 0
                            ? selectedExportList
                            : exportList,
                          "Keyword Category"
                        );
                      }}
                    >
                      <a class="dropdown-item">CSV</a>
                    </div>
                  </div> */}
                </span>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <Table
              className="table table-bordered table-hover keywordcategory-table"
              id="sample-module-expand"
              dataSource={KeywordCatTabcol}
              columns={tabkeywordlist}
              rowSelection={{ type: "checkbox", ...rowSelection }}
              pagination={{
                position: ["bottomRight"],
                showSizeChanger: true,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default KeywordCategory;
