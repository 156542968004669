import React, { useState } from "react";
import { Doughnut } from "react-chartjs-2";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import {
  getDomianAuthoritychart,
} from "../../utility/Constant";
import {
  mmDDyyyyFormateDateSet,
  fixTwoPlaceDecimal,
  getMonthYearName,
  getLastMonthYearName,
} from "../../utility/Globals";

function DomainAuthority(props) {

  // const [startDate, setstartDate] = useState("");
  // const [endDate, setendDate] = useState("");
  // const [compareStartDate, setcompareStartDate] = useState("");
  // const [compareendDate, setcompareendDate] = useState("");

  useEffect(() => {
    // setstartDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    // );
    // setendDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    // );
    // setcompareStartDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    // );
    // setcompareendDate(
    //   mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    // );

    let rowData = {
      projectId: localStorage.getItem("projectGId"),
      DateRange: {
        from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate),
        to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate),
      },
      CompareDateRange: {
        from: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate),
        to: mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate),
      },
    };

    getDomianAuthority(rowData);

  }, [props?.dataParentToChild]);

  const [percentage, setPercentage] = useState(0);

  let firstDayy;
  let lastDayy;
  const today = new Date();
  firstDayy = new Date(today.setDate(today.getDate() - today.getDay() - 7));
  lastDayy = new Date(today.setDate(today.getDate() - today.getDay() + 6));

  const [da, setDa] = useState([]);
  const [dacomp, setDaComp] = useState([]);
  // const [projectId, setProjectId] = useState([]);

  function getDomianAuthority(rowData) {
    APIManager.postAPI(getDomianAuthoritychart, rowData, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          let list = [];
          let list1 = [];
          response.data.table.map((item) => {
            let obj = {
              da: item.da,
              projectId: localStorage.getItem("projectGId"),
            };
            list.push(obj);
            setPercentage(fixTwoPlaceDecimal(item.da));
            if (item.da >= 0 && item.da <= 30) {
              setStatusData(["Needs work", "Remaining"]);
            } else if (item.da >= 30 && item.da <= 75) {
              setStatusData(["Good", "Remaining"]);
            } else if (item.da > 75) {
              setStatusData(["Excellent", "Remaining"]);
            }
          });
          response.data.table1.map((item) => {
            let obj1 = {
              da: item.da,
              projectId: localStorage.getItem("projectGId"),
            };
            list1.push(obj1);
          });
          // setProjectId(response?.data.table[0]?.projectId);
          setDa(response?.data.table[0]?.da);
          setDaComp(response?.data.table1[0]?.da);
        }
      }
    );
  }

  function getColorOfData(value) {
    if (value > 0) {
      return "digit-green";
    } else if (value < 0) {
      return "digit-red";
    } else {
      return "";
    }
  }

  const [statusData, setStatusData] = useState(["", "Remaining"]);
  const data = {
    maintainAspectRatio: false,
    responsive: false,
    labels: statusData,
    datasets: [
      {
        data: [percentage, 100 - percentage],
        backgroundColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
        borderColor: [
          percentage > 75
            ? "#91E395"
            : percentage >= 30 && percentage <= 75
              ? "#FFAA46"
              : "#F64F53",
          "#F2F2F2",
        ],
      },
    ],
  };

  return (
    <>
      <div className="mt-3 dash-content round-chart">
        <div className="d-flex align-items-center justify-content-center pb-2 domainauthority-doughnut seo-health-doughnut">
          <Doughnut
            data={data}
            options={{
              cutoutPercentage: 80,
              responsive: true,
              maintainAspectRatio: true,
              tooltips: {
                enabled: false
              },
              legend: {
                display: false,
                position: "bottom",
                labels: {
                  usePointStyle: true,
                  pointStyle: "circle",
                },
              },
            }}
          />
          <span class="hover-img ranking-img">
            <div height="200" width="300" className="d-flex">
              <div class="row">
                <div className="d-flex align-items-center p-0">
                  <table>
                    <tr>
                      <td className="traffic-value border-rightside px-3">
                        {(da, Math.round(da))}
                      </td>
                      <td className="traffic-value border-rightside px-3">
                        {(dacomp, Math.round(dacomp))}
                      </td>
                      <td className="traffic-value  px-3 text-center">
                        <div
                          className={getColorOfData(
                            Math.round(da) - Math.round(dacomp)
                          )}
                        >
                          {Math.round(da) - Math.round(dacomp)}
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td className="traffic-date border-rightside px-3">
                        {getLastMonthYearName()}
                      </td>
                      <td className="traffic-date border-rightside px-3">
                        {getMonthYearName()}
                      </td>
                      <td className="traffic-date px-3 text-center">Change</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div className="pageauthority percentage-text">
          <div className="text-name score-text">{`${(da, Math.round(da))
            }`}</div>
          <div className="audit-total">
            <div
              className={getColorOfData(
                Math.round(da) - Math.round(dacomp)
              )}
            >
              {(Math.round((da - dacomp) * 100 / dacomp))}%
            </div>
          </div>
        </div>

        <div className="domain-authority text-center mt-4">
          <h6>Domain Authority</h6>
        </div>
      </div>
    </>
  );
}

export default DomainAuthority;
