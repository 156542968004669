import { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";
import { Calendar } from "react-multi-date-picker";
import {
  getDaysInMonth,
  setMonths,
} from "../utility/Globals";
import { mmDDyyyyFormateDateSet } from "../utility/Globals";
import BandwidthAnalysis from "./bandwidthMoniter/BandwidthAnalysis";
import BandwidthMonitoring from "./bandwidthMoniter/BandwidthMonitoring";
import TeamMember from "./bandwidthMoniter/TeamMember";
import FeatherIcon from 'feather-icons-react';

function BandwidthMoniter() {

  const [open, setOpen] = useState(false);
  const [monthYear, setMonthYear] = useState(setMonths(new Date().getMonth() + 1) + " " + new Date().getFullYear());

  var date = new Date();
  var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const [values, setValues] = useState([
    mmDDyyyyFormateDateSet(new Date(firstDay).toLocaleDateString()),
    mmDDyyyyFormateDateSet(new Date(lastDay).toLocaleDateString()),
  ]);

  const [valuesParentToChild, setValuesParentToChild] = useState([
    mmDDyyyyFormateDateSet(new Date(firstDay).toLocaleDateString()),
    mmDDyyyyFormateDateSet(new Date(lastDay).toLocaleDateString()),
  ]);

  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [open]);

  return (
    <>
      <div class="row new-dashboard bandwidth">
        <div class="header-holder header-holder-desktop portlet d-flex px-0 mb-0">
          <div class="header-container py-3 w-100 d-flex justify-content-between">
            <div className="title-box">
              <h4 class="header-title">Welcome {localStorage.getItem("userName")},</h4>
              <p>Here is the bandwidth split of the organization</p>
            </div>
            <div ref={ref} class="calendar-box">
              <div className="calendar-main ga-calender">
                <FeatherIcon icon="calendar" />
                <span className="ms-2 me-3" onClick={() => setOpen(!open)} >{monthYear} </span>
                <FeatherIcon icon="chevron-down" />
                {open && (
                  <div id="example-collapse-text-calendar">
                    <Card body className="daterange-picker-card  mt-2">
                      <div className="d-flex justify-content-between">
                        <div className="calendar-col">
                          <Calendar
                            maxDate={new Date()}
                            onChange={(e) => {
                              var date1 = e[0].format().split("/")
                              // var date2 = e[1].format().split("/")
                              var daysInMonth = getDaysInMonth(date1[1], date1[0])
                              let dateObjArr = [
                                new Date(date1[0] + "/01/" + date1[1]),
                                new Date(date1[0] + "/" + daysInMonth + "/" + date1[1]),
                              ];
                              setValues(dateObjArr);
                              setMonthYear(setMonths(new Date(date1[0]).getMonth() + 1) + " " + new Date(date1[1]).getFullYear());
                            }
                            }
                            value={values}
                            onlyMonthPicker
                            range
                          />
                        </div>
                        <div className="ms-3">
                          <button
                            onClick={() => {
                              setOpen(!open);
                              setValuesParentToChild(values);
                            }}
                            className="btn-outline btn btn-primary"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                    </Card>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BandwidthMonitoring
          dataParentToChild={valuesParentToChild}
        />
      </div>
      <div>
        <BandwidthAnalysis
          dataParentToChild={valuesParentToChild}
        />
      </div>
      <div>
        <TeamMember
          dataParentToChild={valuesParentToChild}
        />
      </div>
    </>
  )
}

export default BandwidthMoniter