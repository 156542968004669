import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  addCountryConfiguration,
  getCountryByOrg,
  updateCountry,
} from "../../utility/Constant";
import { useLocation } from "react-router-dom";
import toast from "react-hot-toast";

const EditCountryDir = ({ update }) => {
  const [country, setCountry] = useState("");
  const [submit, setSubmit] = useState(false);
  const [submit1, setSubmit1] = useState(false);
  const [submit2, setSubmit2] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [locationCode, setLocationCode] = useState("");
  const location = useLocation();
  const [countryName, setCountryName] = useState("");
  const [cName, setCname] = useState(false);
  const [cLocation, setCLocation] = useState(false);
  const [cCode, setCcode] = useState(false);
  const [cMsgName, setMsgname] = useState("");
  const [cMsgLocation, setMsgLocation] = useState("");
  const [cMsgCode, setMsgcode] = useState("");
  const notifyAdd = () => toast.success("Successfully Added!");

  useEffect(() => {
    if (location.state.isEdit) {
      setCountryName(location.state.item.countryName);
      setCountryCode(location.state.item.countryCode);
      setLocationCode(location.state.item.locationCode);
    } else {
    }
    getCountry();
  }, []);

  function formRequiredValidation() {
    let isValidFields = true;
    if (countryName.trim() == "") {
      isValidFields = false;
      setCname(true);
      setMsgname("Country Name is required!");
    } else {
      setCname(false);
      setMsgname("");
    }
    if (countryCode.trim() == "") {
      setCcode(true);
      setMsgcode("Country code is required!");
      isValidFields = false;
    } else {
      setCcode(false);
      setMsgcode("");
    }
    if (locationCode.trim() == "") {
      setCLocation(true);
      setMsgLocation("Location code is required!");
      isValidFields = false;
    } else {
      setCLocation(false);
      setMsgLocation("");
    }
    return isValidFields;
  }

  function formExistValidation() {
    let isValidFields = true;
    country.map((item) => {
      if (item.countryName.toLowerCase() === countryName.toLowerCase()) {
        isValidFields = false;
        setMsgname("Country Name already exist!");
        setCname(true);
      }
    });
    country.map((item) => {
      if (item.CountryCode.toLowerCase() === countryCode.toLowerCase()) {
        isValidFields = false;
        setMsgcode("Country Code already exist!");
        setCcode(true);
      }
    });
    return isValidFields;
  }

  function getCountry() {
    APIManager.getAPI(
      getCountryByOrg + localStorage.getItem("organizationId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        response.data.map((item) => {
          let obj = {
            id: item.id,
            countryName: item.countryName,
            CountryCode: item.countryCode,
            locationCode: item.locationCode,
          };
          list.push(obj);
        });
        setCountry(list);
      }
    });
  }

  function addCountry() {
    setSubmit(true);
    setSubmit1(true);
    setSubmit2(true);
    if (!countryName?.length) {
      return;
    }
    if (!countryCode?.length) {
      return;
    }
    if (!locationCode?.length) {
      return;
    }
    APIManager.postAPI(
      addCountryConfiguration,
      {
        countryName: countryName.trim(),
        CountryCode: countryCode.trim(),
        locationCode: locationCode.trim(),
        OrganizationId: localStorage.getItem("organizationId"),
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearForm();
        notifyAdd();
      }
    });
  }

  function updateCountryItem() {
    setSubmit(true);
    setSubmit1(true);
    setSubmit2(true);
    if (!countryName?.length) {
      return;
    }
    if (!countryCode?.length) {
      return;
    }
    if (!locationCode?.length) {
      return;
    }
    APIManager.putAPI(
      updateCountry,
      {
        CountryName: countryName.trim(),
        CountryCode: countryCode.trim(),
        locationCode: locationCode.trim(),
        OrganizationId: localStorage.getItem("organizationId"),
        id: location.state.item.id,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        clearForm();
      }
    });
  }

  function clearForm() {
    setCountryName("");
    setCountryCode("");
    setLocationCode("");
    update("country");
  }

  return (
    <>
      <div class="portlet slideInUp  setting-top  ">
        <div class="portlet-header portlet-header-bordered">
        <svg   onClick={() => {
              update("country");
            }} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-left close-left-icon"><polyline points="15 18 9 12 15 6"></polyline></svg>
          <h3 class="portlet-title main-title">
            {localStorage.getItem("organizationName")} -{" "}
            {location.state.isEdit ? "Update" : "Add"} Country
          </h3>
        </div>
        <div className="portlet-body my-3">
          <div class="col-md-12 ">
            <label>Country Name</label>
            <label class="field-required"> * </label>
            <input
              className="form-control  w-100"
              type="text"
              name=""
              placeholder="Enter Country Name"
              value={countryName}
              onChange={(e) => {
                setSubmit(false);
                setCountryName(e.target.value);
              }}
            />

            {cName ? (
              <div className="field-required">
                <span> {cMsgName}</span>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-12 mt-3">
            <label>Country Code</label>
            <label class="field-required"> * </label>

            <input
              className="form-control w-100"
              type="text"
              name=""
              placeholder="Enter Country Code"
              value={countryCode}
              onChange={(e) => {
                setSubmit1(false);
                setCountryCode(e.target.value);
              }}
            />
            {cCode ? (
              <div className="field-required">
                <span> {cMsgCode}</span>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div className="col-md-12 mt-3">
            <label>Location Code{submit2}</label>
            <label class="field-required"> * </label>

            <input
              className="form-control w-100"
              type="text"
              name=""
              placeholder="Enter Location Code"
              value={locationCode}
              onChange={(e) => {
                setSubmit2(false);
                setLocationCode(e.target.value);
              }}
            />
            {cLocation ? (
              <div className="field-required">
                <span> {cMsgLocation}</span>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div className="portlet-footer portlet-footer-bordered">
          <div className="col-md-12">
            <button
              onClick={() => {
                if (location.state.isEdit) {
                  if (formRequiredValidation()) {
                    updateCountryItem();
                  }
                } else {
                  if (formExistValidation() && formRequiredValidation()) {
                    addCountry();
                  }
                }
              }}
              className="btn btn-primary me-3"
            >
              {location.state.isEdit ? "Update" : "Save"}
            </button>
            <button
              className="btn btn-outline-secondary"
              onClick={() => {
                update("country");
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCountryDir;
