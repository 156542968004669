import React, { useEffect, useState } from "react";
import APIManager from "../../services/APIManager";
import {
  getAllSeoAuditData,
  GET_Server_URL,
  indexLoGoImage_URL,
} from "../../utility/Constant";
import { useHistory } from "react-router-dom";
import { setPriority } from "../../utility/Globals";
import { useLocation } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import jsPDF from "jspdf";
import "jspdf-autotable";

import { savePDF } from "@progress/kendo-react-pdf";
import Loading from "../../utility/Loading";
import { ImportantDevices } from "@mui/icons-material";
import $ from 'jquery'; 

const Moudles = (props) => {
  const pdfExportComponent = React.useRef(null);
  const location = useLocation();

  const [projectAuditName, setProjectAuditName] = useState("");
  const [response, setRresponse] = useState([]);
  const [auditInstruction, setAuditInstruction] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [visible, setVisible] = useState(true);

  const [indexLogo, setIndexLogo] = useState(indexLoGoImage_URL);

  const printPDF = (e) => {
    setLoading(true);
    var isChanged = false;

    if (document.querySelector("body").classList.contains("theme-dark")) {
      document.querySelector("body").classList.remove("theme-dark");
      document.querySelector("body").classList.add("theme-light");
      isChanged = true;
    }

    e.preventDefault();
    let doc = new jsPDF("l", "mm", "a0");
    doc.autoTable({
      html: "table",
      rowPageBreak: "avoid",
    });

    var b = document.getElementById("main-deiv");

    doc.html(b, {
      margin: 10,
      autoPaging: "text",
      html2canvas: { useCORS: true },
      callback: () => {
        doc.save(`${projectAuditName}.pdf`);
        if (isChanged) {
          document.querySelector("body").classList.remove("theme-light");
          document.querySelector("body").classList.add("theme-dark");
        }
        setLoading(false);
      },
    });
  };

  function topFunction() {
    var e = document.getElementById("audit-1");
    e.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }
  useEffect(() => {
    getAllSeoAuditInfo(location.state.projectAuditId);
  }, []);

  const history = useHistory();
  const [listIndex, setListIndex] = useState([]);
  function getAllSeoAuditInfo(id) {
    APIManager.getAPI(getAllSeoAuditData + id).then((response) => {
      if (response && response.status == 200) {
        setRresponse(response?.data?.auditModuleResponses);
        setAuditInstruction(response?.data?.auditInstruction?.instruction);
        setProjectAuditName(response?.data?.projectAuditName);

        let listIndexArr = [];
        response?.data?.auditModuleResponses.map((item, index) => {
          let c = index;
          let listC = item.audits?.length;
          let i = {
            index: c,
            count: listC,
          };
          listIndexArr.push(i);
        });
        setListIndex(listIndexArr);
        setTimeout(function () {
          var e = document.getElementById("audit-12");
          e.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });

          $(function(){
            $(".editor-image a").attr("target","_blank");
          });
        }, 500);
      }
    });
  }
  let itemCount = 0,
    itemCount2 = 0;

  const image = React.useRef(null);

  return (
    <>
      <div id="audit-1" className="row common-mb-26">
        <div class="dis-none p-0">
          <div className="col-md-12 d-flex mb-3 p-0">
            <i
              class="fa fa-chevron-circle-left"
              style={{ fontSize: 20 + "px", color: "#2196f3" }}
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </div>
        </div>

        <div className="portlet auditpdf p-0">
          <div class="portlet-header portlet-header-bordered">
            <h3 class="portlet-title main-title">Audit Findings Module</h3>
          </div>
          <div className="portlet-body">
            <div className="col-md-12 text-right">
              {localStorage.getItem("userRole") != "Customer" ? (
                <button
                  className="btn btn-primary"
                  onClick={(e) => {
                    if (image.current) {
                      var isChanged = false;
                      setLoading(true);
                      if (
                        document
                          .querySelector("body")
                          .classList.contains("theme-dark")
                      ) {
                        document
                          .querySelector("body")
                          .classList.remove("theme-dark");
                        document
                          .querySelector("body")
                          .classList.add("theme-light");
                        isChanged = true;
                      }

                      savePDF(
                        image.current,
                        {
                          paperSize: "A4",
                          fileName: `${projectAuditName}.pdf`,
                          margin: "0.5cm",
                          keepTogether: ".table-keep",
                          forcePageBreak: ".page-break",
                          landscape: true,
                        },
                        () => {
                          if (isChanged) {
                            document
                              .querySelector("body")
                              .classList.remove("theme-light");
                            document
                              .querySelector("body")
                              .classList.add("theme-dark");
                          }
                          setLoading(false);
                        }
                      );
                    }
                  }}
                >
                  Export PDF
                </button>
              ) : (
                <></>
              )}
            </div>
            <PDFExport
              paperSize="A4"
              margin="2cm"
              fileName={`${projectAuditName}.pdf`}
              ref={pdfExportComponent}
              forcePageBreak=".page-break"
            >
              <div ref={image}>
                <div
                  id="main-deiv"
                  className="row"
                  style={{ fontFamily: "Montserrat" }}
                >
                  <div className="module-contain">
                    <div className="col-md-12 d-flex mt-3 align-items-center p-0 module-title">
                      <h1 className="text-blue fw-bold d-flex me-3 mb-0 ">
                        AUDIT VERSION:
                      </h1>

                      <p className="text-dark text-capitalize fw-bold me-3 mb-0 p-0 versionname module-title project-name">
                        {projectAuditName}
                      </p>
                    </div>
                    <div className="text-center">
                      <br />
                      <img
                        src={indexLogo}
                        width="300px"
                        crossorigin="anonymous"
                      />

                      <br />
                    </div>
                    <h2 className="col-md-12 text-dark text-capitalize fw-bold me-3 p-0 versionname">
                      Index
                    </h2>
                    {response.map((item, index2) => {
                      return (
                        <>
                          <div className="col-md-12 d-flex mt-3 align-items-center p-0 versionname">
                            <h4 className="col-md-12 text-dark text-capitalize fw-bold  me-3 pt-0 p-0 mb-0 versionname">
                              {index2 + 1}
                              {" - "}
                              {item.moduleName}
                            </h4>
                          </div>

                          {item?.audits.map((i, index) => {
                            itemCount2 = itemCount2 + 1;
                            return (
                              <div className="col-md-12 p-0">
                                <div className="col-md-12 my-4 index-point">
                                  <h5 className="col-md-12 me-3 ps-4 versionname ">
                                    {index2 + 1}.{index + 1}
                                    {" - "}
                                    <a
                                      className="pointtitle"
                                      onClick={() => {
                                        let iCount = 0;
                                        listIndex.map((i) => {
                                          if (index2 >= i.index) {
                                            if (index2 == i.index) {
                                              iCount = iCount + index + 1;
                                              return;
                                            } else {
                                              iCount = iCount + i.count;
                                            }
                                          }
                                        });
                                        let idd = "audit_" + iCount;
                                        var e = document.getElementById(idd);
                                        e.scrollIntoView({
                                          behavior: "smooth",
                                          inline: "center",
                                        });
                                      }}
                                    >
                                      {i?.pointTitle}
                                    </a>
                                  </h5>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      );
                    })}

                    <br />
                    <p className="page-break" />
                    <div
                      className="bg-transparent"
                      dangerouslySetInnerHTML={{
                        __html: auditInstruction,
                      }}
                    ></div>
                    {response.map((item, index) => {
                      return (
                        <>
                          <p className="page-break" />
                          <div class="row">
                            {index == 0 ? (
                              <div className="col-md-12 d-flex mt-3 align-items-center p-0 breakdown">
                                <h1>BREAKDOWN OF Audit Findings</h1>
                              </div>
                            ) : (
                              <></>
                            )}
                            <div className="col-md-12 d-flex mt-3 align-items-center p-0">
                              <h2 className="text-dark text-capitalize fw-bold mb-0 versionname">
                                {" "}
                                {index + 1}
                                {" - "}
                                {item.moduleName}
                              </h2>
                            </div>

                            {item.moduleDescription ? (
                              <div className="col-md-12 d-flex mt-3 align-items-center">
                                <div className="text-dark text-capitalize me-3 mb-0 versionname">
                                  <div className="editor-image ">
                                    <h2 className="text-dark text-capitalize me-3 ms-5 versionname">
                                      Introduction
                                    </h2>
                                    <div
                                      className="ms-5 versionname"
                                      dangerouslySetInnerHTML={{
                                        __html: item.moduleDescription,
                                      }}
                                    ></div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                            {item?.audits.map((i, idx) => {
                              itemCount = itemCount + 1;
                              return (
                                <>
                                  <div
                                    id={"audit_" + itemCount}
                                    className="col-md-12 p-0 mt-0 versionname  "
                                  >
                                    <div className="col-md-12 text-dark text-capitalize p-0 ms-5 mt-2 ps-2">
                                      {i?.pointTitle ==
                                      location.state.pointTitle ? (
                                        <p
                                          id="audit-12"
                                          className="point-title text-blue p-0  versionname "
                                        >
                                          <a
                                            href="#"
                                            className="text-dark versionname"
                                          >
                                            {index + 1}
                                            {"."}
                                            {idx + 1}
                                            {" - "}
                                          </a>
                                          {i?.pointTitle}
                                        </p>
                                      ) : (
                                        <p className="point-title p-0  versionname  ">
                                          <a
                                            href="#"
                                            className="text-dark versionname"
                                          >
                                            {index + 1}
                                            {"."}
                                            {idx + 1}
                                          </a>
                                          {" - "} {i?.pointTitle}
                                        </p>
                                      )}
                                    </div>

                                    {i?.comment == "null" ||
                                    i?.comment == null ||
                                    i?.comment == "" ? (
                                      <></>
                                    ) : (
                                      <div className="editor-image my-4 ms-5">
                                        <div className="col-md-12">
                                          <h4 className="text-dark text-capitalize ms-5 versionname">
                                            Observation
                                          </h4>
                                          <div
                                            className="ms-5 bg-transparent "
                                            dangerouslySetInnerHTML={{
                                              __html: i?.comment,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    )}

                                    <div className="col-md-12 mt-3 ms-5 my-4 priority-table">
                                      <table
                                        className="table table-bordered border border-secondary pdf-table table-hover  text-center audit-table ms-5 table-keep"
                                        style={{ width: "auto" }}
                                      >
                                        <tr>
                                          <th className="border-end w-50">
                                            Priority
                                          </th>
                                          <th className="w-50">Impact</th>
                                        </tr>
                                        <tr style={{ borderTop: 0 + "px" }}>
                                          <td className="border-end">
                                            {setPriority(
                                              i.auditResponse?.impact
                                            )}
                                          </td>
                                          <td>
                                            {setPriority(
                                              i?.auditResponse?.priority
                                            )}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>

                                    {i.auditResponse?.suggestion ? (
                                      <div className="editor-image p-0 mt-3 ms-5">
                                        <div className="col-md-12">
                                          <h4 className="text-dark text-capitalize ms-5 versionname">
                                            Recommendation
                                          </h4>
                                          <div
                                            className="ms-5 bg-transparent"
                                            dangerouslySetInnerHTML={{
                                              __html:
                                                i.auditResponse?.suggestion,
                                            }}
                                          ></div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    )}

                                    {i.auditResponse?.auditSupportingDocs
                                      ?.length > 0 ? (
                                      <div className="col-md-12 my-4 ms-5 refrence">
                                        <div className="d-flex align-items-center p-0">
                                          <h4 className="text-dark text-capitalize ms-5 versionname">
                                            References
                                          </h4>
                                        </div>
                                        {i.auditResponse?.auditSupportingDocs?.map(
                                          (dataItem) => {
                                            return (
                                              <div
                                                className="ms-5 bg-transparent reference-link"
                                                onClick={() => {}}
                                              >
                                                <a
                                                  target="_blank"
                                                  href={
                                                    GET_Server_URL +
                                                    dataItem.documents
                                                  }
                                                >
                                                  {dataItem.documents}
                                                </a>
                                              </div>
                                            );
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </>
                              );
                            })}
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </PDFExport>
            <div>
              <div className="example-config"></div>
            </div>
          </div>

          {visible ? (
            <div onClick={topFunction} className="scroll-topbutton">
              <i
                id="myBtn"
                title="Go to top"
                class="fa fa-angle-up"
                aria-hidden="true"
              ></i>
            </div>
          ) : (
            <></>
          )}

          <Loading isLoading={isLoading} />
        </div>
      </div>
    </>
  );
};

export default Moudles;
