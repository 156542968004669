import React, { useEffect, useState, useRef } from "react";
import { Table } from "antd";
import { Card } from "react-bootstrap";

function ModuleExpandRankTracking1() {
  useEffect(() => {}, []);

  return (
    <>
      <div className="portlet newranking">
        <div className="row">
          <div className="col-md-4 d-flex align-items-center ">
            <h5 className="ranking-name">Ranking</h5>

            <select className="ms-3 ranking-brand" name="brand">
              <option>All</option>
              <option>Brand</option>
              <option>Non Brand</option>
            </select>
          </div>
          <div className="col-md-8"></div>
        </div>
      </div>
    </>
  );
}

export default ModuleExpandRankTracking1;
