import { Input, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import APIManager from '../../../services/APIManager';
import { campaignViewsave, getcampaignViewDetails } from '../../../utility/Constant';
import { Sorter } from '../../../common/Sorter';

function Createcampaingreview(props) {

    const columns = [
        {
            title: 'Keyword',
            dataIndex: 'keyword',
            key: 'keyword',
            sorter: {
                compare: (a, b) =>
                    Sorter.DEFAULT(
                        a.keyword.toLocaleLowerCase(),
                        b.keyword.toLocaleLowerCase()
                    ),
                multiple: 3,
            },
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.searchVolume, b.searchVolume),
                multiple: 3,
            },
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            sorter: {
                compare: (a, b) => Sorter.DEFAULT(a.type, b.type),
                multiple: 3,
            },
        },
        {
            title: 'Priority',
            dataIndex: 'priority',
            key: 'priority',
            sorter: {
                compare: (a, b) => a.priority - b.priority,
                multiple: 3,
            },
        },
        {
            title: 'SearchVolume',
            dataIndex: 'searchvolume',
            key: 'searchvolume',
            sorter: {
                compare: (a, b) => a.searchvolume - b.searchvolume,
                multiple: 3,
            },
        },
    ];
    const columnsset = [
        {
            title: 'Competitors',
            dataIndex: 'competitors',
            key: 'competitors',
            sorter: {
                compare: (a, b) =>
                    Sorter.DEFAULT(
                        a.competitors.toLocaleLowerCase(),
                        b.competitors.toLocaleLowerCase()
                    ),
                multiple: 3,
            },
        },
    ];
    useEffect(() => {
        getViewCampaignRankingDetails();

    }, []);
    const handleviewcampaignChangeEdit = (val) => {
        props.isEdit(val);
    };
    const [campingName, setcampingName] = useState("");
    const [Device, setDevice] = useState("");
    const [InputFields, setInputFields] = useState("");
    const [noofkeyword, setnoofkeyword] = useState("");
    const [competitoraddNo, setcompetitoraddNo] = useState("");
    const [viewkeywordlist, setviewkeywordlist] = useState("");
    const [viewcompetitorlist, setviewcompetitorlist] = useState([]);
    const Devicelist = [
        {
            value: "0",
            lable: "Mobile",
        },
        {
            value: "1",
            lable: "Desktop",
        },
    ];

    function getDeviceName(val) {
        let finalVal = val;
        Devicelist.map((i) => {
            if (val == i.value) {
                finalVal = i.lable;
            }
        });
        return finalVal;
    }

    function getViewCampaignRankingDetails() {
        APIManager.getAPI(getcampaignViewDetails + props?.campaignviewID).
            then((response) => {
                if (response && response.status == 200) {
                    const listkeyword = [];
                    const complist = [];
                    setcampingName(response.data.detailsResponse[0].campaignViewName);
                    setDevice(getDeviceName(response.data.detailsResponse[0].device));
                    setInputFields(response.data.detailsResponse[0].rankingBuckets);
                    setnoofkeyword(response.data.detailsResponse[0].noOfKeywords);
                    setcompetitoraddNo(response.data.detailsResponse[0].noOfCompetitors)
                    response.data.keywordsResponse.map((item) => {
                        let obj = {
                            category: item.category,
                            keyword: item.keyword,
                            priority: item.priority == 0 ? "No" : "Yes",
                            searchvolume: item.searchVolume,
                            type: getKeywordTypeName(item.type)
                        }
                        listkeyword.push(obj);
                    })
                    setviewkeywordlist(listkeyword);
                    setKeywordTemp(listkeyword);
                    response.data.competitorsResponse.map((item) => {
                        let obj = {
                            id: item.id,
                            competitors: item.competitor,
                        }
                        complist.push(obj);
                    })
                    setviewcompetitorlist(complist);
                    setcompetitorTemp(complist);
                }
            });
    }

    function viewcampaignsave() {
        APIManager.postAPI(
            campaignViewsave + "?campaignViewConfigId=" + props?.campaignviewID
            ,
            null,
            true
        ).then((response) => {
            if (response && response.status == 200) {
                props.dataChild(0);
            }
        });
    }

    const [KeywordTemp, setKeywordTemp] = useState([]);
    function filterDataFromList(textData) {
        setviewkeywordlist(
            KeywordTemp.filter((person) =>
                person.keyword
                    .toLocaleLowerCase()
                    .includes(textData.toLocaleLowerCase())
            )
        );
    }
    const [competitorTemp, setcompetitorTemp] = useState([]);

    const keywordtype = [
        {
            value: "0",
            lable: "Brand",
        },
        {
            value: "1",
            lable: "Non Brand ",
        },
        // {
        //     value: "2",
        //     lable: "All",
        // },
    ]

    function getKeywordTypeName(val) {
        var finalVal = val;
        keywordtype.map((i) => {
            if (val == i.value) {
                finalVal = i.lable;
            }
        });
        return finalVal;
    }

    return (
        <div className='container-fluid ranking-campaign-review'>
            <h1 className='portlet-title main-title my-5'>Review Campaning</h1>
            <div className='portlet'>
                <div className='d-flex justify-content-between mt-4'>
                    <h2 className='portlet-title main-title'>Campaign Details</h2>
                    <spna onClick={() => handleviewcampaignChangeEdit(0)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit edit-icon me-3"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    </spna>
                </div>
                <div className='campaign-details-box'>
                    <div className="d-flex align-items-center">
                        <p>Campaign View Name :</p>
                        <p className='texxt-dark ms-2'> {campingName}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p>Device :</p>
                        <p className='texxt-dark ms-2'> {Device}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p>Ranking Buckets:</p>
                        <p className='texxt-dark ms-2'> {InputFields}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p>No.of Keywords :</p>
                        <p className='texxt-dark ms-2'> {noofkeyword}</p>
                    </div>
                    <div className="d-flex align-items-center">
                        <p className='mb-0'>Competitors Added :</p>
                        <p className='texxt-dark ms-2 mb-0'> {competitoraddNo}</p>
                    </div>
                </div>
            </div>
            <div className='portlet mt-5' >
                <div className='d-flex justify-content-between mt-4'>
                    <h1 className='portlet-title main-title'>Keywords Details:</h1>
                    <span onClick={() => handleviewcampaignChangeEdit(1)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit edit-icon me-3"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    </span>
                </div>
                <div class="d-flex input-group-icon input-group-lg widget15-compact mt-5 mx-3 align-items-center justify-content-between">
                    <div class="d-flex input-group-prepend ">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                    </div>
                    <span>
                        <Input
                            allowClear
                            style={{ padding: 7 + "px" }}
                            type="text"
                            class="form-control"
                            placeholder="Type to search"
                            id="input-s"
                            onChange={(nameSearch) => {
                                filterDataFromList(nameSearch.target.value);
                            }}
                        />
                    </span>

                    <div className="d-flex keyword-points">
                        <span>keyword Added:</span>
                        <p className='fw-bold ms-2 mb-0'>{noofkeyword}</p>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <Table
                        className="table table-bordered table-hover border-0 keyword-detail-table"
                        id="url"
                        columns={columns}
                        dataSource={viewkeywordlist}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                        }}
                    />
                </div>
            </div>
            <div className='portlet mt-5'>
                <div className='d-flex justify-content-between mt-4'>
                    <h1 className='portlet-title main-title'>Competitors Details:</h1>
                    <span onClick={() => handleviewcampaignChangeEdit(2)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit edit-icon me-3"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                    </span>

                </div>
                <div class="input-group-icon input-group-lg widget15-compact mt-5 mx-3 d-flex justify-content-between align-items-center">
                    <div class="input-group-prepend">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-search"><circle cx="11" cy="11" r="8"></circle><line x1="21" y1="21" x2="16.65" y2="16.65"></line></svg>
                    </div>
                    <span>
                        <Input
                            allowClear
                            style={{ padding: 7 + "px" }}
                            type="text"
                            class="form-control"
                            placeholder="Type to search"
                            onChange={(nameSearch) => {
                                setviewcompetitorlist(
                                    competitorTemp.filter((person) =>
                                        person.competitors
                                            .toLocaleLowerCase()
                                            .includes(
                                                nameSearch.target.value.toLocaleLowerCase()
                                            )
                                    )
                                );
                            }}
                            id="input-s"
                        />
                    </span>

                    <div className="d-flex">
                        <span>Competitors Added:</span>
                        <p className='fw-bold ms-2 mb-0'>{competitoraddNo}</p>
                    </div>
                </div>
                <div className="col-md-12 mt-3">
                    <Table
                        className="table table-bordered table-hover border-0 competitors-detail-table"
                        id="url"
                        columns={columnsset}
                        dataSource={viewcompetitorlist}
                        pagination={{
                            position: ["bottomRight"],
                            showSizeChanger: true,
                        }}
                    />
                </div>
            </div>
            <div className='col-md-12 mt-4 text-end pe-0'>
                <button class="btn btn-primary"
                    onClick={() => {
                        viewcampaignsave();
                    }}>Save</button>
            </div>
        </div>
    )
}

export default Createcampaingreview