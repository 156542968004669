import React, { useEffect, useState, useRef } from "react";
import APIManager from "../services/APIManager";
import { getChartData, getGSCProperty } from "../utility/Constant";
import {
  avgOfArrayItems,
  calanderDate,
  calanderDateCompare,
  convertDate,
  fixTwoPlaceDecimal,
  formateDateIntoDatemonth,
  mmDDyyyyFormateDateSet,
  returnMagnitude,
  sumOfArrayItems,
  toMonthName,
} from "../utility/Globals";

import { addDays } from "date-fns";
import { DateRangePicker } from "react-date-range";
import { Card } from "react-bootstrap";
import { DateObject } from "react-multi-date-picker";
import Gacchart from "./common/Gacchart";
import Pagespeedchart from "./common/Pagespeedchart";
import Gachart from "./common/Gachart";
import Clicksharechart from "./common/Clicksharechart";
import RankingsChart from "./common/RankingsChart";

function Dashboard1() {
  useEffect(() => { }, []);

  const [values, setValues] = useState([
    new DateObject().subtract(9, "days"),
    new DateObject().add(-3, "days"),
  ]);
  const [head1, setHead1] = useState(values[0].format());
  const [head2, setHead2] = useState(values[1].format());
  const [isCompare, setIsCompare] = useState(false);

  const applyBtn = useRef(null);
  const applyBtn2 = useRef(null);
  const [series, setSeries] = useState([
    {
      name: "Total Impressions",
      data: [],
      color: "#008FFB",
    },
  ]);
  const [isDateRage, setIsDateRage] = useState(true);

  const [open, setOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (open && ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
      var tag = localStorage.getItem("isCompareVar");
      if (tag == "false") {
        if (document.getElementsByClassName("rdrDateDisplayWrapper").length > 0)
          document
            .getElementsByClassName("rdrDateDisplayWrapper")[0]
            .children[1].classList.add("display-none");
      }
      if (!open) {
        document
          .getElementsByClassName("rdrDefinedRangesWrapper")[0]
          .classList.add("display-none");
      }
    };
  }, [open]);
  const [state, setState] = useState({
    selection1: {
      startDate: addDays(new Date(), -9),
      endDate: addDays(new Date(), -3),
      key: "selection1",
    },
    selection2: {
      startDate: addDays(new Date(), -9),
      endDate: addDays(new Date(), -3),
      key: "selection2",
    },
  });

  function setheadvalues() {
    setHead1(values[0].format());
    setHead2(values[1].format());
  }

  const colourOptions1 = [
    { value: "FCP", label: "FCP" },
    { value: "FID", label: "FID" },
    { value: "LCP", label: "LCP" },
    { value: "CLS", label: "CLS" },
    { value: "PerformanceScore", label: "Score" },
  ];

  const [isChart, setIsChart] = useState(0);
  function handleClickChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }
  const [selectedDeviceType, setDeviceType] = useState("1");
  const [isgaChart, setIsgaChart] = useState(0);

  return (
    <>
      <div class="row">
        <div class="header-holder header-holder-desktop mb-4 portlet d-flex">
          <div class="header-container container-fluid ps-3 w-100 d-flex justify-content-between">
            <h4 class="header-title">Dashboard</h4>
            <div className="add-new-btnw">
              <button
                className="btn btn-primary"
                style={{ width: "250px" }}
                onClick={() => setOpen(!open)}
              >
                {!isCompare
                  ? calanderDate(
                    state.selection1?.startDate,
                    state.selection1?.endDate
                  )
                  : calanderDateCompare(
                    state.selection1?.startDate,
                    state.selection1?.endDate,
                    state.selection2?.startDate,
                    state.selection2?.endDate
                  )}
              </button>
            </div>
          </div>
          <div></div>
        </div>
        <div class="d-flex">
          {open && (
            <div id="example-collapse-text-calendar">
              <Card body className="daterange-picker-card mt-2">
                <div className="d-flex calendar-flex">
                  <div className="calendar-col">
                    <DateRangePicker
                      onChange={(item) => {
                        let isCompareVarNew =
                          localStorage.getItem("isCompareVar");
                        if (isCompareVarNew == "false") {
                          let selection23 = {};
                          if (item?.selection1) {
                            selection23 = {
                              selection2: {
                                startDate: item?.selection1?.startDate,
                                endDate: item?.selection1?.endDate,
                                key: "selection2",
                              },
                            };
                          } else if (item?.selection2) {
                            selection23 = {
                              selection1: {
                                startDate: item?.selection2?.startDate,
                                endDate: item?.selection2?.endDate,
                                key: "selection1",
                              },
                            };
                          }
                          setState({
                            ...state,
                            ...item,
                            ...selection23,
                          });
                        }

                        if (isCompareVarNew == "true") {
                          setState({ ...state, ...item });
                        }
                      }}
                      showSelectionPreview={true}
                      moveRangeOnFirstSelection={false}
                      months={1}
                      ranges={[state?.selection1, state?.selection2]}
                      direction="horizontal"
                      ariaLabels={{
                        dateInput: {
                          selection1: {
                            startDate: "start date input of selction 1",
                            endDate: "end date input of selction 1",
                          },
                          selection2: {
                            startDate: "start date input of selction 2",
                            endDate: "end date input of selction 2",
                          },
                        },

                        monthPicker: "month picker",
                        yearPicker: "year picker",
                        prevButton: "previous month button",
                        nextButton: "next month button",
                      }}
                      maxDate={
                        new Date(
                          new Date().getFullYear(),
                          new Date().getMonth(),
                          new Date().getDate() - 3
                        )
                      }
                    />
                  </div>
                  <div className="mt-3 mt-lg-0 text-center">
                    <label className="mt-10 d-flex ms-3">
                      <input
                        className="me-3"
                        checked={isCompare}
                        onChange={(e) => {
                          localStorage.setItem(
                            "isCompareVar",
                            e.target.checked
                          );
                          setIsCompare(e.target.checked);
                          if (!e.target.checked) {
                            document.getElementsByClassName(
                              "rdrDateDisplayWrapper"
                            )[0].children[1].style.display = "none";
                            setState({
                              selection1: {
                                startDate: state.selection1?.startDate,
                                endDate: state.selection1?.endDate,
                                key: "selection1",
                              },
                              selection2: {
                                startDate: state.selection1?.startDate,
                                endDate: state.selection1?.endDate,
                                key: "selection2",
                              },
                            });
                          } else {
                            document.getElementsByClassName(
                              "rdrDateDisplayWrapper"
                            )[0].children[1].style.display = "flex";
                            setState({
                              selection1: {
                                startDate: state.selection1?.startDate,
                                endDate: state.selection1?.endDate,
                                key: "selection1",
                              },
                              selection2: {
                                startDate: addDays(new Date(), -9),
                                endDate: addDays(new Date(), -3),
                                key: "selection2",
                              },
                            });
                          }
                        }}
                        type="checkbox"
                      />
                      Compare
                    </label>
                    <button
                      onClick={() => {
                        setheadvalues();
                        setOpen(!open);
                        applyBtn2.current.click();

                        if (
                          convertDate(state.selection1.startDate) ==
                          convertDate(state.selection2.startDate) &&
                          convertDate(state.selection1.endDate) ==
                          convertDate(state.selection2.endDate)
                        ) {
                          setIsDateRage(true);
                        }
                      }}
                      className="btn btn-primary ms-3"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="d-flex">
          <div className="d-flex">
            <div className="portlet-header portlet-header-bordered text-start"></div>
            <div>
              {isgaChart == 0 ? (
                <select
                  style={{ padding: 8 + "px" }}
                  className="w-100"
                  value={selectedDeviceType?.value}
                  onChange={(e) => {
                    let selected = {
                      label: e.target.value,
                      value: e.target.value,
                    };
                    setDeviceType(selected.label);
                  }}
                >
                </select>
              ) : (
                <></>
              )}
            </div>
            <div>
              <select
                style={{ padding: 8 + "px" }}
                className="w-100"
                value={selectedDeviceType}
                onChange={(e) => {
                  setDeviceType(e.target.value);
                }}
              >
                <option value="0">mobile</option>
                <option value="1">desktop</option>
              </select>
            </div>
          </div>
        </div>

        <div className="mt-3 round-chart">
        </div>
      </div>
      <div className="row">
        <div className="">
          <div className="d-flex">


            <div>
              <div class="all-ticket-bread-crumb p-0 overview-details">
                <ul class="d-flex align-items-center">
                  <li
                    className={`${isChart == 0 ? "active" : ""}`}
                    onClick={() => {
                      handleClickChart(0);
                    }}
                  >
                    <span>
                      <i class="fa fa-line-chart" aria-hidden="true"></i>
                    </span>
                  </li>
                  <li
                    className={`${isChart == 1 ? "active" : ""}`}
                    onClick={() => {
                      handleClickChart(1);
                    }}
                  >
                    <span>
                      <i class="fa fa-table" aria-hidden="true"></i>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Pagespeedchart
        ></Pagespeedchart>
      </div>
      <div className="row">
        <div className="px-0 Audit-chart">
          <div className="text-center progress-circle">
            <div className="portlet">
              <Gacchart ></Gacchart>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="px-0 Audit-chart">
          <div className="text-center progress-circle">
            <div className="portlet">
              <Gachart></Gachart>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="px-0 Audit-chart">
          <div className="text-center progress-circle">
            <div className="portlet">
              <RankingsChart />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="px-0 Audit-chart">
          <div className="text-center progress-circle">
            <div className="portlet">
              <Clicksharechart></Clicksharechart>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Dashboard1;
