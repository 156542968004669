import React from "react";
import APIManager from "../../services/APIManager";
import { useEffect } from "react";
import { useState } from "react";
import {
  getRoiDashboardChart,
  getDashboardROIChartData,
  getCompareChartDataROI,
  getTableDataROI,
} from "../../utility/Constant";
import {
  returnMagnitude,
  setMonths,
  getEstimatedClicksColor,
  fixTwoPlaceDecimal,
  mmDDyyyyFormateDateSet,
  getClassName,
  compareValues,
  formatRankDate,
  toMonthName,
} from "../../utility/Globals";
import { Sorter } from "../../common/Sorter";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";

const Roidashboard = (props) => {
  const [startDate, setstartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [compareStartDate, setcompareStartDate] = useState("");
  const [compareendDate, setcompareendDate] = useState("");
  const [listUrltbl, setlistUrltbl] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    setstartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.startDate)
    );
    setendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection1?.endDate)
    );
    setcompareStartDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.startDate)
    );
    setcompareendDate(
      mmDDyyyyFormateDateSet(props?.dataParentToChild?.selection2?.endDate)
    );

    let rowCompareChartData = {
      endDate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection1?.endDate
      ),
      projectId: localStorage.getItem("projectGId"),
      startDate: mmDDyyyyFormateDateSet(
        props?.dataParentToChild?.selection1?.startDate
      ),
    };

    let rowChartData = {
      projectId: localStorage.getItem("projectGId"),
      type: selectDataType,
      ROIDateRange: {
        from: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.startDate
        ),
        to: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.endDate
        ),
      },
      CompareROIDateRange: {
        from: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.startDate
        ),
        to: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.endDate
        ),
      },
    };

    let rowdata1 = {
      ProjectId: localStorage.getItem("projectGId"),
      ROIDateRange: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection1?.endDate
        ),
      },
      CompareROIDateRange: {
        FROM: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.startDate
        ),
        TO: mmDDyyyyFormateDateSet(
          props?.dataParentToChild?.selection2?.endDate
        ),
      },
    };
    getChartData(rowChartData);
    getRoidashboard(rowdata1);

  }, [props?.dataParentToChild]);

  useEffect(() => {
    setIsChart(props?.IsSeoChart);
  }, [props?.IsSeoChart]);

  useEffect(() => {
    localStorage.setItem("selectDataType", "ROI");
  }, []);

  const [options11, setObject11] = useState([]);
  const [cpcListData, setCPClist] = useState([]);
  const [cplListData, setCPLlist] = useState([]);
  const [cpsListData, setCPSlist] = useState([]);
  const [rolListData, setROIlist] = useState([]);
  const [cpcListData1, setCPClist1] = useState([]);
  const [cplListData1, setCPLlist1] = useState([]);
  const [cpsListData1, setCPSlist1] = useState([]);
  const [rolListData1, setROIlist1] = useState([]);
  const [selectDataType, setSelectdataType] = useState("ROI");
  const [cpcListDataCompTotal, setCPClistCompTotal] = useState(0);
  const [rolListDataCompTotal, setROIlistCompTotal] = useState(0);
  const [cplListDataCompTotal, setCPLlistCompTotal] = useState(0);
  const [cpslistCompTotal, setCPSlistCompTotal] = useState(0);
  const [cpcListDataAVG, setCPClistAVG] = useState(0);
  const [cplListDataAVG, setCPLlistAVG] = useState(0);
  const [cpsListDataAVG, setCPSlistAVG] = useState(0);
  const [rolListDataAVG, setROIlistAVG] = useState(0);
  const [cpcListDataAVG1, setCPClistAVG1] = useState(0);
  const [cplListDataAVG1, setCPLlistAVG1] = useState(0);
  const [cpsListDataAVG1, setCPSlistAVG1] = useState(0);
  const [rolListDataAVG1, setROIlistAVG1] = useState(0);
  const [isChart, setIsChart] = useState(0);

  function handleClickChart(type) {
    if (isChart != type) {
      setIsChart(type);
    }
  }
 
  const [series1, setSeries1] = useState([]);
  const [optionsChart, setoptionsChart] = useState({});
  const [cpcListDataComp, setCPClistComp] = useState([]);
  const [rolListDataComp, setROIlistComp] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [CPC, setCPC] = useState(0);
  const [CPL, setCPL] = useState(0);
  const [CPS, setCPS] = useState(0);
  const [ROI, setROI] = useState(0);
  const [CPCComp, setCPCComp] = useState(0);
  const [CPLComp, setCPLComp] = useState(0);
  const [CPSComp, setCPSComp] = useState(0);
  const [ROIComp, setROIComp] = useState(0);
  const [monthCPC, setMonthCPC] = useState(0);
  const [monthCPS, setMonthCPS] = useState(0);
  const [monthCPL, setMonthCPL] = useState(0);
  const [monthROI, setMonthROI] = useState(0);
  const [monthCPCCompare, setmonthCPCCompare] = useState(0);
  const [monthCPSCompare, setmonthCPSCompare] = useState(0);
  const [monthCPLCompare, setmonthCPLCompare] = useState(0);
  const [monthROICompare, setmonthROICompare] = useState(0);
  const [diffCPC, setdiffCPC] = useState(0);
  const [diffCPL, setdiffCPL] = useState(0);
  const [diffCPS, setdiffCPS] = useState(0);
  const [diffROI, setdiffROI] = useState(0);

  const [CPCComparechk, setCPCComparechk] = useState(0);
  const [CPSComparechk, setCPSComparechk] = useState(0);
  const [CPLComparechk, setCPLComparechk] = useState(0);
  const [ROIComparechk, setROIComparechk] = useState(0);

  const [diffROIchk, setdiffROIchk] = useState(0);
  const [diffCPCchk, setdiffCPCchk] = useState(0);
  const [diffCPSchk, setdiffCPSchk] = useState(0);
  const [diffCPLchk, setdiffCPLchk] = useState(0);
  const [options1, setObject1] = useState({
    title: {
      text: "",
      align: "left",
    },
    chart: {
      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          customIcons: [],
        },
      },
    },
    dataLabels: {
      formatter: () => {
        return "";
      },
    },
    grid: {
      row: {
        colors: ["#f3f3f3", "transparent"],
      },
    },
    xaxis: {
      tickPlacement: "on",
      categories: [],
      title: {
        text: "Day",
        style: {
          color: "#000",
        },
      },
    },
    yaxis: {
      logBase: 0,
      tickAmount: 9,
      min: 0,
    },
  });

  function setChartData(rolListData, cpcListData, cpsListData, cplListData, rolListData1, cpcListData1, cpsListData1, cplListData1) {
    let chartOBJ = [];
    let tableColsList = [];
    let selectDataType = localStorage.getItem("selectDataType");
    if (selectDataType == "ROI") {
      chartOBJ.push({
        name: "ROI",
        data: rolListData,
        color: "#91E395",
      });
      chartOBJ.push({
        name: "ROI",
        data: rolListData1,
        color: "#5EB1F3",
      });
    }
    if (selectDataType == "CPC") {
      chartOBJ.push({
        name: "CPC",
        data: cpcListData,
        color: "#91E395",
      });
      chartOBJ.push({
        name: "CPC",
        data: cpcListData1,
        color: "#5EB1F3",
      });
    }
    if (selectDataType == "CPS") {
      chartOBJ.push({
        name: "CPS",
        data: cpsListData,
        color: "#91E395",
      });
      chartOBJ.push({
        name: "CPS",
        data: cpsListData1,
        color: "#5EB1F3",
      });
    }
    if (selectDataType == "CPL") {
      chartOBJ.push({
        name: "CPL",
        data: cplListData,
        color: "#91E395",
      });
      chartOBJ.push({
        name: "CPL",
        data: cplListData1,
        color: "#5EB1F3",
      });
    }
    setSeries1(chartOBJ);   
    setTableColumns(tableColsList);
  }

  function getroiColorClassName(i) {
    if (i == 2) {
      return "avg-val";
    } else if (i == 1) {
      return "avg-val-positive";
    } else if (i == 0) {
      return "avg-val-negative";
    }
  }

  function getRoidashboard(rowdata1) {
    APIManager.postAPI(getRoiDashboardChart, rowdata1, null, true).then(
      (response) => {
        if (
          response &&
          response.status == 200 &&
          response.data != "No Content Found"
        ) {
          let cpc = 0;
          let cpl = 0;
          let cps = 0;
          let roi = 0;
          let cpcCompare = 0;
          let cplCompare = 0;
          let cpsCompare = 0;
          let roiCompare = 0;
          let monthcpc = 0;
          let monthcpl = 0;
          let monthcps = 0;
          let monthroi = 0;
          let monthcpcCompare = 0;
          let monthcplCompare = 0;
          let monthcpsCompare = 0;
          let monthroiCompare = 0;


          response?.data?.table?.map((item) => {
            cpc += item?.cpc;
            cpl += item?.cpl;
            cps += item?.cps;
            roi += item?.roi;
          });
          setCPC(fixTwoPlaceDecimal(cpc));
          setCPL(fixTwoPlaceDecimal(cpl));
          setCPS(fixTwoPlaceDecimal(cps));
          setROI(fixTwoPlaceDecimal(roi));
          setMonthCPC(fixTwoPlaceDecimal(monthcpc));
          setMonthCPS(fixTwoPlaceDecimal(monthcps));
          setMonthCPL(fixTwoPlaceDecimal(monthcpl));
          setMonthROI(fixTwoPlaceDecimal(monthroi));

          response?.data?.table1?.map((item) => {
            cpcCompare += item?.cpcCompare;
            cplCompare += item?.cplCompare;
            cpsCompare += item?.cpsCompare;
            roiCompare += item?.roiCompare;
          });

          setCPCComp(fixTwoPlaceDecimal(cpcCompare));
          setCPLComp(fixTwoPlaceDecimal(cplCompare));
          setCPSComp(fixTwoPlaceDecimal(cpsCompare));
          setROIComp(fixTwoPlaceDecimal(roiCompare));
          setmonthCPCCompare(fixTwoPlaceDecimal(monthcpcCompare));
          setmonthCPSCompare(fixTwoPlaceDecimal(monthcpsCompare));
          setmonthCPLCompare(fixTwoPlaceDecimal(monthcplCompare));
          setmonthROICompare(fixTwoPlaceDecimal(monthroiCompare));

          setCPCComparechk(compareValues(cpc, cpcCompare));
          setCPSComparechk(compareValues(cps, cpsCompare));
          setCPLComparechk(compareValues(cpl, cplCompare));
          setROIComparechk(compareValues(roi, roiCompare));

          let diffRoi =
            roi < roiCompare
              ? (100 * (roiCompare - roi)) / roiCompare
              : (100 * (roi - roiCompare)) / roi;

          let diffCpc =
            cpc < cpcCompare
              ? (100 * (cpcCompare - cpc)) / cpcCompare
              : (100 * (cpc - cpcCompare)) / cpc;

          let diffCps =
            cps < cpsCompare
              ? (100 * (cpsCompare - cps)) / cpsCompare
              : (100 * (cps - cpsCompare)) / cps;

          let diffCpl =
            cpl < cplCompare
              ? (100 * (cplCompare - cpl)) / cplCompare
              : (100 * (cpl - cplCompare)) / cpl;

          setdiffCPC(diffCpc.toFixed(2));
          setdiffCPL(diffCpl.toFixed(2));
          setdiffCPS(diffCps.toFixed(2));
          setdiffROI(diffROI < 0 ? diffROI.toFixed(2) : 0);

          setdiffCPLchk(compareValues(diffCpl, cpl));
          setdiffCPCchk(compareValues(diffCpc, cpc));
          setdiffCPSchk(compareValues(diffCps, cps));
          setdiffROIchk(compareValues(diffRoi, roi));
        }
      }
    );
  }

  function getCompareChartData(rowCompareChartData) {
    APIManager.postAPI(
      getCompareChartDataROI,
      rowCompareChartData,
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        let monthsList = [];
        let organicCPCList = [];
        let paidCPCList = [];
        let organicCPCLTotal = 0;
        let organicCPLTotal = 0;
        let paidCPCLTotal = 0;
        let paidCPSLTotal = 0;
        response.data.map((item) => {
          monthsList.push(setMonths(item?.month));
          organicCPCList.push(item?.organicCPC);
          paidCPCList.push(item?.paidCPC);
          organicCPCLTotal = organicCPCLTotal + item?.organicCPC;
          paidCPCLTotal = paidCPCLTotal + item?.paidCPC;
        });
        setObject11(monthsList);
        setCPClistComp(organicCPCList);
        setROIlistComp(paidCPCList);
        setCPClistCompTotal(
          response.data.length != 0
            ? organicCPCLTotal / response.data.length
            : 0
        );
        setROIlistCompTotal(
          response.data.length != 0 ? paidCPCLTotal / response.data.length : 0
        );
        setCPLlistCompTotal(
          response.data.length != 0
            ? organicCPLTotal / response.data.length
            : 0
        );
        setCPSlistCompTotal(
          response.data.length != 0 ? paidCPSLTotal / response.data.length : 0
        );
      }
    });
  }

  function getChartData(rowChartData) {
    APIManager.postAPI(getDashboardROIChartData, rowChartData, null, true).then(
      (response) => {
        if (response && response.status == 200) {
          let monthsList = [];
          let monthsList1 = [];

          let cpcList = [];
          let cplList = [];
          let cpsList = [];
          let rolList = [];
          let cpcListData = 0;
          let cplListData = 0;
          let cpsListData = 0;
          let rolListData = 0;
          let cpcList1 = [];
          let cplList1 = [];
          let cpsList1 = [];
          let rolList1 = [];
          let cpcListData1 = 0;
          let cplListData1 = 0;
          let cpsListData1 = 0;
          let rolListData1 = 0;

          response?.data?.table?.map((item) => {
            monthsList.push(item?.month);
            cpcList.push(item?.cpc);
            cplList.push(item?.cpl);
            cpsList.push(item?.cps);
            rolList.push(item?.roi);
            rolListData = rolListData + item?.roi;
            cpsListData = cpsListData + item?.cps;
            cpcListData = cpcListData + item?.cpc;
            cplListData = cplListData + item?.cpl;
          });

          response?.data?.table1?.map((item) => {
            monthsList1.push(item?.month);
            cpcList1.push(item?.cpc);
            cplList1.push(item?.cpl);
            cpsList1.push(item?.cps);
            rolList1.push(item?.roi);
            rolListData1 = rolListData1 + item?.roi;
            cpsListData1 = cpsListData1 + item?.cps;
            cpcListData1 = cpcListData1 + item?.cpc;
            cplListData1 = cplListData1 + item?.cpl;
          });
          setCPClist(cpcList);
          setCPLlist(cplList);
          setCPSlist(cpsList);
          setROIlist(rolList);
          setCPClist1(cpcList1);
          setCPLlist1(cplList1);
          setCPSlist1(cpsList1);
          setROIlist1(rolList1);

          setObject1({
            title: {
              text: "",
              align: "left",
            },
            chart: {
              toolbar: {
                show: false,
                offsetX: 0,
                offsetY: 0,
                tools: {
                  download: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  customIcons: [],
                },
              },
            },
            dataLabels: {
              formatter: () => {
                return "";
              },
              enable: false,
            },
            legend: {
              show: false,
            },
            grid: {
              row: {
                colors: ["#f3f3f3", "transparent"],
              },
            },
            xaxis: {
              tickPlacement: "on",
              // categories: monthsList,
              title: {
                text: "Month",
                style: {
                  color: "#000",
                },
              },
            },
            markers: {
              size: 5,
            },
            yaxis: {
              logBase: 0,
              tickAmount: 9,
              min: 0,
              max: (max) => {
                return max * 0.3 + max;
              },
              labels: {
                formatter: function (val) {
                  return returnMagnitude(val);
                },
              },
            },
            // tooltip: {
            //   y: {
            //     formatter: function (value) {
            //       return value;
            //     },
            //   },
            // },
            tooltip: {
              // shared: true,
              // intersect: false,
              custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                let date = "-";
                const hoverXaxis =
                  w.globals.seriesX[seriesIndex][dataPointIndex];
                const hoverIndexes = w.globals.seriesX.map((seriesX) => {
                  return seriesX.findIndex((xData) => xData === hoverXaxis);
                });

                let hoverList = "";
                hoverIndexes.forEach((hoverIndex, seriesEachIndex) => {
                  if (seriesEachIndex == 0) {                    
                      monthsList.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = item;
                        }
                      });              

                    if (
                      hoverIndex >= 0 &&
                      series[seriesEachIndex][hoverIndex] != undefined
                    ) {
                      hoverList += `<span>${toMonthName(date,"Months")}<br /><span class="apexcharts-legend-marker" rel="1" data:collapsed="false" style="background: rgb(0, 143, 251) !important; color: rgb(0, 143, 251); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  } else {                   
                      monthsList1.map((item, index) => {
                        if (dataPointIndex == index) {
                          date = item;
                        }
                      });                    

                    if (
                      hoverIndex >= 0 &&
                      series[seriesEachIndex][hoverIndex] != undefined
                    ) {
                      hoverList += `<span>${toMonthName(date,"Months")}<br /><span class="apexcharts-legend-marker" rel="2" data:collapsed="false" style="background: rgb(0, 227, 150) !important; color: rgb(0, 227, 150); height: 12px; width: 12px; left: 0px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;"></span> ${w.globals.seriesNames[seriesEachIndex]} : ${series[seriesEachIndex][hoverIndex]}</span><br />`;
                    }
                  }
                });
                const formatHoverX = hoverXaxis;

                return `<div class="card">
                  
                  <div class="card-body p-10">
                    ${hoverList}
                  </div>
                </div>`;
              },
            },
            
          });

          if (response.data?.table.length > 0) {
            setCPClistAVG(cpcListData / response.data?.table.length);
            setCPLlistAVG(cplListData / response.data?.table.length);
            setCPSlistAVG(cpsListData / response.data?.table.length);
            setROIlistAVG(rolListData / response.data?.table.length);
          } else {
            setCPClistAVG(0);
            setCPSlistAVG(0);
            setCPLlistAVG(0);
            setROIlistAVG(0);
          }

          if (response.data?.table1.length > 0) {
            setCPClistAVG1(cpcListData1 / response.data?.table1.length);
            setCPLlistAVG1(cplListData1 / response.data?.table1.length);
            setCPSlistAVG1(cpsListData1 / response.data?.table1.length);
            setROIlistAVG1(rolListData1 / response.data?.table1.length);
          } else {
            setCPClistAVG1(0);
            setCPSlistAVG1(0);
            setCPLlistAVG1(0);
            setROIlistAVG1(0);
          }

          let selectDataType = localStorage.getItem("selectDataType");
          if (selectDataType == "ROI") {
            setChartData(rolList,[],[],[], rolList1, [], [], []);
          } else if (selectDataType == "CPC") {
            setChartData([], cpcList,[],[],[], cpcList1, [], []);
          } else if (selectDataType == "CPS") {
            setChartData([], [], cpsList,[],[],[], cpsList1, []);
          } else if (selectDataType == "CPL") {
            setChartData([], [], [], cplList,[],[],[], cplList1);
          }
          else {
            setChartData(rolList, cpcList, cpsList, cplList,[],[],[],[]);
          }
        } else {
          setCPClist([]);
          setCPLlist([]);
          setCPSlist([]);
          setROIlist([]);
          setCPClist1([]);
          setCPLlist1([]);
          setCPSlist1([]);
          setROIlist1([]);
          setSeries1([]);
          setChartData([])
        }
      }
    );
  }

  return (
    <>
      <div className="title pb-4">
        <div className="d-flex justify-content-between align-items-center roi-responsive ">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="me-3">Return on Investment</h4>
          </div>

          <div className="d-flex justify-content-between align-items-center roi-btn-tab">
            <div className="mx-2">
              {isChart == 0 ? (
                <select
                  className="form-select"
                  value={selectDataType}
                  onChange={(e) => {
                    setSelectdataType(e.target.value);
                    localStorage.setItem("selectDataType", e.target.value);               
                    let rowChartData = {
                      projectId: localStorage.getItem("projectGId"),
                      type: e.target.value,
                      ROIDateRange: {
                        from: mmDDyyyyFormateDateSet(
                          props?.dataParentToChild?.selection1?.startDate
                        ),
                        to: mmDDyyyyFormateDateSet(
                          props?.dataParentToChild?.selection1?.endDate
                        ),
                      },
                      CompareROIDateRange: {
                        from: mmDDyyyyFormateDateSet(
                          props?.dataParentToChild?.selection2?.startDate
                        ),
                        to: mmDDyyyyFormateDateSet(
                          props?.dataParentToChild?.selection2?.endDate
                        ),
                      },

                    };
                    getChartData(rowChartData);
                  }}
                >
                  <option value="ROI">ROI</option>
                  <option value="CPC">CPC</option>
                  <option value="CPS">CPS</option>
                  <option value="CPL">CPL</option>
                </select>
              ) : (
                <></>
              )}
            </div>
            <div className="btn-box">
              <div className="d-flex align-items-center">
                <button
                  className={`${isChart == 0 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(0);
                  }}
                >
                  <img
                    src={`${isChart == 0
                      ? "/images/graph-icon-white.svg"
                      : "/images/graph-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
                <button
                  className={`${isChart == 1 ? "active" : ""}`}
                  onClick={() => {
                    handleClickChart(1);
                  }}
                >
                  <img
                    src={`${isChart == 1
                      ? "/images/table-icon-white.svg"
                      : "/images/table-icon-black.svg"
                      }`}
                    alt=""
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" my-3">
        {isChart == 0 ? (
          <>
            {" "}
            <Chart
              options={options1}
              series={series1}
              type="line"
              height="300"
            />
            <div className="legend-box d-flex align-items-center justify-content-center w-100 mt-2">
              <div className="d-flex align-items-center">
                <div className="legend-dot blue"></div>
                <div className="ml-2">{formatRankDate(startDate)}</div>
              </div>
              <div className="d-flex align-items-center ms-5">
                <div className="legend-dot"></div>
                <div className="ml-2">{formatRankDate(compareStartDate)}</div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="col-md-12 text-right backlink-daterange">
              Date Range : {formatRankDate(startDate)} -{" "}
              {formatRankDate(compareStartDate)}
            </div>

            <div className="row roidashboard-table">
              <div className="col-md-3 m-0 p-0">
                <div className=" roi mt-3 me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>ROI</h4>
                    <div className="grren-domain text-right">
                      {ROIComparechk != 0 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>

                  <h3 className={getroiColorClassName(ROIComparechk)}>
                    {diffROI > 0 ? diffROI : 0}%
                  </h3>

                  <div className="row mt-3">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-md-6 p-0">
                        <p>{ROIComp}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>

                      <div className="col-md-6 roi-total-compare">
                        <p>{ROI}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 m-0 p-0">
                <div className=" roi mt-3 me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>CPL</h4>
                    <div className="grren-domain text-right">
                      {CPLComparechk != 0 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>

                  <h3 className={getroiColorClassName(CPLComparechk)}>
                    {diffCPL > 0 ? diffCPL : 0}%
                  </h3>

                  <div className="row mt-3">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-md-6 p-0">
                        <p>{CPLComp}</p>
                        <span>{formatRankDate(startDate)}</span>
                      </div>
                      <div className="col-md-6 roi-total-compare">
                        <p>{CPL}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 m-0 p-0">
                <div className=" roi mt-3 me-2">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>CPS</h4>
                    <div className="grren-domain text-right">
                      {CPSComparechk != 0 ? (
                        <>
                          <svg
                            width="35"
                            height="34"
                            viewBox="0 0 35 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                            <g clip-path="url(#clip0_4_4546)">
                              <path
                                d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.667 12H26.667V17"
                                stroke="#59D866"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4546">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="translate(7.5 7)"
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            width="34"
                            height="34"
                            viewBox="0 0 34 34"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <circle
                              r="17"
                              transform="matrix(1 0 0 -1 17 17)"
                              fill="#FFC0C2"
                            />
                            <g clip-path="url(#clip0_4_4443)">
                              <path
                                d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M21.167 22H26.167V17"
                                stroke="#F64F53"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_4_4443">
                                <rect
                                  width="20"
                                  height="20"
                                  fill="white"
                                  transform="matrix(1 0 0 -1 7 27)"
                                />
                              </clipPath>{" "}
                            </defs>
                          </svg>
                        </>
                      )}
                    </div>
                  </div>
                  <h3 className={getroiColorClassName(CPSComparechk)}>
                    {diffCPS > 0 ? diffCPS : 0}%
                  </h3>

                  <div className="row mt-3">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-md-6 p-0">
                        <p>{CPSComp}</p>
                        <span> {formatRankDate(startDate)} </span>
                      </div>

                      <div className="col-md-6 roi-total-compare">
                        <p>{CPS}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3 m-0 p-0 ">
                <div className=" roi mt-3">
                  <div className="d-flex align-items-center justify-content-between ">
                    <h4>CPC</h4>
                    <div className="grren-domain text-right">
                      <div className="grren-domain text-right">
                        {CPCComparechk != 0 ? (
                          <>
                            <svg
                              width="35"
                              height="34"
                              viewBox="0 0 35 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="17.5" cy="17" r="17" fill="#D9FFDB" />
                              <g clip-path="url(#clip0_4_4546)">
                                <path
                                  d="M26.6663 12L18.7497 19.9167L14.583 15.75L8.33301 22"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.667 12H26.667V17"
                                  stroke="#59D866"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4546">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="translate(7.5 7)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          </>
                        ) : (
                          <>
                            <svg
                              width="34"
                              height="34"
                              viewBox="0 0 34 34"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                r="17"
                                transform="matrix(1 0 0 -1 17 17)"
                                fill="#FFC0C2"
                              />
                              <g clip-path="url(#clip0_4_4443)">
                                <path
                                  d="M26.1663 22L18.2497 14.0833L14.083 18.25L7.83301 12"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M21.167 22H26.167V17"
                                  stroke="#F64F53"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_4_4443">
                                  <rect
                                    width="20"
                                    height="20"
                                    fill="white"
                                    transform="matrix(1 0 0 -1 7 27)"
                                  />
                                </clipPath>{" "}
                              </defs>
                            </svg>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <h3 className={getroiColorClassName(CPCComparechk)}>
                    {diffCPC > 0 ? diffCPC : 0}%
                  </h3>

                  <div className="row mt-3">
                    <div className="d-flex align-items-center justify-content-between p-0">
                      <div className="col-md-6 p-0">
                        <p>{CPCComp}</p>
                        <span> {formatRankDate(startDate)} </span>
                      </div>

                      <div className="col-md-6 roi-total-compare">
                        <p>{CPC}</p>
                        <span>{formatRankDate(compareStartDate)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Roidashboard;
