
import axios from "axios";
import { NavLink, useHistory } from "react-router-dom";
import { Switch } from "react-router-dom";
import { Drawer } from "antd";
import TopNavBarCustom from "../TopNavBar";
import { useState, useEffect } from "react";
import RouteWithSubRoutes from "../../utils/RouteWithSubRoutes";

axios.interceptors.request.use(
    function (response) {
        var element = document.getElementById("loader-main");
        if (element) element.classList.remove("d-none");
        return response;
    },
    function (error) {
        var element = document.getElementById("loader-main");
        if (element) element.classList.add("d-none");
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    function (response) {
        var element = document.getElementById("loader-main");
        if (element) element.classList.add("d-none");
        return response;
    },
    function (error) {
        var element = document.getElementById("loader-main");
        if (element) element.classList.add("d-none");
        return error;
    }
);

const ContentMain = ({ routes }) => {
    const history = useHistory();

    const [view, setview] = useState(true);
    const [contentExpand, setContentExpand] = useState(true);
    const [color, setcolor] = useState(false);
    const [active, setActive] = useState(0);
    useEffect(() => {
        if (localStorage.getItem("iscontentview")) {
            setview(true);
            setContentExpand(true)
        }
    }, []);

    function clodeSideNav() {
        document.querySelector("body").classList.remove("aside-mobile-maximized");
        document.querySelector("body").classList.add("aside-mobile-minimized");
        document.getElementById("aside-backdrop")?.remove();
    }


    function handleClick(val) {
        if (active != val) {
            setActive(val);
        }
    }


    return (
        <>
            <div className="home">{view}
                <div className={`aside ${view ? ' content-aside' : ''}`}>
                    <div class="aside-header d-flex justify-content-between">
                        <NavLink to="/main/dashboard">
                            {/* <div className="aside-title aside-logo"></div> */}
                            <div className="aside-title logo-text">InfiGrowth</div>
                        </NavLink>
                        {view ? (<i class="fa fa-angle-left back-arrow"
                            onClick={() => {
                                history.push("/main/dashboard");
                                setview(false);
                                localStorage.removeItem("iscontentview");
                            }}></i>) : (<></>)}
                        <div class="aside-addon">
                            <button
                                class="btn btn-label-primary btn-icon btn-lg"
                                data-toggle="aside"
                            >
                                <i
                                    class="fa fa-angle-left aside-icon-minimize"
                                    aria-hidden="true"
                                ></i>
                                <i
                                    class="fa fa-angle-right aside-icon-maximize"
                                    aria-hidden="true"
                                ></i>
                            </button>
                        </div>
                    </div>
                    <div className="aside-body" data-simplebar="data-simplebar">
                        {localStorage.getItem("userDatadepartmentName") == "Content" ? (<>
                            <div className="menu">
                                <>
                                    <ul className="p-0">
                                        <li className="menu-item">
                                            <button class="menu-item-link menu-item-toggle">
                                                <a
                                                    className="menu-item-link w-100"
                                                    onClick={() => {
                                                        setcolor(true);
                                                        setContentExpand(true);
                                                        setview(true);
                                                        localStorage.setItem("iscontentview", true);
                                                        history.push("/content-main/dashboardContent");
                                                    }}
                                                >
                                                    <div class="menu-item-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-home"><path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path><polyline points="9 22 9 12 15 12 15 22"></polyline></svg>
                                                        {/* <svg className="t-icon-white" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_5031_5584)">
                                                                <path d="M16.6665 10.0007C16.6665 9.36732 17.0248 8.81732 17.5498 8.53398C18.0498 8.25898 18.3332 7.69232 18.3332 7.11732V5.00065C18.3332 4.08398 17.5832 3.33398 16.6665 3.33398H3.33317C2.4165 3.33398 1.67484 4.07565 1.67484 4.99232V7.11732C1.67484 7.69232 1.94984 8.25898 2.45817 8.52565C2.98317 8.81732 3.33317 9.36732 3.33317 10.0007C3.33317 10.634 2.97484 11.1923 2.44984 11.4673C1.94984 11.7423 1.6665 12.309 1.6665 12.884V15.0007C1.6665 15.9173 2.4165 16.6673 3.33317 16.6673H16.6665C17.5832 16.6673 18.3332 15.9173 18.3332 15.0007V12.884C18.3332 12.309 18.0498 11.7423 17.5498 11.4673C17.0248 11.184 16.6665 10.634 16.6665 10.0007ZM12.0832 13.4173L9.99984 12.084L7.9165 13.4257C7.59984 13.6257 7.1915 13.334 7.2915 12.9673L7.9165 10.5673L5.99984 9.00065C5.70817 8.75898 5.85817 8.28398 6.2415 8.25898L8.70817 8.11732L9.60817 5.82565C9.74984 5.47565 10.2498 5.47565 10.3832 5.82565L11.2832 8.12565L13.7498 8.26732C14.1248 8.29232 14.2832 8.76732 13.9915 9.00898L12.0748 10.5757L12.7082 12.959C12.8082 13.334 12.3998 13.6257 12.0832 13.4173Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_5031_5584">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg> */}


                                                        {/* <svg className="t-icon-dark" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_5031_14180)">
                                                                <path d="M16.9409 10.0007C16.9409 9.36732 17.2981 8.81732 17.8213 8.53398C18.3197 8.25898 18.6021 7.69232 18.6021 7.11732V5.00065C18.6021 4.08398 17.8546 3.33398 16.9409 3.33398H3.65186C2.73823 3.33398 1.99903 4.07565 1.99903 4.99232V7.11732C1.99903 7.69232 2.27312 8.25898 2.77976 8.52565C3.30302 8.81732 3.65186 9.36732 3.65186 10.0007C3.65186 10.634 3.29471 11.1923 2.77146 11.4673C2.27312 11.7423 1.99072 12.309 1.99072 12.884V15.0007C1.99072 15.9173 2.73823 16.6673 3.65186 16.6673H16.9409C17.8546 16.6673 18.6021 15.9173 18.6021 15.0007V12.884C18.6021 12.309 18.3197 11.7423 17.8213 11.4673C17.2981 11.184 16.9409 10.634 16.9409 10.0007ZM12.3728 13.4173L10.2964 12.084L8.21997 13.4257C7.90436 13.6257 7.49738 13.334 7.59705 12.9673L8.21997 10.5673L6.30967 9.00065C6.01897 8.75898 6.16847 8.28398 6.55054 8.25898L9.00901 8.11732L9.90603 5.82565C10.0472 5.47565 10.5456 5.47565 10.6785 5.82565L11.5755 8.12565L14.0339 8.26732C14.4077 8.29232 14.5655 8.76732 14.2748 9.00898L12.3645 10.5757L12.9957 12.959C13.0954 13.334 12.6884 13.6257 12.3728 13.4173Z" fill="#757575" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_5031_14180">
                                                                    <rect width="19.9336" height="20" fill="white" transform="translate(0.32959)" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg> */}


                                                    </div>
                                                    <span className="menu-item-text">Dashboard</span>

                                                    <span class="icon">
                                                        {contentExpand ?
                                                            (
                                                                <>
                                                                </>
                                                            )
                                                            :
                                                            (
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="view-ticket me-0 ">
                                                                    <path d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                                                    <path d="M12.5 2.5H17.5V7.5" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                                                    <path d="M8.33398 11.6667L17.5007 2.5" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                                                </svg>

                                                            )
                                                        }
                                                    </span>
                                                </a>
                                            </button>
                                        </li>

                                        {/* {contentExpand ? ( */}
                                        <div className="mob-mod menu-submenu">
                                            <div>
                                                <li className="menu-item">
                                                    <div className="menu-item-link"
                                                        onClick={() => {
                                                            history.push("/content-main/requirementContent");
                                                        }}>
                                                        <div className="menu-item-icon">
                                                            {/* <i className={item.iconClass}></i> */}
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-file-plus"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="12" y1="18" x2="12" y2="12"></line><line x1="9" y1="15" x2="15" y2="15"></line></svg>
                                                        </div>
                                                        <span className="menu-item-text">
                                                            Requirement
                                                        </span>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div className="mob-mod menu-submenu">
                                            <div>
                                                <li className="menu-item">
                                                    <div className="menu-item-link"
                                                        onClick={() => {
                                                            history.push("/content-main/databaseContent");
                                                        }}>
                                                        <div className="menu-item-icon">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-database"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg>
                                                            {/* <i className={item.iconClass}></i> */}
                                                        </div>
                                                        <span className="menu-item-text">
                                                            Database
                                                        </span>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                        <div className="mob-mod menu-submenu">
                                            <div>
                                                <li className="menu-item">
                                                    <button class="menu-item-link">
                                                        <a
                                                            href="/ticket/dashboard"
                                                            target="_blank"
                                                            className="menu-item-link p-0 w-100"
                                                        >
                                                            <div class="menu-item-icon">
                                                                <svg className="t-icon-white" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_5031_5584)">
                                                                        <path d="M16.6665 10.0007C16.6665 9.36732 17.0248 8.81732 17.5498 8.53398C18.0498 8.25898 18.3332 7.69232 18.3332 7.11732V5.00065C18.3332 4.08398 17.5832 3.33398 16.6665 3.33398H3.33317C2.4165 3.33398 1.67484 4.07565 1.67484 4.99232V7.11732C1.67484 7.69232 1.94984 8.25898 2.45817 8.52565C2.98317 8.81732 3.33317 9.36732 3.33317 10.0007C3.33317 10.634 2.97484 11.1923 2.44984 11.4673C1.94984 11.7423 1.6665 12.309 1.6665 12.884V15.0007C1.6665 15.9173 2.4165 16.6673 3.33317 16.6673H16.6665C17.5832 16.6673 18.3332 15.9173 18.3332 15.0007V12.884C18.3332 12.309 18.0498 11.7423 17.5498 11.4673C17.0248 11.184 16.6665 10.634 16.6665 10.0007ZM12.0832 13.4173L9.99984 12.084L7.9165 13.4257C7.59984 13.6257 7.1915 13.334 7.2915 12.9673L7.9165 10.5673L5.99984 9.00065C5.70817 8.75898 5.85817 8.28398 6.2415 8.25898L8.70817 8.11732L9.60817 5.82565C9.74984 5.47565 10.2498 5.47565 10.3832 5.82565L11.2832 8.12565L13.7498 8.26732C14.1248 8.29232 14.2832 8.76732 13.9915 9.00898L12.0748 10.5757L12.7082 12.959C12.8082 13.334 12.3998 13.6257 12.0832 13.4173Z" fill="white" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_5031_5584">
                                                                            <rect width="20" height="20" fill="white" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>


                                                                <svg className="t-icon-dark" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <g clip-path="url(#clip0_5031_14180)">
                                                                        <path d="M16.9409 10.0007C16.9409 9.36732 17.2981 8.81732 17.8213 8.53398C18.3197 8.25898 18.6021 7.69232 18.6021 7.11732V5.00065C18.6021 4.08398 17.8546 3.33398 16.9409 3.33398H3.65186C2.73823 3.33398 1.99903 4.07565 1.99903 4.99232V7.11732C1.99903 7.69232 2.27312 8.25898 2.77976 8.52565C3.30302 8.81732 3.65186 9.36732 3.65186 10.0007C3.65186 10.634 3.29471 11.1923 2.77146 11.4673C2.27312 11.7423 1.99072 12.309 1.99072 12.884V15.0007C1.99072 15.9173 2.73823 16.6673 3.65186 16.6673H16.9409C17.8546 16.6673 18.6021 15.9173 18.6021 15.0007V12.884C18.6021 12.309 18.3197 11.7423 17.8213 11.4673C17.2981 11.184 16.9409 10.634 16.9409 10.0007ZM12.3728 13.4173L10.2964 12.084L8.21997 13.4257C7.90436 13.6257 7.49738 13.334 7.59705 12.9673L8.21997 10.5673L6.30967 9.00065C6.01897 8.75898 6.16847 8.28398 6.55054 8.25898L9.00901 8.11732L9.90603 5.82565C10.0472 5.47565 10.5456 5.47565 10.6785 5.82565L11.5755 8.12565L14.0339 8.26732C14.4077 8.29232 14.5655 8.76732 14.2748 9.00898L12.3645 10.5757L12.9957 12.959C13.0954 13.334 12.6884 13.6257 12.3728 13.4173Z" fill="#757575" />
                                                                    </g>
                                                                    <defs>
                                                                        <clipPath id="clip0_5031_14180">
                                                                            <rect width="19.9336" height="20" fill="white" transform="translate(0.32959)" />
                                                                        </clipPath>
                                                                    </defs>
                                                                </svg>


                                                            </div>
                                                            <span className="menu-item-text">Tickets</span>

                                                            <span class="icon">
                                                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="view-ticket me-0 ">
                                                                    <path d="M15 10.8333V15.8333C15 16.2754 14.8244 16.6993 14.5118 17.0118C14.1993 17.3244 13.7754 17.5 13.3333 17.5H4.16667C3.72464 17.5 3.30072 17.3244 2.98816 17.0118C2.67559 16.6993 2.5 16.2754 2.5 15.8333V6.66667C2.5 6.22464 2.67559 5.80072 2.98816 5.48816C3.30072 5.17559 3.72464 5 4.16667 5H9.16667" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                                                    <path d="M12.5 2.5H17.5V7.5" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                                                    <path d="M8.33398 11.6667L17.5007 2.5" stroke="#757575" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="view-ticket-path" />
                                                                </svg>
                                                            </span>
                                                        </a>
                                                    </button>
                                                </li>
                                            </div>
                                        </div>
                                        {/* ) : (
                                        <div style={{ marginTop: "0" }}></div>
                                    )} */}
                                    </ul>
                                </>
                            </div>
                        </>) : (<></>)}

                        {localStorage.getItem("userDatadepartmentName") == "Freelancer" ? (<>
                            <div className="menu">
                                <>
                                    <ul className="p-0">
                                        <li className="menu-item">
                                            <button className={`${active == 1 ? "menu-item-link p-0 w-100 active" : "menu-item-link p-0 w-100"}`}>
                                                <a
                                                    className="menu-item-link p-0 w-100"
                                                    onClick={() => {
                                                        setview(true);
                                                        handleClick(1);
                                                        localStorage.setItem("iscontentview", true);
                                                        history.push("/content-main/personalDetails");
                                                    }}
                                                >
                                                    <div class="menu-item-icon">
                                                        <svg className="t-icon-white" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_5031_5584)">
                                                                <path d="M16.6665 10.0007C16.6665 9.36732 17.0248 8.81732 17.5498 8.53398C18.0498 8.25898 18.3332 7.69232 18.3332 7.11732V5.00065C18.3332 4.08398 17.5832 3.33398 16.6665 3.33398H3.33317C2.4165 3.33398 1.67484 4.07565 1.67484 4.99232V7.11732C1.67484 7.69232 1.94984 8.25898 2.45817 8.52565C2.98317 8.81732 3.33317 9.36732 3.33317 10.0007C3.33317 10.634 2.97484 11.1923 2.44984 11.4673C1.94984 11.7423 1.6665 12.309 1.6665 12.884V15.0007C1.6665 15.9173 2.4165 16.6673 3.33317 16.6673H16.6665C17.5832 16.6673 18.3332 15.9173 18.3332 15.0007V12.884C18.3332 12.309 18.0498 11.7423 17.5498 11.4673C17.0248 11.184 16.6665 10.634 16.6665 10.0007ZM12.0832 13.4173L9.99984 12.084L7.9165 13.4257C7.59984 13.6257 7.1915 13.334 7.2915 12.9673L7.9165 10.5673L5.99984 9.00065C5.70817 8.75898 5.85817 8.28398 6.2415 8.25898L8.70817 8.11732L9.60817 5.82565C9.74984 5.47565 10.2498 5.47565 10.3832 5.82565L11.2832 8.12565L13.7498 8.26732C14.1248 8.29232 14.2832 8.76732 13.9915 9.00898L12.0748 10.5757L12.7082 12.959C12.8082 13.334 12.3998 13.6257 12.0832 13.4173Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_5031_5584">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </div>
                                                    <span className="menu-item-text">My Profile</span>
                                                </a>
                                            </button>
                                        </li>
                                        {/* <div className="mob-mod menu-submenu">
                                        <div> */}
                                        <li className="menu-item">
                                            <button className={`${active == 2 ? "menu-item-link p-0 w-100 active" : "menu-item-link p-0 w-100"}`}>
                                                <a
                                                    className="menu-item-link p-0 w-100"
                                                    onClick={() => {
                                                        handleClick(2);
                                                        setview(true);
                                                        localStorage.setItem("iscontentview", true);
                                                        history.push("/content-main/FreelancersRequirementDetails");
                                                    }}>
                                                    <div class="menu-item-icon">
                                                        <svg className="t-icon-white" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_5031_5584)">
                                                                <path d="M16.6665 10.0007C16.6665 9.36732 17.0248 8.81732 17.5498 8.53398C18.0498 8.25898 18.3332 7.69232 18.3332 7.11732V5.00065C18.3332 4.08398 17.5832 3.33398 16.6665 3.33398H3.33317C2.4165 3.33398 1.67484 4.07565 1.67484 4.99232V7.11732C1.67484 7.69232 1.94984 8.25898 2.45817 8.52565C2.98317 8.81732 3.33317 9.36732 3.33317 10.0007C3.33317 10.634 2.97484 11.1923 2.44984 11.4673C1.94984 11.7423 1.6665 12.309 1.6665 12.884V15.0007C1.6665 15.9173 2.4165 16.6673 3.33317 16.6673H16.6665C17.5832 16.6673 18.3332 15.9173 18.3332 15.0007V12.884C18.3332 12.309 18.0498 11.7423 17.5498 11.4673C17.0248 11.184 16.6665 10.634 16.6665 10.0007ZM12.0832 13.4173L9.99984 12.084L7.9165 13.4257C7.59984 13.6257 7.1915 13.334 7.2915 12.9673L7.9165 10.5673L5.99984 9.00065C5.70817 8.75898 5.85817 8.28398 6.2415 8.25898L8.70817 8.11732L9.60817 5.82565C9.74984 5.47565 10.2498 5.47565 10.3832 5.82565L11.2832 8.12565L13.7498 8.26732C14.1248 8.29232 14.2832 8.76732 13.9915 9.00898L12.0748 10.5757L12.7082 12.959C12.8082 13.334 12.3998 13.6257 12.0832 13.4173Z" fill="white" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_5031_5584">
                                                                    <rect width="20" height="20" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </div>
                                                    <span className="menu-item-text"> My Requirement</span>
                                                </a>
                                            </button>

                                        </li>
                                        {/* </div>
                                    </div> */}
                                    </ul>
                                </>
                            </div>
                        </>) : (<></>)}

                    </div>
                </div>
                <div className="holder">
                    <div className="wrapper">
                        <TopNavBarCustom
                            view={view}
                        />

                        <div className="content">
                            <div className="container-fluid">
                                <Switch>
                                    {routes.map((route, i) => (
                                        <RouteWithSubRoutes key={i} {...route} />
                                    ))}
                                </Switch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
        </>

    );
};
export default ContentMain;