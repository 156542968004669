import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactSelect from "react-select";
import APIManager from "../../services/APIManager";
import {
  createKeywords,
  getAllCategory,
  getCountry,
  getGoogleTrandGetConfig,
  getKeyWordByProjectId,
  updateGTconfig,
} from "../../utility/Constant";
import {
  Frequency,
  TypeOfSearch,
  selectAllList,
  themeStyle,
} from "../../utility/Globals";

const GoogleTrends = (props) => {
  const [KeywordGoogleTrendsSelected, setKeywordGoogleTrendsSelected] =
    useState();
  const history = useHistory();
  const [keywordValue, setKeywordValue] = useState("");
  const [selectedFrequency1, setfrequency1] = useState("Daily");
  const [webSearchSelected, setWebSearchSelecttion] = useState("0");
  const [category, setCategoryList] = useState([]);
  const [categorySelected, setCategorySelected] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [countrySelected, setCountrySelected] = useState("India");
  const [keywordList, setKeywordList] = useState([]);
  const [selectedKeyword, setSelectedKeyword] = useState([]);
  const [IsActive, SetIsActive] = useState(false);
  const [oldKeywordList, setOldKeywordList] = useState([]);

  useEffect(() => {
    getCountryList();
    getCategorys();
    getKeywordlist();
  }, []);

  function addkeyword() {
    APIManager.postAPI(
      createKeywords,
      {
        uckType: 2,
        projectId: localStorage.getItem("projectGId"),
        fieldValue: keywordValue,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        getKeywordlist();
        setKeywordValue("");
        SetIsActive(false);
      }
    });
  }
  function getCountryList() {
    APIManager.getAPI(getCountry).then((response) => {
      if (response && response.status == 200) {
        setCountryList(response.data.data);
        getGoogleTrendData(response.data.data);
      }
    });
  }

  function getCategorys() {
    APIManager.getAPI(getAllCategory).then((response) => {
      if (response && response.status == 200) {
        setCategoryList(response.data);
      }
    });
  }

  function getKeywordlist() {
    APIManager.getAPI(
      getKeyWordByProjectId + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        const list = [];
        list.push({
          count: 0,
          key: "key",
          value: "Select All",
          label: "Select All",
          id: 0,
          keyword: "Select All",
        });
        let c = 1;
        const exportList = [];

        response.data.map((item) => {
          list.push({
            count: c,
            key: item.id,
            value: item.fieldValue,
            label: item.fieldValue,
            id: item.id,
            keyword: item.fieldValue,
            delete: (
              <div style={{ marginLeft: 24 + "px" }} onClick={() => {}}>
                <i class="fa fa-trash"></i>
              </div>
            ),
          });
          exportList.push({
            key: item.id,
            id: item.id,
            keyword: item.fieldValue,
          });
          c++;
        });
        setKeywordList(list);
      }
    });
  }

  function getGoogleTrendData(list) {
    APIManager.getAPI(
      getGoogleTrandGetConfig + localStorage.getItem("projectGId")
    ).then((response) => {
      if (response && response.status == 200) {
        setWebSearchSelecttion(response?.data?.searchType);
        setfrequency1(response?.data?.frequency);
        let selectedKeyword = [];
        setKeywordGoogleTrendsSelected(selectedKeyword);
        setCategorySelected(response.data.googleTrendCategoryValue);

        list.map((i) => {
          if (i.iso2 === response.data.country) {
            setCountrySelected(i.name);
          }
        });
        const list2 = [];
        response.data.googleTrendKeywordConfigurations.map((item) => {
          list2.push({
            key: item.keywordId,
            value: item.keywordName,
            label: item.keywordName,
            id: item.keywordId,
          });
        });
        setSelectedKeyword(list[0]);
        setKeywordGoogleTrendsSelected(list2);
      }
    });
  }

  function updateGTconfigiration(
    countrySelected,
    frequencyItem,
    categorySelected,
    webSearchSelected
  ) {
    let keywordidd = [];
    KeywordGoogleTrendsSelected.map((item) => {
      if (item.id != "0") {
        keywordidd.push(item.id);
      }
    });

    APIManager.postAPI(
      updateGTconfig,
      {
        country: countrySelected,
        frequency: frequencyItem,
        keywordIds: keywordidd,
        projectId: localStorage.getItem("projectGId"),
        googleTrendCategoryValue: categorySelected,
        SearchType: webSearchSelected,
      },
      null,
      true
    ).then((response) => {
      if (response && response.status == 200) {
        history.goBack();
      }
    });
  }

  return (
    <>
      <div class="row">
        <div class="col-sm-5 pad-lzero align-items-center">
          <div className="col-md-12 d-flex mb-3 p-0">
            <i
              class="fa fa-chevron-circle-left"
              style={{ fontSize: 20 + "px", color: "#2196f3" }}
              onClick={() => {
                history.goBack();
              }}
            ></i>
          </div>
        </div>
        <div class="col-sm-7 add-new-btnw"></div>
      </div>

      <div class="portlet">
        <div class="portlet-header portlet-header-bordered">
          <h3 class="portlet-title main-title">Google Trends</h3>
        </div>
        <div className="portlet-body">
          <div class="module-description">
            <div class="common-wrapper">
              <div class="common-wcard">
                <div class="common-form-fields">
                  <div class="add-user">
                    <div class="col-md-12">
                      <label>Location</label>
                      <br />
                      <select
                        required
                        value={countrySelected}
                        onChange={(e) => {
                          var c = e.target.value;
                          setCountrySelected(c);
                        }}
                      >
                        {countryList.map((item) => {
                          return <option value={item.name}>{item.name}</option>;
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3">
                      <label>Type of Search</label>
                      <br />
                      <select
                        value={webSearchSelected}
                        onChange={(item) => {
                          setWebSearchSelecttion(item.target.value);
                        }}
                      >
                        {TypeOfSearch.map((item) => {
                          return (
                            <option value={item.value}>{item.label}</option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3 ">
                      <label>Category</label>
                      <br />
                      <select
                        value={categorySelected}
                        onChange={(item) => {
                          setCategorySelected(item.target.value);
                        }}
                      >
                        {category.map((item) => {
                          return (
                            <option value={item.googleTrendCategoryValue}>
                              {item.categoryName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3">
                      <label>Set Frequency</label>
                      <br />
                      <select
                        name=""
                        id="keywordGTFrequency"
                        value={selectedFrequency1}
                        onChange={(item) => {
                          setfrequency1(item.target.value);
                        }}
                      >
                        {Frequency.map((item) => {
                          return (
                            <option value={item.DisplayName}>
                              {item.DisplayName}
                            </option>
                          );
                        })}
                      </select>
                    </div>

                    <div class="col-md-12 mt-3 ">
                      <label>Keyword</label>
                      <div className="d-flex justify-content-between w-set">
                        <ReactSelect
                          styles={themeStyle()}
                          className="w-200"
                          options={keywordList}
                          isMulti
                          closeMenuOnSelect={false}
                          hideSelectedOptions={false}
                          onChange={(selected) => {
                            setKeywordGoogleTrendsSelected(
                              selectAllList(
                                selected,
                                oldKeywordList,
                                keywordList
                              )
                            );
                            setOldKeywordList(selected);
                          }}
                          allowSelectAll={true}
                          value={KeywordGoogleTrendsSelected}
                        />

                        <button
                          style={{ border: "none", background: "none" }}
                          onClick={() => {
                            SetIsActive(true);
                          }}
                        >
                          {" "}
                          <i
                            class="fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>{" "}
                        </button>
                      </div>

                      {IsActive ? (
                        <div class="col-md-12 d-flex w-50 mt-3 p-0">
                          <label></label>
                          <input
                            type="text"
                            name=""
                            placeholder="Enter Keyword"
                            value={keywordValue}
                            onChange={(text) => {
                              setKeywordValue(text.target.value);
                            }}
                          />

                          <button
                            style={{ border: "none", background: "none" }}
                            onClick={() => {
                              SetIsActive(false);
                              setKeywordValue("");
                            }}
                          >
                            {" "}
                            <i
                              class="fa fa-times d-flex"
                              aria-hidden="true"
                            ></i>{" "}
                          </button>

                          <button
                            style={{ border: "none", background: "none" }}
                            id={"rightbtn"}
                            onClick={() => {
                              if (keywordValue) {
                                addkeyword();
                              }
                            }}
                          >
                            {" "}
                            <i class="fa fa-check" aria-hidden="true"></i>{" "}
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 mt-3">
                <button
                  class="btn btn-primary"
                  onClick={() => {
                    let keyWordIdList = [];
                    if (
                      KeywordGoogleTrendsSelected &&
                      KeywordGoogleTrendsSelected.length != 0
                    ) {
                      KeywordGoogleTrendsSelected.map((item) => {
                        keyWordIdList.push(item.id);
                      });
                      updateGTconfigiration(
                        countrySelected,
                        selectedFrequency1,
                        keyWordIdList,
                        categorySelected,
                        webSearchSelected
                      );
                    } else {
                    }
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoogleTrends;
