import React, { useEffect } from "react";
import "antd/dist/antd.css";
import { Modal } from "react-bootstrap";

function ModelScopeOfWork(props) {

  useEffect(() => {

  }, []);

  return (
    <>
      <div class="portlet-header portlet-header-bordered">
        <Modal
          show={props.modelShow}
          onHide={() => {
            props.modelCloseClick()
          }}
          className="ticket-filter-modal"
        >
          <Modal.Header closeButton>
            <div class="d-flex align-items-center">
              <h5 class="fw-bold text-capitalize ms-2 mb-0">Scope of Work</h5>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div class="container">
              <div class="row">
                <div class="col-sm-12">
                  <form class="ticket-filter-form">
                    <div class="col-md-12 mt-3">
                      <label for="exampleInputcode1" class="form-label">Activity Type</label>
                      <select class="form-select" aria-label="Default select example">
                        <option selected="">select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label for="exampleInputcode1" class="form-label">Responsibilities</label>
                      <select class="form-select" aria-label="Default select example">
                        <option selected="">select</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label">Particulars</label>
                      <input
                        type="text"
                        onChange={(text) => {
                        }}
                        class="w-100"
                      ></input>
                    </div>
                    <div class="col-md-12 mt-3">
                      <label className="form-label">Units</label>
                      <input
                        type="text"
                        onChange={(text) => {
                        }}
                        class="w-100"
                      ></input>
                    </div>
                    <div class="col-md-12 mt-3" onClick={() => {
                      props.modelCloseClick()
                    }}>
                      <div class="btn btn-primary d-flex align-items-center justify-content-center">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        <span class="ms-2">Add SOW Ticket</span>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default ModelScopeOfWork;
